import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 'auto',
    },
    'left-side': {
      '& > *': {
        marginRight: 'calc(10px * var(--scale-coefficient))',
      },
    },
    leftSideButton: {
      margin: '0 calc(5px * var(--scale-coefficient))',
      minWidth: 'calc(93px * var(--scale-coefficient))',
    },
    dotted: {
      fontSize: 'calc(12px * var(--scale-coefficient))',
      borderBottom: '1px dotted var(--primary-color)',
    },
    validationButton: {
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      transition: 'background-color 0.2s, color 0.2s',
    },
    'right-side': {},
    addConnectionButton: {
      border: '1px solid var(--dark-cyan)',
      borderRadius: '4px',
      bottom: 'calc(-2px * var(--scale-coefficient));',
    },
    disabled: {
      opacity: '.5',
      pointerEvents: 'none',
    },
  }),
);
