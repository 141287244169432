import React, { FC } from 'react';
import block from 'bem-cn';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NoSchedule from '../no-schedule/no-schedule';
import Schedule from '../../../../types/loader-schedule';
import { ScheduleType } from '../helper';
import { SelectInput } from '../../../common/special-inputs/inputs/select-input';
import { DateInput } from '../../../common/special-inputs/inputs/date-input';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';

const b = block('loader-schedule-body');
const launchersBlock = block('launchers');

const scheduleTypeOption = [
  { value: ScheduleType.EVERY_MINUTES, label: 'Каждые N минут' },
  { value: ScheduleType.EVERY_DAY, label: 'Каждый день' },
];

interface LoaderScheduleProps {
  scheduleData: Schedule.FullData;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const LoaderScheduleBody: FC<LoaderScheduleProps> = (props) => {
  const { scheduleData, handleChange } = props;
  const {
    type,
    intervalInMinutes,
    startDateTime,
    nearestLaunches,
    enable,
  } = scheduleData;

  if (!enable) {
    return <NoSchedule />;
  }

  return (
    <div className={b()}>
      <div className={b('fields')}>
        <div className={b('field')}>
          <SelectInput
            fullWidth
            label="Период"
            value={type}
            formikName="type"
            handleChange={handleChange}
            itemList={scheduleTypeOption.map((type) => ({
              value: type.value,
              showValue: type.label,
            }))}
          />
        </div>
        <div className={b('field')}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateInput
              label="День запуска и время запуска"
              value={startDateTime}
              onChange={(date) =>
                handleChange({
                  target: {
                    value: date?.format('YYYY-MM-DDTHH:mm') || '',
                    name: 'startDateTime',
                  },
                } as React.ChangeEvent<HTMLInputElement>)}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </div>

        {type === ScheduleType.EVERY_MINUTES && (
          <div className={b('field')}>
            <TextInput
              label="Интервал"
              name="intervalInMinutes"
              value={intervalInMinutes}
              handleChange={handleChange}
              fullWidth
            />
          </div>
        )}
      </div>
      {Boolean(nearestLaunches.length) && (
        <div className={launchersBlock()}>
          <h3 className={launchersBlock('title')}>
            Предпросмотр ближайших запусков
          </h3>
          <ul className={launchersBlock('list')}>
            {nearestLaunches.map((launch, index) => {
              return (
                <li className={launchersBlock('item')} key={index}>
                  {launch}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LoaderScheduleBody;
