import React, {
  ChangeEvent,
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CircularProgress } from '@material-ui/core';
import { VariableSizeList } from 'react-window';
import { useSelector } from 'react-redux';
import { getScaleCoefficient } from '../../../../../helpers/ui-helper';
import { useRoundingCounts } from '../../../../../hooks/useRoundingCounts';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import { PanelType } from '../../../../../enums/widget-type';
import {
  setPropForDate,
  setPropForNumberValue,
} from '../../formatting-helpers';
import { useMapProperties } from '../hooks/useMapProperties';
import { State, WidgetProperties } from '../../../../../slices/types';
import { PopupContent } from '../map-helpers';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';
import { CustomProgress } from '../../../../../uikit/Progress';

interface CardsListProps {
  popupContentList: PopupContent[];
  onChangeFilter: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  widgetProperties: WidgetProperties[];
  isLoadingCards: boolean;
}

const CardsList = ({
  popupContentList,
  onChangeFilter,
  widgetProperties,
  isLoadingCards,
}: CardsListProps) => {
  const {
    dateDisplayFormat
  } = useMapProperties({ widgetProperties });

  const isFullScreen = useSelector(
    (state: State) => state.mainPage.isWidgetFullScreen,
  );

  const isDashboardFullScreen = useSelector(
    (state: State) => state.dashboard.currentWidgetIdFullScreen,
  );

  const cardsListRef = useRef<VariableSizeList>(null);

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const getFormattedValue = (
    fieldType: string,
    content: string,
  ) => {
    if (fieldType === 'TEXT') return content;

    const widgetPropertiesForFormatting =
      ['NUMBER', 'DOUBLE'].includes(fieldType)
        ? setPropForNumberValue(widgetProperties)
        : setPropForDate(
          '',
          dateDisplayFormat,
          fieldType,
        );

    return valueFormat(
      content,
      0,
      widgetPropertiesForFormatting,
      PanelType.axisY,
    );
  };

  const MapCard = ({ index, style }: { index: number; style: CSSProperties }) => {
    const popupContent = popupContentList[index];

    return (
      <div
        className="map-card"
        key={`map-popup-${index}`}
        style={{ ...style, height: (style.height as number) - 10 }}
      >
        {popupContent.cardData.map((card) => {
          const formattedValue = getFormattedValue(card.type, card.value);

          return (
            <>
              <span className="map-card__label">{card.title}:</span>
              <div className="map-card__value">{formattedValue}</div>
            </>
          );
        })}
      </div>
    );
  };

  const [virtualizedListHeight, setVirtualizedListHeight] = useState(0);
  const getVirtualizedListHeight = () => {
    const containerHeight: number =
      document.getElementById('map-cards__container')?.clientHeight || 0;
    const containerOffset = 20 * getScaleCoefficient();
    const filterInputOffset = 15 * getScaleCoefficient();
    const topOffset = containerOffset * 2 + filterInputOffset;
    const filterInputHeight = 50 * getScaleCoefficient();

    const calculatedHeight = Math.max(containerHeight - topOffset - filterInputHeight, 0);
    setVirtualizedListHeight(calculatedHeight);
  };

  useEffect(() => {
    getVirtualizedListHeight();
  }, [isFullScreen, isDashboardFullScreen]);

  const getCardHeight = (index: number): number => {
    const cardLength: number = popupContentList[index].cardData.length + 1;

    const titleOffset = 2 * getScaleCoefficient();
    const valueOffset = 6 * getScaleCoefficient();

    const titleHeight = 16 * getScaleCoefficient() + titleOffset;
    const valueHeight = 16 * getScaleCoefficient() + valueOffset;
    const cardPadding = 20 * 2;

    return cardLength * (titleHeight + valueHeight) + cardPadding;
  };

  useEffect(() => {
    cardsListRef.current && cardsListRef.current.resetAfterIndex(0);
  }, [popupContentList]);

  return (
    <div className="map-cards__container" id="map-cards__container">
      <TextInput
        label="Поиск по карточкам"
        handleChange={onChangeFilter}
        searchIcon
        fullWidth
      />

      <div className="map-cards__list">
        {isLoadingCards && <CustomProgress type="circular" />}
        {!isLoadingCards && !popupContentList.length && (
          <div className="map-cards__list_empty">
            Нет данных для отображения
          </div>
        )}
        {!isLoadingCards && Boolean(popupContentList.length) && (
          <VariableSizeList
            className='map-virtualized-list'
            ref={cardsListRef}
            height={virtualizedListHeight}
            itemCount={popupContentList.length}
            itemSize={(index) => getCardHeight(index)}
            width="100%"
          >
            {MapCard}
          </VariableSizeList>
        )}
      </div>
    </div>
  );
};

export default CardsList;
