import { useCallback, useMemo } from 'react';
import { getStickPointValue } from '../../helpers';
import {
  minBarComputedSize,
  leftAxisSettings,
  defaultMinSpaceBetweenBarGroups,
  defaultMinSpaceBetweenBars,
} from '../settings';

interface Props {
  barWidth?: number;
  barHeight?: number;
  barsInGroupCount: number;
  barGroupsCount: number;
  axisScrollOffset?: number;
  columnDiffCoeff?: number;
  columnOffsetCoeff: number;
}

export interface Bar {
  color: string;
  height: number;
  index: number;
  key: string;
  value: number;
  width: number;
  x: number;
  y: number;
}

export const useBarDimensions = ({
  barWidth,
  barHeight,
  barsInGroupCount,
  barGroupsCount,
  axisScrollOffset,
  columnDiffCoeff,
  columnOffsetCoeff,
}: Props) => {
  const barSize = barWidth || barHeight || minBarComputedSize;
  const spaceBetweenBars = Math.max(
    (columnDiffCoeff || 0) * barSize, defaultMinSpaceBetweenBars);
  const minSpaceBetweenBarGroups = Math.max(
    columnOffsetCoeff * barSize, defaultMinSpaceBetweenBarGroups);
  
  const groupBarSize = useMemo(() => {
    return (barSize + spaceBetweenBars) * barsInGroupCount - spaceBetweenBars;
  }, [barSize, barsInGroupCount, spaceBetweenBars]);

  const getMinChartLength = useCallback(() => {
    return (barGroupsCount * (groupBarSize + minSpaceBetweenBarGroups)) - minSpaceBetweenBarGroups;
  }, [barGroupsCount, groupBarSize, minSpaceBetweenBarGroups]);

  const minChartLength = getMinChartLength();

  const getOffsetBetweenBarGroups = useCallback((containerSize: number) => {
    if (minChartLength < containerSize) {
      const spaceBetweenBarGroups = (containerSize - (barGroupsCount * groupBarSize)) / barGroupsCount;

      return Math.abs(spaceBetweenBarGroups);
    }

    return minSpaceBetweenBarGroups;
  }, [
    minChartLength,
    minSpaceBetweenBarGroups,
    barGroupsCount,
    groupBarSize,
  ]);

  const getComputedBarWidth = useCallback((bar: Bar, scale: any) => {
    const minWidth = 5;
    const stickPointValue = getStickPointValue(scale.ticks());
    
    const width = bar.value > 0
      ? scale(bar.value) - scale(stickPointValue)
      : scale(stickPointValue) - scale(bar.value);
  
    return width > minWidth ? width : minWidth;
  }, []);

  const getComputedBarHeight = useCallback((bar: Bar, scale: any) => {
    const minHeight = 5;
    const stickPointValue = getStickPointValue(scale.ticks());

    const height = bar.value < 0
      ? scale(bar.value) - scale(stickPointValue)
      : scale(stickPointValue) - scale(bar.value);
    
    return height > minHeight ? height : minHeight;
  }, []);

  const getBarXCoord = (bar: Bar, scale: any) => {
    const stickPointValue = getStickPointValue(scale.ticks());
    const negativeBarXValue = scale(stickPointValue) - getComputedBarWidth(bar, scale);

    return bar.value > 0
      ? scale(stickPointValue)
      : negativeBarXValue;
  };

  const getBarYCoord = (bar: Bar, scale: any, offsetBarHeightValue: number) => {
    const stickPointValue = getStickPointValue(scale.ticks());
    const negativeBarYValue = scale(stickPointValue) - getComputedBarHeight(bar, scale);
    const topOffset = leftAxisSettings.top - (axisScrollOffset ?? 0) + (offsetBarHeightValue / 2);
    // need to handle all cases: positive, negative and both
    return bar.value > 0
      ? negativeBarYValue + topOffset
      : scale(stickPointValue) + topOffset;
  };

  return {
    getOffsetBetweenBarGroups,
    getComputedBarWidth,
    getComputedBarHeight,
    groupBarSize,
    getBarXCoord,
    getBarYCoord,
    minChartLength,
    spaceBetweenBars,
  };
};
