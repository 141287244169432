import { styled } from '@material-ui/core';
import React, { FC } from 'react';
import clsx from 'clsx';
import { WidgetType } from '../../enums/widget-type';

export interface ICardContent {
  children: React.ReactNode;
  widgetType: WidgetType;
}

const CardContentWrapper = styled('div')(() => ({
  padding: '5px 5px 0 5px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const EXCLUDE_ZOOM_SVG_WIDGET_TYPES = [
  WidgetType.TABLE,
  WidgetType.PIVOT_TABLE,
  WidgetType.INFORMATION_CARD,
  WidgetType.GANTT_CHART,
  WidgetType.SUNBURST,
];

export const CardContent: FC<ICardContent> = ({ children, widgetType }) => {
  return (
    <CardContentWrapper
      className={clsx('card__content', {
        'card__content_zoom-svg':
          !EXCLUDE_ZOOM_SVG_WIDGET_TYPES.includes(widgetType),
      })}
    >
      {children}
    </CardContentWrapper>
  );
};

export default CardContent;
