import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDashboardSettings } from '../../settings/hooks/useDashboardSettings';
import {
  dashboardWidgetDataSelector,
  dashboardWidgetDirtySelector,
  dashboardWidgetErrorSelector,
  dashboardWidgetLoadingSelector,
  setWidgetAPIToDashboard,
} from '../../../../slices/dashboard/dashboard';
import { State } from '../../../../slices/types';
import { Roles } from '../../../../enums/roles';
import { isNeedToIgnoreEmptyWidgetData } from '../../../widget-page/helpers';
import { getActiveFilterType } from '../../../../helpers/dashboard-page/widget';

interface useWidgetCardProps {
  widgetId: number;
  permission: any;
  getFilters: (type: string) => any;
  isActiveDrilldownFilter: boolean;
}

export const useWidgetCard = ({
  widgetId,
  permission,
  getFilters,
  isActiveDrilldownFilter,
}: useWidgetCardProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    dashboardWidgetLoadingSelector(String(widgetId)),
  );
  const isDirty = useSelector(dashboardWidgetDirtySelector(String(widgetId)));
  const error = useSelector(dashboardWidgetErrorSelector(String(widgetId)));

  const { showFiltersForPanel } = useDashboardSettings();

  const widgetProps = useSelector(
    dashboardWidgetDataSelector(String(widgetId)),
  );

  const projectIdParam = useParams<{ projectId: string | undefined }>()?.projectId;

  const projectId: number | null = projectIdParam ? parseInt(projectIdParam) : null;

  const projectPermission =
    useSelector((state: State) => state.mainPage.currentProject?.permission) ||
    0;

  const hasPermissionForEditingWidget =
    projectPermission !== Roles.Reporter && permission !== Roles.Reporter;

  const setWidgetData = useCallback(
    (data: any) => {
      dispatch(
        setWidgetAPIToDashboard({
          widgetId: String(widgetId),
          params: {
            data: {
              ...widgetProps,
              data,
            },
          },
        }),
      );
    },
    [dispatch, widgetId, widgetProps],
  );

  const setWidgetProperties = useCallback(
    (properties: any) => {
      dispatch(
        setWidgetAPIToDashboard({
          widgetId: String(widgetId),
          params: {
            data: {
              ...widgetProps,
              ...properties,
              data: widgetProps.data,
            },
          },
        }),
      );
    },
    [dispatch, widgetId, widgetProps],
  );

  const dashboardProps = useMemo(
    () => ({
      widgetProps,
      setWidgetData,
      setWidgetProperties,
    }),
    [setWidgetData, setWidgetProperties, widgetProps],
  );

  const isEmptyData =
    (error !== null || !widgetProps.data) &&
    !isNeedToIgnoreEmptyWidgetData(widgetProps.type);

  const {
    setFilterField,
    filterField,
    isActiveFilter,
    setMultipleFilterFields,
  }: any = getFilters(getActiveFilterType(isActiveDrilldownFilter));

  return {
    isLoading,
    error,
    widgetProps,
    projectId,
    hasPermissionForEditingWidget,
    dashboardProps,
    isEmptyData,
    setFilterField,
    filterField,
    isActiveFilter,
    setMultipleFilterFields,
    showFiltersForPanel
  };
};
