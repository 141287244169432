import { RefObject, useEffect, useRef } from 'react';
import { CustomEventMap, RootElement } from '../types/root';
import { getRootElement } from '../utils/root-helper';

export function useEventListener<T extends HTMLElement | Window = HTMLDivElement>(
  eventName: keyof CustomEventMap,
  handler: Function,
  element?: RefObject<T>,
) {
  // Create a ref that stores handler
  const savedHandler = useRef<Function>();
  useEffect(() => {
    // Define the listening target
    const targetElement: T | RootElement = (element?.current || getRootElement() || null);
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }
    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }
    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };
    targetElement.addEventListener(eventName, eventListener);
    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, handler]);
}
