import { flatten } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { WidgetNames } from '../../../dictionaries/naming-dictionary/widget-names';
import { State, Widget } from '../../../slices/types';
import DropdownList from '../../common/dropdown-list';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import WidgetListItem from './list-item';
import {
  CommonDictionary,
  DashboardDictionary,
  WidgetDictionary,
} from '../../../dictionaries/naming-dictionary/naming-dictionary';

export enum widgetIconNames {
  HISTOGRAM = 'HistogramWidget',
  HORIZONTAL_HISTOGRAM = 'HorizontalHistogramWidget',
  STACKED_HISTOGRAM = 'StackedHistogramWidget',
  STACKED_HORIZONTAL_HISTOGRAM = 'HorizontalStackedHistogramWidget',
  HISTOGRAM_GRAPH = 'HistogramGraphWidget',
  GRAPH = 'GraphWidget',
  GRAPH_AREA = 'GraphAreaWidget',
  PIE_CHART = 'PieWidget',
  DONUT_CHART = 'DonutWidget',
  TREE_MAP = 'TreemapWidget',
  SPEEDOMETER = 'SpeedometerWidget',
  TABLE = 'TableWidget',
  MAP = 'MapWidget',
  HTML = 'HtmlWidget',
  GANTT_CHART = 'GanttWidget',
  INFORMATION_CARD = 'InfoCardWidget',
  PIVOT_TABLE = 'PivotWidget',
  FUNNEL = 'SalesFunnelWidget',
  RADAR_CHART = 'RadarWidget',
  SUNBURST_CHART = 'SunburstWidget',
  BUBBLE_CHART = 'BubbleWidget',
  NONE = 'Card',
}

type WidgetsGroupListProps = {
  widgets: Widget[];
  title: string;
};

const WidgetsGroupList = ({ widgets, title }: WidgetsGroupListProps) => (
  <>
    {Boolean(widgets?.length) && <h3 className="widget-list-title">{title}</h3>}
    <div>
      {widgets?.map((widget: Widget, index) => {
        const draggableId = {
          // приведение к строке нужна для синхронизации с беком,
          // если сделать id как строку в свойстве дашборда layout,
          // то на беке тоже нужно поправить этот момент (там где импорт проектов)
          id: String(widget.id),
          name: widget.name,
          type: widget.type,
        };

        return (
          <Draggable
            draggableId={JSON.stringify(draggableId)}
            index={index}
            key={`widget-${widget.id}`}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                }}
              >
                <WidgetListItem
                  name={widget.name}
                  id={widget.id}
                  iconName={
                    widgetIconNames[widget.type as keyof typeof widgetIconNames]
                  }
                />
              </div>
            )}
          </Draggable>
        );
      })}
    </div>
  </>
);

const WidgetsList = () => {
  const [nameFilter, setNameFilter] = useState('');

  const filterName = (widget: Widget) =>
    widget.name.toLowerCase().includes(nameFilter.toLowerCase());

  const widgetsList: Widget[] = (
    useSelector(
      (state: State) => state.mainPage?.currentProjectWidgets ?? null,
    ) ?? []
  ).filter(filterName);
  const currentGroupWidgets: Widget[] = (
    useSelector((state: State) => state.mainPage?.currentGroupWidgets) ?? []
  ).filter(filterName);

  const dashboardWidgetsIds = useSelector(
    (state: State) => state.dashboard.widgetIds,
  );
  const dashboardDDWidgetsIds = useSelector(
    (state: State) => state.dashboard.drillDownWidgetIds,
  );

  const notAddedWidgets =
    widgetsList?.filter(
      (widget) =>
        !dashboardWidgetsIds.includes(widget.id) &&
        !dashboardDDWidgetsIds.includes(widget.id as number),
    ) ?? [];
  const addedWidgets =
    widgetsList?.filter((widget) => dashboardWidgetsIds.includes(widget.id)) ??
    [];
  const addedDDWidgets =
    widgetsList?.filter((widget) =>
      dashboardDDWidgetsIds.includes(widget.id as number),
    ) ?? [];

  const notAddedGroupWidgets: Widget[] | null =
    currentGroupWidgets?.filter(
      (widget) => !dashboardWidgetsIds.includes(widget.id),
    ) ?? [];
  const addedGroupWidgets: Widget[] | null =
    currentGroupWidgets?.filter((widget) =>
      dashboardWidgetsIds.includes(widget.id),
    ) ?? [];

  const widgetGroupsList = [
    {
      id: 1,
      title: WidgetDictionary.titleProjectWidgets,
      groups: [
        {
          id: 1,
          title: 'Добавлены',
          widgets: addedWidgets,
        },
        {
          id: 2,
          title: `Добавлены как ${CommonDictionary.Drilldown}`,
          widgets: addedDDWidgets,
        },
        {
          id: 3,
          title: 'Не добавлены',
          widgets: notAddedWidgets,
        },
      ],
    },
    {
      id: 2,
      title: WidgetDictionary.titleGroupWidgets,
      groups: [
        {
          id: 1,
          title: 'Добавлены',
          widgets: addedGroupWidgets,
        },
        {
          id: 2,
          title: 'Не добавлены',
          widgets: notAddedGroupWidgets,
        },
      ],
    },
  ];

  return (
    <>
    <TextInput
      InputProps={{ maxlength: 255 }}
      value={nameFilter}
      label={`Поиск по названию ${WidgetNames.few}`}
      handleChange={(event) => setNameFilter(event.target.value)}
      className="setting-field"
      searchIcon
    />
      <div className="widgets-list">
        {widgetGroupsList.map((section) => {
          const hasWidgets =
            flatten(section.groups.map((group) => group.widgets)).length > 0;

          return (
            hasWidgets && (
              <DropdownList
                key={section.id}
                title={section.title}
                isOpenInitial={true}
              >
                {section.groups.map((group) => (
                  <Droppable
                    key={group.id}
                    droppableId={`projectWidgets-${section.id}-${group.id}`}
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <WidgetsGroupList
                          widgets={group.widgets}
                          title={group.title}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DropdownList>
            )
          );
        })}
      </div>
    </>
  );
};

export default WidgetsList;
