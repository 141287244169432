import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PanelType } from '../enums/widget-type';
import {
  getOrderDirection,
  getPropertiesWithOrderOptionByIndexPanel,
} from '../components/widget-page/charts/helpers';
import { apiGetWidgetData } from '../services/widgetController';
import { setWidgetPropsAction } from '../slices/widget/widget';
import { useDashboardWidgetRequestParams } from '../components/dashboard-page/hooks/useDashboardWidgetRequestParams';
import { WidgetProperties } from '../slices/types';
import { getPanelIndex } from '../components/widget-page/dropdown-layout/helpers/helpers';
import { mergeWidgetProperties } from '../utils/functions';
import { SetOverriddenProperties } from '../helpers/dashboard-page/widget';
import { IconDictionary } from '../dictionaries/icon-dictonary/icon-dictionary';

type UseWidgetSortingProps = {
  dashboardProps?: {
    widgetProps: any;
    setWidgetData: React.Dispatch<any>;
    setWidgetProperties: React.Dispatch<any>;
  };
  widgetProperties: any;
  axisName: PanelType;
  fromDashboard?: boolean;
  overriddenProperties?: WidgetProperties[];
  setOverriddenProperties?: SetOverriddenProperties;
};

type UseWidgetSorting = (
  props: UseWidgetSortingProps,
) => {
  isSortedDataLoading: boolean;
  setSortedDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currentOrderIndexColumn: number;
  getOrderIconByIndex: (index: number) => any;
  changeOrderingHandler: (index: number) => () => void;
};

const getOrderDirectionIcon = (orderDirection: string) => {
  switch (orderDirection) {
    case 'ASC':
      return IconDictionary.WidgetSortDown;
    case 'DESC':
      return IconDictionary.WidgetSortUp;
    default:
      return IconDictionary.WidgetSort;
  }
};

export const useWidgetSorting: UseWidgetSorting = ({
  dashboardProps,
  widgetProperties,
  axisName,
  fromDashboard = false,
  overriddenProperties = [],
  setOverriddenProperties,
}) => {
  const [isSortedDataLoading, setSortedDataLoading] = useState(false);
  const [currentOrderIndexColumn, setCurrentOrderIndexColumn] = useState(0);
  const dispatch = useDispatch();

  const requestParams = useDashboardWidgetRequestParams();

  const changeOrderingHandler = useCallback(
    (index: number) => () => {
      setSortedDataLoading(true);
      setCurrentOrderIndexColumn(index);

      if (dashboardProps) {

        const { setWidgetData, setWidgetProperties } = dashboardProps;

        const modifiedProperties: WidgetProperties[] = getPropertiesWithOrderOptionByIndexPanel({
          properties: widgetProperties,
          index,
          axisName,
        });

        setWidgetProperties(modifiedProperties);

        const requestBody = {
          ...widgetProperties,
          properties: modifiedProperties,
          data: [],
        };

        let requestMethod: any = () => apiGetWidgetData(requestBody);

        if (fromDashboard && setOverriddenProperties) {
          const panelIndex = getPanelIndex({
            properties: modifiedProperties,
            type: axisName,
          });
          setOverriddenProperties(mergeWidgetProperties(overriddenProperties, [modifiedProperties[panelIndex]]));

          // запрос выболнится с эфекта вижета дашборда
          requestMethod = null;
        }

        requestMethod && requestMethod()
          .then((result: any) => {
            setWidgetData(result);
            setSortedDataLoading(false);
          })
          .catch((err: any) => {
            setSortedDataLoading(false);
          });
      } else {
        const properties = [...widgetProperties] || [];

        const modifiedProperties = getPropertiesWithOrderOptionByIndexPanel({
          properties,
          index,
          axisName,
        });

        dispatch(setWidgetPropsAction(modifiedProperties));
      }
    },
    [axisName, dashboardProps, dispatch, fromDashboard, JSON.stringify(requestParams), JSON.stringify(overriddenProperties), widgetProperties],
  );

  const getOrderIconByIndex = (index: number) =>
    getOrderDirectionIcon(getOrderDirection(widgetProperties, index, axisName));

  return {
    isSortedDataLoading,
    setSortedDataLoading,
    currentOrderIndexColumn,
    getOrderIconByIndex,
    changeOrderingHandler,
  };
};
