import React, { useEffect, useState } from 'react';
import { FormControl, RadioGroup } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useClassButton } from '../../../hooks/useClassButton';
import {
  apiCheckProjectNotificationSubscription,
  apiProjectNotificationSubscribe,
} from '../../../services/projectsController';
import { useNotificator } from '../snackbar/hooks';
import { CustomButton } from '../../../uikit/Button';
import { CustomFormControl } from '../../../uikit/FormControl';
import { CustomRadio } from '../../../uikit/Radio/Radio';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: 'calc(16px * var(--scale-coefficient))',
      },
      padding: 'calc(20px * var(--scale-coefficient))',
    },
  }),
);

interface SubscribeFormProps {
  handleCloseForm: () => void;
  projectId: number;
}

export const SubscribeForm: React.FC<SubscribeFormProps> = ({
  handleCloseForm,
  projectId,
}) => {
  const classes = useStyles();
  const classesDefault = useClassButton();

  const { showNotification } = useNotificator();

  const handleSubmit = (e: any) => {
    apiProjectNotificationSubscribe({ notificationType: value, projectId }).then(() => {
      const message =
        value === 'ALL'
          ? 'Вы подписаны на обновления.'
          : value === 'ERROR'
            ? 'Вы подписаны на ошибки.'
            : 'Вы отписаны от обновлений.';
      showNotification({ message, variant: 'success' });
      handleCloseForm();
    });
    e.preventDefault();
    return false;
  };

  const [value, setValue] = useState<'ALL' | 'ERROR' | 'UNSUBSCRIBE'>(
    'UNSUBSCRIBE',
  );

  useEffect(() => {
    apiCheckProjectNotificationSubscription(projectId).then((data) => {
      setValue(data);
    });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      (event.target as HTMLInputElement).value as
        | 'ALL'
        | 'ERROR'
        | 'UNSUBSCRIBE',
    );
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit} autoComplete="off">
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          <CustomFormControl
            value="ALL"
            control={<CustomRadio color="primary" />}
            label="Подписываться на обновления"
          />
          <CustomFormControl
            value="ERROR"
            control={<CustomRadio color="primary" />}
            label="Подписываться только на ошибки"
          />
          <CustomFormControl
            value="UNSUBSCRIBE"
            control={<CustomRadio color="primary" />}
            label="Не подписываться на уведомления"
          />
        </RadioGroup>
      </FormControl>
      <div className="dialog-buttons dialog-buttons_in-form">
        <CustomButton
          type="submit"
          variant="contained"
        >
          Сохранить
        </CustomButton>

        <CustomButton variant="outlined" onClick={handleCloseForm}>
          Отменить
        </CustomButton>
      </div>
    </form>
  );
};
