import { AxiosResponse } from 'axios';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import domtoimage from 'dom-to-image-more';

type InputEvent = Event & { target: HTMLInputElement };

export const openFileUploadDialog = (): Promise<InputEvent> => {
  return new Promise(resolve => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = event => resolve(event as InputEvent);
    input.click();
  });
};

export const convertFileToForm = (file: File): FormData => {
  const form = new FormData();
  form.append('file', file);

  return form;
};

export const fileDownload = (file: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const imageDownload = (image: any, fileName: string) => {
  const link = document.createElement('a');
  link.href = image;

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const defineFileDataFromResponse = (res: AxiosResponse) => {
  const file = res.data;
  const fileName = decodeURIComponent(res.headers['content-disposition']?.split('filename=')[1] || 'data');

  return {
    file,
    fileName
  };
};

export const changeNodeForWidgetScreenshot = (
  node: HTMLDivElement,
  isFinally: boolean = false,
) => {
  const toggleScrollVisible = () => {
    const overflowValue = isFinally ? 'auto' : 'hidden';

    const scrollableChildren: any[] = [
      {
        className: '.screenshot-overflow',
        styleProperty: 'overflow',
      },
      {
        className: '.screenshot-overflow-y',
        styleProperty: 'overflowY',
      },
      {
        className: '.screenshot-overflow-x',
        styleProperty: 'overflowX',
      },
    ];

    scrollableChildren.forEach((scrollableChild) => {
      const scrollChildren = node.querySelectorAll(scrollableChild.className);
      scrollChildren.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        (item as HTMLDivElement).style[scrollableChild.styleProperty] = overflowValue;
      });
    });
  };

  toggleScrollVisible();
};

export const takeScreenShot = async (
  format: string,
  node: HTMLDivElement,
  fileName: string,
  filter?: (node: Node) => boolean,
  startCallback?: () => void,
  finallyCallback?: () => void,
) => {
  startCallback && startCallback();

  const toImage = getToImageFunctionByFormat(format);

  toImage(node, { filter }).then((dataUrl: any) => {
    imageDownload(dataUrl, `${fileName}.${format}`);
  }).finally(() => {
    finallyCallback && finallyCallback();
  });
};

const getToImageFunctionByFormat = (format: string) => {
  switch (format) {
    case 'svg':
      return domtoimage.toSvg;
    default:
      return domtoimage.toPng;
  }
};
