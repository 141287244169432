import { useCallback } from 'react';
import {
  SetMultipleFilterFields,
} from '../../components/dashboard-page/hooks';

interface useWidgetMultipleFiltersProps {
  setMultipleFilterFields: SetMultipleFilterFields;
  isActiveFilter: boolean;
  widgetId: number;
}

export const useWidgetMultipleFilters = ({
  setMultipleFilterFields,
  isActiveFilter,
  widgetId,
}: useWidgetMultipleFiltersProps) => {
  const deselectData = useCallback(() => {
    setMultipleFilterFields && setMultipleFilterFields(widgetId, null, []);
  }, [setMultipleFilterFields, widgetId]);

  const selectData = useCallback(
    (values: string[], selectedFieldsIds: number[], isSameTarget: boolean) => {
      if (!isActiveFilter) return;
      if (isSameTarget) {
        deselectData();
      } else {
        if (!setMultipleFilterFields || !values) return;

        const data = values.map((value) => ({
          operation: '=',
          value: [value],
        }));
        setMultipleFilterFields(widgetId, selectedFieldsIds, data);
      }
    },
    [isActiveFilter, deselectData, setMultipleFilterFields, widgetId],
  );

  return {
    selectData,
    deselectData
  };
};
