import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { LocalStorage } from 'src/enums/local-storage-type';
import CommonLoader from '../../common/common-loader/common-loader';
import { GroupContainer } from '../group/group-container';
import { getAllGroups } from '../../../services';
import { Roles } from '../../../enums/roles';

import {
  Filters,
  Group as GroupType,
  Project,
  State,
} from '../../../slices/types';
import {
  setGroupsAction,
  setInitGroupsAction,
} from '../../../slices/group-slice/group-slice';
import { useMainPage } from '../../../pages/main-page/useMainPage';
import GroupFavorite from '../group-favorite/group-favorite';
import NoGroupsPlaceholder from './no-groups-placeholder';


interface GroupListProps {
  groupView: string;
  newGroupId: number | null;
  setShowNewProjectModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const GroupList = ({
  groupView,
  newGroupId,
  setShowNewProjectModal,
}: GroupListProps) => {
  const {
    handleChangeGroupMenuItemClick: handleClickChange,
    handleDeleteGroupMenuItemClick: handleClickDelete,
    handleShowNewProjectModal: handleClickCreate,
    groups,
  } = useMainPage(setShowNewProjectModal);

  const dispatch = useDispatch();
  const [areGroupsLoading, setAreGroupsLoading] = useState(false);
  const [isGroupsLoadingFirst, setGroupsLoadingFirst] = useState(true);
  const filters = useSelector(
    (state: State) => state.mainPage.filters,
  );
  const { search, groupIds } = filters;

  const isFilterActivate = Boolean(search || groupIds?.length);

  const fetchGroupList = useCallback(
    (filters: Filters) => {
      setAreGroupsLoading(true);
      getAllGroups(filters)
        .then((response) => {
          if (isGroupsLoadingFirst) {
            dispatch(setInitGroupsAction(response?.data));
            setGroupsLoadingFirst(false);
          }
          dispatch(setGroupsAction(response?.data));
        })
        .then(() => setAreGroupsLoading(false));
    },
    [dispatch, isGroupsLoadingFirst],
  );

  const filterRef = useRef<Filters | null>(null);

  useEffect(() => {
    // TODO костыль, почему-то fetchGroupList меняется в каждом рендере и эфект срабатывает еще раз
    // проверяем через ref что filters точно изменились
    if (isEqual(filterRef.current, filters)) return;
    filterRef.current = filters;
    fetchGroupList(filters);
  }, [fetchGroupList, filters]);

  const getFavouriteProjects = (groups: GroupType[]) => {
    const favouriteProjects: Project[] = [];
    groups.length &&
      groups.forEach(
        (group: GroupType) =>
          group.projects &&
          group.projects.forEach((project: Project) => {
            if (project.favourite) {
              favouriteProjects.push(project);
            }
          }),
      );
    return favouriteProjects;
  };

  const filterFavouriteByProjectName = (projects: Project[]) => {
    if (search) {
      return projects.filter(
        (project) =>
          project.name.toLowerCase().indexOf(search.toLowerCase()) !== -1,
      );
    }
    return projects;
  };

  const filterFavouriteByGroupName = (projects: Project[]) => {
    return projects;
  };

  const favouriteProjects = useMemo(
    () => getFavouriteProjects(groups),
    [groups],
  );

  const filteredFavouriteProjects = filterFavouriteByProjectName(
    filterFavouriteByGroupName(favouriteProjects),
  );

  const isShowFavorite = localStorage.getItem(LocalStorage.ACTIVE_PROJECTS_GROUP_ID) === '0';

  return (
    <>
      {areGroupsLoading ? (
        <CommonLoader />
      ) : groups.length > 0 ? (
        <List>
          {favouriteProjects.length > 0 && (
            <GroupFavorite
              title="Избранное"
              projects={filteredFavouriteProjects}
              groupView={groupView}
              id={0}
              isShow={filteredFavouriteProjects.length > 0 && !isFilterActivate && isShowFavorite}
            />
          )}
          {groups.map.length > 0 &&
            groups.map((group: GroupType) => (
              <GroupContainer
                key={group.groupId}
                isFilterActivate={isFilterActivate}
                title={group.groupName}
                projects={group.projects}
                id={group.groupId}
                isShow={group.isShow as boolean}
                permission={group.permission as Roles}
                handleClickChange={handleClickChange(group.groupId)}
                handleClickDelete={handleClickDelete(group.groupId)}
                handleClickCreate={handleClickCreate(group.groupId)}
              />
            ))}
        </List>
      ) : (
        <NoGroupsPlaceholder isNoAccess isHaveFilter={isFilterActivate} />
      )}
    </>
  );
};

export default GroupList;
