import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import block from 'bem-cn';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import ConnectionTypeList from '../../connection-type-list/connection-type-list';
import ConnectionForm from '../../connection-form/connection-form';
import Connection from '../../../../types/connection';
import ConnectionFtp from '../connection-ftp/connection-ftp';
import ConnectionSoapOdata from '../connection-soap-odata/connection-soap-odata';
import ConnectionFilesList from '../../connection-files-list/connection-files-list';
import { getConnectionCategory } from '../../../../helpers/loader-page';
import { RightPanelMode } from '../connections';
import CustomDialog from '../../../../uikit/Dialog/custom-dialog';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../common/icon-svg/icon-svg';

type Data = Connection.Meta[];

const b = block('connections');

const closeButtonStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
};

const useStyles = makeStyles({
  closeButtonStyles,
  buttonIcons: {
    width: 25,
    height: 25,
    marginLeft: 3,
  },
  icons: {
    padding: '3px 0 0 1px',
  },
});

interface RightPanelProps {
  rightPanelMode: RightPanelMode | null;
  data: Data;
  loading: boolean;
  onClose: () => void;
  connection: Connection.View.ItemFull | null;
  groupId: number;
}

export const RightPanel = ({
  rightPanelMode,
  data,
  loading,
  onClose,
  connection,
  groupId,
}: RightPanelProps) => {
  const [error, setError] = useState<any>(null);

  const classes = useStyles();

  const getRightPanelComponent = (mode: RightPanelMode | null) => {
    switch (mode) {
      case RightPanelMode.CREATE_CONNECTION:
        return (
          <ConnectionTypeList
            className="connections__connection-type-list"
            onClosePanel={onClose}
            isLoading={loading}
            data={data}
          />
        );
      case RightPanelMode.EDIT_CONNECTION:
        return (
          <ConnectionForm
            className={b('connection-form')}
            connectionTypeId={connection?.metaId as number}
            connection={connection as Connection.View.ItemFull}
            onCancel={onClose}
            onClosePanel={onClose}
          />
        );
      case RightPanelMode.LOCAL:
      case RightPanelMode.FTP:
      case RightPanelMode.HDFS:
      case RightPanelMode.GOOGLE_SHEETS:
        return (
          <ConnectionFtp
            className={b('files-list')}
            connectionId={connection?.id as number}
            onCancel={onClose}
            setError={setError}
            isGoogleSheets={mode === RightPanelMode.GOOGLE_SHEETS}
            isLocal={mode === RightPanelMode.LOCAL}
            groupId={groupId}
          />
        );
      case RightPanelMode.SOAP:
        return (
          <ConnectionSoapOdata
            connectionId={connection?.id as number}
            connectionType={rightPanelMode}
            connectionTypeId={connection?.metaId as number}
            onCancel={onClose}
          />
        );
      case RightPanelMode.ODATA:
        return (
          <ConnectionSoapOdata
            connectionId={connection?.id as number}
            connectionType={rightPanelMode}
            connectionTypeId={connection?.metaId as number}
            onCancel={onClose}
          />
        );
      default:
        return (
          <ConnectionFilesList
            className={b('files-list')}
            connectionId={connection?.id as number}
            onCancel={onClose}
            setError={setError}
            connectionCategory={getConnectionCategory(
              connection?.metaId as number,
              data,
            )}
          />
        );
    }
  };

  return (
    <>
      <div className={b('right-panel')}>
        <IconButton
          className={classes.closeButtonStyles}
          onClick={onClose}
          size="small"
          aria-label="close-panel"
        >
          <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
        </IconButton>
        {getRightPanelComponent(rightPanelMode)}
      </div>

      <CustomDialog
        isOpen={Boolean(error)}
        onClose={() => {
          setError(null);
        }}
        title="Ошибка"
        maxWidth="xs"
      >
        <Alert severity="error">
          <div>{error?.response?.data?.message || error?.message}</div>
        </Alert>
      </CustomDialog>
    </>
  );
};
