import React, { CSSProperties, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { BarGroup } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { ValueDirection } from '../../../../../../hooks/get-properties/useGetWidgetProperty';
import { calculateTextDimensions } from '../../../../../../utils/functions';
import { setPropForNumberValue } from '../../../formatting-helpers';
import { PanelType } from '../../../../../../enums/widget-type';
import { textDirections } from '../../../../../common/scalable-svg-text';
import { FilterField } from '../../../../../dashboard-page/hooks';
import { tooltipStyles } from '../../../overlay-graph/helper';
import { useBarProps } from '../../hooks/useBarProps';
import { WidgetProperties } from '../../../../../../slices/types';
import { WidgetTooltipData } from '../../../../../../types/widget-tooltip';
import { bottomAxisSettings, lineHeight, valueOffsetOfRect, valueTextSettings } from '../../settings';
import { getFirstActivePanelItemIndex } from '../../../../../../helpers/common-helpers';

interface CustomBarGroupProps {
  barGroup: BarGroup<string>;
  index: number;
  style: CSSProperties;
  setBarWidth: React.Dispatch<React.SetStateAction<number>>;
  filterField: FilterField;
  data: any;
  valueFormat: any;
  yScale: any;
  getComputedBarHeight: any;
  isActiveFilter: boolean;
  bottomAxisHeight: number;
  widgetProperties: WidgetProperties[];
  enableEvents: boolean;
  handleWidgetMouseLeave: (event?: React.MouseEvent) => void;
  handleWidgetMouseMove: (event: any, data: WidgetTooltipData) => void;
  selectBar: (groupIndex: number) => void;
  widgetProps: any;
  columnWidth: number;
  getBarYCoord: any;
  isHasNegative: boolean;
  groupBarSize: number;
  spaceBetweenBars: number;
  stepWight: number;
  axisXLabelWidth: number;
  offsetBetweenGroups: number;
  offsetBarHeightValue: number;
}

export const CustomBarGroup = ({
  barGroup,
  style,
  setBarWidth,
  filterField,
  data,
  valueFormat,
  yScale,
  getComputedBarHeight,
  isActiveFilter,
  bottomAxisHeight,
  widgetProperties,
  enableEvents,
  handleWidgetMouseLeave,
  handleWidgetMouseMove,
  selectBar,
  widgetProps,
  columnWidth,
  getBarYCoord,
  isHasNegative,
  groupBarSize,
  spaceBetweenBars,
  stepWight,
  axisXLabelWidth,
  offsetBetweenGroups,
  offsetBarHeightValue
}: CustomBarGroupProps) => {
  setBarWidth(columnWidth);

  const {
    metaData,
    axisXNames,
    legendsLabels,
    textDirection,
    isNeedToDisplayTooltip,
    isNeedToDisplayOutsideBarValues,
    isNeedToDisplayInsideBarValues,
    scaleByNumber,
    formatByNumber,
    valueTextSize,
    valueColor,
    valueDirection,
  } = useBarProps({
    widgetProperties,
    widgetProps,
  });

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent, bar: any) =>
      handleWidgetMouseMove(event, {
        attrX: axisXNames[0],
        valueX: valueFormat(
          data[barGroup.index].x,
          axisXValueIndex,
          widgetProperties,
          PanelType.axisX,
        ),
        attrY: legendsLabels[bar.index],
        valueY: valueFormat(
          bar.value,
          0,
          setPropForNumberValue(widgetProperties),
          PanelType.axisY,
          false,
          false,
          scaleByNumber ? formatByNumber : null,
          true,
        ),
      }),
    [
      axisXNames,
      barGroup.index,
      data,
      formatByNumber,
      handleWidgetMouseMove,
      legendsLabels,
      scaleByNumber,
      valueFormat,
      widgetProperties,
    ],
  );

  const handleClick = useCallback((event: React.MouseEvent) => {
    if (!enableEvents) return;
    event.stopPropagation();
    selectBar(barGroup.index);
    handleWidgetMouseLeave({ target: {  } } as React.MouseEvent);
  }, [
    barGroup.index,
    enableEvents,
    handleWidgetMouseLeave,
    selectBar,
  ]);

  useEffect(() => {
    return () => {
      handleWidgetMouseLeave();
    };
  });

  return (
    <Group
      key={`bar-group-${barGroup.index}-${barGroup.x0}`}
      left={barGroup.x0}
      style={{
        ...style,
      }}
      className="histogram__bar-group"
    >
      <div
        className="histogram__bottom-value-container"
        style={{
          position: 'absolute',
          height: bottomAxisHeight,
          width: textDirection === textDirections.horizontal ? axisXLabelWidth : groupBarSize,
          left: textDirection === textDirections.horizontal ? -(offsetBetweenGroups / 2) : 0,
          overflow: textDirection !== textDirections.horizontal ? 'visible' : 'hidden',
          direction: textDirection !== textDirections.horizontal ? 'rtl' : 'ltr',
        }}
      >
        <div
          className={clsx('histogram__bottom-value', {
            'histogram__bottom-value_vertical':
              textDirection === textDirections.vertical,
            'histogram__bottom-value_diagonal':
              textDirection === textDirections.diagonal,
          })}
          style={{
            display: 'flex',
            marginTop: textDirection === textDirections.vertical ? '-20px' : 0,
            width:
              textDirection !== textDirections.horizontal
                ? Math.sqrt(
                  Math.pow(bottomAxisHeight, 2) +
                  columnWidth,
                ) - 30
                : '100%',
            height:
            textDirection !== textDirections.horizontal
              ? textDirection === textDirections.diagonal ? bottomAxisSettings.lineHeight : ''
              : bottomAxisHeight,
            transform:
              textDirection === textDirections.diagonal
                ? 'rotate(-45deg) translate(-20px, 10px)'
                : textDirection === textDirections.vertical
                  ? 'rotate(-90deg)'
                  : 'none',
          }}
        >
          <span
            className={clsx('histogram__bottom-value', {
              'histogram__bottom-value_horizontal':
                textDirection === textDirections.horizontal,
            })}
            style={{
              direction: 'ltr',
              height: textDirection === textDirections.diagonal ? bottomAxisSettings.lineHeight : '',
              alignSelf:
                textDirection !== textDirections.horizontal
                  ? 'center'
                  : 'flex-start',
              position: textDirection === textDirections.horizontal ? undefined : 'absolute',
              right: textDirection === textDirections.horizontal ? undefined : 0,
            }}
          >
            {valueFormat(
              data[barGroup.index].x,
              axisXValueIndex,
              widgetProperties,
            )}
          </span>
        </div>
      </div>

      {barGroup.bars.map((bar: any, i: number) => {
        const barHeight = getComputedBarHeight(bar, yScale);

        const isValueNegative = bar.value < 0;
        const isValuePositive = !isValueNegative;
        const isValueVertical = valueDirection === ValueDirection.vertical;

        const valueText = valueFormat(
          bar.value,
          0,
          setPropForNumberValue(widgetProperties),
          PanelType.axisY,
          false,
          false,
          scaleByNumber ? formatByNumber : null,
          true,
        );
        const valueDim = calculateTextDimensions(valueText, {
          fontSize: valueTextSize,
          lineHeight,
          fontWeight: valueTextSettings.fontWeight,
          fontFamily: valueTextSettings.fontFamily,
        });

        const valueTextWidth = isValueVertical
          ? isNeedToDisplayInsideBarValues
            ? Math.min(valueDim.width + 1, barHeight - valueOffsetOfRect * 2)
            : valueDim.width + 1
          : columnWidth;
        const valueTextHeight = valueDim.height;

        const valueLeftShift = -(valueTextWidth - columnWidth) / 2;

        const outsideBarTopOffsetInVerticalTypeText = isValuePositive
          ? -(valueTextHeight / 2 + valueTextWidth / 2 + valueOffsetOfRect)
          : barHeight + valueTextWidth / 2 + valueOffsetOfRect;
        const outsideBarTopOffsetInHorizontalTypeText = isValuePositive
          ? -valueTextHeight
          : barHeight + valueOffsetOfRect;

        const outsideValueStylePosition: CSSProperties = isValueVertical
          ? {
            transform: `translate(${valueLeftShift}px, ${outsideBarTopOffsetInVerticalTypeText}px) rotate(-90deg)`,
          }
          : {
            transform: `translate(${valueLeftShift}px, ${outsideBarTopOffsetInHorizontalTypeText}px)`,
          };

        const insideBarTopOffsetInVerticalTypeText = isValuePositive
          ? barHeight - valueTextHeight - valueTextWidth / 2 + valueOffsetOfRect
          : valueTextWidth / 2 - valueOffsetOfRect;

        const insideBarTopOffsetInHorizontalTypeText = isValuePositive
          ? barHeight - valueTextHeight
          : 0;

        const insideValueStylePosition: CSSProperties = isValueVertical
          ? {
            transform: `translate(${valueLeftShift}px, ${insideBarTopOffsetInVerticalTypeText}px) rotate(-90deg)`,
          }
          : {
            transform: `translate(${valueLeftShift}px, ${insideBarTopOffsetInHorizontalTypeText}px)`,
          };

        const valueStyle: CSSProperties = {
          width: valueTextWidth,
          position: 'absolute',
          fontSize: valueTextSize,
          lineHeight,
          top: 0,
          left: 0,
          textAlign: isValueVertical ? 'start' : 'center',
        };

        return (
          <div
            className="histogram__bar"
            key={bar.key}
            style={{
              position: isHasNegative ? 'static' : 'relative',
              width: columnWidth,
              marginRight: i < barGroup.bars.length - 1 ? spaceBetweenBars : 0,
            }}
          >
            <div
              className={clsx('histogram__rect', {
                'active-drilldown-element': isActiveFilter,
              })}
              style={{
                width: columnWidth,
                height: barHeight,
                top: isHasNegative
                  ? getBarYCoord(bar, yScale, offsetBarHeightValue)
                  : 'unset',
                bottom: isHasNegative
                  ? 'unset'
                  : bottomAxisHeight,
                backgroundColor:
                  (metaData &&
                    metaData[barGroup.index] &&
                    metaData[barGroup.index][bar.index]?.backgroundColor) ||
                  bar.color,
                opacity:
                  filterField?.value === null ||
                  filterField?.value === undefined ||
                  filterField?.value?.includes(data[barGroup.index]?.x)
                    ? 0.8
                    : 0.2,
              }}
              key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
              onClick={handleClick}
              onMouseLeave={
                isNeedToDisplayTooltip ? handleWidgetMouseLeave : undefined
              }
              onMouseMove={
                isNeedToDisplayTooltip
                  ? (event) => handleMouseMove(event, bar)
                  : undefined
              }
            >
              {isNeedToDisplayOutsideBarValues && (
                <div
                  className="histogram__outside-value"
                  style={{
                    ...valueStyle,
                    ...outsideValueStylePosition,

                    color: valueColor ||
                      (metaData &&
                        metaData[barGroup.index] &&
                        metaData[barGroup.index][bar.index]?.color) }}
                >
                  {valueText}
                </div>
              )}

              {isNeedToDisplayInsideBarValues && (
                <div
                  className={clsx('histogram__inside-value', {
                    'histogram__inside-value_hidden': getComputedBarHeight(bar, yScale) < valueTextHeight,
                  })}
                  style={{
                    ...valueStyle,
                    ...insideValueStylePosition,
                    color: valueColor ||
                      (metaData &&
                        metaData[barGroup.index] &&
                        metaData[barGroup.index][bar.index]?.color)
                  }}
                >
                  {valueFormat(
                    bar.value,
                    0,
                    setPropForNumberValue(widgetProperties),
                    PanelType.axisY,
                    false,
                    false,
                    scaleByNumber ? formatByNumber : null,
                    true
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </Group>
  );
};
