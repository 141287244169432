import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getPictureType } from 'src/utils/functions';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { SelectOptions, SelectOptionsMeta } from '../../../type';
import Validation from '../../../validation/type';
import { useStyles } from './styles';
import { SourceSelectField, TargetSelectField } from './input-fields';
import { Operation } from '../../../../../../enums/operation';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';
import { State } from '../../../../../../slices/types';
import { mapConnection } from '../../../../../../mocks/map-connection';
import { extendBeforeSaveValidation } from '../../../validation/logic';

interface FieldListSectionProps {
  fieldsMapping: any[];
  handleSelectChange: (e: any, i: any, type: string) => void;
  handleKeyClick: (i: any) => void;
  needDrawKeyPicture: boolean;
  selectOptions: SelectOptions;
  selectOptionsMeta: SelectOptionsMeta;
  editableFields: any;
  isNewTarget: boolean;
  validation: Validation.Data;
  setEditableFields: any;
  operationType: string;
  handleCancelInputClick: () => void;
  handleDeleteConnection: (i: any) => () => void;
  handleSaveInputClick: (i: any) => () => void;
  handleInputChange: (e: any, i: any) => void;
  isEditableMap: boolean;
}

const FieldListSection: React.FC<FieldListSectionProps> = ({
  fieldsMapping,
  handleSelectChange,
  handleKeyClick,
  needDrawKeyPicture,
  selectOptions,
  selectOptionsMeta,
  editableFields,
  setEditableFields,
  isNewTarget,
  validation,
  operationType,
  handleCancelInputClick,
  handleDeleteConnection,
  handleSaveInputClick,
  handleInputChange,
  isEditableMap,
}) => {
  const classes = useStyles();
  const isValid = !validation.errorText;

  const loaderId: number = useSelector(
    (state: State) => state.mainPage.currentProject?.loaderId ?? 0,
  );

  const mapConnection = useSelector(
    (state: any) => state.mapConnection[loaderId] ?? 0,
  );

  const getTypeError = (
    sourceId: string,
    targetId: string,
    sourceFieldName: string,
    targetFieldName: string,
  ) => {
    let error: string | null = '';
    if (!mapConnection) return error;

    const source = mapConnection.sourceObjects.find((item:any) => item.id === sourceId);
    const target = mapConnection.sourceObjects.find((item:any) => item.id === targetId);

    if (!source || !target) return error;

    const sourceField = source.processedFields.find((item:any) => {
      const name = item.alias || item.displayedName;
      return name === sourceFieldName;
    });
    const targetField = target.processedFields.find((item:any) => {
      const name = item.alias || item.displayedName;
      return name === targetFieldName;
    });

    if (!sourceField || !targetField) return error;

    if (getPictureType(sourceField?.displayedType) !== getPictureType(targetField?.displayedType)) {
      error = 'Связать можно только одинаковые типы полей';
    }

    return error;
  };

  return (
    <div
      className={classes[isValid ? 'field-list-valid' : 'field-list-invalid']}
    >
      {fieldsMapping.map((field, i) => {
        const {
          fieldName: sourceFieldName,
          sourceObjectId: sourceId,
        } = field.sourceFieldName;
        const {
          fieldName: targetFieldName,
          sourceObjectId: targetId,
        } = field.targetField;

        const { keyForMerge } = field;

        const typeError = getTypeError(sourceId, targetId, sourceFieldName, targetFieldName);

        let fieldsValidation = { ...validation };
        if (typeError) {
          fieldsValidation = extendBeforeSaveValidation(fieldsValidation, i);
        }

        return (
          <div className={classes['option-row']} key={field}>
            <SourceSelectField
              i={i}
              sourceFieldName={sourceFieldName}
              selectOptions={selectOptions}
              selectOptionsMeta={selectOptionsMeta}
              sourceId={sourceId}
              needDrawKeyPicture={needDrawKeyPicture}
              keyForMerge={keyForMerge}
              validation={validation}
              handleSelectChange={handleSelectChange}
              handleKeyClick={handleKeyClick}
            />
            <div className={classes['arrow-logo']}>
              {operationType === Operation.Join ? (
                <IconSvg
                  svg={IconDictionary.Key}
                  fill="var(--dark-grey)"
                />
              ) : (
                <IconSvg
                  svg={IconDictionary.ArrowToRightWithStartPoint}
                  fill="var(--dark-grey)"
                />
              )}
            </div>
            {!editableFields[i] ? (
              <TargetSelectField
                i={i}
                targetFieldName={targetFieldName}
                handleSelectChange={handleSelectChange}
                selectOptions={selectOptions}
                selectOptionsMeta={selectOptionsMeta}
                targetId={targetId}
                isNewTarget={isNewTarget}
                validation={validation}
                operationType={operationType}
                setEditableFields={setEditableFields}
              />
            ) : (
              <div className="input-border-wrapper">
                <div className="input-border" />
                {/* hack for border in input */}
                <TextField
                  id={`${i}`}
                  value={editableFields[i].name}
                  variant="outlined"
                  onChange={(e) => {
                    handleInputChange(e, i);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className="input-wrapper">
                          <IconButton onClick={handleSaveInputClick(i)}>
                            <IconSvg svg={IconDictionary.RoundedCheck} fill="var(--success-color)" />
                          </IconButton>
                          <IconButton onClick={handleCancelInputClick}>
                            <IconSvg svg={IconDictionary.CancelEdit} fill="var(--dark-grey)" />
                          </IconButton>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}

            {isEditableMap ?
              <IconButton onClick={handleDeleteConnection(i)}>
                <IconSvg svg={IconDictionary.BasketDelete} fill="var(--dark-grey)" />
              </IconButton> : null}
          </div>
        );
      })}
    </div>
  );
};

export default FieldListSection;
