import { WidgetProperties } from '../../slices/types';
import {
  Property,
  PropertyData,
} from '../../components/widget-page/dropdown-layout/helpers/Property';
import {
  getPanelIndex,
  getParsedAxisValues,
} from '../../components/widget-page/dropdown-layout/helpers/helpers';
import { PanelType } from '../../enums/widget-type';
import { getPropertyValue } from '../dashboard-page';

export const RemovableFieldsInFiltersSynchronization = ['axisName', 'coloring', 'restriction'];

export const resetFilterValueAfterAggregationChanging = (
  properties: WidgetProperties[],
  index: number,
) => {
  const filterAxisIndex: number = getPanelIndex({
    properties,
    type: PanelType.axisFilter,
  });

  const newProperties = [...properties];

  const filterValue: PropertyData[] = getPropertyValue(
    PanelType.axisFilter,
    properties,
  );
  const newFilterValue = [...filterValue];
  newFilterValue[index] = { ...newFilterValue[index], filter: [] };

  newProperties[filterAxisIndex] = {
    ...newProperties[filterAxisIndex],
    value: JSON.stringify(newFilterValue),
  };

  return newProperties;
};

export const getHierarchyLevels = (hierarchyLevelNameMap?: {
  [key: number]: string;
}) => {
  if (!hierarchyLevelNameMap) return [];

  return Object.keys(hierarchyLevelNameMap).map(
    (key) => hierarchyLevelNameMap[parseInt(key)],
  );
};

export const getHierarchyLevelNameMap = (hierarchyLevels: string[]) => {
  if (!hierarchyLevels.length) return {};

  const hierarchyLevelNameMap: { [key: number]: string } = {};
  hierarchyLevels.forEach((level, index) => {
    hierarchyLevelNameMap[index + 1] = level;
  });

  return hierarchyLevelNameMap;
};

export const isDisabledHierarchySetting = (
  axisValues: PropertyData[],
  currentIndex: number,
) => {
  if (!axisValues.length) return false;

  let index = 0;
  let isDisabled = false;

  do {
    if (axisValues[index].hierarchyLink && index !== currentIndex) {
      isDisabled = true;
    }
    index++;
  } while (index < axisValues.length && !isDisabled);

  return isDisabled;
};

export const getFiltersPropertyAfterSynchronization = (
  widgetProperties: WidgetProperties[],
  newProperty: Property,
  isNeedToResetFilters: boolean,
) => {
  const properties = [...widgetProperties];

  let filterAxisIndex: number = getPanelIndex({
    properties,
    type: PanelType.axisFilter,
  });
  if (filterAxisIndex === -1) {
    properties.push({
      name: PanelType.axisFilter,
      value: '[]',
    });
    filterAxisIndex = properties.length - 1;
  }

  const filterAxisValue: PropertyData[] = getParsedAxisValues(
    PanelType.axisFilter,
    properties,
  );

  if (newProperty.getHierarchyLink()) {
    const filterIndex = filterAxisValue.findIndex(
      (item) => item.etlFieldId === newProperty.getEtlFieldId(),
    );
    filterIndex > -1 && filterAxisValue.splice(filterIndex, 1);

    return {
      filterAxisIndex,
      filterAxisValue,
    };
  }

  const filterIndex = filterAxisValue.findIndex(
    (item) => item.etlFieldId === newProperty.getEtlFieldId(),
  );
  const currentFilterProperty =
    filterIndex === -1
      ? new Property({ ...newProperty.data, id: null })
      : new Property(filterAxisValue[filterIndex]);

  currentFilterProperty.changeFunction(newProperty.data.function);
  currentFilterProperty.changeAggregation(newProperty.data.aggregation);

  if (Object.hasOwn(newProperty.data, 'isDrillDownFilter')) {
    const newIsDrilldownFilterValue = newProperty.data.aggregation
      ? false
      : newProperty.data.isDrillDownFilter || false;
    currentFilterProperty.setIsDrilldownFilter(newIsDrilldownFilterValue);
  }

  if (isNeedToResetFilters) {
    currentFilterProperty.changeFilter([]);
  }

  RemovableFieldsInFiltersSynchronization.forEach((field) => {
    delete currentFilterProperty.data[field as keyof PropertyData];
  });

  const filterValue = currentFilterProperty.isCalculated()
    ? currentFilterProperty.getCalculatedValue()
    : currentFilterProperty.getValue();

  if (filterIndex === -1) {
    filterAxisValue.push(filterValue);
  } else {
    filterAxisValue[filterIndex] = filterValue;
  }

  return {
    filterAxisIndex,
    filterAxisValue,
  };
};
