import { getScaleCoefficient } from '../../../../helpers/ui-helper';
import { ValueDirection } from '../../../../hooks/get-properties/useGetWidgetProperty';
import { getStickPointValue } from '../helpers';
import { textDirections } from '../../../common/scalable-svg-text';

export const barSettings = {
  innerTextTop: 20,
  outerTextTopOffset: 5,
  outerTextBottomOffsetHorizontal: 20,
  outerTextBottomOffsetVertical: 10,
};

export const getBarHeight = (bar: any, yScale: any) => {
  const minHeight = 5;
  const stickPointValue = getStickPointValue(yScale.ticks());
  const height =
    bar.value < 0
      ? yScale(bar.value) - yScale(stickPointValue)
      : yScale(stickPointValue) - yScale(bar.value);
  return height > 0 ? height : minHeight;
};

export const getOutsideBarYValue = (bar: any, isVertical: boolean) => {
  return bar.value >= 0
    ? bar.y - barSettings.outerTextTopOffset
    : bar.y +
        (isVertical
          ? barSettings.outerTextBottomOffsetVertical
          : barSettings.outerTextBottomOffsetHorizontal);
};

export const getInsideBarYValue = (bar: any, yScale: any) => {
  return bar.value >= 0
    ? yScale(getStickPointValue(yScale.ticks())) - barSettings.innerTextTop
    : yScale(getStickPointValue(yScale.ticks()));
};

export const isNeedToDisplayAxisBottom = (
  height: number,
  minContainerHeightForDisplayAxisBottom: number,
  textDirection: string,
) => {
  return (
    textDirection === textDirections.horizontal ||
    height > minContainerHeightForDisplayAxisBottom
  );
};

export const getScrollBarWidth = () => {
  const inner = document.createElement('p');
  inner.style.width = '100%';
  inner.style.height = '200px';

  const outer = document.createElement('div');
  outer.style.position = 'absolute';
  outer.style.top = '0px';
  outer.style.left = '0px';
  outer.style.visibility = 'hidden';
  outer.style.width = '200px';
  outer.style.height = '150px';
  outer.style.overflow = 'hidden';
  outer.appendChild(inner);

  document.body.appendChild(outer);
  const w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  let w2 = inner.offsetWidth;
  if (w1 == w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return (w1 - w2) / getScaleCoefficient();
};
