import React from 'react';
import { WidgetType } from '../../../../enums/widget-type';
import { Widget, WidgetProperties } from '../../../../slices/types';
import { SetFilterField, SetMultipleFilterFields } from '../../../dashboard-page/hooks';
import { TableButtons } from './table-buttons/table-buttons';

interface SpecialWidgetHeadButtonsProps {
  widget: Widget;
  dbFiltersControls?: {
    setOverriddenProperties: (newProperties: WidgetProperties[]) => void;
    setFilterField?: SetFilterField;
    setMultipleFilterFields?: SetMultipleFilterFields;
    isActiveFilter?: number | false | undefined;
  };
}

export const SpecialWidgetHeadButtons = ({
  widget,
  dbFiltersControls,
}: SpecialWidgetHeadButtonsProps) => {
  switch (widget.type) {
    case WidgetType.TABLE:
      return (<TableButtons widget={widget} dbFiltersControls={dbFiltersControls} />);
    case WidgetType.PIVOT_TABLE:
      return (<TableButtons widget={widget} dbFiltersControls={dbFiltersControls} pivot />);
    default:
      return null;
  }
};
