import React from 'react';
import { Project } from '../../../slices/types';
import './loader-menu.css';
import { useLoaderMenu } from './useLoaderMenu';
import { CustomPopoverMenu } from '../../../uikit/PopoverMenu/popover-menu';

export interface LoaderMenuProps {
  project: Project;
  handleCloseMenu: () => void;
  rolesAccess: any;
}

const LoaderMenu = ({ project, handleCloseMenu, rolesAccess }: LoaderMenuProps) => {
  const { menuList } = useLoaderMenu({ project, handleCloseMenu, rolesAccess });

  return <CustomPopoverMenu settings={menuList} />;
};

export default LoaderMenu;
