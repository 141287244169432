import React from 'react';
import { Group } from '@visx/group';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { scaleOrdinal } from '@visx/scale';
import { useHorizontalScroll } from '../../../../hooks/useHorizontalScroll';
import { BottomAxis } from './components/bottom-axis';
import { LeftAxis } from './components/left-axis';
import {
  centerLinesXOffset,
  circleParams,
  leftAxisSettings,
} from './settings';
import { setPropForNumberValue } from '../formatting-helpers';
import { PanelType } from '../../../../enums/widget-type';
import { LinePathRenderer } from '../common/line-path';
import './styles.css';
import { useGraphProperties } from './hooks/useGraphProperties';
import { useGraph } from './hooks/useGraph';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { getX, getY } from './helper';
import { getTooltipHandler } from '../../../common/widget-tooltip/widget-tooltip';
import { textDirections } from '../../../common/scalable-svg-text';
import { AreaPathRenderer } from './components/area-path';

interface GraphProps {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  domain: any;
}

export default function Graph({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  domain,
}: GraphProps) {
  const widgetProperties = widgetProps.properties;

  const {
    axisXNames,
    isNeedToDisplayTooltip,
    isNeedToDisplayWidgetTooltip,
    isSmoothedLine,
    textDirection,
    customAxisXWidth,
    scaleByNumber,
    formatByNumber,
  } = useGraphProperties({ widgetProperties });

  const {
    viewportRef,
    deselectPoint,
    height,
    width,
    containerRef,
    widgetData,
    axisYLabelWidth,
    yScale,
    xScale,
    currentBottomAxisSettingsHeight,
    isVisible,
    tooltipsPositions,
    valueFormat,
    graphsMapper,
    legendsLabels,
    colorsActiveLegend,
    selectPoint,
    filteredAxisDataValues,
    isGraphWithNegativeValues,
    isScaleByValueState,
    isNeedToDisplayArea,
  } = useGraph({
    widgetProps,
    setFilterField,
    filterField,
    isActiveFilter,
    domain,
  });
  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(viewportRef);

  const ordinalColorScale = scaleOrdinal({
    domain: legendsLabels.map((value: string, index: number) => String(index)),
    range: colorsActiveLegend,
  });

  const leftOffset = textDirection === textDirections.diagonal ? customAxisXWidth : (customAxisXWidth / 2 + circleParams.radius * 2);

  return (
    <>
      <div
        ref={viewportRef}
        onClick={deselectPoint}
        className="widget-viewport graph-widget screenshot-overflow-y"
      >
        <div
          style={{
            height: 'min-height',
            width: `${axisYLabelWidth}px`,
          }}
        >
          <svg
            className="viewport-svg"
            width={viewportRef.current.clientWidth}
            height={height}
          >
            <LeftAxis
              width={width}
              yScale={yScale}
              widgetProperties={widgetProperties}
            />
          </svg>
        </div>
        <div ref={containerRef} className="graph-widget__container screenshot-overflow-x">
          <svg width={width} height={height}>
            <BottomAxis
              filteredAxisDataValues={filteredAxisDataValues}
              widgetData={widgetData}
              height={height - currentBottomAxisSettingsHeight}
              width={width}
              leftOffset={leftOffset}
              xScale={xScale}
              widgetProperties={widgetProperties}
            />
            <Group
              left={leftOffset}
              top={leftAxisSettings.topOffset}
            >
              {isVisible && (
                <Group left={centerLinesXOffset}>
                  {isNeedToDisplayArea && graphsMapper.map((value: any, index: number) => {
                    const getScaledX = (d: any) => xScale(getX(d)) ?? 0;
                    const getScaledY = (d: any) => yScale(getY(d, index)) ?? 0;

                    const bottomOffset = 10;
                    const areaProps = {
                      height,
                      bottom: currentBottomAxisSettingsHeight + bottomOffset,
                      index,
                    };

                    return (
                      <AreaPathRenderer
                        key={`${ordinalColorScale(index)}-${value}`}
                        areaProps={areaProps}
                        color={ordinalColorScale(index)}
                        getX={getScaledX}
                        getY={getScaledY}
                        data={widgetProps.data}
                        isSmoothed={isSmoothedLine}
                        widgetProperties={widgetProperties}
                        widgetId={widgetProps.id}
                        isScaleByValueState={isScaleByValueState}
                        y1={height - currentBottomAxisSettingsHeight}
                        isGraphWithNegativeValues={isGraphWithNegativeValues}
                      />
                    );
                  })}

                  {graphsMapper.map((value: any, index: number) => {
                    const getScaledX = (d: any) => xScale(getX(d)) ?? 0;
                    const getScaledY = (d: any) => yScale(getY(d, index)) ?? 0;

                    const widgetTooltipGraphProps = {
                      showWidgetTooltip: handleWidgetMouseMove,
                      hideWidgetTooltip: handleWidgetMouseLeave,
                      attrX: axisXNames[0],
                      attrY: legendsLabels[index],
                      indexLine: index,
                      isNeedToDisplayTooltip: isNeedToDisplayWidgetTooltip,
                    };

                    return (
                      <LinePathRenderer
                        key={index}
                        circleParams={circleParams}
                        color={ordinalColorScale(index)}
                        getX={getScaledX}
                        getY={getScaledY}
                        data={widgetData}
                        isSmoothed={isSmoothedLine}
                        widgetTooltipGraphProps={widgetTooltipGraphProps}
                        onSelectPoint={(pointIndex: number) =>
                          selectPoint(index, pointIndex)}
                        widgetProperties={widgetProperties}
                        boundFilterField={filterField}
                        isActiveFilter={isActiveFilter}
                        isNeedToDisplayValuesOnGraph={isNeedToDisplayTooltip}
                      />
                    );
                  })}
                </Group>
              )}
            </Group>
          </svg>
        </div>
      </div>
    </>
  );
}
