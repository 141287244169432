import { useMemo } from 'react';
import { FilterGroupProps } from './index';
import {
  getFilterSettingObject,
  getFilterSettingValue,
} from '../../../helpers/dashboard-page/filters-helper';
import { FilterProperty } from '../../../enums/dashboard-properties';

export const useDashboardFilterProperties = (filterGroup: FilterGroupProps) => {
  const isVisible = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.isVisible),
    [filterGroup],
  );

  const lastAvailable = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.lastAvailable),
    [filterGroup],
  );

  const isDisplayedOnTop = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.isDisplayedOnTop),
    [filterGroup],
  );

  const displayAvailablePeriod = useMemo(
    () =>
      getFilterSettingValue(filterGroup, FilterProperty.displayAvailablePeriod),
    [filterGroup],
  );

  const displayAsSingleDate = useMemo(
    () =>
      getFilterSettingValue(filterGroup, FilterProperty.displayAsSingleDate),
    [filterGroup],
  );

  const displayAsPeriod = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.displayAsPeriod),
    [filterGroup],
  );

  const currentDay = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.currentDay),
    [filterGroup],
  );

  const currentMonth = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.currentMonth),
    [filterGroup],
  );

  const currentYear = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.currentYear),
    [filterGroup],
  );

  const currentWeek = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.currentWeek),
    [filterGroup],
  );

  const overriddenFunction = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.overriddenFunction),
    [filterGroup],
  );

  const dateFunction = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.function),
    [filterGroup],
  );

  const selectDateType = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.selectDateType),
    [filterGroup],
  );

  const selectDateTypeObject = useMemo(
    () => getFilterSettingObject(filterGroup, FilterProperty.selectDateType),
    [filterGroup],
  );

  const displayAsSingleDateObject = useMemo(
    () =>
      getFilterSettingObject(filterGroup, FilterProperty.displayAsSingleDate),
    [filterGroup],
  );

  const displayAsPeriodObject = useMemo(
    () => getFilterSettingObject(filterGroup, FilterProperty.displayAsPeriod),
    [filterGroup],
  );

  const isMandatoryFilter = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.isMandatoryFilter),
    [filterGroup],
  );

  const textFilterMode = useMemo(
    () => getFilterSettingValue(filterGroup, FilterProperty.textFilterMode),
    [filterGroup],
  );


  return {
    isVisible,
    lastAvailable,
    isDisplayedOnTop,
    displayAvailablePeriod,
    displayAsSingleDate,
    displayAsPeriod,
    currentDay,
    currentMonth,
    currentYear,
    currentWeek,
    overriddenFunction,
    dateFunction,
    selectDateType,
    selectDateTypeObject,
    displayAsSingleDateObject,
    displayAsPeriodObject,
    textFilterMode,
    isMandatoryFilter,
  };
};
