import React, { FC, useMemo } from 'react';
import { DdFiltersPanel } from '../../dashboard-page/dashboard-widgets/components/dd-filters-panel/dd-filters-panel';
import { FilterForPanel, SetFilterField } from '../../dashboard-page/hooks';
import { getInputPropertyValue } from '../../widget-page/charts/helpers';
import { Widget, WidgetProperties } from '../../../slices/types';
import styles from './styles.module.css';
import { getFormulasPropertyFromWidgetData } from '../../../helpers/widget-page/widget-properties-helper';
import { WidgetPropertyType, WidgetType } from '../../../enums/widget-type';
import WidgetTypes from '../../../types/widgets';
import { Bookmarks } from './bookmarks';
import { isVisibleBookmarks } from '../../../helpers/common-helpers';

export interface ICardTopPanel {
  widgetProperties: WidgetProperties[];
  widgetDataProperties?: WidgetTypes.DataProperty[];
  widgetType: WidgetType;
  widgetId: number;
  setOverriddenProperties: (newProperties: WidgetProperties[]) => void;
  filtersForPanel?: FilterForPanel[];
  setFilterField?: SetFilterField;
  isActiveFilter?: number | false | undefined;
  showFiltersForPanel?: boolean;
}

export const CardTopPanel: FC<ICardTopPanel> = ({
  widgetProperties = [],
  widgetDataProperties,
  widgetType,
  widgetId,
  setOverriddenProperties,
  filtersForPanel,
  setFilterField,
  isActiveFilter,
  showFiltersForPanel
}) => {
  const addDescription = widgetProperties.length
    ? getFormulasPropertyFromWidgetData(
      { properties: widgetProperties } as Widget,
      WidgetPropertyType.addDescription,
      widgetDataProperties,
    )
    : '';

  const commentColor: string | undefined =
    getInputPropertyValue('commentColor', widgetProperties) || undefined;

  const memoizedDescription: string[] = useMemo(() => {
    return addDescription.split('\\n');
  }, [addDescription]);

  return (
    <>
      {addDescription && (
        <div className={styles.container}>
          <div className={styles.description} style={{ color: commentColor }}>
            {memoizedDescription.map((el, i) => (
              <div key={i}>{el}</div>
            ))}
          </div>
        </div>
      )}
      {filtersForPanel && filtersForPanel.length > 0 && showFiltersForPanel && (
        <DdFiltersPanel filters={filtersForPanel} />
      )}
      {isVisibleBookmarks(widgetType) && (
        <Bookmarks
          widgetProperties={widgetProperties}
          widgetType={widgetType}
          widgetId={widgetId}
          setOverriddenProperties={setOverriddenProperties}
          setFilterField={setFilterField}
          isActiveFilter={isActiveFilter}
        />
      )}
    </>
  );
};

export default CardTopPanel;
