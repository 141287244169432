import { FormControlLabel } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import { settingTabs } from '../../enums';
import { CustomRadio } from '../../../../../uikit/Radio/Radio';
import { CustomFormControl } from '../../../../../uikit/FormControl';

interface RadioSwitcherProps {
  currentTab: settingTabs;
  handleOpenSwitchDialog: (newTab: settingTabs) => void;
}

export const RadioSwitcher = ({ currentTab, handleOpenSwitchDialog }: RadioSwitcherProps) => {
  const tabs = [
    {
      type: settingTabs.auto,
      title: 'Связь по данным',
    },
    {
      type: settingTabs.manual,
      title: 'Связь по фильтрам',
    },
  ];

  return (
    <div className='bound-type-switcher__radio'>
      {tabs.map((tab) => (
        <CustomFormControl
          key={tab.title}
          value={tab.type}
          control={<CustomRadio />}
          label={<span className='bound-type-switcher__radio--label'>{tab.title}</span>}
          onChange={() => handleOpenSwitchDialog(tab.type)}
          checked={currentTab === tab.type}
        />
      ))}
    </div>
  );
};
