import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { CssVariables } from '../../../../enums/css-variables';
import { varcss } from '../../../../helpers/ui-helper';
import { CustomButton } from '../../../../uikit/Button';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import { apiSaveDashboardUserFilter } from '../../../../services/dashboardController';
import { State } from '../../../../slices/types';
import { FilterGroupProps } from '../../../dashboard-page/hooks';
import { DashboardPropertyType } from '../../../../enums/dashboard-properties';
import { getPropertyValue } from '../../../../helpers/dashboard-page';
import { useNotificator } from '../../../common/snackbar/hooks';
import { setInitPreviewFiltersAction } from '../../../../slices/dashboard/dashboard';

export const PreviewSaveButton = () => {
  const dispatch = useDispatch();
  const { showNotification } = useNotificator();

  const dashboardId = useSelector((state: State) => state.dashboard.id);
  const dashboardProperties = useSelector(
    (state: State) => state.dashboard.properties,
  );
  const initPreviewFilters = useSelector(
    (state: State) => state.dashboard.initPreviewFilters,
  );

  const dashboardFilters: FilterGroupProps[] = getPropertyValue(
    DashboardPropertyType.filters,
    dashboardProperties,
  );

  const [isNeedToDisplay, setIsNeedToDisplay] = useState<boolean>(false);

  useEffect(() => {
    setIsNeedToDisplay(!isEqual(initPreviewFilters, dashboardFilters));
  }, [initPreviewFilters, dashboardFilters]);

  const handleClick = () => {
    const filters = dashboardFilters.map((item) => ({
      dashboardFilterId: item.id,
      value: item.value,
    }));
    dashboardId &&
      apiSaveDashboardUserFilter(dashboardId, filters).then(() => {
        dispatch(setInitPreviewFiltersAction(dashboardFilters));
        showNotification({ message: 'Сохранено', variant: 'success' });
      });
  };

  return isNeedToDisplay ? (
    <CustomButton
      variant="contained"
      onClick={handleClick}
      type="submit"
      icon={<IconSvg svg={IconDictionary.Save} fill="var(--white)" />}
      backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
    >
      Сохранить
    </CustomButton>
  ) : null;
};
