import { useSelector } from 'react-redux';
import { compact } from 'lodash';
import { useState } from 'react';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { State } from '../../../../slices/types';
import { isDashboardPage } from '../helpers';

export function useActiveLegend<T>(
  widgetProperties: any,
  widgetId: number,
  currentColors: any,
) {
  const [colorsActiveLegend, setColorsActiveLegend] =
    useState<string[]>(currentColors);

  const activeLegend: Array<any> = getParsedAxisValues(
    WidgetPropertyType.activeLegend,
    widgetProperties,
  );

  const widgetsWithActiveLegend = useSelector(
    (state: State) => state.dashboard.widgetsWithActiveLegend,
  );

  const axisYValues: Array<any> = getParsedAxisValues(
    PanelType.axisY,
    widgetProperties,
  );

  const changeColorsActiveLegend = () => {
    if (activeLegend.length || widgetsWithActiveLegend?.length) {
      const activeLegendIndex = widgetsWithActiveLegend?.findIndex(
        (item: any) => item.id === widgetId,
      );
      const activeLegendForWidget =
        activeLegendIndex + 1 && isDashboardPage()
          ? JSON.parse(widgetsWithActiveLegend[activeLegendIndex].activeLegend)
          : activeLegend;

      if (!activeLegendForWidget.length) {
        setColorsActiveLegend(currentColors);
        return;
      }

      const disabledLegendIndexes: number[] = [];
      axisYValues.forEach((value, index) => {
        const isDisabledLegend =
          activeLegendForWidget.findIndex(
            (item: any) => item.id === value.id,
          ) === -1;
        if (isDisabledLegend) {
          disabledLegendIndexes.push(index);
        }
      });

      const newColors = currentColors.map((color: string, index: number) => {
        if (!disabledLegendIndexes.includes(index)) {
          return color;
        }
      });

      setColorsActiveLegend(compact(newColors));
    } else {
      setColorsActiveLegend(currentColors);
    }
  };

  return {
    changeColorsActiveLegend,
    colorsActiveLegend,
  };
}
