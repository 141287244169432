import { useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CssVariables } from '../../../../enums/css-variables';
import { getTextSkeletonStyles } from '../../../../helpers/styles';
import { varcss } from '../../../../helpers/ui-helper';
import {
  dashboardWidgetDataSelector,
  dashboardWidgetDirtySelector,
  dashboardWidgetLoadingSelector,
} from '../../../../slices/dashboard/dashboard';
import { useDashboardSettings } from '../../settings/hooks/useDashboardSettings';
import {
  getBooleanPropertyValue,
  getHeaderSettings,
  getSelectPropertyValue,
} from '../../../widget-page/charts/helpers';
import { getFormulasPropertyFromWidgetData } from '../../../../helpers/widget-page/widget-properties-helper';
import { WidgetPropertyType } from '../../../../enums/widget-type';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import WidgetTypes from '../../../../types/widgets';
import {
  changeNodeForWidgetScreenshot,
  takeScreenShot,
} from '../../../../helpers/import-export';
import { HeaderPositions } from '../../../../enums/header-position';

interface useWidgetCardHeaderProps {
  widgetId: number;
  widgetDataObject: WidgetTypes.Data;
  containerRef: React.MutableRefObject<HTMLDivElement>;
}

export const useWidgetCardHeader = ({
  widgetId,
  widgetDataObject,
  containerRef,
}: useWidgetCardHeaderProps) => {
  const isLoading = useSelector(
    dashboardWidgetLoadingSelector(String(widgetId)),
  );
  const isDirty = useSelector(dashboardWidgetDirtySelector(String(widgetId)));

  const { showWidgetHeaders } = useDashboardSettings();

  const widgetProps = useSelector(
    dashboardWidgetDataSelector(String(widgetId)),
  );

  const [isLoadingScreenshot, setIsLoadingScreenshot] = useState<boolean>(false);

  const isFullScreenAbility: boolean = useMemo(
    () => getBooleanPropertyValue('fullScreenAbility', widgetProps.properties),
    [widgetProps.properties],
  );

  const widgetTitle = widgetProps?.properties
    ? getFormulasPropertyFromWidgetData(
      widgetProps,
      WidgetPropertyType.title,
      widgetDataObject.properties,
    )
    : '';

  const widgetDescription = widgetProps?.properties
    ? getFormulasPropertyFromWidgetData(
      widgetProps,
      WidgetPropertyType.description,
      widgetDataObject.properties,
    )
    : '';

  const displayHeaderPosition: string =
    getSelectPropertyValue('displayHeaderPosition', widgetProps?.properties) ||
    HeaderPositions.left;

  const headerTextAlign: 'left' | 'center' =
    displayHeaderPosition === HeaderPositions.left ? 'left' : 'center';

  const { titleSettings, subtitleSettings } = getHeaderSettings(
    widgetProps?.properties,
  );

  const titleSettingsWithAnimation = {
    ...titleSettings,
    ...(isLoading ? getTextSkeletonStyles(titleSettings.color || '#000', varcss(CssVariables.WIDGET_BACKDROP_COLOR)) : {})
  } ;

  const subtitleSettingsWithAnimation = {
    ...subtitleSettings,
    ...(isLoading ? getTextSkeletonStyles(subtitleSettings.color || '#000', varcss(CssVariables.WIDGET_BACKDROP_COLOR)) : {})
  } ;

  const projectId: number =
    parseInt(useParams<{ projectId: string }>()?.projectId) ?? 0;

  const { values: dashboardFiltersGroups } = useDashboardFilters();

  const onDownloadImageClick = (imageType: string) => {
    setIsLoadingScreenshot(true);
    const filter = (node: any) => {
      if (!node?.className || typeof node.className !== 'string') {
        return true;
      }

      return !node.className.includes('screenshot-deleted-item');
    };

    const startCallback = () => {
      changeNodeForWidgetScreenshot(containerRef.current);
    };

    const finallyCallback = () => {
      setIsLoadingScreenshot(false);
      changeNodeForWidgetScreenshot(containerRef.current, true);
    };

    // используем setTimeout, чтобы произошел рендер после setIsLoadingScreenshot и до начала выполнения takeScreenShot
    setTimeout(() =>
      takeScreenShot(
        imageType,
        containerRef.current,
        widgetProps.name,
        filter,
        startCallback,
        finallyCallback,
      ),
    );
  };

  return {
    isLoading,
    isDirty,
    showWidgetHeaders,
    isFullScreenAbility,
    widgetTitle,
    widgetDescription,
    headerTextAlign,
    titleSettings: titleSettingsWithAnimation,
    subtitleSettings: subtitleSettingsWithAnimation,
    projectId,
    widgetProps,
    dashboardFiltersGroups,
    onDownloadImageClick,
    isLoadingScreenshot,
  };
};
