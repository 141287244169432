import React from 'react';
import { IconButtonProps, styled } from '@material-ui/core';
import MUIIconButton from '@material-ui/core/IconButton';

export enum IconButtonType {
  primary,
  secondary,
  withoutEffects,
  shadow,
  delete,
}

interface StyledIconButtonProps {
  iconButtonProps: IconButtonProps;
  type: IconButtonType;
}

const getIconColorForHover = (type: IconButtonType) => {
  switch (type) {
    case IconButtonType.primary:
      return 'var(--primary-hover-color)';
    case IconButtonType.secondary:
      return 'var(--primary-color)';
    case IconButtonType.delete:
      return 'var(--red-normal)';
    case IconButtonType.withoutEffects:
      return '';
    case IconButtonType.shadow:
      return '';
  }
};

const StyledIconButton = styled(MUIIconButton)(
  ({ iconButtonType, disabled }: { iconButtonType: IconButtonType, disabled?: boolean }) => ({
    padding: iconButtonType === IconButtonType.shadow ? '5px' : 0,
    opacity: disabled ? 0.6 : 1,

    '&:hover': {
      backgroundColor: iconButtonType === IconButtonType.shadow ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
    },
    '& svg:hover': {
      fill: getIconColorForHover(iconButtonType),
    }
  }),
);

export const CustomIconButton = ({ iconButtonProps, type }: StyledIconButtonProps) => {
  return (
    <StyledIconButton {...iconButtonProps} iconButtonType={type}>
      {iconButtonProps.children}
    </StyledIconButton>
  );
};
