import React from 'react';
import { RadioGroup } from '@material-ui/core';
import {
  DashboardFilterSetting,
  DashboardFilterSettingType,
} from '../../../../../slices/types';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CustomRadio } from '../../../../../uikit/Radio/Radio';
import { useSettingItem } from './useSettingItem';

export interface SettingItemProps {
  setting: DashboardFilterSetting;
  type: DashboardFilterSettingType;
  filtersGroupId: number;
}

export const SettingItem = ({ setting, type, filtersGroupId }: SettingItemProps) => {
  const {
    settingValue,
    changeFilterSetting
  } = useSettingItem({ setting, type, filtersGroupId });

  switch (type) {
    case 'CHECKBOX':
      return (
        <CustomFormControl
          control={
            <CustomCheckbox
              checked={settingValue as boolean}
              onChange={() => changeFilterSetting(setting.name, !settingValue)}
            />
          }
          label={setting.displayedName}
        />
      );
    case 'RADIOBUTTON':
      return (
        <RadioGroup
          value={settingValue}
          onChange={(event, value) => changeFilterSetting(setting.name, value)}
        >
          {setting.options && Object.keys(setting.options).map((optionName) => (
            <CustomFormControl
              key={optionName}
              value={setting.options![optionName]}
              control={<CustomRadio color="primary" />}
              label={optionName}
            />
          ))}
        </RadioGroup>
      );
    default:
      return null;
  }
};
