import React from 'react';
import { Backdrop, Grid } from '@material-ui/core';
import { ClearCacheButton } from '../../common/special-buttons/cache-clear-button';
import MapConnectionTypes from '../../../types/map-connection';
import { CustomButton } from '../../../uikit/Button';
import { useFunctionalPanel } from './useFunctionalPanel';
import { Operation } from '../../../enums/operation';
import { SearchBlock } from './search-block/search-block';

export interface FunctionalPanelProps {
  active: boolean;
  setCurrentOperation: React.Dispatch<React.SetStateAction<Operation | null>>;
  loaderData: MapConnectionTypes.Data;
}

export const FunctionalPanel = ({
  active,
  setCurrentOperation,
  loaderData,
}: FunctionalPanelProps) => {
  const {
    classes,
    operations,
    loaderId,
    nextTickAdviceMode,
    handleClose,
  } = useFunctionalPanel({ active, setCurrentOperation, loaderData });

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={`${classes.functionalPanel} ${!active && classes.disabled}`}
      >
        <Grid item>
          {operations.map((operation) => (
            <CustomButton
              key={operation.title}
              disabled={operation.disabled}
              startIcon={operation.startIcon}
              className={operation.className}
              onClick={operation.onClick}
              variant={operation.variant as any}
            >
              {operation.title}
            </CustomButton>
          ))}
        </Grid>
        <SearchBlock loaderData={loaderData} />
        <Grid item>
          <ClearCacheButton
            active={active}
            loaderId={loaderId}
          />
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={nextTickAdviceMode}
          onClick={handleClose}
        />
      </Grid>
    </>
  );
};
