import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteDialog from '../../../../uikit/DeleteDialog/delete-dialog';
import { toggleDeletePopupAction } from '../../../../slices/map-view/map-view';
import { DeleteButton } from '../../../common/special-buttons/delete-button/delete-button';

interface NodeData {
  id: string;
  type: string;
  name: string;
}

interface ButtonDeleteProps {
  onDelete: () => void;
  nodeData: NodeData;
  isLoading?: boolean;
  error?: string;
}

const getText = (type: string, name: string) => {
  switch(type) {
    case 'operation':
      return { title: 'Удаление операции', bodyText: `операцию ${name}` };
    case 'source':
      return { title: 'Удаление таблицы', bodyText: `таблицу ${name}` };
    default:
      return { title: '', bodyText: '' };
  }
};

const ButtonDelete: React.FC<ButtonDeleteProps> = (props) => {
  const {
    onDelete,
    nodeData: { type, name },
    error,
    isLoading,
  } = props;
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const dispatch = useDispatch();

  const toggleDeleteDialog = () => {
    dispatch(toggleDeletePopupAction(!showDeleteDialog));
    setShowDeleteDialog((prevState) => !prevState);
  };

  const handleDeleting = () => {
    dispatch(toggleDeletePopupAction(false));
    onDelete();
  };

  const { title, bodyText } = getText(type, name);

  return (
    <>
      <DeleteButton onClick={toggleDeleteDialog} />
      {showDeleteDialog && (
        <DeleteDialog
          isOpen={showDeleteDialog}
          onClose={toggleDeleteDialog}
          onDelete={handleDeleting}
          title={title}
          bodyText={bodyText}
          error={error}
          isLoading={isLoading}
          maxWidth="xs"
        />
      )}
    </>
  );
};

export default ButtonDelete;
