import { Popper, PopperProps, styled } from '@material-ui/core';
import React from 'react';

interface StyledPopperProps {
  backgroundColor?: string;
}

const StyledAutocompletePopper = styled(Popper)(({ backgroundColor }: StyledPopperProps) => ({
  '& .MuiAutocomplete-paper': {
    backgroundColor: backgroundColor || 'var(--white)',
    border: '1px solid #DAE7ED',
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    borderRadius: '4px',
    padding: '2px',
  },
  '& .MuiAutocomplete-listbox': {
    padding: 0,
  },
  '& .MuiAutocomplete-option': {
    padding:
      'calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient))',

    '& div': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'calc(16px*var(--scale-coefficient))',
      color: 'var(--black)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const CustomAutocompletePopper = (props: PopperProps & {backgroundColor?: string}) => {
  return (
    <StyledAutocompletePopper {...props} />
  );
};
