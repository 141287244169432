import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { CustomIconButton, IconButtonType } from '../IconButton';
import './index.css';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../components/common/icon-svg/icon-svg';

interface CustomDropdownListProps {
  title?: string;
  isOpenInit: boolean;
  children: JSX.Element | JSX.Element[] | undefined;
}

export const CustomDropdownList = ({
  title,
  isOpenInit,
  children,
}: CustomDropdownListProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenInit);

  return (
    <div className="custom-dropdown-list">
      <div
        className="custom-dropdown-list__head"
        onClick={() => setIsOpen((prev) => !prev)}
        role="button"
        tabIndex={-1}
      >
        {title && <div className="custom-dropdown-list__title">{title}</div>}
        <div className="custom-dropdown-list__actions">
          <CustomIconButton
            iconButtonProps={{
              children: (
                <IconSvg
                  svg={
                    isOpen
                      ? IconDictionary.CompactArrowToBottom
                      : IconDictionary.CompactArrowToTop
                  }
                  width={24}
                  height={24}
                  fill={isOpen ? 'var(--title-color)' : 'var(--dark-grey)'}
                />
              ),
            }}
            type={IconButtonType.secondary}
          />
        </div>
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div className="custom-dropdown-list__body">{children}</div>
      </Collapse>
    </div>
  );
};
