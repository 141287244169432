import React, { useEffect, useState } from 'react';
import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import classNames from 'classnames/bind';
import { numDecline } from '../../../utils/functions';
import { Project } from '../../../slices/types';
import { LoadersTable } from '../loaders-table/loaders-table';
import LoadersCards from '../loaders-cards/loaders-cards';
import IconSvg from '../../common/icon-svg/icon-svg';

import styles from './group-favorite.module.css';
import {CommonDictionary} from "../../../dictionaries/naming-dictionary/naming-dictionary";
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const cx = classNames.bind(styles);

type GroupFavoriteProps = {
  title: string;
  projects?: Project[];
  groupView: string;
  id: number;
  isShow: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 'var(--base-border-radius)',
      backgroundColor: 'var(--white) !important',
      boxShadow:
        '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    },
    in: {
      width: '100%',
      backgroundColor: 'var(--white)',
      boxShadow: 'none',
      borderTop: '1px solid var(--light-grey)',
      borderBottomLeftRadius: 'var(--base-border-radius)',
      borderBottomRightRadius: 'var(--base-border-radius)'
    },
    active: {
      backgroundColor: 'var(--white)',
      paddingTop: '7px',
      paddingBottom: '7px',
    },
    groupMoreButton: {
      marginRight: '30px',
    },
  }),
);

const GroupFavorite = ({
  title,
  projects,
  groupView,
  id,
  isShow,
}: GroupFavoriteProps) => {
  const [isShowGroup, setShowGroup] = useState<boolean>(false);

  useEffect(() => {
    setShowGroup(isShow);
  }, [isShow]);

  const classes = useStyles();
  const arrowClassName = cx(styles.iconArrow, {
    [styles['iconArrowUp']]: isShowGroup,
  });

  const handleGroupHeaderClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowGroup(!isShowGroup);
    event.stopPropagation();
  };

  return (
    <li className={styles.groupContainer}>
      <ListItem
        button
        disableRipple
        onClick={handleGroupHeaderClick}
        className={`group ${classes.root}`}
      >
        <ListItemText>
          <div className={styles.counter}>
            <div className={styles.counter__title}>{title}</div>
            <IconSvg
              svg={IconDictionary.ProjectCount}
              fill="var(--dark-grey)"
              classSVG={styles.counterIcon}
              width={20}
              height={20}
            />
            <span className={styles.counter__projects}>
              {`${projects?.length} ${numDecline(
                projects?.length,
                CommonDictionary.oneProject,
                CommonDictionary.fewProjects,
                CommonDictionary.manyProjects,
              )}`}
            </span>
          </div>
        </ListItemText>

        <div className={arrowClassName}>
          <IconSvg svg={IconDictionary.RoundedArrowToBottom} width={22} height={22} fill={isShowGroup ? 'var(--primary-color)' : 'var(--dark-grey)'} />
        </div>
      </ListItem>

      {isShowGroup && (
        <Collapse in={isShowGroup} className={classes.in} timeout={3}>
          {groupView === 'table' ? (
            <LoadersTable groupId={id} projects={projects} />
          ) : (
            <LoadersCards groupId={id} projects={projects} />
          )}
        </Collapse>
      )}
    </li>
  );
};

export default GroupFavorite;
