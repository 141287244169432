import React from 'react';
import { CustomButton } from 'src/uikit/Button';
import './styles.css';
import { apiExportFile } from 'src/services/export';
import {
  defineFileDataFromResponse,
  fileDownload,
} from 'src/helpers/import-export';
import { useNotificator } from '../snackbar/hooks';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import {
  CommonDictionary,
  DashboardDictionary,
  WidgetDictionary
} from '../../../dictionaries/naming-dictionary/naming-dictionary';

interface SubscribeFormProps {
  isOpen: boolean;
  description?: string;
  handleCloseForm: () => void;
  projectId?: number;
}

export const ExportLocalFilesModal: React.FC<SubscribeFormProps> = ({
  handleCloseForm,
  description,
  isOpen,
  projectId = 0
}) => {
  const { showNotification } = useNotificator();

  const onDownloadHandle = () => {
    apiExportFile(projectId, true).then((res) => {
      const fileData = defineFileDataFromResponse(res);
      fileDownload(fileData.file, fileData.fileName);
    }).catch((error) => {
      const defaultErrorMessage = 'Ошибка при загрузке файла';
      showNotification({
        message: error.response?.data?.message || defaultErrorMessage,
        variant: 'error',
      });
    }).finally(() => {
      handleCloseForm();
    });
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={handleCloseForm}
      description={description}
      title={`Выгрузка ${CommonDictionary.projectGenitive}`}
      maxWidth="xs"
      fullWidth={false}
    >
      <div>
        <div className="dialog-body export-files-modal">
          <p className="export-files-modal__descr">
            Локальные файлы, а также построенные на них {WidgetDictionary.widgets} и {DashboardDictionary.dashboards} не будут выгружены
          </p>
        </div>
        <div className="dialog-buttons">
          <CustomButton
            variant="contained"
            onClick={onDownloadHandle}
          >
            Выгрузить
          </CustomButton>

          <CustomButton variant="outlined" onClick={handleCloseForm}>
            Отменить
          </CustomButton>
        </div>
      </div>
    </CustomDialog>

  );
};
