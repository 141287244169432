import React from 'react';
import { Project } from '../../../../slices/types';
import NewProjectForm from '../../../common/new-project-form/new-project-form';
import CustomDialog from '../../../../uikit/Dialog/custom-dialog';
import {CommonDictionary} from "../../../../dictionaries/naming-dictionary/naming-dictionary";

interface EditModalProps {
  currentProject: Project;
  closeProjectSettings: () => void;
}

export const EditModal = ({
  currentProject,
  closeProjectSettings,
}: EditModalProps) => {
  return (
    <CustomDialog
      isOpen={true}
      onClose={closeProjectSettings}
      maxWidth="xs"
      title={`Редактирование ${CommonDictionary.projectGenitive}`}
    >
      <NewProjectForm
        handleCloseForm={closeProjectSettings}
        projectId={currentProject?.id}
        projectName={currentProject?.name}
        projectGroupId={currentProject?.projectGroupId}
        projectIsPersonal={currentProject?.personal}
        projectIsFavourite={currentProject?.favourite}
      />
    </CustomDialog>
  );
};
