import { useCallback } from 'react';
import { Property } from '../../../dropdown-layout/helpers/Property';

interface Props {
  widgetId: number;
  setFilterField: any;
  enableEvents: boolean;
  filterField: any;
  data: any;
  axisXValues: any;
}

export const useHistogramFilters = ({
  widgetId,
  setFilterField,
  enableEvents,
  filterField,
  data,
  axisXValues,
}: Props) => {
  const deselectBar = useCallback(() => {
    setFilterField && setFilterField(widgetId, null, []);
  }, [setFilterField, widgetId]);

  const selectBar = useCallback(
    (groupIndex: number) => {
      if (!enableEvents) return;
      if (filterField?.value?.includes(data[groupIndex]?.x)) {
        deselectBar();
      } else {
        if (!setFilterField || !data[groupIndex]?.x) return;

        setFilterField(widgetId, new Property(axisXValues[0]).getId(), [
          {
            operation: '=',
            value: [data[groupIndex].x],
          },
        ]);
      }
    },
    [
      enableEvents,
      filterField,
      data,
      deselectBar,
      setFilterField,
      widgetId,
      axisXValues,
    ],
  );

  return {
    selectBar,
  };
};
