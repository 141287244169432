import React, { useEffect } from 'react';
import { getScaleCoefficient } from '../../../../helpers/ui-helper';
import { CustomProgress } from '../../../../uikit/Progress';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { SelectInput } from '../../../common/special-inputs/inputs/select-input';
import { BoundFilterGroupProps } from '../../hooks';
import { BoundType } from '../enums';
import { CustomFormControl } from '../../../../uikit/FormControl';
import { useConnectionGroup } from './useConnectionGroup';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import { WidgetDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface ConnectionGroupProps {
  widgets: any[];
  freeWidgets: any[];
  drillDownGroup: BoundFilterGroupProps;
  onChangeMasterWidget: (value: number, layout?: number) => any;
  onChangeConnectedWidget: (e: any) => any;
  onDeleteGroup: () => void;
  allDrillDownWidgetsIds: number[];
  boundType: BoundType;
  inactiveWidgetsForDD?: any[];
}

const HEIGHT_SEARCH = 44;

export const ConnectionGroup = ({
  widgets,
  freeWidgets,
  drillDownGroup,
  onChangeMasterWidget,
  onChangeConnectedWidget,
  onDeleteGroup,
  allDrillDownWidgetsIds,
  boundType,
  inactiveWidgetsForDD = [],
}: ConnectionGroupProps) => {
  const {
    handleChangeMasterWidget,
    masterWidgetValue,
    potentialMasterWidgetsList,
    isLoadedGroup,
    controlComponent,
    isDisableOption,
    handleChangeSearch,
    filteredConnectOptionsWidgets,
    isBoundType,
    searchRef,
    isLoadingWidgets,
    isNeedShowSearch,
    heightConnectedWidgetList,
  } = useConnectionGroup({
    widgets,
    freeWidgets,
    drillDownGroup,
    onChangeMasterWidget,
    onChangeConnectedWidget,
    onDeleteGroup,
    allDrillDownWidgetsIds,
    boundType,
    inactiveWidgetsForDD,
  });

  return (
    <div className="filter-list-item">
      <div className="filter-list-item__header">
        <h3 className="filter-list-item__heading">Связь</h3>
        <CustomIconButton
          iconButtonProps={{
            children: (
              <IconSvg
                svg={IconDictionary.BasketDelete}
                fill="var(--dark-grey)"
              />
            ),
            onClick: onDeleteGroup,
            className: 'filter-list-item__button',
          }}
          type={IconButtonType.delete}
        />
      </div>

      <div className="filter-list-item__body">
        <div className="filter-list-item__body-item">
          {isLoadingWidgets ? (
            <div className="filter-list-item__body-item-loading">
              <CustomProgress
                type="circular"
                size={HEIGHT_SEARCH * getScaleCoefficient()}
              />
            </div>
          ) : (
            <SelectInput
              label={WidgetDictionary.mainWidget}
              fullWidth
              handleChange={handleChangeMasterWidget}
              value={masterWidgetValue}
              itemList={potentialMasterWidgetsList}
            />
          )}
        </div>
        {isLoadedGroup && isNeedShowSearch && (
          <div className="filter-list-item__body-item">
            <TextInput
              label="Поиск"
              handleChange={handleChangeSearch}
              searchIcon
              fullWidth
              ref={searchRef}
            />
          </div>
        )}

        {isLoadedGroup && (
          <>
            <div
              className="filter-radio-list filter-list-item__body-item"
              style={{ maxHeight: heightConnectedWidgetList }}
            >
              {filteredConnectOptionsWidgets.map((participant: any) => {
                return (
                  <CustomFormControl
                    value={
                      boundType === BoundType.autoDrillDownGroups
                        ? participant.widgetId
                        : `${participant.widgetId}-${participant.name}`
                    }
                    control={controlComponent}
                    label={participant.name}
                    onChange={onChangeConnectedWidget}
                    checked={participant.isActive}
                    disabled={isDisableOption(participant)}
                    key={participant.filterId}
                  />
                );
              })}
            </div>
            {filteredConnectOptionsWidgets.length < 1 && (
              <div className="filter-list-item__no-connection-title">
                Нет {WidgetDictionary.many} для связи
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
