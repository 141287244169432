import React, { CSSProperties } from 'react';
import { guideColor } from '../constants';
import { Scales } from '../hooks/useScales';
import {
  BubbleData,
  BubbleSets,
  Dimensions,
  FormattingValues,
  Interaction,
  SeriesValues,
} from '../types';
import { BottomAxisValue } from './botom-axis-value';
import { ColumnValues } from './column-values';

interface ColumnProps {
  xPosition: number;
  stylePosition: CSSProperties;
  data: BubbleData[];
  fields: SeriesValues;
  formattingValues: FormattingValues;
  interaction: Interaction;
  bubbleSets: BubbleSets;
  dimensions: Dimensions;
  scales: Scales;
}

export const Column = ({
  xPosition,
  stylePosition,
  data,
  fields,
  formattingValues,
  interaction,
  bubbleSets,
  dimensions,
  scales,
}: ColumnProps) => {
  const { isNeedToDisplayAxesGuide } = bubbleSets;

  const { chartHeight, columnWidth } = dimensions;

  const { axisXValue } = data[xPosition];

  return (
    <div
      className="widget-bubble-chart__column"
      key={data[xPosition].axisXPosition}
      style={{
        ...stylePosition,
      }}
    >
      {isNeedToDisplayAxesGuide && (
        <div
          className="widget-bubble-chart__vertical-guide"
          style={{
            left: columnWidth / 2 - 1, // не понятно почему, но если сделать -1 то линия становится визуально как раз посередине
            height: chartHeight,
            borderLeft: `1px solid ${guideColor}`,
          }}
        />
      )}
      <ColumnValues
        xPosition={xPosition}
        data={data}
        fields={fields}
        formattingValues={formattingValues}
        interaction={interaction}
        bubbleSets={bubbleSets}
        dimensions={dimensions}
        scales={scales}
      />
      <BottomAxisValue
        axisXValue={axisXValue}
        dimensions={dimensions}
        formattingValues={formattingValues}
        bubbleSets={bubbleSets}
      />
    </div>
  );
};
