import React from 'react';
import { FilterForPanel } from '../../../hooks';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { DdFilterPanelTooltip } from './dd-filter-panel-tooltip';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';

interface DdFilterPanelItemProps {
  filter: FilterForPanel;
}

export const DdFilterPanelItem = ({ filter }: DdFilterPanelItemProps) => {
  const valuesCount = filter.values.length;
  const isMultipleFilter =
    valuesCount > 1 &&
    (filter.simplifiedType === WidgetSimplifiedDataType.TEXT ||
      filter.simplifiedType === WidgetSimplifiedDataType.BOOLEAN);

  const tooltipValue = (
    <div className="dd-filters-panel__item-value-tooltip">
      {filter.values[0]}
    </div>
  );

  return (
    <CustomTooltip
      disableHoverListener={!isMultipleFilter}
      placement="bottom"
      title={<DdFilterPanelTooltip filter={filter} />}
    >
      <div className="dd-filters-panel__item">
        <CustomTooltip
          title={tooltipValue}
          interactive
          disableHoverListener={isMultipleFilter}
        >
          <div className="dd-filters-panel__item-value">
            {filter.name && `${filter.name}: `}
            {filter.values[0]}
          </div>
        </CustomTooltip>
        {isMultipleFilter && (
          <div className="dd-filters-panel__item-multiple">
            +{valuesCount - 1}
          </div>
        )}
      </div>
    </CustomTooltip>
  );
};
