import React from 'react';
import '../styles.css';
import './panel-item.css';
import { WidgetProperties } from '../../../../slices/types';
import { FunctionsAndAggregationsBlocks } from './functions-and-aggregations-blocks/functions-and-aggregations-blocks';
import { MapModalsSettings } from './map-modals-settings/map-modals-settings';
import { PanelItemValue } from './panel-item-value/panel-item-value';
import { MoreButton } from './more-button/more-button';
import { usePanelItem } from './usePanelItem';
import Dictionaries from '../../../../types/dictionaries';
import { PanelItemPopover } from './panel-item-popover/panel-item-popover';
import { isShowRestriction } from '../helpers';
import { DeleteButton } from '../../../common/special-buttons/delete-button/delete-button';

export interface PanelItemProps {
  panel: WidgetProperties;
  index: number;
  draggableId: string;
  functionsAndAggregations: Dictionaries.FunctionOrAggregationObject;
}

export const PanelItem = ({
  panel,
  index,
  draggableId,
  functionsAndAggregations
}: PanelItemProps) => {
  const {
    setNodeRef,
    style,
    attributes,
    listeners,
    containerRef,
    handleDelete,
    data,
    propertyInstance,
    isMapWidget,
    etlSourceId,
    sourceDisplayName,
    anchorEl,
    setAnchorEl,
    panelsWithRestriction,
    widget,
    handleMoreButtonClick
  } = usePanelItem({
    panel,
    index,
    draggableId,
    functionsAndAggregations
  });

  return (
    <>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div
          className="widget-dropdown__item"
          ref={containerRef}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <DeleteButton onClick={handleDelete} />

          <FunctionsAndAggregationsBlocks
            index={index}
            data={data}
            panel={panel}
          />

          <PanelItemValue propertyInstance={propertyInstance} />

          <MoreButton
            functionsAndAggregations={functionsAndAggregations}
            panel={panel}
            propertyInstance={propertyInstance}
            handleMoreButtonClick={handleMoreButtonClick}
          />

          {isMapWidget && (
            <MapModalsSettings
              index={index}
              etlSourceId={etlSourceId}
              sourceDisplayName={sourceDisplayName}
            />
          )}
        </div>
      </div>

      <PanelItemPopover
        containerWidth={(containerRef.current as any)?.clientWidth ?? 0}
        functions={functionsAndAggregations}
        isShowRestriction={isShowRestriction(
          widget.type,
          panelsWithRestriction.includes(panel.name),
          propertyInstance.isComplex(),
        )}
        index={index}
        panel={panel}
        propertyInstance={propertyInstance}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};
