import { Button as MuiButton } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compact } from 'lodash';
import { PanelType } from '../../../../../enums/widget-type';
import { apiFetchSourceData } from '../../../../../services/widgetController';
import { State } from '../../../../../slices/types';
import { setWidgetPropAction } from '../../../../../slices/widget/widget';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';
import './map-filters-modal.css';
import {
  getParsedAxisValues,
  getSimplifiedType,
} from '../../../dropdown-layout/helpers/helpers';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { mapModalTypes } from '../../enums';
import SourceField from '../map-modals/source-field';
import { useFieldAggregationsAndFunctions } from '../map-modals/useFieldAggregationsAndFunctions';
import { CustomButton } from '../../../../../uikit/Button';
import { MapModalDialog } from '../map-modals/map-modal-dialog/map-modal-dialog';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface MapFiltersModalProps {
  etlSourceId: string;
  widget: any;
  sourceDisplayName: string;
  onClose: (type: mapModalTypes) => void;
}

const MapFiltersModal = ({
  widget,
  etlSourceId,
  sourceDisplayName,
  onClose,
}: MapFiltersModalProps) => {
  const dispatch = useDispatch();
  const [widgetSourceFields, setWidgetSourceFields] = useState<any>([]);
  const [filterWidgetSourceFields, setFilterWidgetSourceFields] = useState<any>(
    [],
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    functions,
    currentFunctions,
    onChangeFunctionHandle,
    initCurrentFunctions,
    onDeleteFunctionHandle,
    clearFunctionsAndAggregations,
  } = useFieldAggregationsAndFunctions(
    PanelType.axisFilter,
    etlSourceId,
    false,
    false,
  );

  const axisFilterValue = getParsedAxisValues(
    PanelType.axisFilter,
    widget.properties,
  );

  const widgetStoreObj = useSelector((state: State) => state.widget);

  const updateSourceFields = (sourceFields: any) =>
    sourceFields.map((field: any) =>
      field.filter ? field : { ...field, filter: [] },
    );

  const currentSourceFields = axisFilterValue.filter(
    (item: any) => item.etlSourceId === etlSourceId,
  );

  const [currentWidgetFields, setCurrentWidgetFields] = useState<any>(
    updateSourceFields(currentSourceFields),
  );

  const handleClose = (event: any) => {
    onClose(mapModalTypes.filter);
    event.stopPropagation();
  };

  const onSearchChange = useCallback(
    (event: any) => {
      const { value } = event.target;

      const searchResult = widgetSourceFields.filter((field: any) =>
        field.name.toLowerCase().includes(value.toLowerCase()),
      );

      setFilterWidgetSourceFields(searchResult);
    },
    [widgetSourceFields],
  );

  const handleWrapperClick = (event: any) => {
    event.stopPropagation();
  };

  useEffect(() => {
    apiFetchSourceData(etlSourceId).then((result) => {
      const filteredWidgetFields = result.fields.filter((item: any) => {
        const isDescriptionItem =
          item.type.indexOf('POLYGON') === -1 &&
          item.type.indexOf('POINT') === -1;

        if (isDescriptionItem) {
          delete item['dimension'];
          delete item['measure'];
          item.etlSourceId = etlSourceId;
          return item;
        }
      });
      const formattedWidgetFields = updateSourceFields(filteredWidgetFields);
      setWidgetSourceFields(formattedWidgetFields);
      setFilterWidgetSourceFields(formattedWidgetFields);
    });
  }, []);

  const onChangeHandle = useCallback(
    (params: any) => {
      const { field, isChecked } = params;
      const fieldsList = [...currentWidgetFields];

      if (isChecked) {
        const filteredList = fieldsList.filter(
          (item: any) => item.name !== field.name,
        );

        const fieldIndex = fieldsList.findIndex(
          (item: any) => item.name === field.name,
        );

        onDeleteFunctionHandle(fieldIndex, true);

        setCurrentWidgetFields(filteredList);
      } else {
        fieldsList.push(field);
        setCurrentWidgetFields(fieldsList);
      }
    },
    [currentWidgetFields, onDeleteFunctionHandle],
  );

  const onSaveButtonClickHandle = useCallback(
    (event: any) => {
      setLoading(true);

      const filteredFieldsList = axisFilterValue.filter(
        (item: any) => item.etlSourceId !== etlSourceId,
      );

      const sortedCurrentFields: any[] = [];
      widgetSourceFields.forEach((sourceField: any) => {
        const currentFieldIndex = currentWidgetFields.findIndex(
          (field: any) => sourceField.etlFieldId === field.etlFieldId,
        );
        if (currentFieldIndex !== -1) {
          sortedCurrentFields.push(currentWidgetFields[currentFieldIndex]);
        }
      });

      const newFunctions = compact(currentFunctions);

      const newFiltersWithFunctions: any[] = [];
      sortedCurrentFields.forEach((filter: any, index: number) => {
        if (
          filter.etlSourceId === etlSourceId &&
          getSimplifiedType(filter.type) === 'DATE' &&
          newFunctions[index]
        ) {
          newFiltersWithFunctions.push({
            ...filter,
            function: newFunctions[index].formula || '',
          });
        } else {
          newFiltersWithFunctions.push(filter);
        }
      });

      const newAxisFilterValue = filteredFieldsList.concat(
        newFiltersWithFunctions,
      ).map((value: PropertyData) => ({ ...value, isActive: true }));

      const widgetCopy = { ...widget };
      const widgetProps = [...widgetCopy.properties];

      const axisFilterIndex = widget.properties.findIndex(
        (prop: any) => prop.name === 'axisFilter',
      );

      if (axisFilterIndex + 1) {
        widgetProps[axisFilterIndex] = {
          name: PanelType.axisFilter,
          value: JSON.stringify(newAxisFilterValue),
        };
      } else {
        widgetProps.push({
          name: PanelType.axisFilter,
          value: JSON.stringify(newAxisFilterValue),
        });
      }

      widgetCopy.properties = widgetProps;
      dispatch(
        setWidgetPropAction({
          name: PanelType.axisFilter,
          value: JSON.stringify(newAxisFilterValue),
        }),
      );

      setLoading(false);
      onClose(mapModalTypes.filter);
    },
    [currentWidgetFields, widgetStoreObj, currentFunctions, etlSourceId],
  );

  const selectAllFieldsHandle = useCallback(() => {
    setCurrentWidgetFields(widgetSourceFields);
  }, [widgetSourceFields]);

  const removeAllFieldsHandle = useCallback(() => {
    setCurrentWidgetFields([]);
    clearFunctionsAndAggregations();
  }, []);

  useEffect(() => {
    const currentWidgetFieldsIndex: any[] = [];
    const currentWidgetFieldsIds = currentWidgetFields.map(
      (field: any) => field.etlFieldId,
    );
    widgetSourceFields.forEach((field: any, index: number) => {
      if (currentWidgetFieldsIds.includes(field.etlFieldId)) {
        currentWidgetFieldsIndex.push(index);
      }
    });
    initCurrentFunctions(currentWidgetFields, currentWidgetFieldsIndex);
  }, [currentWidgetFields, functions.DATE.length]);

  return (
    <MapModalDialog open={true} onClick={handleWrapperClick}>
      <div className="map-filter">
        <div className="map-filter__header">
          <h3 className="map-filter__title">{sourceDisplayName}</h3>
          <CustomButton
            variant="outlined"
            icon={
              <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
            }
            onClick={handleClose}
          />
        </div>

        <div className="map-filter__content">
          <TextInput
            label="Поиск по полям"
            handleChange={onSearchChange}
            searchIcon
          />

          <div className="map-filter__buttons">
            <MuiButton
              className="map-filter__button"
              onClick={selectAllFieldsHandle}
              startIcon={
                <IconSvg
                  svg={IconDictionary.ListAdd}
                  fill="var(--primary-color)"
                />
              }
            >
              <span className="map-filter__add-button-text">Добавить все</span>
            </MuiButton>

            <MuiButton
              className="map-filter__button"
              onClick={removeAllFieldsHandle}
              startIcon={
                <IconSvg
                  svg={IconDictionary.ListDelete}
                  fill="var(--dark-grey)"
                />
              }
            >
              <span className="map-filter__delete-button-text">
                Удалить Все
              </span>
            </MuiButton>
          </div>

          <div className="map-filter__list">
            {filterWidgetSourceFields &&
              filterWidgetSourceFields.map((field: any, index: number) => {
                const isChecked = currentWidgetFields.some(
                  (item: any) => item.name === field.name,
                );
                return (
                  <SourceField
                    key={field.etlFieldId}
                    field={field}
                    view="checkbox"
                    onChangeCurrentFieldHandle={onChangeHandle}
                    onChangeFunctionHandle={onChangeFunctionHandle}
                    onDeleteFunctionHandle={onDeleteFunctionHandle}
                    functions={
                      getSimplifiedType(field.type) === 'DATE'
                        ? functions?.DATE
                        : []
                    }
                    currentFunction={currentFunctions[index]}
                    isChecked={isChecked}
                    index={index}
                    isPossibleDeleteFunction={true}
                  />
                );
              })}
          </div>
        </div>

        <div className="dialog-buttons">
          <CustomButton
            disabled={isLoading}
            loading={isLoading}
            onClick={onSaveButtonClickHandle}
            variant="contained"
          >
            Сохранить
          </CustomButton>
          <CustomButton
            disabled={isLoading}
            type="reset"
            onClick={handleClose}
            variant="outlined"
          >
            Отменить
          </CustomButton>
        </div>
      </div>
    </MapModalDialog>
  );
};

export default MapFiltersModal;
