import { CircularProgress } from '@material-ui/core';
import React from 'react';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { CssVariables } from '../../../../enums/css-variables';
import { varcss } from '../../../../helpers/ui-helper';
import IconSvg from '../../icon-svg/icon-svg';
import './styles.css';
import { useCacheClearButton } from './useCacheClearButton';
import { CustomButton } from '../../../../uikit/Button';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface ClearCacheButtonProps {
  loaderId: number;
  active?: boolean;
  standAlone?: boolean;
}

export const ClearCacheButton = ({
  loaderId,
  active = true,
  standAlone = false,
}: ClearCacheButtonProps) => {
  const {
    isLoading,
    handleClickUpdateCache,
  } = useCacheClearButton({ loaderId, active, standAlone });

  return (
    <CustomTooltip title="Обновить данные">
      <div>
        <CustomButton
          variant={standAlone ? 'outlined': 'text'}
          disabled={!active || isLoading}
          icon={isLoading ? <CustomProgress type="circular" /> : <IconSvg svg={IconDictionary.CashRefresh} fill='var(--dark-grey)' />}
          onClick={handleClickUpdateCache}
          loading={isLoading}
          backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
        />
      </div>
    </CustomTooltip>
  );
};
