import { useMemo } from 'react';
import { getFormulasPropertyFromWidgetData } from 'src/helpers/widget-page/widget-properties-helper';
import {
  PanelType,
  WidgetPropertyType,
} from '../../../../../enums/widget-type';
import {
  useGetNumberWidgetProperty,
  useGetStringWidgetProperty, useGetValueDirectionWidgetProperty
} from '../../../../../hooks/get-properties/useGetWidgetProperty';
import {
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  getLegendsLabelsForWidgetByAxis,
  getPropertyValueFromWidgetData,
  getSelectPropertyValue,
  sortColorPalette,
} from '../../helpers';
import { displayValuesPositions } from '../../histogram-graph/properties';
import { textDirections } from '../../../../common/scalable-svg-text';
import { NumberFormat } from '../../../../../enums/number-format';
import { defaultColors } from '../../common/color';
import { getActivePanelItems, getActivePanelItemsNames } from '../../../../../helpers/common-helpers';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { Widget } from '../../../../../slices/types';
import { valueTextSettings } from '../settings';

interface BarProps {
  widgetProperties: any;
  widgetProps: any;
}

export const useBarProps = ({
  widgetProperties,
  widgetProps,
}: BarProps) => {
  const roundingCountString: string = useMemo(() => getInputPropertyValue(
    WidgetPropertyType.roundingCount,
    widgetProperties,
  ),
  [widgetProperties]);

  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const isNeedToDisplayAvgAxisY: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayAvgAxisY,
    widgetProperties,
  ),
  [widgetProperties]);

  const propertiesFromWidgetData = useMemo(() => widgetProps.data.filter(
    (item: any) => item.isPropertyValue ?? item,
  ),
  [widgetProps]);

  const avgAxisYValue = useMemo(() => getPropertyValueFromWidgetData(
    WidgetPropertyType.displayAvgAxisY,
    propertiesFromWidgetData,
  ),
  [propertiesFromWidgetData]);

  const metaData = useMemo(() => getPropertyValueFromWidgetData(
    WidgetPropertyType.metaData,
    propertiesFromWidgetData,
  ),
  [propertiesFromWidgetData]);

  const axisXNames: Array<string> = useMemo(() => getActivePanelItemsNames(
    widgetProperties,
    PanelType.axisX,
  ),
  [widgetProperties]);

  const isNeedDisplayAggregationInLegend = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.displayAggregationInLegend,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const axisXValues: PropertyData[] = useMemo(
    () => getActivePanelItems(widgetProperties, PanelType.axisX),
    [widgetProperties],
  );

  const legendsLabels = useMemo(() => getLegendsLabelsForWidgetByAxis(
    PanelType.axisY,
    widgetProperties,
    isNeedDisplayAggregationInLegend,
  ),
  [isNeedDisplayAggregationInLegend, widgetProperties]);

  const displayValuesPosition: string = useMemo(() => getSelectPropertyValue(
    WidgetPropertyType.displayValuesPosition,
    widgetProperties,
  ),
  [widgetProperties]) ||
    displayValuesPositions.outside;

  const textDirection: string = useMemo(() => getSelectPropertyValue(
    WidgetPropertyType.TextDirection,
    widgetProperties,
  ),
  [widgetProperties]) ||
    textDirections.horizontal;

  const isNeedToDisplayBarValues: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayBarValues,
    widgetProperties,
  ),
  [widgetProperties]);

  const isNeedToDisplayTooltip: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayTooltip,
    widgetProperties,
  ),
  [widgetProperties]);

  const isNeedToDisplayOutsideBarValues: boolean =
    isNeedToDisplayBarValues &&
    displayValuesPosition === displayValuesPositions.outside;

  const isNeedToDisplayInsideBarValues: boolean =
    isNeedToDisplayBarValues &&
    displayValuesPosition === displayValuesPositions.inside;

  const isScaleByValueState: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.scaleByValue,
    widgetProperties,
  ),
  [widgetProperties]);

  const isNeedToDisplayAxesGuide: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayAxesGuide,
    widgetProperties,
  ),
  [widgetProperties]);

  const customAxisXLabelHeight = useMemo(() => parseInt(
    getInputPropertyValue(WidgetPropertyType.axisXLabelHeight, widgetProperties),
  ),
  [widgetProperties]);

  const customAxisXLabelWidth = useMemo(() => parseInt(
    getInputPropertyValue(WidgetPropertyType.axisXLabelWidth, widgetProperties),
  ),
  [widgetProperties]);

  const customAxisYLabelWidth = useMemo(() => parseInt(
    getInputPropertyValue(WidgetPropertyType.axisYLabelWidth, widgetProperties),
  ),
  [widgetProperties]);

  const offsetBarHeightValue = useMemo(() => parseInt(
    getInputPropertyValue(WidgetPropertyType.offsetValueTotal, widgetProperties),
  ) || 0,

  [widgetProperties]);

  const colorsPaletteState = getJSONPropertyValue(
    WidgetPropertyType.colorPalette,
    widgetProperties,
  );

  const currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || defaultColors;

  const columnWidth = useMemo(() => parseInt(
    getInputPropertyValue(WidgetPropertyType.columnWidth, widgetProperties),
  ) || 65,
  [widgetProperties]);

  const columnOffsetCoeff = useMemo(
    () =>
      parseFloat(getInputPropertyValue(WidgetPropertyType.columnOffsetCoeff, widgetProperties)),
    [widgetProperties],
  );

  const columnDiffCoeff = useMemo(
    () =>
      parseFloat(getInputPropertyValue(WidgetPropertyType.columnDiffCoeff, widgetProperties)),
    [widgetProperties],
  );

  const referenceLine1Text = useMemo(
    () =>
      getInputPropertyValue(WidgetPropertyType.referenceLine1Text, widgetProperties),
    [widgetProperties],
  );

  const referenceLine1Color = useMemo(
    () =>
      getInputPropertyValue(WidgetPropertyType.referenceLine1Color, widgetProperties),
    [widgetProperties],
  );

  const referenceLine1Type = useMemo(
    () =>
      getSelectPropertyValue(WidgetPropertyType.referenceLine1Type, widgetProperties),
    [widgetProperties],
  );

  const referenceLine1 = useMemo(
    () =>
      getBooleanPropertyValue(WidgetPropertyType.referenceLine1, widgetProperties),
    [widgetProperties],
  );

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const valueTextSize = useGetNumberWidgetProperty(
    WidgetPropertyType.textSize,
    widgetProperties,
    valueTextSettings.fontSize,
  );

  const valueColor = useGetStringWidgetProperty(
    WidgetPropertyType.valueColorHistogram,
    widgetProperties,
    undefined,
  );

  const valueDirection = useGetValueDirectionWidgetProperty(
    widgetProperties,
  );

  const referenceLine1Value = widgetProperties.length
    ? getFormulasPropertyFromWidgetData(
      { properties: widgetProperties } as Widget,
      WidgetPropertyType.referenceLine1Value,
      widgetProps.propertiesData,
    ).replaceAll(' ', '')
    : '';

  return {
    roundingCountString,
    roundingCount,
    isNeedToDisplayAvgAxisY,
    avgAxisYValue,
    metaData,
    axisXNames,
    axisXValues,
    legendsLabels,
    textDirection,
    isNeedToDisplayTooltip,
    isNeedToDisplayOutsideBarValues,
    isNeedToDisplayInsideBarValues,
    isScaleByValueState,
    isNeedToDisplayAxesGuide,
    customAxisXLabelHeight,
    customAxisYLabelWidth,
    currentColors,
    columnWidth,
    customAxisXLabelWidth,
    columnOffsetCoeff,
    columnDiffCoeff,
    scaleByNumber,
    formatByNumber,
    valueTextSize,
    valueColor,
    valueDirection,
    offsetBarHeightValue,
    referenceLine1Value,
    referenceLine1Text,
    referenceLine1Color,
    referenceLine1Type,
    referenceLine1
  };
};
