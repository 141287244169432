import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import Sunburst from 'sunburst-chart';
// @ts-ignore
import * as Rainbow from 'rainbowvis.js';
import { cloneDeep, isEqual } from 'lodash';
import { defaultColors } from '../common/color';
import { State } from '../../../../slices/types';
import { useEventListener } from '../../../../hooks/useEventListener';
import './styles.css';
import {
  getAggregationsForWidgetByAxis,
  getAxisValuesForWidget,
  getBooleanPropertyValue,
  getEnumPropertyValue, getInputPropertyValue,
  getJSONPropertyValue,
  getLegendHeight,
  sortColorPalette,
} from '../helpers';
import { PanelType } from '../../../../enums/widget-type';
import { LegendPositions } from '../../../../enums/legend';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { setPropForNumberValue } from '../formatting-helpers';
import { LegendArc } from '../legend-arcs/legent-arc';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';

interface SunburstProps {
  widgetProps?: any;
}

let currentColors: any = defaultColors;

const SunburstChart: React.FC<SunburstProps> = ({
  widgetProps,
}) => {
  const widgetData: any = cloneDeep(widgetProps.data);
  const dataCount = widgetData?.children?.length;

  const widgetStoreProperties = useSelector(
    (state: State) => state.widget.properties,
  );
  const widgetProperties = widgetProps?.properties || widgetStoreProperties;

  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  const roundingCountString: string = getInputPropertyValue(
    'roundingCount',
    widgetProperties,
  );
  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const valueFormat = initValueFormatter({ roundingCount });

  const axisXNames = getAxisValuesForWidget(PanelType.axisX, widgetProperties);
  const axisYName = getAxisValuesForWidget(PanelType.axisY, widgetProperties);

  const maxDataDepth = axisXNames.length;

  const axisYAggregation = getAggregationsForWidgetByAxis(PanelType.axisY, widgetProperties)[0]?.split('(')[0];

  const containerPadding = 20;

  const minLegendWidth = 200;

  const containerRef = useRef(document.createElement('div'));
  const [containerWidth, setContainerWidth] = useState<number>(containerRef.current.clientWidth);
  const [containerHeight, setContainerHeight] = useState<number>(containerRef.current.clientHeight);

  useLayoutEffect(() => {
    calculateDimensions();
  }, [
    widgetData,
    isFullScreen,
    containerRef.current.clientHeight,
    containerRef.current.clientWidth,
  ]);

  const calculateDimensions = () => {
    setContainerWidth(containerRef.current?.clientWidth);
    setContainerHeight(containerRef.current?.clientHeight);
  };

  useEventListener('resize', calculateDimensions);

  const isNeedToDisplayLegend: boolean = getBooleanPropertyValue(
    'displayLegend',
    widgetProperties,
  );

  const isNeedToDisplayTooltip: boolean = getBooleanPropertyValue(
    'displayTooltip',
    widgetProperties,
  );

  const isNeedToDisplayPercentOnSector: boolean = getBooleanPropertyValue(
    'displayPercentOnSector',
    widgetProperties,
  );

  const displayPercentOnLegend: boolean = getBooleanPropertyValue(
    'displayPercentOnLegend',
    widgetProperties,
  );

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );
  currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || currentColors;

  const [sectorColors, setSectorColors] = useState<any[]>([]);

  useEffect(() => {
    if (!sectorColors.length) {
      setPaletteColors();
    }
  }, []);

  const visiblePaletteColors = currentColors.length;

  const setPaletteColors = () => {
    const firstColors = [...currentColors].slice(0, visiblePaletteColors);
    const colors: any[] = [];
    for (let i = 0; i <= dataCount / visiblePaletteColors; i++) {
      colors.push(...firstColors);
    }

    setSectorColors(colors);
  };

  useEffect(() => {
    const newColoring =
      !isEqual(
        [...currentColors].slice(0, visiblePaletteColors),
        [...sectorColors].slice(0, visiblePaletteColors),
      );

    if (newColoring) {
      setPaletteColors();
    }
  }, [currentColors]);

  const legendPosition = useMemo(
    () =>
      getEnumPropertyValue<LegendPositions>('legendPosition', widgetProperties),
    [widgetProperties],
  );

  const containers = document.getElementsByClassName(
    `sunburst-chart-${widgetProps.id}`,
  );
  const sunburstContainer = containers ? containers[0] : null;

  const getParentId = (node: any): number => {
    if (node.__dataNode) {
      return node.__dataNode.parent.id > 0 ? getParentId(node.__dataNode.parent) : node.__dataNode.id;
    }
    return node.parent.id > 0 ? getParentId(node.parent) : node.id;
  };

  const getPathColor = (node: any): string => {
    if (node.__dataNode.id - 1 < dataCount) {
      return sectorColors[node.__dataNode.id - 1];
    }

    const parentId = getParentId(node);

    const rainbow = new Rainbow();
    rainbow.setSpectrum(sectorColors[parentId - 1], 'white');
    rainbow.setNumberRange(1, maxDataDepth + 1);
    const color = `#${rainbow.colourAt(node.__dataNode.depth)}`;

    return color;
  };

  const getTooltipContent = (d: any, node: any) => {
    return `<div class='sunburst-tooltip__container'><div>${axisXNames[node.depth - 1] ? `${axisXNames[node.depth - 1]}:` : ''
      }</div><div class='sunburst-tooltip__value'>${d.name !== 'root' ? valueFormat(d.name, node.depth - 1, widgetProperties) : ''
      }</div></div>
    <div class='sunburst-tooltip__container'><div>${axisYAggregation ? `${axisYAggregation}(${axisYName})` : axisYName
      }:</div><div class='sunburst-tooltip__value'>${valueFormat(
        d.value,
        0,
        setPropForNumberValue(widgetProperties),
        PanelType.axisY,
      )} (${d.percent || 100}%)</div></div>`;
  };

  const sunburstDiameter = Math.min(containerWidth - minLegendWidth, containerHeight) - containerPadding;

  useEffect(() => {
    if (sunburstContainer) {
      sunburstContainer.innerHTML = '';
    }

    if (containerWidth && containerHeight) {
      const chart = Sunburst();

      chart
        .data(widgetData)
        .width(sunburstDiameter)
        .height(sunburstDiameter)
        .excludeRoot(true)
        .size((node) => String(Math.abs(node.value)))
        .label((d) => `${isNeedToDisplayPercentOnSector
          ? `${valueFormat(d.percent, 0, setPropForNumberValue(widgetProperties), PanelType.axisY)}%`
          : ''}`)
        .labelOrientation('angular')
        .color(node => getPathColor(node))
        .showTooltip(() => isNeedToDisplayTooltip)
        .tooltipContent((d, node) => getTooltipContent(d, node))
        .maxLevels(5)(sunburstContainer as HTMLElement);
    }

  }, [
    sunburstContainer,
    JSON.stringify(widgetData),
    sunburstDiameter,
    isNeedToDisplayPercentOnSector,
    isNeedToDisplayTooltip,
    JSON.stringify(sectorColors),
  ]);

  const legendData = widgetData.children.map((item: any) => {
    return { label: item.name, count: item.value, percent: item.percent };
  });

  const legendOffset = 50;

  return (
    <div
      className="widget-sunburst-container screenshot-overflow"
      style={{ flexDirection: legendPosition === LegendPositions.Up ? 'column' : 'column-reverse' }}
    >
      {isNeedToDisplayLegend && [LegendPositions.Up, LegendPositions.Down].includes(legendPosition) && (
        <LegendArc
          widgetProperties={widgetProperties}
          colors={sectorColors}
          data={legendData}
          isSidePosition={false}
        />)}
      <div
        style={{
          padding: '0 20px',
          flexGrow: 1,
          width: '100%',
          minHeight: '200px',
          minWidth: `${200 + minLegendWidth}px`,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: legendPosition === LegendPositions.Left ? 'row-reverse' : 'row',
          alignItems: 'center',
        }}
        ref={containerRef}
      >
        <div id={`sunburst-chart-${widgetProps.id}`} className={`sunburst-chart sunburst-chart-${widgetProps.id}`} />
        {isNeedToDisplayLegend && [LegendPositions.Left, LegendPositions.Right].includes(legendPosition) && (
          <LegendArc
            widgetProperties={widgetProperties}
            colors={sectorColors}
            data={legendData}
            isSidePosition={true}
            style={{
              width: 'auto',
              marginLeft: legendPosition === LegendPositions.Left ? '0px' : '50px',
              marginRight: legendPosition === LegendPositions.Left ? '50px' : '0px',
              minWidth: 'auto',
              maxWidth: containerRef.current.clientWidth - legendOffset - sunburstDiameter,
              height: getLegendHeight(
                legendData.length,
                containerHeight,
                { offset: legendOffset, diameter: sunburstDiameter },
              ),
              maxHeight: containerHeight,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SunburstChart;
