import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import Dictionaries from '../../types/dictionaries';
import { apiGetSavingStrategies } from '../../services/dictionariesController';

const dictionariesInitialState: Dictionaries.State = {};

const addSavingStrategies: CaseReducer<
  Dictionaries.State,
  PayloadAction<Dictionaries.SavingStrategies[]>
> = (state, { payload: data }) => ({
  ...state,
  savingStrategies: data,
});

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: dictionariesInitialState,
  reducers: {
    addSavingStrategies,
  },
});

export const asyncActions = {
  getSavingStrategies: () => async (dispatch: Dispatch<any>) => {
    const data = await apiGetSavingStrategies();
    if (data) {
      dispatch(dictionariesSlice.actions.addSavingStrategies(data));
    }
  },
};

export const dictionariesReducer = dictionariesSlice.reducer;
export const dictionariesAction = {
  ...dictionariesSlice.actions,
  ...asyncActions,
};
