import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'src/slices/types';
import { Roles } from '../../../enums/roles';
import IconSvg from '../../common/icon-svg/icon-svg';
import './group-menu.css';
import { useRoles } from '../../../hooks/useRoles';
import { setDeleteProjectModalAction } from '../../../slices/main-page/main-page-slice';
import { CustomPopoverMenu } from '../../../uikit/PopoverMenu/popover-menu';
import {
  convertFileToForm,
  openFileUploadDialog,
} from '../../../helpers/import-export';
import { getAllGroups } from '../../../services';
import { useNotificator } from '../../common/snackbar/hooks';
import { apiImportFile } from '../../../services/import';
import { setGroupsAction } from '../../../slices/group-slice/group-slice';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface GroupMenuProps {
  permission: Roles,
  handleCloseMenu: (e: React.MouseEvent<HTMLElement>) => void;
  handleClickChange: () => void;
  handleClickDelete: () => void;
  id: number;
}

const GroupMenu = ({
  permission,
  handleCloseMenu,
  handleClickChange,
  handleClickDelete,
  id,
}: GroupMenuProps) => {
  const dispatch = useDispatch();
  const { showNotification } = useNotificator();

  const filters = useSelector((state: State) => state.mainPage.filters);

  const beforeClickAction = (event: React.MouseEvent<HTMLElement>) => {
    handleCloseMenu(event);
  };

  const uploadFile = () => {
    const defaultErrorMessage = 'Ошибка при загрузке файла';
    const showError = (error: string = defaultErrorMessage) => showNotification({
      message: error,
      variant: 'error',
    });

    try {
      openFileUploadDialog().then(fileEvent => {
        const file = (fileEvent.target.files || [])[0];
        apiImportFile(id, convertFileToForm(file))
          .then(() => {
            getAllGroups(filters)
              .then((response) => {
                dispatch(setGroupsAction(response?.data));
              });
          })
          .catch((error) => {
            showError(error.response?.data?.message);
          });
      });
    } catch {
      showError();
    }
  };

  const onClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  
    beforeClickAction(event);
    dispatch(setDeleteProjectModalAction(true));
    handleClickDelete();
    
  };

  const onClickChange = (event: React.MouseEvent<HTMLElement>) => {
    beforeClickAction(event);
    handleClickChange();
    event.stopPropagation();
  };

  const onClickUpload = (event: React.MouseEvent<HTMLElement>) => {
    beforeClickAction(event);
    event.stopPropagation();
    uploadFile();
  };

  const rolesAccess = useRoles({
    isShowCreateProjectButton: permission === Roles.Admin || permission === Roles.Developer,
    isShowEditButton: permission === Roles.Admin,
    isShowDeleteButton: permission === Roles.Admin
  });

  const settingsList = [
    {
      title: 'Редактировать',
      icon: <IconSvg svg={IconDictionary.EditFile} fill='var(--dark-grey)' />,
      onClick: onClickChange,
      isNeedDivider: false,
      disabled: !rolesAccess.isShowEditButton,
    },
    {
      title: 'Загрузить',
      icon: <IconSvg svg={IconDictionary.DownloadFile} fill='var(--dark-grey)' />,
      onClick: onClickUpload,
      isNeedDivider: true,
      disabled: false,
    },
    {
      title: 'Удалить группу',
      icon: <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />,
      onClick: onClickDelete,
      isNeedDivider: false,
      isDelete: true,
      disabled: !rolesAccess.isShowDeleteButton,
    },
  ];

  return <CustomPopoverMenu settings={settingsList} />;
};

export default GroupMenu;
