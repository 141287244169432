import block from 'bem-cn';
import React, { useState } from 'react';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import './source-field-item.css';
import CustomTooltip from '../../../../../../uikit/CustomTooltip';
import { CustomFormControl } from '../../../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../../../uikit/Checkbox';
import {
  CustomIconButton,
  IconButtonType,
} from '../../../../../../uikit/IconButton';
import { getIconByDataType } from '../../../../../widget-page/helpers';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface SourceFieldProps {
  id: string;
  name: string;
  alias: string;
  checked?: boolean;
  type?: string;
  category?: string;
  onTick: () => void;
  onRename: (name: string) => void;
  openTransformationPanel: (id: any) => () => void;
  editComplexField: (sourceFieldId: any) => () => void;
  deleteSourceField: (sourceFieldId: string) => () => void;
  joinKeyNames: string[];
  isEditableMap: boolean;
  isBrokenField?: boolean;
  hasError?: boolean;
}

const b = block('source-field');

const SourceField: React.FC<SourceFieldProps> = ({
  id,
  name,
  alias,
  type,
  checked,
  onTick,
  onRename,
  openTransformationPanel,
  category,
  editComplexField,
  joinKeyNames,
  deleteSourceField,
  isBrokenField,
  isEditableMap,
  hasError = false,
}) => {
  const isSchema = category === 'SCHEMA';
  const isCalculated = category === 'CALCULATED';
  const isTransformation = category === 'TRANSFORMATION';

  const [isEditingField, setEditingField] = useState<boolean>(false);
  const [editingName, setEditingName] = useState<string>(alias || name);
  return (
    <div className={b()} style={{ borderColor: hasError ? 'var(--red-normal)' : undefined }}>
      <CustomFormControl
        control={
          <CustomCheckbox
            name="personal"
            onChange={onTick}
            checked={checked}
            disabled={!isEditableMap}
          />
        }
        label=""
      />
      <p className={b('type-icon')}>
        <IconSvg
          svg={getIconByDataType(type)}
          fill="var(--dark-grey)"
        />
      </p>
      {isEditingField ? (
        <input
          value={editingName}
          maxLength={27}
          onInput={(e) => {
            setEditingName(e.currentTarget.value);
          }}
          className={b('rename')}
        />
      ) : (

        <p className={`${b('title')} ${isBrokenField ? b('error') : ''}`}>{alias || name}</p>
      )}
      <div className={b('icons')}>
        {joinKeyNames.includes(name) && (
          <CustomIconButton
            iconButtonProps={{
              children: <IconSvg svg={IconDictionary.Key} fill="var(--primary-color)" />,
            }}
            type={IconButtonType.primary}
          />
        )}
        {!isEditingField ? (
          <>
            {Boolean(alias) && isSchema && alias !== name && (
              <>
                <CustomTooltip
                  arrow
                  title={`Название в источнике: ${name}`}
                >
                  <div>
                    <IconSvg
                      svg={IconDictionary.InfoRounded}
                      fill="var(--dark-grey)"
                    />
                  </div>
                </CustomTooltip>
              </>
            )}
            {isEditableMap && isSchema && (
              <CustomIconButton
                iconButtonProps={{
                  children: <IconSvg svg={IconDictionary.EditFile} fill="var(--dark-grey)" />,
                  onClick: () => {
                    setEditingName(alias || name);
                    setEditingField(true);
                  },
                }}
                type={IconButtonType.secondary}
              />
            )}
            {!isSchema && (
              <CustomIconButton
                iconButtonProps={{
                  children: <IconSvg svg={IconDictionary.BasketDelete} fill="var(--dark-grey)" />,
                  onClick: deleteSourceField(id),
                }}
                type={IconButtonType.delete}
              />
            )}
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.FieldConversion} fill="var(--dark-grey)" />,
                onClick: isCalculated ? editComplexField(id) : openTransformationPanel(id),
              }}
              type={IconButtonType.secondary}
            />
          </>
        ) : (
          <>
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.RoundedCheck} fill="var(--success-color)" />,
                onClick: () => {
                  onRename(editingName);
                  setEditingField(false);
                },
              }}
              type={IconButtonType.withoutEffects}
            />
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.CancelEdit} fill="var(--dark-grey)" />,
                onClick: () => {
                  setEditingName(alias || name);
                  setEditingField(false);
                },
              }}
              type={IconButtonType.withoutEffects}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SourceField;
