import React, { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ContainerSize, useResizeObserver } from './useResizeObserver';


export const useContainerResizeObserver = (
  elementRef: React.MutableRefObject<HTMLElement>,
  debounceTime: number = 0,
) => {
  const [size, setSize] = useState<ContainerSize>({ containerWidth: 0, containerHeight: 0 });

  const callback = useCallback((rect: ContainerSize) => {
    setSize(rect);
  }, []);

  const debouncedCallbackState = useDebouncedCallback(callback, debounceTime);

  const debouncedCallback = useCallback(
    (rect: ContainerSize) => {
      debouncedCallbackState.callback(rect);
    },
    [debouncedCallbackState],
  );

  useResizeObserver(elementRef, debounceTime ? debouncedCallback : callback);

  return size;
};
