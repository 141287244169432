import React from 'react';
import { TableCell } from '@material-ui/core';
import { CustomButton } from '../../../../uikit/Button';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { Project } from '../../../../slices/types';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import LoaderMenu from '../../loader-menu/loader-menu';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { useLoaderTableSettingCell } from './useLoaderTableSettingCell';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface LoaderTableSettingCellProps {
  project: Project;
}

export const LoaderTableSettingCell = ({ project }: LoaderTableSettingCellProps) => {
  const {
    handleMenuClick,
    openMenu,
    handleCloseMenu,
    anchorEl,
    rolesAccess,
  } = useLoaderTableSettingCell({ project });

  return (
    <TableCell
      align='right'
      className='loader-table__cell loader-table__cell_settings'
    >
      <div>
        <CustomButton
          variant="text"
          size="small"
          disabled={!project.dashboardsCount}
          linkTo={`/project/${project.id}/dashboard/${project.firstDashboardId}/preview`}
          startIcon={<IconSvg
            svg={IconDictionary.OpenDashboards}
            width={24}
            height={24}
            fill="var(--primary-color)"
          />}
          colorType="dark"
        >
          Просмотр
        </CustomButton>
        <CustomIconButton
          iconButtonProps={{
            children: <IconSvg svg={IconDictionary.ProjectSettingsTableView} width={24} height={24} fill='var(--dark-grey)' />,
            onClick: handleMenuClick,
          }}
          type={IconButtonType.secondary}
        />
        <CustomPopover
          open={openMenu}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <LoaderMenu
            project={project}
            handleCloseMenu={handleCloseMenu}
            rolesAccess={rolesAccess}
          />
        </CustomPopover>
      </div>
    </TableCell>
  );
};
