import { useCallback, useMemo } from 'react';
import { FilterField, SetFilterField } from '../../../../dashboard-page/hooks';
import { getWidgetProperties } from '../properties';
import { Property } from '../../../dropdown-layout/helpers/Property';

export const useFilterSelectors = ({
  widgetProps,
  data,
  dataGraph,
  enableEvents,
  setFilterField,
  filterField,
}: {
  widgetProps: any;
  data: any;
  dataGraph: any;
  enableEvents: boolean;
  setFilterField: SetFilterField;
  filterField: FilterField;
}) => {
  const { axisXValuesFull } = useMemo(
    () => getWidgetProperties(widgetProps.properties),
    [widgetProps.properties],
  );

  const deselectBar = useCallback(() => {
    if (!setFilterField) return;

    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id, widgetProps.sourceId]);

  const selectBar = useCallback(
    (groupIndex: number) => {
      if (!enableEvents) return;
      if (filterField?.value?.includes(data[groupIndex]?.x)) {
        deselectBar();
      } else {
        if (!setFilterField || !data[groupIndex]?.x) return;

        setFilterField(
          widgetProps.id,
          new Property(axisXValuesFull[0]).getId(),
          [
            {
              operation: '=',
              value: [data[groupIndex].x],
            },
          ],
        );
      }
    },
    [
      enableEvents,
      filterField,
      data,
      deselectBar,
      setFilterField,
      widgetProps.sourceId,
      widgetProps.id,
      axisXValuesFull,
    ],
  );

  const deselectPoint = useCallback(() => {
    if (!setFilterField) return;

    setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id, widgetProps.sourceId]);

  const selectPoint = useCallback(
    (lineIndex: number, index: number) => {
      if (!enableEvents) return;

      if (filterField?.value?.includes(dataGraph[index].x)) {
        deselectPoint();
      } else {
        if (!setFilterField) return;

        setFilterField(
          widgetProps.id,
          new Property(axisXValuesFull[0]).getId(),
          [
            {
              operation: '=',
              value: [dataGraph[index].x],
            },
          ],
        );
      }
    },
    [
      axisXValuesFull,
      dataGraph,
      deselectPoint,
      enableEvents,
      filterField,
      setFilterField,
      widgetProps.id,
      widgetProps.sourceId,
    ],
  );

  const deselect = () => {
    deselectBar();
    deselectPoint();
  };

  return { selectBar, selectPoint, deselect };
};
