import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { State } from '../../../../slices/types';
import { useNotificator } from '../../snackbar/hooks';
import {
  setDataCacheClearedAction,
  setDataCacheLoadingAction,
} from '../../../../slices/main-page/main-page-slice';
import { apiRunCache } from '../../../../services/widgetController';
import { apiGetMapConnection } from '../../../../services/loadersController';
import { mapConnectionAction } from '../../../../slices/map-connection/map-connection';
import { ClearCacheButtonProps } from './index';
import { isLoaderPage } from '../../../widget-page/charts/helpers';

export const useCacheClearButton = ({ loaderId }: ClearCacheButtonProps) => {
  const dispatch = useDispatch();
  const isCacheReloading =
    useSelector((state: State) => state.mainPage?.isDataCacheLoading) || false;

  const { showNotification } = useNotificator();
  const location = useLocation().pathname;

  const showStatus = useCallback((loaderStatus: string) => {
    switch(loaderStatus) {
      case 'DONE':
        showNotification({ message: 'Данные обновлены', variant: 'success' });
        break;

      case 'PARTIALLY_LOADED':
        showNotification({ message: 'Данные обновлены частично', variant: 'warning' });
        break;

      case 'ERROR':
      case undefined:
        showNotification({ message: 'Ошибка при загрузке', variant: 'error' });
        break;

      default:
        return null;
    }
  }, [showNotification]);

  const handleClickUpdateCache = useCallback(() => {
    dispatch(setDataCacheLoadingAction(true));
    dispatch(setDataCacheClearedAction(false));
    loaderId &&
    apiRunCache(loaderId)
      .then((data) => {
        dispatch(setDataCacheClearedAction(true));
        dispatch(setDataCacheLoadingAction(false));

        if (!isLoaderPage()) {
          showStatus(data);
        } else {
          apiGetMapConnection(loaderId)
            .then((data) => {
              if (data) {
                dispatch(mapConnectionAction.addConnection({ loaderId, data }));
                showStatus(data?.status.name);
              }
            });
        }
      })
      .catch(() => {
        showNotification({ message: 'Ошибка при обновлении кэша', variant: 'error' });
        dispatch(setDataCacheLoadingAction(false));
        dispatch(setDataCacheClearedAction(false));
      });
  }, [dispatch, loaderId, location, showNotification, showStatus]);

  const isLoading = isCacheReloading;
  
  return {
    isLoading,
    handleClickUpdateCache,
  };
};
