import React from 'react';
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  item: {
    minHeight: 'calc(50px * var(--scale-coefficient))',
    paddingLeft: 'calc(5px * var(--scale-coefficient)) !important',
    paddingRight: 'calc(90px * var(--scale-coefficient)) !important',
    padding: '0 !important',
    wordBreak: 'break-all',
  },
  itemIcon: {
    minWidth: 'auto',
  },
  checkbox: {
    padding: 0,
    marginLeft: 0,
  },
  content: {
    paddingLeft: 'calc(10px * var(--scale-coefficient))',
  },
  action: {
    right: 0,
  },
  iconControl: {
    padding: 0,
    marginRight: 'calc(6px * var(--scale-coefficient))',
  },
  dropDownIconOn: {
    transform: 'rotate(180deg)',
    transition: '0.3s',
    color: 'var(--primary-color)',
  },
  activeExcel: {
    backgroundColor: '#EDF1F8',
  },
});

interface Props {
  item: string;
  id: number;
  previewName: string | null;
  fileChangeName: string | null;
  checkedFiles: string[];
  onPreviewClick: (name: string) => () => void;
  onFileChangeClick: (name: string) => () => void;
  onFileDeleteClick: (id: number) => () => void;
  handleToggle: (file: string) => () => void;
}

const SourceItem: React.FC<Props> = ({
  item,
  id,
  previewName,
  fileChangeName,
  onPreviewClick,
  onFileChangeClick,
  checkedFiles,
  handleToggle,
  onFileDeleteClick,
}) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.item} button onClick={handleToggle(item)}>
      <ListItemIcon className={classes.itemIcon}>
        <Checkbox
          edge="start"
          checked={checkedFiles.includes(item)}
          tabIndex={-1}
          disableRipple
          color="primary"
          className={classes.checkbox}
        />
      </ListItemIcon>
      <ListItemText primary={item} className={classes.content} />
      <ListItemSecondaryAction className={classes.action}>
        <IconButton
          className={classes.iconControl}
          onClick={onPreviewClick(item)}
          edge="end"
          aria-label="preview"
        >
          <IconSvg svg={IconDictionary.OpenFile} fill={item === previewName ? 'var(--primary-color)' : 'var(--dark-grey)'} />
        </IconButton>
        <IconButton
          className={classes.iconControl}
          onClick={onFileChangeClick(item)}
          edge="end"
          aria-label="change-source"
        >
          <IconSvg svg={IconDictionary.Tune} fill={item === fileChangeName ? 'var(--primary-color)' : 'var(--dark-grey)'} />
        </IconButton>
        <IconButton
          className={classes.iconControl}
          onClick={onFileDeleteClick(id)}
          edge="end"
          aria-label="change-source"
        >
          <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default SourceItem;
