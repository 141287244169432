import { ConnectionFieldType } from '../../../enums/connection-field-type';

export const fieldName: Readonly<Record<ConnectionFieldType, string>> = {
  [ConnectionFieldType.ConnectionName]: 'connectionName',
  [ConnectionFieldType.Host]: 'host',
  [ConnectionFieldType.Port]: 'port',
  [ConnectionFieldType.Encoding]: 'ftpEncoding',
  [ConnectionFieldType.Login]: 'login',
  [ConnectionFieldType.Password]: 'password',
  [ConnectionFieldType.Namespace]: 'namespace', // ???
  [ConnectionFieldType.NamespaceUrl]: 'namespaceUrl', // ???
  [ConnectionFieldType.ServiceName]: 'serviceName',
  [ConnectionFieldType.SoapRequest]: 'soapRequest', // ??
  [ConnectionFieldType.Url]: 'url',
  [ConnectionFieldType.ConnectionConfig]: 'connectionConfig',
};

export const charsets = [
  'UTF-8',
  'WINDOWS-1251',
  'KOI8-R',
  'ISO-8859-5',
  'IBM866',
];
