import { formatDate } from 'src/components/widget-page/charts/formatting-helpers';
import {
  BoundFilterGroupProps,
  Filter,
  FilterForPanel,
  FilterGroupFilterProperty,
  FilterGroupProps,
} from '../../components/dashboard-page/hooks';
import {
  DashboardPropertyType,
  FilterProperty,
  SelectDateTypes,
} from '../../enums/dashboard-properties';
import { DashboardProperty, WidgetAPI } from '../../slices/types';
import { Property, PropertyData } from '../../components/widget-page/dropdown-layout/helpers/Property';
import {
  getAxisValues,
  getParsedAxisValues,
  getSimplifiedType,
} from '../../components/widget-page/dropdown-layout/helpers/helpers';
import { getStorageName } from '../common-helpers';
import { getFormattedDashboardFilterValue } from './dashboard-widgets';
import { PanelType, WidgetPropertyType } from '../../enums/widget-type';
import { WidgetSimplifiedDataType } from '../../enums/data-type';
import { checkIsDDFilterGroupAppliedToWidget } from './index';

export const getGroupIndexesWhereWidgetIsMaster = (
  widgetId: number,
  groups: BoundFilterGroupProps[],
  isAutoBound?: boolean,
) => {
  const groupIndexes: number[] = [];

  groups.forEach((group, groupIndex) => {
    const isGroupWithCurrentWidget = group.participantFilterWidgets.find(
      (participant) => participant.widgetId === widgetId,
    );
    if (isGroupWithCurrentWidget) {
      for (const participant of group.participantFilterWidgets) {
        const isActiveGroup =
          participant.widgetId === widgetId && participant.isActive;
        const isGroupWhereWidgetIsMaster = isAutoBound
          ? isActiveGroup
          : isActiveGroup && participant.isMaster;

        isGroupWhereWidgetIsMaster && groupIndexes.push(groupIndex);
      }
    }
  });

  return groupIndexes;
};

export const getFilterSettingValue = (
  filterGroup: FilterGroupProps,
  settingName: FilterProperty,
): any => {
  return (
    filterGroup.filterProperties.find(
      (property) => property.name === settingName,
    )?.value || null
  );
};

export const isPeriodDateFilter = (filterGroup: FilterGroupProps) => {
  const selectDateType = getFilterSettingValue(
    filterGroup,
    FilterProperty.selectDateType,
  );
  const displayAsPeriod = getFilterSettingValue(
    filterGroup,
    FilterProperty.displayAsPeriod,
  );
  const displayAsSingleDate = getFilterSettingValue(
    filterGroup,
    FilterProperty.displayAsSingleDate,
  );

  const activeDisplayOptionsCount = displayAsPeriod + displayAsSingleDate;

  return (
    (selectDateType === SelectDateTypes.period ||
      activeDisplayOptionsCount < 2) &&
    displayAsPeriod
  );
};

export const getFilterSettingObject = (
  filterGroup: FilterGroupProps,
  settingName: FilterProperty,
): any => {
  return filterGroup.filterProperties.find(
    (property) => property.name === settingName,
  );
};

export const getFilterSettingValueFromDashboardProperties = (
  dashboardProperties: DashboardProperty[],
  settingName: FilterProperty,
  filtersGroupId: number,
) => {
  const filters = dashboardProperties.find(
    (property) => property.name === DashboardPropertyType.filters,
  );

  if (!filters) return null;

  const parsedFilters: FilterGroupProps[] = JSON.parse(filters.value as string);
  const groupIndex = parsedFilters.findIndex(
    (group) => group.id === filtersGroupId,
  );

  if (groupIndex < 0) return null;

  return getFilterSettingValue(parsedFilters[groupIndex], settingName);
};

export const getFilterGroupDisplayedName = (filterGroup: FilterGroupProps) => {
  return filterGroup.alias || filterGroup.name;
};

export const getWidgetFiltersForPanel = (
  widgetFilters: PropertyData[],
  widgetId: number,
  dashboardFiltersGroups: FilterGroupProps[],
  isDrillDown: boolean,
): FilterForPanel[] => {
  const filters: FilterForPanel[] = [];
  widgetFilters.forEach((widgetFilter) => {
    const filterValue = !isDrillDown ? getFilterValueInGroup(
      dashboardFiltersGroups,
      widgetFilter.name,
      widgetFilter.type,
      widgetId,
    ) : Array.isArray(widgetFilter.filter) ? widgetFilter.filter : [widgetFilter.filter];

    if (filterValue?.length) {
      const filterProperties: FilterGroupFilterProperty[] = [];

      const isActualGroup = isActualGroupWithWidgetFilter(
        dashboardFiltersGroups,
        widgetFilter.name,
        widgetFilter.type,
        widgetId,
      );

      if (
        getSimplifiedType(widgetFilter.type) === WidgetSimplifiedDataType.DATE
      ) {
        filterProperties.push({
          name: FilterProperty.function,
          value: widgetFilter.function,
        });
        const isPeriod = widgetFilter.function === '' || widgetFilter.function.includes('DAY');
        if (isPeriod) {
          filterProperties.push({
            name: FilterProperty.selectDateType,
            value: SelectDateTypes.period,
          });
          filterProperties.push({
            name: FilterProperty.displayAsPeriod,
            value: true,
          });
        } else {
          filterProperties.push({
            name: FilterProperty.selectDateType,
            value: SelectDateTypes.date,
          });
          filterProperties.push({
            name: FilterProperty.displayAsSingleDate,
            value: true,
          });
        }
      }

      const filterField = {
        actual: true,
        name: widgetFilter.name,
        type: widgetFilter.type,
        value: filterValue,
        filterProperties,
      } as FilterGroupProps;

      if (!isActualGroup || isDrillDown) {
        filters.push({
          name: widgetFilter.name,
          values: [
            WidgetSimplifiedDataType.NUMBER,
            WidgetSimplifiedDataType.DATE,
          ].includes(getSimplifiedType(widgetFilter.type))
            ? [getFormattedDashboardFilterValue(filterField)]
            : filterValue.map((item) => typeof item === 'string' ? item : item.value.toString()),
          simplifiedType: getSimplifiedType(widgetFilter.type),
        });
      }
    }
  });

  return filters;
};

export const getOptionsForSelectedSourceField = (
  widgetsCache: Record<string, WidgetAPI>,
  group: BoundFilterGroupProps,
) => {
  const masterWidgetId = group.participantFilterWidgets.find(
    (widget) => widget.isMaster && widget.isActive,
  )?.widgetId;


  const axisValuesList = Object.keys(PanelType).map((axisType) => {
    return getParsedAxisValues(
      axisType as PanelType,
      widgetsCache[String(masterWidgetId)]?.data.properties,
    );
  });

  const selectedValue = axisValuesList.reduce((acc, axisValue) => {
    if (acc) return acc;
    return axisValue.find(
      (type: any) =>
        (type.id || type.widgetFieldId) ===
        group.selectedSourceFieldId,
    );
  }, null);

  const dateDisplayFormat: string = getAxisValues(
    WidgetPropertyType.dateDisplayFormat,
    widgetsCache[String(masterWidgetId)]?.data.properties,
  );

  const dateFormatProperties = {
    function: selectedValue ? new Property(selectedValue).getFunction() || '' : '',
    dataType: selectedValue?.type || '',
    dateDisplayFormat: dateDisplayFormat === '[]' ? 'dd.mm.yyyy' : dateDisplayFormat,
  };

  return {
    name: getStorageName(selectedValue?.storage) || selectedValue?.name || '',
    dateFormatProperties,
    type: selectedValue?.type,
  };

};

export const getBoundFiltersForPanel = (
  boundFiltersGroups: BoundFilterGroupProps[],
  widgetId: number,
  widgetsCache: Record<string, WidgetAPI>,
): FilterForPanel[] => {
  return boundFiltersGroups
    .filter(
      (group) =>
        group.isActive &&
        group.filters?.length &&
        group.filters[0].value &&
        group.participantFilterWidgets.find(
          (item) => item.widgetId === widgetId && item.isActive,
        ),
    )
    .map((group) => {
      const { name, dateFormatProperties, type } = getOptionsForSelectedSourceField(widgetsCache, group);

      return {
        name,
        values: [getFilters(group, dateFormatProperties)],
        simplifiedType: type
          ? getSimplifiedType(type)
          : WidgetSimplifiedDataType.EMPTY,
      };
    }
    );
};

const getFilters = (group: BoundFilterGroupProps, dateFormatProperties: any) => {
  if (group.filters?.length && group.filters[0].value) {
    return group.filters[0].operation === 'NOT IN'
      ? 'Прочее'
      : getSimplifiedType(dateFormatProperties.dataType) === WidgetSimplifiedDataType.DATE ? formatDate(
        group.filters[0].value[0], dateFormatProperties) : group.filters[0].value[0];
  }
  return '';
};

export const getDdFiltersForPanel = (
  ddFiltersGroups: BoundFilterGroupProps[],
  widgetId: number,
  widgetsCache: Record<string, WidgetAPI>,
): FilterForPanel[] => {
  return ddFiltersGroups
    .filter((group) => checkIsDDFilterGroupAppliedToWidget(group, widgetId))
    .map((group) => {
      const { name, dateFormatProperties } = getOptionsForSelectedSourceField(widgetsCache, group);
      return {
        name,
        values: [getFilters(group, dateFormatProperties)],
        simplifiedType: group.type
          ? getSimplifiedType(group.type)
          : WidgetSimplifiedDataType.EMPTY,
      };
    });
};

export const getDashboardFiltersForPanel = (
  dashboardFiltersGroups: FilterGroupProps[],
  widgetId: number,
): FilterForPanel[] => {
  return dashboardFiltersGroups
    .filter(
      (group) =>
        group.actual &&
        group.value?.length &&
        group.widgetFilters.find(
          (item) => item.widgetId === widgetId && item.actual,
        ) &&
        group.filterProperties.find(
          (item) => item.name === FilterProperty.isVisible,
        )?.value,
    )
    .map((group) => ({
      name: group.name,
      values: group.value.map((v, index) => getFormattedDashboardFilterValue(group, index)),
      simplifiedType: group.type
        ? getSimplifiedType(group.type)
        : WidgetSimplifiedDataType.EMPTY,
    }));
};

export const isActualGroupWithWidgetFilter = (
  dashboardFiltersGroups: FilterGroupProps[],
  name: string,
  type: string,
  widgetId: number,
) => {
  return dashboardFiltersGroups.find((filterGroup) => {
    if (getSimplifiedType(filterGroup.type) === getSimplifiedType(type)) {
      const widgetFilterInGroup = filterGroup.widgetFilters.find(
        (filter) => filter.name === name && filter.widgetId === widgetId,
      );

      if (widgetFilterInGroup) {
        return filterGroup.actual && widgetFilterInGroup.actual;
      }
    }

    return false;
  });
};

export const getFilterValueInGroup = (
  dashboardFiltersGroups: FilterGroupProps[],
  name: string,
  type: string,
  widgetId: number,
): Filter[] | null => {
  let value = null;
  dashboardFiltersGroups.forEach((filterGroup) => {
    if (getSimplifiedType(filterGroup.type) === getSimplifiedType(type)) {
      const widgetFilterInGroup = filterGroup.widgetFilters.find(
        (filter) => filter.name === name && filter.widgetId === widgetId,
      );

      if (widgetFilterInGroup) {
        value =
          filterGroup.actual && widgetFilterInGroup.actual
            ? filterGroup.value
            : widgetFilterInGroup.value;
      }
    }
  });

  return value;
};
