export enum SourceType {
  FTP = 'FTP',
  MSSQL = 'MSSQL',
  MySQL = 'MYSQL',
  PostgreSQL = 'POSTGRESQL',
  Oracle = 'ORACLE',
  RedData = 'REDDATA',
  Firebird = 'FIREBIRD',
  MariaDB = 'MARIADB',
  SOAP = 'SOAP',
  OData = 'ODATA',
  EMISS = 'EMISS',
  CI = 'CI',
  Kafka = 'KAFKA',
  Redis = 'REDIS',
  Vertica = 'VERTICA',
  ArangoDB = 'ARANGODB',
  ClickHouse = 'CLICKHOUSE',
  ODBC = 'ODBC',
  API = 'API',
  LOCAL = 'LOCAL',
  HDFS = 'HDFS',
  IMPALA = 'IMPALA',
  HIVE = 'HIVE',
  SAP_HANA = 'SAP_HANA',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS'
}
