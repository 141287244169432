import { useCallback, useMemo } from 'react';
import { compact } from 'lodash';
import { useDispatch } from 'react-redux';
import { DashboardProperty } from '../../../../slices/types';
import { getParsedAxisValues } from '../../../widget-page/dropdown-layout/helpers/helpers';
import { PanelType } from '../../../../enums/widget-type';
import { useDashboardBoundFilters } from '../../hooks/useDashboardBoundFilters';
import { useDashboardManualFilters } from '../../hooks/useDashboardManualFilters';
import { getDrillDownGroupsType } from '../../../../helpers/dashboard-page/widget';
import { useFiltersForPanel } from '../../hooks/useFiltersForPanel';
import { setCurrentWidgetIdFullScreenAction } from '../../../../slices/dashboard/dashboard';
import { SetFilterField, SetMultipleFilterFields } from '../../hooks';
import { FiltersSettingsTypes } from '../../settings';
import { isNeedSetDDSetFilterField } from '../../../../helpers/dashboard-page/dashboard-widgets';

interface useDashboardFiltersLogicProps {
  overriddenProperties: any;
  isDrillDown: boolean;
  dashboardParams: DashboardProperty[];
  widgetProps: any;
  widgetId: number;
  parentId?: number;
  drillDownDepth: number;
  increaseDrilldownDepth: () => void;
  decreaseDrilldownDepth: (isClose?: boolean) => void;
}

export const useDashboardFiltersLogic = ({
  overriddenProperties,
  isDrillDown,
  dashboardParams,
  widgetProps,
  widgetId,
  parentId,
  drillDownDepth,
  increaseDrilldownDepth,
  decreaseDrilldownDepth,
}: useDashboardFiltersLogicProps) => {
  const dispatch = useDispatch();

  const {
    setFilterField: setBoundFilterField,
    getGroupForWidget: getBoundGroupForWidget,
    getParticipantsInGroupsForWidget,
    getBoundGroupsForWidget,
    values: boundValues,
    setMultipleFilterFields: setBoundMultipleFilterFields,
  } = useDashboardBoundFilters();

  const {
    setFilterField: setDrilldownFilterField,
    getGroupWhereWidgetIsMaster,
    getMasterParticipantForWidget,
    getConnectedParticipantForWidget,
    isMasterWidget,
    drilldownValues,
    setMultipleFilterFields: setDrilldownMultipleFilterFields,
    resetAutoMultipleDDFilters,
  } = useDashboardManualFilters(getDrillDownGroupsType(dashboardParams));

  const { filtersForPanel } = useFiltersForPanel(
    boundValues,
    drilldownValues,
    widgetId,
    getParsedAxisValues(PanelType.axisFilter, overriddenProperties),
    isDrillDown,
  );

  const widgetsBoundFilterGroup = getBoundGroupForWidget(widgetProps || {});
  const boundParticipantWidgets = getParticipantsInGroupsForWidget(
    widgetProps || {},
  );
  const widgetBoundFilterGroups = getBoundGroupsForWidget(widgetProps || {});

  const widgetDrillDownFilterGroup = getGroupWhereWidgetIsMaster(
    widgetProps || {},
  );
  const masterParticipantWidget = getMasterParticipantForWidget(
    widgetProps || {},
  );

  const isActiveBoundFilter =
    (boundParticipantWidgets?.find((item) => item.isActive) &&
      Boolean(
        compact(widgetsBoundFilterGroup.map((group) => group.isActive)).length,
      )) ||
    Boolean(widgetBoundFilterGroups?.length);

  // берем первый попавшийся элемент так как в один момент времени для одного виджета
  // во всех группах (если их несколько) может быть указан только один и тот же filters и selectedSourceFieldId
  const boundFilterGroup = useMemo(
    () =>
      widgetsBoundFilterGroup.find((item) =>
        Boolean(item.selectedSourceFieldId),
      ),
    [widgetsBoundFilterGroup],
  );

  const boundFilterField =
    isActiveBoundFilter &&
    boundFilterGroup?.participantFilterWidgets?.find(
      (item) => item.widgetId === widgetProps.id && item.isMaster,
    ) &&
    boundFilterGroup?.filters?.length
      ? {
          value: boundFilterGroup?.filters?.[0].value,
          operation: boundFilterGroup?.filters?.[0].operation,
          // нужен для виджета "карта", для работы со слоями
          selectedFieldId: compact(
            widgetsBoundFilterGroup.map((item) => item.selectedSourceFieldId),
          ),
        }
      : { value: null };

  const connectedWidget = getConnectedParticipantForWidget(widgetProps || {});

  const isActiveDrilldownFilter = Boolean(
    masterParticipantWidget && connectedWidget?.widgetId,
  );

  const drilldownFilterField = isActiveDrilldownFilter
    ? widgetDrillDownFilterGroup?.filters?.[0] || { value: null }
    : { value: null };

  const closeDrillDown = () => {
    if (parentId) {
      resetAutoMultipleDDFilters();
      decreaseDrilldownDepth(true);
    }
  };

  const handleClickBackDD = () => {
    if (parentId) {
      dispatch(setCurrentWidgetIdFullScreenAction(null));
      if (drillDownDepth - 1 === 0) {
        resetAutoMultipleDDFilters();
      } else {
        resetAutoMultipleDDFilters(parentId);
      }
      decreaseDrilldownDepth();
    }
  };

  const openDrilldown = useCallback<SetFilterField>(
    (sourceId, widgetId, data, mapData) => {
      const callback = () => data.length && increaseDrilldownDepth();
      setDrilldownFilterField(sourceId, widgetId, data, mapData, callback);
    },
    [increaseDrilldownDepth, setDrilldownFilterField],
  );

  const openDrilldownMultipleFilter = useCallback<SetMultipleFilterFields>(
    (widgetId, sourceIds, data) => {
      const callback = () => data.length && increaseDrilldownDepth();
      setDrilldownMultipleFilterFields(widgetId, sourceIds, data, callback);
    },
    [increaseDrilldownDepth, setDrilldownMultipleFilterFields],
  );

  const getFilters = (type: string): any => {
    const registry: any = {
      [FiltersSettingsTypes.DRILLDOWN]: {
        setFilterField: isNeedSetDDSetFilterField(widgetProps.type)
          ? openDrilldown
          : () => undefined,
        filterField: drilldownFilterField,
        isActiveFilter: isActiveDrilldownFilter,
        setMultipleFilterFields: openDrilldownMultipleFilter,
      },
      [FiltersSettingsTypes.CHAIN]: {
        setFilterField: setBoundFilterField,
        filterField: boundFilterField,
        isActiveFilter: isActiveBoundFilter,
        setMultipleFilterFields: setBoundMultipleFilterFields,
      },
    };

    return registry[type];
  };

  const isParentForOpenedDrilldown = useMemo(
    () => drilldownFilterField.value !== null,
    [drilldownFilterField],
  );

  return {
    isParentForOpenedDrilldown,
    closeDrillDown,
    connectedWidget,
    drilldownFilterField,
    getFilters,
    isActiveDrilldownFilter,
    isMasterWidget,
    handleClickBackDD,
    filtersForPanel,
  };
};
