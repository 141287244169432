import { Checkbox, CheckboxProps, styled } from '@material-ui/core';
import React from 'react';

const StyledCheckbox = styled(Checkbox)(
  ({ withoutTopBottomPadding }: { withoutTopBottomPadding: boolean }) => ({
    paddingTop: withoutTopBottomPadding
      ? 0
      : 'calc(9px * var(--scale-coefficient))',
    paddingBottom: withoutTopBottomPadding
      ? 0
      : 'calc(9px * var(--scale-coefficient))',
    paddingRight: 'calc(9px * var(--scale-coefficient))',
    paddingLeft: 0,
    backgroundColor: 'inherit !important',
    '&[class*=Mui-checked]': {
      color: 'var(--primary-color)',
    }
  }),
);

interface CustomCheckboxProps extends CheckboxProps {
  withoutTopBottomPadding?: boolean;
}

export const CustomCheckbox = ({
  withoutTopBottomPadding = false,
  ...props
}: CustomCheckboxProps) => {
  return (
    <StyledCheckbox
      disableRipple
      color="primary"
      {...props}
      size="medium"
      withoutTopBottomPadding={withoutTopBottomPadding}
    />
  );
};
