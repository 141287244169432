import React, { FC } from 'react';
import Slide from '@material-ui/core/Slide';
import { SnackbarProvider } from 'notistack';
import { TransitionProps } from '@material-ui/core/transitions';
import { Action } from './action';

export interface NotificationProviderProps {
  children: React.ReactNode
}

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={5}
      preventDuplicate
      autoHideDuration={5000}
      action={(key) =>  <Action id={key} />}
      TransitionComponent={Slide as React.ComponentType<TransitionProps>}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className="notistack-snackbar"
    >
      { children }
    </SnackbarProvider>
  );
};
