import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import imageScheme from '../assets/images/bg-scheme-opacity-0.3.png';

const defaultStyle: CSSProperties = {
  boxSizing: 'border-box',
  display: 'inline-flex',
  alignItems: 'center',
  alignSelf: 'center',
  height: 'calc(46px * var(--scale-coefficient))',
  borderRadius: '4px',
  fontWeight: 700,
  fontSize: 'calc(14px * var(--scale-coefficient))',
  lineHeight: 'calc(22px * var(--scale-coefficient))',
  padding: '0 calc(15px * var(--scale-coefficient))',
  textTransform: 'none',
};

const small: CSSProperties = {
  height: 'calc(38px * var(--scale-coefficient))',
  fontSize: 'calc(12px * var(--scale-coefficient))',
};

const buttonDefault: CSSProperties | Record<string, CSSProperties> = {
  ...defaultStyle,
  border: '1px solid var(--primary-color)',
  boxShadow: '0px 4px 10px rgba(58, 130, 228, 0.12)',
  color: 'var(--title-color)',
  backgroundColor: 'var(--white)',

  '&:hover': {
    backgroundColor: 'var(--white)',
    borderColor: 'var(--dark-cyan)',
  },

  '&:active': {
    borderColor: 'var(--dark-cyan)',
    backgroundColor: 'var(--dark-cyan)',
  },

  '&[disabled]': {
    borderColor: 'var(--primary-color)',
    color: 'var(--title-color)',
  },
};

const buttonPrimary: CSSProperties | Record<string, CSSProperties> = {
  ...defaultStyle,
  backgroundColor: 'var(--primary-color);',
  boxShadow:
    '0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06);',
  color: 'var(--white)',

  '&:hover': {
    backgroundColor: 'var(--primary-hover-color)',
  },

  '&:active': {
    backgroundColor: 'var(--primary-color)',
  },

  '&[disabled]': {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--white)',
    opacity: '0.5',
  },
};

const buttonScheme: CSSProperties | Record<string, CSSProperties> = {
  ...buttonDefault,
  border: '1px solid var(--dark-cyan)',
  backgroundImage: `url(${imageScheme})`,
  backgroundPosition: 'center',
  backgroundSize: '50%',
  backgroundColor: '#EDF1F8',
  textTransform: 'none',
};

const buttonSecondary: CSSProperties | Record<string, CSSProperties> = {
  ...buttonDefault,
  border: '1px solid var(--dark-cyan)',
  backgroundColor: 'var(--dark-cyan)',
  textTransform: 'none',
};

const buttonAcute: CSSProperties | Record<string, CSSProperties> = {
  ...defaultStyle,
  borderRadius: 0,
  color: 'var(--title-color)',
  backgroundColor: 'var(--white)',
  textTransform: 'capitalize',

  '&:hover': {
    backgroundColor: 'var(--white)',
    borderColor: 'var(--dark-cyan)',
  },

  '&:active': {
    borderColor: 'var(--dark-cyan)',
    backgroundColor: 'var(--dark-cyan)',
  },

  '&[disabled]': {
    borderColor: 'var(--primary-color)',
    color: 'var(--title-color)',
  },
};

const buttonNonBorder: CSSProperties | Record<string, CSSProperties> = {
  ...buttonDefault,
  border: 'none',
  backgroundColor: 'transparent',
  textTransform: 'none',
  padding: 0,
  boxShadow: 'none',
};

const buttonLink: CSSProperties | Record<string, CSSProperties> = {
  ...defaultStyle,
  border: 'none',
  backgroundColor: 'transparent',
  textTransform: 'none',
  padding: 0,
  boxShadow: 'none',
  color: 'var(--primary-color)',

  '&:hover': {
    backgroundColor: 'transparent',
    color: '#2d64b3',
  },

  '&:active': {
    backgroundColor: 'transparent',
  },

  '&[disabled]': {
    color: 'var(--title-color)',
  },
};

const buttonLinkDottedBlue: CSSProperties | Record<string, CSSProperties> = {
  ...defaultStyle,
  ...buttonLink,
  color: 'var(--primary-color)',
  fontWeight: 500,
  fontFamily: 'Roboto',

  '&:hover': {
    backgroundColor: 'transparent',
    color: '#2d64b3',
  },

  '&:active': {
    backgroundColor: 'transparent',
  },

  '&[disabled]': {
    color: 'var(--title-color)',
  },

  '& .SVGInline': {
    paddingTop: 4,
  },

  '& .button-text': {
    borderBottom: '1px dotted var(--primary-color)',
  },
};

const buttonSquare: CSSProperties | Record<string, CSSProperties> = {
  ...buttonDefault,
  width: '46px',
  border: '1px solid var(--dark-cyan)',
  borderRadius: '4px',
  bottom: 0,
};

const buttonSquareBlue: CSSProperties | Record<string, CSSProperties> = {
  ...buttonSquare,
  border: '1px solid var(--white)',
  backgroundColor: 'var(--primary-color)',
  '&:hover': {
    backgroundColor: 'var(--primary-color)',
  },
};

const buttonSquareRed: CSSProperties | Record<string, CSSProperties> = {
  ...buttonSquare,
  border: '1px solid var(--white)',
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: 'red',
  },
};

const buttonActionLine: CSSProperties | Record<string, CSSProperties> = {
  ...defaultStyle,
  border: '1px solid var(--dark-cyan)',
  color: 'var(--title-color)',
  minHeight: '46px',
  '&:hover': {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    backgroundColor: 'transparent'
  },
  '&:active': {
    backgroundColor: 'var(--dark-cyan)'
  }
};

export const useClassButton = (style: CSSProperties = {}) =>
  makeStyles({
    default: {
      ...buttonDefault,
      ...style,
    },

    defaultSmall: {
      ...buttonDefault,
      ...small,
      ...style,
    },

    primary: {
      ...buttonPrimary,
      ...style,
    },

    primarySmall: {
      ...buttonPrimary,
      ...small,
      ...style,
    },

    scheme: {
      ...buttonScheme,
      ...style,
    },

    schemeSmall: {
      ...buttonScheme,
      ...small,
      ...style,
    },

    secondary: {
      ...buttonSecondary,
      ...style,
    },

    secondarySmall: {
      ...buttonSecondary,
      ...small,
      ...style,
    },

    acute: {
      ...buttonAcute,
      ...style,
    },

    acuteSmall: {
      ...buttonAcute,
      ...small,
      ...style,
    },
    nonBorder: {
      ...buttonNonBorder,
      ...style,
    },

    nonBorderSmall: {
      ...buttonNonBorder,
      ...small,
      ...style,
    },

    link: {
      ...buttonLink,
      ...style,
    },

    linkDotted: {
      ...buttonLinkDottedBlue,
      ...style,
    },

    square: {
      ...buttonSquare,
      ...style,
    },

    squareBlue: {
      ...buttonSquareBlue,
      ...style,
    },

    squareRed: {
      ...buttonSquareRed,
      ...style,
    },

    actionLine: {
      ...buttonActionLine,
      ...style
    }
  })();
