import React, { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setDashboardPropAction } from '../../../../slices/dashboard/dashboard';
import {
  DashboardSettingsDictionaryItemConfig,
  useDashboardSettingsDictionary,
} from '../../../../dictionaries/dashboard-settings';
import { VisualType } from '../../../../enums/visual-types';
import { useDashboardPropertyValue } from '../../hooks/useDashboardPropertyValue';
import { CustomFormControl } from '../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../uikit/Checkbox';

export enum FiltersSettingsTypes {
  MAIN = 'MAIN',
  CHAIN = 'CHAIN',
  DRILLDOWN = 'DRILLDOWN',
}

export const SettingField = ({
  config,
}: {
  config: DashboardSettingsDictionaryItemConfig;
}) => {
  const dispatch = useDispatch();
  const currentValue = useDashboardPropertyValue<any>(config.name);

  const { afterChangeSetting } = useDashboardSettingsDictionary();

  const onChangeCheckbox = useCallback(
    (e: ChangeEvent<{}>, checked: boolean) => {
      dispatch(
        setDashboardPropAction({
          name: config.name,
          value: checked.toString(),
        }),
      );

      afterChangeSetting(config.name, checked);
    },
    [],
  );

  switch (config.visualType) {
    case VisualType.CHECKBOX: {
      return (
        <CustomFormControl
          control={<CustomCheckbox defaultChecked={true} />}
          label={config.description}
          onChange={onChangeCheckbox}
          checked={currentValue == 'true'}
        />
      );
    }

    default: {
      return null;
    }
  }
};
