import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { Provider as ReduxProvider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import CommonLoader from './components/common/common-loader/common-loader';
import { ResizeObserver } from './providers/ResizeObserver';
import AuthService from './services/auth';

import store from './store';
import './index.css';
import App from './App';
import { NotificationProvider } from './components/common/snackbar/provider';
import { UpdateLoaderStatusProvider } from './providers/UpdateLoaderStatusProvider';
import { AppDataProvider } from './providers/AppDataProvider';
import { DashboardChangesObserver } from './providers/DashboardChangesObserver';
import { RootElement } from './types/root';
import { DATE_LOCAL } from './utils/consts';
import { getRootElement } from './utils/root-helper';


moment.locale(DATE_LOCAL);
moment.tz.setDefault('Europe/Moscow');


declare const module: any;


const rootEl: RootElement | null = getRootElement();

if (rootEl) {

  // отладочная информация
  rootEl.process_env = JSON.parse(JSON.stringify(process.env));
  rootEl.redux_store = store;

  const render = (Component: any) => {
    return ReactDOM.render(
      <React.StrictMode>
        <ReduxProvider store={store}>
          <StylesProvider injectFirst>
            <UpdateLoaderStatusProvider>
              <NotificationProvider>
                <DashboardChangesObserver>
                  <AppDataProvider>
                    <ResizeObserver>
                      <Component />
                    </ResizeObserver>
                  </AppDataProvider>
                </DashboardChangesObserver>
              </NotificationProvider>
            </UpdateLoaderStatusProvider>
          </StylesProvider>
        </ReduxProvider>
      </React.StrictMode>,
      rootEl,
    );
  };

  ReactDOM.render(<CommonLoader />, document.getElementById('root-loader'));

  AuthService.init(() => render(App));

  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default;
      render(NextApp);
    });
  }
} else {
  throw new Error('There is no root element');
}

