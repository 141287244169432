export const LINE_HEIGHT = 1.43;
const MIN_FONT_SIZE = 14;
const MIN_FONT_DYNAMICS_SIZE = 12;
const SPACE_LENGTH = 1;
const DYNAMICS_ICON_LENGTH = 4;
const FONT_SIZE_MODIFIER = 1.7;
const LEGEND_MARGIN = 12;

export enum DynamicPosition {
  Left = 'Слева',
  Right = 'Справа',
  Down = 'Снизу',
}

export const getDynamicPositionFlexDirection = (position: DynamicPosition) => {
  switch (position) {
    case DynamicPosition.Down:
      return 'column';
    case DynamicPosition.Left:
      return 'row-reverse';
    case DynamicPosition.Right:
    default:
      return 'row';
  }
};

export const getFormattedValueText = (
  value: string,
  roundingCount: number | undefined,
  customDisplayValueText: string | undefined,
) => {
  return `${value}${customDisplayValueText ? ' ' : ''}${
    customDisplayValueText || ''
  }`;
};

type FontSizeProps = {
  width: number;
  height: number;
  isDisplayLegend: boolean;
  isDisplayPlan: boolean;
  currentName: string;
  previousName: string;
  currentValueFormatted: string;
  previousValueFormatted: string;
  isNeedToDisplayDynamics: boolean;
  dynamicPosition: DynamicPosition;
  dynamicsValueFormatted: string;
  customDisplayDynamicsText: string;
  dynamicsTextRatio: number;
  fixedFontSize: boolean;
  valuesFontSize: number;
  dynamicSizeRatio: number;
};

export const getFontSize = ({
  width,
  height,
  isDisplayLegend,
  isDisplayPlan,
  currentName,
  previousName,
  currentValueFormatted,
  previousValueFormatted,
  isNeedToDisplayDynamics,
  dynamicPosition,
  dynamicsValueFormatted,
  customDisplayDynamicsText,
  dynamicsTextRatio,
  fixedFontSize,
  valuesFontSize,
  dynamicSizeRatio,
}: FontSizeProps) => {
  if (fixedFontSize && valuesFontSize)
    return {
      fontSize: valuesFontSize,
      fontDynamicsSize: valuesFontSize * dynamicsTextRatio,
      fontNumberDynamicSize: valuesFontSize * dynamicSizeRatio,
    };

  let fontSizeHeight = Math.max(
    (height - LEGEND_MARGIN) /
      ((isDisplayPlan ? LINE_HEIGHT * 2 : LINE_HEIGHT) +
        LINE_HEIGHT * dynamicsTextRatio),

    MIN_FONT_SIZE,
  );

  let fontDynamicsSizeHeight = fontSizeHeight * dynamicsTextRatio;
  let fontNumberDynamicsSizeHeight = fontSizeHeight * dynamicSizeRatio;

  if (fontDynamicsSizeHeight < MIN_FONT_DYNAMICS_SIZE) {
    fontSizeHeight = Math.max(
      (height - LEGEND_MARGIN - MIN_FONT_DYNAMICS_SIZE * LINE_HEIGHT) /
        (isDisplayPlan ? LINE_HEIGHT * 2 : LINE_HEIGHT),
      MIN_FONT_SIZE,
    );
    fontDynamicsSizeHeight = MIN_FONT_DYNAMICS_SIZE;
  }
  if (fontNumberDynamicsSizeHeight < MIN_FONT_DYNAMICS_SIZE) {
    fontSizeHeight = Math.max(
      (height - LEGEND_MARGIN - MIN_FONT_DYNAMICS_SIZE * LINE_HEIGHT) /
        (isDisplayPlan ? LINE_HEIGHT * 2 : LINE_HEIGHT),
      MIN_FONT_SIZE,
    );
    fontNumberDynamicsSizeHeight = MIN_FONT_DYNAMICS_SIZE;
  }

  const name =
    isDisplayLegend && (currentName?.length || 0) > (previousName?.length || 0)
      ? currentName
      : previousName || '';
  const value =
    (currentValueFormatted?.length || 0) > (previousValueFormatted?.length || 0)
      ? currentValueFormatted
      : previousValueFormatted || '';

  const text = `${name}${name ? '  ' : ''}${value}`;
  const textLength = text.length;

  const dynamicsSideLength = Math.max(
    (dynamicsValueFormatted?.length || 0) + DYNAMICS_ICON_LENGTH,
    customDisplayDynamicsText?.length || 0,
  );
  const dynamicsDownLength =
    (dynamicsValueFormatted?.length || 0) +
      DYNAMICS_ICON_LENGTH +
      SPACE_LENGTH +
      customDisplayDynamicsText?.length || 0;

  const dynamicsLength = isNeedToDisplayDynamics
    ? dynamicPosition === DynamicPosition.Down
      ? dynamicsDownLength
      : dynamicsSideLength
    : 0;

  const dynamicsRatioLength = dynamicsLength * dynamicsTextRatio;

  const totalLength =
    dynamicPosition === DynamicPosition.Down
      ? Math.max(textLength, dynamicsRatioLength)
      : textLength + dynamicsRatioLength;

  const fontSize = Math.max(
    width / (totalLength / FONT_SIZE_MODIFIER),
    MIN_FONT_SIZE,
  );

  const fontDynamicsSize = fontSize * dynamicsTextRatio;
  const fontNumberDynamicSize = fontSize * dynamicSizeRatio;

  if (fontSize * dynamicsTextRatio < MIN_FONT_DYNAMICS_SIZE) {
    const dynamicsWidth =
      (MIN_FONT_DYNAMICS_SIZE * dynamicsLength) / FONT_SIZE_MODIFIER;

    const valueWidth =
      dynamicPosition === DynamicPosition.Down ? width : width - dynamicsWidth;

    const fontValueSize = Math.max(
      Math.min(valueWidth / (textLength / FONT_SIZE_MODIFIER), fontSizeHeight),
      MIN_FONT_SIZE,
    );

    return {
      fontSize: fontValueSize,
      fontDynamicsSize: MIN_FONT_DYNAMICS_SIZE,
      fontNumberDynamicSize,
    };
  }

  return fontSize < fontSizeHeight
    ? {
      fontSize,
      fontDynamicsSize,
      fontNumberDynamicSize,
    }
    : {
      fontSize: fontSizeHeight,
      fontDynamicsSize: fontDynamicsSizeHeight,
      fontNumberDynamicSize: fontNumberDynamicsSizeHeight,
    };
};
