import React from 'react';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import {
  HierarchyLink,
  PropertyData,
} from '../../../dropdown-layout/helpers/Property';
import { CustomButton } from '../../../../../uikit/Button';
import { CustomTabs } from '../../../../../uikit/Tabs';
import { MainSettings } from './main-settings/main-settings';
import { ViewSettings } from './view-settings/view-settings';
import { SwipeableContainer } from '../../../../../uikit/SwipeableContainer';
import { useHierarchyModal } from './useHierarchyModal';
import './index.css';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../common/icon-svg/icon-svg';

export interface HierarchyValues {
  dicSourceId: string;
  dicReferenceField: string;
  dicReferenceDisplay: string;
  dicParent: string;
  hierarchyLevels: string[];
  dicParentRootValue: string;
}

export interface HierarchyOptionsProps {
  panelData: PropertyData;
  closeDialog: () => void;
  changeHierarchy: (hierarchyLink?: HierarchyLink) => void;
}

export const HierarchySettingModal = ({
  panelData,
  closeDialog,
  changeHierarchy,
}: HierarchyOptionsProps) => {
  const {
    handleSubmit,
    tabs,
    currentTab,
    handleChange,
    values,
    errors,
    handleClose,
    sourceFields,
    dicParentName,
    handleClickDelete,
  } = useHierarchyModal({
    panelData,
    closeDialog,
    changeHierarchy,
  });

  return (
    <CustomDialog
      isOpen
      onClose={closeDialog}
      title="Настройка иерархии"
      description={panelData.name}
      maxWidth="xs"
    >
      <form className="dialog-body" onSubmit={handleSubmit}>
        <div className="dialog-body__block">
          <CustomTabs buttons={tabs} selected={currentTab} />
        </div>
        <SwipeableContainer index={currentTab}>
          <MainSettings
            handleChange={handleChange}
            values={values}
            errors={errors}
            sourceFields={sourceFields}
            dicParentName={dicParentName}
          />
          <ViewSettings
            handleChange={handleChange}
            hierarchyLevels={values.hierarchyLevels}
            errors={errors}
            dicParentName={dicParentName}
          />
        </SwipeableContainer>

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton variant="contained" type="submit">
            <span>Сохранить</span>
          </CustomButton>
          <CustomButton variant="outlined" type="reset" onClick={handleClose}>
            Отменить
          </CustomButton>

          <div className="dialog-buttons__additional-container">
            <CustomTooltip title="Удалить иерархию">
              <div>
                <CustomButton
                  variant="outlined"
                  icon={<IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />}
                  onClick={handleClickDelete}
                />
              </div>
            </CustomTooltip>
          </div>
        </div>
      </form>
    </CustomDialog>
  );
};
