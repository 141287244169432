import React, { useEffect, useState } from 'react';
import { Box, IconButton, Popover } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { truncate } from 'lodash';
import { useParams } from 'react-router-dom';
import { selectProjectParameters } from '../../../slices/parameters/parameters';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { InputContainer } from '../../common/special-inputs/inputContainer/inputContainer';
import './styles.css';
import IconSvg from '../../common/icon-svg/icon-svg';
import OperationTypeMenu from '../../common/operation-type-menu/operation-type-menu';
import { useNotificator } from '../../common/snackbar/hooks';
import { apiGetFormulas } from '../../../services/dictionariesController';
import {
  formatRestrictionText,
  parseComplexRestriction,
} from '../dropdown-layout/helpers/helpers';
import {
  apiAddCalculatedField,
  apiFetchSourceData,
  apiUpdateCalculatedField,
} from '../../../services/widgetController';
import { setCurrentProjectWidgetSourceFieldsDataAction } from '../../../slices/main-page/main-page-slice';
import { MeasureProps } from '../../../slices/types';
import Dictionaries from '../../../types/dictionaries';
import { CustomButton } from '../../../uikit/Button';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { CustomInfoAlert } from '../../../uikit/InfoAlert';

interface AddMeasureModalProps {
  closeModal: () => void;
  currentSourceId: string;
  currentField?: MeasureProps;
}

const useStyles = makeStyles({
  iconButton: {
    padding: 0,
    height: '20px',

    '&:hover': {
      background: 'var(--white)',
    },
  },
});

const DEFAULT_MEASURE_TYPE = 'DOUBLE';

export const AddMeasureModal = ({
  closeModal,
  currentSourceId,
  currentField,
}: AddMeasureModalProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const projectId: number | undefined =
    Number(useParams<{ projectId: string }>()?.projectId) ?? undefined;

  const [sourceFields, setSourceFields] = useState<MeasureProps[]>([]);

  useEffect(() => {
    apiFetchSourceData(currentSourceId).then((result) => {
      setSourceFields(result.fields);
    });
  }, []);

  const currentSourceFields = sourceFields.filter((field) => field !== currentField) || [];
  const parameters = useSelector(selectProjectParameters);

  const [textareaRestrictionContent, setTextareaRestrictionContent] =
    useState<string>(currentField ? formatRestrictionText(currentField) : '');
  const [templateError, setTemplateError] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [formulasDictionary, setFormulasDictionary] = useState<Dictionaries.GroupFormula[]>([]);
  const [lastFormulaExample, setLastFormulaExample] = useState<string>('');
  const [textareaFormulaContent, setTextareaFormulaContent] = useState<string>('');
  const [name, setName] = useState<string>(currentField?.name || '');
  const [nameError, setNameError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isOpenMenu = Boolean(anchorEl);
  const popoverId = isOpenMenu ? 'simple-popover' : undefined;

  const type = currentField?.type || DEFAULT_MEASURE_TYPE;

  const { showNotification } = useNotificator();

  useEffect(() => {
    apiGetFormulas()
      .then((response) => {
        if (response) {
          const formulas: [string, Dictionaries.Formula[]][] = response
            ? Object.entries(response)
            : [];
          const formulasDictionary: Dictionaries.GroupFormula[] = formulas.map(
            (f: [string, Dictionaries.Formula[]], index: number) => {
              return [f[0], f[1], index];
            },
          );
          setFormulasDictionary(formulasDictionary);
        }
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  }, []);

  const handleRestrictionChange = (value: string) => {
    setTemplateError('');
    setTextareaRestrictionContent(value);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNameError('');
    setName(event.target.value);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getTextareaFormulaContent = (operation: string) => {
    return operation;
  };

  const chooseTransformationOperation = (operation: Dictionaries.Formula) => () => {
    setAnchorEl(null);
    setLastFormulaExample(operation.example);
    setTextareaFormulaContent(getTextareaFormulaContent(operation.dslFormula));
  };

  const copyToClipboard = () => {
    copy(textareaFormulaContent);
    showNotification({
      message: 'Скопировано в буфер обмена',
      variant: 'success',
    });
  };

  const showExample = (text: string) =>
    text
      .split('\\n')
      .filter((line: string) => line !== '')
      .map((line: string) => line.trim())
      .map((i) => {
        return <div key={i}>{i}</div>;
      });

  const onSave = () => {
    const newMeasure: MeasureProps = {
      ...parseComplexRestriction(currentSourceFields, textareaRestrictionContent),
      etlSourceId: currentSourceId,
      name,
      widgetFieldId: null,
      filter: [],
      orderDirection: '',
      calculatedId: currentField?.calculatedId || null,
      etlFieldId: currentField?.etlFieldId || null,
      type,
    };

    if (!truncate(name)) {
      setNameError('Не введено название показателя');
      return;
    }
    if (!truncate(textareaRestrictionContent)) {
      setTemplateError('Не введена формула');
      return;
    }

    setIsLoading(true);
    !currentField && apiAddCalculatedField(projectId, newMeasure)
      .then(() => {
        apiFetchSourceData(currentSourceId).then((result) => {
          dispatch(setCurrentProjectWidgetSourceFieldsDataAction(result.fields));
          setIsLoading(false);
          closeModal();
        });
      })
      .catch((error) => {
        showNotification({
          message: error.response.data.message,
          variant: 'error',
        });
        setIsLoading(false);
      });

    newMeasure.calculatedId && apiUpdateCalculatedField(projectId, newMeasure.calculatedId, newMeasure)
      .then(() => {
        apiFetchSourceData(currentSourceId).then((result) => {
          dispatch(setCurrentProjectWidgetSourceFieldsDataAction(result.fields));
          setIsLoading(false);
          closeModal();
        });
      })
      .catch((error) => {
        showNotification({
          message: error.response.data.message,
          variant: 'error',
        });
        setIsLoading(false);
      });
  };

  return (
    <CustomDialog
      isOpen={true}
      onClose={closeModal}
      title={currentField ? 'Редактирование измерения' : 'Добавление нового измерения'}
    >
      <div className="add-measure">
        <Box display="flex" justifyContent="space-between">
          <TextInput
            value={name}
            label="Введите название показателя"
            handleChange={handleChangeName}
            className="add-measure__input--long"
            error={Boolean(nameError)}
            helperText={nameError}
          />
        </Box>

        <Box>
          <InputContainer
            textInputProps={{
              label: 'Введите формулу. Выбор поля - @. Выбор параметра - #',
              handleChange: () => null,
              fullWidth: true,
              maxRows: 10,
              error: Boolean(templateError),
              helperText: templateError,
            }}
            itemsList={currentSourceFields.map((value, index: number) => ({
              key: index,
              value: value.name,
              aggregation: null,
              id: value.etlFieldId || '',
            }))}
            parameterList={parameters}
            value={textareaRestrictionContent}
            changeValue={handleRestrictionChange}
          />
        </Box>

        <div className="add-measure-function">
          <div className="add-measure-function__title">
            <div className="add-measure-function__description-group">
              <div className="add-measure-function__description">
                Функции
              </div>
              <div className="add-measure-function__formulas-list">
                <IconButton
                  className={classes.iconButton}
                  onClick={handleMenuClick}
                >
                  <IconSvg
                    svg={IconDictionary.PlusUnderline}
                    fill="var(--primary-color)"
                  />
                </IconButton>
                <Popover
                  classes={{ paper: 'add-measure__popover' }}
                  id={popoverId}
                  open={isOpenMenu}
                  onClose={handleCloseMenu}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <OperationTypeMenu
                    formulesDictionary={formulasDictionary}
                    chooseTransformationOperation={
                      chooseTransformationOperation
                    }
                  />
                </Popover>
              </div>
            </div>
            <span className="add-measure-function__main">
              {textareaFormulaContent}
            </span>
            {Boolean(textareaFormulaContent) && (
              <IconButton
                className={classes.iconButton}
                onClick={copyToClipboard}
              >
                <IconSvg
                  svg={IconDictionary.Copy}
                  fill="var(--dark-grey)"
                />
              </IconButton>
            )}
          </div>
        </div>

        {Boolean(lastFormulaExample) && (
          <Box>
            <CustomInfoAlert title="Пример">{showExample(lastFormulaExample)}</CustomInfoAlert>
          </Box>
        )}
      </div>
      <div className="dialog-buttons">
        <CustomButton
          variant="contained"
          type="submit"
          onClick={onSave}
          disabled={isLoading}
          loading={isLoading}
        >
          Сохранить
        </CustomButton>
        <CustomButton
          variant="outlined"
          type="reset"
          onClick={closeModal}
        >
          Отменить
        </CustomButton>
      </div>
    </CustomDialog>
  );
};
