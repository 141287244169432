import React from 'react';
import { LoadersTableRowProps } from './loaders-table-row';
import { useRoles } from '../../../../hooks/useRoles';
import { Roles } from '../../../../enums/roles';
import { LocalStorage } from '../../../../enums/local-storage-type';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { CommonDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface LoaderTableBodyCell {
  title: string | number;
  icon?: JSX.Element;
  link?: string;
  isActiveLink?: boolean;
  onClick?: () => void;
}

export const useLoadersTableRow = ({
  project,
  groupId,
}: LoadersTableRowProps) => {
  const rolesAccess = useRoles({
    isShowEditPointsButton: project.permission !== Roles.Reporter,
    isAllowToGetInProject: project.permission !== Roles.Reporter,
  });

  const handleClickName = () => {
    localStorage.setItem(
      LocalStorage.ACTIVE_PROJECTS_GROUP_ID,
      groupId.toString(),
    );
  };

  const cells: LoaderTableBodyCell[] = [
    {
      title: project.name,
      link: `/project/${project.id}`,
      isActiveLink: rolesAccess.isAllowToGetInProject,
      onClick: handleClickName,
    },
    {
      title: project.author,
    },
    {
      title: project.personal
        ? CommonDictionary.personalM
        : CommonDictionary.publicM,
      icon: project.personal ? (
        <IconSvg
          svg={IconDictionary.PrivateProject}
          classSVG="project-card__status-icon"
          fill="var(--dark-grey)"
          width={24}
          height={24}
        />
      ) : (
        <IconSvg
          svg={IconDictionary.PublicProject}
          classSVG="project-card__status-icon"
          fill="var(--dark-grey)"
          width={24}
          height={24}
        />
      ),
    },
    {
      title: project.published
        ? CommonDictionary.publicF
        : CommonDictionary.personalF,
      icon: project.published ? (
        <IconSvg
          svg={IconDictionary.PublicMap}
          classSVG="project-card__status-icon"
          width={24}
          height={24}
          fill="var(--dark-grey)"
        />
      ) : (
        <IconSvg
          svg={IconDictionary.PrivateMap}
          classSVG="project-card__status-icon"
          fill="var(--dark-grey)"
          width={24}
          height={24}
        />
      ),
    },
    {
      title: project.dashboardsCount,
      icon: (
        <IconSvg svg={IconDictionary.Dashboard} fill="var(--primary-color)" width={24} height={24} />
      ),
    },
    {
      title: project.widgetsCount,
      icon: (
        <IconSvg
          svg={IconDictionary.WidgetCount}
          fill="var(--success-color)"
          width={24}
          height={24}
        />
      ),
    },
    {
      title: project.cacheLastUpdated || '-',
      icon: <IconSvg
        svg={IconDictionary.TimeRefresh}
        fill="var(--dark-grey)"
        width={24}
        height={24}
      />,
    },
    {
      title: '',
    },
  ];

  const cellsCount = cells.length;

  return {
    cells,
    cellsCount,
  };
};
