import { AxisBottom as AxisBottomVisx } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { max, range } from 'd3-array';
import { NumberValue } from 'd3-scale';
import { times, uniqBy } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';
import { calculateTextDimensions } from '../../../../../utils/functions';
import { axisBottomTickLabelProps, axisColor } from '../helper';
import { FormatAxisXValue, SeriesValue, XScale } from '../types';

interface AxisBottomProps {
  xMax: number;
  yMax: number;
  isNeedToDisplayAxesGuide: boolean;
  isNeedInterpolateHorizontalAxis: boolean;
  scale: XScale;
  formatAxisXValue: FormatAxisXValue;
  xValue: SeriesValue;
  xDataValues: (number | Date)[];
}

const textPadding = 5;

export type TickFormat = (value: Date | NumberValue) => string;

export const getUniqValues = (ticks: number[] | Date[], tickFormat: TickFormat) => {
  const ticksFormat = ticks.map((tick) => ({
    tickValue: tick,
    tickText: tickFormat(tick),
  }));

  const ticksUniq = uniqBy(ticksFormat, (tick) => tick.tickText);

  return ticksUniq.map((tick) => tick.tickValue);
};

export const getTickByInterpolation = (
  startValue: number,
  endValue: number,
  interval: number,
) => {
  const intervalLength = (endValue - startValue) / interval;

  return [
    ...range(0, interval).map((step) => +startValue + step * intervalLength),
    endValue,
  ];
};

function getTickByData(arr: number[], ticks: number): number[] {
  if (arr.length === 0 || ticks <= 0) {
    return [];
  }

  // Сортируем массив для упрощения поиска
  const sortedArr = [...arr].sort((a, b) => a - b);

  // Находим минимальное и максимальное значение
  const min: number = sortedArr[0];
  const max: number = sortedArr[sortedArr.length - 1];

  // Если тиков меньше или равно двум, возвращаем минимальное и максимальное значение
  if (ticks <= 2) {
    return ticks === 1 ? [min] : [min, max];
  }

  // Вычисляем размер шага
  const step: number = (max - min) / (ticks - 1);

  // Выбираем числа, соблюдая минимальный шаг
  const result: number[] = [min];
  let lastSelected = min;

  for (const num of sortedArr) {
    if (num - lastSelected >= step && num < max) {
      result.push(num);
      lastSelected = num;
    }
  }

  // Убедимся, что максимальное значение включено
  if (result[result.length - 1] !== max) {
    result.push(max);
  }

  return result.slice(0, ticks);
}

const AxisBottom = memo(
  ({
    xMax,
    yMax,
    isNeedToDisplayAxesGuide,
    isNeedInterpolateHorizontalAxis,
    scale,
    formatAxisXValue,
    xValue,
    xDataValues,
  }: AxisBottomProps) => {
    const isDate = xValue.simplifiedType === WidgetSimplifiedDataType.DATE;

    const tickFormat: TickFormat = useCallback(
      (value: Date | NumberValue) => {
        return formatAxisXValue(value);
      },
      [formatAxisXValue],
    );

    const maxWidthValue = useMemo(
      () =>
        max(
          [...scale.ticks(), ...scale.domain()]
            // в случае числа заменяем дробную часть на свою, чтобы после формата учесть самую длинный текст
            .map((value) =>
              !isDate && isNeedInterpolateHorizontalAxis
                ? Math.trunc(value as number) + 0.444444444444444
                : value,
            )
            .map(tickFormat)
            .map(
              (value) =>
                calculateTextDimensions(value, axisBottomTickLabelProps).width,
            ),
        ),
      [isNeedInterpolateHorizontalAxis, isDate, scale, tickFormat],
    );

    const textWidth = maxWidthValue + textPadding * 2;
    const tickCount = Math.floor(xMax / textWidth);
    const tickValuesNumber = isDate
      ? (xDataValues as Date[]).map((v) => v.getTime())
      : (xDataValues as number[]);
    const tickValuesNumberApportioned = isNeedInterpolateHorizontalAxis
      ? getTickByInterpolation(
        Number(scale.domain()[0]),
        Number(scale.domain()[1]),
        tickCount,
      )
      : getTickByData(tickValuesNumber, tickCount);

    const tickValues = isDate
      ? tickValuesNumberApportioned.map((d) => new Date(d))
      : tickValuesNumberApportioned;
    const tickValuesUniq = getUniqValues(tickValues, tickFormat);

    return (
      <>
        {isNeedToDisplayAxesGuide && (
          <GridColumns
            scale={scale}
            tickValues={tickValuesUniq}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
        )}
        <AxisBottomVisx
          top={yMax}
          scale={scale}
          stroke={axisColor}
          tickStroke={axisColor}
          tickFormat={tickFormat}
          tickValues={tickValuesUniq}
          tickLabelProps={() => axisBottomTickLabelProps}
        />
      </>
    );
  },
);

AxisBottom.displayName = 'AxisBottom';

export default AxisBottom;
