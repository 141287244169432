import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import './styles.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DateFunctions } from '../../../enums/date';
import { ParameterType } from '../../../enums/parameters';
import { useAppDispatch } from '../../../slices';
import {
  saveNewParameter,
  selectIsAddingProjectParameterLoading,
  selectParameterValidatorsEntities,
  selectParameterValidatorsKeys,
  selectProjectParameters,
} from '../../../slices/parameters/parameters';
import { ProjectParameter } from '../../../slices/types';
import { CustomButton } from '../../../uikit/Button';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import { DateField } from '../../widget-page/filters/fields/date-field';
import { SelectField } from '../../widget-page/filters/fields/select/select-field';
import { TextInput } from '../special-inputs/inputs/text-input';
import { CustomProgress } from '../../../uikit/Progress';
import { NewProjectParameter } from './types';


interface ParametersAddModalProps {
  handleClose: () => void;
}

const codePlaceholder = 'Только латинские буквы, цифры и "_"';
const namePlaceholder = 'Максимум 25 символов';
const valueTextPlaceholder = 'введите строку в \' , пример: \'Москва\'';
const valueNumberPlaceholder = 'Разделитель дробной части ".", пример: 12.57';

export const ParametersAddModal = ({
  handleClose,
}: ParametersAddModalProps) => {
  const dispatch = useAppDispatch();

  const projectId: number | undefined =
    Number(useParams<{ projectId: string }>()?.projectId) ?? undefined;

  const parameters = useSelector(selectProjectParameters);
  const validators = useSelector(selectParameterValidatorsEntities);
  const validatorsKeys = useSelector(selectParameterValidatorsKeys);
  const isLoading = useSelector(selectIsAddingProjectParameterLoading);

  const [parameterCode, setParameterCode] = useState('');
  const [parameterCodeError, setParameterCodeError] = useState('');
  const [type, setType] = useState<ParameterType>(
    String(validatorsKeys[0] || '') as ParameterType,
  );
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [value, setValue] = useState('');
  const [isValueError, setIsValueError] = useState(false);

  const newParameter: NewProjectParameter = {
    parameterCode: parameterCode.trim(),
    type,
    name: name.trim(),
    value: value.trim(),
  };

  const datatypeOptions = validatorsKeys.map((key) => ({
    value: String(key),
    label: String(key),
  }));

  const isDisableButton = isLoading || validatorsKeys.length === 0;

  useEffect(() => {
    if (validatorsKeys.length)
      setType(String(validatorsKeys[0] || '') as ParameterType);
  }, [validatorsKeys]);

  const handleChangeCode = (value: string) => {
    setParameterCodeError('');
    if (/^[a-zA-Z0-9_]*$/.test(value) && value.length <= 25) {
      setParameterCode(value);
    }
  };

  const handleChangeName = (value: string) => {
    setNameError('');
    if (value.length <= 25) {
      setName(value);
    }
  };

  const handleChangeDate = (value: Moment | null) => {
    if (value && value.isValid()) {
      setIsValueError(false);
      setValue(value.format('YYYY-MM-DD 00:00:00.0'));
    } else {
      setValue('');
    }
  };

  const handleChangeValue = (value: string) => {
    setIsValueError(false);
    setValue(value);
  };

  const handleCreate = () => {
    const validateRegex = validators[type]?.validateRegex;

    const regExp = new RegExp(validateRegex || '');

    const parametersCodes = parameters.map(
      (parameter) => parameter.parameterCode,
    );

    if (newParameter.parameterCode.length === 0) {
      setParameterCodeError('Нужно заполнить поле');
    } else if (parametersCodes.includes(newParameter.parameterCode)) {
      setParameterCodeError('Такой параметр уже есть');
    } else if (newParameter.name.length === 0) {
      setNameError('Нужно заполнить поле');
    } else if (
      (validateRegex &&
      [ParameterType.TEXT, ParameterType.NUMBER].includes(type) &&
      !regExp.test(newParameter.value)) ||
      newParameter.value.length === 0
    ) {
      setIsValueError(true);
    } else {
      dispatch(saveNewParameter({ projectId, newParameter }))
        .unwrap()
        .then(() => {
          handleClose();
        });
    }
  };

  return (
    <>
      <CustomDialog
        isOpen
        title="Добавить новый параметр"
        onClose={() => handleClose()}
        maxWidth="xs"
      >
        <div className="parameter-add-modal__body">
          <TextInput
            label="Код параметра"
            fullWidth
            value={parameterCode}
            handleChange={(e) => handleChangeCode(e.target.value)}
            error={Boolean(parameterCodeError)}
            helperText={parameterCodeError || codePlaceholder}
          />
          <SelectField
            label="Тип"
            hideClearButton
            isSingleSelection
            isLoading={false}
            options={datatypeOptions}
            values={[type]}
            onChange={(values) => setType(values[0] as ParameterType)}
          />
          <TextInput
            label="Название"
            fullWidth
            value={name}
            handleChange={(e) => handleChangeName(e.target.value)}
            error={Boolean(nameError)}
            helperText={nameError || namePlaceholder}
          />
          {type === ParameterType.DATE ? (
            <DateField
              label="Дата"
              hideClearButton
              onChange={handleChangeDate}
              value={value === '' ? null : new Date(value)}
              filterType={DateFunctions.DAY}
              error={isValueError}
            />
          ) : (
            <TextInput
              label="Значение"
              fullWidth
              value={value}
              handleChange={(e) => handleChangeValue(e.target.value)}
              error={isValueError}
              helperText={type === ParameterType.NUMBER ? valueNumberPlaceholder : valueTextPlaceholder}
            />
          )}
          <div className="parameter-add-modal__footer">
            <CustomButton
              fullWidth
              onClick={handleCreate}
              variant="contained"
              className="parameter-add-modal__footer-button"
              disabled={isDisableButton}
            >
              {isDisableButton ? (
                <CustomProgress type="circular" style={{ color: 'white' }} />
              ) : (
                'Добавить'
              )}
            </CustomButton>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};
