import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { LeafletEvent } from 'leaflet';
import { useParams } from 'react-router-dom';
import { getSortedProperties } from 'src/helpers/common-helpers';
import { useRequestCanceller } from 'src/hooks/useRequestCanceller';
import { DashboardProperty, State, WidgetProperties } from '../../../../../slices/types';
import { PageParams } from '../../../../../types/meta';
import { Feature, PopupContent } from '../map-helpers';
import { apiGetMapPopUpLists, apiGetMapPopUpListsByWidgetId } from '../../../../../services/widgetController';
import { PanelType, WidgetType } from '../../../../../enums/widget-type';
import { isDashboardPage } from '../../helpers';
import { setSelectedMapCardActions } from '../../../../../slices/main-page/main-page-slice';

interface UseMapCardsProps {
  deselectPoint: () => void;
  activeLayers: number[];
  widgetProperties: WidgetProperties[];
  widgetSourceId: string;
  needCache: boolean;
  pushDown: boolean;
  getFormattedValue: (fieldType: string, content: string) => string;
  widgetId: number;
  enhancedParams: {
    dashboardProperties: DashboardProperty[];
    widgetOverriddenProperties: WidgetProperties[];
  };
}

export const useMapCards = ({
  deselectPoint,
  activeLayers,
  widgetProperties,
  widgetSourceId,
  needCache,
  pushDown,
  getFormattedValue,
  widgetId,
  enhancedParams
}: UseMapCardsProps) => {
  const dispatch = useDispatch();

  const params: PageParams = useParams();
  const dashboardId: number | undefined = Number(params.id) || undefined;
  const projectId: number | undefined = Number(params.projectId) || undefined;

  const isFullScreen = useSelector(
    (state: State) => state.mainPage.isWidgetFullScreen,
  );

  const [pointEvent, setPointEvent] = useState<{ layerType: string } | null>(
    null,
  );
  const [mapEvent, setMapEvent] = useState<boolean>(false);
  const [currentCard, setCurrentCard] = useState<string | null>(null);

  const [popupContentList, setPopupContentList] = useState<PopupContent[]>([]);
  const [filteredPopupContentList, setFilteredPopupContentList] = useState<
    PopupContent[]
  >([]);

  const [isLoadingCards, setIsLoadingCards] = useState<boolean>(true);

  const isDashboard = isDashboardPage();

  const { callCancellable } = useRequestCanceller();

  useEffect(() => {
    if (!isNull(currentCard)) {
      const cardsList = popupContentList.filter(
        (item) => item.pointId === currentCard,
      );
      setFilteredPopupContentList(cardsList);
    } else {
      setFilteredPopupContentList(popupContentList);
    }
  }, [currentCard, popupContentList]);

  const setAllPopupContents = () => {

    const fetchPopUpsFunc = isDashboard ? apiGetMapPopUpListsByWidgetId : apiGetMapPopUpLists;

    const filteredWidgetProperties =
      widgetProperties.filter(
        (property) =>
          property.name === PanelType.axisX ||
          property.name === PanelType.axisY ||
          property.name === PanelType.axisFilter);


    const sendingProperties = isDashboard ? {
      dashboardProperties: getSortedProperties(enhancedParams.dashboardProperties),
      widgetOverriddenProperties: getSortedProperties(enhancedParams.widgetOverriddenProperties),
      widgetId,
      dashboardId,
    } : {
      properties: filteredWidgetProperties,
      type: WidgetType.MAP,
      sourceId: widgetSourceId,
      pushDown,
      needCache,
      projectId,
    };

    setIsLoadingCards(true);
    callCancellable(fetchPopUpsFunc, sendingProperties)
      .then((res: PopupContent[]) => {
        setPopupContentList(res);
        setFilteredPopupContentList(res);
      })
      .finally(() => setIsLoadingCards(false));
  };

  const setFilteredCards = useCallback((filterValue: string) => {
    if (!filterValue.length) {
      setFilteredPopupContentList(popupContentList);
    } else {
      const filteredCards = popupContentList.filter((card) => {
        if (!activeLayers.includes(card.layerIndex)) return false;

        let isCardInFilter = false;
        card.cardData.forEach((item) => {
          const formattedValue = getFormattedValue(item.type, item.value);
          if (
            item.title?.toLowerCase().includes(filterValue) ||
            formattedValue?.toLowerCase().includes(filterValue)
          ) {
            isCardInFilter = true;
          }
        });

        return isCardInFilter;
      });
      setFilteredPopupContentList(filteredCards);
    }
  }, [popupContentList, activeLayers, getFormattedValue]);

  useEffect(() => {
    const cardsOfActiveLayers = popupContentList.filter((item) =>
      activeLayers.includes(item.layerIndex),
    );
    setFilteredPopupContentList(cardsOfActiveLayers);
  }, [activeLayers.length]);

  const handlerChangeFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const filterValue: string = event.target.value.toLowerCase();

      setFilteredCards(filterValue);
    },
    [setFilteredCards],
  );

  const clearCardsFilters = () => {
    const cardsFilterInput = document.getElementById(
      'cards-filter-input',
    ) as HTMLInputElement;
    if (cardsFilterInput) {
      cardsFilterInput.value = '';
    }
  };

  const onClickPoint = (event: LeafletEvent) => {
    clearCardsFilters();
    const feature = event.layer.feature as Feature;
    const layerType = feature.geometry.type.toUpperCase();
    setCurrentCard(feature.properties.popupContent[0].pointId);
    setPointEvent({ layerType });
  };

  const onClickMap = () => {
    clearCardsFilters();
    setMapEvent(true);

    dispatch(
      setSelectedMapCardActions({
        key: widgetId,
        value: null,
      }),
    );
  };

  const handlingMapEvents = () => {
    // todo постараться выпилить этот мракобесный костыль
    // проблема 1 - при клике на точку срабатывает событие клика по карте
    // проблема 2 - порядок вызова обработчиков события клика по точке и карте разный для виджета и дашборда
    if (isDashboard) {
      if (pointEvent && mapEvent) {
        setMapEvent(false);
        setPointEvent(null);
      }
      if (!pointEvent && mapEvent) {
        setCurrentCard(null);
        deselectPoint();
        setMapEvent(false);
        setPointEvent(null);
      }
    } else {
      if (pointEvent && !mapEvent) {
        setMapEvent(false);
      }
      if (pointEvent && mapEvent) {
        setCurrentCard(null);
        deselectPoint();
        setMapEvent(false);
        setPointEvent(null);
      }
    }
  };

  useEffect(() => {
    handlingMapEvents();
  }, [pointEvent, mapEvent, isFullScreen]);

  return {
    handlerChangeFilter,
    filteredPopupContentList,
    setAllPopupContents,
    onClickPoint,
    onClickMap,
    isLoadingCards,
  };
};
