import { styled } from '@material-ui/core';
import React from 'react';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';

interface CustomInfoAlertProps extends AlertProps {
  title?: string;
  fullwidth?: boolean;
  children?: string | JSX.Element | JSX.Element[] | React.ReactNode;
}

const StyledInfoAlert = styled(Alert)(
  ({ fullwidth }: Partial<CustomInfoAlertProps>) => ({
    width: fullwidth ? '100%' : 'auto',
    padding:
      'calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) calc(8px * var(--scale-coefficient)) calc(8px * var(--scale-coefficient))',
    border: '1px solid var(--blue-normal)',
    backgroundColor: 'var(--blue-opacity)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: '18px',
    color: 'var(--black)',

    '& [class*=MuiAlert-message]': {
      padding: 0,
    },

    '& [class*=MuiAlert-icon]': {
      color: 'var(--blue-normal)',
      padding: 0,
      marginRight: 'calc(10px * var(--scale-coefficient))',

      '& svg': {
        width: 'calc(24px * var(--scale-coefficient))',
        height: 'calc(24px * var(--scale-coefficient))',
      },
    },

    '& [class*=MuiAlertTitle-root]': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'calc(14px * var(--scale-coefficient))',
      lineHeight: '18px',
      color: 'var(--black)',
      marginBottom: 'calc(12px * var(--scale-coefficient))',
    },
  }),
);

export const CustomInfoAlert = ({
  title = '',
  fullwidth = true,
  children,
  ...props
}: CustomInfoAlertProps) => {
  return (
    <StyledInfoAlert fullwidth={fullwidth} {...props} severity="info">
      <AlertTitle>{title}</AlertTitle>
      {children}
    </StyledInfoAlert>
  );
};
