import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import { getSimplifiedType } from '../../../widget-page/dropdown-layout/helpers/helpers';
import { Filter, FilterGroupProps } from '../../hooks';
import { DashboardFilter } from '../dashboard-filter';
import { isDashboardPreviewPage } from '../../../widget-page/charts/helpers';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import { getAllFieldFilters } from '../../../../helpers/dashboard-page';
import { getFilterSettingValue } from '../../../../helpers/dashboard-page/filters-helper';
import { FilterProperty } from '../../../../enums/dashboard-properties';


export const FiltersContainer = () => {
  const usableFilters = useDashboardFilters();

  const filters: ReactNode[] = [];

  if (usableFilters.values) {
    usableFilters.values.forEach(
      (property: FilterGroupProps, index: number) => {
        const remapedType = getSimplifiedType(property.type);

        const handleChangeFilter = (filters: Filter[], selectDateType?: string, overriddenFunction?: string) => {
          usableFilters && usableFilters.changeFiltersOperation({
            index,
            filters,
            overriddenFunction,
            selectDateType,
          });
        };

        const isVisible = isDashboardPreviewPage() ? getFilterSettingValue(property, FilterProperty.isVisible) : true;

        if (property.actual && isVisible) {
          if (remapedType === 'TEXT') {
            filters.push(
              <DashboardFilter
                property={property}
                handleChangeFilter={handleChangeFilter}
                allFieldFilters={getAllFieldFilters(
                  usableFilters.values,
                  property.id,
                  true,
                )}
              />,
            );
          }

          if (['NUMBER', 'DATE', 'BOOLEAN'].includes(remapedType)) {
            filters.push(
              <DashboardFilter
                property={property}
                handleChangeFilter={handleChangeFilter}
                allFieldFilters={getAllFieldFilters(
                  usableFilters.values,
                  property.id,
                  true,
                )}
              />,
            );
          }
        }
      },
    );
  }

  return (
    <Box
      className="dashboard-filters__list"
    >
      {filters}
    </Box>
  );
};

FiltersContainer.displayName = 'DashboardFiltersContainer';
