import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { State } from '../../../../slices/types';
import { useEventListener } from '../../../../hooks/useEventListener';

import { Arc } from './arc';

import './pie.css';
import { LegendArc } from '../legend-arcs/legent-arc';
import { LegendPositions } from '../../../../enums/legend';

import {
  getAxisValuesForWidget,
  getBooleanPropertyValue,
  getEnumPropertyValue,
  getJSONPropertyValue,
  getLegendsLabelsForWidgetByAxis,
  getSelectPropertyValue,
  sortColorPalette,
} from '../helpers';

import {
  defaultMargin,
  donutThickness,
  minSliceRadius,
  pieOffset,
  widgetContainerPadding,
} from './settings';

import { PanelType } from '../../../../enums/widget-type';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { getTooltipHandler } from '../../../common/widget-tooltip/widget-tooltip';
import { defaultColors } from '../common/color';
import {
  maxLineContainerHeight,
  maxLineContainerWidth,
  zAdditionalContainerWidth,
  zAdditionalTransition,
  zLabelAdditionWidth,
} from '../common/callout-line-settings';
import { Property } from '../../dropdown-layout/helpers/Property';
import { useCalculateRadiusDimensions } from '../hooks/useCalculateRadiusDimensions';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';
import { getActivePanelItems, getActivePanelItemsNames } from '../../../../helpers/common-helpers';

interface Value {
  label: string;
  percent: number;
  count: number;
  radius: number;
}

export type PieProps = {
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  animate?: boolean;
  widgetProps?: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
};

interface ArcValue {
  count: number;
  label: string;
  percent: number;
  radius: number;
}

interface WidgetData {
  data: Array<ArcValue>;
  total: number;
}

let currentColors: any = defaultColors;

export default function PieChart({
  width,
  height,
  margin = defaultMargin,
  animate = true,
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}: PieProps) {
  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  const enableEvents = isActiveFilter;
  const [radius, setRadius] = useState(190);

  const containerRef = useRef(document.createElement('div'));
  const widgetStoreProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const previewData = widgetProps?.data;
  const widgetProperties = widgetProps?.properties || widgetStoreProperties;

  const categoryName = getActivePanelItemsNames(
    widgetProperties,
    PanelType.axisX,
  )[0];

  const valueName = getLegendsLabelsForWidgetByAxis(
    PanelType.axisY,
    widgetProperties,
    false,
  )[0];

  const zName = getLegendsLabelsForWidgetByAxis(
    PanelType.axisZ,
    widgetProperties,
    false,
  )[0];

  const isNeedToDisplayLegend: boolean = getBooleanPropertyValue(
    'displayLegend',
    widgetProperties,
  );

  const displayLegendLine: boolean =
    getSelectPropertyValue('displayLegendLine', widgetProperties)?.trim() ===
      'Выноски' && isNeedToDisplayLegend;

  const displayValueOnLegend: boolean = getBooleanPropertyValue(
    'displayValueOnLegend',
    widgetProperties,
  );

  const displayTextLegend: boolean = getBooleanPropertyValue(
    'displayTextLegend',
    widgetProperties,
  );

  const displayPercentOnLegend: boolean = getBooleanPropertyValue(
    'displayPercentOnLegend',
    widgetProperties,
  );

  const isNeedToDisplayTooltip: boolean = getBooleanPropertyValue(
    'displayTooltip',
    widgetProperties,
  );

  const axisXValuesFull: Array<any> = getActivePanelItems(
    widgetProperties,
    PanelType.axisX,
  );

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );
  currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || currentColors;

  const legendPosition = useMemo(
    () =>
      getEnumPropertyValue<LegendPositions>(
        'legendPositionPieChart',
        widgetProperties,
      ),
    [widgetProperties],
  );

  const isNeedToDisplayOther: boolean = getBooleanPropertyValue(
    'displayOther',
    widgetProperties,
  );

  const otherFieldLabel = 'Прочее';
  const otherFieldOperation = 'NOT IN';
  const defaultFieldOperation = '=';

  const filterOtherWidgetData = (data: Array<ArcValue>): Array<ArcValue> => {
    return data.filter((el: ArcValue) => el.label !== otherFieldLabel);
  };

  const widgetData: Array<ArcValue> = isNeedToDisplayOther
  ? widgetProps?.data.data || previewData.data
  : filterOtherWidgetData(widgetProps?.data.data) || filterOtherWidgetData(previewData.data);

  const needToExtendContainerToZ = Boolean(zName) && displayLegendLine &&
    (displayValueOnLegend || (displayTextLegend && displayPercentOnLegend));

  const currentMaxLineContainerWidth = needToExtendContainerToZ ?
    (zLabelAdditionWidth + maxLineContainerWidth) : maxLineContainerWidth;
  const diameter = radius * 2;
  const centerY = radius;
  const centerX = radius;

  const labels = Object.values(widgetData).map(
    (item) => item.label,
  ) as string[];

  const ordinal = {
    domain: labels,
    range: currentColors as any[],
  };

  const percent = (d: Value) => d.percent;

  const ordinalColorScale = scaleOrdinal(ordinal);

  const { width: containerForRadiusWidth, height: containerForRadiusHeight } =
  containerRef.current.getBoundingClientRect();

  const { calculateDimensions } = useCalculateRadiusDimensions({
    containerRef,
    containerForRadiusWidth,
    containerForRadiusHeight,
    legendPosition,
    widgetContainerPadding,
    isNeedToDisplayLegend,
    radius,
    setRadius,
    displayLegendLine,
    maxLineContainerHeight,
    maxLineContainerWidth,
  });

  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
  }, [
    calculateDimensions,
    isFullScreen,
    previewData
  ]);

  const deselectArc = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const selectArc = useCallback(
    (label: any) => {
      if (!enableEvents) return;
      const isClickOtherField = label === otherFieldLabel;

      const isOtherField =
        filterField.operation === otherFieldOperation && isClickOtherField;

      if (filterField?.value?.includes(label) || isOtherField) {
        deselectArc();
      } else {
        if (!setFilterField) return;

        const value: string[] = isClickOtherField
          ? widgetData
            .map((item) => item.label)
            .filter((label) => label !== otherFieldLabel)
          : [label];

        setFilterField(widgetProps.id, new Property(axisXValuesFull[0]).getId(), [
          {
            operation: isClickOtherField
              ? otherFieldOperation
              : defaultFieldOperation,
            value,
          },
        ]);
      }
    },
    [
      axisXValuesFull,
      deselectArc,
      enableEvents,
      filterField,
      setFilterField,
      widgetData,
      widgetProps.id,
    ],
  );

  if (width < donutThickness) return null;

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(containerRef);

  const widgetTooltipArcProps = {
    showWidgetTooltip: handleWidgetMouseMove,
    hideWidgetTooltip: handleWidgetMouseLeave,
    categoryName,
    valueName,
    zName,
    isNeedToDisplayTooltip,
  };

  const isNeedToDisplaceValue =
    widgetData?.length > 2 && radius <= minSliceRadius;

  const containerWidth = displayLegendLine
    ? diameter + currentMaxLineContainerWidth * 2
    : diameter;
  const containerHeight = displayLegendLine
    ? diameter + maxLineContainerHeight * 2
    : diameter;
  const containerTransitionTop = displayLegendLine
    ? centerY + maxLineContainerHeight
    : centerY;
  const containerTransitionLeft = displayLegendLine
    ? centerX + currentMaxLineContainerWidth + (needToExtendContainerToZ ? zAdditionalTransition : 0)
    : centerX;

  const isLegendOnSide =
    isNeedToDisplayLegend &&
    !displayLegendLine &&
    [LegendPositions.Left, LegendPositions.Right].includes(legendPosition);

  const legendHorisontal = isNeedToDisplayLegend &&
  (legendPosition === LegendPositions.Up ||
    legendPosition === LegendPositions.Down);

  const displayedData = widgetData.filter((data) => data.count !== 0);

  return (
    <div
      className="widget-pie-container"
      style={{
        flexDirection:
          legendPosition === LegendPositions.Up ? 'column' : 'column-reverse',
        alignItems: 'center',
        margin: '0 auto',
        width: '100%',
      }}
    >
      {isNeedToDisplayLegend &&
        [LegendPositions.Up, LegendPositions.Down].includes(legendPosition) &&
        !displayLegendLine && (
          <LegendArc
            widgetProperties={widgetProperties}
            colors={currentColors}
            valueName={valueName}
            data={widgetData}
            zName={zName}
            isSidePosition={false}
          />
      )}
      <div
        style={{
          height: '100%',
          minHeight: '150px',
          minWidth: isNeedToDisplayLegend &&
          (legendPosition === LegendPositions.Left ||
            legendPosition === LegendPositions.Right)
            ? '300px'
            : '150px',
          padding: `8px ${widgetContainerPadding}px 0`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: (legendHorisontal || !isNeedToDisplayLegend) && isFullScreen ? '50%' : '100%',
        }}
        className="widget-pie-container__content"
        ref={containerRef}
        onClick={deselectArc}
      >
        <Box
          p={`${pieOffset} + px`}
          display="flex"
          alignItems="center"
          position="relative"
          height="100%"
          flexDirection={
            legendPosition === LegendPositions.Left ? 'row-reverse' : 'row'
          }
          width="100%"
          justifyContent="center"

        >
          <Box>
            <svg
              width={containerWidth + (needToExtendContainerToZ ? zAdditionalContainerWidth : 0)}
              height={containerHeight}
            >
              <Group
                top={containerTransitionTop}
                left={containerTransitionLeft}
              >
                <Pie
                  data={displayedData}
                  pieValue={percent}
                  outerRadius={( value ) => {
                    return (radius / 100) * (value.data?.radius === null ? 100 : value.data?.radius);
                  }}
                  innerRadius={0}
                >
                  {(pie) => (
                    <Arc<Value>
                      {...pie}
                      isNeedToDisplaceValue={isNeedToDisplaceValue}
                      animate={animate}
                      getKey={(arc) => arc.data.label}
                      getColor={(arc) => ordinalColorScale(arc.data.label)}
                      widgetTooltipArcProps={widgetTooltipArcProps}
                      getIsSelected={(arc) =>
                        filterField?.operation === otherFieldOperation
                          ? arc.data.label === otherFieldLabel
                          : filterField?.value === null ||
                            filterField?.value === undefined ||
                            filterField?.value?.includes(arc.data.label)}
                      onClickDatum={selectArc}
                      widgetProperties={widgetProperties}
                      getRadius={(arc) => {
                        return (radius / 100) * (arc.data?.radius === null ? 100 : arc.data?.radius );
                      }}
                      displayLegendLine={displayLegendLine}
                      zName={zName}
                      valueName={valueName}
                      isActiveFilter={isActiveFilter}
                    />
                  )}
                </Pie>
              </Group>
            </svg>
          </Box>
          {isLegendOnSide && !displayLegendLine && (
            <LegendArc
              widgetProperties={widgetProperties}
              colors={currentColors}
              valueName={valueName}
              zName={zName}
              data={widgetData}
              isSidePosition={true}
              style={{
                width: 'auto',
                marginLeft:
                  legendPosition === LegendPositions.Left ? '0px' : '50px',
                marginRight:
                  legendPosition === LegendPositions.Left ? '50px' : '0px',
                minWidth: 'auto',
                height: '100%',
                overflow: 'auto',
              }}
            />
          )}
        </Box>
      </div>
    </div>
  );
}
