import { CssVariables } from '../enums/css-variables';
import { Placement } from '../enums/visual-types';


export const isNeedToScaleUI = () => {
  const screenWidth = window.innerWidth;
  const limitConst = 1600;

  return screenWidth < limitConst;
};

export const getScaleCoefficient = () => {
  if (isNeedToScaleUI()) {
    return 0.68;
  }

  return 1;
};

export const getScaleCoefficientInPercent = () => {
  return getScaleCoefficient() * 100;
};

export const varcss = (variable: CssVariables) => `var(${variable})`;

export const backgroundColors = {
  [Placement.Widget]: varcss(CssVariables.WIDGET_BACKDROP_COLOR),
  [Placement.Dashboard]: varcss(CssVariables.MAIN_BACKDROP_COLOR),
  [Placement.Default]: 'var(--white)',
};
