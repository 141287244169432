import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { compact } from 'lodash';
import { getAxisValues, getParsedAxisValues } from './helpers/helpers';
import { PanelType, WidgetPropertyType } from '../../../enums/widget-type';
import { setWidgetPropAction } from '../../../slices/widget/widget';
import { insert } from '../../../utils/functions';

interface UsePanelsValues {
  widgetProperties: Array<any>;
}

const useAxisPropertiesCount = (value: string) => {
  const result = useMemo(() => {
    try {
      return JSON.parse(value).length;
    } catch (err: any) {
      return 0;
    }
  }, [value]);

  return result;
};


export const usePanelController = (properties: any[]) => {
  const dispatch = useDispatch();

  const moveTo = useCallback((fromIndex: number, toIndex: number, axisName: PanelType) => {
    const panelValues: any[] =  getParsedAxisValues(axisName, properties);

    panelValues.splice(toIndex, 0, panelValues.splice(fromIndex, 1)[0]);

    dispatch(
      setWidgetPropAction({
        name: axisName,
        value: JSON.stringify(compact(panelValues)),
      }),
    );

  }, [dispatch, properties]);

  const moveToAxis = useCallback((fromIndex: number, fromAxisName: PanelType, toIndex: number, toAxisName: PanelType) => {
    if (fromAxisName === toAxisName) {
      moveTo(fromIndex, toIndex, fromAxisName);
      return;
    }

    const fromPanelValues: any[] =  getParsedAxisValues(fromAxisName, properties);
    const toPanelValues: any[] =  getParsedAxisValues(toAxisName, properties);

    const insertValue = fromPanelValues[fromIndex];

    fromPanelValues.splice(fromIndex, 1);

    const resultToPanel: any[] = insert(toPanelValues, toIndex, insertValue);

    dispatch(
      setWidgetPropAction({
        name: fromAxisName,
        value: JSON.stringify(fromPanelValues),
      }),
    );

    dispatch(
      setWidgetPropAction({
        name: toAxisName,
        value: JSON.stringify(resultToPanel),
      }),
    );
  }, [dispatch, moveTo, properties]);

  return {
    moveTo,
    moveToAxis,
  };
};

const useAxisValue = (panelName: PanelType | WidgetPropertyType, properties: any[]) => {
  const result: string = useMemo(() => getAxisValues(panelName, properties), [
    properties, panelName
  ]);

  return result;
};

export const usePanelsValues = ({
  widgetProperties,
}: UsePanelsValues) => {
  const axisX: string = useAxisValue(PanelType.axisX, widgetProperties);
  const axisY: string = useAxisValue(PanelType.axisY, widgetProperties);
  const axisZ: string = useAxisValue(PanelType.axisZ, widgetProperties);
  const activeLegend: string = useAxisValue(WidgetPropertyType.activeLegend, widgetProperties);
  const filterAxis = useAxisValue(PanelType.axisFilter, widgetProperties);
  const dataSlice = useAxisValue(PanelType.dataSlice, widgetProperties);
  const displayTopColumn = useAxisValue(WidgetPropertyType.displayTopColumn, widgetProperties);
  const displayTopSlice = useAxisValue(WidgetPropertyType.displayTopSlice, widgetProperties);
  const displayTopPoints = useAxisValue(WidgetPropertyType.displayTopPoints, widgetProperties);
  const displayTopPieChart = useAxisValue(WidgetPropertyType.displayTopPieChart, widgetProperties);
  const displayTopTreeMap = useAxisValue(WidgetPropertyType.displayTopTreeMap, widgetProperties);
  const displayMaxRows = useAxisValue(WidgetPropertyType.displayMaxRows, widgetProperties);
  const nameStage = useAxisValue(PanelType.nameStage, widgetProperties);
  const dateEndStage = useAxisValue(PanelType.dateEndStage, widgetProperties);
  const progress = useAxisValue(PanelType.progress, widgetProperties);
  const gradientColoring = useAxisValue(WidgetPropertyType.gradientColoring, widgetProperties);
  const reverseGradient = useAxisValue(WidgetPropertyType.reverseGradient, widgetProperties);
  const displayOther = useAxisValue(WidgetPropertyType.displayOther, widgetProperties);

  const tableHeader = useAxisValue(WidgetPropertyType.tableHeader, widgetProperties);
  const pageSize: string = useAxisValue(WidgetPropertyType.pageSize, widgetProperties);
  const pageNumber: string = useAxisValue(WidgetPropertyType.pageNumber, widgetProperties);
  const pivotSortingField: string = useAxisValue(WidgetPropertyType.pivotSortingField, widgetProperties);
  const displayTotal: string = useAxisValue(WidgetPropertyType.displayTotal, widgetProperties);

  const axisXCount: number = useAxisPropertiesCount(axisX);
  const axisYCount: number = useAxisPropertiesCount(axisY);
  const axisZCount: number = useAxisPropertiesCount(axisZ);
  const filterCount = useAxisPropertiesCount(filterAxis);

  const nameStageCount = useAxisPropertiesCount(nameStage);
  const dateEndStageCount = useAxisPropertiesCount(dateEndStage);
  const progressCount = useAxisPropertiesCount(progress);

  return {
    axisX,
    axisY,
    axisZ,
    activeLegend,
    axisXCount,
    axisYCount,
    nameStage,
    dateEndStage,
    axisZCount,
    filterAxis,
    filterCount,
    displayTopColumn,
    displayTopSlice,
    displayTopPoints,
    dataSlice,
    nameStageCount,
    dateEndStageCount,
    progress,
    progressCount,
    tableHeader,
    pageNumber,
    pageSize,
    gradientColoring,
    reverseGradient,
    displayTopPieChart,
    displayTopTreeMap,
    displayMaxRows,
    displayOther,
    pivotSortingField,
    displayTotal,
  };
};
