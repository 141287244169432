import block from 'bem-cn';
import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import CustomTooltip from '../../uikit/CustomTooltip';
import IconSvg from '../common/icon-svg/icon-svg';
import './main-menu.css';
import IconsViewer from '../common/icons-viewer';
import useItemList from './useItemList';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';

const b = block('main-menu');

const MainMenu = () => {

  const navItems = useItemList();

  return navItems.length ? (
    <nav className={b()}>
      <div className={b('top')}>
        {navItems.map(({ link, img, name, isActive }) => (
          <CustomTooltip key={name} arrow title={name || ''}>
            <a
              key={link}
              href={link}
              className={clsx('main-menu__item', { 'main-menu__item_active': isActive })}
            >
              <img src={img} alt={name} />
            </a>
          </CustomTooltip>
        ))}
        <CustomTooltip arrow title="Руководство пользователя">
          <a
            href={`${process.env.PUBLIC_URL}/Руководство_пользователя_Алмаз_BI.pdf`}
            download={true}
            className={b('item').toString()}
            title="Скачать документацию"
          >
            <IconSvg
              svg={IconDictionary.GuideDownload}
              width={27}
              height={30}
              fill="var(--white)"
            />
          </a>
        </CustomTooltip>
        <CustomTooltip arrow title="Видео уроки на YouTube">
          <a
            href="https://www.youtube.com/playlist?list=PL0pb1WSAiGuhd75N0ZIlOMgJMlYeFUFbN"
            className={b('item').toString()}
            title="Видео уроки на YouTube"
            target="_blank"
            rel="noreferrer"
          >
            <IconSvg
              svg={IconDictionary.QuestionRounded}
              width={27}
              height={30}
              fill="var(--white)"
            />
          </a>
        </CustomTooltip>
      </div>
      {process.env.NODE_ENV === 'development' && <IconsViewer />}
    </nav>
  ) : null;
};

export default MainMenu;
