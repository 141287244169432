import { localPoint } from '@visx/event';
import { Bar } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { sort } from 'd3-array';
import { concat, isNil } from 'lodash';
import React, { useCallback } from 'react';
import Glyphs from '../components/glyphs';
import { bisectX, margin, tooltipStyles, tooltipTopOffset } from '../helper';
import {
  FormatAxisVerticalValue,
  FormatAxisXValue,
  GraphData,
  NearestVerticalValue,
  SeriesValue,
} from '../types';
import { useScales } from './useScales';

interface UseGraphTooltip {
  data: GraphData[];
  xValue: SeriesValue;
  yValues: SeriesValue[];
  zValues: SeriesValue[];
  scales: ReturnType<typeof useScales>;
  colors: string[];
  xMax: number;
  yMax: number;
  marginForAxisLeft: number;
  isNeedToDisplayTooltip: boolean;
  disableControl: boolean;
  formatAxisXValue: FormatAxisXValue;
  formatAxisYValue: FormatAxisVerticalValue;
  formatAxisZValue: FormatAxisVerticalValue;
  selectPoint?: (groupIndex: string) => void;
}

export const useGraphTooltip = ({
  data,
  xValue,
  yValues,
  zValues,
  scales,
  colors,
  xMax,
  yMax,
  marginForAxisLeft,
  isNeedToDisplayTooltip,
  disableControl,
  formatAxisXValue,
  formatAxisYValue,
  formatAxisZValue,
  selectPoint,
}: UseGraphTooltip) => {
  const { xScale, yScale, zScale, accessorX } = scales;

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<{ datum: GraphData; nearestValue: NearestVerticalValue }>();

  const tooltipLeftOffsetByData = tooltipData
    ? xScale(accessorX(tooltipData.datum))
    : 0;

  // tooltip handler
  const getCoordsAndDatumFromEvent = useCallback(
    (
      event:
      | React.TouchEvent<SVGRectElement>
      | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const xOf = x - marginForAxisLeft;
      const yOf = y - margin.top;
      const x0 = xScale.invert(xOf);
      const index = bisectX(accessorX)(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let datum = d0;
      if (d1 && accessorX(d1)) {
        datum =
          x0.valueOf() - accessorX(d0).valueOf() >
          accessorX(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }

      const sortedYZValue =
        datum &&
        sort(
          concat(
            datum.y.map((value, index) => ({
              key: yValues[index].key,
              axis: 'y',
              value,
              top: yScale(value),
              range: Math.abs(yOf - yScale(value)),
            })),
            datum.z.map((value, index) => ({
              key: zValues[index].key,
              axis: 'z',
              value,
              top: zScale(value),
              range: Math.abs(yOf - zScale(value)),
            })),
          )
            .filter((value) => isFinite(value.range))
            .map((value, index) => ({ ...value, color: colors[index] })),
          (v) => v.range,
        );

      return {
        x,
        xOf,
        y,
        yOf,
        // tooltipTop: sortedYZValue.top,
        datum,
        nearestValue: sortedYZValue[0],
      };
    },
    [
      accessorX,
      colors,
      data,
      marginForAxisLeft,
      xScale,
      yScale,
      yValues,
      zScale,
      zValues,
    ],
  );

  const handleTooltip = useCallback(
    (
      event:
      | React.TouchEvent<SVGRectElement>
      | React.MouseEvent<SVGRectElement>,
    ) => {
      const { xOf, yOf, datum, nearestValue } =
        getCoordsAndDatumFromEvent(event);
      datum &&
        showTooltip({
          tooltipData: { datum, nearestValue },
          tooltipLeft: xOf,
          tooltipTop: yOf,
        });
    },
    [getCoordsAndDatumFromEvent, showTooltip],
  );

  const handleClick = useCallback(
    (
      event:
      | React.TouchEvent<SVGRectElement>
      | React.MouseEvent<SVGRectElement>,
    ) => {
      event.stopPropagation();
      const { datum } = getCoordsAndDatumFromEvent(event);
      // тут в будущем нужно будет реализовать работу DD
      selectPoint && selectPoint(datum.x);
    },
    [getCoordsAndDatumFromEvent, selectPoint],
  );

  const EventControllerBar = useCallback(
    () =>
      isNeedToDisplayTooltip && !disableControl ? (
        <Bar
          style={{ cursor: selectPoint ? 'pointer' : undefined }}
          x={0}
          y={0}
          width={xMax}
          height={yMax}
          fill="transparent"
          rx={14}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
          onMouseUp={handleClick}
        />
      ) : null,
    [
      disableControl,
      handleClick,
      handleTooltip,
      hideTooltip,
      isNeedToDisplayTooltip,
      xMax,
      yMax,
    ],
  );

  const GraphTooltipGlyphs = useCallback(
    () =>
      tooltipData ? (
        <Glyphs
          leftLineOffset={tooltipLeft}
          height={yMax}
          datum={tooltipData.datum}
          nearestValue={tooltipData.nearestValue}
          yValues={yValues}
          zValues={zValues}
          xScale={xScale}
          yScale={yScale}
          zScale={zScale}
          accessorX={accessorX}
        />
      ) : null,
    [
      accessorX,
      tooltipData,
      tooltipLeft,
      xScale,
      yMax,
      yScale,
      yValues,
      zScale,
      zValues,
    ],
  );

  const Tooltip = useCallback(
    () =>
      tooltipData ? (
        <div style={{ position: 'static' }}>
          <TooltipWithBounds
            key={Math.random()}
            left={(tooltipLeftOffsetByData || 0) + marginForAxisLeft}
            top={(tooltipTop || 0) + tooltipTopOffset}
            style={tooltipStyles}
          >
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>{xValue.name}:</td>
                  <td>
                    &nbsp;{formatAxisXValue(accessorX(tooltipData.datum))}
                  </td>
                </tr>
                {yValues.length > 0 &&
                  tooltipData.datum.y.map((value, index) => {
                    if (isNil(value) || !yValues[index]) return null;

                    return (
                      <tr
                        key={value}
                        style={{
                          fontWeight:
                            tooltipData.nearestValue.key === yValues[index].key
                              ? 'bold'
                              : undefined,
                        }}
                      >
                        <td>
                          <div
                            style={{
                              display: 'inline-block',
                              width: '0.75em',
                              height: '0.75em',
                              backgroundColor: yValues[index].color,
                              borderRadius: '50%',
                              marginRight: 5,
                            }}
                          />
                        </td>
                        <td>{yValues[index].name}:</td>
                        <td>&nbsp;{formatAxisYValue(value)}</td>
                      </tr>
                    );
                  })}
                {zValues.length > 0 &&
                  tooltipData.datum.z.map((value, index) => {
                    if (isNil(value) || !zValues[index]) return null;

                    return (
                      <tr
                        key={value}
                        style={{
                          fontWeight:
                            tooltipData.nearestValue.key === zValues[index].key
                              ? 'bold'
                              : undefined,
                        }}
                      >
                        <td>
                          <div
                            style={{
                              display: 'inline-block',
                              width: '0.75em',
                              height: '0.75em',
                              backgroundColor: zValues[index].color,
                              borderRadius: '50%',
                              marginRight: 5,
                            }}
                          />
                        </td>
                        <td>{zValues[index].name}:</td>
                        <td>&nbsp;{formatAxisZValue(value)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </TooltipWithBounds>
        </div>
      ) : null,
    [
      accessorX,
      formatAxisXValue,
      formatAxisYValue,
      formatAxisZValue,
      marginForAxisLeft,
      tooltipData,
      tooltipLeftOffsetByData,
      tooltipTop,
      xValue.name,
      yValues,
      zValues,
    ],
  );

  return {
    EventControllerBar,
    TooltipGlyphs: GraphTooltipGlyphs,
    Tooltip,
  };
};
