import { ShowNotificationParams } from 'src/components/common/snackbar/hooks';
import { apiValidateComputedField, apiValidateWhereCondition } from 'src/services/loadersController';
import MapConnection from '../../types/map-connection';
import { Operation } from '../../enums/operation';
import { State } from '../../slices/types';
import Connection from '../../types/connection';
import { CommonDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';
import { apiResetPushDown } from '../../services/widgetController';

export const createUnionMappingToSave = (
  fieldsMapping: MapConnection.FieldsMapping[],
  outputId: string,
  processedFields: MapConnection.ProcessedField[],
): MapConnection.FieldsMapping[] => {
  const rawFieldsMapping = fieldsMapping.map((item) => ({
    ...item,
    target: {
      fieldName: item.sourceFieldName.fieldName,
      fieldId: (
        getDisplayedNameByAliasOrName(
          item.sourceFieldName.fieldName,
          processedFields,
          true,
        ) as MapConnection.ProcessedField
      )?.id,
      sourceObjectId: outputId,
    },
  }));
  const tableFirstFieldsMapping = rawFieldsMapping.map((item) => ({
    keyForMerge: item.keyForMerge,
    loaderStepId: item.loaderStepId,
    sourceFieldName: item.sourceFieldName,
    targetField: item.target,
    usedForGrouping: item.usedForGrouping,
  }));
  const tableSecondFieldsMapping = rawFieldsMapping.map((item) => ({
    keyForMerge: item.keyForMerge,
    loaderStepId: item.loaderStepId,
    sourceFieldName: item.targetField,
    targetField: item.target,
    usedForGrouping: item.usedForGrouping,
  }));
  return [...tableFirstFieldsMapping, ...tableSecondFieldsMapping];
};

export const createUnionMappingToDisplay = (fieldsMapping: MapConnection.FieldsMapping[],
  sourceObjectId: string, targetObjectId: string) => {
  const targetFields: MapConnection.FieldsMapping[] = fieldsMapping
    .filter(item => item.sourceFieldName.sourceObjectId === targetObjectId);

  const rawFieldsMapping: MapConnection.FieldsMapping[] = fieldsMapping
    .filter(item => item.sourceFieldName.sourceObjectId === sourceObjectId)
    .map((item, i) => {
      return {
        ...item,
        targetField: targetFields[i].sourceFieldName
      };
    });
  return rawFieldsMapping;
};

export const getUnionResultDisplayedNames = (
  sourceIds: string[],
  fieldsMapping: MapConnection.FieldsMapping[],
  sourceObjects: MapConnection.SourceObject[],
  namesAsIds: boolean = false,
) => {
  const sourceObjectId = sourceIds[0];
  const targetObjectId = sourceIds[1];

  const sourceObject = sourceObjects.find((sourceObject) => sourceObjectId === sourceObject.id);

  const fieldsMappingToCalculate = createUnionMappingToDisplay(
    fieldsMapping,
    sourceObjectId,
    targetObjectId,
  );

  const resultFieldNames = fieldsMappingToCalculate.map((field) => {
    const name = getDisplayedNameByAliasOrName(
      field.sourceFieldName.fieldName,
      (sourceObject as MapConnection.SourceObject).processedFields,
      namesAsIds,
    );
    if (namesAsIds) {
      return (name as MapConnection.ProcessedField).id;
    }
    return name;
  });

  return resultFieldNames;
};

export const getDisplayedNameByAliasOrName = (
  name: string,
  processedFields: MapConnection.ProcessedField[],
  returnObject?: boolean,
) => {
  let result;

  result = processedFields.find((field) => {
    return field.alias === name;
  });
  if (result !== undefined) return !returnObject ? result?.displayedName : result;

  result = processedFields.find((field) => {
    return field.displayedName === name;
  });

  return !returnObject ? result?.displayedName : result;
};

export const getStepIdsData = (sourceObject: MapConnection.SourceObject | undefined, steps: MapConnection.Step[], sourceObjects: MapConnection.SourceObject[]) => {
  type StepIdData = {
    target: string,
    source: string
  } | object;

  let stepIdData: StepIdData[] = [];

  const stepsIdsData: {
    [key: string]: StepIdData[],
  } = {};

  steps.forEach((currentStep) => {
    const sourceObjectId = currentStep.inputSourceObjectsIds[0];
    const targetObjectId =
      currentStep.operation.type === Operation.Map
        ? currentStep.outputSourceObjectId : currentStep.inputSourceObjectsIds[1];

    const currentSourceProcessedFields = sourceObjects.find(
      (sourceObject) => sourceObject.id === sourceObjectId,
    )?.processedFields || [];

    const currentTargetProcessedFields = sourceObjects.find(
      (sourceObject) => sourceObject.id === targetObjectId,
    )?.processedFields || [];

    let currentFieldsMapping = currentStep?.fieldsMapping;
    if (currentStep?.operation.type === Operation.Union) {
      currentFieldsMapping = createUnionMappingToDisplay(
        currentFieldsMapping,
        sourceObjectId,
        targetObjectId,
      );
    }

    currentFieldsMapping.forEach((fieldMapping: any) => {
      const targetFieldName = fieldMapping.targetField.fieldName;
      const sourceFieldName = fieldMapping.sourceFieldName.fieldName;

      const targetId = currentTargetProcessedFields.find(
        (processedField) =>
          targetFieldName === processedField.alias ||
          targetFieldName === processedField.displayedName,
      )?.id;

      const sourceId = currentSourceProcessedFields.find(
        (processedField) =>
          sourceFieldName === processedField.alias ||
          sourceFieldName === processedField.displayedName,
      )?.id;

      const data = {
        target: targetId,
        source: sourceId,
      };

      stepIdData.push(data);
    });

    stepsIdsData[currentStep?.id] = [...stepIdData];
    stepIdData = [];
  });

  return stepsIdsData;
};


export const getStepsBySourceId = (
  getState: () => State,
  loaderId: number,
  sourceId: string,
) => {
  const sourceData = getSourceData(getState, loaderId, sourceId);
  return getState().mapConnection[loaderId].steps.filter((step) =>
    step.inputSourceObjectsIds.includes(sourceData?.id || ''),
  );
};

export const getSourceData = (getState: () => State, loaderId: number, sourceId: string) => {
  return getState().mapConnection[loaderId].sourceObjects.find(
    (sourceObject) => sourceObject.id === sourceId,
  );
};

export const resultSourcesNames = [CommonDictionary.UnionResult, CommonDictionary.JoinResult];

export const getCorrectListForSourceSearch = (sourceList: MapConnection.SourceObject[]) => {

  const resultSources: {[key: string]: MapConnection.SourceObject[]} = {};

  const correctSourceList: MapConnection.SourceObject[] =
    sourceList.filter((source) => !resultSourcesNames.includes(source.name));

  resultSourcesNames.forEach((resName) => {
    resultSources[resName] = sourceList
      .filter((source) => source.name === resName);

    resultSources[resName].length && correctSourceList.push({
      id: '-1',
      name: resName,
      initialName: resName,
    } as MapConnection.SourceObject);
  });

  return {
    correctSourceList,
    resultSources,
  };
};

export const isNotRenamedResult = (name: string) => {
  resultSourcesNames.forEach((resName) => {
    if (resName === name) {
      return true;
    }
  });

  return false;
};

export const isResult = (name: string) => {
  return resultSourcesNames.includes(name);
};


export const getSourceIdsForSourceFilter = (
  sourceObject: MapConnection.SourceObject,
  resultSources: { [key: string]: MapConnection.SourceObject[] },
) => {
  if (isResult(sourceObject.initialName as string)) {
    const resultSourcesIds = resultSources[sourceObject.initialName as string]
      .filter((source) => isNotRenamedResult(source.name))
      .map((source) => source.id);

    return isNotRenamedResult(sourceObject.name) ? resultSourcesIds : [sourceObject.id];
  }

  return [sourceObject.id];
};

export const getConnectionCategory = (id: number, data: Connection.Meta[]) => {
  return data.find((item) => item.id === id)?.category || '';
};

export const isNeedActivatePushDown = (category: string) => {
  const connectionTypesWithAutoPushDown = ['SQL'];
  return (
    category.length > 0 && connectionTypesWithAutoPushDown.includes(category)
  );
};

export const getNextSourceObjectsInChain = (
  steps: MapConnection.Step[],
  sourceId: string,
  sourceObjects: MapConnection.SourceObject[],
) => {
  const currentSteps = steps.filter((step) =>
    step.inputSourceObjectsIds.includes(sourceId),
  );

  if (!currentSteps.length) return [];

  const currentSourceObjects: MapConnection.SourceObject[] = [];

  currentSteps.forEach((step) => {
    const sourceObject = sourceObjects.find(
      (item) => item.id === step.outputSourceObjectId,
    );
    sourceObject && currentSourceObjects.push(sourceObject);
  });

  return currentSourceObjects;
};

export const getChainOfSources = (
  sourceObjects: MapConnection.SourceObject[],
  steps: MapConnection.Step[],
  startSourceId: string,
  filterOptions?: {
    name: keyof MapConnection.SourceObject;
    value: any;
  }[],
) => {
  const chainOfSources: MapConnection.SourceObject[] = [];

  const startSourceObject = sourceObjects.find(
    (item) => item.id === startSourceId,
  );
  let currentSourceObjects = startSourceObject ? [startSourceObject] : [];
  let nextSourceObjects: MapConnection.SourceObject[] = [];

  while (currentSourceObjects.length > 0) {
    currentSourceObjects.forEach((item) => {
      chainOfSources.push(item);
      nextSourceObjects.push(
        ...getNextSourceObjectsInChain(steps, item.id, sourceObjects),
      );
    });

    currentSourceObjects = nextSourceObjects;
    nextSourceObjects = [];
  }

  return filterOptions
    ? chainOfSources.filter((item) => {
        let isNeedToReturn = true;
        filterOptions.forEach((filter) => {
          if (item[filter.name] !== filter.value) {
            isNeedToReturn = false;
          }
        });
        return isNeedToReturn;
      })
    : chainOfSources;
};

export const updatePushdownOfSourceObjectsChain = async (data: MapConnection.Data, startSourceId: string) => {
  const { steps, sourceObjects } = data;
  const sourceObjectsIdsForCheck: string[] = getChainOfSources(
    sourceObjects,
    steps,
    startSourceId,
    [
      {
        name: 'needPushDown',
        value: false,
      },
    ],
  ).map((item) => item.id);

  if (sourceObjectsIdsForCheck.length) {
    await apiResetPushDown(sourceObjectsIdsForCheck);
  }
};

export const validateComputedFields = (
  computedField: MapConnection.ComputedField,
  showNotification: ({ message, variant }: ShowNotificationParams) => void,
  callback: () => void,
) => {
  apiValidateComputedField(computedField)
    .then(() => {
      callback();
    })
    .catch((error) => {
      showNotification({
        message: error.response?.data?.message || ' Некорректная формула',
        variant: 'error',
      });
    });
};

export const validateWhereCondition = (
  whereCondition: MapConnection.WhereCondition,
  sourceObjectId: string,
  showNotification: ({ message, variant }: ShowNotificationParams) => void,
  callback: () => void,
) => {
  apiValidateWhereCondition(whereCondition, sourceObjectId)
    .then(() => {
      callback();
    })
    .catch((error) => {
      showNotification({
        message: error.response?.data?.message || 'Неизвестная ошибка',
        variant: 'error',
      });
    });
};
