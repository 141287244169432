import React from 'react';
import './heat-map-modal.css';
import { mapModalTypes } from '../../enums';
import { CustomButton } from '../../../../../uikit/Button';
import { useHeatMapModal } from './useHeatMapModal';
import { MainBlock } from './components/main-block';
import { MapModalDialog } from '../map-modals/map-modal-dialog/map-modal-dialog';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface HeatMapModalProps {
  etlSourceId: string;
  sourceDisplayName: string;
  onClose: (type: mapModalTypes) => void;
}

export enum HeatMapGradientDefaultColors {
  green = '#0CC009',
  yellow = '#F5D734',
  red = '#F44545',
}

export const DefaultStartColorForGradientColoring = '#ffffff';

const HeatMapModal = ({
  onClose,
  sourceDisplayName,
  etlSourceId,
}: HeatMapModalProps) => {
  const {
    mainSettingsList,
    currentWidgetField,
    changeCurrentWidgetField,
    gradientSettings,
    gradient,
    handleSave,
    handleClose,
    isLoading,
    handleWrapperClick,
    changeAggregationCallback,
  } = useHeatMapModal({ etlSourceId, onClose });

  return (
    <MapModalDialog open={true} onClick={handleWrapperClick}>
      <div className="heat-map-settings">
        <div className="heat-map-settings__header">
          <h3 className="heat-map-settings__title">{sourceDisplayName}</h3>
          <CustomButton
            variant="outlined"
            icon={
              <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
            }
            onClick={handleClose}
          />
        </div>

        <MainBlock
          mainSettingsList={mainSettingsList}
          changeCurrentWidgetField={changeCurrentWidgetField}
          etlSourceId={etlSourceId}
          currentWidgetField={currentWidgetField}
          gradientSettings={gradientSettings}
          gradient={gradient}
          changeAggregationCallback={changeAggregationCallback}
        />

        <div className="dialog-buttons">
          <CustomButton
            disabled={isLoading || !currentWidgetField}
            loading={isLoading}
            onClick={handleSave}
            variant="contained"
          >
            Сохранить
          </CustomButton>
          <CustomButton
            disabled={isLoading}
            type="reset"
            onClick={handleClose}
            variant="outlined"
          >
            Отменить
          </CustomButton>
        </div>
      </div>
    </MapModalDialog>
  );
};

export default HeatMapModal;
