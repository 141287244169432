import { useGetBooleanWidgetProperty } from '../../../../hooks/get-properties/useGetWidgetProperty';
import {
  getAxisValuesForWidget,
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  getLegendsLabelsForWidgetByAxis,
  getSelectPropertyValue,
} from '../helpers';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { textDirections } from '../../../common/scalable-svg-text';

export enum displayValuesPositions {
  inside = 'Внутри столбцов',
  outside = 'Над столбцами',
}

export const verticalMargin = 80;

export const stageSettings = {
  top: 20,
  left: 20,
  right: 50,
  minWidthStep: 90,
};

export const leftAxisSettings = {
  color: 'var(--grey)',
  left: 65,
  right: 65,
  top: 23,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'end',
};

export const bottomAxisSettings = {
  color: 'var(--grey)',
  left: stageSettings.left,
  top: -52,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'center',
};

export const barValueStyles = {
  outsideColor: 'var(--black)',
  insideColor: 'var(--white)',
  fontSize: 18,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 21,
  textAlign: 'center',
  wordBreak: 'break-all',
  pointerEvents: 'none'
};

export const barSettings = {
  innerTextTop: 20,
  outerTextTopOffset: 5,
  outerTextBottomOffset: 20,
};

export const gridLinesColor = '#D8D8D8';
export const gridLinesXOffset = 10;
export const gridLinesYOffset = 4;

export const xScalePadding = 0.2;
export const valueScalePadding = 0.2;
export const customLeftShiftGraphPoint = 15;

export const debounceDelay = 100;
export const circleParams = {
  radius: 8,
};

export const defaultOffsetValue = 10;

export const getWidgetProperties = (widgetProperties: any) => {
  const roundingCountString: string = getInputPropertyValue(
    'roundingCount',
    widgetProperties,
  );

  const isNeedToDisplayAvgAxisY: boolean = getBooleanPropertyValue(
    'displayAvgAxisY',
    widgetProperties,
  );

  const axisXValues: Array<string> = getAxisValuesForWidget(
    PanelType.axisX,
    widgetProperties,
  );

  const isNeedDisplayAggregationInLegend = getBooleanPropertyValue(
    'displayAggregationInLegend',
    widgetProperties,
  );

  const axisXValuesFull: Array<any> = getParsedAxisValues(
    PanelType.axisX,
    widgetProperties,
  );

  const axisYValues: Array<string> = getAxisValuesForWidget(
    PanelType.axisY,
    widgetProperties,
  );

  const axisZValues: Array<string> = getAxisValuesForWidget(
    PanelType.axisZ,
    widgetProperties,
  );

  const legendsLabels = getLegendsLabelsForWidgetByAxis(PanelType.axisY, widgetProperties, isNeedDisplayAggregationInLegend);

  const legendsZLabels = getLegendsLabelsForWidgetByAxis(PanelType.axisZ, widgetProperties, isNeedDisplayAggregationInLegend);

  const displayValuesPosition: string =
    getSelectPropertyValue('displayValuesPosition', widgetProperties) ||
    displayValuesPositions.outside;

  const isNeedToDisplayBarValues: boolean = getBooleanPropertyValue(
    'displayBarValues',
    widgetProperties,
  );

  const isNeedToDisplayTooltip: boolean = getBooleanPropertyValue(
    'displayTooltip',
    widgetProperties,
  );

  const isNeedToDisplayValuesOnGraph: boolean = getBooleanPropertyValue(
    'displayValuesOnGraph',
    widgetProperties,
  );

  const isNeedToDisplayOutsideBarValues: boolean =
    isNeedToDisplayBarValues &&
    displayValuesPosition === displayValuesPositions.outside;

  const isNeedToDisplayInsideBarValues: boolean =
    isNeedToDisplayBarValues &&
    displayValuesPosition === displayValuesPositions.inside;

  const isScaleByValueState: boolean = getBooleanPropertyValue(
    'scaleByValue',
    widgetProperties,
  );

  const isNeedToDisplayAxesGuide: boolean = getBooleanPropertyValue(
    'displayAxesGuide',
    widgetProperties,
  );

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );

  const isSmoothedLine: boolean = getBooleanPropertyValue(
    'smoothedLine',
    widgetProperties,
  );

  const customAxisYLabelWidth = parseInt(
    getInputPropertyValue('axisYLabelWidth', widgetProperties),
  );

  const customAxisZLabelWidth = parseInt(
    getInputPropertyValue('axisZLabelWidth', widgetProperties),
  );

  const customAxisXLabelHeight = parseInt(
    getInputPropertyValue('axisXLabelHeight', widgetProperties),
  );

  const columnWidth = parseInt(
    getInputPropertyValue('columnWidth', widgetProperties),
  );

  const textDirection: string =
    getSelectPropertyValue('TextDirection', widgetProperties) ||
    textDirections.horizontal;

  const offsetValueTotal = parseInt(
    getInputPropertyValue(WidgetPropertyType.offsetValueTotal, widgetProperties),
  ) || defaultOffsetValue;

  const isSingleScale: boolean = getBooleanPropertyValue(
    WidgetPropertyType.singleScale,
    widgetProperties,
  );

  return {
    roundingCountString,
    isNeedToDisplayAvgAxisY,
    axisXValues,
    isNeedDisplayAggregationInLegend,
    axisYValues,
    axisXValuesFull,
    legendsLabels,
    legendsZLabels,
    displayValuesPosition,
    isNeedToDisplayBarValues,
    isNeedToDisplayTooltip,
    isNeedToDisplayOutsideBarValues,
    isNeedToDisplayInsideBarValues,
    isScaleByValueState,
    isNeedToDisplayAxesGuide,
    colorsPaletteState,
    isSmoothedLine,
    customAxisYLabelWidth,
    customAxisZLabelWidth,
    customAxisXLabelHeight,
    columnWidth,
    isNeedToDisplayValuesOnGraph,
    textDirection,
    axisZValues,
    offsetValueTotal,
    isSingleScale,
  };
};
