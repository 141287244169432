import React from 'react';
import { SelectOptions, SelectOptionsMeta } from '../../type';
import Validation from '../../validation/type';
import { useStyles } from './styles';
import ButtonsSection from './buttons-section/buttons-section';
import FieldListSection from './field-list-section/field-list-section';

interface FormSectionProps {
  fieldsMapping: any[];
  handleSelectChange: (e: any, i: any, type: string) => void;
  handleKeyClick: (i: any) => void;
  needDrawKeyPicture: boolean;
  selectOptions: SelectOptions;
  selectOptionsMeta: SelectOptionsMeta;
  editableFields: any;
  setEditableFields: any;
  handleCancelInputClick: () => void;
  handleDeleteConnection: (i: any) => () => void;
  handleSaveInputClick: (i: any) => () => void;
  handleInputChange: (e: any, i: any) => void;
  onClose: () => void;
  handleSaveButtonClick: () => void;
  handleValidationButtonClick: () => void;
  handleAddConnection: () => void;
  isNewTarget: boolean;
  validation: Validation.Data;
  operationType: string;
  isLoading?: boolean;
  isEditableMap: boolean;
}

const FormSection: React.FC<FormSectionProps> = ({
  fieldsMapping,
  handleSelectChange,
  handleKeyClick,
  needDrawKeyPicture,
  selectOptions,
  selectOptionsMeta,
  editableFields,
  setEditableFields,
  isNewTarget,
  validation,
  operationType,
  handleCancelInputClick,
  handleSaveButtonClick,
  handleValidationButtonClick,
  handleDeleteConnection,
  onClose,
  handleAddConnection,
  handleSaveInputClick,
  handleInputChange,
  isEditableMap,
  isLoading = false
}) => {
  const classes = useStyles();
  return (
    <form className={classes.root} autoComplete="off">
      <FieldListSection
        fieldsMapping={fieldsMapping}
        handleSelectChange={handleSelectChange}
        handleKeyClick={handleKeyClick}
        needDrawKeyPicture={needDrawKeyPicture}
        selectOptions={selectOptions}
        selectOptionsMeta={selectOptionsMeta}
        editableFields={editableFields}
        setEditableFields={setEditableFields}
        isNewTarget={isNewTarget}
        validation={validation}
        operationType={operationType}
        handleCancelInputClick={handleCancelInputClick}
        handleDeleteConnection={handleDeleteConnection}
        handleSaveInputClick={handleSaveInputClick}
        handleInputChange={handleInputChange}
        isEditableMap={isEditableMap}
      />
      <ButtonsSection
        isEditableMap={isEditableMap}
        onClose={onClose}
        handleSaveButtonClick={handleSaveButtonClick}
        handleValidationButtonClick={handleValidationButtonClick}
        handleAddConnection={handleAddConnection}
        isLoading={isLoading}
      />
    </form>
  );
};

export default FormSection;
