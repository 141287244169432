import { WidgetProperties } from '../../../slices/types';
import { WidgetType } from '../../../enums/widget-type';
import Dictionaries from '../../../types/dictionaries';
import { getSimplifiedType } from '../dropdown-layout/helpers/helpers';
import { PropertyData } from '../dropdown-layout/helpers/Property';

export const getPropertiesForSortableContext = (panel: WidgetProperties) => {
  return JSON.parse(panel.value || '[]').map(
    (value: PropertyData, index: number) =>
      `${JSON.stringify({ ...value, uniqueId: index, axisName: panel.name })}`,
  );
};

export const getHiddenElementsTooltip = (hiddenElements: PropertyData[]) => {
  return hiddenElements
    .filter((item) => !item.hierarchyLink?.dicSourceId)
    .map((item) => {
      return `${item.name} (${item.id || item.widgetFieldId})`;
    })
    .join('\n');
};

export const widgetsWithRestriction = [
  WidgetType.HISTOGRAM,
  WidgetType.TABLE,
  WidgetType.PIE_CHART,
  WidgetType.DONUT_CHART,
  WidgetType.SPEEDOMETER,
  WidgetType.HISTOGRAM_GRAPH,
  WidgetType.GRAPH,
  WidgetType.GRAPH_AREA,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.STACKED_HISTOGRAM,
  WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
  WidgetType.PIVOT_TABLE,
  WidgetType.INFORMATION_CARD,
  WidgetType.FUNNEL,
  WidgetType.SUNBURST,
  WidgetType.RADAR_CHART,
  WidgetType.TREE_MAP,
  WidgetType.BUBBLE_CHART,
];

export const widgetsWithDrilldownSetting = [
  WidgetType.TABLE,
];

export const getFunctionsAndAggregations = (
  functionsAndAggregationsByTypes: Dictionaries.FunctionOrAggregationObjectByTypes,
  panelItemString: string,
) => {
  const parsedPanelItem: PropertyData = JSON.parse(panelItemString);
  const type = getSimplifiedType(parsedPanelItem.type);

  return getFunctionsAndAggregationsByType(functionsAndAggregationsByTypes, type);
};

export const getFunctionsAndAggregationsByType = (
  functionsAndAggregationsByTypes: Dictionaries.FunctionOrAggregationObjectByTypes,
  type: string,
) => {
  return (
    functionsAndAggregationsByTypes[type] || { functions: [], aggregations: [] }
  );
};

export const isShowRestriction = (
  widgetType: WidgetType,
  isPanelWithRestriction: boolean,
  isComplex: boolean,
) => {
  return (
    widgetsWithRestriction.includes(widgetType) &&
    isPanelWithRestriction &&
    !isComplex
  );
};

export const isHiddenPanelItem = (hiddenElements: PropertyData[], element: string) => {
  const panelItem = JSON.parse(element);
  return hiddenElements.find((item) => item.etlFieldId === panelItem.etlFieldId);
};
