import { useCallback, useEffect, useState } from 'react';
import { WidgetProperties } from '../../../../../slices/types';
import { useMapProperties } from './useMapProperties';

interface UseMapActiveLegendProps {
  widgetProperties: WidgetProperties[];
}

export const useMapActiveLegend = ({
  widgetProperties,
}: UseMapActiveLegendProps) => {
  const { axisXValues } = useMapProperties({ widgetProperties });

  const [activeLayers, setActiveLayers] = useState<number[]>(
    axisXValues.map((value, index) => index),
  );

  const handleClickLegendItem = useCallback(
    (layerIndex: number) => {
      if (activeLayers.includes(layerIndex)) {
        setActiveLayers(activeLayers.filter((index) => index !== layerIndex));
      } else {
        setActiveLayers([...activeLayers, layerIndex]);
      }
    },
    [activeLayers, setActiveLayers],
  );

  useEffect(() => {
    setActiveLayers(axisXValues.map((value, index) => index));
  }, [axisXValues]);

  return {
    activeLayers,
    handleClickLegendItem,
  };
};
