import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../slices/types';
import Card from '../../../uikit/Card';
import { CardContent } from '../../../uikit/CardContent';
import WidgetContainer from './widget-container';
import { WidgetHeader } from '../widget-header';
import { WidgetType } from '../../../enums/widget-type';
import CardTopPanel from '../../common/card-top-panel';

interface WidgetProps {
  type: string;
  isLoading: boolean;
  errorText?: string;
}

const Widget = ({ type, isLoading, errorText }: WidgetProps) => {
  const widgetData = useSelector((state: State) => state.mainPage.currentProjectWidgetData);
  const widgetProps = { ...useSelector((state: State) => state.widget) };
  widgetProps.type = type as WidgetType;

  return (
    <>
      <Card className="widget-main__chart">
        <WidgetHeader widgetProps={widgetProps} />
        <CardTopPanel
          widgetProperties={widgetProps.properties}
          widgetDataProperties={widgetData?.properties}
          widgetType={widgetProps.type}
          widgetId={widgetProps.id || 0}
          setOverriddenProperties={() => null}
        />
        <CardContent widgetType={widgetProps.type}>
          <WidgetContainer
            widgetProps={widgetProps}
            widgetData={widgetData}
            isLoading={isLoading}
            errorText={errorText}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default Widget;
