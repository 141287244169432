import { useDispatch, useSelector } from 'react-redux';
import { PanelType, WidgetPropertyType } from '../../enums/widget-type';
import { State, WidgetPayload } from '../../slices/types';
import { setWidgetAPIToDashboard } from '../../slices/dashboard/dashboard';
import { getPanelIndex } from '../../components/widget-page/dropdown-layout/helpers/helpers';

export const useWidgetPropertiesChangeOnDashboard = () => {
  const dispatch = useDispatch();

  const dashboardWidgets = useSelector(
    (state: State) => state.dashboard.widgets,
  );

  const changePropertyOnDashboard = (
    widgetId: number,
    newProperties: WidgetPayload[],
  ) => {
    const widget = dashboardWidgets[widgetId];

    if (!widget) return;

    const properties = [...widget.data.properties];

    newProperties.forEach((property) => {
      const propertyIndex = getPanelIndex({
        properties,
        type: property.name as PanelType | WidgetPropertyType,
      });
      if (propertyIndex === -1) {
        properties.push({
          name: property.name,
          value: property.value,
        });
      } else {
        properties[propertyIndex] = { ...properties[propertyIndex], value: property.value };
      }
    });

    dispatch(
      setWidgetAPIToDashboard({
        widgetId: `${widgetId}`,
        params: {
          data: { ...widget.data, properties },
        },
      }),
    );
  };

  return { changePropertyOnDashboard };
};
