import { batch, useDispatch } from 'react-redux';
import { PanelType, WidgetPropertyType } from '../../../../../../enums/widget-type';
import { WidgetProperties } from '../../../../../../slices/types';
import { setWidgetPropAction, removeWidgetPropAction } from '../../../../../../slices/widget/widget';
import { getParsedAxisValues } from '../../../../dropdown-layout/helpers/helpers';
import { PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { getJSONPropertyValue, isDashboardPage } from '../../../helpers';

interface PivotSortingField {
  fieldName: string;
  orderDirection: 'ASC' | 'DESC';
}

export const useColumnSorting = ({
  widgetProperties,
  setDashboardWidgetState
}: {
  widgetProperties: WidgetProperties[];
  setDashboardWidgetState?: (properties: WidgetProperties[]) => void;
}) => {
  const dispatch = useDispatch();

  const sortingField: PivotSortingField = getJSONPropertyValue(
    WidgetPropertyType.pivotSortingField,
    widgetProperties,
    true,
  );

  const getClearedRowsSortAxis = () => {

    const axisValues: PropertyData[] = getParsedAxisValues(PanelType.axisX, widgetProperties);

    const newAxisValues: PropertyData[] = [...axisValues];

    axisValues.forEach((v, index) => {
      newAxisValues[index].orderDirection = null;
    });

    return {
      name: PanelType.axisX,
      value: JSON.stringify(newAxisValues),
    };
  };


  const setSortingWidget = (fieldName: string, orderDirection?: 'ASC' | 'DESC')  => {
    if (orderDirection) {
      batch(() => {
        dispatch(
          setWidgetPropAction({
            name: WidgetPropertyType.pivotSortingField,
            value: JSON.stringify({ fieldName, orderDirection }),
          }),
        );
        // очищаем дополнительно сортировку по строкам
        dispatch(
          setWidgetPropAction(getClearedRowsSortAxis()),
        );
      });
    } else {
      dispatch(removeWidgetPropAction(WidgetPropertyType.pivotSortingField));
    }
  };

  const setSortingDashboard = (fieldName: string, orderDirection?: 'ASC' | 'DESC') => {
    if (!setDashboardWidgetState) return;

    if (orderDirection) {
      setDashboardWidgetState([
        {
          name: WidgetPropertyType.pivotSortingField,
          value: JSON.stringify({ fieldName, orderDirection }),
        },
        // очищаем дополнительно сортировку по строкам
        getClearedRowsSortAxis()
      ]);
    } else {
      setDashboardWidgetState([
        {
          name: WidgetPropertyType.pivotSortingField,
          value: undefined,
        },
        // очищаем дополнительно сортировку по строкам
        getClearedRowsSortAxis()
      ]);
    }
  };

  const setSorting = isDashboardPage() ? setSortingDashboard : setSortingWidget;

  const setFieldSorting = (fieldName: string | null) => {

    if (fieldName === null) {
      setSorting('');
      return;
    }

    if (fieldName === sortingField.fieldName) {
      switch (sortingField.orderDirection) {
        case 'ASC':
          setSorting(fieldName, 'DESC');
          break;
        case 'DESC':
          setSorting(fieldName);
          break;
        default:
          setSorting(fieldName, 'ASC');
          break;
      }
    } else {
      setSorting(fieldName, 'ASC');
    }
  };

  return { sortingField, setFieldSorting };
};
