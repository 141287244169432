import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Property } from '../../dropdown-layout/helpers/Property';
import { PanelType, WidgetType } from '../../../../enums/widget-type';
import {
  getAxisValues,
  getChangedPropertiesAfterPanelItemDelete,
  getChangedPropertiesWithTableHeader,
  getIsNeedSyncFiltersAxis,
  getPanelIndex,
  getSimplifiedType,
} from '../../dropdown-layout/helpers/helpers';
import { setWidgetPropsAction } from '../../../../slices/widget/widget';
import { Widget } from '../../../../slices/types';
import { removeMapPropertyHandler } from '../../dropdown-layout/helpers/map-helper';
import { WidgetSimplifiedDataType } from '../../../../enums/data-type';
import { widgetsWithColoringOptions } from './panel-item-popover/usePanelItemPopover';
import {
  getFiltersPropertyAfterSynchronization,
  resetFilterValueAfterAggregationChanging,
} from '../../../../helpers/widget-page';

export const usePanelItemActions = (widget: Widget) => {
  const dispatch = useDispatch();

  const changeAggregationHandler = useCallback(
    (newProperty: Property, panelType: PanelType, index: number, isNeedToResetFilters = true) => {
      let properties = [...widget.properties] || [];
      const axisIndex: number = getPanelIndex({ properties, type: panelType });
      const axisValue: string = getAxisValues(panelType, properties);
      const parsedValue = JSON.parse(axisValue);

      parsedValue[index] = newProperty.isCalculated()
        ? newProperty.getCalculatedValue()
        : newProperty.getValue();

      const simplifiedType = getSimplifiedType(parsedValue[index].type);
      if (simplifiedType === WidgetSimplifiedDataType.DATE) {
        parsedValue[index].filter = [];
        parsedValue[index].minValue = newProperty.data.minValue;
        parsedValue[index].maxValue = newProperty.data.maxValue;
      }

      if (Object.hasOwn(parsedValue[index], 'isDrillDownFilter')) {
        parsedValue[index].isDrillDownFilter = newProperty.data.aggregation
          ? false
          : newProperty.data.isDrillDownFilter || false;
      }

      properties[axisIndex] = {
        ...properties[axisIndex],
        value: JSON.stringify(parsedValue),
      };

      if (
        getIsNeedSyncFiltersAxis({
          widgetType: widget.type as WidgetType,
          panelType,
        })
      ) {
        const { filterAxisValue, filterAxisIndex } =
          getFiltersPropertyAfterSynchronization(properties, newProperty, isNeedToResetFilters);
        properties[filterAxisIndex] = {
          ...properties[filterAxisIndex],
          value: JSON.stringify(filterAxisValue),
        };
      }

      if (panelType === PanelType.axisFilter && isNeedToResetFilters) {
        properties = resetFilterValueAfterAggregationChanging(
          properties,
          index,
        );
      }

      if (widget.type === WidgetType.TABLE && panelType === PanelType.axisX) {
        properties = getChangedPropertiesWithTableHeader(properties);
      }

      dispatch(setWidgetPropsAction(properties));
    },
    [dispatch, widget.properties, widget.type],
  );

  const removePropertyHandler = useCallback(
    (panelType: PanelType, index: number) => {
      if (widget.type === WidgetType.MAP) {
        removeMapPropertyHandler(widget.properties, index, dispatch);
      } else {
        const newProperties = getChangedPropertiesAfterPanelItemDelete(
          widget.type,
          panelType,
          widget.properties,
          index,
          { widgetsWithColoringOptions },
        );

        dispatch(setWidgetPropsAction(newProperties));
      }
    },
    [dispatch, widget.properties, widget.type],
  );

  return {
    changeAggregationHandler,
    removePropertyHandler,
  };
};
