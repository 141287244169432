import React, { useCallback, useMemo } from 'react';
import './styles.css';
import './react-grid.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '../../../slices/types';
import {
  deleteWidgetIdFromDashboardAction,
  setDashboardPropAction,
} from '../../../slices/dashboard/dashboard';
import { DashboardPropertyType } from '../../../enums/dashboard-properties';
import { dispatchRootEvent } from '../../../utils/root-helper';
import DashboardWidgetsEditView from './dashboard-widgets-edit-view';
import DashboardWidgetsPreviewView from './dashboard-widgets-preview-view';
import { useDrilldownDepth } from './hooks/useDrilldownDepth';
import { useGridLayoutResizer } from './hooks/useGridLayoutResizer';
import { DashboardLayout } from './types';


interface DashboardWidgetsProps {
  isPreview: boolean;
}

const DashboardWidgets = ({ isPreview }: DashboardWidgetsProps) => {
  const dispatch = useDispatch();

  const { increaseDrilldownDepth, decreaseDrilldownDepth, drillDownDepth } =
    useDrilldownDepth();

  const currentDashboard = useSelector(
    (state: State) => state.dashboard,
    shallowEqual,
  );

  const widgetsLayout =
    currentDashboard.properties.find(
      (prop) => prop.name === DashboardPropertyType.layout,
    )?.value || '[]';

  const dashboardLayout: DashboardLayout[] = useMemo(
    () => JSON.parse(widgetsLayout),
    [widgetsLayout],
  );

  const onLayoutChange = useCallback(
    (layout: any) => {
      const GridLayout = [...layout] || [];

      const widgetsLayoutValuesList = [...JSON.parse(widgetsLayout as string)];

      // mutate gird-layout to the format we need to post on server
      GridLayout.forEach((element, index) => {
        const widgetItem = { ...widgetsLayoutValuesList[index] };
        widgetItem.x = element.x;
        widgetItem.i = element.i;
        widgetItem.y = element.y;
        widgetItem.h = element.h;
        widgetItem.w = element.w;
        widgetsLayoutValuesList[index] = widgetItem;
      });

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.layout,
          value: JSON.stringify(widgetsLayoutValuesList),
        }),
      );

      dispatchRootEvent(new Event('resize'));
    },
    [dispatch, widgetsLayout],
  );

  const handleDeleteWidget = useCallback(
    (widgetId: number) => {
      const dashboardLayoutFiltered = dashboardLayout.filter(
        (item) => Number(item.id) !== widgetId,
      );

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.layout,
          value: JSON.stringify(dashboardLayoutFiltered),
        }),
      );

      dispatch(deleteWidgetIdFromDashboardAction(widgetId));
    },
    [dashboardLayout, dispatch],
  );

  const { gridLayoutContainer, gridContainerWidth, currentWidgetIdFullScreen } =
    useGridLayoutResizer();

  const Props = {
    dashboardLayout,
    onLayoutChange,
    handleDeleteWidget,
    gridLayoutContainer,
    gridContainerWidth,
    currentWidgetIdFullScreen,
    drillDownDepth,
    increaseDrilldownDepth,
    decreaseDrilldownDepth,
  };

  return React.createElement(
    isPreview ? DashboardWidgetsPreviewView : DashboardWidgetsEditView,
    Props,
  );
};

export default DashboardWidgets;
