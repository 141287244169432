import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import block from 'bem-cn';

import Connection from '../../../../../../types/connection';
import { State } from '../../../../../../slices/types';
import MapConnection from '../../../../../../types/map-connection';

import { apiSaveMapConnection } from '../../../../../../services/loadersController';
import { apiGetFileTypes } from '../../../../../../services/dictionariesController';
import { getFileParamsById } from '../../../../../../services/connection';

import EditForm from './edit-form';
import { getDataAddressString, getInitialValues } from './utils';
import { fieldName } from './data';

import './edit-form.css';
import { CustomProgress } from '../../../../../../uikit/Progress';

const b = block('edit-form');

type FormValues = Record<
  string,
  string | boolean | { start: string; end: string }[]
>;

interface Props {
  sourceObject: MapConnection.SourceObject;
  handlePopoverClose: () => void;
  reloadScheme: () => void;
  setRangeError: (error: string) => void;
}

const EditFormContainer: React.FC<Props> = ({
  sourceObject,
  handlePopoverClose,
  reloadScheme,
  setRangeError,
}) => {
  const { fileType } = sourceObject;

  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;

  const mapConnection = useSelector<State, MapConnection.Data | undefined>(
    (state) => state.mapConnection[loaderId],
  );

  const [typeId, setTypeId] = useState<number>();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingFilterCell, setIsLoadingFilterCell] = useState<boolean>(false);
  const [fieldsData, setFieldsData] = useState<Connection.FileParam[]>([]);

  const initialValues = getInitialValues(fieldsData, sourceObject.parameters);

  const getFileTypeId = useCallback(() => {
    setIsLoading(true);
    apiGetFileTypes()
      .then((res) => {
        setTypeId(res?.find((item) => item.type === fileType)?.id || 0);
      })
      .catch((err) => {
        setError('Ошибка получения данных');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fileType]);

  useEffect(() => {
    getFileTypeId();
  }, [getFileTypeId]);

  useEffect(() => {
    setIsLoading(true);
    typeId &&
      getFileParamsById(typeId, true)
        .then((res) => {
          setFieldsData(res);
        })
        .catch((err) => {
          setError('Ошибка получения данных');
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [typeId]);

  const saveNewJsonAndReloadScheme = async (
    newJsonWithParams: MapConnection.Data,
  ) => {
    try {
      await apiSaveMapConnection(loaderId, newJsonWithParams);
      handlePopoverClose();
      await reloadScheme();
    } catch (err: any) {
      setError(`Ошибка получения данных ${err.response}`);
      throw err;
    }
  };

  const handleFormSubmit = async (values: FormValues) => {
    setIsLoadingFilterCell(true);
    setError('');
    try {
      const params = Object.entries(values).map(([key, value]) => {
        if (key === fieldName.DATA_ADDRESS) {
          const listName =
            sourceObject.initialName?.split('\t').pop() ||
            sourceObject.name.split('\t').pop();
          const rangeString = getDataAddressString(
            values[fieldName.DATA_ADDRESS] as { start: string; end: string }[],
            listName!,
          );
          return { parameterMetaType: key, value: rangeString };
        }
        return { parameterMetaType: key, value };
      });

      const newSourceObject = { ...sourceObject, ...{ parameters: params } };
      const newSourceObjects = [...mapConnection?.sourceObjects!];
      const changeIndex = mapConnection?.sourceObjects.findIndex(
        (sourceObj) => sourceObj.id === sourceObject.id,
      );
      newSourceObjects[changeIndex!] = newSourceObject;
      const newJsonWithParams = {
        ...mapConnection!,
        ...{ sourceObjects: newSourceObjects },
      };

      await saveNewJsonAndReloadScheme(newJsonWithParams);
    } catch (err: any) {
      setRangeError(err.response.data.message);
      setError(`Ошибка получения данных ${err.response}`);
    }
    setIsLoadingFilterCell(false);
  };

  return (
    <div className={b()}>
      {isLoading && <CustomProgress type="linear" />}
      {error && <p>{error}</p>}
      {fieldsData.length > 0 && (
        <EditForm
          fieldsData={fieldsData}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          handlePopoverClose={handlePopoverClose}
          isLoadingFilterCell={isLoadingFilterCell}
        />
      )}
    </div>
  );
};

export default EditFormContainer;
