import React, { FC } from 'react';
import { ButtonProps, styled } from '@material-ui/core';
import MUIButton from '@material-ui/core/Button';
import clsx from 'clsx';
import { LinkWrap } from '../LinkWrap';

export interface IButton extends Omit<ButtonProps, 'color' | 'variant'> {
  icon?: React.ReactNode | null;
  loading?: boolean;
  variant?: 'text' | 'contained' | 'outlined' | 'tab' | undefined;
  size?: 'medium' | 'small' | 'large';
  secondary?: boolean;
  linkTo?: string;
  shape?: 'rect' | 'circle';
  colorType?: 'light' | 'dark' | 'default';
}

const StyledButton = styled(MUIButton)(
  ({ colorType, icon, secondary, shape, backgroundColor }: Partial<CustomButton>) => ({
    borderRadius: shape === 'circle' ? '100%' : 'var(--base-border-radius)',
    lineHeight: 'calc(20px * var(--scale-coefficient))',
    fontSize: 'calc(14px * var(--scale-coefficient))',
    textTransform: 'none',
    fontWeight: 'bold',
    fontStyle: 'normal',
    boxSizing: 'border-box',
    padding: icon
      ? 'calc(14px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient))'
      : 'calc(14px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient))',
    minWidth: 'calc(46px * var(--scale-coefficient))',
    height: 'calc(46px * var(--scale-coefficient))',

    '&.MuiButton-sizeSmall': {
      padding: icon
        ? 'calc(6px * var(--scale-coefficient))'
        : 'calc(10px * var(--scale-coefficient))',
      minWidth: 'calc(38px * var(--scale-coefficient))',
      height: 'calc(38px * var(--scale-coefficient))',
    },
    '&.MuiButton-sizeLarge': {
      padding: icon
        ? 'calc(17px * var(--scale-coefficient)) calc(15px * var(--scale-coefficient))'
        : 'calc(17px * var(--scale-coefficient)) calc(23px * var(--scale-coefficient))',
      minWidth: 'calc(54px * var(--scale-coefficient))',
      height: 'calc(54px * var(--scale-coefficient))',
    },

    '& .MuiSvgIcon-root': {
      fontSize: 'calc(24px * var(--scale-coefficient))',
    },

    '&.MuiButton-outlined': {
      borderColor: 'var(--dark-cyan)',
      color: 'var(--title-color)',
      backgroundColor: backgroundColor || 'var(--white)',
    },
    '&.MuiButton-outlined.Mui-disabled': {
      backgroundColor: backgroundColor || 'var(--white)',
      opacity: 0.4,
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: backgroundColor || 'var(--white)',
      boxShadow: 'var(--shadow-button-outline-hover)',
    },
    '&.MuiButton-outlined:active': {
      backgroundColor: backgroundColor || 'var(--primary-color)',
      boxShadow: 'none',
    },

    // Contained
    '&.MuiButton-contained': {
      color: 'var(--white)',
      backgroundColor: secondary ? 'var(--red-normal)' : 'var(--primary-color)',
      boxShadow: 'var(--shadow-button-filled-normal)',
    },
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: secondary ? 'var(--red-normal)' : 'var(--primary-color)',
      opacity: 0.4,
    },
    '&.MuiButton-contained:hover': {
      backgroundColor: secondary ? 'var(--red-hover)' : 'var(--primary-hover-color)',
      boxShadow: 'var(--shadow-button-filled-hover)',
    },
    '&.MuiButton-contained:active': {
      backgroundColor: secondary ? 'var(--red-active)' : 'var(--primary-hover-color)',
      boxShadow: 'none',
    },
    '&.MuiButton-outlined .MuiSvgIcon-root': {
      color: 'var(--dark-grey)',
    },
    '&.MuiButton-outlined:active .MuiSvgIcon-root': {
      color: 'var(--title-color)',
    },

    // Tab
    '&.MuiButton-outlined.tab': {
      color: 'var(--title-color)',
    },
    '&.MuiButton-outlined.tab:hover': {
      color: 'var(--title-color)',
      backgroundColor: backgroundColor || 'var(--medium-grey-opacity)',
    },
    '&.MuiButton-outlined.tab:active, &.MuiButton-outlined.tab.selected': {
      color: 'var(--white)',
      backgroundColor: 'var(--primary-color)',
    },

    '&.MuiButton-outlined.tab .MuiSvgIcon-root': {
      color: 'var(--dark-grey)',
    },
    '&.MuiButton-outlined.tab:hover .MuiSvgIcon-root': {
      color: 'var(--primary-color)',
    },
    '&.MuiButton-outlined.tab:hover .SVGInline-svg': {
      fill: 'var(--primary-color)',
    },
    '&.MuiButton-outlined:active.tab .MuiSvgIcon-root': {
      color: 'var(--white)',
    },
    '&.MuiButton-outlined:active.tab .SVGInline-svg': {
      fill: 'var(--white)',
    },

    // Text
    '&.MuiButton-text': {
      border: colorType !== 'default' ? '1px solid var(--primary-color)' : '',
      color: colorType === 'light' ? 'var(--white)' : 'var(--title-color)',

      '&.Mui-disabled': {
        opacity: 0.4
      },
      '&:hover': {
        backgroundColor: colorType === 'light' ? 'var(--white)' : '',
        color: 'var(--title-color)',
        boxShadow: colorType === 'dark' ? 'var(--shadow-button-filled-hover)' : '',
      }
    }
  }),
);

interface CustomButton extends IButton {
  backgroundColor?: string
}

export const CustomButton: FC<CustomButton> = ({
  icon = null,
  loading = false,
  variant = 'outlined',
  size = 'medium',
  shape = 'rect',
  secondary = false,
  colorType = 'default',
  children,
  className,
  linkTo,
  ...props
}) => {
  const MUIVariant = variant === 'tab' ? 'outlined' : variant;

  return (
    <LinkWrap linkTo={linkTo} disabled={props.disabled}>
      <StyledButton
        type="button"
        className={clsx(className, { tab: variant === 'tab' })}
        icon={icon}
        size={size}
        shape={shape}
        variant={MUIVariant}
        secondary={secondary}
        colorType={colorType}
        {...props}
      >
        {icon || children}
      </StyledButton>
    </LinkWrap>
  );
};
