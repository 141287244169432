import React from 'react';
import {
  IconButtonType,
  CustomIconButton,
} from '../../../../../uikit/IconButton';
import { useSettingsButton } from './useSettingsButton';
import { FilterGroupProps } from '../../../hooks';
import { CustomPanel } from '../../../../../uikit/Panel';
import { SettingGroup } from './setting-group';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../common/icon-svg/icon-svg';

export interface SettingsButtonProps {
  group: FilterGroupProps;
}

export const SettingsButton = ({ group }: SettingsButtonProps) => {
  const { toggleSettingsPanel, isOpenSettingsPanel, settingsGroups } =
    useSettingsButton({ group });

  return (
    <>
      <CustomIconButton
        iconButtonProps={{
          children: (
            <IconSvg svg={IconDictionary.Gear} fill={isOpenSettingsPanel ? 'var(--primary-color)' : 'var(--dark-grey)'} />
          ),
          onClick: toggleSettingsPanel,
        }}
        type={IconButtonType.secondary}
      />
      {isOpenSettingsPanel && (
        <CustomPanel
          headProps={{
            title:'Настройки фильтра',
          }}
          handlerClose={toggleSettingsPanel}
          type="dashboardSecondPanel"
        >
          <div className="filter-list-item__panel">
            {settingsGroups.map((settingGroup) => (
              <SettingGroup
                key={settingGroup.title}
                group={settingGroup}
                filtersGroupId={group.id}
              />
            ))}
          </div>
        </CustomPanel>
      )}
    </>
  );
};
