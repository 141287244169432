import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ParametersPanel } from '../../components/common/parameters/parameters-panel';
import DashboardWidgetsController from '../../components/dashboard-page/dashboard-widgets/dashboard-widgets-controller';
import { ProjectDashboardsHeader } from '../../components/project-dashboards-page/header/header';
import './style.css';
import { DashboardFilters } from '../../components/dashboard-page/filters';
import { useCurrentProjectSetter } from '../../hooks/useCurrentProjectSetter';
import { WidgetTooltip } from '../../components/common/widget-tooltip/widget-tooltip';
import {
  setCrossFiltersAction,
  setCurrentDashboardAction,
  setInitPreviewFiltersAction,
} from '../../slices/dashboard/dashboard';
import { State } from '../../slices/types';
import { PageParams } from '../../types/meta';
import { CustomProgress } from '../../uikit/Progress';

interface PreviewDashboardsPageProps {
  isSharedDashboard?: boolean
}

const PreviewDashboardsPage = ({ isSharedDashboard = false }: PreviewDashboardsPageProps) => {
  const history = useHistory();
  const params: PageParams = useParams();
  const dispatch = useDispatch();
  const projectId: number | null = params.projectId ? parseInt(params.projectId) : null;

  const currentProject = useSelector(
    (state: State) => state.mainPage.currentProject,
  );

  useCurrentProjectSetter({ projectId });

  useEffect(() => {
    const path = history.location.pathname;

    if (path.includes('/dashboards') && currentProject?.dashboards.length && !isSharedDashboard) {
      history.push(
        `/project/${projectId}/dashboard/${currentProject.dashboards[0].id}/preview`,
      );
    }

  }, [currentProject, history, isSharedDashboard, projectId]);

  useEffect(() => {
    return () => {
      dispatch(setCrossFiltersAction(null));
      dispatch(setCurrentDashboardAction(''));
      dispatch(setInitPreviewFiltersAction([]));
    };
  }, [dispatch]);

  if (!params.id) return null;

  const dashboardId = parseInt(params.id);

  return (
    <div className="project-dashboards">
      <ProjectDashboardsHeader
        projectId={projectId}
        dashboardId={dashboardId}
        isSharedDashboard={isSharedDashboard}
      />

      <DashboardWidgetsController key={dashboardId} dashboardId={dashboardId} isPreview={true} />

      <DashboardFilters />
      <ParametersPanel isDashboard />
      <WidgetTooltip />
    </div>
  );
};

export default PreviewDashboardsPage;
