import { useCallback, useEffect, useState } from 'react';
import { getConnectionMetaList } from '../services/connection';
import Connection from '../types/connection';

type Data = Connection.Meta[];

type UseConnectionMetaList = () => {
  data: Data;
  loading: boolean;
  refresh: Function;
};

export const useConnectionMetaList: UseConnectionMetaList = () => {
  const [data, setData] = useState<Data>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = useCallback(() => {
    setLoading(true);
    getConnectionMetaList()
      .then(setData)
      .then(() => setLoading(false));
  }, []);

  useEffect(getData, []);

  return {
    data,
    loading,
    refresh: getData,
  };
};
