import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { CustomButton } from 'src/uikit/Button';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { CssVariables } from '../../../enums/css-variables';
import { varcss } from '../../../helpers/ui-helper';
import { selectProjectParameters, switchOpenParametersPanelAction } from '../../../slices/parameters/parameters';
import { PageParams } from '../../../types/meta';
import IconSvg from '../icon-svg/icon-svg';

import './styles.css';
import { useParameters } from './useParameters';

export const ParametersButton = () => {
  const dispatch = useDispatch();

  const params: PageParams = useParams();

  const projectId = Number(params.projectId);
  const dashboardId = Number(params.id);

  const projectParameters = useSelector(selectProjectParameters);

  useParameters(dashboardId ? undefined : projectId, dashboardId);

  const openHandler = () => dispatch(switchOpenParametersPanelAction());

  useEffect(() => {
    return () => {
      dispatch(switchOpenParametersPanelAction(false));
    };
  }, [dispatch]);

  if (dashboardId && projectParameters.length === 0) return null;

  return (
    <>
      <CustomTooltip title="Параметры">
        <div className="parameters-button">
          <CustomButton
            variant="outlined"
            icon={<IconSvg svg={IconDictionary.Gear} fill="var(--dark-grey)" />}
            onClick={openHandler}
            backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
          />
        </div>
      </CustomTooltip>
    </>
  );
};
