import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Group, Project } from '../types';

const groupInitialState: any = {
  group: [],
  initGroups: []
};

const groupSlice = createSlice({
  name: 'groups',
  initialState: groupInitialState,
  reducers: {
    fetch: (state, { payload }: PayloadAction<Group[]>) => {
      return { ...state, group: payload };
    },
    setInitGroups: (state, { payload }: PayloadAction<Group[]>) => {
      return { ...state, initGroups: payload };
    },
    create: (
      state,
      { payload }: PayloadAction<{ groupId: number; groupName: string }>,
    ) => {
      state.group.push({ ...payload, projects: [] });
    },
    deleteProject: (state, { payload }: PayloadAction<Project>) => {
      state.group.forEach((group: Group) => {
        const deletedIndex = group.projects?.findIndex(
          (project: Project) => project.id === payload.id,
        );
        if (deletedIndex !== -1) {
          group.projects && group.projects.splice(deletedIndex as number, 1);
        }
      });
    },
  },
});

export const groupReducer = groupSlice.reducer;

export const {
  fetch: setGroupsAction,
  setInitGroups: setInitGroupsAction,
  create: createNewGroupAction,
  deleteProject: deleteProjectAction,
} = groupSlice.actions;
