import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { Group } from '@visx/group';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { Legends } from './legend';
import { ScalableSVGText } from '../../../common/scalable-svg-text';
import { setPropForNumberValue } from '../formatting-helpers';
import { PanelType } from '../../../../enums/widget-type';
import { useSalesFunnel } from './useSalesFunnel';
import { initValueFormatter } from '../hooks/initValueFormatter';

export interface SalesFunnelProps {
  widgetProps?: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
}

export const valueSettings = {
  lightColor: 'var(--white)',
  darkColor: 'var(--black)',
  fontSize: 18,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 21,
  textAlign: 'center',
  wordBreak: 'break-all',
};

const percentSettings = {
  lightColor: 'var(--white)',
  darkColor: 'var(--black)',
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'center',
  wordBreak: 'break-all',
};

export enum percentTypes {
  percent = 'Процент относительно первого этапа',
  relativePercent = 'Процент относительно предыдущего этапа',
}

const SalesFunnel: React.FC<SalesFunnelProps> = ({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}) => {

  const {
    deselectFilter,
    containerRef,
    widgetData,
    stepsColors,
    properties,
    currentPercentType,
    containerStyles,
    stepsCount,
    enableEvents,
    selectFilter,
    viewSettings,
    widgetProperties,
    roundingCount,
    axisXValueIndex,
  } = useSalesFunnel({
    isActiveFilter,
    widgetProps,
    setFilterField,
    filterField,
  });
  const valueFormat = initValueFormatter({ roundingCount });

  return (
    <div
      onClick={deselectFilter}
      style={{
        padding: `${containerStyles.containerPaddingTopBottom}px ${containerStyles.containerPaddingLeftRight}px`,
        flexGrow: 1,
        minHeight: `${containerStyles.minContainerHeight}px`,
        minWidth: `${containerStyles.minContainerWidth}px`,
      }}
      ref={containerRef}
    >
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        justifyContent="space-around"
        height="100%"
      >
        <Legends
          data={widgetData}
          colors={stepsColors}
          itemHeight={viewSettings.stepHeight}
          trapezoidStepHeight={viewSettings.trapezoidStepHeight}
          maxWidth={viewSettings.containerWidth - viewSettings.firstStepWidth}
          textColor={
            properties.isGradientColoring
              ? valueSettings.darkColor
              : valueSettings.lightColor
          }
          widgetProperties={widgetProps.properties}
          axisXValueIndex={axisXValueIndex}
        />

        <svg
          height={viewSettings.containerHeight}
          width={
            viewSettings.maxLastStepWidth +
            stepsCount * (2 * viewSettings.minTrapezoidStepOffset)
          }
        >
          {widgetData.map((item: any, index: number) => {
            if (index) {
              viewSettings.lastCoordinates.x += viewSettings.minTrapezoidStepOffset;
              viewSettings.lastCoordinates.y +=
                viewSettings.stepHeight + viewSettings.trapezoidStepHeight;
            }
            return (
              <Group
                key={`${item.label}-${index}`}
                className={clsx({ 'active-drilldown-element': isActiveFilter })}
                onClick={(e) => {
                  if (!enableEvents) return;
                  e.stopPropagation();
                  selectFilter(item.label);
                }}
                opacity={
                  filterField?.value === null ||
                  filterField?.value === undefined ||
                  filterField?.value?.includes(item.label)
                    ? 0.8
                    : 0.2
                }
              >
                <polygon
                  points={`${viewSettings.lastCoordinates.x} ${
                    viewSettings.lastCoordinates.y
                  },
                  ${
                    viewSettings.lastCoordinates.x + viewSettings.stepsWidth[index]
                  } ${viewSettings.lastCoordinates.y},
                  ${
                    viewSettings.lastCoordinates.x + viewSettings.stepsWidth[index]
                  } ${viewSettings.lastCoordinates.y + viewSettings.stepHeight},
                  ${viewSettings.lastCoordinates.x} ${
                    viewSettings.lastCoordinates.y + viewSettings.stepHeight
                  }`}
                  fill={stepsColors[index]}
                />
                <polygon
                  points={`${viewSettings.lastCoordinates.x} ${
                    viewSettings.lastCoordinates.y + viewSettings.stepHeight
                  },
                  ${
                    viewSettings.lastCoordinates.x + viewSettings.stepsWidth[index]
                  } ${viewSettings.lastCoordinates.y + viewSettings.stepHeight},
                  ${
                    viewSettings.lastCoordinates.x +
                    viewSettings.stepsWidth[index] -
                    viewSettings.minTrapezoidStepOffset
                  } ${
                    viewSettings.lastCoordinates.y +
                    viewSettings.stepHeight +
                    viewSettings.trapezoidStepHeight
                  },
                  ${
                    viewSettings.lastCoordinates.x +
                    viewSettings.minTrapezoidStepOffset
                  } ${
                    viewSettings.lastCoordinates.y +
                    viewSettings.stepHeight +
                    viewSettings.trapezoidStepHeight
                  }`}
                  fill={stepsColors[index]}
                  fillOpacity={0.5}
                />
                {properties.isNeedToDisplayValue && (
                  <ScalableSVGText
                    x={viewSettings.lastCoordinates.x}
                    y={
                      viewSettings.lastCoordinates.y +
                      (properties.isNeedToDisplayPercent
                        ? viewSettings.valuePadding - viewSettings.percentOffset / 2
                        : viewSettings.valuePadding)
                    }
                    formattedValue={valueFormat(
                      item.count,
                      0,
                      setPropForNumberValue(widgetProperties),
                      PanelType.axisY,
                    )}
                    axis={PanelType.axisY}
                    properties={{
                      width: viewSettings.stepsWidth[index],
                      svgTextStyles: {
                        ...valueSettings,
                        color: properties.isGradientColoring
                          ? valueSettings.darkColor
                          : valueSettings.lightColor,
                      },
                    }}
                    widgetProperties={widgetProperties}
                  />
                )}
                {properties.isNeedToDisplayPercent && (
                  <ScalableSVGText
                    x={viewSettings.lastCoordinates.x}
                    y={
                      viewSettings.lastCoordinates.y +
                      (properties.isNeedToDisplayValue
                        ? viewSettings.stepHeight - viewSettings.valueOffset
                        : 0)
                    }
                    formattedValue={valueFormat(
                      currentPercentType === percentTypes.percent
                        ? item.percent
                        : item.relativePercent,
                      0,
                      setPropForNumberValue(widgetProperties),
                      PanelType.axisY,
                      true,
                    )}
                    axis={PanelType.axisY}
                    properties={{
                      height: properties.isNeedToDisplayValue
                        ? viewSettings.percentOffset
                        : viewSettings.stepHeight,
                      width: viewSettings.stepsWidth[index],
                      svgTextStyles: properties.isNeedToDisplayValue
                        ? {
                          ...percentSettings,
                          color: properties.isGradientColoring
                            ? percentSettings.darkColor
                            : percentSettings.lightColor,
                        }
                        : {
                          ...valueSettings,
                          color: properties.isGradientColoring
                            ? valueSettings.darkColor
                            : valueSettings.lightColor,
                        },
                    }}
                    widgetProperties={widgetProperties}
                  />
                )}
              </Group>
            );
          })}
        </svg>
      </Box>
    </div>
  );
};

export default SalesFunnel;
