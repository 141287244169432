import React, { useCallback, useState } from 'react';
import IconSvg from '../icon-svg/icon-svg';
import './styles.css';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface DropdownListProps {
  title: string;
  isOpenInitial?: boolean;
  children?: React.ReactNode;
}

const DropdownList: React.FC<DropdownListProps> = ({
  title,
  isOpenInitial,
  children,
}) => {
  const [isOpen, setOpen] = useState<boolean | undefined>(isOpenInitial);

  const onClickHandle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={`dropdown-list ${isOpen ? 'dropdown-list--opened' : ''}`}>
      <div className="dropdown-list__content">
        <div className="dropdown-list__header" onClick={onClickHandle}>
          <IconSvg svg={IconDictionary.RoundedArrowToTop} fill={isOpen ? 'var(--primary-color)' : 'var(--dark-grey)'} />
          <span className="dropdown-list__title">{title}</span>
        </div>

        <div className="dropdown-list__items">{children}</div>
      </div>
    </div>
  );
};

export default DropdownList;
