import { useDispatch, useSelector } from 'react-redux';
import { flatten } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DashboardPropertyType } from '../../../../enums/dashboard-properties';
import {
  dashboardWidgetDataPropertiesSelector,
  dashboardWidgetDataSelector,
  dashboardWidgetLoadingSelector,
  deleteWidgetIdFromDashboardAction, setDashboardPropAction,
} from '../../../../slices/dashboard/dashboard';
import WidgetTypes from '../../../../types/widgets';
import {
  DashboardProperty,
  State,
  WidgetProperties,
} from '../../../../slices/types';
import { mergeWidgetProperties } from '../../../../utils/functions';
import { PropertyData } from '../../../widget-page/dropdown-layout/helpers/Property';
import { getPropertyId } from '../../../widget-page/helpers';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import {
  getJsonOverriddenFunctionsByDateFilters,
  initWidgetFiltersOnDashboard,
} from '../../../../helpers/dashboard-page/dashboard-widgets';
import { getAxisValues, getParsedAxisValues } from '../../../widget-page/dropdown-layout/helpers/helpers';
import { PanelType } from '../../../../enums/widget-type';
import {
  enhanceDashboardParams,
  getEffectedChangeForDashboardProperties,
} from '../../../../helpers/dashboard-page/widget';
import { useDashboardWidgetRequestParams } from '../../hooks/useDashboardWidgetRequestParams';
import { useDashboardDataUpdating } from './useDashboardDataUpdating';
import { useDashboardFiltersLogic } from './useDashboardFiltersLogic';

interface useWidgetProps {
  widgetId: number;
  isDrillDown: boolean;
  parentFilters?: PropertyData[] | null;
  increaseDrilldownDepth: () => void;
  decreaseDrilldownDepth: (isClose?: boolean) => void;
  parentId?: number;
  overriddenPropertiesInherited?: WidgetProperties[];
  drillDownDepth: number;
}

export const useWidget = ({
  widgetId,
  isDrillDown,
  parentFilters,
  increaseDrilldownDepth,
  decreaseDrilldownDepth,
  drillDownDepth,
  parentId,
  overriddenPropertiesInherited,
}: useWidgetProps) => {

  const dashboardParams = useDashboardWidgetRequestParams();

  const isLoading = useSelector(
    dashboardWidgetLoadingSelector(String(widgetId)),
  );
  const widgetProps = useSelector(
    dashboardWidgetDataSelector(String(widgetId)),
  );
  const widgetDataProperties = useSelector(
    dashboardWidgetDataPropertiesSelector(String(widgetId)),
  );

  const widgetsWithActiveLegend = useSelector(
    (state: State) => state.dashboard.widgetsWithActiveLegend,
  );

  const dashboardId = useSelector(
    (state: State) => state.dashboard.id,
  );

  const [permission, setPermission] = useState<any>(null);
  const [widgetDataObject, setWidgetDataObject] = useState<WidgetTypes.Data>({
    data: widgetProps.data,
    properties: widgetDataProperties,
  });

  const [widgetErrorText, setWidgetErrorText] = useState<string>('');
  const [overriddenProperties, setWidgetOverriddenProperties] = useState<
    WidgetProperties[]
  >([]);
  const [filtersForChildren, setFiltersForChildren] = useState<
    PropertyData[] | null
  >(parentFilters as PropertyData[] | null);

  const { values: dashboardFiltersGroups, setWidgetFilterValue } =
    useDashboardFilters();

  const containerRef = useRef(document.createElement('div'));

  const enhancedDashboardParams = enhanceDashboardParams(
    dashboardParams,
    overriddenProperties,
    widgetId,
    widgetsWithActiveLegend,
    dashboardFiltersGroups,
    isDrillDown,
    widgetProps,
    dashboardId,
  );

  const setOverriddenProperties = useCallback(
    (newProperties: WidgetProperties[]) => {
      const newOverriddenProperties = mergeWidgetProperties(
        overriddenProperties,
        newProperties,
      );

      setWidgetOverriddenProperties(newOverriddenProperties);
    },
    [overriddenProperties],
  );

  const initFilters = useCallback(
    (properties: WidgetProperties[]) => {
      const parsedAxisValues = getParsedAxisValues(
        PanelType.axisFilter,
        properties,
      ).map((item: PropertyData) => {
        // Инициализация фильтров виджета из настроек дашборда.
        // Берем фильтр из первой попавшейся группы, т.к. допускаем, что фильтров с одним id не может быть больше одного
        const defaultWidgetFilter =
          flatten(
            dashboardFiltersGroups
              .filter((group) => !group.actual)
              .map((group) => group.widgetFilters),
          ).find((filter) => filter.id === item.id)?.value || [];

        return { ...item, filter: defaultWidgetFilter };
      });

      if (parsedAxisValues.length > 0) {
        initWidgetFiltersOnDashboard(
          parsedAxisValues,
          dashboardFiltersGroups,
          overriddenProperties,
          isDrillDown ? overriddenPropertiesInherited : undefined,
          setOverriddenProperties,
        );
      }
    },
    [
      dashboardFiltersGroups,
      isDrillDown,
      overriddenProperties,
      overriddenPropertiesInherited,
      setOverriddenProperties,
    ],
  );

  const initWidgetDataLoadingCallback = useCallback(
    (result: any) => {
      setPermission(result.permission);
      initFilters(result.properties);
    },
    [initFilters],
  );

  useEffect(() => {
    const updateFiltersForChildren = () => {
      if (!overriddenProperties.length) return;
      const widgetAxisFilter: PropertyData[] = getParsedAxisValues(
        PanelType.axisFilter,
        overriddenProperties,
      );

      setFiltersForChildren(widgetAxisFilter);
    };

    updateFiltersForChildren();
  }, [JSON.stringify(overriddenProperties)]);

  const {
    isParentForOpenedDrilldown,
    closeDrillDown,
    connectedWidget,
    drilldownFilterField,
    getFilters,
    isActiveDrilldownFilter,
    isMasterWidget,
    handleClickBackDD,
    filtersForPanel,
  } = useDashboardFiltersLogic({
    overriddenProperties,
    isDrillDown,
    dashboardParams,
    widgetProps,
    widgetId,
    parentId,
    drillDownDepth,
    increaseDrilldownDepth,
    decreaseDrilldownDepth,
  });

  useDashboardDataUpdating({
    widgetId,
    enhancedDashboardParams,
    isParentForOpenedDrilldown,
    setWidgetDataObject,
    setWidgetErrorText,
    initWidgetDataLoadingCallback,
    effectedChangeForDashboardProperties: JSON.stringify(
      getEffectedChangeForDashboardProperties(widgetId, dashboardParams || []),
    ),
    jsonOverriddenFunctionsByDateFilters:
      getJsonOverriddenFunctionsByDateFilters(dashboardFiltersGroups),
    overriddenProperties,
  });

  /* Start
      Функционал синхронизации фильтров виджетов в настройках дашборда
    */
  const overriddenWidgetFilters = getAxisValues(
    PanelType.axisFilter,
    overriddenProperties,
  );

  useEffect(() => {
    const widgetFilters = JSON.parse(overriddenWidgetFilters || '[]');

    if (widgetFilters.length && !isDrillDown) {
      const filters = widgetFilters.map((filter: any) => ({
        filterId: getPropertyId(filter),
        value: filter.filter,
      }));
      // Тут изменяются стейт в части фильтров дашборда,
      // но, так как каждый фильтр идет от конкретного виджета, мы предполагаем,
      // что правка данных из разных виджетов не будет конфликтовать друг с другом
      // и обновлять одни и те же данные.
      setWidgetFilterValue(filters);
    }
  }, [isDrillDown, overriddenWidgetFilters, setWidgetFilterValue]);
  /* End */

  return {
    containerRef,
    setOverriddenProperties,
    overriddenProperties,
    closeDrillDown,
    isParentForOpenedDrilldown,
    connectedWidget,
    filtersForChildren,
    drilldownFilterField,
    widgetDataObject,
    widgetErrorText,
    permission,
    getFilters,
    isActiveDrilldownFilter,
    isMasterWidget,
    handleClickBackDD,
    filtersForPanel,
    isLoading,
    enhancedDashboardParams,
  };
};
