import { styled } from '@material-ui/core';
import React from 'react';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';

interface CustomErrorAlertProps extends AlertProps {
  title?: string;
  fullwidth?: boolean;
  children?: string | JSX.Element | JSX.Element[] | React.ReactNode;
}

const StyledErrorAlert = styled(Alert)(
  ({ fullwidth }: Partial<CustomErrorAlertProps>) => ({
    width: fullwidth ? '100%' : 'auto',
    padding: 'calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) calc(8px * var(--scale-coefficient)) calc(8px * var(--scale-coefficient))',
    border: '1px solid var(--red-normal)',
    backgroundColor: 'var(--red-opacity)',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: '18px',
    color: 'var(--black)',

    '& [class*=MuiAlert-message]': {
      padding: 0,
    },

    '& [class*=MuiAlert-icon]': {
      color: 'var(--red-normal)',
      padding: 0,
      marginRight: 'calc(10px * var(--scale-coefficient))',

      '& svg': {
        width: 'calc(24px * var(--scale-coefficient))',
        height: 'calc(24px * var(--scale-coefficient))'
      }
    },

    '& [class*=MuiAlertTitle-root]': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'calc(14px * var(--scale-coefficient))',
      lineHeight: '18px',
      color: 'var(--black)',
      marginBottom: 'calc(12px * var(--scale-coefficient))',
    },
  }),
);

export const CustomErrorAlert = ({
  title = 'Ошибка',
  fullwidth = true,
  children,
  ...props
}: CustomErrorAlertProps) => {
  return (
    <StyledErrorAlert fullwidth={fullwidth} {...props} severity="error">
      <AlertTitle>{title}</AlertTitle>
      {children}
    </StyledErrorAlert>
  );
};
