import { stringToBool } from '../../../../utils/functions';
import { useDashboardPropertyValue } from '../../hooks/useDashboardPropertyValue';
import { DashboardPropertyType } from '../../../../enums/dashboard-properties';

export const useDashboardSettings = () => {
  const disableLoadData = useDashboardPropertyValue<boolean>(
    DashboardPropertyType.noSendDataRequest,
  );
  const showCacheUpdateTime = useDashboardPropertyValue<boolean>(
    DashboardPropertyType.showCacheUpdateTime,
  );
  const showQuickFiltersPanel = useDashboardPropertyValue<boolean>(
    DashboardPropertyType.showQuickFiltersPanel,
  );
  const showPrintBorders = useDashboardPropertyValue<boolean>(
    DashboardPropertyType.showPrintBorders,
  );
  const showWidgetHeaders = useDashboardPropertyValue<boolean>(
    DashboardPropertyType.showWidgetHeaders,
  );
  const showFiltersForPanel = useDashboardPropertyValue<boolean>(
    DashboardPropertyType.showFiltersForPanel,
  );
  const isActiveCrossFiltration = useDashboardPropertyValue<boolean>(
    DashboardPropertyType.isActiveCrossFiltration,
  );

  return {
    showCacheUpdateTime: stringToBool(showCacheUpdateTime),
    showQuickFiltersPanel: stringToBool(showQuickFiltersPanel),
    showPrintBorders: stringToBool(showPrintBorders),
    showWidgetHeaders: stringToBool(showWidgetHeaders),
    showFiltersForPanel: stringToBool(showFiltersForPanel),
    isActiveCrossFiltration: stringToBool(isActiveCrossFiltration),
    disableLoadData: stringToBool(disableLoadData),
  };
};
