import React, { useCallback, useState } from 'react';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { axisProperties } from './properties';

export const GanttColumn = ({
  xScale,
  data,
  daysRange,
  canvasHeight,
  canvasWidth,
}: any) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const mouseOverHandler = useCallback(() => {
    setHovered(true);
  }, []);

  const mouseOutHandler = useCallback(() => {
    setHovered(false);
  }, []);

  const borderProps = {
    stroke: 'var(--primary-color)',
    strokeWidth: 1,
    strokeOpacity: hovered ? 0.64 : 0,
    fillOpacity: 0,
  };

  const isWeekend = new Date(data).getDay() % 6 == 0;

  const currentDaysLabelWidth = canvasWidth / daysRange.length;

  return (
    <Group top={axisProperties.monthLabelHeight}>
      <rect
        {...borderProps}
        x={xScale(data)}
        y={0}
        height={canvasHeight}
        style={{ pointerEvents: 'none' }}
        width={currentDaysLabelWidth}
      />
      <rect
        {...borderProps}
        x={xScale(data)}
        y={0}
        height={canvasHeight}
        style={{ pointerEvents: 'none' }}
        width={currentDaysLabelWidth}
      />

      <rect
        fill="#FF6B00"
        fillOpacity={isWeekend ? 0.04 : 0}
        x={xScale(data)}
        height={canvasHeight}
        style={{ pointerEvents: 'none' }}
        width={currentDaysLabelWidth}
      />

      <rect
        fillOpacity={0}
        x={xScale(data)}
        height={axisProperties.daysLabelHeight}
        width={currentDaysLabelWidth}
        onMouseOut={mouseOutHandler}
        onMouseOver={mouseOverHandler}
      />

      <Text
        textAnchor="middle"
        verticalAnchor="middle"
        style={{ pointerEvents: 'none' }}
        fill={isWeekend ? 'var(--red-normal)' : 'var(--grey)'}
        x={xScale(data) + currentDaysLabelWidth / 2}
        y={axisProperties.daysLabelHeight / 2}
        className="guntt-font--header"
      >
        {data.format('DD').toString()}
      </Text>
    </Group>
  );
};
