import React, { CSSProperties } from 'react';
import { axisTickLabelProps } from '../constants';
import {
  BubbleSets,
  Dimensions,
  FormattingValues,
  textDirections,
} from '../types';

interface BottomAxisValueProps {
  axisXValue: string;
  dimensions: Dimensions;
  formattingValues: FormattingValues;
  bubbleSets: BubbleSets;
}

const bottomAxisValueTopOffset = 8;

export const BottomAxisValue = ({
  axisXValue,
  dimensions,
  formattingValues,
  bubbleSets,
}: BottomAxisValueProps) => {
  const { chartHeight, axisXHeight, columnWidth } = dimensions;

  const { formatAxisXValue } = formattingValues;

  const { textDirection } = bubbleSets;

  const styleWrapperDirection: CSSProperties =
    textDirection === textDirections.vertical
      ? {
        display: 'flex',
        alignItems: 'center',
        transform: `rotate(-90deg) translate(${axisXHeight / 2 - bottomAxisValueTopOffset}px, -${columnWidth / 2}px)`,
        transformOrigin: 'right center',
      }
      : {};
  const styleTextDirection: CSSProperties =
    textDirection === textDirections.vertical
      ? {
        overflow: 'visible',
        paddingTop: 0,
        textAlign: 'end',
      }
      : {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingTop: '4px',
      };

  return (
    <div
      className="widget-bubble-chart__axis-bottom-label"
      style={{
        ...axisTickLabelProps,
        top: chartHeight,
        height: axisXHeight,
        ...styleWrapperDirection,
      }}
    >
      <div
        style={{
          width: '100%',
          ...styleTextDirection,
        }}
      >
        {formatAxisXValue(axisXValue)}
      </div>
    </div>
  );
};
