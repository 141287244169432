import React from 'react';
import { SelectDateTypes, TextFilterModeSelect } from '../../../../enums/dashboard-properties';
import { DateFunctions } from '../../../../enums/date';
import { Placement } from '../../../../enums/visual-types';
import { getFilterGroupDisplayedName } from '../../../../helpers/dashboard-page/filters-helper';
import { getSimplifiedType } from '../../../widget-page/dropdown-layout/helpers/helpers';
import { DateFilter, DateFilterOptions, } from '../../../widget-page/filters/wrappers/date-filter/date-filter';
import { SelectFilter } from '../../../widget-page/filters/wrappers/select-filter';
import { ValueFilter } from '../../../widget-page/filters/wrappers/value-filter';
import { Filter, FilterGroupProps } from '../../hooks';
import { useDashboardFilterProperties } from '../../hooks/useDashboardFilterProperties';


interface DashboardFilterProps {
  property: FilterGroupProps;
  handleChangeFilter: (filters: Filter[], overriddenFunction?: string) => void;
  closeFilterPanel?: () => void;
  allFieldFilters?: any;
  hideLabel?: boolean;
}

export const DashboardFilter: React.FC<DashboardFilterProps> = ({
  property,
  handleChangeFilter,
  closeFilterPanel,
  allFieldFilters,
  hideLabel,
}) => {
  const remappedType = getSimplifiedType(property.type);

  const currentValue = property.value || [];
  const widgetsFilters = property.widgetFilters || [];
  const actual = property.actual || false;

  const {
    displayAvailablePeriod,
    currentDay,
    currentMonth,
    currentYear,
    currentWeek,
    overriddenFunction,
    dateFunction,
    selectDateTypeObject,
    displayAsSingleDateObject,
    displayAsPeriodObject,
    textFilterMode,
    isMandatoryFilter,
  } = useDashboardFilterProperties(property);

  const filterGroupId = property.id;

  const sourceIds = widgetsFilters.map((filter: any) => filter.sourceId);
  const fields = widgetsFilters.map((filter: any) => filter.name);

  const label = !hideLabel && getFilterGroupDisplayedName(property);

  if (actual && widgetsFilters.filter((widget) => widget.actual).length) {
    switch (remappedType) {
      case 'TEXT': {
        return (
          <SelectFilter
            onChangeFilter={handleChangeFilter}
            currentFilter={currentValue}
            allFieldFilters={allFieldFilters}
            label={label}
            field={fields}
            sourceId={sourceIds}
            filterGroupId={filterGroupId}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Dashboard}
            isSingleSelection={textFilterMode === TextFilterModeSelect.SingleSelect}
            isMandatory={isMandatoryFilter}
          />
        );
      }

      case 'NUMBER': {
        return (
          <ValueFilter
            onChangeFilter={handleChangeFilter}
            currentFilter={currentValue}
            label={label}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Dashboard}
          />
        );
      }

      case 'DATE': {
        const displayOptions = {
          displayAsSingleDate: {
            value: displayAsSingleDateObject?.value,
            displayedName: displayAsSingleDateObject?.displayedName,
          },
          displayAsPeriod: {
            value: displayAsPeriodObject?.value,
            displayedName: displayAsPeriodObject?.displayedName,
          },
        };

        const filterOptions: DateFilterOptions = {
          displayAvailablePeriod,
          quickOptions: {
            currentDay,
            currentWeek,
            currentMonth,
            currentYear,
          },
          overriddenFunction,
          minValue: property.minValue,
          maxValue: property.maxValue,
          displayOptions,
          selectDateType: selectDateTypeObject?.value || SelectDateTypes.date
        };

        return (
          <DateFilter
            onChangeFilter={handleChangeFilter}
            currentFilter={currentValue}
            label={label}
            filterType={
              dateFunction ? dateFunction.split("'")[1] : DateFunctions.NO_TYPE
            }
            filterOptions={filterOptions}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Dashboard}
            isMandatoryFilter={isMandatoryFilter}
          />
        );
      }

      case 'BOOLEAN': {
        return (
          <SelectFilter
            onChangeFilter={handleChangeFilter}
            currentFilter={currentValue}
            allFieldFilters={allFieldFilters}
            label={label}
            field={fields}
            sourceId={sourceIds}
            filterGroupId={filterGroupId}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Dashboard}
          />
        );
      }

      default: {
        return <div>Нет фильтра</div>;
      }
    }
  } else {
    return <></>;
  }
};
