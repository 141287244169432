import 'moment/locale/ru';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import RouteLayout from './components/route-layout/route-layout';
import ProjectPage from './pages/project-page/project-page';
import MainPage from './pages/main-page/main-page';
import WidgetPage from './pages/widget-page/widget-page';
import DashboardPage from './pages/dashboard-page/dashboard-page';
import PreviewDashboardsPage from './pages/preview-dashboards-page';
import AuthPage from './pages/auth-page/auth-page';
import { useNotificator } from './components/common/snackbar/hooks';
import { useEventListener } from './hooks/useEventListener';
import { ErrorPage } from './pages/error-page/error-page';
import { ErrorsHandler } from './providers/ErrorsHandler';
import { SystemSettings, State } from './slices/types';
import { useBaseSystemSettings } from './hooks/common/useBaseSystemSettings';

function App() {
  useBaseSystemSettings();

  const { showNotification } = useNotificator();

  useEventListener('apiUserError', (e: any) => {
    const { message } = e.detail;
    const defaultError = 'Ошибка';
    showNotification({
      message: message || defaultError,
      variant: 'error',
    });
  });

  const settingsData: SystemSettings | null = useSelector(
    (state: State) => state.mainPage.systemSettings,
  );

  useEffect(() => {
    const setSystemSettings = () => {
      if (settingsData) {
        const linkElement = document.head.getElementsByTagName('link')[0];
        linkElement.setAttribute('type', 'image/x-icon');
        linkElement.setAttribute('href', settingsData.SYSTEM_UI_FAVICON);

        !document.title && (document.title = settingsData.SYSTEM_UI_NAME);
      }
    };

    setSystemSettings();
  }, [settingsData]);

  return (
    <ErrorsHandler>
      <Router basename={process.env.PUBLIC_URL || ''}>
        <Switch>
          <RouteLayout path="/" exact component={MainPage} />
          <Route path="/error" component={ErrorPage} />
          <RouteLayout
            path="/project/:projectId/widget/:widgetId"
            component={WidgetPage}
          />
          <Route
            path="/shared/dashboard/:id"
            render={() => <PreviewDashboardsPage isSharedDashboard />}
          />
          <Route
            path="/project/:projectId/dashboard/:id/preview"
            component={PreviewDashboardsPage}
          />
          <Route
            path="/project/:projectId/dashboards"
            component={PreviewDashboardsPage}
          />
          <RouteLayout
            path="/project/:projectId/dashboard/:id"
            component={DashboardPage}
          />
          <RouteLayout
            path="/project/:projectId/widget"
            component={WidgetPage}
          />
          <RouteLayout
            path="/project/:projectId/dashboard"
            component={DashboardPage}
          />
          <Route path="/auth" component={AuthPage} />
          <RouteLayout path="/project/:id" component={ProjectPage} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </ErrorsHandler>
  );
}

export default App;
