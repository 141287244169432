import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { getSimplifiedType } from '../../../dropdown-layout/helpers/helpers';
import {
  Property,
  PropertyData,
} from '../../../dropdown-layout/helpers/Property';
import { PanelType } from '../../../../../enums/widget-type';
import { State, WidgetProperties } from '../../../../../slices/types';
import { usePanelItemActions } from '../usePanelItemActions';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';
import { getPossibleDeleteAggregationTypes } from '../../../helpers';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface FunctionsAndAggregationsBlocksProps {
  data: PropertyData;
  panel: WidgetProperties;
  index: number;
}

const deleteAggregationPanels = [PanelType.axisX, PanelType.axisFilter];

export const FunctionsAndAggregationsBlocks = ({
  data,
  panel,
  index,
}: FunctionsAndAggregationsBlocksProps) => {
  const widgetType = useSelector((state: State) => state.widget.type);
  const widget = useSelector((state: State) => state.widget);

  const deleteAggregationTypesList = getPossibleDeleteAggregationTypes(panel.name as PanelType, widgetType);

  const { changeAggregationHandler } = usePanelItemActions(widget);
  
  const propertyInstance = useMemo(() => new Property(data), [data]);
  const aggregationType = propertyInstance.getValue().aggregation;

  const functionType = propertyInstance.getValue().function;

  const hasRestriction = propertyInstance.getValue().restriction;

  const isPossibleDeleteAggregation = () => {
    return deleteAggregationPanels.includes(panel.name as PanelType) &&
      deleteAggregationTypesList.includes(getSimplifiedType(data.type));
  };

  const isPossibleDeleteFunction =
    getSimplifiedType(data.type) === WidgetSimplifiedDataType.DATE;

  const handleDeleteAggregation = () => {
    if (isPossibleDeleteAggregation()) {
      propertyInstance.changeAggregation('');
      changeAggregationHandler(propertyInstance, panel.name as PanelType, index);
    }
  };

  const handleDeleteFunction = useCallback(() => {
    if (isPossibleDeleteFunction) {
      propertyInstance.changeFunction('');
      changeAggregationHandler(propertyInstance, panel.name as PanelType, index);
    }
  }, [
    changeAggregationHandler,
    panel.name,
    index,
    propertyInstance,
    isPossibleDeleteFunction,
  ]);

  const handleDeleteRestriction = useCallback(() => {
    if (hasRestriction) {
      propertyInstance.changeRestriction(null);
      changeAggregationHandler(propertyInstance, panel.name as PanelType, index);
    }
  }, [
    changeAggregationHandler,
    panel.name,
    index,
    propertyInstance,
    hasRestriction,
  ]);

  return (
    <>
      {aggregationType ? (
        <div
          className={
            isPossibleDeleteAggregation()
              ? 'panel-item__type panel-item__delete-aggregation'
              : 'panel-item__type'
          }
          onClick={handleDeleteAggregation}
        >
          <IconSvg
            classSVG={
              isPossibleDeleteAggregation()
                ? 'panel-item__button-delete'
                : 'panel-item__button-delete panel-item__button-delete__active'
            }
            svg={IconDictionary.BasketDelete}
            width={24}
            height={24}
            fill="var(--red-normal)"
          />
          {propertyInstance.getViewAggregation()}
        </div>
      ) : null}

      {functionType ? (
        <div
          className={
            isPossibleDeleteFunction
              ? 'panel-item__type panel-item__delete-aggregation'
              : 'panel-item__type'
          }
          onClick={handleDeleteFunction}
        >
          <IconSvg
            classSVG={
              isPossibleDeleteFunction
                ? 'panel-item__button-delete'
                : 'panel-item__button-delete panel-item__button-delete__active'
            }
            svg={IconDictionary.BasketDelete}
            width={24}
            height={24}
            fill="var(--red-normal)"
          />
          {propertyInstance.getViewFunction()}{' '}
        </div>
      ) : null}

      {hasRestriction ? (
        <div
          className={
            hasRestriction
              ? 'panel-item__type panel-item__type--icon panel-item__delete-aggregation'
              : 'panel-item__type panel-item__type--icon'
          }
          onClick={handleDeleteRestriction}
        >
          <IconSvg
            classSVG={
              hasRestriction
                ? 'panel-item__button-delete'
                : 'panel-item__button-delete panel-item__button-delete__active'
            }
            svg={IconDictionary.BasketDelete}
            width={24}
            height={24}
            fill="var(--red-normal)"
          />
          <IconSvg
            svg={IconDictionary.PanelItemRestriction}
            width={24}
            height={24}
            fill="var(--primary-color)"
            classSVG="panel-item__button-filter"
          />
        </div>
      ) : null}
    </>
  );
};
