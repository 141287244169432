import { useMemo } from 'react';
import { FilterProperty } from '../../../../enums/dashboard-properties';
import { DateFilterOptions } from '../wrappers/date-filter/date-filter';

interface useDateFilterSettingsProps {
  filterOptions?: DateFilterOptions;
  selectDateType?: string;
}

export const useDateFilterSettings = ({
  selectDateType,
  filterOptions,
}: useDateFilterSettingsProps) => {
  const activeSettings = useMemo(
    () => filterOptions ?
      Object.keys(filterOptions.displayOptions)
        .filter((key) => filterOptions.displayOptions[key].value)
        .map((key) => ({
          value: filterOptions.displayOptions[key].displayedName,
          showValue: filterOptions.displayOptions[key].displayedName,
          name: key,
        })) : [],
    [filterOptions],
  );
  const isNeedToDisplaySelect = useMemo(
    () => activeSettings.length > 1,
    [activeSettings],
  );
  const isNeedToDisplayDate = activeSettings.find(
    (setting) =>
      setting.name === FilterProperty.displayAsSingleDate &&
      (selectDateType === setting.value || !isNeedToDisplaySelect),
  );
  const isNeedToDisplayPeriod = useMemo(
    () =>
      activeSettings.find(
        (setting) =>
          setting.name === FilterProperty.displayAsPeriod &&
          (selectDateType === setting.value || !isNeedToDisplaySelect),
      ),
    [activeSettings, isNeedToDisplaySelect, selectDateType],
  );

  const dateFromOperation = isNeedToDisplayPeriod ? '>=' : '=';

  return {
    activeSettings,
    isNeedToDisplayDate,
    isNeedToDisplaySelect,
    isNeedToDisplayPeriod,
    dateFromOperation,
  };
};
