export const isIFramed = () => {
  let isFramed;

  try {
    // eslint-disable-next-line no-restricted-globals
    isFramed = window !== window.top || document !== top?.document || self.location !== top?.location;
  } catch (e: any) {
    isFramed = false;
  }

  return isFramed;
};
