import { useMemo } from 'react';
import { uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { PanelType, WidgetType } from '../../../../../enums/widget-type';
import { getHiddenElementsTooltip } from '../../helpers';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { FilterInfoItemProps } from './filter-info-item';
import { State } from '../../../../../slices/types';

export const useFilterInfoItem = ({
  panelType,
  hiddenElements,
}: FilterInfoItemProps) => {
  const widget = useSelector((state: State) => state.widget);

  const axisXValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisX, widget.properties),
    [widget.properties],
  );

  const axisXValueNames = useMemo(
    () =>
      uniq(
        axisXValues
          .filter((item: PropertyData) => !item.hierarchyLink?.dicSourceId)
          .map((item: PropertyData) => item.etlFieldId),
      ),
    [axisXValues],
  );

  const axisZValueNames = useMemo(
    () =>
      uniq(
        getParsedAxisValues(PanelType.axisZ, widget.properties).map(
          (item: any) => item.etlFieldId,
        ),
      ),
    [widget.properties],
  );

  const setCountFiltersFromTableColumns = () => {
    switch (widget.type) {
      case WidgetType.TABLE:
        return axisXValueNames.length;
      case WidgetType.PIVOT_TABLE:
        return axisXValueNames.length + axisZValueNames.length;
      default:
        return 0;
    }
  };

  const countFiltersFromTableColumns =
    panelType === PanelType.axisFilter ? setCountFiltersFromTableColumns() : 0;

  const hiddenElementsTooltip = getHiddenElementsTooltip(hiddenElements);

  return {
    countFiltersFromTableColumns,
    hiddenElementsTooltip,
  };
};
