import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { State } from '../../../slices/types';
import './styles.css';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { dispatchRootEvent } from '../../../utils/root-helper';
import IconSvg from '../icon-svg/icon-svg';

const b = block('sidebar');

type SidebarProps = {
  isRight?: boolean;
  children?: React.ReactNode;
};

const Sidebar: React.FC<SidebarProps> = ({ children, isRight = false }) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const adviceMode = useSelector((state: State) => state.mapView.adviceMode);

  const handleOpenClick = () => {
    setOpen(!isOpen);
  };

  const handleContainerClick = () => {
    if (!isOpen) {
      setOpen(true);
    }
    // trigger resize for outer handlers
    setTimeout(() => {
      dispatchRootEvent(new Event('resize'));
    }, 0);
  };

  return (
    <div
      className={`${b()} ${isOpen ? '' : b('close')}
      ${isRight ? b('right') : ''} ${adviceMode ? 'sidebar--deep' : ''}`}
      onClick={handleContainerClick}
    >
      <div className={`${b('content')} ${isOpen ? '' : b('content-hidden')}`}>
        {children}
      </div>

      <button className={b('toggle')} type="button" onClick={handleOpenClick}>
        <IconSvg
          svg={
            isOpen
              ? IconDictionary.FillArrowToLeft
              : IconDictionary.FillArrowToRight
          }
          fill="var(--dark-grey)"
        />
      </button>
    </div>
  );
};

export default Sidebar;
