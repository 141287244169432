import { LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import React from 'react';
import clsx from 'clsx';
import { getScaleCoefficient } from '../../../../../helpers/ui-helper';
import { HeatMapGradientDefaultColors } from '../../../dropdown-panel/modals/heat-map-modal/heat-map-modal';
import { getIconsList } from '../map-helpers';
import {
  AdditionalMapLegendPosition,
  defaultIcon,
  MapDataTypes,
} from '../settings';
import { Color } from '../../common/color';
import { WidgetProperties } from '../../../../../slices/types';
import { useMapProperties } from '../hooks/useMapProperties';
import { LegendPositions } from '../../../../../enums/legend';

export type LegendProps = {
  currentColors: Color[];
  dataTypes: string[];
  heatMapLayers: boolean[];
  widgetProperties: WidgetProperties[];
  isNeedToDisplayCardsList: boolean;
  handleClickLegendItem: (layerIndex: number) => void;
  activeLayers: number[];
};

const legendGlyphSize = 18 * getScaleCoefficient();
const legendGlyphSizeOffset = 6 * getScaleCoefficient();

const MapLegend = ({
  currentColors,
  dataTypes,
  heatMapLayers,
  widgetProperties,
  isNeedToDisplayCardsList,
  handleClickLegendItem,
  activeLayers,
}: LegendProps) => {
  const {
    axisXValues,
    legendPosition,
    axisXValueNames,
    reverseGradientLayers,
  } = useMapProperties({ widgetProperties });

  const getLegendClasses = (): string => {
    const isNeedToDisplayLegendInMap = legendPosition === AdditionalMapLegendPosition.insideMap;
    const isTopOrBottomLegend = legendPosition === LegendPositions.Up ||
      legendPosition === LegendPositions.Down;

    return clsx('map-legend', {
      'map-legend_outside': !isNeedToDisplayLegendInMap,
      'map-legend_outside_top_bottom':
        !isNeedToDisplayLegendInMap && isTopOrBottomLegend,
    });
  };

  const ordinalColorScale = scaleOrdinal({
    domain: axisXValueNames.map((value: string, index: number) => String(index)),
    range: currentColors.slice(0, axisXValueNames.length),
  });

  const iconsList = getIconsList(axisXValues);

  const getItemColor = (
    index: number,
    label: {
      datum: string;
      index: number;
      text: string;
      value?: Color | undefined;
    },
  ) => {
    const disableColor = 'var(--dark-grey)';
    if (!activeLayers.includes(index)) return disableColor;

    if (dataTypes[index] === MapDataTypes.POLYGON) {
      return heatMapLayers[index]
        ? `url(#linear-gradient-${index}-${label.text})`
        : label.value;
    }
    if (dataTypes[index].includes(MapDataTypes.POINT)) {
      return label.value;
    }

    return label.value;
  };

  return (
    <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => label}>
      {(labels) => (
        <div className={getLegendClasses()}>
          {labels.map((label, i) => {
            const isReverseGradient = reverseGradientLayers.find(
              (item) => item.etlSourceId === axisXValues[i].etlSourceId,
            )?.value;
            const heatMapGradient = {
              startColor: isReverseGradient
                ? HeatMapGradientDefaultColors.red
                : HeatMapGradientDefaultColors.green,
              middleColor: HeatMapGradientDefaultColors.yellow,
              endColor: isReverseGradient
                ? HeatMapGradientDefaultColors.green
                : HeatMapGradientDefaultColors.red,
            };

            return (
              <div
                key={`legend-quantile-${i}`}
                className="map-legend__item"
                onClick={() => handleClickLegendItem(i)}
              >
                {iconsList[i] && iconsList[i] !== defaultIcon && (
                  <div
                    className={clsx('map-legend__icon-container', {
                      'map-legend__icon-container_disabled':
                        !activeLayers.includes(i),
                    })}
                    style={{ backgroundImage: `url('${iconsList[i]}')` }}
                  />
                )}
                {(!iconsList[i] || iconsList[i] === defaultIcon) && (
                  <svg
                    width={legendGlyphSize + legendGlyphSizeOffset}
                    height={legendGlyphSize + legendGlyphSizeOffset}
                  >
                    {dataTypes[i] === MapDataTypes.POLYGON && (
                      <>
                        <linearGradient
                          id={`linear-gradient-${i}-${label.text}`}
                        >
                          <stop
                            offset="0%"
                            stopColor={heatMapGradient.startColor}
                          />
                          <stop
                            offset="50%"
                            stopColor={heatMapGradient.middleColor}
                          />
                          <stop
                            offset="100%"
                            stopColor={heatMapGradient.endColor}
                          />
                        </linearGradient>
                        <rect
                          x={legendGlyphSizeOffset / 2}
                          y={legendGlyphSizeOffset / 2}
                          stroke={getItemColor(i, label)}
                          strokeWidth="2px"
                          fill={getItemColor(i, label)}
                          fillOpacity={0.5}
                          width={legendGlyphSize}
                          height={legendGlyphSize}
                          rx={2}
                        />
                      </>
                    )}
                    {dataTypes[i] !== MapDataTypes.POLYGON && (
                      <circle
                        r={legendGlyphSize / 2}
                        stroke={getItemColor(i, label)}
                        strokeWidth={3 * getScaleCoefficient()}
                        fill="var(--white)"
                        cx={(legendGlyphSize + legendGlyphSizeOffset) / 2}
                        cy={(legendGlyphSize + legendGlyphSizeOffset) / 2}
                      />
                    )}
                  </svg>
                )}
                <LegendLabel
                  className={clsx('legend-label__text', {
                    'legend-label__text_disabled': !activeLayers.includes(i),
                  })}
                >
                  {axisXValueNames[Number(label.text)]}
                </LegendLabel>
              </div>
            );
          })}
        </div>
      )}
    </LegendOrdinal>
  );
};

export default MapLegend;
