import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';
import { CSS } from '@dnd-kit/utilities';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import IconSvg from '../../../../../../common/icon-svg/icon-svg';
import { TextInput } from '../../../../../../common/special-inputs/inputs/text-input';
import { useNestedHeaders } from '../../../libs/hooks/useNestedHeaders';
import { Header } from '../../../libs/hooks/useTableData';
import { styles } from './styles';
import {
  IconButtonType,
  CustomIconButton,
} from '../../../../../../../uikit/IconButton';
import { ColorPicker } from '../../../../../settings/color-picker';
import { IconDictionary } from '../../../../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface HeadItemsProps {
  data: Header;
  isChildren: boolean;
  isLast: boolean;
}

const useStyles = makeStyles({ ...styles });

export const HeadItem: FC<HeadItemsProps> = ({ data, isChildren, isLast }) => {
  const [tempTitle, setTempTitle] = useState(data.title);
  const [debouncedTitle] = useDebounce(tempTitle, 300);

  const classes = useStyles({ isChildren });

  const {
    addHeader,
    getChildrenColumnsById,
    removeHeader,
    updateHeader,
    sortHeaders,
    tableNestedLevelsCount,
  } = useNestedHeaders();

  const childrenColumns = getChildrenColumnsById(data.id);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: data.id,
    strategy: verticalListSortingStrategy,
    transition: {
      duration: 300,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const style = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    zIndex: isDragging ? 999 : 0,
    transition,
  };

  const handleSortEnd = (event: any) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      sortHeaders({
        targetId: active.id,
        destinationId: over.id,
      });
    }
  };

  const handleChangeTitle = (e: any) => {
    setTempTitle(e.target.value);
  };

  const handleRemove = () => {
    removeHeader(data.id);
  };

  const handleAddChildren = () => {
    addHeader({ parentID: data.id });
  };

  const handleChangeBackgroundColor = (color: string) => {
    updateHeader(data.id, {
      styles: {
        ...data.styles,
        backgroundColor: color,
      },
    });
  };

  useEffect(() => {
    updateHeader(data.id, {
      title: debouncedTitle,
    });
  }, [data.id, debouncedTitle]);

  return (
    <div
      className={classes.container}
      ref={setNodeRef}
      style={style as any}
      {...attributes}
    >
      <div className={classes.header}>
        <Typography className={classes.title}>{tempTitle}</Typography>
        <div className={classes.actions}>
          <CustomIconButton
            iconButtonProps={{
              children: <IconSvg svg={IconDictionary.VerticalDragAndDrop} fill="var(--grey)" />,
              className: classes.actionsButton,
              ...listeners,
            }}
            type={IconButtonType.withoutEffects}
          />
          {!isChildren && tableNestedLevelsCount > 1 && (
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg fill="var(--primary-color)" svg={IconDictionary.PlusRounded} />,
                className: classes.actionsButton,
                onClick: handleAddChildren,
              }}
              type={IconButtonType.primary}
            />
          )}
          {((!isLast && isChildren) || !isChildren) && (
            <CustomIconButton
              iconButtonProps={{
                children: (
                  <IconSvg
                    svg={IconDictionary.BasketDelete}
                    fill="var(--grey)"
                  />
                ),
                onClick: handleRemove,
                className: classes.actionsButton,
              }}
              type={IconButtonType.delete}
            />
          )}
        </div>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextInput
            label="Название"
            handleChange={handleChangeTitle}
            value={tempTitle}
          />
        </Grid>
        <Grid item xs={4}>
          <ColorPicker
            onChangeColor={handleChangeBackgroundColor}
            value={data.styles.backgroundColor}
            isNeedToDisplayValue={false}
            isPossibleResetColor={false}
          />
        </Grid>
      </Grid>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleSortEnd}
      >
        {!!childrenColumns.length && (
          <Box mt="20px">
            <SortableContext items={childrenColumns}>
              {childrenColumns.map((childrenData) => {
                return (
                  <Box flexGrow={1} key={childrenData.id} mb="16px">
                    <HeadItem
                      data={childrenData}
                      isChildren={true}
                      isLast={childrenColumns.length === 1}
                    />
                  </Box>
                );
              })}
            </SortableContext>
          </Box>
        )}
      </DndContext>
    </div>
  );
};
