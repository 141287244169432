import { WidgetProperties } from 'src/slices/types';
import {
  PanelType,
  WidgetPropertyType,
} from '../../../../../enums/widget-type';
import {
  useGetWidgetProperty,
  useGetAxisWidgetProperty,
  useGetBooleanWidgetProperty,
  useGetColorPaletteWidgetProperty,
  useGetNumberFormatWidgetProperty,
  useGetNumberWidgetProperty,
  useGetStringWidgetProperty,
} from '../../../../../hooks/get-properties/useGetWidgetProperty';
import { textDirections } from '../../../../common/scalable-svg-text';
import { getLegendsLabelsForWidgetByAxis } from '../../helpers';
import { displayValuesPositions } from '../../histogram-graph/properties';

interface UseGraphPropertiesProps {
  widgetProperties: WidgetProperties[];
}

export const useOverlayGraphProperties = ({
  widgetProperties,
}: UseGraphPropertiesProps) => {
  const axisXValues = useGetAxisWidgetProperty(
    PanelType.axisX,
    widgetProperties,
    [],
  );
  const axisYValues = useGetAxisWidgetProperty(
    PanelType.axisY,
    widgetProperties,
    [],
  );
  const axisZValues = useGetAxisWidgetProperty(
    PanelType.axisZ,
    widgetProperties,
    [],
  );

  const roundingCountString = useGetStringWidgetProperty(
    WidgetPropertyType.roundingCount,
    widgetProperties,
  );

  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const displayValuesPosition = useGetStringWidgetProperty(
    WidgetPropertyType.displayValuesPosition,
    widgetProperties,
    displayValuesPositions.outside,
  );
  const textDirection = useGetStringWidgetProperty(
    WidgetPropertyType.TextDirection,
    widgetProperties,
    textDirections.horizontal,
  );

  const isNeedDisplayAggregationInLegend = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayAggregationInLegend,
    widgetProperties,
  );
  const isNeedToDisplayBarValues = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayBarValues,
    widgetProperties,
  );
  const isNeedToDisplayTooltip = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayTooltip,
    widgetProperties,
  );
  const isNeedToDisplayAxesGuide = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayAxesGuide,
    widgetProperties,
  );
  const isSmoothedLine = useGetBooleanWidgetProperty(
    WidgetPropertyType.smoothedLine,
    widgetProperties,
  );
  const scaleByNumber = useGetBooleanWidgetProperty(
    WidgetPropertyType.scaleByNumber,
    widgetProperties,
  );
  const isScaleByValue = useGetBooleanWidgetProperty(
    WidgetPropertyType.scaleByValue,
    widgetProperties,
  );
  const isNeedInterpolateHorizontalAxis = useGetBooleanWidgetProperty(
    WidgetPropertyType.interpolationAxis,
    widgetProperties,
  );
  const isNeedDisplayBrush = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayScroll,
    widgetProperties,
  );

  const customAxisYLabelWidth = useGetNumberWidgetProperty(
    WidgetPropertyType.axisYLabelWidth,
    widgetProperties,
    100
  );
  const customAxisZLabelWidth = useGetNumberWidgetProperty(
    WidgetPropertyType.axisXLabelHeight, // should be changed
    widgetProperties,
    100
  );

  const currentColors = useGetColorPaletteWidgetProperty(widgetProperties);

  const formatByNumber = useGetNumberFormatWidgetProperty(widgetProperties);

  const typeFillGraph = useGetWidgetProperty<
  'Нет' | 'Градиентная' | 'Сплошная'
  >(WidgetPropertyType.typeFillGraph, widgetProperties, 'Нет') as
    | 'Нет'
    | 'Градиентная'
    | 'Сплошная';

  const isNeedToDisplayOutsideBarValues: boolean =
    isNeedToDisplayBarValues &&
    displayValuesPosition === displayValuesPositions.outside;

  const isNeedToDisplayInsideBarValues: boolean =
    isNeedToDisplayBarValues &&
    displayValuesPosition === displayValuesPositions.inside;

  const legendsLabels = getLegendsLabelsForWidgetByAxis(
    PanelType.axisY,
    widgetProperties,
    isNeedDisplayAggregationInLegend,
  );

  const legendsZLabels = getLegendsLabelsForWidgetByAxis(
    PanelType.axisZ,
    widgetProperties,
    isNeedDisplayAggregationInLegend,
  );

  return {
    axisXValues,
    axisYValues,
    axisZValues,
    roundingCount,
    isNeedDisplayAggregationInLegend,
    legendsLabels,
    legendsZLabels,
    displayValuesPosition,
    isNeedToDisplayBarValues,
    isNeedToDisplayTooltip,
    isNeedToDisplayOutsideBarValues,
    isNeedToDisplayInsideBarValues,
    isNeedToDisplayAxesGuide,
    isNeedInterpolateHorizontalAxis,
    isNeedDisplayBrush,
    currentColors,
    isSmoothedLine,
    customAxisYLabelWidth,
    customAxisZLabelWidth,
    textDirection,
    typeFillGraph,
    scaleByNumber,
    formatByNumber,
    isScaleByValue,
  };
};
