import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WidgetType } from '../../enums/widget-type';
import { State, Widget, WidgetPayload } from '../types';
import { WidgetDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';

export const initialState: Widget = {
  properties: [],
  name: WidgetDictionary.title,
  sourceId: '',
  type: WidgetType.NONE,
  shared: false,
  pushDown: false,
  needCache: false,
  validationErrors: null,
};

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setWidgetSourceId: (state, { payload }: PayloadAction<string>) => {
      state.sourceId = payload;
    },
    setCurrentWidget: (state, { payload }: PayloadAction<Widget | ''>) => {
      return payload || initialState;
    },
    setWidgetName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setWidgetProp: (state, { payload }: PayloadAction<WidgetPayload>) => {
      const existingIndex = state.properties.findIndex(
        (property) => property.name === payload.name,
      );
      if (existingIndex !== -1) {
        state.properties[existingIndex] = payload;
      } else {
        const properties = state.properties || [];
        state.properties = [...properties, { ...payload }];
      }
    },
    setWidgetPropList: (state, { payload }: PayloadAction<WidgetPayload[]>) => {
      payload.forEach((property) => {
        const existingIndex = state.properties.findIndex(
          (oldProperty) => oldProperty.name === property.name,
        );
        if (existingIndex !== -1) {
          state.properties[existingIndex] = property;
        } else {
          const properties = state.properties || [];
          state.properties = [...properties, { ...property }];
        }
      });
    },
    setWidgetProps: (
      state,
      { payload } /* : PayloadAction<WidgetProperties> */,
    ) => {
      state.properties = payload;
    },
    setWidgetSharedStatus: (state, { payload }) => {
      state.shared = payload;
    },
    setWidgetPushDown: (state, { payload }) => {
      state.pushDown = payload;
    },
    setWidgetValidationError: (state, { payload }: PayloadAction<{ key: string; value: string; }>) => {
      if (state.validationErrors) {
        state.validationErrors[payload.key] = payload.value;
      } else {
        state.validationErrors = {
          [payload.key]: payload.value
        };
      }
    },
    resetWidgetValidationError: (state, { payload }: PayloadAction<{ key: string; }>) => {
      if (state.validationErrors && state.validationErrors[payload.key]) {
        delete state.validationErrors[payload.key];
      }
    },
    resetWidgetValidationErrors: (state) => {
      state.validationErrors = null;
    },
    removeWidgetProp: (state, { payload }: PayloadAction<string>) => {
      state.properties = state.properties.filter((prop) => prop.name !== payload);
    },
  },
});

export const widgetReducer = widgetSlice.reducer;

export const {
  setWidgetProp: setWidgetPropAction,
  setWidgetPropList: setWidgetPropListAction,
  setWidgetSourceId: setWidgetSourceIdAction,
  setCurrentWidget: setCurrentWidgetAction,
  setWidgetProps: setWidgetPropsAction,
  setWidgetName: setWidgetNameAction,
  setWidgetSharedStatus: setWidgetSharedStatusAction,
  setWidgetPushDown: setWidgetPushDownAction,
  setWidgetValidationError: setWidgetValidationErrorAction,
  resetWidgetValidationErrors: resetWidgetValidationErrorsAction,
  resetWidgetValidationError: resetWidgetValidationErrorAction,
  removeWidgetProp: removeWidgetPropAction
} = widgetSlice.actions;
