import block from 'bem-cn';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useUpdateDataAfterChangeLoaderStatus } from '../../../../hooks/useUpdateDataAfterChangeLoaderStatus';
import './layout-header.css';
import { ParametersButton } from '../../../common/parameters/parameters-button';
import {
  isDashboardPage as getIsDashboardPage,
  isEditDashboardPage,
  isNewDashboardPage,
  isWidgetPage as getIsWidgetPage,
} from '../../../widget-page/charts/helpers';
import { SaveButton } from '../../../widget-page/widget-save-button';
import { State } from '../../../../slices/types';
import { FiltersButton } from '../../../dashboard-page/filters/button';
import { ClearCacheButton } from '../../../common/special-buttons/cache-clear-button';
import { HeadFilters } from '../../../dashboard-page/filters/head-filters';
import { useDashboardSettings } from '../../../dashboard-page/settings/hooks/useDashboardSettings';
import { apiGetLastCacheUpdate } from '../../../../services/projectsController';
import { UserInfo } from '../../../common/user-info/user-info';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { ShareButton } from '../../../common/special-buttons/share-button/share-button';

type LayoutHeaderProps = {};

const b = block('layout-header');

const LayoutHeader: React.FC<LayoutHeaderProps> = () => {
  const { showCacheUpdateTime } = useDashboardSettings();
  const [lastCacheUpdate, setLastCacheUpdate] = useState<string>('');
  const projectId: number =
    parseInt(useParams<{ projectId: string }>()?.projectId) ?? 0;

  const currentPage = window.location.pathname.toLowerCase();
  const isNoHeader = /\/noheader/.test(currentPage);


  const isWidgetPage = getIsWidgetPage();
  const isDashboardPage = getIsDashboardPage();
  const isProjectPage = window.location.pathname.includes('/project');

  const systemSettings = useSelector((state: State) => state.mainPage.systemSettings);
  const projectName: string = useSelector(
    (state: State) => state.mainPage?.currentProject?.name ?? '',
  );
  const loaderId: number = useSelector(
    (state: State) => state.mainPage?.currentProject?.loaderId ?? 0,
  );

  const projectGroupName = useSelector(
    (state: State) => state.mainPage?.currentProject?.projectGroupName,
  );

  const cacheLastUpdateFetcher = useCallback(() => {
    if (!projectId) return;
    apiGetLastCacheUpdate(projectId).then((response) => {
      setLastCacheUpdate(response);
    });
  }, [projectId]);

  useEffect(() => {
    cacheLastUpdateFetcher();
  }, [cacheLastUpdateFetcher]);

  const callbackRefreshData = useCallback(() => {
    cacheLastUpdateFetcher();
  }, [cacheLastUpdateFetcher]);

  useUpdateDataAfterChangeLoaderStatus('LayoutHeader', callbackRefreshData);

  const projectTitle = isWidgetPage || isDashboardPage || isProjectPage
    ? `${
      projectGroupName ? `${projectGroupName} /` : ''
    } ${projectName}`
    : systemSettings?.SYSTEM_UI_NAME || 'Алмаз BI';

  return (
    <>
      {isNoHeader ? null :
        (
          <header className={b()}>
            <div className={b('title-container')}>
              <CustomTooltip title={projectTitle}>
                <span>{projectTitle}</span>
              </CustomTooltip>
            </div>
            {isDashboardPage && <HeadFilters />}

            {showCacheUpdateTime && lastCacheUpdate && isDashboardPage ? (
              <div className="project-card-update__time project-card-update__floating">
                <div className="project-card-update__time-icon">
                  <IconSvg svg={IconDictionary.TimeRefresh} fill='var(--dark-grey)' />
                </div>
                <div className="project-card-update__time-date">
                  {lastCacheUpdate}
                </div>
              </div>
            ) : null}

            {(isWidgetPage || isEditDashboardPage()) && <ParametersButton />}
            {isDashboardPage && <FiltersButton />}
            {(isWidgetPage || isDashboardPage) && (
              <div className="header-widget-buttns">
                {isDashboardPage && !isNewDashboardPage() && (
                  <ShareButton />
                )}
                <ClearCacheButton loaderId={loaderId} standAlone={true} />
                <SaveButton />
              </div>
            )}
            <UserInfo />
          </header>
        )}
    </>
  );
};

export default LayoutHeader;
