import { getInputPropertyValue } from '../components/widget-page/charts/helpers';
import { WidgetProperties } from '../slices/types';
import isNaN from 'lodash/isNaN';

export const useRoundingCounts = (
  widgetProperties: WidgetProperties[],
): number | undefined => {
  const MAX_FRACTION_DIGITS = 8;
  const MIN_FRACTION_DIGITS = 0;

  const roundingCountString: string = getInputPropertyValue(
    'roundingCount',
    widgetProperties,
  );
  if (isNaN(parseInt(roundingCountString))) {
    return undefined;
  }

  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  if (roundingCount === undefined) return undefined;

  if (roundingCount > MAX_FRACTION_DIGITS) return MAX_FRACTION_DIGITS;
  if (roundingCount < MIN_FRACTION_DIGITS) return MIN_FRACTION_DIGITS;

  return roundingCount;
};
