export const stageSettings = {
  top: 20,
  left: 20,
};

export const numTicks = 5;

export const leftAxisSettings = {
  left: 65,
  top: 0,
  topOffset: 25,
  width: 65,
  color: 'var(--grey)',
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'end',
};

export const bottomAxisSettings = {
  color: 'var(--grey)',
  left: stageSettings.left,
  labelOffsetX: 5,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'center',
};

export const gridLinesColor = '#D8D8D8';
export const gridLinesXOffset = 0;
export const gridLinesYOffset = 0;

export const axisBottomOffset = 140;
export const minContainerHeightForDisplayAxisBottom = 150 + axisBottomOffset;
export const offsetForHorizontalText = 50;


export const circleParams = {
  radius: 8,
};

export const quadCircleRadius = circleParams.radius * 4;
export const doubleCircleRadius = circleParams.radius * 2;

export const minDistanceBetweenTooltips = 34;
export const initialBottomAxisSettingsHeight = 60;
export const initialBottomAxisSettingsExtendedHeight = 100;
export const yRangeOffset = 10;

export const centerLinesXOffset = 10;
export const minWidthCustomAxisYLabel = 33;
