import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from '../Button';
import CustomDialog, {
  CustomDialogProps,
} from '../Dialog/custom-dialog';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../components/common/icon-svg/icon-svg';

interface ConfirmDialogProps extends CustomDialogProps {
  message: string;
  onConfirm: () => void;
}

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    paddingTop: 20,
  },
  text: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 'normal',
    fontSize: 'calc(16px * var(--scale-coefficient))',
    lineHeight: 'calc(20px * var(--scale-coefficient))',
    margin: 'calc(10px * var(--scale-coefficient)) 0 calc(18px * var(--scale-coefficient)) 0',
  },
});

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {
    isOpen,
    onClose,
    onConfirm,
    title,
    description,
    message,
    maxWidth,
    children,
  } = props;

  const classes = useStyles();

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      description={description}
      maxWidth={maxWidth}
    >
      {children && children}
      <section className={classes.root}>
        <IconSvg svg={IconDictionary.InfoRounded} width={40} height={40} fill="var(--dark-grey)" />
        <h2 className={classes.text}>
          {message}
        </h2>

        <div className="dialog-buttons">
          <CustomButton
            variant="contained"
            onClick={onConfirm}
          >
            Продолжить
          </CustomButton>

          <CustomButton
            variant="outlined"
            onClick={onClose}
          >
            Отменить
          </CustomButton>
        </div>
      </section>
    </CustomDialog>
  );
};
