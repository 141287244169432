import { uniq } from 'lodash';
import { getStorageName } from 'src/helpers/common-helpers';
import { getSimplifiedType } from '../../dropdown-layout/helpers/helpers';
import { PropertyData } from '../../dropdown-layout/helpers/Property';
import { BubbleCartWidgetData, BubbleData, SeriesValue } from './types';

export const getBubbleData = (
  widgetData: BubbleCartWidgetData[],
): BubbleData[] => {
  const axisXLabels = uniq(
    widgetData.map((data: BubbleCartWidgetData) => data.axisLabel),
  );

  const groupedByLabel = widgetData.reduce<
  Record<string, BubbleCartWidgetData[]>
  >((acc, item) => {
    return {
      ...acc,
      [item.axisLabel]: [...(acc[item.axisLabel] || []), item],
    };
  }, {});

  const sortedByLabelResult: BubbleCartWidgetData[][] = axisXLabels.map(
    (type) => groupedByLabel[type],
  );

  return sortedByLabelResult.map(
    (xValues: BubbleCartWidgetData[], index: number) => {
      const collapsedXValue: BubbleData = {
        axisXValue: xValues[0].axisLabel,
        axisXPosition: index + 1,
        axisYValues: [],
        axisYPercents: [],
        axisZValues: [],
        axisZRadius: [],
        axisZPercents: [],
        slices: [],
      };

      xValues.forEach((domain) => {
        domain.values.forEach((yValue, index) => {
          collapsedXValue.axisYValues.push(yValue);
          collapsedXValue.axisYPercents.push(domain.percents[index]);
          collapsedXValue.axisZValues.push(domain.zvalue);
          collapsedXValue.axisZRadius.push(domain.radius);
          collapsedXValue.axisZPercents.push(domain.zpercent);
          collapsedXValue.slices.push(domain.slice);
        });
      });

      return collapsedXValue;
    },
  );
};

export const getSeriesValues = (
  seriesName: string,
  axisValues: PropertyData[],
  additionalSets?: {
    colors?: string[];
    legendsLabels?: string[];
  },
): SeriesValue[] => {
  const { colors = [], legendsLabels = [] } = additionalSets || {};
  const currentAxisValues = axisValues || [];
  return currentAxisValues
    .filter((axisValue) => axisValue.isActive)
    .map((axisValue, index) => {

      const aliasName = getStorageName(axisValue.storage);

      return {
        id: axisValue.id || axisValue.widgetFieldId || null,
        name: aliasName || axisValue.displayName || axisValue.name,
        key: `${seriesName}-${index}`,
        color: colors?.[index] || '#000',
        simplifiedType: getSimplifiedType(axisValue.type),
        legendsLabel: legendsLabels?.[index],
      };
    });
};
