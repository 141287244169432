import React from 'react';
import { Drawer } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import block from 'bem-cn';
import LoaderHistory from '../../common/loader-history/loader-history';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import LoaderSchedule from '../../main-page/loader-schedule/loader-schedule';
import './loader-controls.css';
import { useMapControls } from './useMapControls';
import { CustomPopover } from '../../../uikit/Popover/popover';
import { CustomPopoverMenu } from '../../../uikit/PopoverMenu/popover-menu';
import { CustomButton } from '../../../uikit/Button';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

const b = block('loader-controls');

export const LoaderControls = () => {
  const {
    currentTitle,
    loaderData,
    currentIsPublished,
    isEditableMap,
    onPublishChange,
    isHistoryOpened,
    closeHistory,
    currentProject,
    isScheduleOpened,
    closeSchedule,
    publishValidationError,
    closePublishErrorModal,
    controls,
    anchorEl,
    handleMenuClick,
    handleCloseMenu,
  } = useMapControls();

  return (
    <div className={b()}>
      <div className={b('container')}>
        <div className={b('title')}>{currentTitle}</div>
        <div className={b('buttons-container')}>
          <CustomButton
            variant="outlined"
            endIcon={
              <IconSvg
                svg={
                  anchorEl
                    ? IconDictionary.SelectArrowUp
                    : IconDictionary.SelectArrowDown
                }
                fill="var(--dark-grey)"
              />
            }
            onClick={handleMenuClick}
          >
            Дополнительно
          </CustomButton>
          <CustomPopover
            open={!!anchorEl}
            onClose={handleCloseMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <CustomPopoverMenu settings={controls} />
          </CustomPopover>

          {typeof loaderData?.published === 'boolean' && (
            <CustomButton
              variant="contained"
              disabled={!isEditableMap}
              onClick={onPublishChange}
            >
              {currentIsPublished ? 'В черновики' : 'Опубликовать'}
            </CustomButton>
          )}
        </div>
      </div>

      <Drawer
        open={Boolean(isHistoryOpened)}
        anchor="right"
        onClose={closeHistory}
      >
        {currentProject && (
          <LoaderHistory
            loaderId={currentProject.loaderId}
            projectName={currentProject.name}
            handleClose={closeHistory}
          />
        )}
      </Drawer>

      <Drawer
        open={Boolean(isScheduleOpened)}
        anchor="right"
        onClose={closeSchedule}
      >
        {currentProject && (
          <LoaderSchedule
            loaderId={currentProject.loaderId}
            handleClose={closeSchedule}
          />
        )}
      </Drawer>

      <CustomDialog
        isOpen={Boolean(publishValidationError)}
        onClose={closePublishErrorModal}
        title="Ошибка"
        maxWidth="xs"
      >
        <Alert severity="error">
          <AlertTitle>Валидация не пройдена</AlertTitle>
          {publishValidationError}
        </Alert>
      </CustomDialog>
    </div>
  );
};
