import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetBooleanWidgetProperty,
  useGetWidgetProperty
} from '../../../../hooks/get-properties/useGetWidgetProperty';
import { WidgetProperties } from '../../../../slices/types';
import { AxesValues, checkMinMultipleBookmark } from './bookmark-popover/useBookmarkPopover';
import { BookmarksProps } from './index';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import Dictionaries from '../../../../types/dictionaries';
import { apiGetAxesAliases } from '../../../../services/dictionariesController';
import { getParsedAxisValues } from '../../../widget-page/dropdown-layout/helpers/helpers';
import { PropertyData } from '../../../widget-page/dropdown-layout/helpers/Property';
import {
  AxisWithBookmarks,
  getPanelItemName,
  isMultipleAxisYWithBookmarks,
} from '../../../../helpers/common-helpers';

export type BookmarkValidationRules = (
  axisValues: PropertyData[],
  axesValues: Record<PanelType, PropertyData[]>,
) => string | null;

export interface Bookmark {
  name: string;
  value: string;
  axis: PanelType;
  isVisible: boolean;
  multiple: boolean;
  validationRules?: BookmarkValidationRules[];
}

export const useBookmarks = ({
  widgetProperties,
  widgetType,
}: BookmarksProps) => {
  const axisXValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisX, widgetProperties),
    [widgetProperties],
  );
  const axisYValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisY, widgetProperties),
    [widgetProperties],
  );

  const isMultiSelect = useGetBooleanWidgetProperty(WidgetPropertyType.multiSelect, widgetProperties, true);

  const axisXActiveValuesCount = axisXValues.filter(
    (item) => item.isActive,
  ).length;
  const axisYActiveValuesCount = axisYValues.filter(
    (item) => item.isActive,
  ).length;

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [currentBookmark, setCurrentBookmark] = React.useState<Bookmark | null>(
    null,
  );
  const [axesNames, setAxesNames] = useState<Dictionaries.AxesAliases | null>(
    null,
  );

  useEffect(() => {
    apiGetAxesAliases(widgetType, AxisWithBookmarks)
      .then((res) => setAxesNames(res))
      .catch(() => {});
  }, [widgetType]);

  const axisXValue = axisXValues.length
    ? axisXValues.find((item) => item.isActive) || axisXValues[0]
    : null;
  const axisYValue = axisYValues.length
    ? axisYValues.find((item) => item.isActive) || axisYValues[0]
    : null;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    bookmark: Bookmark,
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentBookmark(bookmark);
  };

  const bookmarksList: Bookmark[] = [
    {
      name: axesNames ? axesNames[PanelType.axisX] : '',
      value: axisXValue
        ? getPanelItemName(axisXValue) +
          (axisXActiveValuesCount > 1 ? ` +${axisXActiveValuesCount - 1}` : '')
        : '',
      axis: PanelType.axisX,
      isVisible: axisXValues.length > 1,
      multiple: false,
    },
    {
      name: axesNames ? axesNames[PanelType.axisY] : '',
      value: axisYValue
        ? getPanelItemName(axisYValue) +
          (axisYActiveValuesCount > 1 ? ` +${axisYActiveValuesCount - 1}` : '')
        : '',
      axis: PanelType.axisY,
      isVisible: axisYValues.length > 1 && (isMultiSelect ? true : !isMultipleAxisYWithBookmarks(widgetType)),
      multiple: isMultipleAxisYWithBookmarks(widgetType),
      validationRules: isMultipleAxisYWithBookmarks(widgetType) ? [checkMinMultipleBookmark] : undefined
    },
  ];

  const handleClickClose = useCallback(() => {
    setAnchorEl(null);
    setCurrentBookmark(null);
  }, []);

  return {
    bookmarksList,
    handleClick,
    handleClickClose,
    anchorEl,
    currentBookmark,
  };
};
