import React from 'react';
import { CustomPopover } from '../../../../../uikit/Popover/popover';
import { CustomPopoverMenu } from '../../../../../uikit/PopoverMenu/popover-menu';

interface FunctionsListProps {
  isOpenPopover: boolean;
  handleCloseFunctionsList: any;
  items: any;
  handleChangeFunction: any;
  popoverId: any;
  anchorEl: any;
}

const FunctionsList = ({
  popoverId,
  isOpenPopover,
  handleCloseFunctionsList,
  anchorEl,
  items,
  handleChangeFunction,
}: FunctionsListProps) => {
  const functions = items?.map((item: any) => ({
    title: item?.description,
    onClick: () => handleChangeFunction(item),
  }));

  return (
    <CustomPopover
      id={popoverId}
      open={isOpenPopover}
      onClose={handleCloseFunctionsList}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <CustomPopoverMenu settings={functions} />
    </CustomPopover>
  );
};

export default FunctionsList;
