import { apiETL } from '../utils/api';
import { LOADERS_URL } from './loadersController';
import Schedule from '../types/loader-schedule';

/* Получение расписания загрузчика */
export const apiFetchLoaderSchedule = async (
  loaderId: number,
): Promise<Schedule.FullData> => {
  try {
    const { data } = await apiETL.get(`${LOADERS_URL}/${loaderId}/scheduler`);
    return data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Получение расписания загрузчика */
export const apiSetLoaderSchedule = async (
  loaderId: number,
  body: Schedule.FullData,
): Promise<Schedule.FullData> => {
  try {
    const response = await apiETL.put(
      `${LOADERS_URL}/${loaderId}/scheduler`,
      body,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Получение расписания ближайших запусков загрузчика */
export const apiFetchFutureLaunches = async (
  requestBody: Schedule.Launches.Request,
  count: number = 10,
): Promise<Array<string>> => {
  try {
    const response = await apiETL.put(
      '/scheduler/nearestLaunches',
      requestBody,
      {
        params: { count },
      },
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiSetLoaderScheduleActivity = async (
  loaderId: number,
  isActive: boolean
) => {
  try {
    const response = await apiETL.put(`${LOADERS_URL}/${loaderId}/scheduler/activity/${isActive}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};
