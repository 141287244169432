import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { NodeComponentProps } from 'react-flow-renderer/dist/types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import block from 'bem-cn';
import reactStringReplace from 'react-string-replace';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { State } from '../../../../slices/types';
import { setDeletingAction } from '../../../../slices/main-page/main-page-slice';
import {
  changeCurrentSourceIdAction,
  setBottomTableContentAction,
  setBottomTableErrorAction,
  setBottomTableLabelAction,
  toggleShowSelectFieldsPanelAction,
} from '../../../../slices/map-view/map-view';
import ButtonDelete from './button-delete';
import { mapConnectionAction } from '../../../../slices/map-connection/map-connection';
import './source-node.css';
import { apiGetTableContentPreviewBySourceId } from '../../../../services/sources';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import { WidgetDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { useRequestCanceller } from '../../../../hooks/useRequestCanceller';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface SourceData {
  sourceObjectId: string;
  label: string;
  isNewSource: boolean;
  isSelected?: boolean;
  isErrorSource?: boolean;
  isNeedPreviewIcon: boolean;
  isAvailableInOperations: boolean;
  sourceId: number;
  showLeftHandle: boolean;
  showRightHandle: boolean;
}

export interface SourceNodeProps extends NodeComponentProps {
  data: SourceData;
}

const SourceNode: React.FC<SourceNodeProps> = ({
  data: {
    sourceObjectId = '',
    label = 'Название по умолчанию',
    isNewSource = false,
    isSelected = false,
    isErrorSource = false,
    isNeedPreviewIcon = true,
    isAvailableInOperations = false,
    sourceId = 0,
    showLeftHandle,
    showRightHandle,
  },
  type,
  id,
}) => {
  const dispatch = useDispatch();
  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;
  const filteredSources = useSelector((state: State) => state.mapView.sourceFilter);

  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );

  const { callCancellable } = useRequestCanceller();

  const [error, setError] = useState<string>('');
  const adviceMode = useSelector((state: State) => state.mapView.adviceMode);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nodeData = {
    id,
    type,
    name: label,
  };

  const settingError = (errorMessage: string) => {
    setIsLoading(false);
    setError(errorMessage);
  };

  const handleDoubleClick = () => {
    if (isNewSource) return;
    dispatch(toggleShowSelectFieldsPanelAction(true));
    dispatch(changeCurrentSourceIdAction(id));
  };

  const handleDeleteButtonClick = async () => {
    try {
      dispatch(setDeletingAction(true));
      setIsLoading(true);
      dispatch(
        mapConnectionAction.deleteSourceObject(loaderId, id, settingError),
      );
    } catch (er) {
      settingError(`Элемент используется для построения \n ${WidgetDictionary.oneOf}`);
    }
  };

  const handlePreviewButtonClick = async () => {
    dispatch(setBottomTableLabelAction(label));
    dispatch(setBottomTableErrorAction({ isError: false, errorMessage: undefined }));
    try {
      const data = await callCancellable(apiGetTableContentPreviewBySourceId, { sourceObjectId });
      if (data) {
        dispatch(setBottomTableContentAction({ label, data }));
      } else {
        dispatch(setBottomTableErrorAction({ isError: true }));
      }
    } catch (error: any) {
      dispatch(setBottomTableErrorAction({ isError: true, errorMessage: error?.response?.data?.message }));
      console.error(error);
    }
  };

  const renderIcons = () => {
    if (isNewSource) {
      return <IconSvg svg={IconDictionary.New} fill="var(--primary-color)" />;
    }
    return (
      <>
        {isNeedPreviewIcon && (
          <CustomTooltip arrow title="Предпросмотр">
            <div>
              <CustomIconButton
                iconButtonProps={{
                  children: <IconSvg svg={IconDictionary.OpenFile} width={24} height={24} fill="var(--medium-grey)" />,
                  onClick: handlePreviewButtonClick,
                  onTouchStart: handlePreviewButtonClick
                }}
                type={IconButtonType.secondary}
              />
            </div>
          </CustomTooltip>
        )}
      </>
    );
  };

  const b = block('source-node');

  const sourceClasses = classNames(
    b(),
    { [b('selected')]: isSelected },
    { [b('disabled')]: adviceMode && !isAvailableInOperations },
    { [b('error')]: isErrorSource },
    { [b('nopan')]: adviceMode },
  );

  const isFilteredSourceOnInitName = () => {
    return (
      !label.toUpperCase().includes(filteredSources.value.toUpperCase()) &&
      filteredSources.sourceIds.includes(id)
    );
  };

  return (
    <div className={sourceClasses} onDoubleClick={handleDoubleClick} onTouchEnd={handleDoubleClick}>
      <div className='source-node__body'>
        <div className='source-node__icon-section'>
          <IconSvg svg={IconDictionary.Database} width={24} height={24} fill="#26A9CD" />
        </div>
        <div className='source-node__content-section'>
          <CustomTooltip title={label}>
            <div className='source-node__title'>
              <p className={b('title')} title={label} style={{ backgroundColor: isFilteredSourceOnInitName() ? 'yellow' : 'inherit' }}>
                {
                  reactStringReplace(label, filteredSources.value, (match) => (
                    <span style={{ backgroundColor: 'yellow' }}>{match}</span>
                  ))
                }
              </p>
            </div>
          </CustomTooltip>
        </div>

        <div className={b('action-section')}>{renderIcons()}</div>
      </div>

      <Handle
        type='target'
        position={Position.Left}
        style={{ opacity: showLeftHandle ? 1 : 0 }}
        isConnectable={false}
      />
      <Handle
        type='source'
        position={Position.Right}
        style={{ opacity: showRightHandle ? 1 : 0 }}
        isConnectable={false}
      />

      {isEditableMap && (<ButtonDelete
        onDelete={handleDeleteButtonClick}
        nodeData={nodeData}
        error={error}
        isLoading={isLoading}
      />)}
    </div>
  );
};

export default SourceNode;
