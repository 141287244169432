import React, { FC } from 'react';
import block from 'bem-cn';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

const b = block('no-schedule');

const NoSchedule: FC = () => {
  return (
    <div className={b()}>
      <IconSvg svg={IconDictionary.NoSchedule} width={80} height={90} fill="var(--dark-grey)" />
      <div className={b('title')}>Загрузчик без расписания</div>
      <div className={b('subtitle')}>
        Вы сможете самостоятельно запускать загрузчик.
        Для автоматического запуска включите функцию «Запуск по расписанию»
      </div>
    </div>
  );
};
export default NoSchedule;
