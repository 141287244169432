import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { CssVariables } from '../../../enums/css-variables';
import { varcss } from '../../../helpers/ui-helper';
import { isMobilehelper } from '../../../utils/mobile-helper';
import IconSvg from '../../common/icon-svg/icon-svg';
import { ParametersButton } from '../../common/parameters/parameters-button';
import { FiltersButton } from '../../dashboard-page/filters/button';
import '../style.css';
import { isDashboardPage, isWidgetPage } from '../../widget-page/charts/helpers';
import { ExportButton } from '../dashboard-export';
import { HeadFilters } from '../../dashboard-page/filters/head-filters';
import { ClearCacheButton } from '../../common/special-buttons/cache-clear-button';
import { UserInfo } from '../../common/user-info/user-info';
import { useProjectDashboardsHeader } from '../hooks/useProjectDashboardsHeader';
import { CustomButton } from '../../../uikit/Button';
import { UpdateTime } from './update-time';
import { DashboardDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { State } from '../../../slices/types';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { PreviewSaveButton } from './preview-save-button';

export type ProjectDashboardsHeaderProps = {
  projectId: number | null;
  dashboardId: number;
  isSharedDashboard: boolean;
};

export const ProjectDashboardsHeader = ({
  projectId,
  dashboardId,
  isSharedDashboard,
}: ProjectDashboardsHeaderProps) => {
  const systemSettings = useSelector((state: State) => state.mainPage.systemSettings);

  const {
    isMobile,
    classes,
    currentDashboardOption,
    onDashboardChange,
    projectDashboardsList,
    showCacheUpdateTime,
    lastCacheUpdate,
    isNeedToDisplayEditButton,
    loaderId,
    isNeedToDisplayExportButton,
    isNoHeader,
    projectDashboardTitle,
  } = useProjectDashboardsHeader({
    projectId,
    dashboardId,
    isSharedDashboard,
  });

  return (
    <>
      {isNoHeader ? null : (
        <div
          className="dashboards-header"
          style={{ position: isMobile ? 'static' : 'fixed' }}
        >
          <div className="dashboards-navigation">
            <div className="dashboards-navigation__routing">
              <div className="dashboards-navigation__select">
                <NavLink to={isSharedDashboard ? '#' : '/'}>
                  <div className="dashboards-navigation__logo-container">
                    {systemSettings?.SYSTEM_UI_LOGO && (
                      <img
                        className="dashboards-navigation__logo"
                        src={systemSettings?.SYSTEM_UI_LOGO}
                        alt=""
                      />
                    )}
                    {!systemSettings?.SYSTEM_UI_LOGO && (
                      <IconSvg
                        svg={IconDictionary.MainLogo}
                        classSVG="dashboards-navigation__logo"
                        fill="var(--white)"
                      />
                    )}
                  </div>
                </NavLink>
                {projectDashboardsList.length === 1 || isSharedDashboard ? (
                  <div className="widget-sidebar__select">
                    <div className={classes['select-root']}>
                      {projectDashboardTitle}
                    </div>
                  </div>
                ) : (
                  <Select
                    classes={{
                      root: classes['select-root'],
                      selectMenu: classes['select-menu'],
                    }}
                    MenuProps={{
                      classes: { paper: classes.paper },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                    }}
                    className="widget-sidebar__select"
                    placeholder={DashboardDictionary.titleMany}
                    value={currentDashboardOption}
                    onChange={onDashboardChange}
                    IconComponent={() => (
                      <IconSvg
                        classSVG="dashboards-navigation__select-icon"
                        fill="var(--dark-grey)"
                        svg={IconDictionary.SelectArrowDown}
                      />
                    )}
                  >
                    {projectDashboardsList.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        classes={{ root: classes['MuiMenuItem-root'] }}
                      >
                        <CustomTooltip title={item.showValue} key={item.value}>
                          <div className={classes['select-menu__value']}>
                            {item.showValue}
                          </div>
                        </CustomTooltip>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            </div>

            {Boolean(showCacheUpdateTime && lastCacheUpdate) && (
              <UpdateTime time={lastCacheUpdate} />
            )}

            <div className="dashboards-header__controls">
              <div className="dashboards-header__controls_main">
                <HeadFilters />
                <ParametersButton />
                <FiltersButton />
                {!isSharedDashboard && !isMobilehelper() && (
                  <PreviewSaveButton />
                )}
                {isNeedToDisplayEditButton && (
                  <CustomTooltip title="Редактировать">
                    <NavLink
                      to={`/project/${projectId}/dashboard/${dashboardId}`}
                    >
                      <CustomButton
                        variant="outlined"
                        icon={
                          <IconSvg
                            svg={IconDictionary.EditFile}
                            fill="var(--primary-color)"
                          />
                        }
                        backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
                      />
                    </NavLink>
                  </CustomTooltip>
                )}
                {!isSharedDashboard && (
                  <ClearCacheButton
                    loaderId={loaderId}
                    standAlone={true}
                  />)}
              </div>
              {isNeedToDisplayExportButton && (
                <ExportButton isSharedDashboard={isSharedDashboard} dashboardId={dashboardId} />
              )}
            </div>
          </div>

          {!isSharedDashboard && !isMobile && <UserInfo />}
        </div>
      )}
    </>
  );
};
