import moment from 'moment';
import React from 'react';
import { DateFunctions } from 'src/enums/date';
import { initValueFormatter } from '../../widget-page/charts/hooks/initValueFormatter';
import {
  getWeekFilterValue,
  setPropForDate,
} from '../../widget-page/charts/formatting-helpers';
import { PanelType } from '../../../enums/widget-type';
import { FilterGroupProps } from '../hooks';
import { getSimplifiedType } from '../../widget-page/dropdown-layout/helpers/helpers';
import { WidgetSimplifiedDataType } from '../../../enums/data-type';
import { getFormattedDashboardFilterValue } from '../../../helpers/dashboard-page/dashboard-widgets';
import { getFilterGroupDisplayedName } from '../../../helpers/dashboard-page/filters-helper';

export const getFirstValue = (values: any) => {
  const filter = values.find(
    (val: any) => val.operation === '>=' || val.operation === '>',
  );
  return filter?.value;
};

export const getSecondValue = (values: any) => {
  const filter = values.find(
    (val: any) => val.operation === '<=' || val.operation === '<',
  );
  return filter?.value;
};

const getDisplayFormatByAggregation = (filterAggregation: string) => {
  switch (filterAggregation) {
    case DateFunctions.NO_TYPE:
    case DateFunctions.DAY:
    case DateFunctions.HOUR:
    case DateFunctions.MINUTE:
    case DateFunctions.WEEK:
      return 'dd.mm.yyyy';
    case DateFunctions.YEAR:
      return 'yyyy';
    case DateFunctions.MONTH:
      return 'mm.yyyy';
    case DateFunctions.QUARTER:
      return 'QQ.yyyy';
  }
};

const getValuesForPeriod = (values: any, filterAggregation: string) => {
  const valueFormat = initValueFormatter();

  const displayFormat = getDisplayFormatByAggregation(filterAggregation);

  const correctFirstValue =
    filterAggregation === DateFunctions.WEEK && getFirstValue(values)
      ? moment(getFirstValue(values)).startOf('week').toISOString()
      : getFirstValue(values);

  const correctSecondValue =
    filterAggregation === DateFunctions.WEEK && getSecondValue(values)
      ? moment(getSecondValue(values)).endOf('week').toISOString()
      : getSecondValue(values);

  const firstValue = correctFirstValue ? valueFormat(
    correctFirstValue,
    0,
    setPropForDate(filterAggregation, displayFormat),
    PanelType.axisY,
  ) : null;
  const secondValue = correctSecondValue ? valueFormat(
    correctSecondValue,
    0,
    setPropForDate(filterAggregation, displayFormat),
    PanelType.axisY,
  ) : null;

  const additionalString = filterAggregation === DateFunctions.YEAR || filterAggregation === DateFunctions.MONTH ? 'г.' : '';

  if (firstValue && secondValue) return `${firstValue} ${additionalString} - ${secondValue} ${additionalString}`;
  if (firstValue) return `с ${firstValue} ${additionalString}`;
  if (secondValue) return `по ${secondValue} ${additionalString}`;
};

export const getDatePeriod = (
  values: any,
  filterAggregation: string,
  isPeriod: boolean,
) => {
  const valueFormat = initValueFormatter();

  if (values?.length) {
    switch (filterAggregation) {
      case DateFunctions.NO_TYPE:
      case DateFunctions.DAY:
      case DateFunctions.YEAR:
      case DateFunctions.HOUR:
      case DateFunctions.MINUTE:
      case DateFunctions.QUARTER:
      case DateFunctions.MONTH:
        return isPeriod
          ? getValuesForPeriod(values, filterAggregation)
          : valueFormat(
            values[0].value,
            0,
            setPropForDate(
              filterAggregation,
              getDisplayFormatByAggregation(filterAggregation),
            ),
            PanelType.axisY,
          );
      case DateFunctions.WEEK:
        return isPeriod
          ? getValuesForPeriod(values, filterAggregation)
          : getWeekFilterValue(values[0].value);
    }
  }

  return '';
};

export const getTextValueForQuickFilter = (filter: FilterGroupProps) => {
  const count = filter.value.length;
  const isShowCountHint =
    getSimplifiedType(filter.type) === WidgetSimplifiedDataType.TEXT ||
    getSimplifiedType(filter.type) === WidgetSimplifiedDataType.BOOLEAN;

  return (
    <>
      <span className="quick-filters__filter-text--name">{getFilterGroupDisplayedName(filter)}: </span>
      <span className="quick-filters__filter-text--value">
        {getFormattedDashboardFilterValue(filter)}
      </span>
      {isShowCountHint && count > 1 ? (
        <span className="quick-filters__filter-text--counter">
          {' '}
          +{count - 1}
        </span>
      ) : null}
    </>
  );
};

export const getTextValueForQuickFilterTooltip = (filter: FilterGroupProps) => {
  return (
    <>
      {[WidgetSimplifiedDataType.TEXT, WidgetSimplifiedDataType.BOOLEAN].includes(getSimplifiedType(filter.type)) &&
      filter.value.length ? (
          filter.value.map((value, index) => (
            <div key={`${value.operation}${value.value}`}>
              {getFormattedDashboardFilterValue(filter, index)}
            </div>
          ))
        ) : (
          <span>{getFormattedDashboardFilterValue(filter)}</span>
        )}
    </>
  );
};
