import * as Yup from 'yup';
import { ObjectSchema, Shape } from 'yup';
import Connection from '../../../types/connection';
import { fieldName } from './data';

const CONNECTION_ODATA_ID = 5;
const CONNECTION_CLICKHOUSE = 34;
const CONNECTION_GOOGLESHEETS = 43;

interface AnyObject {
  [key: string]: any;
}

export const getInitialValues = (
  data: Connection.Param[],
  connectionData?: { [index: string]: any },
) =>
  data.reduce((acc, { type }) => {
    const validFieldName = fieldName[type];
    return {
      ...acc,
      [validFieldName]: connectionData
        ? connectionData[validFieldName] || ''
        : '',
    };
  }, {});

export const getValidationSchema = (
  data: Connection.Param[],
  connectionTypeId: number,
): ObjectSchema<Shape<AnyObject, AnyObject>> => {
  const res: Record<string, any> = data.reduce(
    (acc, item) => ({
      ...acc,
      [fieldName[item.type]]: Yup.string().required(
        'Заполните обязательное поле',
      ),
    }),
    {},
  );

  res[fieldName.CONNECTION_CONFIG] = Yup.string().notRequired();

  // for ODATA connection login and password are not required
  if ((connectionTypeId === CONNECTION_ODATA_ID) || (connectionTypeId === CONNECTION_CLICKHOUSE) || (connectionTypeId === CONNECTION_GOOGLESHEETS)) {
    res[fieldName.LOGIN] = Yup.string().notRequired();
    res[fieldName.PASSWORD] = Yup.string().notRequired();
  }
  
  return Yup.object().shape<any>(res);
};
