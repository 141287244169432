import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent } from 'react';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { useDispatch } from 'react-redux';
import { SearchBlockProps } from './search-block';
import MapConnectionTypes from '../../../../types/map-connection';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import { changeSourceFilterAction } from '../../../../slices/map-view/map-view';
import {
  getCorrectListForSourceSearch,
  getSourceIdsForSourceFilter,
} from '../../../../helpers/loader-page';

const useStyles = makeStyles({
  gridRoot: {
    marginLeft: 'auto',
    marginRight: 'calc(10px * var(--scale-coefficient))',
    minWidth: 'calc(300px * var(--scale-coefficient))',
  },
  search: {
    '& [class*=MuiInputBase-root]': {
      borderTop: 'none',
      borderBottom: 'none',
      borderRadius: '0 !important',
    }
  }
});

export const useSearchBlock = ({ loaderData }: SearchBlockProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const sourceList: MapConnectionTypes.SourceObject[] = loaderData?.sourceObjects || [];

  const { correctSourceList, resultSources } = getCorrectListForSourceSearch(sourceList);

  const handleChangeSourceObject = (event: ChangeEvent<{}>, sourceObject: MapConnectionTypes.SourceObject) => {
    if (!sourceObject) {
      dispatch(changeSourceFilterAction({ value: '', sourceIds: [] }));
      return;
    }

    const sourceIds = getSourceIdsForSourceFilter(sourceObject, resultSources);
    dispatch(changeSourceFilterAction({ value: sourceObject.name || sourceObject.initialName || '', sourceIds }));
  };

  const handleChangeFilter = (event: any) => {
    const sourceIds = getFilterOptions(event.target.value).map((source) => source.id);
    dispatch(changeSourceFilterAction({ value: event.target.value, sourceIds }));
  };

  const getFilterOptions = (value: string): MapConnectionTypes.SourceObject[] => {
    return correctSourceList.filter((object) =>
      object.name?.toLowerCase().includes(value.toLowerCase()) ||
      object.initialName?.toLowerCase().includes(value.toLowerCase()),
    );
  };

  const renderOption = (props: MapConnectionTypes.SourceObject) => (
    <CustomTooltip title={props?.name || props?.initialName || ''}>
      <div>
        {props?.name || props?.initialName}
      </div>
    </CustomTooltip>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextInput
      {...params}
      formikName={params.id}
      fullWidth
      placeholder='Поиск'
      handleChange={handleChangeFilter}
      searchIcon
    />
  );

  return {
    classes,
    correctSourceList,
    getFilterOptions,
    renderOption,
    renderInput,
    handleChangeSourceObject,
  };
};
