export enum SettingsNames {
  fieldName = 'fieldName',
  aggregation = 'aggregation',
  operation = 'operation',
  value = 'value',
  useFormula = 'useFormula',
  conditionColor = 'conditionColor',
  backgroundColor = 'backgroundColor',
  template = 'template',
  operands = 'operands',
  operandIds = 'operandIds',
  text = 'text',
  fieldEtlId = 'fieldEtlId',
}

export const Operations: any = {
  'DATE': [
    '>=',
    '<=',
    '<',
    '>',
    '=',
    '!=',
    'NOT IN',
    'IN',
    'BETWEEN',
  ],
  'NUMBER': [
    '>=',
    '<=',
    '>',
    '<',
    '=',
    '!=',
    'BETWEEN',
  ],
  'TEXT': [
    'NOT IN',
    'IN',
    'NOT LIKE',
    'LIKE',
  ],
  '': [],
  'BOOLEAN': [
    'IS TRUE',
    'IS FALSE'
  ]
};
