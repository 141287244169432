import React from 'react';
import { useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FormikErrors } from 'formik';
import { CSS } from '@dnd-kit/utilities';
import { TextInput } from '../../../../../common/special-inputs/inputs/text-input';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import {
  CustomIconButton,
  IconButtonType,
} from '../../../../../../uikit/IconButton';
import { HierarchyValues } from '../index';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface ViewSettingItemProps {
  handleChangeValue: (index: number, value: string) => void;
  level: string;
  index: number;
  handleClickDelete: (index: number) => void;
  errors: FormikErrors<HierarchyValues>;
}

export const ViewSettingItem = ({
  level,
  index,
  handleChangeValue,
  handleClickDelete,
  errors,
}: ViewSettingItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: level,
    strategy: verticalListSortingStrategy,
    transition: {
      duration: 300,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const style = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    zIndex: isDragging ? 999 : 0,
    transition,
  };

  return (
    <div
      className="view-settings-item"
      ref={setNodeRef}
      style={style as any}
      {...attributes}
    >
      <div className="view-settings-item__head">
        <div className="view-settings-item__title">Уровень №{index + 1}</div>
        <div className="view-settings-item__buttons">
          <CustomIconButton
            iconButtonProps={{
              children: <IconSvg fill="var(--dark-grey)" svg={IconDictionary.VerticalDragAndDrop} />,
              ...listeners,
            }}
            type={IconButtonType.withoutEffects}
          />
          <CustomIconButton
            iconButtonProps={{
              children: (
                <IconSvg
                  svg={IconDictionary.BasketDelete}
                  fill="var(--dark-grey)"
                />
              ),
              onClick: () => handleClickDelete(index),
            }}
            type={IconButtonType.delete}
          />
        </div>
      </div>
      <div className="view-settings-item__main">
        <TextInput
          error={Boolean(errors.hierarchyLevels) && !level.length}
          label="Название"
          value={level}
          handleChange={(event) => handleChangeValue(index, event.target.value)}
          fullWidth
        />
      </div>
    </div>
  );
};
