import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Point } from '@visx/point';
import { State } from '../../../../slices/types';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import {
  getBooleanPropertyValue, getInputPropertyValue,
  getJSONPropertyValue,
  sortColorPalette,
} from '../helpers';
import { generatePolygonPoints, getMaxData, RadarData } from './helpers';
import { useEventListener } from '../../../../hooks/useEventListener';
import { PanelType } from '../../../../enums/widget-type';
import './radar.css';
import { setPropForNumberValue } from '../formatting-helpers';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { useChartFilters } from './useChartFilters';
import { labelSettings, RadarAxis } from './Axis';
import { defaultColors } from '../common/color';
import { getTooltipHandler } from '../../../common/widget-tooltip/widget-tooltip';
import clsx from 'clsx';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';

interface RadarProps {
  widgetProps?: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
}

let currentColors: any = defaultColors;

const Radar: React.FC<RadarProps> = ({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}) => {
  const { selectFilter, deselectFilter, enableEvents } = useChartFilters({
    widgetProps,
    setFilterField,
    filterField,
    isActiveFilter,
  });

  const widgetStoreProperties = useSelector(
    (state: State) => state.widget.properties,
  );
  const widgetProperties = widgetProps?.properties || widgetStoreProperties;

  const widgetId = widgetProps.id;

  const widgetData: RadarData[] = widgetProps.data;
  const dataCount = widgetData.length;
  const valuesCount = widgetData[0].count.length;

  const isOddData = dataCount % 2 === 0;

  const isFullScreen = useWidgetFullScreen(widgetId);

  const roundingCountString: string = getInputPropertyValue(
    'roundingCount',
    widgetProperties,
  );
  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const valueFormat = initValueFormatter({ roundingCount });

  const isNeedToDisplayLegend: boolean = getBooleanPropertyValue(
    'displayLegend',
    widgetProperties,
  );

  const isNeedToDisplayAxisValues: boolean = getBooleanPropertyValue(
    'displayAxesGuideValues',
    widgetProperties,
  );

  const isNeedToDisplayTooltip: boolean = getBooleanPropertyValue(
    'displayTooltip',
    widgetProperties,
  );

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );
  currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || currentColors;

  const containerRef = useRef(document.createElement('div'));

  const [containerHeight, setHeight] = useState(containerRef.current.clientHeight);
  const [containerWidth, setWidth] = useState(containerRef.current.clientWidth);

  useLayoutEffect(() => {
    calculateDimensions();
  }, [
    widgetData,
    isFullScreen,
    containerRef.current.clientHeight,
    containerRef.current.clientWidth,
  ]);

  const calculateDimensions = () => {
    setWidth(containerRef.current.clientWidth);
    setHeight(containerRef.current.clientHeight);
  };

  useEffect(() => {
    calculateDimensions();
  }, [widgetData, calculateDimensions, isFullScreen]);

  useEventListener('resize', calculateDimensions);

  const labelContainerHeight = 2 * labelSettings.lineHeight;
  const radarOffset = {
    top: 0,
    left: 15,
    right: 15,
    bottom: 20 + 2 * labelContainerHeight,
  };

  const legendOffset = 200;

  const labelContainerWidth = 200;

  const axisValueContainerHeight = 50;

  const maxRadarWidth = containerWidth - radarOffset.left - radarOffset.right;
  const maxRadarHeight = containerHeight - radarOffset.top - radarOffset.bottom;

  const minRadius = 70;
  const maxRadius =
    Math.min(maxRadarHeight, maxRadarWidth - 2 * labelContainerWidth) / 2;
  const radius = Math.max(maxRadius, minRadius);
  const axisOffset = maxRadarHeight / 2 - radius;

  const endDomain = getMaxData(widgetData);
  const yScale = scaleLinear<any>({
    range: [0, radius],
    domain: [0, endDomain],
  });

  const setPolygonsPoints = () => {
    const polygonsPoints: any[] = [];
    for (let i = 0; i < valuesCount; i++) {
      polygonsPoints.push(
        generatePolygonPoints(widgetData, (d) => Math.abs(yScale(d)) ?? 0, i, isOddData),
      );
    }
    return polygonsPoints;
  };

  const polygonsPoints = setPolygonsPoints();

  const levels = 5;
  const axisValues = [...new Array(levels + 1)].map(
    (_, index) => Math.ceil(endDomain / levels) * index,
  );

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(containerRef);

  return (
    <div
      onClick={deselectFilter}
      style={{
        flexGrow: 1,
        width: '100%',
        minHeight: `${
          2 * minRadius +
          2 * labelContainerHeight +
          (isNeedToDisplayLegend ? legendOffset : 0)
        }px`,
        minWidth: `${
          2 * minRadius + 2 * labelContainerWidth + 2 * labelContainerHeight
        }px`,
      }}
      ref={containerRef}
    >
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        justifyContent="center"
        flexGrow={1}
      >
        <svg width={containerWidth} height={containerHeight}>
          <RadarAxis
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            radarOffset={radarOffset}
            levels={levels}
            widgetData={widgetData}
            widgetProperties={widgetProperties}
            radius={radius}
            labelContainerWidth={labelContainerWidth}
            labelContainerHeight={labelContainerHeight}
          />
          {polygonsPoints.map((layer, index) => (
            <Group
              top={containerHeight / 2 - radarOffset.top}
              left={containerWidth / 2}
              key={`radar-polygon-${index}`}
            >
              <polygon
                points={layer.polygonPoints}
                fill={currentColors[index]}
                fillOpacity={0.1}
                stroke={currentColors[index]}
                strokeWidth={1}
              />
            </Group>
          ))}
          {polygonsPoints.map((layer, index) => (
            <Group
              top={containerHeight / 2 - radarOffset.top}
              left={containerWidth / 2}
              key={`radar-point-${index}`}
            >
              {layer.points.map((point: Point, i: number) => (
                <circle
                  key={`widget-${i}-${widgetId}`}
                  className={clsx({ 'active-drilldown-element': isActiveFilter })}
                  cx={point.x}
                  cy={point.y}
                  r={4}
                  fill={currentColors[index]}
                  onClick={(e) => {
                    if (!enableEvents) return;
                    e.stopPropagation();
                    selectFilter(widgetData[i].label);
                  }}
                  opacity={
                    filterField?.value === null ||
                    filterField?.value === undefined ||
                    filterField?.value?.includes(widgetData[i].label)
                      ? 0.8
                      : 0.2
                  }
                  onMouseLeave={
                    isNeedToDisplayTooltip
                      ? handleWidgetMouseLeave
                      : undefined
                  }
                  onMouseMove={
                    isNeedToDisplayTooltip
                      ? (event) =>
                        handleWidgetMouseMove(event, {
                          attrX: widgetData[i].label,
                          valueX: valueFormat(
                            widgetData[i].count[index],
                            0,
                            setPropForNumberValue(widgetProperties),
                            PanelType.axisY,
                          ),
                        })
                      : undefined
                  }
                />
              ))}
            </Group>
          ))}
          {isNeedToDisplayAxisValues && (
            <Group top={axisOffset} style={{ pointerEvents: 'none' }}>
              {axisValues.map(
                (value, i) =>
                  i > 0 && (
                    <foreignObject
                      x={0}
                      y={
                        yScale(axisValues[axisValues.length - 1]) -
                        yScale(value) +
                        labelContainerHeight
                      }
                      width={containerWidth}
                      height={axisValueContainerHeight}
                    >
                      <div
                        style={{
                          position: 'fixed',
                          zIndex: 1,
                          width: '100%',
                        }}
                      >
                        <div className="radar-axis__block">
                          <span className="radar-axis__value">
                            {valueFormat(
                              value,
                              0,
                              setPropForNumberValue(widgetProperties),
                              PanelType.axisY,
                            )}
                          </span>
                        </div>
                      </div>
                    </foreignObject>
                  ),
              )}
            </Group>
          )}
        </svg>
      </Box>
    </div>
  );
};

export default Radar;
