import React, { useCallback } from 'react';
import moment from 'moment';
import { Placement } from '../../../../../enums/visual-types';
import { FilterContainer } from '../../../../common/filter-container/filter-container';
import { useFilter } from '../../hooks/useFilter';
import { FilterValue } from '../../types';
import { DateOptions } from '../../../../../slices/types';
import { FilterTabsContainer } from './filter-tabs-container';
import { RangeBlock } from './range-block';
import { FieldsBlock } from './fields-block';
import { DateFunctions } from '../../../../../enums/date';

export interface DateFilterOptions {
  displayOptions: {
    [key: string]: {
      value: boolean;
      displayedName?: string;
    };
  };
  displayAvailablePeriod: boolean;
  quickOptions?: { [key in DateOptions]: boolean } | null;
  overriddenFunction?: string | null;
  dateFunction?: string;
  minValue: string | null;
  maxValue: string | null;
  selectDateType?: string;
}

interface DateFilterProps {
  onChangeFilter: (filter: any) => void;
  currentFilter: FilterValue[];
  label?: React.ReactNode | string;
  filterType: string;
  closeFilterPanel?: () => void;
  filterOptions: DateFilterOptions;
  placement?: Placement;
  isMandatoryFilter?: boolean;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  onChangeFilter,
  currentFilter,
  label,
  closeFilterPanel,
  filterType,
  filterOptions,
  placement,
  isMandatoryFilter= false,
}) => {
  const rangeDateValues = {
    minValue: filterOptions.minValue,
    maxValue: filterOptions.maxValue,
    displayAvailablePeriod: filterOptions.displayAvailablePeriod
  };

  const dateWithTimes =
    filterType === DateFunctions.NO_TYPE ||
    filterType === DateFunctions.HOUR ||
    filterType === DateFunctions.MINUTE;

  const {
    fromValue,
    toValue,
    handleChangeFrom,
    handleChangeTo,
    handleApply,
    dateValues,
    currentDateOption,
    setCurrentDateOption,
    isDisabledApplyFilterButton,
    selectDateType,
    handleChangeSelectDateType,
    isNeedToDisplayPeriod: isPeriod
  } = useFilter<Date | null>({
    onChangeFilter,
    currentFilter,
    toOperation: '<=',
    closeFilterPanel,
    filterOptions,
    isMandatory: isMandatoryFilter,
  });

  const changeDateFromWithTime = (date: any) => {
    const changeDateTime = 1000;
    setTimeout(() => handleChangeDateTo(date), changeDateTime);
  };

  const handleChangeDateFrom = useCallback((date: any) => {
    if (currentDateOption !== null) {
      setCurrentDateOption(null);
    }
    setCurrentDateOption(null);
    const formatted = date?.isValid()
      ? moment(date).format('YYYY-MM-DD 00:00:00.0').toString()
      : null;
    handleChangeFrom(formatted);
    if (dateWithTimes && !isPeriod) {
      changeDateFromWithTime(date);
    }
  }, [currentDateOption]);

  const handleOnInput = useCallback(() => {
    if (currentDateOption !== null) {
      setCurrentDateOption(null);
    }
  }, [currentDateOption]);

  const handleChangeDateTo = useCallback((date: any) => {
    const formatted = date?.isValid()
      ? moment(date)
        .format(
          !dateWithTimes ? 'YYYY-MM-DD 00:00:00.0' : 'YYYY-MM-DD 23:59:59.0',
        )
        .toString()
      : null;

    handleChangeTo(formatted);
  }, [dateWithTimes, handleChangeTo]);

  const handleClickTab = useCallback((startDate: string, endDate: moment.Moment, option: DateOptions) => {
    handleChangeFrom(startDate);
    isPeriod && handleChangeDateTo(endDate);
    setCurrentDateOption(option);
  }, [handleChangeDateTo, handleChangeFrom, setCurrentDateOption, isPeriod]);

  return (
    <FilterContainer
      label={label}
      isDisabledApplyButton={isDisabledApplyFilterButton}
      handleApply={handleApply}
    >
      <FilterTabsContainer
        currentDateOption={currentDateOption}
        dateValues={dateValues}
        options={filterOptions.quickOptions}
        handleClickTab={handleClickTab}
      />

      <FieldsBlock
        filterOptions={filterOptions}
        handleChangeDateFrom={handleChangeDateFrom}
        handleChangeDateTo={handleChangeDateTo}
        dateValues={dateValues}
        handleOnInput={handleOnInput}
        currentDateOption={currentDateOption}
        values={{ fromValue, toValue }}
        filterType={filterType}
        selectDateType={selectDateType}
        handleChangeSelectDateType={handleChangeSelectDateType}
        placement={placement}
      />

      <RangeBlock filterType={filterType} rangeDateValues={rangeDateValues} />
    </FilterContainer>
  );
};
