import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './rollback-table.css';
import { setLoadingAction } from 'src/slices/main-page/main-page-slice';
import {
  apiGetProjectVersions,
  apiRollbackProjectVersion,
} from 'src/services/projectVersionController';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ConfirmDialog } from 'src/uikit/ConfirmDialog';
import { getAllGroups } from 'src/services';
import { setGroupsAction } from 'src/slices/group-slice/group-slice';
import { State } from '../../../slices/types';
import { TextInput } from '../special-inputs/inputs/text-input';
import { useNotificator } from '../snackbar/hooks';
import { CustomButton } from '../../../uikit/Button';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../uikit/Progress';
import { CustomModalForm } from '../../../uikit/ModalForm';

export interface FormValues {
  description: string;
  projectId: number;
}

interface RollbackVersionFormProps {
  handleCloseForm: () => void;
  projectId: number;
  projectName?: string;
}

interface VersionsList {
  description: string;
  name: string;
  userName: string;
  versionId: number;
  createdAt: string;
}

const RollbackVersionForm: React.FC<RollbackVersionFormProps> = ({
  handleCloseForm,
  projectId,
  projectName,
}) => {
  const dispatch = useDispatch();

  const filters = useSelector((state: State) => state.mainPage.filters);

  const [versionsList, setVersionsList] = useState<VersionsList[] | null>(null);
  const [activeVersionId, setActiveVersionId] = useState<number | null>(null);
  const [isOpenConfirmModal, setModalConfirmModal] = useState<boolean>(false);

  const isLoading = useSelector((state: State) => state.mainPage.isLoading);

  const { showNotification } = useNotificator();

  const handleCloseConfirmModal = () => {
    setModalConfirmModal(false);
  };

  useEffect(() => {
    if (projectId) {
      apiGetProjectVersions({ projectId }).then((data) => {
        setVersionsList(data);
      });
    }
  }, [projectId]);

  const onRollbackContinue = () => {
    handleCloseConfirmModal();
    dispatch(setLoadingAction(true));

    apiRollbackProjectVersion({ versionId: activeVersionId!, projectId })
      .then(() => {
        showNotification({
          message: 'Версия успешно восстановлена',
          variant: 'success',
        });
        getAllGroups(filters)
          .then((response) => {
            dispatch(setGroupsAction(response?.data));
            dispatch(setLoadingAction(false));
          })
          .catch((err) => {
            dispatch(setLoadingAction(false));
          });
        handleCloseForm();
      })
      .catch((err) => {
        const defaultErrorMessage = `Ошибка при восстановлении версии ${CommonDictionary.projectGenitive}`;
        showNotification({
          message: err?.response?.data?.message || defaultErrorMessage,
          variant: 'error',
        });
        dispatch(setLoadingAction(false));
      });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setModalConfirmModal(true);
  };

  const renderList = (versionsList: VersionsList[]) => {
    return (
      <div className="rollback-table">
        <Table className="rollback-table__table">
          <TableHead className="rollback-table__head">
            <TableRow>
              <TableCell className="rollback-table__cell rollback-table__cell_first rollback-table__cell_head">
                Версия
              </TableCell>
              <TableCell className="rollback-table__cell rollback-table__cell_head">
                Дата создания
              </TableCell>
              <TableCell className="rollback-table__cell rollback-table__cell_head">
                Комментарий
              </TableCell>
              <TableCell className="rollback-table__cell rollback-table__cell_head">
                Пользователь
              </TableCell>
              <TableCell className="rollback-table__cell rollback-table__cell_head">
                Имя
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versionsList.map((version) => (
              <TableRow
                className={`rollback-table__row ${
                  activeVersionId === version.versionId &&
                  'rollback-table__row_active'
                }`}
                key={`row-${version}`}
                onClick={() => {
                  setActiveVersionId(version.versionId);
                }}
              >
                <TableCell className="rollback-table__cell rollback-table__cell_first">
                  <div>{version.versionId}</div>
                </TableCell>
                <TableCell className="rollback-table__cell">
                  <div>{version.createdAt}</div>
                </TableCell>
                <TableCell className="rollback-table__cell">
                  <div>{version.description}</div>
                </TableCell>
                <TableCell className="rollback-table__cell">
                  <div>{version.userName}</div>
                </TableCell>
                <TableCell className="rollback-table__cell">
                  <div>{version.name}</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ConfirmDialog
          isOpen={isOpenConfirmModal}
          onClose={handleCloseConfirmModal}
          onConfirm={onRollbackContinue}
          title="Восстановлении версии"
          message="Вы уверены, что хотите восстановить версию? При восстановлении все несохраненные версии будут потеряны."
          maxWidth="xs"
        />
      </div>
    );
  };

  return (
    <>
      <CustomModalForm onSubmit={onSubmit} autoComplete="off">
        <TextInput
          disabled
          formikName="name"
          label={`Название ${CommonDictionary.projectGenitive}`}
          value={projectName}
          fullWidth
        />

        {versionsList && versionsList.length > 0 ? (
          renderList(versionsList)
        ) : (
          <div className="rollback-info">Нет сохраненных версий</div>
        )}

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton
            disabled={isLoading || !activeVersionId}
            variant="contained"
            type="submit"
            startIcon={
              isLoading ? (
                <CustomProgress
                  type="circular"
                  style={{ color: 'var(--white)' }}
                  size={20}
                />
              ) : null
            }
          >
            Восстановить
          </CustomButton>
          <CustomButton
            disabled={isLoading}
            variant="outlined"
            onClick={handleCloseForm}
          >
            Отменить
          </CustomButton>
        </div>
      </CustomModalForm>
    </>
  );
};

export default RollbackVersionForm;