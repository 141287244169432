import React from 'react';
import { useFormik } from 'formik';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';
import './column-rename-options.css';
import { CustomButton } from '../../../../../uikit/Button';
import { PanelType, WidgetType } from '../../../../../enums/widget-type';

interface RenameValues {
  name: string;
  newName: string;
}

interface ColumnRenameOptionsProps {
  panelData: PropertyData;
  closeDialog: () => void;
  changeRenameOptions: (name: any) => void;
  storage: string;
  widgetType: WidgetType;
}

export const ColumnRenameOptions = ({
  panelData,
  closeDialog,
  changeRenameOptions,
  storage,
  widgetType,
}: ColumnRenameOptionsProps) => {
  const labelName =
  widgetType === WidgetType.TABLE ||
  (widgetType === WidgetType.PIVOT_TABLE &&
    panelData.axisName === PanelType.axisY)
    ? 'Новое название поля. Перенос строки - \\n'
    : 'Новое название поля';

  const alias = JSON.parse(storage).alias || '';

  const handleSave = (values: RenameValues) => {
    closeDialog();

    const newStorage = { ...JSON.parse(storage), alias: values.newName };
    changeRenameOptions(JSON.stringify(newStorage));
  };

  const handleClose = () => {
    closeDialog();
  };

  const { values, handleChange, handleSubmit } = useFormik<RenameValues>({
    initialValues: {
      name: panelData.name,
      newName: alias,
    },
    onSubmit: handleSave,
  });

  return (
    <CustomDialog
      isOpen
      onClose={closeDialog}
      title="Переименование поля"
      maxWidth="xs"
      fullWidth={false}
    >
      <form className="column-rename-options__main" onSubmit={handleSubmit}>
        <TextInput
          formikName="name"
          value={values.name}
          label="Название поля"
          disabled
          fullWidth
        />

        <TextInput
          formikName="newName"
          value={values.newName}
          label={labelName}
          handleChange={handleChange}
          fullWidth
        />

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton variant="contained" type="submit">
            <span>Сохранить</span>
          </CustomButton>
          <CustomButton variant="outlined" type="reset" onClick={handleClose}>
            Отменить
          </CustomButton>
        </div>
      </form>
    </CustomDialog>
  );
};
