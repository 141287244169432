import React from 'react';
import { HeatMapMainSetting, MainSettings } from './main-settings';
import { FieldsBlock } from './fields-block';
import { GradientSetting, GradientSettings, MapGradient } from './gradient-settings';
import { PropertyData } from '../../../../dropdown-layout/helpers/Property';

interface MainBlockProps {
  mainSettingsList: HeatMapMainSetting[];
  changeCurrentWidgetField: (field: PropertyData) => void;
  etlSourceId: string;
  currentWidgetField: PropertyData | null;
  gradientSettings: GradientSetting[];
  gradient: MapGradient;
  changeAggregationCallback: (aggregation: any) => void;
}

export const MainBlock = ({
  mainSettingsList,
  changeCurrentWidgetField,
  etlSourceId,
  currentWidgetField,
  gradientSettings,
  gradient,
  changeAggregationCallback,
}: MainBlockProps) => {
  return (
    <div className="heat-map-settings__content">
      <MainSettings settings={mainSettingsList} />
      <FieldsBlock
        etlSourceId={etlSourceId}
        currentWidgetField={currentWidgetField}
        changeCurrentWidgetField={changeCurrentWidgetField}
        changeAggregationCallback={changeAggregationCallback}
      />
      <GradientSettings settings={gradientSettings} gradient={gradient} />
    </div>
  );
};
