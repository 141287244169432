import { AxiosResponse } from 'axios';
import { apiBI, apiETL } from '../utils/api';
import MapConnection from '../types/map-connection';

export const LOADERS_URL = '/loaders';

export const apiCreateNewLoader = async (parameters: {
  name: string;
  loaderGroupId: number;
}) => {
  try {
    const response = await apiETL.post(LOADERS_URL, parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Запуск загрузчика */
export const apiRunLoader = async (loaderId: number) => {
  try {
    await apiETL.post(`${LOADERS_URL}/bi/${loaderId}/run`);
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};


/* Изменение загрузчика */
export const apiFullChangeLoader = async (loaderId: number) => {
  try {
    const response = await apiETL.put(`${LOADERS_URL}/${loaderId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Удаление загрузчика */
export const apiDeleteLoader = async (loaderId: number) => {
  try {
    const response = await apiETL.delete(`${LOADERS_URL}/${loaderId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Получение истории загрузчика */
export const apiHistoryLoader = async (loaderId: number) => {
  try {
    const response = await apiETL.get(`${LOADERS_URL}/${loaderId}/launches`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Получение карты */
export const apiGetMapConnection = async (
  loaderId: number,
): Promise<MapConnection.Data | null> => {
  try {
    const params = {
      view: 'FULL',
    };
    const { data } = await apiETL.get<MapConnection.Data>(
      `${LOADERS_URL}/${loaderId}`,
      { params },
    );
    return data;
  } catch (error: any) {
    console.error(error);
  }
  return null;
};

/* Сохранение карты */
export const apiSaveMapConnection = async (
  loaderId: number,
  mapConnection: MapConnection.Data,
): Promise<MapConnection.Data> => {
  try {
    const { data } = await apiETL.put<MapConnection.Data>(
      `${LOADERS_URL}/${loaderId}`,
      mapConnection,
    );
    return data;
  } catch (error: any) {
    console.error('error', error);
    const responseError = {
      error: error?.response?.data || {
        message: 'Не удалось сохранить данные',
      }
    };
    throw responseError.error;
  }
};

/* Копирование загрузчика */
export const apiCopyLoader = async (
  loaderId: number,
  parameters: { name: string; loaderGroupId: number },
) => {
  try {
    const response = await apiETL.post(
      `${LOADERS_URL}/${loaderId}/copy`,
      parameters,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Редактирование базовой инфы загрузчика */
export const apiBasicChangeLoader = async (
  loaderId: number,
  parameters: { name: string; loaderGroupId: number },
) => {
  try {
    const response = await apiETL.put(
      `${LOADERS_URL}/${loaderId}/basic`,
      parameters,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiChangeSubscribe = async (parameters: {
  loaderId: number;
  type: string;
}) => {
  try {
    const response = await apiETL.put(`${LOADERS_URL}/subscribe`, parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCheckSubscription = async (loaderId: number) => {
  try {
    const response = await apiETL.get(
      `${LOADERS_URL}/subscription?loaderId=${loaderId}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Создание source object */
export const apiValidate = async (
  body: MapConnection.SourceObject,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await apiETL.put(
      '/source-objects/validateTransformations/',
      body,
    );
    return response;
  } catch (error: any) {
    console.error('Request failed:', error.response?.message);
    throw error;
  }
};
export const apiCheckSourceObjectAliases = async (
  sourceObjectId: string,
  body: string[],
): Promise<AxiosResponse<any>> => {
  try {
    const response = await apiBI.post(
      `/fields/calculated/${sourceObjectId}/aliases`,
      body,
    );
    return response;
  } catch (error: any) {
    console.error('Request failed:', error.response?.message);
    throw error;
  }
};

export const apiUpdatePositions = async (
  id: number,
  params: {isSourceObject: boolean, isModelView: boolean, position: { x: number, y: number}}
) => {
  try {
    const { data } = await apiETL.put(`${LOADERS_URL}/${id}/update-position`, params);
    return data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetSingleSources = async (loaderId: number, sourceObjectId: string): Promise<any[]> => {
  try {
    const response = await apiETL.get(`bi${LOADERS_URL}/${loaderId}/single-objects/`, { params: { sourceObjectId } });

    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiValidateComputedField = async (
  body: MapConnection.ComputedField,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await apiETL.put(
      '/source-objects/validate-computed-field/',
      body,
    );
    return response;
  } catch (error: any) {
    console.error('Request failed:', error.response?.message);
    throw error;
  }
};

export const apiValidateWhereCondition = async (
  body: MapConnection.WhereCondition,
  sourceObjectId: string
) => {
  try {
    const response = await apiETL.put(
      `/source-objects/${sourceObjectId}/validate-where-condition/`,
      body,
    );
    return response;
  } catch (error: any) {
    console.error('Request failed:', error.response?.message);
    throw error;
  }
};
