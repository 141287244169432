import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { getScaleCoefficient } from '../../../../../../helpers/ui-helper';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import CustomTooltip from '../../../../../../uikit/CustomTooltip';
import { minColumnWidth, minNotAdaptiveColumnWidth } from '../../settings';
import { HeadCellProps } from '../../interfaces';
import { isNeedToAdaptiveColumns, isTotal } from '../../helper';
import { useHeadCell } from './useHeadCell';
import {
  CustomIconButton,
  IconButtonType,
} from '../../../../../../uikit/IconButton';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

export const HeadCell = ({
  cell,
  widgetProperties,
  style,
  changeDisplayNestedColumn,
  isOpenColumn,
  setDashboardWidgetState,
}: HeadCellProps) => {
  const {
    headBackgroundColor,
    headTextColor,
    classNames,
    isHeadCellWithNestedColumns,
    isNeedToHiddenContent,
    isNeedToDisplayTooltip,
    isCanSort,
    sortIcon,
    sortIconFill,
    handleSortClick,
    memoizedRenamedValue,
    headerSize,
    scaleWidget,
    columnWidthType,
  } = useHeadCell({
    cell,
    widgetProperties,
    style,
    changeDisplayNestedColumn,
    isOpenColumn,
    setDashboardWidgetState,
  });

  return (
    <div
      style={{
        ...style,
        minWidth: isNeedToAdaptiveColumns(scaleWidget, columnWidthType)
          ? minColumnWidth
          : minNotAdaptiveColumnWidth,
        background: headBackgroundColor,
        color: headTextColor,
        fontSize: headerSize * getScaleCoefficient(),
      }}
      className={classNames}
    >
      {isCanSort && (
        <CustomIconButton
          iconButtonProps={{
            children: (
              <IconSvg
                svg={IconDictionary[sortIcon]}
                fill={headTextColor || sortIconFill}
                classSVG="pivot-header__cell-sorting-icon"
              />
            ),
            onClick: handleSortClick,
          }}
          type={IconButtonType.secondary}
        />
      )}
      {isHeadCellWithNestedColumns && (
        <Box
          mr="5px"
          className={clsx('pivot-cell__button', {
            'pivot-head-cell__open-icon': !isOpenColumn,
            'pivot-head-cell__close-icon': isOpenColumn,
          })}
          onClick={() => changeDisplayNestedColumn(cell.id)}
        >
          <IconSvg
            svg={
              isOpenColumn
                ? IconDictionary.MinusSquare
                : IconDictionary.PlusSquare
            }
            fill={headTextColor || sortIconFill}
          />
        </Box>
      )}
      {!isNeedToHiddenContent && (
        <CustomTooltip
          arrow
          disableHoverListener={!isNeedToDisplayTooltip}
          title={
            <div>
              {memoizedRenamedValue.map((el, i) => (
                <div key={i}>{el}</div>
              ))}
            </div>
          }
          placement="top"
        >
          <div className="pivot-header__cell-content">
            {memoizedRenamedValue.map((el, i) => (
              <div key={i}>{el}</div>
            ))}
          </div>
        </CustomTooltip>
      )}
    </div>
  );
};
