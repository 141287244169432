import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { LinearProgress, List } from '@material-ui/core';

import { getConnectionParamsById } from '../../../../services/connection';
import { apiCheckSource, apiCreateSource } from '../../../../services/sources';
import { apiSetSourceParams } from '../../../../services/source-parameters';
import { useValuesBySourceId } from '../../../../hooks/useValuesBySourceId';
import { mapConnectionAction } from '../../../../slices/map-connection/map-connection';

import TablePreview from '../../table-preview/table-preview';

import SourceItemOnForm from '../common/source-item/source-item-on-form';
import SoapForm from './soap-form';
import OdataForm from './odata-form';
import { FileTypesEnum } from '../../../../enums/fyle-type';
import { CustomProgress } from '../../../../uikit/Progress';

const PARAMS_NAME = {
  NAME: 'NAME',
  HTTP_METHOD: 'HTTP_METHOD',
  CONTENT_TYPE: 'CONTENT_TYPE',
  REQUEST_BODY: 'REQUEST_BODY',
  PARAMS_MAP: 'PARAMS_MAP',
  ROW_TAG: 'ROW_TAG',
};

const filterParametersOdata = (parameters: { name: string; value: any }[]) => {
  const method = parameters.find(
    (param) => param.name === PARAMS_NAME.HTTP_METHOD,
  )?.value;
  const contentType = parameters.find(
    (param) => param.name === PARAMS_NAME.CONTENT_TYPE,
  )?.value;

  if (method === 'GET') {
    return parameters.filter(
      (param) =>
        param.name !== PARAMS_NAME.NAME &&
        param.name !== PARAMS_NAME.CONTENT_TYPE &&
        param.name !== PARAMS_NAME.REQUEST_BODY &&
        param.name !== PARAMS_NAME.ROW_TAG,
    );
  }

  if (
    method === 'POST' &&
    contentType === 'application/x-www-form-urlencoded'
  ) {
    return parameters.filter(
      (param) =>
        param.name !== PARAMS_NAME.NAME &&
        param.name !== PARAMS_NAME.REQUEST_BODY &&
        param.name !== PARAMS_NAME.ROW_TAG,
    );
  }

  return parameters.filter(
    (param) =>
      param.name !== PARAMS_NAME.NAME &&
      param.name !== PARAMS_NAME.ROW_TAG,
  );
};

interface Props {
  loaderId: number;
  connectionId: number;
  connectionTypeId: number;
  connectionType: string | null;
  onCancel: () => void;
  closeSourcePanel: () => void;
  getConnectionFiles: () => void;
  isEdit: boolean;
  itemSourceId?: number | null;
  itemChangeName: string | null;
}

const CreateForm: React.FC<Props> = ({
  loaderId,
  connectionId,
  connectionType,
  connectionTypeId,
  onCancel,
  closeSourcePanel,
  isEdit,
  itemSourceId,
  itemChangeName,
  getConnectionFiles,
}) => {
  const dispatch = useDispatch();

  const [errorSubmit, setErrorSubmit] = useState<string | null>(null);
  const [items, setItems] = useState<string[] | null>(null);
  const [previewFile, setPreviewFile] = useState<string | null>(null);
  const [formParameters, setFormParameters] = useState<
    {
      name: string;
      value: any;
    }[]
  >();

  const fetchParams = (_key: string, typeId: number, forSource: boolean) => {
    const response = getConnectionParamsById(typeId, forSource);
    return response;
  };

  const fieldsData = useQuery(['fields', connectionTypeId, true], fetchParams);
  const { data, isLoading, error } = fieldsData;
  const { data: valuesData, setId: setSourceId } = useValuesBySourceId(
    itemSourceId,
  );

  const initialValues = data?.reduce((acc: any, item: any) => {
    return {
      ...acc,
      ...{
        [item.type]:
          valuesData.find((valuesObj: any) => valuesObj.name === item.type)
            ?.value || '',
      },
    };
  }, {});

  const isDisabledCreateSourceButton = Boolean(!items?.length);

  const handleFormSubmit = async (values: Record<string, any>) => {
    setErrorSubmit(null);
    setItems(null);
    setPreviewFile(null);

    const name = values.NAME;
    const responseOutput = values.RESPONSE_OUTPUT;
    const rowTag = values.ROW_TAG;

    const parameters = Object.entries(values).map(([key, value]) => ({
      name: key,
      value,
    }));
    const filteredParameters = filterParametersOdata(parameters);
    setFormParameters(filteredParameters);

    filteredParameters.push({ name:'REQUEST_NAME', value: name });

    if (responseOutput === 'application/xml') {
      filteredParameters.push({ name:'ROW_TAG', value: rowTag });
    }

    const body = {
      connectionId,
      parameters: filteredParameters,
    };
    try {
      await apiCheckSource(body);
      setItems([name]);
    } catch (err: any) {
      console.error(err?.response);
      setErrorSubmit(err?.response?.data?.message);
    }
  };

  const onSuccessCreateFile = (name: string, sourceId: number) => {
    const tableNames: string[] = [name];
    const sourceIdList: number[] = [sourceId];

    dispatch(
      mapConnectionAction.addSourceObjectsSoapOdata({
        loaderId,
        connectionId,
        tableNames,
        sourceIdList,
        fileType: connectionType as FileTypesEnum,
        callback: closeSourcePanel,
        errorCallback: (e) => setErrorSubmit(e?.response?.data?.message),
      }),
    );
  };

  const changeSource = async (values: Record<string, any>) => {
    const idMapParamValues: Record<string, number> = valuesData.reduce(
      (acc, item) => {
        return { ...acc, ...{ [item.name]: item.id } };
      },
      {},
    );
    const params: {
      name: string;
      value: string | boolean;
      id?: number;
    }[] = Object.entries(values).map(([key, value]) => ({
      name: key,
      value,
      id: idMapParamValues[key],
    }));
    const filteredParameters = filterParametersOdata(params);

    const name = values.NAME;
    const responseOutput = values.RESPONSE_OUTPUT;
    const rowTag = values.ROW_TAG;

    filteredParameters.push({ name:'REQUEST_NAME', value: name });

    if (responseOutput === 'application/xml') {
      filteredParameters.push({ name:'ROW_TAG', value: rowTag });
    }

    try {
      await apiSetSourceParams(itemSourceId!, filteredParameters, values.NAME);
      getConnectionFiles();
      onCancel();
    } catch (err: any) {
      setErrorSubmit(err.data.message);
    }
  };

  const createSource = async (values: Record<string, any>) => {
    const name = values.NAME;
    const responseOutput = values.RESPONSE_OUTPUT;
    const rowTag = values.ROW_TAG;

    const parameters = Object.entries(values).map(([key, value]) => ({
      name: key,
      value,
    }));

    const filteredParameters = filterParametersOdata(parameters);
    filteredParameters.push({name:'REQUEST_NAME', value: name});

    if (responseOutput === 'application/xml') {
      filteredParameters.push({ name:'ROW_TAG', value: rowTag });
    }

    const body = {
      connectionId,
      name,
      parameters: filteredParameters,
    };

    try {
      const sourceId = await apiCreateSource(body);
      onSuccessCreateFile(name, sourceId);
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    setItems(null);
    setErrorSubmit(null);
    setPreviewFile(null);
    setSourceId(itemSourceId || 0);
  }, [setSourceId, itemSourceId, isEdit]);

  return (
    <>
      {isLoading && <CustomProgress type="linear" />}

      {(error as any) && <p>{(error as any)?.message}</p>}

      {data && initialValues && connectionType === 'SOAP' && (
        <SoapForm
          initialValues={initialValues}
          handleFormSubmit={handleFormSubmit}
          errorSubmit={errorSubmit}
          onCancel={onCancel}
          fieldsData={data}
          createSource={createSource}
          changeSource={changeSource}
          isDisabledCreateButton={isDisabledCreateSourceButton}
          isEditMode={isEdit}
          itemChangeName={itemChangeName}
        >
          {items && (
            <List dense>
              {items.map((item) => (
                <SourceItemOnForm
                  key={item}
                  file={item}
                  previewFile={previewFile}
                  setPreviewFile={setPreviewFile}
                />
              ))}
            </List>
          )}
        </SoapForm>
      )}

      {data && initialValues && connectionType === 'ODATA' && (
        <OdataForm
          initialValues={initialValues}
          handleFormSubmit={handleFormSubmit}
          errorSubmit={errorSubmit}
          onCancel={onCancel}
          fieldsData={data}
          createSource={createSource}
          changeSource={changeSource}
          isDisabledCreateButton={isDisabledCreateSourceButton}
          isEditMode={isEdit}
          itemChangeName={itemChangeName}
        >
          {items && (
            <List dense>
              {items.map((item) => (
                <SourceItemOnForm
                  key={item}
                  file={item}
                  previewFile={previewFile}
                  setPreviewFile={setPreviewFile}
                />
              ))}
            </List>
          )}
        </OdataForm>
      )}

      {previewFile && (
        <TablePreview
          className="right-panel__table-preview"
          name={previewFile}
          connectionId={connectionId}
          onClose={() => setPreviewFile(null)}
          formParams={formParameters}
          isNew
        />
      )}
    </>
  );
};

export default CreateForm;
