import React, { useCallback, useContext, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PanelType, WidgetType } from '../../../enums/widget-type';
import { WidgetsTypesContext } from '../../../pages/widget-page/widget-page';
import {
  setCurrentProjectWidgetDataAction,
  setCurrentProjectWidgetErrorAction
} from '../../../slices/main-page/main-page-slice';
import { State, WidgetProperties } from '../../../slices/types';
import { initialState as initialWidgetState, setCurrentWidgetAction } from '../../../slices/widget/widget';
import WidgetsTypes from '../../../types/widgets';
import './styles.css';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { WidgetDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { getDefaultWidgetProps, getSettingWidgetProps } from '../../../utils/functions';
import { dispatchRootEvent } from '../../../utils/root-helper';
import IconSvg from '../../common/icon-svg/icon-svg';
import { repairRestrictionFilterProperty, repairTableProperties } from '../dropdown-layout/helpers/itemset-helpers';
import { prepareAxisChanges, prepareAxisRestrictions } from '../helpers';

type WidgetTypesProps = {
  activeWidgetType: string;
  setActiveWidgetType: React.Dispatch<React.SetStateAction<WidgetType>>;
};

const WidgetTypes: React.FC<WidgetTypesProps> = ({
  activeWidgetType,
  setActiveWidgetType,
}) => {
  const dispatch = useDispatch();

  const types = useContext(WidgetsTypesContext);

  const [opened, setOpened] = useState<boolean>(true);

  const toggleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const widget = useSelector((state: State) => state.widget);

  const changeActiveWidgetType = useCallback(
    async (type: WidgetType) => {
      dispatch(setCurrentProjectWidgetDataAction(null));
      dispatch(setCurrentProjectWidgetErrorAction(''));
      const defaultProps = getDefaultWidgetProps(types, type);
      let settingProps: WidgetProperties[] = getSettingWidgetProps(
        defaultProps,
        widget.properties,
      );

      settingProps = prepareAxisRestrictions(settingProps, type);

      settingProps = prepareAxisChanges(settingProps, type, PanelType.axisX);
      settingProps = prepareAxisChanges(settingProps, type, PanelType.axisY);

      settingProps = repairTableProperties(type, settingProps);
      settingProps = repairRestrictionFilterProperty(type, settingProps);

      const newWidget = {
        ...initialWidgetState,
        sourceId: widget.sourceId,
        pushDown: widget.pushDown,
        id: widget.id,
        name: widget.name,
        properties: settingProps,
        type: type as WidgetType,
      };

      const isValidPushDown = widget.pushDown;

      dispatch(
        setCurrentWidgetAction({ ...newWidget, pushDown: isValidPushDown }),
      );
      setActiveWidgetType(type);
    },
    [dispatch, types, widget.properties, widget.sourceId, widget.pushDown, widget.id, widget.name, setActiveWidgetType],
  );

  useLayoutEffect(() => {
    setTimeout(() => {dispatchRootEvent(new Event('resize'));});
  }, [opened]);

  return (
    <div className="widget-types">
      {opened && (
        <div>
          <div className="widget-types-wrap">
            {types.map((widgetType: WidgetsTypes.Type) => {
              const isActive = activeWidgetType === widgetType.type;
              return (
                <CustomTooltip
                  key={`widget-${widgetType.icon}`}
                  title={widgetType.description}
                  arrow
                >
                  <button
                    onClick={() => changeActiveWidgetType(widgetType.type)}
                    className={`widget-type ${
                      isActive && 'widget-type__active'
                    }`}
                    type="button"
                  >
                    <img
                      src={widgetType.icon}
                      alt={`Тип ${WidgetDictionary.few}`}
                    />
                  </button>
                </CustomTooltip>
              );
            })}
          </div>
        </div>
      )}
      <button
        className="widget-type-toggle__button"
        type="button"
        onClick={toggleOpened}
      >
        {
          <IconSvg
            svg={
              opened
                ? IconDictionary.FillArrowToTop
                : IconDictionary.FillArrowToBottom
            }
            fill="var(--dark-grey)"
          />
        }
      </button>
    </div>
  );
};

export default WidgetTypes;
