import { useSelector } from 'react-redux';
import { State } from '../../slices/types';

export const useWidgetFullScreen = (widgetId: number) => {
  const isWidgetFullScreen = useSelector(
    (state: State) => state.mainPage.isWidgetFullScreen,
  );
  const currentWidgetIdFullScreen = useSelector(
    (state: State) => state.dashboard.currentWidgetIdFullScreen,
  );

  const isWidgetFullScreenOnDashboard = currentWidgetIdFullScreen === widgetId;

  return isWidgetFullScreen || isWidgetFullScreenOnDashboard;
};
