import React, { useCallback } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { FormikErrors } from 'formik';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { CustomButton } from '../../../../../../uikit/Button';
import { ViewSettingItem } from './view-setting-item';
import { HierarchyValues } from '../index';
import { CustomErrorAlert } from '../../../../../../uikit/ErrorAlert';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface ViewSettingsProps {
  handleChange: (event: any) => void;
  hierarchyLevels: string[];
  errors: FormikErrors<HierarchyValues>;
  dicParentName?: string;
}

export const ViewSettings = ({
  hierarchyLevels,
  handleChange,
  errors,
  dicParentName,
}: ViewSettingsProps) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active?.id !== over?.id) {
      const prevIndex = active.data.current?.sortable.index;
      const newIndex = over.data.current?.sortable.index;
      const newHierarchyLevels = [...hierarchyLevels];
      const changedElement = newHierarchyLevels.slice(
        prevIndex,
        prevIndex + 1,
      )[0];
      newHierarchyLevels.splice(prevIndex, 1);
      newHierarchyLevels.splice(newIndex, 0, changedElement);

      handleChange({
        target: { name: 'hierarchyLevels', value: newHierarchyLevels },
      });
    }
  };

  const handleClickAddLevel = () => {
    handleChange({
      target: {
        name: 'hierarchyLevels',
        value: [
          ...hierarchyLevels,
          `${dicParentName || 'Уровень'}[${hierarchyLevels.length + 1}]`,
        ],
      },
    });
  };

  const handleClickDelete = useCallback(
    (index: number) => {
      const newHierarchyLevels = [...hierarchyLevels];
      newHierarchyLevels.splice(index, 1);

      handleChange({
        target: { name: 'hierarchyLevels', value: newHierarchyLevels },
      });
    },
    [handleChange, hierarchyLevels],
  );

  const handleChangeValue = useCallback(
    (index: number, value: string) => {
      const newHierarchyLevels = [...hierarchyLevels];
      newHierarchyLevels[index] = value;

      handleChange({
        target: { name: 'hierarchyLevels', value: newHierarchyLevels },
      });
    },
    [handleChange, hierarchyLevels],
  );

  return (
    <div className="hierarchy-settings-container view-settings__container">
      {Boolean(errors.hierarchyLevels) && (
        <CustomErrorAlert
          title="Ошибка валидации"
          className="dialog-body__block"
        >
          {errors.hierarchyLevels}
        </CustomErrorAlert>
      )}
      {hierarchyLevels.length > 0 && (
        <div className="view-settings__dnd-container dialog-body__block">
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={hierarchyLevels}>
              {hierarchyLevels.map((level, index) => {
                return (
                  <ViewSettingItem
                    key={`view-setting-item-${index}`}
                    index={index}
                    level={level}
                    handleClickDelete={handleClickDelete}
                    handleChangeValue={handleChangeValue}
                    errors={errors}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
        </div>
      )}

      <CustomButton
        startIcon={<IconSvg svg={IconDictionary.PlusRounded} fill="var(--primary-color)" />}
        onClick={handleClickAddLevel}
        variant="text"
        className="dialog-body__block"
        fullWidth={false}
      >
        Добавить уровень
      </CustomButton>
    </div>
  );
};
