import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const dragIsActiveInitialState = false;

const dragAndDropSlice = createSlice({
  name: 'dragAndDrop',
  initialState: dragIsActiveInitialState,
  reducers: {
    setDragIsActive: (state, { payload }: PayloadAction<boolean>) => {
      return payload;
    },
  },
});

export const dragAndDropReducer = dragAndDropSlice.reducer;

export const {
  setDragIsActive: setDragIsActiveAction,
} = dragAndDropSlice.actions;
