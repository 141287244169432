import { GridColumns, GridRows } from '@visx/grid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Group } from '@visx/group';
import { useDebouncedCallback } from 'use-debounce';
import { VariableSizeList } from 'react-window';
import { BarGroupHorizontal } from '@visx/shape';
import { AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { times } from 'lodash';
import clsx from 'clsx';
import { ReferenceLineTypes } from 'src/enums/widgets-select-property';
import { PanelType } from '../../../../../enums/widget-type';
import {
  ScalableSVGText,
  textDirections,
} from '../../../../common/scalable-svg-text';
import {
  getCorrectWidgetData,
  getHistogramData,
} from '../../helpers';
import { setPropForNumberValue } from '../../formatting-helpers';
import { useEventListener } from '../../../../../hooks/useEventListener';
import './styles.css';
import { FilterField, SetFilterField } from '../../../../dashboard-page/hooks';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import { getTooltipHandler } from '../../../../common/widget-tooltip/widget-tooltip';
import { useActiveLegend } from '../../hooks/useActiveLegend';
import { getDomainPointsForScaledHistogram } from '../../../../../helpers/widget-page/charts';
import { useWidgetFullScreen } from '../../../../../hooks/charts/useWidgetFullScreen';
import { useBarProps } from '../hooks/useBarProps';
import { useHistogramFilters } from '../hooks/useHistogramFilters';
import {
  axisLeftOffset,
  leftAxisSettings,
  xScalePadding,
  stageSettings,
  valueScalePadding,
  defaultBottomAxisHeight,
  bottomAxisSettings,
  chartOffset,
  gridLinesColor,
  containerOffsetForAxis,
  bottomAxisTicksCount,
  defaultDiffCoeff,
  defaultOffsetCoeff,
  gridLinesYOffset,
  referenceTitleTextXoffset,
  referenceVerticalYTextOffset
} from '../settings';
import { useBarDimensions } from '../hooks/useBarDimensions';
import { CustomBarGroup } from './components/bar-group-horizontal';

export type BarsProps = {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  domain?: number[];
};

export default function HorizontalHistogram({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  domain,
}: BarsProps) {
  const isFullScreen = useWidgetFullScreen(widgetProps.id);
  const enableEvents: boolean = isActiveFilter;
  const widgetProperties = widgetProps.properties;
  const widgetData = widgetProps.data;
  const data = getHistogramData(getCorrectWidgetData(widgetData));
  const barsInGroupCount = widgetProps.data[0].y.length;

  const dataCount = useMemo(() => {
    return getCorrectWidgetData(widgetData).length;
  }, [widgetData]);

  const containerRef = useRef(document.createElement('div'));
  const viewportRef = useRef(document.createElement('div'));

  const {
    roundingCount,
    axisXValues,
    isScaleByValueState,
    isNeedToDisplayAxesGuide,
    customAxisXLabelHeight,
    currentColors,
    columnWidth,
    customAxisXLabelWidth,
    isNeedToDisplayOutsideBarValues,
    columnOffsetCoeff,
    columnDiffCoeff,
    scaleByNumber,
    formatByNumber,
    referenceLine1Value,
    referenceLine1Text,
    referenceLine1Color,
    referenceLine1Type,
    referenceLine1,
  } = useBarProps({
    widgetProperties,
    widgetProps,
  });

  const valueFormat = initValueFormatter({ roundingCount });

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [barGroupOffset, setBarGroupOffset] = useState<number>(0);
  const [barHeight, setBarHeight] = useState<number>(0);

  const bottomAxisHeight = !isNaN(customAxisXLabelHeight)
    ? customAxisXLabelHeight
    : defaultBottomAxisHeight;

  const axisYLabelWidth = isNaN(customAxisXLabelWidth)
    ? leftAxisSettings.left + axisLeftOffset
    : customAxisXLabelWidth;

  const { changeColorsActiveLegend, colorsActiveLegend } = useActiveLegend(
    widgetProperties,
    widgetProps.id,
    currentColors,
  );

  useEffect(() => {
    changeColorsActiveLegend();
  }, [JSON.stringify(currentColors), widgetData]);

  const keys: Array<string> = times(barsInGroupCount, String);

  const getY = (d: any) => d.x;

  const yScale = scaleBand<string>({
    domain: data.map(getY),
    paddingInner: xScalePadding,
    align: 0,
  });

  const valuesScale = scaleBand<string>({
    domain: keys,
    padding: valueScalePadding,
  });

  const { startDomainPoint, endDomainPoint } = getDomainPointsForScaledHistogram(keys, data);

  const xScale = scaleLinear<number>({
    zero: data.length === 1 || !isScaleByValueState,
    nice: true,
    domain: domain || [
      isScaleByValueState
        ? startDomainPoint
        : Math.min(
          ...data.map((d: any) =>
            Math.min(...keys.map((key) => Number(d[key])))),
        ),
      isScaleByValueState
        ? endDomainPoint
        : Math.max(
          ...data.map((d: any) =>
            Math.max(...keys.map((key) => Number(d[key])))),
        ),
    ],
  });

  const isHasNegative = useMemo(() => {
    return xScale.ticks().some((value: number) => value < 0);
  }, [xScale]);

  const reservedOffsetForOutsideValue = isNeedToDisplayOutsideBarValues ? 80 : 0;

  const xMax = width - axisYLabelWidth - leftAxisSettings.left - axisLeftOffset -
    (isHasNegative ? reservedOffsetForOutsideValue * 2 : reservedOffsetForOutsideValue);

  const yMax = height - bottomAxisHeight;

  yScale.rangeRound([0, yMax]);
  valuesScale.rangeRound([0, yScale.bandwidth()]);
  xScale.range([0, xMax]);

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: colorsActiveLegend,
  });

  const debounceDelay = 100;

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setWidth(value);
  }, debounceDelay);

  const { selectBar } = useHistogramFilters({
    widgetId: widgetProps.id,
    setFilterField,
    enableEvents,
    filterField,
    data,
    axisXValues,
  });

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(viewportRef);

  const {
    getOffsetBetweenBarGroups,
    getComputedBarWidth,
    groupBarSize,
    getBarXCoord,
    minChartLength,
    spaceBetweenBars,
  } = useBarDimensions({
    barHeight: columnWidth,
    barsInGroupCount,
    barGroupsCount: dataCount,
    columnDiffCoeff: isNaN(columnDiffCoeff) ? defaultDiffCoeff : columnDiffCoeff,
    columnOffsetCoeff: isNaN(columnOffsetCoeff) ? defaultOffsetCoeff : columnOffsetCoeff,
  });

  const rowsList = React.useRef<VariableSizeList>(null);

  const clearListCash = () => {
    rowsList.current && rowsList.current.resetAfterIndex(0);
  };

  const calculateDimensions = useCallback(() => {
    if (!viewportRef.current) return;
    const { minWidth } = stageSettings;
    const containerWidth = viewportRef.current.clientWidth;
    const containerHeight = viewportRef.current.clientHeight - bottomAxisHeight - chartOffset * 2;

    const currentWidth = minWidth > containerWidth ? minWidth : containerWidth;

    setDebouncedWidth.callback(currentWidth);
    setDebouncedHeight.callback(containerHeight);
    clearListCash();
  }, [bottomAxisHeight, setDebouncedWidth, setDebouncedHeight]);

  const startScaleXPoint =
    axisYLabelWidth +
    axisLeftOffset +
    Number(isHasNegative && reservedOffsetForOutsideValue);

  const renderReferenceLines = () => {
    const referenceX1Line = xScale(parseInt(referenceLine1Value)) + startScaleXPoint;

    return referenceLine1 && referenceX1Line ? (
      <g>
        <line
          strokeDasharray={
            referenceLine1Type === ReferenceLineTypes.dotted ? '5,5' : ''
          }
          x1={referenceX1Line}
          y1={0}
          x2={referenceX1Line}
          y2={viewportRef.current.clientHeight - bottomAxisHeight}
          stroke={referenceLine1Color || '#000'}
        />

        {Boolean(referenceLine1Text) && (
          <text
            x={referenceX1Line + referenceTitleTextXoffset}
            y={referenceVerticalYTextOffset}
            fontFamily="Roboto"
            fontSize="14"
            fontWeight="500"
            fill="#929bac"
          >
            {referenceLine1Text}
          </text>
        )}
      </g>
    ) : null;
  };


  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
    setBarGroupOffset(getOffsetBetweenBarGroups(height));
  }, [
    data,
    calculateDimensions,
    isFullScreen,
    getOffsetBetweenBarGroups,
    viewportRef,
    height
  ]);

  useEffect(() => {
    clearListCash();
  }, [width, height, data, viewportRef]);

  useEffect(() => {
    return () => {
      handleWidgetMouseLeave();
    };
  });

  return (
    <>
      <div
        ref={viewportRef}
        className="histogram-horizontal screenshot-overflow-x"
        style={{ flexDirection: 'column' }}
      >
        <div
          ref={containerRef}
          className={clsx('screenshot-overflow-y', 'histogram-horizontal__container')}
        >

          { isNeedToDisplayAxesGuide && (
            <svg width="100%" height="100%" style={{ position: 'absolute' }}>
              <GridColumns
                left={startScaleXPoint}
                top={chartOffset}
                scale={xScale}
                width={xMax}
                height={height}
                stroke="#e0e0e0"
              />
            </svg>
          )}

          <BarGroupHorizontal
            data={data}
            keys={keys}
            width={xMax}
            y0={getY}
            y0Scale={yScale}
            y1Scale={valuesScale}
            xScale={xScale}
            color={colorScale}
          >
            {(barGroups) => (
              <VariableSizeList
                className="histogram-horizontal__list"
                height={height}
                itemCount={barGroups.length}
                itemSize={(i) => i < data.length - 1 ? (groupBarSize + barGroupOffset) : groupBarSize}
                width={width}
                ref={rowsList}
                style={{
                  alignItems: minChartLength > (viewportRef.current.clientHeight - bottomAxisHeight - chartOffset) ? 'flex-start' : 'center',
                  top: chartOffset,
                }}
              >
                {({ index, style }) => {
                  const barProps = {
                    barGroup: barGroups[index],
                    style,
                    filterField,
                    data,
                    valueFormat,
                    xScale,
                    getComputedBarWidth,
                    isActiveFilter,
                    axisYLabelWidth,
                    widgetProperties,
                    enableEvents,
                    handleWidgetMouseLeave,
                    handleWidgetMouseMove,
                    selectBar,
                    widgetProps,
                    barHeight,
                    setBarHeight,
                    columnWidth,
                    getBarXCoord,
                    isHasNegative,
                    reservedOffsetForOutsideValue: Number(isHasNegative && reservedOffsetForOutsideValue),
                    groupBarSize,
                    spaceBetweenBars,
                  };

                  return CustomBarGroup(barProps);
                }}
              </VariableSizeList>
            )}
          </BarGroupHorizontal>
        </div>
        {referenceLine1 && (
          <div
            className="histogram-horizontal__ticks-container"
            style={{ height: 0, pointerEvents: 'none' }}
          >
            <svg
              width="100%"
              height={viewportRef.current.clientHeight}
            >
              {renderReferenceLines()}
            </svg>
          </div>
        )}

        <div
          className="histogram-horizontal__axis-container"
          style={{
            width: width - gridLinesYOffset,
            height: bottomAxisHeight,
            position: 'absolute',
            bottom: 0,
          }}
        >
          <svg
            width="100%"
            height={bottomAxisHeight}
          >
            <AxisBottom
              left={startScaleXPoint}
              hideAxisLine
              hideTicks
              numTicks={bottomAxisTicksCount}
              top={0}
              tickFormat={(x) => `${x}`}
              tickComponent={(props) => {
                const ticks = xScale.ticks(bottomAxisTicksCount);
                const textWidth = (width - containerOffsetForAxis) / ticks.length;

                return (
                  <Group>
                    <ScalableSVGText
                      x={props.x - textWidth / 2}
                      y={0}
                      formattedValue={valueFormat(
                        props.formattedValue,
                        0,
                        setPropForNumberValue(widgetProperties),
                        PanelType.axisY,
                        false,
                        false,
                        scaleByNumber ? formatByNumber : null
                      )}
                      axis={PanelType.axisY}
                      properties={{
                        width: textWidth,
                        svgTextStyles: bottomAxisSettings,
                        minWidthHide: 20,
                      }}
                      widgetProperties={widgetProperties}
                      showTooltip={false}
                    />
                  </Group>
                );
              }}
              scale={xScale}
              stroke={bottomAxisSettings.color}
              tickStroke={bottomAxisSettings.color}
            />
          </svg>
        </div>
      </div>
    </>
  );
}
