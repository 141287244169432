import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Placement } from '../../../../enums/visual-types';
import { backgroundColors } from '../../../../helpers/ui-helper';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import { DateFunctions } from '../../../../enums/date';
import widget from '../../../dashboard-page/dashboard-widgets/components/widget';

interface DateFieldProps {
  label: string;
  onChange: (date: MaterialUiPickersDate) => void;
  onInput?: () => void;
  value: Date | null;
  filterType: string;
  placement?: Placement;
  error?: boolean;
  hideClearButton?: boolean;
}

interface TextInputStyles {
  backgroundColor?: string;
}

const useIconStyles = makeStyles({
  root: {
    padding: 0,
    color: 'var(--dark-grey)',
  },
});

const useInputStyles = makeStyles({
  root: (props: { backgroundColor: string; error: boolean }) => ({
    '&:hover': {
      backgroundColor: props?.backgroundColor || 'var(--white)',
    },
    backgroundColor: props?.backgroundColor || 'var(--white)',
    border: `1px solid ${
      props.error ? 'var(--red-normal)' : 'var(--dark-cyan)'
    }`,
    borderRadius: 'var(--base-border-radius) !important',
  }),
  filter: {
    height: 'calc(46px * var(--scale-coefficient))',
    padding:
      'calc(27px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient))',
    boxSizing: 'border-box',
  },
  focused: (styles: TextInputStyles) => ({
    backgroundColor: `${styles?.backgroundColor || 'var(--white)'}!important`,
  }),
  shrink: {
    color: 'var(--dark-grey) !important',
  },
  adornment: {
    padding: 0,
  },
  adornmentItem: {
    padding: 0,
  },
});

const CustomField = (props: any) => {
  const placement: Placement = props.InputProps.placement || Placement.Default;
  const backgroundColor = backgroundColors[placement];

  const classes = useInputStyles({ backgroundColor, error: props.error });

  const iconsStyles = useIconStyles();

  return (
    <TextField
      {...props}
      helperText={
        props.error && props.value.length ? 'Неверно введена дата' : ''
      }
      error={props.error}
      fullWidth
      aria-autocomplete="none"
      InputLabelProps={{
        classes: { shrink: classes.shrink },
      }}
      InputProps={{
        endAdornment: (
          <Box p="6px" display="flex" alignItems="center" position="relative">
            {props.InputProps?.handleClearDate && (
              <IconButton
                classes={iconsStyles}
                onClick={props.InputProps?.handleClearDate}
              >
                <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
              </IconButton>
            )}
            {props.InputProps?.endAdornment || null}
          </Box>
        ),
        disableUnderline: true,
        classes: {
          input: `${classes.filter}`,
          root: `${classes.root}`,
          focused: `${classes.focused}`,
          adornedEnd: classes.adornment,
        },
      }}
      variant="filled"
    />
  );
};

export const DateField: React.FC<DateFieldProps> = ({
  label,
  onChange,
  onInput,
  value,
  filterType,
  placement = Placement.Default,
  error = false,
  hideClearButton = false,
}) => {
  // ***************************************************************************
  // * WA: компонент DatePicker вызывает событие onChange каждый раз когда
  // * выбирается толкьо год, толко месяц и день. Чтобы убрать лишние вызовы
  // * onChange, реализован WA через состояние date и useEffect

  const [date, setDate] = useState<MaterialUiPickersDate>(
    value ? moment(value) : null,
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isCalendarOpen && !isFirstRender.current) {
      onChange(date);
    }
    isFirstRender.current = false;
  }, [date, isCalendarOpen]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setDate(date?.isValid() ? date : null);
  };

  const handleOnOpen = () => {
    setIsCalendarOpen(true);
  };

  const handleOnClose = () => {
    setIsCalendarOpen(false);
  };
  // ***************************************************************************

  const handleClearDate = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const iconsStyles = useIconStyles();

  return (
    <>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          KeyboardButtonProps={{ classes: iconsStyles }}
          variant="inline"
          error={error}
          PopoverProps={{
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
          }}
          TextFieldComponent={CustomField}
          Prop
          views={
            filterType === DateFunctions.YEAR
              ? ['year']
              : filterType === DateFunctions.MONTH ||
                filterType === DateFunctions.QUARTER
                ? ['year', 'month']
                : ['year', 'month', 'date']
          }
          format={
            filterType === DateFunctions.YEAR
              ? 'YYYY'
              : filterType === DateFunctions.MONTH ||
                filterType === DateFunctions.QUARTER
                ? 'MM.YYYY'
                : 'DD.MM.YYYY'
          }
          label={label}
          value={value}
          id={label}
          onOpen={handleOnOpen}
          onChange={handleDateChange}
          onClose={handleOnClose}
          onInput={onInput}
          autoOk={true}
          InputProps={{
            // @ts-ignore
            handleClearDate: hideClearButton ? undefined : handleClearDate,
            placement,
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
