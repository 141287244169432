import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { PanelType } from '../../../../../enums/widget-type';

export const useTooltipsPosition = ({
  graphsMapper,
  data,
  xScale,
  yScale,
  getX,
  getY,
  minDistanceBetweenTooltips,
  isNeedMove,
  axis = PanelType.axisY,
}: any) => {
  const [tooltipsPositions, setTooltipsPositions] = useState<any[]>([]);

  useEffect(() => {
    let tooltipsPosition: any[] = [];
    graphsMapper.forEach((value: any, index: number) => {
      const getScaledX = (d: any) => xScale(getX(d)) ?? 0;
      const getScaledY = (d: any) => yScale(getY(d, index)) ?? 0;

      return data?.map((value: any) =>
        tooltipsPosition.push({
          y: getScaledY(value),
          x: getScaledX(value),
          value:
            axis === PanelType.axisY
              ? Number.isInteger(value.y[index])
                ? value.y[index].toFixed(2)
                : `${value.y[index]}`
              : Number.isInteger(value.z[index])
              ? value.z[index].toFixed(2)
              : `${value.z[index]}`,
        }),
      );
    });

    if (!isNeedMove) {
      setTooltipsPositions(tooltipsPosition);
      return;
    }

    tooltipsPosition = tooltipsPosition.sort((current, other) =>
      current.y < other.y ? 1 : -1,
    );

    function move() {
      for (let index = 0; index < tooltipsPosition.length; index++) {
        const position = tooltipsPosition[index];

        const collisionIndex = tooltipsPosition.findIndex(
          (otherPosition, otherIndex) =>
            otherPosition.x === position.x &&
            Math.abs(otherPosition.y - position.y) <
              minDistanceBetweenTooltips &&
            index !== otherIndex,
        );

        if (collisionIndex !== -1 && index !== collisionIndex) {
          tooltipsPosition[collisionIndex] = {
            ...tooltipsPosition[collisionIndex],
            y: tooltipsPosition[index].y - minDistanceBetweenTooltips,
          };

          return true;
        }
      }

      if (
        JSON.stringify(tooltipsPositions) !== JSON.stringify(tooltipsPosition)
      ) {
        setTooltipsPositions(tooltipsPosition);
      }

      return false;
    }

    let repeatCount = 0;
    const maxRepeatCount = 500;

    function repeater() {
      const isNeedMoving = move();

      if (isNeedMoving) {
        if (repeatCount > maxRepeatCount) {
          !isEqual(tooltipsPositions, []) && setTooltipsPositions([]);
          return;
        }
        repeatCount++;
        repeater();
      } else {
        return;
      }
    }

    repeater();
  }, [graphsMapper, yScale, xScale]);

  return tooltipsPositions;
};
