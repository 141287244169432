import { Elements } from 'react-flow-renderer/dist/types';
import { ArrowHeadType } from 'react-flow-renderer/nocss';
import React from 'react';
import { Position } from 'react-flow-renderer';
import MapConnectionTypes from '../../../types/map-connection';
import { Operation } from '../../../enums/operation';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

enum OffsetDistance {
  TableX = 600,
  OffsetTop = 25,
}

export const convertJoinOperation = (
  step: MapConnectionTypes.Step,
  operations: Elements,
  position: any,
) => {
  const OperationX = 400;
  const OperationY = -42;

  let positionState = step.position;

  if (!positionState) {
    positionState = {
      x: position.x + OperationX,
      y: position.y - OperationY,
    };
  }
  const operationNode = {
    id: step.id,
    data: { label: step.operation.type, operationType: step.operation.type },
    position: positionState,
    targetPosition: Position.Top,
    sourcePosition: Position.Right,
    type: 'operation',
  };

  // We draw only when we have output source object. So it's already has fields mapping
  if (step.fieldsMapping.length) {
    const edjeOperationTarget = {
      id: `${step.id}+${step.outputSourceObjectId}`,
      source: step.id,
      target: step.outputSourceObjectId,
      type: 'custom',
      arrowHeadType: ArrowHeadType.Arrow,
      style: { stroke: 'var(--primary-color)', strokeWidth: 'calc(2px * var(--scale-coefficient))' },
    };
    operations.push(edjeOperationTarget);
  }

  step.inputSourceObjectsIds.forEach((sourceObjectId, i) => {
    const edjeSourceOperation = {
      id: `${step.id}+${sourceObjectId}`,
      label: i ? 'join_up' : 'join_down',
      source: sourceObjectId,
      target: step.id,
      type: 'custom',
      arrowHeadType: ArrowHeadType.Arrow,
      style: { stroke: 'var(--primary-color)', strokeWidth: 'calc(2px * var(--scale-coefficient))' },
    };
    operations.push(edjeSourceOperation);
  });
  operations.push(operationNode);
  return operations;
};

export const getTooltipData = (operation: Operation | null) => {
  switch (operation) {
    case Operation.Join:
      return (
        <div>
          <h3>Применение {CommonDictionary.JoinOperation} к таблицам</h3>
          <p>
            Последовательно выберите таблицы, которые хотите объединить в единый
            {CommonDictionary.JoinOperation}.
          </p>
          <p>Для удобства мы выделили объекты доступные для выбора.</p>
        </div>
      );
    case Operation.Union:
      return (
        <div>
          <h4>Применение {CommonDictionary.UnionOperation} к таблицам</h4>
          <p>
            Последовательно выберите таблицы, которые хотите объединить в единый
            {CommonDictionary.UnionOperation}.
          </p>
          <p>Для удобства мы выделили объекты доступные для выбора.</p>
        </div>
      );
  }
};

export const getHighestByYSourceObjectId = (
  step: MapConnectionTypes.Step,
  nodes: any[],
) => {
  let prevY: number = Infinity;
  let highestSourceObjectId = '';

  step.inputSourceObjectsIds.forEach((id) => {
    const sourceY: number =
      nodes.find((node) => node.id === id)?.position.y || 0;
    if (prevY > sourceY) {
      prevY = sourceY;
      highestSourceObjectId = id;
    }
  });
  return highestSourceObjectId;
};

export const getIsError = (
  source: MapConnectionTypes.SourceObject
) => {
  let isError = false;
  if (source.whereCondition === null) {
    isError = true;
  }

  const filteredSourceObjectIds = source.processedFields.map((field) => field?.id);

  source.processedFields.forEach((field) => {
    source.processedFields.forEach((currentField) => {
      const isHaveAllOperandsFieldsInSchema =
        field.transformationOperands.every((operandId: string) => filteredSourceObjectIds.includes(operandId));
      if (!isHaveAllOperandsFieldsInSchema) isError = true;
      if (field.id !== currentField.id && (field.alias && field.alias === currentField.alias)) {
        isError = true;
      }
    });
  });
  return isError;
};

export const convertData = (
  nodesArr: MapConnectionTypes.SourceObject[],
  stepsArr: MapConnectionTypes.Step[],
  sourceIdList: string[],
  availableInOperationsSourcesList: string[],
): Elements => {
  // создание узлов источников
  const nodes = nodesArr.map((obj, index) => {
    const showLeftHandle =
      stepsArr.findIndex((step) => step.outputSourceObjectId.includes(obj.id)) > -1;
    const showRightHandle =
      stepsArr.findIndex((step) => step.inputSourceObjectsIds.includes(obj.id)) > -1;

    return {
      id: obj.id,
      data: {
        label: obj.name,
        isNewSource: obj.schema.length === 0 && obj.initialName !== CommonDictionary.UnionResult,
        isErrorSource: getIsError(obj),
        isSelected: sourceIdList.includes(obj.id),
        isNeedPreviewIcon: true,
        isAvailableInOperations: availableInOperationsSourcesList.includes(
          obj.id,
        ),
        sourceId: obj.sourceId,
        sourceObjectId: obj.id,
        showLeftHandle,
        showRightHandle,
      },
      position: obj.position || {
        x: 20,
        y: index * 100 + OffsetDistance.OffsetTop,
      },
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      type: 'source',
    };
  });

  // массив узлов и связей для операций
  let operations: Elements = [];

  stepsArr.forEach((step) => {
    // создание узлов операций
    const highestSourceObjectId = getHighestByYSourceObjectId(step, nodes);
    const position = nodes.find((node) => node.id === highestSourceObjectId)
      ?.position ?? { x: 200, y: OffsetDistance.OffsetTop };

    const targetNodeIndex = nodes.findIndex(
      (node) => node.id === step.outputSourceObjectId,
    );

    if (targetNodeIndex !== -1) {
      const yTargetOffset =
        step.operation.type === Operation.Join ||
        step.operation.type === Operation.Union
          ? 50
          : 0;
      if (!nodesArr[targetNodeIndex].position) {
        nodes[targetNodeIndex].position = {
          x: position.x + OffsetDistance.TableX,
          y: position.y + yTargetOffset,
        };
      }
    }

    if (step.operation.type === Operation.Join || step.operation.type === Operation.Union) {
      operations = convertJoinOperation(step, operations, position);
    }
  });

  return [...nodes, ...operations];
};

export const filterNewSources = (
  result: string[],
  loaderData: MapConnectionTypes.Data,
  currentOperation: Operation,
  sourceIdList: string[],
) => {
  let newResult = [...result];

  const newSourceTablesIds = loaderData?.sourceObjects
    .filter((sourceObject) => !sourceObject.schema.length)
    ?.map((sourceObject) => sourceObject.id);

  if (!(currentOperation === Operation.Map && sourceIdList.length)) {
    newResult = newResult.filter((sourceId) => {
      return !newSourceTablesIds.includes(sourceId);
    });
  }
  return newResult;
};

export const filterUsedInStepsSources = (
  result: string[],
  loaderData: MapConnectionTypes.Data,
) => {
  let newResult = [...result];

  const usedInStepsSourceIds = loaderData?.steps?.reduce((acc: string[], step) => {
    return [...acc, ...step.inputSourceObjectsIds];
  }, []);

  newResult = newResult.filter((sourceId) => {
    return !usedInStepsSourceIds.includes(sourceId);
  });

  return newResult;
};
