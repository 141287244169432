import React from 'react';
import { TextInputProps } from '../inputs/text-input';
import { InputContainer } from '../inputContainer/inputContainer';
import { useFormulasInputContainer } from './useFormulasInputContainer';
import { WidgetPropertyType } from '../../../../enums/widget-type';

interface FormulasInputProps {
  textInputProps: TextInputProps;
  propertyName: WidgetPropertyType;
  className?: string;
  onlyComplex?: boolean;
}

export const FormulasInputContainer = ({ textInputProps, propertyName, className, onlyComplex = true }: FormulasInputProps) => {
  const {
    value,
    handleChangeDebouncedValue,
    handleStartChange,
    sourcesList,
    regExes,
  } = useFormulasInputContainer(propertyName, onlyComplex);

  return (
    <div className={className}>
      <InputContainer
        textInputProps={{
          ...textInputProps,
          fullWidth: true,
        }}
        itemsList={sourcesList}
        value={value}
        changeValue={(value) => {
          handleStartChange();
          handleChangeDebouncedValue(value);
        }}
        regExes={regExes}
      />
    </div>
  );
};
