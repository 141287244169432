import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { State } from '../../slices/types';
import {
  setChangeModalAction,
  setCheckFilesModalAction,
  setCopyModalAction,
  setCurrentProjectAction,
  setCurrentProjectDashboardsAction,
  setCurrentProjectIdAction,
  setCurrentProjectWidgetsAction,
  setDeleteModalAction,
  setDeleteProjectModalAction,
  setDeletingAction,
  setEditGroupModalAction,
  setSubscribeModalAction,
  setSubscribeMailingModalAction,
  setShowSaveVersionModalAction,
  setShowRollbackVersionModalAction
} from '../../slices/main-page/main-page-slice';
import { isMobilehelper } from '../../utils/mobile-helper';
import {
  apiCheckProjectMapUsage,
  apiDeleteProject,
} from '../../services/projectsController';
import {
  deleteProjectAction,
  setGroupsAction,
} from '../../slices/group-slice/group-slice';
import { apiDeleteGroup, getAllGroups } from '../../services';
import { getMainPageView } from '../../helpers/main-page';
import { CommonDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';

interface GroupsAfterDelete {
  [key: string]: {name: string}[]
}

export const useMainPage = (setShowNewProjectModal: React.Dispatch<React.SetStateAction<boolean>>) => {
  const dispatch = useDispatch();
  const groups = useSelector((state: State) => state.groups.group);

  const isShowDeleteModal = useSelector(
    (state: State) => state.mainPage.isShowDeleteModal,
  );
  const isShowCopyModal = useSelector(
    (state: State) => state.mainPage.isShowCopyModal,
  );
  const isShowEditModal = useSelector(
    (state: State) => state.mainPage.isShowEditModal,
  );
  const isShowEditGroupModal = useSelector(
    (state: State) => state.mainPage.isShowEditGroupModal,
  );
  const isShowDeleteGroupModal = useSelector(
    (state: State) => state.mainPage.isShowDeleteGroupModal,
  );
  const isShowSubscribeModal = useSelector(
    (state: State) => state.mainPage.isShowSubscribeModal,
  );
  const isShowSaveVersionModal = useSelector(
    (state: State) => state.mainPage.isShowSaveVersionModal,
  );
  const isShowMailingSubscribeModal = useSelector(
    (state: State) => state.mainPage.isShowMailingSubscribeModal,
  );
  const isShowRollbackVersionModal = useSelector(
    (state: State) => state.mainPage.isShowRollbackVersionModal,
  );

  const isShowCheckFilesModal = useSelector((state: State) => state.mainPage.isShowCheckFilesModal);

  const currentProject = useSelector(
    (state: State) => state.mainPage.currentProject,
  );

  const isLoading = useSelector((state: State) => state.mainPage.isLoading);
  const isDeleting = useSelector((state: State) => state.mainPage.isDeleting);

  const filters = useSelector((state: State) => state.mainPage.filters);

  useEffect(() => {
    dispatch(setCurrentProjectAction(null));
    dispatch(setCurrentProjectWidgetsAction(null));
    dispatch(setCurrentProjectDashboardsAction(null));
  }, [dispatch]);

  const [error, setError] = useState<string>('');

  const isMobile = useMemo(() => isMobilehelper(), []);
  const initialGroupView = isMobile
    ? 'cards'
    : getMainPageView();

  const handleCloseDeleteModal = () => {
    setError('');
    dispatch(setDeleteModalAction(false));
  };

  const handleClickDelete = useCallback(async () => {
    setError('');

    const projectsListWithMap = await apiCheckProjectMapUsage(
      currentProject?.initialLoaderId,
    );

    const projectNames = projectsListWithMap.map((item: any) => item.name);

    if (projectsListWithMap.length) {
      setError(
        `Удаление невозможно. Данная карта ${CommonDictionary.usedByOtherProduct} ${projectNames.join()} `,
      );
    } else if (currentProject) {
      try {
        dispatch(setDeletingAction(true));
        await apiDeleteProject(currentProject.id);
        dispatch(deleteProjectAction(currentProject));
        handleCloseDeleteModal();
      } catch (err: any) {
        console.error('error', err.response);
        setError(err.response?.data?.message || err.status || 'ошибка');
      } finally {
        dispatch(setDeletingAction(false));
      }
    }
  }, [currentProject]);

  const handleCloseCopyModal = () => {
    dispatch(setCopyModalAction(false));
  };

  const handleCloseChangeModal = () => {
    dispatch(setChangeModalAction(false));
  };

  const handleCloseEditGroupModal = () => {
    dispatch(setEditGroupModalAction(false));
  };

  const handleCloseCheckFilesModal = () => {
    dispatch(setCheckFilesModalAction(false));
  };

  const getDeleteTitle = () => {
    return `${EDIT_TITLE.delete} «${currentProject?.name}»`;
  };

  const handleDeleteGroupMenuItemClick = (item: number) => () => {
    dispatch(setCurrentProjectIdAction(item));
  };

  const handleShowNewProjectModal = (item: number) => () => {
    setShowNewProjectModal(true);
    dispatch(setCurrentProjectIdAction(item));
  };

  const handleCloseNewProjectModal = () => {
    setShowNewProjectModal(false);
  };

  const handleCloseSubscribeModal = () => {
    dispatch(setSubscribeModalAction(false));
  };

  const handleCloseSubscribeMailingModal = () => {
    dispatch(setSubscribeMailingModalAction(false));
  };

  const handleCloseSaveVersionModal = () => {
    dispatch(setShowSaveVersionModalAction(false));
  };

  const handleCloseRollbackVersionModal = () => {
    dispatch(setShowRollbackVersionModalAction(false));
  };

  const handleCloseDeleteProjectModal = () => {
    setError('');
    dispatch(setDeleteProjectModalAction(false));
  };

  const handleChangeGroupMenuItemClick = (item: number) => () => {
    dispatch(setCurrentProjectIdAction(item));
    dispatch(setEditGroupModalAction(true));
  };

  const getProjectsTitles = (groups: GroupsAfterDelete) => {
    const projectsTitles: string[] = [];
    Object.keys(groups).forEach((groupName) => {
      groups[groupName].forEach((project) => {
        projectsTitles.push(project?.name);
      });
    });
    return projectsTitles;
  };

  const handleClickDeleteGroup = (id: number | null) => async () => {
    try {
      dispatch(setDeletingAction(true));
      const groupsInUse = await apiDeleteGroup(id);
      if (Object.keys(groupsInUse).length) {
        const projectsTitles = getProjectsTitles(groupsInUse);
        setError(
          `Удаление невозможно. Карты группы используются в следующих проектах: ${projectsTitles.join()}`,
        );
        return;
      }

      const afterResponse = await getAllGroups(filters);
      dispatch(setGroupsAction(afterResponse?.data));
      handleCloseDeleteProjectModal();
    } catch (err: any) {
      console.error('error', err.response);
      setError(err.response?.data?.message || err.status || 'ошибка');
    } finally {
      dispatch(setDeletingAction(false));
    }
  };

  const EDIT_TITLE = {
    delete: `Удаление ${CommonDictionary.projectGenitive}`,
    change: `Редактирование ${CommonDictionary.projectGenitive}`,
    copy: `Дублирование ${CommonDictionary.projectGenitive}`,
    save: `Сохранение ${CommonDictionary.projectGenitive}`,
    rollback: `Восстановление ${CommonDictionary.projectGenitive}`
  };

  return {
    isLoading,
    handleChangeGroupMenuItemClick,
    handleDeleteGroupMenuItemClick,
    handleShowNewProjectModal,
    isMobile,
    initialGroupView,
    isShowDeleteModal,
    isShowEditGroupModal,
    isShowDeleteGroupModal,
    isShowSaveVersionModal,
    isShowRollbackVersionModal,
    handleCloseDeleteGroupModal: handleCloseDeleteProjectModal,
    handleCloseDeleteModal,
    handleClickDeleteGroup,
    handleClickDelete,
    getDeleteTitle,
    error,
    isDeleting,
    isShowEditModal,
    handleCloseChangeModal,
    handleCloseCopyModal,
    currentProject,
    isShowCopyModal,
    handleCloseNewProjectModal,
    EDIT_TITLE,
    isShowSubscribeModal,
    isShowMailingSubscribeModal,
    handleCloseSubscribeModal,
    handleCloseSubscribeMailingModal,
    groups,
    handleCloseEditGroupModal,
    isShowCheckFilesModal,
    handleCloseCheckFilesModal,
    handleCloseSaveVersionModal,
    handleCloseRollbackVersionModal
  };
};
