import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapView, SourceFilter } from '../types';
import { Operation } from '../../enums/operation';

const initialState: MapView = {
  adviceMode: false,
  showSaveResultPanel: false,
  showSelectFieldsPanel: false,
  operationType: Operation.Map,
  bottomTableContent: { label: '', isError: false, data: { schema: [], sample: [] } },
  currentStepId: '',
  currentSourceId: '',
  showCreateTablePanel: false,
  showDeletePopup: false,
  showChangeMapPopup: false,
  isModelDataMode: false,
  activeJoinKeysList: [],
  edgesDataList: [],
  sourceFilter: {
    value: '',
    sourceIds: [],
  },
};

const mapViewSlice = createSlice({
  name: 'mapView',
  initialState,
  reducers: {
    toggleAdviceMode: (state, { payload }: PayloadAction<boolean>) => {
      state.adviceMode = payload;
    },
    toggleSaveResultPanel: (state, { payload }: PayloadAction<boolean>) => {
      state.showSaveResultPanel = payload;
    },
    toggleShowSelectFieldsPanel: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showSelectFieldsPanel = payload;
    },
    setOperationType: (state, { payload }: PayloadAction<Operation>) => {
      state.operationType = payload;
    },
    setActiveJoinKeysList: (state, { payload }: PayloadAction<any[]>) => {
      state.activeJoinKeysList = payload;
    },
    setEdgesDataList: (state, { payload }: PayloadAction<any[]>) => {
      state.edgesDataList = payload;
    },
    setBottomTableContent: (state, { payload }: PayloadAction<any>) => {
      state.bottomTableContent = payload;
    },
    setBottomTableError: (state, { payload }: PayloadAction<{isError: boolean, errorMessage?: string}>) => {
      state.bottomTableContent.isError = payload.isError;
      state.bottomTableContent.errorMessage = payload.errorMessage;
    },
    setBottomTableLabel: (state, { payload }: PayloadAction<string>) => {
      state.bottomTableContent.label = payload;
    },
    setModelDataMode: (state, { payload }: PayloadAction<boolean>) => {
      state.isModelDataMode = payload;
    },
    changeCurrentStepId: (state, { payload }: PayloadAction<string>) => {
      state.currentStepId = payload;
    },
    changeCurrentSourceId: (state, { payload }: PayloadAction<string>) => {
      state.currentSourceId = payload;
    },
    toggleCreateTablePanel: (state, { payload }: PayloadAction<boolean>) => {
      state.showCreateTablePanel = payload;
    },
    toggleDeletePopup: (state, { payload }: PayloadAction<boolean>) => {
      state.showDeletePopup = payload;
    },
    toggleChangeMapPopup: (state, { payload }: PayloadAction<boolean>) => {
      state.showChangeMapPopup = payload;
    },
    changeSourceFilter: (state, { payload }: PayloadAction<SourceFilter>) => {
      state.sourceFilter = payload;
    },
  },
});

export const mapViewReducer = mapViewSlice.reducer;

export const {
  toggleAdviceMode: toggleAdviceModeAction,
  toggleSaveResultPanel: toggleSaveResultPanelAction,
  toggleShowSelectFieldsPanel: toggleShowSelectFieldsPanelAction,
  setBottomTableContent: setBottomTableContentAction,
  setBottomTableLabel: setBottomTableLabelAction,
  setBottomTableError: setBottomTableErrorAction,
  setOperationType: setOperationTypeAction,
  setModelDataMode: setModelDataModeAction,
  toggleCreateTablePanel: toggleCreateTablePanelAction,
  toggleDeletePopup: toggleDeletePopupAction,
  toggleChangeMapPopup: toggleChangeMapPopupAction,
  changeCurrentStepId: changeCurrentStepIdAction,
  changeCurrentSourceId: changeCurrentSourceIdAction,
  setActiveJoinKeysList: setActiveJoinKeysListAction,
  setEdgesDataList: setEdgesDataListAction,
  changeSourceFilter: changeSourceFilterAction,
} = mapViewSlice.actions;
