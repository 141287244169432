import { apiETL } from '../utils/api';

const URL = '/source-parameters';

// Получение параметров источника по sourceId
export const getParametersBySourceId = async (sourceId: number) => {
  const params = { sourceId };

  try {
    const response = await apiETL.get(`${URL}/by-source`, { params });
    return response;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

/* Update source */
export const apiSetSourceParams = async (
  sourceId: number,
  body: Record<string, string | boolean | number | undefined>[],
  sourceName?: string,
) => {
  try {
    const query = sourceName
      ? `${URL}?sourceId=${sourceId}&sourceName=${sourceName}`
      : `${URL}?sourceId=${sourceId}`;
    const response = await apiETL.put(query, body);
    return response;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error.response;
  }
};
