import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { groupReducer } from './group-slice/group-slice';
import { groupViewReducer } from './group-view-slice/group-view-slice';
import { mapConnectionReducer } from './map-connection/map-connection';
import { dictionariesReducer } from './dictionaries/dictionaries';
import { mapViewReducer } from './map-view/map-view';
import { mapPageReducer } from './main-page/main-page-slice';
import { connectionsReducers } from './connections/connections';
import { connectionFilesListReducer } from './connection-files-list/connection-files-list';
import { selectFieldsPanelReducer } from './select-fields-panel/select-fields-panel';
import { createNewTableReducer } from './create-new-table/create-new-table';
import { widgetReducer } from './widget/widget';
import { dashboardReducer } from './dashboard/dashboard';
import { dragAndDropReducer } from './drag-and-drop/drag-and-drop';
import { errorPageReducer } from './error-page/error-page';
import { parametersReducer } from './parameters/parameters';

const reducer = {
  groups: groupReducer,
  groupView: groupViewReducer,
  mapConnection: mapConnectionReducer,
  dictionaries: dictionariesReducer,
  mapView: mapViewReducer,
  mainPage: mapPageReducer,
  connections: connectionsReducers,
  connectionFilesList: connectionFilesListReducer,
  createNewTable: createNewTableReducer,
  selectFieldsPanel: selectFieldsPanelReducer,
  widget: widgetReducer,
  dashboard: dashboardReducer,
  dragAndDrop: dragAndDropReducer,
  errorPage: errorPageReducer,
  parameters: parametersReducer,
};

const store = configureStore({ reducer });

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
