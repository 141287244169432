import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { arrayMove } from '@dnd-kit/sortable';
import IconSvg from '../../common/icon-svg/icon-svg';
import { State } from '../../../slices/types';
import { setEditableColorPaletteAction } from '../../../slices/main-page/main-page-slice';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { ColorPicker } from './color-picker';
import { useClassButton } from '../../../hooks/useClassButton';
import Dictionaries from '../../../types/dictionaries';
import { apiSetColorPalettes } from '../../../services';
import { getRandomColor } from '../../../utils/colors';
import { CustomButton } from '../../../uikit/Button';
import { WidgetDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const MAX_COLOR_IN_PALETTE = 12;

interface SettingsColorPaletteModalProps {}

const SettingsColorPaletteModal: React.FC<
  SettingsColorPaletteModalProps
> = () => {
  const dispatch = useDispatch();
  const classButton = useClassButton();

  const [isSaving, setSaving] = useState<boolean>(false);

  const palettes = useSelector((state: State) => state.mainPage.colorPalettes);

  const editableColorPalette = useSelector(
    (state: State) => state.mainPage.editableColorPalette,
  ) as Dictionaries.ColorPalette;

  const isCanAddColor =
    (editableColorPalette?.colorsList.length || 0) < MAX_COLOR_IN_PALETTE;

  const handleColorPaletteChangeDescription = (event: any) => {
    const { value } = event.target;
    dispatch(
      setEditableColorPaletteAction({
        ...editableColorPalette,
        description: value,
      }),
    );
  };

  const handleColorPaletteAddColor = () => {
    const palette = cloneDeep(editableColorPalette);
    palette.colorsList.push(getRandomColor());
    dispatch(setEditableColorPaletteAction(palette));
  };

  const handleColorPaletteChangeColor = (index: number, newColor: string) => {
    const palette = cloneDeep(editableColorPalette);
    palette.colorsList[index] = newColor;
    dispatch(setEditableColorPaletteAction(palette));
  };

  const handleColorPaletteDeleteColor = (index: number) => {
    const palette = cloneDeep(editableColorPalette);
    palette.colorsList.splice(index, 1);
    dispatch(setEditableColorPaletteAction(palette));
  };

  const handleCloseEditPalette = () => {
    dispatch(setEditableColorPaletteAction(null));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const palette = cloneDeep(editableColorPalette);
    palette.colorsList = arrayMove(
      palette.colorsList,
      result.source.index,
      result.destination.index,
    );
    dispatch(setEditableColorPaletteAction(palette));
  };

  const onSave = () => {
    setSaving(true);

    const newPalette = cloneDeep(editableColorPalette);
    const oldPalettes = cloneDeep(palettes);

    if (!newPalette.colorsList.includes(newPalette.firstColor)) {
      newPalette.firstColor = newPalette.colorsList[0];
    }

    const index = oldPalettes.findIndex(
      (palette) => palette.name === newPalette.name,
    );

    if (index > -1) {
      oldPalettes[index] = newPalette;
    } else {
      oldPalettes.push(newPalette);
    }

    apiSetColorPalettes(oldPalettes).then(() => {
      setSaving(false);
      dispatch(setEditableColorPaletteAction(null));
    });
  };

  return (
    <div
      className={`settings-modal ${
        editableColorPalette ? 'settings-modal--color-palette-opened' : ''
      }`}
    >
      <div className="settings-modal__header">
        <h3 className="settings-modal__title">
          Цветовая палитра {WidgetDictionary.many}
        </h3>

        <CustomButton
          size="medium"
          variant="outlined"
          icon={<IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />}
          onClick={handleCloseEditPalette}
        />
      </div>

      {editableColorPalette && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable_color_palette">
            {(provided) => (
              <div
                className="settings-color-palette-modal__content"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div>
                  <TextInput
                    InputProps={{ maxlength: 255 }}
                    value={editableColorPalette.description}
                    label="Название палитры"
                    handleChange={(e) => {
                      handleColorPaletteChangeDescription(e);
                    }}
                    className="setting-input-field"
                  />
                </div>
                <div className="settings-modal__title-small">
                  <h4 style={{ flexGrow: 1 }}>Цвета палитры</h4>
                  <span
                    style={{ cursor: isCanAddColor ? 'pointer' : 'initial' }}
                    onClick={handleColorPaletteAddColor}
                  >
                    <IconSvg
                      svg={IconDictionary.PlusRounded}
                      fill={
                        isCanAddColor
                          ? 'var(--primary-color)'
                          : 'var(--dark-grey)'
                      }
                    />
                  </span>
                </div>
                {editableColorPalette.colorsList.map((color, index) => {
                  const onChangeColor = (newColor: string) =>
                    handleColorPaletteChangeColor(index, newColor);
                  return (
                    <Draggable draggableId={`id-${index}`} index={index}>
                      {(provided) => (
                        <div
                          className="settings-modal__item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="settings-color-palette-modal__color-container">
                            <ColorPicker
                              name={`Цвет ${index + 1}`}
                              value={color}
                              onChangeColor={onChangeColor}
                            />
                            <span style={{ alignSelf: 'center' }}>
                              <IconSvg
                                svg={IconDictionary.VerticalDragAndDrop}
                                fill="var(--dark-grey)"
                                classSVG="settings-color-palette-modal__color-container-icon"
                                width={28}
                                height={28}
                              />
                            </span>
                            <span
                              style={{ alignSelf: 'center' }}
                              onClick={() =>
                                handleColorPaletteDeleteColor(index)
                              }
                            >
                              <IconSvg
                                svg={IconDictionary.BasketDelete}
                                fill="var(--dark-grey)"
                                classSVG="settings-color-palette-modal__color-container-icon clickable"
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <div className="settings-color-palette-modal__buttons">
        <CustomButton
          onClick={onSave}
          disabled={isSaving || !editableColorPalette?.colorsList.length}
          variant="contained"
        >
          Сохранить
        </CustomButton>
        <CustomButton
          type="reset"
          onClick={handleCloseEditPalette}
          variant="outlined"
        >
          Отменить
        </CustomButton>
      </div>
    </div>
  );
};

export default SettingsColorPaletteModal;
