import React, { cloneElement, useRef, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { withStyles } from '@material-ui/core/styles';

interface IAutoHideOption {
  timeout: number;
}

interface CustomTooltipProps extends TooltipProps {
  autoHide?: boolean;
  autoHideOption?: IAutoHideOption;
  log?: boolean;
}

const StyledTooltip = withStyles(() => ({
  arrow: {
    color: '#444',
  },
  tooltip: {
    backgroundColor: '#444',
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: 'calc(16px * var(--scale-coefficient))',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    margin: '6px 0',
    padding: '7px 10px',
  },
}))(Tooltip);

export const CustomPlacementTooltip = ({
  title = '',
  arrow = false,
  children,
  classes,
  ...props
}: TooltipProps) => {
  return (
    <StyledTooltip arrow={arrow} title={title} placement="top" {...props}>
      {children}
    </StyledTooltip>
  );
};

export const CustomAutoHideTooltip = ({
  children,
  autoHideOption = { timeout: 3000 },
  ...props
}: CustomTooltipProps) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const timerId = useRef<ReturnType<typeof setTimeout>>();

  const handleOpenTooltip = () => {
    if (timerId.current) clearTimeout(timerId.current);

    setTooltipOpen(true);

    timerId.current = setTimeout(() => {
      setTooltipOpen(false);
    }, autoHideOption?.timeout);
  };

  const handleCloseTooltip = () => {
    if (timerId.current) clearTimeout(timerId.current);
    setTooltipOpen(false);
  };

  const clonedChildren = cloneElement(children, {
    onMouseMove: handleOpenTooltip,
    onMouseLeave: handleCloseTooltip,
    title: null,
  });

  return (
    <CustomPlacementTooltip open={isTooltipOpen} {...props}>
      {clonedChildren}
    </CustomPlacementTooltip>
  );
};

export const CustomTooltip = ({
  children,
  autoHide = false,
  autoHideOption,
  ...props
}: CustomTooltipProps) => {
  return autoHide ? (
    <CustomAutoHideTooltip autoHideOption={autoHideOption} {...props}>
      {children}
    </CustomAutoHideTooltip>
  ) : (
    <CustomPlacementTooltip {...props}>{children}</CustomPlacementTooltip>
  );
};

export default CustomTooltip;
