import { trim } from 'lodash';
import { PanelType } from '../../../../enums/widget-type';
import { Filter } from '../../../dashboard-page/hooks';
import { getConvertedAggregationName, getConvertedFunctionName } from '../../../../helpers/common-helpers';

export interface PropertyRestriction {
  operands: string[];
  template: string;
  operandIds: string[];
  useFormula?: boolean;
}

export interface ColoringSimpleCondition {
  fieldName: string;
  fieldEtlId: string;
  aggregation: string;
  operation: string;
  value: string;
}

export interface ColoringFormula {
  text: string;
  template: string;
  operandIds: string[];
  operands: string[];
}

export type CellColoringGroup = {
  conditionColor: string;
  backgroundColor: string;
  useFormula: boolean;
  simpleCondition: ColoringSimpleCondition;
  formula: ColoringFormula;
};

export interface HierarchyLink {
  dicSourceId: string;
  dicReferenceFieldName: string;
  dicReferenceFieldEtlFieldId: string;
  dicReferenceDisplayName: string;
  dicReferenceDisplayEtlFieldId: string;
  dicParentName: string;
  dicParentEtlFieldId: string;
  hierarchyLevelNameMap: { [key: number]: string };
  dicParentRootValue: string;
}

export interface PropertyData {
  id: null | number;
  widgetFieldId?: null | number, // если поле вычисляемое то id должен быть null и в качестве id мы работаем с эим полем
  calculatedId?: any, // берется из источника, т.е. среди полей на оси виджета не уникально
  clientUID?: string;
  name: string;
  displayName: string;
  aggregation: string;
  function: string;
  restriction: null | PropertyRestriction;
  filter: string | Filter[];
  type: string;
  etlFieldId: string;
  etlSourceId?: string;
  orderDirection?: string | null;
  orderPriority?: number | null;
  minValue?: string;
  maxValue?: string;
  storage?: string;
  coloring?: CellColoringGroup[];
  template?: string,
  operands?: string[],
  operandIds?: string[],
  displayAllCards?: boolean,
  complex?: boolean,
  axisName?: PanelType,
  isLagOrLead?: boolean,
  isInherited?: boolean,
  isDrillDownFilter?: boolean;
  isRestrictionFilter?: boolean;
  isCombineValues?: boolean;
  hierarchyLink?: HierarchyLink;
  link?: string;
  isActive?: boolean;
}

export class Property {
  data: PropertyData;

  constructor(data: PropertyData) {
    this.data = data;
  }

  setIsDrilldownFilter(isDrilldownFilter: boolean) {
    this.data.isDrillDownFilter = isDrilldownFilter;
  }

  setIsRestrictionFilter(isRestrictionFilter: boolean) {
    this.data.isRestrictionFilter = isRestrictionFilter;
  }

  setIsCombineValues(isCombineValues: boolean) {
    this.data.isCombineValues = isCombineValues;
  }

  setHierarchyLink(hierarchyLink?: HierarchyLink) {
    if (hierarchyLink) {
      this.data.hierarchyLink = hierarchyLink;
    } else if (this.data.hierarchyLink) {
      delete this.data.hierarchyLink;
    }
  }

  setLink(link?: string) {
    if (link) {
      this.data.link = link;
    } else if (this.data.link) {
      delete this.data.link;
    }
  }

  changeFilter(filter: any) {
    this.data.filter = filter;
  }

  changeAggregation(aggregation: string) {
    this.data.aggregation = aggregation;
  }

  changeFunction(functionValue: string) {
    this.data.function = functionValue;
  }

  changeRestriction(restriction: PropertyRestriction | null) {
    this.data.restriction = restriction;
  }

  changeOrderDirection(sorting: string | null) {
    this.data.orderDirection = sorting;
  }

  changeOrderPriority(priority: number | null) {
    this.data.orderPriority = priority;
  }

  changeStorage(storage: string) {
    this.data.storage = storage;
  }

  changeColoring(coloring: any) {
    this.data.coloring = coloring;
  }

  isCalculated() {
    return !!this.data.template;
  }

  isComplex() {
    return !!this.data.complex;
  }

  isLagOrLead() {
    return !!this.data.isLagOrLead;
  }

  getViewFunction() {
    const startIndex = this.data.function.indexOf('(\'') + 2;
    const endIndex = this.data.function.indexOf('\',');

    return getConvertedFunctionName(this.data.function.substring(startIndex, endIndex));
  }

  getClientUID() {
    return this.data.clientUID;
  }

  getViewAggregation() {
    return getConvertedAggregationName(this.data.aggregation
      .replace(/\(%s\)/i, '')
      .replace(' ', ''));
  }

  getViewLegend(
    isNeedDisplayAggregationInLegend: boolean = true,
    isWidgetWithSlices: boolean = false,
    isAggregationMoreImportant: boolean = false,
  ) {
    const alias = this.getValueFromStorage('alias');
    const isHaveTitle = Boolean(trim(alias));
    const name = alias
      ? isWidgetWithSlices
        ? `${this.data.name.split(':')[0]}${isHaveTitle ? `: ${alias}` : ``}`
        : alias
      : this.data.name;

    if (!isNeedDisplayAggregationInLegend) return name;

    if ((this.data.function === '' && this.data.aggregation === '') || !!this.data.template) {
      return `${name}`;
    }

    if (isAggregationMoreImportant) {
      const aggregationPresenter = this.getViewAggregation();
      if (aggregationPresenter.length) {
        return `${aggregationPresenter}(${name})`;
      }

      const functionPresenter = this.getViewFunction();
      return `${functionPresenter}(${name})`;
    }

    if (this.data.function === '') {
      const aggregationPresenter = this.getViewAggregation();
      return `${aggregationPresenter}(${name})`;
    }

    const functionPresenter = this.getViewFunction();
    return `${functionPresenter}(${name})`;
  }

  getLegendObject(
    isNeedDisplayAggregationInLegend: boolean = true,
    isWidgetWithSlices: boolean = false,
  ) {
    const alias = this.getValueFromStorage('alias');
    const isHaveTitle = Boolean(trim(alias));
    const name = alias
      ? isWidgetWithSlices
        ? `${this.data.name.split(':')[0]}${isHaveTitle ? `: ${alias}` : ``}`
        : alias
      : this.data.name;

    const legendWithColoring = {
      title: name,
      coloring: this.data.coloring,
    };

    if (!isNeedDisplayAggregationInLegend) return legendWithColoring;

    if (this.data.function === '' && this.data.aggregation === '') {
      return legendWithColoring;
    }

    if (this.data.function === '') {
      const aggregationPresenter = this.getViewAggregation();
      legendWithColoring.title = `${aggregationPresenter}(${name})`;
      return legendWithColoring;
    }

    const functionPresenter = this.getViewFunction();

    legendWithColoring.title = `${functionPresenter}(${name})`;
    return legendWithColoring;
  }

  getType() {
    return this.data.type;
  }

  getFunctionOrAggregation() {
    return this.data.function.split("'")[1] || this.data.aggregation;
  }

  getFunction() {
    return this.data.function?.split("'")[1];
  }

  getId() {
    return this.data.id || this.data.widgetFieldId || 0;
  }

  getValue(): PropertyData {
    return {
      id: this.data.id,
      name: this.data.name,
      displayName: this.data.displayName,
      function: this.data.function,
      aggregation: this.data.aggregation,
      etlFieldId: this.data.etlFieldId,
      restriction: this.data.restriction,
      type: this.data.type,
      etlSourceId: this.data.etlSourceId,
      filter: this.data.filter || [],
      orderDirection: this.data.orderDirection,
      orderPriority: this.data.orderPriority,
      clientUID: this.data.clientUID,
      storage: this.data.storage,
      coloring: this.data.coloring,
      axisName: this.data.axisName,
      minValue: this.data.minValue,
      maxValue: this.data.maxValue,
      isDrillDownFilter: this.data.isDrillDownFilter,
      isRestrictionFilter: this.data.isRestrictionFilter,
      isCombineValues: this.data.isCombineValues,
      hierarchyLink: this.data.hierarchyLink,
      link: this.data.link,
      isActive: this.data.isActive,
    };
  }

  getCalculatedValue(): PropertyData {
    return {
      id: this.data.id,
      clientUID: this.data.clientUID,
      name: this.data.name,
      displayName: this.data.displayName,
      function: this.data.function,
      filter: this.data.filter || [],
      orderDirection: this.data.orderDirection,
      orderPriority: this.data.orderPriority,
      template: this.data.template,
      operands: this.data.operands,
      operandIds: this.data.operandIds,
      etlSourceId: this.data.etlSourceId,
      etlFieldId: this.data.etlFieldId,
      restriction: this.data.restriction,
      type: this.data.type,
      widgetFieldId: this.data.widgetFieldId,
      calculatedId: this.data.calculatedId,
      aggregation: this.data.aggregation,
      storage: this.data.storage,
      coloring: this.data.coloring,
      minValue: this.data.minValue,
      maxValue: this.data.maxValue,
      isDrillDownFilter: this.data.isDrillDownFilter,
      isRestrictionFilter: this.data.isRestrictionFilter,
      isCombineValues: this.data.isCombineValues,
      hierarchyLink: this.data.hierarchyLink,
      link: this.data.link,
      isActive: this.data.isActive,
    };
  }

  getViewName() {
    return this.data.name;
  }

  getViewDisplayName() {
    return this.data.displayName;
  }

  getHierarchyLink() {
    return this.data.hierarchyLink;
  }

  getStorage() {
    return this.data.storage;
  }

  getEtlSourceId() {
    return this.data.etlSourceId ?? '';
  }

  getEtlFieldId() {
    return this.data.etlFieldId ?? '';
  }

  getValueFromStorage(name: string) {
    const storage = JSON.parse(this.data.storage || '{}');
    return storage[name];
  }

  serialize() {
    return JSON.stringify(this.getValue());
  }
}

export function isActive(property: PropertyData) {
  return property.isActive === undefined ? true : property.isActive;
}
