import { Line, LineRadial } from '@visx/shape';
import { Group } from '@visx/group';
import React from 'react';
import { scaleLinear } from '@visx/scale';
import { Point } from '@visx/point';
import { getFirstActivePanelItemIndex } from 'src/helpers/common-helpers';
import { ScalableSVGText } from '../../../common/scalable-svg-text';
import { PanelType } from '../../../../enums/widget-type';
import {
  fullAngle,
  generateAngles,
  generatePoints,
  isCentralPoint,
  RadarData,
} from './helpers';
import { initValueFormatter } from '../hooks/initValueFormatter';

interface RadarAxisProps {
  containerHeight: number;
  containerWidth: number;
  radarOffset: any;
  levels: number;
  widgetData: RadarData[];
  widgetProperties: any;
  radius: number;
  labelContainerWidth: number;
  labelContainerHeight: number;
}

export const labelSettings = {
  color: 'var(--black)',
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 16,
  textAlign: 'center',
  wordBreak: 'normal',
};

export function RadarAxis({
  containerHeight,
  containerWidth,
  radarOffset,
  levels,
  widgetData,
  widgetProperties,
  radius,
  labelContainerWidth,
  labelContainerHeight,
}: RadarAxisProps) {
  const valueFormat = initValueFormatter();

  const dataCount = widgetData.length;
  const isOddData = dataCount % 2 === 0;

  const angles = generateAngles(dataCount);
  const points = generatePoints(dataCount, radius, isOddData);

  const zeroPoint = new Point({ x: 0, y: 0 });

  const axisColor = 'var(--dark-cyan)';

  const radialScale = scaleLinear<number>({
    range: [0, Math.PI * 2],
    domain: [fullAngle, 0],
  });

  const labelOffset = 15;

  const getLabelXPosition = (x: number) => {
    return isCentralPoint(x)
      ? x - labelContainerWidth / 2
      : x < 0
        ? x - labelContainerWidth - labelOffset
        : x + labelOffset;
  };

  const getLabelYPosition = (point: {x: number, y: number}) => {
    const isTopPoint = point.y < 0;

    const epsilon = 2;
    const isBottomPoint = Math.abs( point.y - radius) <= epsilon;

    const isTopCentralPoint = isCentralPoint(point.x) && isTopPoint;
    const isBottomCentralPoint = isCentralPoint(point.x) && isBottomPoint;

    return isTopCentralPoint
      ? point.y - labelContainerHeight
      : isBottomCentralPoint
        ? point.y + labelOffset
        : point.y - labelContainerHeight / 2;
  };

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  return (
    <Group
      top={containerHeight / 2 - radarOffset.top}
      left={containerWidth / 2}
    >
      {[...new Array(levels)].map((_, i) => (
        <LineRadial
          key={`web-${i}`}
          data={angles}
          angle={(d) => radialScale(d.angle) ?? 0}
          radius={((i + 1) * radius) / levels}
          fill="none"
          stroke={axisColor}
          strokeWidth={1}
          strokeOpacity={1}
          strokeLinecap="round"
          strokeDasharray={i === levels - 1 ? 0 : 5}
        />
      ))}
      {[...new Array(dataCount)].map((_, i) => (
        <Group key={`radar-line-point-${i}`}>
          <Line from={zeroPoint} to={points[i]} stroke={axisColor} />
          <circle cx={points[i].x} cy={points[i].y} r={4} fill={axisColor} />
          <ScalableSVGText
            x={getLabelXPosition(points[i].x)}
            y={getLabelYPosition(points[i])}
            formattedValue={
              i === 0
                ? valueFormat(
                  widgetData[dataCount - 1].label,
                  axisXValueIndex,
                  widgetProperties,
                )
                : valueFormat(widgetData[i - 1].label, axisXValueIndex, widgetProperties)
            }
            axis={PanelType.axisY}
            properties={{
              height: isCentralPoint(points[i].x)
                ? labelSettings.lineHeight
                : labelContainerHeight,
              width: labelContainerWidth,
              lineCount: isCentralPoint(points[i].x) ? 1 : 2,
              svgTextStyles: {
                ...labelSettings,
                textAlign: isCentralPoint(points[i].x)
                  ? 'center'
                  : points[i].x < 0
                    ? 'right'
                    : 'left',
              },
            }}
            widgetProperties={widgetProperties}
          />
        </Group>
      ))}
    </Group>
  );
}
