import React from 'react';
import IconSvg from '../../../common/icon-svg/icon-svg';

export const EmptyPanel = () => {
  return (
    <>
      <span className="widget-dropdown__placeholder">
        Перетащите сюда интересующие вас показатели
      </span>
    </>
  );
};
