import React from 'react';
import { ButtonGroupProps, styled, ButtonGroup } from '@material-ui/core';

const StyledButtonGroup = styled(ButtonGroup)({
  minWidth: 'calc(38px * var(--scale-coefficient)) !important',

  '& .MuiButtonGroup-grouped': {
    minWidth: 'calc(38px * var(--scale-coefficient)) !important',
  },

  '& > *:not(:last-child) button': {
    borderBottomColor: 'transparent',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  '& > *:not(:last-child)': {
    borderBottomColor: 'transparent',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  '& > *:not(:first-child) button': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '& > *:not(:first-child)': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  '&:not(.MuiButtonGroup-vertical) > *:not(:last-child) button': {
    borderRightColor: 'transparent',
    borderBottomColor: 'var(--dark-cyan)',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 'var(--base-border-radius)',
  },
  '&:not(.MuiButtonGroup-vertical) > *:not(:last-child)': {
    borderRightColor: 'transparent',
    borderBottomColor: 'var(--dark-cyan)',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 'var(--base-border-radius)',
  },
  '&:not(.MuiButtonGroup-vertical) > *:not(:first-child) button': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '&:not(.MuiButtonGroup-vertical) > *:not(:first-child)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '&:not(.MuiButtonGroup-vertical) > *:last-child button': {
    borderTopRightRadius: 'var(--base-border-radius)',
  },
  '&:not(.MuiButtonGroup-vertical) > *:last-child': {
    borderTopRightRadius: 'var(--base-border-radius)',
  },
});

export const CustomButtonGroup = ({ children, ...props }: ButtonGroupProps) => {
  return (
    <StyledButtonGroup
      style={{ pointerEvents: 'auto' }}
      variant="outlined"
      orientation="horizontal"
      {...props}
    >
      {children}
    </StyledButtonGroup>
  );
};
