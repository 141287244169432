import { useMemo } from 'react';
import { trim } from 'lodash';
import { getBooleanPropertyValue, getInputPropertyValue, getJSONPropertyValue } from '../helpers';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { LegendPositionInformationCard } from '.';
import { PropertyData } from '../../dropdown-layout/helpers/Property';
import { DynamicPosition } from './helper';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';

interface Props {
  widgetProperties: any;
  widgetProps: any;
}

export const useInfoCardProps = ({
  widgetProperties,
  widgetProps,
}: Props) => {
  const isNeedToDisplayDynamicsInPercent: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayDynamicsInPercent,
    widgetProperties,
  ),
  [widgetProperties]);

  const customDisplayValueText = useMemo(() => trim(
    getInputPropertyValue(WidgetPropertyType.displayValueText, widgetProperties),
  ),
  [widgetProperties]);

  const customDisplayDynamicsText = useMemo(() => trim(
    getInputPropertyValue(WidgetPropertyType.displayDynamicsText, widgetProperties),
  ),
  [widgetProperties]);

  const valuesFontSize = useMemo(
    () =>
      parseInt(
        getInputPropertyValue(
          WidgetPropertyType.valuesFontSize,
          widgetProperties,
        ),
      ) || 0,
    [widgetProperties],
  );


  const displayDynamicsGraph: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayDynamicsGraph,
    widgetProperties,
  ),
  [widgetProperties]);

  const isNeedToDisplayBackgroundColor: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.backgroundColor,
    widgetProperties,
  ),
  [widgetProperties]);

  const isDisplayLegend: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayLegend,
    widgetProperties,
  ),
  [widgetProperties]);

  const isDisplayFact: boolean = useMemo(() => getBooleanPropertyValue(
      WidgetPropertyType.displayFactInLegend,
      widgetProperties,
    ),
    [widgetProperties]);

  const isDisplayPlan: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayPlanInLegend,
    widgetProperties,
  ),
  [widgetProperties]);

  const isDisplayDynamicIcon: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayDynamicIcon,
    widgetProperties,
  ),
  [widgetProperties]);

  const isDisplayDynamicSign: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.displayDynamicSign,
    widgetProperties,
  ),
  [widgetProperties]);

  const fixedFontSize: boolean = useMemo(() => getBooleanPropertyValue(
    WidgetPropertyType.fixedFontSize,
    widgetProperties,
  ),
  [widgetProperties]);

  const legendPosition: LegendPositionInformationCard = useMemo(() =>
    (getInputPropertyValue(
      WidgetPropertyType.legendPositionInformationCard,
      widgetProperties,
    ) as LegendPositionInformationCard) || LegendPositionInformationCard.Left,
  [widgetProperties]);

  const dynamicPosition: DynamicPosition = useMemo(() =>
    (getInputPropertyValue(
      WidgetPropertyType.dynamicPosition,
      widgetProperties,
    ) as DynamicPosition) || DynamicPosition.Right,
  [widgetProperties]);

  const currentField: PropertyData | null = useMemo(() =>
    getParsedAxisValues(PanelType.axisZ, widgetProperties)[0] || null,
  [widgetProperties]);

  const previousField: PropertyData | null = useMemo(() =>
    getParsedAxisValues(PanelType.axisY, widgetProperties)[0] || null,
  [widgetProperties]);

  const isNeedToDisplayDynamics: boolean = useMemo(() =>
    getBooleanPropertyValue(WidgetPropertyType.displayDynamics, widgetProperties) &&
    Boolean(currentField) &&
    Boolean(previousField) &&
    Boolean(widgetProps.data.absolute !== null),
  [currentField, previousField, widgetProperties, widgetProps.data.absolute]);

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );

  const dynamicSizeRatio = useMemo(() => parseInt(
      getInputPropertyValue(WidgetPropertyType.dynamicSizeRatio, widgetProperties),
    ) || 100,
    [widgetProperties]);

  return {
    isNeedToDisplayDynamicsInPercent,
    customDisplayValueText,
    customDisplayDynamicsText,
    displayDynamicsGraph,
    isNeedToDisplayBackgroundColor,
    isDisplayLegend,
    isDisplayFact,
    isDisplayPlan,
    isDisplayDynamicIcon,
    isDisplayDynamicSign,
    legendPosition,
    dynamicPosition,
    currentField,
    previousField,
    isNeedToDisplayDynamics,
    colorsPaletteState,
    fixedFontSize,
    valuesFontSize,
    dynamicSizeRatio,
  };
};
