import React from 'react';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { CssVariables } from '../../../enums/css-variables';
import { getScaleCoefficient, varcss } from '../../../helpers/ui-helper';
import IconSvg from '../../common/icon-svg/icon-svg';
import { CustomPopover } from '../../../uikit/Popover/popover';
import { CustomPopoverMenu } from '../../../uikit/PopoverMenu/popover-menu';
import { useDashboardExport } from './useDashboardExport';
import { CustomButton } from '../../../uikit/Button';
import { CustomProgress } from '../../../uikit/Progress';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface ExportButtonProps {
  isSharedDashboard: boolean;
  dashboardId: number;
}

export const ExportButton = ({ isSharedDashboard, dashboardId }: ExportButtonProps) => {
  const {
    onSendClick,
    isSending,
    handleClick,
    isDownloading,
    anchorEl,
    open,
    handleClose,
    menuList,
    isLoadingTypes,
  } = useDashboardExport({ dashboardId });

  return (
    <div
      className="export-content"
      style={isSharedDashboard ? {
        marginRight: 12 * getScaleCoefficient()
      } : {
        borderLeft: '1px solid var(--dark-cyan)',
        paddingLeft: 'calc(12px * var(--scale-coefficient))',
      }}
    >
      {!isSharedDashboard && (
        <CustomTooltip title="Отправить письмо">
          <div>
            <CustomButton
              variant="outlined"
              disabled={isSending}
              icon={isSending ? <CustomProgress type="circular" /> : <IconSvg svg={IconDictionary.MailTo} fill="var(--dark-grey)" />}
              onClick={onSendClick}
              backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
            />
          </div>
        </CustomTooltip>)}

      <CustomTooltip title="Скачать">
        <div>
          <CustomButton
            variant="outlined"
            disabled={isDownloading}
            icon={isDownloading ? <CustomProgress type="circular" /> : <IconSvg svg={IconDictionary.DownloadFile} fill="var(--dark-grey)" />}
            onClick={handleClick}
            backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
          />
        </div>
      </CustomTooltip>

      <CustomPopover
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <CustomPopoverMenu settings={menuList} isLoading={isLoadingTypes} />
      </CustomPopover>
    </div>
  );
};
