import React, { useCallback, useLayoutEffect, useState } from 'react';
import GroupList from '../../components/main-page/group-list/group-list';
import MainPageRightPanel from '../../components/main-page/main-page-right-panel/main-page-right-panel';
import './main-page.css';
import CommonLoader from '../../components/common/common-loader/common-loader';
import { useMainPage } from './useMainPage';
import { Dialogs } from '../../components/main-page/dialogs/dialogs';
import { GroupTitleBlock } from '../../components/main-page/group-title-block/group-title-block';
import { LocalStorage } from '../../enums/local-storage-type';

const MainPage = () => {
  const [showNewProjectModal, setShowNewProjectModal] =
    useState<boolean>(false);
  const [newGroupId, setNewGroupId] = useState<number | null>(null);

  const { isLoading, initialGroupView } = useMainPage(setShowNewProjectModal);

  const [groupView, setGroupView] = useState(initialGroupView);

  const handleShowTable = useCallback(() => {
    setGroupView('table');
    localStorage.setItem(LocalStorage.MAIN_PAGE_GROUP_VIEW, 'table');
  }, [setGroupView]);

  const handleShowCards = useCallback(() => {
    setGroupView('cards');
    localStorage.setItem(LocalStorage.MAIN_PAGE_GROUP_VIEW, 'cards');
  }, [setGroupView]);

  const handleAddNewGroup = useCallback(
    (groupId: number | null) => {
      setNewGroupId(groupId);
    },
    [setNewGroupId],
  );

  useLayoutEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, [newGroupId]);

  return (
    <div className="main-page">
      <GroupTitleBlock
        groupView={groupView}
        handleShowTable={handleShowTable}
        handleShowCards={handleShowCards}
        handleAddNewGroup={handleAddNewGroup}
      />
      <GroupList
        groupView={groupView}
        newGroupId={newGroupId}
        setShowNewProjectModal={setShowNewProjectModal}
      />
      <MainPageRightPanel />
      <Dialogs
        setShowNewProjectModal={setShowNewProjectModal}
        showNewProjectModal={showNewProjectModal}
      />

      {isLoading && <CommonLoader />}
    </div>
  );
};

export default MainPage;
