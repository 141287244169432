import L, { LatLng } from 'leaflet';
import { useMemo } from 'react';
// @ts-ignore
import * as Rainbow from 'rainbowvis.js';
import { Feature } from 'geojson';
import { find, intersection } from 'lodash';
import { WidgetProperties } from '../../../../../slices/types';
import { getSimplifiedType } from '../../../dropdown-layout/helpers/helpers';
import {
  setPropForDate,
  setPropForNumberValue,
} from '../../formatting-helpers';
import { PanelType } from '../../../../../enums/widget-type';
import { FeatureProperties, Feature as FeatureI, PopupContent } from '../map-helpers';
import { useMapProperties } from './useMapProperties';
import { useRoundingCounts } from '../../../../../hooks/useRoundingCounts';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import {
  DefaultStartColorForGradientColoring,
  HeatMapGradientDefaultColors,
} from '../../../dropdown-panel/modals/heat-map-modal/heat-map-modal';
import {
  defaultIcon,
  layerFillOpacity,
  MapDataTypes,
  markerStyles,
  polygonStyles,
} from '../settings';
import { FilterField } from '../../../../dashboard-page/hooks';
import { Color } from '../../common/color';

interface UseMapOptionsProps {
  widgetProperties: WidgetProperties[];
  filterField: FilterField;
  currentColors: Color[];
  isActiveFilter: boolean;
}

export const useMapOptions = ({
  widgetProperties,
  filterField,
  currentColors,
  isActiveFilter,
}: UseMapOptionsProps) => {
  const {
    dateDisplayFormat,
    axisXValues,
    axisYValues,
    axisZValues,
    reverseGradientLayers,
    gradientColoringLayers,
  } = useMapProperties({ widgetProperties });

  const visibleCards = useMemo(
    () => axisXValues.map((value) => value.displayAllCards),
    [axisXValues],
  );

  const heatLayersSourceIds = useMemo(
    () => axisZValues.map((value) => value.etlSourceId),
    [axisZValues],
  );

  const heatMapLayers = useMemo(
    () =>
      axisXValues.map((value) =>
        heatLayersSourceIds.includes(value.etlSourceId),
      ),
    [axisXValues],
  );

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const getFormattedValueForPopupContent = (
    value: string,
    valueType: string,
  ) => {
    const fieldType = getSimplifiedType(valueType);

    if (fieldType === 'TEXT') return value;

    const widgetPropertiesForFormatting =
      fieldType === 'NUMBER'
        ? setPropForNumberValue(widgetProperties)
        : setPropForDate(
        '',
        dateDisplayFormat,
        fieldType,
        );

    return valueFormat(
      value,
      0,
      widgetPropertiesForFormatting,
      PanelType.axisY,
    );
  };

  const getPopupContentBlock = (content: PopupContent) => {
    return content.cardData
      .map((card) => {
        const formattedValue = getFormattedValueForPopupContent(card.value, card.type);

        return `
            <span class='map-card__label'>${card.title}:</span>
            <div class='map-card__value'>${formattedValue}</div>
          `;
      })
      .join('');
  };

  const getPopupContent = (properties: FeatureProperties) => {
    return properties.popupContent
      .map((content) => {
        const contentBlock = getPopupContentBlock(content);
        return `<div class='map-card__container'>${contentBlock}</div>`;
      })
      .join('');
  };

  const onEachFeature = (feature: Feature, layer: L.Layer) => {
    const properties = feature.properties as FeatureProperties;
    if (
      properties &&
      !visibleCards[properties.index] &&
      axisXValues[properties.index]
    ) {
      if (properties && properties.popupContent) {
        const popupContent = getPopupContent(properties);

        const popup = L.popup(
          {
            autoPanPaddingTopLeft: [0, 40],
            maxHeight: 200,
            maxWidth: 260,
            minWidth: 260,
          },
          layer,
        ).setContent(popupContent);
        layer.bindPopup(popup);
      }
    }
  };

  const getGeoJsonStyle = (feature: FeatureI) => {
    const { type } = feature.geometry;
    const itemIndex = feature.properties.index;

    const isHeatLayer = heatMapLayers[itemIndex];

    const rainbow = new Rainbow();

    const coloringSetting = gradientColoringLayers.find(
      (item) => item.etlSourceId === axisXValues[itemIndex].etlSourceId,
    );

    const rainbowColors = coloringSetting
      ? [DefaultStartColorForGradientColoring, coloringSetting.color]
      : [
          HeatMapGradientDefaultColors.green,
          HeatMapGradientDefaultColors.yellow,
          HeatMapGradientDefaultColors.red,
        ];

    const selectedValueName = axisYValues
      .filter((item) => filterField?.selectedFieldId?.includes(item.id!))
      ?.map((item) => item.name);
    const selectedValues = selectedValueName
      .map(
        (name, index) =>
          feature?.properties?.popupContent?.map(
            (item) => find(item.cardData, { title: name })?.value,
          ) || [],
      )
      .flat();

    const axisYIds = axisYValues.map((item) => item.id);

    const isSelectedPolygon = !filterField?.value?.length
      ? true
      : intersection(axisYIds, filterField?.selectedFieldId).length > 0
      ? intersection(filterField?.value, selectedValues).length > 0
      : filterField?.value?.[0] ===
        JSON.stringify(feature.geometry.coordinates);

    switch (type.toUpperCase()) {
      case MapDataTypes.POLYGON:
        if (isHeatLayer) {
          const isReverseGradient = reverseGradientLayers.find(
            (item) => item.etlSourceId === axisXValues[itemIndex].etlSourceId,
          )?.value;

          rainbow.setSpectrum(
            ...(isReverseGradient ? rainbowColors.reverse() : rainbowColors),
          );

          if (!feature.properties.heatColoring) {
            return polygonStyles;
          }

          const { min, max, value } = feature.properties.heatColoring;
          const minValue = min < max ? min : min - 1;
          rainbow.setNumberRange(minValue, max);

          polygonStyles.fillColor = `#${rainbow.colourAt(value)}`;
          polygonStyles.fillOpacity = layerFillOpacity.default;
        } else {
          polygonStyles.fillColor = currentColors[itemIndex];
        }
        return polygonStyles;
      case MapDataTypes.POINT:
        markerStyles.color = currentColors[itemIndex];
        if (isActiveFilter && filterField?.value) {
          markerStyles.fillOpacity = isSelectedPolygon ? 0.8 : 0.3;
        }
        return markerStyles;
      default:
        markerStyles.color = currentColors[itemIndex];
        return markerStyles;
    }
  };

  const getCircleMarker = (feature: Feature, latLng: LatLng) => {
    const properties = feature.properties as FeatureProperties;
    const { icon } = JSON.parse(
      axisXValues[properties.index].storage || '{}',
    );

    if (icon && icon !== defaultIcon) {
      const myIcon = L.icon({
        iconUrl: icon,
        iconSize: [31, 31],
        className: 'map-marker',
        pane: 'markerPane',
      });
      return L.marker(latLng, {
        icon: myIcon,
      });
    }

    return L.circleMarker(latLng, {
      className: 'map-marker',
      pane: 'markerPane',
    });
  };

  return {
    onEachFeature,
    getGeoJsonStyle,
    getCircleMarker,
  };
};
