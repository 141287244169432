import { apiBI } from '../utils/api';

export const PROJECT_VERSION_URL = '/project-version';

export const apiSaveProjectVersion = async (parameters: {
  projectId: number;
  description: string;
}) => {
  try {
    const response = await apiBI.post(`${PROJECT_VERSION_URL}/save`, parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiRollbackProjectVersion = async (parameters: {
  versionId: number;
  projectId: number;
}) => {
  try {
    const response = await apiBI.post(`${PROJECT_VERSION_URL}/rollback`, parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetProjectVersions = async (parameters: {
  projectId: number;
}) => {
  try {
    const response = await apiBI.get(`${PROJECT_VERSION_URL}/${parameters.projectId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};


