import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Text, TextProps } from '@visx/text';
import {
  useGetNumberWidgetProperty,
  useGetStringWidgetProperty, useGetValueDirectionWidgetProperty, ValueDirection
} from '../../../../hooks/get-properties/useGetWidgetProperty';
import { barValueStyles } from '../histogram-graph/properties';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { setPropForNumberValue } from '../formatting-helpers';
import { useRoundingCounts } from '../../../../hooks/useRoundingCounts';
import { NumberFormat } from '../../../../enums/number-format';
import { getBooleanPropertyValue, getSelectPropertyValue } from '../helpers';
import { getFirstActivePanelItemIndex } from '../../../../helpers/common-helpers';

interface LineCircleProps {
  circleParams: any;
  index: number;
  color: string;
  cx: number;
  cy: number;
  value: {
    x: string;
    y: number[];
    z: number[];
  };
  widgetTooltipGraphProps: any;
  onSelectPoint: (index: number) => void;
  isSelected: boolean;
  widgetProperties: any[];
  opacity?: number;
  isLineWithCircle: boolean;
  isActiveFilter: boolean;
  isNeedToDisplayValuesOnGraph?: boolean;
}

export const LineCircle = ({
  index,
  circleParams,
  color,
  cx,
  cy,
  value,
  widgetTooltipGraphProps: wTooltip,
  onSelectPoint,
  isSelected,
  widgetProperties,
  opacity = 0.8,
  isLineWithCircle,
  isActiveFilter,
  isNeedToDisplayValuesOnGraph = false,
}: LineCircleProps) => {
  const mediumCircleRadius = circleParams.radius - 3;
  const smallCircleRadius = circleParams.radius - 5;

  const roundingCount = useRoundingCounts(widgetProperties);
  const valueFormat = initValueFormatter({ roundingCount });

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const valueTextSize = useGetNumberWidgetProperty(
    WidgetPropertyType.textSize,
    widgetProperties,
    barValueStyles.fontSize,
  );
  const valueColorHistogram = useGetStringWidgetProperty(
    WidgetPropertyType.valueColorHistogram,
    widgetProperties,
    barValueStyles.outsideColor,
  );
  const valueDirection = useGetValueDirectionWidgetProperty(widgetProperties);

  const valuePositionProps: TextProps =
    valueDirection === ValueDirection.vertical
      ? {
        textAnchor: 'start',
        verticalAnchor: 'middle',
        angle: 270,
      }
      : {
        textAnchor: 'middle',
        verticalAnchor: 'end',
      };

  const { valueFromAxis = PanelType.axisY } = wTooltip;
  const axis = valueFromAxis === PanelType.axisZ ? 'z' : 'y';

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  const valueY = value[axis][wTooltip.indexLine] ? valueFormat(
    value[axis][wTooltip.indexLine],
    0,
    setPropForNumberValue(widgetProperties),
    PanelType.axisY,
    false,
    false,
    scaleByNumber ? formatByNumber : null,
    true
  ) : 0;

  return (
    <>
      <g
        key={index}
        onMouseLeave={
          wTooltip.isNeedToDisplayTooltip
            ? wTooltip.hideWidgetTooltip
            : undefined
        }
        onMouseMove={
          wTooltip.isNeedToDisplayTooltip
            ? (event) =>
              wTooltip.showWidgetTooltip(event, {
                attrX: wTooltip.attrX,
                valueX: valueFormat(value.x, axisXValueIndex, widgetProperties),
                attrY: wTooltip.attrY,
                valueY,
              })
            : undefined
        }
        onClick={(e) => {
          e.stopPropagation();
          onSelectPoint(index);
        }}
        className={clsx({ 'active-drilldown-element': isActiveFilter })}
      >
        <circle
          r={circleParams.radius}
          cx={cx}
          cy={cy}
          stroke="rgba(33,33,33,0.5)"
          fill={color}
          opacity={!isLineWithCircle ? 0 : (isSelected ? opacity : opacity * 0.1)}
        />
        <circle
          r={mediumCircleRadius}
          cx={cx}
          cy={cy}
          stroke="white"
          fill="white"
          opacity={!isLineWithCircle ? 0 : (isSelected ? opacity : opacity * 0.1)}
        />
        <circle
          r={smallCircleRadius}
          cx={cx}
          cy={cy}
          stroke="rgba(33,33,33,0.5)"
          fill={color}
          opacity={!isLineWithCircle ? 0 : (isSelected ? opacity : opacity * 0.1)}
        />
        {isNeedToDisplayValuesOnGraph && (
          <Text
            x={cx}
            y={cy - 12}
            className="className-test"
            {...valuePositionProps}
            style={{
              fill: valueColorHistogram,
              fontSize: valueTextSize,
              fontFamily: barValueStyles.fontFamily,
              fontStyle: barValueStyles.fontStyle,
              fontWeight: barValueStyles.fontWeight,
              opacity: 0.8,
            }}
          >
            {valueY}
          </Text>)}
      </g>
    </>
  );
};
