import React from 'react';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { CustomButton } from '../../../../../uikit/Button';
import { useLinkModal } from './useLinkModal';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';

export interface LinkSettingModalProps {
  panelData: PropertyData;
  closeDialog: () => void;
  changeLink: (link?: string) => void;
}

export const LinkSettingModal = ({
  panelData,
  closeDialog,
  changeLink,
}: LinkSettingModalProps) => {
  const {
    handleSubmit,
    handleChange,
    values,
    handleClose,
  } = useLinkModal({
    panelData,
    closeDialog,
    changeLink,
  });

  return (
    <CustomDialog
      isOpen
      onClose={closeDialog}
      title="Настройка гиперссылки"
      description={panelData.name}
      maxWidth="xs"
    >
      <form className="dialog-body" onSubmit={handleSubmit}>
        <TextInput
          formikName="link"
          value={values.link}
          label="Текст ссылки"
          handleChange={handleChange}
          fullWidth
          className="dialog-body__form-field"
        />

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton variant="contained" type="submit">
            <span>Сохранить</span>
          </CustomButton>
          <CustomButton variant="outlined" type="reset" onClick={handleClose}>
            Отменить
          </CustomButton>
        </div>
      </form>
    </CustomDialog>
  );
};
