import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import CardSettingsModal from '../../modals/map-card-modal/map-card-modal';
import HeatMapModal from '../../modals/heat-map-modal/heat-map-modal';
import MapFiltersModal from '../../modals/map-filters-modal/map-filters-modal';
import { mapModalTypes } from '../../enums';
import MapPopover from '../../map-popover';
import MapIconsModal from '../../modals/map-icons-modal/map-icons-modal';
import { State } from '../../../../../slices/types';

interface MapPopoverProps {
  index: number;
  etlSourceId: string;
  sourceDisplayName: string;
}

export const MapModalsSettings = ({
  index,
  etlSourceId,
  sourceDisplayName,
}: MapPopoverProps) => {
  const widget = useSelector((state: State) => state.widget);
  
  const [isMapCardModalOpen, setMapCardModal] = useState<boolean>(false);
  const [isHeatMapModalOpen, setHeatMapModal] = useState<boolean>(false);
  const [isMapFilterModalOpen, setMapFilterModal] = useState<boolean>(false);
  const [isMapIconsModalOpen, setMapIconsModal] = useState<boolean>(false);

  const onShowModalClickHandle = useCallback((type: mapModalTypes) => {
    switch (type) {
      case mapModalTypes.cardSettings:
        setMapCardModal(true);
        break;
      case mapModalTypes.heatMapSettings:
        setHeatMapModal(true);
        break;
      case mapModalTypes.filter:
        setMapFilterModal(true);
        break;
      case mapModalTypes.iconsSettings:
        setMapIconsModal(true);
        break;
    }
  }, []);

  const onShowModalCloseHandle = useCallback((type: mapModalTypes) => {
    switch (type) {
      case mapModalTypes.cardSettings:
        setMapCardModal(false);
        break;
      case mapModalTypes.heatMapSettings:
        setHeatMapModal(false);
        break;
      case mapModalTypes.filter:
        setMapFilterModal(false);
        break;
      case mapModalTypes.iconsSettings:
        setMapIconsModal(false);
        break;
    }
  }, []);

  return (
    <>
      <MapPopover
        widget={widget}
        onShowModalClick={onShowModalClickHandle}
        index={index}
      />
      {isMapCardModalOpen && (
        <CardSettingsModal
          widget={widget}
          etlSourceId={etlSourceId}
          sourceDisplayName={sourceDisplayName}
          onClose={onShowModalCloseHandle}
        />
      )}
      {isHeatMapModalOpen && (
        <HeatMapModal
          etlSourceId={etlSourceId}
          sourceDisplayName={sourceDisplayName}
          onClose={onShowModalCloseHandle}
        />
      )}
      {isMapFilterModalOpen && (
        <MapFiltersModal
          widget={widget}
          etlSourceId={etlSourceId}
          sourceDisplayName={sourceDisplayName}
          onClose={onShowModalCloseHandle}
        />
      )}
      {isMapIconsModalOpen && (
        <MapIconsModal
          widget={widget}
          onClose={onShowModalCloseHandle}
          index={index}
        />
      )}
    </>
  );
};
