import React, { useCallback } from 'react';
import { Group } from '@visx/group';
import { barProperties, barTextProperties } from './properties';
import { roundDate } from '../../../../utils/functions';
import { ScalableSVGText } from '../../../common/scalable-svg-text';
import { PanelType } from '../../../../enums/widget-type';

export enum RoundingVariants {
  DAY,
  SECOND,
}

export const GanttRow = ({
  xScale,
  yScale,
  colorScale,
  currentColors,
  data,
  onMouseOver,
  onMouseOut,
  isHovered,
  canvasWidth,
  canvasHeight,
  rowsCount,
  rounding,
  selectBar,
  deselectBar,
  isSelected,
  enableEvents,
}: any) => {
  const barHeight = barProperties.height;

  const endDate =
    rounding === RoundingVariants.DAY
      ? roundDate(data.endDate, 'ceil')
      : data.endDate;
  const startDate =
    rounding === RoundingVariants.DAY
      ? roundDate(data.startDate, 'floor')
      : data.startDate;

  // const mainColor = colorScale(data.taskName); -> use it later;
  const mainColor = currentColors[0];

  const handleClickOnBar = useCallback(
    (e: any) => {
      if (!enableEvents) return;
      e.stopPropagation();
      selectBar(data.taskName);
    },
    [data.taskName, selectBar],
  );

  return (
    <Group onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <rect
        x={0}
        y={yScale(data.taskName)}
        width={canvasWidth}
        height={barHeight + barProperties.paddingVertical * 2}
        fill="var(--medium-grey)"
        fillOpacity={isHovered ? 0.16 : 0}
      />
      <Group
        onClick={handleClickOnBar}
        left={xScale(startDate)}
        top={yScale(data.taskName)}
        opacity={isSelected ? 1 : 0.5}
      >
        <rect
          x={0}
          y={barProperties.paddingVertical}
          width={xScale(endDate) - xScale(startDate)}
          height={barHeight}
          fill={mainColor}
          fillOpacity={0.64}
          rx={5}
          ry={5}
        />
        <rect
          x={0}
          y={barProperties.paddingVertical}
          width={(xScale(endDate) - xScale(startDate)) * data.progress}
          height={barHeight}
          fill={mainColor}
          fillOpacity={0.72}
          rx={5}
          ry={5}
        />
        <ScalableSVGText
          x={barTextProperties.paddingHorizontal}
          y={barProperties.paddingVertical}
          formattedValue={data.taskName}
          axis={PanelType.axisY}
          properties={{
            maxHeightHide: barHeight,
            minWidthHide: 10,
            width:
              xScale(endDate) -
              xScale(startDate) -
              barTextProperties.paddingHorizontal * 2,
            svgTextStyles: {
              ...barTextProperties.styles,
              lineHeight: barHeight,
              color: barTextProperties.styles.insideColor,
            },
          }}
        />
      </Group>
    </Group>
  );
};
