import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { State, WidgetProperties } from '../../../../../slices/types';
import { WidgetDropdownMainContainerProps } from './widget-dropdown-main-container';

export const useWidgetDropdownMainContainer = ({
  id,
}: WidgetDropdownMainContainerProps) => {
  const [dataItemList, setDataItemList] = useState<WidgetProperties[]>([]);

  const widgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const properties = useMemo(
    () => [...widgetProperties] || [],
    [widgetProperties],
  );

  useEffect(() => {
    const itemList = properties.filter((item) => item.name === id);
    itemList && setDataItemList(itemList);
  }, [properties, id]);

  return {
    dataItemList,
  };
};
