import React from 'react';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import block from 'bem-cn';
import Connection from '../../../../../../types/connection';
import { fieldName } from './data';
import { CustomFormControl } from '../../../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../../../uikit/Checkbox';
import { CustomButton } from '../../../../../../uikit/Button';
import { TextInput } from '../../../../../common/special-inputs/inputs/text-input';

const b = block('edit-form');

type FormValues = Record<
  string,
  string | boolean | { start: string; end: string }[]
>;

interface Props {
  fieldsData: Connection.FileParam[];
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  handlePopoverClose: () => void;
  isLoadingFilterCell: boolean;
}

const EditForm: React.FC<Props> = ({
  fieldsData,
  initialValues,
  onSubmit,
  handlePopoverClose,
  isLoadingFilterCell,
}) => {
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleChange }) => (
          <Form>
            {fieldsData.map((item) => {
              const name = fieldName[item.type];

              if (item.type === fieldName.WITH_HEADER) {
                return (
                  <CustomFormControl
                    key={item.type}
                    control={
                      <CustomCheckbox
                        name={name}
                        onChange={handleChange}
                        checked={values[fieldName.WITH_HEADER] as boolean}
                      />
                    }
                    label={item.description}
                  />
                );
              }

              if (item.type === fieldName.DATA_ADDRESS) {
                return (
                  <FieldArray name={name} key={name}>
                    {({ remove, push }) => (
                      <div className={b('container')}>
                        <p>{item.description}</p>
                        {(values[name] as { start: string; end: string }[]).map(
                          (row, index) => (
                            <div className={b('row')} key={`row-${index}`}>
                              <Field name={`${name}.${index}.start`}>
                                {({ field }: FieldProps) => (
                                  <>
                                    <TextInput
                                      {...field}
                                      formikName={field.name}
                                      handleChange={field.onChange}
                                      label="Начало диапазона"
                                    />
                                  </>
                                )}
                              </Field>
                              <Field name={`${name}.${index}.end`}>
                                {({ field }: FieldProps) => (
                                  <>
                                    <TextInput
                                      {...field}
                                      formikName={field.name}
                                      handleChange={field.onChange}
                                      label="Конец диапазона"
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                          ),
                        )}
                      </div>
                    )}
                  </FieldArray>
                );
              }
            })}
            <div className="dialog-buttons dialog-buttons_in-form">
              <CustomButton type="submit" variant="contained" disabled={isLoadingFilterCell}>
                Сохранить
              </CustomButton>
              <CustomButton variant="outlined" onClick={handlePopoverClose}>
                Отменить
              </CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditForm;
