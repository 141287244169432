import { apiBI, apiETL } from '../utils/api';
import Dictionaries from '../types/dictionaries';
import { PanelType, WidgetType } from '../enums/widget-type';

const DICTIONARIES_URL = '/dictionaries';

export const apiGetSavingStrategies = async (): Promise<
  Dictionaries.SavingStrategies[] | null
> => {
  try {
    const response = await apiETL.get(`${DICTIONARIES_URL}/savestrategies`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
  return null;
};

// Получение списка доступных функций для агрегации
export const apiGetAggregationFunctions = async (
  params: Dictionaries.FunctionsParams,
): Promise<any> => {
  try {
    const response = await apiBI.get(`${DICTIONARIES_URL}/functions`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

// Получение списка доступных типов файлов для ftp
export const apiGetFileTypes = async (): Promise<
  Dictionaries.FileTypes[] | null
> => {
  try {
    const response = await apiETL.get(`${DICTIONARIES_URL}/file-types`);
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

// Получение списка доступных формул для преобразования полей таблиц
export const apiGetFormulas = async (
  params: { withoutComplex?: boolean } = {},
): Promise<Dictionaries.APIGroupFormula | null> => {
  try {
    const response = await apiETL.get('/formules/', { params });
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

// Получение списка статусов
export const apiGetStatuses = async (): Promise<
  Dictionaries.Status[] | null
> => {
  try {
    const response = await apiETL.get(`${DICTIONARIES_URL}/statuses`);
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiGetAxesAliases = async (
  type: WidgetType,
  axes: PanelType[],
): Promise<Dictionaries.AxesAliases> => {
  try {
    const response = await apiBI.post(
      `${DICTIONARIES_URL}/widget-axes-aliases`,
      {
        type,
        axes,
      },
    );
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};
