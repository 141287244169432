import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { arrayMove } from '@dnd-kit/sortable';
import { setWidgetPropAction } from '../../../../../../slices/widget/widget';
import { Header } from './useTableData';
import {
  getAxisValues,
  getParsedAxisValues,
} from '../../../../dropdown-layout/helpers/helpers';
import { Property } from '../../../../dropdown-layout/helpers/Property';
import { ComplexTableDndContext } from '../utils/table-dnd-provider';
import { State } from '../../../../../../slices/types';
import { getInputPropertyValue, getJSONPropertyValue } from '../../../helpers';
import { PanelType } from '../../../../../../enums/widget-type';

export interface AddHeaderParams {
  id?: string;
  title?: string;
  parentID?: string | null,
  accessor?: string | null,
  styles?: {
    backgroundColor?: string;
  }
}

export const useNestedHeaders = () => {

  const widgetProps = useSelector((state: State) => state.widget);
  const tableNestedHeaders: Header[] = getJSONPropertyValue('tableNestedHeaders', widgetProps.properties) || [];
  const axisXValuesString = getAxisValues(PanelType.axisX, widgetProps.properties);
  const axisXValues = getParsedAxisValues(PanelType.axisX, widgetProps.properties);
  const tableNestedLevelsCount: number = Number(getInputPropertyValue('nestedLevelsCount', widgetProps.properties) || 1);

  const dndState = useContext(ComplexTableDndContext);

  const tableDndDestionationId: string | null = dndState.destinationId;
  const tableDndTargetId: string | null = dndState.targetId;

  const getCustomColumns = useCallback(() => {
    return tableNestedHeaders.filter((head: any) => !head.accessor);
  }, [tableNestedHeaders]);

  const customColumns = useMemo(() => getCustomColumns(), [getCustomColumns]);

  const dispatch = useDispatch();

  const getChildrenColumnsById = (parentID: string) => {
    return customColumns.filter((head: any) => head.parentID === parentID);
  };

  const getTopLevelColumns = () => {
    return customColumns.filter((head) => !head.parentID);
  };

  const getDefaultTitle = (parentID?: string | null) => {
    if (parentID) {
      const children = getChildrenColumnsById(parentID);
      const parent = getColumnById(parentID);

      return `${parent?.title}.${children.length + 1}`;
    }

    const topLevelHeaders = getTopLevelColumns();
    return `Столбец №${topLevelHeaders.length + 1}`;
  };

  const getHeader = (params?: AddHeaderParams) => {
    const { title, id, parentID, accessor, styles } = params || {};

    const UID = uuid();
    const defaultTitle = getDefaultTitle(parentID);

    const header = {
      title: title || defaultTitle,
      id: id || UID,
      parentID: parentID || null,
      accessor: accessor || null,
      styles: {
        color: 'var(--white)',
        backgroundColor: 'rgba(233, 239, 245, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...styles,
      },
    };

    return header;
  };

  const addHeader = (params?: AddHeaderParams) => {
    const extendedHeaders = [...tableNestedHeaders, getHeader(params)];

    dispatch(
      setWidgetPropAction({
        name: 'tableNestedHeaders',
        value: JSON.stringify(extendedHeaders),
      }),
    );
  };

  const addNestedHeader = (params?: AddHeaderParams) => {
    const rootHeader = getHeader(params);
    const childrenTitle = `${rootHeader.title}.1`;
    const childrenHeader = getHeader({
      title: childrenTitle,
      parentID: rootHeader.id,
    });

    const extendedHeaders = [...tableNestedHeaders, rootHeader, childrenHeader];

    dispatch(
      setWidgetPropAction({
        name: 'tableNestedHeaders',
        value: JSON.stringify(extendedHeaders),
      }),
    );
  };

  const removeHeader = (id: string) => {
    const headers = tableNestedHeaders
      .map((head) => ({
        ...head,
        parentID: head.parentID === id ? null : head.parentID,
      }))
      .filter((head) => {
        return !(head.parentID === null && head.accessor !== null);
      })
      .filter((head) => {
        return head.id !== id ;
      });

    dispatch(
      setWidgetPropAction({
        name: 'tableNestedHeaders',
        value: JSON.stringify(headers),
      }),
    );
  };

  const updateHeader = (id: string, payload: Partial<Header>) => {
    const headers = tableNestedHeaders.map((head) =>
      head.id === id
        ? {
            ...head,
            ...payload,
          }
        : head,
    );

    dispatch(
      setWidgetPropAction({
        name: 'tableNestedHeaders',
        value: JSON.stringify(headers),
      }),
    );
  };

  const resetNestedHeaders = () => {
    dispatch(
      setWidgetPropAction({
        name: 'tableNestedHeaders',
        value: '[]',
      }),
    );
  };

  const getColumnById = (id: string) => {
    return tableNestedHeaders.find((head) => head.id === id);
  };

  const setHeaderParent = useCallback(
    (id: string, parentID: string) => {
      if (!id) return;

      const destinationColumnCopy = cloneDeep(getColumnById(parentID));
      const columnCopy = cloneDeep(getColumnById(id));

      const isFreeColumn = !columnCopy;

      if (!destinationColumnCopy || destinationColumnCopy?.accessor) return;
      if (parentID === id) return;

      if (isFreeColumn) {
        const value = axisXValues.find(
          (value: any) => value.clientUID.toString() === id.toString(),
        );
        const property = new Property(value);

        addHeader({
          parentID,
          id,
          title: property.getViewName(),
          accessor: id,
        });
      } else {
        const index = tableNestedHeaders.findIndex(
          (header) => header.id === id,
        );
        if (index === -1) return;
        const copy = [...tableNestedHeaders];

        copy[index].parentID = parentID;

        dispatch(
          setWidgetPropAction({
            name: 'tableNestedHeaders',
            value: JSON.stringify(copy),
          }),
        );
      }
    },
    [JSON.stringify(axisXValues), JSON.stringify(tableNestedHeaders)],
  );

  const isDroppableColumn = (column: any) => {
    const actualId = getActualColumnId(column);
    const isPlaceholder = isPlaceholderColumn(column);

    if (tableNestedLevelsCount === 1) {
      return !isPlaceholder && !column?.accessor;
    }

    const children = getChildrenColumnsById(actualId);

    const hasNestedHeader = !!children.find((head) => !head.accessor);

    return !isPlaceholder && !column?.accessor && !hasNestedHeader;
  };

  const isPlaceholderColumn = (column: any) => {
    return !!column.placeholderOf;
  };

  const getActualColumnId = (column: any) => {
    return column.id.split('_')[0];
  };

  const isDraggableColumn = (column: any) => {
    const isPlaceholder = isPlaceholderColumn(column);

    return !isPlaceholder && (!column || (column && !!column?.accessor));
  };

  const getColumnStyles = (id: string) => {
    const column = getColumnById(id);

    return column?.styles || {};
  };

  const setDestinationId = (id: string) => {
    dndState.setDestinationId(id);
  };

  const setTargetId = (id: string) => {
    dndState.setTargetId(id);
  };

  const isCurrentTarget = (id: string) => {
    return id === tableDndTargetId;
  };

  const isCurrentDestination = (id: string) => {
    return id === tableDndDestionationId;
  };

  const sortHeaders = ({
    targetId,
    destinationId,
  }: {
    targetId: string;
    destinationId: string;
  }) => {
    const oldIndex = tableNestedHeaders.findIndex(
      (head: Header) => head.id === targetId,
    );
    const newIndex = tableNestedHeaders.findIndex(
      (head: Header) => head.id === destinationId,
    );

    const sorted = arrayMove(tableNestedHeaders, oldIndex, newIndex);

    dispatch(
      setWidgetPropAction({
        name: 'tableNestedHeaders',
        value: JSON.stringify(sorted),
      }),
    );
  };

  const setTableNestedLevelsCount = (id: string) => {
    dispatch(
      setWidgetPropAction({
        name: 'nestedLevelsCount',
        value: id,
      }),
    );
  };

  // Required to synchronize deleted properties
  useEffect(() => {
    tableNestedHeaders.forEach((header: Header) => {
      if (
        header.accessor &&
        !axisXValues.find((value: any) => value.clientUID === header.accessor)
      ) {
        removeHeader(header.id);
      }
    });
  }, [axisXValuesString]);

  return {
    tableNestedHeaders,
    tableNestedLevelsCount,
    addHeader,
    addNestedHeader,
    resetNestedHeaders,
    removeHeader,
    updateHeader,
    sortHeaders,
    setHeaderParent,
    getColumnStyles,
    getActualColumnId,
    getTopLevelColumns,
    getChildrenColumnsById,
    isPlaceholderColumn,
    isDroppableColumn,
    isDraggableColumn,
    isCurrentTarget,
    isCurrentDestination,
    setDestinationId,
    setTargetId,
    setTableNestedLevelsCount,
  };
};
