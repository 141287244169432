import React, { useMemo, useRef, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { CustomCheckbox } from '../../../../uikit/Checkbox';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import { CustomPopoverMenu } from '../../../../uikit/PopoverMenu/popover-menu';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  item: {
    minHeight: 'calc(50px * var(--scale-coefficient))',
    paddingLeft: 'calc(5px * var(--scale-coefficient)) !important',
    paddingRight: 'calc(35px * var(--scale-coefficient)) !important',
    padding: '0 !important',
    wordBreak: 'break-all',
  },
  itemIcon: {
    minWidth: 'auto',
  },
  checkbox: {
    padding: 0,
    marginLeft: 0,
    minWidth: 'auto',

    '& span': {
      marginLeft: '0 !important',
    }
  },
  content: {
    paddingLeft: 'calc(10px * var(--scale-coefficient))',

    '& span': {
      fontSize: 'calc(16px * var(--scale-coefficient))',
    }
  },
  action: {
    right: 0,
  },
  iconControl: {
    borderRadius: 0,
    padding: 'calc(3px * var(--scale-coefficient)) 0',
    marginRight: 'calc(6px * var(--scale-coefficient))',
    width: '100%',
    justifyContent: 'flex-start',
    minHeight: 'calc(32px * var(--scale-coefficient))',
  },
  dropDownIconOn: {
    transform: 'rotate(180deg)',
    transition: '0.3s',
    color: 'var(--primary-color)',
  },
  activeExcel: {
    backgroundColor: '#EDF1F8',
  },
  paper: {
    padding: 'calc(5px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient))',
    minWidth: 'calc(230px * var(--scale-coefficient))',
  },
  popover: {
    border: '1px solid var(--dark-cyan)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    borderRadius: '4px',
  },
  buttonLabel: {
    position: 'absolute',
    marginLeft: 'calc(30px * var(--scale-coefficient))',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: 'calc(18px * var(--scale-coefficient))',
    color: 'var(--black)',
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
});

interface Props {
  file: string;
  id: number;
  previewName: string;
  fileChangeName: string;
  onPreviewClick: (name: string) => () => void;
  onFileChangeClick: (name: string) => () => void;
  onFileUpdateClick: (id: number) => () => void;
  onFileDeleteClick: (id: number) => () => void;
  checkedFiles: string[];
  handleToggle: (file: string) => () => void;
  isLocal: boolean;
}

const FtpFileItem: React.FC<Props> = ({
  file,
  id,
  previewName,
  fileChangeName,
  onPreviewClick,
  onFileChangeClick,
  onFileUpdateClick,
  onFileDeleteClick,
  checkedFiles,
  handleToggle,
  isLocal,
}) => {
  const classes = useStyles();

  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpenPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popoverId = useMemo(() => (isOpenPopover ? 'simple-popover' : undefined), [
    isOpenPopover,
  ]);

  const handleMoreButtonClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(containerRef.current);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const menuList = [
    {
      title: 'Просмотреть',
      icon: <IconSvg svg={IconDictionary.OpenFile} fill={file === fileChangeName ? 'var(--primary-color)' : 'var(--dark-grey)'} />,
      onClick: onPreviewClick(file),
      isNeedDivider: false,
    },
    {
      title: 'Редактировать',
      icon: <IconSvg svg={IconDictionary.EditFile} fill={file === fileChangeName ? 'var(--primary-color)' : 'var(--dark-grey)'} />,
      onClick: onFileChangeClick(file),
      isNeedDivider: false,
    },
    {
      title: 'Обновить',
      icon: <IconSvg svg={IconDictionary.Refresh} fill='var(--dark-grey)' />,
      onClick: onFileUpdateClick(id),
      isNeedDivider: true,
      isHidden: !isLocal,
    },
    {
      title: 'Удалить',
      icon: <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />,
      onClick: onFileDeleteClick(id),
      isNeedDivider: false,
      isDelete: true
    },
  ];

  return (
    <ListItem className={classes.item} button onClick={handleToggle(file)} ref={containerRef}>
      <ListItemIcon className={classes.checkbox}>
        <CustomCheckbox
          edge="start"
          checked={checkedFiles.includes(file)}
          tabIndex={-1}
        />
      </ListItemIcon>
      <ListItemText primary={file} className={classes.content} />
      <ListItemSecondaryAction className={classes.action}>
        <CustomIconButton
          iconButtonProps={{
            children: <IconSvg
              svg={IconDictionary.VerticalMore}
              fill="var(--dark-grey)"
            />,
            onClick: handleMoreButtonClick,
            className: 'more-svg-button',
          }}
          type={IconButtonType.secondary}
        />

        <CustomPopover
          id={popoverId}
          open={isOpenPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <CustomPopoverMenu settings={menuList} />
        </CustomPopover>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FtpFileItem;
