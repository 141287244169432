import React, { useRef, useState } from 'react';
import IconSvg from 'src/components/common/icon-svg/icon-svg';
import { CustomButton } from 'src/uikit/Button';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { useWidgetSaveButton } from './useWidgetSaveButton';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

export const SaveButton = () => {
  const { onSaveClick, isActive, tooltipTitle } = useWidgetSaveButton();

  return (
    <CustomTooltip title={tooltipTitle} autoHide>
      <div>
        <CustomButton
          variant="contained"
          onClick={onSaveClick}
          type="submit"
          disabled={!isActive}
          icon={<IconSvg svg={IconDictionary.Save} fill="var(--white)" />}
        >
          Сохранить
        </CustomButton>
      </div>
    </CustomTooltip>
  );
};
