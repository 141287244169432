import axios, { CancelTokenSource } from 'axios';
import MapConnection from '../types/map-connection';
import { apiBI, apiETL } from '../utils/api';
import { FileTypesEnum } from '../enums/fyle-type';
import { SourcesView } from '../enums/sources-view';
import Connection from '../types/connection';

const SOURCES_URL = '/sources';

// Получение списка источников по connectionId для соединений
// которые не базы данных
export const getSourcesFromConnectionIdForNotDataBase = async (
  connectionId: number,
  fileTypeId?: number,
  view: SourcesView = SourcesView.BASE,
) => {
  const params = fileTypeId
    ? { connectionId, fileTypeId, view }
    : { connectionId, view };

  try {
    const response = await apiETL.get(`${SOURCES_URL}/`, { params });
    return response;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

// Получение списка источников по connectionId
export const getSourcesFromConnectionId = async (connectionId: number, searchQuery?: string) => {
  const params = searchQuery ? { search: searchQuery } : null;
  try {
    const response = await apiETL.get(
      `${SOURCES_URL}/by-connection/${connectionId}/names`,
      { params }
    );
    return response;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

/* Получение подробной информации от таблице */
export const apiGetInfoByTableSource = async (
  connectionId: number,
  objectName: string,
): Promise<MapConnection.TableInfo> => {
  try {
    const { data } = await apiETL.get<MapConnection.TableInfo>(
      `${SOURCES_URL}/by-connection/${connectionId}/schema`,
      { params: { objectName } },
    );
    return data;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

/* Получение подробной информации о новом файле */
export const apiGetInfoByNewFile = async (
  connectionId: number,
  fileType: FileTypesEnum,
  params: {
    name: string;
    value: string | boolean;
  }[],
): Promise<MapConnection.TableInfo> => {
  try {
    const { data } = await apiETL.post(
      `${SOURCES_URL}/by-connection/${connectionId}/schema?fileType=${fileType}`,
      params,
    );
    return data;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

export const apiGetTableContentBySourceId = async (
  sourceId: number,
  tableName: string,
): Promise<MapConnection.TableInfo | null> => {
  try {
    const {
      data: { connectionId },
    } = await apiETL.get<MapConnection.SourceInfo>(
      `${SOURCES_URL}/${sourceId}`,
    );
    const data = await apiGetInfoByTableSource(connectionId, tableName);
    return data;
  } catch (error: any) {
    console.error(error);
  }
  return null;
};

/* Получение превью таблицы по sourceId */

export const apiGetTableContentPreviewBySourceId = async (parameters: {
  sourceObjectId: string,
  id?: string
  signal?: AbortSignal;
}
): Promise<any> => {
  const params = {
    signal: parameters.signal,
    params: { requestId: parameters.id },
  };
  try {
    const { data } = await apiETL.get<any>(
      `/bi/source-objects/${parameters.sourceObjectId}`,
      params,
    );
    return data;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

export const apiCheckSourceUsageByWidgets = async (
  sourcesId: string[],
): Promise<any> => {
  try {
    const { data } = await apiBI.get<any>('/widgets/by-source', {
      params: { sources: `${[sourcesId]  }` },
    });
  
    return data;
  } catch (error: any) {
    console.error(error);
    return error;
  }
};

/* Получение id созданного источника */
export const apiCreateSource = async (
  body: MapConnection.Payload.CreateSource,
): Promise<number> => {
  try {
    const { data } = await apiETL.post(`${SOURCES_URL}/`, body);
    return data;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

/* Обновление источника */
export const apiUpdateSource = async (
  id: number,
  body: MapConnection.Payload.UpdateSource,
): Promise<number> => {
  try {
    const { data } = await apiETL.put(`${SOURCES_URL}/${id}`, body);
    return data;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

/* Валидация перед созданием новой таблицы */
export const apiValidateNewTable = async (
  connectionId: number,
  objectName: string,
): Promise<{
  isSuccess?: boolean;
  isWarning?: boolean;
  isError?: boolean;
  data?: object;
}> => {
  try {
    const { data } = await apiETL.get(`${SOURCES_URL}/validateNewTable`, {
      params: { connectionId, objectName },
    });
    return { isSuccess: true, data };
  } catch (error: any) {
    console.error('ERROR', error.response);
    if (error?.response?.status === 409) {
      return { isWarning: true, data: error?.response?.data?.message ?? '' };
    }
    return { isError: true, data: error?.response?.data?.message ?? '' };
  }
};

export const createNewFileSource = async (
  connectionId: number,
  name: string,
  fileType: FileTypesEnum,
  params: { name: string; value: boolean | string }[],
) => {
  try {
    const response = await apiETL.post(`${SOURCES_URL}/`, {
      connectionId,
      name,
      fileType,
      parameters: params,
    });
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', { error });
    throw error;
  }
};

/* Валидация sql скрипта */
export const apiValidateSqlScript = async (body: {
  connectionId?: number;
  sourceId?: number;
  script: string;
}) => {
  try {
    const response = await apiETL.put(`${SOURCES_URL}/validation-script`, body);
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', { error });
    throw error;
  }
};

// Get files for preview, for excel get lists
export const apiGetFilesForPreview = async (
  connectionId: number,
  fileType: string,
  params: { name: string; value: boolean | string }[],
) => {
  try {
    const response = await apiETL.post(
      `${SOURCES_URL}/sampleFile?connectionId=${connectionId}&fileType=${fileType}`,
      params,
    );
    return response;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

// Get info about connection by sourceId
export const apiGetConnectionInfoBySourceId = async (
  sourceId: number,
): Promise<{ connection: Connection.Create.Response; name: string }> => {
  const response = await apiETL.get(`${SOURCES_URL}/info/${sourceId}`);
  return response.data;
};

/* Check connecting source  */
export const apiCheckSource = async (body: {
  connectionId: number;
  name?: string;
  parameters: { name: string; value: any }[];
}): Promise<any> => {
  try {
    const res = await apiETL.post(`${SOURCES_URL}/check`, body);
    return res.data;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

/* upload file on local-ftp  */
export const apiUploadFile = async (
  groupId: number,
  file: File,
  isForceUpload: boolean
): Promise<any> => {

  const params = isForceUpload ? { force: 'true' } : {};

  try {
    const form = new FormData();
    form.append('file', file); // type File
    const res = await apiETL.post(`/local-ftp/${groupId}/upload`, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params,
    });
    return res?.data;
  } catch (error: any) {
    console.error('Request failed:', error);
    if (error?.response?.data) {
      return { isWarning: true, data: error?.response?.data?.message ?? '' };
    }
    throw error;
  }
};


/* replace file on local-ftp  */
export const apiUpdateLocalFtpFile = async (
  sourceId: number,
  file: File,
): Promise<any> => {
  try {
    const form = new FormData();
    form.append('file', file); // type File
    const res = await apiETL.put(`/local-ftp/${sourceId}/update`, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res?.data;
  } catch (error: any) {
    console.error('Request failed:', error);
    if (error?.response?.data) {
      return { isWarning: true, data: error?.response?.data?.message ?? '' };
    }
    throw error;
  }
};
