import React from 'react';
import { SourceType } from '../../../../enums/source-type';
import Connection from '../../../../types/connection';
import ConnectionItem from '../../connection-item/connection-item';
import { CustomDropdownList } from '../../../../uikit/DropdownList';

type Data = Connection.Meta[];

interface ConnectionsListProps {
  connections: any;
  data: Data;
  activeItem: string | number | null;
  handleConnectionClick: (item: Connection.View.ItemFull) => void;
  onEditConnectionClick: (item: Connection.View.ItemFull) => void;
}

const sortConnections = (a: string, b: string) => (a === 'LOCAL' ? -1 : 0);

export const ConnectionsList = ({
  connections,
  data,
  activeItem,
  handleConnectionClick,
  onEditConnectionClick,
}: ConnectionsListProps) => {
  const getConnectionName = (type: SourceType) =>
    data.find((item) => item.sourceType === type)?.name || type;

  return (
    <div className="connections__container-connections">
      {Object.keys(connections)
        .sort(sortConnections)
        .map((type) => (
          <CustomDropdownList isOpenInit={true} title={getConnectionName(type as SourceType)} key={type}>
            {connections[type].map((item: Connection.View.ItemFull) => (
              <ConnectionItem
                key={item.id}
                item={item}
                activeItem={activeItem}
                handleItemClick={handleConnectionClick}
                handleEditItemClick={onEditConnectionClick}
              />
            ))}
          </CustomDropdownList>
        ))}
    </div>
  );
};
