import React, { useCallback } from 'react';
import '../widget-carousel.css';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { DashboardResponse, State, Widget } from '../../../../slices/types';
import { apiCopyDashboard } from '../../../../services/dashboardController';
import { apiCopyWidget } from '../../../../services/widgetController';
import { PageParams } from '../../../../types/meta';
import {
  setCurrentProjectDashboardsAction,
  setCurrentProjectWidgetsAction,
} from '../../../../slices/main-page/main-page-slice';
import { setCurrentDashboardAction } from '../../../../slices/dashboard/dashboard';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { CustomIconButton, IconButtonType } from '../../../../uikit/IconButton';
import { CustomPopoverMenu } from '../../../../uikit/PopoverMenu/popover-menu';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import { widgetIconNames } from '../../../dashboard-page/widgets-list';

type WidgetCarouselItemProps = {
  name: string;
  iconName?: keyof typeof IconDictionary;
  type?: string;
  id?: number;
  isActive: boolean;
  changeModal?: (
    type: string | undefined,
    actionType: string,
    name: string,
    id: number | undefined,
  ) => void;
  closeWidgetMenu?: any;
  closeDashboardMenu?: any;
  isCurrent?: boolean;
  disableHoverTooltipListener?: boolean;
};

const WidgetCarouselItem: React.FC<WidgetCarouselItemProps> = ({
  name,
  iconName= widgetIconNames.NONE,
  type,
  changeModal,
  isActive,
  id,
  closeWidgetMenu,
  closeDashboardMenu,
  isCurrent,
  disableHoverTooltipListener,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const history = useHistory();
  const params: PageParams = useParams();

  const dispatch = useDispatch();

  const widgetsList: Widget[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? null,
  );

  const dashboardsList: DashboardResponse[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectDashboards ?? null,
  );

  const projectId: number =
    useSelector((state: State) => state.mainPage?.currentProject?.id) ?? 0;

  const onPopoverButtonClick = useCallback(
    (event: any, actionType: string) => {
      event.stopPropagation();
      handleClose();
      changeModal && changeModal(type, actionType, name, id);
    },
    [changeModal, type, name, id],
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onCopyButtonClick = useCallback(() => {
    handleClose();
    const projectId = params.projectId || params.id;
    type === 'widget'
      ? apiCopyWidget(id).then((result) => {
        const widgets = widgetsList ? [...widgetsList] : [];
        widgets.push(result);
        dispatch(setCurrentProjectWidgetsAction([...widgets]));
        history.push(`/project/${projectId}/widget/${result.id}`);
      })
      : apiCopyDashboard(id).then((result) => {
        dispatch(setCurrentDashboardAction(result));
        const dashboards = dashboardsList ? [...dashboardsList] : [];
        dashboards.push(result);
        dispatch(setCurrentProjectDashboardsAction([...dashboards]));

        history.push(`/project/${projectId}/dashboard/${result.id}`);
      });
  }, [params, type, id, widgetsList, dispatch, history, dashboardsList]);

  const onDashboardPreviewClick = useCallback(() => {
    handleClose();
    const projectId = params.projectId || params.id;
    history.push(`/project/${projectId}/dashboard/${id}/preview`);
  }, [params, id]);

  const menuList = [
    {
      title: 'Просмотр',
      icon: <IconSvg
        svg={IconDictionary.OpenDashboards}
        fill="var(--primary-color)"
      />,
      onClick: onDashboardPreviewClick,
      isNeedDivider: true,
      isHidden: type !== 'dashboard',
    },
    {
      title: 'Переименовать',
      icon: <IconSvg svg={IconDictionary.EditFile} fill='var(--dark-grey)' />,
      onClick: (event: React.MouseEvent<HTMLElement>) => onPopoverButtonClick(event, 'rename'),
      isNeedDivider: false,
      isHidden: isCurrent,
    },
    {
      title: 'Дублировать',
      icon: <IconSvg svg={IconDictionary.Copy} fill="var(--dark-grey)" />,
      onClick: onCopyButtonClick,
      isNeedDivider: true,
    },
    {
      title: 'Удалить',
      icon: <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />,
      onClick: (event: React.MouseEvent<HTMLElement>) => onPopoverButtonClick(event, 'delete'),
      isNeedDivider: false,
      isDelete: true
    },
  ];

  return (
    <div
      className={`widget-carousel-item ${
        isActive && 'widget-carousel-item__active'
      }`}
    >
      <NavLink
        style={{ overflow: 'hidden' }}
        onClick={type === 'widget' ? closeWidgetMenu : closeDashboardMenu}
        to={`/project/${projectId}/${type}/${id}`}
      >
        <div style={{ whiteSpace: 'nowrap', display: 'flex', minWidth: 0, alignItems: 'center' }}>
          <IconSvg
            svg={IconDictionary[iconName as keyof typeof IconDictionary]}
            fill="var(--dark-grey)"
          />
          <CustomTooltip
            disableHoverListener={disableHoverTooltipListener}
            title={name}
            arrow
          >
            <div className="widget-carousel-item__block-label">{name}</div>
          </CustomTooltip>
        </div>
      </NavLink>

      {type && (
        <>
          <CustomIconButton
            iconButtonProps={{
              children: <IconSvg svg={IconDictionary.VerticalMore} fill={anchorEl ? 'var(--primary-color)' : 'var(--dark-grey)'} />,
              onClick: handleClick,
            }}
            type={IconButtonType.secondary}
          />

          <CustomPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <CustomPopoverMenu settings={menuList} />
          </CustomPopover>
        </>
      )}
    </div>
  );
};

export default WidgetCarouselItem;
