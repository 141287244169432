import React from 'react';
import { IconButton } from '@material-ui/core';
import block from 'bem-cn';

import './right-panel.css';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

const b = block('right-panel');

interface Props {
  className?: string;
  handlePanelClose: () => void;
  children?: React.ReactNode;
}

const RightPanel: React.FC<Props> = ({
  className = '',
  handlePanelClose,
  children,
}) => {
  return (
    <div className={`${b()} ${className}`}>
      <div className={b('close')}>
        <IconButton
          onClick={handlePanelClose}
          size="small"
          aria-label="close-panel"
        >
          <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
        </IconButton>
      </div>

      <div className={b('content')}>{children}</div>
    </div>
  );
};

export default RightPanel;
