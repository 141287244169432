import { DashboardPropertyType } from '../../enums/dashboard-properties';
import { DashboardProperty } from '../../slices/types';
import { useGetProperty } from './useGetProperty';


export const useGetDashboardProperty = <D,>(
  name: DashboardPropertyType,
  properties: DashboardProperty[],
  defaultValue?: D,
) => {
  return useGetProperty<DashboardPropertyType, DashboardProperty, D>(name, properties, defaultValue);
};
