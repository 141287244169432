import React from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import * as Yup from 'yup';
import block from 'bem-cn';

import { useClassButton } from '../../../../hooks/useClassButton';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';

import '../common/css/create-source-form.css';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../common/icon-svg/icon-svg';

const b = block('create-source-form');

interface Props {
  fieldsData: { type: string; description: string }[];
  initialValues: Record<string, string | boolean>;
  handleFormSubmit: (values: { [key: string]: any }) => Promise<void>;
  errorSubmit: string | null;
  createSource: (values: Record<string, any>) => Promise<void>;
  changeSource: (values: Record<string, any>) => Promise<void>;
  onCancel: () => void;
  isDisabledCreateButton: boolean;
  itemChangeName: string | null;
  isEditMode?: boolean;
  children?: React.ReactNode;
}

const SourceForm: React.FC<Props> = ({
  fieldsData,
  initialValues,
  handleFormSubmit,
  errorSubmit,
  createSource,
  changeSource,
  onCancel,
  isDisabledCreateButton,
  isEditMode,
  itemChangeName,
  children,
}) => {
  const classButton = useClassButton();
  const classStyledButton = useClassButton({ backgroundColor: 'transparent' });

  const handleActionButtonClick = (values: Record<string, any>) => () => {
    if (isEditMode) {
      changeSource(values);
      return null;
    }
    createSource(values);
  };

  const validationSchema = Yup.object().shape({
    NAME: Yup.string().required('Название не должно быть пустым'),
  });

  const startValues: { [field: string]: any } = {
    ...initialValues,
    NAME: itemChangeName || '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={startValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        touched,
        isSubmitting,
        values,
      }) => (
        <form className={b()} onSubmit={handleSubmit}>
          <h3 className={b('title')}>
            {isEditMode ? 'Редактирование запроса' : 'Новый запрос'}
          </h3>
          <div className={b('fields')}>
            <TextInput
              name="NAME"
              error={Boolean(errors.NAME && touched.NAME)}
              label="Название"
              className={b('field')}
              value={values.NAME}
              handleChange={handleChange}
            />

            {errors.NAME && <Alert severity="error">{errors.NAME as string}</Alert>}

            {fieldsData.map((item) => {
              const name = item.type;

              if (item.type === 'SOAP_REQUEST') {
                return (
                  <TextField
                    key={item.type}
                    className={b('field', {
                      [item.type.toLowerCase()]: true,
                    }).toString()}
                    name={name}
                    label={item.description}
                    variant="filled"
                    autoComplete="off"
                    value={values[name]}
                    onChange={handleChange}
                    type="text"
                    size="small"
                    error={Boolean(errors[name] && touched[name])}
                    multiline
                    rows={10}
                  />
                );
              }

              return (
                <TextField
                  key={item.type}
                  className={b('field', {
                    [item.type.toLowerCase()]: true,
                  }).toString()}
                  name={name}
                  label={item.description}
                  variant="filled"
                  autoComplete="off"
                  value={values[item.type]}
                  onChange={handleChange}
                  type="text"
                  size="small"
                  error={Boolean(errors[name] && touched[name])}
                />
              );
            })}

            {isSubmitting ? (
              <p className={b('loading')}>
                <span className={b('progress')}>
                  <CustomProgress type="circular" size={20} />
                </span>
                Идёт проверка
              </p>
            ) : (
              <Button
                type="submit"
                className={classStyledButton.secondarySmall}
                startIcon={
                  <IconSvg
                    svg={IconDictionary.Run}
                    fill="var(--primary-color)"
                  />
                }
              >
                Проверить запрос
              </Button>
            )}

            {children}

            {errorSubmit && (
              <div className={b('error')}>
                <Alert severity="error">{errorSubmit}</Alert>
              </div>
            )}
          </div>
          <div className={b('buttons')}>
            <Button
              className={classButton.primary}
              type="button"
              onClick={handleActionButtonClick(values)}
              disabled={isDisabledCreateButton}
            >
              {isEditMode ? 'Сохранить' : 'Добавить'}
            </Button>
            <Button
              className={classButton.default}
              type="reset"
              onClick={onCancel}
            >
              Отменить
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SourceForm;
