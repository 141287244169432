import { useDispatch } from 'react-redux';
import { VisualType } from '../enums/visual-types';
import { closeDashboardFiltersAction } from '../slices/dashboard/dashboard';
import { WidgetDictionary } from './naming-dictionary/naming-dictionary';
import { DashboardPropertyType } from '../enums/dashboard-properties';

export interface DashboardSettingsDictionaryItemConfig {
  name: DashboardPropertyType;
  description: string;
  visualType: VisualType;
  defaultValue: string | number | boolean;
}

export const useDashboardSettingsDictionary = () => {
  const dispatch = useDispatch();

  const afterChangeShowQuickFiltersPanel = (value: boolean) => {
    value && dispatch(closeDashboardFiltersAction());
  };

  const afterChangeSetting = (settingName: DashboardPropertyType, value: boolean) => {
    switch (settingName) {
      case DashboardPropertyType.showQuickFiltersPanel:
        afterChangeShowQuickFiltersPanel(value);
        break;
      default:
        break;
    }
  };


  return { afterChangeSetting };
};

export const dashboardSettignsDictionary: DashboardSettingsDictionaryItemConfig[] = [
  {
    name: DashboardPropertyType.noSendDataRequest,
    description: 'Не отправлять запрос за данными',
    visualType: VisualType.CHECKBOX,
    defaultValue: false,
  },
  {
    name: DashboardPropertyType.showCacheUpdateTime,
    description: 'Отображать дату обновления',
    visualType: VisualType.CHECKBOX,
    defaultValue: false,
  },
  {
    name: DashboardPropertyType.showQuickFiltersPanel,
    description: 'Отображать панель фильтров',
    visualType: VisualType.CHECKBOX,
    defaultValue: true,
  },
  {
    name: DashboardPropertyType.showFiltersForPanel,
    description: 'Отображать примененные фильтры',
    visualType: VisualType.CHECKBOX,
    defaultValue: true,
  },
  {
    name: DashboardPropertyType.showPrintBorders,
    description: 'Отображать границы печати',
    visualType: VisualType.CHECKBOX,
    defaultValue: true,
  },
  {
    name: DashboardPropertyType.showWidgetHeaders,
    description: `Отображать шапки ${WidgetDictionary.many}`,
    visualType: VisualType.CHECKBOX,
    defaultValue: true,
  },
  {
    name: DashboardPropertyType.isActiveCrossFiltration,
    description: 'Кросс фильтрация',
    visualType: VisualType.CHECKBOX,
    defaultValue: true,
  },
];
