import React from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import block from 'bem-cn';
import * as Yup from 'yup';

import { useClassButton } from '../../../../hooks/useClassButton';

import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import { SelectInput } from '../../../common/special-inputs/inputs/select-input';

import '../common/css/create-source-form.css';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../common/icon-svg/icon-svg';


const RESPONSE_OUTPUT_VALUES = [
  { value: 'application/json', showValue: 'JSON' },
  { value: 'application/xml', showValue: 'XML' }
];

const b = block('create-source-form');

interface Props {
  fieldsData: { type: string; description: string }[];
  initialValues: Record<string, string | boolean>;
  handleFormSubmit: (values: { [key: string]: any }) => Promise<void>;
  errorSubmit: string | null;
  createSource: (values: Record<string, any>) => Promise<void>;
  changeSource: (values: Record<string, any>) => Promise<void>;
  onCancel: () => void;
  isDisabledCreateButton: boolean;
  isEditMode?: boolean;
  itemChangeName: string | null;
  children?: React.ReactNode;
}

const OdataForm: React.FC<Props> = ({
  fieldsData,
  initialValues,
  handleFormSubmit,
  errorSubmit,
  createSource,
  changeSource,
  onCancel,
  isDisabledCreateButton,
  isEditMode,
  itemChangeName,
  children,
}) => {
  const classButton = useClassButton();
  const classStyledButton = useClassButton({ backgroundColor: 'transparent' });

  const startValues = {
    NAME: itemChangeName || '',
    HTTP_METHOD: initialValues.HTTP_METHOD || '',
    PARAMS_MAP: initialValues.PARAMS_MAP || '',
    CONTENT_TYPE: initialValues.CONTENT_TYPE || '',
    REQUEST_BODY: initialValues.REQUEST_BODY || '',
    RESPONSE_OUTPUT: initialValues.RESPONSE_OUTPUT || '',
    ROW_TAG: initialValues.ROW_TAG || '',
  };

  const validationSchema = Yup.object().shape({
    NAME: Yup.string().required('Название не должно быть пустым'),
    HTTP_METHOD: Yup.string().required('Метод не должен быть пустым'),
    RESPONSE_OUTPUT: Yup.string().required('Формат ответа не должен быть пустым'),
  });

  const handleActionButtonClick = (values: Record<string, any>) => () => {
    if (isEditMode) {
      changeSource(values);
      return null;
    }
    createSource(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={startValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        touched,
        isSubmitting,
        values,
      }) => (
        <form className={b()} onSubmit={handleSubmit}>
          <h3 className={b('title')}>
            {isEditMode ? 'Редактирование запроса' : 'Новый запрос'}
          </h3>

          <div className={b('fields')}>
            <TextInput
              name="NAME"
              error={Boolean(errors.NAME) && touched.NAME}
              label="Название"
              className={b('field')}
              value={values.NAME}
              handleChange={handleChange}
            />

            {errors.NAME && <Alert severity="error">{errors.NAME}</Alert>}

            <SelectInput
              formikName="HTTP_METHOD"
              error={Boolean(errors.HTTP_METHOD) && touched.HTTP_METHOD}
              label="Метод GET/POST"
              fullWidth
              value={values.HTTP_METHOD}
              handleChange={handleChange}
              itemList={['GET', 'POST'].map((method) => {
                return { value: method, showValue: method };
              })}
            />

            {errors.HTTP_METHOD && <Alert severity="error">{errors.HTTP_METHOD}</Alert>}

            <SelectInput
              formikName="RESPONSE_OUTPUT"
              error={Boolean(errors.RESPONSE_OUTPUT) && touched.RESPONSE_OUTPUT}
              label="Формат ответа"
              fullWidth
              value={values.RESPONSE_OUTPUT}
              handleChange={handleChange}
              itemList={RESPONSE_OUTPUT_VALUES}
            />

            {errors.RESPONSE_OUTPUT && <Alert severity="error">{errors.RESPONSE_OUTPUT}</Alert>}

            {values.RESPONSE_OUTPUT ===
              'application/xml' && (
              <TextField
                className={b('field')}
                name="ROW_TAG"
                label="Тег"
                autoComplete="off"
                value={values.ROW_TAG}
                onChange={handleChange}
                type="text"
                size="small"
                error={Boolean(errors.ROW_TAG) && touched.ROW_TAG}
                multiline
                rows={5}
                maxRows={10}
              />
            )}

            {values.HTTP_METHOD === 'POST' && (
              <>
                <FormControl fullWidth>
                  <InputLabel id="content-type">MediaType запроса</InputLabel>
                  <Select
                    name="CONTENT_TYPE"
                    labelId="content-type"
                    value={values.CONTENT_TYPE}
                    onChange={handleChange}
                    variant="filled"
                  >
                    <MenuItem value="">пустое значение</MenuItem>
                    <MenuItem value="application/x-www-form-urlencoded">
                      application/x-www-form-urlencoded
                    </MenuItem>
                    <MenuItem value="application/json">
                      application/json
                    </MenuItem>
                  </Select>
                </FormControl>

                {values.CONTENT_TYPE !==
                  'application/x-www-form-urlencoded' && (
                  <TextField
                    className={b('field')}
                    name="REQUEST_BODY"
                    label="Тело запроса"
                    autoComplete="off"
                    value={values.REQUEST_BODY}
                    onChange={handleChange}
                    type="text"
                    size="small"
                    error={Boolean(errors.REQUEST_BODY) && touched.REQUEST_BODY}
                    multiline
                    rows={5}
                  />
                )}
              </>
            )}

            <TextField
              className={b('field')}
              name="PARAMS_MAP"
              label="Параметры запроса"
              autoComplete="off"
              value={values.PARAMS_MAP}
              onChange={handleChange}
              type="text"
              size="small"
              error={Boolean(errors.PARAMS_MAP) && touched.PARAMS_MAP}
              multiline
              rows={5}
            />

            {isSubmitting ? (
              <p className={b('loading')}>
                <span className={b('progress')}>
                  <CustomProgress type="circular" size={20} />
                </span>
                Идёт проверка
              </p>
            ) : (
              <Button
                type="submit"
                className={classStyledButton.secondarySmall}
                startIcon={
                  <IconSvg
                    svg={IconDictionary.Run}
                    fill="var(--primary-color)"
                  />
                }
              >
                Проверить запрос
              </Button>
            )}

            {children}

            {errorSubmit && (
              <div className={b('error')}>
                <Alert severity="error">{errorSubmit}</Alert>
              </div>
            )}
          </div>
          <div className={b('buttons')}>
            <Button
              className={classButton.primary}
              type="button"
              onClick={handleActionButtonClick(values)}
              disabled={isDisabledCreateButton}
            >
              {isEditMode ? 'Сохранить' : 'Добавить'}
            </Button>
            <Button
              className={classButton.default}
              type="reset"
              onClick={onCancel}
            >
              Отменить
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default OdataForm;
