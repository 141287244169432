import block from 'bem-cn';
import React from 'react';
import TablePreview from './table-preview/table-preview';
import { FunctionalPanel } from '../functional-panel/functional-panel';
import { LoaderControls } from '../loader-controls/loader-controls';
import RenderMap from './render-map';
import './style.css';
import { CustomButton } from '../../../uikit/Button';
import { useLoaderMap } from './useLoaderMap';
import { convertData, getTooltipData } from './helper';
import { ReactFlowProvider } from 'react-flow-renderer';

const b = block('map');

export const Map = () => {
  const {
    jsonData,
    loaderId,
    setCurrentOperation,
    loaderData,
    sourceIdList,
    availableInOperationsSourcesList,
    handleClickSource,
    adviceMode,
    onDragStopCallback,
    currentOperation,
    cancelAdviseMode,
    bottomTableLabel,
  } = useLoaderMap();

  return (
    <div className={`${b()} loader-page__map`}>
      <div className={b('top-section')}>
        <LoaderControls />
        <FunctionalPanel
          active={jsonData[loaderId]?.sourceObjects?.length !== 0 ?? false}
          setCurrentOperation={setCurrentOperation}
          loaderData={loaderData}
        />
      </div>
      <div className={b('container')}>
        {jsonData[loaderId] && ( // @ts-ignore
          <ReactFlowProvider>
            <RenderMap
              dataForElements={convertData(
                jsonData[loaderId].sourceObjects,
                jsonData[loaderId].steps,
                sourceIdList,
                availableInOperationsSourcesList,
              )}
              handleClickSource={handleClickSource}
              clickActive={adviceMode}
              onDragStopCallback={onDragStopCallback}
            />
          </ReactFlowProvider>
        )}
        {adviceMode && (
          <div className={b('operation-tooltip')}>
            {getTooltipData(currentOperation)}
            <CustomButton variant="outlined" onClick={cancelAdviseMode} fullWidth>
              Отменить
            </CustomButton>
          </div>
        )}
      </div>
      {Boolean(bottomTableLabel) && <TablePreview />}
    </div>
  );
};
