import React from 'react';
import { merge } from 'lodash';
import { ColorPicker } from '../../../../settings/color-picker';
import { CellColoringGroup } from '../../../../dropdown-layout/helpers/Property';

interface ColorsBlockProps {
  group: CellColoringGroup;
  changeSettingInGroup: (groupIndex: number, group: CellColoringGroup) => void;
  index: number;
}

export const ColorsBlock = ({
  group,
  changeSettingInGroup,
  index,
}: ColorsBlockProps) => {
  const onTextColorChange = (color: string) => {
    changeSettingInGroup(
      index,
      merge(group, {
        conditionColor: color,
      }),
    );
  };
  const onBackgroundColorChange = (color: string) => {
    changeSettingInGroup(
      index,
      merge(group, {
        backgroundColor: color,
      }),
    );
  };

  return (
    <div className="conditional-coloring__colors">
      <div className="conditional-coloring__color-container">
        <ColorPicker
          name="Цвет текста"
          value={group.conditionColor}
          onChangeColor={onTextColorChange}
          isColorWithAlpha
        />
      </div>
      <div className="conditional-coloring__color-container">
        <ColorPicker
          name="Цвет фона"
          value={group.backgroundColor}
          onChangeColor={onBackgroundColorChange}
          isColorWithAlpha
        />
      </div>
    </div>
  );
};
