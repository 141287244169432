import { flatten, sum } from 'lodash';

export const isChartWithPositiveAndNegativeValues = (minValue: number, maxValue: number) => {
  return maxValue * minValue < 0;
};

export const getDomainPointsForScaledHistogram = (keys: string[], data: any) => {
  const values: number[] = flatten(
    data.map((d: any) => flatten(keys.map((key) => Number(d[key])))),
  );

  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);

  const absMinValue = Math.min(
    ...data.map((d: any) => Math.min(...keys.map((key) => Math.abs(Number(d[key]))))),
  );
  const absMaxValue = Math.max(
    ...data.map((d: any) => Math.max(...keys.map((key) => Math.abs(Number(d[key]))))),
  );

  if (isChartWithPositiveAndNegativeValues(minValue, maxValue)) {
    return {
      startDomainPoint: minValue,
      endDomainPoint: maxValue
    };
  }

  if (minValue === maxValue) {
    return {
      startDomainPoint: minValue < 0 ? minValue : 0,
      endDomainPoint: maxValue < 0 ? 0 : maxValue
    };
  }

  const coefficient = minValue < 0 ? -1 : 1;

  const averageValue = sum(values) / values.length;
  const potentialPoint = Math.abs(2 * absMinValue - averageValue);

  const getStart = () => {
    if (potentialPoint > absMinValue) {
      return minValue < 0 ? minValue : 0;
    }
  
    return minValue < 0 ? absMaxValue * coefficient : potentialPoint * coefficient;
  };

  const getEnd = () => {
    if (potentialPoint > absMinValue) {
      return maxValue < 0 ? 0 : maxValue;
    }
  
    return minValue < 0 ?
      potentialPoint * coefficient :
      absMaxValue * coefficient;
  };

  return {
    startDomainPoint: getStart(),
    endDomainPoint: getEnd()
  };
};

export const getDomainPointsForStackedHistogram = (keys: string[], data: any) => {
  const values: number[] = flatten(
    data.map((d: any) => flatten(keys.map((key) => Number(d[key])))),
  );

  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);

  const isAllNegative = values.every((item: any) => item < 0);
  const isHasNegative = values.some((item: any) => item < 0);
  
  
  if (isAllNegative) {
    return {
      startDomainPoint: minValue,
      endDomainPoint: 0,
      isAllNegative,
      isHasNegative
    };
  }

  return {
    startDomainPoint: minValue < 0 ? minValue : 0,
    endDomainPoint: maxValue < 0 ? 0 : maxValue,
    isAllNegative,
    isHasNegative
  };
};
