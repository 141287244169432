import React, { FC, useEffect, useRef, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { useDebounce } from 'use-debounce/lib';
import { makeStyles, Popover } from '@material-ui/core';
import clsx from 'clsx';
import { styles } from './styles';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { rgbaToHex, rgbToHex } from '../../../../utils/colors';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface ColorPickerProps {
  name?: string;
  value: string;
  onChangeColor: (color: string) => void;
  isColorWithAlpha?: boolean;
  isNeedToDisplayValue?: boolean;
  isPossibleResetColor?: boolean;
}

const useStyles = makeStyles({ ...styles });

export const ColorPicker: FC<ColorPickerProps> = ({
  name = '',
  value = '',
  onChangeColor,
  isColorWithAlpha,
  isNeedToDisplayValue = true,
  isPossibleResetColor = true,
}) => {
  const buttonRef = useRef(null);

  const [opened, setOpened] = useState<boolean>(false);
  const [tempColor, setTempColor] = useState<string>(value);
  const classes = useStyles({ color: tempColor });

  const [debouncedColor] = useDebounce<string>(tempColor, 300);

  const handleChangeColor = (color: ColorResult) => {
    const rgbColor = isColorWithAlpha
      ? rgbaToHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a || 0)
      : rgbToHex(color.rgb.r, color.rgb.g, color.rgb.b);
    setTempColor(rgbColor);
  };

  const handleOpenPicker = () => {
    setOpened(true);
  };

  const handleClosePicker = () => {
    setOpened(false);
  };
  useEffect(() => {
    onChangeColor(debouncedColor);
  }, [debouncedColor]);

  useEffect(() => {
    setTempColor(value);
  }, [value]);

  const handleResetColor = (event: any) => {
    setTempColor('');
    event.stopPropagation();
  };

  return (
    <div ref={buttonRef} style={{ width: '100%' }}>
      <button
        type="button"
        className={classes.button}
        onClick={handleOpenPicker}
      >
        <div className={classes.container}>
          <div className={classes.preview} />
          <div
            style={{ display: 'flex', flexDirection: 'column', marginLeft: 9 }}
          >
            <span className={classes.colorTitle}>{name}</span>
            {isNeedToDisplayValue && (
              <span className={classes.color}>{tempColor}</span>
            )}
          </div>
        </div>
        {isPossibleResetColor && (
          <div
            role="button"
            onClick={handleResetColor}
            className={classes.close}
          >
            <IconSvg svg={IconDictionary.Close} width={20} height={20} fill="var(--dark-grey)" />
          </div>
        )}
        <IconSvg
          svg={
            opened
              ? IconDictionary.SelectArrowUp
              : IconDictionary.SelectArrowDown
          }
          width={20}
          height={20}
          fill={
            opened ? 'var(--primary-color)' : 'var(--dark-grey)'
          }
          classSVG={clsx(classes.icon, { opened })}
        />
      </button>
      <div className={classes.pickerContainer}>
        <Popover
          open={opened}
          anchorEl={buttonRef.current}
          onClose={handleClosePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ChromePicker color={tempColor} onChange={handleChangeColor} />
        </Popover>
      </div>
    </div>
  );
};
