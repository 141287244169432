import { Box, ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CssVariables } from '../../../../../../enums/css-variables';
import { getScaleCoefficient, varcss } from '../../../../../../helpers/ui-helper';
import CustomTooltip from '../../../../../../uikit/CustomTooltip';
import { SelectInput } from '../../../../../common/special-inputs/inputs/select-input';
import { isActive } from '../../../../dropdown-layout/helpers/Property';
import { ColumnAndRowSorting } from './column-and-row-sorting';
import { ColumnAndRowFiltering } from './column-and-row-filtering';
import { HeadButtonCellProps } from '../../interfaces';
import { minColumnWidth, minNotAdaptiveColumnWidth } from '../../settings';
import { useHeadButtonCell } from './useHeadButtonCell';
import { CustomPopover } from '../../../../../../uikit/Popover/popover';
import {
  CustomPanelHead,
  CustomPanelHeadType,
} from '../../../../../../uikit/PanelHead';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { isNeedToAdaptiveColumns, isNeedToDisplayTitle } from '../../helper';

export const HeadButtonCell = ({
  panelType,
  widgetProperties,
  widgetId,
  setDashboardWidgetState,
  style,
  isDrillDown,
}: HeadButtonCellProps) => {
  const {
    headBackgroundColor,
    headTextColor,
    openedActions,
    anchorRef,
    buttonTitle,
    isMultiline,
    closeActions,
    popoverPosition,
    currentFieldId,
    onChangeField,
    axisValues,
    onChangeSorting,
    openActions,
    isNeedToDisplayFilters,
    sortingValue,
    isNeedToDisplaySorting,
    isDisabledSettings,
    headerSize,
    scaleWidget,
    columnWidthType,
  } = useHeadButtonCell({
    panelType,
    widgetProperties,
    widgetId,
    setDashboardWidgetState,
    style,
    isDrillDown,
  });

  return (
    <div
      className={clsx('pivot-header__cell-button', {
        'pivot-header__coloring-cell': headBackgroundColor.length,
      })}
      style={{
        ...style,
        background: headBackgroundColor,
        color: headTextColor,
        minWidth: isNeedToAdaptiveColumns(scaleWidget, columnWidthType)
          ? minColumnWidth
          : minNotAdaptiveColumnWidth,
        fontSize: headerSize * getScaleCoefficient(),
      }}
    >
      <ButtonBase
        className={clsx('pivot-header__cell-button_root', {
          'pivot-header__cell-button_opened': openedActions,
        })}
        ref={anchorRef}
        onClick={openActions}
      >
        {isNeedToDisplayTitle(style.width as number) && (
          <CustomTooltip title={buttonTitle} arrow placement="top">
            <div
              className={clsx({
                'pivot-header__cell-button_multiline': isMultiline,
                'pivot-header__cell-button_title': !isMultiline,
              })}
            >
              {buttonTitle}
            </div>
          </CustomTooltip>
        )}

        <Box
          ml="10px"
          className={clsx({
            'pivot-header__cell-button_arrow-down': !openedActions,
            'pivot-header__cell-button_arrow-up': openedActions,
          })}
        >
          <IconSvg
            svg={
              openedActions
                ? IconDictionary.SelectArrowUp
                : IconDictionary.SelectArrowDown
            }
            fill={openedActions ? 'var(--primary-color)' : 'var(--grey)'}
          />
        </Box>
      </ButtonBase>
      <CustomPopover
        open={openedActions}
        onClose={closeActions}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: popoverPosition.top,
          left: popoverPosition.left,
        }}
        backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
      >
        <div>
          <Box className="pivot-header__filters-wrap">
            <CustomPanelHead
              onClose={closeActions}
              type={CustomPanelHeadType.withoutBorder}
            />
            <Box mt="8px">
              <Typography
                className="pivot-header__filters-wrap-title"
                variant="h3"
              >
                Выберите столбец
              </Typography>
              <SelectInput
                className="pivot-header__select-container"
                label="Поле"
                value={currentFieldId}
                handleChange={onChangeField}
                itemList={axisValues.filter(isActive).map((item) => {
                  return {
                    value: item.etlFieldId,
                    showValue:
                      JSON.parse(item.storage || '{}').alias || item.name,
                  };
                })}
              />
            </Box>
            {isNeedToDisplayFilters && (
              <Box className="pivot-header__select-container pivot-header__select-container--absolute-btn">
                <ColumnAndRowFiltering
                  widgetProperties={widgetProperties}
                  widgetId={widgetId}
                  panelType={panelType}
                  currentFieldId={currentFieldId}
                  setDashboardWidgetState={setDashboardWidgetState}
                  isDrillDown={isDrillDown}
                  closeFilterPanel={closeActions}
                />
              </Box>
            )}
            {isNeedToDisplaySorting && (
              <ColumnAndRowSorting
                axisValue={sortingValue}
                onChangeSorting={onChangeSorting}
              />
            )}
            {isDisabledSettings && (
              <div className="pivot-header__no-setting">
                Выбранный столбец не может быть настроен
              </div>
            )}
          </Box>
        </div>
      </CustomPopover>
    </div>
  );
};
