import { useDebouncedCallback } from 'use-debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { scaleLinear, scalePoint } from '@visx/scale';
import { isNaN } from 'lodash';
import { WidgetProperties } from '../../../../../slices/types';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import { getX, getY, GraphData } from '../helper';
import { useGraphProperties } from './useGraphProperties';
import {
  getLegendsLabelsForWidgetByAxis,
  sortColorPalette,
} from '../../helpers';
import { PanelType } from '../../../../../enums/widget-type';
import { Color, defaultColors } from '../../common/color';
import {
  circleParams,
  initialBottomAxisSettingsExtendedHeight,
  initialBottomAxisSettingsHeight,
  leftAxisSettings,
  minDistanceBetweenTooltips,
  minWidthCustomAxisYLabel,
  stageSettings,
  yRangeOffset,
} from '../settings';
import { textDirections } from '../../../../common/scalable-svg-text';
import { useActiveLegend } from '../../hooks/useActiveLegend';
import { useEventListener } from '../../../../../hooks/useEventListener';
import { useTooltipsPosition } from './useTooltipsPositions';
import { useSynchronizeWidgetScrollListener } from '../../../../../hooks/useSynchronizeWidgetScrollListener';
import { FilterField, SetFilterField } from '../../../../dashboard-page/hooks';
import { useRoundingCounts } from '../../../../../hooks/useRoundingCounts';
import { getGraphFilteredValues } from '../../../helpers';
import { Property } from '../../../dropdown-layout/helpers/Property';
import { getSimplifiedType } from '../../../dropdown-layout/helpers/helpers';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';
import { useWidgetFullScreen } from '../../../../../hooks/charts/useWidgetFullScreen';

interface UseGraphProps {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  domain: any;
}

const correctiveShiftBottomAxisSettingsHeight = 20;

export const useGraph = ({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  domain,
}: UseGraphProps) => {
  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  let currentColors: Color[] = defaultColors;

  const widgetProperties: WidgetProperties[] = widgetProps.properties;

  const enableEvents: boolean = isActiveFilter;
;
  const roundingCount = useRoundingCounts(widgetProperties);
  const valueFormat = initValueFormatter({ roundingCount })

  const widgetData: GraphData[] = widgetProps.data;

  const {
    axisXValues,
    isNeedDisplayAggregationInLegend,
    isScaleByValueState,
    customAxisXWidth,
    customAxisXHeight,
    colorsPaletteState,
    textDirection,
    customAxisYLabelWidth,
    isNeedAxisXDateLabelAggregation,
    dateLabelAggregation,
    typeFillGraph,
    formatByNumber,
    scaleByNumber,
  } = useGraphProperties({ widgetProperties });

  const isNeedToDisplayArea = typeFillGraph !== 'Нет';

  const legendsLabels = getLegendsLabelsForWidgetByAxis(
    PanelType.axisY,
    widgetProperties,
    isNeedDisplayAggregationInLegend,
  );

  currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || currentColors;

  const currentBottomAxisSettingsHeight = (customAxisXHeight || 0 ) + correctiveShiftBottomAxisSettingsHeight;

  const { changeColorsActiveLegend, colorsActiveLegend } = useActiveLegend(
    widgetProperties,
    widgetProps.id,
    currentColors,
  );

  useEffect(() => {
    changeColorsActiveLegend();
  }, [JSON.stringify(currentColors), widgetData]);

  const axisYLabelWidth = isNaN(customAxisYLabelWidth)
    ? leftAxisSettings.left
    : Math.max(customAxisYLabelWidth, minWidthCustomAxisYLabel);

  const containerParams = {
    minWidthStep: customAxisXWidth || 130,
    minHeight: 250,
    sidesOffset: axisYLabelWidth
      + (textDirection === textDirections.diagonal ? customAxisXWidth : customAxisXWidth / 2)
      + stageSettings.left * 2,
  };

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const xScale = useMemo(
    () =>
      scalePoint<number>({
        range: [0, width - containerParams.sidesOffset],
        domain: widgetData?.map(getX),
      }),
    [widgetData, width, isScaleByValueState],
  );

  const flattenY = useMemo(() => {
    return widgetData.map((data) => data.y).flat();
  }, [widgetData]);

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        zero: !isScaleByValueState,
        nice: true,
        range: [height - (currentBottomAxisSettingsHeight + yRangeOffset) - leftAxisSettings.topOffset, 0],
        domain: domain || [Math.min(...flattenY), Math.max(...flattenY)],
      }),
    [
      flattenY,
      height,
      isScaleByValueState,
      domain,
      currentBottomAxisSettingsHeight,
    ],
  );

  const filteredAxisDataValues = useMemo(
    () => {
      if (getSimplifiedType(axisXValues?.[0]?.type) ===  WidgetSimplifiedDataType.DATE) {
        return getGraphFilteredValues(
          isNeedAxisXDateLabelAggregation, widgetData, dateLabelAggregation
        );
      }
      return widgetData;
    },
    [axisXValues, widgetData, isNeedAxisXDateLabelAggregation, dateLabelAggregation],
  );

  const containerRef = useRef(document.createElement('div'));
  const viewportRef = useRef(document.createElement('div'));

  const debounceDelay = 100;

  const setDebouncedHeight = useDebouncedCallback((value: number) => {
    setHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value: number) => {
    setWidth(value);
  }, debounceDelay);

  const calculateDimensions = useCallback(() => {
    if (!containerRef.current) return;

    const minWidth = widgetData.length * containerParams.minWidthStep;
    const { minHeight } = containerParams;

    const { width: containerWidth, height: containerHeight } =
      containerRef.current.getBoundingClientRect();

    const currentWidth = minWidth > containerWidth ? minWidth : containerWidth;
    const currentHeight =
      minHeight > containerHeight ? minHeight : containerHeight;

    if (currentWidth !== width) setDebouncedWidth.callback(currentWidth);
    setDebouncedHeight.callback(currentHeight);
  }, [
    widgetData.length,
    containerParams,
    width,
    setDebouncedHeight,
    setDebouncedWidth,
  ]);

  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
  }, [calculateDimensions, isFullScreen]);

  const isVisible = width > circleParams.radius;

  const graphsCount = widgetData[0].y.length;
  const graphsMapper = useMemo(
    () => new Array(graphsCount).fill(''),
    [graphsCount],
  );

  const tooltipsPositions = useTooltipsPosition({
    graphsMapper,
    data: widgetData,
    xScale,
    yScale,
    getX,
    getY,
    minDistanceBetweenTooltips,
    isNeedMove: true,
  });

  const deselectPoint = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const selectPoint = useCallback(
    (lineIndex: number, index: number) => {
      if (!enableEvents) return;

      if (filterField?.value?.includes(widgetData[index].x)) {
        deselectPoint();
      } else {
        if (!setFilterField) return;

        setFilterField(widgetProps.id, new Property(axisXValues[0]).getId(), [
          {
            operation: '=',
            value: [widgetData[index].x],
          },
        ]);
      }
    },
    [
      axisXValues,
      widgetData,
      deselectPoint,
      enableEvents,
      filterField,
      setFilterField,
      widgetProps.id,
    ],
  );

  useSynchronizeWidgetScrollListener(containerRef, widgetProps.id);

  const isGraphWithNegativeValues = flattenY.some((value: number | null) => value && value < 0);

  return {
    viewportRef,
    deselectPoint,
    height,
    width,
    containerRef,
    widgetData,
    axisYLabelWidth,
    yScale,
    xScale,
    currentBottomAxisSettingsHeight,
    containerParams,
    isVisible,
    tooltipsPositions,
    valueFormat,
    graphsMapper,
    legendsLabels,
    colorsActiveLegend,
    selectPoint,
    filteredAxisDataValues,
    isGraphWithNegativeValues,
    isScaleByValueState,
    isNeedToDisplayArea,
  };
};
