import React from 'react';
import * as allCurves from '@visx/curve';
import { Area } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { useSelector } from 'react-redux';
import { getAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { WidgetPropertyType } from '../../../../../enums/widget-type';
import { State } from '../../../../../slices/types';

interface AreaPathProps {
  color: string;
  getX: (i: any) => number;
  getY: (i: any) => number;
  data: any;
  isSmoothed: boolean;
  widgetProperties: any[];
  widgetId: number;
  areaProps: any;
  isScaleByValueState: boolean;
  y1: number;
  isGraphWithNegativeValues: boolean;
}

export const AreaPathRenderer = ({
  color,
  getX,
  getY,
  data,
  isSmoothed,
  widgetProperties,
  widgetId,
  areaProps,
  isScaleByValueState,
  y1,
  isGraphWithNegativeValues,
}: AreaPathProps) => {
  const typeFillGraph = getAxisValues(
    WidgetPropertyType.typeFillGraph,
    widgetProperties,
  );

  const isGradientFill = typeFillGraph === 'Градиентная';
  const gradientFillOpacity = 0.3;

  const isWidgetFullScreen = useSelector(
    (state: State) => state.mainPage.isWidgetFullScreen,
  );

  const currentWidgetIdFullScreen = useSelector(
    (state: State) => state.dashboard.currentWidgetIdFullScreen,
  );

  const isFullScreen =
    currentWidgetIdFullScreen === widgetId ? '1'
      : isWidgetFullScreen ? '1' : '0';

  const gradientId = `${widgetId}-${areaProps.index}-${isFullScreen}-gradient`;

  return (
    <>
      <LinearGradient
        id={gradientId}
        from={color}
        to="var(--white)"
        vertical={true}
      />
      <Area
        curve={isSmoothed ? allCurves.curveMonotoneX : allCurves.curveLinear}
        fill={isGradientFill ? `url(#${gradientId})` : color}
        x={getX}
        y={getY}
        y1={() => isScaleByValueState && !isGraphWithNegativeValues ? y1 - 10 : getY({ y: [0] })}
        data={data}
        stroke={color}
        fillOpacity={isGradientFill ? gradientFillOpacity : 1}
        fillRule="evenodd"
      />
    </>
  );
};
