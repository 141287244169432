import React, { FC } from 'react';
import { useDroppable } from '@dnd-kit/core';

export const DroppableHeader: FC<any> = ({ children, id, disabled }) => {
  const { setNodeRef } = useDroppable({
    id: `${id}`,
    disabled,
    data: {
      id,
    },
  });

  const style = {
    height: '100%',
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};
