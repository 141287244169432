import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton } from '../../../uikit/Button';
import Connection from '../../../types/connection';
import {
  connectionsActions,
  connectionsSelectors,
} from '../../../slices/connections/connections';
import IconSvg from '../../common/icon-svg/icon-svg';
import './connections.css';
import { State } from '../../../slices/types';
import { UploadFileDialog } from '../../common/upload-file-dialog';
import { useConnectionMetaList } from '../../../hooks/useConnectionMetaList';
import { CustomProgress } from '../../../uikit/Progress';
import { RightPanel } from './right-panel';
import { ConnectionsList } from './connections-list';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

export enum RightPanelMode {
  FTP = 'FTP',
  LOCAL = 'LOCAL',
  SOURCE = 'SOURCE',
  SOAP = 'SOAP',
  ODATA = 'ODATA',
  HDFS = 'HDFS',
  CREATE_CONNECTION = 'CREATE_CONNECTION',
  EDIT_CONNECTION = 'EDIT_CONNECTION',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS'
}

const Connections = () => {
  const dispatch = useDispatch();

  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;
  const groupId: number = useSelector(
    (state: State) => state.mapConnection[loaderId]?.loaderGroupId,
  );
  const connections = useSelector(connectionsSelectors.selectFullConnections);
  const isLoading = useSelector(connectionsSelectors.selectConnectionsLoading);

  const [isOpenRightPanel, setOpenRightPanel] = useState<boolean>(false);
  const [rightPanelMode, setRightPanelMode] = useState<RightPanelMode | null>(
    null,
  );
  const [activeItem, setActiveItem] = useState<string | number | null>(null);
  const [connection, setConnection] = useState<Connection.View.ItemFull | null>(
    null,
  );
  const [isDialogUploadFileOpen, setDialogUploadFileOpen] =
    useState<boolean>(false);

  const { data, loading } = useConnectionMetaList();

  useEffect(() => {
    loaderId && dispatch(connectionsActions.getFullConnectionsAction(groupId));
  }, [dispatch, loaderId, groupId]);

  const handleRightPanelOpen = () => {
    setOpenRightPanel(true);
  };

  const handleRightPanelClose = useCallback(() => {
    setOpenRightPanel(false);
    setActiveItem(null);
    setConnection(null);
  }, []);

  const handleCreateConnectionClick = () => {
    setActiveItem('button');
    handleRightPanelOpen();
    setRightPanelMode(RightPanelMode.CREATE_CONNECTION);
  };

  const handleConnectionClick = useCallback(
    (item: Connection.View.ItemFull) => {
      setActiveItem(item.id);
      setConnection(item);
      handleRightPanelOpen();
      setRightPanelMode(item.sourceType as RightPanelMode);
    },
    [],
  );

  const onEditConnectionClick = useCallback(
    (item: Connection.View.ItemFull) => {
      setConnection(item);
      setRightPanelMode(RightPanelMode.EDIT_CONNECTION);
      handleRightPanelOpen();
    },
    [],
  );

  return (
    <>
      {isLoading && (
        <div className="loader-container">
          <CustomProgress type="circular" />
        </div>
      )}
      {!isLoading && Object.keys(connections).length === 0 && (
        <div className="connections__container-empty">
          <IconSvg
            svg={IconDictionary.NoData}
            width={50}
            height={50}
            fill="var(--dark-grey)"
          />
          <h2 className="connections__container-empty__title">
            Нет подключений
          </h2>
          <p className="connections__container-empty__text">
            Чтобы добавить объекты на карту загрузки, создайте новое соединение
          </p>
        </div>
      )}

      {!isLoading && (
        <div className="connections__actions">
          {!connections[RightPanelMode.LOCAL] && (
            <CustomButton
              variant="outlined"
              onClick={() => setDialogUploadFileOpen(true)}
              fullWidth
              className="connections__block"
            >
              Загрузить файл
            </CustomButton>
          )}
          <CustomButton
            variant="outlined"
            onClick={handleCreateConnectionClick}
            fullWidth
            className="connections__block"
          >
            Создать соединение
          </CustomButton>
        </div>
      )}

      {!isLoading && Object.keys(connections).length > 0 && (
        <ConnectionsList
          connections={connections}
          data={data}
          activeItem={activeItem}
          handleConnectionClick={handleConnectionClick}
          onEditConnectionClick={onEditConnectionClick}
        />
      )}

      {isOpenRightPanel && (
        <RightPanel
          rightPanelMode={rightPanelMode}
          data={data}
          loading={loading}
          onClose={handleRightPanelClose}
          connection={connection}
          groupId={groupId}
        />
      )}

      <UploadFileDialog
        isOpen={isDialogUploadFileOpen}
        handleClose={() => setDialogUploadFileOpen(false)}
        groupId={groupId}
      />
    </>
  );
};

export default Connections;
