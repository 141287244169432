import { getScaleCoefficient } from '../../../../helpers/ui-helper';


export enum SortingVariants {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const openIconColor = 'var(--title-color)';
export const closeIconColor = 'var(--grey)';

export const cellHeight = 30 * getScaleCoefficient();
export const cellPadding = 5 * getScaleCoefficient();
export const cellHeaderHeight = 40 * getScaleCoefficient();
export const cellBorderWidth = 1;

export const minColumnWidth = 100 * getScaleCoefficient();
export const minNotAdaptiveColumnWidth = 30 * getScaleCoefficient();

export const defaultColumnsWidths = {
  title: minColumnWidth * getScaleCoefficient(),
  total: minColumnWidth * getScaleCoefficient(),
  nestedColumn: minColumnWidth * getScaleCoefficient(),
};

export const nestedRowOffset = 10 * getScaleCoefficient();

export const scrollOffset = 10 * getScaleCoefficient();
