import React from 'react';
import './panel-info-item.css';

interface PanelInfoItemProps {
  name: string;
}

export const PanelInfoItem = ({ name }: PanelInfoItemProps) => {
  return (
    <div>
      <div className="widget-dropdown__item panel-info-item__container">
        <div className="panel-item__value">
          <span>{name}</span>
        </div>
      </div>
    </div>
  );
};
