export enum StatusName {
  ERROR = 'ERROR',
  DONE = 'DONE',
  NEW = 'NEW',
}

declare namespace History {
  interface Milestone {
    status: {
      name: StatusName;
      description: string;
    };
    startLoad: number;
    endLoad: number;
    errorMessage: string;
    name: string;
    description: string;
    statistics: {};
  }

  interface element {
    status: {
      name: StatusName;
      description: string;
    };
    errorMessage: string;
    startLoad: number;
    endLoad: number;
    loadMilestones: Milestone[];
  }
}

export default History;
