import React, { Fragment } from 'react';
import clsx from 'clsx';
import { CssVariables } from '../../../../../enums/css-variables';
import { WidgetType } from '../../../../../enums/widget-type';
import { varcss } from '../../../../../helpers/ui-helper';
import { SetFilterField, SetMultipleFilterFields } from '../../../../dashboard-page/hooks';
import { Bookmark } from '../useBookmarks';
import { CustomPopover } from '../../../../../uikit/Popover/popover';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { useBookmarkPopover } from './useBookmarkPopover';
import { WidgetProperties } from '../../../../../slices/types';
import { CustomButton } from '../../../../../uikit/Button';
import { getPanelItemName } from '../../../../../helpers/common-helpers';
import './index.css';
import {
  CustomPanelHead,
  CustomPanelHeadType,
} from '../../../../../uikit/PanelHead';

export interface BookmarkPopoverProps {
  bookmarkList: Bookmark[];
  anchorEl: Element | null;
  handleClickClose: () => void;
  widgetProperties: WidgetProperties[];
  widgetId: number;
  widgetType: WidgetType;
  setOverriddenProperties?: (newProperties: WidgetProperties[]) => void;
  setFilterField?: SetFilterField;
  setMultipleFilterFields?: SetMultipleFilterFields;
  isActiveFilter?: number | false | undefined;
}

export const BookmarkPopover = ({
  bookmarkList,
  anchorEl,
  handleClickClose,
  widgetProperties,
  widgetId,
  widgetType,
  setOverriddenProperties,
  setFilterField,
  setMultipleFilterFields,
  isActiveFilter,
}: BookmarkPopoverProps) => {
  const {
    axisValues,
    handleClickChange,
    activeElements,
    validationAxesErrorsDescription,
    isDisabledButton,
    handleClickSave,
  } = useBookmarkPopover({
    bookmarkList,
    anchorEl,
    handleClickClose,
    widgetProperties,
    widgetId,
    widgetType,
    setOverriddenProperties,
    setFilterField,
    setMultipleFilterFields,
    isActiveFilter,
  });

  const haveMultiple = bookmarkList.some((bookmark) => bookmark.multiple);

  return (
    <CustomPopover
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClickClose}
      anchorEl={anchorEl}
      backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
    >
      <div className="bookmark-setting">
        <CustomPanelHead
          onClose={handleClickClose}
          type={CustomPanelHeadType.popup}
          title={
            haveMultiple ? 'Настройка показателей' : 'Выбор показателя'
          }
        />
        <div
          className="bookmark-setting__body"
          style={{
            paddingBottom: haveMultiple
              ? undefined
              : 'calc(4px * var(--scale-coefficient))',
          }}
        >
          {bookmarkList.map((bookmark) => (

            <Fragment key={bookmark.axis}>
              {bookmarkList.length > 1 && axisValues[bookmark.axis].length > 0 && (
                <div
                  className="bookmark-setting__title"
                >
                  <span>{bookmark.name}</span>
                </div>)}
              <div
                key={bookmark.axis}
                className="bookmark-setting__list"
              >
                {axisValues[bookmark.axis].map((item) => {
                  const isSelected = activeElements[bookmark.axis].includes(item.id || item.widgetFieldId || 0);

                  const handleClick = bookmark.multiple
                    ? () => handleClickChange(bookmark.axis, item.id || item.widgetFieldId || 0)
                    : () => !isSelected && handleClickChange(bookmark.axis, item.id || item.widgetFieldId || 0);
                  return (
                    <div
                      key={item.name}
                      className={clsx('bookmark-setting__list-item', {
                        'bookmark-setting__list-item_single': !bookmark.multiple,
                        'bookmark-setting__list-item_selected':
                          !bookmark.multiple && isSelected,
                      })}
                      onClick={handleClick}
                    >
                      <CustomFormControl
                        control={
                          bookmark.multiple ? (
                            <CustomCheckbox
                              checked={isSelected}
                            />
                          ) : (
                            <></>
                          )
                        }
                        label={getPanelItemName(item)}
                      />
                    </div>
                  );
                })}
              </div>
              {validationAxesErrorsDescription[bookmark.axis].map((error) => (
                <div
                  key={error}
                  className="bookmark-setting__error-description"
                >
                  <span>{error}</span>
                </div>))}
            </Fragment>
          ))}
          {haveMultiple && (
            <div
              style={{ marginTop: 'calc(16px * var(--scale-coefficient))' }}
            >
              <CustomButton
                onClick={() => handleClickSave()}
                variant="contained"
                fullWidth
                disabled={isDisabledButton}
              >
                Применить
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    </CustomPopover>
  );
};
