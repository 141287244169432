import React, { FC } from 'react';

import { Backdrop, Dialog } from '@material-ui/core';

export interface BackdropFullscreenProps {
  open: boolean;
}

export const BackdropFullscreen: FC<BackdropFullscreenProps> = ({ open }) => {
  return (
    <Dialog open={open}>
      <Backdrop open />
    </Dialog>
  );
};
