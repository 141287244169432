import { useMemo, useRef } from 'react';

export const useGetProperty = <N, P extends { name: string, value: string }, D,>(
  name: N,
  properties: P[],
  defaultValue?: D,
) => {

  const valuesNotSortedJSON: string | undefined = properties.find((prop) => prop.name === name)?.value;

  const defaultValueRef = useRef(defaultValue);

  const values: unknown = useMemo(
    () => {
      if (valuesNotSortedJSON === undefined) return defaultValueRef.current;
      try {
        return JSON.parse(valuesNotSortedJSON);
      } catch {
        return valuesNotSortedJSON;
      }
    },
    [valuesNotSortedJSON],
  );

  return values;
};
