import { Button, CircularProgress } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import block from 'bem-cn';
import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useClassButton } from '../../../hooks/useClassButton';
import Connection from '../../../types/connection';
import ConnectionForm from '../connection-form/connection-form';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import './connection-type-list.css';
import { CustomProgress } from '../../../uikit/Progress';


const buttonStyle: CSSProperties = {
  width: '100%',
  marginBottom: 'calc(18px * var(--scale-coefficient))',

  '&:last-child': {
    marginBottom: 0,
  },
};

const activeButtonStyle: CSSProperties = {
  background: 'url(.png), #EDF1F8","border":"2px solid var(--primary-color)',
  boxSizing: 'border-box',
  boxShadow:
    '0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06)',
  borderRadius: '5px',
  border: '2px solid var(--primary-color)',
};

const b = block('connection-type-list');

interface Props {
  className?: string;
  onClosePanel: () => void;
  data: Connection.Meta[];
  isLoading: boolean;
}

const ConnectionTypeList: React.FC<Props> = ({
  className = '',
  onClosePanel,
  data,
  isLoading,
}) => {
  const [filter, setFilter] = useState<string>('');
  const [connectionTypeId, setConnectionTypeId] = useState<number | null>();

  const classButton = useClassButton(buttonStyle);
  const classActiveButton = useClassButton(activeButtonStyle);

  const handlerChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => setFilter(target.value.toLowerCase()),
    [],
  );

  const handlerClickSource = (id: number) => () => {
    setConnectionTypeId(id);
  };

  const filteredSourceList = useMemo<Connection.Meta[]>(
    () =>
      data.filter(
        ({ name }) =>
          filter === '' || name.toLowerCase().indexOf(filter) !== -1,
      ),
    [filter, data],
  );

  return (
    <div className={`${b()} ${className}`}>
      <h3 className={b('title')}>Выберите источник данных</h3>
      <TextInput
        label="Быстрый поиск"
        handleChange={handlerChangeFilter}
        searchIcon
        className="connection-type-list__input"
      />

      <div className="connection-type-list__options">
        {isLoading ? (
          <CustomProgress type="circular" />
        ) : (
          filteredSourceList.map(({ id, name }) => (
            <Button
              key={id}
              className={`${classButton.scheme} ${
                connectionTypeId === id && classActiveButton.scheme
              }`}
              onClick={handlerClickSource(id)}
            >
              {name}
            </Button>
          ))
        )}
      </div>

      {connectionTypeId && (
        <ConnectionForm
          className={b('connection-form')}
          connectionTypeId={connectionTypeId}
          onCancel={() => setConnectionTypeId(null)}
          onClosePanel={onClosePanel}
        />
      )}
    </div>
  );
};

export default ConnectionTypeList;
