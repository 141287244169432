import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flatten, isEqual, isNull, isNumber, sum } from 'lodash';
import { useDebouncedCallback } from 'use-debounce/lib';
import { getScaleCoefficient } from '../../../../../helpers/ui-helper';
import { PivotTableSettingsProps } from '../interfaces';
import {
  getAdaptiveColumnsWidth,
  getCellIndexes,
  getColumnsCount,
  getColumnsIds,
  getDefaultClosedColumns, getNotAdaptiveColumnsWidth, isNeedToAdaptiveColumns,
} from '../helper';
import {
  cellBorderWidth,
  cellHeaderHeight,
  defaultColumnsWidths,
  scrollOffset,
} from '../settings';
import { usePivotTableProperties } from './usePivotTableProperties';
import { useEventListener } from '../../../../../hooks/useEventListener';
import { useWidgetFullScreen } from '../../../../../hooks/charts/useWidgetFullScreen';

export const usePivotTableSettings = ({
  closedColumns,
  setClosedColumns,
  widgetData,
  widgetProperties,
  clearTableCash,
  clearTableBodyCashForRows,
  widgetId,
}: PivotTableSettingsProps) => {
  const {
    isNeedToDisplayMiddleTotal,
    isNeedToDisplayTotal,
    isNeedToDisplayTooltip,
    axisXValues,
    axisYValues,
    axisZValues,
    firstColumnWidth,
    dataColumnWidth,
    resultColumnWidth,
    offsetColumnWidth,
    headerSize,
    scaleWidget,
    columnWidthType,
    isPivotRowsCollapsed,
  } = usePivotTableProperties({ widgetProperties });

  const [closedRows, setClosedRows] = useState<number[]>(
    isPivotRowsCollapsed ? widgetData.rows.map((row) => row.id) : [],
  );

  const isPivotWithColumns = useMemo(
    () => axisZValues.length > 0,
    [axisZValues],
  );

  const columnsCount = useMemo(
    () =>
      getColumnsCount(
        widgetData.header,
        isNeedToDisplayMiddleTotal,
        isNeedToDisplayTotal,
        axisYValues,
        isPivotWithColumns,
        closedColumns
      ),
    [
      isNeedToDisplayMiddleTotal,
      isNeedToDisplayTotal,
      widgetData.header,
      axisYValues,
      isPivotWithColumns,
      closedColumns,
    ],
  );

  useEffect(() => {
    clearTableCash();
  }, [axisXValues, axisYValues, axisZValues, columnsCount]);

  const isTableWithMultipleValues = axisYValues.length > 1;

  const getColumnsCountForBody = () => {
    if (!columnsCount.length) return 0;

    const isTableWithTwoColumnsLevels = axisZValues.length === 2;

    const isNeedAddTotalColumn =
      isTableWithTwoColumnsLevels &&
      !isTableWithMultipleValues &&
      isNeedToDisplayTotal;

    return (
      columnsCount[columnsCount.length - 1].length +
      (isNeedAddTotalColumn ? 1 : 0)
    );
  };

  const mainColumnsCount = getColumnsCountForBody();

  const headerCellsCount =
    flatten(columnsCount).length + (isPivotWithColumns ? 1 : 0);

  const cellHeaderHeightWithContent = useMemo(
    () => (headerSize * getScaleCoefficient()) + cellHeaderHeight,
    [headerSize],
  );

  const headerHeight = useMemo(
    () => {
      return isPivotWithColumns
        ? cellHeaderHeightWithContent *
            ((isTableWithMultipleValues ? 2 : 1) + axisZValues.length) -
          cellBorderWidth * (widgetData.header.length + 1)
        : cellHeaderHeightWithContent;
    },
    [axisZValues.length, cellHeaderHeightWithContent, isPivotWithColumns, isTableWithMultipleValues, widgetData.header.length],
  );

  const columnsIds = useMemo(
    () =>
      getColumnsIds(
        isTableWithMultipleValues,
        widgetData.header,
        isNeedToDisplayMiddleTotal,
        isNeedToDisplayTotal,
        closedColumns
      ),
    [
      axisXValues,
      axisZValues,
      axisYValues,
      isNeedToDisplayMiddleTotal,
      isNeedToDisplayTotal,
      widgetData.header,
      closedColumns
    ],
  );

  const isFullScreen = useWidgetFullScreen(widgetId);

  const containerRef = useRef(document.createElement('div'));
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.clientWidth !== containerWidth &&
        setContainerWidth(containerRef.current?.clientWidth || 0);
    });
  }, [isFullScreen]);

  useEffect(() => {
    setClosedColumns(getDefaultClosedColumns(widgetData.header, axisZValues.length));
  }, [axisZValues.length]);

  const debounceDelay = 100;

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setContainerWidth(value);
  }, debounceDelay);

  const calculateDimensions = useCallback(() => {
    setTimeout(() =>
      setDebouncedWidth.callback(containerRef.current?.clientWidth || 0),
    );
  }, [containerRef.current?.clientWidth, setDebouncedWidth]);

  useEventListener('resize', () => calculateDimensions());

  const [columnsWidth, setColumnsWidth] = useState(defaultColumnsWidths);

  useEffect(() => {
    if (containerWidth > 0) {
      const isDisableAdaptive = !isNeedToAdaptiveColumns(scaleWidget, columnWidthType);

      const sumColumnsCount =
        sum(columnsCount[columnsCount.length - 1]) +
        ((axisYValues.length === 1 && axisZValues.length === 2 && isNeedToDisplayTotal) ? 1 : 0);

      const columnsWidthValues = {
        title: parseInt(firstColumnWidth),
        total: parseInt(resultColumnWidth),
        nestedColumn: parseInt(dataColumnWidth),
      };

      const newColumnsWidth = isDisableAdaptive
        ? getNotAdaptiveColumnsWidth(columnsWidthValues)
        : getAdaptiveColumnsWidth(
            columnsWidthValues,
            containerWidth - scrollOffset,
            isNeedToDisplayTotal && axisZValues.length > 0,
            sumColumnsCount,
          );

      if (!isEqual(newColumnsWidth, columnsWidth)) {
        setColumnsWidth(newColumnsWidth);
        clearTableCash();
      }
    }
  }, [
    columnsCount,
    isNeedToDisplayTotal,
    containerWidth,
    firstColumnWidth,
    resultColumnWidth,
    dataColumnWidth,
    closedColumns,
    scaleWidget,
    columnWidthType,
  ]);

  const getSettingCellSizeAndPosition = () => {
    const lastHeaderRowCellsCount =
      sum(columnsCount[columnsCount.length - 1]) -
      (isNeedToDisplayTotal &&
      (axisYValues.length !== 1 || axisZValues.length !== 2)
        ? axisYValues.length
        : 0);
    const settingColumnsWidth =
      lastHeaderRowCellsCount * columnsWidth.nestedColumn;
    return {
      height: cellHeaderHeightWithContent - cellBorderWidth,
      width: settingColumnsWidth,
      x: 0,
      y: 0,
    };
  };

  const getCellSizeAndPositionForSimpleTable = (
    index: number,
    columnIndex: number,
    rowIndex: number,
  ) => {
    const isTotal =
      !columnsCount[rowIndex - 1][columnIndex + 1] && isNeedToDisplayTotal;
    return {
      height: isTotal ? headerHeight : cellHeaderHeightWithContent - cellBorderWidth,
      width: isTotal ? columnsWidth.total : columnsWidth.nestedColumn,
      x: (index - 1) * columnsWidth.nestedColumn,
      y: isTotal ? 0 : rowIndex * (cellHeaderHeightWithContent - cellBorderWidth),
    };
  };

  const getCellSizeAndPositionForTable = (
    index: number,
    columnIndex: number,
    rowIndex: number,
    valuesCount: number
  ) => {
    if (!columnsCount[rowIndex - 1]) return {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    };

    const columnsCountInRowBeforeCurrentCell = sum(
      columnsCount[rowIndex - 1]?.map((count, countIndex) =>
        countIndex < columnIndex ? count : 0,
      ),
    );

    const differenceBetweenValueRowTotalIndexAndCurrentIndex = columnsCount[rowIndex - 1].length - 1 - columnIndex;
    const isValueRowTotal = isNeedToDisplayTotal
      ? (columnsCount.length === 3 && rowIndex === 3 || columnsCount.length === 2 && rowIndex === 2)
      && (differenceBetweenValueRowTotalIndexAndCurrentIndex < valuesCount)
      : false;

    const xPosition = isValueRowTotal
      ? (columnsCountInRowBeforeCurrentCell -
      (valuesCount - differenceBetweenValueRowTotalIndexAndCurrentIndex - 1)) * columnsWidth.nestedColumn +
      (valuesCount - differenceBetweenValueRowTotalIndexAndCurrentIndex - 1) * columnsWidth.total
      : columnsCountInRowBeforeCurrentCell * columnsWidth.nestedColumn;

    const isTotal =
      rowIndex === 1 &&
      !columnsCount[rowIndex - 1][columnIndex + 1] &&
      isNeedToDisplayTotal;

    const headerHeightWithoutSettingRow = headerHeight - cellHeaderHeightWithContent;
    const cellHeight = isTotal
      ? isTableWithMultipleValues
        ? headerHeightWithoutSettingRow
        : headerHeight - cellBorderWidth
      : cellHeaderHeightWithContent - cellBorderWidth;

    const nestedColumnsCount = columnsCount[rowIndex - 1][columnIndex];
    const cellWidth =
      (isTotal
        ? columnsWidth.total *
          (isTableWithMultipleValues && axisZValues.length === 2
            ? axisYValues.length
            : 1)
        : isValueRowTotal
          ? columnsWidth.total
          : columnsWidth.nestedColumn) * nestedColumnsCount;

    return {
      height: cellHeight,
      width: cellWidth,
      x: xPosition,
      y: isTotal ? 0 : rowIndex * (cellHeaderHeightWithContent - cellBorderWidth),
    };
  };

  const getCellSizeAndPositionForTableWithoutColumns = (index: number) => {
    return {
      height: cellHeaderHeightWithContent - cellBorderWidth,
      width: columnsWidth.nestedColumn,
      x: columnsWidth.nestedColumn * index,
      y: 0,
    };
  };

  const cellSizeAndPositionGetter = ({ index }: { index: number }) => {
    if (!isPivotWithColumns)
      return getCellSizeAndPositionForTableWithoutColumns(index);

    if (index === 0) {
      return getSettingCellSizeAndPosition();
    }

    const { columnIndex, rowIndex } = getCellIndexes(index, columnsCount);

    if (isNaN(columnIndex))
      return {
        height: 0,
        width: 0,
        x: 0,
        y: 0,
      };

    if (axisZValues.length === 1 && axisYValues.length === 1) {
      return getCellSizeAndPositionForSimpleTable(index, columnIndex, rowIndex);
    }

    return getCellSizeAndPositionForTable(index, columnIndex, rowIndex, axisYValues.length);
  };

  const changeDisplayNestedRow = useCallback(
    (rowId: number) => {
      const isClosedRow = closedRows.includes(rowId);
      isClosedRow && setClosedRows(closedRows.filter((id) => id !== rowId));
      !isClosedRow && setClosedRows([...closedRows, rowId]);

      clearTableBodyCashForRows();
    },
    [setClosedRows, closedRows],
  );

  const changeDisplayNestedColumn = useCallback(
    (columnId: string) => {
      const isClosedColumn = closedColumns.includes(columnId);
      isClosedColumn && setClosedColumns(closedColumns.filter((id) => id !== columnId));
      !isClosedColumn && setClosedColumns([...closedColumns, columnId]);

      clearTableCash();
    },
    [closedColumns, clearTableCash],
  );

  const isOpenRow = (parentId: number | null): boolean => {
    const upperLevelParentId = widgetData.rows.find(
      (row) => row.id === parentId,
    )?.parentId;
    return (
      isNull(parentId) ||
      (closedRows.includes(parentId)
        ? false
        : isOpenRow(isNumber(upperLevelParentId) ? upperLevelParentId : null))
    );
  };

  return {
    columnsCount,
    mainColumnsCount,
    headerCellsCount,
    headerHeight,
    columnsIds,
    cellSizeAndPositionGetter,
    containerWidth,
    containerRef,
    columnsWidth,
    offsetColumnWidth,
    changeDisplayNestedRow,
    closedRows,
    isOpenRow,
    isNeedToDisplayTooltip,
    isPivotWithColumns,
    changeDisplayNestedColumn,
    closedColumns
  };
};
