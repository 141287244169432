import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PanelType, WidgetType } from '../../../../../enums/widget-type';
import { getTableHeader } from '../../../../widget-page/charts/complex-table/helper';
import { BookmarkPopoverProps } from './index';
import { PropertyData } from '../../../../widget-page/dropdown-layout/helpers/Property';
import { getParsedAxisValues } from '../../../../widget-page/dropdown-layout/helpers/helpers';
import { getJSONPropertyValue, isDashboardPage } from '../../../../widget-page/charts/helpers';
import { setWidgetPropListAction } from '../../../../../slices/widget/widget';
import { useWidgetPropertiesChangeOnDashboard } from '../../../../../hooks/charts/useWidgetPropertiesChangeOnDashboard';

export type AxesValues = Record<PanelType, PropertyData[]>;
export type ActiveElements = Record<PanelType, number[]>;
export type ValidationAxes = Record<PanelType, (string | null)[]>;

export const checkMinMultipleBookmark = (axisValues: PropertyData[]) => {
  return axisValues.length === 0
    ? 'Должен быть выбран как минимум 1 элемент'
    : null;
};

export const useBookmarkPopover = ({
  bookmarkList,
  handleClickClose,
  widgetProperties,
  widgetId,
  widgetType,
  setOverriddenProperties,
  setFilterField,
  setMultipleFilterFields,
  isActiveFilter,
}: BookmarkPopoverProps) => {
  const dispatch = useDispatch();

  const { changePropertyOnDashboard } = useWidgetPropertiesChangeOnDashboard();

  const axesValues: AxesValues = useMemo(
    () => {
      return bookmarkList.reduce((acc, bookmark) => {
        return { ...acc, [bookmark.axis]: getParsedAxisValues(bookmark.axis, widgetProperties) };
      }, {} as AxesValues);
    },
    [bookmarkList, widgetProperties]
  );

  const initActiveElements: ActiveElements =
    Object.entries(axesValues).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value.filter((item) => item.isActive)
          .map((item) => item.id || item.widgetFieldId)
      };
    }, {} as ActiveElements);

  const [activeElements, setActiveElements] =
    useState<ActiveElements>(initActiveElements);

  const validationAxesErrorsDescription = bookmarkList.map((bookmark) => {
    const rules = [...bookmark.validationRules || []];

    const filterActiveValues = (key: PanelType) =>
      (value: PropertyData) => activeElements[key].includes(value.id || value.widgetFieldId || 0);

    const axisValues = axesValues[bookmark.axis].filter(filterActiveValues(bookmark.axis));
    const axesValuesActive = Object.keys(axesValues).map((key) => {
      return { name: key, value: axesValues[key as PanelType].filter(filterActiveValues(key as PanelType)) };
    }).reduce((acc, { name, value }) => {
      return { ...acc, [name]: value };
    }, {} as AxesValues);

    return {
      axis: bookmark.axis,
      description:
        rules
          .map((checkValidation) => checkValidation(axisValues, axesValuesActive))
          .filter(Boolean)
    };
  }).reduce((acc, validationAxis) => {
    return { ...acc, [validationAxis.axis]: validationAxis.description };
  }, {} as ValidationAxes);


  const isDisabledButton = Object.keys(validationAxesErrorsDescription).map((key) => {
    return Boolean(validationAxesErrorsDescription[key as PanelType].length);
  }).some(Boolean);


  const handleClickChange = (axis: PanelType, id: number) => {
    if (!bookmarkList.find((bookmark) => bookmark.axis === axis)?.multiple) {
      handleClickSave([id]);
      return;
    }

    if (activeElements[axis].includes(id)) {
      const index = activeElements[axis].findIndex((item) => item === id);
      const newActiveElements = [...activeElements[axis]];
      newActiveElements.splice(index, 1);
      setActiveElements((elements) => ({ ...elements, [axis]: newActiveElements }));
    } else {
      setActiveElements((elements) => ({ ...elements, [axis]: [...activeElements[axis], id] }));
    }
  };

  const handleClickSave = (activeElement?: [number]) => {
    const newPropertyValues: {name: PanelType | string, value: string}[] =
      bookmarkList.map((bookmark) => {
        const newAxisValues = axesValues[bookmark.axis].map((item) => ({
          ...item,
          isActive: (activeElement || activeElements[bookmark.axis]).includes(
            item.id || item.widgetFieldId || 0,
          ),
        }));

        return {
          name: bookmark.axis,
          value: JSON.stringify(newAxisValues),
        };
      });

    if (isDashboardPage()) {
      // если параметр функции activeElement есть, то значит это смена оси Х, и нужно сбросить связанные фильтры дашборда
      activeElement &&
        isActiveFilter &&
        setFilterField &&
        setFilterField(widgetId, null, []);
      changePropertyOnDashboard(widgetId, newPropertyValues);

      widgetType === WidgetType.TABLE && newPropertyValues.push({
        name: 'tableHeader',
        value: JSON.stringify(getTableHeader(getJSONPropertyValue(PanelType.axisX, newPropertyValues))),
      });

      setMultipleFilterFields && setMultipleFilterFields(widgetId, null, []);

      setOverriddenProperties && setOverriddenProperties(newPropertyValues);
    } else {
      dispatch(
        setWidgetPropListAction(newPropertyValues),
      );
    }
    handleClickClose();
  };

  return {
    axisValues: axesValues,
    handleClickChange,
    activeElements,
    validationAxesErrorsDescription,
    isDisabledButton,
    handleClickSave,
  };
};
