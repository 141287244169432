import React, { useContext, useMemo } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetsTypesContext } from '../../../pages/widget-page/widget-page';
import WidgetDropdown from './index';
import WidgetsTypes, { GroupTypes } from '../../../types/widgets';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import {
  setWidgetPropAction,
  setWidgetSharedStatusAction,
} from '../../../slices/widget/widget';
import { State } from '../../../slices/types';
import {
  getBooleanPropertyValue,
  getInputPropertyValue,
} from '../charts/helpers';
import { CustomCheckbox } from '../../../uikit/Checkbox';
import { CustomFormControl } from '../../../uikit/FormControl';

const renderDropdown = (property: WidgetsTypes.WidgetsMetaProperties) => (
  <WidgetDropdown
    key={`widget-dropdown-${property.name}`}
    name={property.description}
    id={property.name}
  />
);

interface VisualTypeComponentProps {
  property: WidgetsTypes.WidgetsMetaProperties;
  mainProperties: WidgetsTypes.WidgetsMetaProperties[];
}

const VisualTypeDragAndDrop = ({
  property,
  mainProperties,
}: VisualTypeComponentProps) => {

  return property.parentName === null ? (
    <div className="widget-main__row">
      {renderDropdown(property)}
      {mainProperties.map((prop) => {
        return prop.parentName === property.name ? renderDropdown(prop) : null;
      })}
    </div>
  ) : null;
};


const VisualTypeInput = ({
  property,
}: VisualTypeComponentProps) => {
  const dispatch = useDispatch();

  const widgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const handleInputChange = (
    e: any,
    property: WidgetsTypes.WidgetsMetaProperties,
  ) => {
    dispatch(
      setWidgetPropAction({ name: property.name, value: e.target.value }),
    );
  };

  return (
    <div className="widget-dropdown__wrapper">
      <TextInput
        label={property.description}
        className="widget-dropdown__input"
        value={getInputPropertyValue(property.name, widgetProperties)}
        handleChange={(e) => {
          handleInputChange(e, property);
        }}
      />
    </div>
  );
};


const VisualTypeCheckbox = ({
  property,
}: VisualTypeComponentProps) => {
  const dispatch = useDispatch();

  const widgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );


  const handleCheckBoxChange = (
    e: any,
    property: WidgetsTypes.WidgetsMetaProperties,
  ) => {
    dispatch(
      setWidgetPropAction({
        name: property.name,
        value: JSON.stringify(e.target.checked),
      }),
    );
    if (property.name === 'shared') {
      dispatch(setWidgetSharedStatusAction(e.target.checked));
    }
  };
  return (
    <CustomFormControl
      className="widget-dropdown__wrapper"
      control={
        <CustomCheckbox
          onChange={(e) => {
            handleCheckBoxChange(e, property);
          }}
          checked={getBooleanPropertyValue(property.name, widgetProperties)}
          name={property.name}
          color="primary"
        />
      }
      label={property.description}
    />
  );
};

const VisualTypeComponent = ({
  property,
  mainProperties,
}: VisualTypeComponentProps) => {
  switch (property.visualType) {
    case 'DRAG_AND_DROP':
      return (
        <VisualTypeDragAndDrop
          mainProperties={mainProperties}
          property={property}
        />
      );
    case 'INPUT':
      return (
        <VisualTypeInput
          mainProperties={mainProperties}
          property={property}
        />
      );
    case 'CHECKBOX':
      return (
        <VisualTypeCheckbox
          mainProperties={mainProperties}
          property={property}
        />
      );
  }

  return null;
};

type WidgetsDropdownProps = {
  activeWidgetType: string;
};

const WidgetsDropdown: React.FC<WidgetsDropdownProps> = ({
  activeWidgetType,
}) => {
  const types = useContext(WidgetsTypesContext);

  const currentWidgetType = useMemo<WidgetsTypes.Type | undefined>(
    () => types.find((widgetType) => widgetType.type === activeWidgetType),
    [types, activeWidgetType],
  );
  const mainGroup = useMemo<WidgetsTypes.WidgetPropertiesGroup | undefined>(
    () =>
      currentWidgetType?.propertiesGroups?.find(
        (propertiesGroup) => propertiesGroup.type === GroupTypes.main,
      ),
    [currentWidgetType],
  );

  const mainProperties = mainGroup?.propertiesMetaBlocks[0]
    ?.metaProperties as WidgetsTypes.WidgetsMetaProperties[];

  return (
    <>
      {mainProperties &&
        mainProperties.map((property) => (
          <VisualTypeComponent
            key={property.name}
            mainProperties={mainProperties}
            property={property}
          />
        ))}
    </>
  );
};

export default WidgetsDropdown;
