import { AxiosResponse } from 'axios';
import { apiBI } from '../utils/api';

const EXPORT_URL = '/export';

export const apiExportFile = async (id: number, isForced: boolean = false): Promise<AxiosResponse> => {
  try {
    return await apiBI.get(`${EXPORT_URL}/${id}`, { responseType: 'blob', params: { force: isForced } });
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
};
