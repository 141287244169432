import React from 'react';
import { NavLink } from 'react-router-dom';
import IconSvg from '../../../common/icon-svg/icon-svg';
import WidgetCarouselItem from './widget-carousel-item';
import { useDashboardsContent } from '../hooks/useDashboardsContent';
import { DashboardMenu } from './dashboard-menu';
import { carouselItemFontSize, getShowingTooltipState } from '../helper';
import { CustomButton } from '../../../../uikit/Button';
import {
  CommonDictionary,
  DashboardDictionary,
} from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface DashboardsContentProps {
  changeModal: (
    type: string | undefined,
    actionType: string,
    name: string,
    id: number | undefined,
  ) => void;
  carouselItemMaxWidth: number;
  classes: any;
}

export const DashboardsContent = ({
  changeModal,
  carouselItemMaxWidth,
  classes,
}: DashboardsContentProps) => {
  const {
    dashboardRef,
    currentDashboard,
    dashboardsList,
    toggleDashboardList,
    isDashboardPopperOpen,
    params,
    projectId,
    onNewDashboardAdd,
    dashboardAnchor,
    handleDashboardsMenuClose,
  } = useDashboardsContent();

  return (
    <div className="widget-carousel__container">
      <div className="widget-carousel__dropdown" ref={dashboardRef}>
        {currentDashboard && (dashboardsList || []).length > 1 && (
          <button
            type="button"
            aria-describedby="dashboard"
            onClick={toggleDashboardList}
            className="widget-footer__button"
          >
            <IconSvg svg={IconDictionary.Dashboard} fill="var(--dark-grey)" />
            <span className="widget-footer__button-text">{DashboardDictionary.titleMany}</span>
            <IconSvg
              svg={
                isDashboardPopperOpen
                  ? IconDictionary.SelectArrowUp
                  : IconDictionary.SelectArrowDown
              }
              width={22}
              height={22}
              fill={
                isDashboardPopperOpen ? 'var(--primary-color)' : 'var(--dark-grey)'
              }
              classSVG="widget-carousel-arrow"
            />
          </button>
        )}

        <div className="widget-carousel-item__inline_flex">
          {currentDashboard ? (
            <WidgetCarouselItem
              changeModal={changeModal}
              isActive={String(currentDashboard.id) === params.id}
              name={currentDashboard.name}
              type="dashboard"
              id={currentDashboard.id}
              iconName="Dashboard"
              isCurrent={true}
              disableHoverTooltipListener={getShowingTooltipState(
                currentDashboard?.name || '',
                carouselItemFontSize,
                carouselItemMaxWidth,
              )}
            />
          ) : (
            <div className="widget-carousel__dropdown-no-data">
              {!dashboardsList ? (
                <span>
                  <CustomProgress type="circular" size={20} />
                </span>
              ) : (
                <>
                  <IconSvg
                    svg={IconDictionary.NoDashboards}
                    fill="var(--dark-grey)"
                  />
                  <span>В {CommonDictionary.projectPrepositional} не создано {DashboardDictionary.noDashboards}</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {dashboardsList && (
        <NavLink to={`/project/${projectId}/dashboard`}>
          <CustomButton
            variant="outlined"
            icon={<IconSvg svg={IconDictionary.AddDashboard} fill="var(--primary-color)" />}
            onClick={onNewDashboardAdd}
            className="widget-footer__button_icon"
          >
            {currentDashboard ? '' : `Добавить ${DashboardDictionary.one}`}
          </CustomButton>
        </NavLink>
      )}
      {isDashboardPopperOpen && (
        <DashboardMenu
          changeModal={changeModal}
          carouselItemMaxWidth={carouselItemMaxWidth}
          classes={classes}
          dashboardAnchor={dashboardAnchor}
          dashboardRef={dashboardRef}
          handleDashboardsMenuClose={handleDashboardsMenuClose}
        />
      )}
    </div>
  );
};
