import React, { Dispatch } from 'react';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../common/icon-svg/icon-svg';

interface Props {
  file: string;
  previewFile: string | null;
  setPreviewFile: Dispatch<string | null>;
}

const useStyles = makeStyles({
  root: {
    width: 'calc(250px * var(--scale-coefficient))',
    wordBreak: 'break-all',
    paddingLeft: 0,
  },
});

const SourceListOnForm: React.FC<Props> = ({
  file,
  previewFile,
  setPreviewFile,
}) => {
  const handlePreviewClick = (name: string) => () => {
    setPreviewFile(name);
  };

  const classes = useStyles();

  return (
    <ListItem classes={{ root: classes.root }}>
      <ListItemText primary={file} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="preview"
          onClick={handlePreviewClick(file)}
        >
          <IconSvg svg={IconDictionary.OpenSource} fill={file === previewFile ? "var(--primary-color)" : "var(--dark-grey)"} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default SourceListOnForm;
