import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconSvg from '../../common/icon-svg/icon-svg';
import Connection from '../../../types/connection';
import DeleteDialog from '../../../uikit/DeleteDialog/delete-dialog';
import { apiDeleteConnection } from '../../../services/connection';
import { connectionsActions } from '../../../slices/connections/connections';
import { setDeletingAction } from '../../../slices/main-page/main-page-slice';
import { State } from '../../../slices/types';
import { CustomListItem } from '../../../uikit/ListItem';
import { CustomIconButton, IconButtonType } from '../../../uikit/IconButton';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface Props {
  item: Connection.View.ItemFull;
  activeItem: number | string | null;
  handleItemClick: (item: Connection.View.ItemFull) => void;
  handleEditItemClick: (item: Connection.View.ItemFull) => void;
}

const ConnectionItem: React.FC<Props> = ({
  item,
  activeItem,
  handleItemClick,
  handleEditItemClick,
}) => {
  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;
  const groupId: number = useSelector(
    (state: State) => state.mapConnection[loaderId]?.loaderGroupId,
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const dispatch = useDispatch();

  const { id, connectionName } = item;

  const connectionItemViewName = `${connectionName}`;

  const toggleDeleteDialog = () => {
    setShowDeleteDialog((prevState) => !prevState);
  };

  const handleClick = () => {
    handleItemClick(item);
  };

  const handleEditClick = () => {
    handleEditItemClick(item);
  };

  const handleDeleteClick = async () => {
    try {
      dispatch(setDeletingAction(true));
      setIsLoading(true);
      await apiDeleteConnection(id);
      toggleDeleteDialog();
      dispatch(connectionsActions.getFullConnectionsAction(groupId));
    } catch (err: any) {
      setError(
        `Данное соединение используют следующие загрузчики:${err?.response?.data?.message}`,
      );
    } finally {
      dispatch(setDeletingAction(false));
      setIsLoading(false);
    }
  };

  const isValidConnection = item.hasPassword;

  return (
    <>
      <CustomListItem
        title={connectionItemViewName}
        onClick={handleClick}
        error={!isValidConnection}
        active={activeItem === id}
        titleVariant="primary"
      >
        {item.sourceType !== 'LOCAL' && (
          <>
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.EditFile} fill='var(--dark-grey)' />,
                onClick: (event) => { event.stopPropagation(); handleEditClick();},
              }}
              type={IconButtonType.secondary}
            />
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />,
                onClick: toggleDeleteDialog,
              }}
              type={IconButtonType.delete}
            />
          </>
        )}
      </CustomListItem>

      {showDeleteDialog && (
        <DeleteDialog
          isOpen={showDeleteDialog}
          onClose={toggleDeleteDialog}
          onDelete={handleDeleteClick}
          title="Удаление соединения"
          bodyText={`соединение ${connectionItemViewName}`}
          error={error}
          isLoading={isLoading}
          maxWidth="xs"
        />
      )}
    </>
  );
};

export default ConnectionItem;
