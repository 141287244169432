import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { scaleBand, scaleOrdinal, scaleTime } from '@visx/scale';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { useDebouncedCallback } from 'use-debounce';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { GanttRow, RoundingVariants } from './gantt-row';
import { GanttSidebarTable } from './gantt-sidebar-table';
import { GanttMonthRange } from './gantt-month-range';
import { GanttDaysRange } from './gantt-days-range';
import { useEventListener } from '../../../../hooks/useEventListener';
import { useDraggable } from './hooks/useDraggableScroll';

import { axisProperties, canvasProperties } from './properties';
import { useGanttProperties } from './hooks/useGanttProperties';

import './styles.css';
import { getJSONPropertyValue, sortColorPalette } from '../helpers';
import { PanelType } from '../../../../enums/widget-type';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { State } from '../../../../slices/types';
import { defaultColors } from '../common/color';
import { Property } from '../../dropdown-layout/helpers/Property';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';
import { CustomProgress } from '../../../../uikit/Progress';

interface GanttProps {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
}

export const colors = defaultColors;

export const Gantt: React.FC<GanttProps> = ({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}) => {
  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  const enableEvents: boolean = isActiveFilter;
  const widgetProperties = widgetProps.properties;

  const [isOpened, setIsOpened] = useState<boolean>(true);

  const draggableScrollRef = useDraggable();

  const openHandler = useCallback(() => {
    setIsOpened(true);
  }, []);

  const closeHandler = useCallback(() => {
    setIsOpened(false);
  }, []);

  const toggleHandler = useCallback(() => {
    !isOpened ? openHandler() : closeHandler();
  }, [isOpened]);

  const data = widgetProps.data.map((value: any) => ({
    startDate: moment(value.start, 'YYYY-MM-DD HH:mm:ss.S').toDate(),
    endDate: moment(value.end, 'YYYY-MM-DD HH:mm:ss.S').toDate(),
    taskName: value.name,
    status: 'RUNNING',
    progress: value.progress === null ? 1 : value.progress / 100,
    number: value.number,
  }));

  const boundAxisValues: Array<any> = getParsedAxisValues(
    PanelType.nameStage,
    widgetProperties,
  );

  const progressAxis = getJSONPropertyValue(
    PanelType.progress,
    widgetProps.properties,
  );
  const endDateAxis = getJSONPropertyValue(
    PanelType.dateEndStage,
    widgetProps.properties,
  );

  const roundingVariants: Record<string, RoundingVariants> = {
    TIMESTAMP: RoundingVariants.SECOND,
    DATE: RoundingVariants.DAY,
  };

  const containerRef = useRef(document.createElement('div'));

  const [hoveredRowIndex, setHoveredRowIndex] = useState<null | number>(null);

  const {
    totalStartDate,
    totalEndDate,
    numTicksColumns,
    daysRange,
    monthRange,
  } = useGanttProperties(data);

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  const debounceDelay = 0;

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setCanvasHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setCanvasWidth(value);
  }, debounceDelay);

  const calculateDimensions = useCallback(() => {
    let currentHeight = canvasProperties.getHeight(data);
    let currentWidth = canvasProperties.getWidth(daysRange.length);

    if (currentWidth < containerRef.current?.clientWidth) {
      currentWidth = containerRef.current?.clientWidth;
    }

    if (currentHeight < containerRef.current?.clientHeight) {
      currentHeight = containerRef.current?.clientHeight;
    }

    setDebouncedWidth.callback(currentWidth);
    setDebouncedHeight.callback(currentHeight);
  }, [data]);

  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
  }, [data, isFullScreen]);

  const xScale = scaleTime({
    domain: [totalStartDate, totalEndDate],
    range: [0, canvasWidth],
  });

  const yScale = scaleBand({
    domain: data.map((x: any) => x.taskName),
    range: [0, canvasProperties.getHeight(data) - axisProperties.height],
  });

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProps.properties,
  );

  const currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || colors;

  const colorScale = scaleOrdinal<string, string>({
    domain: data.map((x: any) => x.taskName),
    range: currentColors,
  });

  const rowMouseOverHandler = useCallback(
    (index: number) => () => setHoveredRowIndex(index),
    [],
  );

  const rowMouseOutHandler = useCallback(() => setHoveredRowIndex(null), []);


  const deselectBar = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);
  
  const selectBar = useCallback(
    (value: string) => {
      if (!enableEvents) return;

      if (filterField?.value?.includes(value)) {
        deselectBar();
      } else {
        if (!setFilterField || !value) return;

        setFilterField(widgetProps.id, new Property(boundAxisValues[0]).getId(), [{
          operation: '=',
          value: [value],
        }]);
      }
    },
    [JSON.stringify(filterField), boundAxisValues, deselectBar, enableEvents, filterField, setFilterField, widgetProps.id],
  );

  if (canvasHeight <= 0){
    return (
      <div className="guntt-loader__container">
        <CustomProgress type="circular" />
      </div>
    );
  }

  return (
    <div className="guntt-widget-viewport" onClick={deselectBar}>
      <ScrollSync>
        <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
          <div style={{ display: 'flex' }}>
            <ScrollSyncPane>
              <div className="guntt-container--vertical-scroll screenshot-overflow-y">
                <GanttSidebarTable
                  data={data}
                  hoveredRowIndex={hoveredRowIndex}
                  canvasHeight={canvasHeight}
                  rowsCount={data.length}
                  hasProgressColumn={progressAxis.length > 0}
                  isOpened={isOpened}
                />
              </div>
            </ScrollSyncPane>
            <button
              onClick={toggleHandler}
              className="guntt-sidebar-close_button"
            >
              <svg
                width="5"
                height="14"
                viewBox="0 0 5 14"
                fill="none"
                className={`guntt-sidebar-close_icon ${
                  isOpened ? 'opened' : ''
                }`}
              >
                <path d="M5 14V0L0 7.25926L5 14Z" fill="white" />
              </svg>
            </button>
          </div>
          <ScrollSyncPane>
            <div
              className="guntt-container--full-scroll screenshot-overflow"
              ref={draggableScrollRef}
            >
              <div className="guntt-container__wrapper" ref={containerRef}>
                <svg
                  className="gantt-canvas-container"
                  width={canvasWidth}
                  height={canvasHeight}
                >
                  <GridColumns
                    top={axisProperties.monthLabelHeight}
                    width={canvasWidth}
                    height={canvasHeight}
                    numTicks={numTicksColumns}
                    scale={xScale}
                    strokeDasharray="4"
                    strokeWidth={1}
                    stroke="var(--dark-cyan)"
                    offset={canvasWidth / daysRange.length}
                  />

                  <Group top={axisProperties.height}>
                    <rect
                      x={0}
                      y={0}
                      height={1}
                      fill="var(--dark-cyan)"
                      width={canvasWidth}
                    />
                    {data.map((row: any, index: number) => (
                      <GanttRow
                        key={index}
                        xScale={xScale}
                        yScale={yScale}
                        colorScale={colorScale}
                        currentColors={currentColors}
                        data={row}
                        isHovered={hoveredRowIndex === index}
                        onMouseOver={rowMouseOverHandler(index)}
                        onMouseOut={rowMouseOutHandler}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight - axisProperties.height}
                        rowsCount={data.length}
                        rounding={roundingVariants[endDateAxis[0]?.type]}
                        selectBar={selectBar}
                        deselectBar={deselectBar}
                        isSelected={
                          filterField?.value === null ||
                          filterField?.value === undefined ||
                          filterField?.value?.includes(row.taskName)
                        }
                        enableEvents={enableEvents}
                      />
                    ))}
                  </Group>
                  <Group>
                    <GanttMonthRange
                      canvasWidth={canvasWidth}
                      daysRange={daysRange}
                      monthRange={monthRange}
                    />
                    <GanttDaysRange
                      daysRange={daysRange}
                      xScale={xScale}
                      canvasHeight={canvasHeight}
                      canvasWidth={canvasWidth}
                    />
                  </Group>
                </svg>
              </div>
            </div>
          </ScrollSyncPane>
        </div>
      </ScrollSync>
    </div>
  );
};
