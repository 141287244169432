import React from 'react';
import { useFormik } from 'formik';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { CustomButton } from '../../../../../uikit/Button';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';

interface CombineValues {
  isCombineValues: boolean;
}

interface CombineOptionsProps {
  panelData: PropertyData;
  closeDialog: () => void;
  changeCombineOptions: (isCombineValues: boolean) => void;
}

export const ColumnCombineOptions = ({
  panelData,
  closeDialog,
  changeCombineOptions,
}: CombineOptionsProps) => {
  const handleSave = (values: CombineValues) => {
    closeDialog();
    changeCombineOptions(values.isCombineValues);
  };

  const handleClose = () => {
    closeDialog();
  };

  const { values, handleChange, handleSubmit } = useFormik<CombineValues>({
    initialValues: {
      isCombineValues: panelData.isCombineValues || false,
    },
    onSubmit: handleSave,
  });

  return (
    <CustomDialog
      isOpen
      onClose={closeDialog}
      title="Настройка объединения строк"
      maxWidth="xs"
    >
      <form className="dialog-body" onSubmit={handleSubmit}>
        <CustomFormControl
          control={
            <CustomCheckbox
              name="isCombineValues"
              onChange={handleChange}
              checked={values.isCombineValues}
            />
          }
          label="Объединять значения в одну ячейку"
          className="dialog-body__block"
        />

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton variant="contained" type="submit">
            <span>Сохранить</span>
          </CustomButton>
          <CustomButton variant="outlined" type="reset" onClick={handleClose}>
            Отменить
          </CustomButton>
        </div>
      </form>
    </CustomDialog>
  );
};
