import React, { useCallback, useEffect, useState } from 'react';
import { TextInput } from '../../../../../common/special-inputs/inputs/text-input';
import SourceField from '../../map-modals/source-field';
import { getSimplifiedType } from '../../../../dropdown-layout/helpers/helpers';
import { apiFetchSourceData } from '../../../../../../services/widgetController';
import { PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { useFieldAggregationsAndFunctions } from '../../map-modals/useFieldAggregationsAndFunctions';
import { PanelType } from '../../../../../../enums/widget-type';

interface FieldsBlockProps {
  etlSourceId: string;
  currentWidgetField: PropertyData | null;
  changeCurrentWidgetField: (field: PropertyData) => void;
  changeAggregationCallback: (aggregation: any) => void;
}

export const FieldsBlock = ({
  etlSourceId,
  currentWidgetField,
  changeCurrentWidgetField,
  changeAggregationCallback,
}: FieldsBlockProps) => {
  const [widgetSourceFields, setWidgetSourceFields] = useState<PropertyData[]>(
    [],
  );
  const [filterWidgetSourceFields, setFilterWidgetSourceFields] = useState<
    PropertyData[]
  >([]);

  const {
    aggregations,
    currentSingleAggregation,
    onChangeAggregationHandle,
    initCurrentAggregations,
  } = useFieldAggregationsAndFunctions(
    PanelType.axisZ,
    etlSourceId,
    true,
    true,
    changeAggregationCallback,
  );

  useEffect(() => {
    apiFetchSourceData(etlSourceId).then((result) => {
      const filteredWidgetFields = [...result.fields]
        .filter((item: any) => {
          return (
            item.type.indexOf('POLYGON') === -1 &&
            item.type.indexOf('POINT') === -1
          );
        })
        .map((item: any) => {
          const correctItem = { ...item, etlSourceId };
          delete correctItem.dimension;
          delete correctItem.measure;
          return correctItem;
        });

      setWidgetSourceFields(filteredWidgetFields);
      setFilterWidgetSourceFields(filteredWidgetFields);

      if (!currentWidgetField && filteredWidgetFields.length) {
        initCurrentAggregations(undefined, undefined, filteredWidgetFields[0]);
        changeCurrentWidgetField(filteredWidgetFields[0]);
      }
    });
  }, [etlSourceId]);

  useEffect(() => {
    aggregations.NUMBER.length && currentWidgetField &&
      initCurrentAggregations(undefined, undefined, currentWidgetField);
  }, [
    currentWidgetField,
    aggregations.NUMBER.length,
    aggregations.TEXT.length,
    aggregations.DATE.length,
    aggregations,
    initCurrentAggregations,
  ]);

  const onSearchChange = useCallback(
    (event: any) => {
      const { value } = event.target;

      const searchResult = widgetSourceFields.filter((field: any) =>
        field.name.toLowerCase().includes(value.toLowerCase()),
      );

      setFilterWidgetSourceFields(searchResult);
    },
    [widgetSourceFields],
  );

  const changeCurrentFieldHandle = (params: any) => {
    const { field } = params;
    changeCurrentWidgetField(field);
    initCurrentAggregations(undefined, undefined, field);
  };

  return (
    <div className="heat-map-settings__block heat-map-settings__block_bottom heat-map-settings__block_flex">
      <TextInput
        label="Поиск по полям"
        className="widget-sidebar__field"
        handleChange={onSearchChange}
        searchIcon
      />

      <div className="heat-map-settings__list">
        {filterWidgetSourceFields &&
          filterWidgetSourceFields.map((field) => {
            const isChecked =
              currentWidgetField?.etlFieldId === field.etlFieldId;
            return (
              <SourceField
                key={field.etlFieldId}
                field={field}
                view="radio"
                onChangeCurrentFieldHandle={changeCurrentFieldHandle}
                onChangeFunctionHandle={onChangeAggregationHandle}
                functions={aggregations[getSimplifiedType(field.type)]}
                currentFunction={currentSingleAggregation}
                isChecked={isChecked}
              />
            );
          })}
      </div>
    </div>
  );
};
