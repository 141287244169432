import React from 'react';
import MapConnection from 'src/types/map-connection';
import { InputContainer } from '../inputContainer/inputContainer';
import { useCalculatedInputContainer } from './useCalculatedInputContainer';
import { TextInputProps } from '../inputs/text-input';

interface CalculatedInputProps {
  value: string;
  setValue: (param: string) => void;
  processedFields: MapConnection.ProcessedField[];
  textInputProps: TextInputProps;
  className?: string;
  isMaxRowOverflow?: boolean;
}

export const CalculatedInputContainer = ({
  processedFields,
  setValue,
  value,
  textInputProps,
  className,
  isMaxRowOverflow
}: CalculatedInputProps) => {
  const { handleChangeValue, sourcesList, regExes } =
    useCalculatedInputContainer({ value, setValue, processedFields });

  return (
    <InputContainer
      textInputProps={{
        ...textInputProps,
        fullWidth: true,
      }}
      itemsList={sourcesList}
      value={value}
      changeValue={(newValue) => {
        handleChangeValue(newValue);
      }}
      regExes={regExes}
      className={className}
      isMaxRowOverflow={isMaxRowOverflow}
    />
  );
};
