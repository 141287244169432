export enum WidgetType {
  HISTOGRAM = 'HISTOGRAM',
  HORIZONTAL_HISTOGRAM = 'HORIZONTAL_HISTOGRAM',
  STACKED_HISTOGRAM = 'STACKED_HISTOGRAM',
  STACKED_HORIZONTAL_HISTOGRAM = 'STACKED_HORIZONTAL_HISTOGRAM',
  HISTOGRAM_GRAPH = 'HISTOGRAM_GRAPH',
  GRAPH = 'GRAPH',
  GRAPH_AREA = 'GRAPH_AREA',
  TABLE = 'TABLE',
  PIE_CHART = 'PIE_CHART',
  DONUT_CHART = 'DONUT_CHART',
  SPEEDOMETER = 'SPEEDOMETER',
  MAP = 'MAP',
  HTML = 'HTML',
  GANTT_CHART = 'GANTT_CHART',
  PIVOT_TABLE = 'PIVOT_TABLE',
  INFORMATION_CARD = 'INFORMATION_CARD',
  FUNNEL = 'FUNNEL',
  RADAR_CHART = 'RADAR_CHART',
  SUNBURST = 'SUNBURST_CHART',
  TREE_MAP = 'TREE_MAP',
  BUBBLE_CHART = 'BUBBLE_CHART',
  NONE = 'NONE',
}

export enum PanelType {
  axisX = 'axisX',
  axisY = 'axisY',
  axisZ = 'axisZ',
  axisFilter = 'axisFilter',
  dataSlice = 'dataSlice',
  dateEndStage = 'dateEndStage',
  nameStage = 'nameStage',
  progress = 'progress',
}

export enum WidgetPropertyType {
  displayTopColumn = 'displayTopColumn',
  displayTopSlice = 'displayTopSlice',
  displayTopPoints = 'displayTopPoints',
  dateDisplayFormat = 'dateDisplayFormat',
  typeFillGraph = 'typeFillGraph',
  displayAllCards = 'displayAllCards',
  reverseGradient = 'reverseGradient',
  gradientColoring = 'gradientColoring',
  tableHeader = 'tableHeader',
  tableState = 'tableState',
  activeLegend = 'activeLegend',
  pageSize = 'pageSize',
  pageNumber = 'pageNumber',
  pivotCellSettings = 'pivotCellSettings',
  displayDynamics = 'displayDynamics',
  displayDynamicsInPercent = 'displayDynamicsInPercent',
  displayValueText = 'displayValueText',
  displayDynamicsText = 'displayDynamicsText',
  displayDynamicsGraph = 'displayDynamicsGraph',
  backgroundColor = 'backgroundColor',
  displayLegend = 'displayLegend',
  legendPositionInformationCard = 'legendPositionInformationCard',
  dynamicPosition = 'dynamicPosition',
  textSize = 'textSize', // Размер шрифта значений
  displayTextSize = 'displaytextSize',
  displayPlanInLegend = 'displayPlanInLegend',
  displayFactInLegend = 'displayFactInLegend',
  dynamicTextSizeRatio = 'dynamicTextSizeRatio',
  displayDynamicIcon = 'displayDynamicIcon',
  displayDynamicSign = 'displayDynamicSign',
  title = 'title',
  description = 'description',
  addDescription = 'addDescription',
  roundingCount = 'roundingCount',
  displayAggregationInLegend = 'displayAggregationInLegend',
  displayTopPieChart = 'displayTopPieChart',
  displayTopTreeMap = 'displayTopTreeMap',
  displayMaxRows = 'displayMaxRows',
  displayOther = 'displayOther',
  displayZeros = 'displayZeros',
  displayNulls = 'displayNulls',
  pivotSortingField = 'pivotSortingField',
  displayTotal = 'displayTotal',
  colorPalette = 'colorPalette',
  displayValuesPosition = 'displayValuesPosition',
  displayBarValues = 'displayBarValues',
  displayBarValuestotal = 'displayBarValuestotal',
  histogramType = 'histogramType',
  columnWidth = 'columnWidth',
  displayAxesGuide = 'displayAxesGuide',
  TextDirection = 'TextDirection',
  displayTooltip = 'displayTooltip',
  axisYLabelWidth = 'axisYLabelWidth',
  displayValueInPercent = 'displayValueInPercent',
  axisXLabelHeight = 'axisXLabelHeight',
  hiddenColumns = 'hiddenColumns',
  hierarchyInHeader = 'hierarchyinHeader',
  displaySorting = 'displaySorting',
  displaySortOrder = 'displaySortOrder',
  displayBorder = 'displayBorder',
  fixedFontSize = 'fixedFontSize',
  valuesFontSize = 'valuesFontSize',
  dynamicSizeRatio = 'dynamicSizeRatio',
  columnOffsetCoeff = 'columnOffsetCoeff',
  scaleByNumber = 'scaleByNumber',
  formatByNumber = 'formatByNumber',
  smoothedLine = 'smoothedLine',
  displayAvgAxisY = 'displayAvgAxisY',
  metaData = 'metaData',
  scaleByValue = 'scaleByValue',
  axisXLabelWidth = 'axisXLabelWidth',
  columnDiffCoeff = 'columnDiffCoeff',
  columnWidthType = 'columnWidthType',
  scaleWidgetOnDashboard = 'scaleWidgetOnDashboard',
  pointMinSize = 'pointMinSize', // Минимальный размер точки, px
  pointMaxSize = 'pointMaxSize', // Максимальный размер точки, px
  pointDiffSize = 'pointDiffSize', // Минимальное расстояние между точками, px
  displayFunnelValue = 'displayFunnelValue', // Отображать значение
  displayFunnelPercent = 'displayFunnelPercent', // Отображать значение в %
  pointOffsetCoeff = 'pointOffsetCoeff', // Коэфф отступа от границы
  displayNameSlice = 'displayNameSlice', // Отображать название среза
  valueColorHistogram = 'valueColorHistogram', // Цвет шрифта значений
  valueColorTotal = 'valueColorTotal',
  valueDirection = 'valueDirection', // Направление подписей значений
  offsetValueTotal = 'offsetValueTotal',
  interpolationAxis = 'interpolationAxis', // Интерполяция значений оси X
  displayScroll = 'displayScroll', // Отображать скролл
  multiSelect = 'multiSelect', // Отображать множественный выбор
  tableHiddenMethod = 'tableHiddenMethod', // Расчет скрытых столбцов
  referenceLine1Value = 'referenceLine1Value',
  referenceLine1Text = 'referenceLine1Text',
  referenceLine1Color = 'referenceLine1Color',
  referenceLine1Type = 'referenceLine1Type',
  referenceLine1 = 'referenceLine1',
  valueSizeTotalDonut = 'valueSizeTotalDonut', // Размер шрифта итогового значения
  valueColorTotalDonut = 'valueColorTotalDonut', // Цвет шрифта итогового значения
  textSizeInsideTotalDonut = 'textSizeInsideTotalDonut', // Размер шрифта подписи под значением
  textColorInsideTotalDonut = 'textColorInsideTotalDonut', // Цвет шрифта подписи под значением
  singleScale = 'singleScale', // Единая шкала
  pivotRowsCollapsed = 'pivotRowsCollapsed', // Схлопывать строки
}
