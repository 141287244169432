import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchGlobalParameters } from '../slices/parameters/parameters';

export const AppDataProvider: FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGlobalParameters());
  }, [dispatch]);

  return <>{children}</>;
};
