import React from 'react';
import { TextInput } from '../../../../../common/special-inputs/inputs/text-input';
import CustomTooltip from '../../../../../../uikit/CustomTooltip';
import {
  CustomIconButton,
  IconButtonType,
} from '../../../../../../uikit/IconButton';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../../common/icon-svg/icon-svg';

interface ParentRootSettingProps {
  value: string;
  error?: string;
  handleChange: (event: any) => void;
  dicParentName: string;
}

export const ParentRootSetting = ({
  handleChange,
  value,
  dicParentName,
  error,
}: ParentRootSettingProps) => {
  const tooltipValue =
    value.toLowerCase() === 'null'
      ? `${dicParentName} IS NULL`
      : `${dicParentName} = ${value}`;

  const formulaTooltip = (
    <CustomTooltip title={tooltipValue} hidden={!dicParentName}>
      <div>
        <CustomIconButton
          iconButtonProps={{
            children: (
              <IconSvg
                svg={IconDictionary.InfoRounded}
                fill="var(--dark-grey)"
              />
            ),
          }}
          type={IconButtonType.secondary}
        />
      </div>
    </CustomTooltip>
  );

  return (
    <TextInput
      formikName="dicParentRootValue"
      error={Boolean(error)}
      helperText={error}
      label="Стартовое значение"
      value={value}
      handleChange={handleChange}
      fullWidth
      required
      InputProps={{
        endAdornment: formulaTooltip,
      }}
      isAbsoluteHelperText
      className="dialog-body__form-field"
    />
  );
};
