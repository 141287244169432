import block from 'bem-cn';
import React, { useState } from 'react';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import './filter-section.css';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { CustomButton } from '../../../../../uikit/Button';
import LoaderMenu, { SourceOption } from './loader-menu/loader-menu';
import { CustomTabs } from '../../../../../uikit/Tabs';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';
import { CustomPopover } from '../../../../../uikit/Popover/popover';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface FilterSectionProps {
  handlerChangeFilter: any;
  handlerOnlyCheckedFilterSetting: (value: string) => void;
  reloadScheme: (() => void) | null;
  sqlScript?: string | null;
  sourceOptions: SourceOption[];
}

const FilterSection = ({
  handlerChangeFilter,
  handlerOnlyCheckedFilterSetting,
  reloadScheme,
  sqlScript,
  sourceOptions,
}: FilterSectionProps) => {
  const b = block('select-fields-panel');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const tabs = [
    {
      id: 'all-fields',
      title: 'Все поля',
      onClick: () => handlerOnlyCheckedFilterSetting('all-fields'),
    },
    {
      id: 'only-checked',
      title: 'Выбранные',
      onClick: () => handlerOnlyCheckedFilterSetting('only-checked'),
    },
  ];

  return (
    <div className={b('filter-section')}>
      <div style={{ width: sqlScript ? 'calc(406px * var(--scale-coefficient))' : '100%', marginRight: 'calc(12px * var(--scale-coefficient))' }}>
        <TextInput
          label="Поиск по названию"
          handleChange={handlerChangeFilter}
          searchIcon
          fullWidth={true}
        />
      </div>

      {reloadScheme && (
        <CustomTooltip
          arrow
          title="Обновить схему"
          PopperProps={{
            disablePortal: true,
          }}
        >
          <div style={{ marginRight: 'calc(12px * var(--scale-coefficient))' }}>
            <CustomButton
              variant="outlined"
              icon={<IconSvg
                svg={IconDictionary.TableReload}
                fill="var(--dark-grey)"
              />}
              onClick={reloadScheme}
            />
          </div>
        </CustomTooltip>
      )}

      <CustomTabs buttons={tabs} size={120} />

      <CustomButton
        variant="outlined"
        icon={
          <IconSvg svg={IconDictionary.TableSetting} fill="var(--dark-grey)" />
        }
        onClick={handleClickMenu}
        style={{ marginLeft: 'calc(12px * var(--scale-coefficient))' }}
      />

      <CustomPopover
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <LoaderMenu
          sourceOptions={sourceOptions}
        />
      </CustomPopover>
    </div>
  );
};

export default FilterSection;
