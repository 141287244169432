import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import {
  useGetColorPaletteWidgetProperty,
  useGetNumberWidgetProperty, useGetStringWidgetProperty
} from '../../../../hooks/get-properties/useGetWidgetProperty';
import { useRoundingCounts } from '../../../../hooks/useRoundingCounts';
import { State } from '../../../../slices/types';
import { useEventListener } from '../../../../hooks/useEventListener';
import { setPropForNumberValue } from '../formatting-helpers';
import { initValueFormatter } from '../hooks/initValueFormatter';

import { Arc } from './arc';
import { LegendArc } from '../legend-arcs/legent-arc';
import { LegendPositions } from '../../../../enums/legend';

import './donut.css';
import {
  getAxisValuesForWidget,
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  getLegendsLabelsForWidgetByAxis,
  getSelectPropertyValue,
  sortColorPalette,
} from '../helpers';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { getTooltipHandler } from '../../../common/widget-tooltip/widget-tooltip';
import { defaultColors } from '../common/color';
import {
  donutOffset,
  donutThickness,
  minDonutThickness,
  widgetContainerPadding,
} from './settings';
import {
  maxLineContainerHeight,
  maxLineContainerWidth,
} from '../common/callout-line-settings';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { Property } from '../../dropdown-layout/helpers/Property';
import { useCalculateRadiusDimensions } from '../hooks/useCalculateRadiusDimensions';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';
import { NumberFormat } from '../../../../enums/number-format';
import { getActivePanelItems, getActivePanelItemsNames } from '../../../../helpers/common-helpers';

interface Value {
  label: string;
  percent: number;
  count: number;
}

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };

export type DonutProps = {
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  animate?: boolean;
  widgetProps?: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
};

interface ArcValue {
  count: number;
  label: string;
  percent: number;
}

interface WidgetData {
  data: Array<ArcValue>;
  total: number;
}

let currentColors: any = defaultColors;

export default function PieChart({
  width,
  height,
  margin = defaultMargin,
  animate = true,
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}: DonutProps) {
  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  const enableEvents = isActiveFilter;
  const [radius, setRadius] = useState(190);

  const containerRef = useRef(document.createElement('div'));
  const widgetStoreProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const previewData = widgetProps?.data;

  const widgetProperties = widgetProps?.properties || widgetStoreProperties;

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const categoryName = getActivePanelItemsNames(
    widgetProperties,
    PanelType.axisX,
  )[0];

  const valueName = getLegendsLabelsForWidgetByAxis(
    PanelType.axisY,
    widgetProperties,
    false,
  )[0];

  const isNeedToDisplayLegend: boolean = getBooleanPropertyValue(
    'displayLegend',
    widgetProperties,
  );

  const isNeedToDisplayValueInsideChart: boolean = getBooleanPropertyValue(
    'displayValueInsideChart',
    widgetProperties,
  );


  const valueSizeTotalDonut = useGetNumberWidgetProperty(
    WidgetPropertyType.valueSizeTotalDonut,
    widgetProperties,
    32,
  );
  const valueColorTotalDonut = useGetStringWidgetProperty(
    WidgetPropertyType.valueColorTotalDonut,
    widgetProperties,
    'var(--black)',
  );

  const textSizeInsideTotalDonut = useGetNumberWidgetProperty(
    WidgetPropertyType.textSizeInsideTotalDonut,
    widgetProperties,
    14,
  );
  const textColorInsideTotalDonut = useGetStringWidgetProperty(
    WidgetPropertyType.textColorInsideTotalDonut,
    widgetProperties,
    '#808080',
  );


  const displayLegendLine: boolean =
    getSelectPropertyValue('displayLegendLine', widgetProperties)?.trim() ===
      'Выноски' && isNeedToDisplayLegend;

  const isNeedToDisplayTooltip: boolean = getBooleanPropertyValue(
    'displayTooltip',
    widgetProperties,
  );

  const displayTextInsideChartDown: string = getInputPropertyValue(
    'displayTextInsideChartDown',
    widgetProperties,
  );

  const axisXValuesFull: Array<any> = getActivePanelItems(
    widgetProperties,
    PanelType.axisX,
  );

  const isNeedToDisplayOther: boolean = getBooleanPropertyValue(
    'displayOther',
    widgetProperties,
  );

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const legendPosition: LegendPositions = useMemo(
    () =>
      getInputPropertyValue(
        'legendPositionPieChart',
        widgetProperties,
      ) as LegendPositions,
    [widgetProperties],
  );

  const otherFieldLabel = 'Прочее';
  const otherFieldOperation = 'NOT IN';
  const defaultFieldOperation = '=';

  const filterOtherWidgetData = (data: Array<ArcValue>): Array<ArcValue> => {
    return data.filter((el: ArcValue) => el.label !== otherFieldLabel);
  };

  const widgetData: Array<ArcValue> = isNeedToDisplayOther
    ? widgetProps?.data.data || previewData.data
    : filterOtherWidgetData(widgetProps?.data.data) || filterOtherWidgetData(previewData.data);

  const deselectArc = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const selectArc = useCallback(
    (label: any) => {
      if (!enableEvents) return;
      const isClickOtherField = label === otherFieldLabel;

      const isOtherField =
        filterField.operation === otherFieldOperation && isClickOtherField;

      if (filterField?.value?.includes(label) || isOtherField) {
        deselectArc();
      } else {
        if (!setFilterField) return;

        const value: string[] = isClickOtherField
          ? widgetData
            .map((item) => item.label)
            .filter((label) => label !== otherFieldLabel)
          : [label];

        setFilterField(widgetProps.id, new Property(axisXValuesFull[0]).getId(), [
          {
            operation: isClickOtherField
              ? otherFieldOperation
              : defaultFieldOperation,
            value,
          },
        ]);
      }
    },
    [
      axisXValuesFull,
      deselectArc,
      enableEvents,
      filterField,
      setFilterField,
      widgetData,
      widgetProps.id,
    ],
  );

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );
  currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || currentColors;

  const diameter = radius * 2;
  const centerY = radius;
  const centerX = radius;

  const labels = Object.values(widgetData).map(
    (item) => item.label,
  ) as string[];

  const ordinal = {
    domain: labels,
    range: currentColors as any[],
  };

  const percent = (d: Value) => d.percent;

  const ordinalColorScale = scaleOrdinal(ordinal);

  const { width: containerForRadiusWidth, height: containerForRadiusHeight } =
  containerRef.current.getBoundingClientRect();

  const { calculateDimensions } = useCalculateRadiusDimensions({
    containerRef,
    containerForRadiusWidth,
    containerForRadiusHeight,
    legendPosition,
    widgetContainerPadding,
    isNeedToDisplayLegend,
    radius,
    setRadius,
    displayLegendLine,
    maxLineContainerHeight,
    maxLineContainerWidth,
  });

  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
  }, [
    calculateDimensions,
    isFullScreen,
    isNeedToDisplayLegend,
  ]);

  if (width < donutThickness) return null;

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(containerRef);

  const widgetTooltipArcProps = {
    showWidgetTooltip: handleWidgetMouseMove,
    hideWidgetTooltip: handleWidgetMouseLeave,
    categoryName,
    valueName,
    isNeedToDisplayTooltip,
  };

  const containerWidth = displayLegendLine
    ? diameter + maxLineContainerWidth * 2
    : diameter;
  const containerHeight = displayLegendLine
    ? diameter + maxLineContainerHeight * 2
    : diameter;
  const containerTransitionTop = displayLegendLine
    ? centerY + maxLineContainerHeight
    : centerY;
  const containerTransitionLeft = displayLegendLine
    ? centerX + maxLineContainerWidth
    : centerX;

  const innerRadius =
    radius > donutThickness ? radius - donutThickness : minDonutThickness;

  const isLegendOnSide =
    isNeedToDisplayLegend &&
    !displayLegendLine &&
    [LegendPositions.Left, LegendPositions.Right].includes(legendPosition);

  const legendHorisontal = isNeedToDisplayLegend &&
  (legendPosition === LegendPositions.Up ||
    legendPosition === LegendPositions.Down);

  const displayedData = widgetData.filter((data) => data.count !== 0);

  return (
    <div
      className="widget-donut-container"
      style={{
        flexDirection:
          legendPosition === LegendPositions.Up ? 'column' : 'column-reverse',
        alignItems: 'center',
        margin: '0 auto',
        width: '100%',
      }}
    >
      {isNeedToDisplayLegend &&
        [LegendPositions.Up, LegendPositions.Down].includes(legendPosition) &&
        !displayLegendLine && (
          <LegendArc
            widgetProperties={widgetProperties}
            colors={currentColors}
            data={widgetData}
            isSidePosition={false}
          />
      )}
      <div
        style={{
          height: '100%',
          minHeight: '150px',
          minWidth: isNeedToDisplayLegend &&
            (legendPosition === LegendPositions.Left ||
              legendPosition === LegendPositions.Right)
            ? '300px'
            : '150px',
          padding: `8px ${widgetContainerPadding}px 0`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: (legendHorisontal || !isNeedToDisplayLegend) && isFullScreen ? '50%' : '100%',
        }}
        ref={containerRef}
        onClick={deselectArc}
      >
        <Box
          p={`${donutOffset} + px`}
          display="flex"
          alignItems="center"
          position="relative"
          height="100%"
          flexDirection={
            legendPosition === LegendPositions.Left ? 'row-reverse' : 'row'
          }
          width="100%"
          justifyContent="center"
        >
          <Box>
            <svg width={containerWidth} height={containerHeight}>
              <Group
                top={containerTransitionTop}
                left={containerTransitionLeft}
              >
                <Pie
                  data={displayedData}
                  pieValue={percent}
                  outerRadius={radius}
                  innerRadius={innerRadius}
                  padAngle={0.02}
                >
                  {(pie) => (
                    <Arc<Value>
                      {...pie}
                      animate={animate}
                      getKey={(arc) => arc.data.label}
                      getColor={(arc) => ordinalColorScale(arc.data.label)}
                      widgetTooltipArcProps={widgetTooltipArcProps}
                      getIsSelected={(arc) =>
                        filterField?.operation === otherFieldOperation
                          ? arc.data.label === otherFieldLabel
                          : filterField?.value === null ||
                            filterField?.value === undefined ||
                            filterField?.value?.includes(arc.data.label)}
                      onClickDatum={selectArc}
                      widgetProperties={widgetProperties}
                      radius={radius}
                      innerRadius={innerRadius}
                      displayLegendLine={displayLegendLine}
                      isActiveFilter={isActiveFilter}
                    />
                  )}
                </Pie>
              </Group>
            </svg>

            {isNeedToDisplayValueInsideChart && (
              <div
                className="donut-center__content"
                style={{ width: (radius - donutThickness) * 2 }}
              >
                <CustomTooltip
                  title={valueFormat(
                    previewData.total,
                    0,
                    setPropForNumberValue(widgetProperties),
                    PanelType.axisY,
                    false,
                    false,
                    scaleByNumber ? formatByNumber : null,
                    true
                  )}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom"
                >
                  <div
                    className="donut-center__text"
                    style={{
                      fontSize: valueSizeTotalDonut,
                      lineHeight: 1.25,
                      color: valueColorTotalDonut,
                    }}
                  >
                    {valueFormat(
                      previewData.total,
                      0,
                      setPropForNumberValue(widgetProperties),
                      PanelType.axisY,
                      false,
                      false,
                      scaleByNumber ? formatByNumber : null,
                      true
                    )}
                  </div>
                </CustomTooltip>
                <div
                  className="donut-center__description"
                  style={{
                    fontSize: textSizeInsideTotalDonut,
                    lineHeight: 1.25,
                    color: textColorInsideTotalDonut,
                  }}
                >
                  {displayTextInsideChartDown}
                </div>
              </div>
            )}
          </Box>
          {isLegendOnSide && !displayLegendLine && (
            <LegendArc
              widgetProperties={widgetProperties}
              colors={currentColors}
              data={widgetData}
              isSidePosition={true}
              style={{
                width: 'auto',
                marginLeft:
                  legendPosition === LegendPositions.Left ? '0px' : '50px',
                marginRight:
                  legendPosition === LegendPositions.Left ? '50px' : '0px',
                minWidth: 'auto',
                height: '100%',
                overflow: 'auto',
              }}
            />
          )}
        </Box>
      </div>
    </div>
  );
}
