import { batch, useDispatch } from 'react-redux';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { usePivotTableProperties } from '../../hooks/usePivotTableProperties';
import { PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { getParsedAxisValues } from '../../../../dropdown-layout/helpers/helpers';
import { isDashboardPage } from '../../../helpers';
import { PanelType, WidgetPropertyType } from '../../../../../../enums/widget-type';
import { useDashboardFilters } from '../../../../../dashboard-page/hooks/useDashboardFilters';
import { removeWidgetPropAction, setWidgetPropAction } from '../../../../../../slices/widget/widget';
import { HeadButtonCellProps } from '../../interfaces';
import { getHeadRowsButtonTitle } from '../../helper';

export const useHeadButtonCell = ({
  panelType,
  widgetProperties,
  setDashboardWidgetState,
}: HeadButtonCellProps) => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const {
    headBackgroundColor,
    headTextColor,
    headerSize,
    scaleWidget,
    columnWidthType,
  } = usePivotTableProperties({
    widgetProperties,
  });

  const axisValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(panelType, widgetProperties),
    [panelType, widgetProperties],
  );
  const buttonTitle = useMemo(
    () => getHeadRowsButtonTitle(axisValues),
    [axisValues],
  );

  const widgetFilters: PropertyData[] = getParsedAxisValues(
    PanelType.axisFilter,
    widgetProperties,
  );

  const {
    isEnableFilterOnDashboard,
  } = useDashboardFilters();

  const filteredAxisValues: PropertyData[] = useMemo(
    () => axisValues.filter((value) => {
      const isDashboard = isDashboardPage();
      const currentFilter = widgetFilters.find((filter) => filter.etlFieldId === value.etlFieldId);
      return isDashboard ? !isEnableFilterOnDashboard(currentFilter?.id || currentFilter?.widgetFieldId || 0) : true;
    }),
    [axisValues, isEnableFilterOnDashboard, widgetFilters],
  );

  const isMultiline = panelType === PanelType.axisX;

  const [currentFieldId, setCurrentFieldId] = useState<string>(
    axisValues[0]?.etlFieldId,
  );

  const [openedActions, setOpenedActions] = useState<boolean>(false);
  const [popoverPosition, setPopoverPosition] = useState<{
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0,
  });
  const openActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenedActions(true);
    setPopoverPosition({ top: event.clientY, left: event.clientX });
  };
  const closeActions = () => setOpenedActions(false);

  const onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFieldId(event.target.value);
  };

  const onChangeSorting = useCallback(
    (etlFieldId: string, value: string | null) => {

      const newAxisValues: PropertyData[] = [...axisValues];
      const currentValueIndex = axisValues.findIndex(
        (item) => item.etlFieldId === etlFieldId,
      );

      newAxisValues[currentValueIndex].orderDirection = value;

      if (isDashboardPage()) {
        setDashboardWidgetState &&
        setDashboardWidgetState([
          {
            name: panelType,
            value: JSON.stringify(newAxisValues),
          },
          // очищаем дополнительно сортировку по столбцам
          {
            name: WidgetPropertyType.pivotSortingField,
            value: undefined,
          },
        ]);
      } else {
        batch(() => {
          dispatch(
            setWidgetPropAction({
              name: panelType,
              value: JSON.stringify(newAxisValues),
            }),
          );
          // очищаем дополнительно сортировку по строкам
          dispatch(removeWidgetPropAction(WidgetPropertyType.pivotSortingField));
        });
      }
    },
    [axisValues, dispatch, panelType, setDashboardWidgetState],
  );

  const sortingValue = axisValues.filter((item) => !item.hierarchyLink?.dicSourceId).find(
    (item) => item.etlFieldId === currentFieldId,
  );

  const isNeedToDisplaySorting = Boolean(sortingValue);

  const isNeedToDisplayFilters =
    Boolean(filteredAxisValues.length) &&
    Boolean(filteredAxisValues.find((item) => item.etlFieldId === currentFieldId && !item.hierarchyLink?.dicSourceId));

  const isDisabledSettings = !isNeedToDisplayFilters && !isNeedToDisplaySorting;

  return {
    headBackgroundColor,
    headTextColor,
    openedActions,
    anchorRef,
    buttonTitle,
    isMultiline,
    closeActions,
    popoverPosition,
    currentFieldId,
    onChangeField,
    filteredAxisValues,
    axisValues,
    onChangeSorting,
    openActions,
    isNeedToDisplayFilters,
    sortingValue,
    isNeedToDisplaySorting,
    isDisabledSettings,
    headerSize,
    scaleWidget,
    columnWidthType,
  };
};
