export enum PanelItemSetting {
  restriction = 'restriction',
  filterRestriction = 'filterRestriction',
  view = 'view',
  coloring = 'coloring',
  rename = 'rename',
  drilldown = 'drilldown',
  combine = 'combine',
  hierarchy = 'hierarchy',
  link = 'link',
}
