import axios, { CancelTokenSource } from 'axios';
import { apiGetWidgetData } from '../../../services/widgetController';
import { apiDeleteRequests } from '../../../services';
import { setCurrentProjectWidgetDataAction, setCurrentProjectWidgetErrorAction } from '../../../slices/main-page/main-page-slice';
import { Widget } from '../../../slices/types';
import { getParsedAxisValues } from './helpers/helpers';
import { PanelType } from '../../../enums/widget-type';
import { ShowNotificationParams } from '../../common/snackbar/hooks';
import { WIDGETS_WITHOUT_DATA_PROPERTIES } from '../charts/settings';

interface CreateDataFetcher {
  dispatch: any;
  widget: Widget;
  showNotification: ({ message, variant }: ShowNotificationParams) => void;
  callCancellable: <T extends Object, K extends Object>(request: (parameters: T)
  => Promise<any>, params: K, groupName?: string) => Promise<any>

  requestId: string
}
interface Fetcher {
  isNeedReset: boolean;
  cancelSource: CancelTokenSource;
}

// Workaround: BIDEV-3489 для сортировки - если есть сортировка в axisY,
// то ее нужно убрать в axisX
// так же WA есть на дашборде в widgetOverriddenProperties
const getWidgetFixOrderingWA = (widget: Widget) => {
  const axisXIndex = widget.properties.findIndex(
    (prop) => prop.name === PanelType.axisX,
  );
  const axisX: any[] = JSON.parse(widget.properties[axisXIndex]?.value || '[]');

  const axisY: any[] = getParsedAxisValues(PanelType.axisY, widget.properties);

  const widgetPropertiesCopy = [...widget.properties];
  widgetPropertiesCopy.splice(axisXIndex, 1, {
    name: PanelType.axisX,
    value: JSON.stringify(
      axisX.map((field) => ({ ...field, orderDirection: null })),
    ),
  });
  const newWidgetProperties =
    axisXIndex >= 0 &&
    axisY.some((field) => ['ASC', 'DESC'].includes(field.orderDirection))
      ? widgetPropertiesCopy
      : [...widget.properties];

  return { ...widget, properties: newWidgetProperties };
};

export const createDataFetcher = ({
  dispatch,
  widget,
  showNotification,
  callCancellable,
  requestId
}: CreateDataFetcher) => {
  return ({
    isNeedReset,
    cancelSource,
  }: Fetcher): { promise: Promise<any> } => {
    if (isNeedReset) {
      dispatch(setCurrentProjectWidgetDataAction(null));
      dispatch(setCurrentProjectWidgetErrorAction(''));
      return {
        promise: Promise.resolve(null),
      };
    }

    // Workaround: BIDEV-3489 для сортировки
    const newWidget = getWidgetFixOrderingWA(widget);
    const promise = callCancellable(apiGetWidgetData, { widget: newWidget });

    promise
      .then((result: any) => {
        const isDataWithProperties = !WIDGETS_WITHOUT_DATA_PROPERTIES.includes(newWidget.type);
        dispatch(setCurrentProjectWidgetDataAction(isDataWithProperties ? result : { properties: [], data: result }));
      })
      .catch((err) => {
        dispatch(setCurrentProjectWidgetErrorAction(err?.response?.data?.message || ''));
        if (err?.response?.data?.status === 500) {
          const message = err.response.data.message?.match(/\$%(.*)\$%/)?.[1];

          showNotification({
            message: `Ошибка предпросмотра${ message ? `: ${  message}` : '' }`,
            variant: 'error',
          });
        }
        if (!axios.isCancel(err)) {
          dispatch(setCurrentProjectWidgetDataAction(null));
        }

        throw err;
      });

    return {
      promise,
    };
  };
};
