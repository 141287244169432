import { RefObject } from 'react';
import { dispatchRootEvent } from '../utils/root-helper';
import { useEventListener } from './useEventListener';

export function useSynchronizeWidgetScrollListener<
  T extends HTMLElement = HTMLDivElement,
>(element: RefObject<T>, widgetId: number) {
  useEventListener(
    'scroll',
    () => {
      const scrollShift = element.current!.scrollLeft;
      const event = new CustomEvent('scrollWidget', {
        detail: {
          widgetId,
          scrollShift,
          currentWidget: element.current,
        },
      });
      dispatchRootEvent(event);
    },
    element,
  );

  useEventListener('scrollWidget', (e: any) => {
    if (
      e.detail.widgetId === widgetId &&
      e.detail.currentWidget !== element.current
    ) {
      const el = element;
      el.current!.scrollLeft = e.detail.scrollShift;
    }
  });
}
