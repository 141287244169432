import { shallowEqual, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { compact } from 'lodash';
import { State } from '../../../slices/types';
import { DashboardPropertyType } from '../../../enums/dashboard-properties';

export const useDashboardWidgetRequestParams = () => {
  const currentDashboard = useSelector(
    (state: State) => state.dashboard,
    shallowEqual,
  );

  return useMemo(() => {
    const filters = currentDashboard.properties.find(
      (item: any) => item.name === DashboardPropertyType.filters,
    );

    const autoBoundGroups = currentDashboard.properties.find(
      (item: any) => item.name === DashboardPropertyType.autoBoundGroups,
    );

    const manualBoundGroups = currentDashboard.properties.find(
      (item: any) => item.name === DashboardPropertyType.manualBoundGroups,
    );

    const autoDrillDownGroups = currentDashboard.properties.find(
      (item: any) => item.name === DashboardPropertyType.autoDrillDownGroups,
    );

    const manualDrillDownGroups = currentDashboard.properties.find(
      (item: any) => item.name === DashboardPropertyType.manualDrillDownGroups,
    );

    return compact([
      filters,
      autoBoundGroups,
      manualBoundGroups,
      autoDrillDownGroups,
      manualDrillDownGroups,
    ]);
  }, [currentDashboard.properties]);
};
