import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { EntityId } from '@reduxjs/toolkit/src/entities/models';
import { LoadingStatus, ParameterType } from '../../enums/parameters';
import {
  apiAddParameter,
  apiDeleteParameter,
  apiEditParameter,
  apiEditParameterValues,
  apiEditParameterValuesOnDashboard,
  apiGetGlobalParameters,
  apiGetParameters, apiGetParametersOnDashboard,
  apiGetParameterValidators,
} from '../../services/parameters';
import {
  GlobalParameter,
  ParametersState,
  ParameterValidator,
  ProjectParameter,
  ProjectParameterValue,
  State,
} from '../types';

const sliceName = 'parameters';

// Adapters

const globalParametersAdapter = createEntityAdapter<GlobalParameter>({
  selectId: (parameter) => parameter.parameterCode,
});
const projectParametersAdapter = createEntityAdapter<ProjectParameter>({
  selectId: (parameter) => parameter.parameterId,
  sortComparer: (parameter) => parameter.parameterId,
});
const validatorsAdapter = createEntityAdapter<ParameterValidator>({
  selectId: (validator) => validator.type,
});

const initialState: ParametersState = {
  isOpenParametersPanel: false,
  isOpenCreateParameterModal: false,
  globalParameters: globalParametersAdapter.getInitialState({
    status: LoadingStatus.pending,
  }),
  projectParameters: projectParametersAdapter.getInitialState({
    status: LoadingStatus.pending,
    fetchDbStatus: LoadingStatus.pending,
    addingStatus: LoadingStatus.pending,
    editingStatus: LoadingStatus.pending,
    editingValuesStatus: LoadingStatus.pending,
    deletingStatus: LoadingStatus.pending,
  }),
  validators: validatorsAdapter.getInitialState({
    status: LoadingStatus.pending,
  }),
};

// Thunks

export const fetchGlobalParameters = createAsyncThunk(
  `${sliceName}/fetchGlobalParameters`,
  apiGetGlobalParameters,
);

export const fetchProjectParameters = createAsyncThunk(
  `${sliceName}/fetchProjectParameters`,
  apiGetParameters,
);

export const fetchDashboardParameters = createAsyncThunk(
  `${sliceName}/fetchDashboardParameters`,
  apiGetParametersOnDashboard,
);

export const saveNewParameter = createAsyncThunk(
  `${sliceName}/saveNewProjectParameter`,
  async ({
    projectId,
    newParameter,
  }: {
    projectId: number;
    newParameter: Omit<ProjectParameter, 'parameterId'>;
  }) => {
    return apiAddParameter(projectId, newParameter);
  },
);

export const editParameter = createAsyncThunk(
  `${sliceName}/editParameter`,
  apiEditParameter,
);

export const deleteParameter = createAsyncThunk(
  `${sliceName}/deleteParameter`,
  apiDeleteParameter,
);

export const editParameterValues = createAsyncThunk(
  `${sliceName}/editParameterValues`,
  async ({
    projectId,
    dashboardId,
    parameterValues,
  }: {
    projectId: number;
    dashboardId?: number;
    parameterValues: ProjectParameterValue[];
  }) => {
    dashboardId
      ? await apiEditParameterValuesOnDashboard(dashboardId, parameterValues)
      : await apiEditParameterValues(projectId, parameterValues);

    return parameterValues.map((parameterValue) => ({
      parameterId: parameterValue.parameterId,
      ...(dashboardId
        ? { dashboardValue: parameterValue.value }
        : { value: parameterValue.value }),
    }));
  },
);

export const fetchValidators = createAsyncThunk(
  `${sliceName}/fetchValidators`,
  async (arg, { getState, dispatch }) => {
    const state = getState() as State;
    const isLoaded = selectIsValidatorsParameterLoaded(state);
    if (isLoaded) {
      return selectParameterValidators(state);
    }
    dispatch(setValidatorsStatusLoadingAction);

    return apiGetParameterValidators();
  },
);

const parametersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearProjectParameters: (state) => {
      projectParametersAdapter.removeAll(state.projectParameters);
    },
    switchOpenParametersPanel: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isOpenParametersPanel =
        payload === undefined ? !state.isOpenParametersPanel : payload;
    },
    switchOpenAddParameterModal: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isOpenCreateParameterModal =
        payload === undefined ? !state.isOpenCreateParameterModal : payload;
    },
    setValidatorsStatusLoading: (state) => {
      state.validators.status = LoadingStatus.loading;
    },
  },
  extraReducers: (builder) => {
    builder
      // Global Parameters
      .addCase(fetchGlobalParameters.pending, (state) => {
        state.globalParameters.status = LoadingStatus.loading;
      })
      .addCase(fetchGlobalParameters.fulfilled, (state, action) => {
        globalParametersAdapter.setAll(state.globalParameters, action.payload);
        state.globalParameters.status = LoadingStatus.loaded;
      })

      // Fetch Project Parameters
      .addCase(fetchProjectParameters.pending, (state) => {
        state.projectParameters.status = LoadingStatus.loading;
      })
      .addCase(fetchProjectParameters.fulfilled, (state, action) => {
        projectParametersAdapter.setAll(
          state.projectParameters,
          action.payload,
        );
        state.projectParameters.status = LoadingStatus.loaded;
      })
      .addCase(fetchProjectParameters.rejected, (state) => {
        state.projectParameters.status = LoadingStatus.loaded;
      })

      // Fetch Dashboard Parameters
      .addCase(fetchDashboardParameters.pending, (state) => {
        state.projectParameters.fetchDbStatus = LoadingStatus.loading;
      })
      .addCase(fetchDashboardParameters.fulfilled, (state, action) => {
        const data: ProjectParameter[] = action.payload.map(({ value, ...parameter }) => ({
          ...parameter,
          dashboardValue: value,
        }));
        projectParametersAdapter.setAll(
          state.projectParameters,
          data
        );
        state.projectParameters.fetchDbStatus = LoadingStatus.loaded;
      })
      .addCase(fetchDashboardParameters.rejected, (state) => {
        state.projectParameters.fetchDbStatus = LoadingStatus.loaded;
      })

      // Add Project Parameter
      .addCase(saveNewParameter.pending, (state) => {
        state.projectParameters.addingStatus = LoadingStatus.loading;
      })
      .addCase(saveNewParameter.fulfilled, (state, action) => {
        projectParametersAdapter.addOne(
          state.projectParameters,
          action.payload,
        );
        state.projectParameters.addingStatus = LoadingStatus.loaded;
      })
      .addCase(saveNewParameter.rejected, (state) => {
        state.projectParameters.addingStatus = LoadingStatus.loaded;
      })

      // Edit Parameter Values
      .addCase(editParameterValues.pending, (state) => {
        state.projectParameters.editingValuesStatus = LoadingStatus.loading;
      })
      .addCase(editParameterValues.fulfilled, (state, action) => {
        projectParametersAdapter.updateMany(
          state.projectParameters,
          action.payload.map((parameter) => ({
            id: parameter.parameterId,
            changes: parameter,
          })),
        );
        state.projectParameters.editingValuesStatus = LoadingStatus.loaded;
      })
      .addCase(editParameterValues.rejected, (state) => {
        state.projectParameters.editingValuesStatus = LoadingStatus.loaded;
      })

      // Parameter Validators
      .addCase(fetchValidators.fulfilled, (state, action) => {
        validatorsAdapter.setAll(state.validators, action.payload);
        state.validators.status = LoadingStatus.loaded;
      })

      // Edit Parameter
      .addCase(editParameter.pending, (state, action) => {
        state.projectParameters.editingStatus = LoadingStatus.loading;
      })
      .addCase(editParameter.fulfilled, (state, action) => {
        projectParametersAdapter.updateOne(state.projectParameters, {
          id: action.payload.parameterId,
          changes: action.payload,
        });
        state.projectParameters.editingStatus = LoadingStatus.loaded;
      })
      .addCase(editParameter.rejected, (state, action) => {
        state.projectParameters.editingStatus = LoadingStatus.loaded;
      })

      // Delete Parameter
      .addCase(deleteParameter.pending, (state, action) => {
        state.projectParameters.deletingStatus = LoadingStatus.loading;
      })
      .addCase(deleteParameter.fulfilled, (state, action) => {
        projectParametersAdapter.removeOne(
          state.projectParameters,
          action.payload,
        );
        state.projectParameters.deletingStatus = LoadingStatus.loaded;
      })
      .addCase(deleteParameter.rejected, (state, action) => {
        state.projectParameters.deletingStatus = LoadingStatus.loaded;
        state.projectParameters.deletingWarning = action.error.message;
      });
  },
});

// Reducers

export const parametersReducer = parametersSlice.reducer;

export const {
  switchOpenParametersPanel: switchOpenParametersPanelAction,
  switchOpenAddParameterModal: switchOpenAddParameterModalAction,
  clearProjectParameters: clearProjectParametersAction,
  setValidatorsStatusLoading: setValidatorsStatusLoadingAction,
} = parametersSlice.actions;

// Selectors

export const selectIsGlobalParametersLoading = (state: State) =>
  state[sliceName].globalParameters.status === LoadingStatus.loading;
export const selectIsProjectParametersLoading = (state: State) =>
  state[sliceName].projectParameters.status === LoadingStatus.loading;
export const selectIsAddingProjectParameterLoading = (state: State) =>
  state[sliceName].projectParameters.addingStatus === LoadingStatus.loading;
export const selectIsEditingValuesLoading = (state: State) =>
  state[sliceName].projectParameters.editingValuesStatus === LoadingStatus.loading;
export const selectIsValidatorsParameterLoaded = (state: State) =>
  state[sliceName].validators.status === LoadingStatus.loaded;
export const selectIsDeletingProjectParameterLoading = (state: State) =>
  state[sliceName].projectParameters.deletingStatus === LoadingStatus.loading;

export const { selectAll: selectGlobalParameters } =
  globalParametersAdapter.getSelectors(
    (state: State) => state[sliceName].globalParameters,
  );
export const {
  selectAll: selectProjectParameters,
} = projectParametersAdapter.getSelectors(
  (state: State) => state[sliceName].projectParameters,
);
export const {
  selectAll: selectParameterValidators,
  selectEntities: selectParameterValidatorsEntities,
  selectIds: selectParameterValidatorsKeys,
} = validatorsAdapter.getSelectors(
  (state: State) => state[sliceName].validators,
);
