import React, { useState } from 'react';
import './styles.css';
import { Box } from '@material-ui/core';
import { DropzoneOptions } from 'react-dropzone';
import block from 'bem-cn';
import { useDispatch } from 'react-redux';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import { Dropzone } from '../dropzone';
import {
  apiUpdateLocalFtpFile,
  apiUploadFile,
} from '../../../services/sources';
import { connectionsActions } from '../../../slices/connections/connections';
import Connection from '../../../types/connection';
import { CustomButton } from '../../../uikit/Button';
import { CustomProgress } from 'src/uikit/Progress';
import { useNotificator } from '../snackbar/hooks';

type UploadFileDialogProps = {
  fileForUpdate?: Connection.FileFtp | null;
  isOpen: boolean;
  handleClose: () => any;
  groupId: number;
  afterUploadCallback?: () => any;
};

const FILE_EXISTS_TEST_ERROR = 'Файл уже существует';

const b = block('upload-file-dialog');

export const UploadFileDialog = ({
  fileForUpdate,
  isOpen,
  handleClose,
  groupId,
  afterUploadCallback,
}: UploadFileDialogProps) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isWarningOnUpdate, setWarningOnUpdate] = useState(false);
  const [isWarningOnFirstUpload, setWarningOnFirstUpload] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [requestErrorMessage, setRequestErrorMessage] = useState('');

  const [fileForUpload, setFileForUpload] = useState<File | null>(null);
  const [errorDropping, setErrorDropping] = useState('');

  const handleDropAccepted: <T extends File>(files: T[]) => void = (
    acceptedFiles,
  ) => {
    setFileForUpload(acceptedFiles[0]);
    setErrorDropping('');
    setWarningOnFirstUpload(false);
    setWarningOnUpdate(false);
  };
  const handleDropRejected: <T extends File>() => void = () => {
    setFileForUpload(null);
    setWarningOnFirstUpload(false);
    setWarningOnUpdate(false);
    setErrorDropping('Этот файл нельзя добавить');
  };

  const closeDialog = () => {
    handleClose();
    setLoading(false);
    setFileForUpload(null);
    setWarningOnFirstUpload(false);
    setWarningOnUpdate(false);
  };

  const uploadFileFtp = () => {
    if (fileForUpload) {
      setLoading(true);
      if (fileForUpdate?.id) {
        apiUpdateLocalFtpFile(fileForUpdate?.id, fileForUpload)
          .then((response) => {
            if (response.isWarning) {
              setErrorMessage(response.data);
              showNotification({
                message:  'Не удалось загрузить файл, обратитесь к администратору системы',
                variant: 'error',
              })
              setWarningOnUpdate(true);
            } else {
              closeDialog();
              dispatch(connectionsActions.getFullConnectionsAction(groupId));
              afterUploadCallback && afterUploadCallback();
            }
          })
          .catch((error) =>
            showNotification({
              message:  'Не удалось загрузить файл, обратитесь к администратору системы',
              variant: 'error',
            })
          )
          .finally(() => setLoading(false));
      } else {
        apiUploadFile(groupId, fileForUpload, isWarningOnFirstUpload)
          .then((response) => {
            if (response.isWarning) {
              setErrorMessage(response.data);
              showNotification({
                message:  'Не удалось загрузить файл, обратитесь к администратору системы',
                variant: 'error',
              })
              setWarningOnFirstUpload(true);
            } else {
              closeDialog();
              dispatch(connectionsActions.getFullConnectionsAction(groupId));
              afterUploadCallback && afterUploadCallback();
            }
          })
          .catch((error) =>
            showNotification({
              message:  'Не удалось загрузить файл, обратитесь к администратору системы',
              variant: 'error',
            })
          )
          .finally(() => setLoading(false));
      }
    }
  };

  const dropzoneOptions: DropzoneOptions = {
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    accept: '.xlsx, .xls, .csv',
    maxFiles: 1,
  };

  const titleUploadFile = fileForUpdate?.name
    ? `Обновление файла ${fileForUpdate?.name}`
    : dropzoneOptions?.maxFiles === 1
    ? 'Импорт файла'
    : 'Импорт файлов';

  const { showNotification } = useNotificator();

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={closeDialog}
      title={titleUploadFile}
      maxWidth={false}
      fullWidth={false}
    >
      <Dropzone options={dropzoneOptions} isShowAccepted />
      {errorDropping && (
        <>
          <br />
          <span className={b('error')}>{errorDropping}</span>
        </>
      )}
      {isWarningOnFirstUpload && (
        <Box mb={1}>
          <p className={b('error')}>{errorMessage}</p>
          {errorMessage === FILE_EXISTS_TEST_ERROR && (
            <span className={b('link')} onClick={uploadFileFtp}>
              Обновить файл
            </span>
          )}
        </Box>
      )}
      {isWarningOnUpdate && (
        <Box mb={1}>
          <p className={b('error')}>{errorMessage}</p>
        </Box>
      )}
      <div className="dialog-buttons">
        <CustomButton
          variant="contained"
          onClick={uploadFileFtp}
          type="submit"
          loading={isLoading}
          startIcon={
            isLoading ? (
              <CustomProgress
                type="circular"
                style={{ color: 'var(--white)' }}
                size={20}
              />
            ) : null
          }
          disabled={
            !fileForUpload ||
            isLoading ||
            isWarningOnFirstUpload ||
            isWarningOnUpdate
          }
        >
          {fileForUpdate?.id ? 'Обновить' : 'Импортировать'}
        </CustomButton>

        <CustomButton variant="outlined" type="reset" onClick={closeDialog}>
          Отменить
        </CustomButton>
      </div>
    </CustomDialog>
  );
};
