import React from 'react';
import { CustomButton } from 'src/uikit/Button';
import { useMoreButton } from './useMoreButton';
import Dictionaries from '../../../../../types/dictionaries';
import { Property } from '../../../dropdown-layout/helpers/Property';
import { WidgetProperties } from '../../../../../slices/types';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../common/icon-svg/icon-svg';

export interface MoreButtonProps {
  functionsAndAggregations: Dictionaries.FunctionOrAggregationObject;
  propertyInstance: Property;
  panel: WidgetProperties;
  handleMoreButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const MoreButton = ({
  functionsAndAggregations,
  propertyInstance,
  panel,
  handleMoreButtonClick
}: MoreButtonProps) => {
  const {
    isShowMoreButton,
  } = useMoreButton({
    functionsAndAggregations,
    propertyInstance,
    panel,
    handleMoreButtonClick
  });

  return (
    <>
      {isShowMoreButton && (
        <CustomButton
          onClick={handleMoreButtonClick}
          variant="text"
          size="small"
          style={{
            padding: '4px 12px',
            marginLeft: 'auto',
            height: 'auto'
          }}
        >
          <IconSvg
            svg={IconDictionary.HorizontalMore}
            fill="var(--dark-grey)"
            height={20}
          />
        </CustomButton>
      )}
    </>
  );
};
