import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  changeGroupName,
  createNewGroup,
  getAllGroups,
} from '../../../services';
import {
  createNewGroupAction,
  setGroupsAction,
} from '../../../slices/group-slice/group-slice';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { State } from '../../../slices/types';
import { CustomButton } from '../../../uikit/Button';

type GroupInputProps = {
  handleCloseForm?: () => void;
  handleCreateNewGroup?: (groupId: number) => void;
  modalPurpose: string;
  groupId?: number | null;
};

const ERROR_DEFAULT_TEXT = 'Ошибка при создании группы';
const ERROR_CHANGE_NAME_DEFAULT_TEXT = 'Ошибка при изменении имени группы';
const ERROR_INVALID_NAME_TEXT = 'Недопустимое имя группы';
const FAVOURITE_GROUP_NAME = 'Избранное';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: 'calc(16px * var(--scale-coefficient))',
      },
      padding: 'calc(20px * var(--scale-coefficient))',
    },
    buttonCreate: {
      marginRight: '10px',
    },
    fullWidth: {
      width: '100%',
    },
  }),
);

const GroupInput: React.FC<GroupInputProps> = ({
  groupId,
  modalPurpose,
  handleCloseForm,
  handleCreateNewGroup,
}) => {
  const groups = useSelector((state: State) => state.groups.group);
  const currentGroupName =
    groups.find((group) => group?.groupId === groupId)?.groupName || '';

  const getInitialName = () => {
    return modalPurpose === 'create' ? '' : currentGroupName;
  };

  const [name, setName] = useState(getInitialName());
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const classes = useStyles();

  const filters = useSelector((state: State) => state.mainPage.filters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setError('');
    setName(event.target.value);
  };

  const isNameValid = name !== FAVOURITE_GROUP_NAME;

  const handleSubmitCreate = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setError('');

    if (!name.length) return;

    if (!isNameValid) {
      setError(ERROR_INVALID_NAME_TEXT);
      return;
    }

    createNewGroup(name)
      .then((newGroup) => {
        dispatch(createNewGroupAction(newGroup));
        setName('');
        handleCreateNewGroup && handleCreateNewGroup(newGroup.groupId);
        handleCloseForm && handleCloseForm();
      })
      .catch((err) => {
        console.error(ERROR_DEFAULT_TEXT, err.response);
        setError(err.response?.data?.message || ERROR_DEFAULT_TEXT);
      });
  };

  const handleSubmitChange = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (!isNameValid) {
      setError(ERROR_INVALID_NAME_TEXT);
      return;
    }

    if (groupId) {
      changeGroupName(groupId, name)
        .then(() => {
          getAllGroups(filters).then((response) => {
            dispatch(setGroupsAction(response?.data));
          });
          setName('');
          handleCloseForm && handleCloseForm();
        })
        .catch((err) => {
          console.error(ERROR_CHANGE_NAME_DEFAULT_TEXT, err.response);
          setError(
            err.response?.data?.message || ERROR_CHANGE_NAME_DEFAULT_TEXT,
          );
        });
    }
  };

  return (
    <div>
      <form
        className={classes.root}
        onSubmit={
          modalPurpose === 'create' ? handleSubmitCreate : handleSubmitChange
        }
        autoComplete="off"
      >
        <TextInput
          error={!!error}
          label="Имя группы"
          className={classes.fullWidth}
          value={name}
          handleChange={handleChange}
        />

        {error && (
          <Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {error}
          </Alert>
        )}

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton variant="contained" type="submit">
            {modalPurpose === 'create' ? 'Создать' : 'Сохранить'}
          </CustomButton>
          <CustomButton variant="outlined" onClick={handleCloseForm}>
            Отменить
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default GroupInput;
