import React from 'react';
import { WidgetDropdownItem } from '../widget-dropdown-item/widget-dropdown-item';
import { EmptyPanel } from '../empty-panel';
import { useWidgetDropdownMainContainer } from './useWidgetDropdownMainContainer';

export interface WidgetDropdownMainContainerProps {
  id: string;
  name: string;
}

export const WidgetDropdownMainContainer = ({
  id,
  name,
}: WidgetDropdownMainContainerProps) => {
  const { dataItemList } = useWidgetDropdownMainContainer({ id, name });

  return (
    <div className="widget-dropdown">
      <span className="widget-dropdown__label">{name}</span>

      {dataItemList ? (
        <div className="widget-dropdown-item__container">
          {dataItemList.map((panel) => {
            return (
              <WidgetDropdownItem
                key={panel.name}
                panel={panel}
                panelType={id}
              />
            );
          })}
        </div>
      ) : (
        <EmptyPanel />
      )}
    </div>
  );
};
