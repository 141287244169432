import React from 'react';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import GroupMenu from '../../group-menu/group-menu';
import { useGroupTitlePanelButtons } from './useGroupTitlePanelButtons';
import { Roles } from '../../../../enums/roles';
import { CommonDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface GroupTitlePanelButtonsProps {
  permission: Roles;
  projectsCount: number;
  groupId: number;
  isShowGroup: boolean;
  handleClickChange: () => void;
  handleClickDelete: () => void;
  handleClickCreate: () => void;
}

export const GroupTitlePanelButtons = ({
  permission,
  projectsCount,
  groupId,
  isShowGroup,
  handleClickChange,
  handleClickDelete,
  handleClickCreate,
}: GroupTitlePanelButtonsProps) => {
  const {
    rolesAccess,
    handleCreateLoaderClick,
    anchorEl,
    handleMenuClick,
    openMenu,
    handleCloseMenu,
  } = useGroupTitlePanelButtons({
    permission, projectsCount, groupId, isShowGroup, handleClickChange,
    handleClickDelete,
    handleClickCreate,
  });

  return (
    <>
      {rolesAccess.isShowCreateProjectButton && (
        <CustomTooltip
          title={`Создать ${CommonDictionary.projectAccusative}`}
          arrow
          placement='top'
        >
          <div>
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.AddProject} width={24} height={24} fill='var(--primary-color)' />,
                onClick: handleCreateLoaderClick,
              }}
              type={IconButtonType.primary}
            />
          </div>
        </CustomTooltip>
      )}
      <CustomIconButton
        iconButtonProps={{
          children: <IconSvg svg={IconDictionary.VerticalMore} width={24} height={24} fill={anchorEl ? 'var(--primary-color)' : 'var(--dark-grey)'} />,
          onClick: handleMenuClick,
          style: { margin: '0 8px' },
        }}
        type={IconButtonType.secondary}
      />

      <CustomPopover
        open={openMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <GroupMenu
          permission={permission}
          handleCloseMenu={handleCloseMenu}
          handleClickChange={handleClickChange}
          handleClickDelete={handleClickDelete}
          id={groupId}
        />
      </CustomPopover>
      <div className={`icon-arrow ${isShowGroup ? 'icon-arrow-up' : null}`}>
        <IconSvg svg={IconDictionary.RoundedArrowToBottom} width={22} height={22} fill={isShowGroup ? 'var(--primary-color)' : 'var(--dark-grey)'} />
      </div>
    </>
  );
};
