import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFilterSettingValue } from '../../../helpers/dashboard-page/filters-helper';
import { DashboardRequest, DashboardResponse, State, Widget } from '../../../slices/types';
import { useNotificator } from '../../common/snackbar/hooks';
import {
  setActivityAction,
  setCurrentGroupWidgetsAction,
  setCurrentProjectDashboardsAction,
  setCurrentProjectWidgetsAction, setDashboardChangedNotificationDisabledAction, callEffectWidgetEditUpdateDataAction,
} from '../../../slices/main-page/main-page-slice';
import {
  apiCreateNewWidget,
  apiGetAllProjectWidgets,
  apiGetGroupWidgets,
  apiUpdateWidget,
} from '../../../services/widgetController';
import {
  setCurrentWidgetAction,
  setWidgetValidationErrorAction,
  resetWidgetValidationErrorsAction
} from '../../../slices/widget/widget';
import {
  apiCreateNewDashboard,
  apiGetAllProjectDashboards,
  apiUpdateDashboard,
} from '../../../services/dashboardController';
import {
  resetDashboardValidationErrorsAction,
  setCurrentDashboardAction,
  setDashboardValidationErrorAction,

} from '../../../slices/dashboard/dashboard';
import { FilterGroupProps } from '../../dashboard-page/hooks';
import { useDashboardBoundFilters } from '../../dashboard-page/hooks/useDashboardBoundFilters';
import { getPropertyIndex, getPropertyValue } from '../../../helpers/dashboard-page';
import { getCorrectWidgetProperties } from '../../../helpers/widget-page/widget-properties-helper';
import { DashboardDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { isDashboardPage, isWidgetPage } from '../charts/helpers';
import { DashboardPropertyType, FilterProperty } from '../../../enums/dashboard-properties';

export const useWidgetSaveButton = () => {
  const currentWidget = useSelector((state: State) => state.widget);
  const currentDashboard = useSelector((state: State) => state.dashboard);
  const projectId: number = useSelector(
    (state: State) => state.mainPage?.currentProject?.id ?? 0,
  );
  const projectGroupId = useSelector(
    (state: State) => state.mainPage?.currentProject?.projectGroupId,
  );
  const { isActive, isWidgetPageSaveDisabled } = useSelector((state: State) => state.mainPage);

  const dispatch = useDispatch();
  const history = useHistory();
  const { showNotification } = useNotificator();

  const {
    getDefaultAutoMultipleFilters
  } = useDashboardBoundFilters();

  const onSaveClick = async () => {

    if (isWidgetPage()) {
      await saveWidget();
    }

    if (isDashboardPage()) {
      saveDashboard();
    }
  };

  const saveWidget = async () => {
    const widget = { ...currentWidget };
    widget.properties = getCorrectWidgetProperties(widget);
    widget.projectId = projectId;

    if (!validateWidget(widget)) return;
    dispatch(resetWidgetValidationErrorsAction());
    dispatch(setActivityAction(false));

    const updateProjectWidgets = () => {
      dispatch(setActivityAction(true));
      apiGetAllProjectWidgets(projectId).then((result: Widget[]) => {
        dispatch(setCurrentProjectWidgetsAction(result));
      });
      apiGetGroupWidgets(Number(projectGroupId)).then((widgets: Widget[]) => {
        dispatch(setCurrentGroupWidgetsAction(widgets));
      });
    };

    if (widget.id) {
      apiUpdateWidget(widget)
        .then((result) => {
          dispatch(setCurrentWidgetAction(result));
          updateProjectWidgets();
          showNotification({ message: 'Сохранено', variant: 'success' });
          dispatch(callEffectWidgetEditUpdateDataAction());
        })
        .catch((error) => {
          const message = error?.response?.data?.message || 'Ошибка, обратитесь к администратору';
          message && showNotification({ message, variant: 'error' });
          dispatch(setActivityAction(true));
        });
    } else {
      apiCreateNewWidget(widget)
        .then((data: Widget) => {
          dispatch(setCurrentWidgetAction(data));
          updateProjectWidgets();
          history.push(`/project/${projectId}/widget/${data.id}`);
        })
        .catch((error) => {
          const message = error?.response?.data?.message || 'Ошибка, обратитесь к администратору';
          message && showNotification({ message, variant: 'error' });
          dispatch(setActivityAction(true));
        });
    }
  };

  const validateWidget = (widget: Widget) => {
    let isValidWidget = true;
    if (!widget.name) {
      isValidWidget = false;
      dispatch(setWidgetValidationErrorAction({ key: 'name', value: 'Заполните имя виджета' }));
    }

    return isValidWidget;
  };

  const getCorrectDashboardProperties = () => {
    const propertyIndex = getPropertyIndex(DashboardPropertyType.autoBoundGroups, currentDashboard.properties);
    if (propertyIndex < 0) return currentDashboard.properties;

    const newProperties = [...currentDashboard.properties];
    newProperties[propertyIndex] = {
      ...currentDashboard.properties[propertyIndex],
      value: JSON.stringify(getDefaultAutoMultipleFilters()),
    };

    return newProperties;
  };

  const validateDashboard = (dashboard: DashboardRequest) => {
    let isValidDashboard = true;

    if (!dashboard.name) {
      isValidDashboard = false;
      dispatch(
        setDashboardValidationErrorAction({
          key: 'name',
          value: `Заполните  ${DashboardDictionary.dashboardName}`,
        }),
      );
    } else if (dashboard.name.length > 150) {
      isValidDashboard = false;
      dispatch(
        setDashboardValidationErrorAction({
          key: 'name',
          value: `Название ${DashboardDictionary.few} должно быть до 150 символов`,
        }),
      );
    }

    return isValidDashboard;
  };

  const validateDashboardFilters = (dashboard: DashboardRequest) => {

    const filters: FilterGroupProps[] = getPropertyValue(DashboardPropertyType.filters, dashboard.properties);

    // Mandatory validation
    for (const filter of filters) {
      const isMandatory: boolean = getFilterSettingValue(filter, FilterProperty.isMandatoryFilter);

      const errorText = `Ошибка сохранения: фильтр ${filter.name} обязателен для заполенния`;

      if (isMandatory && !filter.value?.[0]?.value) {
        return errorText;
      }
    }

    return null;
  };

  const saveDashboard = () => {
    const dashboard: DashboardRequest = {
      id: currentDashboard.id,
      name: currentDashboard.name,
      projectId: currentDashboard.projectId,
      properties: getCorrectDashboardProperties(),
      widgetIds: currentDashboard.widgetIds,
    };

    const filtersErrorText = validateDashboardFilters(dashboard);
    if (filtersErrorText) {
      showNotification({ message: filtersErrorText, variant: 'error' });
      return;
    }
    if (!validateDashboard(dashboard)) return;
    dispatch(resetDashboardValidationErrorsAction());

    dashboard.projectId = projectId;

    dispatch(setActivityAction(false));
    const updateProjectDashboards = () => {
      dispatch(setActivityAction(true));
      apiGetAllProjectDashboards(projectId).then((result: DashboardResponse[]) => {
        dispatch(setCurrentProjectDashboardsAction(result));
      });
    };

    if (dashboard.id) {
      dispatch(setDashboardChangedNotificationDisabledAction(true));
      apiUpdateDashboard(dashboard)
        .then((result) => {
          dispatch(setCurrentDashboardAction(result));
          updateProjectDashboards();
          showNotification({ message: 'Сохранено', variant: 'success' });
        })
        .catch((error) => {
          const message = error?.response?.data?.message || 'Ошибка, обратитесь к администратору';
          message && showNotification({ message, variant: 'error' });
          dispatch(setActivityAction(true));
        }).finally(() => {
          dispatch(setDashboardChangedNotificationDisabledAction(false));
        });
    } else {
      apiCreateNewDashboard(dashboard)
        .then((data: DashboardResponse) => {
          history.push(`/project/${projectId}/dashboard/${data.id}`);
          updateProjectDashboards();
          showNotification({ message: 'Сохранено', variant: 'success' });
        })
        .catch((error) => {
          const message = error?.response?.data?.message || 'Ошибка, обратитесь к администратору';
          message && showNotification({ message, variant: 'error' });
          dispatch(setActivityAction(true));
        });
    }
  };

  const isActiveButton = isActive && !isWidgetPageSaveDisabled;

  const tooltipTitle = 'Сохранить';

  return {
    onSaveClick,
    isActive: isActiveButton,
    tooltipTitle,
  };
};
