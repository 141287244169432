import React, { useEffect } from 'react';
import './auth-page.css';

import { useHistory } from 'react-router-dom';
import { getUserInfo } from '../../services';
import { apiGetProjectViaSystemObject } from '../../services/projectsController';

const AuthPage = () => {
  const history = useHistory();

  const initRedirection = async () => {
    try {
      const userData = await getUserInfo();
      const systemProjectId = userData.rootSystemObjectId;
      if (systemProjectId) {
        const project = await apiGetProjectViaSystemObject(systemProjectId);
        history.push(`/project/${project.id}/dashboards`);
      } else {
        history.push('/');
      }
    } catch (e: any) {
      // on catch logic
    }
  };

  useEffect(() => {
    initRedirection();
  }, []);

  return null;
};

export default AuthPage;
