import React from 'react';
import { GanttColumn } from './gantt-column';

export const GanttDaysRange = ({
  daysRange,
  xScale,
  canvasHeight,
  canvasWidth,
}: any) => {
  return daysRange.map((column: any, index: number) => {
    return (
      <GanttColumn
        key={column}
        xScale={xScale}
        data={column}
        daysRange={daysRange}
        canvasHeight={canvasHeight}
        canvasWidth={canvasWidth}
      />
    );
  });
};
