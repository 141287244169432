import { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { ComplexTableContext } from '../../index';
import { MultilineTextVariant } from '../../helper';
import { getSelectPropertyValue } from '../../../helpers';

export const useTableTooltip = () => {
  const { isDisplayTooltip, widgetProperties } =
    useContext(ComplexTableContext);

  const showMultilineText: MultilineTextVariant = useMemo(
    () =>
      getSelectPropertyValue(
        'showMultilineText',
        widgetProperties,
      ) as MultilineTextVariant,
    [widgetProperties],
  );

  const classes = clsx('widget-table-cell__value', {
    'widget-table-cell__value_two':
      showMultilineText === MultilineTextVariant.twoString,
    'widget-table-cell__value_three':
      showMultilineText === MultilineTextVariant.threeString,
  });

  return {
    isDisplayTooltip,
    classes,
  };
};
