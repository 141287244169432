import * as Yup from 'yup';
import { ObjectSchema, Shape } from 'yup';
import Connection from '../../../types/connection';
import { stringToBoolean } from '../../../utils/functions';
import { fieldName } from './data';
import { FileFieldType } from '../../../enums/connection-field-type';

interface AnyObject {
  [key: string]: any;
}

export const getInitialValues = (
  data: Connection.FileParam[],
  values: { name: string; value: string }[],
): Record<string, string | boolean> => {
  const initialValues: Record<string, string | boolean> = data.reduce(
    (acc, item) => {
      if (item.type === FileFieldType.WithHeader) {
        return Object.assign(acc, { [fieldName[item.type]]: false });
      }
      if (item.type === FileFieldType.CheckXsd) {
        return Object.assign(acc, { [fieldName[item.type]]: false });
      }
      if (item.type === FileFieldType.Charset) {
        return Object.assign(acc, { [fieldName[item.type]]: 'UTF-8' });
      }
      if (item.type === FileFieldType.JSONLINES) {
        return Object.assign(acc, { [fieldName[item.type]]: true });
      }
      return Object.assign(acc, { [fieldName[item.type]]: '' });
    },
    {},
  );

  values.forEach((value) => {
    initialValues[value.name] = value.value;
    if (value.name === FileFieldType.CheckXsd) {
      initialValues[value.name] =
        stringToBoolean[value.value as 'true' | 'false'];
    }
  });

  return initialValues;
};

export const getValidationSchema = (
  data: Connection.FileParam[],
): ObjectSchema<Shape<AnyObject, AnyObject>> => {
  const res = data.reduce((acc, item) => {
    if (item.type === FileFieldType.WithHeader) {
      return acc;
    }
    if (item.type === FileFieldType.Charset) {
      return acc;
    }
    if (item.type === FileFieldType.DataAddress) {
      return acc;
    }
    if (item.type === FileFieldType.CheckXsd) {
      return acc;
    }
    if (item.type === FileFieldType.XsdFileName) {
      return acc;
    }
    return Object.assign(acc, {
      [fieldName[item.type]]: Yup.string().required(),
    });
  }, {});

  return Yup.object().shape<any>(res);
};
