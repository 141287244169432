import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../../services/auth';
import { State, UserInfo as UserInfoI } from '../../../slices/types';
import { fileDownload, getUserInfo } from '../../../services';
import { setUserInfoAction } from '../../../slices/main-page/main-page-slice';
import './user-info.css';
import { CustomIconButton, IconButtonType } from '../../../uikit/IconButton';
import IconSvg from '../icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

export const UserInfo = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: State) => state.mainPage.userInfo);
  const [userPhoto, setUserPhoto] = useState<string>('');

  useEffect(() => {
    const fetchUserInfo = () => {
      const loadAvatar = (userInfo: UserInfoI | null) => {
        if (userInfo?.photoId) {
          fileDownload(userInfo.photoId).then((result) => {
            setUserPhoto(result.request.responseURL);
          });
        }
      };

      getUserInfo().then((res) => {
        dispatch(setUserInfoAction(res));
        loadAvatar(res);
      });
    };

    fetchUserInfo();
  }, [dispatch]);

  const handleLogout = () => {
    AuthService.doLogout();
  };

  return (
    <div className='user-info'>
      {!userInfo?.photoId && (
        <div className='user-info__icon'>
          <IconSvg
            svg={IconDictionary.Person}
            fill="var(--primary-color)"
            width={20}
            height={20}
          />
        </div>
      )}
      {userInfo?.photoId && (
        <div className='user-info__logo-container'>
          <img src={userPhoto} alt="" className='user-info__logo' />
        </div>
      )}
      <div className='user-info__name-container'>
        <div className='user-info__name'>{userInfo?.name}</div>
      </div>
      <CustomIconButton
        iconButtonProps={{
          children: <IconSvg
            svg={IconDictionary.Exit}
            fill="var(--dark-grey)"
          />,
          className: 'user-info__logout',
          onClick: handleLogout,
        }}
        type={IconButtonType.secondary}
      />
    </div>
  );
};
