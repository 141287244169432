import React from 'react';
import { CustomButton } from '../../../uikit/Button';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import MapConnection from '../../../types/map-connection';
import { useSourceReplaceModal } from './useSourceReplaceModal';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { SelectInput } from '../../common/special-inputs/inputs/select-input';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';
import { CustomProgress } from '../../../uikit/Progress';
import { ConnectionTooltip } from '../select-fields-panel/sections/connection-tooltip';

export interface SourceReplaceModalProps {
  source: MapConnection.SourceObject;
  handleClose: (isNeedToClosePanel?: boolean) => void;
}

export const SourceReplaceModal = ({
  source,
  handleClose,
}: SourceReplaceModalProps) => {
  const {
    values,
    handleChange,
    handleSubmit,
    sourcesList,
    error,
    isDisabledSave,
    handleCloseModal,
    isLoading,
    currentSourceId,
  } = useSourceReplaceModal({ source, handleClose });

  return (
    <CustomDialog
      isOpen={true}
      onClose={handleCloseModal}
      maxWidth="xs"
      title="Замена источника"
    >
      <form className="dialog-body" onSubmit={handleSubmit}>
        {error && (
          <CustomErrorAlert className="dialog-body__block">
            {error}
          </CustomErrorAlert>
        )}

        <TextInput
          formikName="oldSource"
          value={values.oldSource}
          label="Текущий источник"
          handleChange={handleChange}
          className="dialog-body__block"
          fullWidth
          disabled
        />

        <div className="dialog-body__block dialog-body__block_flex">
          <SelectInput
            formikName="newSource"
            label="Новый источник данных"
            value={values.newSource}
            handleChange={handleChange}
            itemList={sourcesList}
            fullWidth
          />
          {currentSourceId ? (
            <div
              style={{ marginLeft: 'calc(10px * var(--scale-coefficient))' }}
            >
              <ConnectionTooltip sourceId={currentSourceId} />
            </div>
          ) : undefined}
        </div>

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton
            variant="contained"
            type="submit"
            disabled={isDisabledSave}
            startIcon={
              isLoading ? (
                <CustomProgress
                  type="circular"
                  style={{ color: 'var(--white)' }}
                  size={20}
                />
              ) : null
            }
          >
            Сохранить
          </CustomButton>
          <CustomButton
            variant="outlined"
            type="reset"
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            Отменить
          </CustomButton>
        </div>
      </form>
    </CustomDialog>
  );
};
