import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import block from 'bem-cn';
import { Formik } from 'formik';
import { SourceType } from 'src/enums/source-type';
import { connectionsActions } from '../../../slices/connections/connections';
import { ConnectionFieldType } from '../../../enums/connection-field-type';
import { useConnectionParamsById } from '../../../hooks/useConnectionParamsById';
import {
  apiCreateConnection,
  apiCreateGoogleSheetConnection,
  apiUpdateConnection,
} from '../../../services/connection';
import Connection from '../../../types/connection';
import { idSourceType } from '../connection-type-list/data';
import { SingleField } from './single-field';
import { getInitialValues, getValidationSchema } from './utils';
import './connection-form.css';
import { State } from '../../../slices/types';
import { CustomButton } from '../../../uikit/Button';
import {
  InlineTextButton,
} from '../../../uikit/InlineTextButton';
import { CustomProgress } from '../../../uikit/Progress';
import { mapConnectionAction } from 'src/slices/map-connection/map-connection';

interface AnyObject {
  [key: string]: string;
}

interface Props {
  className?: string;
  connectionTypeId: number;
  connection?: Connection.View.ItemFull;
  onClosePanel: () => void;
  onCancel: (...args: any[]) => any;
}

const b = block('connection-form');

const ConnectionForm: React.FC<Props> = ({
  className = '',
  connectionTypeId,
  connection,
  onClosePanel,
  onCancel,
}) => {
  const loaderId: number = useSelector(
    (state: State) => state.mainPage.currentProject?.loaderId ?? 0,
  );

  const groupId: number = useSelector(
    (state: State) => state.mapConnection[loaderId]?.loaderGroupId,
  );

  const dispatch = useDispatch();
  const [errorSubmit, setErrorSubmit] = useState<string | null>(null);
  const [isMergeInfoSubmit, setMergeInfoSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data, error, setId, id, loading } = useConnectionParamsById(
    connectionTypeId,
  );

  const isGoogleSheets = idSourceType[id] === SourceType.GOOGLE_SHEETS;

  const typedData = data as Connection.Param[];

  const onSuccess = (isMerge?: boolean) => {
    dispatch(connectionsActions.getFullConnectionsAction(groupId));
    if (isMerge) {
      dispatch(mapConnectionAction.getMapConnection(loaderId));
    }
    onCancel();
    onClosePanel();
  };

  const send = async (values: AnyObject, isMerge?: true) => {
    const connectionValues = { ...values, groupId };
    setMergeInfoSubmit(false);
    setErrorSubmit(null);
    setIsLoading(true);
    const defaultErrorMessage = 'Что-то пошло не так. Попробуйте позже.';

    if (isGoogleSheets) {
      apiCreateGoogleSheetConnection(groupId)
        .then(() => onSuccess())
        .catch((err: any) => {
          setErrorSubmit(err?.data?.message || defaultErrorMessage);
        })
        .finally(() => setIsLoading(false));

      return;
    }

    if (connection) {
      const editedConnection = { ...connection, ...connectionValues };

      apiUpdateConnection(editedConnection, isMerge)
        .then(() => onSuccess(isMerge))
        .catch((err: any) => {
          const ERROR_WITH_INFO_STATUS = 498;
          if (err.status === ERROR_WITH_INFO_STATUS) {
            setMergeInfoSubmit(true);
          } else {
            setErrorSubmit(err?.data?.message || defaultErrorMessage);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      apiCreateConnection(idSourceType[id], connectionValues)
        .then(() => onSuccess())
        .catch((err) => setErrorSubmit(err?.data?.message))
        .finally(() => setIsLoading(false));
    }
  };

  const onSubmit = async (values: AnyObject) => {
    await send(values);
  };

  /* Все поля кроме полей для атвторизации */
  const filteredFields = useMemo<Connection.Param[]>(
    () =>
      typedData.filter(
        ({ type }) =>
          ![ConnectionFieldType.Login, ConnectionFieldType.Password].includes(
            type,
          ),
      ),
    [typedData],
  );

  /* Поля для авторизации */
  const authFields = useMemo<Connection.Param[]>(
    () =>
      typedData.filter(({ type }) =>
        [ConnectionFieldType.Login, ConnectionFieldType.Password].includes(
          type,
        ),
      ),
    [typedData],
  );

  useEffect(() => setId(connectionTypeId), [setId, connectionTypeId]);
  useEffect(() => {
    setErrorSubmit('');
    setMergeInfoSubmit(false);
  }, [connectionTypeId]);

  return (
    <div className={`${b()} ${className}`}>
      <h3 className={b('title')}>Данные о сервере</h3>
      {loading ? (
        <CustomProgress type="circular" />
      ) : data.length ? (
        <Formik
          initialValues={getInitialValues(typedData, connection)}
          validationSchema={getValidationSchema(typedData, connectionTypeId)}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <form className={b('form')} onSubmit={handleSubmit}>
              <div className={b('fields')}>
                {filteredFields.map((item) => (
                  <SingleField
                    key={item.type}
                    className={b('field', {
                      [item.type.toLowerCase()]: true,
                    }).toString()}
                    data={item}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                ))}
                {authFields.length > 0 && !(!connection && isGoogleSheets) && (
                  <>
                    <h3 className={b('title', { mt: true })}>Авторизация</h3>
                    {authFields.map((item) => (
                      <SingleField
                        key={item.type}
                        className={b('field').toString()}
                        data={item}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    ))}
                  </>
                )}
                {errorSubmit && (
                  <Alert severity="error" className={b('error').toString()}>
                    <AlertTitle>Ошибка</AlertTitle>
                    <span className={b('error-text').toString()}>
                      {errorSubmit}
                    </span>
                  </Alert>
                )}

                {isMergeInfoSubmit && (
                  <Alert severity="warning" className={b('error').toString()}>
                    <AlertTitle>Уведомление</AlertTitle>
                    Подключение с такими параметрами уже существует.
                    <InlineTextButton
                      title="нажмите для объединения"
                      onClick={async () => {await send(values, true);}}
                    />
                  </Alert>
                )}
              </div>
              {isLoading && <CustomProgress type="circular" />}
              {!(connection && isGoogleSheets) &&
                <div className={b('buttons')}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    onClick={() => null}
                  >
                    {isGoogleSheets ? 'Создать' : 'Сохранить'}
                  </CustomButton>
                  <CustomButton
                    type="reset"
                    onClick={onCancel}
                    variant="outlined"
                  >
                    Отменить
                  </CustomButton>
                </div>
              }
            </form>
          )}
        </Formik>
      ) : error ? (
        <p className={b('error', { mt: true })}>{error}</p>
      ) : data.length === 0 ? (
        <p className={b('no-data')}>Нет данных для отображения</p>
      ) : null}
    </div>
  );
};

export default ConnectionForm;
