import React from 'react';

interface GlyphCircleProps {
  color: string;
  isOver: boolean;
  leftOffsetData: number;
  topOffsetData: number;
}

export const GlyphCircle = ({
  color,
  isOver,
  leftOffsetData,
  topOffsetData,
}: GlyphCircleProps) => {
  return topOffsetData >= 0 ? (
    <>
      {isOver && (
        <circle
          cx={leftOffsetData}
          cy={topOffsetData}
          r={5}
          fill={color}
          fillOpacity={0.1}
          stroke={color}
          strokeOpacity={1}
          strokeWidth={2}
          pointerEvents="auto"
        />
      )}
      <circle
        cx={leftOffsetData}
        cy={topOffsetData + 1}
        r={4}
        fill={color}
        fillOpacity={0.1}
        stroke={color}
        strokeOpacity={0.1}
        strokeWidth={2}
        pointerEvents="auto"
      />
      <circle
        cx={leftOffsetData}
        cy={topOffsetData}
        r={4}
        fill={color}
        stroke="white"
        strokeWidth={2}
        pointerEvents="auto"
      />
    </>
  ) : null;
};
