import {
  notFoundError,
  securityError,
  unexpectedError,
} from './image-block/images';

enum ErrorStatuses {
  security = '403',
  notFound = '404',
}

export const getErrorTitle = (status: string) => {
  switch (status) {
    case ErrorStatuses.security:
      return 'Нет доступа';
    case ErrorStatuses.notFound:
      return 'Страница не найдена';
    default:
      return 'Непредвиденная ошибка';
  }
};

export const getErrorDescription = (status: string) => {
  switch (status) {
    case ErrorStatuses.security:
      return [
        'У Вас нет прав доступа для просмотра данной страницы',
        'Обратитесь к администратору для выдачи разрешения'
      ];
    case ErrorStatuses.notFound:
      return [
        'К сожалению, запрашиваемая Вами страница была',
        'перемещена или удалена'
      ];
    default:
      return [
        'Запрашиваемая страница временно недоступна',
        'Попробуйте повторить попытку позже'
      ];
  }
};

export const getErrorImage = (status: string) => {
  switch (status) {
    case ErrorStatuses.security:
      return securityError;
    case ErrorStatuses.notFound:
      return notFoundError;
    default:
      return unexpectedError;
  }
};
