import { Box } from '@material-ui/core';
import React from 'react';
import { CssVariables } from '../../../../../enums/css-variables';
import { varcss } from '../../../../../helpers/ui-helper';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { WidgetDictionary } from '../../../../../dictionaries/naming-dictionary/naming-dictionary';
import {
  IconButtonType,
  CustomIconButton,
} from '../../../../../uikit/IconButton';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { CustomButton } from '../../../../../uikit/Button';
import { WidgetLinkButton } from '../../../../common/special-buttons/widget-link-button/widget-link-button';
import { isEditDashboardPage } from '../../../../widget-page/charts/helpers';
import { FiltersButton } from '../../../../widget-page/filters/filters-button';
import ButtonMore from '../button-more';
import CardHeader from '../../../../../uikit/CardHeader';
import { FullscreenButton } from './fullscreen-button';
import { FilterField, SetFilterField, SetMultipleFilterFields } from '../../../hooks';
import { useWidgetCardHeader } from '../../hooks/useWidgetCardHeader';
import WidgetTypes from '../../../../../types/widgets';
import {
  DashboardProperty,
  WidgetProperties,
} from '../../../../../slices/types';
import { CustomButtonGroup } from '../../../../../uikit/ButtonsGroup';
import { BackdropScreenshot } from '../../../../common/backdrop-screenshot';
import { SpecialWidgetHeadButtons } from '../../../../widget-page/widget-header/components/special-widget-head-buttons';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface WidgetCardHeaderProps {
  widgetId: number;
  isStatic: boolean;
  isDrillDown: boolean;
  drilldownFilterField: FilterField;
  widgetDataObject: WidgetTypes.Data;
  drillDownDepth?: number;
  closeDrillDown: () => void;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  overriddenProperties: WidgetProperties[];
  setOverriddenProperties: (newProperties: WidgetProperties[]) => void;
  enhancedParams: {
    dashboardProperties: DashboardProperty[];
    widgetOverriddenProperties: WidgetProperties[];
  };
  overriddenPropertiesInherited?: WidgetProperties[];
  isMasterWidget: (widgetId: number) => boolean;
  handleClickBackDD: () => void;
  setFilterField?: SetFilterField;
  setMultipleFilterFields?: SetMultipleFilterFields;
  isActiveFilter?: number | false | undefined;
}

export const WidgetCardHeader = ({
  widgetId,
  isStatic,
  isDrillDown,
  drilldownFilterField,
  widgetDataObject,
  drillDownDepth,
  closeDrillDown,
  containerRef,
  overriddenProperties,
  setOverriddenProperties,
  enhancedParams,
  overriddenPropertiesInherited,
  isMasterWidget,
  handleClickBackDD,
  setFilterField,
  setMultipleFilterFields,
  isActiveFilter,
}: WidgetCardHeaderProps) => {
  const {
    showWidgetHeaders,
    isFullScreenAbility,
    widgetTitle,
    widgetDescription,
    headerTextAlign,
    titleSettings,
    subtitleSettings,
    projectId,
    widgetProps,
    dashboardFiltersGroups,
    onDownloadImageClick,
    isLoadingScreenshot,
  } = useWidgetCardHeader({
    widgetId,
    widgetDataObject,
    containerRef,
  });

  return (
    <>
      <BackdropScreenshot open={isLoadingScreenshot} />
      {isStatic && !showWidgetHeaders && !isDrillDown ? (
        <FullscreenButton
          widgetId={widgetId}
          isFullScreenAbility={isFullScreenAbility}
          isDrilldownFilterFieldEmpty={drilldownFilterField.value === null}
          className="dashboard-grid-item__fullscreen-button__no-header"
        />
      ) : (
        <CardHeader
          title={widgetTitle}
          titleSettings={titleSettings}
          subtitle={widgetDescription}
          subtitleSettings={subtitleSettings}
          align={headerTextAlign}
          backActionObject={{
            isVisible: Boolean(drillDownDepth && drillDownDepth > 0),
            onClick: handleClickBackDD,
          }}
        >
          <Box
            ml="20px"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            className="dashboard-grid-item__button-group screenshot-deleted-item"
          >
            {isMasterWidget(widgetId) && (
              <CustomTooltip title={WidgetDictionary.detailsWidgetMessage}>
                <Box mr="8px" mb="auto" mt="auto">
                  <CustomIconButton
                    iconButtonProps={{
                      children: (
                        <IconSvg
                          svg={IconDictionary.DrilldownInfo}
                          fill="var(--primary-color)"
                        />
                      ),
                    }}
                    type={IconButtonType.primary}
                  />
                </Box>
              </CustomTooltip>
            )}
            <CustomButtonGroup size="small">
              {isEditDashboardPage() && (
                <CustomTooltip
                  arrow
                  title={`${WidgetDictionary.openWidget} "${widgetProps.name}"`}
                >
                  <div>
                    <CustomButton
                      icon={<IconSvg svg={IconDictionary.EditFile} fill="var(--dark-grey)" />}
                      size="small"
                      linkTo={`/project/${projectId}/widget/${widgetId}`}
                      variant="tab"
                      backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
                    />
                  </div>
                </CustomTooltip>
              )}

              <WidgetLinkButton widgetProperties={widgetProps.properties} />

              <FiltersButton
                widgetProps={widgetProps}
                setOverriddenProperties={setOverriddenProperties}
                overriddenProperties={overriddenProperties}
                overriddenPropertiesInherited={overriddenPropertiesInherited}
                dashboardFiltersGroups={dashboardFiltersGroups}
                isDrillDown={isDrillDown}
              />
              <FullscreenButton
                widgetId={widgetId}
                isFullScreenAbility={isFullScreenAbility}
                isDrilldownFilterFieldEmpty={
                  drilldownFilterField.value === null
                }
              />
              <SpecialWidgetHeadButtons
                widget={widgetProps}
                dbFiltersControls={{ setOverriddenProperties, setFilterField, setMultipleFilterFields, isActiveFilter }}
              />
              <ButtonMore
                widgetId={widgetId}
                enhancedParams={enhancedParams}
                onDownloadImageClick={onDownloadImageClick}
              />
            </CustomButtonGroup>

            {isDrillDown && (
              <Box ml="16px">
                <CustomButton
                  onClick={closeDrillDown}
                  icon={
                    <IconSvg
                      svg={IconDictionary.Close}
                      fill="var(--dark-grey)"
                    />
                  }
                  size="small"
                  backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
                />
              </Box>
            )}
          </Box>
        </CardHeader>
      )}
    </>
  );
};
