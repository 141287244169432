import React, { useMemo } from 'react';
import IconSvg from '../../common/icon-svg/icon-svg';
import FilterPanel from '../filter-panel/filter-panel';
import CreateControls from '../create-controls/create-controls';
import '../../../pages/main-page/main-page.css';
import { isMobilehelper } from '../../../utils/mobile-helper';
import { CustomTabs } from '../../../uikit/Tabs';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface GroupTitleBlockProps {
  groupView: string;
  handleShowTable: () => void;
  handleShowCards: () => void;
  handleAddNewGroup: (groupId: number | null) => void;
}

export const GroupTitleBlock = ({
  groupView,
  handleShowTable,
  handleShowCards,
  handleAddNewGroup,
}: GroupTitleBlockProps) => {
  const isMobile = useMemo(() => isMobilehelper(), []);

  const tabs = [
    {
      id: 'table',
      icon: (
        <IconSvg
          svg={IconDictionary.MainPageTableView}
          width={28}
          height={28}
          fill={groupView === 'table' ? 'var(--white)' : 'var(--dark-grey)'}
        />
      ),
      onClick: handleShowTable,
    },
    {
      id: 'cards',
      icon: (
        <IconSvg
          svg={IconDictionary.MainPageCardView}
          width={28}
          height={28}
          fill={groupView === 'cards' ? 'var(--white)' : 'var(--dark-grey)'}
        />
      ),
      onClick: handleShowCards,
    },
  ];

  return (
    <div className="group-title-block">
      <h3 className="groups-mode-title">{CommonDictionary.projectList}</h3>
      {!isMobile && (
        <div className="main-page__button-group">
          <CustomTabs
            buttons={tabs}
            size={64}
            selected={tabs.findIndex((tab) => groupView === tab.id)}
          />
        </div>
      )}
      <FilterPanel />
      <div className="create-group-button">
        <CreateControls handleAddNewGroup={handleAddNewGroup} />
      </div>
    </div>
  );
};
