import React from 'react';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { CustomButton } from '../../../../../uikit/Button';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import CustomTooltip from '../../../../../uikit/CustomTooltip';

interface ButtonsSectionProps {
  isTextFilterEmpty: boolean;
  onSave: () => void;
  onCancel: () => void;
  openShowCalculatedIndicatorPanel: () => void;
  openFiltrationPanel: () => void;
  isEditableMap: boolean;
  replaceSource: () => void;
  needShowReplaceSourceButton: boolean;
}

const ButtonsSection = ({
  isTextFilterEmpty,
  onSave,
  onCancel,
  openFiltrationPanel,
  openShowCalculatedIndicatorPanel,
  isEditableMap,
  replaceSource,
  needShowReplaceSourceButton,
}: ButtonsSectionProps) => {
  return (
    <div className='dialog-buttons'>
      <CustomButton disabled={!isEditableMap} type="submit" variant="contained" onClick={onSave}>
        Сохранить
      </CustomButton>
      <CustomButton variant="outlined" onClick={onCancel}>
        Отменить
      </CustomButton>

      <div className="dialog-buttons__additional-container">
        {needShowReplaceSourceButton && (
          <CustomTooltip title="Заменить источник">
            <div>
              <CustomButton
                variant="outlined"
                icon={<IconSvg svg={IconDictionary.Replace} fill="var(--dark-grey)" />}
                onClick={replaceSource}
              />
            </div>
          </CustomTooltip>)}
        <CustomTooltip title="Создать новый показатель">
          <div>
            <CustomButton
              variant="outlined"
              icon={<IconSvg svg={IconDictionary.AddCalculatingField} fill="var(--dark-grey)" />}
              onClick={openShowCalculatedIndicatorPanel}
            />
          </div>
        </CustomTooltip>
        <CustomTooltip title="Добавить фильтр">
          <div>
            <CustomButton
              variant={isTextFilterEmpty ? 'outlined' : 'contained'}
              icon={
                <IconSvg
                  svg={IconDictionary.SourceFilter}
                  fill={isTextFilterEmpty ? 'var(--dark-grey)' : 'var(--white)'}
                />
              }
              onClick={openFiltrationPanel}
            />
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default ButtonsSection;
