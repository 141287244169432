import { useEffect, useRef, useState } from 'react';
import { getRootElement } from '../../../../../utils/root-helper';

export const useDraggable = (buttons = [1, 4, 5]) => {
  const ref = useRef(document.createElement('div'));
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);

  useEffect(() => {
    function handleDown(e: any) {
      if (!ref.current.contains(e.target)) {
        return;
      }
      setStartX(e.pageX - ref.current.offsetLeft);
      setStartY(e.pageY - ref.current.offsetTop);
      setStartScrollLeft(ref.current.scrollLeft);
      setStartScrollTop(ref.current.scrollTop);
    }
    function handleMove(e: any) {
      if (!buttons.includes(e.buttons) || !ref.current.contains(e.target)) {
        return;
      }
      e.preventDefault();

      const mouseX = e.pageX - ref.current.offsetLeft;
      const mouseY = e.pageY - ref.current.offsetTop;

      const walkX = mouseX - startX;
      const walkY = mouseY - startY;

      ref.current.scrollLeft = startScrollLeft - walkX;
      ref.current.scrollTop = startScrollTop - walkY;
    }

    getRootElement().addEventListener('mousedown', handleDown);
    getRootElement().addEventListener('mousemove', handleMove);
    return () => {
      getRootElement().removeEventListener('mousedown', handleDown);
      getRootElement().removeEventListener('mousemove', handleMove);
    };
  });

  return ref;
};
