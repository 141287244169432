import {
  FormControlLabel,
  FormControlLabelProps,
  styled,
} from '@material-ui/core';
import React from 'react';

const StyledFormControl = styled(FormControlLabel)({
  margin: 0,

  '& .MuiFormControlLabel-label': {
    color: 'var(--black)',
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: 'calc(18px * var(--scale-coefficient))',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    wordBreak: 'break-word',

    '&.Mui-disabled': {
      opacity: 0.6,
    },
  },
});

export const CustomFormControl = (props: FormControlLabelProps) => {
  return (
    <StyledFormControl {...props} />
  );
};
