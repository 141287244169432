import React from 'react';
import block from 'bem-cn';
import SqlScriptForm from '../sql-script-form/sql-script-form';
import './sql-script-panel.css';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

interface Props {
  className?: string;
  loaderId: number;
  connectionId: number;
  onClose: () => void;
  closeListPanel: () => void;
  setError: any;
}

const b = block('sql-script-panel');

const SqlScriptPanel: React.FC<Props> = ({
  className = '',
  loaderId,
  connectionId,
  onClose,
  closeListPanel,
  setError,
}) => {
  return (
    <div className={`${b()} ${className}`}>
      <h3 className={b('title')}>Ввод {CommonDictionary.SQL} скрипта</h3>
      <SqlScriptForm
        loaderId={loaderId}
        connectionId={connectionId}
        onClose={onClose}
        setError={setError}
        callback={closeListPanel}
        isNewScript
      />
    </div>
  );
};

export default SqlScriptPanel;
