import React, { useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IconSvg from '../../common/icon-svg/icon-svg';
import {
  apiGetInfoByNewFile,
  apiGetInfoByTableSource,
} from '../../../services/sources';
import MapConnection from '../../../types/map-connection';
import { FileTypesEnum } from '../../../enums/fyle-type';
import './table-preview.css';
import { CustomProgress } from '../../../uikit/Progress';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface tableColumn {
  name: string;
  type: string;
  nullable: boolean;
}

type tableRow = Array<string>;

interface TablePreviewProps {
  className?: string;
  name?: string;
  connectionId: number;
  onClose: () => void;
  isNew?: boolean;
  fileTypeName?: FileTypesEnum;
  formParams?: {
    name: string;
    value: string | boolean;
  }[];
}

const useStyles = makeStyles({
  autoWidth: {
    width: 'auto',
  },
});

const b = block('table-preview');

const TablePreview: React.FC<TablePreviewProps> = ({
  className = '',
  name = '',
  connectionId,
  onClose,
  isNew = false,
  fileTypeName = FileTypesEnum.CSV,
  formParams = [],
}) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<MapConnection.TableInfo | null>(null);

  const getPreviewDataForNewFile = useCallback(() => {
    // For preview excel list backend need parametr DATA_ADDRESS witn value = listName
    const params =
      fileTypeName === FileTypesEnum.EXCEL
        ? [...formParams, { name: 'DATA_ADDRESS', value: `'${name}'!A1` }]
        : formParams;
    apiGetInfoByNewFile(connectionId, fileTypeName, params)
      .then((data) => {
        setContent(data);
      })
      .catch((error) => {
        setContent(null);
        console.error('Request failed:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [connectionId, fileTypeName, formParams, name]);

  const getPreviewData = useCallback(() => {
    apiGetInfoByTableSource(connectionId, name)
      .then((data) => {
        setContent(data);
      })
      .catch((error) => {
        setContent(null);
        console.error('Request failed:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [connectionId, name]);

  const getPreviewDataRequest = isNew
    ? getPreviewDataForNewFile
    : getPreviewData;

  useEffect(() => {
    setLoading(true);
    getPreviewDataRequest();
  }, [getPreviewDataRequest]);

  const columnsAmount = content?.schema.length ?? 0;
  const rowsAmount = content?.sample.length ?? 0;

  return (
    <div className={`${b()} ${className}`}>
      <div className={b('head-panel')}>
        <h3 className={b('title')}>{name}</h3>
        <div className={b('info')}>
          <div className={b('column-icon')}>
            <IconSvg svg={IconDictionary.Column} width={24} height={24} fill="var(--dark-grey)" />
          </div>
          <div>столбцы: {columnsAmount}</div>
          <div>
            <IconSvg svg={IconDictionary.Row} width={24} height={24} fill="var(--dark-grey)" />
          </div>
          <div>строки: {rowsAmount}</div>
          <div
            className={b('close')}
            role="button"
            onClick={onClose}
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <IconSvg svg={IconDictionary.Close} width={20} height={20} fill="var(--dark-grey)" />
          </div>
        </div>
      </div>

      {isLoading ? (
        <CustomProgress type="linear" />
      ) : (
        <>
          {content ? (
            <TableContainer component={Paper} className={`${b('container')}`}>
              <Table
                stickyHeader
                aria-label="simple table"
                classes={{ root: `${classes.autoWidth}` }}
              >
                <TableHead className={`${b('header')}`}>
                  <TableRow>
                    {content.schema.map((i) => (
                      <TableCell
                        className={`${b('cell')} ${b('header-cell')}`}
                        key={i.name}
                      >
                        {i.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={`${b('body')}`}>
                  {content.sample.map((row, index) => (
                    <TableRow key={index}>
                      {row.map((cell, i) => (
                        <TableCell key={i} className={`${b('cell')}`}>
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className={b('empty-content')}>
              <div className={b('empty-content__logo')}>
                <IconSvg
                  svg={IconDictionary.NoTable}
                  width={88}
                  height={88}
                  fill="var(--dark-grey)"
                />
              </div>
              <div className={b('empty-content__header')}>Нет данных для отображения</div>
              <div className={b('empty-content__text')}>
                Нет данных для отображения. Проверьте источник.
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TablePreview;
