import { styled } from '@material-ui/core';
import React, { FC } from 'react';
import { CssVariables } from '../../enums/css-variables';
import { varcss } from '../../helpers/ui-helper';

export interface ICard {
  className?: string;
  children: React.ReactNode;
}

const CardWrapper = styled('div')(() => ({
  boxShadow: 'var(--shadow-block)',
  border: '1px solid var(--dark-cyan)',
  borderRadius: 'var(--base-border-radius)',
  width: '100%',
  backgroundColor: varcss(CssVariables.WIDGET_BACKDROP_COLOR),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column'
}));

export const Card: FC<ICard> = ({ className = '', children }) => {
  return <CardWrapper className={`card ${className}`}>{children}</CardWrapper>;
};

export default Card;
