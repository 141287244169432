import { getScaleCoefficient } from '../../../../helpers/ui-helper';
import {
  CommonDictionary,
  DashboardDictionary,
  WidgetDictionary
} from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface LoaderTableHeadCell {
  title: string;
  withSort?: boolean;
  sortName?: string;
  minWidth?: number;
}

export const columns: LoaderTableHeadCell[] = [
  {
    title: '',
    minWidth: 0,
  },
  {
    title: 'Название',
    sortName: 'name',
    withSort: true,
  },
  {
    title: 'Автор',
  },
  {
    title: CommonDictionary.projectType,
  },
  {
    title: CommonDictionary.loadingMapTitle,
  },
  {
    title: DashboardDictionary.titleMany,
  },
  {
    title: WidgetDictionary.titleMany,
  },
  {
    title: 'Дата обновления',
    withSort: true,
    sortName: 'cacheLastUpdated',
    minWidth: 150 * getScaleCoefficient(),
  },
  {
    title: '',
    minWidth: 0,
  },
];

export const getSortingIcon = (sort: string) => {
  switch (sort) {
    case 'ASC':
      return IconDictionary.SortDown;
    case 'DESC':
      return IconDictionary.SortUp;
    default:
      return IconDictionary.Sort;
  }
};

export const getNextSorting = (sort: string) => {
  switch (sort) {
    case 'ASC': return 'DESC';
    case 'DESC': return '';
    default: return 'ASC';
  }
};
