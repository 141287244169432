import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Placement } from '../../../../../enums/visual-types';
import { SelectInput } from '../../../../common/special-inputs/inputs/select-input';
import { DateField } from '../../fields/date-field';
import { DateOptions } from '../../../../../slices/types';
import { DateFilterOptions } from './date-filter';
import { useDateFilterSettings } from '../../hooks/useDateFilterSettings';

interface FieldsBlockProps {
  filterOptions: DateFilterOptions;
  handleChangeDateFrom: (date: any) => void;
  handleChangeDateTo: (date: any) => void;
  handleOnInput: () => void;
  values: {
    fromValue: Date | null;
    toValue: Date | null;
  };
  currentDateOption: DateOptions | null;
  dateValues: any;
  filterType: string;
  selectDateType: string;
  handleChangeSelectDateType: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  placement?: Placement;
}

export const FieldsBlock = ({
  filterOptions,
  handleChangeDateFrom,
  handleChangeDateTo,
  dateValues,
  handleOnInput,
  currentDateOption,
  values,
  filterType,
  selectDateType,
  handleChangeSelectDateType,
  placement,
}: FieldsBlockProps) => {
  const {
    isNeedToDisplayPeriod,
    isNeedToDisplaySelect,
    activeSettings,
    isNeedToDisplayDate,

  } = useDateFilterSettings({ selectDateType, filterOptions });

  useEffect(() => {
    !isNeedToDisplayPeriod && values.toValue && handleChangeDateTo(null);
  }, [isNeedToDisplayPeriod]);

  return (
    <>
      {isNeedToDisplaySelect && (
        <Box mb={1}>
          <SelectInput
            fullWidth
            label="Тип фильтра"
            value={selectDateType}
            handleChange={handleChangeSelectDateType}
            itemList={activeSettings}
          />
        </Box>
      )}
      {isNeedToDisplayDate && (
        <Box display="flex">
          <DateField
            label="Дата"
            onChange={handleChangeDateFrom}
            onInput={handleOnInput}
            value={values.fromValue}
            filterType={
              currentDateOption
                ? dateValues[currentDateOption].sysName
                : filterType
            }
            placement={placement}
          />
        </Box>
      )}
      {isNeedToDisplayPeriod && (
        <>
          <Box display="flex">
            <Box>
              <DateField
                label="Дата, с"
                onChange={handleChangeDateFrom}
                value={values.fromValue}
                filterType={filterType}
                placement={placement}
              />
            </Box>
            <Box ml="11px">
              <DateField
                label="Дата, по"
                onChange={handleChangeDateTo}
                value={values.toValue}
                filterType={filterType}
                placement={placement}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
