import { WidgetProperties } from '../../../slices/types';

export const isActiveParentSetting = (
  parentName: string,
  widgetProperties: WidgetProperties[],
) => {
  const value = widgetProperties.find(
    (prop) => prop.name === parentName,
  )?.value;

  let isActive;

  try {
    isActive = value ? JSON.parse(value) : false;
  } catch {
    isActive = Boolean(value);
  }

  return isActive;
};
