import L from 'leaflet';
import { PropertyData } from '../../dropdown-layout/helpers/Property';
import { MapDataTypes } from './settings';

export interface MapData {
  type: string;
  features: Feature[];
  pointCount: number;
}

export interface Feature {
  geometry: PointGeometry | PolygonGeometry;
  properties: FeatureProperties;
  type: string;
}

export interface FeatureProperties {
  heatColoring: any;
  index: number;
  popupContent: PopupContent[];
}

export interface CardData {
  title: string;
  value: string;
  type: string;
}

export interface PopupContent {
  cardData: CardData[];
  layerIndex: number;
  pointId: string;
}

export interface PointGeometry {
  coordinates: number[];
  type: string;
}

export interface PolygonGeometry {
  coordinates: number[][][];
  type: string;
}

const getPolygonAreaBoundaries = (mapLayers: any) => {
  const point = {
    north: 0,
    east: 0,
    south: 180,
    west: 180,
  };

  Object.keys(mapLayers).forEach((key) => {
    if (
      mapLayers[key].feature.geometry.type.toUpperCase() !==
      MapDataTypes.POLYGON
    ) {
      return point;
    }

    const bound = mapLayers[key].getBounds();

    point.north =
      point.north < bound._northEast.lng ? bound._northEast.lng : point.north;
    point.south =
      point.south > bound._southWest.lng ? bound._southWest.lng : point.south;
    point.east =
      point.east < bound._northEast.lat ? bound._northEast.lat : point.east;
    point.west =
      point.west > bound._southWest.lat ? bound._southWest.lat : point.west;
  });

  return point;
};

const getPointAreaBoundaries = (mapLayers: any[]) => {
  const point = {
    north: 0,
    east: 0,
    south: 180,
    west: 180,
  };

  mapLayers.forEach((layer) => {
    if (
      layer.feature.geometry.type.toUpperCase() ===
      MapDataTypes.POLYGON
    ) {
      return point;
    }

    const geometryCoordinates = [...layer.feature.geometry.coordinates];

    const coordinates = geometryCoordinates.reverse();

    point.north =
      point.north < coordinates[1] ? coordinates[1] : point.north;
    point.south =
      point.south > coordinates[1] ? coordinates[1] : point.south;
    point.east = point.east < coordinates[0] ? coordinates[0] : point.east;
    point.west = point.west > coordinates[0] ? coordinates[0] : point.west;
  });

  return point;
};

const setAreaBoundaries = (mapLayers: any, lastAddedLayer: string): {
  north: number;
  east: number;
  south: number;
  west: number;
} => {
  switch (lastAddedLayer) {
    case MapDataTypes.POLYGON:
      return getPolygonAreaBoundaries(mapLayers);
    case MapDataTypes.POINT:
      return getPointAreaBoundaries(mapLayers);
    default:
      return getPointAreaBoundaries(mapLayers);
  }
};

export const setMapCenterAndZoom = (mapRef: any, dataTypes: string[]) => {
  if (mapRef?.current) {
    const mapLayers = mapRef.current._layers;

    const lastMapLayers: any[] = [];
    Object.keys(mapLayers).forEach((key) => {
      const feature = mapLayers[key].feature as Feature;
      if (
        feature &&
        feature.properties.index === dataTypes.length - 1
      ) {
        lastMapLayers.push(mapLayers[key]);
      }
    });

    const lastMapLayerType = dataTypes[dataTypes.length - 1];

    const areaBoundaries: {
      north: number;
      east: number;
      south: number;
      west: number;
    } = setAreaBoundaries(lastMapLayers, lastMapLayerType);

    const areaBoundariesCorrected = { ...areaBoundaries };

    // отдаляем зум, если слишком близко (0.1 = ~11km)
    const precision = 0.5;
    const diffNorthSouth = Math.abs(areaBoundaries.north - areaBoundaries.south);

    if (diffNorthSouth < precision) {
      areaBoundariesCorrected.north += (precision - diffNorthSouth) / 2;
      areaBoundariesCorrected.south -= (precision - diffNorthSouth) / 2;
    }

    const corner1 = L.latLng(areaBoundariesCorrected.east, areaBoundariesCorrected.north);
    const corner2 = L.latLng(areaBoundariesCorrected.west, areaBoundariesCorrected.south);
    const bounds = L.latLngBounds(corner1, corner2);
    const center = bounds.getCenter();

    const isDataNotLoad =
      areaBoundaries.west === 180 &&
      areaBoundaries.south === 180 &&
      areaBoundaries.north === 0 &&
      areaBoundaries.east === 0;
    if (!isDataNotLoad) {
      mapRef.current.setView(center);
      mapRef.current.fitBounds(bounds);
    }
  }
};

export const getIconsList = (axisXValues: PropertyData[]) => {
  const icons: string[] = [];

  axisXValues.forEach((value) => {
    icons.push(JSON.parse(value.storage || '{}').icon);
  });

  return icons;
};

export const getClusteredMarkers = () => {
  return (L as any).markerClusterGroup({
    spiderfyOnMaxZoom: false,
    disableClusteringAtZoom: 10,
    removeOutsideVisibleBounds: true,
    animateAddingMarkers: true,
    iconCreateFunction(cluster: L.MarkerCluster) {
      const count = cluster.getChildCount();
      return L.divIcon({
        html: count.toString(),
        className: 'cluster-marker',
        iconSize: [31, 31],
      });
    },
  });
};
