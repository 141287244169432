import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const groupViewInitialState = { isTable: false };

const groupViewSlice = createSlice({
  name: 'groupView',
  initialState: groupViewInitialState,
  reducers: {
    toggle: (state, { payload }: PayloadAction<{ isTable: boolean }>) => {
      return payload;
    },
  },
});

export const groupViewReducer = groupViewSlice.reducer;

export const { toggle: setGroupViewAction } = groupViewSlice.actions;
