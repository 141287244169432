import React, { CSSProperties } from 'react';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import IconSvg from '../../../common/icon-svg/icon-svg';
import './styles.css';
import { WidgetType } from '../../../../enums/widget-type';
import { useLegend } from './useLegend';
import { CustomProgress } from '../../../../uikit/Progress';

type LegendSortingProps = {
  isSortedDataLoading: boolean;
  currentOrderIndexColumn: number;
  getOrderIconByIndex: any;
  changeOrderingHandler: any;
};

export type LegendProps = {
  currentColors: any[];
  legendSortingProps?: LegendSortingProps;
  legendsContainerStyles: CSSProperties;
  widgetProperties: any[];
  widgetType: WidgetType;
  widgetId: number;
  isDisabledActivity: boolean;
};

const legendGlyphSize = 16;

export const Legend = ({
  currentColors,
  legendSortingProps,
  legendsContainerStyles,
  widgetProperties,
  widgetType,
  widgetId,
  isDisabledActivity,
}: LegendProps) => {
  const {
    ordinalColorScale,
    isSortedDataLoading,
    changeOrderingHandler,
    currentOrderIndexColumn,
    getOrderIconByIndex,
    isWidgetWithActiveLegend,
    onChangeActiveLegend,
    isActiveLegend,
    legendsLabels,
  } = useLegend({
    currentColors,
    legendSortingProps,
    legendsContainerStyles,
    widgetProperties,
    widgetType,
    widgetId,
    isDisabledActivity,
  });

  return (
    <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => label}>
      {(labels) => (
        <div className="legends-container" style={legendsContainerStyles}>
          {labels.map((label, i) => (
            <LegendItem key={`legend-quantile-${i}`} margin="5px">
              {legendSortingProps && (
                <Box
                  ml="10px"
                  onClick={
                    isSortedDataLoading ? undefined : changeOrderingHandler(i)
                  }
                  className={clsx('legends-order', {
                    'legends-order-pointer': !isSortedDataLoading,
                  })}
                >
                  {isSortedDataLoading && currentOrderIndexColumn === i ? (
                    <CustomProgress type="circular" size="20px" />
                  ) : (
                    <IconSvg
                      svg={getOrderIconByIndex(i)}
                      fill="var(--grey)"
                      isNeedToScale={false}
                    />
                  )}
                </Box>
              )}
              <svg
                width={legendGlyphSize}
                height={legendGlyphSize}
                style={{
                  cursor: isWidgetWithActiveLegend ? 'pointer' : 'auto',
                }}
                onClick={
                  isWidgetWithActiveLegend
                    ? () => onChangeActiveLegend(i)
                    : undefined
                }
              >
                <rect
                  fill={
                    (isWidgetWithActiveLegend && isActiveLegend(i)) ||
                    !isWidgetWithActiveLegend
                      ? label.value
                      : 'var(--grey)'
                  }
                  width={legendGlyphSize}
                  height={legendGlyphSize}
                  rx={2}
                />
              </svg>
              <LegendLabel className="legend-label__text" align="left">
                {legendsLabels[label.index]?.title ||
                  legendsLabels[label.index]}
              </LegendLabel>
            </LegendItem>
          ))}
        </div>
      )}
    </LegendOrdinal>
  );
};
