export const DashboardNames = {
  dashboards: 'дашборды',
  title: 'Дашборд',
  titleMany: 'Дашборды',
  dashboardName: 'имя дашборда',
  one: 'дашборд',
  few: 'дашборда',
  many: 'дашбордов',
  dashboardCount: 'Количество дашбордов',
  dashboardSearch: 'Поиск по дашбордам',
  noDashboards: 'ни одного дашборда'
};
