export const WidgetNames = {
  widgets: 'виджеты',
  title: 'Виджет',
  titleMany: 'Виджеты',
  titleGroupWidgets: 'Общие виджеты',
  titleProjectWidgets: 'Виджеты проекта',
  mainWidget: 'Основной виджет',
  one: 'виджет',
  few: 'виджета',
  many: 'виджетов',
  oneOf: 'одного из виджетов',
  countWidgets: 'одного виджета',
  addWidget: 'Добавить виджет',
  searchWidget: 'Поиск по виджетам',
  newWidget: 'Новый виджет',
  openWidget: 'Редактировать виджет',
  detailsWidgetMessage: 'Для данного виджета настроена детализация',
  countBoundWidget: 'одного связанного виджета',
  betweenWidgets: 'между виджетами',
  manipulateWidget: 'виджет',
};
