import { useCallback, useState } from 'react';
import { settingTabs } from '../../enums';
import { BoundTypeSwitcherProps } from './bound-type-switcher';

export const useBoundTypeSwitcher = ({
  currentTab,
  handleSwitchCurrentTab,
  enableVerification,
}: BoundTypeSwitcherProps) => {
  const [openAlert, setOpenAlertAlert] = useState<boolean>(false);
  const [tab, setTab] = useState<settingTabs>(currentTab);

  const handleClose = () => {
    setOpenAlertAlert(false);
  };

  const handleOpenSwitchDialog = useCallback(
    (newTab: settingTabs) => {
      if (currentTab === newTab) return;

      if (enableVerification) {
        setOpenAlertAlert(true);
        setTab(newTab);
      } else {
        handleSwitchCurrentTab(newTab);
        handleClose();
      }
    },
    [currentTab, enableVerification, handleSwitchCurrentTab],
  );

  const handleSwitch = () => {
    handleSwitchCurrentTab(tab);
    handleClose();
  };

  return {
    handleOpenSwitchDialog,
    openAlert,
    handleClose,
    handleSwitch,
  };
};
