import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual, uniq } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab';
import { selectGlobalParameters } from '../../../../../slices/parameters/parameters';
import { State } from '../../../../../slices/types';
import { CustomRadio } from '../../../../../uikit/Radio/Radio';
import { isEditDashboardPage } from '../../../charts/helpers';
import { SelectFieldOption, SelectFieldProps } from './select-field';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomProgress } from '../../../../../uikit/Progress';

const useStyles = makeStyles({
  sharp: {
    width: 'calc(40px * var(--scale-coefficient))',
    height: 'calc(40px * var(--scale-coefficient))',
    padding: 'calc(10px * var(--scale-coefficient))',
    marginRight: 'calc(8px * var(--scale-coefficient))',
    fontFamily: 'Roboto',
    lineHeight: 'calc(24px * var(--scale-coefficient))',
    fontSize: 'calc(24px * var(--scale-coefficient))',
    fontWeight: 700,
    textAlign: 'center',
  },
});

const all = Symbol('all');

export const useSelectField = ({
  onChange,
  onOpen,
  onInput,
  isLoading,
  values,
  options,
  optionsLimit = 200,
  isNeedSelectAll = true,
  isUseParameters = true,
  isNotFilterOptions,
  isSingleSelection,
  label,
}: SelectFieldProps) => {
  const classes = useStyles();

  const defaultOptions = options.filter((option) =>
    [...values].includes(option.value),
  );
  const defaultOptionsValues = defaultOptions.map((option) => option.value);

  const parameters = useSelector(selectGlobalParameters);
  const parametersList = parameters
    .map((parameter) => `#${parameter.parameterCode}`)
    .filter((parameter) => !defaultOptionsValues.includes(parameter));

  const initialInputValue = isSingleSelection ? String(values?.[0] || '') : '';

  const [inputValue, setInputValue] = useState<string>(
    initialInputValue,
  );
  useEffect(() => {
    setInputValue(initialInputValue);
  }, [initialInputValue]);


  const [filterValue, setFilterValue] = useState<string>('');

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(filterValue.toLowerCase()),
  );

  const [open, setOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement>(null);
  const [isParameterPopperOpen, setParameterPopperOpen] = useState(false);

  const isSelectAll =
    !isLoading &&
    filteredOptions.every((option) => values.includes(option.value));

  const handleChange = (
    event: any,
    value: SelectFieldOption | SelectFieldOption[],
  ) => {

    const values = isSingleSelection
      ? [value as SelectFieldOption].filter(Boolean)
      : (value as SelectFieldOption[]);

    const currentValues = values
      .map((option) => option.value)
      .filter((value) => value !== all)
      .filter((value) => value !== '#');

    const isSelectedParameterClick = values.some((item) => item.value === '#');
    if (isSelectedParameterClick) {
      setOpen(false);
      setParameterPopperOpen(true);
      return;
    }

    const isSelectedAllClick = values.some((item) => item.value === all);
    if (isSelectedAllClick) {
      const allOptionValues = filteredOptions.map(
        (option) => option.value,
      ) as string[];
      const newValue = isSelectAll
        ? ([...currentValues].filter(
          (value) => !allOptionValues.includes(value.toString()),
        ) as string[])
        : allOptionValues;

      if (isSingleSelection) setInputValue((currentValues[0] || '') as string);
      onChange(newValue);
      return;
    }

    if (isSingleSelection) setInputValue((currentValues[0] || '') as string);
    onChange(uniq(currentValues) as string[]);
  };

  const selectAllOption = useMemo(
    () =>
      isNeedSelectAll ? [{ value: all, label: 'Выбрать все значения' }] : [],
    [isNeedSelectAll],
  );

  const parameterOption = useMemo(
    () =>
      isEditDashboardPage() && isUseParameters && parameters.length > 0
        ? [{ value: '#', label: 'Выбрать параметр' }]
        : [],
    [isUseParameters, parameters.length],
  );

  const handleInputChange = useCallback((event: any) => {
    if (event.target.value.includes('#')) {
      setOpen(false);
      setParameterPopperOpen(true);
    }
    setInputValue(event.target.value);
  }, []);

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    if (!isSingleSelection) setInputValue('');
  };

  const handleParameterSelect = (parameter: string) => {
    onChange([...defaultOptionsValues, parameter] as string[]);
    handleParameterClose();
    handleOpen();
    if (!isSingleSelection) setInputValue('');
  };

  const handleParameterClose = () => {
    setParameterPopperOpen(false);
    handleOpen();
    if (!isSingleSelection) setInputValue('');
  };

  const renderOption = (option: any, renderState: any) => {

    const ControlComponent = isSingleSelection ? CustomRadio : CustomCheckbox;

    return option.value === '#' ? (
      <>
        <div className={classes.sharp}>#</div>
        <div>{option.label}</div>
      </>
    ) : (
      <CustomFormControl
        control={
          <ControlComponent
            checked={option.value === all ? isSelectAll : renderState.selected}
          />
        }
        label={option.label}
      />
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      ref={anchorEl}
      variant="filled"
      placeholder={label}
      onInput={onInput}
      onChange={handleInputChange}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading ? <CustomProgress type="circular" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  const filterOptions = (options: any[], state: FilterOptionsState<any>) => {
    if (isNotFilterOptions || isSingleSelection) return options;
    const filteredOptions = options
      .filter((option) =>
        option.label.toLowerCase().includes(state.inputValue.toLowerCase()),
      )
      .slice(0, optionsLimit);
    if (filteredOptions.length) {
      filteredOptions.unshift(...selectAllOption);
      filteredOptions.unshift(...parameterOption);
    }

    if (!isEqual(state.inputValue, filterValue)) {
      setFilterValue(state.inputValue);
    }

    return filteredOptions;
  };

  return {
    open,
    handleChange,
    handleOpen,
    handleClose,
    filterOptions,
    inputValue,
    defaultOptions,
    isParameterPopperOpen,
    parameterOption,
    anchorEl,
    parametersList,
    handleParameterSelect,
    handleParameterClose,
    renderOption,
    renderInput,
  };
};
