import React from 'react';
import './index.css';
import clsx from 'clsx';

interface CustomListItemProps {
  title: string;
  titleVariant?: 'text' | 'primary';
  children?: JSX.Element | JSX.Element[] | false;
  disabled?: boolean;
  onClick?: () => void;
  error?: boolean;
  active?: boolean;
}

export const CustomListItem = ({
  title,
  titleVariant = 'text',
  children,
  disabled,
  onClick,
  error,
  active,
}: CustomListItemProps) => {
  return (
    <div
      className={clsx('custom-list-item', {
        'custom-list-item_disabled': disabled,
        'custom-list-item_error': error,
        'custom-list-item_active': active,
      })}
      onClick={onClick}
    >
      <span
        className={clsx('custom-list-item__title', {
          'custom-list-item__title_primary': titleVariant === 'primary',
        })}
      >
        {title}
      </span>
      {children && <div className="custom-list-item__actions">{children}</div>}
    </div>
  );
};
