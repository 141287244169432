import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { CircularProgress, FormControlLabel } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Group, Project, State } from '../../../slices/types';

import {
  apiBasicChangeProject,
  apiCopyProject,
  apiCreateNewProject,
  apiGetProject,
} from '../../../services/projectsController';

import { getAllGroups } from '../../../services';
import {
  setGroupsAction,
  setInitGroupsAction,
} from '../../../slices/group-slice/group-slice';
import { SelectInput } from '../special-inputs/inputs/select-input';
import { TextInput } from '../special-inputs/inputs/text-input';
import { apiRunCache } from '../../../services/widgetController';
import {
  setCurrentProjectAction,
  setLoadingAction,
} from '../../../slices/main-page/main-page-slice';
import { useNotificator } from '../snackbar/hooks';
import { CustomButton } from '../../../uikit/Button';
import { CustomCheckbox } from '../../../uikit/Checkbox';
import { CustomFormControl } from '../../../uikit/FormControl';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../uikit/Progress';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';

export interface FormValues extends FormikValues {
  name: string;
  projectGroupId: number;
  personal: boolean;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    `Название ${CommonDictionary.projectGenitive} не должно быть пустым`,
  ),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: 'calc(16px * var(--scale-coefficient))',
      },
      padding: 'calc(20px * var(--scale-coefficient))',
    },
    buttonCreate: {
      marginRight: '10px',
      minWidth: '136px',
    },
    circularProgress: {
      color: 'var(--white)',
    },
    checkbox: {
      marginRight: 'calc(40px * var(--scale-coefficient))',
    },
  }),
);

interface NewProjectFormProps {
  handleCloseForm: () => void;
  isCopy?: boolean;
  projectId?: number;
  projectGroupId?: number | null;
  projectName?: string;
  projectIsPersonal?: boolean;
  projectIsFavourite?: boolean;
}

const NewProjectForm: React.FC<NewProjectFormProps> = ({
  handleCloseForm,
  isCopy = false,
  projectId,
  projectGroupId,
  projectName,
  projectIsPersonal,
  projectIsFavourite,
}) => {
  const classes = useStyles();
  const filters = useSelector((state: State) => state.mainPage.filters);

  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector((state: State) =>
    state.groups.initGroups.map((group: Group) => ({
      name: group.groupName,
      id: group.groupId,
    })),
  );
  
  const isLoading = useSelector((state: State) => state.mainPage.isLoading);

  const prefix = isCopy ? 'Copy-' : '';

  const initialValues = {
    name: projectName ? prefix + projectName : '',
    projectGroupId: projectGroupId || 1,
    personal: projectIsPersonal || false,
    referenceMap: false,
  };

  useEffect(() => {
    if (groups.length) return;
    dispatch(setLoadingAction(true));
    getAllGroups().then((response) => {
      dispatch(setGroupsAction(response?.data));
      dispatch(setLoadingAction(false));
    });
  }, []);

  const { showNotification } = useNotificator();

  const onSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    dispatch(setLoadingAction(true));
    if (isCopy && projectId) {
      apiCopyProject(projectId, values)
        .then((result) => {
          apiRunCache(result.loaderId).then(() => {
            handleCloseForm();
            getAllGroups(filters).then((response) => {
              dispatch(setGroupsAction(response?.data));
              dispatch(setLoadingAction(false));
            });
          });
        })
        .catch((err) => {
          console.error(
            `Ошибка при копировании ${CommonDictionary.projectGenitive}`,
            err.response,
          );
          formikHelpers.setErrors({ name: err.response?.data?.message });
          dispatch(setLoadingAction(false));
        });
    }
    if (!isCopy && projectId) {
      apiBasicChangeProject(projectId, {
        ...values,
        favourite: projectIsFavourite,
      })
        .then(() => {
          getAllGroups(filters).then((response) => {
            dispatch(setGroupsAction(response?.data));

            apiGetProject(Number(projectId)).then((project: Project) => {
              handleCloseForm();
              dispatch(setCurrentProjectAction(project));
              showNotification({ message: 'Сохранено', variant: 'success' });
              dispatch(setLoadingAction(false));
            });
          });
        })
        .catch((err) => {
          console.error(
            `Ошибка при редактировании ${CommonDictionary.projectGenitive}`,
            err.response,
          );
          formikHelpers.setErrors({ name: err.response?.data?.message });
          dispatch(setLoadingAction(false));
        });
    }
    if (!isCopy && !projectId) {
      apiCreateNewProject(values)
        .then((data) => {
          history.push(`project/${data.id}`);
          handleCloseForm();
          dispatch(setLoadingAction(false));
        })
        .catch((err) => {
          console.error(
            `Ошибка при создании ${CommonDictionary.projectGenitive}`,
            err.response,
          );
          formikHelpers.setErrors({ name: err.response?.data?.message });
          dispatch(setLoadingAction(false));
        });
    }
  };

  const { handleSubmit, handleChange, setFieldValue, values, errors } =
    useFormik<FormValues>({
      initialValues,
      onSubmit,
      validationSchema,
    });

  const getButtonName = () =>
    isCopy ? 'Дублировать' : projectId ? 'Сохранить' : 'Создать';

  return (
    <form className={classes.root} onSubmit={handleSubmit} autoComplete="off">
      <TextInput
        formikName="name"
        error={!!errors.name}
        label={`Название ${CommonDictionary.projectGenitive}`}
        value={values.name}
        handleChange={handleChange}
        fullWidth
      />
      {(projectId || isCopy) && (
        <SelectInput
          formikName="projectGroupId"
          label="Группа"
          value={values.projectGroupId}
          handleChange={handleChange}
          itemList={groups.map((group) => {
            return { value: group.id, showValue: group.name };
          })}
          fullWidth
        />
      )}

      <CustomFormControl
        className={classes.checkbox}
        control={
          <CustomCheckbox
            disabled={Boolean(isCopy)}
            name="personal"
            onChange={handleChange}
            checked={values.personal}
          />
        }
        label="Персональный"
      />

      {isCopy && (
        <FormControlLabel
          className={classes.checkbox}
          control={
            <CustomCheckbox
              name="referenceMap"
              onChange={handleChange}
              checked={values.referenceMap}
              color="primary"
              size="small"
            />
          }
          label="Переиспользовать карту"
        />
      )}

      {errors.name && <CustomErrorAlert>{errors.name}</CustomErrorAlert>}

      <div className="dialog-buttons dialog-buttons_in-form">
        <CustomButton
          disabled={isLoading}
          variant="contained"
          type="submit"
          startIcon={
            isLoading ? (
              <CustomProgress
                type="circular"
                style={{ color: 'var(--white)' }}
                size={20}
              />
            ) : null
          }
        >
          {getButtonName()}
        </CustomButton>
        <CustomButton
          disabled={isLoading}
          variant="outlined"
          onClick={handleCloseForm}
        >
          Отменить
        </CustomButton>
      </div>
    </form>
  );
};

export default NewProjectForm;
