import { useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';

export interface ShowNotificationParams {
  message: string;
  variant: VariantType;
}

export const useNotificator = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = useCallback(({ message, variant }: ShowNotificationParams) => {
    enqueueSnackbar(message, {
      variant,
    });
  }, []);

  const hideNotification = useCallback((id?: string) => {
    closeSnackbar(id);
  }, []);

  return {
    showNotification,
    hideNotification,
  };
};