import { setWidgetPropAction } from '../../../../slices/widget/widget';
import { PanelType } from '../../../../enums/widget-type';
import { getAxisValues, getParsedAxisValues } from './helpers';

const setMapAxisValue = (
  axisName: string,
  axisValue: any,
  dispatch: any,
  properties: any,
) => {
  const isAxisYSet = properties.some((item: any) => {
    return item.name === axisName && Boolean(item.value) && item.value !== '';
  });

  if (isAxisYSet) {
    const currentValue = getAxisValues(axisName, properties);

    const newValue = JSON.parse(currentValue).concat(axisValue);

    dispatch(
      setWidgetPropAction({
        name: axisName,
        value: JSON.stringify(newValue),
      }),
    );
  } else {
    dispatch(
      setWidgetPropAction({
        name: axisName,
        value: JSON.stringify(axisValue),
      }),
    );
  }
};

export const setMapFields = (
  dispatch: any,
  sourceData: any,
  result: any,
  properties: any,
) => {
  const propertiesCopy = [...properties];
  const mapItemDescription = sourceData.fields.filter((item: any) => {
    const isDescriptionItem =
      item.type.indexOf('POLYGON') === -1 &&
      item.type.indexOf('POINT') === -1 &&
      item.name.indexOf('point') === -1 &&
      item.name.indexOf('polygon') === -1;

    item.isActive = true;

    if (isDescriptionItem) {
      delete item['dimension'];
      delete item['measure'];
      item.etlSourceId = JSON.parse(result.draggableId).id;
      return item;
    }
  });

  const mapCoordinatesData = sourceData.fields.find(
    (item: any) => item.type.includes('POLYGON') || item.type.includes('POINT'),
  );

  const mapCoordinatesDataObj: any = { ...mapCoordinatesData };
  mapCoordinatesDataObj.etlSourceId = JSON.parse(result.draggableId).id;
  mapCoordinatesDataObj.displayName = JSON.parse(result.draggableId).name;
  delete mapCoordinatesDataObj['dimension'];
  delete mapCoordinatesDataObj['measure'];

  const mapCoordinatesList = [];
  mapCoordinatesList.push(mapCoordinatesDataObj);

  setMapAxisValue('axisY', mapItemDescription, dispatch, propertiesCopy);
  setMapAxisValue('axisX', mapCoordinatesList, dispatch, propertiesCopy);
};

export const removeMapPropertyHandler = (
  widgetProperties: any,
  index: any,
  dispatch: any,
) => {
  const properties = [...widgetProperties];

  const axisXValues = getAxisValues('axisX', properties);
  const itemToDelete = JSON.parse(axisXValues)[index];
  const itemSourceId = itemToDelete.etlSourceId;

  const setNewProperty = (axisName: PanelType) => {
    const axisValues = getParsedAxisValues(axisName, properties);
    if (axisValues.length) {
      const axisNewValues = axisValues.filter(
        (item: any) => item.etlSourceId !== itemSourceId,
      );
      dispatch(
        setWidgetPropAction({
          name: axisName,
          value: JSON.stringify(axisNewValues),
        }),
      );
    }
  };

  const axisXValuesParsed = JSON.parse(axisXValues);
  axisXValuesParsed.splice(index, 1);

  dispatch(
    setWidgetPropAction({
      name: 'axisX',
      value: JSON.stringify(axisXValuesParsed),
    }),
  );

  setNewProperty(PanelType.axisY);
  setNewProperty(PanelType.axisZ);
  setNewProperty(PanelType.axisFilter);
};
