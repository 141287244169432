export const styles: any = {
  button: {
    padding: 'calc(9px * var(--scale-coefficient)) calc(11px * var(--scale-coefficient))',
    border: '1px solid var(--dark-cyan)',
    background: 'var(--white)',
    display: 'flex',
    alignItems: 'center',
    height: 'calc(46px * var(--scale-coefficient))',
    boxSizing: 'border-box',
    borderRadius: 'var(--base-border-radius)',
    cursor: 'pointer',
    justifyContent: 'space-between',
    width: '100%',
  },
  preview: ({ color }: { color: string }) => ({
    backgroundColor: color,
    width: 'calc(24px * var(--scale-coefficient))',
    height: 'calc(24px * var(--scale-coefficient))',
    borderRadius: '2px',
    alignSelf: 'center',
  }),
  icon: {
    marginLeft: 'calc(10px * var(--scale-coefficient))',
  },
  pickerContainer: {
    borderRadius: '5px',
    overflow: 'hidden',
  },
  colorTitle: {
    fontFamily: 'Roboto',
    fontSize: 'calc(12px * var(--scale-coefficient))',
    lineHeight: 'calc(12px * var(--scale-coefficient))',
    textAlign: 'left',
    marginTop: 'calc(-3px * var(--scale-coefficient))',
    marginBottom: 'calc(-3px * var(--scale-coefficient))',
    color: 'var(--dark-grey)',
  },
  color: {
    fontFamily: 'Roboto',
    fontSize: 'calc(14px * var(--scale-coefficient))',
    fontWeight: 500,
    lineHeight: 'calc(14px * var(--scale-coefficient))',
    textAlign: 'left',
    color: 'var(--black)',
    marginTop: 2
  },
  close: {
    marginLeft: 'auto',

    '& svg': {
      width: 'calc(16px * var(--scale-coefficient)) !important',
      height: 'calc(16px * var(--scale-coefficient)) !important',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 'calc(6px * var(--scale-coefficient))',
  },
};
