import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { CssVariables } from '../../../../enums/css-variables';
import { FilterProperty } from '../../../../enums/dashboard-properties';
import { getFilterSettingValue } from '../../../../helpers/dashboard-page/filters-helper';
import { getScaleCoefficient, varcss } from '../../../../helpers/ui-helper';
import { isMobilehelper } from '../../../../utils/mobile-helper';
import IconSvg from '../../../common/icon-svg/icon-svg';
import './styles.css';
import {
  closeDashboardFiltersAction,
  openDashboardFiltersAction,
} from '../../../../slices/dashboard/dashboard';
import { isDashboardPreviewPage } from '../../../widget-page/charts/helpers';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import { useDashboardSettings } from '../../settings/hooks/useDashboardSettings';
import { CustomButton } from '../../../../uikit/Button';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export const FiltersButton = () => {
  const { showQuickFiltersPanel } = useDashboardSettings();
  const dispatch = useDispatch();
  const { values: parsedValues } = useDashboardFilters();
  const enabledFiltersCount = parsedValues.filter((prop: any) => {
    return prop?.actual && isDashboardPreviewPage() ? getFilterSettingValue(prop, FilterProperty.isVisible) : true;
  }).length;

  const isMobile = useMemo(() => isMobilehelper(), []);

  const activeFiltersCount = parsedValues.filter((prop: any) => {
    return prop?.actual && prop?.value?.length > 0;
  }).length;

  const openHandler = useCallback(() => {
    dispatch(openDashboardFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (enabledFiltersCount === 0) {
      dispatch(closeDashboardFiltersAction());
    }
  }, [dispatch, enabledFiltersCount]);

  if (!enabledFiltersCount) return null;

  return (
    <>
      {!showQuickFiltersPanel || isMobile ?
        <CustomTooltip title="Фильтры">
          <div style={{ marginRight: 12 * getScaleCoefficient() }}>
            <CustomButton
              variant="outlined"
              icon={<IconSvg
                svg={IconDictionary.WidgetFilter}
                fill="var(--dark-grey)"
              />}
              endIcon={activeFiltersCount ? <div className="dashboard-filters-count">{activeFiltersCount}</div> : null}
              onClick={openHandler}
              backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
            />
          </div>
        </CustomTooltip> : null}
    </>
  );
};

FiltersButton.displayName = 'FiltersButtonHeader';
