import { concat, includes, slice, toUpper, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { WidgetSimplifiedDataType } from '../../../../enums/data-type';
import { PanelItemWarningType } from '../../../../enums/warnings';
import { PanelType, WidgetPropertyType, WidgetType } from '../../../../enums/widget-type';
import {
  AxisWithBookmarks,
  isMultipleAxisYWithBookmarks,
  WidgetsWithBookmarks
} from '../../../../helpers/common-helpers';
import { RemovableFieldsInFiltersSynchronization } from '../../../../helpers/widget-page';
import { Widget, WidgetProperties } from '../../../../slices/types';
import { isNeedMergeNotNumberValues, wrapFieldToReplace } from '../../../../utils/functions';
import { getTableHeader } from '../../charts/complex-table/helper';
import { Property, PropertyData, PropertyRestriction } from './Property';


interface RemovePropertyByIndex {
  properties: any[];
  panelType: PanelType;
  index: number;
}

interface GetPanelIndex {
  properties: WidgetProperties[];
  type: PanelType | WidgetPropertyType;
}

interface PropertyOptions {
  value: string | object;
  currentValue?: string | null;
  isSingle?: boolean;
  settableItemIndex?: number | null;
  isNoMoreTwoOptions?: boolean;
}

enum PropertyType {
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
}

export const typeGuards = ({ widgetType, axisType, property }: any) => {
  switch (axisType) {
    case PanelType.nameStage: {
      if (widgetType === WidgetType.GANTT_CHART) {
        return !property.measure;
      }

      return true;
    }

    case PanelType.axisX:
    case PanelType.progress: {
      if (widgetType === WidgetType.GANTT_CHART) {
        return getSimplifiedType(property.type) === 'NUMBER';
      }

      return true;
    }

    case PanelType.axisY:
    case PanelType.dateEndStage: {
      if (widgetType === WidgetType.GANTT_CHART) {
        return getSimplifiedType(property.type) === 'DATE';
      }

      return true;
    }

    default: {
      return true;
    }
  }
};

export const isCalculatedMeasure = (item: any) => {
  return !!item.template;
};

export const isComplexMeasure = (item: any) => {
  return item.complex;
};

export const getSimplifiedType = (type: string): WidgetSimplifiedDataType => {
  switch (toUpper(type)) {
    case 'DOUBLE':
    case 'LONG':
    case 'INTEGER': {
      return WidgetSimplifiedDataType.NUMBER;
    }

    case 'POINT_LON_LAT':
    case 'POINT_LAT_LON':
    case 'POLYGON':
    case 'STRING': {
      return WidgetSimplifiedDataType.TEXT;
    }

    case 'DATE':
    case 'TIMESTAMP': {
      return WidgetSimplifiedDataType.DATE;
    }

    case 'BOOLEAN':{
      return WidgetSimplifiedDataType.BOOLEAN;
    }
    default: {
      return WidgetSimplifiedDataType.EMPTY;
    }
  }
};

export const getAxisValues = (name: string, properties: Array<any>): string => {
  return properties?.find((prop) => prop.name === name)?.value ?? '[]';
};

export const getParsedAxisValues = (
  axisName: PanelType | WidgetPropertyType,
  properties: Array<any>,
) => {
  try {
    return JSON.parse(getAxisValues(axisName, properties));
  } catch {
    return [];
  }
};

const filterByIndex = (index: number) => (item: any, lIndex: number) =>
  lIndex !== index;

export const transformValueToJSON = (value: any) =>
  value.length ? JSON.stringify(value) : '[]';

export const getAggregationType = (
  property: any,
  panelType: PanelType,
  widget: Widget,
) => {
  const isMeasure = property.measure;

  const type: PropertyType = isMeasure
    ? PropertyType.MEASURE
    : PropertyType.DIMENSION;

  const categoriesTypes = [PanelType.axisX];
  const valuesTypes = [PanelType.axisY];

  const simplifiedType = getSimplifiedType(property.type);

  const axisZWidgetsWithDefaultAggregation = [
    WidgetType.SPEEDOMETER,
    WidgetType.INFORMATION_CARD,
    WidgetType.HISTOGRAM_GRAPH,
    WidgetType.GRAPH_AREA,
    WidgetType.PIE_CHART,
    WidgetType.BUBBLE_CHART,
  ];
  const axisZDataTypesWithCountAggregation = ['DATE', 'TEXT'];

  if (categoriesTypes.includes(panelType)) {
    if (widget.type === WidgetType.TABLE) {
      return {
        [PropertyType.MEASURE]: '',
        [PropertyType.DIMENSION]: '',
      }[type];
    }

    if (
      [WidgetType.SPEEDOMETER, WidgetType.INFORMATION_CARD].includes(
        widget.type as WidgetType,
      )
    ) {
      const aggregation =
        simplifiedType === 'NUMBER' ? 'SUM(%s)' : 'COUNT (DISTINCT %s)';

      return {
        [PropertyType.MEASURE]: aggregation,
        [PropertyType.DIMENSION]: 'COUNT (DISTINCT %s)',
      }[type];
    }

    return {
      [PropertyType.MEASURE]: '',
      [PropertyType.DIMENSION]: '',
    }[type];
  }

  if (valuesTypes.includes(panelType)) {
    if (widget.type === WidgetType.GANTT_CHART) {
      return {
        [PropertyType.MEASURE]: '',
        [PropertyType.DIMENSION]: '',
      }[type];
    }

    return {
      [PropertyType.MEASURE]: 'SUM(%s)',
      [PropertyType.DIMENSION]: 'COUNT (DISTINCT %s)',
    }[type];
  }

  if (panelType === PanelType.axisZ) {
    if (
      axisZWidgetsWithDefaultAggregation.includes(widget.type as WidgetType)
    ) {
      return axisZDataTypesWithCountAggregation.includes(simplifiedType)
        ? 'COUNT (DISTINCT %s)'
        : 'SUM(%s)';
    }
  }

  return '';
};

export const getNewFiltersValue = (properties: any, newValue: any) => {
  const axisFilter = getParsedAxisValues(PanelType.axisFilter, properties);

  const valueIndex = axisFilter.findIndex((item: any) => item.etlFieldId === newValue.etlFieldId);
  valueIndex !== -1 && axisFilter.splice(valueIndex, 1);

  axisFilter.push(newValue);

  return JSON.stringify(axisFilter);
};

export const getNewPropertiesWithFilters = (
  properties: any,
  panelType: PanelType,
  index: number,
) => {
  const filtersIndex = getPanelIndex({
    properties,
    type: PanelType.axisFilter,
  });
  const axisFilter = getParsedAxisValues(PanelType.axisFilter, properties);

  const currentValueName = getParsedAxisValues(panelType, properties)[index]
    .name;

  // если на главной оси полей больше чем 1, то поле с оси фильтров не удаляем
  const countFields =
    getParsedAxisValues(panelType, properties).filter(
      (item: any) => item.name === currentValueName,
    ).length || 0;
  if (countFields > 1) return properties;

  const newAxisFilter = axisFilter.filter(
    (item: any) => item.name !== currentValueName,
  );

  const newProperties = [...properties];
  newProperties[filtersIndex] = {
    ...properties[filtersIndex],
    value: JSON.stringify(newAxisFilter),
  };

  return newProperties;
};

export const getNewPropertyValue = ({
  currentValue = null,
  value,
  isSingle = true,
  settableItemIndex = null,
}: PropertyOptions): string => {
  const parsed =
    isSingle || currentValue === null || currentValue === ''
      ? null
      : JSON.parse(currentValue);

  return JSON.stringify(
    Array.isArray(parsed)
      ? settableItemIndex
        ? [
            ...parsed.slice(0, settableItemIndex),
            value,
            ...parsed.slice(settableItemIndex),
          ]
        : [...parsed, value]
      : parsed
      ? [parsed, value]
      : [value],
  );
};

export const getPanelIndex = ({
  properties,
  type,
}: GetPanelIndex): number =>
  properties.findIndex((item) => item.name === type);

export const getPropertiesWithFilteredByIndexPanel = ({
  properties,
  panelType,
  index,
}: RemovePropertyByIndex): any[] => {
  const propertiesCopy = [...properties];

  const panelIndex = getPanelIndex({ properties, type: panelType });

  if (propertiesCopy[panelIndex] === undefined) {
    console.error('The value to be removed is placed on non-existent panel');
    return [];
  }

  const { value } = properties[panelIndex];

  if (value === undefined) {
    console.error('The value to be removed is placed on empty panel');
    return [];
  }

  const parsedValue = JSON.parse(value);

  const filteredValue = parsedValue.filter(filterByIndex(index));

  // проверяем, если не осталось активных полей, то активируем первое поле
  if (filteredValue.length > 0 && !filteredValue.some((field: PropertyData) => field.isActive)) {
    filteredValue[0].isActive = true;
  }

  const resultValue = transformValueToJSON(filteredValue);

  propertiesCopy[panelIndex] = {
    ...properties[panelIndex],
    value: resultValue,
  };

  const activeLegendIndex = getPanelIndex({
    properties,
    type: WidgetPropertyType.activeLegend,
  });

  if (panelType === PanelType.axisY && activeLegendIndex !== -1) {
    propertiesCopy.splice(activeLegendIndex, 1);
  }

  return propertiesCopy;
};

export const formatRestrictionText = (restriction: PropertyRestriction) => {
  const operandsAmount = restriction.operands?.length;
  let line = restriction.template;
  for (let i = 0; i < operandsAmount; i++) {
    const variableOperand = new RegExp(`\\$${i}(?!\\d)`, 'g');
    line = line.replace(
      variableOperand,
      `${wrapFieldToReplace(restriction.operands[i])}`,
    );
  }
  return line;
};

const compareIndexes = (a: any, c: any) => {
  if (a.firstIndex === c.firstIndex) {
    return c.name.length - a.name.length;
  }
  return a.firstIndex - c.firstIndex;
};

export const parseComplexRestriction = (
  fields: any[],
  line: string,
): PropertyRestriction => {
  const initialOperands: string[] = [];
  const initialOperandIds: string[] = [];
  const result = {
    template: line,
    operands: initialOperands,
    operandIds: initialOperandIds,
  };
  let firstIndexesInfo = [];
  let currentLine = line;
  do {
    firstIndexesInfo = fields
      .map((el: any) => ({
        name: el.name,
        firstIndex: currentLine.indexOf(wrapFieldToReplace(el.name)),
        etlFieldId: el.etlFieldId,
      }))
      .filter((el: any) => currentLine.includes(wrapFieldToReplace(el.name)))
      .sort(compareIndexes);
    if (firstIndexesInfo.length === 0) {
      return result;
    }
    const re = wrapFieldToReplace(firstIndexesInfo[0].name);
    currentLine = currentLine.replace(re, `$${result.operands.length}`);
    result.template = currentLine;
    result.operands = [...result.operands, firstIndexesInfo[0].name];
    result.operandIds = [...result.operandIds, firstIndexesInfo[0].etlFieldId];
  } while (firstIndexesInfo.length > 0);

  return result;
};

export const isHistogram = (widgetType: WidgetType) =>
  [
    WidgetType.HISTOGRAM,
    WidgetType.HORIZONTAL_HISTOGRAM,
    WidgetType.STACKED_HISTOGRAM,
    WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
    WidgetType.HISTOGRAM_GRAPH,
  ].includes(widgetType);

export const isGraph = (widgetType: WidgetType) =>
  [
    WidgetType.GRAPH,
    WidgetType.GRAPH_AREA,
    WidgetType.HISTOGRAM_GRAPH,
    WidgetType.BUBBLE_CHART,
  ].includes(widgetType);

export const getDefaultOrderDirection = (
  widgetType: WidgetType,
  panelType: PanelType,
) => {
  const isASCOrderDirection =
    (isHistogram(widgetType) || isGraph(widgetType)) &&
    panelType === PanelType.axisX;

  return isASCOrderDirection ? 'ASC' : '';
};

const getInitialActiveStatusForPanelItem = (
  panelType: PanelType,
  widget: Widget,
) => {
  if (
    !WidgetsWithBookmarks.includes(widget.type) ||
    !AxisWithBookmarks.includes(panelType)
  ) return true;

  const axisValues = getParsedAxisValues(panelType, widget.properties);

  if (panelType === PanelType.axisY) {
    return isMultipleAxisYWithBookmarks(widget.type) || axisValues.length === 0;
  }

  return axisValues.length === 0;
};

export const getDefaultProperties = (
  property: any,
  panelType: PanelType,
  widget: any,
  etlSourceId: any,
) => {
  return new Property({
    id: null,
    clientUID: uuidv4(),
    name: property.name,
    displayName: property.displayName || '',
    function: '',
    aggregation: getAggregationType(property, panelType, widget),
    restriction: property.restriction,
    type: property.type,
    filter: '',
    etlSourceId,
    etlFieldId: property.etlFieldId,
    orderDirection: getDefaultOrderDirection(widget.type, panelType),
    isDrillDownFilter: property.isDrillDownFilter,
    isActive: getInitialActiveStatusForPanelItem(panelType, widget),
  });
};

export const getDefaultPropertiesForCalculated = (
  property: any,
  panelType: PanelType,
  widget: any,
) => {
  return new Property({
    id: null,
    calculatedId: property.calculatedId,
    clientUID: uuidv4(),
    name: property.name,
    type: property.type,
    filter: '',
    etlFieldId: property.etlFieldId,
    orderDirection: getDefaultOrderDirection(widget.type, panelType),
    template: property.template,
    operands: property.operands,
    operandIds: property.operandIds,
    etlSourceId: property.etlSourceId,
    widgetFieldId: null,
    displayName: property.name,
    aggregation: getAggregationType(property, panelType, widget),
    function: '',
    restriction: null,
    isLagOrLead: property.isLagOrLead,
    isDrillDownFilter: property.isDrillDownFilter,
    isActive: getInitialActiveStatusForPanelItem(panelType, widget),
  });
};

export const getIsNeedSyncFiltersAxis = ({
  widgetType,
  panelType,
}: {
  widgetType: WidgetType;
  panelType: PanelType;
}) => {
  return (
    (panelType === PanelType.axisX && widgetType === WidgetType.TABLE) ||
    ((panelType === PanelType.axisX || panelType === PanelType.axisZ) &&
      widgetType === WidgetType.PIVOT_TABLE)
  );
};

const isFieldInCurrentPanelType = (
  properties: any[],
  panelType: PanelType,
  etlFieldId: string,
): boolean => {
  const etlFieldIds = getParsedAxisValues(panelType, properties).map(
    (item: any) => item.etlFieldId,
  );
  return includes(etlFieldIds, etlFieldId);
};

const isFieldOnMap = (
  properties: any[],
  id: string,
): boolean => {
  const ids = getParsedAxisValues(PanelType.axisX, properties).map(
    (item: any) => item.etlSourceId,
  );

  return includes(ids, id);
};

const isFieldInPivotPanel = (
  properties: any[],
  etlFieldId: string,
  panelType: PanelType,
): boolean => {
  const etlFieldIds = getParsedAxisValues(panelType, properties).map(
    (item: any) => item.etlFieldId,
  );
  return includes(etlFieldIds, etlFieldId);
};

export const isFieldInPivotPanels = (
  properties: any[],
  etlFieldId: string,
  panelType: PanelType,
): boolean => {
  let isFieldInPivotPanels = false;
  const pivotPanelTypes = panelType === PanelType.axisFilter
    ? [PanelType.axisX, PanelType.axisZ]
    : [PanelType.axisX, PanelType.axisY, PanelType.axisZ];
  pivotPanelTypes.forEach((panelType) => {
    const etlFieldIds = getParsedAxisValues(panelType, properties).map(
      (item: any) => item.etlFieldId,
    );
    isFieldInPivotPanels =
      isFieldInPivotPanels || includes(etlFieldIds, etlFieldId);
  });

  return isFieldInPivotPanels;
};

const isFieldInTreeMapPanels = (
  properties: any[],
  panelType: PanelType,
  etlFieldId: string,
): boolean => {
  let isFieldInPanels = false;
  const treeMapPanelTypes = panelType === PanelType.axisFilter ? [] : [PanelType.axisX, PanelType.axisY];
  treeMapPanelTypes.forEach((panelType) => {
    const etlFieldIds = getParsedAxisValues(panelType, properties).map(
      (item: any) => item.etlFieldId,
    );
    isFieldInPanels =
      isFieldInPanels || includes(etlFieldIds, etlFieldId);
  });

  return isFieldInPanels;
};

const isValidFieldForFilters = (
  properties: WidgetProperties[],
  etlFieldId: string,
  property: PropertyData,
) => {
  if (!property.complex) return true;

  let isFieldInPanels = false;
  const panelTypes = [PanelType.axisX, PanelType.axisY];
  panelTypes.forEach((panelType) => {
    const etlFieldIds = getParsedAxisValues(panelType, properties).map(
      (item: any) => item.etlFieldId,
    );
    isFieldInPanels = isFieldInPanels || includes(etlFieldIds, etlFieldId);
  });

  return isFieldInPanels;
};

const getErrorOfAddingFieldToPanelForAllWidgets = (
  properties: WidgetProperties[],
  panelType: PanelType,
  etlFieldId: string,
  property: PropertyData,
): string => {
  switch (panelType) {
    case PanelType.axisFilter:
      return !isValidFieldForFilters(properties, etlFieldId, property)
        ? PanelItemWarningType.complexCalculatedInFilters
        : PanelItemWarningType.empty;
    default:
      return PanelItemWarningType.empty;
  }
};

export const getErrorOfAddingFieldToPanel = (
  properties: WidgetProperties[],
  panelType: PanelType,
  property: PropertyData,
  widgetType: WidgetType,
): string => {
  const etlFieldId =
    widgetType === WidgetType.MAP
      ? (property.id as unknown as string)
      : property.etlFieldId;

  const commonError = getErrorOfAddingFieldToPanelForAllWidgets(properties, panelType, etlFieldId, property);
  if (commonError.length) return commonError;

  switch (widgetType) {
    case WidgetType.PIVOT_TABLE:
      return isFieldInPivotPanel(properties, etlFieldId, panelType)
        ? PanelItemWarningType.repeatingAddingField
        : PanelItemWarningType.empty;
    case WidgetType.TABLE:
      return isFieldInCurrentPanelType(properties, panelType, etlFieldId)
        ? PanelItemWarningType.repeatingAddingField
        : PanelItemWarningType.empty;
    case WidgetType.TREE_MAP:
      return isFieldInTreeMapPanels(properties, panelType, etlFieldId)
        ? PanelItemWarningType.repeatingAddingField
        : PanelItemWarningType.empty;
    case WidgetType.MAP:
      return isFieldOnMap(properties, etlFieldId)
        ? PanelItemWarningType.repeatingAddingField
        : PanelItemWarningType.empty;
    default:
      return PanelItemWarningType.empty;
  }
};

const getNewPropertyWithCorrectColoring = (
  properties: WidgetProperties[],
  panelType: PanelType,
  deletedValue: any,
) => {
  const axisIndex = getPanelIndex({ properties, type: panelType });

  const axis = properties[axisIndex];

  if (!axis.value.length) return axis;

  const axisValue = JSON.parse(axis.value);

  const newAxisValue = [...axisValue];

  axisValue.forEach((value: any, valueIndex: number) => {
    if (value.coloring?.length) {
      const newColoring = [...value.coloring];

      value.coloring.forEach((coloringItem: any, index: number) => {
        coloringItem.useFormula &&
          coloringItem.formula.operandIds.includes(deletedValue.etlFieldId) &&
          newColoring.splice(index, 1);

        !coloringItem.useFormula &&
          coloringItem.formula.simpleCondition?.includes(deletedValue.etlFieldId) &&
          newColoring.splice(index, 1);
      });

      newAxisValue[valueIndex].coloring = newColoring;
    }
  });

  return { ...axis, value: JSON.stringify(newAxisValue) };
};

export const getNewPropertiesWithCorrectColoring = (
  properties: WidgetProperties[],
  deletedValue: any,
): WidgetProperties[] => {
  const newProperties = [...properties];

  const axisXIndex = getPanelIndex({ properties, type: PanelType.axisX });
  const axisYIndex = getPanelIndex({ properties, type: PanelType.axisY });
  const axisZIndex = getPanelIndex({ properties, type: PanelType.axisZ });

  axisXIndex !== -1 &&
    (newProperties[axisXIndex] = getNewPropertyWithCorrectColoring(
      properties,
      PanelType.axisX,
      deletedValue,
    ));
  axisYIndex !== -1 &&
    (newProperties[axisYIndex] = getNewPropertyWithCorrectColoring(
      properties,
      PanelType.axisY,
      deletedValue,
    ));
  axisZIndex !== -1 &&
    (newProperties[axisZIndex] = getNewPropertyWithCorrectColoring(
      properties,
      PanelType.axisZ,
      deletedValue,
    ));

  return newProperties;
};

export const getSynchronizedFilters = (
  properties: WidgetProperties[],
  widgetType: WidgetType,
) => {
  const propertiesCopy = [...properties];

  const axisX: PropertyData[] = getParsedAxisValues(PanelType.axisX, properties);
  const axisZ: PropertyData[] = getParsedAxisValues(PanelType.axisZ, properties);
  const mainProperties: PropertyData[] = axisX.concat(axisZ).filter((item) => !item.hierarchyLink?.dicSourceId);
  const filters: PropertyData[] = getParsedAxisValues(PanelType.axisFilter, properties);
  let filterPanelIndex = getPanelIndex({
    properties,
    type: PanelType.axisFilter,
  });

  filterPanelIndex === -1 && (filterPanelIndex = properties.length);

  const synchronizedCount = mainProperties.length - 1;

  const isPivot = widgetType === WidgetType.PIVOT_TABLE;

  const manualFilters = (isPivot ? filters : slice(filters, synchronizedCount))
    .filter((item) => {
      return !mainProperties.some((prop) => prop.etlFieldId === item.etlFieldId);
    });


  const syncFilters = mainProperties.map((prop) => {
    const filterId =
      filters.find((filter) => filter.etlFieldId === prop.etlFieldId)
        ?.id || null;
    const widgetFieldId = filters.find(
      (filter) => filter.etlFieldId === prop.etlFieldId,
    )?.widgetFieldId;
    const isNeedRemoveWidgetFieldId = filters.find(
      (filter) =>
        filter.etlFieldId === prop.etlFieldId &&
        filter.widgetFieldId &&
        prop.widgetFieldId &&
        filter.widgetFieldId === prop.widgetFieldId,
    );

    const filterProperty = { ...prop };
    filterProperty.widgetFieldId = isNeedRemoveWidgetFieldId ? null : widgetFieldId;
    filterProperty.id = isCalculatedMeasure(filterProperty) ? null : filterId;
    filterProperty.isActive = true;

    RemovableFieldsInFiltersSynchronization.forEach((field) => {
      delete filterProperty[field as keyof PropertyData];
    });

    return filterProperty;
  });

  const syncFiltersUniq = isPivot ? uniqBy(syncFilters, 'etlFieldId') : syncFilters;

  const newFiltersConcat = concat(syncFiltersUniq, manualFilters);

  // сохраняем выставленные ранее значения фильтров
  const newFilters = newFiltersConcat.map((filter) => {
    const oldFilter = filters.find(
      (f) => f.etlFieldId === filter.etlFieldId,
    );
    return {
      ...filter,
      filter: oldFilter ? oldFilter.filter : [],
    };
  });

  propertiesCopy[filterPanelIndex] = {
    value: JSON.stringify(newFilters),
    name: PanelType.axisFilter,
  };

  return propertiesCopy;
};

export const getChangedPropertiesAfterPanelItemDelete = (
  widgetType: WidgetType,
  panelType: PanelType,
  properties: WidgetProperties[],
  index: number,
  options: { widgetsWithColoringOptions: WidgetType[] }
) => {
  let newProperties = [...properties];

  newProperties = getChangedPropertiesWithColoring(
    widgetType,
    panelType,
    newProperties,
    index,
    options.widgetsWithColoringOptions
  );

  newProperties = getFilteredPropertiesWithFiltersChange(
    widgetType,
    panelType,
    newProperties,
    index,
  );

  newProperties = getChangedSpecialPropertiesByWidgetType(
    widgetType,
    newProperties,
  );

  return newProperties;
};

const getChangedPropertiesWithColoring = (
  widgetType: WidgetType,
  panelType: PanelType,
  widgetProperties: WidgetProperties[],
  index: number,
  widgetsWithColoringOptions: WidgetType[]
) => {
  if (widgetsWithColoringOptions.includes(widgetType)) {
    const deletedValue = getParsedAxisValues(panelType, widgetProperties)[
      index
    ];
    return getNewPropertiesWithCorrectColoring(widgetProperties, deletedValue);
  }

  return widgetProperties;
};

const getFilteredPropertiesWithFiltersChange = (
  widgetType: WidgetType,
  panelType: PanelType,
  properties: WidgetProperties[],
  index: number,
) => {
  const isNeedSyncFiltersAxis = getIsNeedSyncFiltersAxis({
    widgetType,
    panelType,
  });

  return getPropertiesWithFilteredByIndexPanel({
    properties: isNeedSyncFiltersAxis
      ? getNewPropertiesWithFilters(
        properties,
        panelType,
        index,
      )
      : properties,
    panelType,
    index,
  });
};

const getChangedSpecialPropertiesByWidgetType = (
  widgetType: WidgetType,
  widgetProperties: WidgetProperties[],
) => {
  switch (widgetType) {
    case WidgetType.TABLE:
      return getChangedPropertiesWithTableHeader(
        widgetProperties,
      );
    default:
      return widgetProperties;
  }
};

export const getChangedPropertiesWithTableHeader = (
  widgetProperties: WidgetProperties[],
) => {
  const axisXValues: PropertyData[] = getParsedAxisValues(
    PanelType.axisX,
    widgetProperties,
  );

  const tableHeader = getTableHeader(axisXValues);

  const tableHeaderIndex = getPanelIndex({
    properties: widgetProperties,
    type: WidgetPropertyType.tableHeader,
  });
  if (tableHeaderIndex === -1) return widgetProperties;

  const newProperties = [...widgetProperties];

  newProperties[tableHeaderIndex] = {
    ...newProperties[tableHeaderIndex],
    value: JSON.stringify(tableHeader),
  };

  return newProperties;
};


export const getOverriddenPropertiesWithoutNumberFilters = (overriddenProperties:  WidgetProperties[] | undefined) => {
  // WA BIDEV-5113 Пропускаем числовой фильтр
  // из-за того что агрегации могут не совпадать, данные становится некорректными

  if (!overriddenProperties) return ;

  const axisIndex: number = getPanelIndex({
    properties: overriddenProperties,
    type: PanelType.axisFilter,
  });

  if (axisIndex >= 0) {
    try {
      const parsedOverriddenAxisFilter: PropertyData[] = JSON.parse(overriddenProperties[axisIndex].value);

      const parsedOverriddenAxisFilterWithoutNumbers
        = parsedOverriddenAxisFilter.filter((filter) => isNeedMergeNotNumberValues(filter));

      const modifiedOverriddenProperties = [ ...overriddenProperties ];

      modifiedOverriddenProperties[axisIndex] = {
        name: PanelType.axisFilter,
        value: JSON.stringify(parsedOverriddenAxisFilterWithoutNumbers),
      };

      return modifiedOverriddenProperties;
    } catch {
      return overriddenProperties;
    }
  }

  return overriddenProperties;
};
