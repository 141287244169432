import clsx from 'clsx';
import React from 'react';
import { Box } from '@material-ui/core';
import { getScaleCoefficient } from '../../../../../../helpers/ui-helper';
import { PivotCellViewProps, RowCellProps } from '../../interfaces';
import CustomTooltip from '../../../../../../uikit/CustomTooltip';
import { useRoundingCounts } from '../../../../../../hooks/useRoundingCounts';
import { initValueFormatter } from '../../../hooks/initValueFormatter';
import { PanelType } from '../../../../../../enums/widget-type';
import {
  cellPadding,
  minColumnWidth,
  minNotAdaptiveColumnWidth,
} from '../../settings';
import { getWidgetPropertiesIsActiveAxes, isNeedToAdaptiveColumns, isTitle } from '../../helper';
import '../../styles.css';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { usePivotTableProperties } from '../../hooks/usePivotTableProperties';

export const PivotCell = ({
  cell,
  depth,
  columnId,
  widgetProperties,
  style,
  isCellWithRowsButton,
  changeDisplayNestedRow,
  rowId,
  isOpenRow,
  axisYIndex = 0,
  cellHeight,
  view,
  handleClickCell,
  isActiveFilter,
  isActiveCurrentRowFilter,
  viewSettings,
}: RowCellProps) => {
  const {
    isNeedToDisplayTooltip,
    offsetColumnWidth,
    scaleWidget,
    columnWidthType,
  } = usePivotTableProperties({ widgetProperties });

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const coloring = cell?.conditionalColoring || {};

  const styleProps: PivotCellViewProps = { depth, view, coloring };

  const additionalText = viewSettings?.displayValueText ? `\u00A0${viewSettings?.displayValueText}` : ''; // тут неразрывной пробел

  const formattedValue = isTitle(columnId)
    ? valueFormat(
        cell?.value,
        depth,
        getWidgetPropertiesIsActiveAxes(widgetProperties),
        PanelType.axisX,
    )
    : valueFormat(
        cell?.value,
        axisYIndex,
        getWidgetPropertiesIsActiveAxes(widgetProperties),
        PanelType.axisY,
    );

  const formattedText = formattedValue
    ? `${formattedValue}${additionalText}`
    : '';

  const leftOffsetForNestedRows =
    styleProps.depth * (parseInt(offsetColumnWidth) * getScaleCoefficient());

  const getMarginForCellContent = () => {
    return view.cellJustifyContent === 'center'
      ? '0 auto'
      : view.cellJustifyContent === 'end'
        ? '0 0 0 auto'
        : '';
  };

  return (
    <div
      className={clsx(
        'pivot-cell',
        { 'pivot-cell_button': isActiveFilter },
        { 'pivot-cell-filter': isActiveCurrentRowFilter },
      )}
      style={{
        ...style,
        maxHeight: cellHeight,
        background:
          styleProps.coloring.backgroundColor ||
          styleProps.view.cellBackgroundColor ||
          '',
        color:
          styleProps.coloring.color ||
          styleProps.view.cellTextColor ||
          'var(--black)',
        fontWeight: styleProps.view.textBold ? 'bold' : 'normal',
        fontSize: styleProps.view.fontSize,
        minWidth: isNeedToAdaptiveColumns(scaleWidget, columnWidthType)
          ? minColumnWidth
          : minNotAdaptiveColumnWidth,
        paddingLeft: cellPadding + leftOffsetForNestedRows,
        justifyContent: viewSettings?.cellJustifyContent,
      }}
      onClick={handleClickCell}
    >
      {isCellWithRowsButton && (
        <Box
          mr="5px"
          className={clsx('pivot-cell__button', {
            'pivot-cell__open-row-icon': !isOpenRow,
            'pivot-cell__close-row-icon': isOpenRow,
          })}
          onClick={(e) => {
            e.stopPropagation();
            changeDisplayNestedRow(rowId);
          }}
        >
          <IconSvg
            svg={
              isOpenRow ? IconDictionary.MinusSquare : IconDictionary.PlusSquare
            }
            fill={isOpenRow ? 'var(--dark-grey)' : 'var(--grey)'}
          />
        </Box>
      )}
      <div
        className="pivot-cell__content"
        style={{
          margin: getMarginForCellContent(),
        }}
      >
        {isNeedToDisplayTooltip ? (
          <CustomTooltip arrow title={formattedText} placement="top">
            <div className="pivot-cell__content--value">{formattedText}</div>
          </CustomTooltip>
        ) : (
          <div className="pivot-cell__content--value">{formattedText}</div>
        )}
      </div>
    </div>
  );
};
