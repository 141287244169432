import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { State } from '../../../slices/types';
import { usePanelController } from '../dropdown-layout/hooks';
import {
  getIsNeedSyncFiltersAxis,
  getParsedAxisValues,
} from '../dropdown-layout/helpers/helpers';
import { PanelType, WidgetType } from '../../../enums/widget-type';
import { WidgetDropdownProps } from './index';

export const useDropdownPanel = ({ id, name }: WidgetDropdownProps) => {
  const widget = useSelector((state: State) => state.widget);

  const widgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const widgetType = useSelector((state: State) => state.widget.type);

  const properties = useMemo(() => [...widgetProperties] || [], [
    widgetProperties,
  ]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { moveToAxis } = usePanelController(widgetProperties);

  const onDragEnd = useCallback((event: any) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const source = JSON.parse(active.id);
    const destination = JSON.parse(over.id);
    const isNeedSyncFiltersAxis =
      getIsNeedSyncFiltersAxis({ widgetType: widget.type as WidgetType, panelType: destination.axisName as PanelType });

    moveToAxis(
      source.uniqueId,
      source.axisName,
      destination.uniqueId,
      destination.axisName,
    );

    if (isNeedSyncFiltersAxis) {

      let indexShift = 0;

      if (widget.type === WidgetType.PIVOT_TABLE && destination.axisName === PanelType.axisZ) {
        indexShift = getParsedAxisValues(PanelType.axisX, properties).length;
      }

      moveToAxis(
        Number(source.uniqueId) + indexShift,
        PanelType.axisFilter,
        Number(destination.uniqueId) + indexShift,
        PanelType.axisFilter,
      );
    }

  }, [moveToAxis, widget.type]);

  const cancelDrop = () => {
    return widgetType === WidgetType.MAP;
  };
  
  return {
    sensors,
    onDragEnd,
    cancelDrop,
  };
};
