import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { AxisLeft } from '@visx/axis';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { BarGroup } from '@visx/shape';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { times } from 'lodash';
import clsx from 'clsx';
import { VariableSizeList } from 'react-window';
import { ReferenceLineTypes } from 'src/enums/widgets-select-property';
import { useHorizontalScrollReactWindow } from '../../../../../hooks/useHorizontalScroll';
import {
  ScalableSVGText,
  textDirections,
} from '../../../../common/scalable-svg-text';
import { getScrollBarWidth } from '../../common/helpers';
import { getCorrectWidgetData, getHistogramData } from '../../helpers';
import { useEventListener } from '../../../../../hooks/useEventListener';
import './styles.css';
import { PanelType } from '../../../../../enums/widget-type';
import { FilterField, SetFilterField } from '../../../../dashboard-page/hooks';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import { setPropForNumberValue } from '../../formatting-helpers';
import { LevelLine } from '../../common/level-line';
import { useSynchronizeWidgetScrollListener } from '../../../../../hooks/useSynchronizeWidgetScrollListener';
import { getTooltipHandler } from '../../../../common/widget-tooltip/widget-tooltip';
import { useActiveLegend } from '../../hooks/useActiveLegend';
import {
  axisBottomOffset,
  gridLinesColor,
  gridLinesXOffset,
  leftAxisSettings,
  offsetForHorizontalText,
  stageSettings,
  valueScalePadding,
  xScalePadding,
  chartOffset,
  valueTextSettings,
  defaultDiffCoeff,
  defaultOffsetCoeff,
  roundingPixel,
  overflowOffset,
  referenceTitleTextYoffset,
} from '../settings';
import { WIDGET_HEIGHT } from '../../settings';
import { useWidgetFullScreen } from '../../../../../hooks/charts/useWidgetFullScreen';
import { useBarProps } from '../hooks/useBarProps';
import { CustomBarGroup } from './components/bar-group-vertical';
import { useHistogramFilters } from '../hooks/useHistogramFilters';
import { useBarDimensions } from '../hooks/useBarDimensions';
import { getDomainPointsForScaledHistogram } from '../../../../../helpers/widget-page/charts';

export type BarsProps = {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  domain?: number[];
};

export default function Histogram({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  domain,
}: BarsProps) {
  const isFullScreen = useWidgetFullScreen(widgetProps.id);
  const enableEvents: boolean = isActiveFilter;
  const widgetProperties = widgetProps.properties;
  const widgetData = widgetProps.data;
  const data = getHistogramData(getCorrectWidgetData(widgetData));

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [barWidth, setBarWidth] = useState(0);
  const [barGroupOffset, setBarGroupOffset] = useState(0);
  const [axisScrollOffset, setAxisScrollOffset] = useState(0);

  const {
    roundingCount,
    axisXValues,
    textDirection,
    isScaleByValueState,
    isNeedToDisplayAxesGuide,
    customAxisXLabelHeight,
    customAxisYLabelWidth,
    currentColors,
    columnWidth,
    columnOffsetCoeff,
    columnDiffCoeff,
    scaleByNumber,
    formatByNumber,
    offsetBarHeightValue,
    referenceLine1Value,
    referenceLine1Text,
    referenceLine1Color,
    referenceLine1Type,
    referenceLine1,
  } = useBarProps({
    widgetProperties,
    widgetProps,
  });

  const valueFormat = initValueFormatter({ roundingCount });

  const dataCount = useMemo(() => {
    return getCorrectWidgetData(widgetData).length;
  }, [widgetData]);

  const scrollBarHeight = useMemo(() => getScrollBarWidth(), []);

  const bottomAxisHeight = !isNaN(customAxisXLabelHeight)
    ? customAxisXLabelHeight
    : textDirection === textDirections.horizontal
    ? offsetForHorizontalText
    : axisBottomOffset;

  const axisYLabelWidth = !isNaN(customAxisYLabelWidth)
    ? customAxisYLabelWidth
    : leftAxisSettings.left;

  const { changeColorsActiveLegend, colorsActiveLegend } = useActiveLegend(
    widgetProperties,
    widgetProps.id,
    currentColors,
  );

  useEffect(() => {
    changeColorsActiveLegend();
  }, [JSON.stringify(currentColors), widgetData]);

  const barsInGroupCount = widgetProps.data[0].y.length;

  const keys: Array<string> = times(barsInGroupCount, String);
  const getX = (data: any) => data.x;

  const xScale = scaleBand<string>({
    domain: data.map(getX),
    paddingInner: xScalePadding,
    align: 0,
  });

  const valuesScale = scaleBand<string>({
    domain: keys,
    padding: valueScalePadding,
  });

  const { startDomainPoint, endDomainPoint } =
    getDomainPointsForScaledHistogram(keys, data);

  const yScale = scaleLinear<number>({
    zero: data.length === 1 || !isScaleByValueState,
    nice: true,
    domain: domain || [
      isScaleByValueState
        ? startDomainPoint
        : Math.min(
            ...data.map((d: any) =>
              Math.min(...keys.map((key) => Number(d[key]))),
            ),
          ),
      isScaleByValueState
        ? endDomainPoint
        : Math.max(
            ...data.map((d: any) =>
              Math.max(...keys.map((key) => Number(d[key]))),
            ),
          ),
    ],
  });

  const isHasNegative = yScale.ticks().some((value: number) => value < 0);
  const isHasPositive = yScale.ticks().some((value: number) => value > 0);
  const isHavePositiveAndNegative = isHasNegative && isHasPositive;

  const xMax = width;
  const yMax =
    height -
    bottomAxisHeight -
    gridLinesXOffset -
    stageSettings.top -
    (isHasNegative ? valueTextSettings.lineHeight : 0) -
    offsetBarHeightValue;

  xScale.rangeRound([0, xMax + leftAxisSettings.left]);
  valuesScale.rangeRound([0, xScale.bandwidth()]);
  yScale.range([yMax, 0]);

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: colorsActiveLegend,
  });

  const containerRef = useRef(document.createElement('div'));
  const viewportRef = useRef(document.createElement('div'));
  const debounceDelay = 100;

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setWidth(value);
  }, debounceDelay);

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(viewportRef);

  const {
    getOffsetBetweenBarGroups,
    getComputedBarHeight,
    groupBarSize,
    getBarYCoord,
    minChartLength,
    spaceBetweenBars,
  } = useBarDimensions({
    barWidth: columnWidth,
    barsInGroupCount,
    barGroupsCount: dataCount,
    axisScrollOffset,
    columnDiffCoeff: isNaN(columnDiffCoeff)
      ? defaultDiffCoeff
      : columnDiffCoeff,
    columnOffsetCoeff: isNaN(columnOffsetCoeff)
      ? defaultOffsetCoeff
      : columnOffsetCoeff,
  });

  const chartContainerWidth =
    viewportRef.current.clientWidth - axisYLabelWidth - chartOffset * 3;
  const offsetBetweenGroups = getOffsetBetweenBarGroups(chartContainerWidth);

  const axisXLabelWidth = groupBarSize + offsetBetweenGroups;

  const calculateDimensions = useCallback(() => {
    if (!viewportRef.current) return;
    const minHeight = WIDGET_HEIGHT;

    const chartContainerWidth =
      viewportRef.current.clientWidth - axisYLabelWidth - chartOffset * 3;
    const containerHeight = viewportRef.current.clientHeight;
    const currentHeight =
      minHeight > containerHeight ? minHeight : containerHeight;

    setDebouncedWidth.callback(chartContainerWidth);
    setDebouncedHeight.callback(currentHeight);
    clearListCash();

    const rowsListEl = (rowsList.current as unknown as any)._outerRef as HTMLDivElement;
    if (rowsListEl.scrollWidth > rowsListEl.clientWidth) {
      setAxisScrollOffset(scrollBarHeight);
    } else {
      setAxisScrollOffset(0);
    }
  }, [axisYLabelWidth, setDebouncedWidth, setDebouncedHeight, minChartLength, scrollBarHeight]);

  useEffect(() => {
    calculateDimensions();
    setBarGroupOffset(getOffsetBetweenBarGroups(width));
  }, [
    data,
    calculateDimensions,
    isFullScreen,
    columnWidth,
    getOffsetBetweenBarGroups,
    viewportRef,
    isFullScreen,
    width,
  ]);

  const { selectBar } = useHistogramFilters({
    widgetId: widgetProps.id,
    setFilterField,
    enableEvents,
    filterField,
    data,
    axisXValues,
  });

  const rowsList = React.useRef<VariableSizeList>(null);

  const clearListCash = () => {
    rowsList.current && rowsList.current.resetAfterIndex(0);
  };

  useSynchronizeWidgetScrollListener(containerRef, widgetProps.id);

  useEventListener('resize', calculateDimensions);

  useHorizontalScrollReactWindow(viewportRef, rowsList);

  const startScaleYPoint = // also need to handle only negative values
    leftAxisSettings.top -
    axisScrollOffset +
    (!isHavePositiveAndNegative
      ? offsetBarHeightValue
      : offsetBarHeightValue / 2);

  const renderReferenceLines = () => {
    // multiply lines we can later render via array [line1, line2, line3].forEach
    const referenceY1Line = yScale(parseInt(referenceLine1Value)) + startScaleYPoint;

    return referenceLine1 && referenceY1Line ? (
      <svg width={viewportRef.current.clientWidth - barsLeftOffset} height="100%" style={{ position: 'absolute', left: barsLeftOffset, top: 0, pointerEvents: 'none', }}>
        <line
          strokeDasharray={
            referenceLine1Type === ReferenceLineTypes.dotted ? '5,5' : ''
          }
          x1={0}
          y1={referenceY1Line}
          x2={viewportRef.current.clientWidth - (axisYLabelWidth + gridLinesXOffset)}
          y2={referenceY1Line}
          stroke={referenceLine1Color || '#000'}
        />

        {Boolean(referenceLine1Text) && (
          <text
            x={0}
            y={referenceY1Line - referenceTitleTextYoffset}
            fontFamily="Roboto"
            fontSize="14"
            fontWeight="500"
            fill="#929bac"
          >
            {referenceLine1Text}
          </text>
        )}
      </svg>
    ) : null;
  };

  useEffect(() => {
    clearListCash();
  }, [width, height, data, barWidth, columnWidth]);

  const barsLeftOffset = axisYLabelWidth + chartOffset * 2;

  return (
    <>
      <div ref={viewportRef} className="histogram screenshot-overflow-y">
        <div
          className={clsx('screenshot-overflow-x', 'histogram__container')}
          ref={containerRef}
          style={{
            left: barsLeftOffset,
            width:
              viewportRef.current.clientWidth -
              axisYLabelWidth -
              chartOffset * 2,
          }}
        >

          { isNeedToDisplayAxesGuide && (
            <svg width={xMax} height="100%" style={{ position: 'absolute' }}>
              <GridRows top={startScaleYPoint} scale={yScale} numTicks={7} width={xMax} height={yMax} stroke="#e0e0e0" />
            </svg>
          )}

          <BarGroup
            data={data}
            keys={keys}
            height={yMax}
            x0={getX}
            x0Scale={xScale}
            x1Scale={valuesScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barGroups) => (
              <VariableSizeList
                height={
                  height >= WIDGET_HEIGHT ? height - roundingPixel : height
                }
                itemCount={barGroups.length}
                itemSize={(i) =>
                  i < data.length - 1
                    ? groupBarSize + barGroupOffset
                    : groupBarSize
                }
                layout="horizontal"
                width={width + overflowOffset}
                ref={rowsList}
                className="histogram__list"
              >
                {({ index, style }) => {
                  const barProps = {
                    barGroup: barGroups[index],
                    index,
                    style,
                    setBarWidth,
                    filterField,
                    data,
                    valueFormat,
                    yScale,
                    getComputedBarHeight,
                    isActiveFilter,
                    bottomAxisHeight,
                    widgetProperties,
                    enableEvents,
                    handleWidgetMouseLeave,
                    handleWidgetMouseMove,
                    selectBar,
                    widgetProps,
                    columnWidth,
                    getBarYCoord,
                    isHasNegative,
                    groupBarSize,
                    spaceBetweenBars,
                    stepWight: xScale.step(),
                    axisXLabelWidth,
                    offsetBetweenGroups,
                    offsetBarHeightValue
                  };

                  return CustomBarGroup(barProps);
                }}
              </VariableSizeList>
            )}
          </BarGroup>
        </div>
        <div
          className="histogram__axis-container"
          style={{
            width: axisYLabelWidth,
            height: height - gridLinesXOffset,
          }}
        >
          <svg width={barsLeftOffset} height="100%">
            <AxisLeft
              left={axisYLabelWidth}
              hideAxisLine
              hideTicks
              numTicks={7}
              top={startScaleYPoint}
              tickFormat={(x) => `${x}`}
              tickComponent={(props) => {
                return (
                  <Group>
                    <ScalableSVGText
                      x={props.x - axisYLabelWidth}
                      y={props.y - leftAxisSettings.lineHeight / 2}
                      formattedValue={valueFormat(
                        props.formattedValue,
                        0,
                        setPropForNumberValue(widgetProperties),
                        PanelType.axisY,
                        false,
                        false,
                        scaleByNumber ? formatByNumber : null,
                      )}
                      axis={PanelType.axisY}
                      properties={{
                        width: axisYLabelWidth,
                        svgTextStyles: leftAxisSettings,
                      }}
                      widgetProperties={widgetProperties}
                    />
                  </Group>
                );
              }}
              scale={yScale}
              stroke={leftAxisSettings.color}
              tickStroke={leftAxisSettings.color}
              tickTransform={`translate (${10}, 0)`}
            />
          </svg>
          {referenceLine1 && renderReferenceLines()}
        </div>
        { /* todo выпилить!!! при удалении ломается все, разобраться и удалить!!! */ }
        <LevelLine />
      </div>
    </>
  );
}
