import React from 'react';
import './no-groups-placeholder.css';
import { getScaleCoefficient } from '../../../helpers/ui-helper';
import { CustomButton } from '../../../uikit/Button';
import { isMobilehelper } from '../../../utils/mobile-helper';
import IconSvg from '../../common/icon-svg/icon-svg';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface NoGroupsPlaceholderProps {
  isNoAccess?: boolean;
  isHaveFilter?: boolean;
}

const NoGroupsPlaceholder = ({ isNoAccess, isHaveFilter = false }: NoGroupsPlaceholderProps) => {
  const title = isNoAccess
    ? isHaveFilter ? `По Вашему запросу не найдено ${CommonDictionary.manyProjects}` : 'У вас нет прав ни на одну группу'
    : `В системе еще нет ${CommonDictionary.manyProjects}`;
  const text = isNoAccess
    ? isHaveFilter ? '': 'Обратитесь к системному администратору'
    : `${CommonDictionary.newGroupAndProjectCreate} для начала работы`;

  return (
    <div className="no-groups-placeholder">
      <div className="no-groups-placeholder__content">
        <div className="no-groups-placeholder__icon-size-wrapper">
          <IconSvg
            svg={IconDictionary.Rocket}
            width={40}
            height={40}
            fill="var(--dark-grey)"
          />
        </div>
        <div className="no-groups-placeholder__title">{title}</div>
        <div className="no-groups-placeholder__text">{text}</div>
        {!isNoAccess && !isMobilehelper() && <CustomButton variant="text">Создать группу</CustomButton>}
      </div>
    </div>
  );
};

export default NoGroupsPlaceholder;
