import React from 'react';
import block from 'bem-cn';
import './title-section.css';
import { CustomButton } from '../../../../../uikit/Button';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface TitleSectionProps {
  onClose: () => void;
}

const b = block('title-section');

const TitleSection: React.FC<TitleSectionProps> = ({ onClose }) => {
  return (
    <div className={b()}>
      <h3 className={b('title')}>Настройка правила сохранения результата</h3>
      <CustomButton
        variant="outlined"
        icon={<IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />}
        onClick={onClose}
      />
    </div>
  );
};

export default TitleSection;
