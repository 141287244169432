import React, { useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import block from 'bem-cn';

import History, { StatusName } from '../../../types/history';

import IconSvg from '../icon-svg/icon-svg';

import './history-step-row.css';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';
import {
  InlineTextButton,
} from '../../../uikit/InlineTextButton';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  root: {
    marginLeft: 'calc(3px * var(--scale-coefficient))',
    border: '1px solid #DAE7ED',
    borderRadius: '3px',
    boxShadow: `0px 4px 10px rgba(58, 130, 228, 0.12),
                  0px 4px 10px rgba(0, 0, 0, 0.06)`,
  },
  tableHead: {
    backgroundColor: '#EDF1F8',
  },
  tableCell: {
    minWidth: 'calc(130px * var(--scale-coefficient))',
  },
  tableCellHead: {
    fontWeight: 'bold',
  },
  tableCellDate: {
    minWidth: 'calc(170px * var(--scale-coefficient))',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
      padding: 'calc(16px * var(--scale-coefficient))',
      fontSize: 'calc(14px * var(--scale-coefficient))',
    },
  },
});

const b = block('history-row');

const getStatusMode = (status: StatusName): 'error' | 'done' | 'default' => {
  switch (status) {
    case StatusName.NEW:
    case StatusName.DONE:
      return 'done';
    case StatusName.ERROR:
      return 'error';
    default:
      return 'default';
  }
};
const getStatusIcon = (status: StatusName) => {
  switch (status) {
    case StatusName.NEW:
    case StatusName.DONE:
      return <IconSvg svg={IconDictionary.SuccessStatus} width={22} height={22} fill="var(--success-color)" />;
    case StatusName.ERROR:
      return (
        <IconSvg svg={IconDictionary.ErrorStatus} width={22} height={22} fill="var(--red-normal)" />
      );
    default:
      return null;
  }
};

interface HistoryStepRowProps {
  step: History.Milestone;
}

const HistoryStepRow = ({ step }: HistoryStepRowProps) => {
  const [showText, setShowText] = useState<boolean>(false);

  const classes = useStyles();

  const handleClickMore = () => {
    setShowText(true);
  };

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${b('sign')} ${b(
            'sign',
            getStatusMode(step.status.name),
          )}`}
        >
          <p className={b('step-title')}>{step.name}</p>
          <p>{step.description}</p>
        </TableCell>
        <TableCell>
          <div className={b('step-status')}>
            <div className={b('step-status-icon')}>
              {getStatusIcon(step.status.name)}
            </div>
            {step.status.description}
          </div>
        </TableCell>
        <TableCell>
          {moment(step.startLoad).format('D.M.YYYY  H:mm:ss')}
        </TableCell>
        <TableCell>
          {step.endLoad
            ? moment(step.endLoad).format('D.M.YYYY  H:mm:ss')
            : null}
        </TableCell>
      </TableRow>

      {step.status.name === StatusName.ERROR ? (
        <TableRow>
          <TableCell colSpan={5}>
            <CustomErrorAlert>
              <div className={b('alert-box')}>
                <p
                  className={`${b('alert-text')} ${
                    showText ? b('alert-text-full') : ''
                  }`}
                >
                  {step.errorMessage}
                </p>
                {!showText ? (
                  <InlineTextButton title=" ещё" onClick={handleClickMore} />
                ) : null}
              </div>
            </CustomErrorAlert>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default HistoryStepRow;
