/* eslint-disable react/jsx-key */
import React, { createContext, FC } from 'react';
import { TableDndProvider } from './libs/utils/table-dnd-provider';
import { TableRoot } from './ui/table-root';
import { WidgetProperties } from '../../../../slices/types';
import { Header, useTableData } from './libs/hooks/useTableData';
import { FilterField, SetFilterField, SetMultipleFilterFields } from '../../../dashboard-page/hooks';
import { PropertyData } from '../../dropdown-layout/helpers/Property';

export interface ComplexTableProps {
  widgetProps: any;
  setDashboardWidgetState?: ( properties: WidgetProperties[]) => void;
  dashboardWidgetState?: Array<WidgetProperties>;
  isLoadingInEditor: boolean;
  isDrillDown?: boolean;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  setMultipleFilterFields: SetMultipleFilterFields;
  errorText?: string;
}

export interface ComplexTableContextProps {
  setDashboardWidgetState?: ( properties: WidgetProperties[]) => void;
  isDrillDown: boolean;
  widgetProperties: WidgetProperties[];
  widgetState: any;
  widgetError: string;
  hierarchyInHeader: boolean;
  axisXValues: PropertyData[];
  axisXValuesString: string;
  isNeedDisplayAggregationInLegend: boolean;
  isEnabledResizing: boolean;
  tableNestedHeaders: Header[];
  isLoadingData: boolean;
  isScaleWidgetOnDashboard: boolean;
  headBackgroundColor: string;
  headTextColor: string;
  rowsColor: string;
  isColoringRows: boolean;
  displayFilterHeader: boolean;
  isNeedToDisplayTotal: boolean;
  setFilterField: SetFilterField;
  setMultipleFilterFields: SetMultipleFilterFields;
  filterField: FilterField;
  isActiveFilter: boolean;
  isDisplayTooltip: boolean;
  hiddenColumns: string[];
  displaySorting: boolean;
  displaySortOrder: boolean;
  displayBorder: boolean;
  valueSize: number;
}

export const ComplexTableContext = createContext<ComplexTableContextProps>({
  setDashboardWidgetState: (state) => {},
  widgetProperties: [],
  widgetState: null,
  widgetError: '',
  hierarchyInHeader: false,
  axisXValues: [],
  axisXValuesString: '',
  isNeedDisplayAggregationInLegend: false,
  isEnabledResizing: false,
  tableNestedHeaders: [],
  isLoadingData: false,
  isScaleWidgetOnDashboard: false,
  headBackgroundColor: '',
  headTextColor: '',
  rowsColor: '',
  isColoringRows: false,
  displayFilterHeader: true,
  isDrillDown: false,
  isNeedToDisplayTotal: false,
  setFilterField: () => undefined,
  setMultipleFilterFields: () => undefined,
  filterField: {} as FilterField,
  isActiveFilter: false,
  isDisplayTooltip: true,
  hiddenColumns: [],
  displaySorting: false,
  displaySortOrder: false,
  displayBorder: false,
  valueSize: 14,
});

ComplexTableContext.displayName = 'ComplexTableContext';

export const ComplexTable: FC<ComplexTableProps> = (
  {
    widgetProps,
    dashboardWidgetState,
    setDashboardWidgetState,
    isLoadingInEditor,
    isDrillDown = false,
    setFilterField,
    setMultipleFilterFields,
    filterField,
    isActiveFilter,
    errorText,
  }) => {

  const {
    widgetProperties,
    widgetState,
    axisXValues,
    axisXValuesString,
    isNeedDisplayAggregationInLegend,
    isEnabledResizing,
    tableNestedHeaders,
    isLoadingData,
    isScaleWidgetOnDashboard,
    headBackgroundColor,
    headTextColor,
    rowsColor,
    isColoringRows,
    displayFilterHeader,
    isDisplayTooltip,
    isNeedToDisplayTotal,
    hiddenColumns,
    hierarchyInHeader,
    displaySorting,
    displaySortOrder,
    displayBorder,
    valueSize,
  } = useTableData({
    widgetProps,
    dashboardWidgetState,
    setDashboardWidgetState,
    isLoadingInEditor,
    isDrillDown,
    setFilterField,
    setMultipleFilterFields,
    filterField,
    isActiveFilter,
  });

  return (
    <ComplexTableContext.Provider value={{
      setDashboardWidgetState,
      isDrillDown,
      widgetProperties,
      widgetState,
      widgetError: errorText || '',
      hierarchyInHeader,
      axisXValues,
      axisXValuesString,
      isNeedDisplayAggregationInLegend,
      isEnabledResizing,
      tableNestedHeaders,
      isLoadingData,
      isScaleWidgetOnDashboard,
      headBackgroundColor,
      headTextColor,
      rowsColor,
      isColoringRows,
      displayFilterHeader,
      isDisplayTooltip,
      isNeedToDisplayTotal,
      setFilterField,
      setMultipleFilterFields,
      filterField,
      isActiveFilter,
      hiddenColumns: hierarchyInHeader ? [] : hiddenColumns,
      displaySorting,
      displaySortOrder,
      displayBorder,
      valueSize,
    }}
    >
      <TableDndProvider>
        <TableRoot />
      </TableDndProvider>
    </ComplexTableContext.Provider>
  );
};
