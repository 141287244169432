import Validation from './type';
import MapConnection from '../../../../types/map-connection';
import { SelectOptionsMeta } from '../type';
import { getPictureType } from '../../../../utils/functions';

enum errorTexts {
  fill = 'Заполните все соответствия таблицы.',
  key = 'Не указан ключ для соединения данных.',
  duplicate = 'Присутствуют дубликаты.',
  types = 'Связать можно только одинаковые типы полей.',
}

export const getInitialValidation = () => {
  return {
    isValid: true,
    values: {},
    errorText: '',
  };
};

export const defineValidation = (
  validation: Validation.Data,
  fieldsMapping: MapConnection.FieldsMapping[],
  isNeedToValidateKeys: boolean,
  selectOptionsMeta?: SelectOptionsMeta
) => {
  let newValidationValues = { ...validation.values };
  newValidationValues = setFill(newValidationValues, fieldsMapping);
  newValidationValues = setDuplicate(newValidationValues, fieldsMapping);

  if (selectOptionsMeta) {
    newValidationValues = setTypes(newValidationValues, fieldsMapping, selectOptionsMeta);
  }

  if (isNeedToValidateKeys) {
    newValidationValues = setKeys(newValidationValues, fieldsMapping);
  }

  return {
    isValid: getIsValid(newValidationValues),
    values: newValidationValues,
    errorText: getErrorText(newValidationValues),
  };
};

const setFill = (
  validationValues: Validation.Values,
  fieldsMapping: MapConnection.FieldsMapping[],
) => {
  const newValidationValues = { ...validationValues };
  const fillList = fieldsMapping.reduce((list, el, i) => {
    const data = {
      target: !el.targetField.fieldName,
      source: !el.sourceFieldName.fieldName,
    };
    return { [i]: data, ...list };
  }, {});

  const isHaveError = !!Object.values(fillList).filter((el: any) => {
    return el.target || el.source;
  }).length;

  if (isHaveError) {
    newValidationValues.fill = fillList;
  }

  return newValidationValues;
};

const setKeys = (
  validationValues: Validation.Values,
  fieldsMapping: MapConnection.FieldsMapping[],
) => {
  const newValidationValues = { ...validationValues };

  const fieldsMappingWithActiveKeys = fieldsMapping.filter(
    (fieldsMappingItem) => {
      return fieldsMappingItem.keyForMerge;
    },
  );

  if (!fieldsMappingWithActiveKeys.length) {
    newValidationValues.key = true;
  }

  return newValidationValues;
};

const setDuplicate = (
  validationValues: Validation.Values,
  fieldsMapping: MapConnection.FieldsMapping[],
) => {
  const newValidationValues = { ...validationValues };
  const duplicateList = fieldsMapping.reduce((list, el, i, self) => {
    const isHaveTargetDuplicate = !!self.filter((element, j) => {
      if (i === j) return false;
      const currentTargetFieldName = el.targetField.fieldName
        .trim();
      const otherTargetFieldName = element.targetField.fieldName
        .trim();
      return (
        currentTargetFieldName === otherTargetFieldName &&
        currentTargetFieldName !== ''
      );
    }).length;

    const data = {
      target: isHaveTargetDuplicate,
      source: false, // We can add validation on source if we need it in future
    };
    return { [i]: data, ...list };
  }, {});

  const isHaveError = !!Object.values(duplicateList).filter((el: any) => {
    return el.target || el.source;
  }).length;

  if (isHaveError) {
    newValidationValues.duplicate = duplicateList;
  }

  return newValidationValues;
};

const setTypes = (
  validationValues: Validation.Values,
  fieldsMapping: MapConnection.FieldsMapping[],
  selectOptionsMeta: SelectOptionsMeta
) => {
  const newValidationValues = { ...validationValues };
  const typesList = fieldsMapping.reduce((list, el, i, self) => {
    const areCorrectTypes = !!self.filter((element, j) => {
      const sourceId = el.sourceFieldName.sourceObjectId;
      const targetId = el.targetField.sourceObjectId;
      const currentSourceFieldType =
        getPictureType(selectOptionsMeta[sourceId][el.sourceFieldName.fieldName]?.type);
      const otherTargetFieldType =
        getPictureType(selectOptionsMeta[targetId][el.targetField.fieldName]?.type);
      return (
        currentSourceFieldType === otherTargetFieldType
      );
    }).length;

    const data = {
      target: !areCorrectTypes,
      source: !areCorrectTypes,
    };
    return { [i]: data, ...list };
  }, {});

  const isHaveError = !!Object.values(typesList).filter((el: any) => {
    return el.target || el.source;
  }).length;

  if (isHaveError) {
    newValidationValues.types = typesList;
  }

  return newValidationValues;
};

const getIsValid = (newValidationValues: Validation.Values) => {
  return (
    !newValidationValues.key &&
    !newValidationValues.fill &&
    !newValidationValues.types &&
    !newValidationValues.duplicate
  );
};

export const getErrorText = (newValidationValues: Validation.Values) => {
  let errorText = '';

  if (newValidationValues.fill) {
    errorText += ` ${errorTexts.fill}`;
  }

  if (newValidationValues.key) {
    errorText += ` ${errorTexts.key}`;
  }

  if (newValidationValues.duplicate) {
    errorText += ` ${errorTexts.duplicate}`;
  }

  if (newValidationValues.types) {
    errorText += ` ${errorTexts.types}`;
  }

  return errorText;
};

export const extendBeforeSaveValidation = (validation: Validation.Data, i: number) => {
  const fieldsValidation = validation;

  fieldsValidation.values.types &&
    (fieldsValidation.values.types[i] = { target: true, source: true });
  !fieldsValidation.values.types &&
    (fieldsValidation.values.types = { [i]: { target: true, source: true } });

  return fieldsValidation;
};
