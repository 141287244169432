import { inRange, isUndefined } from 'lodash';
import moment, { Moment } from 'moment';
import { WidgetPropertyType } from '../../../enums/widget-type';
import { WidgetProperties } from '../../../slices/types';
import { getBooleanPropertyValue } from './helpers';
import { DateFormatProperties } from './hooks/initValueFormatter';
import { DATE_LOCAL } from '../../../utils/consts';
import { DateFunctions } from '../../../enums/date';
import { NumberFormat } from '../../../enums/number-format';

export const setPropForNumberValue = (widgetProperties?: WidgetProperties[]) => {
  const WidgetProp = [
    {
      name: 'axisY',
      value: '[{"type":"DOUBLE","aggregation":"","function":""}]',
    },
    {
      name: 'axisZ',
      value: '[{"type":"DOUBLE","aggregation":"","function":""}]',
    },
  ];

  const displayZeros = widgetProperties?.find(
    (prop) => prop.name === WidgetPropertyType.displayZeros,
  );
  if (displayZeros) WidgetProp.push(displayZeros);

  const displayValueText = widgetProperties?.find(
    (prop) => prop.name === WidgetPropertyType.displayValueText,
  );
  if (displayValueText) WidgetProp.push(displayValueText);

  return WidgetProp;
};

export const setPropForDate = (
  func: string,
  dateDisplayFormat?: string,
  type: string = 'DATE',
) => {
  const WidgetProp = dateDisplayFormat
    ? [
        {
          name: 'axisY',
          value: `[{"type":"${type}","aggregation":"","function":"formula'${func}'"}]`,
        },
        { name: 'dateDisplayFormat', value: `${dateDisplayFormat}` },
      ]
    : [
        {
          name: 'axisY',
          value: `[{"type":"${type}","aggregation":"","function":"formula'${func}'"}]`,
        },
      ];
  return WidgetProp;
};

const getCorrectDateFormat = (format: string): string => {
  switch (format) {
    case 'dd.mm.yyyy':
      return 'DD.MM.YYYY';
    case 'yyyy-mm-dd':
      return 'YYYY-MM-DD';
    case 'dd-Mon-yyyy':
      return 'DD-MMM-YYYY';
    case 'yyyy-Mon-dd':
      return 'YYYY-MMM-DD';
    case 'dd.mm':
      return 'DD.MM';
    case 'dd-Mon':
      return 'DD-MMM';
    case 'mm.yyyy':
      return 'MM.YYYY';
    case 'Mon-yyyy':
      return 'MMM-YYYY';
    case 'yyyy':
      return 'YYYY';
    case 'mm':
      return 'MM';
    case 'Mon':
      return 'MMM';
    case 'dd':
      return 'DD';
    case 'QQ.yyyy':
      return `Q[${getQuarterViewByLocale(DATE_LOCAL)}].YYYY`;
    case 'II-yyyy':
      return 'Q-YYYY';
    case 'QQ':
      return `Q[${getQuarterViewByLocale(DATE_LOCAL)}]`;
    case 'II':
      return 'Q';
    default:
      return format;
  }
};

const getQuarterViewByLocale = (local: string) => {
  switch (local) {
    case 'ru':
      return 'КК';
    case 'en':
      return 'Q';
    default:
      return 'Q';
  }
};

const getCorrectDateForQuarter = (date: Moment, format: string): string => {

  const getRomeQuarter = (quarter: number): string => {
    switch (quarter) {
      case 1: return 'I';
      case 2: return 'II';
      case 3: return 'III';
      case 4: return 'IV';
      default: return `${quarter}`;
    }
  };

  switch (format) {
    case 'QQ.yyyy':
      return date.format(`Q[${getQuarterViewByLocale(DATE_LOCAL)}].YYYY`);
    case 'II-yyyy':
      return `${getRomeQuarter(date.quarter())}-${date.year()}`;
    case 'QQ':
      return date.format(`Q[${getQuarterViewByLocale(DATE_LOCAL)}]`);
    case 'II':
      return getRomeQuarter(date.quarter());
    default:
      return date.format(getCorrectDateFormat(format));
  }
};

export const formatDate = (
  value: string,
  dateFormatProperties: DateFormatProperties,
) => {
  if (!moment(value).isValid()) {
    return value;
  }
  switch (dateFormatProperties.function) {
    case DateFunctions.NO_TYPE:
      return dateFormatProperties.dataType === 'DATE'
        ? moment(value)
            .format(
              getCorrectDateFormat(dateFormatProperties.dateDisplayFormat),
            )
        : moment(value)
            .format(
              `${getCorrectDateFormat(
                dateFormatProperties.dateDisplayFormat,
              )} HH:mm:ss`,
            );
    case DateFunctions.WEEK:
    case DateFunctions.DAY:
      return moment(value)
        .format(getCorrectDateFormat(dateFormatProperties.dateDisplayFormat));
    case DateFunctions.YEAR:
      return moment(value).format('YYYY');
    case DateFunctions.QUARTER:
      return getCorrectDateForQuarter(moment(value), 'QQ.yyyy');
    case DateFunctions.MONTH:
      return moment(value)
        .format(
          getCorrectDateFormat(dateFormatProperties.dateDisplayFormat),
        );
    case DateFunctions.HOUR:
      return dateFormatProperties.dataType === 'DATE'
        ? moment(value)
            .format(
              getCorrectDateFormat(dateFormatProperties.dateDisplayFormat),
            )
        : moment(value)
            .format(
              `${getCorrectDateFormat(
                dateFormatProperties.dateDisplayFormat,
              )} HH:00`,
            );
    case DateFunctions.MINUTE:
      return dateFormatProperties.dataType === 'DATE'
        ? moment(value)
            .format(
              getCorrectDateFormat(dateFormatProperties.dateDisplayFormat),
            )
        : moment(value)
            .format(
              `${getCorrectDateFormat(
                dateFormatProperties.dateDisplayFormat,
              )} HH:mm:ss`,
            );
    default:
      return value;
  }
};

const padZeros = (wholeString: string, digitsAfterCommaLength: number) => {
  const result = wholeString.split(' ');
  const digitsInString = result[0];

  let formattedNumberInString = '';
  let digitsInStringAfterComma = '';
  let digitsInStringBeforeComma = '';

  const isFloatString = !Number.isInteger(
    parseFloat(digitsInString.replace(',', '.').replaceAll(' ', '')),
  );

  if (isFloatString) {
    const floatDigitsList = digitsInString.split(',');
    [digitsInStringBeforeComma, digitsInStringAfterComma] = floatDigitsList;

    formattedNumberInString = `${digitsInStringBeforeComma},${digitsInStringAfterComma.padEnd(
      digitsAfterCommaLength,
      '0',
    )}`;
  } else {
    const zeroesAmountString = Array(digitsAfterCommaLength).fill(0).join('');
    formattedNumberInString = `${digitsInString}${
      zeroesAmountString.length > 0 ? `,${zeroesAmountString}` : ''
    }`;
  }

  result[0] = formattedNumberInString;
  return result.join(' ');
};

const formatNumberWithSettings = (
  numberFormat: NumberFormat,
  value: number,
  maxFractionDigits: number,
  displayZeros?: boolean,
  displayValueText?: string,
) => {
  const formatter = new Intl.NumberFormat('ru', {
    maximumFractionDigits: maxFractionDigits,
  });
  let formattedValue = formatter.format(value);

  if (inRange(Math.abs(value), 1000, 1000000)) {
    formattedValue =
      new Intl.NumberFormat('ru', {
        maximumFractionDigits: maxFractionDigits,
      }).format(value / 1000) +
      (numberFormat === NumberFormat.en ? ' K' : ' Тыс');
  } else if (inRange(Math.abs(value), 1000000, 1000000000)) {
    formattedValue =
      new Intl.NumberFormat('ru', {
        maximumFractionDigits: maxFractionDigits,
      }).format(value / 1000000) +
      (numberFormat === NumberFormat.en ? ' M' : ' Млн');
  } else if (inRange(Math.abs(value), 1000000000, 1000000000000)) {
    formattedValue =
      new Intl.NumberFormat('ru', {
        maximumFractionDigits: maxFractionDigits,
      }).format(value / 1000000000) +
      (numberFormat === NumberFormat.en ? ' G' : ' Млрд');
  }

  return (
    (displayZeros
      ? padZeros(formattedValue, maxFractionDigits)
      : formattedValue) +
    (displayValueText ? ` ${displayValueText}` : '')
  );
};

export const formatNumber = (
  value: any,
  isPercentValue: boolean,
  roundingCount: number,
  displayZeros?: boolean,
  numberFormat?: NumberFormat | null,
  displayValueText?: string,
) => {
  if (parseFloat(value) + 1) {
    const fractionDigits = !isUndefined(roundingCount) && !isNaN(roundingCount) ? roundingCount : 2;
    const maxFractionDigits = fractionDigits > 8 ? 8 : fractionDigits;

    if (numberFormat)
      return formatNumberWithSettings(
        numberFormat,
        parseFloat(value),
        maxFractionDigits,
        displayZeros,
        displayValueText,
      );

    const formatter = new Intl.NumberFormat('ru', {
      maximumFractionDigits: maxFractionDigits ?? 8,
    });
    const formattedValue = formatter.format(+value);

    return (
      (displayZeros
        ? padZeros(formattedValue, maxFractionDigits)
        : formattedValue) +
      (displayValueText ? ` ${displayValueText}` : '')
    );
  }

  return value;
};

export const getWeekFilterValue = (value: string) => {
  const startWeek = moment(value)
    .startOf('week')
    .format('DD.MM.YYYY');
  const endWeek = moment(value)
    .endOf('week')
    .format('DD.MM.YYYY');

  return `${startWeek} - ${endWeek}`;
};
