import { useCallback, useContext, useMemo } from 'react';
import { random } from 'lodash';
import { PanelType } from '../../../../../../enums/widget-type';
import { ComplexTableContext } from '../../index';
import { useRoundingCounts } from '../../../../../../hooks/useRoundingCounts';
import { initValueFormatter } from '../../../hooks/initValueFormatter';
import { CellVariant, TableCellProps } from './index';
import { useWidgetFilters } from '../../../../../../hooks/charts/useWidgetFilters';
import { Property } from '../../../../dropdown-layout/helpers/Property';
import { CommonDictionary } from '../../../../../../dictionaries/naming-dictionary/naming-dictionary';
import { NumberFormat } from '../../../../../../enums/number-format';

export const useTableCell = ({
  cell,
  cellIndex,
  loading,
  isTotal,
}: TableCellProps) => {
  const {
    widgetProperties,
    rowsColor,
    axisXValues,
    isColoringRows,
    filterField,
    setFilterField,
    isActiveFilter,
    widgetState,
    valueSize,
  } = useContext(ComplexTableContext);

  const skeletonTextWidth = useMemo(() => random(70, 130), []);

  const roundingCount = useRoundingCounts(widgetProperties);

  const needShowLoader = loading || (!cell.value && cell.column.accessor);

  const isNeedToColoringCell = isColoringRows && cell.row.index % 2 !== 0;

  const valueFormat = initValueFormatter({ roundingCount });

  const currentPropertyIndex = useMemo(
    () =>
      axisXValues.findIndex(
        (value: any) => `${value.clientUID}` === `${cell.column.id}`,
      ),
    [axisXValues, cell.column.id],
  );
  const currentProperty = axisXValues[currentPropertyIndex];

  const storage = useMemo(
    () => JSON.parse(currentProperty?.storage ?? '{}'),
    [currentProperty],
  );

  const cellVariant: CellVariant = storage?.cellVariant;
  const backgroundColor =
    storage?.cellBackgroundColor || (isNeedToColoringCell ? rowsColor : '');
  const textColor = storage?.cellTextColor;
  const textBold = storage?.textBold;
  const justifyContent = storage?.cellJustifyContent;
  const progressColor = storage?.progressColor;
  const numberFormat = storage?.numberFormat;
  const displayValueText =
    storage?.displayValueText?.length > 0 ? ` ${storage.displayValueText}` : '';

  const isProgress = [
    CellVariant.PROGRESS_AND_TEXT,
    CellVariant.PROGRESS,
  ].includes(cellVariant);

  const value = isProgress ? cell.value?.value : String(cell.value?.value);

  const formattedValue = valueFormat(
    value,
    currentPropertyIndex,
    widgetProperties,
    PanelType.axisX,
    false,
    false,
    numberFormat === NumberFormat.no ? null : numberFormat,
  );
  const formattedValueWithValueText =
    formattedValue.length === 0
      ? formattedValue
      : formattedValue + displayValueText;

  const isFirstTotalCell = isTotal && cellIndex === 0;

  // для первого столбца итогов меняем строку "null" на пустую строку
  const formattedValueWithText =
    isFirstTotalCell &&
    (formattedValueWithValueText || '').toLowerCase() === 'null'
      ? ''
      : formattedValueWithValueText;

  const progressValue =
    cellVariant === CellVariant.PROGRESS_AND_TEXT ? formattedValueWithText : '';
  const progressPercent =
    isProgress && cell.value?.progress ? cell.value.progress : null;

  const displayedValue =
    isFirstTotalCell
      ? `Итого${formattedValueWithText ? ': ' : ''}${formattedValueWithText}`
      : formattedValueWithText;

  const cellValue = cell.column.accessor
    ? isProgress && !isTotal
      ? progressValue
      : displayedValue
    : `${CommonDictionary.dimensions} не привязаны`;

  const view = {
    progressColor,
    textColor,
    backgroundColor,
    textBold,
    justifyContent,
    conditionalColoring: cell.value?.conditionalColoring || null,
    valueSize,
  };

  const { selectData } = useWidgetFilters({
    setFilterField,
    filterField,
    isActiveFilter,
    widgetId: widgetState.id,
  });

  const handleClickCell = useCallback(() => {
    const currentColumn = axisXValues.find(
      (value) => value.clientUID === cell.column.id,
    );
    if (!currentColumn || !isActiveFilter) return;

    const columnId = new Property(currentColumn).getId();

    selectData(cell.value.value, columnId);
  }, [axisXValues, cell, isActiveFilter, selectData]);

  return {
    needShowLoader,
    skeletonTextWidth,
    cellValue,
    view,
    progressPercent,
    handleClickCell,
  };
};
