import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { setLoadingAction } from 'src/slices/main-page/main-page-slice';
import { apiSaveProjectVersion } from 'src/services/projectVersionController';
import { CustomModalForm } from '../../../uikit/ModalForm';
import { State } from '../../../slices/types';
import { TextInput } from '../special-inputs/inputs/text-input';
import { useNotificator } from '../snackbar/hooks';
import { CustomButton } from '../../../uikit/Button';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../uikit/Progress';

export interface FormValues {
  description: string,
  projectId: number,
}

interface SaveVersionFormProps {
  handleCloseForm: () => void;
  projectId: number;
  projectName?: string;
}

const SaveVersionForm: React.FC<SaveVersionFormProps> = ({
  handleCloseForm,
  projectId,
  projectName,
}) => {
  const dispatch = useDispatch();
  
  const isLoading = useSelector((state: State) => state.mainPage.isLoading);

  const initialValues: FormValues = {
    description: '',
    projectId,
  };

  const { showNotification } = useNotificator();

  const onSubmit = (
    values: FormValues,
  ) => {
    dispatch(setLoadingAction(true));
    apiSaveProjectVersion(values)
      .then(() => {
        showNotification({
          message: 'Сохранено',
          variant: 'success',
        });
        handleCloseForm();
      })
      .catch((err) => {
        const defaultErrorMessage = `Ошибка при сохранении версии ${CommonDictionary.projectGenitive}`;
        showNotification({
          message: err?.response?.data?.message || defaultErrorMessage,
          variant: 'error',
        });
      })
      .finally(() => {
        dispatch(setLoadingAction(false));
      });
  };

  const { handleSubmit, handleChange, values } =
    useFormik<FormValues>({
      initialValues,
      onSubmit,
    });

  return (
    <CustomModalForm onSubmit={handleSubmit} autoComplete="off">
      <TextInput
        disabled
        formikName="name"
        label={`Название ${CommonDictionary.projectGenitive}`}
        value={projectName}
        handleChange={handleChange}
        fullWidth
      />

      <TextInput
        formikName="description"
        label="Комментарий"
        value={values.description}
        handleChange={handleChange}
        fullWidth
      />

      <div className="dialog-buttons dialog-buttons_in-form">
        <CustomButton
          disabled={isLoading}
          variant="contained"
          type="submit"
          startIcon={
            isLoading ? (
              <CustomProgress
                type="circular"
                style={{ color: 'var(--white)' }}
                size={20}
              />
            ) : null
          }
        >
          Сохранить
        </CustomButton>
        <CustomButton
          disabled={isLoading}
          variant="outlined"
          onClick={handleCloseForm}
        >
          Отменить
        </CustomButton>
      </div>
    </CustomModalForm>
  );
};

export default SaveVersionForm;