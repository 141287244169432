import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ItemLoadersCard from './item-loaders-card';

import { Project } from '../../../slices/types';

interface LoaderListProps {
  projects: Project[] | undefined;
  groupId: number;
}

const useStyles = makeStyles({
  'cards-list': {
    margin: '10px',
    display: 'grid',
    gridGap: 'calc(25px * var(--scale-coefficient))',
    gridTemplateColumns: 'repeat(auto-fill, calc(280px * var(--scale-coefficient)))',
    ['@media (max-width: 430px)']: {
      gridTemplateColumns: 'repeat(auto-fill, 100%)'
    }
  },
});

const LoadersCards = ({ projects, groupId }: LoaderListProps) => {
  const classes = useStyles();

  return (
    <div className={classes['cards-list']}>
      {projects
        ? projects.map((project, index) => (
            <ItemLoadersCard
              key={project.id}
              groupId={groupId}
              project={project}
            />
          ))
        : null}
    </div>
  );
};

export default LoadersCards;
