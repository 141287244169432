import React, { FC, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { PanelType } from '../../../../enums/widget-type';
import DashboardWidget from './widget';
import { State, WidgetProperties } from '../../../../slices/types';
import { ParticipantFilterWidget } from '../../hooks';
import { PropertyData } from '../../../widget-page/dropdown-layout/helpers/Property';

interface DrilldownDialogProps {
  onClose: () => void;
  isParentForOpenedDrilldown: boolean;
  connectedWidget: ParticipantFilterWidget | null;
  handleDeleteWidget?: (widgetId: number) => void;
  filtersForChildren: PropertyData[] | null;
  increaseDrilldownDepth: () => void;
  decreaseDrilldownDepth: (isClose?: boolean) => void;
  drillDownDepth: number;
  parentId: number;
  overriddenPropertiesProp?: WidgetProperties[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide in direction="down" ref={ref} {...props} />;
});

export const DrilldownDialog: FC<DrilldownDialogProps> = memo(
  ({
    onClose,
    isParentForOpenedDrilldown,
    connectedWidget,
    handleDeleteWidget,
    filtersForChildren,
    increaseDrilldownDepth,
    decreaseDrilldownDepth,
    drillDownDepth,
    parentId,
    overriddenPropertiesProp
  }) => {
    const currentWidgetIdFullScreen = useSelector(
      (state: State) => state.dashboard.currentWidgetIdFullScreen,
    );

    if (!connectedWidget || !isParentForOpenedDrilldown) return null;

    const overriddenProperties = overriddenPropertiesProp?.filter((property) =>
      property.name === PanelType.axisFilter
    );

    const isFullScreen = currentWidgetIdFullScreen === connectedWidget.widgetId;

    return (
      <Dialog
        TransitionComponent={Transition as any}
        open={isParentForOpenedDrilldown}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        maxWidth={false}
        keepMounted={false}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        fullScreen={isFullScreen}
      >
        <div
          className={clsx('dashboard-fullscreen-popup', {
            'dashboard-fullscreen-popup__fullscreen': isFullScreen,
          })}
        >
          <DashboardWidget
            widgetId={connectedWidget.widgetId}
            handleDeleteWidget={handleDeleteWidget}
            isStatic
            isDrillDown
            parentFilters={filtersForChildren}
            increaseDrilldownDepth={increaseDrilldownDepth}
            decreaseDrilldownDepth={decreaseDrilldownDepth}
            drillDownDepth={drillDownDepth}
            parentId={parentId}
            overriddenPropertiesInherited={overriddenProperties}
          />
        </div>
      </Dialog>
    );
  },
);

DrilldownDialog.displayName = 'DrilldownDialog';
