import React from 'react';
import './bound-type-switcher.css';
import { settingTabs } from '../../enums';
import { ConfirmDialog } from '../../../../../uikit/ConfirmDialog';
import { TabsSwitcher } from './tabs-switcher';
import { RadioSwitcher } from './radio-switcher';
import { useBoundTypeSwitcher } from './useBoundTypeSwitcher';

export interface BoundTypeSwitcherProps {
  type: 'tabs' | 'radio';
  currentTab: settingTabs;
  handleSwitchCurrentTab: (tab: settingTabs) => void;
  enableVerification: boolean;
}

export const BoundTypeSwitcher: React.FC<BoundTypeSwitcherProps> = ({
  type,
  currentTab,
  handleSwitchCurrentTab,
  enableVerification,
}) => {
  const {
    handleOpenSwitchDialog,
    openAlert,
    handleClose,
    handleSwitch,
  } = useBoundTypeSwitcher({
    type,
    currentTab,
    handleSwitchCurrentTab,
    enableVerification,
  });

  return (
    <div className="bound-type-switcher">
      <div className='bound-type-switcher__type-filters-title'>
        Выберите способ связи
      </div>
      {type === 'tabs' && (<TabsSwitcher currentTab={currentTab} handleOpenSwitchDialog={handleOpenSwitchDialog} />)}
      {type === 'radio' && (<RadioSwitcher currentTab={currentTab} handleOpenSwitchDialog={handleOpenSwitchDialog} />)}
      <ConfirmDialog
        isOpen={openAlert}
        onClose={handleClose}
        onConfirm={handleSwitch}
        title='Сменить тип связи?'
        message='В случае смены типа связи, настройки текущего удалятся.'
        maxWidth='xs'
      />
    </div>
  );
};
