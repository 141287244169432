import { batch, useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { compact, uniqBy } from 'lodash';
import { useGetDashboardProperty } from '../../../hooks/get-properties/useGetDashboardProperty';
import { State, Widget } from '../../../slices/types';
import { setDashboardPropAction } from '../../../slices/dashboard/dashboard';
import {
  BoundFilterGroupProps,
  ParticipantFilterWidget,
  SetFilterField,
  SetMultipleFilterFields,
} from './index';
import {
  getValuesForWidgetMapManualBound,
} from '../../../helpers/dashboard-page';
import { getGroupIndexesWhereWidgetIsMaster } from '../../../helpers/dashboard-page/filters-helper';
import { DashboardPropertyType } from '../../../enums/dashboard-properties';

export const useDashboardBoundFilters = () => {
  const dispatch = useDispatch();
  const properties = useSelector((state: State) => state.dashboard.properties);

  const autoBoundGroupsValues = useGetDashboardProperty(
    DashboardPropertyType.autoBoundGroups,
    properties,
    []
  ) as BoundFilterGroupProps[];

  const manualBoundGroupsValues = useGetDashboardProperty(
    DashboardPropertyType.manualBoundGroups,
    properties,
    []
  ) as BoundFilterGroupProps[];

  const isValidFilters = useCallback(
    (
      selectedFieldId: number | null,
      widgetId: number,
      mapData: { data: any; transcription: any; etlSourceId: string } | null,
    ) => {
      const isAutoBoundActive =
        autoBoundGroupsValues.length &&
        autoBoundGroupsValues.find((item) => item.isActive);
      if (!selectedFieldId || isAutoBoundActive) return true;

      let isValid = false;

      if (manualBoundGroupsValues.length) {
        const currentManualBoundGroup = manualBoundGroupsValues.find((item) => {
          return item.participantFilterWidgets.find(
            (participantFilterWidget) =>
              participantFilterWidget.widgetId === widgetId &&
              participantFilterWidget.isMaster,
          );
        });

        if (currentManualBoundGroup) {
          const { selectedMapFieldId } = getValuesForWidgetMapManualBound(
            widgetId,
            currentManualBoundGroup.participantFilterWidgets,
            mapData,
          );
          const selectedId = selectedMapFieldId || selectedFieldId;

          currentManualBoundGroup.participantFilterWidgets.forEach(
            (participantFilterWidget) => {
              if (
                participantFilterWidget.filterId === selectedId &&
                participantFilterWidget.isActive
              ) {
                isValid = true;
              }
            },
          );
        }
      }

      return isValid;
    },
    [autoBoundGroupsValues.length, manualBoundGroupsValues, autoBoundGroupsValues],
  );

  // сейчас виджет карта может быть в нескольких группах связанных фильтров,
  // когда появится еще подобный виджет, то нужно будет перенести функции с логикой определения данных в виджеты

  const setFilterField = useCallback<SetFilterField>(
    (widgetId, selectedFieldId, data, mapData = null) => {
      if (!isValidFilters(selectedFieldId, widgetId, mapData)) return;

      if (autoBoundGroupsValues.length) {
        const groupsIndexes = autoBoundGroupsValues
          .map((item, index) => ({ ...item, index }))
          .filter(
            (item) =>
              item.participantFilterWidgets.find(
                (participant) =>
                  participant.widgetId === widgetId &&
                  (mapData?.etlSourceId ?? participant.sourceId) ===
                    participant.sourceId,
              ) && item.isActive,
          )
          .map((item) => item.index);

        groupsIndexes.forEach((groupIndex) => {
          autoBoundGroupsValues[groupIndex].participantFilterWidgets.forEach(
            (item) => {
              item.isMaster =
                widgetId === item.widgetId &&
                (mapData?.etlSourceId ?? item.sourceId) === item.sourceId;
            },
          );

          autoBoundGroupsValues[groupIndex].filters = data;
          autoBoundGroupsValues[groupIndex].selectedSourceFieldId =
            selectedFieldId;
        });
      }

      if (manualBoundGroupsValues.length) {
        // очищаем старые связи по фильтрам
        manualBoundGroupsValues.forEach((group, index) => {
          manualBoundGroupsValues[index].filters = [];
          manualBoundGroupsValues[index].selectedSourceFieldId = null;
        });

        const groupsIndexes: number[] = [];
        manualBoundGroupsValues.forEach((group, index) => {
          const currentWidgetIndex = group.participantFilterWidgets.findIndex(
            (widget) => widget.isMaster && widget.widgetId === widgetId,
          );

          if (currentWidgetIndex !== -1) {
            groupsIndexes.push(index);
          }
        });

        groupsIndexes.forEach((value) => {
          const { selectedMapFieldId, mapValues } =
            getValuesForWidgetMapManualBound(
              widgetId,
              manualBoundGroupsValues[value].participantFilterWidgets,
              mapData,
            );

          // mapData && !mapValues.length - значит "если это карта и не слой по которому нажали"
          const selectedSourceFieldId =
            mapData && !mapValues.length
              ? null
              : selectedMapFieldId || selectedFieldId;

          const filters =
            selectedSourceFieldId || (mapData && mapValues.length)
              ? [
                  {
                    operation: data[0]?.operation,
                    value: mapValues.length ? mapValues : data[0]?.value,
                  },
                ]
              : [];

          if (!filters.length || !selectedSourceFieldId) return;

          manualBoundGroupsValues[value].filters = filters;
          manualBoundGroupsValues[value].selectedSourceFieldId =
            selectedSourceFieldId;
        });
      }

      batch(() => {
        dispatch(
          setDashboardPropAction({
            name: DashboardPropertyType.autoBoundGroups,
            value: JSON.stringify(autoBoundGroupsValues),
          }),
        );
        dispatch(
          setDashboardPropAction({
            name: DashboardPropertyType.manualBoundGroups,
            value: JSON.stringify(manualBoundGroupsValues),
          }),
        );
      });
    },
    [
      JSON.stringify(autoBoundGroupsValues),
      JSON.stringify(manualBoundGroupsValues),
    ],
  );

  const resetAutoMultipleFilters = useCallback(() => {
    const newValues = getDefaultAutoMultipleFilters();

    dispatch(
      setDashboardPropAction({
        name: DashboardPropertyType.autoBoundGroups,
        value: JSON.stringify(newValues),
      }),
    );
  }, [JSON.stringify(autoBoundGroupsValues), dispatch]);

  const getDefaultAutoMultipleFilters = useCallback(() => {
    const uniqGroups = uniqBy(autoBoundGroupsValues, 'id');

    return uniqGroups.map((group) => ({
      ...group,
      filters: [],
      selectedSourceFieldId: null,
    }));
  }, [JSON.stringify(autoBoundGroupsValues), dispatch]);

  const resetAutoMultipleFilterFields = useCallback(
    (currentGroup: BoundFilterGroupProps) => {
      const newValues = autoBoundGroupsValues.filter(
        (value) => value.id !== currentGroup.id,
      );
      newValues.push({
        ...currentGroup,
        filters: [],
        selectedSourceFieldId: null,
      });

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.autoBoundGroups,
          value: JSON.stringify(newValues),
        }),
      );
    },
    [JSON.stringify(autoBoundGroupsValues), dispatch],
  );

  const setMultipleFilterFieldsForAuto = useCallback<SetMultipleFilterFields>(
    (widgetId, selectedFieldsIds, data, callback) => {
      const groupIndexes = getGroupIndexesWhereWidgetIsMaster(
        widgetId,
        autoBoundGroupsValues,
        true,
      );
      if (!groupIndexes.length) return;

      const groupIndex = groupIndexes[0];
      const currentGroup = { ...autoBoundGroupsValues[groupIndex] };
      currentGroup.participantFilterWidgets =
        currentGroup.participantFilterWidgets.map((item) =>
          item.widgetId === widgetId && item.isActive
            ? {
              ...item,
              isMaster: true,
            }
            : {
              ...item,
              isMaster: false,
            },
        );

      if (!selectedFieldsIds || !selectedFieldsIds.length) {
        resetAutoMultipleFilterFields(currentGroup);
        return;
      }

      const newValues = autoBoundGroupsValues.filter(
        (value) => value.id !== currentGroup.id,
      );

      selectedFieldsIds.forEach((id, index) => {
        const copyCurrentGroupWithDifferentValues = {
          ...currentGroup,
          selectedSourceFieldId: id,
          filters: [data[index]],
        };
        newValues.push(copyCurrentGroupWithDifferentValues);
      });

      callback && callback();
      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.autoBoundGroups,
          value: JSON.stringify(newValues),
        }),
      );
    },
    [
      JSON.stringify(autoBoundGroupsValues),
      dispatch,
      resetAutoMultipleFilterFields,
    ],
  );

  const setMultipleFilterFields = useCallback<SetMultipleFilterFields>(
    (widgetId, selectedFieldsIds, data, callback) => {
      if (autoBoundGroupsValues.length) {
        setMultipleFilterFieldsForAuto(
          widgetId,
          selectedFieldsIds,
          data,
          callback,
        );
      }
    },
    [JSON.stringify(autoBoundGroupsValues)],
  );

  const toggleFilterGroup = useCallback(
    (id: number) => {
      if (!autoBoundGroupsValues.length) return;

      const groupIndex = autoBoundGroupsValues.findIndex(
        (item) => item.id === id,
      );
      autoBoundGroupsValues[groupIndex].isActive =
        !autoBoundGroupsValues[groupIndex].isActive;

      // если включаем группу, и при этом все участники выключены, то их тоже вкл.
      if (
        autoBoundGroupsValues[groupIndex].isActive &&
        !autoBoundGroupsValues[groupIndex].participantFilterWidgets.find(
          (filter) => filter.isActive,
        )
      ) {
        autoBoundGroupsValues[groupIndex].participantFilterWidgets.forEach(
          (filter, index) => {
            autoBoundGroupsValues[groupIndex].participantFilterWidgets[
              index
            ].isActive = true;
          },
        );
      }

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.autoBoundGroups,
          value: JSON.stringify(autoBoundGroupsValues),
        }),
      );
    },
    [autoBoundGroupsValues],
  );

  const toggleIsMaster = useCallback(
    (groupId: number, id: number) => {
      if (!autoBoundGroupsValues.length) return;
      const groupIndex = autoBoundGroupsValues.findIndex(
        (item) => item.id === groupId,
      );
      const index = autoBoundGroupsValues[
        groupIndex
      ].participantFilterWidgets.findIndex((item: any) => item.id === id);

      autoBoundGroupsValues[groupIndex].participantFilterWidgets[
        index
      ].isMaster =
        !autoBoundGroupsValues[groupIndex].participantFilterWidgets[index]
          .isMaster;

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.autoBoundGroups,
          value: JSON.stringify(autoBoundGroupsValues),
        }),
      );
    },
    [autoBoundGroupsValues],
  );

  const toggleParticipantIsActive = useCallback(
    (groupId: number, id: number) => {
      if (!autoBoundGroupsValues.length) return;

      const groupIndex = autoBoundGroupsValues.findIndex(
        (item) => item.id === groupId,
      );
      const index = autoBoundGroupsValues[
        groupIndex
      ].participantFilterWidgets.findIndex((item: any) => item.id === id);

      autoBoundGroupsValues[groupIndex].participantFilterWidgets[
        index
      ].isActive =
        !autoBoundGroupsValues[groupIndex].participantFilterWidgets[index]
          .isActive;

      if (
        !autoBoundGroupsValues[groupIndex].participantFilterWidgets.find(
          (filter) => filter.isActive,
        )
      ) {
        autoBoundGroupsValues[groupIndex].isActive = false;
      }

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.autoBoundGroups,
          value: JSON.stringify(autoBoundGroupsValues),
        }),
      );
    },
    [autoBoundGroupsValues],
  );

  const getGroupForWidget = useCallback(
    (widget: Widget): BoundFilterGroupProps[] => {
      const boundGroupsValues = manualBoundGroupsValues.concat(
        autoBoundGroupsValues,
      );
      return (
        boundGroupsValues.filter((item) =>
          item.participantFilterWidgets.find(
            (participant) => participant.widgetId === widget.id,
          ),
        ) || []
      );
    },
    [autoBoundGroupsValues, manualBoundGroupsValues],
  );

  const getBoundGroupsForWidget = useCallback(
    (widget: Widget): BoundFilterGroupProps[] => {
      const groupsIndexes: number[] = [];
      manualBoundGroupsValues.forEach((group, index) => {
        const currentWidgetIndex = group.participantFilterWidgets.findIndex(
          (item) => item.isMaster && item.widgetId === widget.id,
        );

        if (currentWidgetIndex !== -1) {
          groupsIndexes.push(index);
        }
      });

      return groupsIndexes.map((value) => manualBoundGroupsValues[value]);
    },
    [manualBoundGroupsValues],
  );

  const getParticipantsInGroupsForWidget = useCallback(
    (widget: Widget): ParticipantFilterWidget[] | null => {
      const groups = getGroupForWidget(widget);

      return (
        compact(
          groups?.map(
            (item) =>
              item.participantFilterWidgets?.find(
                (filter: ParticipantFilterWidget) =>
                  Number(filter.widgetId) === Number(widget.id),
              ) || null,
          ),
        ) || null
      );
    },
    [getGroupForWidget],
  );

  const setAutoBoundGroups = useCallback(
    (groups: BoundFilterGroupProps[]) => {
      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.autoBoundGroups,
          value: JSON.stringify(groups),
        }),
      );
    },
    [dispatch],
  );

  return {
    values: manualBoundGroupsValues.concat(autoBoundGroupsValues),
    autoValues: autoBoundGroupsValues,
    toggleFilterGroup,
    toggleParticipantIsActive,
    toggleIsMaster,
    setFilterField,
    getGroupForWidget,
    getParticipantsInGroupsForWidget,
    getBoundGroupsForWidget,
    setAutoBoundGroups,
    setMultipleFilterFields,
    resetAutoMultipleFilters,
    getDefaultAutoMultipleFilters,
  };
};
