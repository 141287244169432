import React from 'react';
import { Group } from '@visx/group';
import { AxisLeft } from '@visx/axis';
import { isNaN } from 'lodash';
import { useRoundingCounts } from 'src/hooks/useRoundingCounts';
import { useGraphProperties } from '../hooks/useGraphProperties';
import { ScalableSVGText } from '../../../../common/scalable-svg-text';
import { PanelType } from '../../../../../enums/widget-type';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import { setPropForNumberValue } from '../../formatting-helpers';
import {
  circleParams,
  gridLinesColor,
  gridLinesXOffset,
  leftAxisSettings,
  numTicks,
  stageSettings,
} from '../settings';

interface LeftAxisProps {
  width: any;
  yScale: any;
  widgetProperties: any[];
}

export const LeftAxis = ({
  width,
  yScale,
  widgetProperties,
}: LeftAxisProps) => {
  const { isNeedToDisplayAxesGuide, customAxisYLabelWidth, scaleByNumber, formatByNumber } =
    useGraphProperties({ widgetProperties });

  const textYOffset = 5;

  const roundingCount = useRoundingCounts(widgetProperties);
  const valueFormat = initValueFormatter({ roundingCount });

  const axisYLabelWidth = isNaN(customAxisYLabelWidth)
    ? leftAxisSettings.left
    : customAxisYLabelWidth;

  return (
    <AxisLeft
      hideAxisLine
      hideTicks
      numTicks={numTicks}
      left={axisYLabelWidth}
      top={leftAxisSettings.top + leftAxisSettings.topOffset}
      tickFormat={(x) => `${x}`}
      tickComponent={(props) => {
        return (
          <Group>
            <ScalableSVGText
              x={props.x - axisYLabelWidth + stageSettings.left}
              y={props.y - textYOffset}
              formattedValue={valueFormat(
                props.formattedValue,
                0,
                setPropForNumberValue(widgetProperties),
                PanelType.axisY,
                false,
                false,
                scaleByNumber ? formatByNumber : null
              )}
              axis={PanelType.axisY}
              properties={{
                width: axisYLabelWidth,
                svgTextStyles: leftAxisSettings,
              }}
              widgetProperties={widgetProperties}
            />
            {isNeedToDisplayAxesGuide && (
              <rect
                className="visx-bar"
                x={props.x + gridLinesXOffset + circleParams.radius + stageSettings.left}
                y={props.y}
                width={width - stageSettings.left * 2}
                height="1"
                fill={gridLinesColor}
              />
            )}
          </Group>
        );
      }}
      scale={yScale}
      stroke={leftAxisSettings.color}
      tickStroke={leftAxisSettings.color}
      tickLabelProps={() => ({
        fill: leftAxisSettings.color,
        textAnchor: 'middle',
      })}
    />
  );
};
