import block from 'bem-cn';
import React from 'react';
import { Button } from '@material-ui/core';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { useClassButton } from '../../../../../hooks/useClassButton';

import './options-section.css';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface OptionsSectionProps {
  onAddAll: () => void;
  onDeleteAll: () => void;
  isEditableMap: boolean;
}

const OptionsSection = ({ onAddAll, onDeleteAll, isEditableMap }: OptionsSectionProps) => {
  const b = block('select-fields-panel');
  const classButton = useClassButton();
  return (
    isEditableMap ? <div className={b('options-section')}>
      <div className={b('options-section-left')}>
        <Button
          classes={{ startIcon: b('options-section-svg-pic') }}
          className={`${classButton.nonBorder} ${b(
            'options-section-left-side-button',
          )}`}
          onClick={onAddAll}
          startIcon={
            <IconSvg svg={IconDictionary.ListAdd} fill="var(--primary-color)" />
          }
        >
          <span className={b('options-section-dotted-add')}>Добавить Все</span>
        </Button>
      </div>
      <div className={b('options-section-right')}>
        <Button
          classes={{ startIcon: b('options-section-svg-pic') }}
          className={`${classButton.nonBorder} ${b(
            'options-section-left-side-button',
          )}`}
          onClick={onDeleteAll}
          startIcon={
            <IconSvg svg={IconDictionary.ListDelete} fill="var(--dark-grey)" />
          }
        >
          <span className={b('options-section-dotted-delete')}>
            Удалить Все
          </span>
        </Button>
      </div>
    </div> : null
  );
};

export default OptionsSection;
