import SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';

interface SocketNetworkParams {
  url: string;
}

type HandlerUID = string;

enum ConnectionStatus {
  DISCONNECTED,
  CONNECTED,
  CONNECTING,
  DISCONNECTING,
}

export class SocketNetwork {
  public constructor({ url }: SocketNetworkParams) {
    this.url = url;
  }

  private url: string = '';

  private ws: any = null;

  private status: ConnectionStatus = ConnectionStatus.DISCONNECTED;

  private onConnectHandlers: Array<Function> = [];

  getConnectionStatus(): ConnectionStatus {
    return this.status;
  }

  async synchronizedConnect(onConnect: Function): Promise<void> {
    if (this.status === ConnectionStatus.CONNECTING) {
      this.onConnectHandlers.push(onConnect);
      return;
    }

    if (this.status === ConnectionStatus.CONNECTED) {
      onConnect();
      return;
    }

    this.connect(onConnect);
  }

  async connect(onConnect: Function): Promise<void> {
    this.onConnectHandlers.push(onConnect);

    this.status = ConnectionStatus.CONNECTING;

    // переносить в конструктор создание экземпляров НЕЛЬЗЯ!
    // иначе SockJS сам устанавливает конект и Stomp падает при своем конекте
    const socket = new SockJS(this.url);
    this.ws = Stomp.over(socket);
    this.ws.debug = null;

    await this.ws.connect({}, () => {
      this.status = ConnectionStatus.CONNECTED;
      this.onConnectHandlers.forEach((handler) => {
        handler();
      });
      this.onConnectHandlers = [];
    });
  }

  disconnect() {
    if (this.ws !== null) {
      this.status = ConnectionStatus.DISCONNECTING;
      this.ws.disconnect({}, () => {
        this.status = ConnectionStatus.DISCONNECTED;
      });
    }
  }

  addHandler(channelName: string, callback: Function): HandlerUID {
    const { id } = this.ws.subscribe(channelName, callback);
    return id;
  }

  removeHandler(uid: HandlerUID) {
    this.ws.unsubscribe(uid);
  }
}
