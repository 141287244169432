import React, { useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
import './styles.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import { DashboardProperty, WidgetProperties } from 'src/slices/types';
import MapLegend from './components/map-legend';
import CardsList from './components/cards-list';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { NoDataTemplate } from '../../../common/no-widget-data-template';
import { useMapProperties } from './hooks/useMapProperties';
import { useMapSettings } from './hooks/useMapSettings';
import { CSSForLegendPosition } from '../../helpers';

interface MapProps {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  enhancedParams: {dashboardProperties: DashboardProperty[], widgetOverriddenProperties: WidgetProperties[]};
}

function MapGeoJSON({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  enhancedParams
}: MapProps) {
  const widgetProperties = useMemo(() => widgetProps.properties, [widgetProps.properties]);

  const {
    isNeedToDisplayLegend,
    legendPosition,
  } = useMapProperties({ widgetProperties });

  const {
    dataError,
    mapContainerRef,
    currentColors,
    heatMapLayers,
    isNeedToDisplayCardsList,
    dataTypes,
    filteredPopupContentList,
    handlerChangeFilter,
    handleClickLegendItem,
    activeLayers,
    isLoadingCards,
  } = useMapSettings({
    widgetProps,
    filterField,
    isActiveFilter,
    setFilterField,
    enhancedParams
  });

  const legendsContainerStyles = useMemo(
    () => CSSForLegendPosition[legendPosition],
    [legendPosition],
  );

  return dataError.length ? (<NoDataTemplate withLinkToEditor={false} message={dataError} />) : (
    <div style={{ ...legendsContainerStyles, display: 'flex', flexGrow: 1 }}>
      {isNeedToDisplayLegend && Boolean(dataTypes.length) && (
        <MapLegend
          currentColors={currentColors}
          dataTypes={dataTypes}
          heatMapLayers={heatMapLayers}
          widgetProperties={widgetProperties}
          isNeedToDisplayCardsList={isNeedToDisplayCardsList}
          handleClickLegendItem={handleClickLegendItem}
          activeLayers={activeLayers}
        />
      )}
      <div className="map-container">
        {isNeedToDisplayCardsList && (
          <CardsList
            popupContentList={filteredPopupContentList}
            onChangeFilter={handlerChangeFilter}
            widgetProperties={widgetProperties}
            isLoadingCards={isLoadingCards}
          />
        )}
        <div id="map" ref={mapContainerRef} />
      </div>
    </div>
  );
}

export default MapGeoJSON;
