import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import './styles.css';
import IconSvg from '../../common/icon-svg/icon-svg';
import { openDashboardFiltersSettingAction } from '../../../slices/dashboard/dashboard';
import { dashboardSettignsDictionary } from '../../../dictionaries/dashboard-settings';
import { SettingField } from './setting-field';
import { State } from '../../../slices/types';
import { useDashboardBoundFilters } from '../hooks/useDashboardBoundFilters';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

export enum FiltersSettingsTypes {
  MAIN = 'MAIN',
  CHAIN = 'CHAIN',
  DRILLDOWN = 'DRILLDOWN',
  PARAMETERS = 'PARAMETERS',
}

type SettingsButtonProps = {
  type: FiltersSettingsTypes;
  iconName: keyof typeof IconDictionary;
  title: string;
};

const SettingsButton: React.FC<SettingsButtonProps> = ({
  type,
  iconName,
  title,
}) => {
  const dispatch = useDispatch();
  const isActive = useSelector(
    (state: State) => state.dashboard.openedFiltersSetting === type,
  );
  const {
    resetAutoMultipleFilters,
  } = useDashboardBoundFilters();

  const openHandler = () => {
    if (type === FiltersSettingsTypes.CHAIN) {
      resetAutoMultipleFilters();
    }

    dispatch(
      openDashboardFiltersSettingAction({
        name: type,
      }),
    );
  };

  return (
    <button
      type="button"
      onClick={openHandler}
      className={`setting-filters-button ${isActive ? 'active' : ''}`}
    >
      <div className="setting-filters-icon">
        <IconSvg
          svg={IconDictionary[iconName]}
          fill={isActive ? 'var(--primary-color)' : 'var(--dark-grey)'}
        />
      </div>
      <div className="filters-button-title">{title}</div>
    </button>
  );
};

const Settings = () => {
  const buttons: SettingsButtonProps[] = [
    {
      type: FiltersSettingsTypes.MAIN,
      iconName: 'WidgetFilter',
      title: 'Фильтры',
    },
    {
      type: FiltersSettingsTypes.CHAIN,
      iconName: 'DashboardBoundSetting',
      title: 'Связи',
    },
    {
      type: FiltersSettingsTypes.DRILLDOWN,
      iconName: 'DashboardDrilldownSetting',
      title: CommonDictionary.Drilldown
    },
    {
      type: FiltersSettingsTypes.PARAMETERS,
      iconName: 'DashboardParameterSetting',
      title: 'Параметры',
    },
  ];

  return (
    <div className="settings-list">
      <Box>
        {dashboardSettignsDictionary.map((config) => (
          <SettingField config={config} key={config.name} />
        ))}
      </Box>
      <Box mb="15px">
        <h3 className="setting-title">Настройка фильтров</h3>
        <div>
          {buttons.map((button) => (
            <SettingsButton
              key={button.type}
              type={button.type}
              iconName={button.iconName}
              title={button.title}
            />
          ))}
        </div>
      </Box>
    </div>
  );
};

export default Settings;
