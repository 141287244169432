import React from 'react';
import './index.css';

interface SwipeableContainerProps {
  children: React.ReactNode;
  index: number;
}

export const SwipeableContainer = ({
  children,
  index,
}: SwipeableContainerProps) => {
  return (
    <div className="swipeable-container">
      <div className="swipeable-container__content" style={{ transform: `translateX(${-100 * index}%)` }}>
        {children}
      </div>
    </div>
  );
};
