import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import * as Rainbow from 'rainbowvis.js';

import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import './speedometer.css';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { setPropForNumberValue } from '../formatting-helpers';
import { PanelType } from '../../../../enums/widget-type';
import { State } from '../../../../slices/types';
import { useRoundingCounts } from '../../../../hooks/useRoundingCounts';
import { useEventListener } from '../../../../hooks/useEventListener';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { getInputPropertyValue } from '../helpers';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';

interface PositionNums {
  x: number;
  y: number;
  rx?: number;
  ry?: number;
}

interface SpeedometerProps {
  x: number;
  y: number;
  text: string;
  widgetProperties: any;
  id: number;
}

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) => {
  const angleInRadians = ((angleInDegrees - 230) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const debounceDelay = 33;

function SpeedometerChart(props: SpeedometerProps) {
  const isFullScreen = useWidgetFullScreen(props.id);

  const { widgetProperties } = props;


  const speedometerRoot: any = useRef(document.createElement('div'));
  const [side, setSide] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);


  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const letter = document.getElementsByClassName('lateralText')[0];
  const [textOffset, setTextOffset] = useState<number>(0);

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setWidth(value);
  }, debounceDelay);

  const setDebouncedSide = useDebouncedCallback((value) => {
    setSide(value);
  }, debounceDelay);

  const { x, y, text } = props;
  const resolution = 1;
  const radius = 100;
  const outerRadiusBigTicks = radius * 1.5;
  const innerRadiusBigTicks = radius * 1.3;
  const outerRadiusSmallTicks = radius * 1.35;
  const innerRadiusSmallTicks = radius * 1.3;
  const outerRadiusSpeedometer = radius * 1.25;
  const innerRadiusSpeedometer = radius;
  const viewBox = 400;
  const offsetNums = 10;
  const positionNums: PositionNums[] = [];
  let startDot: PositionNums = { x: 0, y: 0 };
  let endDot: PositionNums = { x: 0, y: 0 };

  function describeArc(
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number,
    bigTick: boolean,
    i: number,
  ) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

    const minSpeedometerAngle = 0;
    const maxSpeedometerAngle = 279;
    const overlap = 4;

    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      arcSweep,
      0,
      end.x,
      end.y,
      'L',
      x,
      y,
      'L',
      start.x,
      start.y,
    ].join(' ');

    if (bigTick) {
      const startNums = polarToCartesian(
        x + offsetNums,
        y + offsetNums,
        radius + offsetNums,
        endAngle,
      );

      positionNums.push({
        x: startNums.x,
        y: startNums.y,
      });
    }

    if (i === minSpeedometerAngle) {
      startDot = {
        x: start.x + overlap,
        y: start.y + overlap,
        rx: radius,
        ry: radius,
      };
    } else if (i === maxSpeedometerAngle) {
      endDot = {
        x: start.x,
        y: start.y,
        rx: radius,
        ry: radius,
      };
    }

    return d;
  }

  function generateConicGradiant(
    radius: number,
    resolution: number,
    multiplier: number,
    flags: any = { isBigTicks: false, isSmallTicks: false },
  ) {
    const paths = [];
    const { isBigTicks, isSmallTicks } = flags;
    const isSpeedometer = !isBigTicks && !isSmallTicks;

    const pathCount = 280 * resolution;

    const rainbow = new Rainbow();
    rainbow.setSpectrum('#FF6B00', '#ECC80B', '#05A403');
    rainbow.setNumberRange(0, pathCount - 1);

    for (let i = 0; i < pathCount; i++) {
      const pathColor = `#${  rainbow.colourAt(i)}`;
      const isColorPath = i < 280 / multiplier;

      const color = isColorPath ? pathColor : '#edf1f8';

      const isPathWithBigTicks = i === 0 || i % 70 === 0 || i === 279;
      const bigTick = isBigTicks && isPathWithBigTicks;

      const isPathWithSmallTicks = i % 14 === 0;
      const smallTick = isSmallTicks && isPathWithSmallTicks;

      const startAngle = i + (i === 279 && !isSpeedometer ? 1 : 0) / resolution;
      const endAngle =
        (i + (isSpeedometer ? 2 : i === 279 ? 1 : 0)) / resolution;

      if (isSpeedometer || bigTick || smallTick) {
        paths.push({
          d: describeArc(
            radius,
            radius,
            radius,
            startAngle,
            endAngle,
            bigTick,
            i,
          ),
          fill: color,
        });
      }
    }
    return paths;
  }

  function generateOverlay(outerRadius: number, innerRadius: number) {
    return {
      cx: outerRadius,
      cy: outerRadius,
      r: innerRadius,
      fill: 'var(--white)',
    };
  }

  const multiplier = x / y;
  const toFixed = (num: number, coefficient: number) =>
    (Number((num * coefficient).toFixed(4)) / 1).toString();

  const calcNums = [
    toFixed(0, 0),
    toFixed(x, 0.25),
    toFixed(x, 0.5),
    toFixed(x, 0.75),
    toFixed(x, 1),
  ];

  const bigTicks = generateConicGradiant(
    outerRadiusBigTicks,
    resolution,
    multiplier,
    { isBigTicks: true },
  );
  const overlayBigTicks = generateOverlay(
    outerRadiusBigTicks,
    innerRadiusBigTicks,
  );
  const smallTicks = generateConicGradiant(
    outerRadiusSmallTicks,
    resolution,
    multiplier,
    { isSmallTicks: true },
  );
  const overlaySmallTicks = generateOverlay(
    outerRadiusSmallTicks,
    innerRadiusSmallTicks,
  );
  const speedometer = generateConicGradiant(
    outerRadiusSpeedometer,
    resolution,
    multiplier,
  );
  const overlaySpeedometer = generateOverlay(
    outerRadiusSpeedometer,
    innerRadiusSpeedometer,
  );

  const lateralTextOffset = outerRadiusBigTicks + offsetNums;
  const centerNumWidth = side / 2.3;

  const isHeightAdjustment = speedometerRoot.current.clientHeight < side;
  const resetTopTranslateY = isHeightAdjustment
    ? 'top-translateY__reset'
    : '';

  const calculateDimensions = useCallback(() => {
    if (!speedometerRoot.current) return;

    const minSideWidth = 142;

    const { height: clientHeight, width: clientWidth } =
      speedometerRoot.current.getBoundingClientRect();

    setDebouncedSide.callback(
      Math.max(
        Math.min(
          clientWidth,
          clientHeight,
        ),
        minSideWidth,
      ),
    );

    const lateralTexts: any = document.querySelectorAll('.lateralText');
    const lateralTextsWidth: any = [];
    lateralTexts.forEach((text: any) => {
      lateralTextsWidth.push(text.getBoundingClientRect().width);
    });
    setDebouncedWidth.callback(clientWidth);
    setDebouncedHeight.callback(clientHeight);

    const upContainerInDashboard = speedometerRoot.current.closest(
      '.dashboard-grid-item__container',
    );
    const upContainerInWidgetEditor = speedometerRoot.current.closest(
      '.widget-main__chart-container',
    );

    const widgetOrDashboard =
      upContainerInDashboard || upContainerInWidgetEditor;
    const calcScrollLeft =
      (widgetOrDashboard?.scrollWidth - clientWidth) / 2;
    widgetOrDashboard && widgetOrDashboard.scrollTo(calcScrollLeft, 0);
  }, [
    setDebouncedSide,
    setDebouncedWidth,
    setDebouncedHeight,
  ]);

  const calculatingTextOffset = useCallback(() => {
    const textLength = valueFormat(+calcNums[1], 0, setPropForNumberValue(widgetProperties), PanelType.axisY).toString().length;
    const newTextOffset = letter ? textLength * letter.getClientRects()[0]?.height : 0;
    setTextOffset(newTextOffset);
  }, [calcNums, letter, valueFormat]);

  const calculateOnResize = useCallback(() => {
    calculateDimensions();
    calculatingTextOffset();
  }, [
    calculateDimensions,
    calculatingTextOffset
  ]);

  useEffect(() => {
    calculateOnResize();
  }, [
    calculateOnResize,
    isFullScreen,
  ]);

  useEventListener('resize', calculateOnResize);

  const textSize = getInputPropertyValue('textSize', widgetProperties);

  return (
    <div ref={speedometerRoot} className="speedometer__root">
      <svg
        className={`speedometer__svg ${resetTopTranslateY}`}
        width={width}
        height={height}
        style={{ minWidth: 2 * outerRadiusSpeedometer + 2 * textOffset }}
        viewBox={`0 0 ${viewBox} ${viewBox}`}
        version="1.1"
      >
        <g>
          <g
            style={{
              transform: `translate(calc(50% - ${lateralTextOffset}px), calc(50% - ${lateralTextOffset}px))`,
            }}
          >
            {calcNums.map((num, i) => {
              return (
                <text
                  key={i}
                  className="lateralText"
                  fill="var(--grey)"
                  x={positionNums[i].x}
                  y={positionNums[i].y}
                  alignmentBaseline={
                    i === 0 || i === 4 ? 'before-edge' : 'after-edge'
                  }
                  textAnchor={
                    i === 0 || i === 1 ? 'end' : i === 2 ? 'middle' : 'start'
                  }
                >
                  {valueFormat(
                    num,
                    0,
                    setPropForNumberValue(widgetProperties),
                    PanelType.axisY,
                  )}
                </text>
              );
            })}
          </g>
          <g
            style={{
              transform: `translate(calc(50% - ${outerRadiusBigTicks}px), calc(50% - ${outerRadiusBigTicks}px))`,
            }}
          >
            {bigTicks.map((tick, i) => (
              <path key={i} d={tick.d} stroke="var(--grey)" />
            ))}
            <circle
              cx={overlayBigTicks.cx}
              cy={overlayBigTicks.cy}
              r={overlayBigTicks.r}
              fill={overlayBigTicks.fill}
            />
          </g>
          <g
            style={{
              transform: `translate(calc(50% - ${outerRadiusSmallTicks}px), calc(50% - ${outerRadiusSmallTicks}px))`,
            }}
          >
            {smallTicks.map((tick, i) => (
              <path key={i} d={tick.d} stroke="var(--grey)" />
            ))}
            <circle
              cx={overlaySmallTicks.cx}
              cy={overlaySmallTicks.cy}
              r={overlaySmallTicks.r}
              fill={overlaySmallTicks.fill}
            />
          </g>
          <g
            style={{
              transform: `translate(calc(50% - ${outerRadiusSpeedometer}px), calc(50% - ${outerRadiusSpeedometer}px))`,
            }}
          >
            {speedometer.map((path, i) => (
              <path key={i} d={path.d} fill={path.fill} />
            ))}
            <circle
              cx={overlaySpeedometer.cx}
              cy={overlaySpeedometer.cy}
              r={overlaySpeedometer.r}
              fill={overlaySpeedometer.fill}
            />
            <path
              className="path"
              d={`M${endDot.x} ${endDot.y} A ${endDot.rx} ${endDot.ry}, 0, 1, 0, ${startDot.x} ${startDot.y}`}
              fill="none"
              stroke="var(--white)"
              strokeWidth={radius / 1.5}
            />
          </g>
        </g>
      </svg>
      <div
        className="speedometer-center__wrap"
        style={{
          width,
          height,
          minWidth: 2 * outerRadiusSpeedometer + 2 * textOffset,
        }}
      >
        <div
          className="speedometer-center__content"
          style={{ width: centerNumWidth }}
        >
          <CustomTooltip
            title={valueFormat(y, 0, setPropForNumberValue(widgetProperties), PanelType.axisY)}
          >
            <div
              className="speedometer-center__num"
              style={{ fontSize: textSize ? `${textSize}px` : '' }}
            >
              {valueFormat(y, 0, setPropForNumberValue(widgetProperties), PanelType.axisY)}
            </div>
          </CustomTooltip>
          <div className="speedometer-center__description">{text}</div>
        </div>
      </div>
    </div>
  );
}

export default SpeedometerChart;
