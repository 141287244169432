import React, { FC, useContext, useRef, useMemo, useState } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import { CssVariables } from '../../../../../../enums/css-variables';
import { varcss } from '../../../../../../helpers/ui-helper';
import {
  SortingVariants,
  useColumnSorting,
} from '../../libs/hooks/useColumnSorting';
import { HeaderActionFilters } from './filters';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { Property } from '../../../../dropdown-layout/helpers/Property';
import { useColumnFilters } from '../../libs/hooks/useColumnFilters';
import CustomTooltip from '../../../../../../uikit/CustomTooltip';
import { ComplexTableContext } from '../../index';
import './widget-table-header-action.css';
import { DEFAULT_COMPLEX_TABLE_TITLE } from '../table-head';
import { CustomPopover } from '../../../../../../uikit/Popover/popover';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';


export interface HeaderActionProps {
  column: any;
  classes: string;
}

const getNextSorting = (value: SortingVariants | null) => {
  switch (value) {
    case SortingVariants.ASC: return SortingVariants.DESC;
    case SortingVariants.DESC: return null;
    default: return SortingVariants.ASC;
  }
};

const getSortingIcon = (value: SortingVariants | null) => {
  switch (value) {
    case SortingVariants.ASC: return IconDictionary.WidgetSortUp;
    case SortingVariants.DESC: return IconDictionary.WidgetSortDown;
    default: return IconDictionary.WidgetSort;
  }
};

export const HeaderAction: FC<HeaderActionProps> = ({ column, classes }) => {
  const anchorRef = useRef(null);
  const [openedActions, setOpenedActions] = useState(false);

  const openActions = () => setOpenedActions(true);
  const closeActions = () => setOpenedActions(false);

  const {
    axisXValues,
    isNeedDisplayAggregationInLegend,
    displayFilterHeader,
    headTextColor,
    displaySorting,
    displaySortOrder,
  } = useContext(ComplexTableContext);
  const currentPropertyIndex: number = axisXValues.findIndex((value: any) => `${value.clientUID}` === `${column.id}`);
  const currentProperty =  axisXValues[currentPropertyIndex];

  const { getSorting, getSortingOrderPriority, setSorting } = useColumnSorting({
    propertyIndex: currentPropertyIndex,
  });

  const sorting = getSorting();
  const sortingOrderPriority = getSortingOrderPriority();

  const storage = JSON.parse(currentProperty?.storage ?? '{}');
  const sortIconColor = sorting ? DEFAULT_COMPLEX_TABLE_TITLE.activeColor :
    storage?.titleTextColor ||
    headTextColor ||
    DEFAULT_COMPLEX_TABLE_TITLE.textColor;

  const { getFilter, setFilter, getAllFiltersFromAxis } = useColumnFilters({
    propertyIndex: currentPropertyIndex,
  });
  const currentFilter = getFilter();
  const displayFilter = displayFilterHeader && currentFilter.isVisible !== false && !currentFilter.isDashboardFilter;

  const onChangeSorting = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setSorting(getNextSorting(sorting));
    event.stopPropagation();
    event.preventDefault();
  };

  if (!currentProperty) return null;

  const property = new Property(currentProperty);

  const currentFilterValue = currentFilter?.filter;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const memoizableTitles = useMemo(() => {
    const valueFromStorage = (
      property.getValueFromStorage('alias') || ''
    ).split('\\n');

    const buttonTitle: any[] = valueFromStorage[0]
      ? valueFromStorage
      : [property.getViewLegend(isNeedDisplayAggregationInLegend, false, true)];

    const tooltipTitle: any[] = valueFromStorage[0]
      ? valueFromStorage
      : [property.getViewLegend(isNeedDisplayAggregationInLegend)];

    return {
      buttonTitle,
      tooltipTitle,
    };
  }, [property, isNeedDisplayAggregationInLegend]);

  const { buttonTitle, tooltipTitle } = memoizableTitles;

  return (
    <>
      <Box justifyContent={storage?.cellJustifyContent || 'center'} className="widget-table-header-action__wrap">
        {displaySorting && (
          <Box
            className="widget-table-header-action__sorting_icon"
            onClick={onChangeSorting}
          >
            <IconSvg
              svg={getSortingIcon(sorting)}
              fill={sortIconColor}
            />
            {displaySortOrder && sortingOrderPriority && (<span className="widget-table-header-action__sorting_priority">{sortingOrderPriority}</span>)}
          </Box>
        )}
        <ButtonBase
          className={clsx(
            'widget-table-header-action',
            { 'widget-table-header-action__opened': openedActions }
          )}
          ref={anchorRef}
          onClick={openActions}
          disabled={!displayFilter}
        >
          <CustomTooltip
            arrow
            title={
              <div>
                {tooltipTitle.map((el, i) => (
                  <div key={i}>{el}</div>
                ))}
              </div>
            }
            PopperProps={{
              disablePortal: true,
            }}
            placement="top"
          >
            <div
              className={clsx(
                'widget-table-header-action__button-title',
                classes,
              )}
            >
              {buttonTitle.map((el, i) => (
                <div key={i}>{el}</div>
              ))}
            </div>
          </CustomTooltip>

          {Boolean(currentFilterValue && currentFilterValue.length > 0) && (
            <Box ml="8px" className="widget-table-header-action__filters-notice">
              {currentFilterValue.length}
            </Box>
          )}

          {displayFilter && (
            <Box
              className={clsx('widget-table-header-action__arrow', {
                'widget-table-header-action__arrow-up': openedActions,
              })}
            >
              <IconSvg
                svg={IconDictionary.SelectArrowDown}
                fill={openedActions ? 'var(--primary-color)' : 'var(--grey)'}
              />
            </Box>)}
        </ButtonBase>
      </Box>
      <CustomPopover
        open={openedActions}
        onClose={closeActions}
        anchorEl={anchorRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{
          marginTop: 5,
        }}
        backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
      >
        <Box className="widget-table-header-action__filters-wrap">
          <Box className="widget-table-header-action__filters">
            <HeaderActionFilters
              currentFilter={currentFilter}
              setFilter={setFilter}
              getAllFiltersFromAxis={getAllFiltersFromAxis}
              closeFilterPanel={closeActions}
            />
          </Box>
        </Box>
      </CustomPopover>
    </>
  );
};
