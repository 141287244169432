import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UpdateLoaderStatusContext } from '../providers/UpdateLoaderStatusProvider';
import { State } from '../slices/types';


export const useUpdateDataAfterChangeLoaderStatus = (label: string, callbackRefreshData: () => void) => {

  const projectLoadingId = useSelector(
    (state: State) => state.mainPage.currentProject?.loaderId || 0,
  );

  const { addLSSubscribe, deleteLSSubscribe } = useContext(UpdateLoaderStatusContext);

  useEffect(() => {
    addLSSubscribe({
      name: label,
      func: (loaderId) => {
        if (projectLoadingId === loaderId) {
          callbackRefreshData();
        }
      },
    });
    return () => {
      deleteLSSubscribe(label);
    };
  }, [addLSSubscribe, deleteLSSubscribe, label, projectLoadingId, callbackRefreshData]);
};
