import React, { useCallback } from 'react';
import {
  getBezierPath,
  getMarkerEnd,
  WrapEdgeProps,
} from 'react-flow-renderer';
import { setActiveJoinKeysListAction } from 'src/slices/map-view/map-view';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'src/slices/types';

export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
  data
}: WrapEdgeProps) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const dispatch = useDispatch();

  const edgesDataList = useSelector(
    (state: State) => state.mapView.edgesDataList,
  );

  const onClick = useCallback(() => {
    edgesDataList.forEach((edjesData) => {
      if ((edjesData.sourceId === data.sourceId || edjesData.sourceId === data.targetId) &&
        (edjesData.targetId === data.sourceId || edjesData.targetId === data.targetId)) {
        dispatch(setActiveJoinKeysListAction(edjesData.initialFieldsList));
      }
    });
  }, [edgesDataList, data, dispatch]);

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <path
        id={id}
        style={{ ...style }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        onClick={onClick}
        style={{ ...style, fill: 'none', strokeOpacity: 0, strokeWidth: 6 }}
        d={edgePath}
      />
    </>
  );
};
