import { useEffect } from 'react';
import { useAppDispatch } from 'src/slices';
import {
  clearProjectParametersAction,
  fetchDashboardParameters,
  fetchProjectParameters,
} from '../../../slices/parameters/parameters';

export const useParameters = (projectId?: number, dashboardId?: number) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId) dispatch(fetchProjectParameters(projectId));

    return () => {
      dispatch(clearProjectParametersAction());
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    if (dashboardId) dispatch(fetchDashboardParameters(dashboardId));

    return () => {
      dispatch(clearProjectParametersAction());
    };
  }, [dispatch, dashboardId]);
};
