import { CircularProgress } from '@material-ui/core';
import React from 'react';
import CardTopPanel from '../../../../common/card-top-panel';
import { DdFiltersPanel } from '../dd-filters-panel/dd-filters-panel';
import CardContent from '../../../../../uikit/CardContent';
import { NoDataTemplate } from '../../../../common/no-widget-data-template';
import WidgetContainer from '../../../../widget-page/charts/widget-container';
import Card from '../../../../../uikit/Card';
import { useWidgetCard } from '../../hooks/useWidgetCard';
import { FilterField, FilterForPanel } from '../../../hooks';
import { WidgetCardHeader } from './widget-card-header';
import WidgetTypes from '../../../../../types/widgets';
import {
  DashboardProperty,
  WidgetProperties,
} from '../../../../../slices/types';
import { CustomProgress } from '../../../../../uikit/Progress';

interface WidgetCardProps {
  widgetId: number;
  isStatic: boolean;
  isDrillDown: boolean;
  drilldownFilterField: FilterField;
  widgetDataObject: WidgetTypes.Data;
  widgetErrorText: string;
  drillDownDepth: number;
  permission: any;
  getFilters: (type: string) => any;
  isActiveDrilldownFilter: boolean;
  closeDrillDown: () => void;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  overriddenProperties: WidgetProperties[];
  setOverriddenProperties: (newProperties: WidgetProperties[]) => void;
  enhancedParams: {
    dashboardProperties: DashboardProperty[];
    widgetOverriddenProperties: WidgetProperties[];
  };
  overriddenPropertiesInherited?: WidgetProperties[];
  isMasterWidget: (widgetId: number) => boolean;
  handleClickBackDD: () => void;
  filtersForPanel: FilterForPanel[];
}

export const WidgetCard = ({
  widgetId,
  isStatic,
  isDrillDown,
  drilldownFilterField,
  widgetDataObject,
  widgetErrorText,
  drillDownDepth,
  permission,
  getFilters,
  isActiveDrilldownFilter,
  closeDrillDown,
  containerRef,
  overriddenProperties,
  setOverriddenProperties,
  enhancedParams,
  overriddenPropertiesInherited,
  isMasterWidget,
  handleClickBackDD,
  filtersForPanel,
}: WidgetCardProps) => {
  const {
    isLoading,
    widgetProps,
    projectId,
    hasPermissionForEditingWidget,
    dashboardProps,
    isEmptyData,
    setFilterField,
    filterField,
    isActiveFilter,
    setMultipleFilterFields,
    showFiltersForPanel,
  } = useWidgetCard({
    widgetId,
    permission,
    getFilters,
    isActiveDrilldownFilter,
  });

  return (
    <Card className="dashboard-grid-item__card">
      <>
        <WidgetCardHeader
          widgetId={widgetId}
          isStatic={isStatic}
          isDrillDown={isDrillDown}
          drillDownDepth={drillDownDepth}
          drilldownFilterField={drilldownFilterField}
          widgetDataObject={widgetDataObject}
          closeDrillDown={closeDrillDown}
          containerRef={containerRef}
          overriddenProperties={overriddenProperties}
          setOverriddenProperties={setOverriddenProperties}
          enhancedParams={enhancedParams}
          overriddenPropertiesInherited={overriddenPropertiesInherited}
          isMasterWidget={isMasterWidget}
          handleClickBackDD={handleClickBackDD}
          setFilterField={setFilterField}
          setMultipleFilterFields={setMultipleFilterFields}
          isActiveFilter={isActiveFilter}
        />
        <CardTopPanel
          widgetProperties={widgetProps?.properties}
          widgetDataProperties={widgetDataObject.properties}
          widgetType={widgetProps.type}
          widgetId={widgetProps.id || 0}
          setOverriddenProperties={setOverriddenProperties}
          filtersForPanel={filtersForPanel}
          setFilterField={setFilterField}
          isActiveFilter={isActiveFilter}
          showFiltersForPanel={showFiltersForPanel}
        />
        <CardContent widgetType={widgetProps.type}>
          {isEmptyData && !isLoading && (
            <NoDataTemplate
              withLinkToEditor={!isDrillDown && hasPermissionForEditingWidget}
              widgetId={widgetId}
              errorText={widgetErrorText}
            />
          )}
          {!isEmptyData && (
            <WidgetContainer
              widgetProps={widgetProps}
              widgetData={widgetDataObject}
              dashboardProps={dashboardProps}
              overriddenProperties={overriddenProperties}
              enhancedParams={enhancedParams}
              setOverriddenProperties={setOverriddenProperties}
              setFilterField={setFilterField}
              setMultipleFilterFields={setMultipleFilterFields}
              filterField={filterField}
              isActiveFilter={isActiveFilter}
              isDrillDown={isDrillDown}
              errorText={widgetErrorText}
            />
          )}
          {isLoading && (
            <div className="widget-main__loader--wrapper">
              <div className="dashboard-grid-item__loader">
                <CustomProgress type="circular" />
              </div>
            </div>
          )}
        </CardContent>
      </>
    </Card>
  );
};
