import { v4 as uuid } from 'uuid';
import { useMemo } from 'react';
import { apiDeleteRequests } from '../services';

type UseRequestCanceller = () => {
  callCancellable:<T extends Object, K extends Object>(request: (parameters: T)
  => Promise<any>, params: K, groupName?: string) => Promise<any>
  cancellableController: { cancelAll: () => void, cancelGroup: (groupName: string) => void };
};

type cancellableItem = {id: string, abortController: AbortController, groupName: string | null};
let cancellableList: cancellableItem[] = [];

export const useRequestCanceller: UseRequestCanceller = () => {
  const callCancellable = async (
    request: (params: any) => Promise<any>,
    params: object,
    groupName?: string
  ) => {
    const abortController = new AbortController();
    const id = uuid();

    cancellableList.push({ id, abortController, groupName: groupName || null });
    const extendedParams = { ...params, id, signal: abortController.signal };

    return request(extendedParams).finally(() => {
      cancellableList = cancellableList.filter((item) => item.id !== id);
    });
  };

  const cancelList = (cancellableList: cancellableItem[], idsToDelete: string[]) => {
    if (idsToDelete.length > 0) {
      apiDeleteRequests(idsToDelete);
      cancellableList.forEach((item) => {
        item.abortController.abort();
      });
    }
  };

  const cancellableController = useMemo(
    () => ({
      cancelAll: () => {
        const idsToDelete = cancellableList.map((item) => item.id);
        cancelList(cancellableList, idsToDelete);
        cancellableList = [];
      },

      cancelGroup: (groupName: string) => {
        const cancelledListGroup =
          cancellableList.filter((item) => item.groupName === groupName);
        const idsToDelete = cancelledListGroup.map((item) => item.id);
        cancelList(cancelledListGroup, idsToDelete);
        cancellableList = cancellableList.filter((item) => item.groupName !== groupName);
      }
    }),
    [],
  );

  return {
    callCancellable,
    cancellableController
  };
};
