import { SourceType } from '../../../enums/source-type';

export const idSourceType: Readonly<Record<number, SourceType>> = {
  3: SourceType.MSSQL,
  4: SourceType.MySQL,
  5: SourceType.OData,
  6: SourceType.PostgreSQL,
  7: SourceType.MariaDB,
  8: SourceType.Oracle,
  9: SourceType.RedData,
  10: SourceType.Firebird,
  11: SourceType.SOAP,
  14: SourceType.ODBC,
  15: SourceType.FTP,
  17: SourceType.API,
  18: SourceType.EMISS,
  21: SourceType.Kafka,
  22: SourceType.Redis,
  23: SourceType.LOCAL,
  34: SourceType.ClickHouse,
  35: SourceType.HDFS,
  38: SourceType.IMPALA,
  39: SourceType.HIVE,
  40: SourceType.Vertica,
  41: SourceType.ArangoDB,
  42: SourceType.SAP_HANA,
  43: SourceType.GOOGLE_SHEETS
};
