import React from 'react';
import {
  CustomPanelHead,
  CustomPanelHeadType,
} from '../../../../../uikit/PanelHead';
import { CustomPopover } from '../../../../../uikit/Popover/popover';
import { Widget } from '../../../../../slices/types';
import { useHiddenColumnsModal } from './useHiddenColumnsModal';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CustomButton } from '../../../../../uikit/Button';

export interface HiddenColumnsModalProps {
  anchorEl: Element | null;
  onClose: () => void;
  widget: Widget;
}

export const HiddenColumnsModal = ({
  anchorEl,
  onClose,
  widget,
}: HiddenColumnsModalProps) => {
  const {
    handleClickSave,
    handleClickChange,
    columnsList,
    hiddenColumns,
    handleClickChangeAll,
    isDisabledButton,
    handleClickClose,
  } = useHiddenColumnsModal({ widget, onClose });

  return (
    <CustomPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClickClose}
      anchorEl={anchorEl}
    >
      <div className="hidden-columns">
        <CustomPanelHead
          onClose={handleClickClose}
          type={CustomPanelHeadType.popup}
          title="Отображение столбцов"
        />
        <div className="hidden-columns__body">
          <div className="hidden-columns__all-block">
            <CustomFormControl
              control={
                <CustomCheckbox
                  checked={!hiddenColumns.length}
                  onChange={handleClickChangeAll}
                />
              }
              label="Выбрать все"
            />
          </div>
          <div className="hidden-columns__list">
            {columnsList.map((column) => (
              <CustomFormControl
                key={column.name}
                control={
                  <CustomCheckbox
                    checked={!hiddenColumns.includes(column.id)}
                    onChange={() => handleClickChange(column.id)}
                  />
                }
                label={column.name}
              />
            ))}
          </div>
          <CustomButton
            onClick={handleClickSave}
            variant="contained"
            fullWidth
            disabled={isDisabledButton}
          >
            Применить
          </CustomButton>
        </div>
      </div>
    </CustomPopover>
  );
};
