import React from 'react';
import { CssVariables } from '../../../../../enums/css-variables';
import { Placement } from '../../../../../enums/visual-types';
import { backgroundColors, varcss } from '../../../../../helpers/ui-helper';
import SelectParameter from '../../../../common/special-inputs/select-parameter/select-parameter';
import { CustomAutocomplete } from '../../../../../uikit/Autocomplete';
import { useSelectField } from './useSelectField';

export type SelectFieldOption = {
  value: string | Symbol | number;
  label: string;
};

export interface SelectFieldProps {
  onChange: (options: (string | Symbol | number)[]) => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOpen?: () => void;
  isLoading: boolean;
  values: (string | Symbol | number)[];
  options: SelectFieldOption[];
  optionsLimit?: number;
  isNeedSelectAll?: boolean;
  isUseParameters?: boolean;
  isNotFilterOptions?: boolean;
  placement?: Placement;
  isSingleSelection?: boolean;
  label?: string;
  hideClearButton?: boolean;
}

export const SelectField = ({
  onChange,
  onOpen,
  onInput,
  isLoading,
  values,
  options,
  optionsLimit = 200,
  isNeedSelectAll = true,
  isUseParameters = true,
  isNotFilterOptions,
  placement = Placement.Default,
  isSingleSelection = false,
  label = 'Поиск',
  hideClearButton = false,
}: SelectFieldProps) => {

  const {
    open,
    handleChange,
    handleOpen,
    handleClose,
    filterOptions,
    inputValue,
    defaultOptions,
    isParameterPopperOpen,
    parameterOption,
    anchorEl,
    parametersList,
    handleParameterSelect,
    handleParameterClose,
    renderOption,
    renderInput,
  } = useSelectField({
    onChange,
    onOpen,
    onInput,
    isLoading,
    values,
    options,
    optionsLimit,
    isNeedSelectAll,
    isUseParameters,
    isNotFilterOptions,
    isSingleSelection,
    label,
  });

  const backgroundColor = backgroundColors[placement];

  const value: any = isSingleSelection ? defaultOptions[0] : [...defaultOptions];

  return (
    <>
      <CustomAutocomplete
        open={open}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        options={options}
        filterOptions={filterOptions}
        getOptionDisabled={() => isLoading}
        inputValue={inputValue}
        value={value}
        getOptionLabel={(option) => option?.label}
        renderOption={renderOption}
        renderInput={renderInput}
        className="filter-select-field"
        backgroundColor={backgroundColor}
        multiple={!isSingleSelection}
        getOptionSelected={(option, value) => option?.value === value?.value}
        hideClearButton={hideClearButton}
      />
      {isParameterPopperOpen && parameterOption.length > 0 && (
        <SelectParameter
          anchorEl={anchorEl.current}
          parameterList={parametersList}
          onSelect={handleParameterSelect}
          onClose={handleParameterClose}
        />
      )}
    </>
  );
};
