import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import { styled } from '@material-ui/core';
import { ProjectParameter } from '../../../../slices/types';
import { getRootElement } from '../../../../utils/root-helper';
import { TextInput, TextInputProps } from '../inputs/text-input';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { findDiffChar } from './helper';

interface ItemListProps {
  key: string | number;
  value: string;
  aggregation: any;
  id: string | number;
}

const StyledPopover = styled(Popover)({
  '& .MuiPaper-root': {
    width: '260px',
    overflow: 'hidden',
  },

  '& .MuiList-root': {
    height: '230px',
    overflowY: 'auto',
    marginRight: '5px'
  },
  '& .MuiListItem-root': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block'
  },
  '& .MuiInputBase-root': {
    borderTop: 'none !important',
    borderLeft: 'none',
    borderRight: 'none',
  }
});

interface InputContainerPopoverItem {
  key: string | number;
  value: string;
}

interface InputContainerPopoverProps {
  anchorEl: HTMLInputElement | null;
  handleClose: () => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  itemList: InputContainerPopoverItem[];
  handleChangeItem: (item: any) => void;
}

export const InputContainerPopover: React.FC<InputContainerPopoverProps> = ({
  anchorEl,
  handleClose,
  handleSearchChange,
  itemList,
  handleChangeItem,
}) => {

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const listLength = itemList.length || 0;

  // Обработчик событий клавиатуры
  const handleKeyboardNavigation = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      setSelectedItemIndex((prevIndex) =>
        Math.min(prevIndex + 1, listLength - 1)
      );
    } else if (event.key === 'ArrowUp') {
      setSelectedItemIndex((prevIndex) =>
        Math.max(prevIndex - 1, 0)
      );
    } else if (event.key === 'Enter' && itemList?.length) {
      handleChangeItem(itemList[selectedItemIndex]);
    }
  }, [itemList, handleChangeItem, listLength, selectedItemIndex]);

  useEffect(() => {
    getRootElement().addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      getRootElement().removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [handleKeyboardNavigation]);

  return (
    <StyledPopover
      anchorEl={anchorEl}
      open
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <TextInput
        label="Поиск по полям"
        handleChange={handleSearchChange}
        searchIcon
        fullWidth={true}
        inputRef={(input: any) => input && input.focus()}
      />
      <Paper>
        <List>
          {itemList.map((item: InputContainerPopoverItem, index) => {
            return (
              <CustomTooltip title={item.value} key={item.key}>
                <ListItem
                  button
                  selected={index === selectedItemIndex}
                  onClick={() => handleChangeItem(item)}
                  onMouseOver={() => setSelectedItemIndex(index)}
                >
                  {item.value}
                </ListItem>
              </CustomTooltip>
            );
          })}
        </List>
      </Paper>
    </StyledPopover>
  );
};

interface InputContainerProps {
  textInputProps: TextInputProps;
  itemsList: ItemListProps[];
  parameterList?: ProjectParameter[];
  value: string;
  changeValue: (value: string) => void;
  regExes?: { regEx: RegExp; replaceValue: any }[];
  className?: string;
  isMaxRowOverflow?: boolean
}

export const InputContainer: React.FC<InputContainerProps> = ({
  textInputProps,
  itemsList,
  parameterList,
  value,
  changeValue,
  regExes,
  className,
  isMaxRowOverflow = true
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const [isOpenPopoverParameter, setIsOpenPopoverParameter] = useState<boolean>(false);
  const [filteredItemsList, setFilteredItemsList] = useState<ItemListProps[] | null>(null);

  const [indexParamSymbol, setIndexParamSymbol] = useState(-1);


  useEffect(() => {
    setFilteredItemsList(itemsList);
  }, [itemsList]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: string = event.target.value;
    const isSpecialSymbol = newValue.indexOf('@') !== -1;

    const indexDiffChar = findDiffChar(value, newValue);
    const isSpecialSymbolParameter = newValue[indexDiffChar] === '#';

    if (isSpecialSymbol) {
      setIsOpenPopover(true);
      setAnchorEl(event.currentTarget);
    } else if (isOpenPopover) {
      setIsOpenPopover(false);
      setAnchorEl(null);
    }

    if (isSpecialSymbolParameter) {
      setIsOpenPopoverParameter(true);
      setIndexParamSymbol(indexDiffChar);
      setAnchorEl(event.currentTarget);
    } else if (isOpenPopoverParameter) {
      setIsOpenPopoverParameter(false);
      setAnchorEl(null);
    }

    regExes &&
      regExes.forEach((item) => {
        newValue = newValue.replace(item.regEx, item.replaceValue);
      });

    changeValue(newValue);
  };

  const handleChangeItem = (item: any) => {

    const measure: any = itemsList.find((measure) => measure.key === item.key);

    setIsOpenPopover(false);
    setFilteredItemsList(itemsList);

    const getCorrectValueWithAggregation = (value: string) => {
      if (measure.aggregation === 'COUNT(DISTINCT %s)') {
        return `COUNT(DISTINCT [${value}])`;
      }

      return `${measure.aggregation.split('(')[0]}([${value}])`;
    };

    let newValue = value.replace(
      '@',
      !measure.ignoreAggregation && measure.aggregation
        ? getCorrectValueWithAggregation(measure.value)
        : `[${measure.value}]`,
    );

    regExes &&
      regExes.forEach((item) => {
        newValue = newValue.replace(item.regEx, item.replaceValue);
      });

    changeValue(newValue);
  };

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const searchResult = itemsList.filter((item) =>
        item.value.toLowerCase().includes(value.toLowerCase()),
      );

      setFilteredItemsList(searchResult);
    },
    [setFilteredItemsList, itemsList],
  );

  const handleClose = () => {
    setIsOpenPopover(false);
    setAnchorEl(null);

    const newValue = value.replace('@', '');
    changeValue(newValue);
  };


  const handleChangeItemParameter = (item: InputContainerPopoverItem) => {

    setIsOpenPopoverParameter(false);
    setAnchorEl(null);

    const parameter = parameterList?.find((param) => param.parameterId === item.key);

    if (parameter && indexParamSymbol > -1) {
      const newValue = `${value.substring(0, indexParamSymbol)}#${parameter.parameterCode}${value.substring(indexParamSymbol + 1)}`;
      setIndexParamSymbol(-1);
      changeValue(newValue);
    }
  };

  const handleCloseParameter = () => {
    setIsOpenPopoverParameter(false);
    setAnchorEl(null);
    setIndexParamSymbol(-1);
  };

  const measureItemList: InputContainerPopoverItem[] =
    filteredItemsList?.map((item) => ({ key: item.key, value: item.value })) ||
    [];

  const parameterItemList: InputContainerPopoverItem[] =
    parameterList?.map((param) => ({ key: param.parameterId, value: param.parameterCode })) ||
    [];

  return (
    <div className={className}>
      <TextInput
        className={className}
        multiline
        minRows={4}
        maxRows={isMaxRowOverflow ? 6 : 999}
        {...textInputProps}
        handleChange={handleChange}
        value={value}
      />

      {isOpenPopover && (
        <InputContainerPopover
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSearchChange={handleSearchChange}
          itemList={measureItemList}
          handleChangeItem={handleChangeItem}
        />)}

      {parameterList && isOpenPopoverParameter && (
        <InputContainerPopover
          anchorEl={anchorEl}
          handleClose={handleCloseParameter}
          handleSearchChange={handleSearchChange}
          itemList={parameterItemList}
          handleChangeItem={handleChangeItemParameter}
        />)}
    </div>
  );
};
