import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SourceReplaceModalProps } from './index';
import { State } from '../../../slices/types';
import {
  apiGetMapConnection,
  apiGetSingleSources,
} from '../../../services/loadersController';
import { apiReplaceSource } from '../../../services/widgetController';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { updatePushdownOfSourceObjectsChain } from '../../../helpers/loader-page';

interface SourceReplaceValues {
  oldSource: string;
  newSource: string;
}

enum DefaultError {
  save = 'Изменения не были сохранены.',
  getSources = 'На карте отсутствуют источники, на которые можно заменить текущий.',
  getMap = 'Не удалось получить обновленную карту.',
  empty = '',
}

export const useSourceReplaceModal = ({
  source,
  handleClose,
}: SourceReplaceModalProps) => {
  const dispatch = useDispatch();

  const loaderId =
    useSelector((state: State) => state.mainPage?.currentProject?.loaderId) ||
    0;

  const [sources, setSources] = useState<{ id: string; name: string; sourceId: number }[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    apiGetSingleSources(loaderId, source.id)
      .then((res) => setSources(res))
      .catch((error) => {
        setError(error.response?.data?.message || DefaultError.getSources);
      });
  }, [loaderId, source.id]);

  const handleSave = (values: SourceReplaceValues) => {
    setIsLoading(true);

    apiReplaceSource(source.id, values.newSource).then(() => {
      return apiGetMapConnection(loaderId);
    }).catch((error) => {
      setError(error.response?.data?.message || DefaultError.save);
    }).then((data) => {
      if (data) {
        dispatch(mapConnectionAction.addConnection({ loaderId, data }));
        return updatePushdownOfSourceObjectsChain(data, source.id);
      }
    }).catch((error) => {
      setError(error.response?.data?.message || DefaultError.getMap);
    }).then(() => {
      handleClose(true);
    }).catch((error) => {
      setError(error.response?.data?.message || DefaultError.empty);
    }).finally(() => setIsLoading(false));
  };

  const { values, handleChange, handleSubmit, resetForm } =
    useFormik<SourceReplaceValues>({
      initialValues: { oldSource: source.name || '', newSource: '' },
      onSubmit: handleSave,
    });

  const sourcesList = sources.map((source) => {
    return { value: source.id, showValue: source.name };
  });

  const isDisabledSave = values.newSource.length === 0 || isLoading;

  const handleCloseModal = () => {
    resetForm();
    setError(null);
    handleClose();
  };

  const currentSourceId = sources.find((item) => item.id === values.newSource)?.sourceId;

  return {
    values,
    handleChange,
    handleSubmit,
    sourcesList,
    error,
    isDisabledSave,
    handleCloseModal,
    isLoading,
    currentSourceId,
  };
};
