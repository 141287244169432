import React from 'react';
import CustomTooltip from '../../../../../../uikit/CustomTooltip';
import { useTableTooltip } from './useTableTooltip';

type TableTooltipProps = {
  value: string;
  style?: React.CSSProperties
};

export const TableTooltip = ({ value, style }: TableTooltipProps) => {
  const {
    isDisplayTooltip,
    classes
  } = useTableTooltip();

  return (
    <CustomTooltip
      title={value}
      interactive
      disableHoverListener={!isDisplayTooltip}
    >
      <div style={style} className={classes}>
        {value}
      </div>
    </CustomTooltip>
  );
};
