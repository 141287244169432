import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { State } from '../../../../slices/types';
import { WidgetType } from '../../../../enums/widget-type';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { PropertyData } from '../../dropdown-layout/helpers/Property';
import { getPanelsForSourcesCheck } from '../../helpers';

const WidgetsWithMultipleSources = [WidgetType.MAP];

export const useDefinePushdownActive = () => {
  const widget = useSelector((state: State) => state.widget);
  const currentProjectWidgetSources =
    useSelector((state: State) => state.mainPage.currentProjectWidgetSources) ||
    [];

  const defineSourcesWithActivePushDownForMultipleSources = useCallback(() => {
    const panels = getPanelsForSourcesCheck(widget.type);
    let isActivePushDown = true;

    panels.forEach((panelType) => {
      const panelValues: PropertyData[] = getParsedAxisValues(
        panelType,
        widget.properties,
      );
      panelValues.forEach((value) => {
        const source = currentProjectWidgetSources.find(
          (source) => source.id === value.etlSourceId,
        );
        if (source) {
          isActivePushDown = isActivePushDown && source?.needPushDown;
        }
      });
    });

    return isActivePushDown;
  }, [currentProjectWidgetSources, widget.properties, widget.type]);

  const defineSourcesWithActivePushDown = useCallback(() => {
    if (!currentProjectWidgetSources.length) return false;

    if (WidgetsWithMultipleSources.includes(widget.type)) {
      return defineSourcesWithActivePushDownForMultipleSources();
    }

    return currentProjectWidgetSources[0]?.needPushDown;
  }, [
    currentProjectWidgetSources,
    defineSourcesWithActivePushDownForMultipleSources,
    widget.type,
  ]);

  const isSourcesWithActivePushDown = useMemo(
    () => defineSourcesWithActivePushDown(),
    [defineSourcesWithActivePushDown],
  );

  return isSourcesWithActivePushDown;
};
