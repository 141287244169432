import React from 'react';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { State } from '../../../slices/types';
import { getErrorImage } from '../helper';
import { LocalStorage } from '../../../enums/local-storage-type';

export const ImageBlock = () => {
  const status = useSelector((state: State) => state.errorPage.code) || '';

  return (
    <div>
      <SVGInline svg={getErrorImage(status)} width="40vw" height="60vh" />
    </div>
  );
};
