import React from 'react';
import { NavLink } from 'react-router-dom';
import IconSvg from '../../../common/icon-svg/icon-svg';
import WidgetCarouselItem from './widget-carousel-item';
import { widgetIconNames } from '../../../dashboard-page/widgets-list';
import { useWidgetContent } from '../hooks/useWidgetsContent';
import { WidgetMenu } from './widget-menu';
import { carouselItemFontSize, getShowingTooltipState } from '../helper';
import { CustomButton } from '../../../../uikit/Button';
import {
  CommonDictionary,
  WidgetDictionary,
} from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface WidgetsContentProps {
  changeModal: (
    type: string | undefined,
    actionType: string,
    name: string,
    id: number | undefined,
  ) => void;
  carouselItemMaxWidth: number;
  classes: any;
}

export const WidgetsContent = ({
  changeModal,
  carouselItemMaxWidth,
  classes,
}: WidgetsContentProps) => {
  const {
    widgetRef,
    currentWidget,
    widgetsList,
    toggleWidgetList,
    isWidgetPopperOpen,
    params,
    projectId,
    onNewWidgetAdd,
    handleWidgetsMenuClose,
    widgetAnchor,
    currentGroupWidgets,
  } = useWidgetContent();

  return (
    <div className="widget-carousel__container">
      <div className="widget-carousel__dropdown" ref={widgetRef}>
        {currentWidget && (widgetsList || []).length > 1 && (
          <button
            type="button"
            aria-describedby="widget"
            onClick={toggleWidgetList}
            className="widget-footer__button"
          >
            <IconSvg
              svg={IconDictionary.Card}
              fill="var(--dark-grey)"
            />
            <span className="widget-footer__button-text">
              {WidgetDictionary.titleMany}
            </span>
            <IconSvg
              svg={
                isWidgetPopperOpen
                  ? IconDictionary.SelectArrowUp
                  : IconDictionary.SelectArrowDown
              }
              width={22}
              height={22}
              fill={
                isWidgetPopperOpen ? 'var(--primary-color)' : 'var(--dark-grey)'
              }
              classSVG="widget-carousel-arrow"
            />
          </button>
        )}

        <div className="widget-carousel-item__inline_flex">
          {currentWidget ? (
            <WidgetCarouselItem
              changeModal={changeModal}
              isActive={String(currentWidget.id) === params.widgetId}
              type="widget"
              id={currentWidget.id}
              name={currentWidget.name}
              iconName={
                widgetIconNames[
                  currentWidget.type as keyof typeof widgetIconNames
                ]
              }
              isCurrent={true}
              disableHoverTooltipListener={getShowingTooltipState(
                currentWidget?.name || '',
                carouselItemFontSize,
                carouselItemMaxWidth,
              )}
            />
          ) : (
            <div className="widget-carousel__dropdown-no-data">
              {!widgetsList ? (
                <span>
                  <CustomProgress type="circular" size={20} />
                </span>
              ) : (
                <>
                  <IconSvg
                    svg={IconDictionary.NoWidgets}
                    fill="var(--dark-grey)"
                  />
                  <span>
                    В {CommonDictionary.projectPrepositional} не создано ни{' '}
                    {WidgetDictionary.countWidgets}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {widgetsList && (
        <NavLink to={`/project/${projectId}/widget`}>
          <CustomButton
            variant="outlined"
            icon={
              <IconSvg
                svg={IconDictionary.CardAdd}
                fill="var(--primary-color)"
              />
            }
            onClick={onNewWidgetAdd}
            className="widget-footer__button_icon"
          >
            {currentWidget ? '' : WidgetDictionary.addWidgets}
          </CustomButton>
        </NavLink>
      )}
      {isWidgetPopperOpen && (
        <WidgetMenu
          classes={classes}
          carouselItemMaxWidth={carouselItemMaxWidth}
          changeModal={changeModal}
          currentGroupWidgets={currentGroupWidgets}
          widgetAnchor={widgetAnchor}
          widgetRef={widgetRef}
          handleWidgetsMenuClose={handleWidgetsMenuClose}
        />
      )}
    </div>
  );
};
