/* eslint-disable react/jsx-key */
import {
  TableCell,
  TableHead as MUITableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useContext, useMemo } from 'react';
import { getScaleCoefficient } from '../../../../../../helpers/ui-helper';
import { DndBackdrop } from '../dnd-backdrop';
import { HeaderAction } from '../header-action';
import { useNestedHeaders } from '../../libs/hooks/useNestedHeaders';
import { DraggableColumn } from '../table-draggable-column';
import { DroppableHeader } from '../table-droppable-header';
import {
  getBooleanPropertyValue,
  getInputPropertyValue,
  getSelectPropertyValue,
  isDashboardPage,
} from '../../../helpers';
import '../table-styles.css';
import { ComplexTableContext } from '../../index';
import {
  MultilineTextVariant,
  getStringCountByMultilineTextVariant,
} from '../../helper';

export enum DEFAULT_COMPLEX_TABLE_TITLE {
  backgroundColor = '#f2f6fb',
  textColor = 'var(--grey)',
  activeColor = 'var(--primary-color)',
}

type ViewStyle = {
  fontSize: number;
  textColor: string;
  backgroundColor: string;
};

export const TableHead: FC<any> = ({
  tableInstance,
  isNeedToDisplayHierarchyInHeader,
}) => {
  const { headerGroups } = tableInstance;

  const isDashboard = isDashboardPage();

  const {
    isEnabledResizing,
    axisXValues,
    headBackgroundColor,
    headTextColor,
    widgetProperties,
    widgetState,
    hiddenColumns,
  } = useContext(ComplexTableContext);
  const {
    isDraggableColumn,
    isDroppableColumn,
    isCurrentTarget,
    isCurrentDestination,
    isPlaceholderColumn,
    getActualColumnId,
  } = useNestedHeaders();

  const cellRenderer = (column: any) => {
    const classes = clsx('widget-table-cell__head-value', {
      'widget-table-cell__head-value_two':
        showMultilineText === MultilineTextVariant.twoString,
      'widget-table-cell__head-value_three':
        showMultilineText === MultilineTextVariant.threeString,
      'widget-table-cell__head-value-container': !column.accessor,
    });

    return column.accessor ? (
      <HeaderAction column={column} classes={classes} />
    ) : (
      <div className={classes}>{column.render('Header', { classes })} </div>
    );
  };

  const columnNumberCellRenderer = (columnIndex: number, storage: any) => {
    const classes = clsx('widget-table-cell__head-value', {
      'widget-table-cell__head-value_two':
        showMultilineText === MultilineTextVariant.twoString,
      'widget-table-cell__head-value_three':
        showMultilineText === MultilineTextVariant.threeString,
    });

    return (
      <div
        className="widget-table-header-action__wrap"
        style={{
          justifyContent: `${
            storage.cellJustifyContent ? storage.cellJustifyContent : 'center'
          }`,
        }}
      >
        <div className={classes}>{columnIndex}</div>
      </div>
    );
  };

  const resizer = (column: any) => (
    <div
      {...column.getResizerProps()}
      className={clsx('widget-table__resizer', {
        'widget-table__resizing': column.isResizing,
      })}
    />
  );

  const getHeaderOffset = (): number => {
    const tableBody = document.getElementsByClassName('table-body-list')[0];
    if (!tableBody) return 0;

    const scrollOffset = 5;

    return tableBody.scrollHeight > tableBody.clientHeight ? scrollOffset : 0;
  };

  const showMultilineText: MultilineTextVariant = useMemo(
    () =>
      getSelectPropertyValue(
        'showMultilineText',
        widgetProperties,
      ) as MultilineTextVariant,
    [widgetProperties],
  );

  const showColumnNumber = useMemo(
    () => getBooleanPropertyValue('columnNumber', widgetProperties),
    [widgetProperties],
  );

  const headerSize: number = useMemo(
    () =>
      Number(getInputPropertyValue('tableHeaderSize', widgetProperties) || 14),
    [widgetProperties],
  );

  const scaleCoefficient = getScaleCoefficient();

  const rowHeight: number = useMemo(() => {
    const headerSizeScaled = headerSize * scaleCoefficient;
    const defaultRowHeight = 34;
    const initialFontSize = 14 * scaleCoefficient;
    const stringsCount =
      getStringCountByMultilineTextVariant(showMultilineText);
    const heightPerPixelOffset = 3;

    return (
      defaultRowHeight +
      (headerSizeScaled * stringsCount +
        (headerSizeScaled / initialFontSize) *
          heightPerPixelOffset *
          stringsCount)
    );
  }, [showMultilineText, headerSize, scaleCoefficient]);

  const getRenderer = (column: any, isMainHeader: boolean, view: ViewStyle) => {
    const style = {
      ...column.style,
      color: view.textColor,
      fontSize: view.fontSize,
    };

    if (isMainHeader) {
      style.background = view.backgroundColor;
    }

    return (
      <div className="widget-table__table-header-cell-content" style={style}>
        {cellRenderer(column)}
      </div>
    );
  };

  const getFlatColumnList = (groupsList: any) => {
    const columnsList = groupsList[groupsList.length - 1];

    return columnsList.headers;
  };

  const renderTableColumnsNumber = () => {
    const columns = getFlatColumnList(headerGroups);

    const filteredColumns = columns.filter((column: any) => {
      const actualId = getActualColumnId(column);

      if (!hiddenColumns.includes(actualId)) return column;
    }, []);

    return (
      <TableRow
        style={{
          height: rowHeight,
          marginRight: getHeaderOffset(),
          display: 'flex',
          minWidth: '250px',
        }}
        className="widget-table__row widget-table__header-row"
      >
        {filteredColumns.map((column: any, index: number) => {
          const currentPropertyIndex = axisXValues.findIndex(
            (value: any) => `${value.clientUID}` === `${column.id}`,
          );

          const actualId = getActualColumnId(column);

          if (hiddenColumns.includes(actualId)) return null;

          const currentProperty = axisXValues[currentPropertyIndex];

          const storage = JSON.parse(currentProperty?.storage ?? '{}');

          const style = {
            ...column.style,
            color:
              storage?.titleTextColor ||
              headTextColor ||
              DEFAULT_COMPLEX_TABLE_TITLE.textColor,
            fontSize: headerSize * scaleCoefficient,
            backgroundColor:
              storage?.titleBackgroundColor ||
              headBackgroundColor ||
              DEFAULT_COMPLEX_TABLE_TITLE.backgroundColor,
          };

          return (
            <TableCell
              {...column.getHeaderProps([
                {
                  className: clsx(column.className, {
                    'widget-table__cell': true,
                    'widget-table__header-cell': true,
                    'widget-table__header-cell-column-number': true,
                  }),
                },
              ])}
              key={column.id}
            >
              <div
                className="widget-table__table-header-cell-content"
                style={style}
              >
                {columnNumberCellRenderer(index + 1, storage)}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <MUITableHead
      className="widget-table__table-head"
      id={`table-head-${widgetState.id}`}
    >
      {headerGroups.map((headerGroup: any, groupIndex: number) => {
        const isMainHeader = groupIndex === headerGroups.length - 1;
        return (
          (isNeedToDisplayHierarchyInHeader || isMainHeader) && (
            <TableRow
              {...headerGroup.getHeaderGroupProps([
                {
                  className: clsx(headerGroup.className, {
                    'widget-table__row': true,
                    'widget-table__header-row': true,
                  }),
                  style: {
                    height: rowHeight,
                    marginRight: getHeaderOffset(),
                  },
                },
              ])}
            >
              {headerGroup.headers.map((column: any) => {
                const actualId = getActualColumnId(column);

                if (hiddenColumns.includes(actualId)) return null;

                const currentPropertyIndex = axisXValues.findIndex(
                  (value: any) => `${value.clientUID}` === `${column.id}`,
                );

                const currentProperty = axisXValues[currentPropertyIndex];

                const storage = JSON.parse(currentProperty?.storage ?? '{}');

                const view: ViewStyle = {
                  fontSize: headerSize * scaleCoefficient,
                  textColor:
                    storage?.titleTextColor ||
                    headTextColor ||
                    DEFAULT_COMPLEX_TABLE_TITLE.textColor,
                  backgroundColor:
                    storage?.titleBackgroundColor ||
                    headBackgroundColor ||
                    DEFAULT_COMPLEX_TABLE_TITLE.backgroundColor,
                };

                return (
                  <TableCell
                    {...column.getHeaderProps([
                      {
                        className: clsx(column.className, {
                          'widget-table__cell': true,
                          'widget-table__header-cell': true,
                          'widget-table__resizing-cell':
                            column.isResizing && !isDashboard,
                        }),
                      },
                    ])}
                    key={column.id}
                  >
                    {isPlaceholderColumn(column) ? null : (
                      <>
                        <DroppableHeader
                          id={actualId}
                          disabled={isDashboard || !isDroppableColumn(column)}
                        >
                          <DraggableColumn
                            id={actualId}
                            disabled={isDashboard || !isDraggableColumn(column)}
                          >
                            {getRenderer(column, isMainHeader, view)}
                          </DraggableColumn>
                        </DroppableHeader>
                        {isEnabledResizing && !isDashboard && resizer(column)}
                        <DndBackdrop
                          isTarget={isCurrentTarget(actualId)}
                          isDestination={isCurrentDestination(actualId)}
                        />
                      </>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          )
        );
      })}

      {showColumnNumber && renderTableColumnsNumber()}
    </MUITableHead>
  );
};
