import React from 'react';
import ReactFlow, { Controls, useStoreState, useZoomPanHelper } from 'react-flow-renderer';
import { Elements } from 'react-flow-renderer/dist/types';
import { useSelector } from 'react-redux';
import SourceNode from './custom-elements/source-node';
import { CustomEdge } from './custom-elements/custom-edje';
import useStyles from '../map/render-map-styles';
import { State } from '../../../slices/types';
import { CustomButtonGroup } from '../../../uikit/ButtonsGroup';
import { CustomButton } from '../../../uikit/Button';
import IconSvg from '../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface Props {
  dataForElements: Elements;
  onDragStopCallback: (nodesState: any, el: any) => void;
}
let nodesState: any;


const RenderMap: React.FC<Props> = ({
  dataForElements,
  onDragStopCallback,
}) => {
  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );
  const StateChecker = () => {
    const nodes = useStoreState((state) => state.nodes);
    nodesState = nodes;
    return null;
  };

  const classes = useStyles();

  const { zoomIn, zoomOut, fitView } = useZoomPanHelper();

  return (
    <ReactFlow
      className={classes['react-flow']}
      elements={dataForElements}
      nodeTypes={{
        source: SourceNode as any,
      }}
      nodesDraggable={isEditableMap}
      nodesConnectable={isEditableMap}
      elementsSelectable={true}
      edgeTypes={{
        custom: CustomEdge as any,
      }}
      onNodeDragStop={(event, node) => {
        onDragStopCallback(nodesState, node);
      }}
      defaultZoom={1}
      minZoom={0.5}
      maxZoom={1.25}
    >
      <StateChecker />
      <Controls showInteractive={false} showZoom={false} showFitView={false}>
        <CustomButtonGroup orientation="vertical" size="large">
          <CustomButton
            onClick={zoomIn as any}
            icon={<IconSvg svg={IconDictionary.MapZoomIn} fill="var(--dark-grey)" />}
            variant="text"
          />
          <CustomButton
            onClick={zoomOut as any}
            icon={<IconSvg svg={IconDictionary.MapZoomOut} fill="var(--dark-grey)" />}
            variant="text"
          />
          <CustomButton
            onClick={fitView as any}
            icon={<IconSvg svg={IconDictionary.FitView} fill="var(--dark-grey)" />}
            variant="text"
          />
        </CustomButtonGroup>
      </Controls>
    </ReactFlow>
  );
};

export default RenderMap;
