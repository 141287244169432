import React from 'react';
import './loader-menu.css';
import { CustomFormControl } from '../../../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../../../uikit/Checkbox';

export type SourceOption = {
  value: boolean;
  handler: () => void;
  disabled: boolean;
  label: string;
};

interface LoaderMenuProps {
  sourceOptions: SourceOption[];
}

const LoaderMenu = ({
  sourceOptions,
}: LoaderMenuProps) => {
  return (
    <div className="loader-menu">
      {sourceOptions.map((option) => (
        <CustomFormControl
          key={option.label}
          control={
            <CustomCheckbox
              disabled={option.disabled}
              onChange={option.handler}
              checked={option.value}
            />
          }
          label={option.label}
          disabled={option.disabled}
        />
      ))}
    </div>
  );
};

export default LoaderMenu;
