import React, { useEffect, useMemo, useRef, useState } from 'react';
import { compact } from 'lodash';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getScaleCoefficient } from '../../../../helpers/ui-helper';
import { apiGetWidgetById } from '../../../../services/widgetController';
import { setDashboardPropAction, setWidgetAPIToDashboard } from '../../../../slices/dashboard/dashboard';
import { useDashboardManualFilters } from '../../hooks/useDashboardManualFilters';
import { State, Widget } from '../../../../slices/types';
import { ParticipantFilterWidget } from '../../hooks';
import { getAxisValues } from '../../../widget-page/dropdown-layout/helpers/helpers';
import { PanelType, WidgetType } from '../../../../enums/widget-type';
import { BoundType } from '../enums';
import { CustomCheckbox } from '../../../../uikit/Checkbox';
import { CustomRadio } from '../../../../uikit/Radio/Radio';
import { ConnectionGroupProps } from './connectionGroup';

export const EXCLUDE_DRILLDOWN_WIDGET_TYPES = [
  WidgetType.SPEEDOMETER,
  WidgetType.INFORMATION_CARD,
  WidgetType.HTML,
  WidgetType.SUNBURST,
];

const NOT_MASTER_AUTO_DD_AND_BOUND_WIDGET_TYPES = [
  ...EXCLUDE_DRILLDOWN_WIDGET_TYPES,
  WidgetType.MAP,
];

const NOT_MASTER_MANUAL_DD_WIDGET_TYPES = [
  ...NOT_MASTER_AUTO_DD_AND_BOUND_WIDGET_TYPES,
  WidgetType.TABLE,
];

const HEIGHT_ITEM_LIST = 38;
const MAX_LIST_ITEMS_COUNT = 5;

export const useConnectionGroup = ({
  widgets,
  freeWidgets,
  drillDownGroup,
  onChangeMasterWidget,
  allDrillDownWidgetsIds,
  boundType,
  inactiveWidgetsForDD = [],
}: ConnectionGroupProps) => {
  const dispatch = useDispatch();

  const isLoadedGroup = Boolean(drillDownGroup.participantFilterWidgets.length);

  const { widgetId: masterWidgetId, sourceId } =
  drillDownGroup.participantFilterWidgets?.find(
    (participant) => !!participant?.isMaster,
  ) || {};

  const searchRef = useRef<any>(null);

  const { getAllConnectedWidget, widgetsIds } =
    useDashboardManualFilters(boundType, !masterWidgetId);

  const allConnectedWidgets = useMemo(() => getAllConnectedWidget(), [getAllConnectedWidget]);

  const connectedWidgetNotOnDashboard = useMemo(() => allConnectedWidgets.filter(
    (participant: ParticipantFilterWidget) =>
      !widgetsIds.includes(participant.widgetId),
  ), [allConnectedWidgets, widgetsIds]);

  // Догружаем виджеты, от которых можно строить связи, но которых нет на дашборде.
  const [isLoadingWidgets, setLoadingWidgets] = useState(false);
  const [isFetchedConnectedWidgetsNotInDashboard, setFetchedConnectedWidgetsNotInDashboard] = useState(false);

  const [mappedConnectedWidgetsNotOnDashboard, setMappedConnectedWidgetsNotOnDashboard] =
    useState<Widget[]>([]);

  useEffect(() => {
    if ((masterWidgetId || connectedWidgetNotOnDashboard.length === 0)
      && isFetchedConnectedWidgetsNotInDashboard) return;
    setFetchedConnectedWidgetsNotInDashboard(true);
    const requests = connectedWidgetNotOnDashboard
      .map((widget) => {
        return apiGetWidgetById(widget.widgetId);
      });

    setLoadingWidgets(true);
    Promise.all(requests).then((result: Widget[]) => {
      setLoadingWidgets(false);
      let filteredResult = result;
      // hack for initial render, when it doesn't have self option as masterWidget value
      if (!isFetchedConnectedWidgetsNotInDashboard) {
        filteredResult = result.filter(item => item.id === masterWidgetId);
      }

      setMappedConnectedWidgetsNotOnDashboard(filteredResult);
    });
  }, [connectedWidgetNotOnDashboard, dispatch, masterWidgetId, isFetchedConnectedWidgetsNotInDashboard]);

  const enabledWidgetsIds = freeWidgets.map(({ id }) => id);

  // в случае карт в filterId храним id поля с оси
  const masterWidgetFilterId = drillDownGroup.participantFilterWidgets?.find(
    (participant: any) => !!participant?.isMaster,
  )?.filterId;

  // В виджете Карта может быть несколько слоев, по-этому для него собираем отдельно
  const potentialMasterMapWidgets = [
    ...widgets,
    ...mappedConnectedWidgetsNotOnDashboard,
  ]
    .filter(({ type }) => type === 'MAP')
    .map((widget: any) => {
      const axisXs = JSON.parse(
        getAxisValues(PanelType.axisX, widget.properties),
      );
      const axisYs = JSON.parse(
        getAxisValues(PanelType.axisY, widget.properties),
      );

      return boundType === BoundType.autoDrillDownGroups
        ? axisXs.map((axisX: any) => {
            return {
              value: axisX.id,
              id: widget.id,
              name: `${widget.name}.${axisX.displayName || axisX.name}`,
              type: 'MAP',
              sourceId: axisX.etlSourceId,
            };
          })
        : axisYs.map((axisY: any) => {
            const nameX = axisXs.find(
              (axisX: any) => axisX.etlSourceId === axisY.etlSourceId,
            ).displayName;

            return {
              value: axisY.id,
              id: widget.id,
              name: `${widget.name}.${nameX}.${axisY.name}`,
              type: 'MAP',
              sourceId: axisY.etlSourceId,
            };
          });
    })
    .flat();

  const NOT_MASTER_WIDGET_TYPES =
    boundType === BoundType.manualDrillDownGroups
      ? NOT_MASTER_MANUAL_DD_WIDGET_TYPES
      : NOT_MASTER_AUTO_DD_AND_BOUND_WIDGET_TYPES;

  const potentialMasterWidgets = [
    ...widgets,
    ...mappedConnectedWidgetsNotOnDashboard,
  ]
    .filter(({ type }) => !NOT_MASTER_WIDGET_TYPES.includes(type))
    .map((widget: any) => {
      const axisXs = JSON.parse(
        getAxisValues(PanelType.axisX, widget.properties),
      );
      const filterName = axisXs?.[0]?.displayName || axisXs?.[0]?.name;

      return {
        value:
          boundType === BoundType.autoDrillDownGroups
            ? widget.id
            : axisXs?.[0]?.id || axisXs?.[0]?.widgetFieldId,
        id: widget.id,
        name:
          boundType === BoundType.autoDrillDownGroups
            ? widget.name
            : `${widget.name}.${filterName}`,
        type: 'NONE',
        sourceId: axisXs?.[0]?.etlSourceId,
      };
    })
    .concat(potentialMasterMapWidgets);

  const masterMapWidgetValue = potentialMasterWidgets.find((widget) => {
    if (boundType === BoundType.autoDrillDownGroups) {
      return (
        widget.type === 'MAP' &&
        widget.id === masterWidgetId &&
        widget.sourceId === sourceId
      );
    }
    return widget.type === 'MAP' && widget.value === masterWidgetFilterId;
  })?.value;

  const masterWidgetValue =
    masterMapWidgetValue ||
    potentialMasterWidgets.find((widget) => {
      return widget.id === masterWidgetId;
    })?.value;

  const connectOptions: ParticipantFilterWidget[] = drillDownGroup.participantFilterWidgets?.filter(
    (participant: ParticipantFilterWidget) => !participant?.isMaster,
  );

  useEffect(() => {
    setFilteredConnectOptionsWidgets(connectOptions);
    searchRef.current && (searchRef.current.value = '');
  }, [JSON.stringify(connectOptions)]);

  const [filteredConnectOptionsWidgets, setFilteredConnectOptionsWidgets] =
    useState(connectOptions);

  const isNeedShowSearch = connectOptions.length > MAX_LIST_ITEMS_COUNT;
  const heightConnectedWidgetList = HEIGHT_ITEM_LIST * MAX_LIST_ITEMS_COUNT * getScaleCoefficient();

  const isDisableOption = (participant: any): boolean => {
    const isCycleDD = Boolean(
      Boolean(inactiveWidgetsForDD?.length) &&
        inactiveWidgetsForDD.findIndex(
          (item: any) =>
            item.master === masterWidgetId &&
            item.dependent === participant.widgetId,
        ) + 1,
    );

    return (
      (boundType === BoundType.autoDrillDownGroups &&
        !participant?.isActive &&
        allDrillDownWidgetsIds.includes(participant?.widgetId)) ||
      isCycleDD
    );
  };

  const handleChangeMasterWidget = (e: any) => {
    if (boundType === BoundType.autoDrillDownGroups) {
      const mapWidgetId = potentialMasterMapWidgets.find(
        (widget) =>
          widget.value === e.target.value && widget.type === WidgetType.MAP,
      )?.id;

      onChangeMasterWidget(
        mapWidgetId || e.target.value,
        mapWidgetId && e.target.value,
      );
    } else {
      onChangeMasterWidget(e.target.value);
    }
  };

  const isBoundType: boolean = [
    BoundType.autoBoundGroups,
    BoundType.manualBoundGroups,
  ].includes(boundType);

  const controlComponent = isBoundType ? <CustomCheckbox /> : <CustomRadio />;

  const potentialMasterWidgetsList = potentialMasterWidgets.map((widget) => {
    return {
      value: widget.value,
      showValue: widget.name,
      disabled:
        // для текущего виджета не отключаем другие слои (нужно для карты)
        masterWidgetId !== widget.id &&
        !isBoundType &&
        (!enabledWidgetsIds.includes(widget.id) ||
          connectOptions.findIndex(
            (participant: ParticipantFilterWidget) =>
              participant.isActive && participant.widgetId === widget.id,
          ) !== -1),
    };
  });

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!value.length) {
      setFilteredConnectOptionsWidgets(connectOptions);
    } else {
      const filteredWidgets = connectOptions.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredConnectOptionsWidgets(filteredWidgets);
    }
  };

  return {
    handleChangeMasterWidget,
    masterWidgetValue,
    potentialMasterWidgetsList,
    isLoadedGroup,
    controlComponent,
    isDisableOption,
    handleChangeSearch,
    filteredConnectOptionsWidgets,
    isBoundType,
    searchRef,
    isLoadingWidgets,
    isNeedShowSearch,
    heightConnectedWidgetList
  };
};
