import { defaultColors } from '../../components/widget-page/charts/common/color';
import { sortColorPalette } from '../../components/widget-page/charts/helpers';
import { PropertyData } from '../../components/widget-page/dropdown-layout/helpers/Property';
import { NumberFormat } from '../../enums/number-format';
import { PanelType, WidgetPropertyType } from '../../enums/widget-type';
import { WidgetProperties } from '../../slices/types';
import Dictionaries from '../../types/dictionaries';
import { useGetProperty } from './useGetProperty';

const useGetWidgetProperty = <D,>(
  name: WidgetPropertyType,
  properties: WidgetProperties[],
  defaultValue?: D,
) => {
  return useGetProperty<WidgetPropertyType, WidgetProperties, D>(
    name,
    properties,
    defaultValue,
  );
};

const useGetStringWidgetProperty = <D,>(
  name: WidgetPropertyType,
  properties: WidgetProperties[],
  defaultValue?: D,
) => {
  return useGetWidgetProperty<D>(
    name,
    properties,
    defaultValue,
  ) as string;
};

const useGetNumberWidgetProperty = <D,>(
  name: WidgetPropertyType,
  properties: WidgetProperties[],
  defaultValue?: D,
) => {
  return useGetWidgetProperty<D>(
    name,
    properties,
    defaultValue,
  ) as number;
};

const useGetBooleanWidgetProperty = <D,>(
  name: WidgetPropertyType,
  properties: WidgetProperties[],
  defaultValue?: D,
) => {
  return useGetWidgetProperty<D>(
    name,
    properties,
    defaultValue,
  ) as boolean;
};

const useGetAxisWidgetProperty = <D,>(
  name: PanelType,
  properties: WidgetProperties[],
  defaultValue?: D,
) => {
  return useGetProperty<PanelType, WidgetProperties, D>(
    name,
    properties,
    defaultValue,
  ) as PropertyData[];
};

const useGetColorPaletteWidgetProperty = (
  properties: WidgetProperties[],
) => {
  const colorPalette = (
    useGetWidgetProperty<undefined>(
      WidgetPropertyType.colorPalette,
      properties,
    ) as Dictionaries.ColorPalette | undefined
  );

  if (colorPalette) {
    return sortColorPalette(
      colorPalette.firstColor,
      colorPalette.colorsList,
    ) || defaultColors;
  }
  return defaultColors;
};

const useGetNumberFormatWidgetProperty = (
  properties: WidgetProperties[],
  defaultValue: NumberFormat = NumberFormat.no,
) => {
  return (
    useGetWidgetProperty<NumberFormat>(
      WidgetPropertyType.formatByNumber,
      properties,
      defaultValue,
    ) as NumberFormat
  );
};

enum ValueDirection {
  horizontal = 'Горизонтальные',
  vertical = 'Вертикальные',
}

const useGetValueDirectionWidgetProperty = (
  properties: WidgetProperties[],
  defaultValue: ValueDirection = ValueDirection.horizontal,
) => {
  return useGetWidgetProperty<ValueDirection>(
    WidgetPropertyType.valueDirection,
    properties,
    defaultValue,
  ) as ValueDirection;
};

enum TableHiddenMethod {
  bookmark = 'По запросу',
  hidden = 'Не перечитытывать',
}

const useGetTableHiddenMethodWidgetProperty = (
  properties: WidgetProperties[],
  defaultValue: TableHiddenMethod = TableHiddenMethod.bookmark,
) => {
  return useGetWidgetProperty<TableHiddenMethod>(
    WidgetPropertyType.tableHiddenMethod,
    properties,
    defaultValue,
  ) as TableHiddenMethod;
};

export {
  ValueDirection,
  TableHiddenMethod,
  useGetWidgetProperty,
  useGetStringWidgetProperty,
  useGetNumberWidgetProperty,
  useGetBooleanWidgetProperty,
  useGetAxisWidgetProperty,
  useGetColorPaletteWidgetProperty,
  useGetNumberFormatWidgetProperty,
  useGetValueDirectionWidgetProperty,
  useGetTableHiddenMethodWidgetProperty,
};