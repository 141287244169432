import React from 'react';
import { Grid } from '@material-ui/core';
import { useSearchBlock } from './useSearchBlock';
import { CustomAutocomplete } from '../../../../uikit/Autocomplete';
import MapConnectionTypes from '../../../../types/map-connection';

export interface SearchBlockProps {
  loaderData: MapConnectionTypes.Data;
}

export const SearchBlock = ({ loaderData }: SearchBlockProps) => {
  const {
    classes,
    correctSourceList,
    getFilterOptions,
    renderOption,
    renderInput,
    handleChangeSourceObject,
  } = useSearchBlock({ loaderData });
  
  return (
    <Grid item classes={{ root: classes.gridRoot }}>
      <CustomAutocomplete
        options={correctSourceList}
        filterOptions={(options, state) => getFilterOptions(state.inputValue)}
        getOptionLabel={(option) => option?.name || option?.initialName}
        renderOption={renderOption}
        multiple={false}
        renderInput={renderInput}
        onlyInput
        disableCloseOnSelect={false}
        onChange={handleChangeSourceObject}
        className={classes.search}
      />
    </Grid>
  );
};
