import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import GridLayout from 'react-grid-layout';
import './styles.css';
import './react-grid.css';
import { getScaleCoefficient } from '../../../helpers/ui-helper';
import QuickFilters from '../filters/quick-filters';
import DashboardWidget from './components/widget';
import { DashboardSettingsPrint } from '../settings/common/dashboard-settings-print';
import { getDashboardItemClasses } from '../../../helpers/dashboard-page/dashboard-widgets';
import DashboardWidgetsFilterManager from './dashboard-widgets-filters-manager';
import { DashboardWidgetsViewProps } from './types';


const DashboardWidgetsEditView = ({
  dashboardLayout,
  onLayoutChange,
  handleDeleteWidget,
  gridLayoutContainer,
  gridContainerWidth,
  currentWidgetIdFullScreen,
  drillDownDepth,
  increaseDrilldownDepth,
  decreaseDrilldownDepth,
}: DashboardWidgetsViewProps) => {

  return (
    <>
      <QuickFilters />
      <Droppable droppableId="dashboard" mode="standard">
        {(provided, snapshot) => (
          <div
            className="dashboard-droppable"
            {...provided.droppableProps}
            style={{
              boxShadow: snapshot.isDraggingOver
                ? '0 0 13px 1px #3b82e452'
                : 'none',
            }}
            ref={provided.innerRef}
          >
            <div className="dashboard-grid-content" ref={gridLayoutContainer}>
              <DashboardSettingsPrint
                gridLayoutContainer={gridLayoutContainer}
              />
              <GridLayout
                layout={dashboardLayout}
                className="dashboard-widgets"
                onLayoutChange={onLayoutChange}
                cols={12}
                rowHeight={30}
                width={gridContainerWidth}
                draggableHandle='.card__header'
                draggableCancel='.dashboard-grid-item__button-group'
                containerPadding={[12 * getScaleCoefficient(), 12 * getScaleCoefficient()]}
              >
                {dashboardLayout.map((widget) => (
                  <div className={getDashboardItemClasses(Number(widget.id), currentWidgetIdFullScreen)} key={`${widget.id}`}>
                    <DashboardWidget
                      widgetId={Number(widget.id)}
                      handleDeleteWidget={handleDeleteWidget}
                      increaseDrilldownDepth={increaseDrilldownDepth}
                      decreaseDrilldownDepth={decreaseDrilldownDepth}
                      drillDownDepth={drillDownDepth}
                    />
                  </div>
                ))}
              </GridLayout>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <DashboardWidgetsFilterManager dashboardLayout={dashboardLayout} />
    </>
  );
};

export default DashboardWidgetsEditView;
