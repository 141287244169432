import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { WidgetsTypesContext } from '../../../pages/widget-page/widget-page';
import { MeasureProps, SourceField, State, Widget } from '../../../slices/types';
import Sidebar from '../../common/sidebar';
import { SelectInput } from '../../common/special-inputs/inputs/select-input';
import './styles.css';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import LabelField from '../../common/label-field/label-field';

import {
  apiFetchLoaderSources,
  apiFetchSearchResult,
  apiFetchSourceData,
} from '../../../services/widgetController';

import {
  setWidgetPropsAction,
  setWidgetPushDownAction,
  setWidgetSourceIdAction,
} from '../../../slices/widget/widget';
import WidgetsTypes from '../../../types/widgets';
import { getDefaultWidgetProps } from '../../../utils/functions';
import {
  setCurrentProjectWidgetSourcesAction,
  setCurrentProjectWidgetSourceFieldsDataAction,
} from '../../../slices/main-page/main-page-slice';
import { DEFAULT_DEBOUNCE_INPUT } from '../../../hooks/useDebouncedInput';
import IconSvg from '../../common/icon-svg/icon-svg';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { AddMeasureModal } from './add-measure-modal';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface WidgetSidebarProps {
  isDragging: boolean;
  isDisable: boolean;
}

const WidgetSidebar: FC<WidgetSidebarProps> = ({
  isDragging = false,
  isDisable = false,
}) => {
  const dispatch = useDispatch();

  const widgetTypes = useContext(WidgetsTypesContext);

  const widgetSourceId = useSelector((state: State) => state.widget.sourceId);
  const widgetId = useSelector((state: State) => state.widget.id);
  const widgetType = useSelector((state: State) => state.widget.type);

  const sourceFieldsState =
    useSelector(
      (state: State) => state.mainPage?.currentProjectWidgetSourceFieldsData,
    ) ?? [];

  const [sourcesList, setSourcesList] = useState<any[]>([]);
  const currentSource: string = widgetSourceId;

  const [filteredSourceFields, setFilteredSourceFields] = useState<(SourceField | MeasureProps)[]>([]);

  const loaderId: number =
    useSelector((state: State) => state.mainPage?.currentProject?.loaderId) ??
    0;

  const isDataCacheCleared: boolean =
    useSelector((state: State) => state.mainPage?.isDataCacheCleared) ?? 0;

  useEffect(() => {
    const updateSourceState = () => {
      if (widgetSourceId) {
        const currentSource = sourcesList.find((item) => item.id === widgetSourceId);
        dispatch(setCurrentProjectWidgetSourcesAction([currentSource]));
      }
    };

    updateSourceState();
  }, [dispatch, sourcesList, widgetSourceId]);

  useEffect(() => {
    const updateSourceFields = () => {
      if (widgetSourceId) {
        setFilteredSourceFields(sourceFieldsState);
      }
    };

    updateSourceFields();
  }, [JSON.stringify(sourceFieldsState)]);

  const updateSourcesData = useCallback((loaderId: number) => {
    loaderId &&
      apiFetchLoaderSources(loaderId).then((result) => {
        setSourcesList(result);
      });

    if (widgetSourceId) {
      apiFetchSourceData(widgetSourceId).then((result) => {
        dispatch(setWidgetSourceIdAction(result.id));
        dispatch(setCurrentProjectWidgetSourceFieldsDataAction(result.fields));
        setFilteredSourceFields(result.fields);
      });
    } else {
      setSourcesList([]);
      dispatch(setCurrentProjectWidgetSourceFieldsDataAction([]));
      setFilteredSourceFields([]);
    }
  }, [dispatch, widgetSourceId]);

  useEffect(() => {
    updateSourcesData(loaderId);
  }, [widgetId, widgetType, loaderId, updateSourcesData]);

  useEffect(() => {
    if (isDataCacheCleared) {
      updateSourcesData(loaderId);
    }
  }, [widgetId, widgetType, loaderId, isDataCacheCleared, updateSourcesData]);

  const currentSourceId = useMemo(() => {
    return widgetSourceId;
  }, [widgetSourceId]);

  const onSourceChange = useCallback(
    (event: any) => {
      const sourceId = event.target.value;
      apiFetchSourceData(sourceId).then((result) => {
        dispatch(setCurrentProjectWidgetSourceFieldsDataAction(result.fields));
        setFilteredSourceFields(result.fields);
        dispatch(setWidgetSourceIdAction(result.id));
        const defaultWidgetProps: any = getDefaultWidgetProps(
          widgetTypes,
          widgetType,
        );
        dispatch(setWidgetPropsAction(defaultWidgetProps));

        const needPushDown = sourcesList.find((item) => item.id === sourceId)?.needPushDown || false;
        dispatch(setWidgetPushDownAction(needPushDown));
      });
    },
    [dispatch, widgetTypes, widgetType, sourcesList],
  );

  const fetchSearchResult = useDebouncedCallback((value) => {
    currentSource &&
    apiFetchSearchResult(currentSource, value).then(
      (result) => {
        setFilteredSourceFields(result);
      },
    );
  }, DEFAULT_DEBOUNCE_INPUT);

  const onSearchChange = (event: any) => {
    fetchSearchResult.callback(event.target.value);
  };

  const isDimension = (item: (SourceField | MeasureProps)) => {
    return item.dimension === true;
  };

  const isMeasure = (item: (SourceField | MeasureProps)) => {
    return item.measure === true;
  };

  const [openAddingMeasure, setOpenAddingMeasure] = useState<boolean>(false);

  const handleAddMeasureClose = useCallback(() => {
    setOpenAddingMeasure(false);
  }, [setOpenAddingMeasure]);

  const handleAddMeasureClick = () => {
    setOpenAddingMeasure(true);
  };

  return (
    <Sidebar>
      <div
        className={`widget-sidebar ${
          isDisable ? 'widget-sidebar--disabled' : ''
        }`}
      >
        <SelectInput
          className="widget-sidebar__select"
          label="Имя таблицы"
          value={widgetSourceId}
          handleChange={(event) => onSourceChange(event)}
          itemList={sourcesList.map((item) => ({ value: item.id, showValue: item.name }))}
        />

        <TextInput
          label="Поиск по полям"
          className="widget-sidebar__field"
          handleChange={onSearchChange}
          searchIcon
        />

        <div className="widget-sidebar__list">
          <div className="widget-sidebar__droppable">
            <Droppable droppableId="dimensions">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredSourceFields?.filter(isDimension).length > 0 && (
                    <h3 className="widget-sidebar__title">{CommonDictionary.measures}</h3>
                  )}

                  <div className="widget-sidebar__item">
                    {filteredSourceFields &&
                      filteredSourceFields
                        .filter(isDimension)
                        .map((item, index) => (
                          <div
                            className="widget-sidebar__dropdown"
                            key={item.etlFieldId}
                          >
                            <Draggable
                              draggableId={JSON.stringify(item)}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    marginBottom: 'calc(16px * var(--scale-coefficient))',
                                  }}
                                >
                                  <LabelField field={item} />
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="widget-sidebar__droppable">
            <Droppable droppableId="measures">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredSourceFields.length > 0 && (
                    <div className="widget-sidebar__title-container">
                      <h3 className="widget-sidebar__title">{CommonDictionary.dimensions}</h3>
                      <CustomTooltip
                        title="Добавить новое измерение"
                        arrow={true}
                      >
                        <div
                          tabIndex={0}
                          role="button"
                          onClick={handleAddMeasureClick}
                        >
                          <IconSvg fill="var(--primary-color)" svg={IconDictionary.PlusRounded} />
                        </div>
                      </CustomTooltip>
                    </div>
                  )}
                  <div className="widget-sidebar__item">
                    {filteredSourceFields &&
                      filteredSourceFields
                        .filter(isMeasure)
                        .map((item, index) => (
                          <div
                            className={`widget-sidebar__dropdown ${
                              isDragging ? 'widget-sidebar__dragged' : ''
                            }`}
                            key={item.etlFieldId}
                          >
                            <Draggable
                              draggableId={JSON.stringify(item)}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    marginBottom: 'calc(16px * var(--scale-coefficient))',
                                  }}
                                >
                                  <LabelField field={item} />
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>

      {openAddingMeasure && (
        <AddMeasureModal
          closeModal={handleAddMeasureClose}
          currentSourceId={currentSourceId as string}
        />
      )}
    </Sidebar>
  );
};

export default WidgetSidebar;
