import React from 'react';
import { FilterForPanel } from '../../../hooks';

export const DdFilterPanelTooltip = ({
  filter,
}: {
  filter: FilterForPanel;
}) => {
  return (
    <div className="dd-filters-panel__tooltip">
      {filter.values.map((value) => (
        <span key={value}>{value}</span>
      ))}
    </div>
  );
};
