import React, { useMemo } from 'react';
import { CssVariables } from '../../../../../enums/css-variables';
import { varcss } from '../../../../../helpers/ui-helper';
import {
  TableHiddenMethod,
  useGetTableHiddenMethodWidgetProperty
} from '../../../../../hooks/get-properties/useGetWidgetProperty';
import { Widget, WidgetProperties } from '../../../../../slices/types';
import { CustomButton } from '../../../../../uikit/Button';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { BookmarkPopover } from '../../../../common/card-top-panel/bookmarks/bookmark-popover';
import {
  checkMinMultipleBookmark
} from '../../../../common/card-top-panel/bookmarks/bookmark-popover/useBookmarkPopover';
import { Bookmark, BookmarkValidationRules } from '../../../../common/card-top-panel/bookmarks/useBookmarks';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { SetFilterField, SetMultipleFilterFields } from '../../../../dashboard-page/hooks';
import { HiddenColumnsModal } from './hidden-columns-modal';
import './index.css';
import { getBooleanPropertyValue } from '../../../charts/helpers';
import { PanelType, WidgetPropertyType } from '../../../../../enums/widget-type';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface TableButtonsProps {
  widget: Widget;
  dbFiltersControls?: {
    setOverriddenProperties?: (newProperties: WidgetProperties[]) => void;
    setFilterField?: SetFilterField;
    setMultipleFilterFields?: SetMultipleFilterFields;
    isActiveFilter?: number | false | undefined;
  };
  pivot?: boolean;
}

const getPivotAxesDisplayedNames = (axis: PanelType | null) => {
  switch (axis) {
    case PanelType.axisX:
      return 'Строки';
    case PanelType.axisY:
      return 'Значения';
    case PanelType.axisZ:
      return 'Столбцы';
    default:
      return '';
  }
};

export const getCheckDoubleEtlFieldIdInPivot: (axis: PanelType) => BookmarkValidationRules =
  (axis) =>
    (
      axisValues,
      axesValues,
    ) => {
      const axesValuesOther = Object.keys(axesValues)
        .filter((key) => key !== axis)
        .map((key) => ({ name: key, value: axesValues[key as PanelType] }));

      const doubleField = axisValues
        .map((axisValue) => {
          const axisName = axesValuesOther
            .map((axesValueOther) => {
              return axesValueOther.value.find(
                (valueOther) => valueOther.etlFieldId === axisValue.etlFieldId,
              )
                ? axesValueOther.name
                : null;
            })
            .filter(Boolean)?.[0]; // если дубль есть в нескольких осях, то говорим только про одну из них

          return {
            name: axisValue.name,
            doubleAxisName: getPivotAxesDisplayedNames(axisName as PanelType),
          };
        })
        .filter((doubleFields) => Boolean(doubleFields.doubleAxisName))?.[0];

      return doubleField?.doubleAxisName
        ? `Поле ${doubleField.name} уже есть на оси ${doubleField.doubleAxisName}`
        : null;
    };


export const TableButtons = ({ widget, dbFiltersControls = {}, pivot = false }: TableButtonsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const hierarchyInHeader: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.hierarchyInHeader, widget.properties),
    [widget.properties],
  );

  const tableHiddenMethod = useGetTableHiddenMethodWidgetProperty(widget.properties);
  const isBookmarks = tableHiddenMethod === TableHiddenMethod.bookmark;

  const { setOverriddenProperties, setFilterField, setMultipleFilterFields, isActiveFilter } = dbFiltersControls;

  const currentBookmark: Bookmark[] = pivot ? [
    {
      axis: PanelType.axisX,
      isVisible: true,
      multiple: true,
      name: getPivotAxesDisplayedNames(PanelType.axisX),
      value: '',
      validationRules: [checkMinMultipleBookmark, getCheckDoubleEtlFieldIdInPivot(PanelType.axisX)],
    },
    {
      axis: PanelType.axisY,
      isVisible: true,
      multiple: true,
      name: getPivotAxesDisplayedNames(PanelType.axisY),
      value: '',
      validationRules: [checkMinMultipleBookmark, getCheckDoubleEtlFieldIdInPivot(PanelType.axisY)],
    },
    {
      axis: PanelType.axisZ,
      isVisible: true,
      multiple: true,
      name: getPivotAxesDisplayedNames(PanelType.axisZ),
      value: '',
      validationRules: [(axisValues: PropertyData[]) => {
        return axisValues.length > 2
          ? 'Должно быть выбрано не больше двух элементов'
          : null;
      }, getCheckDoubleEtlFieldIdInPivot(PanelType.axisZ)],
    },
  ] : [{
    axis: PanelType.axisX,
    isVisible: true,
    multiple: true,
    name: 'Столбцы',
    value: '',
    validationRules: [checkMinMultipleBookmark],
  }];

  return (
    <>
      {!hierarchyInHeader && (!isBookmarks || widget.id) && (
        <>
          <CustomTooltip
            arrow
            title="Настроить скрытые столбцы"
            key="Настроить скрытые столбцы"
          >
            <div>
              <CustomButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                icon={
                  <IconSvg
                    svg={IconDictionary.HiddenColumnsSetting}
                    fill="var(--dark-grey)"
                  />
                }
                size="small"
                variant="tab"
                backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
              />
            </div>
          </CustomTooltip>

          {isBookmarks || pivot ?
            widget.id && Boolean(anchorEl) && (
              <BookmarkPopover
                bookmarkList={currentBookmark}
                anchorEl={anchorEl}
                handleClickClose={() => setAnchorEl(null)}
                widgetProperties={widget.properties}
                widgetId={widget.id}
                widgetType={widget.type}
                setOverriddenProperties={setOverriddenProperties}
                setFilterField={setFilterField}
                setMultipleFilterFields={setMultipleFilterFields}
                isActiveFilter={isActiveFilter}
              />
            ) : (
              <HiddenColumnsModal
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                widget={widget}
              />
            )}
        </>
      )}
    </>
  );
};
