import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { State } from '../../../../../slices/types';
import { PanelType, WidgetType } from '../../../../../enums/widget-type';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { setWidgetPropAction } from '../../../../../slices/widget/widget';

const widgetsWithSynchronization = [WidgetType.TABLE, WidgetType.PIVOT_TABLE];

export const useAxisRenameSynchronization = () => {
  const dispatch = useDispatch();
  const widget = useSelector((state: State) => state.widget);

  const axisRenameSynchronization = useCallback((storage: string, clientUID?: string) => {
    if (!widgetsWithSynchronization.includes(widget.type) || !clientUID) return;

    const filters: PropertyData[] = getParsedAxisValues(PanelType.axisFilter, widget.properties);
    const newFilters = [...filters];

    const currentFilterIndex = filters.findIndex((item) => item.clientUID === clientUID);

    if (currentFilterIndex === -1) return;

    const alias = JSON.parse(storage)?.alias || '';

    newFilters[currentFilterIndex] = {
      ...newFilters[currentFilterIndex],
      storage: JSON.stringify({ alias })
    };

    dispatch(
      setWidgetPropAction({
        name: PanelType.axisFilter,
        value: JSON.stringify(newFilters),
      }),
    );
  }, [dispatch, widget.properties, widget.type]);

  return {
    axisRenameSynchronization
  };
};
