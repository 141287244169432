import { WidgetNames } from './widget-names';
import { DashboardNames } from './dashboard-names';
import { CommonNames } from './common-names';

export interface Dictionary {
  [key: string]: string;
}

export const WidgetDictionary: Dictionary = {
  ...WidgetNames,
};

export const DashboardDictionary: Dictionary = {
  ...DashboardNames,
};

export const CommonDictionary: Dictionary = {
  ...CommonNames,
};
