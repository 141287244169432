import { v4 as uuid } from 'uuid';
import { getScaleCoefficientInPercent } from '../helpers/ui-helper';
import { apiBI, checkAccess } from '../utils/api';
import { Dashboard, DashboardResponse, DashboardFilterProperties, DashboardRequest } from '../slices/types';

export const apiGetAllProjectDashboards = async (projectId: number) => {
  try {
    const response = await apiBI.get(`/dashboards/by-project/${projectId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCreateNewDashboard = async (parameters: Partial<Dashboard>) => {
  try {
    const response = await apiBI.post('/dashboards', parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiUpdateDashboard = async (parameters: DashboardRequest) => {
  try {
    const response = await apiBI.put<DashboardResponse>('/dashboards', parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apigetDashboardById = async (dashboardId: number | undefined, isPreview = false) => {
  try {
    const response = await apiBI.get<DashboardResponse>(`/dashboards/${dashboardId}?preview=${isPreview}`);
    return response.data;
  } catch (error: any) {
    checkAccess(error);
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const deleteDashboardById = async (dashboardId: number | undefined) => {
  try {
    const response = await apiBI.delete(`/dashboards/${dashboardId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCopyDashboard = async (dashboardId: number | undefined) => {
  try {
    const response = await apiBI.post(`/dashboards/${dashboardId}/copy`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiResetDashboardFiltersById = async (dashboardId: number | undefined) => {
  try {
    const response = await apiBI.put(`/dashboards/${dashboardId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiResetDashboardAutoBoundById = async (dashboardId: number | undefined) => {
  try {
    const response = await apiBI.put(`/dashboards/${dashboardId}/redistributeAutoBound`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const getDrillDownByMasterWidget = async (
  dashboardId: number,
  widgetId: number,
  layout?: number,
) => {
  try {
    const response = await apiBI.get(
      `/dashboards/${dashboardId}/auto-by-widget/${widgetId}`, {
        params: { layout },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const getManualByMasterWidget = async (
  dashboardId: number,
  argumentFieldId: number,
  type: string = 'manualBoundGroups',
) => {
  try {
    const response = await apiBI.get(
      `dashboards/${dashboardId}/manual-by-argument/${argumentFieldId}?type=${type}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiExportDashboard = async (
  dashboardId: number | undefined,
  type: string,
  requestParams: any = [],
  requestId?: string
) => {
  const params = {
    requestBody: requestParams,
    requestId: requestId || uuid()
  };

  try {
    const response = await apiBI.post(
      `/export/dashboard/${dashboardId}/${type}?scaleCoefficient=${getScaleCoefficientInPercent()}`,
      params,
      { responseType: 'blob' },
    );
    return response;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetExportsTypes = async (): Promise<string[]> => {
  try {
    const response = await apiBI.get('/dashboards/exportTypes');
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetFilterPropertiesById = async (filterId: number): Promise<DashboardFilterProperties[]> => {
  try {
    const response = await apiBI.get(`/dashboards/filterProperties/${filterId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetSharedURL = async (dashboardId: number): Promise<string> => {
  try {
    const response = await apiBI.get(`/dashboards/${dashboardId}/share`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiDeleteSharedURL = async (dashboardId: number) => {
  try {
    const response = await apiBI.put(`/dashboards/${dashboardId}/withhold`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiSaveDashboardUserFilter = async (dashboardId: number, filters: any[]) => {
  try {
    const response = await apiBI.post(`/dashboards/${dashboardId}/userFilters`, filters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};
