import React from 'react';
import './index.css';
import { WidgetProperties } from '../../../../slices/types';
import { SetFilterField } from '../../../dashboard-page/hooks';
import { useBookmarks } from './useBookmarks';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../icon-svg/icon-svg';
import { InlineTextButton } from '../../../../uikit/InlineTextButton';
import { WidgetType } from '../../../../enums/widget-type';
import { BookmarkPopover } from './bookmark-popover';

export interface BookmarksProps {
  widgetProperties: WidgetProperties[];
  widgetType: WidgetType;
  widgetId: number;
  setOverriddenProperties: (newProperties: WidgetProperties[]) => void;
  setFilterField?: SetFilterField;
  isActiveFilter?: number | false | undefined;
}

export const Bookmarks = ({
  widgetProperties,
  widgetType,
  widgetId,
  setOverriddenProperties,
  setFilterField,
  isActiveFilter,
}: BookmarksProps) => {
  const {
    bookmarksList,
    handleClick,
    handleClickClose,
    anchorEl,
    currentBookmark,
  } = useBookmarks({
    widgetProperties,
    widgetType,
    widgetId,
    setOverriddenProperties,
    setFilterField,
    isActiveFilter,
  });

  return (
    <div className="bookmarks-container">
      {bookmarksList.map((bookmark) => {
        if (!bookmark.isVisible) return null;
        return (
          <div key={bookmark.axis} className="bookmark-block">
            <div className="bookmark-name">{bookmark.name}</div>
            <div className="bookmark-value">
              <InlineTextButton
                title={bookmark.value}
                onClick={(event) => handleClick(event, bookmark)}
                endIcon={
                  <IconSvg
                    svg={
                      currentBookmark?.axis === bookmark.axis
                        ? IconDictionary.CompactArrowToTop
                        : IconDictionary.CompactArrowToBottom
                    }
                    fill="var(--primary-color)"
                  />
                }
              />
            </div>
          </div>
        );
      })}

      {currentBookmark && (
        <BookmarkPopover
          bookmarkList={[currentBookmark]}
          anchorEl={anchorEl}
          handleClickClose={handleClickClose}
          widgetProperties={widgetProperties}
          widgetId={widgetId}
          widgetType={widgetType}
          setOverriddenProperties={setOverriddenProperties}
          setFilterField={setFilterField}
          isActiveFilter={isActiveFilter}
        />
      )}
    </div>
  );
};
