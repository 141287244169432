import React, { useMemo, useRef } from 'react';
import { Group } from '@visx/group';
import { AxisBottom } from '@visx/axis';
import moment from 'moment';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';
import {
  ScalableSVGText,
  textDirections,
} from '../../../../common/scalable-svg-text';
import { axisBottomOffset } from '../../bar-group/settings';
import { GraphData } from '../helper';
import {
  bottomAxisSettings,
  gridLinesColor,
  gridLinesXOffset,
  gridLinesYOffset,
  leftAxisSettings,
} from '../settings';
import { useGraphProperties } from '../hooks/useGraphProperties';
import { DateNamesValues } from '../../../../../enums/date';

interface BottomAxisProps {
  height: number;
  width: number;
  widgetData: GraphData[];
  filteredAxisDataValues: GraphData[];
  xScale: any;
  widgetProperties: any[];
  leftOffset: number;
}

const VERTICAL_GUIDE_LIMIT = 300;
const SHOW_EVERY_VALUE = 10;

export const BottomAxis = ({
  filteredAxisDataValues,
  widgetData,
  width,
  height,
  xScale,
  widgetProperties,
  leftOffset,
}: BottomAxisProps) => {

  const {
    isNeedToDisplayAxesGuide,
    isNeedAxisXDateLabelAggregation,
    dateLabelAggregation,
    textDirection,
    simplifiedTypeAxisX,
  } = useGraphProperties({
    widgetProperties,
  });

  const isNeedToDisplayVerticalGuide = isNeedToDisplayAxesGuide && widgetData.length < VERTICAL_GUIDE_LIMIT;

  const getTextWidth = useMemo(() => {
    return (textValue: any) => {
      const sideOffsets = 70;
      const calculatedWidth = (width - sideOffsets) / filteredAxisDataValues.length;
      const maxWidth = 160;
      const isLastOrFirstValue =
        textValue === filteredAxisDataValues[0].x ||
        textValue === filteredAxisDataValues[filteredAxisDataValues.length - 1].x;
      if (!isLastOrFirstValue) return calculatedWidth;
      return calculatedWidth > maxWidth ? maxWidth : calculatedWidth;
    };
  }, [width, filteredAxisDataValues]);

  const textXOffset = 10;
  const lineOffsetYForText = 10;
  const diagonalTextOffset = 35;

  const getAxisBottomTextSetting = (setting: string, formattedValue: any) => {
    switch (setting) {
      case 'lineCount':
        return textDirection === textDirections.vertical
          ? Math.floor(
            getTextWidth(formattedValue) / bottomAxisSettings.lineHeight,
          )
          : textDirection === textDirections.diagonal
            ? 1
            : 2;
      case 'width':
        return textDirection === textDirections.vertical
          ? axisBottomOffset
          : textDirection === textDirections.diagonal
            ? Math.sqrt(
              Math.pow(getTextWidth(formattedValue), 2) +
                Math.pow(axisBottomOffset, 2),
            ) - diagonalTextOffset
            : getTextWidth(formattedValue);
      case 'height':
        return textDirection === textDirections.vertical
          ? getTextWidth(formattedValue)
          : null;
    }
  };

  const index = useRef(0);
  index.current = 0;

  return (
    <AxisBottom
      hideAxisLine
      hideTicks
      numTicks={widgetData.length}
      tickComponent={(props) => {
        const isHiddenLabel =
          filteredAxisDataValues.length > VERTICAL_GUIDE_LIMIT &&
          index.current % SHOW_EVERY_VALUE !== 0;
        const isFiltered = !filteredAxisDataValues.some(value => value.x === props.formattedValue);
        index.current +=1;

        const leftOffsetForLabel = textDirection === textDirections.diagonal
          ? props.x - (getAxisBottomTextSetting('width', props.formattedValue) || 0) + diagonalTextOffset
          : props.x - (getTextWidth(props.formattedValue) / 2) + textXOffset;

        if (isHiddenLabel || isFiltered) return null;
        const isMomentValue = simplifiedTypeAxisX === WidgetSimplifiedDataType.DATE &&
          moment(props.formattedValue).isValid();

        return (
          <Group top={height - gridLinesYOffset} left={leftOffset}>
            <ScalableSVGText
              x={leftOffsetForLabel}
              y={props.y}
              formattedValue={isNeedAxisXDateLabelAggregation && isMomentValue
                ? moment(props.formattedValue).format(DateNamesValues[dateLabelAggregation])
                : props.formattedValue}
              properties={{
                minWidthHide: 30,
                lineCount: getAxisBottomTextSetting(
                  'lineCount',
                  props.formattedValue,
                ),
                height: getAxisBottomTextSetting(
                  'height',
                  props.formattedValue,
                ),
                width: getAxisBottomTextSetting('width', props.formattedValue),
                svgTextStyles: bottomAxisSettings,
                textDirection,
                tooltipPlacement: 'top',
                isNeedToFormat: !isNeedAxisXDateLabelAggregation ||
                  simplifiedTypeAxisX === WidgetSimplifiedDataType.NUMBER,
                tickTranslateX: 0,
              }}
              widgetProperties={widgetProperties}
            />

            {isNeedToDisplayVerticalGuide && (
              <rect
                className="visx-bar"
                x={props.x + gridLinesXOffset + textXOffset}
                y={-height + lineOffsetYForText}
                width={1}
                height={Math.abs(height)}
                fill={gridLinesColor}
              />
            )}
          </Group>
        );
      }}
      scale={xScale}
      stroke={leftAxisSettings.color}
      tickStroke={leftAxisSettings.color}
      tickLabelProps={() => ({
        fill: leftAxisSettings.color,
        textAnchor: 'middle',
      })}
    />
  );
};
