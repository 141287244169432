import React from 'react';
import { Popover, PopoverProps, styled } from '@material-ui/core';

interface CustomPopoverProps extends PopoverProps {
  overflow?: string;
  backgroundColor?: string;
}

const StyledPopover = styled(Popover)((props: Partial<CustomPopoverProps>) => ({
  '& .MuiPaper-root': {
    backgroundColor: props.backgroundColor || 'var(--white)',
    border: '1px solid #DAE7ED',
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    borderRadius: 'var(--base-border-radius)',
    padding: '2px',
    overflow: props.overflow || '',
    display: 'flex',
  },
}));

export const CustomPopover = ({ overflow, ...props }: CustomPopoverProps) => {
  return <StyledPopover overflow={overflow} {...props}>{props.children}</StyledPopover>;
};
