import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compact, flatten, isEmpty, isNumber, sortBy } from 'lodash';
import { isNaN } from 'formik';
import { CircularProgress } from '@material-ui/core';
import { getStorageName } from '../../../helpers/common-helpers';
import { getTextSkeletonStyles } from '../../../helpers/styles';
import BarGroup from './bar-group/vertical';
import HorizontalBarGroup from './bar-group/horizontal';
import Pie from './pie';
import Donut from './donut';
import Graph from './graph';
import GraphArea from './graph-area';
import StackedHistogram, { histogramTypes } from './stacked-histogram';
import StackedHorizontalHistogram from './stacked-horizontal-histogram';
import Map from './map';
import HtmlChart from './html';
import Speedometer from './speedometer';
import InformationCard from './information-card';
import SalesFunnel from './sales-funnel';
import { Gantt } from './gantt';
import Radar from './radar';
import TreeMap from './tree-map';

import { PanelType, WidgetType } from '../../../enums/widget-type';
import { NoDataTemplate } from '../../common/no-widget-data-template';
import {
  getAxisValues,
  getPanelIndex,
  getParsedAxisValues,
  transformValueToJSON,
} from '../dropdown-layout/helpers/helpers';
import { Property, PropertyData } from '../dropdown-layout/helpers/Property';

import './styles.css';
import { initValueFormatter } from './hooks/initValueFormatter';
import SunburstChart from './sunburst';
import { ComplexTable } from './complex-table';
import { DashboardProperty, Widget, WidgetProperties } from '../../../slices/types';
import HistogramGraph from './histogram-graph';
import OverlayGraph from './overlay-graph/overlay-graph';
import {
  WIDGET_SLICE_CONTAINER_HEIGHT,
  WIDGET_SLICE_HEADER_HEIGHT,
} from './settings';
import { PivotTable } from './pivot-table';
import { PivotData } from './pivot-table/interfaces';
import { BubbleChart } from './bubble/bubble-chart';
import { isNeedToIgnoreEmptyWidgetData } from '../helpers';
import { CustomProgress } from '../../../uikit/Progress';
import { DateFunctions } from '../../../enums/date';

interface PropertyByIndex {
  properties: any[];
  index: number;
  axisName: PanelType;
}

interface RenderSlicedChartProp {
  renderChart: (options: any) => JSX.Element;
  widgetProps: any;
  slice: string;
  widgetType: WidgetType;
  options: any;
  domain: number[];
  dashboardWidgetState?: WidgetProperties[];
}

interface RenderWidgetChartProp {
  widgetType: WidgetType;
  widgetProps: any;
  slices: string[];
  options: any;
  dashboardWidgetState?: WidgetProperties[];
  enhancedParams?: {dashboardProperties: DashboardProperty[], widgetOverriddenProperties: WidgetProperties[]};
}

const WIDGET_TYPES_WITHOUT_SLICES_HEIGHT_SETTING = [WidgetType.FUNNEL];
// виджеты со срезами внутри
export const WIDGETS_SLICE_INSIDE = [
  WidgetType.GRAPH,
  WidgetType.GRAPH_AREA,
  WidgetType.HISTOGRAM,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.STACKED_HISTOGRAM,
  WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
  WidgetType.BUBBLE_CHART,
];

export const isWidgetPage = () => /\/project\/\d+\/widget/i.test(window.location.pathname);
export const isNewWidgetPage = () => /\/project\/\d+\/widget$/i.test(window.location.pathname);
export const isDashboardPage = () =>
  window.location.pathname.includes('/dashboard');
export const isDashboardPreviewPage = () =>
  /\/dashboard\/\d+\/preview|\/shared\/dashboard\/\d+|\/dashboards/i.test(window.location.pathname);
export const isDashboardSharedPage = () =>
  /\/shared\/dashboard\/\d+/i.test(window.location.pathname);
export const isEditDashboardPage = () =>
  /\/project\/\d+\/dashboard\/\d+$/i.test(window.location.pathname);
export const isNewDashboardPage = () =>
  /\/project\/\d+\/dashboard$/i.test(window.location.pathname);
export const isLoaderPage = () => !isWidgetPage() && !isDashboardPage();

export const getBooleanPropertyValue = (
  name: string,
  widgetProperties: Array<any>,
): boolean => {
  const value = widgetProperties?.find((prop) => prop.name === name)?.value;
  try {
    return value ? JSON.parse(value) : false;
  } catch (e) {
    return false;
  }
};

export const getPropertyValueFromWidgetData = (
  name: string,
  widgetData: any[],
) => {
  return widgetData?.find(
    (data) => data.isPropertyValue && data.x === name && data.y,
  )?.y;
};

export const getJSONPropertyValue = (
  name: string,
  widgetProperties: Array<any>,
  isObject?: boolean,
): any | Array<any> => {
  const value = widgetProperties?.find((prop) => prop.name === name)?.value;

  const defaultValue = isObject ? {} : [];

  try {
    return value ? JSON.parse(value) : defaultValue;
  } catch (e: any) {
    return defaultValue;
  }
};

export const getSelectPropertyValue = (
  name: string,
  widgetProperties: Array<any>,
): string => {
  return widgetProperties?.find((prop) => prop.name === name)?.value;
};

export const getInputPropertyValue = (
  name: string,
  widgetProperties: Array<any>,
): string => {
  return widgetProperties?.find((prop) => prop.name === name)?.value;
};

export function getEnumPropertyValue<T>(name: string, properties: any[]) {
  const values: T = properties?.find((prop: any) => prop.name === name)?.value;
  return values;
}

export const getAxisValuesForWidget = (
  axisName: PanelType,
  properties: Array<any>,
  isShowDisplayName?: boolean,
  isGetAliases?: boolean
) => {
  try {
    return JSON.parse(getAxisValues(axisName, properties)).map((value: any) =>
      (isGetAliases && getStorageName(value.storage)) || (isShowDisplayName ? value.displayName : value.name)
    );
  } catch {
    return [];
  }
};

export const getLegendsLabelsForWidgetByAxis = (
  axisName: PanelType,
  properties: Array<any>,
  isNeedDisplayAggregationInLegend: boolean,
  isLegendObject: boolean = false,
) => {
  try {
    return JSON.parse(getAxisValues(axisName, properties))
      .filter((item: PropertyData) => item.isActive)
      .map((value: PropertyData) => {
      const propertyInstance = new Property(value);
      const slices = JSON.parse(getAxisValues(PanelType.dataSlice, properties) || '[]');
      const isWidgetWithSlices = slices?.length > 0;

      return isLegendObject
        ? propertyInstance.getLegendObject(
          isNeedDisplayAggregationInLegend,
          isWidgetWithSlices,
        )
        : propertyInstance.getViewLegend(
          isNeedDisplayAggregationInLegend,
          isWidgetWithSlices,
        );
    });
  } catch {
    return [];
  }
};

export const getStickPointValue = (stickValues: any[]) => {
  if (stickValues.includes(0)) return 0;
  const isHaveNegative = stickValues.some((value) => value < 0);
  if (isHaveNegative) {
    return Math.max(...stickValues);
  }
  return Math.min(...stickValues);
};

export const sortColorPalette = (color: string, colors: string[]) => {
  if (!colors) return null;
  const firstColorIndex = colors.indexOf(color);
  let sortedColors = colors;
  if (firstColorIndex !== 0) {
    const leftColorsPart = colors.slice(firstColorIndex);
    const rightColorsPart = colors.slice(0, firstColorIndex);
    sortedColors = [...leftColorsPart, ...rightColorsPart];
  }
  return sortedColors;
};

export const getOrderDirection = (
  properties: Array<any>,
  index: number,
  axisName: PanelType,
) => {
  try {
    const propertiesObject = JSON.parse(getAxisValues(axisName, properties))[
      index
    ];
    return 'orderDirection' in propertiesObject
      ? propertiesObject.orderDirection
      : null;
  } catch {
    return null;
  }
};

export const getPropertiesWithOrderOptionByIndexPanel = ({
  properties,
  index: indexOrdered,
  axisName,
}: PropertyByIndex): any[] => {
  const propertiesCopy = [...properties];

  const panelIndex = getPanelIndex({ properties, type: axisName });

  const { value } = properties[panelIndex];

  const parsedValue = JSON.parse(value);

  const oldColumn = parsedValue.find(
    (prop: any) =>
      prop.orderDirection === 'ASC' || prop.orderDirection === 'DESC',
  );

  const modifiedProperty = parsedValue.map((column: any, index: number) => {
    let orderDirection: string | null = null;
    if (index === indexOrdered)
      if (column.id === oldColumn?.id) {
        switch (oldColumn?.orderDirection) {
          case 'ASC':
            orderDirection = 'DESC';
            break;
          case 'DESC':
            orderDirection = null;
            break;
          default:
            orderDirection = 'ASC';
        }
      } else {
        orderDirection = 'ASC';
      }

    return {
      ...column,
      orderDirection,
    };
  });

  const resultValue = transformValueToJSON(modifiedProperty);

  propertiesCopy[panelIndex] = {
    ...properties[panelIndex],
    value: resultValue,
  };

  return propertiesCopy;
};

const validator = {
  [WidgetType.HISTOGRAM]: (data: any) => Array.isArray(data),
  [WidgetType.HORIZONTAL_HISTOGRAM]: (data: any) => Array.isArray(data),
  [WidgetType.STACKED_HISTOGRAM]: (data: any) => Array.isArray(data),
  [WidgetType.STACKED_HORIZONTAL_HISTOGRAM]: (data: any) => Array.isArray(data),
  [WidgetType.HISTOGRAM_GRAPH]: (data: any) => Array.isArray(data),
  [WidgetType.GRAPH]: (data: any) => Array.isArray(data),
  [WidgetType.GRAPH_AREA]: (data: any) => Array.isArray(data),
  [WidgetType.PIE_CHART]: (preview: any) => Array.isArray(preview.data),
  [WidgetType.DONUT_CHART]: (preview: any) => Array.isArray(preview.data),
  [WidgetType.SPEEDOMETER]: (data: any) =>
    isNumber(data.x) && isNumber(data.y),
  [WidgetType.TABLE]: () => true,
  [WidgetType.MAP]: (data: any) => Array.isArray(data?.features),
  [WidgetType.PIVOT_TABLE]: (data: any) => Array.isArray(data.data),
  [WidgetType.HTML]: (data: any) => Array.isArray(data),
  [WidgetType.INFORMATION_CARD]: (data: any) => isNumber(data?.currentValue),
  [WidgetType.GANTT_CHART]: (data: any) => Array.isArray(data),
  [WidgetType.FUNNEL]: (data: any) => Array.isArray(data),
  [WidgetType.RADAR_CHART]: (data: any) => Array.isArray(data),
  [WidgetType.SUNBURST]: (data: any) => Array.isArray(data?.children),
  [WidgetType.TREE_MAP]: (preview: any) => Array.isArray(preview?.items),
  [WidgetType.BUBBLE_CHART]: (data: any) => Array.isArray(data),
  [WidgetType.NONE]: () => false,
};

const isEmptyData = (type: string, data: any) => {
  const methods: any = {
    [WidgetType.HISTOGRAM]: (data: any) => !data.length,
    [WidgetType.HORIZONTAL_HISTOGRAM]: (data: any) => !data.length,
    [WidgetType.STACKED_HISTOGRAM]: (data: any) => !data.length,
    [WidgetType.STACKED_HORIZONTAL_HISTOGRAM]: (data: any) => !data.length,
    [WidgetType.HISTOGRAM_GRAPH]: (data: any) => !data.length,
    [WidgetType.GRAPH]: (data: any) => !data.length,
    [WidgetType.GRAPH_AREA]: (data: any) => !data.length,
    [WidgetType.PIE_CHART]: (preview: any) => !preview.data?.length,
    [WidgetType.DONUT_CHART]: (preview: any) => !preview.data?.length,
    [WidgetType.SPEEDOMETER]: (data: any) =>
      isEmpty(data) || (data?.x === 0 && data?.y === 0),
    [WidgetType.TABLE]: (preview: any) => !preview?.data?.length,
    [WidgetType.MAP]: (data: any) => !data?.features?.length,
    [WidgetType.GANTT_CHART]: (data: any) => !data.length,
    [WidgetType.PIVOT_TABLE]: (data: PivotData) => !data?.header?.length,
    [WidgetType.INFORMATION_CARD]: (data: any) =>
      !isNumber(data.currentValue),
    [WidgetType.FUNNEL]: (data: any) => !data.length,
    [WidgetType.RADAR_CHART]: (data: any) => !data.length,
    [WidgetType.SUNBURST]: (data: any) => !data?.children?.length,
    [WidgetType.TREE_MAP]: (preview: any) => !preview.items?.length,
    [WidgetType.BUBBLE_CHART]: (data: any) => !data?.length,
    [WidgetType.NONE]: () => true,
  };

  if (!methods[type]) {
    return true;
  }

  return methods[type](data);
};

const getRenderChartFunction = (type: WidgetType) => {
  switch (type) {
    case WidgetType.HISTOGRAM: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        domain,
      }: any) => {
        return (
          <BarGroup
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
            domain={domain}
          />
        );
      };
    }
    case WidgetType.HORIZONTAL_HISTOGRAM: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        domain,
      }: any) => {
        return (
          <HorizontalBarGroup
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
            domain={domain}
          />
        );
      };
    }
    case WidgetType.STACKED_HISTOGRAM: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        domain,
      }: any) => {
        return (
          <StackedHistogram
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
            domain={domain}
          />
        );
      };
    }
    case WidgetType.STACKED_HORIZONTAL_HISTOGRAM: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        domain,
      }: any) => {
        return (
          <StackedHorizontalHistogram
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
            domain={domain}
          />
        );
      };
    }
    case WidgetType.HISTOGRAM_GRAPH: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        domain,
      }: any) => {
        return (
          <HistogramGraph
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
            domain={domain}
          />
        );
      };
    }
    case WidgetType.GRAPH: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        domain,
      }: any) => {
        return (
          <Graph
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
            domain={domain}
          />
        );
      };
    }
    case WidgetType.GRAPH_AREA: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        domain,
      }: any) => {
        return (
          // <GraphArea
          //   widgetProps={widgetProps}
          //   setFilterField={setFilterField}
          //   filterField={filterField}
          //   isActiveFilter={isActiveFilter}
          //   domain={domain}
          // />
          <OverlayGraph
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
          />
        );
      };
    }
    case WidgetType.PIE_CHART: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
      }: any) => {
        return (
          <Pie
            width={800}
            height={640}
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
          />
        );
      };
    }
    case WidgetType.DONUT_CHART: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
      }: any) => {
        return (
          <Donut
            width={800}
            height={640}
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
          />
        );
      };
    }
    case WidgetType.FUNNEL: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
      }: any) => {
        return (
          <SalesFunnel
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
          />
        );
      };
    }
    case WidgetType.RADAR_CHART: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
      }: any) => {
        return (
          <Radar
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
          />
        );
      };
    }
    case WidgetType.MAP: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
        enhancedParams
      }: any) => {
        return (
          <Map
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
            enhancedParams={enhancedParams}
          />
        );
      };
    }
    case WidgetType.TREE_MAP: {
      return ({
        widgetProps,
        setFilterField,
        filterField,
        isActiveFilter,
      }: any) => {
        return (
          <TreeMap
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
          />
        );
      };
    }
    case WidgetType.BUBBLE_CHART: {
      return ({
                widgetProps,
                setFilterField,
                filterField,
                isActiveFilter,
              }: any) => {
        return (
          <BubbleChart
            widgetProps={widgetProps}
            setFilterField={setFilterField}
            filterField={filterField}
            isActiveFilter={isActiveFilter}
          />
        );
      };
    }
    case WidgetType.INFORMATION_CARD: {
      return ({ widgetProps }: any) => {
        return (
          <InformationCard
            widgetProps={widgetProps}
          />
        );
      };
    }
    default: {
      return () => {
        return <CustomProgress type="linear" />;
      };
    }
  }
};

export const getSliceData = (widgetType: string, widgetData: any, slice: string) => {
  switch (widgetType) {
    case WidgetType.PIE_CHART:
    case WidgetType.DONUT_CHART: {
      return {
        total: widgetData.total,
        data: widgetData.data.filter(
          (data: any) => data.slice === undefined || data.slice === slice,
        ),
      };
    }
    default: {
      return widgetData.filter(
        (data: any) => data.slice === undefined || data.slice === slice,
      );
    }
  }
};

const renderSlicedChart = ({
  renderChart,
  widgetProps,
  slice,
  widgetType,
  options,
  domain,
}: RenderSlicedChartProp) => {
  const widgetSliceData = getSliceData(
    widgetProps.type,
    widgetProps.data,
    slice,
  );

  const valueFormatter = initValueFormatter();
  const formattedTitle = valueFormatter(
    slice,
    0,
    widgetProps?.properties,
    PanelType.dataSlice,
  );

  const widgetSliceProps = { ...widgetProps };
  widgetSliceProps.data = widgetSliceData;

  const isValidData = validator[widgetType](widgetSliceData);

  const customAxisYLabelWidth = parseInt(
    getInputPropertyValue('axisYLabelWidth', widgetProps.properties),
  );
  const customAxisXLabelWidth = parseInt(
    getInputPropertyValue('axisXLabelWidth', widgetProps.properties),
  );

  const axisYLabelWidth = !isNaN(customAxisYLabelWidth)
    ? customAxisYLabelWidth
    : !isNaN(customAxisXLabelWidth)
      ? customAxisXLabelWidth
      : 0;

  const isNeedSettingContainerHeight =
    !WIDGET_TYPES_WITHOUT_SLICES_HEIGHT_SETTING.includes(widgetType);

  return isValidData ? (
    <div
      className="widget-viewport_slice-margin"
      style={{
        height: isNeedSettingContainerHeight ? '100%' : 'auto',
        minHeight: isNeedSettingContainerHeight ? WIDGET_SLICE_CONTAINER_HEIGHT : 'auto',
      }}
    >
      <div
        className="widget-viewport_slice-title"
        style={{
          marginLeft: axisYLabelWidth,
          height: WIDGET_SLICE_HEADER_HEIGHT,
        }}
      >
        {formattedTitle}
      </div>
      {renderChart({
        widgetProps: widgetSliceProps,
        setFilterField: options?.setFilterField,
        filterField: options?.filterField,
        isActiveFilter: options?.isActiveFilter,
        domain,
      })}
    </div>
  ) : null;
};

const getDomain = (widgetType: WidgetType, widgetProps: any) => {
  switch (widgetType) {
    case WidgetType.STACKED_HISTOGRAM:
    case WidgetType.STACKED_HORIZONTAL_HISTOGRAM: {
      const histogramType =
        getSelectPropertyValue('histogramType', widgetProps.properties) ||
        histogramTypes.stacked;

      const isNormalized = histogramType === histogramTypes.normalized;
      const isOverlap = histogramType === histogramTypes.overlap;

      const data = getStackedHistogramData(
        getCorrectWidgetData(widgetProps.data),
        isNormalized,
        isOverlap,
      );
      const maxTotal = getStackedMinMaxTotal(
        data,
        true,
        isNormalized,
        isOverlap,
      );
      const minTotal = getStackedMinMaxTotal(
        data,
        false,
        isNormalized,
        isOverlap,
      );
      return [minTotal, maxTotal];
    }
    case WidgetType.HISTOGRAM:
    case WidgetType.HORIZONTAL_HISTOGRAM:
    case WidgetType.GRAPH:
    case WidgetType.GRAPH_AREA: {
      const allDataValues = flatten(
        getCorrectWidgetData(widgetProps.data).map((item) => item.y),
      );
      return [Math.min(...allDataValues), Math.max(...allDataValues)];
    }
    case WidgetType.HISTOGRAM_GRAPH: {
      const dataAllValues = flatten(
        getCorrectWidgetData(widgetProps.data).map((item) => item.y),
      ).concat(
        flatten(getCorrectWidgetData(widgetProps.data).map((item) => item.z)),
      );
      return [Math.min(...dataAllValues), Math.max(...dataAllValues)];
    }
    default: {
      return [0, 1];
    }
  }
};

const renderWidgetChart = ({
  widgetType,
  widgetProps,
  slices,
  options,
  enhancedParams
}: RenderWidgetChartProp) => {
  const isSlice = Boolean(slices.length) && !WIDGETS_SLICE_INSIDE.includes(widgetType);
  const domain = getDomain(widgetType, widgetProps);

  if (isSlice) {
    return (
      <div className="widget-viewport_slices screenshot-overflow">
        {slices.map((slice) => {
          return renderSlicedChart({
            renderChart: getRenderChartFunction(widgetType),
            widgetProps,
            slice,
            widgetType,
            options,
            domain,
          });
        })}
      </div>
    );
  }

  const isValidData = validator[widgetType](widgetProps.data);
  return isValidData
    ? getRenderChartFunction(widgetType)({
      widgetProps,
      setFilterField: options?.setFilterField,
      filterField: options?.filterField,
      isActiveFilter: options?.isActiveFilter,
      dashboardWidgetState: options?.overriddenProperties,
      enhancedParams
    })
    : null;
};

export const getSlices = (widgetType: string, widgetData?: any) => {
  switch (widgetType) {
    case WidgetType.PIE_CHART:
    case WidgetType.DONUT_CHART: {
      return [
        ...new Set(
          Array.isArray(widgetData?.data)
            ? widgetData.data.map((data: any) => data.slice)
            : [],
        ),
      ].filter((slice) => slice !== undefined) as string[];
    }
    default: {
      return compact([
        ...new Set(
          Array.isArray(widgetData)
            ? widgetData.map((data: any) => data.slice)
            : [],
        ),
      ]) as string[];
    }
  }
};

export const getWidgetRenderer =
  (
    type: string,
    widgetProps: any,
    options?: any,
    errorText?: string,
    enhancedParams?: {
      dashboardProperties: DashboardProperty[];
      widgetOverriddenProperties: WidgetProperties[];
    },
  ) => () => {
    const widgetData = widgetProps?.data;

    const slices = getSlices(type, widgetData);

    const correctWidgetData = type.includes('HISTOGRAM')
      ? getCorrectWidgetData(widgetData)
      : widgetData;

    if (options?.isLoading) {
      return (
        <div className="circular-progress__container">
          <CustomProgress type="circular" size={50} />
        </div>
      );
    }

    const isEmptyWidgetData = correctWidgetData ? isEmptyData(type, correctWidgetData) : true;

    if (!isNeedToIgnoreEmptyWidgetData(type as WidgetType) && isEmptyWidgetData) {
      return (
        <NoDataTemplate
          withLinkToEditor={options?.withLinkToEditor}
          widgetId={options?.widgetId}
          errorText={errorText}
        />
      );
    }

    switch (type) {
      case WidgetType.HISTOGRAM:
      case WidgetType.HORIZONTAL_HISTOGRAM:
      case WidgetType.STACKED_HISTOGRAM:
      case WidgetType.STACKED_HORIZONTAL_HISTOGRAM:
      case WidgetType.HISTOGRAM_GRAPH:
      case WidgetType.PIE_CHART:
      case WidgetType.DONUT_CHART:
      case WidgetType.GRAPH:
      case WidgetType.GRAPH_AREA:
      case WidgetType.MAP:
      case WidgetType.INFORMATION_CARD:
      case WidgetType.FUNNEL:
      case WidgetType.RADAR_CHART:
      case WidgetType.TREE_MAP:
      case WidgetType.BUBBLE_CHART: {
        return renderWidgetChart({
          widgetType: type,
          widgetProps,
          slices,
          options,
          enhancedParams
        });
      }

      case WidgetType.TABLE: {
        return (
          <ComplexTable
            widgetProps={widgetProps}
            dashboardWidgetState={options?.overriddenProperties}
            setDashboardWidgetState={options?.setOverriddenProperties}
            isLoadingInEditor={options?.isLoading}
            isDrillDown={options?.isDrillDown}
            setFilterField={options?.setFilterField}
            filterField={options?.filterField}
            isActiveFilter={options?.isActiveFilter}
            setMultipleFilterFields={options?.setMultipleFilterFields}
            errorText={errorText}
          />
        );
      }

      case WidgetType.SPEEDOMETER: {
        const isValidData = validator[WidgetType.SPEEDOMETER](widgetData);

        return isValidData ? (
          <Speedometer
            widgetProperties={widgetProps.properties}
            x={widgetData.x}
            y={widgetData.y}
            text=""
            id={widgetProps.id}
          />
        ) : null;
      }

      case WidgetType.PIVOT_TABLE: {
        return <PivotTable
          widgetProps={widgetProps}
          dashboardWidgetState={options?.overriddenProperties}
          setDashboardWidgetState={options?.setOverriddenProperties}
          isDrillDown={options?.isDrillDown}
          setFilterField={options?.setFilterField}
          filterField={options?.filterField}
          isActiveFilter={options?.isActiveFilter}
          setMultipleFilterFields={options?.setMultipleFilterFields}
        />;
      }

      case WidgetType.HTML: {
        return <HtmlChart widgetProps={widgetProps} />;
      }
      case WidgetType.GANTT_CHART: {
        return (
          <Gantt
            setFilterField={options?.setFilterField}
            filterField={options?.filterField}
            isActiveFilter={options?.isActiveFilter}
            widgetProps={widgetProps}
          />
        );
      }

      case WidgetType.SUNBURST: {
        return <SunburstChart widgetProps={widgetProps} />;
      }

      default: {
        return <CustomProgress type="linear" />;
      }
    }
  };

export const getAggregationsForWidgetByAxis = (
  axisName: PanelType,
  properties: Array<any>,
) =>
  getParsedAxisValues(axisName, properties).map((value: any) =>
    new Property(value).getViewAggregation(),
  );

export const getFunctionsForWidgetByAxis = (
  axisName: PanelType,
  properties: Array<any>,
) =>
  getParsedAxisValues(axisName, properties).map((value: any) =>
    new Property(value).getFunction(),
  );

export const getTypesForWidgetByAxis = (
  axisName: PanelType,
  properties: Array<any>,
) =>
  getParsedAxisValues(axisName, properties).map((value: any) =>
    new Property(value).getType(),
  );

export const getStorageForWidgetByAxis = (
  axisName: PanelType,
  properties: Array<any>,
) =>
  getParsedAxisValues(axisName, properties).map((value: any) =>
    JSON.parse(new Property(value).getStorage() || '{}'),
  );

export const getCorrectWidgetData = (widgetData: any[]) => {
  return Array.isArray(widgetData)
    ? widgetData.filter((item: any) => !item.isPropertyValue ?? item)
    : [];
};

export const getLegendHeight = (
  widgetDataLength: number,
  containerHeight: number,
  chartProps: { offset: number; diameter: number },
) => {
  const isHorizontalContainer =
    chartProps.diameter + 2 * chartProps.offset < containerHeight;

  return isHorizontalContainer
    ? 'auto'
    : chartProps.diameter;
};

export const getHistogramDataIndexes = (
  data: any,
  isOverlapHistogram: boolean,
) => {
  if (!isOverlapHistogram) return null;

  return data.map(({ x, y }: any) => {
    const yAndIndexes = y.map((value: any, index: number) => {
      return { y: value, index };
    });

    return sortBy(yAndIndexes, ['y', 'index']).map((item: any) => item.index);
  });
};

export const getHistogramData = (
  data: any,
  isOverlapHistogram: boolean = false,
) => {
  return data.map(({ x, y }: any) => {
    const newY = [...y];
    if (isOverlapHistogram) {
      newY.sort(function (a: number, b: number) {
        return a - b;
      });
    }
    return {
      x,
      ...Object.fromEntries(
        newY?.map((value: any, index: number) => [index, value ?? 0]),
      ),
    };
  });
};

const getStackedOrNormalizedPercentData = (absData: any) => {
  const percentData = absData.map((xData: any) => {
    const { x, ...data } = xData;
    const sumData = Object.values(data).reduce(
      (accumulator: any, currentValue: any) => accumulator + Math.abs(currentValue), 0
    ) as number;
    for (const key in data) {
      if ({}.hasOwnProperty.call(data, key)) {
        data[key] = (data[key] / sumData) * 100;
      }
    }
    return { x, ...data };
  });

  return percentData;
};

const getOverlapPercentData = (absData: any) => {
  const overlapData = absData.map((xData: any) => {
    const { x, ...data } = xData;

    let maxValue = data['0'];
    Object.keys(data).forEach(
      (key) => (maxValue = Math.max(maxValue, Math.abs(data[key]))),
    );

    for (const key in data) {
      if ({}.hasOwnProperty.call(data, key)) {
        data[key] = (Math.abs(data[key]) / Math.abs(maxValue)) * 100;
      }
    }
    return { x, ...data };
  });

  return overlapData;
};

export const getStackedHistogramPercentData = (
  widgetData: any,
  isOverlap: boolean,
) => {
  const absData = getHistogramData(getCorrectWidgetData(widgetData), isOverlap);

  if (isOverlap) {
    const overlapData = getOverlapPercentData(absData);
    return overlapData;
  }

  const percentData = getStackedOrNormalizedPercentData(absData);
  return percentData;
};

export const getStackedHistogramData = (
  widgetData: any,
  isNormalized: boolean,
  isOverlap: boolean,
) => {
  const absData = getHistogramData(getCorrectWidgetData(widgetData), isOverlap);

  if (isNormalized) {
    const normalizedData = getStackedOrNormalizedPercentData(absData);
    return normalizedData;
  }

  return absData;
};

export const getStackedMinMaxTotal = (
  data: any,
  max: boolean,
  isNormalized: boolean,
  isOverlap: boolean,
) => {
  if (isNormalized) return max ? 100 : 0;
  const values = data.map((row: any) =>
    Object.keys(row)
      .filter((d) => d !== 'x')
      .map((key: any) => row[key])
      .reduce((accumulator, currentValue) => {

        return isOverlap
          ? currentValue
          : max
            ? currentValue >= 0
              ? accumulator + currentValue
              : accumulator
            : currentValue < 0
              ? accumulator + currentValue
              : accumulator;
      }, 0),
  );

  if (isOverlap) {
    values.push(0);
  }

  return max ? Math.max(...values) : Math.min(...values);
};

export const isNeedShowFiltersButton = (widget: Widget) =>
  ![WidgetType.TABLE].includes(widget?.type as WidgetType);

export const getHeaderSettings = (widgetProperties: any) => {
  const headerSize = parseInt(
    getInputPropertyValue('headerSize', widgetProperties),
  );

  const descriptionSize = parseInt(
    getInputPropertyValue('descriptionSize', widgetProperties),
  );

  const headerColor: string | undefined = getInputPropertyValue(
    'headerColor',
    widgetProperties,
  ) || undefined;

  const descriptionColor: string | undefined = getInputPropertyValue(
    'descriptionColor',
    widgetProperties,
  ) || undefined;

  const titleLineHeightCoeff = 0.3;
  const subtitleLineHeightCoeff = 0.15;

  const titleSettings = {
    fontSize: `${headerSize}px`,
    lineHeight: `${headerSize + headerSize * titleLineHeightCoeff}px`,
    color: headerColor,
    // ...getTextSkeletonStyles(headerColor || '#000', '#fff'),
  };

  const subtitleSettings = {
    fontSize: `${descriptionSize}px`,
    lineHeight: `${
      descriptionSize + descriptionSize * subtitleLineHeightCoeff
    }px`,
    color: descriptionColor
  };

  return { titleSettings, subtitleSettings };
};

export const getSortedBarMaps = (
  currentColors: string[],
  legendsLabels: string[],
  widgetData: any[],
  barIndex: number,
) => {

  type Bar = {
    label: string,
    value: number,
    color: string
  };

  const unsortedBarsData = getCorrectWidgetData(widgetData);
  const barValues: number[] = unsortedBarsData.map((item) => (item.y))[barIndex];
  const barColors: string[] = [...currentColors];
  const barMaps: Bar[] = legendsLabels.map((item: string, index: number) => (
    {
      label: item,
      value: barValues[index],
      color: barColors[index],
    }
  ));
  return [...barMaps].sort((prev: Bar, next: Bar) => prev.value - next.value);
};

export const isNeedToHiddenContainerOverflow = (widgetType: WidgetType) => {
  return widgetType === WidgetType.PIVOT_TABLE;
};

export const getDateTypeFromFunction = (field?: PropertyData) => {
  return field?.function?.split("'")[1] || DateFunctions.NO_TYPE;
};

export const getConvertedBarGroupsForStackedHist = (barList: any[], columnsCount: number) => {
  return barList.reduce((accumulator: any[], currentValue: any) => {
    const [...resultList] = accumulator;

    if (!resultList[currentValue.index]) {
      resultList[currentValue.index] = [currentValue];
    } else {
      const hasItem = resultList[currentValue.index].some((el: any) => el.index === currentValue.index);

      if (hasItem) {
        resultList[currentValue.index].push(currentValue);
      }
    }

    return resultList;
  }, new Array(columnsCount));
};
