import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, List } from '@material-ui/core';

import block from 'bem-cn';

import { getSourcesFromConnectionIdForNotDataBase } from '../../../../services/sources';
import { useClassButton } from '../../../../hooks/useClassButton';

import TablePreview from '../../table-preview/table-preview';
import { mapConnectionAction } from '../../../../slices/map-connection/map-connection';

import CommonLoader from '../../../common/common-loader/common-loader';
import RightPanel from '../../rigth-panel-common-component/right-panel';
import CreateForm from './create-form';
import SourceItem from '../common/source-item/source-item';

import { State } from '../../../../slices/types';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';

import '../common/css/sources-panel.css';
import { setDeletingAction } from '../../../../slices/main-page/main-page-slice';
import { apiDeleteSource } from '../../../../services/connection';
import DeleteDialog from '../../../../uikit/DeleteDialog/delete-dialog';
import { FileTypesEnum } from '../../../../enums/fyle-type';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../common/icon-svg/icon-svg';

const b = block('sources-panel');

interface Props {
  className?: string;
  onCancel: () => void;
  connectionId: number;
  connectionType: string | null;
  connectionTypeId: number;
}

const ConnectionSoapOdata: React.FC<Props> = ({
  className = '',
  onCancel,
  connectionId,
  connectionType,
  connectionTypeId,
}) => {
  const dispatch = useDispatch();
  const isSaving = useSelector(
    (state: State) => state.connectionFilesList.isSaving,
  );
  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;

  const classButton = useClassButton();

  const [filter, setFilter] = useState<string>('');
  const [files, setFiles] = useState<{ name: string; id: number }[]>([]);
  const [previewName, setPreviewName] = useState<string | null>(null);
  const [itemChangeName, setItemChangeName] = useState<string | null>(null);
  const [itemChangeSourceId, setItemChangeSourceId] = useState<number | null>(
    null,
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isShowPanel, setIsShowPanel] = useState<boolean>(false);
  const [checkedFiles, setCheckedFiles] = useState<string[]>([]);
  const [currentFileId, setCurrentFileId] = useState<number | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const checkedFilesSourceIdList = checkedFiles.map(
    (name) => files.find((file) => name.includes(file.name))?.id || 0,
  );

  const handleToggle = (file: string) => () => {
    const currentIndex = checkedFiles.indexOf(file);
    const newChecked = [...checkedFiles];

    if (currentIndex === -1) {
      newChecked.push(file);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFiles(newChecked);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog((prevState) => !prevState);
  };

  const onSave = () => {
    dispatch(
      mapConnectionAction.addSourceObjectsSoapOdata({
        loaderId,
        sourceIdList: checkedFilesSourceIdList,
        connectionId,
        tableNames: checkedFiles,
        fileType: connectionType as FileTypesEnum,
        callback: onCancel,
        errorCallback: e => setError(e?.response?.data?.message)
      }),
    );
  };

  const closePreview = () => {
    setPreviewName('');
  };

  const handlerChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => setFilter(target.value.toLowerCase()),
    [],
  );

  const openPanel = () => setIsShowPanel(true);
  const closePanel = () => {
    setIsShowPanel(false);
    setItemChangeName(null);
  };

  const handleNewQueryClick = () => {
    setPreviewName(null);
    setItemChangeName(null);
    setItemChangeSourceId(0);
    openPanel();
  };

  const getConnectionFiles = useCallback(() => {
    getSourcesFromConnectionIdForNotDataBase(connectionId)
      .then((response) => {
        setLoading(false);
        setFiles(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Request failed:', error);
      });
  }, [connectionId]);

  const handlePreviewClick = (name: string) => () => {
    setPreviewName(name);
  };

  const handleDeleteClick = async () => {
    try {
      dispatch(setDeletingAction(true));
      setLoading(true);
      await apiDeleteSource(currentFileId as number);
      toggleDeleteDialog();
      getConnectionFiles();
    } catch (err: any) {
      setError(
        `Данное соединение используют следующие загрузчики:${err?.response?.data?.message}`,
      );
    } finally {
      dispatch(setDeletingAction(false));
      setLoading(false);
    }
  };

  const handleFileDeleteClick = (id: number) => () => {
    toggleDeleteDialog();
    setCurrentFileId(id);
  };

  const handleFileChangeClick = (name: string) => () => {
    closePanel();
    setItemChangeName(name);
    const fileChange = files.find((file) => file.name === name);
    const sourceId = fileChange?.id;

    sourceId && setItemChangeSourceId(sourceId);
    openPanel();
  };

  useEffect(() => {
    setFiles([]);
    setCheckedFiles([]);
    setPreviewName(null);
    closePanel();
    setLoading(true);
    getConnectionFiles();
  }, [getConnectionFiles]);

  useEffect(() => {
    previewName && setItemChangeName(null);
    previewName && closePanel();
  }, [previewName]);

  useEffect(() => {
    itemChangeName && setPreviewName(null);
  }, [itemChangeName]);

  const filteredFilesList = files.filter(
    ({ name }: any) => !filter || name.toLowerCase().indexOf(filter) !== -1,
  );

  const deleteFileName = filteredFilesList.find(
    (file) => file.id === currentFileId,
  )?.name;

  return (
    <div className={`${b()} ${className}`}>
      <div className={b('title-container')}>
        <h3 className={b('title')}>Список запросов</h3>

        <Button
          className={classButton.linkDotted}
          startIcon={<IconSvg svg={IconDictionary.PlusRounded} fill="var(--primary-color)" />}
          onClick={handleNewQueryClick}
          disableRipple
        >
          <span className="button-text">Новый запрос</span>
        </Button>
      </div>

      <TextInput
        label="Быстрый поиск"
        fullWidth
        handleChange={handlerChangeFilter}
        searchIcon
      />

      <div className={b('files-section')}>
        {isLoading && <CustomProgress type="linear" />}

        <List>
          {filteredFilesList.map((file) => (
            <SourceItem
              key={file.name}
              id={file.id}
              item={file.name}
              checkedFiles={checkedFiles}
              handleToggle={handleToggle}
              previewName={previewName}
              onPreviewClick={handlePreviewClick}
              fileChangeName={itemChangeName}
              onFileChangeClick={handleFileChangeClick}
              onFileDeleteClick={handleFileDeleteClick}
            />
          ))}
        </List>
      </div>

      <div className={b('buttons')}>
        <Button className={classButton.primary} type="submit" onClick={onSave}>
          Добавить
        </Button>
        <Button className={classButton.default} type="reset" onClick={onCancel}>
          Отменить
        </Button>
      </div>

      {previewName && (
        <TablePreview
          className={b('table-preview')}
          name={previewName}
          connectionId={connectionId}
          onClose={closePreview}
        />
      )}

      {isShowPanel && (
        <RightPanel className={b('right-panel')} handlePanelClose={closePanel}>
          <CreateForm
            loaderId={loaderId}
            connectionId={connectionId}
            connectionType={connectionType}
            connectionTypeId={connectionTypeId}
            onCancel={closePanel}
            closeSourcePanel={onCancel}
            isEdit={Boolean(itemChangeName)}
            itemSourceId={itemChangeSourceId}
            itemChangeName={itemChangeName}
            getConnectionFiles={getConnectionFiles}
          />
        </RightPanel>
      )}

      {isSaving && <CommonLoader />}

      <DeleteDialog
        isOpen={showDeleteDialog}
        onClose={toggleDeleteDialog}
        onDelete={handleDeleteClick}
        title="Удаление файла"
        bodyText={`файл ${deleteFileName}`}
        isLoading={isLoading}
        maxWidth="xs"
        error={error}
      />
    </div>
  );
};

export default ConnectionSoapOdata;
