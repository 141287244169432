import { RefObject, useEffect } from 'react';
import { VariableSizeList } from 'react-window';

export function useHorizontalScroll<
  C extends HTMLElement = HTMLDivElement,
>(containerRef: RefObject<C>) {

  useEffect(() => {

    const container = containerRef.current;

    const addScrollHandler = (event: any) => {
      // Проверяем, если нажата клавиша Shift (для горизонтальной прокрутки)
      if (event.shiftKey && container) {
        // Отключаем вертикальную прокрутку страницы
        event.preventDefault();

        // Прокручиваем контейнер
        container.scrollLeft += event.deltaY;
      }
    };

    container && container.addEventListener('wheel', addScrollHandler);

    return () => {
      container && container.removeEventListener('wheel', addScrollHandler);
    };
  }, [containerRef]);
}


export function useHorizontalScrollReactWindow<
  C extends HTMLElement = HTMLDivElement,
>(containerRef: RefObject<C>, rowsList: RefObject<VariableSizeList>) {

  useEffect(() => {

    const container = containerRef.current;

    const addScrollHandler = (event: any) => {
      // Проверяем, если нажата клавиша Shift (для горизонтальной прокрутки)
      if (event.shiftKey) {
        // Отключаем вертикальную прокрутку страницы
        event.preventDefault();

        // Прокручиваем контейнер
        const rowsListEl = (rowsList.current as unknown as any)._outerRef as HTMLDivElement;

        rowsListEl.scrollLeft += event.deltaY;
      }
    };

    container && container.addEventListener('wheel', addScrollHandler);

    return () => {
      container && container.removeEventListener('wheel', addScrollHandler);
    };
  }, [containerRef, rowsList]);
}
