import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../../slices/types';
import { getFilterSettingValueFromDashboardProperties } from '../../../../../helpers/dashboard-page/filters-helper';
import { SettingItemProps } from './setting-item';
import { useDashboardFilters } from '../../../hooks/useDashboardFilters';
import {
  DashboardPropertyType,
  FilterProperty, TextFilterModeSelect,
} from '../../../../../enums/dashboard-properties';
import { setDashboardPropAction } from '../../../../../slices/dashboard/dashboard';

export const useSettingItem = ({
  setting,
  filtersGroupId,
}: SettingItemProps) => {
  const dispatch = useDispatch();

  const dashboardProperties = useSelector(
    (state: State) => state.dashboard.properties,
  );
  const settingValue = getFilterSettingValueFromDashboardProperties(
    dashboardProperties,
    setting.name,
    filtersGroupId,
  );

  const { values: dashboardFiltersGroups } = useDashboardFilters();

  const changeFilterSetting = (
    settingName: FilterProperty,
    value: string | boolean,
  ) => {
    const groupIndex = dashboardFiltersGroups.findIndex(
      (filterGroup) => filterGroup.id === filtersGroupId,
    );

    if (groupIndex < 0) return;

    const newDashboardFiltersGroups = [...dashboardFiltersGroups];
    const settingIndex = dashboardFiltersGroups[
      groupIndex
    ].filterProperties.findIndex((property) => property.name === settingName);

    newDashboardFiltersGroups[groupIndex].filterProperties[settingIndex] = {
      ...newDashboardFiltersGroups[groupIndex].filterProperties[settingIndex],
      value,
    };

    if (settingName === FilterProperty.textFilterMode && value === TextFilterModeSelect.SingleSelect) {
      newDashboardFiltersGroups[groupIndex].value = [newDashboardFiltersGroups[groupIndex].value?.[0]].filter(Boolean);
    }

    dispatch(
      setDashboardPropAction({
        name: DashboardPropertyType.filters,
        value: JSON.stringify(newDashboardFiltersGroups),
      }),
    );
  };

  return {
    settingValue,
    changeFilterSetting
  };
};
