import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { GridOnScrollProps, VariableSizeGrid } from 'react-window';
import { Collection } from 'react-virtualized';
import { isEqual } from 'lodash';
import { getScaleCoefficient } from '../../../../../helpers/ui-helper';
import { usePivotTableProperties } from './usePivotTableProperties';
import {
  getCellByHeadLevelAndIndex,
  getCellIndexes,
  getDefaultClosedColumns,
  getFiltersValuesForDrilldown,
  getHeaderWithoutMiddleTotals,
  isOpenColumn,
} from '../helper';
import { HeadCell } from '../components/head/head-cell';
import { HeadButtonCell } from '../components/head/head-button-cell';
import { PanelType } from '../../../../../enums/widget-type';
import { PivotData, PivotRow, PivotTableProps } from '../interfaces';
import { usePivotTableSettings } from './usePivotTableSettings';
import { mergeWidgetProperties } from '../../../../../utils/functions';
import { isDashboardPage } from '../../helpers';
import { cellHeight } from '../settings';
import { useWidgetMultipleFilters } from '../../../../../hooks/charts/useWidgetMultipleFilters';

export const usePivotTable = ({
  widgetProps,
  dashboardWidgetState,
  setDashboardWidgetState,
  isDrillDown,
  isActiveFilter,
  setMultipleFilterFields,
}: PivotTableProps) => {
  const staticBodyGrid = React.useRef<VariableSizeGrid>(null);
  const dynamicBodyGrid = React.useRef<VariableSizeGrid>(null);
  const staticHeaderGrid = React.useRef<VariableSizeGrid>(null);
  const dynamicHeaderCollection = React.useRef<Collection>(null);

  const isDashboard = isDashboardPage();

  const widgetProperties = useMemo(() => {
    if (isDashboard) {
      const newWidgetProps = { ...widgetProps };
      newWidgetProps.properties = mergeWidgetProperties(
        widgetProps.properties,
        dashboardWidgetState,
      );

      return newWidgetProps.properties;
    }

    return widgetProps.properties;
  }, [isDashboard, dashboardWidgetState, widgetProps]);

  const {
    isNeedToDisplayMiddleTotal,
    isNeedToDisplayTotal,
    valueSize,
    axisZValues,
    axisYValues,
    axisXValues,
  } = usePivotTableProperties({ widgetProperties });

  const [scrollLeft, setScrollLeft] = React.useState(0);
  const [closedColumns, setClosedColumns] = useState<string[]>(
    getDefaultClosedColumns(widgetProps.data.header, axisZValues.length),
  );

  const [selectedFilters, setSelectedFilters] = useState<{
    filterValues: any[];
    filterIds: number[];
  } | null>(null);

  const { selectData } = useWidgetMultipleFilters({
    setMultipleFilterFields,
    isActiveFilter,
    widgetId: widgetProps.id,
  });

  const handleClickCell = useCallback(
    (cellId: string, row: PivotRow) =>
      (event: React.MouseEvent<HTMLTableRowElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const { filterValues, filterIds } = getFiltersValuesForDrilldown(
          cellId,
          {
            axisXValues,
            axisYValues,
            axisZValues,
          },
          row,
          widgetProps.data.header,
        );

        if (filterIds.length) {
          const isSameCell = isEqual({ filterValues, filterIds }, selectedFilters);
          if (isSameCell) {
            setSelectedFilters(null);
          } else {
            setSelectedFilters({ filterValues, filterIds });
          }
          selectData(filterValues, filterIds, isSameCell);
        }
      },
    [axisXValues, axisYValues, axisZValues, selectData, selectedFilters, widgetProps.data.header],
  );

  const onScrollStatic = (props: GridOnScrollProps) => {
    dynamicBodyGrid.current &&
      dynamicBodyGrid.current.scrollTo({
        scrollTop: props.scrollTop,
      });
  };
  const onScrollDynamic = (props: GridOnScrollProps) => {
    if (staticBodyGrid.current) {
      staticBodyGrid.current.scrollTo({ scrollTop: props.scrollTop });
    }

    setScrollLeft(props.scrollLeft);
  };

  const getHeadCellForTableWithoutColumns = (
    index: number,
    key: number,
    style: CSSProperties,
  ) => {
    const cell = widgetData.header[0][index];
    if (!cell) return null;

    const cellExtended = { ...cell, isLastChild: true };

    return (
      <HeadCell
        key={key}
        cell={cellExtended}
        widgetProperties={widgetProperties}
        style={style}
        changeDisplayNestedColumn={changeDisplayNestedColumn}
        isOpenColumn={!closedColumns.includes(cell.id)}
        setDashboardWidgetState={setDashboardWidgetState}
      />
    );
  };

  const headerCellRenderer = ({
    index,
    key,
    style,
  }: {
    index: number;
    key: number;
    style: CSSProperties;
  }) => {
    if (!isPivotWithColumns)
      return getHeadCellForTableWithoutColumns(index, key, style);

    if (!index)
      return (
        <HeadButtonCell
          widgetProperties={widgetProperties}
          widgetId={widgetProps.id}
          panelType={PanelType.axisZ}
          setDashboardWidgetState={setDashboardWidgetState}
          style={style}
          isDrillDown={isDrillDown}
        />
      );

    const { rowIndex, sumCellsCountBeforeCurrentLevel } = getCellIndexes(
      index,
      columnsCount,
    );

    const cell = getCellByHeadLevelAndIndex(
      widgetData.header,
      rowIndex,
      index,
      sumCellsCountBeforeCurrentLevel,
      closedColumns,
    );
    if (!cell) return null;

    return (
      <HeadCell
        key={key}
        cell={cell}
        widgetProperties={widgetProperties}
        style={style}
        changeDisplayNestedColumn={changeDisplayNestedColumn}
        isOpenColumn={isOpenColumn(cell.parentId, cell.id, closedColumns)}
        setDashboardWidgetState={setDashboardWidgetState}
      />
    );
  };

  const widgetData: PivotData = {
    rows: widgetProps.data.rows,
    header: isNeedToDisplayMiddleTotal
      ? widgetProps.data.header
      : getHeaderWithoutMiddleTotals(widgetProps.data.header, closedColumns),
  };

  const clearTableCash = useCallback(() => {
    staticBodyGrid.current && staticBodyGrid.current.resetAfterColumnIndex(0);
    staticBodyGrid.current && staticBodyGrid.current.resetAfterRowIndex(0);

    staticHeaderGrid.current &&
      staticHeaderGrid.current.resetAfterColumnIndex(0);
    staticHeaderGrid.current && staticHeaderGrid.current.resetAfterRowIndex(0);

    dynamicBodyGrid.current && dynamicBodyGrid.current.resetAfterColumnIndex(0);
    dynamicBodyGrid.current && dynamicBodyGrid.current.resetAfterRowIndex(0);

    dynamicHeaderCollection.current &&
      dynamicHeaderCollection.current.recomputeCellSizesAndPositions();
  }, [
    staticBodyGrid,
    dynamicBodyGrid,
    staticHeaderGrid,
    dynamicHeaderCollection,
  ]);

  const clearTableBodyCashForRows = useCallback(() => {
    staticBodyGrid.current && staticBodyGrid.current.resetAfterRowIndex(0);
    dynamicBodyGrid.current && dynamicBodyGrid.current.resetAfterRowIndex(0);
  }, [staticBodyGrid, dynamicBodyGrid]);

  const cellWithContentHeight = cellHeight + valueSize * getScaleCoefficient();

  const view = {
    fontSize: valueSize * getScaleCoefficient(),
  };

  const {
    columnsCount,
    mainColumnsCount,
    headerCellsCount,
    headerHeight,
    columnsIds,
    cellSizeAndPositionGetter,
    containerRef,
    columnsWidth,
    offsetColumnWidth,
    changeDisplayNestedRow,
    closedRows,
    isOpenRow,
    isPivotWithColumns,
    isNeedToDisplayTooltip,
    changeDisplayNestedColumn,
  } = usePivotTableSettings({
    closedColumns,
    setClosedColumns,
    widgetData,
    widgetProperties,
    clearTableCash,
    clearTableBodyCashForRows,
    widgetId: widgetProps.id,
  });

  return {
    containerRef,
    staticHeaderGrid,
    columnsWidth,
    offsetColumnWidth,
    headerHeight,
    widgetProperties,
    axisYValues,
    dynamicHeaderCollection,
    headerCellsCount,
    isNeedToDisplayTotal,
    headerCellRenderer,
    cellSizeAndPositionGetter,
    scrollLeft,
    staticBodyGrid,
    onScrollStatic,
    widgetData,
    changeDisplayNestedRow,
    closedRows,
    dynamicBodyGrid,
    onScrollDynamic,
    mainColumnsCount,
    columnsIds,
    isOpenRow,
    isNeedToDisplayTooltip,
    cellWithContentHeight,
    view,
    handleClickCell,
  };
};
