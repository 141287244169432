import React, { useCallback } from 'react';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SelectOptions, SelectOptionsMeta } from '../../../../type';
import IconSvg from '../../../../../../common/icon-svg/icon-svg';
import Validation from '../../../../validation/type';
import { Operation } from '../../../../../../../enums/operation';
import { IconDictionary } from '../../../../../../../dictionaries/icon-dictonary/icon-dictionary';
import { getIconByDataType } from '../../../../../../widget-page/helpers';

const useStyles = makeStyles({
  'field-svg': {
    marginRight: '15px',
  },
  'menu-item': {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    color: 'var(--title-color)',
  },
});

interface TargetSelectFieldProps {
  i: any;
  targetFieldName: any;
  handleSelectChange: (e: any, i: any, type: string) => void;
  selectOptions: SelectOptions;
  selectOptionsMeta: SelectOptionsMeta;
  targetId: any;
  isNewTarget: boolean;
  validation: Validation.Data;
  setEditableFields: any;
  operationType: string;
}

const TargetSelectField: React.FC<TargetSelectFieldProps> = ({
  i,
  targetFieldName,
  handleSelectChange,
  selectOptions,
  selectOptionsMeta,
  targetId,
  isNewTarget,
  validation,
  operationType,
  setEditableFields,
}) => {
  const classes = useStyles();
  const getType = (option: string) => selectOptionsMeta[targetId][option]?.type;

  const handlePlusClick = useCallback(
    (index: any) => () => {
      const editableField = { [index]: { name: '' } };
      setEditableFields(editableField);
    },
    [setEditableFields],
  );

  const handleEditClick = useCallback(
    (index: any, value: string) => () => {
      const editableField = { [index]: { name: value } };
      setEditableFields(editableField);
    },
    [setEditableFields],
  );
  const errorFill = validation.values?.fill || {};
  const errorDuplicate = validation.values?.duplicate || {};
  const errorTypes = validation.values?.types || {};
  return (
    <TextField
      id={`${i}`}
      error={errorFill[i]?.target || errorDuplicate[i]?.target || errorTypes[i]?.target}
      select
      value={targetFieldName}
      variant="outlined"
      disabled={isNewTarget}
      onChange={(e) => {
        handleSelectChange(e, i, 'target');
      }}
      InputProps={{
        endAdornment: targetFieldName ? (
          isNewTarget ? (
            <InputAdornment
              onClick={handleEditClick(i, targetFieldName)}
              position="end"
            >
              <div className="select-wrapper select-wrapper-disabled">
                <IconButton>
                  <IconSvg
                    svg={IconDictionary.ListEdit}
                    fill="var(--primary-color)"
                  />
                </IconButton>
              </div>
            </InputAdornment>
          ) : null
        ) : !isNewTarget ? (
          <InputAdornment onClick={handlePlusClick(i)} position="end">
            {operationType === Operation.Map && (
              <div className="select-wrapper">
                <IconButton>
                  <IconSvg
                    svg={IconDictionary.PlusRounded}
                    fill="var(--dark-grey)"
                  />
                </IconButton>
              </div>
            )}
          </InputAdornment>
        ) : null,
      }}
    >
      {selectOptions[targetId].map((option) => (
        <MenuItem key={option} value={option}>
          <div className={classes['menu-item']}>
            {option && (
              <IconSvg
                classSVG={classes['field-svg']}
                svg={getIconByDataType(getType(option))}
                fill="var(--dark-grey)"
              />
            )}
            {option}
          </div>
        </MenuItem>
      ))}
      {!selectOptions[targetId].includes(targetFieldName) &&
        targetFieldName !== 'unknown' && (
          <MenuItem key={targetFieldName} value={targetFieldName}>
            <div className={classes['menu-item']}>
              {targetFieldName && (
                <IconSvg
                  classSVG={classes['field-svg']}
                  svg={getIconByDataType(getType(targetFieldName))}
                  fill="var(--dark-grey)"
                />
              )}
              {targetFieldName}
            </div>
          </MenuItem>
      )}
    </TextField>
  );
};

export default TargetSelectField;
