import { apiBI, checkAccess } from '../utils/api';
import Dictionaries from '../types/dictionaries';

export const getAllGroups = async (filters?: {
  search: string;
  groupIds: number[];
}) => {
  const params = filters ? {
    search: filters.search ? filters.search : undefined,
    groupIds: filters.groupIds.length ? filters.groupIds.join(',') : undefined,
  } : undefined;

  try {
    const response = await apiBI.get('groups/', { params });
    return response;
  } catch (error: any) {
    checkAccess(error);

    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const getAllGroupsWithMap = async (loaderId: number) => {
  try {
    const response = await apiBI.get(`groups/maps?loader_id=${loaderId}`);
    return response;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const getHeaderInfo = async () => {
  const mock = {
    logo:
      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
    favicon:
      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
    title: 'Development',
    subtitle: 'Development',
    tiles: [
      {
        dictionaryId: 1,
        disabled: false,
        img:
          'http://192.168.32.189:8080/assets/images/main-menu/visualization.svg',
        link: 'http://192.168.32.189/#/',
        name: 'Представление данных',
      },
      {
        dictionaryId: 3,
        disabled: false,
        img:
          'http://192.168.32.189:8080/assets/images/main-menu/visualization.svg',
        link: 'http://192.168.32.189:8181/#!/dashboard',
        name: 'Информационное взаимодействие',
      },
      {
        dictionaryId: 5,
        disabled: false,
        img:
          'http://192.168.32.189:8080/assets/images/main-menu/visualization.svg',
        link: 'http://192.168.32.189:8181/#!/dashboard',
        name: 'Тест тест',
      },
      {
        dictionaryId: 7,
        disabled: false,
        img:
          'http://192.168.32.189:8080/assets/images/main-menu/visualization.svg',
        link: 'http://192.168.32.189:8181/#!/dashboard',
        name: 'Новая строка',
      },
    ],
  };

  try {
    // const { data } = await apiETL.get('/look/header-info/');
    // return data;
    return mock;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    return mock;
  }
};

export const getSVGIcon = async (link: string) => {
  const mockImg =
    '<svg width="48" height="57"><rect width="48" height="57" style="fill:rgb(0,0,255);stroke-width:0;stroke:rgb(255,255,255)" /></svg>';
  const file = await fetch(link)
    .then((res) => res.text())
    .catch((error) => {
      console.error(`Request failed: ${error}`);
      return mockImg;
    });
  return file;
};

export const getUserInfo = async () => {
  try {
    const { data } = await apiBI.get('users/active');
    return data;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
  }
};

export const getSystemSettings = async () => {
  try {
    const { data } = await apiBI.get('settings');
    return data;
  } catch (error: any) {
    checkAccess(error);
    console.error(`Request failed: ${error}`);
  }
};

export const createNewGroup = async (name: string) => {
  try {
    const response = await apiBI.post('groups/', { name });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const changeGroupName = async (id: number, name: string) => {
  try {
    const response = await apiBI.put('groups/', { id, name });

    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
};

/* Удаление группы */
export const apiDeleteGroup = async (groupId: number | null) => {
  try {
    const response = await apiBI.delete(`groups/${groupId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
};

export const sendLogout = async () => {
  try {
    const response = await apiBI.get('auth/logout-url');
    return response;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const fileDownload = async (id: number) => {
  try {
    const response = await apiBI.get(`file/${id}`);
    return response;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const apiGetColorPalettes = async () => {
  try {
    const response = await apiBI.get('/dictionaries/properties/colorPalette');
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const apiGetMapIcons = async () => {
  try {
    const response = await apiBI.get('/dictionaries/properties/mapIcons');
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const apiSetColorPalettes = async (palettes: Dictionaries.ColorPalette[]) => {
  try {
    const response = await apiBI.put('/dictionaries/properties/colorPalette/options', palettes.map(palette => JSON.stringify(palette)));
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const apiDeleteRequests = async (requestIds: string[]) => {
  try {
    const response = await apiBI.post('/spark/cancel_requests', requestIds);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};
