import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { CSSProperties } from 'react';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';
import { WidgetSimplifiedDataType } from '../../enums/data-type';
import { DateNames } from '../../enums/date';
import { LegendPositions } from '../../enums/legend';
import { PanelType, WidgetType } from '../../enums/widget-type';
import {
  isAxisWithBookmarks,
  isMultipleAxisXWithBookmarks,
  isMultipleAxisYWithBookmarks
} from '../../helpers/common-helpers';
import { WidgetProperties } from '../../slices/types';
import { getEnumKeys, replaceAll } from '../../utils/functions';
import { getDefaultOrderDirection, getParsedAxisValues, getSimplifiedType } from './dropdown-layout/helpers/helpers';
import {
  axisAdditionBreakeRules,
  getPropertiesWithTableHeader,
  isSingleProperty
} from './dropdown-layout/helpers/itemset-helpers';
import { PropertyData } from './dropdown-layout/helpers/Property';


export const getIconByDataType = (type: any) => {
  const simplifiedType = getSimplifiedType(type);

  switch (simplifiedType) {
    case  WidgetSimplifiedDataType.DATE:
      return IconDictionary.TimeDataType;
    case WidgetSimplifiedDataType.NUMBER:
      return IconDictionary.NumberDataType;
    case WidgetSimplifiedDataType.BOOLEAN:
      return IconDictionary.BooleanDataType;
    case WidgetSimplifiedDataType.TEXT:
      return IconDictionary.TextDataType;
    default:
      return IconDictionary.UnknownDataType;
  }
};

type CSSForLegendPositionType = {
  [key: string]: CSSProperties;
};

export const CSSForLegendPosition: CSSForLegendPositionType = {
  [LegendPositions.Left]: {
    flexDirection: 'row',
  },
  [LegendPositions.Right]: {
    flexDirection: 'row-reverse',
  },
  [LegendPositions.Down]: {
    flexDirection: 'column-reverse',
  },
  [LegendPositions.Up]: {
    flexDirection: 'column',
  },
};

export const getAxisWithRestriction = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.PIVOT_TABLE:
      return ['axisX', 'axisZ'];
    default:
      return ['axisX', 'axisY', 'axisZ'];
  }
};


const getDefaultPossibleDeleteAggregationTypes = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.SPEEDOMETER:
      return [WidgetSimplifiedDataType.TEXT];
    case WidgetType.TABLE:
      return [
        WidgetSimplifiedDataType.DATE,
        WidgetSimplifiedDataType.TEXT,
        WidgetSimplifiedDataType.NUMBER,
        WidgetSimplifiedDataType.BOOLEAN,
      ];
    default:
      return [WidgetSimplifiedDataType.DATE, WidgetSimplifiedDataType.TEXT];
  }
};

export const getPossibleDeleteAggregationTypes = (panelType: PanelType, widgetType: WidgetType) => {
  switch (panelType) {
    case PanelType.axisFilter:
      return [
        WidgetSimplifiedDataType.DATE,
        WidgetSimplifiedDataType.TEXT,
        WidgetSimplifiedDataType.NUMBER,
        WidgetSimplifiedDataType.BOOLEAN,
      ];
    default:
      return getDefaultPossibleDeleteAggregationTypes(widgetType);
  }
};

export const getAxisWithColoringSetting = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.PIVOT_TABLE:
    case WidgetType.HISTOGRAM:
    case WidgetType.HORIZONTAL_HISTOGRAM:
    case WidgetType.BUBBLE_CHART:
    case WidgetType.HISTOGRAM_GRAPH:
      return ['axisY'];
    case WidgetType.TABLE:
      return ['axisX'];
    default:
      return [];
  }
};

export const getAxisWithRenameSetting = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.TABLE:
      return ['axisX'];
    case WidgetType.HISTOGRAM:
    case WidgetType.HORIZONTAL_HISTOGRAM:
    case WidgetType.STACKED_HISTOGRAM:
    case WidgetType.STACKED_HORIZONTAL_HISTOGRAM:
    case WidgetType.GRAPH:
    case WidgetType.DONUT_CHART:
    case WidgetType.TREE_MAP:
    case WidgetType.BUBBLE_CHART:
    case WidgetType.FUNNEL:
    case WidgetType.RADAR_CHART:
      return ['axisX', 'axisY'];
    case WidgetType.INFORMATION_CARD:
    case WidgetType.HISTOGRAM_GRAPH:
      return ['axisY', 'axisZ'];
    case WidgetType.PIE_CHART:
    case WidgetType.GRAPH_AREA:
    case WidgetType.PIVOT_TABLE:
      return ['axisX', 'axisY', 'axisZ'];
    default:
      return [];
  }
};

export const getAggregationOfFieldForPanelItemSettings = (
  panelName: PanelType,
) => {
  switch (panelName) {
    case PanelType.axisX:
    case PanelType.axisY:
      return 'SUM(%s)';
    case PanelType.axisZ:
      return 'COUNT (DISTINCT %s)';
    default:
      return '';
  }
};

export const getGraphFilteredValues = (
  isNeedAxisXDateLabelAggregation: boolean,
  widgetData: any[],
  dateLabelAggregation: keyof typeof DateNames
) => {
  const isValidDate = moment(widgetData[0]?.x).isValid();
  if (!isNeedAxisXDateLabelAggregation || !isValidDate) return widgetData;
  const properDateType = DateNames[dateLabelAggregation];
  const uniqueDatesByCurrentAggregation: any[] = [];
  const result = widgetData.filter((value) => {
    const currentValue = moment(value.x).startOf(properDateType).valueOf();
    if (!uniqueDatesByCurrentAggregation.includes(currentValue)) {
      uniqueDatesByCurrentAggregation.push(currentValue);
      return true;
    }
  });
  return result;
};

export const isFilledRequiredAxes = (widgetType: WidgetType, { ...axes }) => {
  const {
    axisXCount,
    axisYCount,
    axisZCount,
    nameStageCount,
    dateEndStageCount,
  } = axes;

  const widgetDataResetRules: { [key: string]: boolean } = {
    [WidgetType.TABLE]: axisXCount <= 0,
    [WidgetType.HISTOGRAM]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.HORIZONTAL_HISTOGRAM]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.STACKED_HISTOGRAM]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.STACKED_HORIZONTAL_HISTOGRAM]:
      axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.HISTOGRAM_GRAPH]:
      axisXCount <= 0 || (axisYCount <= 0 && axisZCount <= 0),
    [WidgetType.GRAPH]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.GRAPH_AREA]: axisXCount <= 0 || (axisYCount <= 0 && axisZCount <= 0),
    [WidgetType.PIE_CHART]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.DONUT_CHART]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.TREE_MAP]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.SPEEDOMETER]: axisZCount <= 0 || axisYCount <= 0,
    [WidgetType.MAP]: axisXCount <= 0,
    [WidgetType.GANTT_CHART]:
      axisXCount <= 0 ||
      axisYCount <= 0 ||
      nameStageCount <= 0 ||
      dateEndStageCount <= 0,
    [WidgetType.PIVOT_TABLE]:
      axisYCount <= 0 || axisXCount <= 0,
    [WidgetType.INFORMATION_CARD]: axisZCount <= 0 && axisYCount <= 0,
    [WidgetType.FUNNEL]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.RADAR_CHART]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.SUNBURST]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.BUBBLE_CHART]: axisXCount <= 0 || axisYCount <= 0,
    [WidgetType.NONE]: true,
  };

  return !widgetDataResetRules[widgetType];
};

export const getPanelsForSourcesCheck = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.MAP:
      return [PanelType.axisX];
    default:
      return [];
  }
};

export const getWidgetFilterName = (property: PropertyData) => {
  const storage = property.storage ? JSON.parse(property.storage) : null;
  const alias = storage?.alias ? replaceAll(storage.alias, '\\n', ' ') : '';

  return alias || property.name;
};

export const getPropertyId = (property: any) => {
  return property.id || property.widgetFieldId;
};

export const isNeedToIgnoreEmptyWidgetData = (type: WidgetType) => {
  return type === WidgetType.HTML ||
    type === WidgetType.TABLE;
};

export const prepareAxisChanges = (
  widgetProps: WidgetProperties[],
  type: WidgetType,
  axis: PanelType,
) => {
  const settingProps = [...widgetProps];

  const axisValue: PropertyData[] = getParsedAxisValues(axis, settingProps);

  const isMapWidgetType = type === WidgetType.MAP;

  if (!isEmpty(axisValue)) {
    axisValue[0].orderDirection = getDefaultOrderDirection(
      type as WidgetType,
      axis,
    );
    const axisIndex = settingProps.findIndex((item: any) => item.name === axis);

    // if (axis === PanelType.axisX && type === WidgetType.TABLE) {
    //   settingProps = getPropertiesWithTableHeader(settingProps);
    // }

    let axisValueActiveIndexes = axisValue
      .map((value, index) => (value.isActive ? index : -1))
      .filter((index) => index !== -1);

    if (axis === PanelType.axisX && !isMultipleAxisXWithBookmarks(type)) {
      axisValueActiveIndexes = [axisValueActiveIndexes[0] ?? -1];
    }

    if (axisValueActiveIndexes.length && axis === PanelType.axisY && !isMultipleAxisYWithBookmarks(type)) {
      axisValueActiveIndexes = [axisValueActiveIndexes[0] ?? -1];
    }

    if (axisValueActiveIndexes.length === 0) {
      axisValueActiveIndexes.push(0);
    }

    const axisXValueActive = isAxisWithBookmarks(type, axis)
      ? axisValue.map((value, index) => ({
        ...value,
        isActive: axisValueActiveIndexes.includes(index),
      }))
      : axisValue.map((value) => ({ ...value, isActive: true }));

    if (isMapWidgetType) {
      settingProps[axisIndex].value = '';
    } else {

      settingProps[axisIndex].value = JSON.stringify(axisXValueActive);
    }
  }

  return settingProps;
};

export const prepareAxisRestrictions = (
  widgetProps: WidgetProperties[],
  widgetType: WidgetType,
) => {
  const settingProps = cloneDeep(widgetProps);

  const keys = getEnumKeys(PanelType);

  keys.forEach((axisKey) => {
    const axis = PanelType[axisKey as keyof typeof PanelType];

    const axisIndex = settingProps.findIndex((item: any) => item.name === axis);

    const values: PropertyData[] = JSON.parse(
      settingProps[axisIndex]?.value || '[]',
    );

    if (!Array.isArray(values) || !values.length) return;

    const filteredValues = values.filter((value) =>
      !axisAdditionBreakeRules.some((axisRule) => {
        if (axisRule.isOnlyForNewField) return false;
        const needBrake = axisRule.checkRule({
          widgetType,
          panelType: PanelType[axis as keyof typeof PanelType],
          field: value,
          fields: values,
        });
        return needBrake;
      },
      ),
    ).filter((value, index) => index === 0 || !isSingleProperty(widgetType, axis));

    settingProps[axisIndex].value = JSON.stringify(filteredValues);
  });

  return settingProps;
};
