import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  apigetDashboardById,
  apiUpdateDashboard,
} from '../../../../services/dashboardController';
import { apiUpdateWidget } from '../../../../services/widgetController';
import {
  setCurrentProjectDashboardsAction,
  setCurrentProjectWidgetsAction,
} from '../../../../slices/main-page/main-page-slice';
import { Dashboard, DashboardRequest, DashboardResponse, State, Widget } from '../../../../slices/types';
import { setCurrentWidgetAction } from '../../../../slices/widget/widget';
import { setDashboardNameAction } from '../../../../slices/dashboard/dashboard';
import { PageParams } from '../../../../types/meta';
import { BIRequestCatcher } from '../../../../utils/functions';
import { CustomButton } from '../../../../uikit/Button';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import CustomDialog from '../../../../uikit/Dialog/custom-dialog';

interface ModalRenameProps {
  isOpen: boolean;
  handleModalClose: (success?: boolean) => void;
  type: string | undefined;
  name: string;
  id: number | undefined;
}

const ModalRename: React.FC<ModalRenameProps> = ({
  isOpen,
  type,
  name,
  handleModalClose,
  id,
}) => {
  const dispatch = useDispatch();
  const currentPageParams: PageParams = useParams();

  const widgetsList: Widget[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? null,
  );

  const dashboardsList: DashboardResponse[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectDashboards ?? null,
  );

  const [newItemName, setNewItemName] = useState<string>(name);
  const [modifiedDashboard, setModifiedDashboard] = useState<DashboardResponse | null>(null);

  const dashboardId = type === 'dashboard' ? id : null;

  useEffect(() => {
    dashboardId &&
    apigetDashboardById(dashboardId).then((result) => {
      setModifiedDashboard(result);
    });
  }, [dashboardId]);

  const handleClose = (status: boolean) =>
    handleModalClose(status);

  const onRenameWidgetClick = useCallback(() => {
    if (type === 'widget') {
      const currentWidget =
        widgetsList?.find((widget) => widget.id === id) ?? {};
      const editingWidget: Widget = { ...(currentWidget as Widget) };
      editingWidget.name = newItemName;

      if (editingWidget.id === currentPageParams.widgetId) {
        dispatch(setCurrentWidgetAction(editingWidget));
      }
      BIRequestCatcher.addUrlToIgnoreList('/widgets');

      apiUpdateWidget(editingWidget).then(() => {
        BIRequestCatcher.cleanIgnoreList();
        const widgets = widgetsList ? [...widgetsList] : [];

        const itemIndex: number =
          widgets.findIndex((item) => item.id === id) || 0;

        widgets[itemIndex] = editingWidget;
        dispatch(setCurrentProjectWidgetsAction(widgets));
        handleClose(true);
      });
    }

    if (type === 'dashboard') {
      const currentDashboard: DashboardResponse | null =
        dashboardsList?.find((dashboard) => dashboard.id === id) ?? modifiedDashboard;

      if (!currentDashboard) return;

      const editingDashboard: DashboardRequest = {
        id: currentDashboard.id,
        name: currentDashboard.name,
        properties: currentDashboard.properties,
        widgetIds: currentDashboard.widgetIds,
      };
      editingDashboard.name = newItemName;

      if (editingDashboard.id === Number(currentPageParams.widgetId)) {
        dispatch(setDashboardNameAction(newItemName));
      }

      BIRequestCatcher.addUrlToIgnoreList('/dashboards');

      apiUpdateDashboard(editingDashboard).then((dashboardResponse) => {
        BIRequestCatcher.cleanIgnoreList();
        const dashboards = [...(dashboardsList ?? [currentDashboard])] || [];

        const itemIndex: number =
          dashboards.findIndex((item) => item.id === id) || 0;

        dashboards[itemIndex] = dashboardResponse;
        dispatch(setCurrentProjectDashboardsAction(dashboards));
        handleClose(true);
      });
    }
  }, [newItemName, widgetsList, dashboardsList]);

  const onNameChange = useCallback((event: any) => {
    setNewItemName(event.target.value);
  }, []);

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => handleClose(false)}
      title="Изменение названия"
      maxWidth="xs"
    >
      <div className="dialog-body">
        <TextInput
          defaultValue={name}
          InputProps={{ maxlength: 255 }}
          label="Введите новое название"
          handleChange={onNameChange}
          fullWidth
        />
      </div>

      <div className="dialog-buttons">
        <CustomButton
          variant="contained"
          onClick={onRenameWidgetClick}
        >
          Сохранить
        </CustomButton>
        <CustomButton variant="outlined" onClick={() => handleClose(false)}>
          Отменить
        </CustomButton>
      </div>
    </CustomDialog>
  );
};

export default ModalRename;
