import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import IconSvg from '../../common/icon-svg/icon-svg';
import { PageParams } from '../../../types/meta';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { WidgetDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomIconButton, IconButtonType } from '../../../uikit/IconButton';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface WidgetListItemProps {
  iconName: keyof typeof IconDictionary;
  name: string;
  id?: number;
}

const WidgetListItem: React.FC<WidgetListItemProps> = ({ iconName, name, id }) => {
  const params: PageParams = useParams();
  return (
    <div className="widgets-list-item">
      <CustomTooltip
        arrow
        title={name}
        enterDelay={300}
        enterNextDelay={300}
      >
        <div className="widgets-list-item__left">
          <IconSvg
            svg={IconDictionary[iconName]}
            fill="var(--dark-grey)"
          />
          <span className="widgets-list-item__name">{name}</span>
        </div>
      </CustomTooltip>

      <div className="widgets-list-item__container">
        <NavLink to={`/project/${params.projectId}/widget/${id}`}>
          <CustomTooltip
            arrow
            title={WidgetDictionary.openWidget}
          >
            <div>
              <CustomIconButton
                iconButtonProps={{
                  children: <IconSvg svg={IconDictionary.EditFile} fill="var(--dark-grey)" />,
                }}
                type={IconButtonType.secondary}
              />
            </div>
          </CustomTooltip>
        </NavLink>
      </div>
    </div>
  );
};

export default WidgetListItem;
