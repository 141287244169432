import { useCallback, useEffect, useState } from 'react';
import { getParametersBySourceId } from '../services/source-parameters';
import { FileFieldType } from '../enums/connection-field-type';

type Data = { name: FileFieldType; value: string; id: number }[];

type UseValuesBySourceId = (
  id: number | null | undefined,
) => {
  id: number;
  setId: (id: number) => void;
  data: Data;
  loading: boolean;
  error: string | null;
};

export const useValuesBySourceId: UseValuesBySourceId = (defaultId) => {
  const initialId = defaultId || 0;

  const [id, setId] = useState<number>(initialId);
  const [data, setData] = useState<Data>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = useCallback(() => {
    setLoading(true);
    setError(null);

    getParametersBySourceId(id)
      .then((resData: any) => setData(resData.data))
      .catch(({ message }: { message: string }) => setError(message))
      .then(() => setLoading(false));
  }, [id]);

  const handlerChangeId = useCallback((newId: number) => {
    setData([]);
    setError(null);
    setId(newId);
  }, []);

  useEffect(getData, [id]);

  if (!defaultId) {
    return {
      id: 0,
      setId: handlerChangeId,
      data: [],
      loading: false,
      error: null,
    };
  }

  return {
    id,
    setId: handlerChangeId,
    data,
    loading,
    error,
  };
};
