import { ParentSize } from '@visx/responsive';
import React, {
  useMemo,
  useRef,
} from 'react';
import './styles.css';
import { scaleLinear } from '@visx/scale';
import { Area } from '@visx/shape';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as allCurves from '@visx/curve';
import { trim } from 'lodash';
import clsx from 'clsx';
import { setPropForNumberValue } from '../formatting-helpers';
import {
  getBooleanPropertyValue,
  getInputPropertyValue,
  getSelectPropertyValue,
  sortColorPalette,
} from '../helpers';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { defaultColors } from '../common/color';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { initValueFormatter } from '../hooks/initValueFormatter';
import {
  DynamicPosition,
  getDynamicPositionFlexDirection,
  getFontSize,
  getFormattedValueText,
  LINE_HEIGHT,
} from './helper';
import { useInfoCardProps } from './useInfoCardProps';
import { LinkWrap } from '../../../../uikit/LinkWrap';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import { NumberFormat } from '../../../../enums/number-format';

const COLOR_INFORMATION_CARD_UP = '#37AD58';
const COLOR_INFORMATION_CARD_DOWN = '#EE6565';

let currentColors: any = defaultColors;

export enum typesColoring {
  color = 'Сплошная',
  gradient = 'Полупрозрачная',
}

export enum LegendPositionInformationCard {
  Left = 'Слева',
  Right = ' Справа',
}

export type InformationCardDataProps = {
  currentValue: number;
  previousValue: number;
  absolute: number;
  percent: number;
};

export type InformationCardProps = {
  widgetProps: {
    properties: any;
    data: InformationCardDataProps;
    id: number;
  };
};

const InformationCard = ({
  widgetProps,
  height,
  width,
}: InformationCardProps & { height: number; width: number }) => {
  const widgetProperties = widgetProps.properties;

  const {
    isNeedToDisplayDynamicsInPercent,
    customDisplayValueText,
    customDisplayDynamicsText,
    displayDynamicsGraph,
    isNeedToDisplayBackgroundColor,
    isDisplayLegend,
    isDisplayFact,
    isDisplayPlan,
    isDisplayDynamicIcon,
    isDisplayDynamicSign,
    legendPosition,
    dynamicPosition,
    currentField,
    previousField,
    isNeedToDisplayDynamics,
    colorsPaletteState,
    fixedFontSize,
    valuesFontSize,
    dynamicSizeRatio,
  } = useInfoCardProps({
    widgetProperties,
    widgetProps,
  });

  const roundingCountString: string = getInputPropertyValue(
    'roundingCount',
    widgetProperties,
  );
  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const valueFormat = initValueFormatter({ roundingCount });

  const currentName: string =
    JSON.parse(currentField?.storage || '{}')?.alias || currentField?.name;
  const previousName: string =
    JSON.parse(previousField?.storage || '{}')?.alias || previousField?.name;
  const isHaveTitle = Boolean(trim(currentName)) || Boolean(trim(previousName));
  const isDisplayFactField = isDisplayFact && Boolean(currentField);
  const isDisplayPlanField = isDisplayPlan && Boolean(previousField);

  const dynamicsTextRatio =
    (Number(
      getInputPropertyValue(
        WidgetPropertyType.dynamicTextSizeRatio,
        widgetProperties,
      ),
    ) || 80) / 100;

  currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || currentColors;

  const typeCardColoring: string =
    getSelectPropertyValue('colorIntensive', widgetProperties) ||
    typesColoring.color;

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const isGradientColoring = typeCardColoring === typesColoring.gradient;

  const currentValue = widgetProps.data.currentValue || 0;
  const previousValue = widgetProps.data.previousValue || 0;

  const currentValueFormatted = getFormattedValueText(
    valueFormat(
      currentValue,
      0,
      setPropForNumberValue(widgetProperties),
      PanelType.axisY,
      false,
      false,
      scaleByNumber ? formatByNumber : null,
    ),
    roundingCount,
    customDisplayValueText,
  );
  const previousValueFormatted = getFormattedValueText(
    valueFormat(
      previousValue,
      0,
      setPropForNumberValue(widgetProperties),
      PanelType.axisY,
      false,
      false,
      scaleByNumber ? formatByNumber : null,
    ),
    roundingCount,
    customDisplayValueText,
  );

  const initialPercent = widgetProps.data.percent || 0;
  const isTrendingDirectionUp = initialPercent >= 0;
  const percent = isDisplayDynamicSign
    ? initialPercent
    : Math.abs(initialPercent);
  const absolute = isDisplayDynamicSign
    ? widgetProps.data.absolute
    : Math.abs(widgetProps.data.absolute);

  const dynamicsValue = isNeedToDisplayDynamicsInPercent
    ? `${(percent
      ? valueFormat(
        Math.round(percent * 100) / 100,
        0,
        setPropForNumberValue(widgetProperties),
        PanelType.axisY,
        false,
        false,
        scaleByNumber ? formatByNumber : null,
      )
      : 0
    ).toLocaleString()}%`
    : valueFormat(
      absolute,
      0,
      setPropForNumberValue(widgetProperties),
      PanelType.axisY,
      false,
      false,
      scaleByNumber ? formatByNumber : null,
    )
      ? valueFormat(
        absolute,
        0,
        setPropForNumberValue(widgetProperties),
        PanelType.axisY,
        false,
        false,
        scaleByNumber ? formatByNumber : null,
      )
      : 0;

  const dynamicsValueFormatted: string = `${
    isTrendingDirectionUp && isDisplayDynamicSign && initialPercent ? '+' : ''
  }${dynamicsValue}`;

  const trendingDirectionColor = isTrendingDirectionUp
    ? COLOR_INFORMATION_CARD_UP
    : COLOR_INFORMATION_CARD_DOWN;

  const viewportRef = useRef(document.createElement('div'));

  const xScale = scaleLinear<number>({
    range: [0, width],
    domain: [1, 100],
  });

  const yScale = scaleLinear<number>({
    zero: true,
    nice: true,
    range: [0, height],
    domain: [Math.max(currentValue, previousValue) * 1.1, 0],
  });

  const getScaledX = (d: any) => {
    return xScale(d.x) ?? 0;
  };
  const getScaledY = (d: any) => {
    return yScale(d.y) ?? 0;
  };

  const data = [
    { x: 1, y: previousValue },
    { x: 25, y: previousValue * (isTrendingDirectionUp ? 1.05 : 0.95) },
    { x: 76, y: currentValue * (isTrendingDirectionUp ? 0.95 : 1.05) },
    { x: 100, y: currentValue },
  ];
  // end: график на фоне

  const setBackground = () => {
    return isNeedToDisplayBackgroundColor
      ? isGradientColoring
        ? `linear-gradient(to top, white, ${currentColors[0]})`
        : currentColors[0]
      : 'white';
  };

  const { fontSize, fontDynamicsSize, fontNumberDynamicSize } = getFontSize({
    width: width * 0.9,
    height,
    isDisplayLegend,
    isDisplayPlan,
    currentName,
    previousName: isDisplayPlan ? previousName : currentName,
    currentValueFormatted,
    previousValueFormatted: isDisplayPlan
      ? previousValueFormatted
      : currentValueFormatted,
    isNeedToDisplayDynamics,
    dynamicPosition,
    dynamicsValueFormatted,
    customDisplayDynamicsText,
    dynamicsTextRatio,
    fixedFontSize,
    valuesFontSize,
    dynamicSizeRatio: dynamicSizeRatio / 100,
  });

  return (
    <div
      className="information-card__container"
      ref={viewportRef}
      style={{
        height: '100%',
        fontSize,
        lineHeight: LINE_HEIGHT,
      }}
    >
      {(displayDynamicsGraph || isNeedToDisplayBackgroundColor) && (
        <div
          className="information-card__background"
          style={{
            opacity: isGradientColoring ? 0.5 : 1,
            background: setBackground(),
          }}
        >
          {Boolean(widgetProps.data.previousValue) && displayDynamicsGraph && (
            <svg
              style={{ verticalAlign: 'top' }}
              className="viewport-svg"
              width={width}
              height={height}
            >
              <Area
                curve={allCurves.curveCardinal}
                fill={trendingDirectionColor}
                x={getScaledX}
                y={getScaledY}
                y1={() => getScaledY({ y: 0 })}
                data={data}
                stroke={trendingDirectionColor}
                fillOpacity={1}
                fillRule="evenodd"
                opacity={0.14}
              />
            </svg>
          )}
        </div>
      )}
      <div
        className="information-card__content"
        style={{
          flexDirection: getDynamicPositionFlexDirection(dynamicPosition),
        }}
      >
        <div className="information-card__value-wrap">
          {isDisplayFactField && (
            <div
              className="information-card__info-wrap"
              style={{
                flexDirection:
                  legendPosition === LegendPositionInformationCard.Left
                    ? 'row'
                    : 'row-reverse',
              }}
            >
              {isDisplayLegend && isHaveTitle && (
                <LinkWrap
                  linkTo={currentField?.link}
                  disabled={!currentField?.link}
                  absolute
                >
                  <div
                    className={clsx('information-card__legend', {
                      'information-card__legend_right':
                        legendPosition !== LegendPositionInformationCard.Left,
                      'information-card__legend_link': currentField?.link,
                    })}
                  >
                    <CustomTooltip arrow title={currentName}>
                      <span>{currentName}</span>
                    </CustomTooltip>
                  </div>
                </LinkWrap>
              )}
              <div className="information-card__value">
                <span>{currentValueFormatted}</span>
              </div>
            </div>
          )}

          {isDisplayPlanField && (
            <div
              className="information-card__info-wrap"
              style={{
                flexDirection:
                  legendPosition === LegendPositionInformationCard.Left
                    ? 'row'
                    : 'row-reverse',
              }}
            >
              {isDisplayLegend && isHaveTitle && (
                <div
                  className={clsx('information-card__legend', {
                    'information-card__legend_right':
                      legendPosition !== LegendPositionInformationCard.Left,
                  })}
                >
                  <CustomTooltip arrow title={previousName}>
                    <span>{previousName}</span>
                  </CustomTooltip>
                </div>
              )}
              <div className="information-card__value">
                <span>{previousValueFormatted}</span>
              </div>
            </div>
          )}

          {!currentField && !previousField && (
            <div className="information-card__value">
              <span>{customDisplayValueText}</span>
            </div>
          )}
        </div>

        {isNeedToDisplayDynamics && (
          <div
            className="information-card__dynamics"
            style={{
              flexDirection:
                dynamicPosition === DynamicPosition.Down ? 'row' : 'column',
              fontSize: fontDynamicsSize,
            }}
          >
            <div className="information-card__dynamics-value-wrap">
              {isDisplayDynamicIcon && (
                <IconSvg
                  svg={IconDictionary.TrendingUp}
                  fill={trendingDirectionColor}
                  width={fontNumberDynamicSize * LINE_HEIGHT}
                  height={fontNumberDynamicSize * LINE_HEIGHT}
                  classSVG={
                    isTrendingDirectionUp ? '' : 'information-card__mirror-up'
                  }
                />
              )}
              <span
                className="information-card__dynamics-value"
                style={{
                  color: trendingDirectionColor,
                  fontSize: fontNumberDynamicSize,
                }}
              >
                {dynamicsValueFormatted}
              </span>
            </div>
            {customDisplayDynamicsText && (
              <CustomTooltip arrow title={customDisplayDynamicsText}>
                <span className="information-card__dynamics-description">
                  {customDisplayDynamicsText}
                </span>
              </CustomTooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const InformationCardWrapper = (props: InformationCardProps) => {
  return (
    <ParentSize>
      {({ width, height }) => {
        if (height < 10) return null;
        return <InformationCard {...props} width={width} height={height} />;
      }}
    </ParentSize>
  );
};

InformationCardWrapper.displayName = 'InformationCard';

export default InformationCardWrapper;
