import { useCallback } from 'react';
import {
  FilterField,
  SetFilterField,
} from '../../components/dashboard-page/hooks';

interface useWidgetFiltersProps {
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  widgetId: number;
}

export const useWidgetFilters = ({
  setFilterField,
  filterField,
  isActiveFilter,
  widgetId,
}: useWidgetFiltersProps) => {
  const deselectData = useCallback(() => {
    setFilterField && setFilterField(widgetId, null, []);
  }, [setFilterField, widgetId]);

  const selectData = useCallback(
    (value: string, axisValueId: number) => {
      if (!isActiveFilter) return;
      if (filterField?.value?.includes(value)) {
        deselectData();
      } else {
        if (!setFilterField || !value) return;

        setFilterField(widgetId, axisValueId, [
          {
            operation: '=',
            value: [value],
          },
        ]);
      }
    },
    [isActiveFilter, filterField, deselectData, setFilterField, widgetId],
  );

  return {
    selectData
  };
};
