import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const DEFAULT_DEBOUNCE_INPUT = 400;

export const useDebouncedInput: <T>(
  debouncedValue: T,
  callback: (value: T) => void,
  debounce?: number,
) => [T, (value: T) => void] = (debouncedValue, callback, debounce = DEFAULT_DEBOUNCE_INPUT) => {
  const [value, setValue] = useState(debouncedValue);

  useEffect(() => {
    setValue(debouncedValue);
  }, [JSON.stringify(debouncedValue)]);

  const setStateValue = useDebouncedCallback(callback, debounce);

  const handleValueChange = useCallback((value: any) => {
    setValue(value);
    setStateValue.callback(value);
  }, [setStateValue]);

  return [value, handleValueChange];
};

export default function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debouncer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(debouncer);
    };
  }, [value, delay]);

  return debouncedValue;
}

