import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../icon-svg/icon-svg';

export interface ActionProps {
  id: string | number;
}

export const Action: FC<ActionProps> = ({ id } ) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <>
      <IconButton onClick={() => { closeSnackbar(id); }}>
        <IconSvg svg={IconDictionary.Close} fill="var(--white)" />
      </IconButton>
    </>
  );
};

