import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isEqual, uniq } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { useRequestCanceller } from '../../../../hooks/useRequestCanceller';
import useDebounce from '../../../../hooks/useDebouncedInput';
import { Placement } from '../../../../enums/visual-types';
import { FilterContainer } from '../../../common/filter-container/filter-container';
import { SelectField } from '../fields/select/select-field';
import {
  apiGetFilterListByDashboardFilterId,
  apiGetFilterListBySourceAndField,
} from '../../../../services/widgetController';
import { onlyUnique } from '../../../../utils/functions';

interface SelectFilterProps {
  onChangeFilter: (filter: any) => void;
  field: string | string[];
  sourceId: string | string[];
  currentFilter: any;
  allFieldFilters: any;
  label?: React.ReactNode | string;
  widgetId?: number;
  filterGroupId?: number;
  closeFilterPanel?: () => void;
  placement?: Placement;
  isSingleSelection?: boolean;
  isMandatory?: boolean;
}

export const SelectFilter: React.FC<SelectFilterProps> = ({
  onChangeFilter,
  field,
  sourceId,
  currentFilter,
  label,
  allFieldFilters,
  widgetId,
  filterGroupId,
  closeFilterPanel,
  placement,
  isSingleSelection = false,
  isMandatory = false,
}) => {
  const defaultToValue: string[] = currentFilter?.map(
    (filter: any) => filter.value,
  );

  const [values, setValues] = useState<string[]>(defaultToValue || []);
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const debouncedSearchValueTime = 500;
  const debouncedSearchValue: string = useDebounce(searchValue, debouncedSearchValueTime);

  const { callCancellable, cancellableController } = useRequestCanceller();

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const fetchOptions = (query?: string) => {
    setLoading(true);
    if (Array.isArray(field) && Array.isArray(sourceId)) {
      const promises: Promise<any[]>[] = [];

      const fields = uniq(field);

      for (let i = 0; i < fields.length; i++) {
        const currentField = fields[i];
        promises.push(
          filterGroupId
            ? callCancellable(apiGetFilterListByDashboardFilterId, {
              field: currentField,
              filters: allFieldFilters,
              filterGroupId,
              query
            }, 'filter')
            : callCancellable(apiGetFilterListBySourceAndField, {
              field: currentField,
              filters: allFieldFilters,
              widgetId,
              query
            }, 'filter'),
        );
      }

      Promise.all(promises)
        .then((data) => {
          const result: any[] = data.flat();

          setList(result.filter(onlyUnique));
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    const promise = filterGroupId
      ? callCancellable(apiGetFilterListByDashboardFilterId, {
        field: field as string,
        filters: allFieldFilters,
        filterGroupId,
        query
      }, 'filter')
      : callCancellable(apiGetFilterListBySourceAndField, {
        field: field as string,
        filters: allFieldFilters,
        widgetId,
        query
      }, 'filter');

    promise.then((response) => {
      setList(response);
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    if (debouncedSearchValue !== undefined) {
      fetchOptions(debouncedSearchValue);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    return () => {
      cancellableController.cancelGroup('filter');
    };
  }, [cancellableController]);

  useEffect(() => {
    setValues(defaultToValue || []);
  }, [JSON.stringify(currentFilter)]);

  const options = useMemo(
    () => {
      const result = list.map((option) => ({ label: option, value: option }));

      values && values.forEach((filter) => {
        if (!result.find((item) => item.value === filter)) {
          result.push({
            label: filter,
            value: filter,
          });
        }
      });

      return result;
    },
    [list, values],
  );

  const handleChange = (options: (string | Symbol | number)[]) => {
    const value: string[] = options as string[];

    setValues(value);
  };

  const filterValue = values?.map((option) => {
    return {
      operation: '=',
      value: option,
    };
  });

  const isNoChanges = isEqual(filterValue, currentFilter);
  const isMandatoryConditionCorrect = !isMandatory || filterValue?.[0]?.value?.length > 0;
  const isDisabledApplyFilterButton = isNoChanges || !isMandatoryConditionCorrect;

  const changeFilterValue = useCallback(() => {
    closeFilterPanel && closeFilterPanel();

    if (isNoChanges) return;

    onChangeFilter(filterValue);
  }, [filterValue, isNoChanges, onChangeFilter]);

  return (
    <FilterContainer
      label={label}
      isDisabledApplyButton={isDisabledApplyFilterButton}
      handleApply={changeFilterValue}
    >
      <Box display="flex">
        <SelectField
          isLoading={loading}
          options={options}
          values={values}
          onChange={handleChange}
          onOpen={fetchOptions}
          onInput={onInput}
          placement={placement}
          isSingleSelection={isSingleSelection}
        />
      </Box>
    </FilterContainer>
  );
};
