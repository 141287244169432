import { CSS } from '@dnd-kit/utilities';
import { useSelector } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import React, { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { Property, PropertyData } from '../../dropdown-layout/helpers/Property';
import { State } from '../../../../slices/types';
import { PanelType, WidgetType } from '../../../../enums/widget-type';
import { usePanelItemActions } from './usePanelItemActions';
import { PanelItemProps } from './panel-item';
import { getAxisWithRestriction } from '../../helpers';

export const usePanelItem = ({ panel, index, draggableId }: PanelItemProps) => {
  const data: PropertyData = JSON.parse(draggableId);
  const propertyInstance = useMemo(() => new Property(data), [data]);

  const etlSourceId = data.etlSourceId || '';
  const sourceDisplayName = data.displayName;

  const widget = useSelector((state: State) => state.widget);

  const containerRef = useRef(null);

  const isMapWidget = widget.type === WidgetType.MAP;

  const { removePropertyHandler } = usePanelItemActions(widget);

  const handleDelete = useCallback(() => {
    removePropertyHandler(panel.name as PanelType, index);
  }, [index, removePropertyHandler, panel.name]);

  const [anchorEl, setAnchorEl] = useState(null);

  const panelsWithRestriction = getAxisWithRestriction(widget.type);

  const handleMoreButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(containerRef.current);
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
  } = useSortable({ id: draggableId });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || '',
    display: 'flex',
    zIndex: isSorting ? '999' : 'inherit',
  };

  return {
    setNodeRef,
    style,
    attributes,
    listeners,
    containerRef,
    handleDelete,
    data,
    propertyInstance,
    isMapWidget,
    etlSourceId,
    sourceDisplayName,
    anchorEl,
    setAnchorEl,
    panelsWithRestriction,
    widget,
    handleMoreButtonClick,
  };
};
