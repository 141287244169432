import React from 'react';
import { useFormik } from 'formik';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { CustomButton } from '../../../../../uikit/Button';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';
import { getSimplifiedType } from '../../../dropdown-layout/helpers/helpers';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CommonDictionary } from '../../../../../dictionaries/naming-dictionary/naming-dictionary';

interface DrilldownValues {
  isDrillDownFilter: boolean;
}

interface DrilldownOptionsProps {
  panelData: PropertyData;
  closeDialog: () => void;
  changeDDOptions: (isDrillDownFilter: boolean) => void;
}

export const ColumnDdOptions = ({
  panelData,
  closeDialog,
  changeDDOptions,
}: DrilldownOptionsProps) => {
  const handleSave = (values: DrilldownValues) => {
    closeDialog();

    changeDDOptions(values.isDrillDownFilter);
  };

  const handleClose = () => {
    closeDialog();
  };

  const { values, handleChange, handleSubmit } = useFormik<DrilldownValues>({
    initialValues: {
      isDrillDownFilter: panelData.isDrillDownFilter || false,
    },
    onSubmit: handleSave,
  });

  const isDisabledSetting =
    getSimplifiedType(panelData.type) === WidgetSimplifiedDataType.NUMBER ||
    Boolean(panelData.aggregation);

  return (
    <CustomDialog
      isOpen
      onClose={closeDialog}
      title={`Настройка ${CommonDictionary.drilldown}`}
      maxWidth="xs"
    >
      <form className="dialog-body" onSubmit={handleSubmit}>
        <CustomFormControl
          control={
            <CustomCheckbox
              disabled={isDisabledSetting}
              name="isDrillDownFilter"
              onChange={handleChange}
              checked={values.isDrillDownFilter}
            />
          }
          label={`Участвует в ${CommonDictionary.drilldown}`}
          className="dialog-body__block"
        />

        <div className="dialog-buttons dialog-buttons_in-form">
          <CustomButton variant="contained" type="submit">
            <span>Сохранить</span>
          </CustomButton>
          <CustomButton variant="outlined" type="reset" onClick={handleClose}>
            Отменить
          </CustomButton>
        </div>
      </form>
    </CustomDialog>
  );
};
