import { Box, styled, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import CustomTooltip from '../CustomTooltip';
import { IconButtonType, CustomIconButton } from '../IconButton';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../components/common/icon-svg/icon-svg';

export interface ICardHeader {
  title: string;
  titleSettings?: ICardTextSettings;
  subtitle?: string;
  subtitleSettings?: ICardTextSettings;
  align?: 'left' | 'center';
  children?: React.ReactNode;
  backActionObject?: { isVisible: boolean; onClick: () => void; };
}

export interface ICardTextSettings {
  fontSize: string;
  lineHeight: string;
}

const CardHeaderWrapper = styled('div')(() => ({
  borderBottom: '1px solid var(--dark-cyan)',
  padding: 'calc(5px * var(--scale-coefficient)) calc(5px * var(--scale-coefficient)) calc(5px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient))',

  '& .card__header__back': {
    marginRight: 'calc(8px * var(--scale-coefficient))',

    '& svg': {
      width: 'calc(26px * var(--scale-coefficient)) !important',
      height: 'calc(26px * var(--scale-coefficient)) !important',
      maxWidth: 'none !important',
      maxHeight: 'none !important',
    }
  }
}));

const EllipsedTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 700,
  fontFamily: 'Manrope',
}));

export const CardHeader: FC<ICardHeader> = ({
  title,
  titleSettings = { fontSize: 'calc(20px * var(--scale-coefficient))', lineHeight: 'calc(30px * var(--scale-coefficient))' },
  subtitle = '',
  subtitleSettings = { fontSize: 'calc(14px * var(--scale-coefficient))', lineHeight: 'calc(20px * var(--scale-coefficient))' },
  align = 'left',
  children,
  backActionObject = { isVisible: false, onClick: () => null },
}) => {
  return (
    <CardHeaderWrapper className="card__header">
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        textAlign={align}
        height="100%"
      >
        {backActionObject.isVisible && (
          <Box
            display='flex'
            alignItems='center'
            height="100%"
          >
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.ArrowToLeft} fill="var(--primary-color)" />,
                onClick: backActionObject.onClick,
                className: 'card__header__back'
              }}
              type={IconButtonType.primary}
            />
          </Box>
        )}
        <Box
          overflow='hidden'
          flexGrow={1}
          display='flex'
          justifyContent='center'
          flexDirection='column'
          minHeight='calc(38px * var(--scale-coefficient))'
        >
          <CustomTooltip arrow title={title} placement="top">
            <EllipsedTypography variant='h2' style={{ ...titleSettings }}>
              {title}
            </EllipsedTypography>
          </CustomTooltip>
          {Boolean(subtitle) && (
            <CustomTooltip arrow title={subtitle} placement="top">
              <EllipsedTypography
                variant="subtitle2"
                style={{ ...subtitleSettings }}
              >
                {subtitle}
              </EllipsedTypography>
            </CustomTooltip>
          )}
        </Box>
        <Box display="flex" alignItems="center" flexShrink={0}>
          {children}
        </Box>
      </Box>
    </CardHeaderWrapper>
  );
};

export default CardHeader;
