import React, { memo, useCallback, useMemo, useState } from 'react';
import { Brush } from '@visx/brush';
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import { Bounds } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import { calculateTextDimensions } from '../../../../../utils/functions';
import { getRootElement } from '../../../../../utils/root-helper';
import { brushHeight, tooltipStyles } from '../helper';
import {
  FormatAxisXValue,
  GraphData,
  HorizontalAccessor,
  VerticalScale,
  XScale,
} from '../types';

const brushColor = 'var(--dark-grey)';
// цвет фона задается в файле стилей
const brushHandleBorderColor = '#c4c4c4';
const brushHandleBackgroundColor = '#f7f7f7';

interface GraphBrushProps {
  widgetData: GraphData[];
  setData: (data: GraphData[]) => void;
  brushXScale: XScale;
  brushYScale: VerticalScale;
  xBrushMax: number;
  marginForAxisLeft: number;
  widgetWidth: number;
  accessorX: HorizontalAccessor;
  setDisableBrushControl: React.Dispatch<React.SetStateAction<boolean>>;
  xScale: XScale;
  formatAxisXValue: FormatAxisXValue;
}

const GraphBrush = memo(({
  widgetData,
  setData,
  brushXScale,
  brushYScale,
  xBrushMax,
  marginForAxisLeft,
  widgetWidth,
  accessorX,
  setDisableBrushControl,
  xScale,
  formatAxisXValue,
}: GraphBrushProps) => {
  const initialBrushPosition = useMemo(
    () => ({
      start: { x: 0 },
      end: { x: xBrushMax },
    }),
    [xBrushMax],
  );

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1 } = domain;
    const data = widgetData.filter((s) => {
      const datumX = accessorX(s);
      const x = datumX instanceof Date ? datumX.getTime() : datumX;
      return x > x0 && x < x1;
    });
    setData(data);
  };

  const domain = xScale.domain();

  const boundValues: [string, string] = [
    formatAxisXValue(domain[0]),
    formatAxisXValue(domain[1]),
  ];

  return (
    <Brush
      xScale={brushXScale}
      yScale={brushYScale}
      width={xBrushMax}
      height={brushHeight}
      // отступ нужен для корректного выделения на прокрутке
      margin={{ left: marginForAxisLeft }}
      handleSize={brushHeight}
      resizeTriggerAreas={['left', 'right']}
      brushDirection="horizontal"
      initialBrushPosition={initialBrushPosition}
      onChange={onBrushChange}
      onClick={() => {
        setData(widgetData);
      }}
      selectedBoxStyle={{ fill: brushColor }}
      useWindowMoveEvents
      renderBrushHandle={(props) => (
        <BrushHandle
          {...props}
          setDisableBrushControl={setDisableBrushControl}
          boundValues={boundValues}
          marginForAxisLeft={marginForAxisLeft}
          widgetWidth={widgetWidth}
        />
      )}
    />
  );
});

GraphBrush.displayName = 'GraphBrush';

const tipPaddingLeft = 7;
const tipPaddings = tipPaddingLeft * 2;
const tipPaddingUp = 4;
const tipOffset = 4;

const BrushHandle = ({
  x,
  height,
  isBrushActive,
  setDisableBrushControl,
  className,
  boundValues,
  marginForAxisLeft,
  widgetWidth,
}: BrushHandleRenderProps & {
  setDisableBrushControl: React.Dispatch<React.SetStateAction<boolean>>;
  boundValues: [string, string];
  marginForAxisLeft: number;
  widgetWidth: number;
}) => {
  const pathWidth = 7;
  const pathHeight = 18;
  const brashHandleXOffset = pathWidth / 2;
  const brashXLeftOffset = x + pathWidth / 2;

  const enableBrushControl = useCallback(() => {
    setDisableBrushControl(false);
    setHoldTip(false);
    window.removeEventListener('mouseup', enableBrushControl);
  }, [setDisableBrushControl]);

  const [openTip, setOpenTip] = useState(false);
  const [holdTip, setHoldTip] = useState(false);

  const showTip = holdTip || openTip;

  if (!isBrushActive) {
    return null;
  }

  const isRightValue = className === 'visx-brush-handle-right';
  const value = isRightValue ? boundValues[1] : boundValues[0];

  const textDim = calculateTextDimensions(value, tooltipStyles);

  const textWidth = textDim.width;
  const textHeight = textDim.height;

  const tipWrapperWidth = textWidth + tipPaddings;
  const tipWrapperHeight = textHeight + tipPaddingUp * 2;
  const tipWrapperXLeft = -tipWrapperWidth - tipOffset;
  const tipWrapperXRight = pathWidth + tipOffset;

  // логика перемещения тултипа на другую сторону, если тултип не вмещается полностью со своей стороны
  const handle = isRightValue
    ? tipWrapperWidth +
        pathWidth +
        tipOffset +
        brashXLeftOffset +
        marginForAxisLeft <
      widgetWidth
      ? 'right'
      : 'left'
    : tipWrapperWidth + tipOffset - brashHandleXOffset - marginForAxisLeft < x
      ? 'left'
      : 'right';
  const isPositionHandleRight = handle === 'right';

  const tipWrapperX = isPositionHandleRight
    ? tipWrapperXRight
    : tipWrapperXLeft;

  const tipTextX = isPositionHandleRight
    ? pathWidth + tipOffset + tipPaddings / 2
    : -tipWrapperXRight;
  const textAnchor = isPositionHandleRight ? 'start' : 'end';
  return (
    <Group left={brashXLeftOffset} top={(height - pathHeight) / 2}>
      <rect
        width={pathWidth}
        height={pathHeight}
        rx={4}
        fill={brushHandleBackgroundColor}
        stroke={brushHandleBorderColor}
        style={{ cursor: 'ew-resize' }}
        onMouseDown={(event) => {
          event.stopPropagation();
          setHoldTip(true);
          setDisableBrushControl(true);
          getRootElement().addEventListener('mouseup', enableBrushControl);
        }}
        onMouseEnter={() => setOpenTip(true)}
        onMouseLeave={() => setOpenTip(false)}
      />
      {showTip && (
        <>
          <rect
            width={tipWrapperWidth}
            height={tipWrapperHeight}
            fill={tooltipStyles.backgroundColor}
            x={tipWrapperX}
            y={-2}
            rx={4}
          />
          <text
            className="widget-overlay-graph__brush--tip"
            x={tipTextX}
            y={textHeight}
            textAnchor={textAnchor}
          >
            {value}
          </text>
        </>
      )}
    </Group>
  );
};

export default GraphBrush;
