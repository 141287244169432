import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import {
  IconButtonType,
  CustomIconButton,
} from '../../../../../../uikit/IconButton';
import { CellColoringGroup } from '../../../../dropdown-layout/helpers/Property';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

const exampleValue = '123';

interface GroupHeadProps {
  isOpen: boolean;
  onChangeVisibleGroup: () => void;
  group: CellColoringGroup;
  index: number;
  deleteGroup: (index: number) => void;
  copyGroup: (index: number) => void;
}

export const GroupHead = ({
  isOpen,
  onChangeVisibleGroup,
  group,
  index,
  copyGroup,
  deleteGroup,
}: GroupHeadProps) => {
  return (
    <div
      className={clsx('conditional-coloring__head', {
        'conditional-coloring__head-close': !isOpen,
      })}
    >
      <CustomIconButton
        iconButtonProps={{
          children: (
            <IconSvg
              svg={IconDictionary.RoundedArrowToBottom}
              fill={isOpen ? 'var(--primary-color)' : 'var(--dark-grey)'}
            />
          ),
          onClick: onChangeVisibleGroup,
          className: clsx({
            'conditional-coloring_open-button': isOpen,
          })
        }}
        type={IconButtonType.withoutEffects}
      />
      <div className="conditional-coloring__title">
        {`Ваша формула: ${
          group.useFormula
            ? group.formula.text
            : `${group.simpleCondition.fieldName} ${group.simpleCondition.operation} ${group.simpleCondition.value}`
        }`}
      </div>
      <div className="conditional-coloring-head__right">
        <div
          className="conditional-coloring__square"
          style={{
            background: group.backgroundColor,
            color: group.conditionColor,
          }}
        >
          {exampleValue}
        </div>
        <CustomIconButton
          iconButtonProps={{
            children: (
              <IconSvg
                svg={IconDictionary.Copy}
                fill="var(--dark-grey)"
              />
            ),
            onClick: () => copyGroup(index),
          }}
          type={IconButtonType.secondary}
        />
        <CustomIconButton
          iconButtonProps={{
            children: (
              <IconSvg
                svg={IconDictionary.BasketDelete}
                fill="var(--dark-grey)"
              />
            ),
            onClick: () => deleteGroup(index),
          }}
          type={IconButtonType.delete}
        />
      </div>
    </div>
  );
};
