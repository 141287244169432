import { CustomEventMap, RootElement } from '../types/root';


export const getRootElement = (): RootElement => {
  return document.getElementById('root')!;
};

export const dispatchRootEvent = <K extends keyof CustomEventMap>(customEvent: CustomEventMap[K]) => {
  const root: RootElement | null = getRootElement();
  root && root.dispatchEvent(customEvent);
};
