import { useSelector } from 'react-redux';
import { State } from '../../slices/types';
import { mainMenuItems } from './data';
import { MainMenuItem } from './type';

const useItemList = () => {
  const systemSettings = useSelector((state: State) => state.mainPage.systemSettings);
  const SYSTEM_UI_MENU: MainMenuItem[] = JSON.parse(
    systemSettings?.SYSTEM_UI_MENU ?? '[]',
  );

  const filteredItems =
    SYSTEM_UI_MENU.length > 0
      ? SYSTEM_UI_MENU.filter(({ disabled }) => !disabled)
      : mainMenuItems.filter(({ disabled }) => !disabled);

  return filteredItems.map((item) =>
    item.name === 'Представление данных'
      ? { ...item, link: `${window.location.origin}${process.env.PUBLIC_URL}`, isActive: true }
      : item,
  );
};

export default useItemList;
