import React from 'react';
import { settingTabs } from '../../enums';
import { CustomTabs } from '../../../../../uikit/Tabs';

interface TabsSwitcherProps {
  currentTab: settingTabs;
  handleOpenSwitchDialog: (newTab: settingTabs) => void;
}

export const TabsSwitcher = ({ currentTab, handleOpenSwitchDialog }: TabsSwitcherProps) => {
  const tabs = [
    {
      id: settingTabs.auto,
      title: 'Связь по данным',
      onClick: () => handleOpenSwitchDialog(settingTabs.auto),
    },
    {
      id: settingTabs.manual,
      title: 'Связь по фильтрам',
      onClick: () => handleOpenSwitchDialog(settingTabs.manual),
    },
  ];

  return (
    <div className='bound-type-switcher__tabs'>
      <CustomTabs buttons={tabs} />
    </div>
  );
};
