import React from 'react';
import IconSvg from '../icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface NoDataTemplateProps {
  withLinkToEditor: boolean;
  widgetId?: number;
  message?: string;
  errorText?: string
}
export const NoDataTemplate: React.FC<NoDataTemplateProps> = ({
  withLinkToEditor,
  widgetId,
  message = 'Нет данных для отображения',
  errorText
}) => {
  if (withLinkToEditor && !widgetId) {
    throw new Error(
      `Requeried "widgetId(${widgetId})" for withLinkEditor === true`,
    );
  }

  return (
    <div className="dashboard-grid-item__container-error">
      <IconSvg svg={IconDictionary.NoData} width={48} height={44} fill="var(--dark-grey)" />
      <p className="dashboard-grid-item__container-error-title">{errorText || message}</p>
    </div>
  );
};
