export interface RadarData {
  count: number[];
  label: string;
}

export const fullAngle = 360;

export const generateAngles = (length: number) =>
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (fullAngle / length),
  }));

export const generatePoints = (length: number, radius: number, isOddData: boolean) => {
  const step = (Math.PI * 2) / length;
  const coefficient = isOddData ? 1 : -1;
  return [...new Array(length)].map((_, i) => ({
    x: radius * Math.sin(i * step),
    y: coefficient * radius * Math.cos(i * step),
  }));
};

const getValue = (d: any, index: number) => d.count[index];

export function generatePolygonPoints<Datum>(
  dataArray: RadarData[],
  scale: (n: number) => number,
  index: number,
  isOddData: boolean,
) {
  const step = (Math.PI * 2) / dataArray.length;
  const coefficient = isOddData ? 1 : -1;

  const points: { x: number; y: number }[] = new Array(
    dataArray.length,
  ).fill({ x: 0, y: 0 });
  const polygonPoints: string = new Array(dataArray.length + 1)
    .fill('')
    .reduce((res, _, i) => {
      if (i > dataArray.length) return res;
      const xVal = scale(getValue(dataArray[i - 1], index)) * Math.sin(i * step);
      const yVal = coefficient * scale(getValue(dataArray[i - 1], index)) * Math.cos(i * step);
      points[i - 1] = { x: xVal, y: yVal };
      res += `${xVal},${yVal} `;
      return res;
    });

  return { points, polygonPoints };
}

export const getMaxData = (widgetData: RadarData[]): number => {
  let max = 0;
  widgetData.forEach((item) => {
    item.count.forEach((count) => {
      max = Math.max(max, count);
    });
  });

  return max;
};

export const isCentralPoint = (x: number): boolean => {
  const epsilon = 2;
  const difference = 2;
  return Math.abs( Math.abs(x) - difference) <= epsilon;
};
