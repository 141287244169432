import React from 'react';
import { WidgetSimplifiedDataType } from '../../../../enums/data-type';
import { WidgetTooltipData } from '../../../common/widget-tooltip/widget-tooltip';

export interface BubbleCartWidgetData {
  axisLabel: string;
  percents: number[];
  radius: number;
  slice: string;
  values: number[];
  zpercent: number;
  zvalue: number;
}

export interface BubbleData {
  axisXValue: string;
  axisXPosition: number;
  axisYValues: number[];
  axisYPercents: number[];
  axisZValues: number[];
  axisZRadius: number[];
  axisZPercents: number[];
  slices: string[];
}

export interface Dimensions {
  width: number;
  chartWidth: number;
  chartHeight: number;
  chartTopOffset: number;
  chartBottomOffset: number;
  columnWidth: number;
  minDiameter: number;
  maxDiameter: number;
  pointDiffSize: number;
  axisXHeight: number;
  axisYWidth: number;
}

export interface SeriesValue {
  id: number | null;
  name: string;
  key: string;
  color: string;
  simplifiedType: WidgetSimplifiedDataType;
  legendsLabel?: string;
}

export type FormatAxisXValue = (value: string) => string;
export type FormatAxisVerticalValue = (value: number, index?: number) => string;

export interface Interaction {
  handleBubbleMouseMove:  (event: React.MouseEvent<Element, MouseEvent>, data: WidgetTooltipData) => void;
  handleBubbleMouseLeave: (event: React.MouseEvent<Element, MouseEvent>) => (false | undefined);
  selectBubble: ((value: string) => void) | undefined;
  deselectBubble: (() => void) | undefined;
  selectedAxisXValue: string[] | null;
}

export interface FormattingValues {
  formatAxisXValue: FormatAxisXValue;
  formatAxisYValue: FormatAxisVerticalValue;
  formatAxisZValue: FormatAxisVerticalValue;
}

export interface SeriesValues {
  xField: SeriesValue;
  yFields: SeriesValue[];
  zField: SeriesValue;
}

export interface BubbleSets {
  pointMinSize: number;
  pointMaxSize: number;
  pointDiffSize: number;
  axisXHeight: number;
  axisYWidth: number;
  pointOffsetCoeff: number;
  isScaleByValue: boolean;
  isNeedToDisplayValue: boolean;
  isNeedToDisplayPercent: boolean;
  isNeedToDisplayNameSlice: boolean;
  isNeedToDisplayTooltip: boolean;
  isNeedToDisplayAxesGuide: boolean;
  textDirection: string;
}

// todo создать хук чтения этого свойства из свойств виджета и перенести туда этот enum
export enum textDirections {
  horizontal = 'Горизонтальные',
  vertical = 'Вертикальные',
  // diagonal = 'По диагонали',
}

