import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { max, min } from 'd3-array';
import { union } from 'lodash';
import { valueScalePadding, xScalePadding } from './properties';


export const getX = (data: any) => data.x;
export const getZ = (d: any, index: number = 0): number =>
  d.z ? d.z[index] : 0;

export const getMinMaxData = (data: any, keys: string[]) => {
  return [
    Math.min(
      ...data.map((d: any) => Math.min(...keys.map((key) => Number(d[key])))),
    ),
    Math.max(
      ...data.map((d: any) => Math.max(...keys.map((key) => Number(d[key])))),
    ),
  ];
};
export const getMinMaxGraphData = (dataGraph: any) => {
  return [
    Math.min(...dataGraph.map((data: any) => data.z).flat()),
    Math.max(...dataGraph.map((data: any) => data.z).flat()),
  ];
};

export const getScales = ({
  data,
  dataGraph,
  keys,
  isScaleByValueState,
  domain,
  xMax,
  yMax,
  currentColors,
  isSingleScale,
}: {
  data: any;
  dataGraph: any;
  keys: string[];
  isScaleByValueState: boolean;
  domain: number[] | undefined;
  xMax: number;
  yMax: number;
  currentColors: any;
  isSingleScale: boolean;
}) => {
  const xScale = scaleBand<string>({
    domain: union(data.map(getX), dataGraph.map(getX)),
    paddingInner: xScalePadding,
  });

  const valuesScale = scaleBand<string>({
    domain: keys,
    padding: valueScalePadding,
  });

  const domainY = domain || getMinMaxData(data, keys);
  const domainZ = domain || getMinMaxGraphData(dataGraph);
  const domainSingleScale = [
    min([...domainY, ...domainZ]),
    max([...domainY, ...domainZ]),
  ] as [number, number] ;

  const yScale = scaleLinear<number>({
    zero: data.length === 1 || !isScaleByValueState,
    nice: true,
    domain: isSingleScale ? domainSingleScale : domainY,
  });

  const zScale = scaleLinear<number>({
    zero: data.length === 1 || !isScaleByValueState,
    nice: true,
    domain: isSingleScale ? domainSingleScale : domainZ,
  });

  xScale.rangeRound([0, xMax]);
  valuesScale.rangeRound([0, xScale.bandwidth()]);
  yScale.range([yMax, 0]);
  zScale.range([yMax, 0]);

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: currentColors,
  });

  return {
    xScale,
    valuesScale,
    yScale,
    zScale,
    colorScale,
  };
};
