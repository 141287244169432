import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Placement } from '../../../../../../enums/visual-types';
import {
  getParsedAxisValues,
  getSimplifiedType,
} from '../../../../dropdown-layout/helpers/helpers';
import { ValueFilter } from '../../../../filters/wrappers/value-filter';
import { SelectFilter } from '../../../../filters/wrappers/select-filter';
import {
  DateFilter,
  DateFilterOptions,
} from '../../../../filters/wrappers/date-filter/date-filter';
import { PanelType } from '../../../../../../enums/widget-type';
import { isDashboardPage } from '../../../helpers';
import { BoundType } from '../../../../../dashboard-page/settings/enums';
import { ColumnAndRowFilteringProps } from '../../interfaces';
import { useColumnAndRowFilters } from '../../hooks/useColumnAndRowFilters';
import { PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { useDashboardFilters } from '../../../../../dashboard-page/hooks/useDashboardFilters';
import { useDashboardBoundFilters } from '../../../../../dashboard-page/hooks/useDashboardBoundFilters';
import { useDashboardManualFilters } from '../../../../../dashboard-page/hooks/useDashboardManualFilters';
import { getAllFieldFilters } from '../../../../../../helpers/dashboard-page';
import { SelectDateTypes } from '../../../../../../enums/dashboard-properties';
import { FilterValue } from '../../../../filters/types';
import { useColumnSorting } from './useColumnSorting';
import { DateFunctions } from '../../../../../../enums/date';


export const ColumnAndRowFiltering = ({
  widgetProperties,
  widgetId,
  panelType,
  currentFieldId,
  setDashboardWidgetState,
  closeFilterPanel,
}: ColumnAndRowFilteringProps) => {

  const { getFilter, setFilter } = useColumnAndRowFilters({
    widgetProperties,
    panelType,
    widgetId,
    currentFieldId,
    setDashboardWidgetState,
  });

  const { setFieldSorting } = useColumnSorting({
    widgetProperties,
    setDashboardWidgetState,
  });

  const isDashboard = isDashboardPage();
  const { values: dashboardFiltersGroups, getGroupForFilter } =
    useDashboardFilters();

  const widgetFilters: PropertyData[] = getParsedAxisValues(
    PanelType.axisFilter,
    widgetProperties,
  );
  const currentFilter = getFilter();

  const currentFilterId = currentFilter?.id || -1;
  const widgetFiltersIds = widgetFilters.map((value) => {
    return value.id;
  });

  const filterGroupId = getGroupForFilter(currentFilterId)?.id || 0;

  const boundFilers = useDashboardBoundFilters();
  const drilldownFilters = useDashboardManualFilters(
    BoundType.autoDrillDownGroups,
  )?.drilldownValues;
  drilldownFilters.concat(
    useDashboardManualFilters(BoundType.manualDrillDownGroups)?.drilldownValues,
  );

  const allFieldFilters = isDashboard
    ? getAllFieldFilters(
      dashboardFiltersGroups,
      filterGroupId,
      isDashboard,
      widgetFilters,
      widgetFiltersIds,
      boundFilers,
      widgetId,
      drilldownFilters,
    )
    : getAllFieldFilters(
      widgetFilters,
      currentFilterId,
      isDashboard,
      widgetFilters,
      widgetFiltersIds,
    );

  const onChangeFilter = (filter: any) => {
    setFilter(filter);
  };

  const label = useMemo(() => <span style={{ fontWeight: 400 }}>Фильтр по столбцу</span>, []);

  const renderer = useMemo(() => {
    if (!currentFilter) return null;

    const remapedType = getSimplifiedType(currentFilter.type);
    const { filter } = currentFilter;

    const preparedAllFieldFilters = allFieldFilters.map(
      (fieldFilter: any) => {
        const newFieldFilter = { ...fieldFilter };
        if (currentFilter.id === fieldFilter.sourceFieldId) {
          newFieldFilter.filters = [];
        }
        return newFieldFilter;
      },
    );

    switch (remapedType) {
      case 'TEXT': {
        if (currentFilter.aggregation?.includes('COUNT')) {
          return (
            <ValueFilter
              onChangeFilter={onChangeFilter}
              currentFilter={filter as FilterValue[]}
              label={currentFilter.name}
              closeFilterPanel={closeFilterPanel}
              placement={Placement.Widget}
            />
          );
        }

        return (
          <SelectFilter
            label={label}
            onChangeFilter={onChangeFilter}
            field={currentFilter.name}
            sourceId={currentFilter.etlSourceId || ''}
            currentFilter={currentFilter.filter}
            allFieldFilters={preparedAllFieldFilters}
            widgetId={widgetId}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Widget}
          />
        );
      }

      case 'DATE': {
        if (currentFilter.aggregation?.includes('COUNT')) {
          return (
            <ValueFilter
              onChangeFilter={onChangeFilter}
              currentFilter={filter as FilterValue[]}
              label={currentFilter.name}
              closeFilterPanel={closeFilterPanel}
              placement={Placement.Widget}
            />
          );
        }

        // todo в данном месте displayOptions - костыль, надо доработать логику фильтров виджета с учетом новых фильтров дашборда
        const isPeriod = currentFilter.function === '' || currentFilter.function.includes('DAY');
        const filterOptions: DateFilterOptions = {
          displayAvailablePeriod: true,
          minValue: currentFilter.minValue || null,
          maxValue: currentFilter.maxValue || null,
          displayOptions: {
            displayAsSingleDate: {
              value: !isPeriod,
              displayedName: SelectDateTypes.date,
            },
            displayAsPeriod: {
              value: isPeriod,
              displayedName: SelectDateTypes.period,
            },
          },
          selectDateType: isPeriod ? SelectDateTypes.period : SelectDateTypes.date
        };

        return (
          <DateFilter
            label={label}
            onChangeFilter={onChangeFilter}
            currentFilter={filter as FilterValue[]}
            filterType={
              currentFilter.function
                ? currentFilter.function.split("'")[1]
                : DateFunctions.NO_TYPE
            }
            filterOptions={filterOptions}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Widget}
          />
        );
      }

      case 'NUMBER': {
        return (
          <ValueFilter
            label={label}
            onChangeFilter={onChangeFilter}
            currentFilter={filter as FilterValue[]}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Widget}
          />
        );
      }

      case 'BOOLEAN': {
        return (
          <SelectFilter
            label={label}
            onChangeFilter={onChangeFilter}
            field={currentFilter.name}
            sourceId={currentFilter.etlSourceId || ''}
            currentFilter={currentFilter.filter}
            allFieldFilters={preparedAllFieldFilters}
            widgetId={widgetId}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Widget}
          />
        );
      }

      default: {
        return (
          <div>Нет фильтра для параметра с типом "{currentFilter.type}"</div>
        );
      }
    }
  }, [label, currentFilter, allFieldFilters]);

  return currentFilter ? (
    <>
      {renderer}
    </>
  ) : null;
};
