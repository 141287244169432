import moment from 'moment';
import { cloneDeep } from 'lodash';
import { CSSProperties } from 'react';
import { getSimplifiedType } from '../components/widget-page/dropdown-layout/helpers/helpers';
import {
  Property,
  PropertyData,
} from '../components/widget-page/dropdown-layout/helpers/Property';
import WidgetsTypes from '../types/widgets';
import { apiBI } from './api';
import { WidgetProperties } from '../slices/types';
import { initialState } from '../slices/widget/widget';
import { Filter, FilterGroupProps } from '../components/dashboard-page/hooks';
import { getPropertyId } from '../components/widget-page/helpers';

export const numDecline = (
  num: number = 0,
  single: string,
  singleTwice: string,
  plural: string,
): string => {
  if (num > 10 && Math.round((num % 100) / 10) === 1) {
    return plural;
  }
  switch (num % 10) {
    case 1:
      return single;
    case 2:
    case 3:
    case 4:
      return singleTwice;
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 0:
      return plural;
  }
  return single;
};

export const clone = <T>(src: T): T => JSON.parse(JSON.stringify(src)) as T;

export const stringToBoolean = {
  true: true,
  false: false,
};

// TODO нужно переписать логику с использованием getSimplifiedType()
export const getPictureType = (columnType: any) => {
  enum Icon {
    NUMBER = 'NumberTypeLogo',
    STRING = 'StringTypeLogo',
    DATE = 'DateTypeLogo',
    BOOLEAN = 'BooleanTypeLogo',
    QUESTION = 'Question',
  }

  switch (columnType) {
    case 'NUMBER':
      return Icon.NUMBER;
    case 'INTEGER':
      return Icon.NUMBER;
    case 'BINARY':
      return Icon.NUMBER;
    case 'DOUBLE':
      return Icon.NUMBER;
    case 'LONG':
      return Icon.NUMBER;
    case 'STRING':
      return Icon.STRING;
    case 'DATE':
      return Icon.DATE;
    case 'TIMESTAMP':
      return Icon.DATE;
    case 'BOOLEAN':
      return Icon.BOOLEAN;
    case 'UNKNOWN':
      return Icon.QUESTION;
    default:
      return Icon.QUESTION;
  }
};

interface Option {
  label: string;
  value: any;
}

export const arrayToOptions = (array: Array<any>): Array<Option> => {
  return array.map((item) => ({ label: item, value: item }));
};

export const wrapFieldToReplace = (fieldName: string) => {
  return `[${fieldName}]`;
};

export const getDefaultWidgetProps = (
  widgetTypes: WidgetsTypes.Type[],
  type?: string,
) => {
  const currentType = type || initialState.type;
  const currentTypeData = widgetTypes?.find(
    (widgetType) => widgetType.type === currentType,
  );

  return currentTypeData?.propertiesGroups.reduce((acc: any, propertyGroup) => {
    propertyGroup &&
      propertyGroup.propertiesMetaBlocks.forEach((propertiesMetaBlocks) => {
        propertiesMetaBlocks &&
          propertiesMetaBlocks.metaProperties.forEach((metaProperty) => {
            acc.push({
              name: metaProperty.name,
              value: metaProperty.defaultValue,
            });
          });
      });
    return acc;
  }, []) || [];
};

export const getSettingWidgetProps = (defaultProps: WidgetProperties[], widgetProps: WidgetProperties[]) => {
  let settingProps: WidgetProperties[] = [];
  const defaultPropsCopy = cloneDeep(defaultProps);
  const widgetPropsCopy = cloneDeep(widgetProps);

  settingProps = defaultPropsCopy.reduce((acc: any[], prop: any) => {
    const widgetProp = widgetPropsCopy.find((widgetProp: any) => {
      return widgetProp.name === prop.name;
    });

    const settingProp = widgetProp || prop;

    return [...acc, settingProp];
  }, settingProps);

  return settingProps;
};

export const calculateTextDimensions = (text: string, styles: any) => {
  let createdEl: any = document.createElement('div');

  document.body.appendChild(createdEl);
  createdEl.style.fontSize = `${styles.fontSize}px`;
  if(styles.fontFamily) createdEl.style.fontFamily = styles.fontFamily;
  if(styles.fontStyle) createdEl.style.fontStyle = styles.fontStyle;
  if(styles.fontWeight) createdEl.style.fontWeight = styles.fontWeight;
  if(styles.lineHeight) createdEl.style.lineHeight = styles.lineHeight;
  createdEl.style.position = 'absolute';
  createdEl.style.left = '-1000';
  createdEl.style.top = '-1000';
  createdEl.innerHTML = text;

  const result = {
    width: createdEl.clientWidth,
    height: createdEl.clientHeight,
  };

  document.body.removeChild(createdEl);
  createdEl = null;

  return result;
};

export const replaceAll = (
  str: string,
  pattern: string,
  replacement: string,
) => {
  return str.split(pattern).join(replacement);
};

export const BIRequestCatcher = (() => {
  let ignoreList: any[] = [];
  return {
    subscribe: (url: string, onSuccess?: any, onError?: any) => {
      apiBI.interceptors.response.use(
        (response: any) => {
          if (response?.config?.url === url && !ignoreList.includes(url)) {
            onSuccess(response);
          }
          return response;
        },
        (error) => {
          if (error?.config?.url === url && !ignoreList.includes(url)) {
            onError(error);
          }
          return Promise.reject(error);
        },
      );
    },
    addUrlToIgnoreList: (url: string) => {
      ignoreList.push(url);
    },
    cleanIgnoreList: () => {
      ignoreList = [];
    },
  };
})();

export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}

export function stringToBool(value: string) {
  return value === 'true';
}

export function getUID() {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }

  return `${
    chr4() + chr4()
  }-${chr4()}-${chr4()}-${chr4()}-${chr4()}${chr4()}${chr4()}`;
}

export const getRangeOfDates = (startDate: any, endDate: any) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const diff = toDate.diff(fromDate, 'day');
  const range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, 'day'));
  }
  return range;
};

export const roundDate = (date: Date, mode: string = 'floor') => {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const oneDayMs = 24 * 60 * 60 * 1000;

  switch (mode) {
    case 'floor': {
      return new Date(
        Math.floor((date.getTime() - offsetMs) / oneDayMs) * oneDayMs +
          offsetMs,
      );
    }
    case 'round': {
      return new Date(
        Math.round((date.getTime() - offsetMs) / oneDayMs) * oneDayMs +
          offsetMs,
      );
    }

    case 'ceil': {
      return new Date(
        Math.ceil((date.getTime() - offsetMs) / oneDayMs) * oneDayMs + offsetMs,
      );
    }

    default: {
      return new Date(date);
    }
  }
};

export const insert = (arr: any[], index: number, newItem: any) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const roundNumber = (number: number, digits?: number) =>
  parseFloat(number.toFixed(digits || 0));

export const mergeWidgetProperties = (
  oldProperties: WidgetProperties[],
  newProperties: WidgetProperties[] = [],
) => {
  const mergedProperties: WidgetProperties[] = JSON.parse(
    JSON.stringify(oldProperties),
  );

  newProperties.forEach((property, index) => {
    const axisIndex = mergedProperties.findIndex(
      (item) => item.name === property.name,
    );

    if (axisIndex > -1) {
      mergedProperties[axisIndex] = {
        ...oldProperties[axisIndex],
        ...newProperties[index],
        value: property.value,
      };
    } else {
      mergedProperties.push({
        name: property.name,
        value: property.value,
      });
    }
  });

  if (JSON.stringify(oldProperties) === JSON.stringify(mergedProperties)) return oldProperties;

  return mergedProperties;
};

export const isNeedMergeNotNumberValues = (item: PropertyData) =>
  getSimplifiedType(item.type) !== 'NUMBER' &&
  !item.aggregation?.includes('COUNT');

type MergeAxisFiltersOptions = {
  isNeedToAddFields?: boolean;
  dashboardFilterGroups?: FilterGroupProps[],
};

const FUNCTIONS_ORDER_PRIORITY_RULE: string[] = ['', "date_trunc('DAY',%s)", "date_trunc('WEEK',%s)", "date_trunc('MONTH',%s)", "date_trunc('QUARTER',%s)", "date_trunc('YEAR',%s)"];

const getGreatestPriorityFunction = (oldFunction: string, newFunction: string): string => {
  const newIndex = FUNCTIONS_ORDER_PRIORITY_RULE.indexOf(newFunction);
  const oldIndex = FUNCTIONS_ORDER_PRIORITY_RULE.indexOf(oldFunction);

  const isHaveNewIndex = FUNCTIONS_ORDER_PRIORITY_RULE.includes(newFunction);
  const isHaveOldIndex = FUNCTIONS_ORDER_PRIORITY_RULE.includes(oldFunction);

  if (!isHaveNewIndex && !isHaveOldIndex) {
    return oldFunction;
  }

  if (!isHaveOldIndex || (newIndex > oldIndex && isHaveNewIndex)) {
    return newFunction;
  }

  return oldFunction;
};

export const mergeAxisFilters = (
  oldFields: PropertyData[],
  newFields: PropertyData[] = [],
  {
    dashboardFilterGroups = [],
    isNeedToAddFields = false,
  }: MergeAxisFiltersOptions,
) => {
  const mergedFields: PropertyData[] = cloneDeep(oldFields);

  newFields.forEach((newField) => {
    const newFieldId = new Property(newField).getId();

    const widgetFilters = dashboardFilterGroups
      .map((group) => ({
        ...group,
        widgetFilters: group.widgetFilters.map((widgetFilter) => ({ ...widgetFilter, actualGroup: group.actual }))
      }))
      .find((group) =>
        group.widgetFilters
          .find(
            (widgetFilter) => widgetFilter.id === newFieldId
          ),
      )?.widgetFilters || [];
    const groupFilterIDs =
      widgetFilters?.map((widgetFilter) => widgetFilter.id);

    // если у фильтров одинаковый ID, то связываем сразу по нему, вне зависимости от dashboardFilterGroups
    const filterIDs = [
      ...groupFilterIDs.filter((filterID) => filterID !== newFieldId),
      newFieldId,
    ];

    const axisIndex = mergedFields.findIndex((mergedField) =>
      filterIDs?.includes(new Property(mergedField).getId()),
    );

    if (axisIndex > -1) {
      // в рамках задачи BIDEV-7396 отключили проверку на то является ли фильтр фильтром виджета, и наследуем всё
      // const isDashboardFilter = Boolean(widgetFilters.find((widgetFilter) =>
      //   widgetFilter.id === newFieldId
      //   && (widgetFilter.actual && widgetFilter.actualGroup)
      // ));
      // const isWidgetFilter = newField.filter?.length && !isDashboardFilter;

      let newFilterValue: string | Filter[];

      if (typeof newField.filter === 'string' || typeof mergedFields[axisIndex].filter === 'string') {
        newFilterValue = newField.filter || mergedFields[axisIndex].filter;
      } else {
        newFilterValue = [...newField.filter as Filter[], ...mergedFields[axisIndex].filter as Filter[]];
      }

      const newFunction = getGreatestPriorityFunction(mergedFields[axisIndex].function, newField.function);

      mergedFields[axisIndex] = {
        ...mergedFields[axisIndex],
        filter: newFilterValue,
        function: newFunction,
      };
    } else if (isNeedToAddFields) {
      mergedFields.push({ ...newField, isInherited: true });
    }
  });

  return mergedFields;
};

export const initialMergeAxisFilters = (
  filters: PropertyData[],
  dashboardFilterGroups: FilterGroupProps[],
) => {
  const newFilters = cloneDeep(filters);

  filters.forEach((filter, index) => {
    dashboardFilterGroups.forEach((group) => {
      const dashboardFilter = group.widgetFilters.find(
        (widgetFilter) => widgetFilter.id === getPropertyId(filter),
      );
      dashboardFilter && (newFilters[index].filter = dashboardFilter.value);
    });
  });

  return newFilters;
};

export const getEnumKeys = <E extends object>(e: E): string[] =>
  Object.keys(e).filter((key) => isNaN(Number(key))) as string[];

export const parseJSON = (json: string): unknown | undefined => {
  try {
    return JSON.parse(json) as unknown;
  } catch (e) {
    return undefined;
  }
};
