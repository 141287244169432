import React from 'react';
import { axisTickLabelProps, MARGIN_TOP_LABEL_VALUE } from '../constants';
import { Scales } from '../hooks/useScales';
import {
  BubbleData,
  BubbleSets,
  Dimensions,
  FormattingValues,
  Interaction,
  SeriesValues,
} from '../types';

interface ColumnValuesProps {
  xPosition: number;
  data: BubbleData[];
  fields: SeriesValues;
  formattingValues: FormattingValues;
  interaction: Interaction;
  bubbleSets: BubbleSets;
  dimensions: Dimensions;
  scales: Scales;
}

export const ColumnValues = ({
  xPosition,
  data,
  fields,
  formattingValues,
  interaction,
  bubbleSets,
  dimensions,
  scales,
}: ColumnValuesProps) => {
  const {
    handleBubbleMouseMove,
    handleBubbleMouseLeave,
    selectBubble,
    selectedAxisXValue,
  } = interaction;

  const { formatAxisXValue, formatAxisYValue, formatAxisZValue } =
    formattingValues;

  const { xField, yFields, zField } = fields;

  const {
    isNeedToDisplayValue,
    isNeedToDisplayPercent,
    isNeedToDisplayNameSlice,
    isNeedToDisplayTooltip,
  } = bubbleSets;

  const { chartHeight, chartTopOffset, columnWidth } = dimensions;

  const { verticalScale, radiusScale } = scales;

  const { axisXValue } = data[xPosition];

  return (
    <div
      data-name={`Столбик график ${xPosition}`}
      style={{
        height: chartHeight,
        width: '100%',
      }}
    >
      {yFields.map((value, index) => {
        const yValue = data[xPosition].axisYValues[index];
        if (!isFinite(yValue)) return null;
        const yPercent = data[xPosition].axisYPercents[index];
        const zValue = data[xPosition].axisZValues[index];
        const zPercent = data[xPosition].axisZPercents[index];
        const slice = data[xPosition].slices[index];
        const yTopOffset = verticalScale(yValue) + chartTopOffset;

        const diameter = radiusScale(data[xPosition].axisZRadius[index]) * 2;

        const onMouseMove = isNeedToDisplayTooltip
          ? (event: any) => {
            handleBubbleMouseMove(event, {
              attrX: xField.name,
              valueX: formatAxisXValue(axisXValue),
              attrY: value?.legendsLabel,
              valueY: `${String(
                formatAxisYValue(yValue),
              )} (${formatAxisYValue(yPercent)}%)`,
              attrZ: zField?.name,
              valueZ: `${formatAxisZValue(zValue)} (${formatAxisZValue(
                zPercent,
              )}%)`,
            });
          }
          : undefined;

        const onMouseLeave = isNeedToDisplayTooltip
          ? handleBubbleMouseLeave
          : undefined;

        const onClick: React.MouseEventHandler<HTMLDivElement> | undefined =
          selectBubble
            ? (event) => {
              event.stopPropagation();
              selectBubble(axisXValue);
            }
            : undefined;

        const isActiveSelect = Boolean(selectBubble);

        const bubbleOpacity =
          selectedAxisXValue === null ||
          selectedAxisXValue?.includes(axisXValue)
            ? 1
            : 0.2;

        return (
          <div
            className="widget-bubble-chart__value"
            key={value.key}
            style={{
              top: yTopOffset,
            }}
          >
            <div
              className="widget-bubble-chart__value-circle"
              style={{
                left: columnWidth / 2 + -diameter / 2,
                top: -diameter / 2,
                width: diameter,
                height: diameter,
                backgroundColor: value.color,
                opacity: bubbleOpacity,
                cursor: isActiveSelect ? 'pointer' : undefined,
              }}
              onClick={onClick}
              onMouseMove={onMouseMove}
              onMouseLeave={onMouseLeave}
            />
            <div
              className="widget-bubble-chart__value-labels"
              style={{
                top: diameter / 2 + MARGIN_TOP_LABEL_VALUE,
                width: columnWidth,
              }}
            >
              <div
                className="relative"
                style={{
                  ...axisTickLabelProps,
                  cursor: isActiveSelect ? 'pointer' : undefined,
                }}
                onClick={onClick}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
              >
                {isNeedToDisplayValue && (
                  <div className="relative">
                    {formatAxisYValue(yValue)}
                  </div>
                )}
                {isNeedToDisplayPercent && (
                  <div className="relative">
                    {formatAxisYValue(yPercent)}%
                  </div>
                )}
                {isNeedToDisplayNameSlice && slice && (
                  <div className="relative">{slice}</div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};