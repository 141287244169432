import React, { CSSProperties, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import _, { sortBy } from 'lodash';
import { Group } from '@visx/group';
import { BarStack } from '@visx/shape/lib/types';
import { Bar } from '../../bar-group/hooks/useBarDimensions';
import { barValueStyles, bottomAxisSettings, gridLinesXOffset, minBottomValueTextWidth } from '../settings';
import { PanelType } from '../../../../../enums/widget-type';
import { textDirections } from '../../../../common/scalable-svg-text';
import { useStackBarProps } from '../hooks/useStackBarProps';
import { getCorrectWidgetData, getHistogramData, getHistogramDataIndexes, getStackedHistogramPercentData } from '../../helpers';
import { getTooltipHandler } from '../../../../common/widget-tooltip/widget-tooltip';
import { FilterField } from '../../../../dashboard-page/hooks';
import { WidgetProperties } from '../../../../../slices/types';
import { tickLineWidth } from '../../bar-group/settings';
import { getFirstActivePanelItemIndex } from '../../../../../helpers/common-helpers';

interface Props {
  convertedBarGroup: any;
  barGroups: BarStack<any, string>[];
  style: CSSProperties;
  index: number;
  isHasNegative: boolean;
  barWidth: number;
  yMax: number;
  yScale: any;
  currentColors: string[];
  valueFormat: any;
  setPropForNumberValue: (
    widgetProperties?: WidgetProperties[],
  ) => WidgetProperties[];
  widgetProperties: WidgetProperties[];
  isActiveFilter: boolean;
  filterField: FilterField;
  data: any;
  enableEvents: boolean;
  viewportRef: React.MutableRefObject<HTMLDivElement>;
  bottomAxisHeight: number;
  widgetData: any;
  selectBar: (value: string) => void;
  keys: string[];
  axisXLabelWidth: number;
  offsetBetweenGroups: number;
}

export const CustomBarGroup = ({
  convertedBarGroup = [],
  barGroups,
  style,
  index,
  isHasNegative,
  barWidth,
  yMax,
  yScale,
  currentColors,
  valueFormat,
  setPropForNumberValue,
  widgetProperties,
  isActiveFilter,
  filterField,
  data,
  enableEvents,
  viewportRef,
  bottomAxisHeight,
  widgetData,
  selectBar,
  keys,
  axisXLabelWidth,
  offsetBetweenGroups,
}: Props) => {
  const {
    columnWidth,
    isDisplayValueInPercent,
    isNormalized,
    isOverlap,
    isStacked,
    isNeedToDisplayTooltip,
    textDirection,
    isNeedToDisplayBarValuesTotal,
    isNeedToDisplayBarValues,
    isNeedToDisplayInsideBarValues,
    legendsLabels,
    axisYValues,
    axisXValues,
    scaleByNumber,
    formatByNumber,
    valueTextSize,
    valueColorTotal,
    valueColor
  } = useStackBarProps({
    widgetProperties,
  });

  const originalData = useMemo(
    () => getHistogramData(getCorrectWidgetData(widgetData), isOverlap),
    [widgetData, isOverlap],
  );

  const dataWithPercents = useMemo(
    () =>
      isNormalized
        ? { ...data }
        : getStackedHistogramPercentData(widgetData, isOverlap),
    [isNormalized, data, widgetData, isOverlap],
  );

  const getBarValue = (bar: any) => {
    return bar.bar.data[bar.key];
  };

  const getBarYValue = (bar: any): any => {
    const keysSlice = keys.slice(0, keys.indexOf(bar.key));
    const barValue = getBarValue(bar);

    const valueForScale = keysSlice
      .map((key) => bar.bar.data[key])
      .reduce((accumulator: number, currentValue: number) => {
        if (isOverlap) return accumulator;

        if (barValue >= 0) {
          return currentValue < 0
            ? accumulator
            : (accumulator + currentValue);
        }

        return currentValue >= 0
          ? accumulator
          : (accumulator + currentValue);
      }, 0);

    return barValue > 0
      ? yScale(valueForScale + barValue)
      : yScale(valueForScale);
  };

  const getBarValueFromPercent = (data: any, bar: any) => {
    return _.find(data, (row) => row.x === bar.bar.data.x)[bar.key];
  };

  const getInsideValue = (bar: any) => {
    const value = isNeedToDisplayBarValues ? valueFormat(
      getBarValueFromPercent(originalData, bar),
      0,
      setPropForNumberValue(widgetProperties),
      PanelType.axisY,
      false,
      false,
      scaleByNumber ? formatByNumber : null,
      true
    ) : '';

    const percentValue = isDisplayValueInPercent
      ? valueFormat(
        getBarValueFromPercent(dataWithPercents, bar),
        0,
        setPropForNumberValue(widgetProperties),
        PanelType.axisY,
        isDisplayValueInPercent,
      )
      : '';

    const percentString = value && percentValue.length ? ` (${percentValue})` : percentValue;

    return value + percentString;
  };

  const dataIndexes = useMemo(
    () => getHistogramDataIndexes(getCorrectWidgetData(widgetData), isOverlap),
    [widgetData, isOverlap],
  );

  const { handleWidgetMouseMove, handleWidgetMouseLeave } = getTooltipHandler(
    viewportRef,
  );

  useEffect(() => {
    return () => {
      handleWidgetMouseLeave();
    };
  });

  const barTotalValues = getCorrectWidgetData(widgetData).map((item: any) =>
    _.sum(item.y),
  );

  const zeroPoint = yScale(0);
  const sortedBarGroup =
    isOverlap
      ? sortBy(convertedBarGroup, (barA: any, barB: any) => Math.abs(barB?.height) - Math.abs(barA?.height))
      : convertedBarGroup;

  const barHeightsList = sortedBarGroup.flatMap((bar: Bar) => bar.height);

  const positiveTotalHeight = barHeightsList.reduce((accumulator: number, currentValue: number) => {
    return currentValue >= 0 ? accumulator + currentValue : accumulator;
  }, 0);
  const negativeTotalHeight = barHeightsList.reduce((accumulator: number, currentValue: number) => {
    return currentValue < 0 ? accumulator - currentValue : accumulator;
  }, 0);

  const barGroupStyle: CSSProperties = {
    top: !isNormalized
      ? 'unset'
      : 0,
    bottom: isStacked
      ? isHasNegative
        ? 'unset'
        : 0
      : 'unset',
    width: barWidth,
    height: isStacked
      ? 'fit-content'
      : yMax,
  };

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  return (
    <Group
      className="stacked-histogram__bar-group"
      key={`stacked-bar-group-${index}`}
      style={{
        ...style,
        ...barGroupStyle,
      }}
    >
      {sortedBarGroup.map((bar: any) => {
        const isNullData = isNaN(bar.bar.data[0]);
        if (isNullData) return;

        const maxHeight = barTotalValues[bar.index] >= 0 ? Math.max(...barHeightsList) : Math.min(...barHeightsList);

        let groupIndex = 0;
        const tooltipListY = barGroups
          .map((bGroup: any) => {
            groupIndex = bGroup.bars.findIndex(
              (b: Bar) => b.index === bar.index,
            );
            return bGroup.bars.find((b: Bar) => b.index === bar.index);
          })
          .map((b: Bar) => ({
            attrY: dataIndexes && b
              ? legendsLabels[dataIndexes[groupIndex][parseInt(b.key)]]
              : legendsLabels[b?.key || 0],
            valueY: getInsideValue(b),
            color:
            dataIndexes && b
              ? currentColors[
                dataIndexes[groupIndex][parseInt(b.key)]
              ]
              : b?.color ?? 'white',
          }));

        const barValueNotFormatted = getBarValueFromPercent(originalData, bar);

        const barRectStyle: CSSProperties = {
          position: isHasNegative || isOverlap
            ? 'absolute'
            : 'relative',
          top: isHasNegative
            ? bar.height >= 0
              ? getBarYValue(bar) + tickLineWidth
              : getBarYValue(bar)
            : 'unset',
          bottom: (isOverlap || isStacked) && isHasNegative
            ? 'unset'
            : 0,
          width: barWidth,
          height: Math.abs(bar.height),
        };

        const totalValueStyle: CSSProperties = {
          top: isOverlap ?
            barTotalValues[bar.index] >= 0
              ? zeroPoint - Math.abs(maxHeight) - barValueStyles.lineHeight
              : zeroPoint + Math.abs(maxHeight)
            : isStacked
              ? barTotalValues[bar.index] >= 0
                ? isHasNegative
                  ? zeroPoint - positiveTotalHeight - barValueStyles.lineHeight
                  : -barValueStyles.lineHeight
                : zeroPoint + negativeTotalHeight
              : barTotalValues[bar.index] >= 0
                ? zeroPoint - positiveTotalHeight - barValueStyles.lineHeight
                : zeroPoint + negativeTotalHeight,
          width: barWidth,
          color: barValueStyles.outsideColor,
        };

        const barColor = dataIndexes
          ? currentColors[
            dataIndexes[bar.index][+bar.key]
          ]
          : bar.color;

        return (
          <>
            {isNeedToDisplayBarValuesTotal &&
              barGroups[bar.key].index === axisYValues.length - 1 && (
                <div
                  className="stacked-histogram__total-value"
                  style={{
                    ...totalValueStyle,
                    fontSize: valueTextSize,
                    color: valueColorTotal || '#000'
                  }}
                >
                  {valueFormat(
                    barTotalValues[bar.index],
                    0,
                    setPropForNumberValue(widgetProperties),
                    PanelType.axisY,
                    false,
                    false,
                    scaleByNumber ? formatByNumber : null,
                    true
                  )}
                </div>
            )}

            <div
              className={clsx('stacked-histogram__rect', {
                'active-drilldown-element': isActiveFilter,
              })}
              style={{
                ...barRectStyle,
                backgroundColor: `${barColor}`,
                opacity:
                  filterField?.value === null ||
                  filterField?.value === undefined ||
                  filterField?.value?.includes(data[index]?.x)
                    ? 0.8
                    : 0.2,
              }}
              key={`bar-group-bar-${sortedBarGroup.index}-${bar.index}-${bar.bar.data.x}-${bar.key}`}
              onClick={(e) => {
                if (!enableEvents) return;
                e.stopPropagation();
                selectBar(bar.bar.data.x);
              }}
              onMouseLeave={
                isNeedToDisplayTooltip
                  ? handleWidgetMouseLeave
                  : undefined
              }
              onMouseMove={
                isNeedToDisplayTooltip
                  ? (event) =>
                    handleWidgetMouseMove(event, {
                      attrX: axisXValues[0],
                      valueX: valueFormat(
                        bar.bar.data.x,
                        axisXValueIndex,
                        widgetProperties,
                      ),
                      list: tooltipListY,
                    })
                  : undefined
              }
            >
              {isNeedToDisplayInsideBarValues && (
                <div
                  className={clsx('stacked-histogram__inside-value', {
                    'stacked-histogram__inside-value_hidden': Math.abs(bar.height) < barValueStyles.lineHeight,
                  })}
                  style={{
                    width: barWidth,
                    top: barValueNotFormatted >= 0 ? 0 : 'unset',
                    bottom: barValueNotFormatted >= 0 ? 'unset' : 0,
                    color: valueColor,
                    fontSize: valueTextSize,
                  }}
                >
                  {getInsideValue(bar)}
                </div>
              )}
            </div>
          </>
        );
      })}

      <div
        className="stacked-histogram__bottom-value-container"
        style={{
          position: isStacked
            ? 'relative'
            : 'absolute',
          bottom: isOverlap || isNormalized
            ? -(bottomAxisHeight + gridLinesXOffset)
            : isStacked && isHasNegative
              ? -yMax
              : 0,
          height: bottomAxisHeight,
          width: textDirection === textDirections.horizontal ? axisXLabelWidth : columnWidth,
          left: textDirection === textDirections.horizontal ? -(offsetBetweenGroups / 2) : 0,
          overflow: textDirection === textDirections.horizontal ? 'hidden': 'visible',
          direction: textDirection === textDirections.horizontal ? 'ltr' : 'rtl',
          alignItems: textDirection === textDirections.horizontal ? undefined : 'flex-start',
        }}
      >
        <div
          className={clsx('stacked-histogram__bottom-value', {
            'stacked-histogram__bottom-value_vertical':
              textDirection === textDirections.vertical,
            'stacked-histogram__bottom-value_diagonal':
              textDirection === textDirections.diagonal,
          })}
          style={{
            display: 'flex',
            marginTop:  textDirection === textDirections.vertical ? '0px' : 0,
            width:
              textDirection === textDirections.diagonal
                ? Math.sqrt(
                  Math.pow(bottomAxisHeight, 2) +
                  columnWidth,
                ) - 30
                : textDirection === textDirections.vertical ? '100%' : '100%',
            height:
            textDirection !== textDirections.horizontal
              ? textDirection === textDirections.diagonal ? bottomAxisSettings.lineHeight : ''
              : bottomAxisHeight,
            transform:
              textDirection === textDirections.diagonal
                ? 'rotate(-45deg) translate(-5px, 10px)'
                : textDirection === textDirections.vertical
                  ? 'rotate(-90deg)'
                  : 'none',
            transformOrigin: textDirection === textDirections.vertical ? 'right center' : undefined,
            position: textDirection === textDirections.vertical ? 'absolute' : undefined,
            right: textDirection === textDirections.vertical ? columnWidth / 2  : undefined,
          }}
        >
          <span
            className={clsx('stacked-histogram__bottom-value-text', {
              'stacked-histogram__bottom-value-text_horizontal':
                textDirection === textDirections.horizontal,
            })}
            style={{
              height:
                textDirection === textDirections.diagonal
                  ? bottomAxisSettings.lineHeight
                  : 'inherit',
              direction: 'ltr',
              position: textDirection === textDirections.horizontal ? undefined : 'absolute',
              right: textDirection === textDirections.horizontal ? undefined : 0,
            }}
          >
            {valueFormat(
              data[index]?.x,
              axisXValueIndex,
              widgetProperties,
            )}
          </span>
        </div>
      </div>
    </Group>
  );
};
