import React from 'react';
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useClassButton } from '../../../hooks/useClassButton';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

export interface NotificationProps {
  onAddExistingTable?: () => void;
  onChangeField?: () => void;
  notificationData: {
    isSuccess?: boolean;
    isWarning?: boolean;
    isError?: boolean;
    data?: object;
  };
}

const Notification: React.FC<NotificationProps> = ({
  notificationData,
  onAddExistingTable,
  onChangeField,
}) => {
  const { isSuccess, isWarning, isError, data } = notificationData;
  const classButton = useClassButton({ marginRight: '15px' });

  if (isSuccess) {
    return (
      <Alert severity="success">
        <AlertTitle>Успешно</AlertTitle>
        <p>
          Проверка успешно пройдена. Нажмите "Создать таблицу" для добавления
          объекта на карту загрузчика. После добавления выберите условие из
          которого будут загружаться данные. Затем задайте финальные настройки.
        </p>
      </Alert>
    );
  }

  if (isWarning) {
    return (
      <Alert severity="warning">
        <AlertTitle>Уведомление</AlertTitle>
        <p>{data as any}. Ваши действия?</p>
        <Button
          onClick={onChangeField}
          className={classButton.link}
        >
          Изменить название таблицы
        </Button>
        <Button
          onClick={onAddExistingTable}
          className={classButton.link}
          startIcon={<IconSvg svg={IconDictionary.ListAdd} fill="var(--grey-color)" />}
        >
          Добавить существующую таблицу
        </Button>
      </Alert>
    );
  }

  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Ошибка</AlertTitle>
        {data as any}
      </Alert>
    );
  }

  return null;
};

export default Notification;
