import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'field-list-valid': {
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: 'calc(80vh - 200px * var(--scale-coefficient))',
    },
    'field-list-invalid': {
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: 'calc(80vh - 275px * var(--scale-coefficient))',
    },
    'option-row': {
      display: 'flex',
      justifyContent: 'space-between',

      '& .MuiTextField-root': {
        margin: 'calc(8px * var(--scale-coefficient)) 0',
      },
    },
    'arrow-logo': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
);
