import React from 'react';
import { calculateTextDimensions } from '../../../utils/functions';
import { DashboardResponse, Widget } from '../../../slices/types';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

export type SortingType = 'noSort' | 'ASC' | 'DESC';

export const carouselItemFontSize: number = 14;

export const calcTooltipOpenWidth = (): number => {
  const windWidth = document.documentElement.clientWidth;
  if (windWidth > 1600) return 318;
  if (windWidth > 1366 && windWidth <= 1600) return 218;
  return 118;
};

export const getShowingTooltipState = (
  text: string,
  fontSize: number,
  maxWidth: number,
) => {
  const textWidth: number = calculateTextDimensions(text, { fontSize }).width;
  return textWidth < maxWidth;
};

export const compareForSort = (sort: SortingType) => (
  a: Widget | DashboardResponse,
  b: Widget | DashboardResponse,
) => {
  switch (sort) {
    case 'ASC':
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    case 'DESC':
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    default:
      return 0;
  }
};

export const getSortIcon = (sort: SortingType) => {
  switch (sort) {
    case 'ASC':
      return IconDictionary.SortDown;
    case 'DESC':
      return IconDictionary.SortUp;
    default:
      return IconDictionary.Sort;
  }
};

export const changeSort = (
  sort: SortingType,
  setSort: (value: React.SetStateAction<SortingType>) => void,
) => {
  switch (sort) {
    case 'noSort':
      setSort('ASC');
      break;
    case 'ASC':
      setSort('DESC');
      break;
    default:
      setSort('noSort');
      break;
  }
};
