import { JoinTypes } from '../../../enums/join-types';
import { UnionTypes } from '../../../enums/union-types';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

export const joinTypes = [
  {
    name: JoinTypes.LEFT_JOIN,
    description: CommonDictionary.LeftJoin,
  },
  {
    name: JoinTypes.RIGHT_JOIN,
    description: CommonDictionary.RightJoin,
  },
  {
    name: JoinTypes.FULL_JOIN,
    description: CommonDictionary.FullJoin,
  },
  {
    name: JoinTypes.INNER_JOIN,
    description: CommonDictionary.InnerJoin,
  },
];

export const unionTypes = [
  {
    name: UnionTypes.UNION,
    description: CommonDictionary.Union,
  },
  {
    name: UnionTypes.UNION_ALL,
    description: CommonDictionary.UnionAll,
  },
];
