import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
} from '@material-ui/pickers';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment/moment';
import { DATE_FORMAT } from '../../../../utils/consts';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../icon-svg/icon-svg';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: 'var(--white)',
    },
    background: 'var(--white)',
    border: '1px solid var(--dark-cyan)',
    borderRadius: '4px',

    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  input: {
    height: 'calc(44px * var(--scale-coefficient))',
    boxSizing: 'border-box',
  },
  focused: {
    background: 'var(--white)!important',
    borderBottom: '2px solid var(--primary-color)',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  shrink: {
    color: 'var(--dark-grey) !important',
  },
});

export const DateInput = ({
  label,
  invalidDateMessage = 'Невалидный тип даты',
  value,
  onChange,
  disablePast = false,
  ...props
}: KeyboardDateTimePickerProps) => {
  const classes = useStyles();

  const [date, setDate] = useState<MaterialUiPickersDate>(value ? moment(value) : null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    setDate(date);
    !isCalendarOpen && date?.isValid() && onChange(date);
    !date && onChange(date);
  };

  const handleOnOpen = () => {
    setIsCalendarOpen(true);
  };

  const handleOnClose = () => {
    setIsCalendarOpen(false);
    onChange(date);
  };

  return (
    <KeyboardDateTimePicker
      variant="inline"
      hideTabs
      disablePast={disablePast}
      label={label}
      ampm={false}
      value={date}
      onChange={handleChangeDate}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      format={DATE_FORMAT}
      invalidDateMessage={invalidDateMessage}
      keyboardIcon={
        <CustomIconButton
          iconButtonProps={{
            children: <IconSvg svg={IconDictionary.Calendar} width={24} height={24} fill="var(--dark-grey)" />,
          }}
          type={IconButtonType.secondary}
        />
      }
      inputVariant="filled"
      InputLabelProps={{
        classes: { shrink: classes.shrink },
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          input: classes.input,
          focused: classes.focused,
        },
      }}
      {...props}
    />
  );
};
