import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Divider } from '@material-ui/core';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import LoaderScheduleBody from './loader-schedule-body/loader-schedule-body';
import {
  apiFetchFutureLaunches,
  apiFetchLoaderSchedule,
  apiSetLoaderSchedule,
} from '../../../services/scheduler';
import Schedule from '../../../types/loader-schedule';
import { ScheduleType } from './helper';
import './loader-schedule.css';
import { State } from '../../../slices/types';
import { CustomButton } from '../../../uikit/Button';
import { CustomTabs } from '../../../uikit/Tabs';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';
import { CustomProgress } from '../../../uikit/Progress';
import IconSvg from '../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface LoaderScheduleProps {
  loaderId: number;
  handleClose: () => void;
}

const b = block('loader-schedule');

const initScheduleData = {
  type: ScheduleType.EVERY_DAY,
  intervalInMinutes: '',
  startDateTime: '',
  nearestLaunches: [],
  enable: false,
};

const LoaderSchedule = (props: LoaderScheduleProps) => {
  const { loaderId, handleClose } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [scheduleData, setScheduleData] = useState<Schedule.FullData>(
    initScheduleData,
  );
  const {
    type,
    intervalInMinutes,
    startDateTime,
    nearestLaunches,
    enable,
  } = scheduleData;

  const fetchSchedule = useCallback(() => {
    setIsLoading(true);
    apiFetchLoaderSchedule(loaderId)
      .then((schedule) => {
        if (!schedule) return;
        setScheduleData(schedule);
      })
      .catch((e) => {
        setError(e.response?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [loaderId]);

  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule]);

  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );

  const calculateNearestLaunches = useCallback(() => {
    if (startDateTime) {
      setIsLoading(true);
      const requestBody = { type, startDateTime, intervalInMinutes };
      apiFetchFutureLaunches(requestBody)
        .then((futureLaunches) => {
          setScheduleData((prevScheduleData: Schedule.FullData) => ({
            ...prevScheduleData,
            nearestLaunches: futureLaunches,
          }));
        })
        .catch((e) => {
          setError(e.response.message);
        })
        .finally(() => setIsLoading(false));
    }
  }, [intervalInMinutes, startDateTime, type]);

  useEffect(() => {
    calculateNearestLaunches();
  }, [startDateTime, intervalInMinutes, type, calculateNearestLaunches]);

  const handleChangeSchedule = (flag: boolean) => {
    setScheduleData((prevScheduleData: Schedule.FullData) => ({
      ...prevScheduleData,
      enable: flag,
    }));
  };

  const handleChangeField = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setScheduleData((prevScheduleData: Schedule.FullData) => ({
      ...prevScheduleData,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const requestBody = {
        type,
        startDateTime,
        intervalInMinutes,
        nearestLaunches,
        enable,
      };

      await apiSetLoaderSchedule(loaderId, requestBody);
      handleClose();
    } catch (e: any) {
      setError(e.response.message);
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      id: 0,
      title: 'Запуск по расписанию',
      onClick: () => handleChangeSchedule(true),
    },
    {
      id: 1,
      title: 'Не устанавливать расписание',
      onClick: () => handleChangeSchedule(false),
    },
  ];

  return (
    <section className={b()}>
      <div className={b('title')}>
        <h2 className={b('title-text')}>Настройка расписания запуска</h2>
        {isLoading && <CustomProgress type="circular" size={30} style={{ marginLeft: 10 }} />}
        <CustomButton
          variant="outlined"
          icon={<IconSvg
            svg={IconDictionary.Close}
            fill="var(--dark-grey)"
          />}
          onClick={handleClose}
        />
      </div>
      <Divider />
      <div className={b('container')}>
        <CustomTabs buttons={tabs} selected={scheduleData.enable ? 0 : 1} />

        <LoaderScheduleBody
          scheduleData={scheduleData}
          handleChange={handleChangeField}
        />
        {error && (
          <div className={b('error')}>
            <CustomErrorAlert>{error}</CustomErrorAlert>
          </div>
        )}
      </div>
      <div className="dialog-buttons">
        <CustomButton variant="contained" onClick={handleSubmit} disabled={!isEditableMap}>
          Сохранить
        </CustomButton>
        <CustomButton variant="outlined" onClick={handleClose}>
          Отменить
        </CustomButton>
      </div>
    </section>
  );
};

export default LoaderSchedule;
