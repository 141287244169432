import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputBase from '@material-ui/core/InputBase';
import { Popover } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputBase: {
    padding: 'calc(15px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient))',
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      padding: 'calc(8px * var(--scale-coefficient))',
      border: '1px solid var(--dark-cyan)',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 'calc(16px * var(--scale-coefficient))',
      height: 'calc(28px * var(--scale-coefficient))',
      '&:focus': {
        borderBottom: '2px solid var(--primary-color)',
        height: 'calc(27px * var(--scale-coefficient))',
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 'calc(15px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient))',
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 'calc(16px * var(--scale-coefficient))',
  },
  popperDisablePortal: {
    position: 'relative',
  },
}));

type SelectParameterProps = {
  anchorEl: HTMLElement | null;
  parameterList: string[];
  onSelect(value: string): void;
  onClose: () => void;
};

const SelectParameter: React.FC<SelectParameterProps> = ({
  anchorEl,
  parameterList,
  onSelect,
  onClose,
}) => {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{}>, newValue: string | null) => {
    newValue && onSelect(newValue);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Popover
        open
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <div style={{ width: 300 }}>
          <Autocomplete
            open
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            onChange={handleChange}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No parameters"
            renderOption={(option) => (
              <div>{option}</div>
            )}
            options={parameterList}
            getOptionLabel={(option) => option}
            renderInput={(params) => {
              return (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  className={classes.inputBase}
                />
              );
            }}
          />
        </div>
      </Popover>
    </>
  );
};

export default SelectParameter;
