import React, { memo } from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { WidgetProperties } from '../../../../slices/types';
import { DrilldownDialog } from './drilldown-dialog';
import { PropertyData } from '../../../widget-page/dropdown-layout/helpers/Property';
import { WidgetCard } from './widget-card/widget-card';
import { useWidget } from '../hooks/useWidget';
import { DeleteButton } from '../../../common/special-buttons/delete-button/delete-button';
import { CustomProgress } from '../../../../uikit/Progress';

export type DashboardWidgetProps = {
  widgetId: number;
  handleDeleteWidget?: (widgetId: number) => void;
  isStatic?: boolean;
  isDrillDown?: boolean;
  parentFilters?: PropertyData[] | null;
  increaseDrilldownDepth: () => void;
  decreaseDrilldownDepth: (isClose?: boolean) => void;
  parentId?: number;
  drillDownDepth: number;
  overriddenPropertiesInherited?: WidgetProperties[];
};

const DashboardWidget: React.FC<DashboardWidgetProps> = memo(
  ({
    widgetId,
    handleDeleteWidget,
    isStatic = false,
    isDrillDown = false,
    parentFilters = null,
    increaseDrilldownDepth,
    decreaseDrilldownDepth,
    drillDownDepth,
    parentId,
    overriddenPropertiesInherited,
  }) => {

    const {
      containerRef,
      setOverriddenProperties,
      overriddenProperties,
      closeDrillDown,
      isParentForOpenedDrilldown,
      connectedWidget,
      filtersForChildren,
      drilldownFilterField,
      widgetDataObject,
      widgetErrorText,
      permission,
      getFilters,
      isActiveDrilldownFilter,
      isMasterWidget,
      handleClickBackDD,
      filtersForPanel,
      enhancedDashboardParams,
    } = useWidget({
      widgetId,
      isDrillDown,
      parentFilters,
      increaseDrilldownDepth,
      decreaseDrilldownDepth,
      drillDownDepth,
      parentId,
      overriddenPropertiesInherited,
    });

    return (
      <div
        ref={containerRef}
        className="dashboard-grid-item"
        data-widget-id={widgetId}
      >
        <DrilldownDialog
          onClose={closeDrillDown}
          isParentForOpenedDrilldown={isParentForOpenedDrilldown}
          connectedWidget={connectedWidget}
          filtersForChildren={filtersForChildren}
          handleDeleteWidget={handleDeleteWidget}
          increaseDrilldownDepth={increaseDrilldownDepth}
          decreaseDrilldownDepth={decreaseDrilldownDepth}
          drillDownDepth={drillDownDepth}
          parentId={widgetId}
          overriddenPropertiesProp={overriddenProperties}
        />

        <WidgetCard
          widgetId={widgetId}
          isStatic={isStatic}
          isDrillDown={isDrillDown}
          drilldownFilterField={drilldownFilterField}
          widgetDataObject={widgetDataObject}
          widgetErrorText={widgetErrorText}
          drillDownDepth={drillDownDepth}
          permission={permission}
          getFilters={getFilters}
          isActiveDrilldownFilter={isActiveDrilldownFilter}
          closeDrillDown={closeDrillDown}
          containerRef={containerRef}
          overriddenProperties={overriddenProperties}
          setOverriddenProperties={setOverriddenProperties}
          enhancedParams={enhancedDashboardParams}
          overriddenPropertiesInherited={overriddenPropertiesInherited}
          isMasterWidget={isMasterWidget}
          handleClickBackDD={handleClickBackDD}
          filtersForPanel={filtersForPanel}
        />

        {!isStatic && handleDeleteWidget &&  (
          <DeleteButton onClick={() => handleDeleteWidget(widgetId)} />
        )}
      </div>
    );
  },
);

DashboardWidget.displayName = 'DashboardWidget';

export default DashboardWidget;
