import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';


export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) =>
  isDragging
    ? {
      border: '1px solid var(--dark-cyan)',
      borderRadius: 'calc(4px * var(--scale-coefficient))',
      ...draggableStyle,
    }
    : { ...draggableStyle };
