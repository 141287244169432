import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { useNestedHeaders } from '../../libs/hooks/useNestedHeaders';
import { Header } from '../../libs/hooks/useTableData';
import { HeadItem } from './head-item';
import { styles } from './styles';
import { CustomTabs } from '../../../../../../uikit/Tabs';
import { CustomButton } from '../../../../../../uikit/Button';
import { ConfirmDialog } from '../../../../../../uikit/ConfirmDialog';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles(styles);

export const HeadersConstructor = () => {
  const [openedConfirmDialog, setIsOpenedConfirmDialog] = useState(false);

  const toggleConfirmDialog = () => {
    setIsOpenedConfirmDialog((prev) => !prev);
  };

  const confirmDialog = () => {
    resetNestedHeaders();
    setTableNestedLevelsCount(tableNestedLevelsCount === 1 ? '2' : '1');
    toggleConfirmDialog();
  };

  const {
    addHeader,
    getTopLevelColumns,
    sortHeaders,
    setTableNestedLevelsCount,
    tableNestedLevelsCount,
    resetNestedHeaders,
    addNestedHeader,
  } = useNestedHeaders();

  const topLevelColumns = getTopLevelColumns();

  const classes = useStyles();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleSortEnd = (event: any) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      sortHeaders({
        targetId: active.id,
        destinationId: over.id,
      });
    }
  };

  const handleChangeNestedLevels = (id: string) => {
    if (topLevelColumns.length) {
      setIsOpenedConfirmDialog(true);
    } else {
      resetNestedHeaders();
      setTableNestedLevelsCount(id);
    }
  };

  const handleAddHeader = () => {
    switch (tableNestedLevelsCount) {
      case 1: {
        addHeader();
        break;
      }
      case 2: {
        addNestedHeader();
        break;
      }
      default: {
        addHeader();
        break;
      }
    }
  };

  const tabItems = [
    { label: '1', id: '1' },
    { label: '2', id: '2' },
  ];
  const tabs = tabItems.map((item) => ({
    id: item.label,
    title: item.label,
    onClick: () => handleChangeNestedLevels(item.id),
  }));
  const selectedTabIndex = tabs.findIndex(
    (item) => item.id === tableNestedLevelsCount.toString(),
  );

  return (
    <div className={classes.container}>
      <Box width="100%" mb="20px">
        <CustomTabs buttons={tabs} selected={selectedTabIndex} />
      </Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleSortEnd}
      >
        <SortableContext items={topLevelColumns}>
          <Box className={classes.headsContainer}>
            {topLevelColumns.map((head: Header) => {
              return (
                <Box flexGrow={1} key={head.id} mb="16px">
                  <HeadItem
                    data={head}
                    isChildren={false}
                    isLast={topLevelColumns.length === 1}
                  />
                </Box>
              );
            })}
          </Box>
          <CustomButton
            startIcon={
              <IconSvg svg={IconDictionary.PlusRounded} fill="var(--primary-color)" />
            }
            onClick={handleAddHeader}
            variant="text"
          >
            Добавить уровень
          </CustomButton>
        </SortableContext>
      </DndContext>

      <ConfirmDialog
        isOpen={openedConfirmDialog}
        onClose={toggleConfirmDialog}
        onConfirm={confirmDialog}
        title="Изменение уровня"
        message="Это приведет к удалению уже созданных столбцов"
        maxWidth="xs"
      />
    </div>
  );
};
