
export enum settingTabs {
  manual = 'manual',
  auto = 'auto',
}
export enum BoundType {
  autoBoundGroups = 'autoBoundGroups',
  manualBoundGroups = 'manualBoundGroups',
  autoDrillDownGroups = 'autoDrillDownGroups',
  manualDrillDownGroups = 'manualDrillDownGroups',
}