import { RadioGroup, Snackbar } from '@material-ui/core';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import WidgetsTypes from '../../../types/widgets';
import { setWidgetPropAction } from '../../../slices/widget/widget';
import { State } from '../../../slices/types';
import {
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  getSelectPropertyValue,
} from '../charts/helpers';
import { ColorPalette } from './setting-types';
import { HeadersConstructor } from '../charts/complex-table/ui/headers-constructor';
import SettingsColorPaletteModal from './settings-color-palette-modal';
import { ColorPicker } from './color-picker';
import {
  TextInput,
  TextInputProps,
} from '../../common/special-inputs/inputs/text-input';
import { useDebouncedInput } from '../../../hooks/useDebouncedInput';
import { CustomButton } from '../../../uikit/Button';
import { CustomCheckbox } from '../../../uikit/Checkbox';
import { CustomFormControl } from '../../../uikit/FormControl';
import { CustomRadio } from '../../../uikit/Radio/Radio';
import { isActiveParentSetting } from './helpers';
import { WidgetPropertyType } from '../../../enums/widget-type';
import { FormulasInputContainer } from '../../common/special-inputs/formulas-input-container/formulas-input-container';
import IconSvg from '../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const TextInputDebounce: React.FC<TextInputProps> = ({
  value: debouncedValue,
  handleChange: debounceHandleChange,
  ...otherProps
}) => {
  const [value, handleChange] = useDebouncedInput(
    debouncedValue,
    debounceHandleChange as (event: any) => void,
  );

  return (
    <TextInput
      value={value}
      handleChange={(e) => {
        handleChange(e.target?.value);
      }}
      {...otherProps}
    />
  );
};

interface SettingsModalProps {
  isOpen: boolean;
  onCloseButtonHandle: () => void;
  propertiesGroup: WidgetsTypes.WidgetPropertiesGroup | undefined;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  isOpen,
  onCloseButtonHandle,
  propertiesGroup,
}) => {
  const dispatch = useDispatch();
  const [isValidationError, setValidationError] = useState<boolean>(false);

  const widgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const handleCheckBoxTypeChange = (
    e: any,
    property: WidgetsTypes.WidgetsMetaProperties,
  ) => {
    dispatch(
      setWidgetPropAction({
        name: property.name,
        value: JSON.stringify(e.target.checked),
      }),
    );
  };

  const handleSelectTypeChange = (
    e: any,
    property: WidgetsTypes.WidgetsMetaProperties,
  ) => {
    dispatch(
      setWidgetPropAction({
        name: property.name,
        value: e.target.value,
      }),
    );
  };

  const handleInputTypeChange = (
    value: string,
    property: WidgetsTypes.WidgetsMetaProperties,
  ) => {
    let isValid = true;

    if (property.validationValue && value) {
      isValid = !!value.match(property.validationValue);
    }

    const isRequiredField = property.defaultValue.length > 0;
    if (!isValid || (!value && isRequiredField)) {
      setValidationError(true);
      return false;
    }

    setValidationError(false);

    dispatch(
      setWidgetPropAction({
        name: property.name,
        value,
      }),
    );
  };

  const handleColorInputTypeChange = useCallback(
    (e: string, property: WidgetsTypes.WidgetsMetaProperties) => {
      dispatch(
        setWidgetPropAction({
          name: property.name,
          value: e,
        }),
      );
    },
    [dispatch],
  );

  const handleCustomTypeChange = (
    value: any,
    property: WidgetsTypes.WidgetsMetaProperties,
  ) => {
    dispatch(
      setWidgetPropAction({
        name: property.name,
        value,
      }),
    );
  };

  const addDescriptionState = getInputPropertyValue(
    'addDescription',
    widgetProperties,
  );
  const [addDescription, handleAddDescriptionChange] = useDebouncedInput(
    addDescriptionState,
    (value) => {
      dispatch(setWidgetPropAction({ name: 'addDescription', value }));
    },
  );

  const getVisualTypeComponent = (
    property: WidgetsTypes.WidgetsMetaProperties,
  ) => {
    switch (property.visualType) {
      case 'CHECKBOX': {
        const isVisibleProperty =
          !property.parentName ||
          isActiveParentSetting(property.parentName, widgetProperties);

        return (
          isVisibleProperty && (
            <CustomFormControl
              control={
                <CustomCheckbox
                  onChange={(e) => {
                    handleCheckBoxTypeChange(e, property);
                  }}
                  checked={getBooleanPropertyValue(
                    property.name,
                    widgetProperties,
                  )}
                  name={property.name}
                />
              }
              label={property.description}
            />
          )
        );
      }
      case 'INPUT': {
        const isVisibleProperty =
          !property.parentName ||
          isActiveParentSetting(property.parentName, widgetProperties);

        return (
          isVisibleProperty && (
            <>
              <TextInputDebounce
                key={property.name}
                label={property.description}
                value={getInputPropertyValue(property.name, widgetProperties)}
                helperText={property.validationDescription}
                handleChange={(value: string) => {
                  handleInputTypeChange(value, property);
                }}
                fullWidth
              />
            </>
          )
        );
      }
      case 'TEXT': {
        const isVisibleProperty =
          !property.parentName ||
          isActiveParentSetting(property.parentName, widgetProperties);

        return (
          isVisibleProperty && (
            <>
              <TextInput
                value={addDescription}
                label={property.description}
                handleChange={(event) =>
                  handleAddDescriptionChange(event.target.value)
                }
                rows={4}
                maxRows={14}
                multiline
                fullWidth
              />
            </>
          )
        );
      }
      case 'DYNAMIC_TEXT': {
        const isVisibleProperty =
          !property.parentName ||
          isActiveParentSetting(property.parentName, widgetProperties);

        return (
          isVisibleProperty && (
            <FormulasInputContainer
              textInputProps={{
                label: property.description,
                fullWidth: true,
                rows: 6,
                maxRows: 6,
              }}
              propertyName={property.name as WidgetPropertyType}
              onlyComplex={property.name !== WidgetPropertyType.referenceLine1Value}
            />
          )
        );
      }
      case 'COLOR_INPUT': {
        const isVisibleProperty =
          !property.parentName ||
          isActiveParentSetting(property.parentName, widgetProperties);

        return (
          isVisibleProperty && (
            <ColorPicker
              name={property.description}
              value={getInputPropertyValue(property.name, widgetProperties)}
              onChangeColor={(e) => {
                handleColorInputTypeChange(e, property);
              }}
            />
          )
        );
      }
      case 'COLOR_PALETTE':
        return (
          <ColorPalette
            handleChange={(value) => {
              handleCustomTypeChange(JSON.stringify(value), property);
            }}
            value={
              getJSONPropertyValue(property.name, widgetProperties) as object
            }
          />
        );

      case 'TABLE_HEADER_BUILDER': {
        const isVisibleProperty =
          !property.parentName ||
          isActiveParentSetting(property.parentName, widgetProperties);

        return isVisibleProperty && <HeadersConstructor />;
      }

      case 'SELECT': {
        const isVisibleProperty =
          !property.parentName ||
          isActiveParentSetting(property.parentName, widgetProperties);

        return (
          isVisibleProperty && (
            <>
              <div className="settings-modal__item">
                <div className="settings-modal__item-title">
                  {property?.description}
                </div>
              </div>
              <RadioGroup
                onChange={(e) => {
                  handleSelectTypeChange(e, property);
                }}
              >
                {Object.values(property?.options).map((option: any, i) => (
                  <CustomFormControl
                    key={i}
                    value={option}
                    control={
                      <CustomRadio
                        checked={
                          getSelectPropertyValue(
                            property.name,
                            widgetProperties,
                          ) === option
                        }
                      />
                    }
                    label={option}
                  />
                ))}
              </RadioGroup>
            </>
          )
        );
      }

      case 'MAP_ICON':
        return (
          <>
            <div className="settings-modal__item">
              <div className="settings-modal__item-title">
                {property?.description}
              </div>
            </div>
            <div className="settings-modal__icons-container">
              {property?.options?.map((icon: string, index: number) => {
                return (
                  <div
                    key={`settings-modal-icon-${index}`}
                    className="settings-modal__icon-container"
                  >
                    <div
                      className="settings-modal__icon"
                      style={{
                        backgroundImage: `url('${icon}')`,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </>
        );
    }
  };

  return (
    <div className={`settings-modal ${isOpen ? 'settings-modal--opened' : ''}`}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isValidationError}
        message="Проверьте правильность введенного значения"
        key="error"
        autoHideDuration={2000}
        className="clear-cache-button__snackbar"
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          className="clear-cache-button__alert"
        >
          Проверьте правильность введенного значения
        </MuiAlert>
      </Snackbar>

      <div className="settings-modal__header">
        <h3 className="settings-modal__title">
          {propertiesGroup?.description}
        </h3>

        <CustomButton
          size="medium"
          variant="outlined"
          icon={<IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />}
          onClick={onCloseButtonHandle}
        />
      </div>

      {isOpen && (
        <div className="settings-modal_content">
          {propertiesGroup?.propertiesMetaBlocks.map((block) => (
            <div key={block.id}>
              {block.title && (
                <div className="settings-modal__item">
                  <div className="settings-modal__item-title">
                    {block.title}
                  </div>
                </div>
              )}
              {block?.metaProperties.map((property) => (
                <div className="settings-modal__item" key={property.id}>
                  {getVisualTypeComponent(property)}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      <SettingsColorPaletteModal />
    </div>
  );
};

export default memo(SettingsModal);
