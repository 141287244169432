import React, { CSSProperties, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Group } from '@visx/group';
import { BarStack } from '@visx/shape/lib/types';
import { getFirstActivePanelItemIndex } from '../../../../../helpers/common-helpers';
import { Bar } from '../../bar-group/hooks/useBarDimensions';
import { PanelType } from '../../../../../enums/widget-type';
import { textDirections } from '../../../../common/scalable-svg-text';
import { getCorrectWidgetData, getHistogramData, getHistogramDataIndexes, getStackedHistogramPercentData } from '../../helpers';
import { getTooltipHandler } from '../../../../common/widget-tooltip/widget-tooltip';
import { FilterField } from '../../../../dashboard-page/hooks';
import { WidgetProperties } from '../../../../../slices/types';
import { useStackBarProps } from '../../stacked-histogram/hooks/useStackBarProps';
import { barValueStyles, bottomAxisSettings, gridLinesYOffset, leftAxisSettings, minValueWidth } from '../settings';

interface Props {
  convertedBarGroup: any;
  barGroups: BarStack<any, string>[];
  style: CSSProperties;
  index: number;
  isHasNegative: boolean;
  barHeight: number;
  leftOffset: number;
  xScale: any;
  currentColors: string[];
  valueFormat: any;
  setPropForNumberValue: (widgetProperties?: WidgetProperties[]) => WidgetProperties[];
  widgetProperties: WidgetProperties[];
  isActiveFilter: boolean;
  filterField: FilterField;
  data: any;
  enableEvents: boolean;
  viewportRef: React.MutableRefObject<HTMLDivElement>;
  axisYLabelWidth: number;
  widgetData: any;
  selectBar: (value: string) => void;
  keys: string[];
  barTotalValues: number[];
  totalValueOffset: number;
  getTotalValue: (index: number) => any;
  overlapTotalPositiveOffset: number;
}

export const CustomBarGroup = ({
  convertedBarGroup,
  barGroups,
  style,
  index,
  isHasNegative,
  barHeight,
  leftOffset,
  xScale,
  currentColors,
  valueFormat,
  setPropForNumberValue,
  widgetProperties,
  isActiveFilter,
  filterField,
  data,
  enableEvents,
  viewportRef,
  axisYLabelWidth,
  widgetData,
  selectBar,
  keys,
  barTotalValues,
  totalValueOffset,
  getTotalValue,
  overlapTotalPositiveOffset
}: Props) => {
  const {
    isNormalized,
    isOverlap,
    textDirection,
    isNeedToDisplayBarValuesTotal,
    scaleByNumber,
    formatByNumber,
    isNeedToDisplayBarValues,
    isDisplayValueInPercent,
    isNeedToDisplayTooltip,
    legendsLabels,
    axisXValues,
    axisYValues,
    isNeedToDisplayInsideBarValues,
    isStacked,
    axisYTotalValueWidth,
    valueTextSize,
    valueColorTotal,
    valueColor
  } = useStackBarProps({
    widgetProperties,
  });

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(viewportRef);

  const originalData = useMemo(
    () => getHistogramData(getCorrectWidgetData(widgetData), isOverlap),
    [widgetData, isOverlap],
  );

  const dataWithPercents = useMemo(
    () =>
      isNormalized
        ? { ...data }
        : getStackedHistogramPercentData(widgetData, isOverlap),
    [isNormalized, data, widgetData, isOverlap],
  );

  const dataIndexes = useMemo(
    () => getHistogramDataIndexes(getCorrectWidgetData(widgetData), isOverlap),
    [widgetData, isOverlap],
  );

  const getBarValue = (bar: any) => {
    return bar.bar.data[bar.key];
  };

  const getBarValueFromPercent = (data: any, bar: any) => {
    return _.find(data, (row) => row.x === bar.bar.data.x)[bar.key];
  };

  const getInsideValue = (bar: any) => {
    const value = isNeedToDisplayBarValues ? valueFormat(
      getBarValueFromPercent(originalData, bar),
      0,
      setPropForNumberValue(widgetProperties),
      PanelType.axisY,
      false,
      false,
      scaleByNumber ? formatByNumber : null,
      true
    ) : '';

    const percentValue = isDisplayValueInPercent
      ? valueFormat(
        getBarValueFromPercent(dataWithPercents, bar),
        0,
        setPropForNumberValue(widgetProperties),
        PanelType.axisY,
        isDisplayValueInPercent,
      )
      : '';

    const percentString = value && percentValue.length ? ` (${percentValue})` : percentValue;

    return value + percentString;
  };

  const getBarXValue = (bar: any): any => {
    const keysSlice = keys.slice(0, keys.indexOf(bar.key));
    const returnVal = keysSlice
      .map((key) => bar.bar.data[key])
      .reduce((accumulator, currentValue) => {
        return isOverlap ? accumulator : getBarValue(bar) > 0
          ? currentValue < 0
            ? accumulator
            : accumulator + currentValue
          : currentValue >= 0
            ? accumulator
            : accumulator + currentValue;
      }, 0);

    return getBarValue(bar) > 0
      ? xScale(returnVal)
      : xScale(returnVal + getBarValue(bar));
  };

  const zeroPoint = xScale(0);

  const sortedBarGroup = isOverlap ?
    [...(convertedBarGroup || [])]?.sort(
      (barA: any, barB: any) => Math.abs(barB.width) - Math.abs(barA.width)
    ) : convertedBarGroup || [];

  const barWidthsList = sortedBarGroup.flatMap((bar: Bar) => bar.width);

  const barGroupStyle: CSSProperties = {
    width: isStacked
      ? 'fit-content'
      : '100%',
    height: barHeight,
  };

  const totalValue = barTotalValues[index];
  const maxWidth = totalValue >= 0 ?
    Math.max(...barWidthsList) :
    Math.min(...barWidthsList);

  const positiveTotalWidth = barWidthsList.reduce((accumulator: number, currentValue: number) => {
    return currentValue >= 0 ? accumulator + currentValue : accumulator;
  }, 0);
  const negativeTotalWidth = barWidthsList.reduce((accumulator: number, currentValue: number) => {
    return currentValue < 0 ? accumulator - currentValue : accumulator;
  }, 0);

  useEffect(() => {
    return () => {
      handleWidgetMouseLeave();
    };
  });

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  return (
    <Group
      className="stacked-horizontal-histogram__bar-group"
      key={`stacked-bar-group-${index}`}
      style={{
        ...style,
        ...barGroupStyle,
      }}
    >
      <div
        className="stacked-histogram-horizontal__axisY-value-container"
        style={{
          position: 'absolute',
          height: barHeight,
          width: axisYLabelWidth,
        }}
      >
        <div
          style={{
            display: 'flex',
            width:
              textDirection === textDirections.vertical
                ? barHeight
                : axisYLabelWidth,
            height: '100%',
            transform:
              textDirection === textDirections.diagonal
                ? 'rotate(-45deg) translate(0, 20px)'
                : textDirection === textDirections.vertical
                  ? 'rotate(-90deg)'
                  : 'none',
          }}
        >
          <span
            className={clsx('histogram-horizontal__axisY-value', {
              'histogram-horizontal__axisY-value_vertical':
                textDirection === textDirections.vertical,
              'histogram-horizontal__axisY-value_diagonal':
                textDirection === textDirections.diagonal,
            })}
            style={{
              height: bottomAxisSettings.lineHeight,
              alignSelf:
                textDirection === textDirections.diagonal
                  ? 'flex-start'
                  : 'center'
            }}
          >
            {valueFormat(
              data[index]?.x,
              axisXValueIndex,
              widgetProperties,
            )}
          </span>
        </div>
      </div>

      {sortedBarGroup.map((bar: any) => {
        const isNullData = isNaN(bar.bar.data[0]);
        if (isNullData) return;

        const barRectStyle: CSSProperties = {
          position: 'absolute',
          left: getBarXValue(bar) + leftOffset + (isHasNegative ? totalValueOffset : 0),
          width: Math.abs(bar.width),
          height: barHeight,
        };

        const barColor = dataIndexes
          ? currentColors[
            dataIndexes[bar.index][+bar.key]
          ]
          : bar.color;

        const totalValueStyle: CSSProperties = isOverlap ? {
          left: isHasNegative ?
            totalValue >= 0 ?
              zeroPoint + maxWidth + leftOffset + totalValueOffset + gridLinesYOffset :
              zeroPoint + maxWidth + leftOffset - gridLinesYOffset :
            axisYLabelWidth + leftAxisSettings.left + gridLinesYOffset + maxWidth + overlapTotalPositiveOffset,
        } : {
          left: isHasNegative ?
            totalValue >= 0 ?
              zeroPoint + positiveTotalWidth + leftOffset + totalValueOffset + gridLinesYOffset :
              zeroPoint - negativeTotalWidth + leftOffset - gridLinesYOffset :
            positiveTotalWidth + leftOffset + gridLinesYOffset,
        };

        let groupIndex = 0;
        const tooltipListY = barGroups
          .map((bGroup: any) => {
            groupIndex = bGroup.bars.findIndex(
              (b: Bar) => b.index === bar.index,
            );
            return bGroup.bars.find((b: Bar) => b.index === bar.index);
          })
          .map((b: Bar) => ({
            attrY: dataIndexes && b
              ? legendsLabels[dataIndexes[groupIndex][parseInt(b.key)]]
              : legendsLabels[b?.key || 0],
            valueY: getInsideValue(b),
            color:
            dataIndexes && b
              ? currentColors[
                dataIndexes[groupIndex][parseInt(b.key)]
              ]
              : b?.color ?? 'white',
          }));

        return (
          <>
            <div
              className={clsx('stacked-horizontal-histogram__rect', {
                'active-drilldown-element': isActiveFilter,
              })}
              style={{
                ...barRectStyle,
                backgroundColor: `${barColor}`,
                opacity:
                  filterField?.value === null ||
                  filterField?.value === undefined ||
                  filterField?.value?.includes(data[index]?.x)
                    ? 0.8
                    : 0.2,
              }}
              key={`bar-group-bar-${sortedBarGroup.index}-${bar.index}-${bar.bar.data.x}-${bar.key}`}
              onClick={(e) => {
                if (!enableEvents) return;
                e.stopPropagation();
                selectBar(bar.bar.data.x);
              }}
              onMouseLeave={
                isNeedToDisplayTooltip
                  ? handleWidgetMouseLeave
                  : undefined
              }
              onMouseMove={
                isNeedToDisplayTooltip
                  ? (event) =>
                    handleWidgetMouseMove(event, {
                      attrX: axisXValues[0],
                      valueX: valueFormat(
                        bar.bar.data.x,
                        0,
                        widgetProperties,
                      ),
                      list: tooltipListY,
                    })
                  : undefined
              }
            >
              {isNeedToDisplayInsideBarValues && (
                <div
                  className={clsx('stacked-horizontal-histogram__inside-value', {
                    'stacked-horizontal-histogram__inside-value_hidden': Math.abs(barHeight) < barValueStyles.lineHeight || Math.abs(bar.width) < minValueWidth,
                  })}
                  style={{
                    fontSize: valueTextSize,
                    color: valueColor,
                    top: (barHeight / 2) - (barValueStyles.lineHeight / 2),
                    justifyContent: isOverlap ?
                      getBarValueFromPercent(originalData, bar) < 0 ?
                        'flex-start' :
                        'flex-end' :
                      'center',
                    paddingInline: gridLinesYOffset,
                  }}
                >
                  <span className="stacked-horizontal-histogram__inside-value-text">
                    {getInsideValue(bar)}
                  </span>
                </div>
              )}
            </div>

            {isNeedToDisplayBarValuesTotal &&
              barGroups[bar.key].index === axisYValues.length - 1 && (
                <div
                  className="stacked-horizontal-histogram__total-value"
                  style={{
                    fontSize: valueTextSize,
                    color: valueColorTotal || '#000',
                    ...totalValueStyle,
                    height: barHeight,
                  }}
                >
                  <span
                    className="stacked-horizontal-histogram__total-value-text"
                    style={{
                      top: (barHeight / 2) - (barValueStyles.lineHeight / 2),
                      height: barValueStyles.lineHeight,
                    }}
                  >
                    {getTotalValue(bar.index)}
                  </span>
                </div>
            )}
          </>
        );
      })}
    </Group>
  );
};
