import { flatten } from 'lodash';
import React, { useMemo } from 'react';
import { VariableSizeGrid } from 'react-window';
import { AutoSizer, Collection } from 'react-virtualized';
import { cellBorderWidth } from './settings';
import { PanelType } from '../../../../enums/widget-type';
import { PivotCell } from './components/body/pivot-cell';
import { HeadButtonCell } from './components/head/head-button-cell';
import { isTotal } from './helper';
import { usePivotTable } from './hooks/usePivotTable';
import { PivotTableProps } from './interfaces';

export const PivotTable = ({
  widgetProps,
  dashboardWidgetState,
  setDashboardWidgetState,
  isDrillDown,
  setFilterField,
  filterField,
  isActiveFilter,
  setMultipleFilterFields,
}: PivotTableProps) => {
  const {
    containerRef,
    staticHeaderGrid,
    columnsWidth,
    headerHeight,
    widgetProperties,
    axisYValues,
    dynamicHeaderCollection,
    headerCellsCount,
    isNeedToDisplayTotal,
    headerCellRenderer,
    cellSizeAndPositionGetter,
    scrollLeft,
    staticBodyGrid,
    onScrollStatic,
    widgetData,
    changeDisplayNestedRow,
    closedRows,
    dynamicBodyGrid,
    onScrollDynamic,
    mainColumnsCount,
    columnsIds,
    isOpenRow,
    cellWithContentHeight,
    view,
    handleClickCell,
  } = usePivotTable({
    widgetProps,
    dashboardWidgetState,
    setDashboardWidgetState,
    isDrillDown,
    setFilterField,
    filterField,
    isActiveFilter,
    setMultipleFilterFields,
  });

  return (
    /* @ts-ignore */
    <AutoSizer>
      {({ height, width }) => (
        <div
          style={{ height: `${height}px`, width: `${width}px` }}
          ref={containerRef}
        >
          <div className="pivot__grid-container">
            <VariableSizeGrid
              ref={staticHeaderGrid}
              className="GridStatic"
              columnCount={1}
              columnWidth={() => columnsWidth.title}
              height={headerHeight}
              rowCount={1}
              rowHeight={() => headerHeight}
              width={columnsWidth.title}
              style={{
                overflow: 'hidden',
              }}
            >
              {({ style }) => (
                <HeadButtonCell
                  widgetProperties={widgetProperties}
                  widgetId={widgetProps.id}
                  panelType={PanelType.axisX}
                  setDashboardWidgetState={setDashboardWidgetState}
                  style={style}
                  isDrillDown={isDrillDown}
                />
              )}
            </VariableSizeGrid>
            {/* @ts-ignore */}
            <Collection
              ref={dynamicHeaderCollection}
              cellCount={headerCellsCount + (isNeedToDisplayTotal ? 0 : 1)}
              cellRenderer={headerCellRenderer}
              cellSizeAndPositionGetter={cellSizeAndPositionGetter}
              height={headerHeight}
              width={width - columnsWidth.title}
              scrollLeft={scrollLeft}
              className="Collection"
            />
          </div>

          <div className="pivot__grid-container">
            <VariableSizeGrid
              ref={staticBodyGrid}
              onScroll={onScrollStatic}
              style={{ overflowY: 'hidden' }}
              className="GridStatic"
              columnCount={1}
              columnWidth={() => columnsWidth.title}
              height={height - headerHeight}
              rowCount={widgetData.rows.length - (isNeedToDisplayTotal ? 0 : 1)}
              rowHeight={(index) =>
                isOpenRow(widgetData.rows[index].parentId)
                  ? cellWithContentHeight
                  : 0
              }
              width={columnsWidth.title}
            >
              {({ rowIndex, style }) => {
                const isActiveCurrentRowFilter = filterField?.value?.[0] === widgetData.rows[rowIndex]?.title?.value;

                if (!isOpenRow(widgetData.rows[rowIndex].parentId)) {
                  return <></>;
                }

                return (
                  <PivotCell
                    key={`pivot-cell-title-${rowIndex}`}
                    widgetProperties={widgetProperties}
                    cell={widgetData.rows[rowIndex].title}
                    depth={widgetData.rows[rowIndex].depth}
                    style={style}
                    cellHeight={cellWithContentHeight}
                    columnId="title"
                    isCellWithRowsButton={widgetData.rows[rowIndex].nestedRows}
                    changeDisplayNestedRow={changeDisplayNestedRow}
                    rowId={widgetData.rows[rowIndex].id}
                    isOpenRow={
                      !closedRows.includes(widgetData.rows[rowIndex].id)
                    }
                    view={view}
                    handleClickCell={handleClickCell('title', widgetData.rows[rowIndex])}
                    isActiveFilter={isActiveFilter}
                    isActiveCurrentRowFilter={isActiveCurrentRowFilter}
                  />
                );
              }}
            </VariableSizeGrid>

            <VariableSizeGrid
              ref={dynamicBodyGrid}
              onScroll={onScrollDynamic}
              className="Grid screenshot-overflow"
              columnCount={mainColumnsCount}
              columnWidth={(index) =>
                columnsIds[index + 1] && isTotal(columnsIds[index + 1])
                  ? columnsWidth.total
                  : columnsWidth.nestedColumn
              }
              height={height - headerHeight}
              rowCount={widgetData.rows.length - (isNeedToDisplayTotal ? 0 : 1)}
              rowHeight={(index) =>
                isOpenRow(widgetData.rows[index].parentId)
                  ? cellWithContentHeight
                  : 0
              }
              width={width - columnsWidth.title - cellBorderWidth}
            >
              {({ columnIndex, rowIndex, style }) => {
                if (!isOpenRow(widgetData.rows[rowIndex].parentId)) {
                  return <></>;
                }
                const columnId = columnsIds[columnIndex + 1];
                const cell = widgetData.rows[rowIndex][columnId];
                const isActiveCurrentRowFilter = filterField?.value?.[0] === widgetData.rows[rowIndex]?.title?.value;

                const etlFieldId = flatten(widgetData.header).find(
                  (headerCell) => headerCell.id === columnId,
                )?.etlFieldId;

                const axisYIndex = axisYValues.findIndex(
                  (axisY) => axisY.etlFieldId === etlFieldId,
                );
                const storage = JSON.parse(axisYValues[axisYIndex]?.storage ?? '{}');

                const viewSettings = {
                  displayValueText: storage?.displayValueText,
                  cellJustifyContent: storage?.cellJustifyContent,
                };

                return (
                  <PivotCell
                    key={`pivot-cell-${rowIndex}-${columnIndex + 1}`}
                    widgetProperties={widgetProperties}
                    cell={cell}
                    depth={0}
                    style={style}
                    columnId={columnId}
                    cellHeight={cellWithContentHeight}
                    isCellWithRowsButton={false}
                    changeDisplayNestedRow={changeDisplayNestedRow}
                    rowId={widgetData.rows[rowIndex].id}
                    isOpenRow={
                      !closedRows.includes(widgetData.rows[rowIndex].id)
                    }
                    axisYIndex={axisYIndex === -1 ? 0 : axisYIndex}
                    view={view}
                    handleClickCell={handleClickCell(columnId, widgetData.rows[rowIndex])}
                    isActiveFilter={isActiveFilter}
                    isActiveCurrentRowFilter={isActiveCurrentRowFilter}
                    viewSettings={viewSettings}
                  />
                );
              }}
            </VariableSizeGrid>
          </div>
        </div>
      )}
    </AutoSizer>
  );
};
