export enum ParameterType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
}

export enum LoadingStatus {
  pending = 'pending',
  loading = 'loading',
  loaded = 'loaded',
}
