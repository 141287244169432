import React, { useMemo } from 'react';
import { Box, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { SortingVariants } from '../../settings';
import { ColumnSortingProps } from '../../interfaces';
import { CustomTabs } from '../../../../../../uikit/Tabs';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

const sortingList = [
  {
    title: 'По умолчанию',
    value: null,
    icon:  <IconSvg
      svg={IconDictionary.WidgetSort}
      fill='var(--grey)'
    />
  },
  {
    title: 'По возрастанию',
    value: SortingVariants.ASC,
    icon: <IconSvg
      svg={IconDictionary.WidgetSortDown}
      fill='var(--grey)'
    />
  },
  {
    title: 'По убыванию',
    value: SortingVariants.DESC,
    icon: <IconSvg
      svg={IconDictionary.WidgetSortUp}
      fill='var(--grey)'
    />
  }
];

const useStyles = makeStyles({
  icon: {
    fill: 'var(--grey)',
    minWidth: 'calc(30px * var(--scale-coefficient)) !important'
  },
  iconSelected: {
    fill: 'var(--primary-color)'
  },
  text: {
    fontWeight: 500,
    fontSize: 'calc(14px * var(--scale-coefficient))',
  },
  title: {
    paddingBottom: '3px',
    fontSize: 'calc(14px * var(--scale-coefficient))',
  },
});

export const ColumnAndRowSorting = ({
  axisValue,
  onChangeSorting,
}: ColumnSortingProps) => {
  const classes = useStyles();

  const getSorting = () => {
    return axisValue?.orderDirection;
  };

  const tabs = useMemo(
    () => [...sortingList.map(({ value, icon }) => {
      return {
        id: value as string,
        icon,
        onClick: () => onChangeSorting(axisValue?.etlFieldId!, value)
      };
    })],
    [sortingList, axisValue],
  );

  const selectedTabIndex = tabs.findIndex((tab) => tab.id === getSorting()) || 0;

  return axisValue ? (
    <>
      <Box>
        <Typography variant="h3" className={classes.title}>Сортировка</Typography>
      </Box>
      <List>
        <CustomTabs buttons={tabs} selected={selectedTabIndex} />
      </List>
    </>
  ) : (<></>);
};
