import React from 'react';
import { CssVariables } from '../../../../enums/css-variables';
import { varcss } from '../../../../helpers/ui-helper';
import { CustomButton } from '../../../../uikit/Button';
import IconSvg from '../../icon-svg/icon-svg';
import './index.css';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface DeleteButtonProps {
  onClick: () => void;
}

export const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <div className="button-delete-container">
      <CustomButton
        onClick={onClick}
        icon={
          <IconSvg
            svg={IconDictionary.BasketDelete}
            fill="var(--red-normal)"
          />
        }
        size="small"
        shape="circle"
        backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
      />
    </div>
  );
};
