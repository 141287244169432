import block from 'bem-cn';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Resizable } from 're-resizable';
import { CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import IconSvg from '../../../common/icon-svg/icon-svg';
import { useClassButton } from '../../../../hooks/useClassButton';
import { State } from '../../../../slices/types';
import { setBottomTableContentAction } from '../../../../slices/map-view/map-view';
import './table-preview.css';
import { useRequestCanceller } from '../../../../hooks/useRequestCanceller';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  autoWidth: {
    width: 'auto',
  },
});

const b = block('table-preview');

const getEmptyTablePreviewData = () => ({ schema: [], sample: [] });

const TablePreview = () => {
  const { cancellableController } = useRequestCanceller();
  const dispatch = useDispatch();
  const classes = useStyles();
  const classButton = useClassButton({ width: 'calc(35px * var(--scale-coefficient))', height: 'calc(35px * var(--scale-coefficient))' });

  const closeBottomPreview = () => {
    cancellableController.cancelAll();
    dispatch(
      setBottomTableContentAction({
        label: '',
        data: getEmptyTablePreviewData(),
        isError: false,
      }),
    );
  };


  const { label, data: content, isError, errorMessage } = useSelector(
    (state: State) => state.mapView.bottomTableContent,
  );

  const columnsAmount = content.schema.length;
  const rowsAmount = content.sample.length;
  return (
    <Resizable
      className="resize-panel"
      defaultSize={{
        width: 'auto',
        height: '35vh',
      }}
      minHeight="100"
      enable={{
        top: true,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      <div className="main-container">
        <div className={b()}>
          <div className={b('head-panel')}>
            <h3 className={b('title')}>{label}</h3>
            <div className={b('info')}>
              <div className={b('column-icon')}>
                <IconSvg svg={IconDictionary.Column} width={24} height={24} fill="var(--dark-grey)" />
              </div>
              <div>столбцы: {columnsAmount ? String(columnsAmount) : '-'}</div>
              <div>
                <IconSvg svg={IconDictionary.Row} width={24} height={24} fill="var(--dark-grey)" />
              </div>
              <div>строки: {rowsAmount ? String(rowsAmount) : '-'}</div>
              <IconButton
                onClick={closeBottomPreview}
                className={classButton.square}
              >
                <IconSvg
                  classSVG={b('ommited-icon').toString()}
                  svg={IconDictionary.Close}
                  width={20}
                  height={20}
                  fill="var(--dark-grey)"
                />
              </IconButton>
            </div>
          </div>

          {content.schema.length && !isError ? (
            <TableContainer component={Paper} className={`${b('container')}`}>
              <Table
                stickyHeader
                aria-label="simple table"
                classes={{ root: `${classes.autoWidth}` }}
              >
                <TableHead className={`${b('header')}`}>
                  <TableRow>
                    {content.schema.map((i: any) => (
                      <TableCell
                        className={`${b('cell')} ${b('header-cell')}`}
                        key={i.name}
                      >
                        {i.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={`${b('body')}`}>
                  {content.sample.map((row: any, index: any) => (
                    <TableRow key={index}>
                      {row.map((cell: any, i: any) => (
                        <TableCell key={i} className={`${b('cell')}`}>
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            isError ?
              <div className={`${b('error-container')}`}>
                <IconSvg svg={IconDictionary.NoData} width={48} height={44} fill="var(--dark-grey)" />
                <p className={`${b('error-title')}`}>{errorMessage || 'Ошибка при получении данных'}</p>
              </div> :
              <div className={`${b('placeholder-progress')}`}>
                <CustomProgress type="circular" />
              </div>
          )}
        </div>
      </div>
    </Resizable>
  );
};

export default TablePreview;
