import React from 'react';
import { CustomErrorAlert } from '../../../../../uikit/ErrorAlert';

interface ValidationAlertProps {
  errorText: any;
}

const ValidationAlert: React.FC<ValidationAlertProps> = ({ errorText }) => {
  return (
    errorText && (
      <CustomErrorAlert title="Валидация не пройдена">
        {errorText}
      </CustomErrorAlert>
    )
  );
};

export default ValidationAlert;
