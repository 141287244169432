export const verticalMargin = 80;

export const stageSettings = {
  top: 20,
  bottom: 10,
  left: 40,
  right: 40,
  minWidth: 200,
  minWidthStep: 90,
  minHeightStep: 50,
};

export const leftAxisSettings = {
  color: 'var(--grey)',
  left: 50,
  top: 30,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 16,
  textAlign: 'end',
};

export const bottomAxisSettings = {
  color: 'var(--grey)',
  left: stageSettings.left,
  top: -52,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 16,
  textAlign: 'center',
};

export const barSettings = {
  innerTextTop: 20,
  outerTextLeftOffset: 6,
  outerTextRightOffset: 20,
  outerTextTopOffset: 5,
  outerTextBottomOffset: 20,
};

export const valueTextSettings = {
  fontSize: 18,
  fontWeight: 700,
  lineHeight: 26,
  fontFamily: 'Roboto',
};

export const lineHeight = 1.2;
export const valueOffsetOfRect = 4;

export const gridLinesColor = '#D8D8D8';
export const gridLinesXOffset = 10;
export const gridLinesYOffset = 4;
export const axisBottomOffsetForScaling = 2;

export const xScalePadding = 0.2;
export const valueScalePadding = 0.2;

export const axisBottomOffset = 100;
export const minContainerHeightForDisplayAxisBottom = 150 + axisBottomOffset;
export const barsOffsetForDiagonalText = 20;
export const offsetForHorizontalText = 30;
export const defaultMinSpaceBetweenBarGroups = 10;
export const defaultMinSpaceBetweenBars = 6;
export const axisLeftOffset = 15;
export const minBarComputedSize = 60;
export const axisDiagonalTextOffset = 70;
export const barGroupOffset = 20;
export const chartOffset = 15;
export const defaultBottomAxisHeight = 60;
export const defaultBottomAxisValueWidth = 60;
export const containerOffsetForAxis = 300;
export const bottomAxisTicksCount = 7;
export const tickLineWidth = 1;
export const defaultDiffCoeff = 0.1;
export const defaultOffsetCoeff = 0.8;
export const roundingPixel = 1;
export const overflowOffset = 35;
export const overlapTotalPositiveOffset = 5;
export const referenceTitleTextYoffset = 5;
export const referenceTitleTextXoffset = 5;
export const referenceVerticalYTextOffset = 10;
