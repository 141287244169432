import React from 'react';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { WidgetsContent } from './widgets-content';
import { DashboardsContent } from './dashboards-content';
import WidgetsTypes from '../../../../types/widgets';
import { CustomButton } from '../../../../uikit/Button';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface ContentProps {
  openProjectSettings: () => void;
  changeModal: (
    type: string | undefined,
    actionType: string,
    name: string,
    id: number | undefined,
  ) => void;
  carouselItemMaxWidth: number;
  classes: any;
}

export const Content = ({
  openProjectSettings,
  changeModal,
  carouselItemMaxWidth,
  classes,
}: ContentProps) => {
  return (
    <div className="widget-carousel__content">
      <WidgetsContent
        changeModal={changeModal}
        carouselItemMaxWidth={carouselItemMaxWidth}
        classes={classes}
      />
      <DashboardsContent
        changeModal={changeModal}
        carouselItemMaxWidth={carouselItemMaxWidth}
        classes={classes}
      />
      <CustomButton
        variant="outlined"
        icon={<IconSvg svg={IconDictionary.EditFile} fill='var(--primary-color)' />}
        onClick={openProjectSettings}
      />
    </div>
  );
};
