import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import block from 'bem-cn';

import './operation-type-item.css';
import Dictionaries from '../../../../types/dictionaries';
import IconSvg from '../../icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  listHover: {
    '&:hover': {
      color: 'var(--primary-color)',
    },
  },
  listIcon: {
    minWidth: 40,
    marginRight: '-30px',
    justifyContent: 'right',

  },
});

interface OperationTypeMenuProps {
  formulasType: Dictionaries.GroupFormula;
  chooseTransformationOperation: (operation: Dictionaries.Formula) => () => void;
  onMouseEnterCallback: (index: number) => void;
  currentFormulaIndex: number | null;
}

const OperationTypeMenu = ({
  formulasType,
  chooseTransformationOperation,
  onMouseEnterCallback,
  currentFormulaIndex
}: OperationTypeMenuProps) => {
  const classes = useStyles();
  const [name, formulas, index] = formulasType;

  const isBottomBlock = index > 2;

  const b = block('operation-type-item');
  return (
    <div className={`${currentFormulaIndex === index ? 'active': ''} ${b()}`} onMouseEnter={() => {onMouseEnterCallback(index);}}>
      <ListItem
        button
        className={`hover ${classes.listHover} ${b('outer-item')}`}
      >
        <ListItemText>{name}</ListItemText>
        <ListItemIcon className={classes.listIcon}>
          <IconSvg svg={IconDictionary.CompactArrowToRight} fill="var(--primary-color)" />
        </ListItemIcon>
      </ListItem>
      <div className={isBottomBlock ? b('list list-bottom') : b('list')}>
        <List classes={{ root: b('inner-menu') }}>
          {formulas.map((formula, i) => (
            <ListItem
              key={i}
              onClick={chooseTransformationOperation(formula)}
              classes={{ root: b('inner-item') }}
            >
              {formula.name}
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default OperationTypeMenu;
