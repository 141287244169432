import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

export interface DndBackdropProps {
  isTarget: boolean;
  isDestination: boolean;
}

const useStyles = makeStyles((theme) => (
  {
    '@keyframes dash': {
      to: {
        backgroundPosition: '100% 0%, 0% 100%, 0% 0%, 100% 100%'
      }
    },
    container: ({ background }: { background: string }) => ({
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: background,
    }),
    border: {
      background: `linear-gradient(90deg, var(--primary-color) 50%, transparent 50%), 
                   linear-gradient(90deg, var(--primary-color) 50%, transparent 50%), 
                   linear-gradient(0deg, var(--primary-color) 50%, transparent 50%), 
                   linear-gradient(0deg, var(--primary-color) 50%, transparent 50%)`,
      backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
      backgroundSize: '8px 2px, 8px 2px, 2px 8px, 2px 8px',
      backgroundPosition: '0% 0%, 100% 100%, 0% 100%, 100% 0px',
      animationName: '$dash',
      animationDuration: '15s',
      animationTimingFunction: 'linear',
      animationIterationCount:'infinite',
    },
  }));

export const DndBackdrop: FC<DndBackdropProps> = ({ isTarget, isDestination }) => {
  let background = 'transparent';
  
  if (isTarget) {
    background = '#3b82e436';
  }

  if (isDestination) {
    background = 'rgb(255 255 255 / 60%)';
  }

  const classes = useStyles({ background });
    
  if (!(isTarget || isDestination))  return null;
  
  return (
    <div className={clsx(classes.container, {
      [classes.border]: isDestination,
    })}
    />
  );
};
