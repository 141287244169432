import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LocalStorage } from '../../../../enums/local-storage-type';
import { State, Widget } from '../../../../slices/types';
import { PageParams } from '../../../../types/meta';
import { setCurrentWidgetAction } from '../../../../slices/widget/widget';
import {
  setCurrentProjectWidgetDataAction,
  setCurrentProjectWidgetErrorAction,
} from '../../../../slices/main-page/main-page-slice';

export const useWidgetContent = () => {
  const dispatch = useDispatch();

  const widgetRef = useRef(document.createElement('div'));

  const params: PageParams = useParams();

  const projectId: number = useSelector(
    (state: State) => state.mainPage?.currentProject?.id ?? 0,
  );

  const widgetsList: Widget[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? null,
  );

  const [widgetAnchor, setWidgetAnchor] = React.useState<Element | null>(null);
  const [isWidgetPopperOpen, setWidgetPopperOpen] = useState<boolean>(false);

  const lastWidget = widgetsList?.find(
    (widget: any) =>
      String(widget.id) ===
      (localStorage.getItem(LocalStorage.CAROUSEL_LAST_WIDGET_ID) || ''),
  );

  const currentGroupWidgets: Widget[] | null = useSelector(
    (state: State) => state.mainPage?.currentGroupWidgets ?? null,
  );

  const currentWidget =
    widgetsList?.find((widget: any) => widget.id == params.widgetId) ??
    currentGroupWidgets?.find((widget: any) => widget.id == params.widgetId) ??
    lastWidget ??
    (widgetsList?.[0] || null);

  useEffect(() => {
    localStorage.setItem(
      LocalStorage.CAROUSEL_LAST_WIDGET_ID,
      String(currentWidget?.id) || '',
    );
  }, [currentWidget]);

  const toggleWidgetList = useCallback(() => {
    setWidgetPopperOpen(!isWidgetPopperOpen);
    setWidgetAnchor(widgetRef.current as any);
  }, [widgetRef, isWidgetPopperOpen]);

  const handleWidgetsMenuClose = () => setWidgetPopperOpen(false);

  const onNewWidgetAdd = useCallback(() => {
    dispatch(setCurrentWidgetAction(''));
    dispatch(setCurrentProjectWidgetDataAction(null));
    dispatch(setCurrentProjectWidgetErrorAction(''));
  }, [dispatch]);

  return {
    widgetRef,
    currentWidget,
    widgetsList,
    toggleWidgetList,
    isWidgetPopperOpen,
    params,
    projectId,
    onNewWidgetAdd,
    handleWidgetsMenuClose,
    widgetAnchor,
    currentGroupWidgets,
  };
};
