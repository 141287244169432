import React, { FC, useContext, useMemo } from 'react';
import { Placement } from '../../../../../../../enums/visual-types';
import { getSimplifiedType } from '../../../../../dropdown-layout/helpers/helpers';
import {
  DateFilter,
  DateFilterOptions,
} from '../../../../../filters/wrappers/date-filter/date-filter';
import { SelectFilter } from '../../../../../filters/wrappers/select-filter';
import { ValueFilter } from '../../../../../filters/wrappers/value-filter';
import { isDashboardPage } from '../../../../helpers';
import { BoundType } from '../../../../../../dashboard-page/settings/enums';
import { ComplexTableContext } from '../../../index';
import { useDashboardFilters } from '../../../../../../dashboard-page/hooks/useDashboardFilters';
import { useDashboardBoundFilters } from '../../../../../../dashboard-page/hooks/useDashboardBoundFilters';
import { useDashboardManualFilters } from '../../../../../../dashboard-page/hooks/useDashboardManualFilters';
import { getAllFieldFilters } from '../../../../../../../helpers/dashboard-page';
import { SelectDateTypes } from '../../../../../../../enums/dashboard-properties';
import { DateFunctions } from '../../../../../../../enums/date';

export interface HeaderActionFiltersProps {
  currentFilter: any;
  setFilter: (filter: any) => void;
  getAllFiltersFromAxis: () => any;
  closeFilterPanel?: () => void;
}

export const HeaderActionFilters: FC<HeaderActionFiltersProps> = ({
  currentFilter,
  setFilter,
  getAllFiltersFromAxis,
  closeFilterPanel
}) => {
  const { widgetState } = useContext(ComplexTableContext);
  const widgetId = widgetState.id;

  const isDashboard = isDashboardPage();
  const {
    values: dashboardFiltersGroups,
    getGroupForFilter,
  } = useDashboardFilters();

  const widgetFiltersIds: string = getAllFiltersFromAxis().map((value: any) => {
    return value.id;
  });

  const currentFilterId = currentFilter.id || currentFilter.widgetFieldId;
  const widgetFilters = getAllFiltersFromAxis();
  const filterGroupId = getGroupForFilter(currentFilterId)?.id || 0;

  const boundFilers = useDashboardBoundFilters();
  const drilldownValues = useDashboardManualFilters(BoundType.autoDrillDownGroups)?.drilldownValues;
  drilldownValues.concat(useDashboardManualFilters(BoundType.manualDrillDownGroups)?.drilldownValues);


  const allFieldFilters = isDashboard
    ? getAllFieldFilters(
      dashboardFiltersGroups,
      filterGroupId,
      true,
      widgetFilters,
      widgetFiltersIds,
      boundFilers,
      widgetId,
      drilldownValues,
    )
    : getAllFieldFilters(
      widgetFilters,
      currentFilterId,
      false,
      widgetFilters,
      widgetFiltersIds,
    );

  const onChangeFilter = (filter: any) => {
    setFilter(filter);
  };

  const renderer = useMemo(() => {
    const remapedType = getSimplifiedType(currentFilter.type);
    const { filter } = currentFilter;

    const preparedAllFieldFilters = allFieldFilters.map(
      (fieldFilter: any) => {
        const newFieldFilter = { ...fieldFilter };
        if (currentFilter.id === (fieldFilter.sourceFieldId || fieldFilter.widgetCalculatedFieldId)) {
          newFieldFilter.filters = [];
        }
        return newFieldFilter;
      },
    );

    switch (remapedType) {
      case 'TEXT': {
        if (currentFilter.aggregation?.includes('COUNT')) {
          return (
            <ValueFilter
              onChangeFilter={onChangeFilter}
              currentFilter={filter}
              label={currentFilter.name}
              closeFilterPanel={closeFilterPanel}
              placement={Placement.Widget}
            />
          );
        }

        return (
          <SelectFilter
            onChangeFilter={onChangeFilter}
            field={currentFilter.name}
            sourceId={currentFilter.etlSourceId}
            currentFilter={currentFilter.filter}
            allFieldFilters={preparedAllFieldFilters}
            widgetId={widgetId}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Widget}
          />
        );
      }

      case 'DATE': {
        if (currentFilter.aggregation?.includes('COUNT')) {
          return (
            <ValueFilter
              onChangeFilter={onChangeFilter}
              currentFilter={filter}
              label={currentFilter.name}
              closeFilterPanel={closeFilterPanel}
              placement={Placement.Widget}
            />
          );
        }

        // todo в данном месте displayOptions - костыль, надо доработать логику фильтров виджета с учетом новых фильтров дашборда
        const isPeriod = currentFilter.function === '' || currentFilter.function.includes('DAY');
        const filterOptions: DateFilterOptions = {
          displayAvailablePeriod: true,
          minValue: currentFilter.minValue,
          maxValue: currentFilter.maxValue,
          displayOptions: {
            displayAsSingleDate: {
              value: !isPeriod,
              displayedName: SelectDateTypes.date,
            },
            displayAsPeriod: {
              value: isPeriod,
              displayedName: SelectDateTypes.period,
            },
          },
          selectDateType: isPeriod ? SelectDateTypes.period : SelectDateTypes.date
        };

        return (
          <DateFilter
            onChangeFilter={onChangeFilter}
            filterOptions={filterOptions}
            currentFilter={filter}
            filterType={
              currentFilter.function
                ? currentFilter.function.split("'")[1]
                : DateFunctions.NO_TYPE
            }
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Widget}
          />
        );
      }

      case 'NUMBER': {
        return (
          <ValueFilter
            onChangeFilter={onChangeFilter}
            currentFilter={filter}
            closeFilterPanel={closeFilterPanel}
            placement={Placement.Widget}
          />
        );
      }

      case 'BOOLEAN': {
        return (
          <SelectFilter
            onChangeFilter={onChangeFilter}
            currentFilter={filter}
            field={currentFilter.name}
            sourceId={currentFilter.etlSourceId}
            closeFilterPanel={closeFilterPanel}
            widgetId={widgetId}
            allFieldFilters={preparedAllFieldFilters}
            placement={Placement.Widget}
          />
        );
      }

      default: {
        return (
          <div>Нет фильтра для параметра с типом "{currentFilter.type}"</div>
        );
      }
    }
  }, [currentFilter, allFieldFilters]);

  return (
    <div>
      {renderer}
    </div>
  );
};

