import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import IconSvg from '../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      background: 'var(--white)',
      width: '40%',
      height: '100%',
      position: 'absolute',
      right: 0,
      zIndex: 100,
      boxShadow:
        '0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06)',
    },
    title: {
      color: 'var(--black)',
    },
    'title-panel': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px 20px',
      background: 'var(--white)',
      borderBottom: '1px solid var(--dark-cyan)',
    },
    closeButton: {
      padding: '10px',
      border: '1px solid var(--dark-cyan)',
      borderRadius: '4px',
    },
    mainContent: {
      padding: '15px 20px',
    },
  }),
);

interface Props {
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const PanelContainer = ({ onClose, title, children }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes['title-panel']}>
        <h3 className={classes.title}>{title}</h3>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          aria-label="close"
        >
          <IconSvg
            svg={IconDictionary.Close}
            fill="var(--dark-grey)"
          />
        </IconButton>
      </div>
      <div className={classes.mainContent}>{children}</div>
    </div>
  );
};

export default PanelContainer;
