import { useCallback } from 'react';
import { LegendPositions } from '../../../../enums/legend';
import { minRadius, defaultMargin } from '../pie/settings';

interface RadiusDimensionsProps {
  containerRef: React.RefObject<HTMLDivElement>;
  containerForRadiusWidth: number;
  containerForRadiusHeight: number;
  legendPosition: LegendPositions;
  widgetContainerPadding: number;
  isNeedToDisplayLegend: boolean;
  radius: number;
  setRadius: (value: number) => void;
  displayLegendLine: boolean;
  maxLineContainerHeight: number;
  maxLineContainerWidth: number;
}

export const useCalculateRadiusDimensions = ({
  containerRef,
  containerForRadiusWidth,
  containerForRadiusHeight,
  legendPosition,
  widgetContainerPadding,
  isNeedToDisplayLegend,
  radius,
  setRadius,
  displayLegendLine,
  maxLineContainerHeight,
  maxLineContainerWidth,
}: RadiusDimensionsProps) => {
  const minDifference = 10;

  const calculateCurrentRadius = useCallback((
    containerHeight: number,
    containerWidth: number,
  ) => {
    const topOffset =
      isNeedToDisplayLegend && displayLegendLine
        ? maxLineContainerHeight
        : widgetContainerPadding;
    const leftOffset =
      isNeedToDisplayLegend && displayLegendLine
        ? maxLineContainerWidth
        : widgetContainerPadding;
    const radiusWithoutOffset = Math.min(containerHeight, containerWidth) / 2;
    let currentRadius;

    if (containerHeight < containerWidth) {
      currentRadius = radiusWithoutOffset - topOffset - defaultMargin.top;
    } else {
      currentRadius = radiusWithoutOffset - leftOffset - defaultMargin.left;
    }
    return Math.max(currentRadius, minRadius);
  }, [
    isNeedToDisplayLegend,
    displayLegendLine,
    maxLineContainerHeight,
    widgetContainerPadding,
    maxLineContainerWidth,
  ]);

  const calculateDimensions = useCallback(() => {
    if (!containerRef.current) return;
  
    const { width: containerWidth, height: containerHeight } =
      containerRef.current.getBoundingClientRect();
  
    let currentRadius;
  
    if (Math.abs(containerWidth - containerForRadiusWidth) < minDifference ||
      Math.abs(containerHeight - containerForRadiusHeight) < minDifference) {
      currentRadius = calculateCurrentRadius(
        containerForRadiusHeight,
        containerForRadiusWidth,
      );
    } else {
      currentRadius = calculateCurrentRadius(
        containerHeight,
        containerWidth,
      );
    }
    
    const maxRadius = isNeedToDisplayLegend &&
    (legendPosition === LegendPositions.Left || legendPosition === LegendPositions.Right)
      ? containerWidth / 4
      : Math.min(containerHeight, containerWidth) / 2 - widgetContainerPadding;
  
    if (currentRadius !== radius) {
      setRadius(currentRadius > maxRadius ? maxRadius : currentRadius);
    }
  }, [
    calculateCurrentRadius,
    containerForRadiusHeight,
    containerForRadiusWidth,
    containerRef,
    isNeedToDisplayLegend,
    legendPosition,
    radius,
    setRadius,
    widgetContainerPadding,
  ]);

  return {
    calculateDimensions,
  };
};
