import { isNumber } from 'lodash';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { DisplayColumnZeros } from '../complex-table/ui/cells/custom';
import {
  getAggregationsForWidgetByAxis, getBooleanPropertyValue,
  getFunctionsForWidgetByAxis, getInputPropertyValue, getStorageForWidgetByAxis,
  getTypesForWidgetByAxis,
} from '../helpers';
import { formatDate, formatNumber } from '../formatting-helpers';
import {
  getAxisValues, getParsedAxisValues,
  getSimplifiedType,
} from '../../dropdown-layout/helpers/helpers';
import { WidgetSimplifiedDataType } from '../../../../enums/data-type';
import { AggregationName } from '../../../../enums/aggregations-functions';
import { NumberFormat } from '../../../../enums/number-format';

export interface DateFormatProperties {
  function: string;
  dataType: string;
  dateDisplayFormat: string;
  isNeedToIgnoreAggregation?: boolean;
}

interface FormatParameters {
  aggregation: any;
  function: any;
  type: any;
  index: number;
  dateDisplayFormat: string;
  isPercentValue: boolean;
  isNeedToIgnoreAggregation: boolean;
  displayZeros: boolean;
  numberFormat?: NumberFormat | null;
  displayValueText?: string;
}

const IGNORED_DATE_FORMAT_AGGREGATION = [
  AggregationName.MAX,
  AggregationName.MIN,
];

export const initValueFormatter = ({ roundingCount = 8 } = {}) => {
  const formatValue = (value: any, formatParameters: FormatParameters) => {

    const rounding = isNumber(roundingCount) ? roundingCount : formatParameters.isPercentValue ? 2 : 8;

    let dateFormatProperties: DateFormatProperties;
    switch (getSimplifiedType(formatParameters.type)) {
      case WidgetSimplifiedDataType.DATE:
        dateFormatProperties = {
          function: formatParameters.function || '',
          dataType: formatParameters.type,
          dateDisplayFormat:
            formatParameters.dateDisplayFormat === '[]'
              ? ''
              : formatParameters.dateDisplayFormat,
          isNeedToIgnoreAggregation: formatParameters.isNeedToIgnoreAggregation,
        };
        return formatParameters.aggregation &&
          !formatParameters.isNeedToIgnoreAggregation
          ? formatNumber(
            parseInt(value),
            formatParameters.isPercentValue,
            rounding,
            formatParameters.displayZeros,
            formatParameters.numberFormat,
            formatParameters.displayValueText
          )
          : formatDate(value.toString(), dateFormatProperties);
      case WidgetSimplifiedDataType.NUMBER:
        return formatNumber(
          value,
          formatParameters.isPercentValue,
          rounding,
          formatParameters.displayZeros,
          formatParameters.numberFormat,
          formatParameters.displayValueText
        );
      case WidgetSimplifiedDataType.TEXT:
      case WidgetSimplifiedDataType.BOOLEAN:
        return formatParameters.aggregation
          ? formatNumber(
            parseInt(value),
            formatParameters.isPercentValue,
            rounding,
            formatParameters.displayZeros,
            formatParameters.numberFormat,
            formatParameters.displayValueText
          )
          : value;
      default:
        return value;
    }
  };

  return (
    value: any,
    index: number,
    widgetProperties: any[],
    axis: PanelType = PanelType.axisX,
    isPercentValue: boolean | undefined = false,
    isNeedToIgnoreAggregation: boolean = false,
    numberFormat: NumberFormat | null = null,
    isNeedToDisplayValueText: boolean = false,
  ) => {
    const dateDisplayFormat = getAxisValues(
      WidgetPropertyType.dateDisplayFormat,
      widgetProperties,
    );

    const displayValueText = getInputPropertyValue(
      WidgetPropertyType.displayValueText,
      widgetProperties,
    );

    const displayZerosFromWidget = getBooleanPropertyValue(
      WidgetPropertyType.displayZeros,
      widgetProperties,
    );

    const displayNullsFromWidget = getBooleanPropertyValue(
      WidgetPropertyType.displayNulls,
      widgetProperties,
    );

    const storage = getStorageForWidgetByAxis(axis, widgetProperties)[index];
    const displayColumnZeros: DisplayColumnZeros = storage?.displayColumnZeros;
    const displayZeros =
      displayColumnZeros === 'displayed' ? true :
        displayColumnZeros === 'hidden' ? false :
          displayZerosFromWidget;

    const aggregations = getAggregationsForWidgetByAxis(axis, widgetProperties);
    const functions = getFunctionsForWidgetByAxis(axis, widgetProperties);

    const types = getTypesForWidgetByAxis(axis, widgetProperties);
    const type = String(
      getParsedAxisValues(axis, widgetProperties)[index]?.aggregation,
    ).includes('COUNT')
      ? 'DOUBLE'
      : types[index];

    const formatParameters: FormatParameters = {
      aggregation: aggregations[index],
      function: functions[index] || '',
      type,
      index,
      dateDisplayFormat,
      isPercentValue,
      isNeedToIgnoreAggregation: IGNORED_DATE_FORMAT_AGGREGATION.includes(
        aggregations[index],
      ),
      displayZeros,
      numberFormat,
      displayValueText: isNeedToDisplayValueText ? displayValueText : '',
    };

    const absValue = value === 'null' || value === null ? (displayNullsFromWidget ? 'null' : '') :
      value !== undefined
        ? formatValue(value, formatParameters)
        : formatValue(0, formatParameters);

    return isPercentValue ? absValue && `${absValue}%` : absValue;
  };
};
