import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikErrors } from 'formik';
import { SelectInput } from '../../../../../common/special-inputs/inputs/select-input';
import { HierarchyValues } from '../index';
import { SourceField, State } from '../../../../../../slices/types';
import WidgetTypes from '../../../../../../types/widgets';
import { apiFetchLoaderSources } from '../../../../../../services/widgetController';
import { ParentRootSetting } from './parent-root-setting';

interface MainSettingsProps {
  values: HierarchyValues;
  handleChange: (event: any) => void;
  errors: FormikErrors<HierarchyValues>;
  sourceFields: SourceField[];
  dicParentName?: string;
}

export const MainSettings = ({
  values,
  handleChange,
  errors,
  sourceFields,
  dicParentName,
}: MainSettingsProps) => {
  const loaderId: number =
    useSelector((state: State) => state.mainPage?.currentProject?.loaderId) ??
    0;

  const [sourceList, setSourceList] = useState<WidgetTypes.SourceItem[]>([]);

  useEffect(() => {
    const initData = () => {
      loaderId &&
        apiFetchLoaderSources(loaderId).then((result) => {
          setSourceList(result);
        });
    };

    initData();
  }, [loaderId]);

  const handleChangeDicSourceId = (event: any) => {
    handleChange(event);

    const resetMainSettings = () => {
      const dependentValues = [
        'dicReferenceField',
        'dicReferenceDisplay',
        'dicParent',
      ];

      dependentValues.forEach((fieldName) => {
        handleChange({ target: { name: fieldName, value: '' } });
      });
    };

    values.dicSourceId && resetMainSettings();
  };

  const selectFields = [
    {
      name: 'dicSourceId',
      label: 'Справочник',
      handleChange: handleChangeDicSourceId,
      list: sourceList.map((source) => {
        return { value: source.id, showValue: source.name };
      }),
    },
    {
      name: 'dicReferenceField',
      label: 'Столбец - ключ',
      handleChange,
      list: sourceFields.map((field) => {
        return { value: field.etlFieldId, showValue: field.name };
      }),
    },
    {
      name: 'dicParent',
      label: 'Столбец - родитель',
      handleChange,
      list: sourceFields.map((field) => {
        return { value: field.etlFieldId, showValue: field.name };
      }),
    },
    {
      name: 'dicReferenceDisplay',
      label: 'Поле для отображения',
      handleChange,
      list: sourceFields.map((field) => {
        return { value: field.etlFieldId, showValue: field.name };
      }),
    },
  ];

  return (
    <div className="hierarchy-settings-container">
      {selectFields.map((field) => (
        <SelectInput
          key={field.name}
          formikName={field.name}
          label={field.label}
          value={values[field.name as keyof HierarchyValues]}
          handleChange={field.handleChange}
          itemList={field.list}
          fullWidth
          className="dialog-body__form-field"
          error={Boolean(errors[field.name as keyof HierarchyValues])}
          helperText={errors[field.name as keyof HierarchyValues] as string}
          required
          hiddenShrink={false}
        />
      ))}
      <ParentRootSetting
        value={values.dicParentRootValue}
        handleChange={handleChange}
        error={errors.dicParentRootValue}
        dicParentName={dicParentName || ''}
      />
    </div>
  );
};
