import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import block from 'bem-cn';
import './connection-file.css';
import { CustomCheckbox } from '../../../uikit/Checkbox';
import { CustomFormControl } from '../../../uikit/FormControl';
import { IconButtonType, CustomIconButton } from '../../../uikit/IconButton';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

const useStyles = makeStyles({
  label: {
    marginRight: '-5px',
  },
});

interface ConnectionFileItemProps {
  name: string;
  previewName: string;
  addToNames: (name: string) => void;
  deleteFromNames: (name: string) => void;
  setPreviewName: (name: string) => void;
}

const b = block('connection-file');

const ConnectionFileItem: React.FC<ConnectionFileItemProps> = ({
  name,
  previewName,
  setPreviewName,
  addToNames,
  deleteFromNames,
}) => {
  const [isChecked, setChecked] = useState<boolean>(false);

  const isActiveColor = name === previewName ? 'var(--primary-color)' : 'var(--dark-grey)';
  const classes = useStyles();

  const handleChange = useCallback(() => {
    isChecked ? deleteFromNames(name) : addToNames(name);
    setChecked(!isChecked);
  }, [isChecked, deleteFromNames, addToNames, name]);

  const handlePreviewClick = () => {
    setPreviewName(name);
  };

  return (
    <div className={b()}>
      <CustomFormControl
        className={classes.label}
        control={
          <CustomCheckbox
            checked={isChecked}
            onChange={handleChange}
            name={name}
          />
        }
        label={
          <div className={b('label')} title={name}>
            {name}
          </div>
        }
      />
      <CustomIconButton
        iconButtonProps={{
          children: <IconSvg svg={IconDictionary.OpenFile} fill={isActiveColor} />,
          onClick: handlePreviewClick,
        }}
        type={IconButtonType.secondary}
      />
    </div>
  );
};

export default ConnectionFileItem;
