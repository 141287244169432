import MapConnection from '../types/map-connection';
import { State } from '../slices/types';
import { getSourceData, getStepsBySourceId } from './loader-page/index';

type MultiplyStepsState = {
  id: string,
  operationsLength: number,
  currentOperationIndex: number,
};

export class MultiplyStepsOnSource {
  loaderId: number;

  constructor(loaderId: number) {
    this.loaderId = loaderId;
  }

  private state: MultiplyStepsState[] = [];

  private setState(state: MultiplyStepsState[]) {
    this.state = state;
  }

  private setInitialSourceObjectState = (id: string, operationsLength: number) => {
    const prevState = this.state;
    this.setState([...prevState, {
      id,
      operationsLength,
      currentOperationIndex: 0,
    }]);
  };

  private incrementSourceObjectState = (id: string) => {
    const newState = this.state.map((sourceData) => {
      const { currentOperationIndex } = sourceData;
      const newSourceData = {
        ...sourceData,
        currentOperationIndex:
            sourceData.id === id ? currentOperationIndex + 1 : currentOperationIndex
      };
      return newSourceData;
    });
    this.setState(newState);
  };

  private deleteSourceObjectState = (id: string) => {
    const newState = this.state.filter(sourceData => sourceData.id !== id);
    this.setState(newState);
  };

  public update(id: string, getState: () => State) {
    const currentObject = this.state.find(source => source.id === id);
    if (currentObject) {
      const { currentOperationIndex, operationsLength } = currentObject;
      if (currentOperationIndex + 1 >= operationsLength) {
        this.deleteSourceObjectState(id);
      } else {
        this.incrementSourceObjectState(id);
      }
    } else {
      const sourceObjectSteps = getStepsBySourceId(getState, this.loaderId, id);
      if (sourceObjectSteps.length > 1) {
        this.setInitialSourceObjectState(id, sourceObjectSteps.length);
      }
    }
  }

  public getSourceOperationIndex(id: string): number {
    const currentObject = this.state.find(source => source.id === id);
    return currentObject?.currentOperationIndex || 0;
  }

  public getSourceData(getState: () => State): MapConnection.SourceObject | undefined {
    const sourceObjectId = this.state[this.state.length - 1]?.id || '';
    return getSourceData(getState, this.loaderId, sourceObjectId);
  }
}
