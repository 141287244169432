import React, { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../common/sidebar';
import { isNewDashboardPage } from '../../widget-page/charts/helpers';
import WidgetsList from '../widgets-list';
import Settings, { FiltersSettingsTypes } from '../settings';
import FiltersSetting from '../settings/filters-setting';
import FiltersChainingSettings from '../settings/filters-chaining-settings';
import GlobalParametersSetting from '../settings/parameters-setting/global-parameters-setting';
import FiltersDrilldownSettings from '../settings/filters-drilldown-settings';
import './styles.css';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { State } from '../../../slices/types';
import {
  closeDashboardFiltersSettingAction,
  resetDashboardValidationErrorAction,
  setDashboardNameAction,
} from '../../../slices/dashboard/dashboard';
import { useDebouncedInput } from '../../../hooks/useDebouncedInput';
import { CustomTabs } from '../../../uikit/Tabs';
import {DashboardDictionary, WidgetDictionary} from '../../../dictionaries/naming-dictionary/naming-dictionary';
import {DashboardNames} from "../../../dictionaries/naming-dictionary/dashboard-names";

const FiltersSettingWrap = () => {
  const openedFiltersSetting = useSelector(
    (state: any) => state.dashboard.openedFiltersSetting);

  switch (openedFiltersSetting) {
    case FiltersSettingsTypes.MAIN: return <FiltersSetting />;
    case FiltersSettingsTypes.CHAIN: return <FiltersChainingSettings />;
    case FiltersSettingsTypes.DRILLDOWN: return <FiltersDrilldownSettings />;
    case FiltersSettingsTypes.PARAMETERS: return <GlobalParametersSetting />;
    default: return null;
  }
};

const DashboardSidebar = () => {
  const [currentTab, setCurrentTab] = useState<string>('widgets');

  const dispatch = useDispatch();

  const dashboardErrors = useSelector(
    (state: State) => state.dashboard.validationErrors,
  );
  const dashboardId = useSelector(
    (state: State) => state.dashboard.id,
  );
  const dashboardNameState = useSelector(
    (state: State) => state.dashboard.name,
  );
  const [dashboardName, handleDashboardNameChange] = useDebouncedInput(
    dashboardNameState,
    (name) => {
      dashboardErrors?.name && name.length && dispatch(resetDashboardValidationErrorAction({ key: 'name' }));
      dispatch(setDashboardNameAction({ name }));
    },
  );

  useEffect(() => {
    return () => {
      dispatch(closeDashboardFiltersSettingAction());
    };
  }, [dashboardId]);

  const tabs = [
    {
      id: 0,
      title: WidgetDictionary.titleMany,
      onClick: () => setCurrentTab('widgets'),
    },
    {
      id: 1,
      title: 'Настройки',
      onClick: () => setCurrentTab('settings'),
    },
  ];

  return (
    <Sidebar>
      <div className="dashboard-sidebar">
        <div className="dashboard-tabs">
          <CustomTabs buttons={tabs} />
        </div>
        <div className="dashboard-name">
          <TextInput
            InputProps={{ maxlength: 255 }}
            value={dashboardName}
            label={`Введите ${DashboardDictionary.dashboardName}`}
            handleChange={(event) => {
              const { value } = event.target;
              handleDashboardNameChange(value);
            }}
            className="setting-field"
            error={Boolean(dashboardErrors?.name)}
            helperText={dashboardErrors?.name}
          />
        </div>
        <div className={currentTab === 'settings' ? 'noDisplay' : 'dashboard-sidebar-widgets'}>
          <WidgetsList />
        </div>
        <div className={currentTab === 'widgets' ? 'noDisplay' : 'dashboard-sidebar-settings'}>
          {currentTab === 'settings' && <Settings />}
        </div>

        <FiltersSettingWrap />
      </div>
    </Sidebar>
  );
};

export default DashboardSidebar;
