import React, { useRef, useState } from 'react';
import IconSvg from 'src/components/common/icon-svg/icon-svg';
import { CustomPopoverMenu, PopoverMenuSetting } from 'src/uikit/PopoverMenu/popover-menu';
import {
  defineFileDataFromResponse,
  fileDownload,
} from 'src/helpers/import-export';
import { apiExportWidget } from 'src/services/widgetController';
import { CustomPopover } from 'src/uikit/Popover/popover';
import { useNotificator } from 'src/components/common/snackbar/hooks';
import { CssVariables } from '../../../../../enums/css-variables';
import { varcss } from '../../../../../helpers/ui-helper';
import { CustomButton } from '../../../../../uikit/Button';
import { DashboardDictionary } from '../../../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface IButtonMore {
  widgetId:number;
  enhancedParams: any;
  onDownloadImageClick: (imageType: string) => void;
}

const ButtonMore: React.FC<IButtonMore> = ({ widgetId, enhancedParams, onDownloadImageClick }) => {
  const { showNotification } = useNotificator();

  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
  const [isDownloadLoadingAndSend, setIsDownloadLoadingAndSend] = useState<boolean>(false);

  const downloadXlsx = (callback?: () => void) => {
    apiExportWidget(widgetId, 'xlsx', enhancedParams)
      .then((response: any) => {
        const fileData = defineFileDataFromResponse(response);
        fileDownload(fileData.file, fileData.fileName);
      })
      .catch(() => {
        showNotification({ message: 'Ошибка при загрузке файла', variant: 'error' });
      })
      .finally(callback);
  };

  const onDownloadClick = () => {
    setIsDownloadLoading(true);
    downloadXlsx(() => {
      setIsDownloadLoading(false);
      closeMenu();
    });
  };

  const onSendClick = (event: any) => {
    const openEmail = () => {
      const dashboardLink = window.location.href;

      const link = document.createElement('a');
      link.setAttribute('href', `mailto:?body=Ссылка на ${DashboardDictionary.one} - ${dashboardLink} %0D%0AНеобходимо приложить скачанный файл к письму.`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    setIsDownloadLoadingAndSend(true);
    downloadXlsx(() => {
      setIsDownloadLoadingAndSend(false);
      closeMenu();
      openEmail();
    });
  };

  const settingsList: PopoverMenuSetting[] = [
    {
      title: 'Отправить письмо',
      icon: <IconSvg svg={IconDictionary.MailTo} fill="var(--dark-grey)" />,
      onClick: onSendClick,
      isNeedDivider: false,
      disabled: isDownloadLoadingAndSend,
      loading: isDownloadLoadingAndSend,
    },
    {
      title: 'Скачать xslx',
      icon: <IconSvg svg={IconDictionary.TableDownload} fill="var(--dark-grey)" />,
      onClick: onDownloadClick,
      isNeedDivider: false,
      disabled: isDownloadLoading,
      loading: isDownloadLoading,
    },
    {
      title: 'Скачать png',
      icon: <IconSvg svg={IconDictionary.Camera} fill="var(--dark-grey)" />,
      onClick: () => {
        onDownloadImageClick('png');
        closeMenu();
      },
      isNeedDivider: false,
    },
    {
      title: 'Скачать svg',
      icon: <IconSvg svg={IconDictionary.Svg} fill="var(--dark-grey)" />,
      onClick: () => {
        onDownloadImageClick('svg');
        closeMenu();
      },
      isNeedDivider: false,
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenPopover = Boolean(anchorEl);
  const containerRef = useRef(null);

  const handleMoreButtonClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(containerRef.current);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    closeMenu();
  };

  return (
    <div ref={containerRef}>
      <CustomButton
        onClick={handleMoreButtonClick}
        icon={<IconSvg svg={IconDictionary.VerticalMore} fill="var(--dark-grey)" />}
        size="small"
        variant="tab"
        backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
      />

      <CustomPopover
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpenPopover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
      >
        <CustomPopoverMenu settings={settingsList} />
      </CustomPopover>
    </div>
  );
};

export default ButtonMore;
