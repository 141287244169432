/* eslint-disable react/jsx-key */
import { TableCell as MUITableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import React from 'react';
import { CustomCell } from '../cells/custom';
import '../table-styles.css';
import { useTableCell } from './useTableCell';

export enum CellVariant {
  TEXT = 'TEXT',
  PROGRESS_AND_TEXT = 'PROGRESS_AND_TEXT',
  PROGRESS = 'PROGRESS',
}

export interface TableCellProps {
  cell: any;
  cellIndex: number;
  loading: boolean;
  isTotal?: boolean;
  isCombinedValue?: boolean;
  isCombinedStartValue?: boolean;
  isDisplayBorder?: boolean;
  isLastRow?: boolean;
}

export const TableCell = ({ cell, cellIndex, loading, isTotal, isCombinedValue, isCombinedStartValue, isDisplayBorder, isLastRow }: TableCellProps) => {
  const {
    needShowLoader,
    skeletonTextWidth,
    cellValue,
    view,
    progressPercent,
    handleClickCell,
  } = useTableCell({ cell, cellIndex, loading, isTotal });

  return (
    <MUITableCell
      {...cell.getCellProps([
        {
          className: clsx(cell.column.className, {
            'widget-table__cell': true,
            'widget-table__body-cell': true,
            'widget-table__cell-bordered': isDisplayBorder && !isTotal,
            'widget-table__body-cell-combined': isCombinedValue,
            'widget-table__body-cell-combined-start': isCombinedStartValue,
            'widget-table__body-cell-last-row': isLastRow,
            'widget-table__body-cell-total': isTotal,
          }),
          style: { ...cell.column.style },
        },
      ])}
      // BIDEV-7455 отключен выбор одного поля так как есть множественный выбор всей строки
      // если не нужен будет, то в будущем нужно удалить этот функционал
      // onClick={isTotal ? undefined : handleClickCell}
    >
      {needShowLoader ? (
        <Skeleton width={skeletonTextWidth} />
      ) : (
        <CustomCell
          value={isCombinedValue ? null : cellValue}
          progress={progressPercent}
          view={view}
          isTotal={isTotal}
        />
      )}
    </MUITableCell>
  );
};
