import { useCallback, useEffect } from 'react';
import { wsETL } from '../utils/api';
import { UpdateLoaderStatusController, UpdateWidgetCacheController } from '../classes/UpdateLoaderStatusController';
import { useWidgetChangesObserver } from './useWidgetChangesObserver';

const updateLoaderStatusController = new UpdateLoaderStatusController();
const updateWidgetCacheController = new UpdateWidgetCacheController();



enum LoaderStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  DONE = 'DONE',
  PARTIALLY_LOADED = 'PARTIALLY_LOADED',
}

export const useUpdateLoaderStatus = () => {
  useEffect(() => {
    const onChangeLoaderStatus = (messageOutput: any) => {
      const { id: loaderId, status } = JSON.parse(messageOutput.body);
      if (
        status.name === LoaderStatus.DONE ||
        status.name === LoaderStatus.PARTIALLY_LOADED
      ) {
        updateLoaderStatusController.callSubscribes(loaderId);
      }
    };

    let loaderChangeStatusUID: string | null;

    wsETL && wsETL.synchronizedConnect(() => {
      loaderChangeStatusUID = wsETL && wsETL.addHandler(
        '/topic/loader-changed-status',
        onChangeLoaderStatus,
      );
    });

    return () => {
      if (!loaderChangeStatusUID) return;
      wsETL && wsETL.removeHandler(loaderChangeStatusUID);
    };
  }, []);


  const onWidgetsHaveBeenChanged = useCallback((widgetIds: number[]) => {
    updateWidgetCacheController.callSubscribes(widgetIds);
  }, []);

  useWidgetChangesObserver(onWidgetsHaveBeenChanged);


  return {
    addLSSubscribe: updateLoaderStatusController.addSubscribe.bind(
      updateLoaderStatusController,
    ),
    deleteLSSubscribe: updateLoaderStatusController.deleteSubscribe.bind(
      updateLoaderStatusController,
    ),
    addWCSubscribe: updateWidgetCacheController.addSubscribe.bind(
      updateWidgetCacheController,
    ),
    deleteWCSubscribe: updateWidgetCacheController.deleteSubscribe.bind(
      updateWidgetCacheController,
    ),
  };
};
