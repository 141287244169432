import React, { useEffect, useId, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { apiDeleteSharedURL, apiGetSharedURL } from '../../../../services/dashboardController';
import { PageParams } from '../../../../types/meta';
import { useNotificator } from '../../snackbar/hooks';
import { ShareButtonView } from './share-button-view';
import { State } from '../../../../slices/types';

export const ShareButton = () => {
  const dashboard = useSelector((state: State) => state.dashboard);
  const params: PageParams = useParams();
  const dashboardId = parseInt(params?.id || '0');
  const sharedButton = useRef<HTMLDivElement | null>(null);
  const idSharedPopover = useId();
  const { showNotification } = useNotificator();

  const [isLoading, setIsLoading] = useState(false);
  const [sharedUrl, setSharedUrl] = useState<string | null>(null);
  const [isOpenSharedPopover, setOpenSharedPopover] = useState(false);
  const [isSharedDashboard, setIsSharedDashboard] = useState<boolean>(dashboard.shared);

  useEffect(() => {
    setIsSharedDashboard(dashboard.shared);
  }, [dashboard.shared]);

  const shareDashboard = () => {
    setIsLoading(true);
    apiGetSharedURL(dashboardId)
      .then((url) => {
        setSharedUrl(url);
        setOpenSharedPopover(true);
        setIsSharedDashboard(true);
      })
      .catch((error) => {
        showNotification({
          message:
            error.response?.data?.message || 'Ошибка при получении ссылки',
          variant: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleCloseSharedPopover = () => {
    setOpenSharedPopover(false);
  };

  const handleCopySharedUrl = () => {
    copy(sharedUrl || '');
    showNotification({
      message: 'Скопировано в буфер обмена',
      variant: 'success',
    });
  };

  const handleClickDeleteShared = () => {
    setIsLoading(true);

    apiDeleteSharedURL(dashboardId).then(() => {
      setIsSharedDashboard(false);
      setSharedUrl('');
    }).catch((error) => {
      showNotification({
        message: error.response?.data?.message || 'Ошибка при попытке отменить публикацию',
        variant: 'error',
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <ShareButtonView
      sharedButton={sharedButton}
      idSharedPopover={idSharedPopover}
      isLoading={isLoading}
      shareDashboard={shareDashboard}
      isOpenSharedPopover={isOpenSharedPopover}
      handleCloseSharedPopover={handleCloseSharedPopover}
      sharedUrl={sharedUrl}
      handleCopySharedUrl={handleCopySharedUrl}
      handleClickDeleteShared={handleClickDeleteShared}
      isSharedDashboard={isSharedDashboard}
      handleClick={() => setOpenSharedPopover(true)}
    />
  );
};
