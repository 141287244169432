import { useSelector } from 'react-redux';
import { State } from '../slices/types';

export const useRoles = (roles: any) => {
  const userInfo = useSelector((state: State) => state.mainPage.userInfo);
  const newRoles = { ...roles };

  if (userInfo?.isAdmin) {
    Object.keys(newRoles).forEach((key) => {
      newRoles[key] = true;
    });
  }

  return newRoles;
};
