import React from 'react';
import { CustomFormControl } from '../../../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../../../uikit/Checkbox';

export interface HeatMapMainSetting {
  value: boolean;
  action: () => void;
  label: string;
}

interface MainSettingsProps {
  settings: HeatMapMainSetting[];
}

export const MainSettings = ({ settings }: MainSettingsProps) => {
  return (
    <div className="heat-map-settings__block heat-map-settings__block_inline">
      {settings.map((setting) => (
        <CustomFormControl
          key={setting.label}
          control={
            <CustomCheckbox checked={setting.value} onChange={setting.action} withoutTopBottomPadding={true} />
          }
          label={setting.label}
        />
      ))}
    </div>
  );
};
