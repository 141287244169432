import { compact, constant, isNull, times } from 'lodash';
import { PanelType } from '../../../../enums/widget-type';
import { WidgetProperties } from '../../../../slices/types';
import { getPanelIndex } from '../../dropdown-layout/helpers/helpers';
import { PivotColumnsWidth, PivotHeadCell, PivotRow } from './interfaces';
import { defaultColumnsWidths, minColumnWidth, minNotAdaptiveColumnWidth } from './settings';
import { isActive, PropertyData } from '../../dropdown-layout/helpers/Property';
import { ColumnWidthType } from '../../../../enums/widgets-select-property';

export const isTitle = (id: string) => {
  return id === 'title';
};

export const isTotal = (id: string) => {
  return id.includes('total');
};

export const isMiddleTotal = (id: string) => {
  return id.includes('middleTotal');
};

export const getColumnsIds = (
  isMultipleValues: boolean,
  header: PivotHeadCell[][],
  isNeedToDisplayMiddleTotal: boolean,
  isNeedToDisplayTotal: boolean,
  closedColumns: string[]
) => {
  const columns: string[] = [];

  columns.push('title');

  if (header.length) {
    const lastHeaderRow = header[header.length - 1];
    lastHeaderRow.forEach((cell) => {
      isMiddleTotal(cell.id) &&
        (isNeedToDisplayMiddleTotal || !isOpenColumn(cell.parentId, cell.id, closedColumns)) &&
        columns.push(cell.id);
      isTotal(cell.id) && isNeedToDisplayTotal && columns.push(cell.id);
      !isMiddleTotal(cell.id) && !isTotal(cell.id) && isVisibleColumn(cell.parentId, cell.id, closedColumns)
        && columns.push(cell.id);
    });
  }

  if (!isMultipleValues && header.length > 1) {
    columns.push('total');
  }

  return columns;
};

export const getHeaderWithoutMiddleTotals = (
  header: PivotHeadCell[][],
  closedColumns: string[]
): PivotHeadCell[][] => {
  const newHeader: PivotHeadCell[][] = [];
  header.forEach((cells) => {
    newHeader.push(cells.filter((cell) => !isMiddleTotal(cell.id) || !isOpenColumn(cell.parentId, cell.id, closedColumns)));
  });

  return newHeader;
};

const getColumnsCountForNoColumnsPivot = (header: PivotHeadCell[][]) => {
  return [times(header[0].length, constant(1))];
};

export const getColumnsCount = (
  header: PivotHeadCell[][],
  isNeedToDisplayMiddleTotal: boolean,
  isNeedToDisplayTotal: boolean,
  axisYValues: PropertyData[],
  isPivotWithColumns: boolean,
  closedColumns: string[],
): number[][] => {
  if (!header.length) return [];

  if (!isPivotWithColumns) return getColumnsCountForNoColumnsPivot(header);

  const isMultipleValues = axisYValues.length > 1;
  const firstHeadRowIndex = 0;
  const secondHeadRowIndex = 1;
  const valueHeadRowIndex = 2;

  const nestedColumnsForLastRow = 1;
  const nestedColumnsForRowBeforeValues = axisYValues.length;

  const columnsCount: number[][] = [];
  const headerLevelsWithoutValues = isMultipleValues
    ? header.length - 1
    : header.length;

  if (headerLevelsWithoutValues === 1 && !isMultipleValues) {
    columnsCount[firstHeadRowIndex] = times(
      header[firstHeadRowIndex].length - (isNeedToDisplayTotal ? 0 : 1),
      constant(nestedColumnsForLastRow),
    );
  }

  if (headerLevelsWithoutValues === 1 && isMultipleValues) {
    columnsCount[firstHeadRowIndex] = times(
      header[firstHeadRowIndex].length - (isNeedToDisplayTotal ? 0 : 1),
      constant(nestedColumnsForRowBeforeValues),
    );

    columnsCount[secondHeadRowIndex] = times(
      header[secondHeadRowIndex].length - (isNeedToDisplayTotal ? 0 : axisYValues.length),
      constant(nestedColumnsForLastRow),
    );
  }

  if (headerLevelsWithoutValues > 1) {
    columnsCount[firstHeadRowIndex] = [];
    header[firstHeadRowIndex].forEach((cell) => {
      if (isTotal(cell.id)) {
        columnsCount[firstHeadRowIndex].push(isNeedToDisplayTotal ? nestedColumnsForLastRow : 0);
      } else {
        const cellsCount =
          compact(
            header[secondHeadRowIndex].map((nestedCell) => {
              return (
                nestedCell.parentId === cell.id &&
                (isTotal(nestedCell.id) ? isNeedToDisplayTotal : true)
              );
            }),
          ).length * axisYValues.length;

        columnsCount[firstHeadRowIndex].push(cellsCount);
      }
    });

    columnsCount[secondHeadRowIndex] =
      times(header[secondHeadRowIndex].length, constant(nestedColumnsForRowBeforeValues));

    if (isMultipleValues) {
      columnsCount[valueHeadRowIndex] = times(
        header[valueHeadRowIndex].length - (isNeedToDisplayTotal ? 0 : axisYValues.length),
        constant(nestedColumnsForLastRow),
      );
    }
  }

  return getColumnsCountWithClosedColumns(header, closedColumns, columnsCount, axisYValues.length);
};

export const isOpenColumn = (parentId: string | null, id: string, closedColumns: string[]) => {
  return isNull(parentId)
    ? !closedColumns.includes(id)
    : !closedColumns.includes(parentId);
};

export const isVisibleColumn = (parentId: string | null, id: string, closedColumns: string[]): boolean => {
  return isOpenColumn(parentId, id, closedColumns) || isMiddleTotal(id) || isTotal(id);
};

const getColumnsCountWithClosedColumns = (
  header: PivotHeadCell[][],
  closedColumns: string[],
  columnsCount: number[][],
  valuesCount: number,
) => {
  if (columnsCount.length !== header.length) return [];

  const newColumnsCount = [ ...columnsCount ];
  header.forEach((headerRow, rowIndex) => {
    const newHeaderRow: number[] = [];
    headerRow.forEach((cell, cellIndex) => {
      const isFirstHeadLevel = !rowIndex;
      newHeaderRow.push(
        isVisibleColumn(cell.parentId, cell.id, closedColumns) ?
          columnsCount[rowIndex][cellIndex] : isFirstHeadLevel ? valuesCount
            : 0
      );
    });
    newColumnsCount[rowIndex] = compact(newHeaderRow);
  });

  return newColumnsCount;
};

export const getCellIndexes = (index: number, columnsCount: number[][]) => {
  const cellsCount: number[] = columnsCount.map((item) => item.length);

  let headRowLevel: number = 0;
  let sumCellsCount: number = 0;

  do {
    sumCellsCount += cellsCount[headRowLevel];
    headRowLevel++;
  } while (sumCellsCount < index);

  const sumCellsCountBeforeCurrentLevel =
    sumCellsCount - cellsCount[headRowLevel - 1];

  const columnIndex =
    index - 1 - (headRowLevel === 1 ? 0 : sumCellsCountBeforeCurrentLevel);

  return {
    columnIndex,
    rowIndex: headRowLevel,
    sumCellsCountBeforeCurrentLevel,
  };
};

export const getHeaderWithoutClosedColumns = (header: PivotHeadCell[][], closedColumns: string[]) => {

  const maxDeepLevel = header.reduce((a, b) => b[0].level > a ? b[0].level : a, 0);

  const headerWithoutClosedColumns: PivotHeadCell[][] = [];
  header.forEach((headerRow, rowIndex) => {
    !rowIndex && (headerWithoutClosedColumns[rowIndex] = headerRow);

    if (rowIndex) {
      headerWithoutClosedColumns[rowIndex] = headerRow.filter((column) => {
        return isVisibleColumn(column.parentId, column.id, closedColumns);
      });
    }

    headerWithoutClosedColumns[rowIndex] = headerWithoutClosedColumns[rowIndex].map(
      (col) => ({ ...col, isLastChild: col.level === maxDeepLevel })
    );

  });

  return headerWithoutClosedColumns;
};

export const getCellByHeadLevelAndIndex = (
  header: PivotHeadCell[][],
  headRowLevel: number,
  index: number,
  sumCellsCountBeforeCurrentLevel: number,
  closedColumns: string[]
) => {
  const headerWithoutClosedColumns = getHeaderWithoutClosedColumns(header, closedColumns);
  return headerWithoutClosedColumns[headRowLevel - 1]?.[
    index - 1 - (headRowLevel === 1 ? 0 : sumCellsCountBeforeCurrentLevel)
  ];
};

const getColumnsWidthByWidthCoefficients = (
  columnsWidthCoefficients: {
    title: number;
    total: number;
    nestedColumn: number;
  },
  isNeedToCalculateWithTotal: boolean,
) => {
  if (
    columnsWidthCoefficients.title <= 0 ||
    columnsWidthCoefficients.total <= 0 ||
    columnsWidthCoefficients.nestedColumn <= 0
  ) {
    return defaultColumnsWidths;
  }

  const minRatio = isNeedToCalculateWithTotal
    ? Math.min(
        columnsWidthCoefficients.title,
        columnsWidthCoefficients.total,
        columnsWidthCoefficients.nestedColumn,
      )
    : Math.min(
        columnsWidthCoefficients.title,
        columnsWidthCoefficients.nestedColumn,
      );

  return {
    title: (columnsWidthCoefficients.title * minColumnWidth) / minRatio,
    total: (columnsWidthCoefficients.total * minColumnWidth) / minRatio,
    nestedColumn:
      (columnsWidthCoefficients.nestedColumn * minColumnWidth) / minRatio,
  };
};

export const getAdaptiveColumnsWidth = (
  columnsWidthCoefficients: PivotColumnsWidth,
  containerWidth: number,
  isNeedToCalculateWithTotal: boolean,
  columnsCount: number
) => {
  let ratio: number;

  const columnsWidth = getColumnsWidthByWidthCoefficients(columnsWidthCoefficients, isNeedToCalculateWithTotal);

  const tableWidth = getPivotTableWidth(
    columnsWidth,
    columnsCount,
    isNeedToCalculateWithTotal,
  );

  if (containerWidth > tableWidth) {
    ratio = containerWidth / tableWidth;
  } else {
    ratio = isNeedToCalculateWithTotal
      ? Math.max(
          minColumnWidth / columnsWidth.title,
          minColumnWidth / columnsWidth.total,
          minColumnWidth / columnsWidth.nestedColumn,
        )
      : Math.max(
          minColumnWidth / columnsWidth.title,
          minColumnWidth / columnsWidth.nestedColumn,
        );
  }

  return {
    title: columnsWidth.title * ratio,
    total: columnsWidth.total * ratio,
    nestedColumn: columnsWidth.nestedColumn * ratio,
  };
};

export const getPivotTableWidth = (
  columnsWidth: PivotColumnsWidth,
  columnsCount: number,
  isNeedToCalculateWithTotal: boolean,
) => {
  return (
    columnsWidth.title +
    columnsCount * columnsWidth.nestedColumn +
    (isNeedToCalculateWithTotal ? columnsWidth.total - columnsWidth.nestedColumn : 0)
  );
};

export const getDefaultClosedColumns = (header: PivotHeadCell[][], columnsCount: number) => {
  if (!header?.length || columnsCount !== 2) return [];
  return header[0].map((cell) => cell.id);
};

export const getHeadRowsButtonTitle = (axisValues: PropertyData[]) => {
  const names = axisValues
    .filter(isActive)
    .map((item) => JSON.parse(item.storage || '{}').alias || item.hierarchyLink?.dicParentName || item.name);

  return names.map(
    (name, index) => name + (names.length - 1 !== index ? '/' : ''),
  ).join('');
};

export const getNotAdaptiveColumnsWidth = (
  columnsWidthValues: PivotColumnsWidth,
) => {
  return {
    title: Math.max(minNotAdaptiveColumnWidth, columnsWidthValues.title),
    total: Math.max(minNotAdaptiveColumnWidth, columnsWidthValues.total),
    nestedColumn: Math.max(minNotAdaptiveColumnWidth, columnsWidthValues.nestedColumn),
  };
};

export const isNeedToAdaptiveColumns = (scaleWidget: boolean, columnWidthType: ColumnWidthType) => {
  return scaleWidget || (columnWidthType !== ColumnWidthType.pixels);
};

export const isNeedToDisplayTitle = (width: number) => {
  return width >= minColumnWidth;
};

export const getFiltersValuesForDrilldown = (
  cellId: string,
  axes: {
    axisXValues: PropertyData[];
    axisYValues: PropertyData[];
    axisZValues: PropertyData[];
  },
  row: PivotRow,
  header: PivotHeadCell[][],
) => {
  const { axisXValues, axisYValues, axisZValues } = axes;

  const currentAxisXValue = axisXValues[row.depth];
  const currentAxisXValueId =
    currentAxisXValue.id || currentAxisXValue.widgetFieldId || 0;

  const isHierarchyPivotTable = isHierarchyPivot(axisXValues);
  const isTotalRow = row.title.value === 'Итог';

  const filterValues = isHierarchyPivotTable || isTotalRow ? [] : [row.title.value];
  const filterIds = isHierarchyPivotTable || isTotalRow ? [] : [currentAxisXValueId];

  if (isTitle(cellId) || isTotal(cellId) || isMiddleTotal(cellId)) {
    return {
      filterValues,
      filterIds,
    };
  }

  let currentHeaderElement = header[header.length - 1].find(
    (item) => item.id === cellId,
  );

  if (axisYValues.length > 1) {
    const currentValueHeaderElementIndex = header[header.length - 1].findIndex(
      (item) => item.id === cellId,
    );

    const lastHeaderRowWithoutValues = header[header.length - 2];
    const currentHeaderElementIndex = Math.floor(currentValueHeaderElementIndex/axisYValues.length);

    currentHeaderElement = lastHeaderRowWithoutValues[currentHeaderElementIndex];
  }

  const currentAxisZValue = axisZValues.find(
    (item) => item.etlFieldId === currentHeaderElement?.etlFieldId,
  );
  const currentAxisZValueId = currentAxisZValue?.id || currentAxisZValue?.widgetFieldId || 0;

  if (currentAxisZValue && currentHeaderElement) {
    filterValues.push(currentHeaderElement.name);
    filterIds.push(currentAxisZValueId);
  }

  return {
    filterValues,
    filterIds,
  };
};

export const isHierarchyPivot = (axisXValues: PropertyData[]) => {
  return Boolean(axisXValues.find((item) => item.hierarchyLink?.dicSourceId));
};

export const getWidgetPropertiesIsActiveAxes = (properties: WidgetProperties[]) => {

  const propertiesCopy = [...properties];

  [
    PanelType.axisX,
    PanelType.axisY,
    PanelType.axisZ,
  ].forEach((axis) => {
    const panelIndex = getPanelIndex({ properties: propertiesCopy, type: axis });

    if (panelIndex > -1) {
      try {
        const values = JSON.parse(propertiesCopy[panelIndex].value);

        const valuesIsActive = values.filter(isActive);

        propertiesCopy[panelIndex] = {
          name: axis,
          value: JSON.stringify(valuesIsActive),
        };
      } catch (e) {
        // Если ошибка то, properties не изменяем
      }
    }
  });

  return propertiesCopy;
};
