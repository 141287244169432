import React, { useMemo } from 'react';
import { BarGroup } from '@visx/shape';
import { Group } from '@visx/group';
import { Text, TextProps } from '@visx/text';
import clsx from 'clsx';
import {
  useGetNumberWidgetProperty,
  useGetStringWidgetProperty,
  useGetValueDirectionWidgetProperty,
  ValueDirection,
} from '../../../../hooks/get-properties/useGetWidgetProperty';
import {
  getBooleanPropertyValue,
  getSelectPropertyValue,
  getStickPointValue,
} from '../helpers';
import { setPropForNumberValue } from '../formatting-helpers';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { ScalableSVGText } from '../../../common/scalable-svg-text';
import {
  barValueStyles,
  getWidgetProperties,
} from '../histogram-graph/properties';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { FilterField } from '../../../dashboard-page/hooks';
import { WidgetTooltipData } from '../../../../types/widget-tooltip';

import {
  getBarHeight,
  getInsideBarYValue,
  getOutsideBarYValue,
} from './helpers';
import { NumberFormat } from '../../../../enums/number-format';

interface BarGroupRendererProps {
  data: any;
  keys: any;
  yMax: any;
  getX: any;
  xScale: any;
  valuesScale: any;
  yScale: any;
  colorScale: any;
  widgetProperties: any;
  setBarWidth: React.Dispatch<React.SetStateAction<number>>;
  setBarGroupWidth: React.Dispatch<React.SetStateAction<number>>;
  setBarLeftShift: React.Dispatch<React.SetStateAction<number>>;
  enableEvents: boolean;
  selectBar: (groupIndex: number) => void;
  filterField: FilterField;
  getCustomBarWidth: () => number | false;
  handleWidgetMouseMove: (event: any, data: WidgetTooltipData) => void;
  handleWidgetMouseLeave: (event: React.MouseEvent) => false | undefined;
  metaData: any;
}

const insideValuesOffset = 10;

export const BarGroupRenderer = ({
  data,
  keys,
  yMax,
  getX,
  xScale,
  valuesScale,
  yScale,
  colorScale,
  widgetProperties,
  setBarWidth,
  setBarGroupWidth,
  setBarLeftShift,
  enableEvents,
  selectBar,
  filterField,
  getCustomBarWidth,
  handleWidgetMouseMove,
  handleWidgetMouseLeave,
  metaData,
}: BarGroupRendererProps) => {
  const {
    roundingCountString,
    axisXValues,
    legendsLabels,
    isNeedToDisplayTooltip,
    isNeedToDisplayOutsideBarValues,
    isNeedToDisplayInsideBarValues,
  } = useMemo(() => getWidgetProperties(widgetProperties), [widgetProperties]);

  const valueTextSize = useGetNumberWidgetProperty(
    WidgetPropertyType.textSize,
    widgetProperties,
    18,
  );
  const valueColorHistogram = useGetStringWidgetProperty(
    WidgetPropertyType.valueColorHistogram,
    widgetProperties,
    'var(--grey)',
  );
  const valueDirection = useGetValueDirectionWidgetProperty(widgetProperties);

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const valueFormat = initValueFormatter({ roundingCount });

  return (
    <BarGroup
      data={data}
      keys={keys}
      height={yMax}
      x0={getX}
      x0Scale={xScale}
      x1Scale={valuesScale}
      yScale={yScale}
      color={colorScale}
    >
      {(barGroups) => {
        if (barGroups.length) {
          const barWidth = barGroups[0].bars[0].width;
          const barLeftShift = barGroups[0].bars[0].x;

          const shiftLastBarInFirstGroup =
            barGroups[0].bars[barGroups[0].bars.length - 1].x;
          const barGroupWidth =
            shiftLastBarInFirstGroup +
            (getCustomBarWidth() || barWidth) -
            barLeftShift;

          setBarWidth(barWidth);
          setBarGroupWidth(barGroupWidth);
          setBarLeftShift(barLeftShift);
        }

        return barGroups.map((barGroup) => {
          return (
            <Group
              key={`bar-group-${barGroup.index}-${barGroup.x0}`}
              className={clsx({ 'active-drilldown-element': enableEvents })}
              left={barGroup.x0}
              onClick={(e) => {
                if (!enableEvents) return;
                e.stopPropagation();
                selectBar(barGroup.index);
              }}
            >
              {barGroup.bars.map((bar, index) => {
                const barWidth = getCustomBarWidth() || bar.width;

                const outsideBarValuePositionProps: TextProps =
                  valueDirection === ValueDirection.vertical
                    ? bar.value >= 0
                      ? {
                        textAnchor: 'start',
                        verticalAnchor: 'middle',
                        angle: 270,
                      }
                      : {
                        textAnchor: 'end',
                        verticalAnchor: 'middle',
                        angle: 270,
                      }
                    : {
                      textAnchor: 'middle',
                      verticalAnchor: 'end',
                    };

                const insideNegativeBarValuePositionProps: TextProps = {
                  textAnchor: 'end',
                  verticalAnchor: 'middle',
                  angle: 270,
                };

                const insideBarValuePositionProps: TextProps =
                  bar.value > 0
                    ? outsideBarValuePositionProps
                    : insideNegativeBarValuePositionProps;

                return (
                  <g key={bar.key}>
                    <rect
                      key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                      x={bar.x}
                      y={
                        bar.value < 0
                          ? yScale(getStickPointValue(yScale.ticks()))
                          : bar.y
                      }
                      width={barWidth}
                      height={getBarHeight(bar, yScale)}
                      fill={
                        (metaData &&
                          metaData[barGroup.index] &&
                          metaData[barGroup.index][bar.index]
                            ?.backgroundColor) ||
                        bar.color
                      }
                      opacity={
                        filterField?.value === null ||
                        filterField?.value === undefined ||
                        filterField?.value?.includes(data[barGroup.index]?.x)
                          ? 0.8
                          : 0.2
                      }
                      rx={4}
                      onMouseLeave={
                        isNeedToDisplayTooltip
                          ? handleWidgetMouseLeave
                          : undefined
                      }
                      onMouseMove={
                        isNeedToDisplayTooltip
                          ? (event) =>
                            handleWidgetMouseMove(event, {
                              attrX: axisXValues[0],
                              valueX: valueFormat(
                                data[barGroup.index].x,
                                0,
                                widgetProperties,
                              ),
                              attrY: legendsLabels[bar.index],
                              valueY: valueFormat(
                                bar.value,
                                0,
                                setPropForNumberValue(widgetProperties),
                                PanelType.axisY,
                                false,
                                false,
                                scaleByNumber ? formatByNumber : null,
                                true,
                              ),
                            })
                          : undefined
                      }
                    />
                    {isNeedToDisplayOutsideBarValues && (
                      <Text
                        x={bar.x + barWidth / 2}
                        y={getOutsideBarYValue(bar, valueDirection === ValueDirection.vertical)}
                        {...outsideBarValuePositionProps}
                        fill={valueColorHistogram}
                        style={{
                          fontSize: valueTextSize,
                          fontFamily: barValueStyles.fontFamily,
                          fontStyle: barValueStyles.fontStyle,
                          fontWeight: barValueStyles.fontWeight,
                        }}
                      >
                        {valueFormat(
                          bar.value,
                          0,
                          setPropForNumberValue(widgetProperties),
                          PanelType.axisY,
                          false,
                          false,
                          scaleByNumber ? formatByNumber : null,
                          true,
                        )}
                      </Text>
                    )}

                    {isNeedToDisplayInsideBarValues &&
                      valueDirection !== ValueDirection.vertical && (
                        <ScalableSVGText
                          x={bar.x}
                          y={getInsideBarYValue(bar, yScale)}
                          formattedValue={valueFormat(
                            bar.value,
                            0,
                            setPropForNumberValue(widgetProperties),
                            PanelType.axisY,
                            false,
                            false,
                            scaleByNumber ? formatByNumber : null,
                            true,
                          )}
                          axis={PanelType.axisY}
                          properties={{
                            maxHeightHide: getBarHeight(bar, yScale),
                            minWidthHide: 30,
                            width: barWidth,
                            svgTextStyles: {
                              ...barValueStyles,
                              color: barValueStyles.insideColor,
                            },
                          }}
                          widgetProperties={widgetProperties}
                        />
                    )}

                    {isNeedToDisplayInsideBarValues &&
                      valueDirection === ValueDirection.vertical &&
                      bar.value !== 0 && (
                        <Text
                          x={bar.x + barWidth / 2}
                          y={
                            getInsideBarYValue(bar, yScale) + insideValuesOffset
                          }
                          {...insideBarValuePositionProps}
                          fill={valueColorHistogram}
                          style={{
                            fontSize: valueTextSize,
                            fontFamily: barValueStyles.fontFamily,
                            fontStyle: barValueStyles.fontStyle,
                            fontWeight: barValueStyles.fontWeight,
                          }}
                        >
                          {valueFormat(
                            bar.value,
                            0,
                            setPropForNumberValue(widgetProperties),
                            PanelType.axisY,
                            false,
                            false,
                            scaleByNumber ? formatByNumber : null,
                            true,
                          )}
                        </Text>
                    )}
                  </g>
                );
              })}
            </Group>
          );
        });
      }}
    </BarGroup>
  );
};
