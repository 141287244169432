import { TableRow } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { Project } from '../../../../slices/types';
import { useLoadersTableRow } from './useLoadersTableRow';
import { LoaderTableCell } from '../cell/loader-table-cell';
import { LoaderTableSettingCell } from '../cell/loader-table-setting-cell';

export interface LoadersTableRowProps {
  project: Project;
  groupId: number;
}

export const LoadersTableRow = ({ project, groupId }: LoadersTableRowProps) => {
  const {
    cells,
    cellsCount,
  } = useLoadersTableRow({ project, groupId });

  return (
    <TableRow className={clsx('loader-table__row', { 'loader-table__row_published': project.published })}>
      {cells.map((cell, index) => {
        if (index === cellsCount - 1) {
          return (
            <LoaderTableSettingCell key={index} project={project} />
          );
        }

        return (<LoaderTableCell key={index} cell={cell} index={index} />);
      })}
    </TableRow>
  );
};

