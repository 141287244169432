import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DashboardResponse,
  Filters,
  Loader,
  MainPage,
  Project,
  RightPanelMode,
  SystemSettings,
  UserInfo,
  Widget,
} from '../types';
import Dictionaries from '../../types/dictionaries';
import { isIFramed } from '../../utils/iframe-helper';
import WidgetTypes from '../../types/widgets';

const isFramed = isIFramed();

const initialState: MainPage = {
  isShowDeleteModal: false,
  isDataCacheCleared: false,
  isDataCacheLoading: false,
  isShowCopyModal: false,
  isShowEditModal: false,
  isShowEditGroupModal: false,
  isShowDeleteGroupModal: false,
  isShowSubscribeModal: false,
  isShowMailingSubscribeModal: false,
  isShowConnectionModal: false,
  isShowCheckFilesModal: false,
  isShowSaveVersionModal: false,
  isShowRollbackVersionModal: false,
  isWidgetPageSaveDisabled: false,
  currentLoader: null,
  currentProject: null,
  currentProjectId: null,
  currentProjectWidgets: null,
  currentProjectDashboards: null,
  currentProjectWidgetData: null,
  currentProjectWidgetSourceFieldsData: null,
  currentProjectWidgetSources: null,
  widgetError: '',
  rightPanelMode: null,
  isDeleting: false,
  isActive: true,
  currentGroupWidgets: null,
  isLoading: false,
  userInfo: null,
  systemSettings: null,
  isWidgetFullScreen: isFramed,
  editableMap: true,
  colorPalettes: [],
  editableColorPalette: null,
  selectedMapCards: null,
  isDashboardChangedNotificationDisabled: false,
  wasClickOnPushdownCheckbox: false,
  filters: { search: '', groupIds: [] },
  effectWidgetEditUpdateData: false,
};

// TODO rename in from main page, set as just mainSlice
const mainPageSlice = createSlice({
  name: 'mainPage',
  initialState,
  reducers: {
    setDeleteModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowDeleteModal = payload;
    },
    setCopyModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowCopyModal = payload;
    },
    setSubscribeModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowSubscribeModal = payload;
    },
    setSubscribeMailingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowMailingSubscribeModal = payload;
    },
    setConnectionModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowConnectionModal = payload;
    },
    setCheckFilesModal: (state: { isShowCheckFilesModal: any; }, { payload }: PayloadAction<boolean>) => {
      state.isShowCheckFilesModal = payload;
    },
    setChangeModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowEditModal = payload;
    },
    setEditGroupModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowEditGroupModal = payload;
    },
    setDeleteProjectModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowDeleteGroupModal = payload;
    },
    setShowSaveVersionModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowSaveVersionModal = payload;
    },
    setShowRollbackVersionModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowRollbackVersionModal = payload;
    },
    setCurrentLoader: (state, { payload }: PayloadAction<Loader>) => {
      state.currentLoader = payload;
    },
    setCurrentProject: (state, { payload }: PayloadAction<Project | null>) => {
      state.currentProject = payload;
    },
    setCurrentProjectId: (state, { payload }: PayloadAction<number | null>) => {
      state.currentProjectId = payload;
    },
    setRightPanelMode: (state, { payload }: PayloadAction<RightPanelMode>) => {
      state.rightPanelMode = payload;
    },
    setDeleting: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeleting = payload;
    },
    setActivity: (state, { payload }: PayloadAction<boolean>) => {
      state.isActive = payload;
    },
    setCurrentProjectWidgets: (state, { payload }: PayloadAction<Widget[] | null>) => {
      state.currentProjectWidgets = payload;
    },
    setCurrentProjectDashboards: (
      state,
      { payload }: PayloadAction<DashboardResponse[] | null>,
    ) => {
      state.currentProjectDashboards = payload;
    },
    setCurrentProjectWidgetData: (state, { payload }: PayloadAction<any>) => {
      state.currentProjectWidgetData = payload;
    },
    setDataCacheCleared: (state, { payload }: PayloadAction<any>) => {
      state.isDataCacheCleared = payload;
    },
    setDataCacheLoading: (state, { payload }: PayloadAction<any>) => {
      state.isDataCacheLoading = payload;
    },
    setCurrentProjectWidgetSourceFieldsData: (
      state,
      { payload }: PayloadAction<any>,
    ) => {
      state.currentProjectWidgetSourceFieldsData = payload;
    },
    setCurrentProjectWidgetSources: (
      state,
      { payload }: PayloadAction<WidgetTypes.SourceItem[] | null>,
    ) => {
      state.currentProjectWidgetSources = payload;
    },
    setCurrentGroupWidgets: (state, { payload }: PayloadAction<Widget[] | null>) => {
      state.currentGroupWidgets = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setUserInfo: (state, { payload }: PayloadAction<UserInfo>) => {
      state.userInfo = payload;
    },
    setSystemSettings: (state, { payload }: PayloadAction<SystemSettings>) => {
      state.systemSettings = payload;
    },
    setWidgetFullScreen: (state, { payload }: PayloadAction<boolean>) => {
      state.isWidgetFullScreen = payload;
    },
    setMapEditable: (state, { payload }: PayloadAction<boolean>) => {
      state.editableMap = payload;
    },
    setColorPalettes: (state, { payload }: PayloadAction<Dictionaries.ColorPalette[]>) => {
      state.colorPalettes = payload;
    },
    setEditableColorPalette: (state, { payload }: PayloadAction<Dictionaries.ColorPalette | null>) => {
      state.editableColorPalette = payload;
    },
    setWidgetPageSaveDisabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isWidgetPageSaveDisabled = payload;
    },

    setSelectedMapCards: (state, { payload }: PayloadAction<{key: number; value: string | null;}>) => {
      if (payload.value) {
        !state.selectedMapCards && (state.selectedMapCards = {});
        state.selectedMapCards[payload.key] = payload.value;
      } else {
        state.selectedMapCards && state.selectedMapCards[payload.key] && (delete state.selectedMapCards[payload.key]);
      }
    },
    resetSelectedMapCards: (state) => {
      state.selectedMapCards = null;
    },
    setDashboardChangedNotificationDisabled: (state, { payload }) => {
      state.isDashboardChangedNotificationDisabled = payload;
    },
    setCurrentProjectWidgetError: (state, { payload }) => {
      state.widgetError = payload;
    },
    setWasClickOnPushdownCheckbox: (state, { payload }: PayloadAction<boolean>) => {
      state.wasClickOnPushdownCheckbox = payload;
    },
    setFilters: (state: { filters: Filters }, { payload }: PayloadAction<Filters>) => {
      state.filters.search = payload.search;
      state.filters.groupIds = payload.groupIds;
    },
    callEffectWidgetEditUpdateData: (state) => {
      state.effectWidgetEditUpdateData = !state.effectWidgetEditUpdateData;
    },
  },
});

export const mapPageReducer = mainPageSlice.reducer;

export const {
  setDeleteModal: setDeleteModalAction,
  setCopyModal: setCopyModalAction,
  setChangeModal: setChangeModalAction,
  setEditGroupModal: setEditGroupModalAction,
  setDeleteProjectModal: setDeleteProjectModalAction,
  setSubscribeModal: setSubscribeModalAction,
  setSubscribeMailingModal: setSubscribeMailingModalAction,
  setConnectionModal: setConnectionModalAction,
  setCheckFilesModal: setCheckFilesModalAction,
  setShowSaveVersionModal: setShowSaveVersionModalAction,
  setShowRollbackVersionModal: setShowRollbackVersionModalAction,
  setCurrentLoader: setCurrentLoaderAction,
  setCurrentProject: setCurrentProjectAction,
  setCurrentProjectId: setCurrentProjectIdAction,
  setRightPanelMode: setRightPanelModeAction,
  setDeleting: setDeletingAction,
  setActivity: setActivityAction,
  setCurrentProjectWidgets: setCurrentProjectWidgetsAction,
  setCurrentProjectWidgetData: setCurrentProjectWidgetDataAction,
  setCurrentProjectWidgetError: setCurrentProjectWidgetErrorAction,
  setCurrentProjectDashboards: setCurrentProjectDashboardsAction,
  setCurrentProjectWidgetSourceFieldsData: setCurrentProjectWidgetSourceFieldsDataAction,
  setCurrentProjectWidgetSources: setCurrentProjectWidgetSourcesAction,
  setDataCacheCleared: setDataCacheClearedAction,
  setDataCacheLoading: setDataCacheLoadingAction,
  setCurrentGroupWidgets: setCurrentGroupWidgetsAction,
  setLoading: setLoadingAction,
  setUserInfo: setUserInfoAction,
  setSystemSettings: setSystemSettingsAction,
  setWidgetFullScreen: setWidgetFullScreenAction,
  setMapEditable: setMapEditableAction,
  setColorPalettes: setColorPalettesAction,
  setEditableColorPalette: setEditableColorPaletteAction,
  setSelectedMapCards: setSelectedMapCardActions,
  resetSelectedMapCards: resetSelectedMapCardsAction,
  setWidgetPageSaveDisabled: setWidgetPageSaveDisabledAction,
  setDashboardChangedNotificationDisabled: setDashboardChangedNotificationDisabledAction,
  setWasClickOnPushdownCheckbox: setWasClickOnPushdownCheckboxAction,
  callEffectWidgetEditUpdateData: callEffectWidgetEditUpdateDataAction,
  setFilters: setFiltersAction,
} = mainPageSlice.actions;
