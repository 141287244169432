import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import './column-coloring-options.css';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import {
  CellColoringGroup,
  PropertyData,
} from '../../../dropdown-layout/helpers/Property';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { ConditionalColoringGroup } from './coloring-group/conditional-coloring-group';
import { PanelType } from '../../../../../enums/widget-type';
import { apiValidateColoring } from '../../../../../services/widgetController';
import { CustomButton } from '../../../../../uikit/Button';
import { State } from '../../../../../slices/types';
import { CustomErrorAlert } from '../../../../../uikit/ErrorAlert';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

type ColumnColoringOptionsDialogProps = {
  panelData: PropertyData;
  closeDialog: () => void;
  changeColoringOptions: (coloring: any) => void;
  axisValue: PropertyData[];
  panelName: PanelType;
};

export const ColumnColoringOptionsDialog = ({
  panelData,
  closeDialog,
  changeColoringOptions,
  axisValue,
  panelName,
}: ColumnColoringOptionsDialogProps) => {

  const projectId: number | undefined =
    Number(useParams<{ projectId: string }>()?.projectId) ?? undefined;

  const [settingsGroups, setSettingsGroups] = useState<CellColoringGroup[]>(panelData.coloring || []);
  const [validateError, setValidateError] = useState<string | null>(null);
  const isPushDown = useSelector((state: State) => state.widget.pushDown);

  const changeSettingInGroup = useCallback((groupIndex: number, group: CellColoringGroup) => {
    const newSettingsGroups = [...settingsGroups];

    newSettingsGroups[groupIndex] = group;

    setSettingsGroups(newSettingsGroups);
  }, [settingsGroups, setSettingsGroups]);

  const handleSave = () => {
    apiValidateColoring(projectId, { ...panelData, coloring: settingsGroups }, isPushDown).then(() => {
      closeDialog();
      changeColoringOptions(settingsGroups);
      setValidateError(null);
    }).catch((error: any) => {
      setValidateError(error.response.data.message);
    });
  };

  const addCondition = () => {
    const newGroup: CellColoringGroup = {
      simpleCondition: {
        fieldName: panelData.name,
        fieldEtlId: panelData.etlFieldId,
        aggregation: panelData.aggregation || '',
        operation: '',
        value: '',
      },
      formula: {
        template: '',
        operands: [],
        operandIds: [],
        text: '',
      },
      useFormula: false,
      conditionColor: '',
      backgroundColor: '',
    };

    setSettingsGroups([...settingsGroups, newGroup]);
  };

  const copyGroup = useCallback((index: number) => {
    setSettingsGroups([...settingsGroups, cloneDeep(settingsGroups[index])]);
  }, [settingsGroups]);

  const deleteGroup = useCallback((index: number) => {
    const newGroup = [...settingsGroups];
    newGroup.splice(index, 1);
    setSettingsGroups(newGroup);
  }, [settingsGroups]);

  return (
    <CustomDialog
      isOpen
      onClose={closeDialog}
      title={`Раскраска столбца «${panelData.name}»`}
      maxWidth="sm"
    >
      <div className="column-coloring-options-dialog__main">
        {!settingsGroups.length && (
          <div className="column-coloring-options-dialog__empty">
            <div className="column-coloring-options-dialog__empty-description">
              В данный момент не добавлено ни одного условия
            </div>
          </div>
        )}

        {validateError && (
          <div className="column-coloring-options-dialog__error-container">
            <CustomErrorAlert>{validateError}</CustomErrorAlert>
          </div>
        )}
        <div className="column-coloring-options-dialog__list">
          {settingsGroups.map((group, index) => (
            <ConditionalColoringGroup
              key={index}
              index={index}
              group={group}
              copyGroup={copyGroup}
              deleteGroup={deleteGroup}
              axisValues={axisValue}
              changeSettingInGroup={changeSettingInGroup}
              panelName={panelName}
              fieldType={panelData.type}
            />
          ))}
        </div>
      </div>

      <div className="column-coloring-options-dialog__buttons-container">
        <CustomButton
          variant="contained"
          type="submit"
          onClick={handleSave}
        >
          <span>Сохранить</span>
        </CustomButton>
        <CustomButton
          variant="outlined"
          type="reset"
          onClick={closeDialog}
        >
          Отменить
        </CustomButton>

        <div className="column-coloring-options-dialog__buttons-container_right">
          <CustomButton
            variant="text"
            className="column-coloring-options-dialog__add-button"
            startIcon={
              <IconSvg
                svg={IconDictionary.PlusRounded}
                fill="var(--primary-color)"
              />
            }
            onClick={addCondition}
          >
            Добавить условие
          </CustomButton>
        </div>
      </div>
    </CustomDialog>
  );
};
