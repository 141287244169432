import React from 'react';
import { styled } from '@material-ui/core';
import { CustomButton, IButton } from '../Button';

export interface InlineTextButtonProps extends Omit<IButton, 'colorType'> {
  title: string;
  styleProps?: { fontSize?: number; };
  colorType?: 'primary' | 'black';
}

const getTextColorForHover = (colorType: string) => {
  switch (colorType) {
    case 'primary':
      return 'var(--primary-hover-color)';
    case 'black':
      return 'var(--primary-color)';
    default:
      return 'var(--primary-color)';
  }
};

const getTextColor = (colorType: string) => {
  switch (colorType) {
    case 'primary':
      return 'var(--primary-color)';
    case 'black':
      return 'var(--black)';
    default:
      return 'var(--primary-color)';
  }
};

const StyledTextButton = styled(CustomButton)(
  ({ colorType, styleProps, hoverColor }: Partial<InlineTextButtonProps & { hoverColor: string }>) => ({
    padding: 0,
    height: 'auto',
    fontWeight: 400,
    color: `${getTextColor(colorType as string)} !important`,
    background: 'none !important',
    ...styleProps,
    fontSize: `calc(${styleProps?.fontSize || 14}px * var(--scale-coefficient))`,

    '&:hover': {
      color: `${hoverColor} !important`,
    },

    '& [class*=MuiButton-endIcon]': {
      marginLeft: 'calc(4px * var(--scale-coefficient))',
    },

    '& [class*=MuiButton-startIcon]': {
      marginRight: 'calc(4px * var(--scale-coefficient))',
    },

    '&:hover svg': {
      fill: `${hoverColor} !important`,
    }
  }),
);

export const InlineTextButton = ({
  title,
  styleProps,
  colorType = 'primary',
  ...props
}: InlineTextButtonProps) => {
  return (
    <StyledTextButton
      {...props}
      variant="text"
      hoverColor={getTextColorForHover(colorType)}
      styleProps={styleProps}
    >
      {title}
    </StyledTextButton>
  );
};
