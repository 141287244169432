import React, { useState } from 'react';
import { List } from '@material-ui/core';

import OperationTypeItem from './operation-type-item/operation-type-item';

import './operation-type-menu.css';
import Dictionaries from '../../../types/dictionaries';

interface OperationTypeMenuProps {
  formulesDictionary: Dictionaries.GroupFormula[];
  chooseTransformationOperation: (
    operation: Dictionaries.Formula,
  ) => () => void;
}

const OperationTypeMenu = ({
  formulesDictionary,
  chooseTransformationOperation,
}: OperationTypeMenuProps) => {
  const [currentFormulaIndex, setCurrentFormulaIndex] = useState<number| null>(null);
  
  const onMouseEnterCallback = (index: number) => {
    setCurrentFormulaIndex(index);
  };
  
  return (
    <List>
      {formulesDictionary.map((type: Dictionaries.GroupFormula, key) => (
        <OperationTypeItem
          onMouseEnterCallback={onMouseEnterCallback}
          currentFormulaIndex={currentFormulaIndex}
          formulasType={type}
          chooseTransformationOperation={chooseTransformationOperation}
          key={key}
        />
      ))}
    </List>
  );
};

export default OperationTypeMenu;
