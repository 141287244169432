import React, { useMemo, useState } from 'react';
import '../column-coloring-options.css';
import { useSelector } from 'react-redux';
import { getSimplifiedType } from '../../../../dropdown-layout/helpers/helpers';
import { State } from '../../../../../../slices/types';
import { PanelType, WidgetType } from '../../../../../../enums/widget-type';
import { getAggregationOfFieldForPanelItemSettings } from '../../../../helpers';
import { CellColoringGroup, PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { GroupHead } from './group-head';
import { SimpleFormulaBlock } from './simple-formula-block';
import { MultiFormulaBlock } from './multi-formula-block';
import { ColorsBlock } from './colors-block';

type ConditionalColoringGroupProps = {
  index: number;
  group: CellColoringGroup;
  deleteGroup: (index: number) => void;
  copyGroup: (index: number) => void;
  axisValues: PropertyData[];
  changeSettingInGroup: (groupIndex: number, group: CellColoringGroup) => void;
  panelName: PanelType;
  fieldType: string;
};

export const ConditionalColoringGroup = ({
  index,
  group,
  deleteGroup,
  copyGroup,
  axisValues,
  changeSettingInGroup,
  panelName,
  fieldType,
}: ConditionalColoringGroupProps) => {
  const [isOpen, setOpen] = useState<boolean>(true);

  const isPivot =
    useSelector((state: State) => state.widget.type) === WidgetType.PIVOT_TABLE;

  const sourceFields =
    useSelector(
      (state: State) => state.mainPage?.currentProjectWidgetSourceFieldsData,
    ) ?? [];

  const fields = axisValues
    .map((value) => ({ ...value, isAxisValue: true }))
    .concat(
      sourceFields
        .filter((sourceField) => {
          const isFieldInAxisValues = axisValues.find(
            (value) => value.etlFieldId === sourceField.etlFieldId,
          );
          return (
            !isFieldInAxisValues &&
            getSimplifiedType(sourceField.type) === 'NUMBER'
          );
        })
        .map((value: any) => {
          return { ...value, isAxisValue: false };
        }),
    )
    .filter((sourceField) => !sourceField.isLagOrLead);

  const formatAxisValues = useMemo(
    () =>
      fields.map((value: any, index: number) => {
        return {
          key: index,
          value: value.displayedName || value.name,
          aggregation: value.isAxisValue
            ? value.aggregation
            : getAggregationOfFieldForPanelItemSettings(panelName),
          id: value.etlFieldId,
          type: value.type,
          ignoreAggregation: Boolean(value.complex),
        };
      }),
    [fields, panelName],
  );

  const onChangeVisibleGroup = () => {
    setOpen(!isOpen);
  };
  
  return (
    <div className="conditional-coloring__container">
      <GroupHead
        isOpen={isOpen}
        index={index}
        group={group}
        onChangeVisibleGroup={onChangeVisibleGroup}
        copyGroup={copyGroup}
        deleteGroup={deleteGroup}
      />

      {isOpen && (
        <div>
          <SimpleFormulaBlock
            group={group}
            changeSettingInGroup={changeSettingInGroup}
            formatAxisValues={formatAxisValues}
            index={index}
            fieldType={fieldType}
            axisValues={axisValues}
          />

          {!isPivot && (
            <MultiFormulaBlock
              group={group}
              changeSettingInGroup={changeSettingInGroup}
              formatAxisValues={formatAxisValues}
              index={index}
            />
          )}

          <ColorsBlock
            group={group}
            changeSettingInGroup={changeSettingInGroup}
            index={index}
          />
        </div>
      )}
    </div>
  );
};
