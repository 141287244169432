import { FileFieldType } from '../../../../../../enums/connection-field-type';

export const fieldName: Readonly<Record<FileFieldType, string>> = {
  [FileFieldType.WithHeader]: FileFieldType.WithHeader,
  [FileFieldType.Charset]: FileFieldType.Charset,
  [FileFieldType.WorkingDirectory]: FileFieldType.WorkingDirectory,
  [FileFieldType.Delimiter]: FileFieldType.Delimiter,
  [FileFieldType.Mask]: FileFieldType.Mask,
  [FileFieldType.DataAddress]: FileFieldType.DataAddress,
  [FileFieldType.RowTag]: FileFieldType.RowTag,
  [FileFieldType.CheckXsd]: FileFieldType.CheckXsd,
  [FileFieldType.XsdFileName]: FileFieldType.XsdFileName,
  [FileFieldType.JSONLINES]: FileFieldType.JSONLINES,
  [FileFieldType.DOCUMENT_ID]: FileFieldType.DOCUMENT_ID,
  [FileFieldType.SHEET]: FileFieldType.SHEET,
};
