import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateDataAfterChangeLoaderStatus } from '../../../hooks/useUpdateDataAfterChangeLoaderStatus';
import { useDashboardSettings } from '../../dashboard-page/settings/hooks/useDashboardSettings';
import { Dashboard, SystemSettings, State } from '../../../slices/types';
import { apiGetLastCacheUpdate } from '../../../services/projectsController';
import { apiGetAllProjectDashboards } from '../../../services/dashboardController';
import {
  dashboardWidgetsSelector,
  setCrossFiltersAction,
} from '../../../slices/dashboard/dashboard';
import { WidgetType } from '../../../enums/widget-type';
import { isMobilehelper } from '../../../utils/mobile-helper';
import { ProjectDashboardsHeaderProps } from '../header/header';
import { defineCrossFilters, getPropertyValue } from '../../../helpers/dashboard-page';
import { DashboardPropertyType } from '../../../enums/dashboard-properties';
import { FilterGroupProps } from '../../dashboard-page/hooks';

const useStyles = makeStyles({
  'select-root': {
    fontFamily: 'Manrope',
    backgroundColor: 'var(--header-backdrop-color) !important',
    border: 'none',
    fontWeight: 'bold',
    fontSize: 'calc(20px * var(--scale-coefficient))',
    lineHeight: 'calc(24px * var(--scale-coefficient))',
    color: 'var(--title-color)',
  },
  'select-menu': {
    maxHeight: '2.4em',
    whiteSpace: 'normal',
    paddingTop: 0,
    paddingRight: 'calc(24px * var(--scale-coefficient)) !important',
    paddingBottom: 0,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow-y': 'hidden',
  },
  'MuiMenuItem-root': {
    whiteSpace: 'normal',
    maxHeight: '3.5em',
    'overflow': 'hidden',
    padding: 'calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient))',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(16px * var(--scale-coefficient))',
    color: 'var(--black)',
  },
  'select-menu__value': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    'overflow': 'hidden',
  },
  focused: {
    backgroundColor: 'var(--header-backdrop-color) !important',
    border: 'none',
    color: 'black',
    borderBottom: '2px solid var(--primary-color)',
  },
  paper: {
    maxHeight: 'calc(400px * var(--scale-coefficient)) !important',
    maxWidth: 'calc(400px * var(--scale-coefficient)) !important',
    minWidth: 'calc(200px * var(--scale-coefficient)) !important',
    backgroundColor: 'var(--header-backdrop-color)',
    border: '1px solid #DAE7ED',
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    borderRadius: 'var(--base-border-radius)',
    padding: '2px',

    '& [class*=MuiList-root]': {
      padding: 0
    }
  }
});

const PermissionsForEdit = [23, 24];

export const useProjectDashboardsHeader = ({
  projectId,
  dashboardId,
}: ProjectDashboardsHeaderProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();

  const [projectDashboardsList, setProjectDashboards] = useState<{value: number | undefined, showValue: string}[]>([]);
  const [currentDashboardOption, setCurrentDashboardOption] = useState<number | null>(null);
  const [lastCacheUpdate, setLastCacheUpdate] = useState<string>('');

  const { showCacheUpdateTime, isActiveCrossFiltration } = useDashboardSettings();
  const loaderId =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) || 0;
  const permission =
    useSelector((state: State) => state.mainPage.currentProject?.permission) ||
    -1;
  const currentDashboardProperties =
    useSelector((state: State) => state.dashboard.properties);
  const settingsData: SystemSettings | null = useSelector((state: State) => state.mainPage.systemSettings);
  const crossFilters = useSelector(
    (state: State) => state.dashboard.crossFilters,
  );
  const projectDashboardTitle = useSelector(
    (state: State) => state.dashboard.name,
  );

  const mutateDashboardsForSelect = useCallback((list: Dashboard[]) => {
    return list.map((dashboard) => {
      return {
        value: dashboard.id,
        showValue: dashboard.name,
      };
    });
  }, []);

  const cacheLastUpdateFetcher = useCallback(() => {
    projectId && apiGetLastCacheUpdate(projectId).then((response) => {
      setLastCacheUpdate(response);
    });
  }, [projectId]);

  useEffect(() => {
    cacheLastUpdateFetcher();
  }, [cacheLastUpdateFetcher]);

  const callbackRefreshData = useCallback(() => {
    cacheLastUpdateFetcher();
  }, [cacheLastUpdateFetcher]);

  useUpdateDataAfterChangeLoaderStatus('ProjectDashboardsHeader', callbackRefreshData);

  useEffect(() => {
    projectId && dashboardId && apiGetAllProjectDashboards(projectId).then((result: Dashboard[]) => {
      const dashboardsListMutated: {value: number | undefined, showValue: string}[] = mutateDashboardsForSelect(result);
      const currentDashboardId =
        dashboardsListMutated.find((db) => db.value === dashboardId)
          ?.value || dashboardsListMutated[0]?.value;
      currentDashboardId && setCurrentDashboardOption(currentDashboardId);
      setProjectDashboards(dashboardsListMutated);
    });
  }, [dashboardId, mutateDashboardsForSelect, projectId]);

  useEffect(() => {
    const currentDashboardId =
      projectDashboardsList.find((db) => db.value === dashboardId)
        ?.value || projectDashboardsList[0]?.value || null;
    dashboardId && setCurrentDashboardOption(currentDashboardId);
  }, [dashboardId, projectDashboardsList]);

  const saveFiltersForCrossFiltration = useCallback(() => {
    if (!isActiveCrossFiltration) return;

    const filters: FilterGroupProps[] = getPropertyValue(DashboardPropertyType.filters, currentDashboardProperties);

    const definedCrossFilters = defineCrossFilters(filters, crossFilters);

    dispatch(setCrossFiltersAction(definedCrossFilters));
  }, [crossFilters, currentDashboardProperties, dispatch, isActiveCrossFiltration]);

  const onDashboardChange = useCallback(
    (event: React.ChangeEvent<{name?: string | undefined, value: any}>) => {
      const selectedDashboardId = event.target.value;

      saveFiltersForCrossFiltration();

      history.push(
        `/project/${projectId}/dashboard/${selectedDashboardId}/preview`,
      );
    },
    [history, projectId, saveFiltersForCrossFiltration],
  );

  const widgets = useSelector(dashboardWidgetsSelector);

  const [isNeedToDisplayExportButton, setIsNeedToDisplayExportButton] =
    useState<boolean>(false);

  useEffect(() => {
    const widgetTypes = widgets.map((widget) => widget.type);
    const isNeedToDisplayExportButton = !!widgetTypes.filter(
      (type: string) => type !== WidgetType.HTML && type !== WidgetType.MAP,
    ).length;

    setIsNeedToDisplayExportButton(isNeedToDisplayExportButton);
  }, [widgets]);

  const isMobile = useMemo(() => isMobilehelper(), []);

  const isNeedToDisplayEditButton = PermissionsForEdit.includes(permission) && !isMobilehelper();

  const SYSTEM_UI_NAME = settingsData?.SYSTEM_UI_NAME;

  useEffect(() => {
    document.title = `${SYSTEM_UI_NAME || ''} | Просмотр: ${projectDashboardTitle}`;
    return () => {
      document.title = SYSTEM_UI_NAME || '';
    };
  }, [SYSTEM_UI_NAME, currentDashboardOption, projectDashboardTitle]);

  const currentPage = window.location.pathname.toLowerCase();
  const isNoHeader = /\/noheader/.test(currentPage);

  return {
    isMobile,
    classes,
    currentDashboardOption,
    onDashboardChange,
    projectDashboardsList,
    showCacheUpdateTime,
    lastCacheUpdate,
    isNeedToDisplayEditButton,
    loaderId,
    isNeedToDisplayExportButton,
    isNoHeader,
    projectDashboardTitle,
  };
};
