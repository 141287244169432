import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';

import { Project, RightPanelMode, State } from '../../../slices/types';
import { setRightPanelModeAction } from '../../../slices/main-page/main-page-slice';

import LoaderHistory from '../../common/loader-history/loader-history';
import LoaderSchedule from '../loader-schedule/loader-schedule';

const MainPageRightPanel = () => {
  const dispatch = useDispatch();
  const { rightPanelMode } = useSelector((state: State) => state.mainPage);

  const currentProject = useSelector(
    (state: State) => state.mainPage.currentProject,
  );

  const handleClose = () => {
    dispatch(setRightPanelModeAction(null));
  };

  const getChildrenComponent = (
    mode: RightPanelMode,
    currentProject: Project,
  ) => {
    if (mode === 'history') {
      return (
        <LoaderHistory
          loaderId={currentProject.loaderId}
          projectName={currentProject.name}
          handleClose={handleClose}
        />
      );
    }
    if (mode === 'schedule') {
      return (
        <LoaderSchedule
          loaderId={currentProject.loaderId}
          handleClose={handleClose}
        />
      );
    }

    return null;
  };

  return (
    <Drawer open={Boolean(rightPanelMode)} anchor="right" onClose={handleClose}>
      {currentProject && getChildrenComponent(rightPanelMode, currentProject)}
    </Drawer>
  );
};

export default MainPageRightPanel;
