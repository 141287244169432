import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { PageParams } from '../../types/meta';
import { Project, State, SystemSettings } from '../../slices/types';
import { isNewDashboardPage } from '../../components/widget-page/charts/helpers';
import { apiGetProject } from '../../services/projectsController';
import { setCurrentProjectAction } from '../../slices/main-page/main-page-slice';
import { DashboardDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';


export const useDashboardPage = () => {
  const params: PageParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const dashboardId = Number(params.id);

  const { isActive } = useSelector((state: State) => state.mainPage);

  const dashboardName = useSelector((state: State) => state.dashboard.name);
  const settingsData: SystemSettings | null = useSelector((state: State) => state.mainPage.systemSettings);

  const SYSTEM_UI_NAME = settingsData?.SYSTEM_UI_NAME;

  useEffect(() => {
    const initTitle = () => {
      document.title = `${SYSTEM_UI_NAME || ''} | ${
        isNewDashboardPage() ? `Новый ${DashboardDictionary.one}` : `${DashboardDictionary.title}: ${dashboardName}`
      }`;
    };

    const unsubscribe = () => {
      document.title = SYSTEM_UI_NAME || '';
    };

    initTitle();
    return () => unsubscribe();
  }, [SYSTEM_UI_NAME, dashboardName, dispatch]);

  useEffect(() => {
    if (!params.id && !params.projectId) {
      history.push('/');
    }

    apiGetProject(Number(params.projectId)).then((project: Project) => {
      dispatch(setCurrentProjectAction(project));
    });
  }, [dispatch, history, params.id, params.projectId]);

  return {
    isActive,
    dashboardId,
  };
};
