import React from 'react';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { PanelInfoItem } from '../../panel-info-item/panel-info-item';
import { PanelType } from '../../../../../enums/widget-type';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { useFilterInfoItem } from './useFilterInfoItem';

export interface FilterInfoItemProps {
  panelType: PanelType;
  hiddenElements: PropertyData[];
}

export const FilterInfoItem = ({ panelType, hiddenElements }: FilterInfoItemProps) => {
  const {
    countFiltersFromTableColumns,
    hiddenElementsTooltip
  } = useFilterInfoItem({ panelType, hiddenElements });

  if (countFiltersFromTableColumns < 1) return null;

  return (
    <CustomTooltip title={hiddenElementsTooltip}>
      <div>
        <PanelInfoItem name="Фильтры на столбцах" />
      </div>
    </CustomTooltip>
  );
};
