/**
 * Важно! Если значение enum CssVariables поменяется,
 * то нужно вручную просмотреть всех css файлы и заменить старое значение на новое
 * */

export enum CssVariables {
  WIDGET_BACKDROP_COLOR = '--widget-backdrop-color',
  MAIN_BACKDROP_COLOR = '--main-backdrop-color',
  HEADER_BACKDROP_COLOR = '--header-backdrop-color',
}
