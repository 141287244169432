export const nest = (
  items: any[],
  id = null,
  parentIdField = 'parentID',
  childrenField = 'columns',
): any[] =>
  items
    .filter((item) => {
      return item && item[parentIdField] === id;
    })
    .map((item) => {
      const children = nest(items, item.id);

      if (children.length) {
        return { ...item, [childrenField]: children };
      }

      return { ...item };
    });
