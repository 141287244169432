import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import { compact } from 'lodash';
import {
  apiGetWidgetsListBySourceAndField,
  apiUpdateSchemeBySourceObjectGetWidgets,
} from '../../../services/widgetController';

import MapConnection from '../../../types/map-connection';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { toggleSavingSelectFieldsAction } from '../../../slices/select-fields-panel/select-fields-panel';

import {
  changeCurrentSourceIdAction,
  toggleShowSelectFieldsPanelAction,
} from '../../../slices/map-view/map-view';
import {
  ButtonsSection,
  FieldsSection,
  FilterSection,
  OptionsSection,
  TitleSection,
} from './sections';
import FiltrationPanel from '../filtration-panel/filtration-panel';
import CalculatedIndicatorPanel from '../calculated-indicator-panel/calculated-indicator-panel';
import TransformationPanel from '../transformation-panel/transformation-panel';
import SqlScriptForm from '../sql-script-form/sql-script-form';
import { apiCheckSourceObjectAliases, apiGetMapConnection } from '../../../services/loadersController';
import { Modes } from './helper';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import DeleteDialog from '../../../uikit/DeleteDialog/delete-dialog';
import CommonLoader from '../../common/common-loader/common-loader';
import { State } from '../../../slices/types';

import './select-fields-panel.css';
import { useNotificator } from '../../common/snackbar/hooks';
import { getStepIdsData } from '../../../helpers/loader-page';
import { SourceOption } from './sections/filter-section/loader-menu/loader-menu';
import { getWhereCondition } from '../save-result-panel/logic';
import { replaceAll } from '../../../utils/functions';
import { apiGetSqlScript } from '../../../services/source-objects';
import { CustomButton } from '../../../uikit/Button';
import {
  CommonDictionary,
  WidgetDictionary,
} from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomSuccessAlert } from '../../../uikit/SuccessAlert';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';
import { SourceReplaceModal } from '../source-replace-modal';

interface SelectFieldsPanelProps {
  sourceObjectId: string;
  data: MapConnection.Data;
  loaderId: number;
  stepId: string;
  dictFileTypes: string[];
}

const SelectFieldsPanel = ({
  sourceObjectId,
  data,
  loaderId,
  dictFileTypes,
}: SelectFieldsPanelProps) => {
  const b = block('select-fields-panel');
  const dispatch = useDispatch();
  const { showNotification } = useNotificator();
  const isSaving = useSelector(
    (state: State) => state.selectFieldsPanel.isSaving,
  );
  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );

  const [joinKeyNames, setJoinKeyNames] = useState<string[]>([]);
  useEffect(() => {
    const getJoinKeyNames = () => {
      const joinOutputObject = data.steps
        .filter((x) => x.operation.type === 'JOIN')
        .find((x) => x.outputSourceObjectId === sourceObjectId);
      const leftFields: MapConnection.Field[] = joinOutputObject
        ? joinOutputObject.fieldsMapping.map((x) => x.sourceFieldName)
        : [];
      const rightFields: MapConnection.Field[] = joinOutputObject
        ? joinOutputObject.fieldsMapping.map((x) => x.targetField)
        : [];
      const joinKeys: MapConnection.Field[] = [...leftFields, ...rightFields];
      const keyNames = joinKeys.map((x) => {
        return `${
          data.sourceObjects.find((el) => el.id === x.sourceObjectId)?.name
        }.${x.fieldName}`;
      });
      return keyNames;
    };
    setJoinKeyNames(getJoinKeyNames());
  }, [data.sourceObjects, data.steps, sourceObjectId]);

  useEffect(() => {
    return () => {
      dispatch(toggleShowSelectFieldsPanelAction(false));
    };
  }, [dispatch]);


  const sourceObject = data.sourceObjects.find(
    (el) => el.id === sourceObjectId,
  );

  const [sourceObjectScript, setSourceObjectScript] = useState<string | null>(sourceObject?.script || null);

  const initialSourceObjectName = sourceObject?.name;
  const initialSourceObjectProcessedFields = sourceObject?.processedFields;
  const initialNeedForWidget = sourceObject?.needForWidget;
  const initialNeedCache = sourceObject?.needCache;
  const initialNeedPushDown = sourceObject?.needPushDown;
  const initialFilter = sourceObject?.whereCondition;
  const sysName = sourceObject?.initialName;
  const fileType: string = sourceObject?.fileType || '';

  const currentStep = useMemo<MapConnection.Step | object>(() => {
    const result = data.steps.find((step) =>
      step.inputSourceObjectsIds.includes(sourceObjectId),
    );
    if (!result) return {};
    return result;
  }, [data, sourceObjectId]);

  const initialNeedRemoveDuplicates = sourceObject
    ? sourceObject.needRemoveDuplicates
    : false;
  const initialNeedRemoveEmptyStrings = sourceObject
    ? sourceObject.needRemoveEmptyStrings
    : false;
  const initialNeedCheckSchema = sourceObject
    ? sourceObject.needCheckSchema
    : true;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [sourceObjectName, setSourceObjectName] = useState<any>(
    initialSourceObjectName,
  );
  const [
    sourceObjectProcessedFields,
    setSourceObjectProcessedFields,
  ] = useState<any>(initialSourceObjectProcessedFields);

  useEffect(() => {
    setSourceObjectProcessedFields(initialSourceObjectProcessedFields);
  }, [initialSourceObjectProcessedFields]);

  const [constructorOrSqlMode, setConstructorOrSqlMode] = useState<Modes>(
    Modes.CONSTRUCTOR,
  );
  const [
    onlyCheckedFilterSetting,
    setOnlyCheckedFilterSetting,
  ] = useState<string>('all-fields');
  const [filter, setFilter] = useState<string>('');
  const [
    currentCalculatedIndicatorField,
    setCurrentCalculatedIndicatorField,
  ] = useState<any>(null);
  const [currentTransformationField, setCurrentTransformationField] = useState<
  string | null
  >(null);
  const [showFiltrationPanel, setShowFiltrationPanel] = useState<boolean>(
    false,
  );
  const [textFilter, setTextFilter] = useState<any>(initialFilter);
  const [
    showSuccessValidationPanel,
    setShowSuccessValidationPanel,
  ] = useState<boolean>(false);
  const [failValidationError, setFailValidationError] = useState<string>('');
  const [rangeError, setRangeError] = useState<string>('');

  const [needCheckSchema, setNeedCheckSchema] = useState<boolean>(
    initialNeedCheckSchema,
  );
  const [needForWidget, setNeedForWidget] = useState<boolean>(
    Boolean(initialNeedForWidget),
  );
  const [needCache, setNeedCache] = useState<boolean>(
    Boolean(initialNeedCache),
  );
  const [needPushDown, setNeedPushDown] = useState<boolean>(
    Boolean(initialNeedPushDown),
  );
  const [needRemoveDuplicates, setNeedRemoveDuplicates] = useState<boolean>(
    initialNeedRemoveDuplicates,
  );
  const [needRemoveEmptyStrings, setNeedRemoveEmptyStrings] = useState<boolean>(
    initialNeedRemoveEmptyStrings,
  );
  const [
    calculatedSchemaWidgetsList,
    setCalculatedSchemaWidgetsList,
  ] = useState<any[]>([]);
  const [
    calculatedSchemaDeleteErrorText,
    setCalculatedSchemaDeleteErrorText,
  ] = useState<string>('');

  const [
    calculatedFieldWidgetsList,
    setCalculatedFieldWidgetsList
  ] = useState<any[]>([]);
  const [
    currentCalculatedFieldId,
    setCurrentCalculatedFieldId,
  ] = useState<string>('');
  const [
    calculatedFieldDeleteErrorText,
    setCalculatedFieldDeleteErrorText,
  ] = useState<string>('');

  const [
    isOpenSourceReplaceModal,
    setIsOpenSourceReplaceModal,
  ] = useState<boolean>(false);

  const [
    wrongAlias,
    setWrongAlias,
  ] = useState<string[]>([]);

  const getCalculatedFieldDeleteErrorText = (widgetsList: any[]) => {
    return `Данный показатель используют следующие ${WidgetDictionary.few}: ${widgetsList.join(
      ', ',
    )}`;
  };

  const onDeleteSourceField = (sourceFieldId: string) => () => {
    setCurrentCalculatedFieldId(sourceFieldId);
    setCalculatedFieldDeleteErrorText('');

    apiGetWidgetsListBySourceAndField({
      source: sourceObjectId,
      field: sourceFieldId,
    })
      .then((result) => {
        setCalculatedFieldWidgetsList(result);
        if (!result.length) {
          deleteSourceField(sourceFieldId);
        } else {
          setCalculatedFieldDeleteErrorText(
            getCalculatedFieldDeleteErrorText(result),
          );
        }
      })
      .catch(() => {
        setCalculatedFieldWidgetsList([]);
        // here we can add handle error logic
      });
  };

  const deleteSourceField = (fieldId: string) => {
    const updatedSourceObjectProcessedFields = sourceObjectProcessedFields.filter(
      (field: any) => field.id !== fieldId,
    );
    setSourceObjectProcessedFields(updatedSourceObjectProcessedFields);
  };

  const addNewProcessedField = (newProcessedField: any) => {
    setSourceObjectProcessedFields([
      newProcessedField,
      ...sourceObjectProcessedFields,
    ]);
  };

  const addNewTextFilter = (newTextFilter: any) => {
    setTextFilter(newTextFilter);
  };

  const editProcessedFields = (editedProcessedField: any) => {
    const newSourceObjectProcessedFields = sourceObjectProcessedFields.map(
      (field: any) =>
        field.id === editedProcessedField.id ? editedProcessedField : field,
    );
    setSourceObjectProcessedFields([...newSourceObjectProcessedFields]);
  };

  const tickCheckSchemaNeed = () => {
    setNeedCheckSchema(!needCheckSchema);
  };

  const tickNeedForWidget = () => {
    setNeedForWidget(!needForWidget);
  };

  const tickNeedCache = () => {
    setNeedCache(!needCache);
  };

  const tickNeedPushDown = () => {
    setNeedPushDown(!needPushDown);
  };

  const tickDuplicateLinesHiding = () => {
    setNeedRemoveDuplicates(!needRemoveDuplicates);
  };

  const tickEmptyLinesHiding = () => {
    setNeedRemoveEmptyStrings(!needRemoveEmptyStrings);
  };

  const sourceOptions: SourceOption[] = compact([
    {
      value: needForWidget,
      handler: tickNeedForWidget,
      disabled: !isEditableMap,
      label: `Источник для ${WidgetDictionary.few}`,
    },
    {
      value: needCache,
      handler: tickNeedCache,
      disabled: !isEditableMap,
      label: 'Сохранить в кэш',
    },
    {
      value: needPushDown,
      handler: tickNeedPushDown,
      disabled: !isEditableMap || !sourceObject?.singleSource,
      label: 'Прямой запрос',
    },
    {
      value: needRemoveDuplicates,
      handler: tickDuplicateLinesHiding,
      disabled: !isEditableMap,
      label: 'Убрать дублирующие значения',
    },
    {
      value: needRemoveEmptyStrings,
      handler: tickEmptyLinesHiding,
      disabled: !isEditableMap,
      label: 'Исключать пустые строки',
    },
  ]);

  const handlerChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => setFilter(target.value.toLowerCase()),
    [],
  );

  const handlerOnlyCheckedFilterSetting = (value: string) => {
    value && setOnlyCheckedFilterSetting(value);
  };

  const handleToggleConstructorOrSqlMode = (value: Modes) => {
    value && setConstructorOrSqlMode(value);
  };

  const filteredByTextFilterFieldsList = sourceObjectProcessedFields.filter(
    ({ displayedName, alias }: any) => {
      return !filter || (alias || displayedName).toLowerCase().indexOf(filter) !== -1;
    },
  );

  const filteredSourceObjectFieldsList = filteredByTextFilterFieldsList.filter(
    ({ selected }: any) =>
      onlyCheckedFilterSetting === 'all-fields' ? true : selected,
  ).map((field: any) => ({ ...field, hasError: wrongAlias.includes(field.alias) }));

  const isTextFilterEmpty =
    textFilter?.template === '' && textFilter?.operands?.length === 0;

  const handleTick = (id: string) => () => {
    const newState = sourceObjectProcessedFields.map((field: any) => {
      if (field.id === id) {
        return {
          ...field,
          selected: !field.selected,
        };
      }
      return field;
    });
    setSourceObjectProcessedFields(newState);
  };

  const handleRename = (id: string) => (name: string) => {
    setWrongAlias([]);
    let replaceString = '';
    let replacerString = '';
    let replacerFieldId = '';
    let newState = sourceObjectProcessedFields.map((field: any) => {
      if (field.id === id) {
        replaceString = field.alias || field.displayedName;
        replacerFieldId = field.id;
        const result = {
          ...field,
          alias: name.trim(),
        };
        replacerString = result.alias || result.displayedName;
        return result;
      }
      return field;
    });

    newState = newState.map((field: any) => {
      if (field.transformationOperands.includes(replacerFieldId)) {
        const displayedName =
          replaceAll(field.displayedName, replaceString, replacerString).trim();
        return {
          ...field,
          displayedName,
        };
      }
      return field;
    });

    setSourceObjectProcessedFields(newState);
  };

  const uncheckField = (item: string) => () => {
    const newState = sourceObjectProcessedFields.map((field: any) => {
      if (field.displayedName === item) {
        return {
          ...field,
          selected: false,
        };
      }
      return field;
    });
    setSourceObjectProcessedFields(newState);
  };

  const tickAllFilteredDefiniteValue = (value: boolean) => {
    const filteredSourceObjectFieldsListNames = filteredSourceObjectFieldsList.map(
      ({ displayedName }: any) => displayedName,
    );
    const newState = sourceObjectProcessedFields.map((field: any) => {
      if (filteredSourceObjectFieldsListNames.includes(field.displayedName)) {
        return {
          ...field,
          selected: value,
        };
      }
      return field;
    });
    setSourceObjectProcessedFields(newState);
  };

  const checkAllFiltered = () => {
    tickAllFilteredDefiniteValue(true);
  };

  const uncheckAllFiltered = () => {
    tickAllFilteredDefiniteValue(false);
  };

  const closeSelectFieldsPanel = useCallback(() => {
    dispatch(toggleShowSelectFieldsPanelAction(false));
    dispatch(changeCurrentSourceIdAction(''));
  }, [dispatch]);

  const handleCloseReplaceModal = useCallback((isNeedToClosePanel = false) => {
    setIsOpenSourceReplaceModal(false);
    isNeedToClosePanel && closeSelectFieldsPanel();
  }, [closeSelectFieldsPanel]);

  const closeSuccessValidationPanel = () => {
    setShowSuccessValidationPanel(false);
  };

  const closeFailValidationPanel = () => {
    setFailValidationError('');
  };

  const closeRangeErrorPanel = () => {
    setRangeError('');
  };

  const onCloseCalculatedFieldModal = useCallback(() => {
    setCalculatedFieldWidgetsList([]);
  }, []);

  const closeSchemaWidgetsErrorPanel = () => {
    setCalculatedSchemaWidgetsList([]);
  };

  const onDeleteCalculatedFieldModal = useCallback(() => {
    onCloseCalculatedFieldModal();
    deleteSourceField(currentCalculatedFieldId);
  }, [currentCalculatedFieldId]);

  const onValidateAndSave = (callPurpose: string) => () => {

    if (!sourceObject) return;

    dispatch(toggleSavingSelectFieldsAction(true));
    setWrongAlias([]);

    const aliases: string[] = sourceObjectProcessedFields
      .map((field: any) => field.alias)
      .filter(Boolean);

    apiCheckSourceObjectAliases(sourceObject.id, aliases)
      .then(() => {
        const validationPassedCallback = (message?: string) => {
          if (callPurpose === 'save') {
            closeSelectFieldsPanel();
            showNotification({
              message: 'Сохранено',
              variant: 'success'
            });
          } else {
            showNotification({
              message: message || 'Ошибка, обратитесь к администратору',
              variant: 'error'
            });
            setShowSuccessValidationPanel(true);
          }
          dispatch(toggleSavingSelectFieldsAction(false));
        };
        const validationNotPassedCallback = (error: string) => {
          setFailValidationError(error);
          dispatch(toggleSavingSelectFieldsAction(false));
        };

        const { fieldsMapping } = currentStep as MapConnection.Step;
        const stepId = (currentStep as MapConnection.Step).id;

        dispatch(
          mapConnectionAction.validateAndSaveMapWithUpdatedProcessedFields(
            {
              loaderId,
              sourceObjectId,
              stepId,
              sourceObjectName,
              processedFields: sourceObjectProcessedFields,
              schema: null,
              stepIdsData: getStepIdsData(sourceObject, data.steps, data.sourceObjects),
              fieldsMapping,
              needRemoveDuplicates,
              needRemoveEmptyStrings,
              needCheckSchema,
              whereCondition: getWhereCondition(textFilter, sourceObjectProcessedFields),
              needCache,
              needForWidget,
              singleSource: sourceObject?.singleSource || null,
              needPushDown: sourceObject?.singleSource ? needPushDown : false,
            },
            validationPassedCallback,
            validationNotPassedCallback,
            callPurpose,
            true,
          ),
        );
      })
      .catch((error) => {
        dispatch(toggleSavingSelectFieldsAction(false));

        const message = error?.response?.data?.message;
        if (!message) return;
        const aliasError = message.split('\n').slice(1).map((alias: string) => alias.trim().replace(/^,|,$/g, ''));
        setWrongAlias(aliasError);
      });
  };

  const onValidate = onValidateAndSave('validate');

  const onSave = onValidateAndSave('save');

  const onCancel = () => {
    closeSelectFieldsPanel();
  };

  const getCalculatedSchemaDeleteErrorText = (widgetsList: any[]) => {
    return `Обновление схемы повлияло на следующие ${WidgetDictionary.widgets}: ${widgetsList.join(
      ', ',
    )}`;
  };
  const reloadScheme = async () => {
    try {
      setLoading(true);
      const widgetsList = (await apiUpdateSchemeBySourceObjectGetWidgets(
        sourceObjectId,
      )) as String[];
      setCalculatedSchemaWidgetsList(widgetsList);
      if (widgetsList.length) {
        setCalculatedSchemaDeleteErrorText(
          getCalculatedSchemaDeleteErrorText(widgetsList),
        );
      }
      const reloadedData = (await apiGetMapConnection(
        loaderId,
      )) as MapConnection.Data;
      if (reloadedData) {
        dispatch(
          mapConnectionAction.addConnection({ loaderId, data: reloadedData }),
        );
        const reloadedSourceObject = reloadedData?.sourceObjects.find(
          (el) => el.id === sourceObjectId,
        );
        const reloadedObjectFields = reloadedSourceObject?.processedFields;
        setSourceObjectProcessedFields(reloadedObjectFields);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      throw err;
    }
  };

  const openShowCalculatedIndicatorPanel = () => {
    setCurrentCalculatedIndicatorField('new-indicator');
  };

  const replaceSource = () => {
    setIsOpenSourceReplaceModal(true);
  };

  const openTransformationPanel = (id: any) => () => {
    const sourceField = sourceObjectProcessedFields.find(
      (el: any) => el.id === id,
    );
    setCurrentTransformationField(sourceField);
  };

  const openFiltrationPanel = () => {
    setShowFiltrationPanel(true);
  };

  const editComplexField = (id: any) => () => {
    const sourceField = sourceObjectProcessedFields.find(
      (el: any) => el.id === id,
    );
    if (sourceField.category === 'TRANSFORMATION') {
      setCurrentTransformationField(sourceField);
    }
    if (sourceField.category === 'CALCULATED') {
      setCurrentCalculatedIndicatorField(sourceField);
    }
  };

  const closeTransformationPanel = () => {
    setCurrentTransformationField(null);
  };

  const closeFiltrationPanel = () => {
    setShowFiltrationPanel(false);
  };

  const closeShowCalculatedIndicatorPanel = () => {
    setCurrentCalculatedIndicatorField(null);
  };

  const changeTitle = (newTitle: string) => {
    setSourceObjectName(newTitle);
  };

  const isEditableSql = Boolean(sourceObject?.script);

  useEffect(() => {
    if (sourceObject?.singleConnectionId && !isEditableSql) {
      apiGetSqlScript(sourceObjectId)
        .then((res) => {
          setSourceObjectScript(res);
        });
    }
  }, [sourceObjectId]);

  useEffect(() => {
    if (isEditableSql) {
      setSourceObjectScript(sourceObject?.script || null);
    }
  }, [sourceObject?.script]);

  if (constructorOrSqlMode === Modes.SQL) {
    return (
      <div className={b()}>
        <TitleSection
          sourceObjectName={sourceObjectName}
          sourceObject={sourceObject!}
          closeSelectFieldsPanel={closeSelectFieldsPanel}
          mode={constructorOrSqlMode}
          toggleMode={handleToggleConstructorOrSqlMode}
          sqlScript={sourceObjectScript}
          reloadScheme={reloadScheme}
          changeTitle={changeTitle}
          setRangeError={setRangeError}
          isEditableMap={isEditableMap}
        />
        <div className={b('sql-body')}>
          <SqlScriptForm
            isEditableMap={isEditableMap}
            loaderId={loaderId}
            onClose={() => setConstructorOrSqlMode(Modes.CONSTRUCTOR)}
            callback={() => setConstructorOrSqlMode(Modes.CONSTRUCTOR)}
            sourceId={sourceObject?.sourceId as number}
            script={sourceObjectScript}
            isEditableSql={isEditableSql}
          />
        </div>
      </div>
    );
  }

  const needToAddReloadScheme = sysName !== CommonDictionary.JoinResult &&
    sysName !== CommonDictionary.UnionResult;
  const isHideAllAddRemovePanel = onlyCheckedFilterSetting === 'only-checked';

  return (
    <div className={b()}>
      <TitleSection
        sourceObjectName={sourceObjectName}
        sourceObject={sourceObject!}
        closeSelectFieldsPanel={closeSelectFieldsPanel}
        mode={constructorOrSqlMode}
        toggleMode={handleToggleConstructorOrSqlMode}
        sqlScript={sourceObjectScript}
        changeTitle={changeTitle}
        setRangeError={setRangeError}
        reloadScheme={reloadScheme}
        isEditableMap={isEditableMap}
      />

      <FilterSection
        handlerChangeFilter={handlerChangeFilter}
        handlerOnlyCheckedFilterSetting={handlerOnlyCheckedFilterSetting}
        reloadScheme={needToAddReloadScheme ? reloadScheme : null}
        sqlScript={sourceObjectScript}
        sourceOptions={sourceOptions}
      />

      {!isHideAllAddRemovePanel && (
        <OptionsSection
          onAddAll={checkAllFiltered}
          onDeleteAll={uncheckAllFiltered}
          isEditableMap={isEditableMap}
        />
      )}

      <FieldsSection
        isEditableMap={isEditableMap}
        isLoading={isLoading}
        filteredSourceObjectFieldsList={filteredSourceObjectFieldsList}
        sourceObjectProcessedFields={sourceObjectProcessedFields}
        handleTick={handleTick}
        handleRename={handleRename}
        openTransformationPanel={openTransformationPanel}
        editComplexField={editComplexField}
        deleteSourceField={onDeleteSourceField}
        joinKeyNames={joinKeyNames}
      />

      <ButtonsSection
        isEditableMap={isEditableMap}
        isTextFilterEmpty={isTextFilterEmpty}
        onSave={onSave}
        onCancel={onCancel}
        openFiltrationPanel={openFiltrationPanel}
        openShowCalculatedIndicatorPanel={openShowCalculatedIndicatorPanel}
        replaceSource={replaceSource}
        needShowReplaceSourceButton={Boolean(sourceObject?.sourceId)}
      />

      <CustomDialog
        isOpen={showSuccessValidationPanel}
        onClose={closeSuccessValidationPanel}
        maxWidth="xs"
      >
        <div className="dialog-body">
          <CustomSuccessAlert title="Успешно">
            Валидация пройдена
          </CustomSuccessAlert>
        </div>
      </CustomDialog>

      <CustomDialog
        isOpen={Boolean(failValidationError)}
        onClose={closeFailValidationPanel}
        maxWidth="xs"
        title="Валидация таблицы"
      >
        <div>
          <div className="dialog-body">
            <CustomErrorAlert
              className={b('alert').toString()}
              title="Валидация не пройдена"
            >
              {failValidationError}
            </CustomErrorAlert>
          </div>
          <div className="dialog-buttons">
            <CustomButton variant="outlined" onClick={closeFailValidationPanel}>
              Закрыть
            </CustomButton>
          </div>
        </div>
      </CustomDialog>

      <CustomDialog
        isOpen={Boolean(rangeError)}
        onClose={closeRangeErrorPanel}
        maxWidth="xs"
      >
        <div className="dialog-body">
          <CustomErrorAlert title="Ошибка диапазона">
            {rangeError}
          </CustomErrorAlert>
        </div>
      </CustomDialog>

      <CustomDialog
        isOpen={Boolean(calculatedSchemaWidgetsList.length)}
        onClose={closeSchemaWidgetsErrorPanel}
        maxWidth="xs"
        title="Обновление схемы"
      >
        <div className="dialog-body">
          <CustomErrorAlert title="Внимание!">
            {calculatedSchemaDeleteErrorText}
          </CustomErrorAlert>
        </div>
      </CustomDialog>

      {!!currentTransformationField && (
        <TransformationPanel
          isEditableMap={isEditableMap}
          closeTransformationPanel={closeTransformationPanel}
          sourceField={currentTransformationField}
          uncheckField={uncheckField(currentTransformationField)}
          addNewProcessedField={addNewProcessedField}
          editProcessedFields={editProcessedFields}
          sourceObjectId={sourceObjectId}
          sourceObjectFields={sourceObjectProcessedFields}
          needPushDown={sourceObject?.singleSource ? needPushDown : false}
        />
      )}

      {!!currentCalculatedIndicatorField && (
        <CalculatedIndicatorPanel
          isEditableMap={isEditableMap}
          closeShowCalculatedIndicatorPanel={closeShowCalculatedIndicatorPanel}
          sourceField={currentCalculatedIndicatorField}
          sourceObjectFields={sourceObjectProcessedFields.filter(
            (field: any) => field.category === 'SCHEMA',
          )}
          addNewProcessedField={addNewProcessedField}
          editProcessedFields={editProcessedFields}
          sourceObjectId={sourceObjectId}
          needPushDown={sourceObject?.singleSource ? needPushDown : false}
        />
      )}

      {showFiltrationPanel && (
        <FiltrationPanel
          isEditableMap={isEditableMap}
          closeFiltrationPanel={closeFiltrationPanel}
          sourceObjectFields={sourceObjectProcessedFields.filter(
            (field: any) => field.category === 'SCHEMA',
          )}
          sourceObjectId={sourceObjectId}
          addNewTextFilter={addNewTextFilter}
          textFilter={textFilter}
        />
      )}

      {isSaving && <CommonLoader />}

      <DeleteDialog
        isOpen={Boolean(calculatedFieldWidgetsList.length)}
        onClose={onCloseCalculatedFieldModal}
        onDelete={onDeleteCalculatedFieldModal}
        title="Удаление показателя"
        bodyText="показатель"
        isLoading={isLoading}
        maxWidth="xs"
        error={calculatedFieldDeleteErrorText}
      />

      {isOpenSourceReplaceModal && (
        <SourceReplaceModal
          source={sourceObject as MapConnection.SourceObject}
          handleClose={handleCloseReplaceModal}
        />
      )}
    </div>
  );
};

export default SelectFieldsPanel;
