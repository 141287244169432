import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import hexToRgba from 'hex-to-rgba';
import { ComplexTableProps } from '../..';
import { PanelType, WidgetPropertyType } from '../../../../../../enums/widget-type';
import {
  TableHiddenMethod,
  useGetTableHiddenMethodWidgetProperty
} from '../../../../../../hooks/get-properties/useGetWidgetProperty';
import { dashboardWidgetLoadingSelector } from '../../../../../../slices/dashboard/dashboard';
import { State } from '../../../../../../slices/types';
import {
  getAxisValues,
  getParsedAxisValues,
} from '../../../../dropdown-layout/helpers/helpers';
import {
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  isDashboardPage,
} from '../../../helpers';
import { ComplexTableDndContext } from '../utils/table-dnd-provider';
import { mergeWidgetProperties } from '../../../../../../utils/functions';
import { DEFAULT_COMPLEX_TABLE_TITLE } from '../../ui/table-head';
import { PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { getPropertyValueNotParsed } from '../../../../../../helpers/dashboard-page';

export interface Header {
  id: string;
  title: string;
  parentID: string | null;
  accessor: string | null;
  width?: number | null;
  styles: {
    backgroundColor: string;
  }
}

export const useTableData = (props: ComplexTableProps) => {
  // т.к таблица основана на контексте, а useTableData может быть вызыван извне, (настройки виджета),
  // получаем widgetProps из стейта виджета для этих случаев.
  const widgetProps = useSelector((state: State) => state.widget);
  const widgetData = useSelector((state: State) => state.mainPage.currentProjectWidgetData);

  const dndState = useContext(ComplexTableDndContext);

  const isDashboard = isDashboardPage();

  const widgetState = useMemo(() => {
    if (isDashboard) {
      // На дашборде всегда данные есть внутри контекста, поэтому
      // нужно взять state.widgetProps, и заменить в нем необходимое из overridenProperties

      const newWidgetProps = { ...props.widgetProps };
      newWidgetProps.properties = mergeWidgetProperties(props.widgetProps.properties, props.dashboardWidgetState);

      return newWidgetProps || {};
    }

    // Если это не дашборд, то данные берем из стейта, т.к в конце разработки выяснилось что доступ к контексту может быть не всегда

    return {
      ...widgetProps,
      data: widgetData?.data
    };
  }, [isDashboard, props.dashboardWidgetState, props.widgetProps, widgetData, widgetProps]);

  const widgetProperties = widgetState.properties  || [];

  const isLoadingOnDashboard = useSelector(dashboardWidgetLoadingSelector(widgetState.id));

  const axisXValuesString = getAxisValues(PanelType.axisX, widgetProperties);
  const axisXValues: PropertyData[] = getParsedAxisValues(PanelType.axisX, widgetProperties);
  const tableNestedHeaders: Header[] = useMemo(
    () => getJSONPropertyValue('tableNestedHeaders', widgetProperties) || [],
    [widgetProperties],
  );
  const tableNestedLevelsCount: number = Number(
    getInputPropertyValue('nestedLevelsCount', widgetProperties) || 1,
  );

  const valueSize: number = Number(
    getInputPropertyValue('tableValueSize', widgetProperties) || 14,
  );

  const tableHiddenMethod = useGetTableHiddenMethodWidgetProperty(widgetProperties);
  const isBookmarks = tableHiddenMethod === TableHiddenMethod.bookmark;

  const hiddenColumnsJSON = getPropertyValueNotParsed(
    WidgetPropertyType.hiddenColumns,
    widgetProperties,
  );
  const hiddenColumns: string[] = useMemo(
    () => isBookmarks ? [JSON.parse(hiddenColumnsJSON || '[]')] : JSON.parse(hiddenColumnsJSON || '[]'),
    [isBookmarks, hiddenColumnsJSON],
  );

  const tableDndDestionationId: string | null = dndState.destinationId;
  const tableDndTargetId: string | null = dndState.targetId;

  const isStickyHeader: boolean = getBooleanPropertyValue(
    'fixedTableHeader',
    widgetProperties
  );

  const isNeedDisplayAggregationInLegend = getBooleanPropertyValue(
    'displayAggregationInHeader',
    widgetProperties,
  );

  const isEnabledResizing = getBooleanPropertyValue(
    'isEnabledResizing',
    widgetProperties,
  );

  const isScaleWidgetOnDashboard = getBooleanPropertyValue(
    'scaleWidgetOnDashboard',
    widgetProperties,
  );

  const headBackgroundColor: string = getInputPropertyValue(
    'colorHeader',
    widgetProperties,
  ) || '';

  const headTextColor: string = getInputPropertyValue(
    'colorTextHeader',
    widgetProperties,
  ) || '';

  const isColoringRows: boolean = getBooleanPropertyValue(
    'colorLineCheck',
    widgetProperties,
  );

  const isDisplayTooltip: boolean = getBooleanPropertyValue(
    'displayTooltip',
    widgetProperties,
  );

  const isNeedToDisplayTotal: boolean = useMemo(
    () => getBooleanPropertyValue('displayTotal', widgetProperties),
    [widgetProperties],
  );

  const hierarchyInHeader: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.hierarchyInHeader, widgetProperties),
    [widgetProperties],
  );

  const displaySorting: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displaySorting, widgetProperties),
    [widgetProperties],
  );

  const displaySortOrder: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displaySortOrder, widgetProperties),
    [widgetProperties],
  );

  const displayBorder: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displayBorder, widgetProperties),
    [widgetProperties],
  );

  const displayFilterHeader: boolean = getBooleanPropertyValue('displayFilterHeader', widgetProperties);

  const rowsColor = headBackgroundColor.length
    ? hexToRgba(headBackgroundColor, 0.35)
    : hexToRgba(DEFAULT_COMPLEX_TABLE_TITLE.backgroundColor, 0.6);

  const isLoadingData = isDashboard ? isLoadingOnDashboard : props.isLoadingInEditor;

  return {
    widgetProperties,
    widgetState,
    isStickyHeader,
    axisXValues,
    axisXValuesString,
    isNeedDisplayAggregationInLegend,
    isEnabledResizing,
    tableNestedHeaders,
    tableDndTargetId,
    tableDndDestionationId,
    tableNestedLevelsCount,
    isLoadingData,
    isScaleWidgetOnDashboard,
    headBackgroundColor,
    headTextColor,
    rowsColor,
    isColoringRows,
    displayFilterHeader,
    isDisplayTooltip,
    isNeedToDisplayTotal,
    hiddenColumns,
    hierarchyInHeader,
    displaySorting,
    displaySortOrder,
    displayBorder,
    valueSize
  };
};
