import { useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import { PanelItemPopoverProps } from './panel-item-popover';
import { State } from '../../../../../slices/types';
import {
  HierarchyLink,
  PropertyData,
  PropertyRestriction,
} from '../../../dropdown-layout/helpers/Property';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { PanelType, WidgetType } from '../../../../../enums/widget-type';
import { usePanelItemActions } from '../usePanelItemActions';
import {
  getAxisWithColoringSetting,
  getAxisWithRenameSetting,
} from '../../../helpers';
import { useAxisRenameSynchronization } from '../hooks/useAxisRenameSynchronization';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { widgetsWithDrilldownSetting } from '../../helpers';
import { CommonDictionary } from '../../../../../dictionaries/naming-dictionary/naming-dictionary';
import { PanelItemSetting } from '../../../../../enums/panel-item-settings';
import { isDisabledHierarchySetting } from '../../../../../helpers/widget-page';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

const widgetsWithRenameOptions = [
  WidgetType.HISTOGRAM,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.STACKED_HISTOGRAM,
  WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
  WidgetType.GRAPH,
  WidgetType.GRAPH_AREA,
  WidgetType.HISTOGRAM_GRAPH,
  WidgetType.RADAR_CHART,
  WidgetType.TABLE,
  WidgetType.INFORMATION_CARD,
  WidgetType.PIE_CHART,
  WidgetType.DONUT_CHART,
  WidgetType.TREE_MAP,
  WidgetType.BUBBLE_CHART,
  WidgetType.PIVOT_TABLE,
];

const widgetsWithHierarchyOptions = [WidgetType.PIVOT_TABLE];

const widgetsWithLinkOptions = [WidgetType.INFORMATION_CARD];

const panelsWithViewOptions = ['axisX'];
const panelsWithDrilldownOptions = ['axisX'];
const panelsWithHierarchyOptions = ['axisX'];
const panelsWithCombineRowOptions = ['axisX'];
const panelsWithLinkOptions = [PanelType.axisZ];

const widgetsWithCombineRowOptions = [WidgetType.TABLE];
const widgetsWithViewOptions = [WidgetType.TABLE];

export const widgetsWithColoringOptions = [
  WidgetType.TABLE,
  WidgetType.PIVOT_TABLE,
  WidgetType.HISTOGRAM,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.HISTOGRAM_GRAPH,
];

// export const widgetsWithFilterRestriction = [
//   WidgetType.HISTOGRAM,
//   WidgetType.HORIZONTAL_HISTOGRAM,
//   WidgetType.STACKED_HISTOGRAM,
//   WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
//   WidgetType.GRAPH,
//   WidgetType.GRAPH_AREA,
//   WidgetType.HISTOGRAM_GRAPH,
//   WidgetType.BUBBLE_CHART,
//   WidgetType.TABLE,
//   WidgetType.PIVOT_TABLE,
// ];

const isShowFilterRestriction = (widgetType: WidgetType, panelType: PanelType) => {
  // 1 из 2: отключено в задаче BIDEV-7059, если все ок, удалить комментарии из коммита
  return panelType === PanelType.axisFilter; // && widgetsWithFilterRestriction.includes(widgetType);
};

export const usePanelItemPopover = ({
  functions,
  isShowRestriction,
  index,
  panel,
  propertyInstance,
  anchorEl,
  setAnchorEl,
}: PanelItemPopoverProps) => {
  const [openSettingModalType, setOpenSettingModalType] =
    useState<null | PanelItemSetting>(null);

  const widget = useSelector((state: State) => state.widget);
  const sourceFields: any[] =
    useSelector(
      (state: State) => state.mainPage?.currentProjectWidgetSourceFieldsData,
    ) ?? [];


  const axisValue: PropertyData[] = useMemo(
    () =>
      getParsedAxisValues(PanelType.axisX, widget.properties)
        .concat(getParsedAxisValues(PanelType.axisY, widget.properties))
        .concat(getParsedAxisValues(PanelType.axisZ, widget.properties)),
    [widget.properties],
  );
  const isOpenPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const { changeAggregationHandler } = usePanelItemActions(widget);

  const aggregationType = propertyInstance.getValue().aggregation;
  const functionType = propertyInstance.getValue().function;

  const handleClosePopover = useCallback((event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenModal = (
    event: React.MouseEvent<HTMLElement>,
    type: PanelItemSetting,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpenSettingModalType(type);
  };

  const handleCloseModal = () => {
    setOpenSettingModalType(null);
  };

  const panelsWithRenameOptions = getAxisWithRenameSetting(widget.type);
  const panelsWithColoringOptions = getAxisWithColoringSetting(widget.type);

  const isShowFunctionsPopover = () =>
    functions.functions?.length ||
    functions.aggregations?.length ||
    isShowRestriction;

  const isShowViewOptions = () =>
    (widgetsWithViewOptions.includes(widget.type) &&
    panelsWithViewOptions.includes(panel.name)) ||
    (widget.type === WidgetType.PIVOT_TABLE && panel.name === PanelType.axisY);

  const isShowDrilldownOptions = () =>
    widgetsWithDrilldownSetting.includes(widget.type) &&
    panelsWithDrilldownOptions.includes(panel.name);

  const isShowCombineRowOptions = () =>
    widgetsWithCombineRowOptions.includes(widget.type) &&
    panelsWithCombineRowOptions.includes(panel.name);

  const isShowRenameOptions = () =>
    widgetsWithRenameOptions.includes(widget.type) &&
    panelsWithRenameOptions.includes(panel.name);

  const isShowColoringOptions = () =>
    widgetsWithColoringOptions.includes(widget.type) &&
    panelsWithColoringOptions.includes(panel.name) &&
    !propertyInstance.isLagOrLead();

  const isShowHierarchyOptions = () =>
    widgetsWithHierarchyOptions.includes(widget.type) &&
    panelsWithHierarchyOptions.includes(panel.name);

  const isShowLinkOptions = () =>
    widgetsWithLinkOptions.includes(widget.type) &&
    panelsWithLinkOptions.includes(panel.name);

  const handleChangeAggregation = useCallback(
    (type: string, functionType: string) => (event: any) => {
      if (functionType === 'aggregations') {
        propertyInstance.changeAggregation(type);
      }

      if (functionType === 'functions') {
        propertyInstance.changeFunction(type);
      }

      event.stopPropagation();
      handleClosePopover(event);
      changeAggregationHandler(propertyInstance, panel.name, index);
    },
    [handleClosePopover, changeAggregationHandler, propertyInstance, panel.name, index],
  );

  const addNewRestriction = useCallback(
    (restriction: PropertyRestriction | null) => {
      propertyInstance.changeRestriction(restriction);
      changeAggregationHandler(propertyInstance, panel.name, index);
    },
    [changeAggregationHandler, index, panel.name, propertyInstance],
  );

  const changeViewOptions = useCallback(
    (storage: any) => {
      propertyInstance.changeStorage(storage);
      changeAggregationHandler(propertyInstance, panel.name, index);
    },
    [changeAggregationHandler, index, panel.name, propertyInstance],
  );

  const changeColoringOptions = useCallback(
    (coloring: any) => {
      propertyInstance.changeColoring(coloring);
      changeAggregationHandler(propertyInstance, panel.name, index, false);
    },
    [changeAggregationHandler, index, panel.name, propertyInstance],
  );

  const { axisRenameSynchronization } = useAxisRenameSynchronization();

  const changeRenameOptions = useCallback(
    (storage: string) => {
      propertyInstance.changeStorage(storage);
      changeAggregationHandler(propertyInstance, panel.name, index);
      axisRenameSynchronization(storage, propertyInstance.getClientUID());
    },
    [propertyInstance, changeAggregationHandler, panel.name, index, axisRenameSynchronization],
  );

  const changeDDOptions = useCallback(
    (isDrillDownFilter: boolean) => {
      propertyInstance.setIsDrilldownFilter(isDrillDownFilter);
      changeAggregationHandler(propertyInstance, panel.name, index);
    },
    [propertyInstance, changeAggregationHandler, panel.name, index],
  );

  const changeRestrictionFilterOption = useCallback(
    (isRestrictionFilter: boolean) => {
      propertyInstance.setIsRestrictionFilter(isRestrictionFilter);
      changeAggregationHandler(propertyInstance, panel.name, index, false);
    },
    [propertyInstance, changeAggregationHandler, panel.name, index],
  );

  const changeCombineOptions = useCallback(
    (isCombineValues: boolean) => {
      propertyInstance.setIsCombineValues(isCombineValues);
      changeAggregationHandler(propertyInstance, panel.name, index);
    },
    [propertyInstance, changeAggregationHandler, panel.name, index],
  );

  const changeHierarchy = useCallback(
    (hierarchyLink?: HierarchyLink) => {
      propertyInstance.setHierarchyLink(hierarchyLink);
      changeAggregationHandler(propertyInstance, panel.name, index);
    },
    [propertyInstance, changeAggregationHandler, panel.name, index],
  );

  const changeLink = useCallback(
    (link?: string) => {
      propertyInstance.setLink(link);
      changeAggregationHandler(propertyInstance, panel.name, index);
    },
    [propertyInstance, changeAggregationHandler, panel.name, index],
  );

  const functionsMenuList =
    functions.functions.length > 0
      ? functions.functions.map((item) => ({
          title: item?.description,
          onClick: handleChangeAggregation(item.formula, 'functions'),
          selected:
            item?.formula?.toLowerCase() === aggregationType?.toLowerCase() ||
            item?.formula?.toLowerCase() === functionType?.toLowerCase(),
        }))
      : null;

  const aggregationsMenuList =
    functions.aggregations.length > 0
      ? functions.aggregations.map((item) => ({
          title: item?.description,
          onClick: handleChangeAggregation(item.formula, 'aggregations'),
          selected:
            item?.formula?.toLowerCase() === aggregationType?.toLowerCase() ||
            item?.formula?.toLowerCase() === functionType?.toLowerCase(),
        }))
      : null;

  const menuList = [
    {
      title: 'Функции',
      icon: (
        <IconSvg
          svg={IconDictionary.FunctionSetting}
          fill="var(--dark-grey)"
        />
      ),
      disabled: false,
      isNeedDivider: false,
      isHidden: !functions.functions.length,
      children: functionsMenuList,
    },
    {
      title: 'Агрегации',
      icon: (
        <IconSvg
          svg={IconDictionary.AggregationSetting}
          fill="var(--dark-grey)"
        />
      ),
      disabled: false,
      isNeedDivider: false,
      isHidden: !functions.aggregations.length,
      children: aggregationsMenuList,
    },
    {
      title: 'Условия',
      icon: (
        <IconSvg
          svg={IconDictionary.PanelItemRestriction}
          fill="var(--dark-grey)"
        />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.restriction),
      disabled: false,
      isNeedDivider: false,
      isHidden: !isShowRestriction,
    },
    {
      title: 'Условия',
      icon: (
        <IconSvg
          svg={IconDictionary.PanelItemRestriction}
          fill="var(--dark-grey)"
        />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.filterRestriction),
      disabled: false,
      isNeedDivider: false,
      isHidden: !isShowFilterRestriction(widget.type, panel.name),
    },
    {
      title: 'Вид',
      icon: (
        <IconSvg
          svg={IconDictionary.ViewSetting}
          fill="var(--dark-grey)"
        />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.view),
      disabled: false,
      isNeedDivider: false,
      isHidden: !isShowViewOptions(),
    },
    {
      title:
        widget.type === WidgetType.PIVOT_TABLE ||
        widget.type === WidgetType.TABLE
          ? 'Раскраска ячеек'
          : 'Раскраска столбцов',
      icon: (
        <IconSvg
          svg={IconDictionary.ColoringSetting}
          fill="var(--dark-grey)"
        />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.coloring),
      disabled: false,
      isNeedDivider: false,
      isHidden: !isShowColoringOptions(),
    },
    {
      title: 'Переименовать на легенде',
      icon: (
        <IconSvg
          svg={IconDictionary.EditFile}
          fill="var(--dark-grey)"
        />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.rename),
      disabled: false,
      isNeedDivider: false,
      isHidden: !isShowRenameOptions(),
    },
    {
      title: `Настроить ${CommonDictionary.drilldown}`,
      icon: (
        <IconSvg svg={IconDictionary.DrilldownSetting} fill="var(--dark-grey)" />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.drilldown),
      disabled: false,
      isNeedDivider: false,
      isHidden: !isShowDrilldownOptions(),
    },
    {
      title: 'Объединить значения',
      icon: (
        <IconSvg svg={IconDictionary.DashboardDrilldownSetting} fill="var(--dark-grey)" />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.combine),
      disabled: false,
      isNeedDivider: false,
      isHidden: !isShowCombineRowOptions(),
    },
    {
      title: 'Настроить иерархию',
      icon: (
        <IconSvg svg={IconDictionary.HierarchySetting} fill="var(--dark-grey)" />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.hierarchy),
      disabled: isDisabledHierarchySetting(getParsedAxisValues(panel.name, widget.properties), index),
      isNeedDivider: false,
      isHidden: !isShowHierarchyOptions(),
    },
    {
      title: 'Гиперссылка',
      icon: (
        <IconSvg svg={IconDictionary.Link} fill="var(--dark-grey)" />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenModal(event, PanelItemSetting.link),
      isNeedDivider: false,
      isHidden: !isShowLinkOptions(),
    },
  ];

  return {
    isOpenPopover,
    handleClosePopover,
    sourceFields,
    addNewRestriction,
    changeViewOptions,
    changeColoringOptions,
    axisValue,
    changeRenameOptions,
    menuList,
    isShowFunctionsPopover,
    widgetType: widget.type,
    changeDDOptions,
    changeRestrictionFilterOption,
    changeCombineOptions,
    openSettingModalType,
    handleCloseModal,
    changeHierarchy,
    changeLink,
  };
};
