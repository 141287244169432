import React, { useCallback, useEffect, useState } from 'react';
import './dashboard-settings-print.css';
import { useEventListener } from '../../../../hooks/useEventListener';
import { getRootElement } from '../../../../utils/root-helper';
import { useDashboardSettings } from '../hooks/useDashboardSettings';

const printHeight = 1083;

interface SettingsPrintProps {
  gridLayoutContainer: React.MutableRefObject<HTMLDivElement>,
}

export const DashboardSettingsPrint: React.FC<SettingsPrintProps> = ({ gridLayoutContainer }) => {
  const [gridContainerHeight, setGridContainerHeight] = useState<number>(
    gridLayoutContainer.current.scrollHeight,
  );

  const [needToHideBorders, setNeedToHideBorders] = useState<boolean>(
    false,
  );

  const renderTimes = Math.floor(gridContainerHeight / printHeight);
  const { showPrintBorders } = useDashboardSettings();
  const listOfRenderTimes: number[] = [...Array(renderTimes)];

  const resizeGridContainer = useCallback(() => {
    // We need to calculate height of the container without ruler borders inside
    setNeedToHideBorders(true);
    setTimeout(() => {
      setGridContainerHeight(gridLayoutContainer?.current?.scrollHeight);
      setNeedToHideBorders(false);
    });
  }, []);

  useEventListener('resize', resizeGridContainer);

  return (
    <>{showPrintBorders
      ? listOfRenderTimes.map((e, i) => <div
          style={{ top: `${(i + 1) * printHeight }px`, display: needToHideBorders ? 'none' : 'inherit' }}
          className="dashboard-setting-border"
          key={i}
      />)
      : null}
    </>
  );
};
