import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, keys, uniq } from 'lodash';
import './styles.css';
import { CircularProgress } from '@material-ui/core';
import { BoundFilterGroupProps, ParticipantFilterWidget } from '../../hooks';
import {
  closeDashboardFiltersSettingAction,
  dashboardWidgetsSelector,
  setWidgetAPIToDashboard,
} from '../../../../slices/dashboard/dashboard';
import { ConnectionGroup } from './connectionGroup';
import {
  EXCLUDE_DRILLDOWN_WIDGET_TYPES,
} from './useConnectionGroup';
import { State, Widget } from '../../../../slices/types';
import { useCheckingCycleInDrilldown } from '../hooks/useСheckingСycleInDrilldown';
import { BoundPlaceholder } from '../common/bound-placeholder';
import { BoundType, settingTabs } from '../enums';
import { BoundTypeSwitcher } from '../common/bound-type-switcher/bound-type-switcher';
import { BoundContentTitle } from '../common/bound-content-title';
import { DashboardSettingContainer } from '../common/dashboard-setting-container';
import { apiGetWidgetByIds } from '../../../../services/widgetController';
import { useDashboardManualFilters } from '../../hooks/useDashboardManualFilters';
import { WidgetDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../../uikit/Progress';

const getBoundType = (tab: settingTabs) =>
  tab === settingTabs.auto
    ? BoundType.autoDrillDownGroups
    : BoundType.manualDrillDownGroups;

const FiltersDrilldownSetting = memo(() => {

  const dashboardProperties = useSelector(
    (state: State) => state.dashboard.properties,
  );

  const currentProjectWidgets = useSelector(
    (state: State) => state.dashboard.widgets,
  );

  const currentTabDefault: settingTabs = JSON.parse(dashboardProperties?.find(
    (param) => param.name === BoundType.manualDrillDownGroups
  )?.value as string || '[]')?.length > 0
    ? settingTabs.manual : settingTabs.auto;

  const [currentTab, setCurrentTab] = useState<settingTabs>(currentTabDefault);
  const [isWidgetLoading, setWidgetLoading] = useState(true);

  const {
    drilldownValues,
    widgetsIds,
    dashboardId,
    addEmptyGroup,
    changeMasterWidget,
    changeConnectedWidget,
    deleteGroup,
    getAllConnectedWidget,
    getAllMasterWidget,
    clearDrillDownGroups,
  } = useDashboardManualFilters(getBoundType(currentTab));

  const { inactiveWidgetsForDD } = useCheckingCycleInDrilldown(drilldownValues);

  const widgets = useSelector(dashboardWidgetsSelector);

  const addConnection = () => {
    addEmptyGroup();
  };

  const dispatch = useDispatch();

  const projectWidgetsList: Widget[] | [] = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? [],
  );

  const onChangeMasterWidget = useCallback(
    (groupIndex: number) => (value: number, layout?: number) => {
      changeMasterWidget(groupIndex, value, layout);
    },
    [drilldownValues, dashboardId],
  );

  const onChangeConnectedWidget = useCallback(
    (groupIndex: number) => (e: any) => {
      changeConnectedWidget(groupIndex, e.target.value);
    },
    [drilldownValues, dashboardId],
  );

  const closeHandler = () => {
    dispatch(closeDashboardFiltersSettingAction());
  };

  // догружаем недостающие виджеты
  useEffect(() => {
    // получаем список всех еще не загруженых мастер виджетов
    const allMasterWidget = getAllMasterWidget()
      .map((widget) => widget.widgetId || 0)
      .filter((id) => !keys(currentProjectWidgets).includes(String(id)) && id > 0);

    if(allMasterWidget.length) {
      apiGetWidgetByIds(allMasterWidget).then((results) => {
        results.forEach((result) => {
          dispatch(
            setWidgetAPIToDashboard({
              widgetId: result.id,
              params: {
                data: result,
              },
            }),
          );
        });
      }).finally(() => setWidgetLoading(false));
    } else {
      setWidgetLoading(false);
    }
  }, []);

  const allConnectedWidgets = getAllConnectedWidget();
  const connectedWidgetNotOnDashboard = allConnectedWidgets.filter(
    (participant: ParticipantFilterWidget) =>
      !widgetsIds.includes(participant.widgetId),
  );

  const mappedConnectedWidtgetsNotOnDashboard = connectedWidgetNotOnDashboard.map(
    ({ widgetId }) => {
      return projectWidgetsList.find(({ id }) => id === widgetId) || {} as Widget;
    },
  );

  const allDrillDownWidgetsIds = drilldownValues.reduce((accum: number[], current: BoundFilterGroupProps) => {
    const widgetIds = current.participantFilterWidgets
      .filter(widget => widget.isActive && !widget.isMaster)
      .map(widget => widget.widgetId);
    return uniq([...accum, ...widgetIds]);
  }, []);

  const freeWidgets = [
    ...mappedConnectedWidtgetsNotOnDashboard,
    ...widgets,
  ].filter(({ id, type }) => {
    if (isUndefined(id)) return false;
    if (EXCLUDE_DRILLDOWN_WIDGET_TYPES.includes(type)) return false;

    for (const group of drilldownValues) {
      if (!group.participantFilterWidgets) return true;
      for (const participant of group?.participantFilterWidgets) {
        if (participant.isMaster && +participant.widgetId === +id) {
          return false;
        }
      }
    }

    return true;
  });

  const isCanAddConnection =
    projectWidgetsList?.length > 1 &&
    widgets?.length > 0 &&
    !!freeWidgets.length;

  const handleSwitchCurrentTab = (tab: settingTabs) => {
    clearDrillDownGroups();
    setCurrentTab(tab);
  };

  return (
    <DashboardSettingContainer
      title={`Настройка связанных ${WidgetDictionary.many}`}
      handlerClose={closeHandler}
    >
      {isWidgetLoading ? (
        <div className="filters-chaining-list__container-progress">
          <CustomProgress type="circular" />
        </div>
      ) : (
        <>
          <BoundTypeSwitcher
            type="radio"
            currentTab={currentTab}
            handleSwitchCurrentTab={handleSwitchCurrentTab}
            enableVerification={Boolean(drilldownValues.length)}
          />

          <BoundContentTitle isCanAddBound={isCanAddConnection} handleAddBound={addConnection} />

          <div className="filters-chaining-list__container">
            {(drilldownValues.length ? (
              <div className="filters-drilldown-list__container">
                {drilldownValues?.map((group, index: number) => (
                  <ConnectionGroup
                    key={(group?.id || 0) + index}
                    drillDownGroup={group}
                    widgets={widgets}
                    freeWidgets={freeWidgets}
                    onChangeMasterWidget={onChangeMasterWidget(index)}
                    onChangeConnectedWidget={onChangeConnectedWidget(index)}
                    onDeleteGroup={() => deleteGroup(index)}
                    allDrillDownWidgetsIds={allDrillDownWidgetsIds}
                    inactiveWidgetsForDD={inactiveWidgetsForDD}
                    boundType={getBoundType(currentTab)}
                  />
                ))}
              </div>
            ) : (
              <BoundPlaceholder isCanAddConnection={isCanAddConnection} />
            ))}
          </div>
        </>)}
    </DashboardSettingContainer>
  );
});

FiltersDrilldownSetting.displayName = 'FiltersDrilldownSetting';

export default FiltersDrilldownSetting;
