import React, { memo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { FilterGroupProps, WidgetFiltersProps } from '../../../hooks';
import { useDashboardFilters } from '../../../hooks/useDashboardFilters';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { getItemStyle } from './helper';
import { ListItemHead } from './list-item-head';
import {
  IconButtonType,
  CustomIconButton,
} from '../../../../../uikit/IconButton';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../common/icon-svg/icon-svg';

interface DraggableItemData {
  group: FilterGroupProps;
  item: WidgetFiltersProps;
}

interface FilterListItemProps {
  group: FilterGroupProps;
  draggableItemData: DraggableItemData;
}

const FilterListItem: React.FC<FilterListItemProps> = memo(({
  group,
  draggableItemData,
}) => {
  const {
    toggleActualFilter,
  } = useDashboardFilters();

  const isAllowedToDrop =
    draggableItemData.group?.type === group.type &&
    draggableItemData.group?.name !== group.name;

  return (
    <Droppable droppableId={JSON.stringify(group)} mode="standard" type="ITEM">
      {(provided) => (
        <div
          className={clsx('filter-list-item', { 'filter-list-droppable': isAllowedToDrop })}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <ListItemHead group={group} />

          <div className="filter-list-item__main">
            {group.widgetFilters?.filter((filter) => !filter.isArchived)?.map((filter, i) => (
              <Draggable
                key={filter.id}
                draggableId={JSON.stringify({ item: filter, group })}
                index={i + group.id}
              >
                {(provided, snapshot) => (
                  <div
                    className="filter-group-item__container"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <CustomFormControl
                      control={
                        <CustomCheckbox
                          checked={filter.actual}
                          className={`${group.actual && Boolean(group.widgetFilters?.length) ? '' : 'filter-list-item__main-group-unchecked'}`}
                          color={`${group.actual && Boolean(group.widgetFilters?.length) ? 'primary' : 'default'}`}
                          onChange={() =>
                            toggleActualFilter(group.id, filter.id)}
                        />
                      }
                      label={`${filter.widgetName}.${filter.name}`}
                    />
                    <CustomIconButton
                      iconButtonProps={{
                        style: { marginLeft: 'auto' },
                        children: <IconSvg fill="var(--dark-grey)" svg={IconDictionary.VerticalDragAndDrop} />,
                      }}
                      type={IconButtonType.withoutEffects}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});

FilterListItem.displayName = 'FilterListItem';

export default FilterListItem;
