import React from 'react';
import { Button, MenuItem, TextField } from '@material-ui/core';
import block from 'bem-cn';
import './operation-sub-type-section.css';
import { useClassButton } from '../../../../../hooks/useClassButton';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import MapConnection from '../../../../../types/map-connection';
import { numDecline } from '../../../../../utils/functions';
import { Operation } from '../../../../../enums/operation';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

enum Icon {
  DIRECT = 'StrategyDirect',
  HISTORY = 'StrategyHistory',
  REWRITE = 'StrategyRewrite',
  KEY = 'StrategyKey',
  QUESTION = 'Question',
  LEFT_JOIN = 'JoinLeft',
  RIGHT_JOIN = 'JoinRight',
  INNER_JOIN = 'JOIN',
  FULL_JOIN = 'UNION',
  UNION = 'UNION'
}

interface OperationSubTypeProps {
  data: MapConnection.Data;
  currentOperationSubType: any;
  operationSubTypes: any[];
  addAllSourceOptions: () => void;
  addAllTargetOptions: () => void;
  operationSubTypeChange: (e: any) => void;
  calculateSelectOptions: (fieldsMapping: any) => void;
  sourceObjectId: string;
  targetObjectId: string;
  isNewTarget: boolean;
  operationType: string;
  isEditableMap: boolean;
}

const b = block('operation-sub-type-section');

const OperationSubTypeSection: React.FC<OperationSubTypeProps> = ({
  data,
  operationSubTypes,
  addAllSourceOptions,
  addAllTargetOptions,
  currentOperationSubType,
  operationSubTypeChange,
  calculateSelectOptions,
  sourceObjectId,
  targetObjectId,
  isNewTarget,
  operationType,
  isEditableMap
}) => {
  const sourceName = data.sourceObjects.find((el) => el.id === sourceObjectId)
    ?.name;
  const targetName = data.sourceObjects.find((el) => el.id === targetObjectId)
    ?.name;
  const fullSelectOptionsList: any[string] = calculateSelectOptions([]);
  const sourceNamesLength = fullSelectOptionsList[sourceObjectId].length;
  const targetNamesLength = fullSelectOptionsList[targetObjectId].length;

  const littleButton = useClassButton({ height: '30px' });

  const getOperationSubTypePicture = (operationSubType: any) => {
    switch (operationSubType) {
      case 'LEFT_JOIN':
        return IconDictionary.LeftJoin;
      case 'RIGHT_JOIN':
        return IconDictionary.RightJoin;
      case 'FULL_JOIN':
        return IconDictionary.UnionOrFullJoin;
      case 'INNER_JOIN':
        return IconDictionary.Join;
      case 'UNION':
      case 'UNION_ALL':
        return IconDictionary.UnionOrFullJoin;
      default:
        return IconDictionary.UnknownOperation;
    }
  };

  return (
    <div className={b()}>
      <div className={b('left-part')}>
        <div className={b('left-part-icon')}>
          <IconSvg
            svg={IconDictionary.ArrowToRightTop}
            width={115}
            height={24}
            fill="var(--primary-color)"
            classSVG={b('arrow').toString()}
          />
        </div>
        <div className={b('info-block')}>
          <div className={b('tbl-name')}>{sourceName}</div>
          <div className={b('support-info')}>
            <span className={b('fields-ammount')}>
              {`${sourceNamesLength} ${numDecline(
                sourceNamesLength,
                'поле',
                'поля',
                'полей',
              )}`}
            </span>
            {isEditableMap ? <Button
              classes={{ startIcon: b('svg-pic') }}
              className={`${littleButton.nonBorder} ${b('add-all-button')}`}
              onClick={addAllSourceOptions}
              startIcon={
                <IconSvg svg={IconDictionary.ListAdd} fill="var(--primary-color)" />
              }
            >
              <span className={b('dotted')}>Добавить все</span>
            </Button> : null}
          </div>
        </div>
      </div>
      <div className={b('middle-part')}>
        <TextField
          className={b('operation-sub-type-field')}
          select
          value={currentOperationSubType}
          onChange={operationSubTypeChange}
          variant="outlined"
          InputProps={{
            classes: {
              root: `${b('operation-sub-type-input')}  ${b('white-bg')}`,
            },
          }}
        >
          {operationSubTypes.map((operationSubType) => (
            <MenuItem key={operationSubType.name} value={operationSubType.name}>
              <Button
                classes={{ startIcon: b('operation-sub-type-svg-pic') }}
                className={`${littleButton.nonBorder}`}
                startIcon={
                  <IconSvg
                    svg={
                      getOperationSubTypePicture(
                        operationSubType.name,
                      )
                    }
                    fill="var(--primary-color)"
                  />
                }
              >
                <span>{operationSubType.description}</span>
              </Button>
            </MenuItem>
          ))}
        </TextField>
      </div>

      <div className={b('right-part')}>
        <div className={b('right-part-icon')}>
          {operationType === Operation.Join || operationType === Operation.Union ? (
            <IconSvg
              svg={IconDictionary.ArrowToRightTop}
              width={150}
              height={24}
              fill="var(--primary-color)"
              classSVG={b('arrow').toString()}
            />
          ) : (
            <IconSvg
              svg={IconDictionary.ArrowToRightBottom}
              width={123}
              height={17}
              fill="var(--primary-color)"
              classSVG={b('arrow').toString()}
            />
          )}
        </div>
        <div className={b('info-block')}>
          <div className={b('tbl-name')}>{targetName}</div>
          <div className={b('support-info')}>
            {isNewTarget ? (
              <span className={b('new')}>Новая таблица</span>
            ) : (
              <div>
                <span className={b('fields-ammount')}>
                  {`${targetNamesLength} ${numDecline(
                    targetNamesLength,
                    'поле',
                    'поля',
                    'полей',
                  )}`}
                </span>
                {isEditableMap ? <Button
                  classes={{ startIcon: b('svg-pic') }}
                  className={`${littleButton.nonBorder} ${b('add-all-button')}`}
                  onClick={addAllTargetOptions}
                  startIcon={
                    <IconSvg
                      svg={IconDictionary.ListAdd}
                      fill="var(--primary-color)"
                    />
                  }
                >
                  <span className={b('dotted')}>Добавить все</span>
                </Button> : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationSubTypeSection;
