import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CssVariables } from '../../../enums/css-variables';
import { varcss } from '../../../helpers/ui-helper';
import { OverPanel } from '../../common/over-panel/over-panel';
import { FiltersContainer } from './container';
import './styles.css';
import { closeDashboardFiltersAction } from '../../../slices/dashboard/dashboard';
import { CustomButton } from '../../../uikit/Button';
import { useDashboardFilters } from '../hooks/useDashboardFilters';

export const DashboardFilters = memo(() => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: any) => state.dashboard.isOpenedFilters);
  const usableFilters = useDashboardFilters();

  const closeHandler = useCallback(() => {
    dispatch(closeDashboardFiltersAction());
  }, [dispatch]);

  const onResetFilters = useCallback(() => {
    usableFilters.resetFilters();
  }, [usableFilters]);

  if (!isOpened) return null;

  return (
    <OverPanel
      closeHandler={closeHandler}
      title="Настройка фильтров"
      footer={
        <CustomButton
          fullWidth
          onClick={onResetFilters}
          variant="outlined"
          className="dashboard-filters__drop-filters-btn"
          backgroundColor={varcss(CssVariables.MAIN_BACKDROP_COLOR)}
        >
          Сбросить все фильтры
        </CustomButton>
      }
    >
      <FiltersContainer />
    </OverPanel>
  );
});

DashboardFilters.displayName = 'DashboardFilters';
