import React, { memo, useCallback, useState } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, uniq } from 'lodash';
import FilterListItem from './list-item';
import { BoundFilterGroupProps, ParticipantFilterWidget } from '../../hooks';
import {
  closeDashboardFiltersSettingAction,
  dashboardWidgetsSelector,
} from '../../../../slices/dashboard/dashboard';
import { ConnectionGroup } from '../filters-drilldown-settings/connectionGroup';
import { State, Widget } from '../../../../slices/types';
import { WidgetType } from '../../../../enums/widget-type';
import { BoundPlaceholder } from '../common/bound-placeholder';
import { BoundType, settingTabs } from '../enums';
import { BoundTypeSwitcher } from '../common/bound-type-switcher/bound-type-switcher';
import { DashboardSettingContainer } from '../common/dashboard-setting-container';
import { BoundContentTitle } from '../common/bound-content-title';
import { useDashboardBoundFilters } from '../../hooks/useDashboardBoundFilters';
import { useDashboardManualFilters } from '../../hooks/useDashboardManualFilters';
import { apiResetDashboardAutoBoundById } from '../../../../services/dashboardController';
import { CustomButton } from '../../../../uikit/Button';
import { DashboardPropertyType } from '../../../../enums/dashboard-properties';

export const EXCLUDE_BOUND_WIDGET_TYPES = [
  WidgetType.SPEEDOMETER,
  WidgetType.PIVOT_TABLE,
  WidgetType.INFORMATION_CARD,
  WidgetType.HTML
];

const FiltersChainingSettings = memo(() => {
  const {
    autoValues,
    toggleFilterGroup,
    toggleParticipantIsActive,
    toggleIsMaster,
    setAutoBoundGroups,
  } = useDashboardBoundFilters();

  const {
    drilldownValues,
    widgetsIds,
    dashboardId,
    addEmptyGroup,
    changeMasterWidget,
    changeConnectedWidget,
    deleteGroup,
    getAllConnectedWidget,
  } = useDashboardManualFilters(BoundType.manualBoundGroups);

  const [currentTab, setCurrentTab] = useState<settingTabs>(settingTabs.auto);
  const [isResetting, setIsResetting] = useState<boolean>(false);

  const widgets = useSelector(dashboardWidgetsSelector);

  const projectWidgetsList: Widget[] | [] = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? [],
  );

  const widgetIds: number[] = useSelector(
    (state: State) => state.dashboard.widgetIds,
  );

  const onChangeMasterWidget = useCallback(
    (groupIndex: number) => (value: any) => {
      changeMasterWidget(groupIndex, value);
    },
    [drilldownValues, dashboardId],
  );

  const onChangeConnectedWidget = useCallback(
    (groupIndex: number) => (e: any) => {
      changeConnectedWidget(groupIndex, e.target.value);
    },
    [drilldownValues, dashboardId],
  );

  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(closeDashboardFiltersSettingAction());
  };

  const addConnection = () => {
    addEmptyGroup();
  };

  const allConnectedWidgets = getAllConnectedWidget();

  const connectedWidgetNotOnDashboard = allConnectedWidgets.filter(
    (participant: ParticipantFilterWidget) =>
      !widgetsIds.includes(participant.widgetId),
  );

  const mappedConnectedWidtgetsNotOnDashboard = connectedWidgetNotOnDashboard.map(
    ({ widgetId }) => {
      return (
        projectWidgetsList.find(({ id }) => id === widgetId) || ({} as Widget)
      );
    },
  );

  const allDrillDownWidgetsIds = drilldownValues.reduce(
    (accum: number[], current: BoundFilterGroupProps) => {
      const widgetIds = current.participantFilterWidgets
        .filter((widget) => widget.isActive)
        .map((widget) => widget.widgetId);
      return uniq([...accum, ...widgetIds]);
    },
    [],
  );

  const setFreeWidgets = () => {
    // если на дашборде только одна карта, то не фильтруем доступные для связи виджеты,
    // чтобы можно было настроить связь между несколькими слоями карты
    if (widgets.length === 1 && widgets[0].type === 'MAP') {
      return widgets;
    } else {
      return [
        ...mappedConnectedWidtgetsNotOnDashboard,
        ...widgets,
      ].filter(({ id }) => {
        if (isUndefined(id)) return false;

        for (const group of drilldownValues) {
          if (!group.participantFilterWidgets) return true;
          for (const participant of group?.participantFilterWidgets) {
            if (participant.isMaster && +participant.widgetId === Number(id)) {
              return false;
            }
          }
        }

        return true;
      });
    }
  };

  const freeWidgets = setFreeWidgets();

  const potentialBoundMasterWidgets =
    widgets
      .filter((widget) => !EXCLUDE_BOUND_WIDGET_TYPES.includes(widget.type))
      .filter((widget) => widgetIds.includes(widget.id || 0));

  const isCanAddConnection =
    !!freeWidgets.length &&
    drilldownValues.length < potentialBoundMasterWidgets.length;

  const onReset = useCallback(() => {
    setIsResetting(true);

    apiResetDashboardAutoBoundById(dashboardId)
      .then((response) => {
        const filters = response.properties.find(
          ({ name }: any) => name === DashboardPropertyType.autoBoundGroups,
        );
        setAutoBoundGroups(JSON.parse(filters.value));
      })
      .finally(() => {
        setIsResetting(false);
      });
  }, [dashboardId, setAutoBoundGroups]);

  return (
    <DashboardSettingContainer
      title="Настройка связанных фильтров"
      handlerClose={closeHandler}
    >
      <BoundTypeSwitcher
        type="tabs"
        currentTab={currentTab}
        handleSwitchCurrentTab={setCurrentTab}
        enableVerification={false}

      />

      {currentTab === settingTabs.auto &&
        <div className="filters-controls__container">
          <CustomButton onClick={onReset} loading={isResetting} variant="outlined" fullWidth>
            Сбросить группы
          </CustomButton>
        </div>
      }

      {currentTab === settingTabs.manual &&
        <BoundContentTitle
          isCanAddBound={isCanAddConnection}
          handleAddBound={addConnection}
        />}

      <div className="filters-chaining-list__container">
        {currentTab === settingTabs.auto &&
          autoValues?.map((item, index: number) => (
            <div key={item.id}>
              <FilterListItem
                index={index}
                data={item}
                toggleFilterGroup={toggleFilterGroup}
                toggleIsMaster={toggleIsMaster}
                toggleIsParticipantInFilters={toggleParticipantIsActive}
              />
            </div>
          ))}

        {currentTab === settingTabs.manual &&
          (drilldownValues.length ? (
            <div>
              {drilldownValues?.map((group, index: number) => (
                <ConnectionGroup
                  key={(group?.id || 0) + index}
                  drillDownGroup={group}
                  widgets={widgets}
                  freeWidgets={freeWidgets}
                  onChangeMasterWidget={onChangeMasterWidget(index)}
                  onChangeConnectedWidget={onChangeConnectedWidget(index)}
                  onDeleteGroup={() => deleteGroup(index)}
                  allDrillDownWidgetsIds={allDrillDownWidgetsIds}
                  boundType={BoundType.manualBoundGroups}
                />
              ))}
            </div>
          ) : (
            <BoundPlaceholder isCanAddConnection={isCanAddConnection} />
          ))}
      </div>
    </DashboardSettingContainer>
  );
});

FiltersChainingSettings.displayName = 'FiltersChainingSettings';

export default FiltersChainingSettings;
