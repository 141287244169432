import React, { memo, useCallback, useEffect, useState } from 'react';
import './styles.css';
import { useDispatch } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router';
import FilterListItem from './list-item/list-item';
import { closeDashboardFiltersSettingAction, setDashboardPropAction } from '../../../../slices/dashboard/dashboard';
import { getSimplifiedType } from '../../../widget-page/dropdown-layout/helpers/helpers';
import { apiResetDashboardFiltersById } from '../../../../services/dashboardController';
import { PageParams } from '../../../../types/meta';
import { DashboardSettingContainer } from '../common/dashboard-setting-container';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import { CustomButton } from '../../../../uikit/Button';
import { useRequestCanceller } from '../../../../hooks/useRequestCanceller';
import { DashboardPropertyType } from '../../../../enums/dashboard-properties';

const FiltersSetting = memo(() => {
  const { values } = useDashboardFilters();
  const dispatch = useDispatch();

  const [draggableItemData, setDraggableItemData] = useState<any>({
    item: null,
    group: null,
  });

  const [draggableGroupData, setDraggableGroupData] = useState<any>([]);

  const [isResetting, setIsResetting] = useState<boolean>(false);

  useEffect(() => {
    setDraggableGroupData(values);
  }, [values]);

  const params: PageParams = useParams();
  const { cancellableController } = useRequestCanceller();
  const dashboardId: number = Number(params.id);
  const usableFilters = useDashboardFilters();

  const onReset = useCallback(() => {
    setIsResetting(true);

    apiResetDashboardFiltersById(dashboardId)
      .then((response) => {
        const filters = response.properties.find(
          ({ name }: any) => name === DashboardPropertyType.filters,
        );
        usableFilters.setGroups(JSON.parse(filters.value));
      })
      .finally(() => {
        setIsResetting(false);
      });
  }, []);

  const closeHandler = () => {
    cancellableController.cancelGroup('filter');
    dispatch(closeDashboardFiltersSettingAction());
  };

  const onDragStart = (result: any) => {
    setDraggableItemData(JSON.parse(result.draggableId));
  };

  const onDragEnd = (result: any) => {
    if (result.destination) {
      if (result.type === 'ITEM') {
        const droppableGroup = JSON.parse(result.destination.droppableId);
        const { item, group } = draggableItemData;
  
        const isAllowedToDrop =
          getSimplifiedType(draggableItemData.group.type) ===
            getSimplifiedType(droppableGroup.type) &&
          draggableItemData.group.name !== droppableGroup.name;
  
        if (isAllowedToDrop) {
          usableFilters.addFilterInGroup(droppableGroup.id, item);
          usableFilters.removeFilterFromGroup(group.id, item.id);
        }
      } else {
        const currentList = [...draggableGroupData];
        const [removed] = currentList.splice(result.source.index, 1);
        currentList.splice(result.destination.index, 0, removed);

        setDraggableGroupData(currentList);

        dispatch(
          setDashboardPropAction({
            name: DashboardPropertyType.filters,
            value: JSON.stringify(currentList),
          }),
        );
      }
    }

    setDraggableItemData({ group: null, item: null });
  };

  return (
    <DashboardSettingContainer
      title="Настройка фильтров"
      handlerClose={closeHandler}
    >
      <div className="filters-controls__container">
        <CustomButton
          onClick={onReset}
          variant="outlined"
          fullWidth
          loading={isResetting}
        >
          Сбросить группы
        </CustomButton>
      </div>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="filters-list" mode="standard" type="GROUP">
          {(provided) => (
            <div
              className="filters-list__container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {draggableGroupData?.map((group: any, i: number) => {
                return (
                  <Draggable
                    key={group?.id}
                    draggableId={`${group?.id}`}
                    index={i}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FilterListItem
                          draggableItemData={draggableItemData}
                          group={group}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </DashboardSettingContainer>
  );
});

FiltersSetting.displayName = 'FiltersSetting';

export default FiltersSetting;
