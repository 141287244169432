import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getFilterSettingValue } from '../../../helpers/dashboard-page/filters-helper';
import { useGetDashboardProperty } from '../../../hooks/get-properties/useGetDashboardProperty';
import { State } from '../../../slices/types';
import { setDashboardPropAction } from '../../../slices/dashboard/dashboard';
import { getSimplifiedType } from '../../widget-page/dropdown-layout/helpers/helpers';
import { Filter, FilterGroupProps } from './index';
import { DashboardPropertyType, FilterProperty } from '../../../enums/dashboard-properties';
import { useDashboardFiltersUpdate } from '../dashboard-widgets/hooks/useDashboardFiltersUpdate';

export const useDashboardFilters = () => {
  const dispatch = useDispatch();
  const properties = useSelector((state: State) => state.dashboard.properties);

  const values = useGetDashboardProperty(DashboardPropertyType.filters, properties, []) as FilterGroupProps[];

  const { getUpdateDateFilters } = useDashboardFiltersUpdate(values);

  const changeFiltersOperation = useCallback(
    ({
      filters,
      index,
      overriddenFunction,
      selectDateType,
    }: {
      filters: Filter[];
      index: number;
      overriddenFunction?: string;
      selectDateType?: string;
    }) => {

      if (!filters) return;
      if (!values.length) return;

      if (values[index]) {
        values[index].value = filters;
        const overriddenFunctionSettingIndex = values[
          index
        ].filterProperties.findIndex(
          (setting) => setting.name === FilterProperty.overriddenFunction,
        );
        values[index].filterProperties[overriddenFunctionSettingIndex] = {
          ...values[index].filterProperties[overriddenFunctionSettingIndex],
          value: overriddenFunction || null,
        };

        const selectDateTypeSettingIndex = values[
          index
        ].filterProperties.findIndex(
          (setting) => setting.name === FilterProperty.selectDateType,
        );
        values[index].filterProperties[selectDateTypeSettingIndex] = {
          ...values[index].filterProperties[selectDateTypeSettingIndex],
          value: selectDateType || '',
        };
      } else {
        throw new Error(
          `Filter with index "${index}" not found on dashboard's properties`,
        );
      }
      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(values),
        }),
      );
    },
    [dispatch, values],
  );

  const setGroups = useCallback(
    (groups: FilterGroupProps[]) => {
      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(groups),
        }),
      );
    },
    [dispatch],
  );

  const resetFilters = useCallback(() => {
    const emptyValues = values.map((filter: any) => {

      const isMandatoryFilter = getFilterSettingValue(filter, FilterProperty.isMandatoryFilter);

      return {
        ...filter,
        value: isMandatoryFilter ? filter.value : [],
      };
    });

    dispatch(
      setDashboardPropAction({
        name: DashboardPropertyType.filters,
        value: JSON.stringify(emptyValues),
      }),
    );
  }, [dispatch, values]);

  const toggleActualGroup = useCallback(
    (groupId: number) => {
      if (!values.length) return;

      const groupIndex = values.findIndex((item) => item.id === groupId);
      values[groupIndex].actual = !values[groupIndex].actual;

      // если включаем группу, и при этом все фильтры выключены, то их тоже вкл.
      if (
        values[groupIndex].actual &&
        !values[groupIndex].widgetFilters.find((filter) => filter.actual)
      ) {
        values[groupIndex].widgetFilters.forEach((filter, index) => {
          values[groupIndex].widgetFilters[index].actual = true;
        });
      }

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(values),
        }),
      );
    },
    [dispatch, values],
  );

  const toggleActualFilter = useCallback(
    (groupId: number, filterId: number) => {
      if (!values.length) return;

      const groupIndex = values.findIndex((item) => item.id === groupId);
      const filterIndex = values[groupIndex].widgetFilters.findIndex(
        (item) => item.id === filterId,
      );

      values[groupIndex].widgetFilters[filterIndex].actual =
        !values[groupIndex].widgetFilters[filterIndex].actual;

      if (!values[groupIndex].widgetFilters.find((filter) => filter.actual)) {
        values[groupIndex].actual = false;
      }

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(values),
        }),
      );
    },
    [dispatch, values],
  );

  const isActiveFilterForWidget = useCallback(
    (widgetId: any) => {
      for (const group of values) {
        for (const filter of group.widgetFilters) {
          if (filter.widgetId === widgetId) {
            if (!filter.actual || !group.actual) return true;
          }
        }
      }

      return false;
    },
    [values],
  );

  const getGroupForFilter = useCallback(
    (id: any) => {
      for (const group of values) {
        for (const filter of group.widgetFilters) {
          if (Number(filter.id) === Number(id)) {
            return group;
          }
        }
      }

      return null;
    },
    [values],
  );

  const getGroupIndexForFilter = useCallback(
    (id: any) => {
      let index = 0;
      for (const group of values) {
        for (const filter of group.widgetFilters) {
          if (Number(filter.id) === Number(id)) {
            return index;
          }
        }
        index++;
      }

      return -1;
    },
    [values],
  );

  const isEnableFilterOnDashboard = useCallback(
    (id: number) => {
      for (const group of values) {
        for (const filter of group.widgetFilters) {
          if (Number(filter.id) === Number(id)) {
            if (group.actual && filter.actual) return true;
          }
        }
      }

      return false;
    },
    [values],
  );

  const addFilterInGroup = useCallback(
    (groupId: number, filter: any) => {
      if (!values.length) return;

      const groupIndex = values.findIndex((item) => item.id === groupId);

      values[groupIndex].widgetFilters.push(filter);

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(values),
        }),
      );
    },
    [dispatch, values],
  );

  const removeFilterFromGroup = useCallback(
    (groupId: number, filterId: any) => {
      if (!values.length) return;

      const groupIndex = values.findIndex((item) => item.id === groupId);

      const newWidgetFilters = values[groupIndex].widgetFilters.filter(
        (item) => !(item.id === filterId),
      );

      values[groupIndex].widgetFilters = newWidgetFilters;

      if (
        !values[groupIndex].widgetFilters.filter((widget) => widget.actual)
          .length
      ) {
        values[groupIndex].actual = false;
        values[groupIndex].value = [];
      }

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(values),
        }),
      );
    },
    [dispatch, values],
  );

  const getFilterByNameAndType = useCallback(
    (name: string, type: string) => {
      return (
        values.find(
          (item) =>
            item.name === name &&
            getSimplifiedType(item.type) === getSimplifiedType(type),
        ) || null
      );
    },
    [values],
  );

  const getFilterInGroup = useCallback(
    (name: string, type: string, widgetId: number): FilterGroupProps | null => {
      let filter = null;
      values.forEach((filterGroup) => {
        if (
          filterGroup.actual &&
          getSimplifiedType(filterGroup.type) === getSimplifiedType(type)
        ) {
          const currentFilterInGroupIndex = filterGroup.widgetFilters.findIndex(
            (filter) => filter.name === name && widgetId === filter.widgetId,
          );
          currentFilterInGroupIndex !== -1 &&
            (filter = filterGroup.widgetFilters[currentFilterInGroupIndex]);
        }
      });

      return filter;
    },
    [values],
  );

  const getFilterIndexByNameAndType = useCallback(
    (name: string, type: string) => {
      return values.findIndex(
        (item) => item.name === name && item.type === type,
      );
    },
    [values],
  );

  const setWidgetFilterValue = useCallback(
    (filters: Array<{ filterId: number; value: Filter[] }>) => {
      if (!values.length) return;
      const newValues = [...values];

      filters.forEach((filter) => {
        const { filterId, value } = filter;

        const groupIndex = values.findIndex((value) => {
          return (
            value.widgetFilters.findIndex((item) => item.id === filterId) > -1
          );
        });
        const filterIndex = values[groupIndex]?.widgetFilters.findIndex(
          (item) => item.id === filterId,
        );

        if (groupIndex > -1 && filterIndex > -1) {
          newValues[groupIndex].widgetFilters[filterIndex].value = value;
        }
      });

      const newFilters = getUpdateDateFilters(newValues);

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(newFilters),
        }),
      );
    },
    [dispatch, getUpdateDateFilters, values],
  );

  const renameFilterGroup = useCallback(
    (groupId: number, alias: string | null) => {
      const groupIndex = values.findIndex((item) => item.id === groupId);

      if (groupIndex === -1) return;

      const newFilters = [...values];
      newFilters[groupIndex] = { ...newFilters[groupIndex], alias };

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(newFilters),
        }),
      );
    },
    [values, dispatch],
  );

  return {
    values,
    changeFiltersOperation,
    resetFilters,
    toggleActualGroup,
    toggleActualFilter,
    isActiveFilterForWidget,
    addFilterInGroup,
    removeFilterFromGroup,
    setGroups,
    getFilterByNameAndType,
    getFilterIndexByNameAndType,
    isEnableFilterOnDashboard,
    getGroupForFilter,
    getGroupIndexForFilter,
    setWidgetFilterValue,
    getFilterInGroup,
    renameFilterGroup,
  };
};
