import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { WidgetHeaderProps } from './index';
import { State, Widget } from '../../../slices/types';
import { getFormulasPropertyFromWidgetData } from '../../../helpers/widget-page/widget-properties-helper';
import { WidgetPropertyType } from '../../../enums/widget-type';
import { setWidgetFullScreenAction } from '../../../slices/main-page/main-page-slice';
import {
  getBooleanPropertyValue,
  getHeaderSettings,
  getSelectPropertyValue,
} from '../charts/helpers';
import { isIFramed } from '../../../utils/iframe-helper';
import { HeaderPositions } from '../../../enums/header-position';

export const useWidgetHeader = ({ widgetProps }: WidgetHeaderProps) => {
  const dispatch = useDispatch();
  const widgetData = useSelector(
    (state: State) => state.mainPage.currentProjectWidgetData,
  );
  const isFullScreen = useSelector(
    (state: State) => state.mainPage.isWidgetFullScreen,
  );
  const currentWidget = widgetProps || {} as Widget;
  const title = widgetData
    ? getFormulasPropertyFromWidgetData(
        currentWidget,
        WidgetPropertyType.title,
        widgetData.properties,
      )
    : '';
  const description = widgetData
    ? getFormulasPropertyFromWidgetData(
        currentWidget,
        WidgetPropertyType.description,
        widgetData.properties,
      )
    : '';

  const setFullScreen = () => {
    dispatch(setWidgetFullScreenAction(!isFullScreen));
  };

  const isFullScreenAbility: boolean = useMemo(
    () =>
      getBooleanPropertyValue('fullScreenAbility', currentWidget.properties),
    [currentWidget.properties],
  );

  const displayHeaderPosition: string =
    getSelectPropertyValue(
      'displayHeaderPosition',
      currentWidget.properties,
    ) || HeaderPositions.left;

  const headerTextAlign: 'left' | 'center' =
    displayHeaderPosition === HeaderPositions.left ? 'left' : 'center';

  const { titleSettings, subtitleSettings } = getHeaderSettings(
    currentWidget.properties,
  );

  const isFramed = useMemo(() => isIFramed(), []);

  return {
    title,
    titleSettings,
    description,
    subtitleSettings,
    headerTextAlign,
    isFullScreenAbility,
    isFramed,
    isFullScreen,
    setFullScreen,
  };
};
