import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { CustomButton } from '../../../../../uikit/Button';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  noConnections: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 'calc(108px * var(--scale-coefficient))',
    paddingBottom: 'calc(30px * var(--scale-coefficient))',
    overflowY: 'scroll',
  },
  noConnectionsMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& svg': {
      width: 'calc(88px * var(--scale-coefficient)) !important',
      height: 'calc(88px * var(--scale-coefficient)) !important',
    },
  },
  noConnectionsLogo: {
    marginBottom: 'calc(30px * var(--scale-coefficient))',

    '& svg': {
      maxWidth: 'none !important',
      maxHeight: 'none !important'
    }
  },
  noConnectionsText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 'calc(16px * var(--scale-coefficient))',
    lineHeight: 'calc(19px * var(--scale-coefficient))',
    color: 'var(--black)',
    width: 'calc(368px * var(--scale-coefficient))',
    textAlign: 'center',
    marginBottom: 'calc(30px * var(--scale-coefficient))',
  },
  noConnectionsHeader: {
    color: 'var(--title-color)',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 1,
    fontSize: 'calc(20px * var(--scale-coefficient))',
    lineHeight: 'calc(27px * var(--scale-coefficient))',
    marginBottom: 'calc(15px * var(--scale-coefficient))',
  },
});

interface NoConnectionProps {
  onAddConnection: () => void;
  isEditableMap: boolean;
}

const NoConnections = ({ onAddConnection, isEditableMap }: NoConnectionProps) => {
  const classes = useStyles();

  return (
    <div className={classes.noConnections}>
      <div className={classes.noConnectionsMain}>
        <div className={classes.noConnectionsLogo}>
          <IconSvg svg={IconDictionary.NoConnection} width={88} height={88} fill="var(--dark-grey)" />
        </div>
        <div className={classes.noConnectionsHeader}>
          Нет соединений между таблицами
        </div>
        {isEditableMap ?
          <>
            <div className={classes.noConnectionsText}>
              Выберите вид соединения, который хотите применить, а затем нажмите
              кнопку
            </div>
            <div>
              <CustomButton
                variant="outlined"
                onClick={onAddConnection}
              >
                Создать связь
              </CustomButton>
            </div>
          </>
          : null}
      </div>
    </div>
  );
};

export default NoConnections;
