// конвертирует в универсальный объект для сравнения isEqual, сбрасывает сортировки в массивах
import { sortBy, upperFirst } from 'lodash';
import { AggregationName } from '../enums/aggregations-functions';
import { CommonDictionary } from '../dictionaries/naming-dictionary/naming-dictionary';
import { DateFunctions } from '../enums/date';
import { PropertyData } from '../components/widget-page/dropdown-layout/helpers/Property';
import { PanelType, WidgetType } from '../enums/widget-type';
import { WidgetProperties } from '../slices/types';
import { getAxisValues, getParsedAxisValues } from '../components/widget-page/dropdown-layout/helpers/helpers';

export const WidgetsWithBookmarks = [
  WidgetType.FUNNEL,
  WidgetType.HISTOGRAM,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.STACKED_HISTOGRAM,
  WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
  WidgetType.GRAPH,
  WidgetType.GRAPH_AREA,
  WidgetType.TREE_MAP,
  WidgetType.PIE_CHART,
  WidgetType.DONUT_CHART,
  WidgetType.RADAR_CHART,
  WidgetType.BUBBLE_CHART,
];
export const AxisWithBookmarks = [
  PanelType.axisX,
  PanelType.axisY,
];

export const isAxisWithBookmarks = (type: WidgetType, panel: PanelType) =>
  WidgetsWithBookmarks.includes(type) && AxisWithBookmarks.includes(panel);

export const isMultipleAxisXWithBookmarks = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.TABLE:
      return true;
    default:
      return false;
  }
};

export const isMultipleAxisYWithBookmarks = (widgetType: WidgetType) => {
  switch (widgetType) {
    case WidgetType.FUNNEL:
    case WidgetType.TREE_MAP:
    case WidgetType.PIE_CHART:
    case WidgetType.DONUT_CHART:
      return false;
    default:
      return true;
  }
};

export const getComparableRecursive = (v: any): any => {
  if (v === null) return undefined;
  if (
    typeof v === 'object' &&
    !Array.isArray(v)
  ) {
    const newV: any = {};
    for (const key of Object.keys(v)) {
      newV[key] = getComparableRecursive(v[key]);
    }
    return newV;
  }
  if (Array.isArray(v)) {
    if (!v?.length) return undefined;
    let newA: any = [];
    v.forEach((val: any, index: number) => {
      newA[index] = getComparableRecursive(val);
    });
    if (typeof v[0] === 'object') {
      newA = sortBy(newA, ['id', 'name', 'operation']);
    } else {
      newA = sortBy(newA);
    }
    return newA;
  }
  if (typeof v === 'string') {
    try {
      return getComparableRecursive(JSON.parse(v));
    } catch {
      return v;
    }
  }
  return v;
};

export const getSortedProperties = (properties: any[]) => {
  return sortBy(properties, ['name'])
    .map((prop: any) => {
      let value;
      try {
        const valueParsed = JSON.parse(prop.value);
        if (
          valueParsed && Array.isArray(valueParsed) &&
          typeof valueParsed === 'object' &&
          (prop.name === 'axisFilter' || prop.name === 'filters')
        ) {
          for (let index = 0; index < valueParsed.length; ++index) {
            if (Array.isArray(valueParsed[index].value))
              valueParsed[index].value = sortBy(valueParsed[index].value, ['operation', 'value']);
            if (Array.isArray(valueParsed[index].filter))
              valueParsed[index].filter = sortBy(valueParsed[index].filter, ['operation', 'value']);
            if (Array.isArray(valueParsed[index].filterProperties))
              valueParsed[index].filterProperties = sortBy(valueParsed[index].filterProperties, ['displayedName', 'name']);
          }
          value = JSON.stringify(sortBy(valueParsed, ['id', 'name']));
        } else {
          value = JSON.stringify(valueParsed);
        }
      } catch {
        value = prop.value;
      }
      return {
        name: prop.name,
        value,
      };
    });

};

export const ChangeColorLuminance = (hex: string, lum: number) => {
  let hexColor: string;

  hexColor = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hexColor.length < 6) {
    hexColor = hexColor[0]+hexColor[0]+hexColor[1]+hexColor[1]+hexColor[2]+hexColor[2];
  }

  let rgb = '#';
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hexColor.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += (`00${c}`).substr(c.length);
  }

  return rgb;
};

export const getConvertedAggregationName = (aggregation: string) => {
  let convertedAggregation = aggregation;

  if (aggregation.includes('DISTINCT')) {
    return CommonDictionary.CountDistinctAggregation;
  }

  Object.keys(AggregationName).forEach((key) => {
    convertedAggregation = convertedAggregation.replace(key, CommonDictionary[`${upperFirst(key.toLowerCase())}Aggregation`]);
  });

  return convertedAggregation;
};

export const getConvertedFunctionName = (dateFunction: string) => {
  let convertedDateFunction = dateFunction;

  Object.keys(DateFunctions).forEach((key) => {
    convertedDateFunction = convertedDateFunction.replace(key, CommonDictionary[`${upperFirst(key.toLowerCase())}Function`]);
  });

  return convertedDateFunction;
};

export const getPanelItemName = (item: PropertyData) => {
  return JSON.parse(item.storage || '{}').alias || item.name;
};

export const isVisibleBookmarks = (widgetType: WidgetType) => {
  return WidgetsWithBookmarks.includes(widgetType);
};

export const getActivePanelItems = (
  widgetProperties: WidgetProperties[],
  panelType: PanelType,
) => {
  const axisValues: PropertyData[] = getParsedAxisValues(
    panelType,
    widgetProperties,
  );

  if (!axisValues.length) return [];

  return axisValues.filter((item) => item.isActive) || axisValues[0];
};

export const getStorageName = (storage: string | undefined) => {
  try {
    return JSON.parse(storage || '{}').alias || '';
  } catch {
    return '';
  }
};

export const getActivePanelItemsNames = (
  widgetProperties: WidgetProperties[],
  panelType: PanelType,
) => {
  try {
    return JSON.parse(getAxisValues(panelType, widgetProperties))
      .filter((value: PropertyData) => value.isActive)
      .map(
        (value: PropertyData) =>
          getStorageName(value.storage) || value.name,
      );
  } catch {
    return [];
  }
};

export const getFirstActivePanelItemIndex = (
  widgetProperties: WidgetProperties[],
  panelType: PanelType,
) => {
  const axisValues: PropertyData[] = getParsedAxisValues(
    panelType,
    widgetProperties,
  );

  return Math.max(axisValues.findIndex((item) => item.isActive), 0);
};
