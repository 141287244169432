import React, { useCallback, useLayoutEffect } from 'react';
import moment from 'moment';
import { dispatchRootEvent } from '../../../../utils/root-helper';
import { axisProperties, canvasProperties } from './properties';

import './styles.css';

const TableCell = ({ children, isHead, height }: any) => {
  return (
    <td
      style={{
        height: `${isHead ? axisProperties.height : 'auto'}px`,
      }}
    >
      {children}
    </td>
  );
};

export const GanttSidebarTable = ({
  data,
  hoveredRowIndex,
  canvasHeight,
  hasProgressColumn,
  isOpened
}: any) => {

  const triggerResize = useCallback(() => {
    dispatchRootEvent(new Event('resize'));
  }, []);

  useLayoutEffect(() => {
    triggerResize();
  }, [isOpened]);

  return (
    <div className={`guntt-sidebar ${isOpened ? 'opened' : ''}`}>
      <div className="guntt-sidebar__wrapper">
        <table
          cellSpacing="0"
          className="guntt-sidebar__table"
          style={{ height: `${canvasProperties.getHeight(data)  }px` }}
        >
          <thead>
            <tr>
              <TableCell isHead>№</TableCell>
              <TableCell isHead>Начало</TableCell>
              <TableCell isHead>Конец</TableCell>
              {hasProgressColumn && <TableCell isHead>Прогресс</TableCell>}
            </tr>
          </thead>
          <tbody>
            {data.map((row: any, index: number) => (
              <tr key={index} className={index === hoveredRowIndex ? 'active' : ''}>
                <TableCell>{row.number}</TableCell>
                <TableCell>
                  {moment(row.startDate).format('DD/MM/YY')}
                </TableCell>
                <TableCell>{moment(row.endDate).format('DD/MM/YY')}</TableCell>

                {hasProgressColumn && (
                  <TableCell>{Math.ceil(row.progress * 100)}%</TableCell>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
