import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'react-flow-renderer';
import { NodeComponentProps } from 'react-flow-renderer/dist/types';
import hexToRgba from 'hex-to-rgba';
import {
  changeCurrentStepIdAction,
  setOperationTypeAction,
  toggleSaveResultPanelAction,
} from '../../../../slices/map-view/map-view';
import { setDeletingAction } from '../../../../slices/main-page/main-page-slice';
import { Operation } from '../../../../enums/operation';
import { State } from '../../../../slices/types';
import IconSvg from '../../../common/icon-svg/icon-svg';
import ButtonDelete from './button-delete';
import './operation-node.css';
import { mapConnectionAction } from '../../../../slices/map-connection/map-connection';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import { CommonDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';

interface OperationElementProps extends NodeComponentProps {}

const OperationNode: React.FC<OperationElementProps> = ({
  id,
  type,
  data,
}) => {
  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const operation = data.operationType;
  const adviceMode = useSelector((state: State) => state.mapView.adviceMode);
  const jsonData = useSelector((state: State) => state.mapConnection);
  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );
  const isValid =
    jsonData[loaderId]?.steps.find((step) => step.id === id)?.fieldsMapping
      .length !== 0 ?? false;

  const getElementInfo = (operationType: Operation) => {
    switch (operationType) {
      case Operation.Join:
        return { logo: IconDictionary.Join, text: CommonDictionary.JoinOperation, color: isValid ? 'var(--primary-color)' : 'var(--red-normal)' };
      case Operation.Union:
        return { logo: IconDictionary.UnionOrFullJoin, text: CommonDictionary.UnionOperation, color: isValid ? 'var(--primary-color)' : 'var(--red-normal)' };
      default:
        return { logo: IconDictionary.UnknownOperation, text: CommonDictionary.UnknownOperation, color: 'var(--primary-color)' };
    }
  };

  const getOperationClasses = (
    operationType: Operation,
    isAdviceMode: boolean,
  ) => {
    let classes = 'operation-node';
    switch (operationType) {
      case Operation.Join:
      case Operation.Union:
        classes += ' operation-node__join';
        break;
      default:
        break;
    }
    if (isAdviceMode) {
      classes += ' operation-node__disabled';
    }
    return classes;
  };

  const elementInfo = getElementInfo(operation);
  const nodeData = { id, type, name: elementInfo.text };

  const handleSetError = (errorMessage: string) => {
    setIsLoading(false);
    setError(errorMessage);
  };

  const handleDoubleClick = () => {
    dispatch(changeCurrentStepIdAction(id));
    dispatch(setOperationTypeAction(operation));
    dispatch(toggleSaveResultPanelAction(true));
  };

  const handleDeleteButtonClick = () => {
    dispatch(setDeletingAction(true));
    setIsLoading(true);
    dispatch(mapConnectionAction.deleteStep(loaderId, id, handleSetError));
  };
  return (
    <div
      className={getOperationClasses(operation, adviceMode)}
      onDoubleClick={handleDoubleClick}
    >
      <div className="operation-node__content">
        <div className="operation-node__icon-container" style={{ background: hexToRgba(elementInfo.color, 0.1) }}>
          <IconSvg
            svg={elementInfo.logo}
            fill={elementInfo.color}
          />
        </div>
        <div className="operation-node__text" style={{ color: elementInfo.color }}>{elementInfo.text}</div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
      />
      {isEditableMap ?
        <ButtonDelete
          onDelete={handleDeleteButtonClick}
          nodeData={nodeData}
          isLoading={isLoading}
          error={error}
        /> : null}
    </div>
  );
};

export default OperationNode;
