/* eslint-disable react/jsx-key */
import React, { createContext, FC, useState } from 'react';

export interface TableDndProviderProps {
  children: React.ReactNode
}

export interface ComplexTableDndState {
  targetId: string | null;
  destinationId: string | null;
  setTargetId: (id: string) => void;
  setDestinationId: (id: string) => void;
}
  

export const ComplexTableDndContext = createContext<ComplexTableDndState>({
  targetId: null,
  destinationId: null,
  setTargetId: (id: string) => {},
  setDestinationId: (id: string) => {},
});


export const TableDndProvider: FC<TableDndProviderProps> = (
  {
    children
  }) => {
    
  const [destinationId, setDestinationId] = useState<string | null>(null);
  const [targetId, setTargetId] = useState<string | null>(null);
    
  const handleSetDestinationId = (id: string) => {
    setDestinationId(id);
  };

  const handleSetTargetId = (id: string) => {
    setTargetId(id);
  };

  return (
    <ComplexTableDndContext.Provider value={{
      destinationId,
      targetId,
      setDestinationId: handleSetDestinationId,
      setTargetId: handleSetTargetId
    }}
    >
      { children }
    </ComplexTableDndContext.Provider>

  );
};