import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useUpdateDataAfterChangeWidgetData
} from '../../../../hooks/useUpdateDataAfterChangeWidgetData';
import { selectProjectParameters } from '../../../../slices/parameters/parameters';
import {
  DashboardProperty,
  State,
  WidgetProperties,
} from '../../../../slices/types';
import { getSortedProperties } from '../../../../helpers/common-helpers';
import {
  dashboardWidgetDataSelector,
  setWidgetAPIToDashboard,
} from '../../../../slices/dashboard/dashboard';
import {
  apiGetWidgetById,
  apiGetWidgetDataById,
} from '../../../../services/widgetController';
import { WIDGETS_WITHOUT_DATA_PROPERTIES } from '../../../widget-page/charts/settings';
import { setDataCacheLoadingAction } from '../../../../slices/main-page/main-page-slice';
import { CallsErrors } from '../../../../enums/errors';
import WidgetTypes from '../../../../types/widgets';
import { useRequestCanceller } from '../../../../hooks/useRequestCanceller';
import { useDashboardSettings } from '../../settings/hooks/useDashboardSettings';

interface useDashboardDataUpdatingProps {
  widgetId: number;
  enhancedDashboardParams: {
    dashboardProperties: DashboardProperty[];
    widgetOverriddenProperties: WidgetProperties[];
  };
  isParentForOpenedDrilldown: boolean;
  setWidgetDataObject: Dispatch<SetStateAction<WidgetTypes.Data>>;
  setWidgetErrorText: Dispatch<SetStateAction<string>>;
  initWidgetDataLoadingCallback: (result: any) => void;
  effectedChangeForDashboardProperties: string;
  jsonOverriddenFunctionsByDateFilters: string;
  overriddenProperties: any;
}


export const useDashboardDataUpdating = ({
  widgetId,
  enhancedDashboardParams,
  isParentForOpenedDrilldown,
  setWidgetDataObject,
  setWidgetErrorText,
  initWidgetDataLoadingCallback,
  effectedChangeForDashboardProperties,
  jsonOverriddenFunctionsByDateFilters,
  overriddenProperties,
}: useDashboardDataUpdatingProps) => {
  const dispatch = useDispatch();

  const { callCancellable } = useRequestCanceller();

  const widgetsWithActiveLegend = useSelector(
    (state: State) => state.dashboard.widgetsWithActiveLegend,
  );
  const dashboardId = useSelector(
    (state: State) => state.dashboard.id,
  );
  const isCacheReloading =
    useSelector((state: State) => state.mainPage?.isDataCacheLoading) || false;
  const widgetProps = useSelector(
    dashboardWidgetDataSelector(String(widgetId)),
  );
  const projectParameters = useSelector(selectProjectParameters);

  const [isWidgetPropertiesLoaded, setWidgetPropertiesLoaded] = useState(false);

  const { disableLoadData } = useDashboardSettings();

  useEffect(() => {
    const initialDataLoading = () => {

      dispatch(
        setWidgetAPIToDashboard({
          widgetId: String(widgetId),
          params: {
            loading: false,
            dirty: false,
          },
        }),
      );

      apiGetWidgetById(widgetId).then((result: any) => {
        initWidgetDataLoadingCallback(result);

        dispatch(
          setWidgetAPIToDashboard({
            widgetId: String(widgetId),
            params: {
              data: result,
            },
          }),
        );
        setWidgetPropertiesLoaded(true);
      });
    };

    initialDataLoading();
  }, [dispatch, widgetId]);


  const activateLoader = (id: number) => {
    dispatch(
      setWidgetAPIToDashboard({
        widgetId: String(id),
        params: {
          loading: true,
          error: null,
        },
      }),
    );
  };

  const getWidgetDataById = (id: number) => {
    if (isParentForOpenedDrilldown || disableLoadData ) return;

    // сортируем properties для корректного кеширования на беке
    const enhancedParamsSorted = {
      dashboardProperties: getSortedProperties(
        enhancedDashboardParams.dashboardProperties,
      ),
      widgetOverriddenProperties: getSortedProperties(
        enhancedDashboardParams.widgetOverriddenProperties,
      ),
    };

    const requestParams = { ...enhancedParamsSorted, dashboardId };

    activateLoader(id);

    callCancellable(apiGetWidgetDataById, {
      widgetId: id,
      requestParams,
    })
      .then((result: any) => {
        onSuccessLoadWidgetData(result, id);
      })
      .catch((error) => {
        onErrorLoadWidgetData(error, id);
      });
  };

  const onSuccessLoadWidgetData = (result: any, id: number) => {
    const properties = [...widgetProps.properties];

    const isDataWithProperties = !WIDGETS_WITHOUT_DATA_PROPERTIES.includes(
      widgetProps.type,
    );

    setWidgetDataObject(
      isDataWithProperties ? result : { properties: [], data: result },
    );

    dispatch(
      setWidgetAPIToDashboard({
        widgetId: String(id),
        params: {
          data: {
            ...widgetProps,
            properties,
            data: isDataWithProperties ? result.data : result,
          },
          loading: false,
          dirty: true,
          error: null,
          dataProperties: isDataWithProperties ? result.properties : [],
        },
      }),
    );

    if (isCacheReloading) {
      dispatch(setDataCacheLoadingAction(false));
    }
  };

  const onErrorLoadWidgetData = (error: any, id: number) => {
    const isCanceledRequest = CallsErrors.canceled === error.message;

    if (isCanceledRequest) {
      dispatch(
        setWidgetAPIToDashboard({
          widgetId: String(id),
          params: {
            data: {
              ...widgetProps,
            },
            loading: false,
            dirty: true,
            error: null,
          },
        }),
      );
    } else {
      const properties = [...widgetProps.properties];
      setWidgetDataObject({} as WidgetTypes.Data);
      setWidgetErrorText(error?.response?.data?.message || error?.message || '');
      dispatch(
        setWidgetAPIToDashboard({
          widgetId: String(id),
          params: {
            data: {
              ...widgetProps,
              properties,
              data: [],
            },
            loading: false,
            dirty: false,
            error: error.message || 'Неизвестная ошибка',
          },
        }),
      );
    }
  };

  useEffect(() => {
    const updateWidgetAfterActiveLegendChange = () => {
      if (widgetsWithActiveLegend && widgetsWithActiveLegend.length) {
        getWidgetDataById(widgetId);
      }
    };

    updateWidgetAfterActiveLegendChange();
  }, [JSON.stringify(widgetsWithActiveLegend)]);

  useEffect(() => {
    if (isWidgetPropertiesLoaded) getWidgetDataById(widgetId);
  }, [
    widgetId,
    effectedChangeForDashboardProperties, // Параметры для дашборда (тут все его фильтры)
    JSON.stringify(overriddenProperties),
    jsonOverriddenFunctionsByDateFilters,
    isWidgetPropertiesLoaded,
    disableLoadData,
    projectParameters
  ]);

  const callbackRefreshData = useCallback(() => {
    getWidgetDataById(widgetId);
  }, [
    widgetId,
    effectedChangeForDashboardProperties, // Параметры для дашборда (тут все его фильтры)
    JSON.stringify(overriddenProperties),
    jsonOverriddenFunctionsByDateFilters,
    isWidgetPropertiesLoaded,
    disableLoadData,
  ]);

  useUpdateDataAfterChangeWidgetData(widgetId, callbackRefreshData);
};
