import React, { useCallback, useMemo } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import './legend-vertical.css';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { setPropForNumberValue } from '../formatting-helpers';
import { getBooleanPropertyValue, getSelectPropertyValue } from '../helpers';
import { useRoundingCounts } from '../../../../hooks/useRoundingCounts';
import { NumberFormat } from '../../../../enums/number-format';
import { getFirstActivePanelItemIndex } from '../../../../helpers/common-helpers';

const legendGlyphSize = 16;

export function LegendVertical({
  colors,
  data,
  valueName,
  zName,
  widgetProperties,
}: {
  colors: any;
  data: any;
  zName?: string;
  valueName?: string;
  widgetProperties: any[];
}) {
  const labels = Object.values(data).map((item: any) => item.label) as string[];
  const counts = Object.values(data).map((item: any) => item.count) as number[];
  const zPercent = Object.values(data).map((item: any) => item.zpercent) as number[];
  const zValue = Object.values(data).map((item: any) => item.zvalue) as number[];
  const percents = Object.values(data).map(
    (item: any) => item.percent,
  ) as number[];

  const ordinal = {
    domain: labels,
    range: colors,
  };

  const ordinalColorScale = scaleOrdinal(ordinal);

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const isNeedToDisplayPercentOnLegend: boolean = getBooleanPropertyValue(
    'displayPercentOnLegend',
    widgetProperties,
  );

  const isNeedToDisplayValueOnLegend: boolean = getBooleanPropertyValue(
    'displayValueOnLegend',
    widgetProperties,
  );

  const isNeedToDisplayTextOnLegend: boolean = getBooleanPropertyValue(
    'displayTextLegend',
    widgetProperties,
  );

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const getText = useCallback(
    (text: string) => {
      const axisXValueIndex = getFirstActivePanelItemIndex(
        widgetProperties,
        PanelType.axisX,
      );
      return valueFormat(text, axisXValueIndex, widgetProperties);
    },
    [valueFormat, widgetProperties],
  );

  const getValue = useCallback(
    (value: number, percent: number) =>
      ` ${
        isNeedToDisplayValueOnLegend && value
          ? valueFormat(value, 0, setPropForNumberValue(), PanelType.axisY, false,
            false,
            scaleByNumber ? formatByNumber : null, true)
          : ''
      } ${
        isNeedToDisplayPercentOnLegend && percent
          ? isNeedToDisplayValueOnLegend
            ? `(${valueFormat(
              percent,
              0,
              setPropForNumberValue(widgetProperties),
              PanelType.axisY,
            )}%)`
            : `${valueFormat(
              percent,
              0,
              setPropForNumberValue(widgetProperties),
              PanelType.axisY,
            )}%`
          : ''
      }`,
    [isNeedToDisplayPercentOnLegend, isNeedToDisplayValueOnLegend, valueFormat],
  );

  return (
    <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => label}>
      {(labels) => (
        <div className="legend-v-donut-container">
          {labels.map((label, i) => (
            <LegendItem key={`legend-v-donut-quantile-${i}`} margin="5px">
              <svg width={legendGlyphSize} height={legendGlyphSize}>
                <rect
                  fill={label.value as string | undefined}
                  width={legendGlyphSize}
                  height={legendGlyphSize}
                  rx={2}
                />
              </svg>
              {!zName
                ? <>
                  <LegendLabel
                    className="legend-v-donut-label__text"
                    align="left"
                    margin="0 0 0 4px"
                  >
                    <span className="legend-v-donut-label__text">
                      {getText(label.text)}
                    </span>
                    <span className="legend-v-donut-label__value">
                      {getValue(counts[i], percents[i])}
                    </span>
                  </LegendLabel>
                </>
                : <>
                  <div className="legend-donut-label__container">
                    <span className="legend-v-donut-label__text">
                      {getText(label.text)}
                    </span>

                    <span>{isNeedToDisplayTextOnLegend && (isNeedToDisplayPercentOnLegend || isNeedToDisplayValueOnLegend) && `${valueName}:`}</span>
                    {isNeedToDisplayValueOnLegend && counts[i] && (
                      <div className="legend-v-donut-label__text_multiply">
                        {valueFormat(
                          counts[i],
                          0,
                          setPropForNumberValue(widgetProperties),
                          PanelType.axisY,
                          false,
                          false,
                          scaleByNumber ? formatByNumber : null,
                          true
                        )}
                      </div>
                    )}
                    {isNeedToDisplayPercentOnLegend && percents[i] !== undefined && (
                      <div className="legend-v-donut-label__value">
                        ({valueFormat(
                        percents[i],
                        0,
                        setPropForNumberValue(widgetProperties),
                        PanelType.axisY,
                      )}
                        %)
                      </div>
                    )}
                  </div>
                  <div className="legend-donut-label__container">
                    {isNeedToDisplayTextOnLegend && (isNeedToDisplayPercentOnLegend || isNeedToDisplayValueOnLegend) && `${zName}:`}
                    {isNeedToDisplayValueOnLegend && zValue[i] && (
                      <div className="legend-v-donut-label__value">
                        {valueFormat(
                          zValue[i],
                          0,
                          setPropForNumberValue(widgetProperties),
                          PanelType.axisY,
                          false,
                          false,
                          scaleByNumber ? formatByNumber : null,
                          true
                        )}
                      </div>
                    )}
                    {isNeedToDisplayPercentOnLegend && zPercent[i] !== undefined && (
                      <div className="legend-v-donut-label__value">
                        ({valueFormat(
                        zPercent[i],
                        0,
                        setPropForNumberValue(widgetProperties),
                        PanelType.axisY,
                      )}
                        %)
                      </div>
                    )}
                  </div>
                </>}
            </LegendItem>

          ))}
        </div>
      )}
    </LegendOrdinal>
  );
}
