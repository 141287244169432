import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LocalStorage } from '../../../../enums/local-storage-type';
import { PageParams } from '../../../../types/meta';
import { Dashboard, DashboardResponse, State } from '../../../../slices/types';
import { setCurrentDashboardAction } from '../../../../slices/dashboard/dashboard';

export const useDashboardsContent = () => {
  const dashboardRef = useRef(document.createElement('div'));
  const dispatch = useDispatch();

  const projectId: number = useSelector(
    (state: State) => state.mainPage?.currentProject?.id ?? 0,
  );

  const dashboardsList: DashboardResponse[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectDashboards ?? null,
  );

  const params: PageParams = useParams();

  const [dashboardAnchor, setDashboardAnchor] = React.useState(null);
  const [isDashboardPopperOpen, setDashboardPopperOpen] =
    useState<boolean>(false);

  const lastDashboard = dashboardsList?.find(
    (dashboard: any) =>
      String(dashboard.id) ===
      (localStorage.getItem(LocalStorage.CAROUSEL_LAST_DASHBOARD_ID) || ''),
  );

  const currentDashboard =
    dashboardsList?.find((dashboard: any) => dashboard.id == params.id) ??
    lastDashboard ??
    (dashboardsList?.[0] || null);

  useEffect(() => {
    localStorage.setItem(
      LocalStorage.CAROUSEL_LAST_DASHBOARD_ID,
      String(currentDashboard?.id) || '',
    );
  }, [currentDashboard]);

  const toggleDashboardList = useCallback(() => {
    setDashboardPopperOpen(!isDashboardPopperOpen);
    setDashboardAnchor(dashboardRef.current as any);
  }, [dashboardRef, isDashboardPopperOpen]);

  const onNewDashboardAdd = () => {
    dispatch(setCurrentDashboardAction(''));
  };

  const handleDashboardsMenuClose = () => setDashboardPopperOpen(false);

  return {
    dashboardRef,
    currentDashboard,
    dashboardsList,
    toggleDashboardList,
    isDashboardPopperOpen,
    params,
    projectId,
    onNewDashboardAdd,
    dashboardAnchor,
    handleDashboardsMenuClose,
  };
};
