import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { State } from '../../../slices/types';
import { Operation } from '../../../enums/operation';
import {
  changeCurrentStepIdAction,
  changeSourceFilterAction,
  setBottomTableLabelAction,
  setOperationTypeAction,
  toggleAdviceModeAction,
  toggleSaveResultPanelAction,
} from '../../../slices/map-view/map-view';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { apiUpdatePositions } from '../../../services/loadersController';
import { filterNewSources } from './helper';

export const useLoaderMap = () => {
  const dispatch = useDispatch();
  const jsonData = useSelector((state: State) => state.mapConnection);

  const loaderId =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) || 0;

  const loaderData = useSelector(
    (state: State) => state.mapConnection[loaderId],
  );
  const adviceMode = useSelector((state: State) => state.mapView.adviceMode);

  const { label: bottomTableLabel } = useSelector(
    (state: State) => state.mapView.bottomTableContent,
  );

  const [sourceIdList, setSourceIdList] = useState<string[]>([]);
  const [currentOperation, setCurrentOperation] = useState<Operation | null>(
    null,
  );

  const availableInOperationsSourcesList = useMemo<string[]>(() => {
    const allSourcesIdsList =
      loaderData?.sourceObjects.map((sourceObject) => {
        return sourceObject.id;
      }) || [];
    let result = allSourcesIdsList;
    result =
      filterNewSources(
        result,
        loaderData,
        currentOperation as Operation,
        sourceIdList,
      ) || result;

    // switched off for now
    // result =
    //   filterUsedInStepsSources(
    //     result,
    //     loaderData,
    //   ) || result;

    return [...result, ...sourceIdList];
  }, [loaderData, sourceIdList, currentOperation]);

  const addStep = useCallback(
    (sourceIdListParam: string[], operation: Operation) => {
      const stepId = uuid();
      dispatch(changeCurrentStepIdAction(stepId));
      dispatch(
        mapConnectionAction.addStep({
          loaderId,
          operation,
          stepId,
          sourceIdList: sourceIdListParam,
        }),
      );
    },
    [dispatch, loaderId],
  );

  const onStepCreateCallback = useCallback(
    (operation: Operation) => {
      dispatch(setOperationTypeAction(operation));
      dispatch(toggleSaveResultPanelAction(true));
    },
    [dispatch],
  );

  const onDragStopCallback = useCallback(
    (nodesState: any, el: any) => {
      dispatch(
        mapConnectionAction.updateNodesState({
          loaderId,
          nodesState,
          isModelDataMode: false,
        }),
      );
      apiUpdatePositions(el.id, {
        isSourceObject: el.type === 'source',
        isModelView: false,
        position: el.position,
      });
    },
    [dispatch, loaderId],
  );

  const cancelAdviseMode = useCallback(() => {
    dispatch(toggleAdviceModeAction(false));
  }, [dispatch]);

  const handleClickSource = useCallback(
    (id: string) => {
      let newSourceIdList = [...sourceIdList];
      if (
        newSourceIdList.includes(id) ||
        !availableInOperationsSourcesList.includes(id)
      ) {
        newSourceIdList = newSourceIdList.filter((sourceId) => sourceId !== id);
        setSourceIdList(newSourceIdList);
        return;
      }
      newSourceIdList.push(id);
      setSourceIdList(newSourceIdList);
      if (newSourceIdList.length === 2) {
        setSourceIdList([]);
        cancelAdviseMode();
        addStep(newSourceIdList, currentOperation as Operation);
        onStepCreateCallback(currentOperation as Operation);
      }
    },
    [
      addStep,
      availableInOperationsSourcesList,
      cancelAdviseMode,
      currentOperation,
      onStepCreateCallback,
      sourceIdList,
    ],
  );

  useEffect(() => {
    if (!adviceMode) {
      setSourceIdList([]);
    }
  }, [adviceMode]);

  useEffect(() => {
    return () => {
      dispatch(setBottomTableLabelAction(''));
      dispatch(changeSourceFilterAction({ value: '', sourceIds: [] }));
    };
  }, [dispatch]);

  return {
    jsonData,
    loaderId,
    setCurrentOperation,
    loaderData,
    sourceIdList,
    availableInOperationsSourcesList,
    handleClickSource,
    adviceMode,
    onDragStopCallback,
    currentOperation,
    cancelAdviseMode,
    bottomTableLabel,
  };
};
