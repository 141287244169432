import block from 'bem-cn';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainMenu from '../../main-menu/main-menu';
import LayoutHeader from './layout-header/layout-header';
import './layout.css';
import { WidgetTooltip } from '../../common/widget-tooltip/widget-tooltip';
import { State } from '../../../slices/types';
import IconSvg from '../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

type Props = {
  children?: React.ReactNode;
};

const b = block('layout');

const Layout: React.FC<Props> = ({ children }) => {
  const currentPage = window.location.pathname.toLowerCase();
  const isNoHeader = /\/noheader/.test(currentPage);
  const systemSettings = useSelector((state: State) => state.mainPage.systemSettings);

  return (
    <div className={isNoHeader ? `${b()} ${b('no-header')}` : b()}>
      <Link className={b('logo')} to="/">
        <div className="layout__logo-container">
          {systemSettings?.SYSTEM_UI_LOGO && (
            <img
              className="layout__logo-icon"
              src={systemSettings?.SYSTEM_UI_LOGO}
              alt=""
            />
          )}
          {!systemSettings?.SYSTEM_UI_LOGO && (
            <IconSvg
              classSVG="layout__logo-icon"
              svg={IconDictionary.MainLogo}
              fill="var(--white)"
            />
          )}
        </div>
      </Link>
      <LayoutHeader />
      <aside className={b('aside')}>
        <MainMenu />
      </aside>
      <main className={b('content')}>{children}</main>
      <WidgetTooltip />
    </div>
  );
};

export default Layout;
