import { useCallback, useMemo } from 'react';
import { getEffectedChangeForDashboardFilters } from '../../../../helpers/dashboard-page/dashboard-widgets';
import { Filter, FilterGroupProps } from '../../hooks';
import {
  FilterProperty,
  SelectDateTypes,
} from '../../../../enums/dashboard-properties';

export interface DateFiltersSetting {
  filterGroupId: number;
  displayAsPeriod: boolean;
  displayAsSingleDate: boolean;
  selectDateType: string;
}

export const useDashboardFiltersUpdate = (
  dashboardFilters?: FilterGroupProps[],
) => {
  const dateFiltersSettings = useMemo(
    () =>
      dashboardFilters
        ? getEffectedChangeForDashboardFilters(dashboardFilters)
        : null,
    [dashboardFilters],
  );

  const getNewDateFilterValue = (
    setting: DateFiltersSetting,
    filterValues: Filter[],
  ) => {
    const isActiveMultipleSettings =
      setting.displayAsPeriod && setting.displayAsSingleDate;
    const isInactiveSettings =
      !setting.displayAsPeriod && !setting.displayAsSingleDate;
    const { selectDateType } = setting;

    if (isActiveMultipleSettings) {
      const actualSelectDateType =
        filterValues.length > 0
          ? filterValues[0].operation === '='
            ? SelectDateTypes.date
            : SelectDateTypes.period
          : selectDateType;
      return { filterValues, selectDateType: actualSelectDateType };
    }
    if (isInactiveSettings) return { filterValues: [], selectDateType };

    if (setting.displayAsPeriod && filterValues[0].operation === '=') {
      return {
        filterValues: [{ ...filterValues[0], operation: '>=' }],
        selectDateType: SelectDateTypes.period,
      };
    }

    if (setting.displayAsSingleDate && filterValues[0].operation !== '=') {
      const startValue = filterValues.find((item) => item.operation === '>=');
      return {
        filterValues: startValue ? [{ ...startValue, operation: '=' }] : [],
        selectDateType: SelectDateTypes.date,
      };
    }

    return { filterValues, selectDateType };
  };

  const getUpdateDateFilters = useCallback(
    (dashboardFilters: FilterGroupProps[]) => {
      if (!dateFiltersSettings?.length) return dashboardFilters;

      const newDashboardFilters = [...dashboardFilters];

      dateFiltersSettings.forEach((setting) => {
        const currentGroupIndex = newDashboardFilters.findIndex(
          (item) => item.id === setting.filterGroupId,
        );
        if (
          currentGroupIndex !== -1 &&
          dashboardFilters[currentGroupIndex].value?.length
        ) {
          const { filterValues, selectDateType } = getNewDateFilterValue(
            setting,
            dashboardFilters[currentGroupIndex].value,
          );

          newDashboardFilters[currentGroupIndex].value = filterValues;

          const selectDateTypeIndex = newDashboardFilters[
            currentGroupIndex
          ].filterProperties.findIndex(
            (property) => property.name === FilterProperty.selectDateType,
          );

          if (selectDateTypeIndex !== -1) {
            newDashboardFilters[currentGroupIndex].filterProperties[
              selectDateTypeIndex
            ] = {
              ...newDashboardFilters[currentGroupIndex].filterProperties[
                selectDateTypeIndex
              ],
              value: selectDateType,
            };
          }
        }
      });

      return newDashboardFilters;
    },
    [dateFiltersSettings],
  );

  return {
    getUpdateDateFilters,
  };
};
