export enum FilterProperty {
  isVisible = 'isVisible',
  lastAvailable = 'lastAvailable',
  isDisplayedOnTop = 'isDisplayedOnTop',
  displayAvailablePeriod = 'displayAvailablePeriod',
  displayAsSingleDate = 'displayAsSingleDate',
  displayAsPeriod = 'displayAsPeriod',
  currentDay = 'currentDay',
  currentMonth = 'currentMonth',
  currentYear = 'currentYear',
  currentWeek = 'currentWeek',
  overriddenFunction = 'overriddenFunction',
  function = 'function',
  selectDateType = 'selectDateType',
  textFilterMode = 'textFilterMode',
  isMandatoryFilter = 'isMandatoryFilter',
}

export enum SelectDateTypes {
  date = 'Дата',
  period = 'Период'
}
export enum TextFilterModeSelect {
  MultiSelect = 'Мультивыбор',
  SingleSelect = 'Одиночный выбор'
}

export enum DashboardPropertyType {
  filters = 'filters',
  autoBoundGroups = 'autoBoundGroups',
  autoDrillDownGroups = 'autoDrillDownGroups',
  manualDrillDownGroups = 'manualDrillDownGroups',
  manualBoundGroups = 'manualBoundGroups',
  layout = 'layout',
  showCacheUpdateTime = 'showCacheUpdateTime',
  showQuickFiltersPanel = 'showQuickFiltersPanel',
  showPrintBorders = 'showPrintBorders',
  showWidgetHeaders = 'showWidgetHeaders',
  showFiltersForPanel = 'showFiltersForPanel',
  isActiveCrossFiltration = 'isActiveCrossFiltration',
  noSendDataRequest = 'noSendDataRequest',
}
