import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isEqual } from 'lodash';
// @ts-ignore
import * as Rainbow from 'rainbowvis.js';
import { State, WidgetProperties } from '../../../../slices/types';
import {
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  getSelectPropertyValue,
  isDashboardPage,
  sortColorPalette,
} from '../helpers';
import { PanelType } from '../../../../enums/widget-type';
import { Color, defaultColors } from '../common/color';
import { percentTypes, SalesFunnelProps, valueSettings } from './index';
import { Property } from '../../dropdown-layout/helpers/Property';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';
import {
  getActivePanelItems,
  getFirstActivePanelItemIndex,
} from '../../../../helpers/common-helpers';

enum typesFunnelColoring {
  color = 'Один цвет',
  gradient = 'Градиент',
}

let currentColors: Color[] = defaultColors;

export const useSalesFunnel = ({
  isActiveFilter, widgetProps, setFilterField, filterField
}: SalesFunnelProps) => {
  const enableEvents: boolean = isActiveFilter;

  const widgetStoreProperties = useSelector(
    (state: State) => state.widget.properties,
  );
  const widgetProperties: WidgetProperties[] = widgetProps?.properties || widgetStoreProperties;

  const widgetData = widgetProps.data;

  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  const isNeedToDisplayValue: boolean = getBooleanPropertyValue(
    'displayFunnelValue',
    widgetProperties,
  );

  const isNeedToDisplayPercent: boolean = getBooleanPropertyValue(
    'displayFunnelPercent',
    widgetProperties,
  );

  const roundingCountString: string = getInputPropertyValue(
    'roundingCount',
    widgetProperties,
  );
  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const currentPercentType: string =
    getSelectPropertyValue('typeFunnelPercent', widgetProperties) ||
    percentTypes.percent;

  const axisXValuesFull = getActivePanelItems(widgetProperties, PanelType.axisX);
  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  const typeFunnelColoring: string =
    getSelectPropertyValue('typeFunnelColor', widgetProperties) ||
    typesFunnelColoring.color;

  const isGradientColoring = typeFunnelColoring === typesFunnelColoring.gradient;

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );
  currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || currentColors;

  const [stepsColors, setStepsColors] = useState<any[]>([]);

  useEffect(() => {
    if (!stepsColors.length && !isGradientColoring) {
      setPaletteColors();
    } else if (!stepsColors.length && isGradientColoring) {
      setGradientColors();
    }
  }, []);

  const defaultVisiblePaletteColors = 10;
  const visiblePaletteColors = Math.min(defaultVisiblePaletteColors, currentColors.length);

  const setPaletteColors = () => {
    const firstColors = [...currentColors].slice(0, visiblePaletteColors);
    const colors: any[] = [];
    for(let i = 0; i <= stepsCount / visiblePaletteColors; i++) {
      colors.push(...firstColors);
    }

    setStepsColors(colors);
  };

  const setGradientColors = () => {
    const rainbow = new Rainbow();
    rainbow.setSpectrum('white', currentColors[0]);
    rainbow.setNumberRange(-10, 100);

    const colors: any[] = [];

    const colorStep = 100 / stepsCount;

    widgetData.forEach((item: any, index: number) => {
      const color = `#${  rainbow.colourAt(colorStep * (index + 1))}`;
      colors.push(color);
    });

    setStepsColors(colors);
  };

  const changeColors = () => {
    if (!isGradientColoring) {
      setPaletteColors();
    } else {
      setGradientColors();
    }
  };

  useEffect(() => {
    if (stepsColors.length < stepsCount) return;

    const newColoring =
      !isEqual(
        [...currentColors].slice(0, visiblePaletteColors),
        [...stepsColors].slice(0, visiblePaletteColors),
      ) && !isGradientColoring;
    const newGradient =
      isGradientColoring && stepsColors.length &&
      stepsColors[stepsCount - 1].toLowerCase() !==
      currentColors[0].toLowerCase();

    if (newColoring || newGradient) {
      changeColors();
    }
  }, [isGradientColoring, currentColors]);

  const valueOffset = 20;
  const percentOffset = 15;

  const legendOffset = 15;
  const minLegendWidth = 200 + legendOffset;

  const containerPaddingLeftRight = 20;
  const containerPaddingTopBottom = 10;

  const containerRef = useRef(document.createElement('div'));
  const containerWidth =
    containerRef.current.clientWidth - (2 * containerPaddingLeftRight);

  const [containerHeight, setHeight] = useState(containerRef.current.clientHeight);

  const isDashboard = isDashboardPage();

  const debounceDelay = 500;

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  useLayoutEffect(() => {
    setHeight(
      containerRef.current.clientHeight - 2 * containerPaddingTopBottom,
    );
  }, [containerRef.current.clientHeight, widgetData, isFullScreen]);

  useLayoutEffect(() => {
    // при определенном масштабе clientHeight меняется на 2px +-, поэтому добавили условие на разницу новой и старой высоту
    if (
      isDashboard &&
      Math.abs(
        containerRef.current.clientHeight -
        2 * containerPaddingTopBottom -
        containerHeight,
      ) > 5
    ) {
      setDebouncedHeight.callback(
        containerRef.current.clientHeight - 2 * containerPaddingTopBottom,
      );
    }
  }, [widgetData, containerRef.current.clientHeight]);

  const stepsCount = useMemo(() => widgetData.length, [widgetData.length]);

  const baseAngle = 60;
  const baseTrapezoidStepPercent = 0.15;

  const minStepHeight = 40;
  const minTrapezoidStepHeight = minStepHeight * baseTrapezoidStepPercent;
  const stepHeight =
    containerHeight / stepsCount > minStepHeight + minTrapezoidStepHeight
      ? containerHeight / stepsCount -
      (containerHeight / stepsCount) * baseTrapezoidStepPercent
      : minStepHeight;
  const trapezoidStepHeight = baseTrapezoidStepPercent * stepHeight;
  const minTrapezoidStepOffset =
    (minStepHeight + minStepHeight * baseTrapezoidStepPercent) /
    Math.tan((baseAngle * Math.PI) / 180);

  const firstStepWidth = Math.min(
    (2 * containerHeight) / Math.tan((baseAngle * Math.PI) / 180),
    containerWidth - minLegendWidth,
  );

  const minLastStepWidth = 60;
  const maxLastStepWidth = 250;

  const setStepsWidth = () => {
    const stepsWidth: number[] = [];

    const lastWidth = Math.min(Math.max(
      firstStepWidth - (2 * minTrapezoidStepOffset) * stepsCount,
      minLastStepWidth,
    ), maxLastStepWidth);
    stepsWidth.push(lastWidth);

    for (let i = stepsCount - 2; i > -1; i--) {
      const width =
        lastWidth + (2 * minTrapezoidStepOffset) * (stepsCount - 1 - i);
      stepsWidth.unshift(width);
    }

    return stepsWidth;
  };
  const stepsWidth = setStepsWidth();

  const lastCoordinates = {
    x: 0,
    y: 0,
  };

  const minContainerHeight =
    stepsCount * (minStepHeight + trapezoidStepHeight) +
    (2 * containerPaddingTopBottom);
  const minContainerWidth =
    minLastStepWidth + (2 * minTrapezoidStepOffset) * stepsCount + minLegendWidth;

  const deselectFilter = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null,  []);
  }, [setFilterField, widgetProps.id]);

  const selectFilter = useCallback(
    (label: string) => {
      if (!enableEvents) return;
      if (filterField?.value?.includes(label)) {
        deselectFilter();
      } else {
        if (!setFilterField || !label) return;

        setFilterField(widgetProps.id, new Property(axisXValuesFull[0]).getId(), [
          {
            operation: '=',
            value: [label],
          },
        ]);
      }
    },
    [axisXValuesFull, deselectFilter, enableEvents, filterField, setFilterField, widgetProps.id],
  );

  const valuePadding = (stepHeight - valueSettings.lineHeight) / 2;

  return {
    deselectFilter,
    containerRef,
    widgetData,
    stepsColors,
    properties: {
      isGradientColoring,
      isNeedToDisplayValue,
      isNeedToDisplayPercent,
    },
    currentPercentType,
    containerStyles: {
      containerPaddingTopBottom,
      containerPaddingLeftRight,
      minContainerHeight,
      minContainerWidth
    },
    stepsCount,
    enableEvents,
    selectFilter,
    viewSettings: {
      trapezoidStepHeight,
      containerWidth,
      firstStepWidth,
      stepHeight,
      stepsWidth,
      containerHeight,
      maxLastStepWidth,
      minTrapezoidStepOffset,
      lastCoordinates,
      valueOffset,
      percentOffset,
      valuePadding
    },
    widgetProperties,
    roundingCount,
    axisXValueIndex,
  };
};
