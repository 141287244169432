import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import Connection from '../../types/connection';
import { getGetConnectionsFull } from '../../services/connection';
import { State } from '../types';

const connectionsInitialState: Connection.State = {
  fullConnections: {},
  isLoading: true,
};

const setFullConnections: CaseReducer<
  Connection.State,
  PayloadAction<Connection.View.Full>
> = (state, { payload: data }) => ({
  ...state,
  fullConnections: data,
});

const toggleLoading: CaseReducer<Connection.State, PayloadAction<boolean>> = (
  state,
  { payload: flag },
) => ({
  ...state,
  isLoading: flag,
});

const connectionsSlice = createSlice({
  name: 'connections',
  initialState: connectionsInitialState,
  reducers: {
    toggleLoading,
    setFullConnections,
  },
});

export const asyncActions = {
  getFullConnectionsAction: (groupId: number) => async (
    dispatch: Dispatch<any>,
  ) => {
    if (!groupId) {
      return;
    }

    try {
      dispatch(connectionsActions.toggleLoading(true));
      const connections = await getGetConnectionsFull(groupId);
      dispatch(connectionsSlice.actions.setFullConnections(connections));
    } catch (e: any) {
      console.error(e);
    } finally {
      dispatch(connectionsActions.toggleLoading(false));
    }
  },
};

export const connectionsSelectors = {
  selectFullConnections: (state: State) => state.connections.fullConnections,
  selectConnectionsLoading: (state: State) => state.connections.isLoading,
};

export const connectionsReducers = connectionsSlice.reducer;
export const connectionsActions = {
  ...connectionsSlice.actions,
  ...asyncActions,
};
