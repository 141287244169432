import React, { useState } from 'react';
import {
  getNextSorting,
  getSortingIcon,
  LoaderTableHeadCell,
} from '../head/helper';
import { LoaderTableBodyCell } from '../body/useLoadersTableRow';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import IconSvg from '../../../common/icon-svg/icon-svg';

interface CellBodyProps {
  cell: LoaderTableHeadCell | LoaderTableBodyCell;
  sortHandler?: (sort: string) => void;
}

export const CellBody = ({ cell, sortHandler }: CellBodyProps) => {
  const [sortingType, setSortingType] = useState<string>('');

  const handleChangeSort = () => {
    setSortingType(getNextSorting(sortingType));
    sortHandler && sortHandler((cell as LoaderTableHeadCell).sortName || '');
  };
  
  return (
    <div style={{ minWidth: `${(cell as LoaderTableHeadCell).minWidth}px` }}>
      {(cell as LoaderTableBodyCell).icon && (
        <div className="loader-table__cell-icon">
          {(cell as LoaderTableBodyCell).icon}
        </div>
      )}
      <div className="loader-table__cell-value">
        {cell.title}
      </div>
      {(cell as LoaderTableHeadCell).withSort && (
        <div className="loader-table__cell-sort">
          <CustomIconButton
            iconButtonProps={{
              children: <IconSvg
                svg={getSortingIcon(sortingType)}
                fill={sortingType ? 'var(--primary-color)' : 'var(--dark-grey)'}
                width={24}
                height={24}
              />,
              onClick: handleChangeSort,
            }}
            type={IconButtonType.secondary}
          />
        </div>
      )}
    </div>
  );
};
