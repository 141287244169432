import { v4 as uuid } from 'uuid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { validateComputedFields } from 'src/helpers/loader-page';
import { CalculatedInputContainer } from 'src/components/common/special-inputs/calculated-input-container/calculated-input-container';
import IconSvg from '../../common/icon-svg/icon-svg';
import FieldItem from './field-item/field-item';
import OperationTypeMenu from '../../common/operation-type-menu/operation-type-menu';
import { useClassButton } from '../../../hooks/useClassButton';
import { apiGetFormulas } from '../../../services/dictionariesController';
import './calculated-indicator-panel.css';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import { apiGetWidgetsListBySourceAndField } from '../../../services/widgetController';
import MapConnection from '../../../types/map-connection';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { CustomButton } from '../../../uikit/Button';
import { useNotificator } from '../../common/snackbar/hooks';
import { CustomPopover } from '../../../uikit/Popover/popover';
import { ConfirmDialog } from '../../../uikit/ConfirmDialog';
import { CustomInfoAlert } from '../../../uikit/InfoAlert';
import { WidgetDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface CalculatedIndicatorPanelProps {
  sourceObjectFields: any[];
  closeShowCalculatedIndicatorPanel: () => void;
  addNewProcessedField: (field: any) => void;
  editProcessedFields: (field: any) => void;
  sourceField: any;
  sourceObjectId: string;
  isEditableMap: boolean;
  needPushDown: boolean;
}

const CalculatedIndicatorPanel = ({
  editProcessedFields,
  sourceField,
  closeShowCalculatedIndicatorPanel,
  sourceObjectFields,
  addNewProcessedField,
  sourceObjectId,
  isEditableMap,
  needPushDown,
}: CalculatedIndicatorPanelProps) => {
  const { showNotification } = useNotificator();

  const [filterSourceObjectFields, setFilterSourceObjectFields] =
    useState<any>(sourceObjectFields);

  const isCreateMode = sourceField === 'new-indicator';
  const initialTextareaContent = isCreateMode ? '' : sourceField.displayedName;

  const [textareaContent, setTextareaContent] = useState<any>(
    initialTextareaContent,
  );

  const [aliasName, setAliasName] = useState<string>(sourceField.alias || '');

  // array of formules applied to sourceField
  const [formulesDictionary, setFormulesDictionary] = useState<any>({});
  const [lastFormulaExample, setLastFormulaExample] = useState<string>('');

  // next 4 lines, 2 methods and 2 more lines about css list
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const classButton = useClassButton();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isOpenMenu = Boolean(anchorEl);
  const popoverId = isOpenMenu ? 'simple-popover' : undefined;

  const isSaveDisabled = !textareaContent.replace(/ /g, '');

  const getTextareaContent = (operation: any) => {
    return operation;
  };

  const chooseTransformationOperation = (operation: any) => () => {
    setLastFormulaExample(operation.example);
    setTextareaContent(getTextareaContent(operation.dslFormula));
  };

  const compare = (a: any, c: any) => {
    if (a.firstIndex === c.firstIndex) {
      return c.name.length - a.name.length;
    }
    return a.firstIndex - c.firstIndex;
  };

  const getTemplateAndOperands = (): {
    transformationTemplate: string;
    transformationOperands: string[];
  } => {
    const filteredString: string =
      textareaContent?.replace(/(\'\[\'|\'\]\')/g, '') || '';

    let fieldsInTemplate: string[] = filteredString.match(/\[(.*?)\]/g) || [];
    fieldsInTemplate = fieldsInTemplate.map((field) =>
      field.substring(1, field.length - 1),
    );

    const currentFields = fieldsInTemplate.map((fieldName) => {
      return sourceObjectFields.find(
        (field) =>
          fieldName === field.alias || fieldName === field.displayedName,
      ) as MapConnection.ProcessedField;
    });

    const operands = currentFields?.map((field) => field?.id);

    let template = textareaContent;
    currentFields.forEach((field, index) => {
      template = template.replace(
        `[${field?.alias || field?.displayedName}]`,
        `$${index}`,
      );
    });

    return {
      transformationOperands: operands,
      transformationTemplate: template,
    };
  };

  const [isLoading, setLoading] = useState(false);
  const [calculatedFieldWidgetsList, setCalculatedFieldWidgetsList] = useState<
    any[]
  >([]);
  const [calculatedFieldEditErrorText, setCalculatedFieldEditErrorText] =
    useState<string>('');
  const [isInvalidName, setInvalidName] = useState(false);

  const getCalculatedFieldEditErrorText = (widgetsList: any[]) => {
    return `Данный показатель используют следующие ${
      WidgetDictionary.few
    }: ${widgetsList.join(', ')}`;
  };

  const onEditSourceField = () => {
    setLoading(true);
    apiGetWidgetsListBySourceAndField({
      source: sourceObjectId,
      field: sourceField.id,
    })
      .then((result) => {
        if (result.length) {
          setCalculatedFieldWidgetsList(result);
          setCalculatedFieldEditErrorText(
            getCalculatedFieldEditErrorText(result),
          );
        } else {
          onSave();
        }
        setLoading(false);
      })
      .catch(() => {
        setCalculatedFieldWidgetsList([]);
        setLoading(false);
      });
  };

  const onPrepareSave = () => {
    if (aliasName.trim().length === 0) {
      setInvalidName(true);
      showNotification({
        message: 'Имя показателя не введено',
        variant: 'error',
      });
      return;
    }

    if (isCreateMode) {
      onSave();
    } else {
      onEditSourceField();
    }
  };

  const onSave = () => {
    const { transformationTemplate, transformationOperands } =
      getTemplateAndOperands();
    const computedField = {
      sourceObjectId,
      alias: aliasName.trim(),
      category: '',
      transformationTemplate,
      transformationOperandsIds: transformationOperands,
      needPushdown: needPushDown,
      id: '',
    };

    if (isCreateMode) {
      const newProcessedField = {
        id: uuid(),
        schemaName: '',
        alias: aliasName.trim(),
        displayedName: textareaContent,
        transformationTemplate,
        transformationOperands,
        selected: true,
        category: 'CALCULATED',
        displayedType: 'FORMULA',
      };

      computedField.category = 'CALCULATED';

      validateComputedFields(computedField, showNotification, () => {
        addNewProcessedField(newProcessedField);
        closeShowCalculatedIndicatorPanel();
      });
    } else {
      const editedProcessedField = {
        ...sourceField,
        id: sourceField.id,
        alias: aliasName.trim(),
        displayedName: textareaContent,
        transformationTemplate,
        transformationOperands,
      };

      computedField.category = sourceField.category;
      computedField.id = sourceField.id;

      validateComputedFields(computedField, showNotification, () => {
        editProcessedFields(editedProcessedField);
        closeShowCalculatedIndicatorPanel();
      });
    }
  };

  const getFormulas = useCallback(() => {
    apiGetFormulas()
      .then((response) => {
        const formulas = response ? Object.entries(response) : [];
        const formulasDictionary: any[] = [];
        formulas.forEach((f: [string, any], index: number) => {
          formulasDictionary.push([...f, index]);
        });
        response && setFormulesDictionary(formulasDictionary);
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  }, []);

  const showExample = (text: string) =>
    text
      .split('\\n')
      .map((i, index) => {
        return <div key={`formula-example-${index}`}>{i}</div>;
      });

  useEffect(() => {
    getFormulas();
  }, []);

  const onCloseWidgetWarningHandler = () => {
    setCalculatedFieldWidgetsList([]);
  };

  const onSearchChange = useCallback(
    (event: any) => {
      const { value } = event.target;

      const searchResult = sourceObjectFields.filter((field) => {
        return (
          field.displayedName.toLowerCase().includes(value.toLowerCase()) ||
          field.alias?.toLowerCase().includes(value.toLowerCase())
        );
      });

      setFilterSourceObjectFields(searchResult);
    },
    [sourceObjectFields],
  );

  return (
    <>
      <CustomDialog
        isOpen={true}
        onClose={closeShowCalculatedIndicatorPanel}
        title="Новый вычисляемый показатель"
        description="Используйте поля для настройки"
        maxWidth={false}
        fullWidth={false}
      >
        <div className="calculated-indicator-panel">
          <div>
            <div className="calculated-indicator-panel__side">
              <div className="calculated-indicator-panel__side_top">
                <TextInput
                  label="Поиск по полям"
                  handleChange={onSearchChange}
                  searchIcon
                  fullWidth={true}
                />
              </div>
              <div className="calculated-indicator-panel__side_bottom">
                {filterSourceObjectFields.map((el: any, index: number) => (
                  <FieldItem
                    key={el.displayedName}
                    name={el.displayedName}
                    alias={el.alias}
                    type={el.displayedType}
                  />
                ))}
              </div>
            </div>
            <div className="calculated-indicator-panel__main">
              <TextInput
                label="Введите название показателя"
                handleChange={(e) => {
                  setAliasName(e.target.value || null);
                }}
                value={aliasName}
                fullWidth={true}
                error={isInvalidName}
                inputProps={{ maxlength: 27 }}
              />

              <div
                className="calculated-indicator-panel__main__textarea-section"
                style={{
                  height: lastFormulaExample
                    ? 'calc(100% - 175px)'
                    : 'calc(100% - 65px)',
                }}
              >
                <div className="calculated-indicator-panel__main__textarea-section__main">
                  <div className="calculated-indicator-panel__main__textarea-section__formulas-list">
                    <CustomButton
                      variant="outlined"
                      onClick={handleMenuClick}
                      icon={
                        <IconSvg
                          svg={IconDictionary.PlusUnderline}
                          fill="var(--primary-color)"
                        />
                      }
                    />
                    <CustomPopover
                      classes={{ paper: 'calculated-indicator-panel__popover' }}
                      id={popoverId}
                      open={isOpenMenu}
                      onClose={handleCloseMenu}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <OperationTypeMenu
                        formulesDictionary={formulesDictionary}
                        chooseTransformationOperation={
                          chooseTransformationOperation
                        }
                      />
                    </CustomPopover>
                  </div>
                  <CalculatedInputContainer
                    processedFields={sourceObjectFields}
                    className="calculated-panel__textarea"
                    textInputProps={{
                      label: 'Выберите формулу, выбор поля - @',
                      fullWidth: true,
                      multiline: true,
                      styles: {
                        isBorder: false,
                        isShrink: false,
                        isFixHeight: false,
                      },
                    }}
                    isMaxRowOverflow={false}
                    setValue={setTextareaContent}
                    value={textareaContent}
                  />
                </div>
              </div>

              {lastFormulaExample && (
                <div className="calculated-indicator-panel__main__example-section">
                  <CustomInfoAlert title="Примеры">
                    {showExample(lastFormulaExample)}
                  </CustomInfoAlert>
                </div>
              )}
            </div>
          </div>

          <div className="dialog-buttons">
            <CustomButton
              onClick={onPrepareSave}
              disabled={!isEditableMap || isSaveDisabled || isLoading}
              variant="contained"
            >
              Сохранить
            </CustomButton>
            <CustomButton
              type="reset"
              onClick={closeShowCalculatedIndicatorPanel}
              variant="outlined"
            >
              Отменить
            </CustomButton>
          </div>
        </div>
      </CustomDialog>

      <ConfirmDialog
        isOpen={Boolean(calculatedFieldWidgetsList.length)}
        onClose={onCloseWidgetWarningHandler}
        onConfirm={onSave}
        title="Изменение показателя"
        message={`Вы уверены, что хотите изменить показатель? После сохранения действие нельзя будет отменить. ${calculatedFieldEditErrorText}`}
        maxWidth="xs"
      />
    </>
  );
};

export default CalculatedIndicatorPanel;
