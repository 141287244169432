import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { Backdrop } from '@material-ui/core';
import animationData from './almaz-animation-1.json';

import './common-loader.css';

const CommonLoader = () => {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (element && element.current) {
      lottie.loadAnimation({
        container: element.current!,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  }, [element]);

  return (
    <Backdrop open classes={{ root: 'common-loader__backdrop' }}>
      <div className="common-loader__animation" ref={element} />
    </Backdrop>
  );
};

export default CommonLoader;
