import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';
import './axis-restriction-modal.css';
import { useParams } from 'react-router-dom';
import OperationTypeMenu from '../../../../common/operation-type-menu/operation-type-menu';
import { apiGetFormulas } from '../../../../../services/dictionariesController';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { apiValidateRestrictions } from '../../../../../services/widgetController';
import {
  formatRestrictionText,
  getSimplifiedType,
  isComplexMeasure,
  parseComplexRestriction,
} from '../../../dropdown-layout/helpers/helpers';
import {
  PropertyData,
  PropertyRestriction,
} from '../../../dropdown-layout/helpers/Property';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import { SelectInput } from '../../../../common/special-inputs/inputs/select-input';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';
import { Operations } from '../column-conditional-coloring-modal/enums';
import { Aggregations } from './enums';
import { InputContainer } from '../../../../common/special-inputs/inputContainer/inputContainer';
import { useNotificator } from '../../../../common/snackbar/hooks';
import { State } from '../../../../../slices/types';
import { WidgetType } from '../../../../../enums/widget-type';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomButton } from '../../../../../uikit/Button';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CustomInfoAlert } from '../../../../../uikit/InfoAlert';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import { getConvertedAggregationName, getConvertedFunctionName } from '../../../../../helpers/common-helpers';


interface AxisRestrictionModalProps {
  sourceFields: any[];
  panelData: PropertyData;
  closeConditionModal: () => void;
  addNewRestriction: (restriction: PropertyRestriction | null) => void;
}

const OPERATION_SIGN = '&OPERATION&';

const useStyles = makeStyles({
  iconButton: {
    padding: 0,
    height: '20px',

    '&:hover': {
      background: 'var(--white)',
    },
  },
});

const getOperationsForType = (aggregation: string | null | boolean, type: string) =>
  Operations[aggregation ? 'NUMBER' : getSimplifiedType(type)];

const getTemplateWithoutOperation = (template: string, isHaveAggregation: boolean, type: string) => {

  const operations = (getOperationsForType(isHaveAggregation, type) || [])
    .sort((a: string, b: string) => a.indexOf(b) > -1 ? -1 : 1);

  for (const operation of operations) {
    if (template.indexOf(` ${operation} `) > -1) {
      return {
        operation,
        templateWithoutOperation: template.replace(operation, OPERATION_SIGN),
      };
    }
  }

  return {
    templateWithoutOperation: template, operation: ''
  };
};

const getRestrictionData = (restriction: PropertyRestriction, sourceFields: any[]) => {
  const { template, operands, operandIds } = restriction;

  const columnType = sourceFields.find(
    (value: any) => value.etlFieldId === operandIds[0],
  )?.type;
  const column = {
    etlFieldId: operandIds[0],
    name: operands[0],
    type: columnType,
  };

  const isHaveAggregation = !/\$0 /.test(template.trim());

  const { templateWithoutOperation, operation } = getTemplateWithoutOperation(template, isHaveAggregation, columnType);

  const regTmpl = new RegExp(`(.+) ${OPERATION_SIGN} (.+)`);
  const matched = templateWithoutOperation.match(regTmpl);
  const operand = matched?.[1] || '';
  const formattedValue = (matched?.[2] || '').replace(/^\((.*?)\)$/, '$1');

  return { column, operation, formattedValue, operand };
};


const AxisRestrictionModal = ({
  sourceFields,
  panelData,
  closeConditionModal,
  addNewRestriction,
}: AxisRestrictionModalProps) => {
  const { restriction } = panelData;

  const projectId: number | undefined =
    Number(useParams<{ projectId: string }>()?.projectId) ?? undefined;

  const [textareaRestrictionContent, setTextareaRestrictionContent] =
    useState<string>('');
  const [textareaFormulaContent, setTextareaFormulaContent] = useState<any>('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [formulasDictionary, setFormulasDictionary] = useState<any>({});
  const [lastFormulaExample, setLastFormulaExample] = useState<string>('');
  const [hasValidationError, setValidationError] = useState<boolean>(false);
  const [isValidate, setValidationStatus] = useState<boolean>(false);
  const [column, setColumn] = useState<any>({
    etlFieldId: null,
    name: null,
    type: 'STRING',
  });
  const [aggregation, setAggregation] = useState<string | null>(null);
  const [operation, setOperation] = useState<string>('');
  const [restrictionValue, setRestrictionValue] = useState<string>('');
  const [isComplexFormulaChecked, setComplexFormulaChecked] =
    useState<boolean>(false);
  const classes = useStyles();

  const isOpenMenu = Boolean(anchorEl);
  const popoverId = isOpenMenu ? 'simple-popover' : undefined;

  const isPivot =
    useSelector((state: State) => state.widget.type) === WidgetType.PIVOT_TABLE;
  const isPushDown = useSelector((state: State) => state.widget.pushDown);

  const handleRestrictionChange = (value: any) => {
    setValidationError((hasError) => (hasError ? !hasError : hasError));
    setTextareaRestrictionContent(value);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    apiGetFormulas()
      .then((response) => {
        const formulas = response ? Object.entries(response) : [];
        const formulasDictionary: any[] = [];
        formulas.forEach((f: [string, any], index: number) => {
          formulasDictionary.push([...f, index]);
        });
        response && setFormulasDictionary(formulasDictionary);
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  }, []);

  const getTextareaFormulaContent = (operation: any) => {
    return operation;
  };

  const chooseTransformationOperation = (operation: any) => () => {
    setLastFormulaExample(operation.example);
    setTextareaFormulaContent(getTextareaFormulaContent(operation.dslFormula));
  };

  const showExample = (text: string) =>
    text
      .split('\\n')
      .filter((line: string) => line !== '')
      .map((line: string) => line.trim())
      .map((i) => {
        return <div key={i}>{i}</div>;
      });

  const operationWithRoundBrackets = ['IN', 'NOT IN'];

  const formulaValue = operationWithRoundBrackets.includes(operation)
    ? `(${restrictionValue})`
    : restrictionValue;

  const formula = `${
    aggregation
      ? aggregation.replace('%s', `[${column.name}]`)
      : `[${column.name}]`
  } ${operation} ${formulaValue}`;

  const parseRestriction = (): PropertyRestriction => {
    if (isComplexFormulaChecked) {
      return parseComplexRestriction(sourceFields, textareaRestrictionContent);
    }

    const template = `${
      aggregation ? aggregation.replace('%s', '$0') : '$0'
    } ${operation} ${formulaValue}`;
    return {
      template,
      operands: [column.name],
      operandIds: [column.etlFieldId],
    };
  };

  const onSave = () => {
    const restriction = {
      ...parseRestriction(),
      useFormula: isComplexFormulaChecked,
    };

    apiValidateRestrictions(projectId, { ...panelData, restriction }, isPushDown)
      .then(() => {
        setValidationError(false);
        addNewRestriction(restriction);
        closeConditionModal();
      })
      .catch(() => {
        setValidationError(true);
        showNotification({ message: 'Невалидное условие', variant: 'error' });
      })
      .finally(() => {
        setValidationStatus(false);
      });
  };

  const { showNotification } = useNotificator();

  const copyToClipboard = () => {
    copy(textareaFormulaContent);
    showNotification({
      message: 'Скопировано в буфер обмена',
      variant: 'success',
    });
  };

  const parseSimpleRestriction = useCallback((restriction: PropertyRestriction) => {

    const { column: _column, operation: _operation, formattedValue, operand }
    = getRestrictionData(restriction, sourceFields);

    const _aggregation = operand === '$0' ? null : operand.replace('$0', '%s');
    return { column: _column, operation: _operation, formattedValue, aggregation: _aggregation };
  }, [sourceFields]);

  const onColumnChange = (event: any) => {
    setValidationError((hasError) => (hasError ? !hasError : hasError));
    const currentColumn = sourceFields.find(
      (value: any) => value.etlFieldId === event.target.value,
    );
    setColumn({
      etlFieldId: event.target.value,
      name: currentColumn.name,
      type: currentColumn.type,
    });
    setAggregation(null);
  };

  const onAggregationChange = (event: any) => {
    setValidationError((hasError) => (hasError ? !hasError : hasError));
    setAggregation(event.target.value);
  };

  const onOperationChange = (event: any) => {
    setValidationError((hasError) => (hasError ? !hasError : hasError));
    setOperation(event.target.value);
  };

  const onChangeRestrictionValue = (event: any) => {
    setValidationError((hasError) => (hasError ? !hasError : hasError));
    setRestrictionValue(event.target.value);
  };

  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) return;

    if (restriction) {
      const isComplexFormula = Boolean(restriction.useFormula);
      setComplexFormulaChecked(isComplexFormula);

      if (isComplexFormula) {
        const initialTextareaRestrictionContent =
          formatRestrictionText(restriction);
        setTextareaRestrictionContent(initialTextareaRestrictionContent);
      } else {
        const { column, operation, formattedValue, aggregation } =
          parseSimpleRestriction(restriction);

        setColumn({ ...column });
        setOperation(operation);
        setRestrictionValue(formattedValue);
        setAggregation(aggregation);
      }
    } else {
      setColumn({
        etlFieldId: panelData.etlFieldId,
        name: panelData.name,
        type: panelData.type,
      });
      const aggregation =
        panelData.type === 'DATE' ? panelData.function : panelData.aggregation;
      setAggregation(aggregation);
    }
    isInitialized.current = true;
  }, [panelData, parseSimpleRestriction, restriction]);

  const toggleIsFormula = () => {
    setComplexFormulaChecked(!isComplexFormulaChecked);
  };

  const isDisabledValue = isComplexFormulaChecked || (getSimplifiedType(column.type) === 'BOOLEAN' && !aggregation);

  return (
    <>
      <CustomDialog
        isOpen
        onClose={closeConditionModal}
        title={`Условие для столбца «${panelData.name}»`}
        maxWidth="sm"
      >
        <Box className="axis-condition__container">
          {!isComplexFormulaChecked && (
            <Box mb="20px">
              <div className="axis-condition__formula">
                {`Ваша формула: ${formula}`}
              </div>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" mb="20px">
            <SelectInput
              label="Столбец"
              value={column.etlFieldId}
              handleChange={(event) => onColumnChange(event)}
              itemList={sourceFields
                .filter((field: any) => !isComplexMeasure(field))
                .map((value: any) => {
                  return { value: value.etlFieldId, showValue: value.name };
                })}
              disabled={isComplexFormulaChecked || isPivot}
              className="axis-condition__input axis-condition__input--middle"
            />
            <SelectInput
              value={aggregation}
              label={
                getSimplifiedType(column.type) === 'DATE'
                  ? 'Функция'
                  : 'Агрегация'
              }
              handleChange={onAggregationChange}
              itemList={Aggregations[getSimplifiedType(column.type)]?.map(
                (value: any) => ({
                  value: value.formula,
                  showValue: getSimplifiedType(column.type) === 'DATE' ? getConvertedFunctionName(value.name) : getConvertedAggregationName(value.name),
                }),
              )}
              disabled={isComplexFormulaChecked || isPivot}
              className="axis-condition__input axis-condition__input--small"
            />
            <SelectInput
              value={operation}
              label="Условие"
              handleChange={onOperationChange}
              itemList={getOperationsForType(aggregation, column.type)
                ?.map((value: any) => {
                  return { value, showValue: value };
                })}
              className="axis-condition__input axis-condition__input--small"
              disabled={isComplexFormulaChecked}
            />
          </Box>
          <Box>
            <TextInput
              value={restrictionValue}
              label="Введите значение"
              handleChange={onChangeRestrictionValue}
              fullWidth
              disabled={isDisabledValue}
            />
          </Box>
          {!isPivot && (
            <Box className="axis-condition__checkbox-container">
              <CustomFormControl
                control={
                  <CustomCheckbox
                    checked={isComplexFormulaChecked}
                    color="primary"
                    onChange={toggleIsFormula}
                  />
                }
                label="Ввод формулы"
              />
            </Box>
          )}

          {isComplexFormulaChecked && (
            <Box className="axis-condition__complex-formula">
              <Box>
                <InputContainer
                  textInputProps={{
                    label: 'Введите формулу. Выбор поля - @',
                    handleChange: () => null,
                    fullWidth: true,
                  }}
                  itemsList={sourceFields
                    .filter((field: any) => !isComplexMeasure(field))
                    .map((value: any, index: number) => ({
                      key: index,
                      value: value.name,
                      aggregation: null,
                      id: value.etlFieldId,
                    }))}
                  value={textareaRestrictionContent}
                  changeValue={handleRestrictionChange}
                />
              </Box>
              <Box>
                <div className="axis-condition-function">
                  <div className="axis-condition-function__title">
                    <div className="axis-condition-function__description-group">
                      <div className="axis-condition-function__description">
                        Функции
                      </div>
                      <div className="axis-condition-function__formulas-list">
                        <IconButton
                          className={classes.iconButton}
                          onClick={handleMenuClick}
                        >
                          <IconSvg
                            svg={IconDictionary.PlusUnderline}
                            fill="var(--primary-color)"
                          />
                        </IconButton>
                        <Popover
                          classes={{ paper: 'axis-condition__popover' }}
                          id={popoverId}
                          open={isOpenMenu}
                          onClose={handleCloseMenu}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <OperationTypeMenu
                            formulesDictionary={formulasDictionary}
                            chooseTransformationOperation={
                              chooseTransformationOperation
                            }
                          />
                        </Popover>
                      </div>
                    </div>
                    <span className="axis-condition-function__main">
                      {textareaFormulaContent}
                    </span>
                    {Boolean(textareaFormulaContent) && (
                      <IconButton
                        className={classes.iconButton}
                        onClick={copyToClipboard}
                      >
                        <IconSvg
                          svg={IconDictionary.Copy}
                          fill="var(--dark-grey)"
                        />
                      </IconButton>
                    )}
                  </div>
                </div>
              </Box>
              {Boolean(lastFormulaExample) && (
                <Box>
                  <CustomInfoAlert title="Пример">{showExample(lastFormulaExample)}</CustomInfoAlert>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <div className="dialog-buttons">
          <CustomButton
            variant="contained"
            type="submit"
            onClick={onSave}
            disabled={hasValidationError}
            loading={isValidate}
          >
            Сохранить
          </CustomButton>
          <CustomButton
            type="reset"
            onClick={closeConditionModal}
            variant="outlined"
          >
            Отменить
          </CustomButton>
        </div>
      </CustomDialog>
    </>
  );
};

export default AxisRestrictionModal;
