import { styled, Switch } from '@material-ui/core';
import { SwitchProps } from '@material-ui/core/Switch/Switch';
import React from 'react';

const StyledSwitch = styled(Switch)({
  zoom: '68%',

  '& .MuiFormControlLabel-label': {
    color: 'var(--black)',
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: 'calc(16px * var(--scale-coefficient))',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },

  '& [class*=Mui-checked]': {
    color: 'var(--primary-color)',
  },

  '& [class*=Mui-checked] + .MuiSwitch-track': {
    backgroundColor: 'var(--primary-color)',
  }
});

export const CustomSwitch = (props: SwitchProps) => {

  return <StyledSwitch color="primary" {...props} />;
};
