import { Button } from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';
import IconSvg from '../../common/icon-svg/icon-svg';
import { Widget } from '../../../slices/types';
import { getJSONPropertyValue } from '../charts/helpers';
import { PanelType } from '../../../enums/widget-type';
import { MapDataTypes } from '../charts/map/settings';
import { mapModalTypes } from './enums';
import { CustomPopover } from '../../../uikit/Popover/popover';
import { CustomPopoverMenu } from '../../../uikit/PopoverMenu/popover-menu';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { CustomButton } from '../../../uikit/Button';

interface MapPopoverProps {
  onShowModalClick: (type: mapModalTypes) => void;
  widget: Widget;
  index: number;
}

const MapPopover = ({ onShowModalClick, widget, index }: MapPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const wrapperRef = useRef(null);
  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (isOpen ? 'map-popover' : undefined), [isOpen]);

  const handleMoreButtonClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(wrapperRef.current);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const showModal = (
    event: React.MouseEvent<HTMLElement>,
    modalType: mapModalTypes,
  ) => {
    event.stopPropagation();
    onShowModalClick(modalType);
    handleClose(event);
  };

  const dataTypes = getJSONPropertyValue(
    PanelType.axisX,
    widget.properties,
  ).map((item: any) => item.type.toUpperCase());

  const isPolygon =
    dataTypes[index] && dataTypes[index].toUpperCase() === MapDataTypes.POLYGON;
  const isPoint =
    dataTypes[index] && dataTypes[index].toUpperCase().includes(MapDataTypes.POINT);

  const menuList = [
    {
      title: 'Настройка карточки',
      icon: <IconSvg
        svg={IconDictionary.MapCardSetting}
        fill="var(--dark-grey)"
      />,
      onClick: (event: React.MouseEvent<HTMLElement>) => showModal(event, mapModalTypes.cardSettings),
    },
    {
      title: 'Тепловая настройка карты',
      icon: <IconSvg
        svg={IconDictionary.MapHeatSetting}
        fill="var(--dark-grey)"
      />,
      onClick: (event: React.MouseEvent<HTMLElement>) => showModal(event, mapModalTypes.heatMapSettings),
      isHidden: !isPolygon,
    },
    {
      title: 'Настройка фильтров',
      icon: <IconSvg
        svg={IconDictionary.Filter}
        fill="var(--dark-grey)"
      />,
      onClick: (event: React.MouseEvent<HTMLElement>) => showModal(event, mapModalTypes.filter),
    },
    {
      title: 'Настройка точек',
      icon: <IconSvg
        svg={IconDictionary.Point}
        fill="var(--dark-grey)"
      />,
      onClick: (event: React.MouseEvent<HTMLElement>) => showModal(event, mapModalTypes.iconsSettings),
      isHidden: !isPoint
    },
  ];

  return (
    <div className="map-popover" ref={wrapperRef}>
      <CustomButton
        onClick={handleMoreButtonClick}
        variant="text"
        size="small"
        style={{
          padding: '4px 12px',
          marginLeft: 'auto',
          height: 'auto'
        }}
      >
        <IconSvg
          svg={IconDictionary.HorizontalMore}
          fill="var(--dark-grey)"
          height={20}
        />
      </CustomButton>

      <CustomPopover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CustomPopoverMenu settings={menuList} />
      </CustomPopover>
    </div>
  );
};

export default MapPopover;
