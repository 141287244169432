import React, { useCallback, useEffect, useState } from 'react';
import { Roles } from '../../../enums/roles';
import { Project } from '../../../slices/types';
import { LocalStorage } from '../../../enums/local-storage-type';
import './group.css';
import { GroupBody } from './group-body';
import { GroupTitlePanel } from './title-panel/group-title-panel';

type GroupContainerProps = {
  title: string;
  projects?: Project[];
  isFilterActivate: boolean;
  id: number;
  isShow: boolean;
  permission: Roles;
  handleClickChange: () => void;
  handleClickDelete: () => void;
  handleClickCreate: () => void;
};

export const GroupContainer = ({
  title,
  projects,
  isFilterActivate,
  id,
  isShow,
  permission,
  handleClickChange,
  handleClickDelete,
  handleClickCreate,
}: GroupContainerProps) => {
  const [isShowGroup, setShowGroup] = useState<boolean>(false);

  useEffect(() => {
    setShowGroup(isShow);
  }, [isShow]);

  useEffect(() => {
    const isActiveGroup =
      parseInt(
        localStorage.getItem(LocalStorage.ACTIVE_PROJECTS_GROUP_ID) || '',
      ) === id;
    if (isActiveGroup && !isFilterActivate) {
      setShowGroup(isActiveGroup);
    }
  }, [id, isFilterActivate]);

  const handleGroupHeaderClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setShowGroup((prev) => !prev);
    event.stopPropagation();
  }, []);

  return (
    <li className='group-container'>
      <GroupTitlePanel
        title={title}
        handleGroupHeaderClick={handleGroupHeaderClick}
        permission={permission}
        projectsCount={projects?.length || 0}
        groupId={id}
        isShowGroup={isShowGroup}
        handleClickChange={handleClickChange}
        handleClickDelete={handleClickDelete}
        handleClickCreate={handleClickCreate}
      />

      {isShowGroup && (<GroupBody groupId={id} projects={projects} />)}
    </li>
  );
};
