import { Group } from '@visx/group';
import React from 'react';
import {
  HierarchyNode,
  HierarchyRectangularNode,
} from '@visx/hierarchy/lib/types';
import { trim } from 'lodash';
import { useRoundingCounts } from 'src/hooks/useRoundingCounts';
import { PanelType } from 'src/enums/widget-type';
import { TreeMapDataObject } from './types';
import { ScalableSVGText } from '../../../common/scalable-svg-text';
import { WidgetProperties } from '../../../../slices/types';
import {
  DateFormatProperties,
  initValueFormatter,
} from '../hooks/initValueFormatter';
import { formatDate, setPropForNumberValue } from '../formatting-helpers';
import { getSimplifiedType } from '../../dropdown-layout/helpers/helpers';
import { Property, PropertyData } from '../../dropdown-layout/helpers/Property';
import { WidgetSimplifiedDataType } from '../../../../enums/data-type';

const BACKGROUND_COLOR = '#e8e8e8';
const STROKE_WIDTH = 2;

export type TreeMapCellProps = {
  node: HierarchyRectangularNode<HierarchyNode<TreeMapDataObject>>;
  sliceName?: string;
  sliceIndex: number;
  cellIndex: number;
  axisXValuesFull: any[];
  axisYValuesFull: any[];
  dateDisplayFormat?: string | undefined;
  dataSliceProperties?: PropertyData;
  handleWidgetMouseLeave: (event: React.MouseEvent) => false | undefined;
  handleWidgetMouseMove: (
    event: any,
    data: { valueX: string; attrX: string, valueY: string; attrY: string, slice: string | undefined },
  ) => void;
  getCellColor: (
    sliceIndex: number,
    cellIndex: number,
    value: number,
    isSelected: boolean,
  ) => string;
  isFilterActive: boolean;
  getIsSelected: (value: string) => boolean;
  onClickDatum: (label: string) => void;
  widgetProperties: WidgetProperties[];
  displayTextOnDiagram: boolean;
  displayValueOnDiagram: boolean;
  displayPercentOnSector: boolean;
};

const TreeMapCell = ({
  node,
  sliceName,
  dataSliceProperties,
  sliceIndex = 0,
  cellIndex = 0,
  axisXValuesFull,
  axisYValuesFull,
  dateDisplayFormat,
  handleWidgetMouseLeave,
  handleWidgetMouseMove,
  getCellColor,
  isFilterActive,
  getIsSelected,
  onClickDatum,
  widgetProperties,
  displayTextOnDiagram,
  displayValueOnDiagram,
  displayPercentOnSector,
}: TreeMapCellProps) => {
  const nodeWidth = node.x1 - node.x0;
  const nodeHeight = node.y1 - node.y0;

  const textPadding = 6;
  const fontSize = 14;
  const lineHeight = fontSize + textPadding;

  const scalableSVGTextProperties = {
    width: nodeWidth - textPadding,
    height: lineHeight,
    svgTextStyles: {
      color: 'var(--white)',
      fontSize,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight,
      innerTextAlign: 'start',
    },
    isLineHeightPriority: true,
  };

  const roundingCount = useRoundingCounts(widgetProperties);
  const valueFormat = initValueFormatter({ roundingCount });
  const getFormattedValue = (count: number) =>
    valueFormat(count, 0, setPropForNumberValue(widgetProperties), PanelType.axisY);

  const dateFormatProperties: DateFormatProperties = {
    function: dataSliceProperties ? (new Property(dataSliceProperties).getFunction() || '') : '',
    dataType: dataSliceProperties?.type || '',
    dateDisplayFormat: dateDisplayFormat || '',
  };

  const currentName = JSON.parse(axisYValuesFull[0]?.storage || '{}')?.alias || axisYValuesFull[0]?.name;
  const sliceFormattedName = getSimplifiedType(dateFormatProperties?.dataType) === WidgetSimplifiedDataType.DATE ?
    formatDate(sliceName!, dateFormatProperties) : sliceName;

  const sliceNameLabel = dataSliceProperties && trim(currentName) ? sliceFormattedName : undefined;
  const currentNameLabel = trim(currentName) || (dataSliceProperties ? '' : ' ');

  const isNeedToDisplaySliceName = nodeHeight - lineHeight >= lineHeight;

  const percentYOffset = displayValueOnDiagram ? lineHeight : 0;
  const percentYOffsetBottom = displayPercentOnSector ? percentYOffset + lineHeight : percentYOffset;

  const sliceNameYOffset = percentYOffsetBottom;
  const sliceNameYOffsetBottom = sliceNameYOffset + lineHeight;

  const textYOffset = sliceNameYOffsetBottom;

  const tooltipValue = `${getFormattedValue(node.data.data.size!)}${
    displayPercentOnSector
      ? ` (${getFormattedValue(node.data.data.percent!)}%)`
      : ''
  }`;

  return (
    <Group
      top={node.y0}
      left={node.x0}
      onMouseLeave={handleWidgetMouseLeave}
      onMouseMove={(event) =>
        handleWidgetMouseMove(event, {
          attrX: `${axisXValuesFull[0].name}`,
          valueX: `${node.data.id || ''}`,
          attrY: currentNameLabel,
          valueY: tooltipValue,
          slice: sliceNameLabel,
        })}
      onClick={(e) => {
        e.stopPropagation();
        handleWidgetMouseLeave(e);
        onClickDatum(node.data.id || '');
      }}
    >
      <rect
        width={nodeWidth}
        height={nodeHeight}
        stroke={BACKGROUND_COLOR}
        strokeWidth={STROKE_WIDTH}
        fill={getCellColor(
          sliceIndex,
          cellIndex,
          node.value || 0,
          getIsSelected(node.data.id || ''),
        )}
        opacity={isFilterActive && !getIsSelected(node.data.id || '') ? 0.2 : 1}
      />
      {displayValueOnDiagram && (
        <ScalableSVGText
          x={textPadding / 2}
          formattedValue={getFormattedValue(node.data.data.size!)}
          properties={{
            ...scalableSVGTextProperties,
            lineCount: 1
          }}
        />
      )}
      {displayPercentOnSector && (
        <ScalableSVGText
          x={textPadding / 2}
          y={percentYOffset}
          formattedValue={`${getFormattedValue(node.data.data.percent!)}%`}
          properties={{
            ...scalableSVGTextProperties,
            lineCount: 1
          }}
        />
      )}

      {displayTextOnDiagram && (
        <>
          {isNeedToDisplaySliceName && (
            <ScalableSVGText
              x={textPadding / 2}
              y={sliceNameYOffset}
              formattedValue={sliceFormattedName}
              properties={{
                ...scalableSVGTextProperties,
                lineCount: 1,
              }}
              widgetProperties={widgetProperties}
            />
          )}
          <ScalableSVGText
            x={textPadding / 2}
            y={textYOffset}
            formattedValue={node.data.id}
            properties={{
              ...scalableSVGTextProperties,
              height: Math.max(nodeHeight - textYOffset - textPadding, 0),
              lineCount: Math.max(Math.floor((nodeHeight - textYOffset - textPadding) / lineHeight), 0),
            }}
            widgetProperties={widgetProperties}
          />
        </>
      )}
    </Group>
  );
};

export default TreeMapCell;
