import { v4 as uuid } from 'uuid';
import { apiBI, apiETL, checkAccess } from '../utils/api';
import { DashboardProperty, MeasureProps, Widget, WidgetProperties } from '../slices/types';
import { PropertyData } from '../components/widget-page/dropdown-layout/helpers/Property';
import { WidgetType } from '../enums/widget-type';

export const SOURCES_URL = '/sources';

export const apiFetchSourceData = async (sourceId: string): Promise<any> => {
  try {
    const response = await apiBI.get(`${SOURCES_URL}/${sourceId}`);

    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiFetchLoaderSources = async (
  loaderId: number,
  type?: string,
) => {
  const params = {
    widgetType: type,
  };

  try {
    const response = await apiBI.get(`${SOURCES_URL}/by-loader/${loaderId}`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiFetchSearchResult = async (
  sourceId?: string,
  searchString?: string,
) => {
  const params = {
    q: searchString,
  };
  try {
    const response = await apiBI.get(`${SOURCES_URL}/${sourceId}/search`, {
      params,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetWidgetTypes = async () => {
  try {
    const response = await apiBI.get('/dictionaries/widgets');
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCreateNewWidget = async (parameters: Widget) => {
  try {
    const response = await apiBI.post('/widgets', parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiUpdateWidget = async (parameters: Widget) => {
  try {
    const response = await apiBI.put('/widgets', parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetAllProjectWidgets = async (projectId: number) => {
  try {
    const response = await apiBI.get(`/widgets/by-project/${projectId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetGroupWidgets = async (groupId: number) => {
  try {
    const response = await apiBI.get(`/widgets/by-group/${groupId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetWidgetById = async (widgetId: number) => {
  try {
    const response = await apiBI.get(`/widgets/${widgetId}`);
    return response.data;
  } catch (error: any) {
    checkAccess(error);
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetWidgetByIds = async (widgetIds: number[]) => {
  try {

    const promises = widgetIds.map((widgetId) => apiBI.get(`/widgets/${widgetId}`));

    const results = await Promise.all(promises);
    return results.map((result) => result.data);
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetWidgetDataById = async (
  parameters: { widgetId: number, requestParams?: any, id?: string; signal?: AbortSignal }
) => {
  const params = {
    requestBody: parameters.requestParams,
    requestId: parameters.id || uuid()
  };

  try {
    const response = await apiBI.post(
      `/widgets/data/${parameters.widgetId}`,
      params,
      { signal: parameters.signal }
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
};

export const apiGetWidgetData = async (parameters: {widget: Widget, id?: string; signal?: AbortSignal}) => {
  const params = {
    requestBody: parameters.widget,
    requestId: parameters.id || uuid()
  };

  try {
    const response = await apiBI.post('/widgets/data/preview', { ...params }, { signal: parameters.signal });

    if (response) return response.data;
  } catch (error: any) {
    console.error(
      `Request failed: ${error.length ? error.response.status : 'No response'}`,
    );
    throw error;
  }
};

export const deleteWidgetById = async (
  widgetId: number | undefined,
  force: boolean = false,
) => {
  try {
    const response = await apiBI.delete(`/widgets/${widgetId}?force=${force}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiNewSourcesToCache = async (data: string[]) => {
  try {
    const response = await apiETL.put('/bi/source-objects/to-cache', data);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiUpdateSourcesToCache = async (data: string[]) => {
  try {
    const response = await apiETL.put('/bi/source-objects/update-cache', data);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiDeleteSourcesFromCache = async (data: string[]) => {
  try {
    const response = await apiETL.delete('/bi/source-objects/del-cache', {
      data,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiUpdateSourcesFields = async (data: string[]) => {
  try {
    const response = await apiBI.put('/fields/by-source', data);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiRunCache = async (loaderId: number) => {
  try {
    const response = await apiETL.get(`/bi/loaders/${loaderId}/run`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiUpdateSourceMap = async (loaderId: number) => {
  try {
    const response = await apiETL.get(`/loaders/${loaderId}?view=FULL`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetWidgetsListBySourceAndField = async (parameters: {
  field: string;
  source: string;
}) => {
  try {
    const response = await apiBI.get(
      `/widgets/by-source-and-field?field=${parameters.field}&source=${parameters.source}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetFilterListBySourceAndField = async (parameters: {
  field: string;
  filters: any;
  widgetId?: number;
  query?: string;
  id?: string;
  signal?: AbortSignal;
}) => {
  const params = {
    requestBody: parameters.filters,
    requestId: parameters.id || uuid()
  };

  try {
    const response = await apiBI.post(
      '/fields/options/',
      params,
      {
        signal: parameters.signal,
        params: {
          field: parameters.field,
          widget_id: parameters.widgetId,
          query: parameters.query
        }
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetFilterListByDashboardFilterId = async (parameters: {
  field: string;
  filters: any;
  filterGroupId?: number;
  query?: string;
  id?: string,
  signal?: AbortSignal
}) => {
  const params = {
    requestBody: parameters.filters,
    requestId: parameters.id || uuid()
  };

  try {
    const response = await apiBI.post(
      '/fields/dashboardOptions/',
      params,
      {
        signal: parameters.signal,
        params: {
          dashboard_filter_id: parameters.filterGroupId,
          query: parameters.query
        }
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

/* Актуализирует схему источника, возвращает список виджетов, в которых используются удаленные или изменившие тип поля */
export const apiUpdateSchemeBySourceObjectGetWidgets = async (
  sourceObjectId: string,
) => {
  try {
    const response = await apiBI.get(`${SOURCES_URL}/${sourceObjectId}/schema`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
};

export const apiCopyWidget = async (widgetId: number | undefined) => {
  try {
    const response = await apiBI.post(`/widgets/${widgetId}/copy`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiValidateRestrictions = async (projectId: number, property: PropertyData, pushDown: boolean, requestId?: string) => {
  const params = {
    requestBody: property,
    requestId: requestId || uuid()
  };

  try {
    const response = await apiBI.post(`fields/${projectId}/validateRestriction?pushdown=${pushDown}`, params);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.status}`);
    throw error;
  }
};

export const apiValidateColoring = async (projectId: number, coloring: PropertyData, pushDown: boolean, requestId?: string) => {
  const params = {
    requestBody: coloring,
    requestId: requestId || uuid()
  };

  try {
    const response = await apiBI.post(`fields/${projectId}/validateColoring?pushdown=${pushDown}`, params);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.status}`);
    throw error;
  }
};

export const apiAddCalculatedField = async (projectId: number, data: MeasureProps, requestId?: string) => {
  const params = {
    requestBody: data,
    requestId: requestId || uuid()
  };

  try {
    const response = await apiBI.post(`/fields/calculated/${projectId}`, params);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiUpdateCalculatedField = async (projectId: number, id: number, data: MeasureProps, requestId?: string) => {
  const params = {
    requestBody: data,
    requestId: requestId || uuid()
  };

  try {
    const response = await apiBI.put(`/fields/calculated/${projectId}/${id}`, params);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiDeleteCalculatedField = async (
  id: number | undefined,
  force: boolean = false,
) => {
  try {
    const response = await apiBI.delete(`/fields/calculated/${id}?force=${force}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};



export const apiGetMapPopUpListsByWidgetId = async (requestParams: {
  dashboardProperties?: DashboardProperty[],
  widgetOverriddenProperties?: WidgetProperties[]
  widgetId?: number;
  properties?: WidgetProperties[];
  type?: WidgetType;
  sourceId?: string;
  pushDown?: boolean;
  needCache?: boolean;
  dashboardId?: boolean;
}, requestId?: string
) => {

  const sendingParams = {
    widgetOverriddenProperties: requestParams.widgetOverriddenProperties,
    dashboardProperties: requestParams.dashboardProperties,
    dashboardId: requestParams.dashboardId,
  };

  const { widgetId } = requestParams;
  const params = {
    requestBody: sendingParams,
    requestId: requestId || uuid()
  };

  try {
    const response = await apiBI.post(
      `/widgets/data/${widgetId}/popUpCards`,
      params,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetMapPopUpLists = async (requestParams: {
  dashboardProperties?: DashboardProperty[],
  widgetOverriddenProperties?: WidgetProperties[]
  widgetId?: number;
  properties?: WidgetProperties[];
  type?: WidgetType;
  sourceId?: string;
  pushDown?: boolean;
  needCache?: boolean;
  projectId?: number;
}, requestId?: string
) => {
  const params = {
    requestBody: requestParams,
    requestId: requestId || uuid(),
  };

  try {
    const response = await apiBI.post(
      '/widgets/data/popUpCards',
      params,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiWidgetValidatePushDown = async (parameters: Widget, requestId?: string): Promise<boolean> => {
  const params = {
    requestBody: parameters,
    requestId: requestId || uuid()
  };

  try {
    const response = await apiBI.post('/widgets/validatePushDown', params);
    return response.data as boolean;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiResetPushDown = async (data: string[]) => {
  try {
    const response = await apiBI.put('/widgets/resetPushDown', data);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiExportWidget = async (
  widgetId: number | undefined,
  type: string,
  requestParams: any = [],
  requestId?: string,
) => {
  const params = {
    requestBody: requestParams,
    requestId: requestId || uuid(),
  };

  try {
    const response = await apiBI.post(
      `/export/widget/${widgetId}/${type}`,
      params,
      { responseType: 'blob' },
    );
    return response;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiReplaceSource = async (oldSourceObjectId: string, newSourceObjectId: string) => {
  try {
    const response = await apiETL.put(`${SOURCES_URL}/replace`, {}, { params: { oldSourceObjectId, newSourceObjectId } });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};
