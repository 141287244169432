import React, { memo, useState } from 'react';
import './styles.css';
import clsx from 'clsx';
import { CssVariables } from '../../../../enums/css-variables';
import { varcss } from '../../../../helpers/ui-helper';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { useDashboardSettings } from '../../settings/hooks/useDashboardSettings';
import { Filter, FilterGroupProps } from '../../hooks';
import { DashboardFilter } from '../dashboard-filter';
import { isDashboardPreviewPage } from '../../../widget-page/charts/helpers';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import { getAllFieldFilters } from '../../../../helpers/dashboard-page';
import { getFilterSettingValue } from '../../../../helpers/dashboard-page/filters-helper';
import { FilterProperty } from '../../../../enums/dashboard-properties';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import {
  getTextValueForQuickFilter,
  getTextValueForQuickFilterTooltip,
} from '../helper';
import { CustomButton } from '../../../../uikit/Button';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

const QuickFilters = memo(() => {
  const { showQuickFiltersPanel } = useDashboardSettings();
  const [isCollapsed, setCollapsed] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const isOpenFilterEdit = Boolean(anchorEl);
  const [editableFilter, setEditableFilter] = useState<{
    filter: FilterGroupProps;
    changeValue: (filters: Filter[], overriddenFunction?: string) => void;
  } | null>(null);

  const usableFilters = useDashboardFilters();

  const filtersValues = usableFilters.values
    .map((filter, index) => ({ ...filter, index }))
    .filter((filter: FilterGroupProps) => {
      const isVisible = isDashboardPreviewPage()
        ? getFilterSettingValue(filter, FilterProperty.isVisible)
        : true;
      return !(
        !filter.actual ||
        !isVisible ||
        getFilterSettingValue(filter, FilterProperty.isDisplayedOnTop) ||
        filter.widgetFilters.filter((widget) => widget.actual).length === 0
      );
    });

  const handleFilterEditOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    filter: FilterGroupProps,
    changeValue: (filters: Filter[]) => void,
  ) => {
    setEditableFilter({
      filter,
      changeValue,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleFilterEditClose = () => {
    setEditableFilter(null);
    setAnchorEl(null);
  };

  const isRender = showQuickFiltersPanel && Boolean(filtersValues.length);

  if (!isRender) return null;

  return (
    <div className="quick-filters">
      <div
        className={clsx('quick-filters__filters', {
          'quick-filters__filters_hide': isCollapsed,
        })}
      >
        {filtersValues.map((filter) => {
          const changeValue = (
            filters: Filter[],
            selectDateType?: string,
            overriddenFunction?: string,
          ) => {
            usableFilters &&
              usableFilters.changeFiltersOperation({
                index: filter.index,
                filters,
                overriddenFunction,
                selectDateType,
              });
          };

          return (
            <>
              <div
                key={filter.id}
                className="quick-filters__filter"
                style={
                  filter.id === editableFilter?.filter.id
                    ? { backgroundColor: '#DAE7ED' }
                    : {}
                }
                onClick={(e) => handleFilterEditOpen(e, filter, changeValue)}
              >
                <CustomTooltip
                  title={getTextValueForQuickFilterTooltip(filter)}
                >
                  <span className="quick-filters__filter-text">
                    {getTextValueForQuickFilter(filter)}
                  </span>
                </CustomTooltip>
              </div>
            </>
          );
        })}
      </div>
      <CustomButton
        size={isCollapsed ? 'small' : 'medium'}
        variant="text"
        icon={
          <IconSvg
            svg={isCollapsed ? IconDictionary.CompactArrowToBottom : IconDictionary.CompactArrowToTop}
            fill="var(--primary-color)"
          />
        }
        onClick={() => setCollapsed((prev) => !prev)}
        className="quick-filters__arrow"
      />

      {editableFilter && (
        <CustomPopover
          open={isOpenFilterEdit}
          anchorEl={anchorEl}
          onClose={handleFilterEditClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          backgroundColor={varcss(CssVariables.MAIN_BACKDROP_COLOR)}
        >
          <div className="quick-filters__filter-wrapper">
            <DashboardFilter
              property={editableFilter.filter}
              handleChangeFilter={editableFilter.changeValue}
              closeFilterPanel={handleFilterEditClose}
              allFieldFilters={getAllFieldFilters(
                usableFilters.values,
                editableFilter.filter.id,
                true,
              )}
              hideLabel
            />
          </div>
        </CustomPopover>
      )}
    </div>
  );
});

QuickFilters.displayName = 'QuickFilters';

export default QuickFilters;
