import React, { memo, useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDashboardPropAction,
  setWidgetIdToDashboardAction,
} from '../../../slices/dashboard/dashboard';
import { setDragIsActiveAction } from '../../../slices/drag-and-drop/drag-and-drop';
import { State } from '../../../slices/types';
import { isNeedToAddWidgetOnDashboard } from '../../../helpers/dashboard-page';
import { DashboardPropertyType } from '../../../enums/dashboard-properties';

type Props = {
  children?: React.ReactNode;
};

const DropdownLayout: React.FC<Props> = memo(({ children }) => {
  const dashboard = useSelector((state: State) => state.dashboard);
  const dispatch = useDispatch();

  const onDragStart = () => {
    dispatch(setDragIsActiveAction(true));
  };

  const onDragEnd = useCallback(
    (result: any) => {
      dispatch(setDragIsActiveAction(false));

      const widgetData = JSON.parse(result.draggableId);

      const isDropDestinationCorrect =
        result.destination?.droppableId === 'dashboard';

      if (isDropDestinationCorrect && isNeedToAddWidgetOnDashboard(dashboard, +widgetData.id)) {
        const layout = dashboard?.properties.find(
          (prop) => prop.name === DashboardPropertyType.layout,
        ) || { name: '', value: '[]' };

        const layoutItems = [...JSON.parse(layout.value as string)];

        if (layoutItems.some((item) => item.id === widgetData.id)) {
          return;
        }

        layoutItems.push({
          i: String(widgetData.id),
          x: 0,
          y: 0,
          w: 4,
          h: 6,
          id: widgetData.id,
          name: widgetData.name,
          type: widgetData.type,
        });

        dispatch(
          setDashboardPropAction({
            name: DashboardPropertyType.layout,
            value: JSON.stringify(layoutItems),
          }),
        );

        dispatch(setWidgetIdToDashboardAction(parseInt(widgetData.id)));
      }
    },
    [dashboard.properties, dispatch],
  );

  return (
    <div className="dropdown-layout">
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {children}
      </DragDropContext>
    </div>
  );
});

DropdownLayout.displayName = 'DropdownLayout';

export default DropdownLayout;
