import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Group } from '@visx/group';
import { Treemap, treemapBinary } from '@visx/hierarchy';
import { useDebouncedCallback } from 'use-debounce';
import { Widget } from '../../../../slices/types';
import './index.css';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { useEventListener } from '../../../../hooks/useEventListener';
import { getTooltipHandler } from '../../../common/widget-tooltip/widget-tooltip';
import TreeMapCell from './tree-map-cell';
import { useWidgetColors, useWidgetData, useWidgetProperties } from './hooks';
import { Property } from '../../dropdown-layout/helpers/Property';
import clsx from 'clsx';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';

const MIN_WIDTH = 150;
const MIN_HEIGHT = 60;

export type TreeMapProps = {
  widgetProps: Widget;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
};

const TreeMap = ({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}: TreeMapProps) => {
  const widgetProperties = widgetProps.properties;
  const widgetId = widgetProps.id || 0;

  const isFullScreen = useWidgetFullScreen(widgetId);

  const {
    axisXValuesFull,
    axisYValuesFull,
    displayTextOnDiagram,
    displayValueOnDiagram,
    displayPercentOnSector,
    dataSlice,
    dateDisplayFormat,
  } = useWidgetProperties(widgetProperties);
  const { data, root, isSliced } = useWidgetData(widgetProps);
  const { getCellColor } = useWidgetColors({
    widgetProperties,
    root,
    isSliced,
  });

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const dataSliceProperties = dataSlice[0];

  const viewportRef = useRef(document.createElement('div'));

  const debounceDelay = 30;

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setWidth(value);
  }, debounceDelay);

  const calculateDimensions = useCallback(() => {
    const currentWidth = viewportRef.current?.clientWidth;
    const currentHeight = viewportRef.current?.clientHeight;

    if (currentWidth !== width) {
      setDebouncedWidth.callback(Math.max(currentWidth, MIN_WIDTH));
    }

    if (currentHeight !== height) {
      setDebouncedHeight.callback(Math.max(currentHeight, MIN_HEIGHT));
    }
  }, [width, height, setDebouncedWidth, setDebouncedHeight]);

  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
  }, [calculateDimensions, isFullScreen]);

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(viewportRef);

  const otherFieldLabel = 'Прочее';
  const otherFieldOperation = 'NOT IN';
  const defaultFieldOperation = '=';

  const deselectCell = useCallback(() => {
    setFilterField && setFilterField(widgetId, null, []);
  }, [setFilterField, widgetId]);

  const selectCell = useCallback(
    (label: string, sliceIndex?: number) => {
      if (!isActiveFilter) return;
      const isClickOtherField = label === otherFieldLabel;

      const isOtherField =
        filterField.operation === otherFieldOperation && isClickOtherField;

      if (filterField?.value?.includes(label) || isOtherField) {
        deselectCell();
      } else {
        if (!setFilterField) return;

        // тут нужно получить все оставшиеся значения кроме Прочее
        const data = (
          (isSliced && sliceIndex
            ? root.children?.[sliceIndex].children
            : root.children) || []
        ).map((item) => item.data.id || '');
        const value: string[] = isClickOtherField
          ? data.filter((label) => label !== otherFieldLabel)
          : [label];

        setFilterField(widgetId, new Property(axisXValuesFull[0]).getId(), [
          {
            operation: isClickOtherField
              ? otherFieldOperation
              : defaultFieldOperation,
            value,
          },
        ]);
      }
    },
    [
      isActiveFilter,
      filterField,
      deselectCell,
      setFilterField,
      isSliced,
      root.children,
      widgetId,
      axisXValuesFull,
    ],
  );

  const getIsSelected = (value: string) => {
    return filterField?.operation === otherFieldOperation
      ? value === otherFieldLabel
      : !!filterField?.value?.includes(value);
  };

  return (
    <div className="widget-treemap__container" ref={viewportRef}>
      <svg className="widget-treemap__svg" width={width} height={height}>
        <Treemap<typeof data>
          root={root}
          size={[width, height]}
          tile={treemapBinary}
        >
          {(treemap) => {
            return (
              <Group className={clsx({ 'active-drilldown-element': isActiveFilter })}>
                {isSliced && treemap.children
                  ? treemap.children.map((slice, sliceIndex: number) => {
                    return (
                      slice.children &&
                      slice.children.map((cell, cellIndex: number) => {
                        return (
                          <TreeMapCell
                            key={`cell-${cellIndex}`}
                            node={cell}
                            sliceIndex={sliceIndex}
                            sliceName={slice.data.id}
                            cellIndex={cellIndex}
                            dateDisplayFormat={dateDisplayFormat}
                            dataSliceProperties={dataSliceProperties}
                            axisXValuesFull={axisXValuesFull}
                            axisYValuesFull={axisYValuesFull}
                            handleWidgetMouseLeave={handleWidgetMouseLeave}
                            handleWidgetMouseMove={handleWidgetMouseMove}
                            getCellColor={getCellColor}
                            isFilterActive={Boolean(filterField?.value)}
                            getIsSelected={getIsSelected}
                            onClickDatum={(label: string) =>
                              selectCell(label, sliceIndex)}
                            widgetProperties={widgetProperties}
                            displayTextOnDiagram={displayTextOnDiagram}
                            displayValueOnDiagram={displayValueOnDiagram}
                            displayPercentOnSector={displayPercentOnSector}
                          />
                        );
                      })
                    );
                  })
                  : treemap.children &&
                    treemap.children.map((cell, cellIndex: number) => {
                      return (
                        <TreeMapCell
                          key={`cell-${cellIndex}`}
                          node={cell}
                          sliceIndex={0}
                          cellIndex={cellIndex}
                          axisXValuesFull={axisXValuesFull}
                          axisYValuesFull={axisYValuesFull}
                          handleWidgetMouseLeave={handleWidgetMouseLeave}
                          handleWidgetMouseMove={handleWidgetMouseMove}
                          getCellColor={getCellColor}
                          isFilterActive={Boolean(filterField?.value)}
                          getIsSelected={getIsSelected}
                          onClickDatum={selectCell}
                          widgetProperties={widgetProperties}
                          displayTextOnDiagram={displayTextOnDiagram}
                          displayValueOnDiagram={displayValueOnDiagram}
                          displayPercentOnSector={displayPercentOnSector}
                        />
                      );
                    })}
              </Group>
            );
          }}
        </Treemap>
      </svg>
    </div>
  );
};

export default TreeMap;
