import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../slices/types';
import { CustomButton } from '../../../uikit/Button';
import './info-block.css';
import { getErrorDescription, getErrorTitle } from '../helper';
import { LocalStorage } from '../../../enums/local-storage-type';

export const InfoBlock = () => {
  const handleClickReturn = () => {
    window.location.href = process.env.PUBLIC_URL || '/';
  };

  const status = useSelector((state: State) => state.errorPage.code) || '';

  const title = getErrorTitle(status);
  const description = getErrorDescription(status);

  return (
    <div className="error-page__info-container">
      <div className="error-page__title">{title}</div>
      <div className="error-page__description">
        {description.map((text) => (
          <p key={text}>{text}</p>
        ))}
      </div>
      <div>
        <CustomButton variant="contained" onClick={handleClickReturn}>
          На главную страницу
        </CustomButton>
      </div>
    </div>
  );
};
