import {
  CircularProgress,
  CircularProgressProps,
  LinearProgressProps,
  styled,
} from '@material-ui/core';
import React, { CSSProperties } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const StyledCircularProgress = styled(CircularProgress)({
  color: 'var(--primary-color)'
});

const StyledLinearProgress = styled(LinearProgress)({
  backgroundColor: 'var(--white)',

  '& [class*=MuiLinearProgress-bar]': {
    backgroundColor: 'var(--primary-color)'
  }
});

interface CustomProgressProps extends Omit<CircularProgressProps, 'color' | 'classes' | 'variant'>, Omit<LinearProgressProps, 'classes'> {
  color?: 'primary' | 'secondary';
  type: 'circular' | 'linear';
  style?: CSSProperties;
}

export const CustomProgress = ({
  type,
  style,
  ...props
}: CustomProgressProps) => {
  switch (type) {
    case 'circular':
      return <StyledCircularProgress style={style} {...(props as CircularProgressProps)} />;
    case 'linear':
      return <StyledLinearProgress style={style} {...(props as LinearProgressProps)} />;
    default:
      return <StyledCircularProgress style={style} {...(props as CircularProgressProps)} />;
  }
};
