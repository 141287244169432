import React, { useCallback, useEffect, useRef, useState } from 'react';
import './color-palette.css';
import { Button, makeStyles, Popper } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  apiGetColorPalettes,
  apiSetColorPalettes,
} from '../../../../../services';
import {
  setColorPalettesAction,
  setEditableColorPaletteAction,
} from '../../../../../slices/main-page/main-page-slice';
import Dictionaries from '../../../../../types/dictionaries';
import { State } from '../../../../../slices/types';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import DeleteDialog from '../../../../../uikit/DeleteDialog/delete-dialog';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import { CustomButton } from '../../../../../uikit/Button';

type ColorPaletteProps = {
  handleChange: (value: any) => void;
  value: any;
};

const popperModifiersParams = {
  flip: {
    enabled: false,
  },
  offset: {
    enabled: true,
    offset: '0, 5',
  },
};

const useStyles = makeStyles({
  button: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center',
    alignSelf: 'center',
    height: 'calc(46px * var(--scale-coefficient))',
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: 'calc(22px * var(--scale-coefficient))',
    padding: '0 calc(15px * var(--scale-coefficient))',
    textTransform: 'none',
    backgroundColor: 'var(--dark-cyan)',
    color: 'var(--title-color)',
  },
});

const getNewPaletteTemplate = (): Dictionaries.ColorPalette => {
  return {
    description: 'Новая палитра',
    name: uuid(),
    firstColor: '',
    colorsList: [],
  };
};

const ColorPalette: React.FC<ColorPaletteProps> = ({
  handleChange,
  value,
}) => {
  const dispatch = useDispatch();

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [propsForPaletteDelete, setPropsForPaletteDelete] = useState<any>({});

  const toggleDeleteDialog = () => {
    setShowDeleteDialog((prevState) => !prevState);
  };

  const classes = useStyles();

  const [isOpenSelect, setSelectState] = useState<boolean>(false);
  const [firstColorAnchor, setFirstColorAnchor] = React.useState(null);

  const firstColorRef = useRef(document.createElement('div'));

  const palettes = useSelector((state: State) => state.mainPage.colorPalettes);

  const editableColorPalette = useSelector(
    (state: State) => state.mainPage.editableColorPalette,
  );
  const isDeletingInProgress = useSelector(
    (state: State) => state.mainPage.isDeleting,
  );

  const handleBlockChange = (option: any, optionValue: any) => {
    if (option.name !== optionValue.name) {
      handleChange(option);
    }
  };

  const handleFirstColorChange = (color: any) => {
    if (value.firstColor !== color) {
      const newValue: any = { ...value };
      newValue.firstColor = color;
      handleChange(newValue);
    }
  };

  const toggleFirstColorSelect = useCallback(
    (e: any) => {
      setSelectState(!isOpenSelect);
      setFirstColorAnchor(firstColorRef.current as any);
    },
    [firstColorRef, isOpenSelect],
  );

  const handleCloseEditPalette = () => {
    dispatch(setEditableColorPaletteAction(null));
  };

  const handleOpenEditPalette = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    name: string,
  ) => {
    if (editableColorPalette?.name === name) {
      handleCloseEditPalette();
    } else {
      const palette = palettes.find((palette) => palette.name === name) ?? null;
      dispatch(setEditableColorPaletteAction(palette));
    }
    event.stopPropagation();
  };

  const handleCreateNewPalette = () => {
    dispatch(setEditableColorPaletteAction(getNewPaletteTemplate()));
  };

  const updateColorPalettes = (colorPalettes: any) => {
    const loadedPalettes = colorPalettes.options?.map((palette: any) => JSON.parse(palette)) || [];
    dispatch(setColorPalettesAction(loadedPalettes));

    if (loadedPalettes.length) {
      const foundIndex = loadedPalettes.findIndex(
        (palette: any) => palette.name === value.name,
      );
      const index = foundIndex < 0 ? 0 : foundIndex;
      const firstColor =
        foundIndex >= 0 &&
        loadedPalettes[index].colorsList.includes(value.firstColor)
          ? value.firstColor
          : loadedPalettes[index].firstColor;
      handleChange({ ...loadedPalettes[index], firstColor });
    }
  };

  const onDeleteClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    toggleDeleteDialog();
    setPropsForPaletteDelete({ event, index, name: palettes[index].description });
  };

  const handleDeletePalette = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    const newPalettes = palettes.filter((item, inx) => inx !== index);

    apiSetColorPalettes(newPalettes).then(() => {
      apiGetColorPalettes().then(updateColorPalettes);
    });
    event.stopPropagation();
    setPropsForPaletteDelete({});
    toggleDeleteDialog();
  };

  useEffect(() => {
    setSelectState(false);
  }, [value.name]);

  const isExistsEditableColorPalette = Boolean(editableColorPalette);

  useEffect(() => {
    apiGetColorPalettes().then(updateColorPalettes);
  }, [isExistsEditableColorPalette]);

  return (
    <div>
      {palettes.map((palette: any, index) => {
        const isActive = palette.name === value.name;
        const colorIcon =
          palette.name === editableColorPalette?.name ? 'var(--primary-color)' : 'var(--dark-grey)';
        return (
          <div
            key={palette.name}
            className={`color-palette ${isActive ? 'active' : ''}`}
            onClick={() => {
              handleBlockChange(palette, value);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h4 style={{ flexGrow: 1 }}>{palette.description}</h4>
              <span>
                <div
                  className="color-palette__wrap_icon"
                  onClick={(e) => handleOpenEditPalette(e, palette.name)}
                >
                  <IconSvg
                    svg={IconDictionary.ListEdit}
                    fill={colorIcon}
                  />
                </div>
              </span>
              <span>
                <div
                  className="color-palette__wrap_icon"
                  onClick={(e) => onDeleteClick(e, index)}
                >
                  <IconSvg
                    svg={IconDictionary.BasketDelete}
                    fill="var(--dark-grey)"
                  />
                </div>
              </span>
            </div>
            <div className="color-palette__item-container">
              {palette.colorsList.map((color: any, index: number) => {
                return (
                  <div
                    className="color-palette__item"
                    key={index}
                    style={{ background: color }}
                  />
                );
              })}
            </div>
            {isActive && (
              <div>
                <div
                  ref={firstColorRef}
                  className="color-palette__first-color"
                  onClick={toggleFirstColorSelect}
                >
                  <div
                    className="color-palette__first-color-item"
                    style={{ background: value.firstColor }}
                  />
                  <div className="color-palette__first-color-text">
                    Первый цвет палитры
                  </div>
                  <div className="color-palette__first-color-arrow">
                    <IconSvg
                      svg={
                        isOpenSelect
                          ? IconDictionary.SelectArrowUp
                          : IconDictionary.SelectArrowDown
                      }
                      width={20}
                      height={20}
                      fill={
                        isOpenSelect ? 'var(--primary-color)' : 'var(--dark-grey)'
                      }
                    />
                  </div>
                </div>
                <Popper
                  placement="bottom-end"
                  className="color-palette__first-color__dropdown"
                  id="firstColor"
                  open={isOpenSelect}
                  modifiers={popperModifiersParams}
                  anchorEl={firstColorAnchor}
                >
                  <div className="color-palette__first-color__dropdown-outer-container">
                    <div className="color-palette__first-color__dropdown-container">
                      {palette.colorsList.map((color: any, index: number) => {
                        const isActiveFirstColor = value.firstColor === color;
                        return (
                          <div
                            onClick={() => {
                              handleFirstColorChange(color);
                            }}
                            className={clsx('color-palette__first-color__dropdown-item', {
                              'active': isActiveFirstColor
                            })}
                            key={index}
                            style={{ background: color }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Popper>
              </div>
            )}
          </div>
        );
      })}
      <CustomButton
        startIcon={
          <IconSvg svg={IconDictionary.PlusRounded} fill="var(--primary-color)" />
        }
        onClick={handleCreateNewPalette}
        variant="text"
        fullWidth
      >
        Добавить новую палитру
      </CustomButton>

      {showDeleteDialog && (
        <DeleteDialog
          isOpen={showDeleteDialog}
          onClose={toggleDeleteDialog}
          onDelete={() => handleDeletePalette(propsForPaletteDelete.event, propsForPaletteDelete.index)}
          title="Удаление палитры"
          bodyText={`палитру «${propsForPaletteDelete.name}»`}
          maxWidth="xs"
          isLoading={isDeletingInProgress}
        />
      )}
    </div>
  );
};

export default ColorPalette;
