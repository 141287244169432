import { useCallback } from 'react';
import MapConnection from 'src/types/map-connection';

export interface CalculatedObject {
  template: string;
  operandIds: number[];
  valueWithoutOperands: string;
}

interface CalculatedInputProps {
  value: string;
  setValue: (param: string) => void;
  processedFields: MapConnection.ProcessedField[];
}

export const useCalculatedInputContainer = ({
  value,
  setValue,
  processedFields,
}: CalculatedInputProps) => {
  const handleChangeValue = useCallback(
    (newValue: string) => {
      setValue(newValue);
    },
    [setValue],
  );

  const sourcesList = processedFields.map(
    (value: MapConnection.ProcessedField, index: number) => ({
      key: index,
      value: value.alias || value.displayedName,
      aggregation: null,
      id: value.id || '',
    }),
  );

  const regExes = [
    {
      regEx: /\][^\s]/,
      replaceValue: (substring: string) => `${substring[0]}${substring[1]}`,
    },
    {
      regEx: /[^\s]\[/,
      replaceValue: (substring: string) => `${substring[0]}${substring[1]}`,
    },
  ];

  return {
    value,
    handleChangeValue,
    sourcesList,
    regExes,
  };
};
