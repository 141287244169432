import React, { useCallback } from 'react';
import { merge } from 'lodash';
import { CustomFormControl } from '../../../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../../../uikit/Checkbox';
import { InputContainer } from '../../../../../common/special-inputs/inputContainer/inputContainer';
import { CellColoringGroup } from '../../../../dropdown-layout/helpers/Property';
import { parseComplexRestriction } from '../../../../dropdown-layout/helpers/helpers';

interface MultiFormulaBlockProps {
  group: CellColoringGroup;
  changeSettingInGroup: (groupIndex: number, group: CellColoringGroup) => void;
  formatAxisValues: any[];
  index: number;
}

export const MultiFormulaBlock = ({
  group,
  changeSettingInGroup,
  formatAxisValues,
  index,
}: MultiFormulaBlockProps) => {
  const toggleIsFormula = () => {
    changeSettingInGroup(
      index,
      merge(group, {
        useFormula: !group.useFormula,
      }),
    );
  };

  const changeFormulaValue = useCallback(
    (value: string) => {
      const formula = parseComplexRestriction(
        formatAxisValues.map((value) => {
          return {
            name: value.value,
            etlFieldId: value.id,
          };
        }),
        value,
      );

      changeSettingInGroup(
        index,
        merge(group, {
          formula: {
            text: value,
            template: formula.template,
            operandIds: formula.operandIds,
            operands: formula.operands,
          },
        }),
      );
    },
    [changeSettingInGroup, formatAxisValues, group, index],
  );

  return (
    <div className="conditional-coloring__formula-container">
      <CustomFormControl
        control={
          <CustomCheckbox
            checked={group.useFormula}
            onChange={toggleIsFormula}
          />
        }
        label="Ввод формулы"
      />

      {group.useFormula && (
        <InputContainer
          textInputProps={{
            label: 'Введите формулу. Выбор поля - @',
            handleChange: () => null,
            fullWidth: true,
          }}
          itemsList={formatAxisValues}
          value={group.formula.text}
          changeValue={changeFormulaValue}
        />
      )}
    </div>
  );
};
