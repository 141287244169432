import React from 'react';
import { CssVariables } from '../../../../enums/css-variables';
import { varcss } from '../../../../helpers/ui-helper';
import { WidgetProperties } from '../../../../slices/types';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { CustomButton } from '../../../../uikit/Button';
import { useWidgetLinkButton } from './useWidgetLinkButton';
import IconSvg from '../../icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface WidgetLinkButtonProps {
  widgetProperties?: WidgetProperties[];
}

export const WidgetLinkButton = ({
  widgetProperties = [],
}: WidgetLinkButtonProps) => {
  const { link, openLink, tooltipTitle } = useWidgetLinkButton({
    widgetProperties,
  });

  return (
    <>
      {Boolean(link) && (
        <CustomTooltip arrow title={tooltipTitle}>
          <div>
            <CustomButton
              icon={
                <IconSvg
                  svg={IconDictionary.Link}
                  fill="var(--primary-color)"
                />
              }
              onClick={openLink}
              size="small"
              variant="tab"
              backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
            />
          </div>
        </CustomTooltip>
      )}
    </>
  );
};
