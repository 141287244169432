import { ConnectionView } from '../enums/connection-view';
import { SourceType } from '../enums/source-type';
import Connection from '../types/connection';
import { apiETL } from '../utils/api';

/* Создание подключения */
export const apiCreateConnection = async (
  sourceType: SourceType,
  params: Connection.Create.Params,
): Promise<Connection.Create.Response> => {
  try {
    const { data } = await apiETL.post<Connection.Create.Response>(
      '/connections/',
      {
        sourceType,
        ...params,
      },
    );
    return data;
  } catch (error: any) {
    console.error('Request failed', error);
    throw error.response;
  }
};

export const apiCreateGoogleSheetConnection = async (
  groupId: number,
) => {
  try {
    const { data } = await apiETL.post(
      `/connections/google-sheets/${groupId}`,
    );
    return data;
  } catch (error: any) {
    console.error('Request failed', error);
    throw error.response;
  }
};

/* Получение списка подключений с выбором вида view */
export const apiGetConnections = async <R extends Connection.View.All>(
  view: ConnectionView,
  groupId: any,
): Promise<R> => {
  try {
    const { data } = await apiETL.get<R>('/connections/', {
      params: { view, groupId },
    });
    return data;
  } catch (error: any) {
    console.error(error);
    throw error?.response?.data ?? error;
  }
};

/* Обновление соединения */
export const apiUpdateConnection = async (body: Connection.View.ItemFull, isMerge?: true) => {
  try {
    const { data } = await apiETL.put('/connections/', body, { params: { merge: isMerge } });
    return data;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error.response;
  }
};

/* Удаление соединения */
export const apiDeleteConnection = async (id: number) => {
  try {
    const { data } = await apiETL.delete(`/connections/${id}`);
    return data;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

export const apiDeleteSource = async (id: number) => {
  try {
    const { data } = await apiETL.delete(`/sources/${id}`);
    return data;
  } catch (error: any) {
    console.error('Request failed:', error);
    throw error;
  }
};

/* получение списка подключений сгруппированный по типам */
export const getGetConnectionsSourceName = async (
  groupId: number,
): Promise<Connection.View.SourceName> =>
  apiGetConnections<Connection.View.SourceName>(
    ConnectionView.SourceName,
    groupId,
  );

/* получение списка подключений сгруппированный по типам в кратком виде */
export const getGetConnectionsBase = async (
  groupId: number,
): Promise<Connection.View.Base> =>
  apiGetConnections<Connection.View.Base>(ConnectionView.Base, groupId);

/* получение списка подключений полный сгруппированный по типам */
export const getGetConnectionsFull = async (
  groupId: number,
): Promise<Connection.View.Full> =>
  apiGetConnections<Connection.View.Full>(ConnectionView.Full, groupId);

export const getConnectionMetaList = async (): Promise<Connection.Meta[]> => {
  try {
    const { data } = await apiETL.get<Connection.Meta[]>('/connections-meta/');
    return data;
  } catch (error: any) {
    console.error(error);
    return [];
  }
};

export const getConnectionParamsById = async (
  id: number,
  forSource: boolean = false,
): Promise<Connection.Param[]> => {
  const { data } = await apiETL.get<Connection.Param[]>(
    `/connections-meta/${id}/connection-params?forSource=${forSource}`,
  );
  return data;
};

export const getFileParamsById = async (
  id: number,
  savedInLoaderContext: boolean = false,
): Promise<Connection.FileParam[]> => {
  try {
    const { data } = await apiETL.get<Connection.FileParam[]>(
      `/file-meta/${id}/file-parameters?savedInLoaderContext=${savedInLoaderContext}`,
    );
    return data;
  } catch (error: any) {
    console.error(error);
    throw error?.response?.data ?? error;
  }
};
