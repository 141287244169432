import './index.css';
import React, { memo, useEffect, useState } from 'react';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { apiGetConnectionInfoBySourceId } from '../../../../../services/sources';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../common/icon-svg/icon-svg';

interface ConnectionTooltipProps {
  sourceId: number;
}

export const ConnectionTooltip = memo(({ sourceId }: ConnectionTooltipProps) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    apiGetConnectionInfoBySourceId(sourceId)
      .then((res) => setData(res))
      .catch(() => {});
  }, [sourceId]);

  const connection = data?.connection;
  const name = data?.name;
  const { sourceType, url, login, host, port, connectionName } =
    connection || {};

  return (
    <CustomTooltip
      arrow
      title={
        data ? (
          <div className="connection-tooltip">
            <h3>Подключение {sourceType}</h3>
            <p className="connection-tooltip__text">
              {url || `${login}@${host}:${port}`}
            </p>
            <h3>Имя соединения</h3>
            <p className="connection-tooltip__text">{connectionName}</p>
            <h3>Источник</h3>
            <p>{name}</p>
          </div>
        ) : (
          ''
        )
      }
    >
      <div>
        <IconSvg
          svg={IconDictionary.InfoRounded}
          width={24}
          height={24}
          fill="var(--dark-grey)"
        />
      </div>
    </CustomTooltip>
  );
});

ConnectionTooltip.displayName = 'ConnectionTooltip';
