import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import './index.css';
import { CustomPanelHead, CustomPanelHeadProps } from '../PanelHead';

interface CustomPanelProps {
  headProps: CustomPanelHeadProps;
  handlerClose: () => void;
  children?: React.ReactNode;
  type?: 'default' | 'dashboardSecondPanel';
}

export const CustomPanel = ({
  children,
  headProps,
  handlerClose,
  type = 'default',
}: CustomPanelProps) => {
  return (
    <ClickAwayListener onClickAway={handlerClose}>
      <div
        className={clsx('custom-panel', {
          'custom-panel_dashboard-second': type === 'dashboardSecondPanel',
        })}
      >
        <CustomPanelHead {...headProps} onClose={handlerClose} />
        {children}
      </div>
    </ClickAwayListener>
  );
};
