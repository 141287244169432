import React from 'react';
import './bound-content-title.css';
import { CustomButton } from '../../../../uikit/Button';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface BoundContentTitleProps {
  isCanAddBound: boolean;
  handleAddBound: () => void;
}

export const BoundContentTitle: React.FC<BoundContentTitleProps> = ({
  isCanAddBound,
  handleAddBound,
}) => {
  return (
    <div
      className="bound-content-title__container"
    >
      <h3
        className="bound-content-title__title"
      >
        Список связей
      </h3>
      <CustomButton
        disabled={!isCanAddBound}
        variant="text"
        size="small"
        startIcon={
          <IconSvg
            svg={IconDictionary.PlusRounded}
            fill={isCanAddBound ? 'var(--primary-color)' : 'var(--dark-grey)'}
            classSVG="bound-content-title__button-icon"
          />
        }
        onClick={handleAddBound}
      >
        <span
          className="bound-content-title__button-text"
          style={{ color: isCanAddBound ? 'var(--primary-color)' : 'var(--dark-grey)' }}
        >
          Добавить
        </span>
      </CustomButton>
    </div>
  );
};
