export const CommonNames = {
  data: 'Данные',
  readyCards: 'Готовые карты',
  ownCard: 'Своя карта',
  loadingMap: 'карта загрузки',
  publicLoadingMap: 'Публичная карта загрузки',
  loadingMapTitle: 'Карта загрузки',
  projectList: 'Список проектов',
  projectTitle: 'Проект',
  projectAdded: 'Проект добавлен',
  projectDel: 'Проект удален',
  oneProject: 'проект',
  fewProjects: 'проекта',
  manyProjects: 'проектов',
  projectGenitive: 'проекта', // в родительном падеже
  projectAccusative: 'проект', // в винительном падеже
  projectPrepositional: 'проекте', // в предложном падеже
  personalProject: 'Персональный проект',
  publicProject: 'Публичный проект',
  projectType: 'Тип проекта',
  newProjectCreate: 'Создание нового проекта',
  newGroupAndProjectCreate: 'Создайте группу и новый проект',
  usedByOtherProduct: 'используется в другом проекте',
  personalM: 'Персональный',
  personalF: 'Персональная',
  publicM: 'Публичный',
  publicF: 'Публичная',
  dimensions: 'Показатели',
  measures: 'Измерения',
  drilldown: 'drilldown',
  Drilldown: 'Drilldown',
  SQL: 'SQL',
  SQLScript: 'SQL-скрипт',
  SQLRequest: 'SQL-запрос',
  JoinOperation: 'Join',
  UnionOperation: 'Union',
  UnknownOperation: 'Unknown',
  LeftJoin: 'Left join',
  RightJoin: 'Right join',
  FullJoin: 'Full join',
  InnerJoin: 'Inner join',
  Union: 'Union',
  UnionAll: 'Union all',
  CountAggregation: 'COUNT',
  CountDistinctAggregation: 'C_DISTINCT',
  MaxAggregation: 'MAX',
  MinAggregation: 'MIN',
  SumAggregation: 'SUM',
  AvgAggregation: 'AVG',
  YearFunction: 'YEAR',
  QuarterFunction: 'QUARTER',
  MonthFunction: 'MONTH',
  WeekFunction: 'WEEK',
  DayFunction: 'DAY',
  HourFunction: 'HOUR',
  MinuteFunction: 'MINUTE',
  JoinResult: 'join_result',
  UnionResult: 'union_result',
};
