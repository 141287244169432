import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { State } from '../../../../slices/types';
import { setWidgetPushDownAction } from '../../../../slices/widget/widget';
import { useDefinePushdownActive } from './useDefinePushdownActive';
import { setWasClickOnPushdownCheckboxAction } from '../../../../slices/main-page/main-page-slice';

export const usePushdownCheckbox = () => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const widget = useSelector((state: State) => state.widget);

  const isSourcesWithActivePushDown = useDefinePushdownActive();

  const handleChange = () => {
    dispatch(setWasClickOnPushdownCheckboxAction(true));
    dispatch(setWidgetPushDownAction(!widget.pushDown));
  };

  const isDisabled = (isLoading || !isSourcesWithActivePushDown) && !widget.pushDown;

  return {
    isLoading,
    handleChange,
    widget,
    isDisabled,
  };
};
