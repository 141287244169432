import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { CustomFormControl } from '../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../uikit/Checkbox';
import { usePushdownCheckbox } from './usePushdownCheckbox';
import { CustomProgress } from '../../../../uikit/Progress';

export const PushDownCheckbox = () => {
  const {
    isLoading,
    handleChange,
    widget,
    isDisabled,
  } = usePushdownCheckbox();

  return (
    <CustomFormControl
      disabled={isDisabled}
      control={
        isLoading ? (
          <Box height={38} width={38} padding="8px">
            <CustomProgress type="circular" size={20} />
          </Box>
        ) : (
          <CustomCheckbox
            name="personal"
            onChange={handleChange}
            checked={widget.pushDown}
          />
        )
      }
      label="Прямой запрос к источнику"
    />
  );
};
