import { apiBI, checkAccess } from '../utils/api';

export const PROJECTS_URL = '/projects';

export const apiCreateNewProject = async (parameters: {
  name: string;
  projectGroupId: number;
  personal: boolean;
}) => {
  try {
    const response = await apiBI.post(`${PROJECTS_URL}/`, parameters);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiBasicChangeProject = async (
  projectId: number,
  parameters: { name: string; projectGroupId: number; personal: boolean; favourite?: boolean },
) => {
  try {
    const response = await apiBI.put(`${PROJECTS_URL}/`, {
      ...parameters,
      id: projectId,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiSetProject = async (
  loaderId: number,
  parameters: {
    id: number;
    loaderId: number;
    name: string;
    personal: boolean;
    projectGroupId: number;
  },
) => {
  try {
    const response = await apiBI.put(`${PROJECTS_URL}/`, {
      ...parameters,
      loaderId,
    });
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const apiDeleteProject = async (projectId: number) => {
  try {
    const response = await apiBI.delete(`${PROJECTS_URL}/${projectId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCheckProjectMapUsage = async (loaderId?: number) => {
  try {
    const response = await apiBI.get(`/projects/by-loader/referenced/${loaderId}`);
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetProject = async (projectId: number) => {
  try {
    const response = await apiBI.get(`${PROJECTS_URL}/${projectId}`);
    return response.data;
  } catch (error: any) {
    checkAccess(error);

    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetProjectViaSystemObject = async (systemObjectId: number) => {
  try {
    const response = await apiBI.get(
      `${PROJECTS_URL}/system-object/${systemObjectId}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCopyProject = async (
  projectId: number,
  parameters: { name: string; projectGroupId: number; personal: boolean },
) => {
  try {
    const response = await apiBI.post(`${PROJECTS_URL}/${projectId}/copy`, {
      ...parameters,
      id: projectId,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiGetLastCacheUpdate = async (projectId: number) => {
  try {
    const response = await apiBI.get(
      `${PROJECTS_URL}/${projectId}/cache-last-updated`,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiProjectNotificationSubscribe = async (
  parameters: { notificationType: 'ALL' | 'ERROR' | 'UNSUBSCRIBE', projectId: number },
) => {
  try {
    const response = await apiBI.put('/subscription/notification', {
      ...parameters,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCheckProjectNotificationSubscription = async (projectId: number) => {
  try {
    const response = await apiBI.get(
      `/subscription/notification?projectId=${projectId}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiProjectMailingSubscribe = async (
  parameters: { isSubscribe: boolean, mailingType: {type: string, value: string | null }, mailingFormat: string, projectId: number},
) => {
  try {
    const response = await apiBI.put('/subscription/mailing', {
      ...parameters,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};

export const apiCheckProjectMailingSubscription = async (projectId: number) => {
  try {
    const response = await apiBI.get(
      `/subscription/mailing?projectId=${projectId}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response.status}`);
    throw error;
  }
};
