import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, WidgetProperties } from '../../../../slices/types';
import {
  PanelType,
  WidgetPropertyType,
  WidgetType,
} from '../../../../enums/widget-type';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { PropertyData } from '../../dropdown-layout/helpers/Property';
import { setWidgetPropAction } from '../../../../slices/widget/widget';
import { setWidgetsIdWithActiveLegendAction } from '../../../../slices/dashboard/dashboard';

interface useWidgetActiveLegendProps {
  widgetProperties: WidgetProperties[];
  widgetType: WidgetType;
  widgetsWithAxisZLegend: WidgetType[];
  isDisabledActivity: boolean;
  widgetId: number;
}

const WIDGET_TYPE_WITH_ACTIVE_LEGEND = [
  WidgetType.HISTOGRAM,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.GRAPH,
];

export const useWidgetActiveLegend = ({
  widgetsWithAxisZLegend,
  widgetType,
  widgetProperties,
  isDisabledActivity,
  widgetId,
}: useWidgetActiveLegendProps) => {
  const dispatch = useDispatch();

  const widgetsWithActiveLegend =
    useSelector((state: State) => state.dashboard.widgetsWithActiveLegend) ||
    [];

  let axisValues: PropertyData[] = getParsedAxisValues(
    PanelType.axisY,
    widgetProperties,
  );

  if (widgetsWithAxisZLegend.includes(widgetType)) {
    axisValues = axisValues.concat(
      getParsedAxisValues(PanelType.axisZ, widgetProperties),
    );
  }

  const activeLegends: PropertyData[] = getParsedAxisValues(
    WidgetPropertyType.activeLegend,
    widgetProperties,
  );

  const [activeAxisValues, setActiveAxisValues] = useState(
    activeLegends.length ? activeLegends : axisValues,
  );

  useEffect(() => {
    if (!activeLegends.length) {
      setActiveAxisValues(axisValues);
    }
  }, [activeLegends.length, JSON.stringify(axisValues)]);

  useEffect(() => {
    setActiveAxisValues(axisValues);
  }, [widgetType]);

  const onChangeActiveLegend = (index: number) => {
    const valueIndex = activeAxisValues.findIndex(
      (item) => item.id === axisValues[index].id,
    );
    const isActive = valueIndex !== -1;
    const newActiveAxisValues = [...activeAxisValues];

    if (isActive && activeAxisValues.length > 1) {
      newActiveAxisValues.splice(valueIndex, 1);
    } else if (!isActive) {
      newActiveAxisValues.push(axisValues[index]);
    }

    const sortNewActiveAxisValues: PropertyData[] = [];
    axisValues.forEach((value) => {
      const activeValue = newActiveAxisValues.find(
        (activeValue) => value.clientUID === activeValue.clientUID,
      );
      activeValue && sortNewActiveAxisValues.push(activeValue);
    });

    setActiveAxisValues(sortNewActiveAxisValues);

    dispatch(
      setWidgetPropAction({
        name: WidgetPropertyType.activeLegend,
        value: JSON.stringify(sortNewActiveAxisValues),
      }),
    );

    const newWidgetsWithActiveLegend = [...widgetsWithActiveLegend];

    const widgetIndex = widgetsWithActiveLegend.findIndex(
      (item: any) => item.id === widgetId,
    );
    if (widgetIndex + 1) {
      newWidgetsWithActiveLegend.splice(widgetIndex, 1);
    }
    newWidgetsWithActiveLegend.push({
      id: widgetId,
      activeLegend: JSON.stringify(sortNewActiveAxisValues),
    });

    dispatch(setWidgetsIdWithActiveLegendAction(newWidgetsWithActiveLegend));
  };

  const isWidgetWithActiveLegend =
    !isDisabledActivity && WIDGET_TYPE_WITH_ACTIVE_LEGEND.includes(widgetType);

  const isActiveLegend = (index: number) => {
    return (
      activeAxisValues.findIndex(
        (item: any) => item.id === axisValues[index].id,
      ) !== -1 || !activeAxisValues.length
    );
  };

  return {
    isWidgetWithActiveLegend,
    onChangeActiveLegend,
    isActiveLegend
  };
};
