import React, { useEffect } from 'react';
import './error-page.css';
import { useDispatch } from 'react-redux';
import { InfoBlock } from '../../components/error-page/info-block/info-block';
import { ImageBlock } from '../../components/error-page/image-block/image-block';
import { clearErrorPageErrorAction } from '../../slices/error-page/error-page';

export const ErrorPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearErrorPageErrorAction());
    };
  }, [dispatch]);

  return (
    <div className="error-page">
      <InfoBlock />
      <ImageBlock />
    </div>
  );
};
