import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsGlobalParametersLoading,
  selectGlobalParameters,
} from '../../../../slices/parameters/parameters';
import styles from './parameters-setting.module.css';
import { closeDashboardFiltersSettingAction } from '../../../../slices/dashboard/dashboard';
import { DashboardSettingContainer } from '../common/dashboard-setting-container';
import { CommonDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../../uikit/Progress';

const GlobalParametersSetting = memo(() => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsGlobalParametersLoading);

  const parameters = useSelector(selectGlobalParameters);

  const closeHandler = () => {
    dispatch(closeDashboardFiltersSettingAction());
  };

  return (
    <DashboardSettingContainer
      title={`Параметры ${CommonDictionary.projectGenitive}`}
      handlerClose={closeHandler}
    >
      {isLoading ? (
        <CustomProgress type="linear" />
      ) : (
        <>
          <div className={styles.wrap}>
            <div className={styles.title}>Глобальные параметры</div>
            {parameters.map((parameter) => (
              <div key={parameter.parameterCode}>
                {parameter.parameterCode} - {parameter.description}
              </div>
            ))}
          </div>
        </>
      )}
    </DashboardSettingContainer>
  );
});

GlobalParametersSetting.displayName = 'ParametersSetting';

export default GlobalParametersSetting;
