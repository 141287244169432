import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { NodeComponentProps } from 'react-flow-renderer/dist/types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import block from 'bem-cn';
import { State } from '../../../../slices/types';
import './source-node.css';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { getIconByDataType } from '../../../widget-page/helpers';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface SourceData {
  label: string;
  isNewSource: boolean;
  isSelected?: boolean;
  isErrorSource?: boolean;
  isNeedPreviewIcon: boolean;
  isAvailableInOperations: boolean;
  sourceId: number;
  processedFields: any[];
}

export interface SourceNodeProps extends NodeComponentProps {
  data: SourceData;
}

const SourceNode: React.FC<SourceNodeProps> = ({
  data: {
    label = 'Название по умолчанию',
    processedFields = [],
  },
}) => {
  const activeJoinKeysList = useSelector(
    (state: State) => state.mapView.activeJoinKeysList,
  );

  const b = block('source-node-model');

  const sourceClasses = classNames(
    b(),
  );

  return (
    <>
      <div className={sourceClasses}>
        <div className={b('text-container')}>
          <p className={b('text')}>
            {label}
          </p>
        </div>
        <div className={b('list')}>
          {processedFields.map((processedField, i) => {
            const isKeyField = activeJoinKeysList.includes(processedField.id);
            return (
              <div className={b('list-item-container')} key={i}>
                <div className={b('list-item-icon-container')}>
                  <IconSvg
                    svg={getIconByDataType(processedField.displayedType)}
                    fill="var(--dark-grey)"
                    width={24}
                    height={24}
                  />
                </div>
                <div className={`${b('list-item')} ${isKeyField && b('list-item-key')}`}>
                  {processedField.displayedName}
                </div>
                <div className={b('list-item-icon')}>
                  {isKeyField ? <IconSvg
                    classSVG="source-field__type"
                    svg={IconDictionary.Key}
                    fill="var(--dark-grey)"
                  /> : ''}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Handle
        type='target'
        position={Position.Left}
        isConnectable={false}
      />
      <Handle
        type='source'
        position={Position.Right}
        isConnectable={false}
      />
    </>
  );
};

export default SourceNode;
