import { Box } from '@material-ui/core';
import React from 'react';
import { CssVariables } from '../../../enums/css-variables';
import { varcss } from '../../../helpers/ui-helper';
import { CustomButtonGroup } from '../../../uikit/ButtonsGroup';
import { FiltersButton } from '../filters/filters-button';
import { WidgetLinkButton } from '../../common/special-buttons/widget-link-button/widget-link-button';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { CustomButton } from '../../../uikit/Button';
import { SpecialWidgetHeadButtons } from './components/special-widget-head-buttons';
import CardHeader from '../../../uikit/CardHeader';
import { Widget } from '../../../slices/types';
import { useWidgetHeader } from './useWidgetHeader';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

export interface WidgetHeaderProps {
  widgetProps: Widget;
}

export const WidgetHeader = ({ widgetProps }: WidgetHeaderProps) => {
  const {
    title,
    titleSettings,
    description,
    subtitleSettings,
    headerTextAlign,
    isFullScreenAbility,
    isFramed,
    isFullScreen,
    setFullScreen,
  } = useWidgetHeader({ widgetProps });

  return (
    <CardHeader
      title={title}
      titleSettings={titleSettings}
      subtitle={description}
      subtitleSettings={subtitleSettings}
      align={headerTextAlign}
    >
      <Box ml="20px">
        <CustomButtonGroup size="small">
          <FiltersButton widgetProps={widgetProps} />
          <WidgetLinkButton widgetProperties={widgetProps?.properties} />

          {isFullScreenAbility && (
            <>
              {!isFramed &&
                (isFullScreen ? (
                  <CustomTooltip arrow title="Свернуть" key="Свернуть">
                    <div>
                      <CustomButton
                        onClick={setFullScreen}
                        icon={<IconSvg svg={IconDictionary.CloseFullscreen} fill="var(--dark-grey)" />}
                        size="small"
                        variant="tab"
                        backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
                      />
                    </div>
                  </CustomTooltip>
                ) : (
                  <CustomTooltip
                    arrow
                    title="На весь экран"
                    key="На весь экран"
                  >
                    <div>
                      <CustomButton
                        onClick={setFullScreen}
                        icon={<IconSvg svg={IconDictionary.OpenFullscreen} fill="var(--dark-grey)" />}
                        size="small"
                        variant="tab"
                        colorType="dark"
                        backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
                      />
                    </div>
                  </CustomTooltip>
                ))}
            </>
          )}

          <SpecialWidgetHeadButtons widget={widgetProps} />
        </CustomButtonGroup>
      </Box>
    </CardHeader>
  );
};
