import React from 'react';

type WidgetCarouselItemProps = {
  icon: string;
  isActive?: boolean;
  name: string;
};

const SettingsItem: React.FC<WidgetCarouselItemProps> = ({
  isActive = false,
  icon,
  name,
}) => {
  return (
    <div className={`settings-item ${isActive ? 'active' : ''}`}>
      <img src={icon} />
      <span className="settings-item__name">{name}</span>
    </div>
  );
};

export default SettingsItem;
