import React from 'react';
import './dashboard-setting-container.css';
import { Typography } from '@material-ui/core';
import { CustomButton } from '../../../../uikit/Button';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface SettingContainerProps {
  title: string;
  handlerClose: () => void;
  children?: React.ReactNode;
}

export const DashboardSettingContainer: React.FC<SettingContainerProps> = ({
  children,
  title,
  handlerClose,
}) => {
  return (
    <div className="setting-container__container">
      <div className="setting-container__heading">
        <Typography className="setting-container__title">{title}</Typography>
        <CustomButton
          aria-label="close"
          variant="outlined"
          size="small"
          icon={<IconSvg
            svg={IconDictionary.Close}
            fill="var(--dark-grey)"
          />}
          onClick={handlerClose}
        />
      </div>
      {children}
    </div>
  );
};
