import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { State } from '../../../slices/types';
import { defineValidation } from './validation';
import { apiSetLoaderScheduleActivity } from '../../../services/scheduler';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import IconSvg from '../../common/icon-svg/icon-svg';
import { setModelDataModeAction } from '../../../slices/map-view/map-view';
import {CommonDictionary} from "../../../dictionaries/naming-dictionary/naming-dictionary";
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const b = block('loader-controls');

export const useMapControls = () => {
  const currentProject = useSelector(
    (state: State) => state.mainPage.currentProject,
  );

  const loaderId: any = (currentProject && currentProject.loaderId) || '';

  const dispatch = useDispatch();

  const loaderData = useSelector(
    (state: State) => state.mapConnection[loaderId],
  );

  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );

  const [isHistoryOpened, setHistoryOpened] = useState<Boolean>(false);
  const [isScheduleOpened, setScheduleOpened] = useState<Boolean>(false);
  const [currentTitle, setCurrentTitle] = useState<any>('');
  const [publishValidationError, setPublishValidationError] = useState<String>(
    '',
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openSchedule = () => {
    setScheduleOpened(true);
  };

  const closeSchedule = () => {
    setScheduleOpened(false);
  };

  const openHistory = () => {
    setHistoryOpened(true);
  };

  const closeHistory = () => {
    setHistoryOpened(false);
  };

  const onPublishChange = async () => {
    const isPublished = !currentIsPublished;
    if (isPublished) {
      const validationErrorText = defineValidation(loaderData);
      setPublishValidationError(validationErrorText);
      if (!validationErrorText) {
        setIsPublished(true);
        dispatch(
          mapConnectionAction.updatePublishStatus({
            loaderId,
            published: true,
          }),
        );
      }
    } else {
      setIsPublished(false);
      dispatch(
        mapConnectionAction.updatePublishStatus({ loaderId, published: false }),
      );
    }
  };

  const getTitle = () =>
    isEditableMap ? (
      <span className={b('title')}>{currentProject?.name}</span>
    ) : (
      <div>
        {CommonDictionary.loadingMapTitle} <span className={b('title')}>{loaderData?.name}</span>
      </div>
    );

  useEffect(() => {
    setCurrentTitle(getTitle());
  }, [currentProject, loaderData]);

  const closePublishErrorModal = () => setPublishValidationError('');

  const [isPublished, setIsPublished] = useState<boolean | undefined>(
    undefined,
  );
  const currentIsPublished =
    isPublished === undefined ? loaderData?.published : isPublished;

  const controls = [
    {
      title: 'История',
      icon: <IconSvg svg={IconDictionary.TimeRefresh} fill='var(--dark-grey)' />,
      onClick: openHistory,
      disabled: false,
      isNeedDivider: false,
    },
    {
      title: 'Расписание',
      icon: <IconSvg svg={IconDictionary.TimeSchedule} fill='var(--dark-grey)' />,
      onClick: openSchedule,
      disabled: false,
      isNeedDivider: true,
    },
    {
      title: 'Модель данных',
      icon: <IconSvg svg={IconDictionary.DataModel} fill='var(--dark-grey)' />,
      onClick: () => dispatch(setModelDataModeAction(true)),
      disabled: false,
      isNeedDivider: false,
    },
  ];

  return {
    currentTitle,
    loaderData,
    currentIsPublished,
    isEditableMap,
    onPublishChange,
    isHistoryOpened,
    closeHistory,
    currentProject,
    isScheduleOpened,
    closeSchedule,
    publishValidationError,
    closePublishErrorModal,
    controls,
    anchorEl,
    handleMenuClick,
    handleCloseMenu
  };
};
