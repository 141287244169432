import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { produce } from 'immer';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/slices';
import { CssVariables } from '../../../enums/css-variables';
import { ParameterType } from '../../../enums/parameters';
import { getScaleCoefficient, varcss } from '../../../helpers/ui-helper';
import {
  selectProjectParameters,
  switchOpenParametersPanelAction,
  switchOpenAddParameterModalAction,
  fetchValidators,
  selectIsProjectParametersLoading,
  deleteParameter,
  selectIsDeletingProjectParameterLoading,
  selectParameterValidatorsEntities,
  editParameterValues,
  selectIsEditingValuesLoading,
} from '../../../slices/parameters/parameters';
import { ProjectParameter, State } from '../../../slices/types';

import './styles.css';
import { PageParams } from '../../../types/meta';
import { CustomButton } from '../../../uikit/Button';
import DeleteDialog from '../../../uikit/DeleteDialog/delete-dialog';
import { CustomProgress } from '../../../uikit/Progress';
import { isDashboardSharedPage } from '../../widget-page/charts/helpers';
import { OverPanel } from '../over-panel/over-panel';
import { compareParameterValues } from './helpers';
import { ParameterItem } from './parameter-item';
import { ParametersAddModal } from './parameters-add-modal';
import { EditableParameter } from './types';


interface ParametersPanelProps {
  isDashboard: boolean;
  projectId: number;
  dashboardId: number;
}

const getEditableParameter = (
  isDashboard: boolean,
  parameters: ProjectParameter[],
) =>
  parameters.map(
    ({ parameterId, parameterCode, type, name, value, dashboardValue }) => {
      return {
        parameterId,
        parameterCode,
        type,
        name,
        value: (isDashboard ? dashboardValue || value : value) || '',
        isValidationValueError: false,
      };
    },
  );

const ParametersPanelComponent = ({
  isDashboard,
  projectId,
  dashboardId,
}: ParametersPanelProps) => {
  const dispatch = useAppDispatch();

  const isModalOpened = useSelector(
    (state: State) => state.parameters.isOpenCreateParameterModal,
  );
  const isLoading = useSelector(selectIsProjectParametersLoading);
  const isDeletingLoading = useSelector(
    selectIsDeletingProjectParameterLoading,
  );
  const isEditingValuesLoading = useSelector(selectIsEditingValuesLoading);
  const projectParameters = useSelector(selectProjectParameters);
  const validators = useSelector(selectParameterValidatorsEntities);

  const initialParameters = getEditableParameter(isDashboard, projectParameters);

  const [parameters, setParameters] = useState<EditableParameter[]>(
    initialParameters,
  );
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setParameters(getEditableParameter(isDashboard, projectParameters));
    }
  }, [isDashboard, projectParameters]);

  const [parameterIdDeleteConfirm, setParameterIdDeleteConfirm] = useState<
  number | null
  >(null);
  const [deletingWarningText, setDeletingWarningText] = useState('');
  const [deletingForceParameterId, setDeletingForceParameterId] = useState<
  number | null
  >(null);

  const parameterNameForDelete = parameters.find(
    (parameter) => parameter.parameterId === deletingForceParameterId,
  )?.parameterCode;

  const isValuesHaveValidationErrors = parameters.some(
    (parameter) => parameter.isValidationValueError,
  );

  const diffParameterValuesList = compareParameterValues(
    initialParameters,
    parameters,
  );

  const isDisabledApplyChangesButton =
    isValuesHaveValidationErrors ||
    isEditingValuesLoading ||
    diffParameterValuesList.length === 0;

  const handleClosePanel = () =>
    dispatch(switchOpenParametersPanelAction(false));

  const handleOpenAddModal = () =>
    dispatch(switchOpenAddParameterModalAction(true));
  const handleCloseAddModal = () =>
    dispatch(switchOpenAddParameterModalAction(false));

  const handleChangeValue = (parameterId: number, value: string) => {
    setParameters(
      produce((draft) => {
        const parameter = draft.find(
          (parameter) => parameter.parameterId === parameterId,
        );
        if (parameter) {
          parameter.value = value;

          parameter.isValidationValueError = false;

          if (
            [ParameterType.TEXT, ParameterType.NUMBER].includes(parameter.type)
          ) {
            const validateRegex =
              validators[parameter.type]?.validateRegex || '.*';
            const regExp = new RegExp(validateRegex);
            parameter.isValidationValueError = !regExp.test(value);
          }

          if (!value.length) {
            parameter.isValidationValueError = true;
          }
        }
      }),
    );
  };

  const handleOpenDeleteModal = (parameterId: number) => {
    setDeletingForceParameterId(parameterId);
    setParameterIdDeleteConfirm(parameterId);
  };

  const handleDeleteParameter = (parameterId: number, force: boolean) => {
    setDeletingForceParameterId(parameterId);
    dispatch(deleteParameter({ parameterId, force }))
      .unwrap()
      .then(() => {
        setDeletingForceParameterId(null);
        setDeletingWarningText('');
      })
      .catch((e) => {
        setDeletingWarningText(e.message);
      });
  };

  const handleApplyChanges = () => {
    if (!isValuesHaveValidationErrors) {
      dispatch(
        editParameterValues({
          projectId,
          dashboardId: isDashboard ? dashboardId : undefined,
          parameterValues: parameters.map((parameter) => ({
            parameterId: parameter.parameterId,
            value: parameter.value,
          })),
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchValidators());
  }, [dispatch]);

  return (
    <>
      <OverPanel
        closeHandler={handleClosePanel}
        title="Параметры проекта"
        footer={!isDashboardSharedPage() && (
          <div className="parameters-panel__wrap">
            {isDashboard ? (
              null
            ) : (
              <CustomButton
                fullWidth
                onClick={handleOpenAddModal}
                variant="outlined"
                backgroundColor={varcss(CssVariables.MAIN_BACKDROP_COLOR)}
                style={{ marginRight: 12 * getScaleCoefficient() }}
              >
                Добавить
              </CustomButton>
            )}
            <CustomButton
              fullWidth
              onClick={handleApplyChanges}
              variant="contained"
              backgroundColor={varcss(CssVariables.MAIN_BACKDROP_COLOR)}
              style={{
                marginLeft: isDashboard
                  ? undefined
                  : 12 * getScaleCoefficient(),
              }}
              disabled={isDisabledApplyChangesButton}
            >
              {isEditingValuesLoading ? (
                <CustomProgress type="circular" style={{ color: 'white' }} />
              ) : (
                'Применить'
              )}
            </CustomButton>
          </div>)
        }
      >
        {isLoading ? (
          <CustomProgress type="linear" />
        ) : (
          parameters.map((parameter) => (
            <ParameterItem
              key={parameter.parameterId}
              parameter={parameter}
              onChangeValue={(value: string) =>
                handleChangeValue(parameter.parameterId, value)}
              onDelete={() => handleOpenDeleteModal(parameter.parameterId)}
              isDeletingLoading={
                isDeletingLoading &&
                parameter.parameterId === deletingForceParameterId
              }
              isDashboard={isDashboard}
            />
          ))
        )}
      </OverPanel>

      {isModalOpened && (
        <ParametersAddModal handleClose={handleCloseAddModal} />
      )}

      {parameterIdDeleteConfirm && (
        <DeleteDialog
          isOpen
          error={deletingWarningText}
          onClose={() => {
            setParameterIdDeleteConfirm(null);
          }}
          onDelete={() => {
            setParameterIdDeleteConfirm(null);
            handleDeleteParameter(parameterIdDeleteConfirm, false);
          }}
          title={`Удаление параметра ${parameterNameForDelete}`}
          bodyText="параметр"
          maxWidth="xs"
          isLoading={isDeletingLoading}
        />
      )}
      {deletingWarningText && deletingForceParameterId && (
        <DeleteDialog
          isOpen
          error={deletingWarningText}
          onClose={() => {
            setDeletingWarningText('');
            setDeletingForceParameterId(null);
          }}
          onDelete={() => handleDeleteParameter(deletingForceParameterId, true)}
          title={`Удаление параметра ${parameterNameForDelete}`}
          bodyText="параметр"
          maxWidth="xs"
          isLoading={isDeletingLoading}
        />
      )}
    </>
  );
};

export const ParametersPanel = memo(
  ({ isDashboard = false }: { isDashboard?: boolean }) => {
    const params: PageParams = useParams();

    const projectId = Number(params.projectId);
    const dashboardId = Number(params.id);

    const isOpened = useSelector(
      (state: State) => state.parameters.isOpenParametersPanel,
    );

    if (!isOpened) return null;

    return (
      <ParametersPanelComponent
        isDashboard={isDashboard}
        projectId={projectId}
        dashboardId={dashboardId}
      />
    );
  },
);

ParametersPanel.displayName = 'ParametersPanel';
