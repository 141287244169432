import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CssVariables } from '../../../../../enums/css-variables';
import { varcss } from '../../../../../helpers/ui-helper';
import { CustomButton } from '../../../../../uikit/Button';
import { setCurrentWidgetIdFullScreenAction } from '../../../../../slices/dashboard/dashboard';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { State } from '../../../../../slices/types';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../../common/icon-svg/icon-svg';

interface FullscreenButtonProps {
  widgetId: number;
  isFullScreenAbility: boolean;
  isDrilldownFilterFieldEmpty: boolean;
  className?: string;
}

export const FullscreenButton = ({
  widgetId,
  isFullScreenAbility,
  isDrilldownFilterFieldEmpty,
  className,
}: FullscreenButtonProps) => {
  const dispatch = useDispatch();
  const setWidgetIdFullScreen = useCallback(
    (widgetId: number | null) => () => {
      dispatch(setCurrentWidgetIdFullScreenAction(widgetId));
    },
    [dispatch],
  );

  const currentWidgetIdFullScreen = useSelector(
    (state: State) => state.dashboard.currentWidgetIdFullScreen,
  );

  const isFullScreenView = currentWidgetIdFullScreen === widgetId;

  return (
    <>
      {isFullScreenAbility && !isFullScreenView && isDrilldownFilterFieldEmpty && (
        <CustomTooltip title="На весь экран" arrow>
          <div>
            <CustomButton
              className={className}
              icon={<IconSvg svg={IconDictionary.OpenFullscreen} fill="var(--dark-grey)" />}
              onClick={setWidgetIdFullScreen(widgetId)}
              size="small"
              variant="tab"
              backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
            />
          </div>
        </CustomTooltip>
      )}

      {isFullScreenView && (
        <CustomButton
          className={className}
          icon={<IconSvg svg={IconDictionary.CloseFullscreen} fill="var(--dark-grey)" />}
          onClick={setWidgetIdFullScreen(null)}
          size="small"
          variant="tab"
          backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
        />
      )}
    </>
  );
};
