import React, { useEffect, useState } from 'react';
import { TextInput, TextInputProps } from './text-input';

export const NumberInput: React.FC<TextInputProps> = ({
  value,
  handleChange,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    // Заменяем запятую на точку
    value = value.replace(/,/g, '.');
    // Если это вторая точка в строке - не вводим ее
    if (/\..*\./.test(value)) return;
    // Оставляем только цифры и точки
    value = value.replace(/[^\d.]/g, '');
    setInputValue(value);
    if (!isNaN(parseFloat(value))) handleChange && handleChange(value);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    // Оставляем только цифры и запятые и точки
    const filteredText = pastedText.replace(/[^\d,.]/g, '');
    // Заменяем запятую на точку
    const newValue = filteredText.replace(/,/g, '.');
    const newValueNum = parseFloat(`${inputValue}${newValue}`);
    if (!isNaN(newValueNum)) {
      setInputValue(String(newValueNum));
      handlePrepareValue(String(newValueNum));
    }
  };

  const handleBlur = () => {
    handlePrepareValue(inputValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handlePrepareValue(inputValue);
    }
  };

  const handlePrepareValue = (value: string) => {
    handleChange &&
      handleChange(!isNaN(parseFloat(value)) ? String(parseFloat(value)) : '');
  };

  const inputValueReplaced = inputValue.replace(/\./g, ',');

  return (
    <TextInput
      value={inputValueReplaced}
      handleChange={handleInputChange}
      handlePaste={handlePaste}
      handleBlur={handleBlur}
      handleKeyDown={handleKeyDown}
      {...otherProps}
    />
  );
};
