import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compact } from 'lodash';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { PanelType, WidgetPropertyType } from '../../../../../enums/widget-type';
import { State } from '../../../../../slices/types';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import {
  DefaultStartColorForGradientColoring,
  HeatMapGradientDefaultColors,
} from './heat-map-modal';
import { MapGradient } from './components/gradient-settings';
import { mapModalTypes } from '../../enums';
import { setWidgetPropAction } from '../../../../../slices/widget/widget';

export interface ReversGradient {
  etlSourceId: string;
  value: boolean;
}

export interface GradientColoring {
  etlSourceId: string;
  color: string;
}

interface useHeatMapModalProps {
  etlSourceId: string;
  onClose: (type: mapModalTypes) => void;
}

export const useHeatMapModal = ({ etlSourceId, onClose }: useHeatMapModalProps) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const widgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const axisZValue: PropertyData[] = getParsedAxisValues(PanelType.axisZ, widgetProperties);
  const axisZCurrentIndex = axisZValue.findIndex(
    (value) => value.etlSourceId === etlSourceId,
  );

  const gradientColoringList: GradientColoring[] = getParsedAxisValues(
    WidgetPropertyType.gradientColoring,
    widgetProperties,
  );
  const gradientColoring = gradientColoringList.find(
    (item) => item.etlSourceId === etlSourceId,
  );

  const [currentAggregation, setCurrentAggregation] = useState<any>(null);
  const changeAggregationCallback = useCallback((aggregation: any) => {
    setCurrentAggregation(aggregation);
  }, []);

  const initGradientColoringMap = Boolean(gradientColoring);
  const initHeatMap = axisZCurrentIndex !== -1 && !initGradientColoringMap;
  const initCurrentWidgetField = axisZCurrentIndex !== -1 ? axisZValue[axisZCurrentIndex] : null;

  const [isHeatMap, setIsHeatMap] = useState<boolean>(initHeatMap);
  const [isGradientColoringMap, setGradientColoringMapLayer] =
    useState<boolean>(initGradientColoringMap);
  const [currentWidgetField, setCurrentWidgetField] = useState<PropertyData | null>(
    initCurrentWidgetField,
  );
  const [gradientColor, setGradientColor] = useState<string>(
    gradientColoring?.color || '#FF0000',
  );

  const reverseGradientValue: ReversGradient[] = getParsedAxisValues(
    WidgetPropertyType.reverseGradient,
    widgetProperties,
  );
  const initReverseGradient =
    reverseGradientValue.find((item) => item.etlSourceId === etlSourceId)
      ?.value || false;

  const [isChangeGradient, setIsChangeGradient] = useState<boolean>(initReverseGradient);

  const changeCurrentWidgetField = useCallback((field: PropertyData) => {
    setCurrentWidgetField(field);
  }, []);

  const toggleIsHeatMap = () => {
    !isHeatMap && setGradientColoringMapLayer(false);
    setIsHeatMap((prev) => !prev);
  };
  const toggleIsGradientColoringMap = () => {
    !isGradientColoringMap && setIsHeatMap(false);
    setGradientColoringMapLayer((prev) => !prev);
  };

  const mainSettingsList = [
    {
      value: isHeatMap,
      action: toggleIsHeatMap,
      label: 'Тепловая раскраска',
    },
    {
      value: isGradientColoringMap,
      action: toggleIsGradientColoringMap,
      label: 'Градиентная раскраска',
    },
  ];

  const handleChangeGradientColor = (color: string) => {
    setGradientColor(color);
  };

  const toggleIsChangeGradient = () => {
    setIsChangeGradient((prev) => !prev);
  };

  const gradientSettings = [
    {
      type: 'color',
      value: gradientColor,
      onChange: handleChangeGradientColor,
      label: 'Цвет',
      isVisible: isGradientColoringMap
    },
    {
      type: 'line',
      isVisible: true
    },
    {
      type: 'checkbox',
      value: isChangeGradient,
      onChange: toggleIsChangeGradient,
      label: 'Сменить порядок цветов',
      isVisible: true
    }
  ];

  const initGradient = {
    startColor: isChangeGradient
      ? HeatMapGradientDefaultColors.red
      : HeatMapGradientDefaultColors.green,
    middleColor: HeatMapGradientDefaultColors.yellow,
    endColor: isChangeGradient
      ? HeatMapGradientDefaultColors.green
      : HeatMapGradientDefaultColors.red,
  };
  const [gradient, setGradient] = useState<MapGradient>(initGradient);

  useEffect(() => {
    if (isGradientColoringMap) {
      setGradient({
        startColor: isChangeGradient
          ? gradientColor
          : DefaultStartColorForGradientColoring,
        endColor: isChangeGradient
          ? DefaultStartColorForGradientColoring
          : gradientColor,
      });
    } else {
      setGradient({
        startColor: isChangeGradient
          ? HeatMapGradientDefaultColors.red
          : HeatMapGradientDefaultColors.green,
        middleColor: HeatMapGradientDefaultColors.yellow,
        endColor: isChangeGradient
          ? HeatMapGradientDefaultColors.green
          : HeatMapGradientDefaultColors.red,
      });
    }
  }, [isChangeGradient, isGradientColoringMap, gradientColor]);

  const updateWidgetProperty = (propertyName: PanelType | WidgetPropertyType, newValue: string) => {
    dispatch(
      setWidgetPropAction({
        name: propertyName,
        value: newValue,
      }),
    );
  };

  const getNewValueProperty = (data: any[], propertyValue: any, isNeedCompact: boolean) => {
    const valueIndex = data.findIndex(
      (value) => value.etlSourceId === etlSourceId,
    );

    let newData = [...data];

    if (valueIndex !== -1) {
      newData[valueIndex] = propertyValue;
    } else {
      newData.push(propertyValue);
    }

    isNeedCompact && (newData = compact(newData));

    return JSON.stringify(newData);
  };

  const handleSave = () => {
    if (!currentWidgetField) return;
    setLoading(true);

    const newGradientColoring = getNewValueProperty(
      gradientColoringList,
      isGradientColoringMap
        ? {
            color: gradientColor,
            etlSourceId,
          }
        : null,
      true,
    );
    const newReverseGradient = getNewValueProperty(
      reverseGradientValue,
      { etlSourceId, value: isChangeGradient },
      false,
    );

    let newAxisZ = [...axisZValue];

    const isActiveColoring = isGradientColoringMap || isHeatMap;

    if (axisZCurrentIndex !== -1) {
      isActiveColoring &&
        (newAxisZ[axisZCurrentIndex] = {
          ...currentWidgetField,
          aggregation: currentAggregation?.formula,
          isActive: true,
        });
      !isActiveColoring && newAxisZ.splice(axisZCurrentIndex, 1);
    } else {
      isActiveColoring && newAxisZ.push({
        ...currentWidgetField,
        aggregation: currentAggregation?.formula,
        isActive: true,
      });
    }
    newAxisZ = compact(newAxisZ);

    updateWidgetProperty(PanelType.axisZ, JSON.stringify(newAxisZ));
    updateWidgetProperty(WidgetPropertyType.gradientColoring, newGradientColoring);
    updateWidgetProperty(WidgetPropertyType.reverseGradient, newReverseGradient);

    setLoading(false);
    handleClose();
  };

  const handleClose = () => {
    onClose(mapModalTypes.heatMapSettings);
  };

  const handleWrapperClick = (event: any) => {
    event.stopPropagation();
  };

  return {
    mainSettingsList,
    currentWidgetField,
    changeCurrentWidgetField,
    gradientSettings,
    gradient,
    handleSave,
    handleClose,
    isLoading,
    handleWrapperClick,
    changeAggregationCallback
  };
};
