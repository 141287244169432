export const getTextSkeletonStyles = (mainColor: string, bgColor: string) => {
  return {
    background: `repeating-linear-gradient(-75deg, ${mainColor} 30%, ${bgColor} 70%)`,
    backgroundSize: '600vw 600vw',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    animation: '2s linear infinite forwards',
    animationName: 'text-skeleton'
  };
};
