import React from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ObjectSchema, Shape } from 'yup';
import { Formik } from 'formik';
import block from 'bem-cn';

import { useClassButton } from '../../../hooks/useClassButton';
import Connection from '../../../types/connection';
import { fieldName } from './data';

import './file-form.css';
import { CustomProgress } from '../../../uikit/Progress';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

const b = block('file-form');

interface AnyObject {
  [key: string]: any;
}

interface Props {
  initialValues: Record<string, string | boolean>;
  validationSchema: ObjectSchema<Shape<AnyObject, AnyObject>>;
  handleFormSubmit: (values: {
    [key: string]: string | boolean;
  }) => Promise<void>;
  errorSubmit: string | null;
  fieldsData: Connection.FileParam[];
  onCancel: () => void;
  isDisabledAddButton: boolean;
  filesComponent: React.ReactNode;
  addFileToSourceList: (values: {
    [key: string]: string | boolean;
  }) => () => void;
  changeSource: (values: { [key: string]: string | boolean }) => () => void;
  isNewFile: boolean;
  isLocal: boolean;
}

const FileForm: React.FC<Props> = ({
  initialValues,
  validationSchema,
  handleFormSubmit,
  errorSubmit,
  fieldsData,
  onCancel,
  isDisabledAddButton,
  filesComponent,
  addFileToSourceList,
  changeSource,
  isNewFile,
  isLocal,
}) => {
  const classButton = useClassButton();
  const classStyledButton = useClassButton({ backgroundColor: 'transparent' });

  // todo если появится какое-то еще условие, то лучше выпилить эту логику с фронта и добавить дополнительный параметр disabled в fieldsData
  const isDisabledField = (name: string) => {
    return isLocal && (name === fieldName.MASK || name === fieldName.WORKING_DIRECTORY);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        touched,
        isSubmitting,
        values,
      }) => (
        <form className={b()} onSubmit={handleSubmit}>
          <div className={b('fields')}>
            {fieldsData.map((item) => {
              const name = fieldName[item.type];

              if (
                name === fieldName.WITH_HEADER ||
                name === fieldName.CHECK_XSD ||
                name === fieldName.JSONLINES
              ) {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={Boolean(values[name])}
                        name={name}
                        color="primary"
                        size="small"
                      />
                    }
                    label={item.description}
                    className={b('field', {
                      [item.type.toLowerCase()]: true,
                    }).toString()}
                  />
                );
              }

              if (name === fieldName.CHARSET) {
                return (
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-label">
                      {item.description}
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      defaultValue="UTF-8"
                      name={name}
                      value={values[fieldName.CHARSET]}
                      onChange={handleChange}
                    >
                      <MenuItem value="UTF-8">UTF-8</MenuItem>
                      <MenuItem value="windows-1251">windows-1251</MenuItem>
                      <MenuItem value="KOI8-R">KOI8-R</MenuItem>
                      <MenuItem value="ISO-8859-5">ISO-8859-5</MenuItem>
                      <MenuItem value="IBM866">IBM866</MenuItem>
                    </Select>
                  </FormControl>
                );
              }

              return (
                <TextField
                  key={item.type}
                  className={b('field', {
                    [item.type.toLowerCase()]: true,
                  }).toString()}
                  name={name}
                  label={item.description}
                  variant="filled"
                  autoComplete="off"
                  value={values[item.type]}
                  onChange={handleChange}
                  type="text"
                  size="small"
                  error={Boolean(errors[name]) && touched[name]}
                  disabled={isDisabledField(name)}
                />
              );
            })}

            {isSubmitting ? (
              <p className={b('loading')}>
                <span className={b('progress')}>
                  <CustomProgress type="circular" size={20} />
                </span>
                Идёт проверка
              </p>
            ) : (
              <Button
                type="submit"
                className={classStyledButton.secondarySmall}
                startIcon={
                  <IconSvg
                    svg={IconDictionary.Run}
                    fill="var(--primary-color)"
                  />
                }
              >
                Проверить доступность файла
              </Button>
            )}

            {errorSubmit && <Alert style={{wordBreak: 'break-all'}} severity="error">{errorSubmit}</Alert>}

            {filesComponent}
          </div>
          <div className={b('buttons')}>
            <Button
              className={classButton.primary}
              type="button"
              disabled={isDisabledAddButton}
              onClick={
                isNewFile ? addFileToSourceList(values) : changeSource(values)
              }
            >
              {isNewFile ? 'Добавить' : 'Сохранить'}
            </Button>
            <Button
              className={classButton.default}
              type="reset"
              onClick={onCancel}
            >
              Отменить
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default FileForm;
