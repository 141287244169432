import React from 'react';
import { Dialog, DialogContent, styled } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { WithStyles, withStyles } from '@material-ui/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CustomButton } from '../Button';
import CustomTooltip from '../CustomTooltip';
import IconSvg from '../../components/common/icon-svg/icon-svg';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';

export interface CustomDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  isDisableCloseButton?: boolean;
  isTitleWithTooltip?: boolean;
  children?: React.ReactNode;
}

const titleStyles = () =>
  createStyles({
    root: {
      margin: 0,
      padding: 'calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient))',
      minHeight: 'calc(60px * var(--scale-coefficient))',
    },
    settingRoot: {
      margin: 0,
      minHeight: 'calc(78px * var(--scale-coefficient))',
      borderBottom: '1px solid var(--dark-cyan)',
      padding: '0 calc(20px * var(--scale-coefficient))',
      display: 'flex',
      alignItems: 'center',
      height: 'calc(78px * var(--scale-coefficient))',
    },
    titleContainer: {
      marginRight: 'auto',
      paddingRight: 'calc(20px * var(--scale-coefficient))',
      minWidth: 0,
    },
    title: {
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 'calc(18px * var(--scale-coefficient))',
      lineHeight: 'calc(24px * var(--scale-coefficient))',
      color: 'var(--black)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginRight: 'auto',
    },
    description: {
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 'calc(14px * var(--scale-coefficient))',
      lineHeight: 'calc(20px * var(--scale-coefficient))',
      color: 'var(--dark-grey)',
    }
  });

export interface DialogTitleProps extends WithStyles<typeof titleStyles> {
  title: string;
  description?: string;
  isDisableCloseButton: boolean;
  onClose: () => void;
  isTitleWithTooltip: boolean;
}

const DialogTitle = withStyles(titleStyles)((props: DialogTitleProps) => {
  const { onClose, classes, title, description, isDisableCloseButton, isTitleWithTooltip } = props;

  const titleBlock = isTitleWithTooltip ? (
    <CustomTooltip title={title}>
      <div className={classes.title}>{title}</div>
    </CustomTooltip>
  ) : (
    <div className={classes.title}>{title}</div>
  );

  return (
    <MuiDialogTitle disableTypography className={classes.settingRoot}>
      <div className={classes.titleContainer}>
        {titleBlock}
        {description && <div className={classes.description}>{description}</div>}
      </div>
      <CustomButton
        variant="outlined"
        icon={<IconSvg
          svg={IconDictionary.Close}
          fill="var(--dark-grey)"
        />}
        onClick={onClose}
        disabled={isDisableCloseButton}
      />
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  root: () =>  ({
    padding: 0,
  }),
});

const StyledDialog = styled(Dialog)({
  '& [class*=MuiPaper-root]': {
    borderRadius: 'var(--base-border-radius)',
  }
});

const CustomDialog: React.FC<CustomDialogProps> = ({
  title = '',
  description,
  isOpen,
  onClose,
  maxWidth = 'sm',
  fullWidth = true,
  isDisableCloseButton = false,
  children,
  isTitleWithTooltip = false,
}) => {
  const classes = useStyles();

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle
        onClose={onClose}
        title={title}
        description={description}
        isDisableCloseButton={isDisableCloseButton}
        isTitleWithTooltip={isTitleWithTooltip}
      />

      <DialogContent classes={{ root: classes.root }}>{children}</DialogContent>
    </StyledDialog>
  );
};

export default CustomDialog;
