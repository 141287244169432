import React from 'react';
import { Collapse } from '@material-ui/core';
import { LoadersTable } from '../loaders-table/loaders-table';
import LoadersCards from '../loaders-cards/loaders-cards';
import { getMainPageView } from '../../../helpers/main-page';
import { Project } from '../../../slices/types';

interface GroupBodyProps {
  groupId: number;
  projects?: Project[];
}

export const GroupBody = ({ groupId, projects }: GroupBodyProps) => {
  const groupView = getMainPageView();

  return (
    <Collapse in={true} className='group-body' timeout={3}>
      {groupView === 'table' ? (
        <LoadersTable groupId={groupId} projects={projects} />
      ) : (
        <LoadersCards groupId={groupId} projects={projects} />
      )}
    </Collapse>
  );
};
