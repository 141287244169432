import { useEffect } from 'react';
import { wsBI } from '../utils/api';


export const useWidgetChangesObserver = (callback?: Function) => {

  useEffect(() => {

    let loaderChangeStatusUID: string;

    const onMessageReceived = (messageOutput: any) => {
      try {
        const IDs: number[] = JSON.parse(messageOutput.body);

        callback && callback(IDs);
      } catch (e) {
        console.error('Ошибка извлечения списка widget id из топика /topic/cache-evicted');
      }
    };

    wsBI.synchronizedConnect(() => {
      loaderChangeStatusUID = wsBI.addHandler(
        '/topic/cache-evicted',
        onMessageReceived,
      );
    });

    return () => {
      if (!loaderChangeStatusUID) return;
      wsBI.removeHandler(loaderChangeStatusUID);
    };
  }, [callback]);
};
