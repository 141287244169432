import { FixedSizeList } from 'react-window';
import React from 'react';
import {
  LABEL_LINE_HEIGHT,
  MARGIN_TOP_LABEL_VALUE,
  MIN_CHART_TOP_OFFSET,
} from '../constants';
import { useScales } from '../hooks/useScales';
import {
  BubbleData,
  BubbleSets,
  Dimensions,
  FormattingValues,
  Interaction,
  SeriesValues,
} from '../types';
import { AxisLeft } from './axis-left';
import { Column } from './column';

interface BubbleProps {
  width: number;
  height: number;
  data: BubbleData[];
  fields: SeriesValues;
  formattingValues: FormattingValues;
  interaction: Interaction;
  bubbleSets: BubbleSets;
}

export const Bubble = ({
  width,
  height,
  data,
  fields,
  formattingValues,
  interaction,
  bubbleSets,
}: BubbleProps) => {
  const { deselectBubble } = interaction;
  const { zField } = fields;
  const {
    pointMinSize,
    pointMaxSize,
    pointDiffSize,
    axisXHeight,
    axisYWidth,
    pointOffsetCoeff,
    isNeedToDisplayValue,
    isNeedToDisplayPercent,
    isNeedToDisplayNameSlice,
  } = bubbleSets;

  // bounds
  const minDiameter = pointMinSize;
  const maxDiameter = zField ? pointMaxSize : minDiameter;

  const chartTopOffset = Math.max(
    maxDiameter * pointOffsetCoeff,
    MIN_CHART_TOP_OFFSET,
  );
  const chartBottomOffset =
    maxDiameter * pointOffsetCoeff +
    (isNeedToDisplayValue ? LABEL_LINE_HEIGHT : 0) +
    (isNeedToDisplayPercent ? LABEL_LINE_HEIGHT : 0) +
    (isNeedToDisplayNameSlice ? LABEL_LINE_HEIGHT : 0) +
    (isNeedToDisplayValue || isNeedToDisplayPercent || isNeedToDisplayNameSlice
      ? MARGIN_TOP_LABEL_VALUE
      : 0);

  const chartWidth = width - axisYWidth;
  const chartHeight = height - axisXHeight;

  const isNeedScroll = data.length * pointDiffSize > chartWidth;
  const columnWidth = isNeedScroll
    ? pointDiffSize
    : (chartWidth / (data.length * pointDiffSize)) * pointDiffSize;

  const dimensions: Dimensions = {
    width,
    chartWidth,
    chartHeight,
    chartTopOffset,
    chartBottomOffset,
    columnWidth,
    minDiameter,
    maxDiameter,
    pointDiffSize,
    axisXHeight,
    axisYWidth,
  };

  const scales = useScales({
    data,
    bubbleSets,
    dimensions,
  });

  return (
    <div style={{ width, height }} onClick={deselectBubble}>
      <AxisLeft
        dimensions={dimensions}
        bubbleSets={bubbleSets}
        formattingValues={formattingValues}
        scales={scales}
      />
      <div
        className="widget-bubble-chart__chart"
        style={{
          width: chartWidth,
          height,
          left: axisYWidth,
        }}
      >
        <FixedSizeList
          height={height}
          itemCount={data.length}
          itemSize={columnWidth}
          layout="horizontal"
          width={chartWidth}
          style={{ overflowY: 'hidden' }}
        >
          {({ index, style }) => (
            <Column
              xPosition={index}
              stylePosition={style}
              data={data}
              fields={fields}
              formattingValues={formattingValues}
              interaction={interaction}
              bubbleSets={bubbleSets}
              dimensions={dimensions}
              scales={scales}
            />
          )}
        </FixedSizeList>
      </div>
    </div>
  );
};
