/* eslint-disable react/jsx-key */
import React, { createContext, FC } from 'react';
import { useUpdateLoaderStatus } from '../hooks/useUpdateLoaderStatus';
import { IUpdateLoaderStatusSubscribe, IUpdateWidgetCacheSubscribe } from '../classes/UpdateLoaderStatusController';

export interface IUpdateLoaderStatusContext {
  addLSSubscribe: (subscribe: IUpdateLoaderStatusSubscribe) => void;
  deleteLSSubscribe: (label: string) => void;
  addWCSubscribe: (subscribe: IUpdateWidgetCacheSubscribe) => void;
  deleteWCSubscribe: (widgetId: number) => void;
}

export const UpdateLoaderStatusContext =
  createContext<IUpdateLoaderStatusContext>({
    addLSSubscribe: () => {},
    deleteLSSubscribe: () => {},
    addWCSubscribe: () => {},
    deleteWCSubscribe: () => {},
  });

UpdateLoaderStatusContext.displayName = 'ComplexTableContext';

export const UpdateLoaderStatusProvider: FC<{children: JSX.Element}> = ({ children }) => {

  const { addLSSubscribe, deleteLSSubscribe, addWCSubscribe, deleteWCSubscribe } = useUpdateLoaderStatus();

  return (
    <UpdateLoaderStatusContext.Provider
      value={{
        addLSSubscribe,
        deleteLSSubscribe,
        addWCSubscribe,
        deleteWCSubscribe,
      }}
    >
      {children}
    </UpdateLoaderStatusContext.Provider>
  );
};
