export enum ConnectionFieldType {
  ConnectionName = 'CONNECTION_NAME',
  Host = 'HOST',
  Port = 'PORT',
  ServiceName = 'SERVICE_NAME',
  Login = 'LOGIN',
  Password = 'PASSWORD',
  Namespace = 'NAMESPACE',
  SoapRequest = 'SOAP_REQUEST',
  NamespaceUrl = 'NAMESPACE_URL',
  Encoding = 'ENCODING',
  Url = 'URL',
  ConnectionConfig = 'CONNECTION_CONFIG',
}

export enum FileFieldType {
  WithHeader = 'WITH_HEADER',
  Delimiter = 'DELIMITER',
  Mask = 'MASK',
  WorkingDirectory = 'WORKING_DIRECTORY',
  Charset = 'CHARSET',
  DataAddress = 'DATA_ADDRESS',
  RowTag = 'ROW_TAG',
  CheckXsd = 'CHECK_XSD',
  XsdFileName = 'XSD_FILE_NAME',
  JSONLINES = 'JSONLINES',
  DOCUMENT_ID = 'DOCUMENT_ID',
  SHEET = 'SHEET'
}
