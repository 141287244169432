import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { getRangeOfDates, roundDate } from '../../../../../utils/functions';

const getMonthRange = (daysRange: any[]) => {
  const monthRangeDynamic: any[] = [];
  const counter = new Map();

  for (const day of daysRange) {
    const key = `${day.month()}/${day.year()}`;
    if (counter.has(key)) {
      counter.set(key, {
        ...counter.get(key),
        count: counter.get(key).count + 1,
      });
    } else {
      counter.set(key, { count: 1, day });
    }
  }

  [...counter.entries()].reduce((accumulator, [, data]) => {
    monthRangeDynamic.push({
      date: moment(data.day),
      daysCount: data.count,
      prevDays: accumulator,
    });
    return accumulator + data.count;
  }, 0);

  return monthRangeDynamic;
};

export const useGanttProperties = (originalData: Array<any>) => {
  const data = cloneDeep(originalData);
  const endDateSortedTasks = data.sort((a, b) => a.endDate - b.endDate);
  const startDateSortedTasks = data.sort((a, b) => a.startDate - b.startDate);

  const totalEndDate = roundDate(
    endDateSortedTasks[endDateSortedTasks.length - 1].endDate,
    'ceil',
  );
  const totalStartDate = roundDate(startDateSortedTasks[0].startDate, 'floor');

  const daysRange = getRangeOfDates(totalStartDate, totalEndDate);

  const numTicksRows = data.length;
  const numTicksColumns = daysRange.length;

  const monthRange = useMemo(() => getMonthRange(daysRange), [
    JSON.stringify(daysRange),
  ]);

  return {
    totalEndDate,
    totalStartDate,
    numTicksRows,
    numTicksColumns,
    daysRange,
    monthRange,
  };
};
