import React from 'react';
import './legend.css';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { PanelType } from '../../../../enums/widget-type';

export function Legends({
  data,
  colors,
  itemHeight,
  trapezoidStepHeight,
  maxWidth,
  textColor,
  widgetProperties,
  axisXValueIndex,
}: {
  data: any[];
  colors: string[];
  itemHeight: number;
  trapezoidStepHeight: number;
  maxWidth: number;
  textColor: string;
  widgetProperties?: any;
  axisXValueIndex: number;
}) {
  const valueFormat = initValueFormatter();

  return (
    <div className="sales-funnel-legend__container" style={{ maxWidth }}>
      <div className="sales-funnel-legend__list">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className="sales-funnel-legend__item"
              style={{ height: itemHeight, marginBottom: trapezoidStepHeight }}
            >
              <div
                className="sales-funnel-legend__number"
                style={{ backgroundColor: colors[index], color: textColor }}
              >
                {index + 1}
              </div>
              <div className="sales-funnel-legend__label">
                {valueFormat(
                  item.label,
                  axisXValueIndex,
                  widgetProperties,
                  PanelType.axisX,
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
