import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { HierarchyOptionsProps, HierarchyValues } from './index';
import { apiFetchSourceData } from '../../../../../services/widgetController';
import { SourceField } from '../../../../../slices/types';
import { HierarchyLink } from '../../../dropdown-layout/helpers/Property';
import {
  getHierarchyLevelNameMap,
  getHierarchyLevels,
} from '../../../../../helpers/widget-page';

const validate = (values: HierarchyValues) => {
  const errors: { [key: string]: string } = {};

  Object.keys(values).forEach((fieldName) => {
    // @ts-ignore
    if (
      typeof values[fieldName as keyof HierarchyValues] === 'string' &&
      !values[fieldName as keyof HierarchyValues]?.length
    ) {
      errors[fieldName] = 'Поле обязательно для заполнения';
    }
  });

  values.hierarchyLevels.forEach((level) => {
    if (!level.length) {
      errors.hierarchyLevels = 'Названия уровней обязательны для заполнения';
    }
  });

  return errors;
};

export const useHierarchyModal = ({
  panelData,
  closeDialog,
  changeHierarchy,
}: HierarchyOptionsProps) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [sourceFields, setSourceFields] = useState<SourceField[]>([]);

  const tabs = [
    {
      id: 'main',
      title: 'Основные настройки',
      onClick: () => setCurrentTab(0),
    },
    {
      id: 'view',
      title: 'Настройки отображения',
      onClick: () => setCurrentTab(1),
    },
  ];

  const handleSave = (values: HierarchyValues) => {
    const hierarchyLink: HierarchyLink = {
      dicSourceId: values.dicSourceId,
      dicReferenceFieldName:
        sourceFields.find(
          (source) => source.etlFieldId === values.dicReferenceField,
        )?.name || '',
      dicReferenceFieldEtlFieldId: values.dicReferenceField,
      dicReferenceDisplayName:
        sourceFields.find(
          (source) => source.etlFieldId === values.dicReferenceDisplay,
        )?.name || '',
      dicReferenceDisplayEtlFieldId: values.dicReferenceDisplay,
      dicParentName: dicParentName || '',
      dicParentEtlFieldId: values.dicParent,
      hierarchyLevelNameMap: getHierarchyLevelNameMap(values.hierarchyLevels),
      dicParentRootValue: values.dicParentRootValue,
    };

    changeHierarchy(hierarchyLink);
    closeDialog();
  };

  const handleClose = () => {
    closeDialog();
  };

  const initialValues = panelData.hierarchyLink
    ? {
        dicSourceId: panelData.hierarchyLink.dicSourceId,
        dicReferenceField: panelData.hierarchyLink.dicReferenceFieldEtlFieldId,
        dicReferenceDisplay:
          panelData.hierarchyLink.dicReferenceDisplayEtlFieldId,
        dicParent: panelData.hierarchyLink.dicParentEtlFieldId,
        hierarchyLevels: getHierarchyLevels(
          panelData.hierarchyLink.hierarchyLevelNameMap,
        ),
        dicParentRootValue: panelData.hierarchyLink.dicParentRootValue,
      }
    : {
        dicSourceId: '',
        dicReferenceField: '',
        dicReferenceDisplay: '',
        dicParent: '',
        hierarchyLevels: [],
        dicParentRootValue: 'NULL',
      };

  const { values, handleChange, handleSubmit, errors, setErrors } =
    useFormik<HierarchyValues>({
      initialValues,
      onSubmit: handleSave,
      validate,
      validateOnChange: false,
      validateOnBlur: false,
    });

  const handleChangeFormikValue = useCallback(
    (event: any) => {
      handleChange(event);

      const resetError = () => {
        if (errors[event.target.name as keyof HierarchyValues]) {
          const newErrors = { ...errors };
          delete newErrors[event.target.name as keyof HierarchyValues];
          setErrors(newErrors);
        }
      };

      resetError();
    },
    [errors, handleChange, setErrors],
  );

  const dicParentName = useMemo(
    () =>
      sourceFields.find((source) => source.etlFieldId === values.dicParent)
        ?.name,
    [sourceFields, values.dicParent],
  );

  useEffect(() => {
    const loadSourceFields = () => {
      if (values.dicSourceId) {
        apiFetchSourceData(values.dicSourceId).then((result) => {
          setSourceFields(result.fields);
        });
      }
    };

    loadSourceFields();
  }, [values.dicSourceId]);

  const handleClickDelete = () => {
    changeHierarchy();
    closeDialog();
  };

  return {
    handleSubmit,
    tabs,
    currentTab,
    handleChange: handleChangeFormikValue,
    values,
    errors,
    handleClose,
    sourceFields,
    dicParentName,
    setErrors,
    handleClickDelete,
  };
};
