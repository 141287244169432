import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorPage { code: string | null }

const initialState: ErrorPage = { code: null };

const errorPageSlice = createSlice({
  name: 'errorPage',
  initialState,
  reducers: {
    setCode: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.code = payload;
    },
    clearErrorPageError: (
      state,
    ) => {
      state.code = null;
    },
  },
});

export const errorPageReducer = errorPageSlice.reducer;

export const {
  setCode: setCodeAction,
  clearErrorPageError: clearErrorPageErrorAction,
} = errorPageSlice.actions;
