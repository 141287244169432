import block from 'bem-cn';
import React from 'react';
import copy from 'copy-to-clipboard';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { wrapFieldToReplace } from '../../../../utils/functions';
import './field-item.css';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { useNotificator } from '../../../common/snackbar/hooks';
import { CustomIconButton, IconButtonType } from '../../../../uikit/IconButton';
import { getIconByDataType } from '../../../widget-page/helpers';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface FieldItemProps {
  name: string;
  alias: string;
  type?: string;
}

const b = block('field-item');

const FieldItem: React.FC<FieldItemProps> = ({ name, type, alias }) => {
  const { showNotification } = useNotificator();

  const copyToClipboard = () => {
    copy(wrapFieldToReplace(alias || name));
    showNotification({
      message: 'Скопировано в буфер',
      variant: 'success'
    });
  };

  return (
    <div className={b()}>
      <p className={b('type-icon')}>
        <IconSvg
          svg={getIconByDataType(type)}
          fill="var(--dark-grey)"
        />
      </p>
      <CustomTooltip title={alias || name} arrow>
        <p className="field-item__title">{alias || name}</p>
      </CustomTooltip>
      <div className={b('icons')}>
        {alias && alias !== name && (
          <CustomTooltip
            arrow
            title={`Название в источнике: ${name}`}
          >
            <div>
              <IconSvg
                svg={IconDictionary.InfoRounded}
                fill="var(--dark-grey)"
              />
            </div>
          </CustomTooltip>
        )}
        <CustomTooltip
          arrow
          title="Скопировать имя поля"
        >
          <div>
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.Copy} fill="var(--dark-grey)" />,
                onClick: copyToClipboard,
              }}
              type={IconButtonType.secondary}
            />
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default FieldItem;
