import React, { ComponentType } from 'react';
import { Route } from 'react-router-dom';
import Layout from './layout/layout';

interface Props {
  path: string;
  component: ComponentType<any>;
  exact?: boolean;
}

const RouteLayout: React.FC<Props> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteLayout;
