import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { closestCenter, DndContext } from '@dnd-kit/core';
import './styles.css';
import { WidgetDropdownMainContainer } from './components/widget-dropdown-main-container/widget-dropdown-main-container';
import { useDropdownPanel } from './useDropdownPanel';

export interface WidgetDropdownProps {
  id: string;
  name: string;
}

const WidgetDropdown: React.FC<WidgetDropdownProps> = ({
  id,
  name,
}) => {
  const {
    sensors,
    onDragEnd,
    cancelDrop,
  } = useDropdownPanel({ id, name });

  return (
    <>
      <Droppable droppableId={`widgetDroppable-${id}`} mode="standard" direction="horizontal">
        {
        (provided, snapshot) => (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
            cancelDrop={cancelDrop}
          >
            <div
              className="widget-droppable"
              {...provided.droppableProps}
              style={{
                boxShadow: snapshot.isDraggingOver
                  ? '0 0 13px 1px #3b82e452'
                  : 'none',
                zIndex: snapshot.isDraggingOver ? 1 : 'inherit',
                boxSizing: 'border-box'
              }}
              ref={provided.innerRef}
            >
              <WidgetDropdownMainContainer id={id} name={name} />
              {provided.placeholder}
            </div>
          </DndContext>
        )
      }
      </Droppable>
    </>
  );
};

export default WidgetDropdown;
