import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import L, {
  LayerGroup,
  LeafletEvent,
  Map,
  PathOptions,
  StyleFunction,
} from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { GeoJsonObject } from 'geojson';
import { isEqual } from 'lodash';
import { useMapProperties } from './useMapProperties';
import { DashboardProperty, State, WidgetProperties } from '../../../../../slices/types';
import { layerFillOpacity, MapDataTypes, POINT_LIMIT } from '../settings';
import { Color, defaultColors } from '../../common/color';
import { isDashboardPage, sortColorPalette } from '../../helpers';
import {
  CardData,
  Feature,
  getClusteredMarkers,
  MapData,
  PolygonGeometry,
  setMapCenterAndZoom,
} from '../map-helpers';
import { FilterField, SetFilterField } from '../../../../dashboard-page/hooks';
import { useMapCards } from './useMapCards';
import { useMapOptions } from './useMapOptions';
import { useMapActiveLegend } from './useMapActiveLegend';
import {
  setPropForDate,
  setPropForNumberValue,
} from '../../formatting-helpers';
import { PanelType } from '../../../../../enums/widget-type';
import { useRoundingCounts } from '../../../../../hooks/useRoundingCounts';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import { Property } from '../../../dropdown-layout/helpers/Property';
import { setSelectedMapCardActions } from '../../../../../slices/main-page/main-page-slice';
import { useWidgetFullScreen } from '../../../../../hooks/charts/useWidgetFullScreen';
import '../custom-controls';

interface UseMapSettingsProps {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  enhancedParams: {dashboardProperties: DashboardProperty[], widgetOverriddenProperties: WidgetProperties[]};
}

export const useMapSettings = ({
  widgetProps,
  filterField,
  isActiveFilter,
  setFilterField,
  enhancedParams
}: UseMapSettingsProps) => {
  const dispatch = useDispatch();

  const widgetId: number = widgetProps.id;

  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  const widgetProperties = widgetProps.properties;

  const mapData = widgetProps.data;

  const {
    mapLayerClusterization,
    axisXValues,
    axisYValues,
    axisZValues,
    colorsPaletteState,
    axisFilter,
    dateDisplayFormat,
  } = useMapProperties({ widgetProperties });

  const parsedMapData: MapData | null = mapData || null;

  const isClusterizationMap = parsedMapData
    ? mapLayerClusterization || parsedMapData.pointCount > POINT_LIMIT
    : false;

  const {
    activeLayers,
    handleClickLegendItem,
  } = useMapActiveLegend({ widgetProperties });

  const selectedMapCardForBoundFilter =
    useSelector(
      (state: State) =>
        state.mainPage.selectedMapCards &&
        state.mainPage.selectedMapCards[widgetId],
    ) || null;

  const [dataError, setDataError] = useState<string>('');
  const [isNeedToDisplayCardsList, setIsNeedToDisplayCardsList] =
    useState<boolean>(false);
  const [dataTypes, setDataTypes] = useState<string[]>([]);

  const isMounted = useRef<boolean>(false);
  const mapRef = useRef<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement>(document.createElement('div'));
  const layerRef = React.useRef<LayerGroup | null>(null);

  const visibleCards = useMemo(
    () => axisXValues.map((value) => value.displayAllCards || false),
    [axisXValues],
  );

  const heatLayersSourceIds = useMemo(
    () => axisZValues.map((value) => value.etlSourceId),
    [axisZValues],
  );

  const heatMapLayers = useMemo(
    () =>
      axisXValues.map((value) =>
        heatLayersSourceIds.includes(value.etlSourceId),
      ),
    [axisXValues],
  );

  const enableEvents: boolean = isActiveFilter;

  const currentColors: Color[] = useMemo(
    () =>
      sortColorPalette(
        colorsPaletteState?.firstColor,
        colorsPaletteState?.colorsList,
      ) || defaultColors,
    [colorsPaletteState],
  );

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const getFormattedValue = useCallback((fieldType: string, content: string,) => {
    if (fieldType === 'TEXT') return content;

    const widgetPropertiesForFormatting =
      fieldType === 'NUMBER'
        ? setPropForNumberValue(widgetProperties)
        : setPropForDate(
          '',
          dateDisplayFormat,
          fieldType,
        );

    return valueFormat(
      content,
      0,
      widgetPropertiesForFormatting,
      PanelType.axisY,
    );
  }, [dateDisplayFormat, valueFormat]);

  const deselectPoint = useCallback(() => {
    setFilterField &&
    setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  // часть логики фильтров (deselectPoint) выполняется в useMapCards, потому что там отслеживается
  // по какой части карты был произведен клик, и какое событие должно отработать
  const {
    handlerChangeFilter,
    filteredPopupContentList,
    setAllPopupContents,
    onClickPoint,
    onClickMap,
    isLoadingCards,
  } = useMapCards({
    deselectPoint,
    activeLayers,
    widgetProperties,
    enhancedParams,
    widgetSourceId: widgetProps.sourceId,
    needCache: widgetProps.needCache,
    pushDown: widgetProps.pushDown,
    getFormattedValue,
    widgetId
  });

  const { onEachFeature, getGeoJsonStyle, getCircleMarker } = useMapOptions({
    widgetProperties,
    filterField,
    currentColors,
    isActiveFilter,
  });

  const polygonDataVerification = (mapLayer: Feature) => {
    const polygonGeometry: PolygonGeometry = mapLayer.geometry as PolygonGeometry;
    if (
      !polygonGeometry?.coordinates ||
      !polygonGeometry.coordinates.length ||
      !polygonGeometry.coordinates[0].length ||
      !polygonGeometry.coordinates[0][0].length ||
      typeof polygonGeometry.coordinates[0][0][0] !== 'number'
    ) {
      setDataError(
        'В данных отсутствуют координаты или данные имеют неправильную структуру.',
      );
    }
  };

  const pointDataVerification = (mapLayer: Feature) => {
    if (
      !mapLayer.geometry?.coordinates ||
      !mapLayer.geometry.coordinates.length ||
      typeof mapLayer.geometry.coordinates[0] !== 'number'
    ) {
      setDataError(
        'В данных отсутствуют координаты или данные имеют неправильную структуру.',
      );
    }
  };

  const dataVerification = (mapLayer: Feature) => {
    if (mapLayer.geometry?.type) {
      switch (mapLayer.geometry.type.toUpperCase()) {
        case MapDataTypes.POLYGON:
          polygonDataVerification(mapLayer);
          break;
        case MapDataTypes.POINT:
          pointDataVerification(mapLayer);
          break;
        default:
          setDataError(
            'Некорректный тип данных. Тип данных должен быть полигоном или точкой.',
          );
      }
    }
  };

  const selectPoint = useCallback(
    (layerValue:  number[] | number[][][], layerIndex: number, values: CardData[]) => {
      if (!enableEvents) return;
      if (!setFilterField || !values) return;

      const axisXValue = new Property(axisXValues[layerIndex]);

      setFilterField(
        widgetId,
        axisXValue.getId(),
        [
          {
            operation: 'IN',
            value: [JSON.stringify(layerValue)],
          },
        ],
        {
          data: values,
          transcription: axisYValues.filter(
            (index: any) =>
              index.etlSourceId === axisXValue.getEtlSourceId(),
          ),
          etlSourceId: axisXValue.getEtlSourceId(),
        },
      );
    },
    [axisXValues, axisYValues, enableEvents, setFilterField, widgetId],
  );

  const saveCurrentLayer = (event: LeafletEvent) => {
    if (!isDashboardPage() || !isActiveFilter) return;

    const feature = event.layer.feature as Feature;
    dispatch(
      setSelectedMapCardActions({
        key: widgetId,
        value: JSON.stringify(feature.geometry),
      }),
    );
  };

  const onClickLayer = (event: L.LeafletEvent, isInteractiveLayer: boolean) => {
    isInteractiveLayer && onClickPoint(event);
    isActiveFilter && saveCurrentLayer(event); // only for bound filters on dashboard

    const feature = event.layer.feature as Feature;

    const layerIndex = feature?.properties.index;
    const layerValue = feature?.geometry.coordinates;

    const values = feature?.properties?.popupContent?.map((item) => {
      return item.cardData?.reduce((previous, current) => {
        const value = previous;
        value[current.title] = current.value;
        return value;
      }, {} as any);
    });

    selectPoint(layerValue, layerIndex, values);
  };

  const createLayer = (mapLayer: Feature, clusteredMarkers: any) => {
    const GeoJSONLayer = L.geoJSON(mapLayer as GeoJsonObject, {
      style: getGeoJsonStyle as PathOptions | StyleFunction,
      pointToLayer: getCircleMarker,
      onEachFeature,
    });

    if (
      isClusterizationMap &&
      mapLayer.geometry.type.toUpperCase() === MapDataTypes.POINT
    ) {
      clusteredMarkers.addLayer(GeoJSONLayer);
      // eslint-disable-next-line no-param-reassign
      clusteredMarkers._featureGroup.options.index =
        mapLayer.properties.index;
      clusteredMarkers._featureGroup.options.popupContentsForCluster.push(
        mapLayer.properties.popupContent,
      );
      layerRef.current && layerRef.current.addLayer(clusteredMarkers);
    } else {
      layerRef.current && layerRef.current.addLayer(GeoJSONLayer);
    }

    return GeoJSONLayer;
  };

  const createMap = () => {
    if (parsedMapData) {
      layerRef.current && layerRef.current.clearLayers();
      const geoJsonData = parsedMapData.features;

      const clusteredMarkers = getClusteredMarkers();
      clusteredMarkers._featureGroup.options.popupContentsForCluster = [];

      geoJsonData && geoJsonData.map((mapLayer) => {
        dataVerification(mapLayer);
        if (dataError.length) return;

        try {
          if (!activeLayers.includes(mapLayer.properties.index)) return;
          const isInteractiveLayer =
            Boolean(isNeedToDisplayCardsList && visibleCards[mapLayer.properties.index]);

          const GeoJSONLayer = createLayer(mapLayer, clusteredMarkers);

          GeoJSONLayer.on('click', (event) =>
            onClickLayer(event, isInteractiveLayer),
          );
        } catch (e: any) {
          setDataError(`Некорректные координаты. ${e.message}`);
        }
      });
    } else {
      layerRef.current && layerRef.current.clearLayers();
    }
  };

  const initMap = () => {
    if (dataError.length) return;
    if (isMounted.current && mapRef.current) {
      mapRef.current.off();
      mapRef.current.remove();
    }

    mapRef.current = L.map(mapContainerRef.current, {
      center: [49.418317, 43.911639],
      zoom: 5,
      layers: [
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
      zoomControl: false,
    }).on('click', onClickMap);

    L.control.zoom = (options) => new L.Control.Zoom(options);
    L.control.zoom().addTo(mapRef.current);

    mapRef.current.createPane('markerPane');
  };

  // for bound filters on dashboard
  const defineSelectedFieldStyles = () => {
    if (!isDashboardPage()) return;

    // @ts-ignore
    Object.keys(mapRef.current._layers).forEach((key) => {
      // @ts-ignore
      const layer = mapRef.current._layers[key];
      layer.setStyle &&
        layer.setStyle({
          fillOpacity: filterField?.value
            ? layerFillOpacity.notSelected
            : layerFillOpacity.default,
        });
    });

    if (!filterField?.value) {
      return;
    }

    // @ts-ignore
    Object.keys(mapRef.current._layers).forEach((key) => {
      // @ts-ignore
      const layer = mapRef.current._layers[key];

      const isCurrentLayer =
        layer.feature?.geometry &&
        JSON.stringify(layer.feature?.geometry) ===
          selectedMapCardForBoundFilter;

      if (isCurrentLayer) {
        layer.setStyle &&
          layer.setStyle({ fillOpacity: layerFillOpacity.selected });
      }
    });
  };

  useEffect(() => {
    initMap();
    if (dataError.length || !mapRef.current) return;
    layerRef.current = L.layerGroup().addTo(mapRef.current);
  }, [isFullScreen, dataError]);

  useEffect(() => {
    if (dataError.length) setDataError('');
  }, [dataTypes, axisXValues]);

  useEffect(() => {
    const defineLayersDataTypes = () => {
      const newDataTypes = axisXValues.map((item) => item.type.toUpperCase());
      !isEqual(newDataTypes, dataTypes) && setDataTypes(newDataTypes);
    };

    defineLayersDataTypes();
  }, [mapData, widgetProperties, isFullScreen]);

  useEffect(() => {
    createMap();
  }, [
    mapData,
    dataTypes,
    activeLayers,
    JSON.stringify(currentColors),
    isFullScreen,
    mapLayerClusterization,
    isNeedToDisplayCardsList,
  ]);

  useEffect(() => {
    defineSelectedFieldStyles();
  }, [JSON.stringify(filterField)]);

  useEffect(() => {
    if (dataTypes.length) {
      setMapCenterAndZoom(mapRef, dataTypes);
    }
  }, [dataTypes, isFullScreen]);

  const defineCardsList = () => {
    setIsNeedToDisplayCardsList(Boolean(visibleCards.includes(true)));
  };

  useEffect(() => {
    defineCardsList();
  }, [visibleCards]);

  useEffect(() => {
    Boolean(visibleCards.includes(true)) && setAllPopupContents();
    !isMounted.current && (isMounted.current = true);
  }, [JSON.stringify(axisYValues), JSON.stringify(axisFilter), JSON.stringify(enhancedParams)]);

  return {
    dataError,
    mapContainerRef,
    currentColors,
    heatMapLayers,
    isNeedToDisplayCardsList,
    dataTypes,
    filteredPopupContentList,
    handlerChangeFilter,
    handleClickLegendItem,
    activeLayers,
    isLoadingCards,
  };
};
