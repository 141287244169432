import React, { useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { CssVariables } from '../../../../enums/css-variables';
import { Placement } from '../../../../enums/visual-types';
import { initWidgetFiltersOnDashboard } from '../../../../helpers/dashboard-page/dashboard-widgets';
import { varcss } from '../../../../helpers/ui-helper';
import {
  getAxisValues,
  getPanelIndex,
  getParsedAxisValues,
  getSimplifiedType,
} from '../../dropdown-layout/helpers/helpers';
import { Property, PropertyData } from '../../dropdown-layout/helpers/Property';
import { PanelType } from '../../../../enums/widget-type';
import { setWidgetPropsAction } from '../../../../slices/widget/widget';
import { State, Widget, WidgetProperties } from '../../../../slices/types';
import { ValueFilter } from '../wrappers/value-filter';
import {
  DateFilter,
  DateFilterOptions,
} from '../wrappers/date-filter/date-filter';
import { SelectFilter } from '../wrappers/select-filter';

import './styles.css';
import { isDashboardPage } from '../../charts/helpers';
import { FilterGroupProps } from '../../../dashboard-page/hooks';
import { BoundType } from '../../../dashboard-page/settings/enums';
import { useDashboardBoundFilters } from '../../../dashboard-page/hooks/useDashboardBoundFilters';
import { useDashboardManualFilters } from '../../../dashboard-page/hooks/useDashboardManualFilters';
import { getAllFieldFilters } from '../../../../helpers/dashboard-page';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { CustomButton } from '../../../../uikit/Button';
import { CustomPanelHead, CustomPanelHeadType } from '../../../../uikit/PanelHead';
import { SelectDateTypes } from '../../../../enums/dashboard-properties';
import { getWidgetFilterName } from '../../helpers';
import { SetOverriddenProperties } from '../../../../helpers/dashboard-page/widget';
import { DateFunctions } from '../../../../enums/date';

interface FiltersContainerProps {
  anchorEl: Element | null;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  values: PropertyData[];
  widgetProps?: Widget;
  setOverriddenProperties?: SetOverriddenProperties;
  overriddenProperties?: WidgetProperties[];
  overriddenPropertiesInherited?: WidgetProperties[];
  dashboardFiltersGroups?: FilterGroupProps[];
}

export const FiltersContainer: React.FC<FiltersContainerProps> = ({
  anchorEl,
  onClose,
  values,
  widgetProps,
  setOverriddenProperties,
  overriddenProperties,
  overriddenPropertiesInherited,
  dashboardFiltersGroups,
}) => {
  const isDashboard = isDashboardPage();
  const boundFilers = useDashboardBoundFilters();
  const drilldownValues =
    useDashboardManualFilters(BoundType.autoDrillDownGroups)?.drilldownValues ||
    [];
  drilldownValues.push(
    ...(useDashboardManualFilters(BoundType.manualDrillDownGroups)
      ?.drilldownValues || []),
  );

  const isOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const localProperties = useSelector(
    (state: State) => state.widget.properties,
  );
  const localSourceId = useSelector((state: State) => state.widget.sourceId);

  const properties = widgetProps?.properties || localProperties;
  const sourceId = widgetProps?.sourceId || localSourceId;

  const widgetProperties = useMemo(() => [...properties] || [], [properties]);

  const handleChangeFilter = useCallback(
    (property: any, filterEtlFieldId: any, filter: any) => {
      const propertyInstance = new Property(property);
      propertyInstance.changeFilter(filter);

      const widgetProperties = [...properties] || [];
      const axisIndex: number = getPanelIndex({
        properties: widgetProperties,
        type: PanelType.axisFilter,
      });
      const axisValue: string = getAxisValues(
        PanelType.axisFilter,
        widgetProperties,
      );
      const parsedValue = JSON.parse(axisValue);

      const index = parsedValue.findIndex(
        ({ etlFieldId }: any) => etlFieldId === filterEtlFieldId,
      );

      if (index === -1) {
        console.warn('Try to access to undefined filter');
        return;
      }

      parsedValue[index] = propertyInstance.isCalculated()
        ? propertyInstance.getCalculatedValue()
        : propertyInstance.getValue();

      widgetProperties[axisIndex] = {
        ...widgetProperties[axisIndex],
        value: JSON.stringify(parsedValue),
      };

      dispatch(setWidgetPropsAction(widgetProperties));
    },
    [dispatch, properties],
  );
  const handleChangeFilterFromDashboard = useCallback(
    (property: any, filterEtlFieldId: any, filter: any) => {
      if (!setOverriddenProperties || !overriddenProperties) return;

      const propertyInstance = new Property(property);

      propertyInstance.changeFilter(filter);

      const axisValue: string = getAxisValues(
        PanelType.axisFilter,
        overriddenProperties,
      );
      const parsedValue = JSON.parse(axisValue);

      const index = parsedValue.findIndex(
        ({ etlFieldId }: any) => etlFieldId === filterEtlFieldId,
      );

      if (index === -1) {
        console.warn('Try to access to undefined filter');
        return;
      }

      parsedValue[index] = propertyInstance.isCalculated()
        ? propertyInstance.getCalculatedValue()
        : propertyInstance.getValue();

      setOverriddenProperties([
        {
          name: PanelType.axisFilter,
          value: JSON.stringify(parsedValue),
        },
      ]);
    },
    [JSON.stringify(overriddenProperties), setOverriddenProperties],
  );

  const handleResetFilterFromDashboard = useCallback(() => {
    onClose({}, 'backdropClick');

    if (!overriddenProperties || !setOverriddenProperties) return;

    if (overriddenPropertiesInherited) {
      const parsedAxisValues: PropertyData[] = getParsedAxisValues(
        PanelType.axisFilter,
        widgetProperties,
      );

      initWidgetFiltersOnDashboard(
        parsedAxisValues,
        dashboardFiltersGroups || [],
        overriddenProperties,
        overriddenPropertiesInherited,
        setOverriddenProperties,
      );
    } else {
      const axisValue: string = getAxisValues(
        PanelType.axisFilter,
        overriddenProperties,
      );
      const parsedValue: PropertyData[] = JSON.parse(axisValue);

      const result = parsedValue.map((prop) => ({
        ...prop,
        filter: [],
      }));

      setOverriddenProperties([
        {
          name: PanelType.axisFilter,
          value: JSON.stringify(result),
        },
      ]);
    }

  }, [
    dashboardFiltersGroups,
    onClose,
    overriddenProperties,
    overriddenPropertiesInherited,
    setOverriddenProperties,
    widgetProperties,
  ]);

  const handleResetFilter = useCallback(() => {
    onClose({}, 'backdropClick');
    const widgetProperties = [...properties] || [];
    const axisIndex: number = getPanelIndex({
      properties: widgetProperties,
      type: PanelType.axisFilter,
    });
    const parsedValues: any[] = getParsedAxisValues(
      PanelType.axisFilter,
      widgetProperties,
    );

    widgetProperties[axisIndex] = {
      ...widgetProperties[axisIndex],
      value: JSON.stringify(
        parsedValues.map((prop: any) => ({ ...prop, filter: [] })),
      ),
    };
    dispatch(setWidgetPropsAction(widgetProperties));
  }, [onClose, properties]);

  const handleReset = isDashboard
    ? handleResetFilterFromDashboard
    : handleResetFilter;

  const handleChange = isDashboard
    ? handleChangeFilterFromDashboard
    : handleChangeFilter;

  const renderer = useCallback(
    (property: any, index: number, allFieldFilters: any) => {
      const remapedType = getSimplifiedType(property.type);
      const { filter } = property;

      const onChangeFilter = (filter: any) => {
        handleChange(property, property.etlFieldId, filter);
      };

      const preparedAllFieldFilters = allFieldFilters.map(
        (fieldFilter: any) => {
          const newFieldFilter = { ...fieldFilter };

          const filterFieldId =
            fieldFilter.sourceFieldId ||
            fieldFilter.widgetCalculatedFieldId;

          const propertyId = property.id || property.widgetFieldId;

          if (propertyId === filterFieldId && !fieldFilter.isBound) {
            newFieldFilter.filters = [];
          }
          return newFieldFilter;
        },
      );

      switch (remapedType) {
        case 'TEXT': {
          if (property.aggregation?.includes('COUNT')) {
            return (
              <ValueFilter
                onChangeFilter={onChangeFilter}
                currentFilter={filter}
                label={getWidgetFilterName(property)}
                placement={Placement.Widget}
              />
            );
          }

          return (
            <SelectFilter
              currentFilter={filter}
              allFieldFilters={preparedAllFieldFilters}
              onChangeFilter={onChangeFilter}
              sourceId={sourceId}
              field={property.name}
              label={getWidgetFilterName(property)}
              widgetId={widgetProps?.id}
              placement={Placement.Widget}
            />
          );
        }

        case 'DATE': {
          if (property.aggregation?.includes('COUNT')) {
            return (
              <ValueFilter
                onChangeFilter={onChangeFilter}
                currentFilter={filter}
                label={getWidgetFilterName(property)}
                placement={Placement.Widget}
              />
            );
          }

          // todo в данном месте displayOptions - костыль, надо доработать логику фильтров виджета с учетом новых фильтров дашборда
          const isPeriod = property.function === '' || property.function.includes('DAY');
          const filterOptions: DateFilterOptions = {
            displayAvailablePeriod: true,
            minValue: property.minValue,
            maxValue: property.maxValue,
            displayOptions: {
              displayAsSingleDate: {
                value: !isPeriod,
                displayedName: SelectDateTypes.date,
              },
              displayAsPeriod: {
                value: isPeriod,
                displayedName: SelectDateTypes.period,
              },
            },
            selectDateType: isPeriod ? SelectDateTypes.period : SelectDateTypes.date
          };

          return (
            <DateFilter
              onChangeFilter={onChangeFilter}
              currentFilter={filter}
              label={getWidgetFilterName(property)}
              filterType={
                property.function
                  ? property.function.split("'")[1]
                  : DateFunctions.NO_TYPE
              }
              filterOptions={filterOptions}
              placement={Placement.Widget}
            />
          );
        }

        case 'NUMBER': {
          return (
            <ValueFilter
              onChangeFilter={onChangeFilter}
              currentFilter={filter}
              label={getWidgetFilterName(property)}
              placement={Placement.Widget}
            />
          );
        }

        case 'BOOLEAN': {
          return (
            <SelectFilter
              currentFilter={filter}
              allFieldFilters={preparedAllFieldFilters}
              onChangeFilter={onChangeFilter}
              sourceId={sourceId}
              field={property.name}
              label={getWidgetFilterName(property)}
              widgetId={widgetProps?.id}
              placement={Placement.Widget}
            />
          );
        }

        default: {
          return <div>Нет фильтра для параметра с типом "{property.type}"</div>;
        }
      }
    },
    [JSON.stringify(overriddenProperties), properties, sourceId],
  );

  const allWidgetFiltersIds: string = getParsedAxisValues(
    PanelType.axisFilter,
    widgetProperties,
  ).map((value: any) => {
    return value.id;
  });

  return (
    <CustomPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      style={{ marginTop: '5px' }}
      overflow="hidden"
      backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
    >
      <Box className="filters-container">
        <CustomPanelHead onClose={() => onClose({}, 'backdropClick')} type={CustomPanelHeadType.popup} title="Фильтры" />
        <div className="filters-container__body">
          <div className="filters-container__list">
            {values
              .filter((filter) => !filter.isInherited)
              .map((property: any, index: number) => {
                return renderer(
                  property,
                  index,
                  getAllFieldFilters(
                    dashboardFiltersGroups || values,
                    property.id,
                    isDashboard,
                    values,
                    allWidgetFiltersIds,
                    boundFilers,
                    widgetProps?.id,
                    drilldownValues,
                  ),
                );
              })}
          </div>

          <CustomButton fullWidth onClick={handleReset} variant="outlined" backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}>
            Сбросить все фильтры
          </CustomButton>
        </div>
      </Box>
    </CustomPopover>
  );
};

FiltersContainer.displayName = 'WidgetFiltersContainer';
