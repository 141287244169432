import { useMemo } from 'react';
import clsx from 'clsx';
import { usePivotTableProperties } from '../../hooks/usePivotTableProperties';
import { initValueFormatter } from '../../../hooks/initValueFormatter';
import { getSimplifiedType } from '../../../../dropdown-layout/helpers/helpers';
import { PanelType } from '../../../../../../enums/widget-type';
import { getWidgetPropertiesIsActiveAxes, isMiddleTotal, isNeedToDisplayTitle, isTotal } from '../../helper';
import { HeadCellProps } from '../../interfaces';
import { WidgetSimplifiedDataType } from '../../../../../../enums/data-type';
import { useColumnSorting } from './useColumnSorting';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

export const useHeadCell = ({
  cell,
  widgetProperties,
  isOpenColumn,
  setDashboardWidgetState,
  style,
}: HeadCellProps) => {
  const {
    headBackgroundColor,
    headTextColor,
    axisZValues,
    axisYValues,
    isNeedToDisplaySorting,
    isNeedToDisplayTooltip,
    headerSize,
    scaleWidget,
    columnWidthType,
  } = usePivotTableProperties({ widgetProperties });

  const currentAxisIndex = useMemo(
    () => axisZValues.findIndex((item) => item.etlFieldId === cell.etlFieldId),
    [axisZValues, cell.etlFieldId],
  );

  const currentAxisYIndex = useMemo(
    () => axisYValues.findIndex((item) => item.etlFieldId === cell.etlFieldId),
    [axisYValues, cell.etlFieldId],
  );

  const alias: string | null =
    JSON.parse(axisYValues[currentAxisYIndex]?.storage ?? '{}').alias || null;

  const valueFormat = initValueFormatter();

  const isDateValue =
    currentAxisIndex !== -1 &&
    getSimplifiedType(axisZValues[currentAxisIndex].type) ===
      WidgetSimplifiedDataType.DATE;

  const isNumberValue =
    currentAxisIndex !== -1 &&
    getSimplifiedType(axisZValues[currentAxisIndex].type) ===
      WidgetSimplifiedDataType.NUMBER;

  const formattedValue =
    isDateValue || isNumberValue
      ? valueFormat(
        cell.name,
        currentAxisIndex,
        getWidgetPropertiesIsActiveAxes(widgetProperties),
        PanelType.axisZ,
      )
      : cell.name;

  const renamedValue = alias || formattedValue;

  const isHeadCellWithNestedColumns =
    cell.level === 1 && axisZValues.length === 2 && !isTotal(cell.id);

  const isNeedToHiddenContent =
    isMiddleTotal(cell.id) &&
    cell.level === 2 &&
    axisZValues.length === 2 &&
    !isOpenColumn || !isNeedToDisplayTitle(style.width as number);

  const { sortingField, setFieldSorting } = useColumnSorting({
    widgetProperties,
    setDashboardWidgetState,
  });

  const sortIcon: keyof typeof IconDictionary =
    sortingField.fieldName === cell.id
      ? sortingField.orderDirection === 'ASC'
        ? 'WidgetSortDown'
        : 'WidgetSortUp'
      : 'WidgetSort';

  const sortIconFill =
    sortingField.fieldName === cell.id
      ? 'var(--primary-color)'
      : 'var(--grey)';

  const isCanSort = cell.isLastChild && isNeedToDisplaySorting;

  const handleSortClick = isCanSort
    ? () => setFieldSorting(cell.id)
    : undefined;

  const classNames = clsx('pivot-header__cell', {
    'pivot-header__total': isTotal(cell.id),
    'pivot-header__cell_dark':
      !isTotal(cell.id) && (isMiddleTotal(cell.id) || !cell.parentId),
    'pivot-header__cell_sort': sortIcon,
  });

  const memoizedRenamedValue: any[] = useMemo(() => {
    return renamedValue.split('\\n');
  }, [renamedValue]);

  return {
    headBackgroundColor,
    headTextColor,
    classNames,
    renamedValue,
    isHeadCellWithNestedColumns,
    isNeedToDisplayTooltip,
    isNeedToHiddenContent,
    isCanSort,
    sortIcon,
    sortIconFill,
    handleSortClick,
    memoizedRenamedValue,
    headerSize,
    scaleWidget,
    columnWidthType,
  };
};
