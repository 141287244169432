import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { CustomSuccessAlert } from '../../../uikit/SuccessAlert';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';
import { CustomWarningAlert } from '../../../uikit/WarningAlert';

export interface NotificationProps {
  title: string;
  content: React.ReactNode | string;
  isSuccess?: boolean;
  isWarning?: boolean;
  isError?: boolean;
}

const Notification: React.FC<NotificationProps> = ({
  isSuccess,
  isWarning,
  isError,
  title,
  content,
}) => {
  if (isSuccess) {
    return (
      <CustomSuccessAlert title={title}>{content}</CustomSuccessAlert>
    );
  }

  if (isWarning) {
    return (
      <CustomWarningAlert title={title}>{content}</CustomWarningAlert>
    );
  }

  if (isError) {
    return (
      <CustomErrorAlert title={title}>{content}</CustomErrorAlert>
    );
  }

  return null;
};

export default Notification;
