export const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(
    b,
  )}`.toUpperCase();
};

export const rgbaToHex = (r: number, g: number, b: number, a: number) => {
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255).toString(16).substring(0, 2)
  ];

  outParts.forEach((part, i) => {
    if (part.length === 1) {
      outParts[i] = `0${part}`;
    }
  });

  return (`#${outParts.join('')}`.toUpperCase());
};

export const getRandomColor = () => {
  const rnd = () => Math.floor(Math.random() * 256);
  return rgbToHex(rnd(), rnd(), rnd());
};
