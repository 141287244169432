/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { useDraggable } from '@dnd-kit/core';

export const DraggableColumn: FC<any> = ({ id, children, disabled  }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: `${id}`,
    disabled,
    data: {
      id,
    }
  });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    zIndex: isDragging ? 999 : 1,
    height: '100%',
    background: 'rgb(242 246 251)',
    boxShadow: '#00000017 0px 2px 9px 5px',
  } : {
    height: '100%',
  };

  const onPointerDown: any = listeners?.onPointerDown;

  return (
    <>
      <div ref={setNodeRef} style={style} onPointerDown={onPointerDown} {...attributes}>
        {children}
      </div>
    </>
  );
};
