import React, { useMemo } from 'react';
import { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie';
import {
  animated,
  interpolate,
  useTransition,
  UseTransitionResult,
} from 'react-spring';
import { easeOutCubic } from '../../../../utils/easings';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { setPropForNumberValue } from '../formatting-helpers';
import { getBooleanPropertyValue, getSelectPropertyValue } from '../helpers';
import { useRoundingCounts } from '../../../../hooks/useRoundingCounts';
import { CalloutLine } from '../common/callout-line';
import clsx from 'clsx';
import { NumberFormat } from '../../../../enums/number-format';
import { getFirstActivePanelItemIndex } from '../../../../helpers/common-helpers';

const TRANSITION_DURATION = 600;

type AnimatedArcProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  delay?: number;
  onClickDatum?: any;
  widgetTooltipArcProps?: any;
  getIsSelected: (d: PieArcDatum<Datum>) => boolean;
  widgetProperties: any[];
  radius: number;
  innerRadius?: number;
  displayLegendLine: boolean;
  isActiveFilter: boolean;
};

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

export function Arc<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
  widgetTooltipArcProps: wTooltip,
  getIsSelected,
  widgetProperties,
  radius,
  innerRadius,
  displayLegendLine,
  isActiveFilter,
}: AnimatedArcProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
    arcs,
    getKey,
    // @ts-ignore react-spring doesn't like this overload
    {
      config: {
        duration: TRANSITION_DURATION,
        easing: easeOutCubic,
      },
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
    },
  );

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );


  const isNeedToDisplayPercentOnSector: boolean = getBooleanPropertyValue(
    'displayPercentOnSector',
    widgetProperties,
  );

  const displayValueOnLegend: boolean = getBooleanPropertyValue(
    'displayValueOnLegend',
    widgetProperties,
  );

  const displayPercentOnLegend: boolean = getBooleanPropertyValue(
    'displayPercentOnLegend',
    widgetProperties,
  );

  const displayTextOnLegend: boolean = getBooleanPropertyValue(
    'displayTextOnLegend',
    widgetProperties,
  );

  const getFormattedValue = (count: number) =>
    valueFormat(
      count,
      0,
      setPropForNumberValue(widgetProperties),
      PanelType.axisY,
      false,
      false,
      scaleByNumber ? formatByNumber : null,
      true
    );

  const getFormattedPercent = (percent: number) =>
    `${valueFormat(percent, 0, setPropForNumberValue(widgetProperties), PanelType.axisY)}%`;

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  return (
    <>
      {transitions.map(
        ({
          item: arc,
          props,
          key,
        }: {
          item: PieArcDatum<Datum>;
          props: AnimatedStyles;
          key: string;
        }) => {
          const transition = transitions.filter(
            (
              el: UseTransitionResult<
                PieArcDatum<Datum>,
                Pick<AnimatedStyles, 'startAngle' | 'endAngle' | 'opacity'>
              >,
            ) => el.key === key,
          )[0];
          const { index } = transition.item;
          const { label, count, percent } = transition.item.data as any;
          const angleDifference = Math.abs(arc.startAngle - arc.endAngle);

          const [centroidX, centroidY] = path.centroid(arc);
          const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.5;
          const hasSpaceForLine = arc.endAngle - arc.startAngle >= 0.3;

          return (
            <g
              key={key}
              onMouseLeave={
                wTooltip?.isNeedToDisplayTooltip
                  ? wTooltip?.hideWidgetTooltip
                  : undefined
              }
              onMouseMove={
                wTooltip?.isNeedToDisplayTooltip
                  ? (event) =>
                      wTooltip?.showWidgetTooltip(event, {
                        attrX: wTooltip!.categoryName,
                        valueX: valueFormat(label, axisXValueIndex, widgetProperties),
                        attrY: wTooltip!.valueName,
                        valueY: `${getFormattedValue(count)} (${getFormattedPercent(percent)})`,
                      })
                  : undefined
              }
              onClick={(e) => {
                e.stopPropagation();
                onClickDatum(label);
              }}
              className={clsx({ 'active-drilldown-element': isActiveFilter })}
            >
              <animated.path
                d={interpolate(
                  [props.startAngle, props.endAngle],
                  (startAngle, endAngle) =>
                    path({
                      ...arc,
                      startAngle,
                      endAngle,
                    }),
                )}
                fill={getColor(arc)}
                opacity={getIsSelected(arc) ? 1 : 0.2}
              />
              {hasSpaceForLabel && isNeedToDisplayPercentOnSector && (
                <animated.g style={{ opacity: props.opacity }}>
                  <text
                    fill="white"
                    x={centroidX}
                    y={centroidY}
                    dy=".33em"
                    className="donut-section__text"
                    textAnchor="middle"
                    pointerEvents="none"
                  >
                    {`${valueFormat(
                      percent,
                      0,
                      setPropForNumberValue(widgetProperties),
                      PanelType.axisY,
                    )}%`}
                  </text>
                </animated.g>
              )}
              {displayLegendLine && (
                <CalloutLine
                  color={getColor(arc)}
                  x={centroidX}
                  y={centroidY}
                  radius={radius}
                  innerRadius={innerRadius}
                  hasSpaceForLine={hasSpaceForLine}
                  legend={{
                    label: valueFormat(label, 0, widgetProperties),
                    count: getFormattedValue(count),
                    percent: getFormattedPercent(percent),
                    displayPercentOnLegend,
                    displayValueOnLegend,
                    displayTextOnLegend,
                  }}
                  angleDifference={angleDifference}
                  arcIndex={index}
                />
              )}
            </g>
          );
        },
      )}
    </>
  );
}
