import React from 'react';
import { useSelector } from 'react-redux';
import SaveVersionForm from 'src/components/common/save-version-form/save-version-form';
import RollbackVersionForm from 'src/components/common/rollback-version-form/rollback-version-form';
import { SubscribeMailingForm } from 'src/components/common/subscribe-mailing-form/subscribe-mailing-form';
import DeleteDialog from '../../../uikit/DeleteDialog/delete-dialog';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import GroupInput from '../group-input/group-input';
import NewProjectForm from '../../common/new-project-form/new-project-form';
import { useMainPage } from '../../../pages/main-page/useMainPage';
import { SubscribeForm } from '../../common/subscribe-form/subscribe-form';
import { State } from '../../../slices/types';
import { ExportLocalFilesModal } from '../../common/export-localfiles-modal';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

interface DialogsProps {
  setShowNewProjectModal: React.Dispatch<React.SetStateAction<boolean>>;
  showNewProjectModal: boolean;
}

export const Dialogs = ({
  setShowNewProjectModal,
  showNewProjectModal,
}: DialogsProps) => {
  const {
    isShowDeleteModal,
    handleCloseDeleteGroupModal,
    handleCloseDeleteModal,
    handleClickDeleteGroup,
    handleClickDelete,
    getDeleteTitle,
    error,
    isDeleting,
    isShowEditModal,
    handleCloseChangeModal,
    handleCloseCopyModal,
    handleCloseSaveVersionModal,
    handleCloseRollbackVersionModal,
    currentProject,
    isShowCopyModal,
    isShowSaveVersionModal,
    isShowRollbackVersionModal,
    handleCloseNewProjectModal,
    EDIT_TITLE,
    isShowSubscribeModal,
    isShowMailingSubscribeModal,
    handleCloseSubscribeModal,
    handleCloseSubscribeMailingModal,
    isShowEditGroupModal,
    isShowDeleteGroupModal,
    handleCloseEditGroupModal,
    isShowCheckFilesModal,
    handleCloseCheckFilesModal,
  } = useMainPage(setShowNewProjectModal);

  const currentProjectId = useSelector(
    (state: State) => state.mainPage.currentProjectId,
  );

  return (
    <>
      <DeleteDialog
        isOpen={isShowDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={handleClickDelete}
        title={getDeleteTitle()}
        bodyText={`данный ${CommonDictionary.oneProject}`}
        maxWidth="xs"
        error={error}
        isLoading={isDeleting}
        isTitleWithTooltip
      />

      <DeleteDialog
        isOpen={isShowDeleteGroupModal}
        onClose={handleCloseDeleteGroupModal}
        onDelete={handleClickDeleteGroup(currentProjectId)}
        title="Удаление группы"
        bodyText="данную группу"
        maxWidth="xs"
        error={error}
        isLoading={isDeleting}
      />

      <CustomDialog
        isOpen={isShowEditGroupModal}
        onClose={handleCloseEditGroupModal}
        title="Редактирование группы"
        maxWidth="xs"
      >
        <GroupInput
          handleCloseForm={handleCloseEditGroupModal}
          modalPurpose="change"
          groupId={currentProjectId}
        />
      </CustomDialog>

      <CustomDialog
        isOpen={isShowEditModal}
        onClose={handleCloseChangeModal}
        title={EDIT_TITLE.change}
        maxWidth="xs"
      >
        <NewProjectForm
          handleCloseForm={handleCloseChangeModal}
          projectId={currentProject?.id}
          projectName={currentProject?.name}
          projectGroupId={currentProject?.projectGroupId}
          projectIsPersonal={currentProject?.personal}
          projectIsFavourite={currentProject?.favourite}
        />
      </CustomDialog>

      <CustomDialog
        isOpen={isShowCopyModal}
        onClose={handleCloseCopyModal}
        title={EDIT_TITLE.copy}
        maxWidth="xs"
      >
        <NewProjectForm
          handleCloseForm={handleCloseCopyModal}
          projectId={currentProject?.id}
          projectName={currentProject?.name}
          projectGroupId={currentProject?.projectGroupId}
          projectIsPersonal={currentProject?.personal}
          isCopy
        />
      </CustomDialog>

      <CustomDialog
        isOpen={isShowSaveVersionModal}
        onClose={handleCloseSaveVersionModal}
        title={EDIT_TITLE.save}
        maxWidth="xs"
      >
        <SaveVersionForm
          handleCloseForm={handleCloseSaveVersionModal}
          projectName={currentProject?.name}
          projectId={currentProject?.id || 0}
        />
      </CustomDialog>

      <CustomDialog
        isOpen={isShowRollbackVersionModal}
        onClose={handleCloseRollbackVersionModal}
        title={EDIT_TITLE.rollback}
        maxWidth="lg"
      >
        <RollbackVersionForm
          handleCloseForm={handleCloseRollbackVersionModal}
          projectName={currentProject?.name}
          projectId={currentProject?.id || 0}
        />
      </CustomDialog>

      <CustomDialog
        isOpen={showNewProjectModal}
        onClose={handleCloseNewProjectModal}
        title={CommonDictionary.newProjectCreate}
        maxWidth="xs"
      >
        <NewProjectForm
          handleCloseForm={handleCloseNewProjectModal}
          projectGroupId={currentProjectId}
        />
      </CustomDialog>

      <CustomDialog
        isOpen={isShowSubscribeModal}
        onClose={handleCloseSubscribeModal}
        title="Настройка уведомлений"
        maxWidth="xs"
      >
        <SubscribeForm
          handleCloseForm={handleCloseSubscribeModal}
          projectId={currentProject?.id || 0}
        />
      </CustomDialog>

      <CustomDialog
        isOpen={isShowMailingSubscribeModal}
        onClose={handleCloseSubscribeMailingModal}
        title="Рассылка отчетов"
        maxWidth="xs"
      >
        <SubscribeMailingForm
          handleCloseForm={handleCloseSubscribeMailingModal}
          projectId={currentProject?.id || 0}
        />
      </CustomDialog>

      <ExportLocalFilesModal
        isOpen={isShowCheckFilesModal}
        handleCloseForm={handleCloseCheckFilesModal}
        projectId={currentProject?.id}
        description={currentProject?.name}
      />
    </>
  );
};
