import { getScaleCoefficient } from '../../../../helpers/ui-helper';


export const axisProperties = {
  height: 60 * getScaleCoefficient(),
  daysLabelHeight: 30 * getScaleCoefficient(),
  monthLabelHeight: 30 * getScaleCoefficient(),
  daysLabelWidth: 25 * getScaleCoefficient(),
};

export const barProperties = {
  height: 30 * getScaleCoefficient(),
  paddingVertical: 15 * getScaleCoefficient(),
};

export const barTextProperties = {
  paddingHorizontal: 10 * getScaleCoefficient(),
  styles: {
    outsideColor: 'var(--black)',
    insideColor: 'var(--white)',
    fontSize: 14 * getScaleCoefficient(),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    wordBreak: 'break-all',
    pointerEvents: 'none'
  },
};

export const canvasProperties = {
  getWidth: (daysCount: number) => daysCount * axisProperties.daysLabelWidth,
  getHeight: (data: any) =>
    data.length * (barProperties.height + barProperties.paddingVertical * 2) +
    axisProperties.height,
};
