import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDropdownLayout } from './useDropdownLayout';

type DropdownLayoutProps = {
  children: ({
    isReloading,
    setIsReady,
  }: {
    isReloading: boolean;
    setIsReady: (value: boolean) => void;
  }) => React.ReactNode;
};

export const DropdownLayout = ({ children }: DropdownLayoutProps) => {
  const {
    onDragStart,
    onDragEnd,
    isLoading,
    setIsReady,
  } = useDropdownLayout();

  return (
    <div className="dropdown-layout">
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {children({ isReloading: isLoading, setIsReady })}
      </DragDropContext>
    </div>
  );
};
