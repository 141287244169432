import { batch, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { partition, uniqBy } from 'lodash';
import { getRecursiveWidgetIdDDChain } from '../../../helpers/dashboard-page/dashboard-widgets';
import { useGetDashboardProperty } from '../../../hooks/get-properties/useGetDashboardProperty';
import { BoundType } from '../settings/enums';
import { DashboardProperty, State, Widget } from '../../../slices/types';
import {
  setDashboardPropAction,
} from '../../../slices/dashboard/dashboard';
import {
  getDrillDownByMasterWidget,
  getManualByMasterWidget,
} from '../../../services/dashboardController';
import {
  BoundFilterGroupProps, FilterGroupProps,
  ParticipantFilterWidget,
  SetFilterField,
  SetMultipleFilterFields,
} from './index';
import {
  getPropertyValue,
  getValuesForWidgetMapManualBound,
} from '../../../helpers/dashboard-page';
import { DashboardPropertyType } from '../../../enums/dashboard-properties';


export const useDashboardManualFilters = (boundType: BoundType, log?: boolean) => {

  const dispatch = useDispatch();
  const properties: DashboardProperty[] = useSelector((state: State) => state.dashboard.properties);
  const widgetsIds: number[] = useSelector((state: State) => state.dashboard.widgetIds);
  const dashboardId: number | undefined = useSelector((state: State) => state.dashboard.id);

  const values = useGetDashboardProperty(
    // TODO, нужно объединить enum BoundType и DashboardPropertyType, чтобы избавиться от unknown
    boundType as unknown as DashboardPropertyType,
    properties,
    []
  ) as BoundFilterGroupProps[];

  const getGroupIndexWhereWidgetIsMaster = (widgetId: any) => {
    const groups = values;

    let findIndex = 0;

    for (const group of groups) {
      if (group.participantFilterWidgets.find((participant) => participant.widgetId === widgetId)) {
        for (const participant of group.participantFilterWidgets) {
          if (+participant.widgetId === +widgetId && !!participant.isMaster && !!participant.isActive) {
            return findIndex;
          }
        }
      }
      findIndex++;
    }

    return null;
  };

  const setFilterField = useCallback<SetFilterField>((widgetId, selectedFieldId, data, mapData = null, callback) => {
    if (!values.length) return;

    const groupIndex = getGroupIndexWhereWidgetIsMaster(widgetId);

    if (groupIndex === null) return;

    if (boundType === BoundType.manualDrillDownGroups && mapData) {
      const {
        selectedMapFieldId,
        mapValues,
      } = getValuesForWidgetMapManualBound(
        widgetId,
        values[groupIndex].participantFilterWidgets,
        mapData,
      );

      // если mapValues пусто то значит нажали на другой слой карты, DD не открываем
      if (mapValues.length === 0) return;

      const filters = [
        {
          operation: data[0]?.operation,
          value: mapValues,
        },
      ];

      values[groupIndex].filters = filters;
      values[groupIndex].selectedSourceFieldId = selectedMapFieldId;
    } else {
      values[groupIndex].filters = data;
      values[groupIndex].selectedSourceFieldId = selectedFieldId;
    }

    callback && callback();
    dispatch(
      setDashboardPropAction({
        name: boundType,
        value: JSON.stringify(values),
      }),
    );
  }, [JSON.stringify(values)]);

  const setMultipleFilterFields = useCallback<SetMultipleFilterFields>((
    widgetId,
    selectedFieldsIds,
    data,
    callback
  ) => {
    if (!values.length) return;

    const groupIndex = getGroupIndexWhereWidgetIsMaster(widgetId);

    if (groupIndex === null) return;

    if (boundType !== BoundType.autoDrillDownGroups) {
      setFilterField(widgetId, selectedFieldsIds ? selectedFieldsIds[0] : null, data, null, callback);
      return;
    }

    const currentGroup = { ...values[groupIndex] };

    const newValues = values.filter((value, index) => index !== groupIndex);

    if (!selectedFieldsIds || !selectedFieldsIds.length) return;

    selectedFieldsIds.forEach((id, index) => {
      const copyCurrentGroupWithDifferentValues = {
        ...currentGroup,
        selectedSourceFieldId: id,
        filters: [data[index]]
      };
      newValues.push(copyCurrentGroupWithDifferentValues);
    });


    callback && callback();
    dispatch(
      setDashboardPropAction({
        name: boundType,
        value: JSON.stringify(newValues),
      }),
    );
  }, [JSON.stringify(values)]);

  const addEmptyGroup = useCallback(() => {
    const manualBoundGroupsValues = getPropertyValue<BoundFilterGroupProps[]>(
      BoundType.manualBoundGroups,
      properties,
    );
    const prevValues = boundType === BoundType.manualBoundGroups ? manualBoundGroupsValues : values;
    const expandedValues: BoundFilterGroupProps[] = [...prevValues, {
      id: null,
      name: '',
      isActive: false,
      participantFilterWidgets: [],
    }];

    dispatch(
      setDashboardPropAction({
        name: boundType,
        value: JSON.stringify(expandedValues),
      }),
    );
  }, [values, properties]);

  const deleteWidgetFromDashboardFilter = useCallback((connectedWidget: any, dashboardFiltersValues: FilterGroupProps[]) => {

    const dashboardFilters = [...dashboardFiltersValues];

    const widgetOnDashboard = widgetsIds.includes(connectedWidget.widgetId);

    const groupsWithWidget = values.filter((value) => {
      return value.participantFilterWidgets.find(widget => !widget.isMaster && widget.isActive && widget.widgetId === connectedWidget.widgetId);
    });

    const widgetUsedInOtherConnection = groupsWithWidget.length > 1;

    if (!widgetOnDashboard && !widgetUsedInOtherConnection) {
      for (let i = 0; i < dashboardFilters.length; i++) {
        for (let j = 0; j < dashboardFilters[i].widgetFilters.length; j++) {
          if (connectedWidget.widgetId === dashboardFilters[i].widgetFilters[j].widgetId) {
            dashboardFilters[i].widgetFilters[j] = { ...dashboardFilters[i].widgetFilters[j], isArchived: true };
          }
        }
      }

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(dashboardFilters),
        }),
      );
    }
  }, [dispatch, values, widgetsIds]);

  const deleteGroup = useCallback(
    (index: number) => {
      const deletedValue = values[index];

      const widgetId =
        deletedValue.participantFilterWidgets.find(
          (widget) => widget.isMaster && widget.isActive,
        )?.widgetId;

      if (widgetId) {

        const widgetIdListForDelete = [
          widgetId,
          ...getRecursiveWidgetIdDDChain(values, [], widgetId, true),
        ];

        const connectedWidget = deletedValue.participantFilterWidgets.find(
          (widget) => !widget.isMaster && widget.isActive,
        );

        if (connectedWidget) {
          const dashboardFilters: DashboardProperty | undefined = properties.find(
            (item) => item.name === DashboardPropertyType.filters,
          );
          const dashboardFiltersValues = JSON.parse(
            (dashboardFilters?.value as string) || '[]',
          );

          deleteWidgetFromDashboardFilter(
            connectedWidget,
            dashboardFiltersValues,
          );
        }

        const clearedValues = values.filter(
          (group) =>
            !group.participantFilterWidgets.find(
              (widget) =>
                widget.isMaster &&
                widget.isActive &&
                widgetIdListForDelete.includes(widget.widgetId),
            ),
        );

        dispatch(
          setDashboardPropAction({
            name: boundType,
            value: JSON.stringify(clearedValues),
          }),
        );
      } else {
        const clearedValues = values.filter((_, i) => i !== index);

        dispatch(
          setDashboardPropAction({
            name: boundType,
            value: JSON.stringify(clearedValues),
          }),
        );
      }
    },
    [values, dispatch, boundType, properties, deleteWidgetFromDashboardFilter],
  );


  const changeMasterWidget = useCallback(
    (
      groupIndex: number,
      id: number, // в случае связанных фильтров id это selectedFieldId, в случае DD это widgetId
      layout?: number,
    ) => {
      if (!dashboardId) return;

      if (boundType === BoundType.autoDrillDownGroups) {
        const copy = [...values];

        getDrillDownByMasterWidget(dashboardId, id, layout).then((group) => {
          copy[groupIndex] = group;

          dispatch(
            setDashboardPropAction({
              name: DashboardPropertyType.autoDrillDownGroups,
              value: JSON.stringify(copy),
            }),
          );
        });
      } else {
        const manualBoundGroups = getPropertyValue<BoundFilterGroupProps[]>(
          boundType,
          properties,
        );

        getManualByMasterWidget(dashboardId, id, boundType).then((group) => {
          manualBoundGroups[groupIndex] = group;

          batch(
            () => {
              dispatch(
                setDashboardPropAction({
                  name: boundType,
                  value: JSON.stringify(manualBoundGroups),
                }),
              );
            }
          );
        });
      }
    },
    [boundType, values, dashboardId, properties],
  );

  // удаляем битые связи
  const clearNotRelevantBounds = (bounds: BoundFilterGroupProps[]) => {

    const connectedWidgets =
      bounds
        .map((bound) =>
          bound.participantFilterWidgets.find(
            (item) => item.isActive && !item.isMaster
          )?.widgetId || null
        );

    return bounds
      .filter((bound) =>
        [...connectedWidgets, ...widgetsIds].includes(
          bound.participantFilterWidgets.find(
            (item) => item.isMaster
          )?.widgetId || 0)
      );
  };

  const changeConnectedWidget = useCallback(
    async (groupIndex: number, widgetId: number | string) => {
      if (!dashboardId) return;
      let copy = [...values];
      const currentValue = copy[groupIndex];
      const connectedWidget = currentValue.participantFilterWidgets.find(widget => !widget.isMaster && widget.isActive);
      const dashboardFilters: DashboardProperty | undefined = properties.find(item => item.name === DashboardPropertyType.filters);
      const dashboardFiltersValues = JSON.parse(dashboardFilters?.value as string || '[]');

      if (connectedWidget && parseInt(String(widgetId)) !== connectedWidget.widgetId) {
        deleteWidgetFromDashboardFilter(connectedWidget, dashboardFiltersValues);
      }

      copy[groupIndex].participantFilterWidgets = copy[
        groupIndex
        ].participantFilterWidgets.map((participant: any) => {
        return{
          ...participant,
          isActive: (boundType === BoundType.autoDrillDownGroups
              ? Number(widgetId) === Number(participant.widgetId)
              : boundType === BoundType.manualDrillDownGroups
                ? widgetId === String(`${participant.widgetId}-${participant.name}`)
                : widgetId === String(`${participant.widgetId}-${participant.name}`)
                  ? !participant.isActive
                  : participant.isActive) ||
            participant.isMaster,
        };
      });

      // чтобы строить цепочки DD среди виджетов, которых нет на дашборде,
      // нужно их отдельно загрузить в стейт
      const idActiveWidget = copy[groupIndex].participantFilterWidgets
        .find((item) => item.isActive && !item.isMaster)?.widgetId || null;
      if (idActiveWidget && !widgetsIds.includes(idActiveWidget)) {

        // так же проверяем что если ConnectedWidget был мастером для связи,
        // и виджета нет на дашборде, то связь нужно удалить
        copy = clearNotRelevantBounds(copy);
      }

      dispatch(
        setDashboardPropAction({
          name: boundType,
          value: JSON.stringify(copy),
        }),
      );
    },
    [values, dashboardId, properties],
  );

  const getGroupsForWidget = useCallback((widget: Widget) => {
    const groups = values.filter((item) =>
      item.participantFilterWidgets.find((participant) => participant.widgetId === widget.id));
    return groups;
  }, [values]);

  const getMasterParticipantForWidget = (widget: Widget) => {
    const masterGroup = getGroupWhereWidgetIsMaster(widget);
    if (!masterGroup) return null;

    for (const participant of masterGroup?.participantFilterWidgets) {
      if (participant.isActive && participant.isMaster) {
        return participant;
      }
    }

    return null;
  };

  const getConnectedParticipantForWidget = (widget: Widget) => {
    const isMaster = !!getMasterParticipantForWidget(widget)?.isMaster;
    const masterGroup = getGroupWhereWidgetIsMaster(widget);

    if (!isMaster) return null;
    if (!masterGroup) return null;

    for (const participant of masterGroup?.participantFilterWidgets) {
      if (participant.isActive && !participant.isMaster) {
        return participant;
      }
    }

    return null;
  };

  const getGroupWhereWidgetIsConnected = (widget: Widget) => {
    const groups = getGroupsForWidget(widget);

    for (const group of groups) {
      for (const participant of group.participantFilterWidgets) {
        if (participant.isActive && !participant.isMaster && participant.widgetId === widget.id) {
          return group;
        }
      }
    }

    return null;
  };

  const getGroupWhereWidgetIsMaster = (widget: Widget) => {
    const groups = getGroupsForWidget(widget);

    for (const group of groups) {
      for (const participant of group.participantFilterWidgets) {
        if (participant.isActive && participant.isMaster && participant.widgetId === widget.id) {
          return group;
        }
      }
    }

    return null;
  };

  const getAllConnectedWidget = useCallback(() => {
    const result: ParticipantFilterWidget[] = [];
    const groups = values;

    for (const group of groups) {
      for (const participant of group.participantFilterWidgets) {
        if (participant.isActive && !participant.isMaster) {
          result.push(participant);
        }
      }
    }

    return result;
  }, [values]);

  const getAllMasterWidget = () => {
    const result: ParticipantFilterWidget[] = [];
    const groups = values;

    for (const group of groups) {
      for (const participant of group.participantFilterWidgets) {
        if (participant.isActive && participant.isMaster) {
          result.push(participant);
        }
      }
    }

    return result;
  };

  const isMasterWidget = (widgetId: number) => {
    const masterWidgets = getAllMasterWidget();

    return Boolean(masterWidgets.find((widget) => widget.widgetId === widgetId));
  };

  const clearDrillDownGroups = () => {
    batch(() => {
      dispatch(
        setDashboardPropAction({
          name: BoundType.autoDrillDownGroups,
          value: '[]',
        }),
      );
      dispatch(
        setDashboardPropAction({
          name: BoundType.manualDrillDownGroups,
          value: '[]',
        }),
      );
    });
  };

  const resetDrillDownValues = (groups: BoundFilterGroupProps[]) => {
    const newValues = [...groups];
    groups.forEach((item, index) => {
      newValues[index].filters = [];
      newValues[index].selectedSourceFieldId = null;
    });

    dispatch(
      setDashboardPropAction({
        name: boundType,
        value: JSON.stringify(newValues),
      }),
    );
  };

  const resetAutoMultipleDDFilters = (masterWidgetId?: number) => {

    const getUniqKeyGroupDD = (group: BoundFilterGroupProps) => {
      const masterWidgetId = group.participantFilterWidgets.find(
        (widget) => widget.isMaster && widget.isActive,
      )?.widgetId;
      const connectedWidgetId = group.participantFilterWidgets.find(
        (widget) => !widget.isMaster && widget.isActive,
      )?.widgetId;
      return group.id || `${masterWidgetId}-${connectedWidgetId}`;
    };

    if (masterWidgetId) {
      const checkMasterGroups = (group: BoundFilterGroupProps) =>
        group.participantFilterWidgets.find(
          (filterWidget) =>
            filterWidget.isMaster &&
            filterWidget.isActive &&
            filterWidget.widgetId === masterWidgetId,
        );
      const [groupForClearing, otherGroups] = partition(
        values,
        checkMasterGroups,
      );

      groupForClearing.forEach((item, index) => {
        groupForClearing[index].filters = [];
        groupForClearing[index].selectedSourceFieldId = null;
      });

      const newValues = [...otherGroups, ...uniqBy(groupForClearing, getUniqKeyGroupDD)];

      dispatch(
        setDashboardPropAction({
          name: boundType,
          value: JSON.stringify(newValues),
        }),
      );
    } else {
      const uniqGroups = uniqBy(values, getUniqKeyGroupDD);
      resetDrillDownValues(uniqGroups);
    }
  };

  return {
    drilldownValues: values,
    widgetsIds,
    dashboardId,
    setFilterField,
    changeConnectedWidget,
    changeMasterWidget,
    getGroupWhereWidgetIsConnected,
    getGroupWhereWidgetIsMaster,
    getMasterParticipantForWidget,
    getConnectedParticipantForWidget,
    getAllConnectedWidget,
    getAllMasterWidget,
    addEmptyGroup,
    deleteGroup,
    clearDrillDownGroups,
    resetDrillDownValues,
    isMasterWidget,
    setMultipleFilterFields,
    resetAutoMultipleDDFilters,
  };
};
