import React, { useMemo, useState } from 'react';
import { CssVariables } from '../../../../enums/css-variables';
import { varcss } from '../../../../helpers/ui-helper';
import { useDashboardFiltersInHead } from '../../hooks/useDashboardFiltersInHead';
import './styles.css';
import { getDatePeriod } from '../helper';
import { DashboardFilter } from '../dashboard-filter';
import {
  getFilterGroupDisplayedName,
  getFilterSettingValue,
  isPeriodDateFilter,
} from '../../../../helpers/dashboard-page/filters-helper';
import { FilterProperty } from '../../../../enums/dashboard-properties';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { DateFunctions } from '../../../../enums/date';

export const HeadFilters = () => {
  const { headerDateFilter, changeHeaderDateFilterOperation } =
    useDashboardFiltersInHead();

  const [anchorEl, setAnchorEl] = useState(null);

  const isOpenFilterEdit = Boolean(anchorEl);

  const handleFilterEditOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterEditClose = () => {
    setAnchorEl(null);
  };

  const dateFunction = useMemo(
    () =>
      headerDateFilter
        ? getFilterSettingValue(
            headerDateFilter,
            FilterProperty.overriddenFunction,
          ) || getFilterSettingValue(headerDateFilter, FilterProperty.function)
        : null,
    [headerDateFilter],
  );

  const datePeriod = useMemo(() => {
    return (
      headerDateFilter &&
      getDatePeriod(
        headerDateFilter.value,
        dateFunction?.split("'")[1] || DateFunctions.NO_TYPE,
        isPeriodDateFilter(headerDateFilter),
      )
    );
  }, [dateFunction, headerDateFilter]);

  return (
    <>
      {headerDateFilter && (
        <>
          <div className="dashboard-head__date-filters-wrapper">
            <div className="dashboard-head__date-filter">
              <div
                className="dashboard-head__date-filter-value"
                style={isOpenFilterEdit ? { backgroundColor: '#DAE7ED' } : {}}
                onClick={handleFilterEditOpen}
              >
                {datePeriod || getFilterGroupDisplayedName(headerDateFilter)}
              </div>
            </div>
          </div>
          <CustomPopover
            open={isOpenFilterEdit}
            anchorEl={anchorEl}
            onClose={handleFilterEditClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
          >
            <div className="dashboard-head__filter-container">
              <DashboardFilter
                property={headerDateFilter}
                handleChangeFilter={changeHeaderDateFilterOperation}
                closeFilterPanel={handleFilterEditClose}
              />
            </div>
          </CustomPopover>
        </>
      )}
    </>
  );
};
