import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Dictionaries from '../../../../types/dictionaries';
import { FileTypesEnum } from '../../../../enums/fyle-type';
import { SelectInput } from '../../../common/special-inputs/inputs/select-input';
import Notification from '../../../common/notification/notification';

interface Props {
  fileTypeId: number;
  setFileTypeId: Dispatch<SetStateAction<number>>;
  setFileTypeName: Dispatch<SetStateAction<FileTypesEnum | undefined>>;
  selectError?: boolean;
  fileTypes: Dictionaries.FileTypes[];
}

const FileTypeSelect: React.FC<Props> = ({
  fileTypeId = 0,
  setFileTypeId,
  setFileTypeName,
  selectError = false,
  fileTypes,
}) => {
  const [typeValue, setTypeValue] = useState(fileTypeId);

  useEffect(() => {
    setTypeValue(fileTypeId);
  }, [fileTypeId]);

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeValue(event.target.value as number);
    setFileTypeId(event.target.value as number);
    setFileTypeName(fileTypes.find((type) => type.id === event.target.value)?.type);
  };

  return (
    <div>
      { selectError &&
      <div className="ftp-notification-container">
        <Notification
          title="Внимание!"
          content="Не указан тип нового файла. Необходимо выбрать тип файла из выпадающего списка!"
          isWarning={true}
        />
      </div>}
      <SelectInput
        formikName="loaderGroupId"
        label="Тип файлов"
        value={typeValue}
        fullWidth
        handleChange={handleTypeChange}
        itemList={
          fileTypes
            ? fileTypes.map((type) => {
              return { value: type.id, showValue: type.description };
            })
            : [{ value: 0, showValue: 'Загрузка' }]
        }
      />
    </div>
  );
};

export default FileTypeSelect;
