import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../slices/types';
import { getRootElement } from '../../../../utils/root-helper';

export const useGridLayoutResizer = () => {
  const gridLayoutContainer = useRef(document.createElement('div')); // to prevent TS "Object is possibly null" error

  const [gridContainerWidth, setGridContainerWidth] = useState<number>(
    gridLayoutContainer.current?.clientWidth,
  ) || 0;

  useEffect(() => {
    const resizeGridContainer = () => {
      setGridContainerWidth(gridLayoutContainer?.current?.clientWidth);
    };

    resizeGridContainer();

    getRootElement().addEventListener('resize', resizeGridContainer);

    return () => {
      getRootElement().removeEventListener('resize', resizeGridContainer);
    };
  }, []);

  const currentWidgetIdFullScreen = useSelector(
    (state: State) => state.dashboard.currentWidgetIdFullScreen,
  );

  return {
    gridLayoutContainer,
    gridContainerWidth,
    currentWidgetIdFullScreen,
  };
};
