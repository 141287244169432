import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeasureProps, State } from '../../../../slices/types';
import {
  getParsedAxisValues,
  isCalculatedMeasure,
  parseComplexRestriction,
} from '../../../widget-page/dropdown-layout/helpers/helpers';
import { WidgetPropertyType } from '../../../../enums/widget-type';
import { setWidgetPropAction } from '../../../../slices/widget/widget';
import { setWidgetPageSaveDisabledAction } from '../../../../slices/main-page/main-page-slice';
import { useDebouncedInput } from '../../../../hooks/useDebouncedInput';

export interface FormulasObject {
  template: string;
  operandIds: number[];
  valueWithoutOperands: string;
}

export const useFormulasInputContainer = (propertyName: WidgetPropertyType, onlyComplex: boolean) => {
  const dispatch = useDispatch();

  const sources = (
    useSelector(
      (state: State) => state.mainPage?.currentProjectWidgetSourceFieldsData,
    ) || []
  ).filter(
    (source) =>
      isCalculatedMeasure(source) &&
      (!onlyComplex || (source as MeasureProps).complex) &&
      !(source as MeasureProps).isLagOrLead,
  );
  const widgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const property: FormulasObject = getParsedAxisValues(
    propertyName,
    widgetProperties,
  );

  const handleChangeValue = useCallback(
    (newValue: string) => {
      const newPropertyValue = {
        ...parseComplexRestriction(sources, newValue),
        valueWithoutOperands: newValue,
      };
      dispatch(
        setWidgetPropAction({
          name: propertyName,
          value: JSON.stringify(newPropertyValue),
        }),
      );
      dispatch(setWidgetPageSaveDisabledAction(false));
    },
    [dispatch, propertyName, sources],
  );

  const handleStartChange = useCallback(
    () => {
      dispatch(setWidgetPageSaveDisabledAction(true));
    },
    [dispatch, propertyName, sources],
  );

  const sourcesList = sources.map((value, index: number) => ({
    key: index,
    value: value.name,
    aggregation: null,
    id: value.etlFieldId || '',
  }));

  const [value, handleChangeDebouncedValue] = useDebouncedInput(
    property.valueWithoutOperands || '',
    handleChangeValue,
    100
  );

  const regExes = [
    {
      regEx: /\][^\s]/,
      replaceValue: (substring: string) => `${substring[0]} ${substring[1]}`
    },
    {
      regEx: /[^\s]\[/,
      replaceValue: (substring: string) => `${substring[0]} ${substring[1]}`
    }
  ];

  return {
    value,
    handleChangeDebouncedValue,
    handleStartChange,
    sourcesList,
    regExes,
  };
};
