import { useMemo } from 'react';
import { WidgetProperties } from '../../../../../slices/types';
import {
  getAxisValuesForWidget,
  getBooleanPropertyValue,
  getJSONPropertyValue,
  getSelectPropertyValue,
} from '../../helpers';
import { PanelType, WidgetPropertyType } from '../../../../../enums/widget-type';
import {
  getAxisValues,
  getParsedAxisValues,
} from '../../../dropdown-layout/helpers/helpers';
import { LegendPositions } from '../../../../../enums/legend';
import { AdditionalMapLegendPosition } from '../settings';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { ReversGradient } from '../../../dropdown-panel/modals/heat-map-modal/useHeatMapModal';

interface UseMapPropertiesProps {
  widgetProperties: WidgetProperties[];
}

export const useMapProperties = ({
  widgetProperties,
}: UseMapPropertiesProps) => {
  const mapLayerClusterization = useMemo(
    () => getBooleanPropertyValue('mapLayerClusterization', widgetProperties),
    [widgetProperties],
  );

  const axisXValueNames: string[] = useMemo(
    () => getAxisValuesForWidget(PanelType.axisX, widgetProperties, true),
    [widgetProperties],
  );

  const dateDisplayFormat = useMemo(
    () => getAxisValues(WidgetPropertyType.dateDisplayFormat, widgetProperties),
    [widgetProperties],
  );

  const axisXValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisX, widgetProperties),
    [widgetProperties],
  );
  const axisYValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisY, widgetProperties),
    [widgetProperties],
  );
  const axisZValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisZ, widgetProperties),
    [widgetProperties],
  );

  const reverseGradientLayers: ReversGradient[] = useMemo(
    () => getParsedAxisValues(WidgetPropertyType.reverseGradient, widgetProperties),
    [widgetProperties],
  );

  const gradientColoringLayers: { color: string, etlSourceId: string }[] = useMemo(
    () => getParsedAxisValues(WidgetPropertyType.gradientColoring, widgetProperties),
    [widgetProperties],
  );

  const isNeedToDisplayLegend: boolean = useMemo(
    () => getBooleanPropertyValue('displayLegend', widgetProperties),
    [widgetProperties],
  );

  const colorsPaletteState = useMemo(
    () => getJSONPropertyValue('colorPalette', widgetProperties),
    [widgetProperties],
  );

  const legendPosition: LegendPositions | AdditionalMapLegendPosition = useMemo(
    () =>
      getSelectPropertyValue('legendPositionMap', widgetProperties) as LegendPositions | AdditionalMapLegendPosition ||
      LegendPositions.Up,
    [widgetProperties],
  );

  const axisFilter: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisFilter, widgetProperties),
    [widgetProperties],
  );

  return {
    mapLayerClusterization,
    axisXValueNames,
    dateDisplayFormat,
    axisXValues,
    axisYValues,
    axisZValues,
    reverseGradientLayers,
    gradientColoringLayers,
    isNeedToDisplayLegend,
    colorsPaletteState,
    legendPosition,
    axisFilter,
  };
};
