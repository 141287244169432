import { useFormik } from 'formik';
import { LinkSettingModalProps } from './index';

interface LinkValues {
  link: string;
}

export const useLinkModal = ({
  panelData,
  closeDialog,
  changeLink,
}: LinkSettingModalProps) => {
  const handleSave = (values: LinkValues) => {
    changeLink(values.link);
    closeDialog();
  };

  const handleClose = () => {
    closeDialog();
  };

  const initialValues = {
    link: panelData.link || '',
  };

  const { values, handleChange, handleSubmit } =
    useFormik<LinkValues>({
      initialValues,
      onSubmit: handleSave,
    });

  return {
    handleSubmit,
    handleChange,
    values,
    handleClose,
  };
};
