import React, { CSSProperties, useEffect, useMemo } from 'react';
import { useGetColorPaletteWidgetProperty } from '../../../../hooks/get-properties/useGetWidgetProperty';
import { Legend } from './legend';
import {
  getEnumPropertyValue,
  isDashboardPage,
} from '../helpers';
import { PanelType, WidgetType } from '../../../../enums/widget-type';
import { useWidgetSorting } from '../../../../hooks/useWidgetSorting';
import { LegendPositions } from '../../../../enums/legend';
import { WidgetProperties } from '../../../../slices/types';
import { mergeWidgetProperties } from '../../../../utils/functions';
import { SetOverriddenProperties } from '../../../../helpers/dashboard-page/widget';


export const WIDGET_TYPE_WITH_LEGEND = [
  WidgetType.HISTOGRAM,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.STACKED_HISTOGRAM,
  WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
  WidgetType.GRAPH,
  WidgetType.GRAPH_AREA,
  WidgetType.RADAR_CHART,
  WidgetType.HISTOGRAM_GRAPH,
  WidgetType.BUBBLE_CHART,
];

const WIDGET_TYPE_WITH_SORTING_LEGEND = [
  WidgetType.HISTOGRAM,
  WidgetType.HORIZONTAL_HISTOGRAM,
  WidgetType.STACKED_HISTOGRAM,
  WidgetType.STACKED_HORIZONTAL_HISTOGRAM,
];

export type LegendContainerProps = {
  widgetProps: any;
  isDisabledActivity: boolean;
  dashboardProps?: any;
  overriddenProperties?: WidgetProperties[];
  setOverriddenProperties?: SetOverriddenProperties;
};

const CSSHorizontal: CSSProperties = {
  flexDirection: 'column',
  padding: '8px 16px'
};

const CSSVertical: CSSProperties = {
  flexDirection: 'row',
  padding: '4px 0'
};

export const CSSForLegendPosition: Record<LegendPositions, CSSProperties> = {
  [LegendPositions.Left]: CSSHorizontal,
  [LegendPositions.Right]: CSSHorizontal,
  [LegendPositions.Down]: CSSVertical,
  [LegendPositions.Up]: CSSVertical,
};

export const LegendContainer = ({
  widgetProps,
  isDisabledActivity,
  dashboardProps,
  overriddenProperties,
  setOverriddenProperties,
}: LegendContainerProps) => {

  const currentColors = useGetColorPaletteWidgetProperty(widgetProps.properties);

  useEffect(() => {
    setSortedDataLoading(false);
  }, [widgetProps.data]);

  const legendPosition = useMemo(
    () =>
      getEnumPropertyValue<LegendPositions>(
        'legendPosition',
        widgetProps.properties,
      ),
    [widgetProps.properties],
  );

  const legendsContainerStyles = useMemo(
    () => CSSForLegendPosition[legendPosition],
    [legendPosition],
  );

  const {
    isSortedDataLoading,
    setSortedDataLoading,
    currentOrderIndexColumn,
    getOrderIconByIndex,
    changeOrderingHandler,
  } = useWidgetSorting({
    dashboardProps,
    widgetProperties: mergeWidgetProperties(
      widgetProps.properties,
      overriddenProperties,
    ),
    axisName: PanelType.axisY,
    fromDashboard: isDashboardPage(),
    overriddenProperties,
    setOverriddenProperties,
  });

  const legendSortingProps = {
    isSortedDataLoading,
    currentOrderIndexColumn,
    getOrderIconByIndex,
    changeOrderingHandler,
  };

  if (!WIDGET_TYPE_WITH_LEGEND.includes(widgetProps.type)) return null;

  return (
    <Legend
      currentColors={currentColors}
      legendSortingProps={
        !isDisabledActivity && WIDGET_TYPE_WITH_SORTING_LEGEND.includes(widgetProps.type)
          ? legendSortingProps
          : undefined
      }
      legendsContainerStyles={legendsContainerStyles}
      widgetProperties={widgetProps.properties}
      widgetType={widgetProps.type}
      widgetId={widgetProps.id}
      isDisabledActivity={isDisabledActivity}
    />
  );
};
