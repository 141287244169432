import React from 'react';
import { ColorPicker } from '../../../../settings/color-picker';
import { CustomFormControl } from '../../../../../../uikit/FormControl';
import { CustomCheckbox } from '../../../../../../uikit/Checkbox';

export interface GradientSetting {
  type: string;
  value?: boolean | string;
  onChange?: any;
  label?: string;
  isVisible?: boolean;
}

export interface MapGradient {
  startColor: string;
  middleColor?: string;
  endColor: string;
}

interface GradientSettingsProps {
  settings: GradientSetting[];
  gradient: MapGradient;
}

export const GradientSettings = ({
  settings,
  gradient,
}: GradientSettingsProps) => {
  return (
    <div className="heat-map-settings__block">
      {settings.map((setting) => {
        if (!setting.isVisible) return <></>;
        switch (setting.type) {
          case 'checkbox':
            return (
              <CustomFormControl
                control={
                  <CustomCheckbox
                    checked={setting.value as boolean}
                    onChange={setting.onChange}
                    withoutTopBottomPadding={true}
                  />
                }
                label={setting.label}
              />
            );
          case 'line':
            return (
              <div className="heat-map-settings__block heat-map-settings__block_inline heat-map-settings__block_bottom heat-map-settings__block_border">
                <div className="heat-map-settings__gradient-text">min</div>
                <div
                  className="heat-map-settings__gradient"
                  style={{
                    background: gradient.middleColor
                      ? `linear-gradient(to right,
                        ${gradient?.startColor},
                        ${gradient?.middleColor},
                        ${gradient?.endColor})`
                      : `linear-gradient(to right, ${gradient?.startColor},
                        ${gradient?.endColor})`,
                  }}
                />
                <div className="heat-map-settings__gradient-text">max</div>
              </div>
            );
          case 'color':
            return (
              <div className="heat-map-settings__block heat-map-settings__block_bottom">
                <ColorPicker
                  name={setting.label as string}
                  value={setting.value as string}
                  onChangeColor={setting.onChange}
                />
              </div>
            );
          default:
            return <></>;
        }
      })}
    </div>
  );
};
