import React, { useState } from 'react';
import { useRoles } from '../../../../hooks/useRoles';
import { Roles } from '../../../../enums/roles';
import { GroupTitlePanelButtonsProps } from './group-title-panel-buttons';

export const useGroupTitlePanelButtons = ({
  permission,
  projectsCount,
  handleClickCreate,
}: GroupTitlePanelButtonsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const openMenu = Boolean(anchorEl);

  const rolesAccess = useRoles({
    isShowCreateProjectButton:
      permission === Roles.Admin || permission === Roles.Developer,
  });

  const handleCreateLoaderClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClickCreate();
    event.stopPropagation();
  };

  return {
    rolesAccess,
    handleCreateLoaderClick,
    anchorEl,
    handleMenuClick,
    openMenu,
    handleCloseMenu,
  };
};
