import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconSvg from '../icon-svg/icon-svg';
import './label-field.css';
import { getIconByDataType } from '../../widget-page/helpers';
import {
  getParsedAxisValues,
  isCalculatedMeasure,
} from '../../widget-page/dropdown-layout/helpers/helpers';
import DeleteDialog from '../../../uikit/DeleteDialog/delete-dialog';
import {
  apiDeleteCalculatedField,
  apiFetchSourceData,
  apiGetWidgetById,
} from '../../../services/widgetController';
import { setCurrentProjectWidgetSourceFieldsDataAction } from '../../../slices/main-page/main-page-slice';
import { AddMeasureModal } from '../../widget-page/sidebar/add-measure-modal';
import { setCurrentWidgetAction } from '../../../slices/widget/widget';
import { State } from '../../../slices/types';
import { PanelType } from '../../../enums/widget-type';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { IconButtonType, CustomIconButton } from '../../../uikit/IconButton';
import { CustomPopover } from '../../../uikit/Popover/popover';
import { CustomPopoverMenu } from '../../../uikit/PopoverMenu/popover-menu';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

type LabelFieldProps = {
  field: any;
};

const LabelField: React.FC<LabelFieldProps> = ({ field }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const containerRef = useRef(null);

  const widget = useSelector((state: State) => state.widget);
  const isDeletingInProgress = useSelector(
    (state: State) => state.mainPage.isDeleting,
  );

  const isOpenPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const [deleteError, setDeleteError] = useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  const toggleDeleteDialog = () => {
    setShowDeleteDialog((prevState) => !prevState);
  };

  const toggleEditDialog = () => {
    showEditDialog && updateWidgetAfterCalculatedChange(field.calculatedId);

    setShowEditDialog((prevState) => !prevState);
  };

  const handleMoreButtonClick = () => {
    setAnchorEl(containerRef.current);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const updateWidgetAfterCalculatedChange = (fieldId: number) => {
    const axisXValue = getParsedAxisValues(PanelType.axisX, widget.properties);
    const axisYValue = getParsedAxisValues(PanelType.axisY, widget.properties);
    const axisZValue = getParsedAxisValues(PanelType.axisZ, widget.properties);

    const isNeedToUpdateWidget =
      axisXValue.find((item: any) => item.calculatedId === fieldId) ||
      axisYValue.find((item: any) => item.calculatedId === fieldId) ||
      axisZValue.find((item: any) => item.calculatedId === fieldId);

    isNeedToUpdateWidget && widget.id && apiGetWidgetById(widget.id).then((result: any) => {
      dispatch(setCurrentWidgetAction(result));
    });
  };

  const handleDelete = () => {
    const fieldId = field.calculatedId;
    const currentSourceId = field.etlSourceId;
    const force = !!deleteError.length;
    apiDeleteCalculatedField(field.calculatedId, force).then(() => {
      apiFetchSourceData(currentSourceId).then((result) => {
        dispatch(setCurrentProjectWidgetSourceFieldsDataAction(result.fields));
      });
      updateWidgetAfterCalculatedChange(fieldId);
    }).catch((error) => {
      setDeleteError(error.response.data.message);
    });
  };

  const handleEditCalculated = (event: any) => {
    handleClose(event);
    toggleEditDialog();
  };

  const handleDeleteCalculated = (event: any) => {
    handleClose(event);
    toggleDeleteDialog();
  };

  const menuList = [
    {
      title: 'Редактировать',
      icon: <IconSvg svg={IconDictionary.EditFile} fill='var(--dark-grey)' />,
      onClick: handleEditCalculated,
      isNeedDivider: true,
    },
    {
      title: 'Удалить',
      icon: <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />,
      onClick: handleDeleteCalculated,
      isNeedDivider: false,
      isDelete: true
    },
  ];

  return (
    <div className="label-field" ref={containerRef}>
      <IconSvg
        svg={getIconByDataType(field.type)}
        fill="var(--dark-grey)"
      />
      <CustomTooltip arrow title={field.name}>
        <span className="label-field__label">
          {field.name}
        </span>
      </CustomTooltip>
      {isCalculatedMeasure(field) && (
        <CustomIconButton
          iconButtonProps={{
            children: <IconSvg
              svg={IconDictionary.VerticalMore}
              fill="var(--dark-grey)"
            />,
            onClick: handleMoreButtonClick,
          }}
          type={IconButtonType.secondary}
        />
      )}

      <CustomPopover
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpenPopover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CustomPopoverMenu settings={menuList} />
      </CustomPopover>

      {showEditDialog && (
        <AddMeasureModal
          closeModal={toggleEditDialog}
          currentSourceId={field.etlSourceId}
          currentField={field}
        />
      )}

      <DeleteDialog
        isOpen={showDeleteDialog}
        onClose={toggleDeleteDialog}
        onDelete={handleDelete}
        title="Удаление вычисляемого показателя"
        bodyText={`показатель «${field.name}»`}
        maxWidth="xs"
        error={deleteError}
        isLoading={isDeletingInProgress}
      />
    </div>
  );
};

export default LabelField;
