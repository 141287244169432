import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateNewTable } from '../types';

const initialState: CreateNewTable = {
  isSaving: false,
};

const createNewTableSlice = createSlice({
  name: 'createNewTable',
  initialState,
  reducers: {
    toggleSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.isSaving = payload;
    },
  },
});

export const createNewTableReducer = createNewTableSlice.reducer;

export const {
  toggleSaving: toggleSavingNewTableAction,
} = createNewTableSlice.actions;
