import { Dialog, DialogProps, styled } from '@material-ui/core';
import React from 'react';

const StyledDialog = styled(Dialog)({
  '& [class*=MuiPaper-root]': {
    margin: 0,
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(0, 0, 0, 0.06)',
    borderLeft: '1px solid var(--dark-cyan)',
    borderRadius: 0,
    maxHeight: 'none',
  },
  '& [class*=MuiDialog-container]': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});

export const MapModalDialog = (props: DialogProps) => {
  return (
    <StyledDialog
      {...props}
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      maxWidth={false}
    >
      {props.children}
    </StyledDialog>
  );
};
