import { Group } from '@visx/group';
import { AxisBottom } from '@visx/axis';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { VariableSizeList } from 'react-window';
import { useDebouncedCallback } from 'use-debounce';
import { BarStackHorizontal } from '@visx/shape';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import _ from 'lodash';
import { PanelType } from '../../../../enums/widget-type';
import { calculateTextDimensions } from '../../../../utils/functions';
import {
  ScalableSVGText,
} from '../../../common/scalable-svg-text';
import {
  getConvertedBarGroupsForStackedHist,
  getCorrectWidgetData,
  getStackedHistogramData,
  getStackedMinMaxTotal,
} from '../helpers';
import { setPropForNumberValue } from '../formatting-helpers';
import { useEventListener } from '../../../../hooks/useEventListener';
import './styles.css';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import {
  barValueStyles,
  bottomAxisSettings,
  defaultLeftDataWidth,
  defaultMobileLeftDataWidth,
  gridLinesColor,
  gridLinesYOffset,
  leftAxisSettings,
  stageSettings,
} from './settings';
import { Property } from '../../dropdown-layout/helpers/Property';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';
import { useStackBarProps } from '../stacked-histogram/hooks/useStackBarProps';
import {
  axisLeftOffset,
  chartOffset,
  defaultBottomAxisHeight,
  bottomAxisTicksCount,
  containerOffsetForAxis,
  defaultOffsetCoeff,
  minBarComputedSize,
  tickLineWidth,
  overlapTotalPositiveOffset
} from '../bar-group/settings';
import { useBarDimensions } from '../bar-group/hooks/useBarDimensions';
import { getDomainPointsForStackedHistogram } from '../../../../helpers/widget-page/charts';
import { CustomBarGroup } from './components/bar-group';
import { getFirstActivePanelItemIndex } from '../../../../helpers/common-helpers';

export type BarsProps = {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  domain?: number[];
};

export default function StackedHorizontalHistogram({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  domain,
}: BarsProps) {
  const isFullScreen = useWidgetFullScreen(widgetProps.id);
  const enableEvents: boolean = isActiveFilter;
  const widgetProperties = widgetProps.properties;
  const widgetData = widgetProps.data;

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [barHeight, setBarHeight] = useState<number>(0);
  const [barOffset, setBarOffset] = useState<number>(0);
  const [dataCount, setDatacount] = useState<number>(0);

  const {
    roundingCount,
    columnWidth,
    customAxisXLabelHeight,
    isNormalized,
    isOverlap,
    isNeedToDisplayBarValuesTotal,
    currentColors,
    isNeedToDisplayAxesGuide,
    columnCount,
    scaleByNumber,
    formatByNumber,
    axisXValuesFull,
    customAxisXLabelWidth,
    columnOffsetCoeff,
    axisYTotalValueWidth,
  } = useStackBarProps({
    widgetProperties,
  });

  const bottomAxisHeight = !isNaN(customAxisXLabelHeight)
    ? customAxisXLabelHeight
    : defaultBottomAxisHeight;

  const axisYLabelWidth = isNaN(customAxisXLabelWidth)
    ? leftAxisSettings.left + axisLeftOffset
    : customAxisXLabelWidth;

  const valueFormat = initValueFormatter({ roundingCount });

  const widniwWidth = window.innerWidth;
  const isMobile = widniwWidth < 768;

  let leftDataWidth = useMemo(
    () =>
      isMobile
        ? defaultMobileLeftDataWidth
        : customAxisXLabelWidth,
    [isMobile, widgetProperties],
  );

  if (!leftDataWidth && leftDataWidth !== 0) {
    leftDataWidth = defaultLeftDataWidth;
  }

  const data = useMemo(
    () =>
      getStackedHistogramData(getCorrectWidgetData(widgetData), isNormalized, isOverlap),
    [widgetData, isNormalized, isOverlap],
  );

  const maxTotal = getStackedMinMaxTotal(data, true, isNormalized, isOverlap);
  const minTotal = getStackedMinMaxTotal(data, false, isNormalized, isOverlap);

  const barTotalValues = getCorrectWidgetData(widgetData).map((item: any) => _.sum(item.y));

  const getTotalValue = (index: number) => {
    return valueFormat(
      barTotalValues[index],
      0,
      setPropForNumberValue(widgetProperties),
      PanelType.axisY,
      false,
      false,
      scaleByNumber ? formatByNumber : null,
      true
    );
  };

  const getTotalValueOffset = () => {
    return barTotalValues.reduce((acc, value, index) => {
      // добавил +1, чтобы убрать погрешность применения коэффициента масштабирования
      const offset = calculateTextDimensions(getTotalValue(index) as string, barValueStyles).width;
      return offset > acc ? offset : acc;
    }, 0);
  };

  const referenceData = widgetData[0].y;

  const keys: Array<string> = referenceData.map((value: any, index: number) =>
    String(index),
  );

  const getY = (d: any) => d.x;

  const {
    startDomainPoint,
    endDomainPoint,
    isAllNegative,
    isHasNegative
  } = getDomainPointsForStackedHistogram(keys, data);

  const pointsForNotOverlap = {
    start: isNormalized && isHasNegative
      ? -100
      : minTotal,
    end: isNormalized && isAllNegative
      ? 0
      : isNormalized && isHasNegative
        ? 100
        : maxTotal
  };

  const pointsForScale = [
    isOverlap
      ? startDomainPoint
      : pointsForNotOverlap.start,
    isOverlap
      ? endDomainPoint
      : pointsForNotOverlap.end
  ];

  const totalValueOffset = isNeedToDisplayBarValuesTotal ? getTotalValueOffset() : 0;

  const leftOffset = axisYLabelWidth + axisLeftOffset + tickLineWidth;

  const xMax = width - totalValueOffset - axisYTotalValueWidth;
  const yMax = height - bottomAxisHeight;

  const xScale = scaleLinear<number>({
    nice: true,
    domain: domain || pointsForScale,
  });

  const yScale = scaleBand<string>({
    domain: data.map(getY),
    padding: 0.2,
    paddingOuter: 0,
  });

  xScale.range([
    0,
    xMax - (leftAxisSettings.left + leftDataWidth),
  ]);

  yScale.rangeRound([0, yMax]);

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: currentColors,
  });

  const containerRef = useRef(document.createElement('div'));
  const viewportRef = useRef(document.createElement('div'));

  const debounceDelay = 100;

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setWidth(value);
  }, debounceDelay);

  const deselectBar = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const selectBar = useCallback(
    (value: string) => {
      if (!enableEvents) return;
      if (filterField?.value?.includes(value)) {
        deselectBar();
      } else {
        if (!setFilterField || !value) return;

        setFilterField(widgetProps.id, new Property(axisXValuesFull[0]).getId(), [{
          operation: '=',
          value: [value],
        }]);
      }
    },
    [enableEvents, filterField, deselectBar, setFilterField, widgetProps.id, axisXValuesFull],
  );

  const rowsList = React.useRef<VariableSizeList>(null);

  const clearListCash = () => {
    rowsList.current && rowsList.current.resetAfterIndex(0);
  };

  useEffect(() => {
    clearListCash();
  }, [width, height, data, barHeight, columnWidth]);

  const {
    getOffsetBetweenBarGroups,
    minChartLength,
  } = useBarDimensions({
    barHeight,
    barsInGroupCount: 1,
    barGroupsCount: dataCount,
    columnOffsetCoeff: isNaN(columnOffsetCoeff) ? defaultOffsetCoeff : columnOffsetCoeff
  });

  const calculateDimensions = useCallback(() => {
    if (!viewportRef.current) return;
    const { minWidth } = stageSettings;
    const containerWidth = viewportRef.current.clientWidth;
    const containerHeight = viewportRef.current.clientHeight - bottomAxisHeight - chartOffset * 2;

    const currentWidth = minWidth > containerWidth ? minWidth : containerWidth;

    setDebouncedWidth.callback(currentWidth);
    setDebouncedHeight.callback(containerHeight);
    clearListCash();
  }, [bottomAxisHeight, setDebouncedWidth, setDebouncedHeight]);

  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
    setBarHeight(columnWidth || minBarComputedSize);
    const containerSize = viewportRef.current.clientHeight - axisYLabelWidth - chartOffset * 2;
    setBarOffset(getOffsetBetweenBarGroups(containerSize));
  }, [calculateDimensions, columnWidth, viewportRef, getOffsetBetweenBarGroups, isFullScreen, axisYLabelWidth]);

  return (
    <div
      ref={viewportRef}
      className="stacked-horizontal-histogram screenshot-overflow-x"
      style={{ flexDirection: 'column' }}
    >
      <div
        className="stacked-horizontal-histogram__ticks-container"
        style={{ height: 0 }}
      >
        <svg
          width="100%"
          height={viewportRef.current.clientHeight - bottomAxisHeight}
        >
          <AxisBottom
            left={leftOffset + (isHasNegative ? totalValueOffset : 0)}
            hideAxisLine
            hideTicks
            numTicks={7}
            top={0}
            tickFormat={(x) => `${x}`}
            tickComponent={(props) => {
              return (
                isNeedToDisplayAxesGuide && (
                  <Group>
                    <rect
                      x={props.x}
                      y={1}
                      width="1"
                      height={containerRef.current.clientHeight}
                      fill={gridLinesColor}
                    />
                  </Group>
                )
              );
            }}
            scale={xScale}
            stroke={bottomAxisSettings.color}
            tickStroke={bottomAxisSettings.color}
          />
        </svg>
      </div>

      <div
        ref={containerRef}
        className="stacked-horizontal-histogram__container screenshot-overflow-y"
      >
        <BarStackHorizontal
          data={data}
          keys={keys}
          width={xMax}
          y={getY}
          yScale={yScale}
          xScale={xScale}
          color={colorScale}
        >
          {(barGroups) => {
            const barList = [...barGroups]?.flatMap((group) => group.bars);
            const convertedBarGroups = getConvertedBarGroupsForStackedHist(barList, columnCount)
              .filter((group) => group !== undefined);

            setDatacount(convertedBarGroups.length);

            return (
              <VariableSizeList
                className="stacked-horizontal-histogram__list"
                height={height}
                itemCount={dataCount}
                itemSize={(i) => i < dataCount - 1 ? (barHeight + barOffset) : barHeight}
                width={width}
                ref={rowsList}
                style={{
                  alignItems: minChartLength > height ? 'flex-start' : 'center',
                  top: chartOffset,
                }}
              >
                {({ index, style }) => {
                  const barGroupProps = {
                    convertedBarGroup: convertedBarGroups[index],
                    barGroups,
                    style,
                    index,
                    isHasNegative,
                    barHeight,
                    leftOffset,
                    xScale,
                    currentColors,
                    valueFormat,
                    setPropForNumberValue,
                    widgetProperties,
                    isActiveFilter,
                    filterField,
                    data,
                    enableEvents,
                    viewportRef,
                    axisYLabelWidth,
                    widgetData,
                    selectBar,
                    keys,
                    barTotalValues,
                    totalValueOffset,
                    getTotalValue,
                    overlapTotalPositiveOffset
                  };

                  return CustomBarGroup(barGroupProps);
                }}
              </VariableSizeList>
            );
          }}
        </BarStackHorizontal>
      </div>

      <div
        className="stacked-horizontal-histogram__axis-container"
        style={{
          width: width - gridLinesYOffset,
          height: bottomAxisHeight,
          position: 'absolute',
          bottom: 0,
        }}
      >
        <svg
          width="100%"
          height={bottomAxisHeight}
        >
          <AxisBottom
            left={leftOffset + (isHasNegative ? totalValueOffset : 0)}
            hideAxisLine
            hideTicks
            numTicks={bottomAxisTicksCount}
            top={0}
            tickFormat={(x) => `${x}`}
            tickComponent={(props) => {
              const ticks = xScale.ticks(bottomAxisTicksCount);
              const textWidth = (width - containerOffsetForAxis) / ticks.length;

              return (
                <Group>
                  <ScalableSVGText
                    x={props.x - textWidth / 2}
                    y={0}
                    formattedValue={valueFormat(
                      props.formattedValue,
                      0,
                      setPropForNumberValue(widgetProperties),
                      PanelType.axisY,
                      false,
                      false,
                      scaleByNumber ? formatByNumber : null
                    )}
                    axis={PanelType.axisY}
                    properties={{
                      width: textWidth,
                      svgTextStyles: bottomAxisSettings,
                      minWidthHide: 20,
                    }}
                    widgetProperties={widgetProperties}
                    showTooltip={false}
                  />
                </Group>
              );
            }}
            scale={xScale}
            stroke={bottomAxisSettings.color}
            tickStroke={bottomAxisSettings.color}
          />
        </svg>
      </div>
    </div>
  );
}
