import { AxisLeft, AxisRight } from '@visx/axis';
import { curveLinear, curveMonotoneX } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { Area, LinePath } from '@visx/shape';
import { isNil } from 'lodash';
import React, { memo, useId } from 'react';
import {
  axisColor,
  axisLeftTickLabelProps,
  axisRightTickLabelProps,
  onlyNegativeDomainValues,
} from '../helper';
import {
  ColorScale,
  XScale,
  GraphData,
  SeriesValue,
  VerticalScale,
  GetVerticalAccessor,
  GetY1,
  FormatAxisVerticalValue,
  HorizontalAccessor,
} from '../types';

const gradientOpacity = 0.3;

interface SeriesProps {
  series: SeriesValue[];
  data: GraphData[];
  xScale: XScale;
  verticalScale: VerticalScale;
  colorScale: ColorScale;
  horizontalAccessor: HorizontalAccessor;
  verticalAccessor: GetVerticalAccessor;
  isLineSeries: boolean;
  isGradientFill: boolean;
  isSmoothedLine: boolean;
  getY1: GetY1;
}

const Series = ({
  series,
  data,
  xScale,
  verticalScale,
  colorScale,
  horizontalAccessor,
  verticalAccessor,
  isLineSeries,
  isGradientFill,
  isSmoothedLine,
  getY1,
}: SeriesProps) => {
  const GraphArea = ({
    value,
    index,
    data,
  }: {
    value: SeriesValue;
    index: number;
    data: GraphData[];
  }) => {
    const gradientId = useId();

    const onlyNegativeValues = onlyNegativeDomainValues(verticalScale);

    return (
      <>
        <LinearGradient
          id={gradientId}
          from={colorScale(value.key)}
          to={colorScale(value.key)}
          fromOpacity={onlyNegativeValues ? 0 : 1}
          toOpacity={onlyNegativeValues ? 1 : 0}
          vertical={true}
        />
        <Area
          curve={isSmoothedLine ? curveMonotoneX : curveLinear}
          fill={isGradientFill ? `url(#${gradientId})` : colorScale(value.key)}
          x={(d) => xScale(horizontalAccessor(d))}
          y={(d) => verticalScale(verticalAccessor(index)(d) as unknown as number)}
          y1={() => getY1(verticalScale, verticalAccessor)}
          data={data}
          stroke={colorScale(value.key)}
          fillOpacity={isGradientFill ? gradientOpacity : 1}
          fillRule="evenodd"
        />
      </>
    );
  };

  return (
    <>
      {series.map((value, index) => {
        const clearedData = data.filter(
          (d) => !isNil(verticalAccessor(index)(d)),
        );

        return isLineSeries ? (
          <LinePath<GraphData>
            key={value.key}
            data={clearedData}
            x={(d) => xScale(horizontalAccessor(d))}
            y={(d) =>
              verticalScale(verticalAccessor(index)(d) as unknown as number)}
            strokeWidth={1}
            stroke={colorScale(value.key)}
            curve={isSmoothedLine ? curveMonotoneX : curveLinear}
          />
        ) : (
          <GraphArea
            key={value.key}
            value={value}
            index={index}
            data={clearedData}
          />
        );
      })}
    </>
  );
};

interface GraphSeriesProps {
  isAxisRight?: boolean;
  axisRightOffsetLeft?: number;
  series: SeriesValue[];
  data: GraphData[];
  xScale: XScale;
  verticalScale: VerticalScale;
  colorScale: ColorScale;
  horizontalAccessor: HorizontalAccessor;
  verticalAccessor: GetVerticalAccessor;
  isLineSeries: boolean;
  isGradientFill: boolean;
  isSmoothedLine: boolean;
  getY1: GetY1;
  formatAxisValue: FormatAxisVerticalValue;
  betweenGraphOffset: number;
}

const AxisSeries = memo(({
  isAxisRight,
  axisRightOffsetLeft,
  series,
  data,
  xScale,
  verticalScale,
  colorScale,
  horizontalAccessor,
  verticalAccessor,
  isLineSeries,
  isGradientFill,
  isSmoothedLine,
  getY1,
  formatAxisValue,
  betweenGraphOffset,
}: GraphSeriesProps) => {
  const Axis = isAxisRight ? AxisRight : AxisLeft;

  return (
    <>
      <Series
        series={series}
        data={data}
        xScale={xScale}
        verticalScale={verticalScale}
        colorScale={colorScale}
        horizontalAccessor={horizontalAccessor}
        verticalAccessor={verticalAccessor}
        isLineSeries={isLineSeries}
        isGradientFill={isGradientFill}
        isSmoothedLine={isSmoothedLine}
        getY1={getY1}
      />
      <Axis
        left={isAxisRight ? (axisRightOffsetLeft as number + betweenGraphOffset) : -betweenGraphOffset}
        scale={verticalScale}
        stroke={axisColor}
        tickStroke={axisColor}
        tickFormat={(value) => {
          return formatAxisValue(value as number);
        }}
        tickLabelProps={() =>
          isAxisRight ? axisRightTickLabelProps : axisLeftTickLabelProps}
      />
    </>
  );
});

AxisSeries.displayName = 'AxisSeries';

export default AxisSeries;
