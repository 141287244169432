import { useEffect } from 'react';
import './styles.css';
import './react-grid.css';
import { shallowEqual, useSelector } from 'react-redux';
import { flatten } from 'lodash';
import { State } from '../../../slices/types';
import { BoundFilterGroupProps } from '../hooks';
import { BoundType } from '../settings/enums';
import { getChain } from '../../../helpers/dashboard-page/dashboard-widgets';
import { useDashboardManualFilters } from '../hooks/useDashboardManualFilters';

const DashboardWidgetsFilterManager = ({
  dashboardLayout,
}: {
  dashboardLayout: any;
}) => {
  const currentDashboard = useSelector(
    (state: State) => state.dashboard,
    shallowEqual,
  );

  const { drilldownValues: drilldownAutoValues, deleteGroup: deleteAutoGroup } =
    useDashboardManualFilters(BoundType.autoDrillDownGroups);

  const {
    drilldownValues: drilldownManuaValues,
    deleteGroup: deleteManualGroup,
  } = useDashboardManualFilters(BoundType.manualDrillDownGroups);

  useEffect(() => {
    const deleteUnnecessaryDrilldownValues = (
      drilldownValues: BoundFilterGroupProps[],
      deleteGroup: (index: number) => void,
    ) => {
      const actualDDBlocks = flatten(
        currentDashboard.widgetIds.map((widgetId) => {
          return getChain([widgetId], drilldownValues);
        }),
      );

      drilldownValues.forEach((value, index) => {
        const isGroupValid = value.participantFilterWidgets.some(
          (widget) =>
            widget.isMaster && actualDDBlocks.includes(widget.widgetId),
        );

        if (value.id && !isGroupValid) {
          deleteGroup(index);
        }
      });
    };

    deleteUnnecessaryDrilldownValues(drilldownAutoValues, deleteAutoGroup);
    deleteUnnecessaryDrilldownValues(drilldownManuaValues, deleteManualGroup);
  }, [
    currentDashboard.widgetIds,
    dashboardLayout,
    deleteAutoGroup,
    deleteManualGroup,
    drilldownAutoValues,
    drilldownManuaValues,
  ]);

  return null;
};

export default DashboardWidgetsFilterManager;
