import React, { useCallback } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Placement } from '../../../../enums/visual-types';
import { backgroundColors } from '../../../../helpers/ui-helper';
import { NumberInput } from '../../../common/special-inputs/inputs/number-input';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface ValueFieldProps {
  label: string;
  onChange: (value: string | null) => void;
  value: string | null;
  placement?: Placement;
  showClearButton?: boolean;
}

export const ValueField: React.FC<ValueFieldProps> = ({
  label,
  onChange,
  value,
  placement= Placement.Default,
  showClearButton = false,
}) => {
  const backgroundColor = backgroundColors[placement];

  const handleChange = useCallback((value: string) => {
    onChange(value);
  }, []);

  const handleClear = useCallback(() => {
    onChange('');
  }, []);

  return (
    <NumberInput
      value={value}
      handleChange={handleChange}
      fullWidth
      InputProps={showClearButton ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton style={{ margin: 0 }} onClick={handleClear}>
              <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
            </IconButton>
          </InputAdornment>
        ),
      } : undefined}
      label={label}
      styles={{ isBottomBorderActive: false }}
      backgroundColor={backgroundColor}
    />
  );
};
