import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { CssVariables } from '../../enums/css-variables';
import { getSystemSettings } from '../../services';
import { setSystemSettingsAction } from '../../slices/main-page/main-page-slice';
import { SystemSettingsResponse } from '../../slices/types';
import { ChangeColorLuminance } from '../../helpers/common-helpers';

export const useBaseSystemSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const initSystemSettings = () => {
      getSystemSettings().then(
        (systemSettingsResponse: SystemSettingsResponse) => {
          const systemSettings = { ...systemSettingsResponse };
          dispatch(setSystemSettingsAction(systemSettings));

          const root = document.documentElement;
          root.style.setProperty(
            '--primary-color',
            systemSettings.SYSTEM_UI_PRIMARY_COLOR_FIRST ||
              'var(--default-primary-color)',
          );
          root.style.setProperty(
            '--backdrop-color',
            systemSettings.SYSTEM_UI_BACKDROP_COLOR ||
              'var(--default-backdrop-color)',
          );
          root.style.setProperty(
            '--title-color',
            systemSettings.SYSTEM_UI_TITLES_COLOR ||
              'var(--default-title-color)',
          );
          root.style.setProperty(
            '--panel-color',
            systemSettings.SYSTEM_UI_PANEL_COLOR ||
              systemSettings.SYSTEM_UI_PRIMARY_COLOR_FIRST ||
              'var(--default-primary-color)',
          );
          root.style.setProperty(
            '--success-color',
            systemSettings.SYSTEM_UI_SUCCESS_COLOR ||
              'var(--default-success-color)',
          );

          root.style.setProperty(
            '--primary-hover-color',
            systemSettings.SYSTEM_UI_PRIMARY_COLOR_FIRST
              ? ChangeColorLuminance(
                  systemSettings.SYSTEM_UI_PRIMARY_COLOR_FIRST,
                  0.2,
                )
              : 'var(--default-primary-hover-color)',
          );
          root.style.setProperty(
            '--backdrop-item-color',
            systemSettings.SYSTEM_UI_BACKDROP_COLOR
              ? ChangeColorLuminance(
                systemSettings.SYSTEM_UI_BACKDROP_COLOR,
                -0.08,
              )
              : 'var(--default-backdrop-item-color)',
          );
          root.style.setProperty(
            CssVariables.WIDGET_BACKDROP_COLOR,
            systemSettings.SYSTEM_UI_WIDGET_BACKDROP_COLOR || 'var(--white)',
          );
          root.style.setProperty(
            CssVariables.MAIN_BACKDROP_COLOR,
            systemSettings.SYSTEM_UI_MAIN_BACKDROP_COLOR || 'var(--white)',
          );
          root.style.setProperty(
            CssVariables.HEADER_BACKDROP_COLOR,
            systemSettings.SYSTEM_UI_HEADER_BACKDROP_COLOR || 'var(--white)',
          );
        },
      );
    };

    initSystemSettings();
  }, [dispatch]);
};
