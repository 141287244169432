import React, { useState } from 'react';
import {
  Collapse,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import moment from 'moment';
import block from 'bem-cn';

import History, { StatusName } from '../../../types/history';
import { numDecline } from '../../../utils/functions';

import IconSvg from '../icon-svg/icon-svg';
import HistoryStepRow from './history-step-row';

import './loader-history-element.css';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';

const useStyles = makeStyles({
  root: {
    marginLeft: 'calc(3px * var(--scale-coefficient))',
    border: '1px solid #DAE7ED',
    borderRadius: '3px',
    boxShadow: `0px 4px 10px rgba(58, 130, 228, 0.12),
                  0px 4px 10px rgba(0, 0, 0, 0.06)`,
  },
  tableHead: {
    backgroundColor: '#EDF1F8',
  },
  tableCell: {
    minWidth: 'calc(130px * var(--scale-coefficient))',
  },
  tableCellHead: {
    fontWeight: 'bold',
    padding: 'calc(16px * var(--scale-coefficient))',
    fontSize: 'calc(14px * var(--scale-coefficient))',
  },
  tableCellWide: {
    minWidth: 'calc(170px * var(--scale-coefficient))',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
      padding: 'calc(16px * var(--scale-coefficient))',
      fontSize: 'calc(14px * var(--scale-coefficient))',
    },
  },
  in: {
    marginLeft: 'calc(5px * var(--scale-coefficient))',
    boxShadow: `0px 4px 10px rgba(58, 130, 228, 0.12),
                  0px 4px 10px rgba(0, 0, 0, 0.06)`,
  },
});

const b = block('history-element');

const getStatusMode = (status: StatusName): 'error' | 'done' | 'default' => {
  switch (status) {
    case StatusName.NEW:
    case StatusName.DONE:
      return 'done';
    case StatusName.ERROR:
      return 'error';
    default:
      return 'default';
  }
};

interface LoaderHistoryElementProps {
  element: History.element;
}

const LoaderHistoryElement = ({ element }: LoaderHistoryElementProps) => {
  const [isShow, setShow] = useState<boolean>(false);

  const classes = useStyles();

  const handleClick = () => {
    setShow(!isShow);
  };

  const stepsErrorLength = element.loadMilestones.filter(
    (step) => step.status.name === StatusName.ERROR,
  ).length;

  return (
    <li>
      <ListItem
        button
        onClick={handleClick}
        className={`${b()} ${b(getStatusMode(element.status.name))} ${
          classes.root
        }`}
      >
        <ListItemText>
          <div className={b('inner-text')}>
            <p>
              {moment(element.startLoad).format('D.MM.YYYY H:mm:ss')}
              {element.endLoad ? <> &ndash; </> : null}
              {element.endLoad
                ? moment(element.endLoad).format('D.MM.YYYY H:mm:ss')
                : null}
            </p>

            <div className={b('count')}>
              <IconSvg svg={IconDictionary.HistoryStep} classSVG={b('icon-count').toString()} width={22} height={22} fill="var(--dark-grey)" />
              {stepsErrorLength ? `${element.loadMilestones.length - stepsErrorLength}/` : null}

              {element.loadMilestones.length}
              {numDecline(
                element.loadMilestones.length,
                ' шаг',
                ' шага',
                ' шагов',
              )}

              <div
                className={`${b('icon-arrow')} ${
                  isShow ? 'icon-arrow-up' : null
                }`}
              >
                <IconSvg svg={IconDictionary.RoundedArrowToBottom} width={22} height={22} fill={isShow ? 'var(--primary-color)' : 'var(--dark-grey)'} />
              </div>
            </div>
          </div>
        </ListItemText>
      </ListItem>

      <Collapse in={isShow} className={classes.in} timeout={1}>
        {element.loadMilestones.length > 0 ? (
          <Table aria-label="history-step table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCellHead}>
                  Действие
                </TableCell>
                <TableCell
                  className={`${classes.tableCellWide} ${classes.tableCellHead}`}
                >
                  Статус
                </TableCell>
                <TableCell
                  className={`${classes.tableCellWide} ${classes.tableCellHead}`}
                >
                  Дата начала
                </TableCell>
                <TableCell
                  className={`${classes.tableCellWide} ${classes.tableCellHead}`}
                >
                  Дата окончания
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {element.loadMilestones.map((step: History.Milestone) => (
                <HistoryStepRow key={`row-${step.startLoad}`} step={step} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className={b('alert-box')}>
            {element.status.name === StatusName.ERROR ? (
              <CustomErrorAlert>{element.errorMessage}</CustomErrorAlert>
            ) : (
              <Alert severity="success">
                <p className={b('alert-text')}>{element.status.description}</p>
              </Alert>
            )}
          </div>
        )}
      </Collapse>
    </li>
  );
};

export default LoaderHistoryElement;
