import React from 'react';
import { merge } from 'lodash';
import { useSelector } from 'react-redux';
import { SelectInput } from '../../../../../common/special-inputs/inputs/select-input';
import { getSimplifiedType } from '../../../../dropdown-layout/helpers/helpers';
import { Aggregations } from '../../axis-rectriction/enums';
import { Operations } from '../enums';
import { TextInput } from '../../../../../common/special-inputs/inputs/text-input';
import { CellColoringGroup, PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { State } from '../../../../../../slices/types';
import { WidgetType } from '../../../../../../enums/widget-type';

interface SimpleFormulaBlockProps {
  group: CellColoringGroup;
  changeSettingInGroup: (groupIndex: number, group: CellColoringGroup) => void;
  formatAxisValues: any[];
  index: number;
  fieldType: string;
  axisValues: PropertyData[];
}

export const SimpleFormulaBlock = ({
  group,
  changeSettingInGroup,
  formatAxisValues,
  index,
  fieldType,
  axisValues,
}: SimpleFormulaBlockProps) => {
  const onAggregationChange = (event: any) => {
    changeSettingInGroup(
      index,
      merge(group, {
        simpleCondition: {
          aggregation: event.target.value,
        },
      }),
    );
  };

  const onOperationChange = (event: any) => {
    changeSettingInGroup(
      index,
      merge(group, {
        simpleCondition: {
          operation: event.target.value,
        },
      }),
    );
  };

  const onValueChange = (event: any) => {
    changeSettingInGroup(
      index,
      merge(group, {
        simpleCondition: {
          value: event.target.value,
        },
      }),
    );
  };

  const currentColumn = formatAxisValues.find(
    (value: any) => value.value === group.simpleCondition.fieldName,
  );

  const currentColumnId = currentColumn?.id || '';

  const onColumnChange = (event: any) => {
    const field = formatAxisValues.find(
      (value: any) => value.id === event.target.value,
    );

    changeSettingInGroup(
      index,
      merge(group, {
        simpleCondition: {
          fieldName: field.value,
          fieldEtlId: field.id,
          aggregation: field.aggregation,
        },
      }),
    );
  };

  const isDisabledAggregation = () => {
    return Boolean(
      group.useFormula ||
      axisValues.find((value) => value.etlFieldId === currentColumn?.id)
    );
  };

  const isPivot =
    useSelector((state: State) => state.widget.type) === WidgetType.PIVOT_TABLE;

  const isDisabledValue = group.useFormula || (getSimplifiedType(currentColumn?.type) === 'BOOLEAN' && !currentColumn.aggregation);

  return (
    <>
      <div className="conditional-coloring__flex">
        <SelectInput
          label="Столбец"
          value={currentColumnId}
          handleChange={(event) => onColumnChange(event)}
          itemList={formatAxisValues.map((value: any) => {
            return { value: value.id, showValue: value.value };
          })}
          disabled={group.useFormula || isPivot}
          className="conditional-coloring__select_big"
        />
        <SelectInput
          value={group.simpleCondition.aggregation}
          label={
            getSimplifiedType(fieldType) === 'DATE' ? 'Функция' : 'Агрегация'
          }
          handleChange={onAggregationChange}
          itemList={Aggregations[getSimplifiedType(currentColumn?.type)].map(
            (value: any) => ({
              value: value.formula,
              showValue: value.name,
            }),
          )}
          disabled={isDisabledAggregation() || isPivot}
          className="conditional-coloring__select_middle"
        />
        <SelectInput
          value={group.simpleCondition.operation}
          label="Условие"
          handleChange={(event) => onOperationChange(event)}
          itemList={
            currentColumn
              ? Operations[
                  currentColumn.aggregation
                    ? 'NUMBER'
                    : getSimplifiedType(currentColumn.type)
                ].map((value: any) => {
                  return { value, showValue: value };
                })
              : []
          }
          disabled={group.useFormula}
          className="conditional-coloring__select_middle"
        />
      </div>

      <TextInput
        value={group.simpleCondition.value}
        label="Введите значение"
        handleChange={(event) => onValueChange(event)}
        fullWidth
        disabled={isDisabledValue}
      />
    </>
  );
};
