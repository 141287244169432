import MapConnection from '../../../types/map-connection';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

const errorTexts = {
  operations: 'Имеются невалидные операции.',
  unusedSources: 'Имеются неиспользованные источники.',
  joinResultInMapping: `Необходимо добавить операцию Map для итогового ${CommonDictionary.JoinResult}.`,
  empty: 'Карта загрузчика пустая.',
};

export const defineValidation = (data: MapConnection.Data) => {
  const validationValues: any = {};

  // validationValues.operations = checkOperations(data);
  // validationValues.unusedSources = checkUnusedSources(data);
  // validationValues.joinResultInMapping = checkJoinResultInMapping(data);
  validationValues.empty = checkEmpty(data);

  return getErrorText(validationValues);
};

const checkOperations = (data: MapConnection.Data) => {
  return !!data.steps.filter((step) => !step.fieldsMapping.length)?.length;
};

const checkUnusedSources = (data: MapConnection.Data) => {
  const sourcesIdsList = data.sourceObjects.map((sourceObject) => {
    return sourceObject.id;
  });

  const stepsAllSourcesIdsList = data.steps.reduce((acc: any[], step) => {
    acc.push(step.outputSourceObjectId);
    return [...acc, ...step.inputSourceObjectsIds];
  }, []);

  return !sourcesIdsList.every((id) => stepsAllSourcesIdsList.includes(id));
};

const checkJoinResultInMapping = (data: MapConnection.Data) => {
  const joinSourcesIds = data.sourceObjects
    .filter((sourceObject) => sourceObject.sourceId === null)
    .map((sourceObject) => {
      return sourceObject.id;
    });

  const stepsSourcesIdsList = data.steps.reduce((acc: any[], step) => {
    return [...acc, ...step.inputSourceObjectsIds];
  }, []);

  return !joinSourcesIds.every((id) => stepsSourcesIdsList.includes(id));
};

const checkEmpty = (data: MapConnection.Data) => {
  return !data.sourceObjects.length;
};

const getErrorText = (validationValues: any) => {
  let errorText = '';

  if (validationValues.unusedSources) {
    errorText += ` ${errorTexts.unusedSources}`;
  }

  if (validationValues.operations) {
    errorText += ` ${errorTexts.operations}`;
  }

  if (validationValues.joinResultInMapping) {
    errorText += ` ${errorTexts.joinResultInMapping}`;
  }

  if (validationValues.empty) {
    errorText += ` ${errorTexts.empty}`;
  }

  return errorText;
};
