import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '43%',
        boxShadow:
          '0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06)',
        borderRadius: '3px',
        background: 'var(--white)',
      },
      '& .MuiSelect-selectMenu': {
        padding: '0 calc(14px * var(--scale-coefficient))',
        height: 'calc(46px * var(--scale-coefficient))',
        alignItems: 'center',
        display: 'flex',
      },
      '& .MuiSvgIcon-root': {
        top: 'calc(50% - 12px * var(--scale-coefficient))',
      },
      '& .MuiSelect-selectMenu > div': {
        overflow: 'hidden',
        marginRight: 'calc(10px * var(--scale-coefficient))',
      },
      '& .MuiInputBase-inputAdornedEnd > div': {
        marginRight: 'calc(30px * var(--scale-coefficient))',
      },
      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '0px',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: '0px',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: '0px',
      },
      '& .MuiOutlinedInput-adornedStart .MuiInputBase-inputAdornedStart': {
        paddingLeft: 'calc(46px * var(--scale-coefficient))',
      },
      '& .MuiOutlinedInput-adornedEnd .select-wrapper .MuiIconButton-root': {
        padding: '0',
        position: 'absolute',
        left: 'calc(-55px * var(--scale-coefficient))',
      },
      '& .MuiOutlinedInput-adornedStart .select-wrapper .SVGInline': {
        padding: '0',
        position: 'absolute',
        right: 'calc(-35px * var(--scale-coefficient))',
      },
      '& .MuiOutlinedInput-adornedEnd .input-wrapper .MuiIconButton-root': {
        padding:
          'calc(6px * var(--scale-coefficient)) calc(3px * var(--scale-coefficient)) calc(6px * var(--scale-coefficient)) calc(3px * var(--scale-coefficient))',
      },
      '& .SVGInline': {
        display: 'flex',
      },
      '& .select-wrapper': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .Mui-disabled .MuiSelect-icon': {
        display: 'none',
      },
      '& .Mui-disabled': {
        color: 'var(--title-color)',
      },
      '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
      '& .MuiOutlinedInput-adornedEnd .select-wrapper-disabled .MuiIconButton-root':
        {
          left: 'calc(-35px * var(--scale-coefficient))',
        },
      '& .input-border-wrapper': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
        verticalAlign: 'top',
        border: 0,
        width: '43%',
        margin: 'calc(8px * var(--scale-coefficient))',
        boxShadow:
          '0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06)',
        borderRadius: '3px',
      },
      '& .input-border-wrapper > .MuiFormControl-root': {
        width: '100%',
        margin: 0,
        boxShadow: 'none',
      },
      '& .input-border': {
        borderBottom: '1px solid var(--primary-color)',
        position: 'absolute',
        top: 'calc(37px * var(--scale-coefficient))',
        margin:
          'calc(5px * var(--scale-coefficient)) 0px calc(10px * var(--scale-coefficient)) calc(-22px * var(--scale-coefficient))',
        width: 'calc(100% - 76px * var(--scale-coefficient))',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: 'calc(20px * var(--scale-coefficient))',
      overflowY: 'auto',
    },
  }),
);
