import block from 'bem-cn';
import { v4 as uuid } from 'uuid';
import React, { useCallback, useEffect, useState } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import { apiValidateComputedField } from 'src/services/loadersController';
import { validateComputedFields } from 'src/helpers/loader-page';
import { replaceAll, wrapFieldToReplace } from '../../../utils/functions';
import IconSvg from '../../common/icon-svg/icon-svg';
import OperationTypeMenu from '../../common/operation-type-menu/operation-type-menu';
import { apiGetFormulas } from '../../../services/dictionariesController';
import './transformation-panel.css';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import { apiGetWidgetsListBySourceAndField } from '../../../services/widgetController';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { useNotificator } from '../../common/snackbar/hooks';
import MapConnection from '../../../types/map-connection';
import { CustomButton } from '../../../uikit/Button';
import { CustomPopover } from '../../../uikit/Popover/popover';
import { CustomInfoAlert } from '../../../uikit/InfoAlert';
import { ConfirmDialog } from '../../../uikit/ConfirmDialog';
import { WidgetDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface TransformationPanelProps {
  sourceField: any;
  sourceObjectId: any;
  closeTransformationPanel: () => void;
  addNewProcessedField: (field: any) => void;
  editProcessedFields: (field: any) => void;
  sourceObjectFields: MapConnection.ProcessedField[];
  uncheckField: () => void;
  isEditableMap: boolean;
  needPushDown: boolean;
}

const TransformationPanel = ({
  closeTransformationPanel,
  sourceField,
  addNewProcessedField,
  uncheckField,
  editProcessedFields,
  sourceObjectId,
  sourceObjectFields,
  isEditableMap,
  needPushDown
}: TransformationPanelProps) => {
  const b = block('transformation-panel');

  const { showNotification } = useNotificator();

  const [
    transformationFieldEditErrorText,
    setTransformationFieldEditErrorText,
  ] = useState<string>('');

  const getTransformationFieldEditErrorText = (widgetsList: any[]) => {
    return `Данный показатель используют следующие ${WidgetDictionary.widgets}: ${widgetsList.join(
      ', ',
    )}`;
  };

  const getEditSourceFieldErrorText = async () => {
    let error = '';
    try {
      const widgetsList = await apiGetWidgetsListBySourceAndField({
        source: sourceObjectId,
        field: sourceField.id,
      });
      if (widgetsList && widgetsList.length) {
        error = getTransformationFieldEditErrorText(widgetsList);
      }
    } catch (e: any) {
      error = e.message;
    }
    setTransformationFieldEditErrorText(error);
    return error;
  };

  const onCloseWidgetWarningHandler = () => {
    setTransformationFieldEditErrorText('');
  };

  let initialTextareaContent: any;
  if (sourceField.category === 'SCHEMA') {
    initialTextareaContent = wrapFieldToReplace(sourceField.alias || sourceField.displayedName);
  } else {
    initialTextareaContent = sourceField.displayedName;
  }

  const [textareaContent, setTextareaContent] = useState<any>(
    initialTextareaContent,
  );
  // array of formules applied to sourceField
  const [formulesDictionary, setFormulesDictionary] = useState<any>([]);
  const [lastFormulaExample, setLastFormulaExample] = useState<string>('');

  const [isPointTransformation, setIsPointTransformation] =
    useState<boolean>(false);

  const [aliasName, setAliasName] = useState<string>(sourceField.category !== 'SCHEMA' ? (sourceField.alias || '') : '');

  const [
    pointTransformationOperandsValues,
    setPointTransformationOperandsValues,
  ] = useState<any>([]);

  const [isInvalidName, setInvalidName] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isOpenMenu = Boolean(anchorEl);
  const popoverId = isOpenMenu ? 'simple-popover' : undefined;

  const onPrepareSave = async () => {
    const errorText = await getEditSourceFieldErrorText();

    if (aliasName.trim().length === 0) {
      setInvalidName(true);
      showNotification({
        message: 'Имя показателя не введено',
        variant: 'error',
      });
      return;
    }
    if (!errorText.length || sourceField.category === 'SCHEMA') {
      onSave();
    }
  };

  const onSave = async () => {
    const computedField = {
      sourceObjectId,
      alias: aliasName.trim(),
      category: '',
      transformationTemplate: '',
      transformationOperandsIds: [''],
      needPushdown: needPushDown,
      id: '',
    };

    if (sourceField.category === 'SCHEMA') {
      const re = wrapFieldToReplace(sourceField.alias || sourceField.displayedName);
      const { initialSourceFieldId } = sourceField;
      const transformationTemplate = replaceAll(textareaContent, re, '$0').trim();
      const transformationOperandsValue = [sourceField.id];
      const newProcessedField = {
        id: uuid(),
        schemaName: '',
        alias: aliasName.trim(),
        initialSourceFieldId,
        displayedName: textareaContent,
        transformationTemplate,
        transformationOperands: transformationOperandsValue,
        selected: true,
        category: 'TRANSFORMATION',
        displayedType: 'FORMULA',
      };

      computedField.transformationOperandsIds = transformationOperandsValue;
      computedField.transformationTemplate = transformationTemplate;
      computedField.category = newProcessedField.category;

      validateComputedFields(computedField, showNotification, () => {
        uncheckField();
        addNewProcessedField(newProcessedField);
        closeTransformationPanel();
      });
    }
    if (sourceField.category === 'TRANSFORMATION') {

      const sourceTransformationField = sourceObjectFields.find((field) => {
        return field.id === sourceField.transformationOperands[0];
      });
      const replaceString = sourceTransformationField?.alias || sourceTransformationField?.displayedName || '';

      const re = wrapFieldToReplace(replaceString);

      const transformationTemplateValue = isPointTransformation
        ? 'to_point($0, $1)'
        : replaceAll(textareaContent, re, '$0').trim();

      const { initialSourceFieldId } = sourceField;

      const transformationOperandsValue = isPointTransformation
        ? pointTransformationOperandsValues
        : sourceField.transformationOperands;

      const editedProcessedField = {
        ...sourceField,
        id: sourceField.id,
        alias: aliasName.trim(),
        displayedName: textareaContent,
        initialSourceFieldId,
        transformationTemplate: transformationTemplateValue,
        transformationOperands: transformationOperandsValue,
      };

      computedField.transformationOperandsIds = transformationOperandsValue;
      computedField.transformationTemplate = transformationTemplateValue;
      computedField.category = editedProcessedField.category;
      computedField.id = editedProcessedField.id;

      validateComputedFields(computedField, showNotification, () => {
        editProcessedFields(editedProcessedField);
        closeTransformationPanel();
      });
    }
  };

  const cancelTransformation = () => {
    setTextareaContent(initialTextareaContent);
    setLastFormulaExample('');
  };

  const getFormulas = useCallback(() => {
    apiGetFormulas({ withoutComplex: true })
      .then((response) => {
        const formulas = response
          ? Object.entries(response).filter(
            (x: [string, any]) => x[0] !== 'Условие',
          )
          : [];
        const formulasDictionary: any[] = [];
        formulas.forEach((f: [string, any], index: number) => {
          formulasDictionary.push([...f, index]);
        });
        response && setFormulesDictionary(formulasDictionary);
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  }, []);

  const handleChange = (e: any) => {
    const sourceTransformationField = sourceObjectFields.find((field) => {
      return field.id === sourceField.transformationOperands[0];
    });
    const potentialValue = e.target.value;
    const columnName =
      sourceField.category === 'TRANSFORMATION'
        ? sourceTransformationField?.alias || sourceTransformationField?.displayedName
        : sourceField.alias || sourceField.displayedName;
    const re = wrapFieldToReplace(columnName);
    if (potentialValue.includes(re)) {
      setTextareaContent(potentialValue);
    }
  };

  const getTextareaContent = (operation: any) => {
    return operation.replace(/\$column/g, textareaContent);
  };

  const chooseTransformationOperation = (operation: any) => () => {
    setIsPointTransformation(
      operation.dslFormula === 'to_point($column, $column)',
    );

    setLastFormulaExample(operation.example);
    setTextareaContent(getTextareaContent(operation.dslFormula));
  };

  useEffect(() => {
    getFormulas();
  }, []);

  const showExample = (text: string) =>
    text
      .split('\\n')
      .map((i, index) => {
        return <div key={`formula-example-${index}`}>{i}</div>;
      });

  const isDisabledSaving: boolean =
    (textareaContent === initialTextareaContent &&
      sourceField.category === 'SCHEMA') ||
    (textareaContent === initialTextareaContent &&
      aliasName.trim() === (sourceField.alias || '').trim());
  return (
    <>
      <CustomDialog
        isOpen={true}
        onClose={closeTransformationPanel}
        title="Преобразование поля"
        maxWidth="sm"
      >
        <div className={b()}>
          <TextInput
            label="Введите название показателя"
            handleChange={(e) => {setAliasName(e.target.value || '');}}
            value={aliasName}
            inputProps={{ maxlength: 27 }}
            error={isInvalidName}
            fullWidth
          />

          <div className={b('textarea-section')}>
            <div className={b('textarea-section-description')}>
              Введите преобразования с полем
            </div>
            <div className={b('textarea-section-main')}>
              <div className={b('textarea-section-formulas-list')}>
                <CustomButton
                  variant="outlined"
                  onClick={handleMenuClick}
                  icon={
                    <IconSvg
                      svg={IconDictionary.PlusUnderline}
                      fill="var(--primary-color)"
                    />
                  }
                />
                <CustomPopover
                  classes={{ paper: b('popover') }}
                  id={popoverId}
                  open={isOpenMenu}
                  onClose={handleCloseMenu}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <OperationTypeMenu
                    formulesDictionary={formulesDictionary}
                    chooseTransformationOperation={
                      chooseTransformationOperation
                    }
                  />
                </CustomPopover>
              </div>
              <TextareaAutosize
                onChange={handleChange}
                value={textareaContent}
                className={b('textarea-section-textarea')}
                aria-label="minimum height"
                rowsMin={9}
                rowsMax={9}
              />
            </div>
          </div>

          {lastFormulaExample && (
            <div className={b('examples')}>
              <CustomInfoAlert title="Примеры">{showExample(lastFormulaExample)}</CustomInfoAlert>
            </div>
          )}
        </div>
        <div className="dialog-buttons">
          <CustomButton
            type="submit"
            variant="contained"
            className="buttons-section-left-side-button"
            onClick={onPrepareSave}
            disabled={!isEditableMap || isDisabledSaving}
          >
            Сохранить
          </CustomButton>

          <CustomButton
            variant="outlined"
            type="reset"
            className="buttons-section-left-side-button"
            onClick={
              textareaContent === initialTextareaContent
                ? closeTransformationPanel
                : cancelTransformation
            }
          >
            Отменить
          </CustomButton>
        </div>
      </CustomDialog>
      <ConfirmDialog
        isOpen={Boolean(transformationFieldEditErrorText.length && sourceField.category !== 'SCHEMA')}
        onClose={onCloseWidgetWarningHandler}
        onConfirm={onSave}
        title='Изменение показателя'
        message={`Вы уверены, что хотите изменить показатель? После сохранения действие нельзя будет отменить. ${transformationFieldEditErrorText}`}
        maxWidth='xs'
      />
    </>
  );
};

export default TransformationPanel;
