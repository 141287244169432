import React, { useEffect, useState } from 'react';
import './tabs.css';
import clsx from 'clsx';
import { isNumber } from 'lodash';

interface TabButton {
  title?: string;
  icon?: JSX.Element;
  onClick: (id: string | number) => void;
  id: string | number;
}

interface TabsProps {
  buttons: TabButton[];
  size?: number;
  selected?: number | null;
  height?: 'medium' | 'small' | 'large';
  variant?: 'standard' | 'chips';
}

export const CustomTabs = ({
  buttons,
  size = 0,
  selected,
  height = 'medium',
  variant = 'standard',
}: TabsProps) => {
  const [selectedButton, setSelectedButton] = useState<number | null>(
    selected === null ? null : selected || 0,
  );

  useEffect(() => {
    (isNumber(selected) || selected === null) &&
      selected !== selectedButton &&
      setSelectedButton(selected);
  }, [selected, selectedButton]);

  const handleClickTab = (buttonIndex: number) => {
    setSelectedButton(buttonIndex);
    buttons[buttonIndex].onClick(buttons[buttonIndex].id);
  };

  const getHeight = () => {
    switch (height) {
      case 'large':
        return 'calc(54px * var(--scale-coefficient))';
      case 'medium':
        return 'calc(46px * var(--scale-coefficient))';
      case 'small':
        return 'calc(38px * var(--scale-coefficient))';
    }
  };

  const tabWidth = `calc(${size}px * var(--scale-coefficient))`;

  return (
    <div
      className="tabs-container"
      style={{
        width: size
          ? `calc(${size * buttons.length}px * var(--scale-coefficient))`
          : '100%',
        height: getHeight(),
        minHeight: getHeight(),
        maxHeight: getHeight(),
      }}
    >
      {buttons.map((button, index) => (
        <div
          key={index}
          role="button"
          onClick={() => handleClickTab(index)}
          tabIndex={-1}
          className={clsx('tabs-container__tab', {
            'tabs-container__tab_active': index === selectedButton,
            'tabs-container__tab_first': !index,
            'tabs-container__tab_last': index === buttons.length - 1,
            'tabs-container__tab_chip': variant === 'chips',
          })}
          style={{
            minWidth: size ? tabWidth : 'auto',
            width: size ? tabWidth : 'auto',
            maxWidth: size ? tabWidth : 'auto',
          }}
        >
          {button.icon}
          {button.title}
        </div>
      ))}
    </div>
  );
};
