import { Moment } from 'moment/moment';
import React from 'react';

import './styles.css';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { DateFunctions } from '../../../enums/date';
import { ParameterType } from '../../../enums/parameters';
import { getScaleCoefficient } from '../../../helpers/ui-helper';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { CustomProgress } from '../../../uikit/Progress';
import { DateField } from '../../widget-page/filters/fields/date-field';
import IconSvg from '../icon-svg/icon-svg';
import { TextInput } from '../special-inputs/inputs/text-input';
import { EditableParameter } from './types';

interface ParameterItemProps {
  parameter: EditableParameter;
  onChangeValue: (value: string) => void;
  onDelete: () => void;
  isDeletingLoading: boolean;
  isDashboard: boolean;
}

export const ParameterItem = ({
  parameter,
  onChangeValue,
  onDelete,
  isDeletingLoading,
  isDashboard,
}: ParameterItemProps) => {
  let svgItem = IconDictionary.Text;
  let typeDesc = '';

  switch (parameter.type) {
    case ParameterType.NUMBER:
      svgItem = IconDictionary.Numbers;
      typeDesc = 'Число';
      break;
    case ParameterType.DATE:
      svgItem = IconDictionary.Time;
      typeDesc = 'Дата';
      break;
    case ParameterType.TEXT:
      svgItem = IconDictionary.Text;
      typeDesc = 'Строка';
      break;
  }

  const handleChangeDate = (value: Moment | null) => {
    if (value && value.isValid()) {
      onChangeValue(value.format('YYYY-MM-DD 00:00:00.0'));
    } else {
      onChangeValue('');
    }
  };

  return (
    <div className="parameter-edit-item">
      <div className="parameter-edit-item__heading">
        <div className="parameter-edit-item__heading-title-block">
          <CustomTooltip title={typeDesc} arrow>
            <div>
              <IconSvg svg={svgItem} fill="var(--dark-grey)" />
            </div>
          </CustomTooltip>
          <span className="parameter-edit-item__heading-title">
            {isDashboard ? parameter.name : parameter.parameterCode}
          </span>
        </div>
        {!isDashboard && (
          <div className="parameter-edit-item__heading-delete-wrap">
            {isDeletingLoading ? (
              <CustomProgress
                type="circular"
                style={{
                  width: 24 * getScaleCoefficient(),
                  height: 24 * getScaleCoefficient(),
                }}
              />
            ) : (
              <CustomTooltip title="Удалить" arrow>
                <div
                  className="parameter-edit-item__heading-delete-icon"
                  onClick={() => onDelete()}
                >
                  <IconSvg
                    svg={IconDictionary.BasketDelete}
                    fill="var(--dark-grey)"
                  />
                </div>
              </CustomTooltip>
            )}
          </div>
        )}
      </div>
      <div>
        {parameter.type === ParameterType.DATE ? (
          <DateField
            label="Дата"
            hideClearButton
            onChange={handleChangeDate}
            value={parameter.value === '' ? null : new Date(parameter.value)}
            filterType={DateFunctions.DAY}
            error={parameter.isValidationValueError}
          />
        ) : (
          <TextInput
            className="parameter-edit-item__input"
            value={parameter.value}
            handleChange={(e) => onChangeValue(e.target.value)}
            label={isDashboard ? parameter.parameterCode : parameter.name}
            error={parameter.isValidationValueError}
          />
        )}
      </div>
    </div>
  );
};
