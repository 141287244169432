import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { CustomButton } from '../Button';
import './index.css';
import { CustomIconButton, IconButtonType } from '../IconButton';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../components/common/icon-svg/icon-svg';

export enum CustomPanelHeadType {
  panel = 'panel',
  popup = 'popup',
  withoutBorder = 'withoutBorder',
}

export interface CustomPanelHeadProps {
  title?: string;
  description?: string;
  onClose?: () => void;
  type?: CustomPanelHeadType;
  closeButtonSize?: 'small' | 'medium' | 'large';
}

const getButtonByType = (
  type: CustomPanelHeadType,
  closeButtonSize: 'small' | 'medium' | 'large',
  onClose?: () => void,
) => {
  switch (type) {
    case CustomPanelHeadType.panel:
      return (
        <CustomButton
          aria-label="close"
          variant="outlined"
          size={closeButtonSize}
          icon={<IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />}
          onClick={onClose}
        />
      );
    case CustomPanelHeadType.popup:
    case CustomPanelHeadType.withoutBorder:
      return (
        <CustomIconButton
          iconButtonProps={{
            children: <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />,
            onClick: onClose,
          }}
          type={IconButtonType.secondary}
        />
      );
    default:
      return (
        <CustomButton
          aria-label="close"
          variant="outlined"
          size={closeButtonSize}
          icon={<IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />}
          onClick={onClose}
        />
      );
  }
};

export const CustomPanelHead = ({
  title,
  description,
  type = CustomPanelHeadType.panel,
  onClose,
  closeButtonSize = 'small',
}: CustomPanelHeadProps) => {
  return (
    <div
      className={clsx('custom-panel-head', {
        'custom-panel-head_popup': type === CustomPanelHeadType.popup,
        'custom-panel-head_withoutBorder':
          type === CustomPanelHeadType.withoutBorder,
      })}
    >
      <div className="custom-panel-head__info">
        {title && (
          <Typography className="custom-panel-head__title">
            {title}
          </Typography>
        )}
        {description && (
          <Typography className="custom-panel-head__description">
            {description}
          </Typography>
        )}
      </div>
      {getButtonByType(type, closeButtonSize, onClose)}
    </div>
  );
};
