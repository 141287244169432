import { useCallback, useEffect, useState } from 'react';
import {
  getConnectionParamsById,
  getFileParamsById,
} from '../services/connection';
import Connection from '../types/connection';

type Data = Connection.Param[] | Connection.FileParam[];

type UseConnectionParamsById = (
  id: number,
  fileMode?: boolean,
) => {
  id: number;
  setId: (id: number) => void;
  data: Data;
  loading: boolean;
  error: string | null;
};

export const useConnectionParamsById: UseConnectionParamsById = (
  defaultId,
  fileMode = false,
) => {
  const [id, setId] = useState<number>(defaultId);
  const [data, setData] = useState<Data>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = useCallback(() => {
    const getParamsById: (id: number) => Promise<Data> = fileMode
      ? getFileParamsById
      : getConnectionParamsById;

    setLoading(true);
    setError(null);

    getParamsById(id)
      .then((resData: any) => { setData(resData); })
      .catch(({ message }: { message: string }) => setError(message))
      .then(() => setLoading(false));
  }, [id, fileMode]);

  const handlerChangeId = useCallback((newId: number) => {
    setData([]);
    setError(null);
    setId(newId);
  }, []);

  useEffect(getData, [id]);

  return {
    id,
    setId: handlerChangeId,
    data,
    loading,
    error,
  };
};
