import { compact, isEqual, uniqWith } from 'lodash';
import { DashboardPropertyType, FilterProperty, TextFilterModeSelect } from 'src/enums/dashboard-properties';
import { getSimplifiedType } from '../../components/widget-page/dropdown-layout/helpers/helpers';
import {
  BoundFilterGroupProps,
  Filter,
  FilterGroupProps,
  ParticipantFilterWidget,
  useDashboardBoundFiltersResult,
} from '../../components/dashboard-page/hooks';
import { PropertyData } from '../../components/widget-page/dropdown-layout/helpers/Property';
import { DashboardFilterTypes } from '../../enums/dashboard-filter-types';
import { Dashboard, DashboardProperty, DashboardResponse } from '../../slices/types';
import { WidgetSimplifiedDataType } from '../../enums/data-type';
import { getPropertyId } from '../../components/widget-page/helpers';
import { getFilterSettingValue } from './filters-helper';

export function getPropertyValue<T>(
  name: string,
  properties: DashboardProperty[],
) {
  const JSONValue = properties.find((prop) => prop.name === name);

  const values: T = JSONValue ? JSON.parse(JSONValue.value) || [] : [];

  return values;
}

export const getPropertyIndex = (
  name: string,
  properties: DashboardProperty[],
) => {
  return properties.findIndex((prop) => prop.name === name);
};

export function getPropertyValueNotParsed(
  name: string,
  properties: DashboardProperty[],
): string {
  return properties.find((prop) => prop.name === name)?.value || '';
}

const extendFiltersByUniqueBoundDashboardFilter = (
  result: any[],
  values?: BoundFilterGroupProps[],
  widgetId?: number,
) => {
  if (!widgetId) return;

  if (values) {
    values.forEach((group) =>
      group.participantFilterWidgets.forEach((_widgetFilter) => {
        if (
          _widgetFilter.widgetId === widgetId &&
          !!group.filters &&
          !!group.selectedSourceFieldId
        ) {
          const filter = {
            operation: group.filters[0].operation,
            value: group.filters[0].value,
          };
          const filterIndex = result.findIndex(
            (item) =>
              item.sourceFieldId === _widgetFilter.filterId ??
              group.selectedSourceFieldId,
          );
          if (filterIndex >= 0) {
            result.splice(filterIndex, 1, {
              filters: _widgetFilter.isActive ? [filter] : [],
              sourceFieldId:
                _widgetFilter.filterId ?? group.selectedSourceFieldId,
              isBound: true,
            });
          } else {
            result.push({
              filters: _widgetFilter.isActive ? [filter] : [],
              sourceFieldId:
                _widgetFilter.filterId ?? group.selectedSourceFieldId,
              isBound: true,
            });
          }
        }
      }),
    );
  }
};

export const checkIsDDFilterGroupAppliedToWidget = (
  group: BoundFilterGroupProps,
  widgetId: number,
) =>
  group.participantFilterWidgets.find(
    (_widgetFilter) =>
      Boolean(group.filters?.length) &&
      Boolean(group.selectedSourceFieldId) &&
      _widgetFilter.widgetId === widgetId &&
      // для связи по фильтрам наследование фильтра происходит только на 1 уровень
      (group.type === DashboardFilterTypes.MANUAL_DRILL_DOWN
        ? _widgetFilter.isActive
        : true),
  );

const extendFiltersByUniqueDrillDownDashboardFilter = (
  result: any[],
  values?: BoundFilterGroupProps[],
  widgetId?: number,
) => {
  if (!widgetId) return;

  if (values) {
    values.forEach((group) => {
      if (checkIsDDFilterGroupAppliedToWidget(group, widgetId)) {
        const filter = {
          operation: group.filters?.[0].operation,
          value: group.filters?.[0].value,
        };
        const filterIndex = result.findIndex(
          (item) => item.sourceFieldId === group.selectedSourceFieldId,
        );
        if (filterIndex >= 0) {
          result.splice(filterIndex, 1, {
            filters: [filter],
            sourceFieldId: group.selectedSourceFieldId,
            isBound: true,
          });
        } else {
          result.push({
            filters: [filter],
            sourceFieldId: group.selectedSourceFieldId,
            isBound: true,
          });
        }
      }
    });
  }
};

export const getAllFieldFilters = (
  filtersEntities: any,
  filterEntityId: number,
  isDashboard?: boolean,
  filtersFromWidgetAxis?: PropertyData[],
  filtersIdsFromWidget?: any,
  boundFilters?: useDashboardBoundFiltersResult,
  widgetId?: number,
  drilldownValues?: BoundFilterGroupProps[],
) => {
  let result: any = [];

  if (isDashboard) {
    filtersEntities.forEach((filterEl: any) => {
      filterEl.widgetFilters.forEach((widgetFilter: any) => {
        const filterFromWidget = (filtersFromWidgetAxis?.find(
          (item: any) => getPropertyId(item) === widgetFilter.id,
        )?.filter || []) as Filter[];
        const isCurrentFilterEntity = filterEl.id !== filterEntityId;
        const isActiveFilterOnDashboard =
          filterEl.actual && widgetFilter.actual;

        const filters = filterEl.value.map((item: any) => ({
          operation: item.operation,
          value: [item.value],
        }));
        const filtersWidget = filterFromWidget.map((item: any) => ({
          operation: item.operation,
          value: [item.value],
        }));

        // TODO Workaround BIDEV-3658 (1 из 2)
        // очищаем фильтры с типом данных число, так как они
        // не корректно считаются на сервере и могут отфильтровать все данные.
        const isNeedClearFilterWA =
          getSimplifiedType(filterEl.type) === 'NUMBER';

        const filtersValue = isCurrentFilterEntity && !isNeedClearFilterWA
          ? isActiveFilterOnDashboard
            ? filters
            : filtersWidget : null;

        if (filtersValue) {
          const overriddenFunction = getFilterSettingValue(filterEl, FilterProperty.function);

          result.push({
            filters: filtersValue,
            sourceFieldId: widgetFilter.calculated ? null : widgetFilter.id,
            widgetCalculatedFieldId: widgetFilter.calculated
              ? widgetFilter.id
              : undefined,
            overriddenFunction: overriddenFunction === null ? undefined : overriddenFunction,
          });
        }
      });
    });

    if (filtersFromWidgetAxis) {
      result = result.filter((value: any) => {
        return filtersIdsFromWidget.includes(value.sourceFieldId);
      });
    }

    extendFiltersByUniqueBoundDashboardFilter(
      result,
      boundFilters?.values,
      widgetId,
    );

    extendFiltersByUniqueDrillDownDashboardFilter(
      result,
      drilldownValues,
      widgetId,
    );

    // добавляем фильтры виджета, унаследованные в DD
    filtersFromWidgetAxis &&
      filtersFromWidgetAxis
        .filter((filter) => filter.isInherited)
        .forEach((filter) => {
          const filters = (filter.filter as unknown as Filter[]).map(
            (filterValue) => ({
              operation: filterValue.operation,
              value: [filterValue.value],
            }),
          );

          result.push({
            filters,
            sourceFieldId: filter.id,
            widgetCalculatedFieldId: filter.calculatedId, // нужно уточнить у бека нужно ли тут точно calculatedId?
          });
        });

    result = uniqWith(
      result.filter((item: any) => item.filters.length),
      (a: any, b: any) =>
        (a.sourceFieldId || a.widgetCalculatedFieldId) ===
        (b.sourceFieldId || b.widgetCalculatedFieldId),
    );
  } else {
    filtersEntities.forEach((filterEl: any) => {
      // TODO Workaround BIDEV-3658 (2 из 2)
      // очищаем фильтры с типом данных число и строка у которых есть агрегация,
      // так как они не корректно считаются на сервере и могут отфильтровать все данные.
      const isNeedClearFilterWA =
        ['NUMBER', 'TEXT'].includes(getSimplifiedType(filterEl?.type)) &&
        Boolean(filterEl?.aggregation);

      const filters = filterEl.filter?.map((item: any) => ({
        operation: item.operation,
        value: [item.value],
      }));
      result.push({
        filters:
          (filterEl.id || filterEl.widgetFieldId) !== filterEntityId &&
          !isNeedClearFilterWA
            ? filters
            : [],
        sourceFieldId: filterEl.calculated
          ? null
          : filterEl.id || filterEl.widgetFieldId,
        widgetCalculatedFieldId: filterEl.calculated ? filterEl.id : undefined,
      });
    });
  }

  return result;
};

export const getValuesForWidgetMapManualBound = (
  widgetId: number,
  participantFilterWidgets: ParticipantFilterWidget[],
  mapData: { data: any; transcription: any } | null,
) => {
  if (!mapData) return { selectedMapFieldId: null, mapValues: [] };

  const selectedMapFieldId =
    participantFilterWidgets.find(
      (widget) => widget.isMaster && widget.widgetId === widgetId,
    )?.filterId || null;
  const mapValueName = mapData.transcription?.find(
    (item: any) => item.id === selectedMapFieldId,
  )?.name;
  const mapValues: string[] = compact(
    mapData.data?.map((item: any) => item[mapValueName]) || [],
  );

  return { selectedMapFieldId, mapValues };
};

export const getOrderNumberOfTypeFilter = (type: string): number => {
  switch (getSimplifiedType(type)) {
    case 'DATE':
      return 1;
    case 'TEXT':
      return 2;
    case 'NUMBER':
      return 3;
    default:
      return 10;
  }
};

export const getIconNameForFilterGroup = (type: string) => {
  switch (type) {
    case WidgetSimplifiedDataType.DATE: {
      return 'WidgetDataTimestamp';
    }
    case WidgetSimplifiedDataType.NUMBER: {
      return 'WidgetDataNumber';
    }
    case WidgetSimplifiedDataType.TEXT: {
      return 'WidgetDataString';
    }
    case WidgetSimplifiedDataType.BOOLEAN: {
      return 'BooleanTypeLogo';
    }
    default: {
      return 'WidgetDataString';
    }
  }
};

export const isNeedToAddWidgetOnDashboard = (
  dashboard: Dashboard,
  widgetId: number,
) => {
  return (
    !dashboard.drillDownWidgetIds.includes(widgetId) &&
    !dashboard.widgetIds.includes(widgetId)
  );
};

export const getDashboardWithCrossFilters = (
  dashboard: DashboardResponse,
  crossFilters: FilterGroupProps[],
) => {
  const crossFiltersList: FilterGroupProps[] = [...crossFilters];
  const dashboardFilters: FilterGroupProps[] = getPropertyValue(
    DashboardPropertyType.filters,
    dashboard.properties,
  );

  if (!crossFiltersList.length || !dashboardFilters?.length) return dashboard;

  const newFilters = [...dashboardFilters];
  dashboardFilters.forEach((filterGroup, index) => {
    const crossFilter = crossFiltersList.find(
      (crossFilterGroup) =>
        isEqualFilterGroupsByNameAndType(crossFilterGroup, filterGroup)
    );
    const isMandatoryFilter = Boolean(getFilterSettingValue(filterGroup, FilterProperty.isMandatoryFilter));
    const textFilterMode = getFilterSettingValue(filterGroup, FilterProperty.textFilterMode);

    const isGetOnlyTheFirst = textFilterMode === TextFilterModeSelect.SingleSelect;

    // Если есть кросс-фильтр и если фильтр обязателен, то не перетираем, если нечем (нет значения у кросс-фильтра)
    if (crossFilter && (!isMandatoryFilter || crossFilter.value.length)) {
      newFilters[index] = {
        ...newFilters[index],
        value: isGetOnlyTheFirst ? [crossFilter.value[0]].filter(Boolean) : crossFilter.value,
      };
    }
  });

  const filtersPropertyIndex = getPropertyIndex(
    DashboardPropertyType.filters,
    dashboard.properties,
  );
  const newProperties = [...dashboard.properties];
  newProperties[filtersPropertyIndex] = {
    ...newProperties[filtersPropertyIndex],
    value: JSON.stringify(newFilters),
  };

  return {
    ...dashboard,
    properties: newProperties,
  };
};


export const defineCrossFilters = (
  dashboardFilters: FilterGroupProps[],
  crossFilters: FilterGroupProps[] | null,
) => {

  const crossFiltersNotInDb: FilterGroupProps[] =  [];

  crossFilters && crossFilters.forEach(crossFilter => {
    if (!dashboardFilters.find((dashboardFilter) => isEqualFilterGroupsByNameAndType(crossFilter, dashboardFilter))) {
      crossFiltersNotInDb.push(crossFilter);
    }
  });

  const newCrossFilters = [...dashboardFilters];

  return [...newCrossFilters, ...crossFiltersNotInDb];
};

export const isEqualFilterGroupsByNameAndType = (firstGroup: FilterGroupProps, secondGroup: FilterGroupProps) => {
  const firstGroupAlias = firstGroup.alias || firstGroup.name;
  const secondGroupAlias = secondGroup.alias || secondGroup.name;
  const isEqualAliases = firstGroupAlias === secondGroupAlias;

  return firstGroup.type === secondGroup.type && (isEqualAliases || firstGroup.name === secondGroup.name);
};
