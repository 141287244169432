import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useClassButton } from '../../../hooks/useClassButton';
import { toggleCreateTablePanelAction } from '../../../slices/map-view/map-view';
import PanelContainer from '../panel-container/PanelContainer';
import { apiValidateNewTable } from '../../../services/sources';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import {
  connectionsActions,
  connectionsSelectors,
} from '../../../slices/connections/connections';
import Connection from '../../../types/connection';
import Notification from './notification';
import { State } from '../../../slices/types';
import CommonLoader from '../../common/common-loader/common-loader';
import { CustomProgress } from '../../../uikit/Progress';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    marginTop: 'auto',
  },
  leftSideButton: {
    marginRight: '15px',
  },
  textFieldWrapper: {
    marginBottom: '15px',
  },
});

interface FieldsValue {
  connectionId: string;
  schemaName: string;
  tableName: string;
}

const CreateTablePanel: FC = () => {
  const classes = useStyles();
  const classButton = useClassButton();
  const dispatch = useDispatch();
  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;

  const groupId: number = useSelector(
    (state: State) => state.mapConnection[loaderId]?.loaderGroupId,
  );
  const fullConnections = useSelector(
    connectionsSelectors.selectFullConnections,
  );
  const tableNameRef = useRef<HTMLInputElement>(null);
  const isSaving = useSelector((state: State) => state.createNewTable.isSaving);

  // на данный момент необходимо показывать только пострегсс подключения
  const selectOptions =
    fullConnections?.POSTGRESQL?.map(
      ({ login, host, port, id }: Connection.View.ItemFull) => ({
        label: `${login}@${host}:${port}`,
        value: id,
      }),
    ) ?? [];

  const [notificationData, setNotificationData] = useState<{
    isSuccess?: boolean;
    isWarning?: boolean;
    isError?: boolean;
    data?: object;
  }>({});
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(connectionsActions.getFullConnectionsAction(groupId));
  }, [dispatch]);

  const onCreateNewTable = async ({
    connectionId,
    schemaName,
    tableName,
  }: FieldsValue) => {
    const fullTableName = `${schemaName}.${tableName}`;
    setIsLoading(true);
    const response = await apiValidateNewTable(
      Number(connectionId),
      fullTableName,
    );
    setIsAlert(true);
    if (!response.isSuccess) {
      setNotificationData(response);
    }
    setIsLoading(false);
    const cb = () => {
      closePanel();
    };

    if (response.isSuccess) {
      dispatch(
        mapConnectionAction.addSourceObjectsByNewTablePanel({
          loaderId: Number(loaderId),
          connectionId: Number(connectionId),
          tableName: fullTableName,
          cb,
        }),
      );
    }
  };

  const closePanel = () => {
    dispatch(toggleCreateTablePanelAction(false));
  };

  const addExistingTable = () => {
    dispatch(
      mapConnectionAction.addSourceObjects({
        loaderId: Number(loaderId),
        loaderGroupId: 1, // TODO: Необходимо научиться получать по loaderId
        connectionId: Number(values.connectionId),
        tableNames: [`${values.schemaName}.${values.tableName}`],
        needPushDown: false,
      }),
    );
    closePanel();
  };

  const focusOnTableNameField = () => {
    if (tableNameRef.current !== null) {
      tableNameRef.current.focus();
    }
  };

  const { values, errors, handleChange, handleSubmit } = useFormik<FieldsValue>(
    {
      initialValues: {
        connectionId: '',
        schemaName: 'ods',
        tableName: '',
      },
      validationSchema: Yup.object({
        connectionId: Yup.string().required('Заполните обязательное поле'),
        schemaName: Yup.string().required('Заполните обязательное поле'),
        tableName: Yup.string().required('Заполните обязательное поле'),
      }),
      onSubmit: onCreateNewTable,
    },
  );

  return (
    <>
      <PanelContainer
        onClose={closePanel}
        title="Настройка правила сохранения результата"
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <form autoComplete="false" noValidate onSubmit={handleSubmit}>
            <Box>
              <Box className={classes.textFieldWrapper}>
                <TextField
                  select
                  label="Подключение"
                  name="connectionId"
                  value={values.connectionId}
                  error={Boolean(errors.connectionId)}
                  helperText={errors.connectionId}
                  onChange={handleChange}
                  variant="filled"
                  required
                  fullWidth
                >
                  {selectOptions.map(
                    (option: { value: string; label: string }) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Box>
              <Box className={classes.textFieldWrapper}>
                <TextField
                  label="Название схемы"
                  name="schemaName"
                  value={values.schemaName}
                  onChange={handleChange}
                  error={Boolean(errors.schemaName)}
                  helperText={errors.schemaName}
                  variant="filled"
                  required
                  fullWidth
                />
              </Box>
              <Box className={classes.textFieldWrapper}>
                <TextField
                  label="Название таблицы"
                  name="tableName"
                  error={Boolean(errors.tableName)}
                  helperText={errors.tableName}
                  value={values.tableName}
                  onChange={handleChange}
                  inputRef={tableNameRef}
                  variant="filled"
                  required
                  fullWidth
                />
              </Box>
              {isAlert && (
                <Box my={1}>
                  <Notification
                    notificationData={notificationData}
                    onAddExistingTable={addExistingTable}
                    onChangeField={focusOnTableNameField}
                  />
                </Box>
              )}
            </Box>
            <Box mt="auto">
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  className={`${classButton.primarySmall} ${classes.leftSideButton}`}
                  endIcon={
                    isLoading ? (
                      <CustomProgress type="circular" size={20} />
                    ) : (
                      <IconSvg svg={IconDictionary.Save} fill="var(--white)" />
                    )
                  }
                >
                  Cоздать таблицу
                </Button>
                <Button
                  disabled={isLoading}
                  className={`${classButton.defaultSmall} ${classes.leftSideButton}`}
                  type="reset"
                  onClick={closePanel}
                >
                  Отменить
                </Button>
              </div>
            </Box>
          </form>
        </Grid>
      </PanelContainer>
      {isSaving && <CommonLoader />}
    </>
  );
};

export default CreateTablePanel;
