import React from 'react';
import { Text } from '@visx/text';
import { Group } from '@visx/group';
import { axisProperties } from './properties';
import moment from 'moment';

export const GanttMonthRange = ({ monthRange, daysRange, canvasWidth }: any) => {
  return (
    <Group>
      {monthRange.map((monthData: any) => {
        const offset = monthData.prevDays * canvasWidth / daysRange.length;

        const width = monthData.daysCount * canvasWidth / daysRange.length;

        return (
          <Group>
            <rect
              y={axisProperties.monthLabelHeight - 1}
              x={offset}
              width={width}
              height={1}
              fill="var(--dark-cyan)"
            />

            <rect
              y={0}
              x={offset + width}
              width={1}
              height={axisProperties.monthLabelHeight}
              fill="var(--dark-cyan)"
            />

            <Text
              fill="var(--grey)"
              textAnchor="middle"
              verticalAnchor="middle"
              x={offset + width / 2}
              y={axisProperties.monthLabelHeight / 2}
              fontWeight="bold"
              fontSize="14px"
              fontFamily="Roboto"
            >
              {moment(monthData.date).format('MMMM YYYY')}
            </Text>
          </Group>
        );
      })}
      )
    </Group>
  );
};
