export type Color = string;

export enum ColorList {
  MEDIUM_MAGENTA = '#815FE3',
  AMBER = '#FCBF23',
  EMERALD = '#21D29B',
  BITTERSWEET = '#F86464',
  PALE_MAGENTA = '#FC86E2',
  CORNFLOWER_BLUE = '#408DD6',
  SANDYBROWN = '#EF9757',
  LAWNGREEN = '#87EF46',
  JADE = '#4ba359',
  PALE_BLUE = '#51CAEF',
  MILITARY_AIR_BLUE = '#658a9b',
  SILVER = '#D4D4D4',
}

export const defaultColors: Color[] = [
  ColorList.MEDIUM_MAGENTA,
  ColorList.AMBER,
  ColorList.EMERALD,
  ColorList.BITTERSWEET,
  ColorList.PALE_MAGENTA,
  ColorList.CORNFLOWER_BLUE,
  ColorList.SANDYBROWN,
  ColorList.LAWNGREEN,
  ColorList.JADE,
  ColorList.PALE_BLUE,
  ColorList.MILITARY_AIR_BLUE,
  ColorList.SILVER,
];
