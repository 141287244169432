import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../slices/types';
import { useNotificator } from '../../common/snackbar/hooks';
import { apiGetExportsTypes } from '../../../services/dashboardController';
import {
  DashboardExportActionType,
  useExportSendDashboard,
} from '../../../hooks/dashboard-preview/useExportSendDashboard';

export const useDashboardExport = ({ dashboardId }: { dashboardId: number }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dashboard = useSelector((state: State) => state.dashboard);
  const dashboardName = dashboard.name;

  const { showNotification } = useNotificator();

  const [exportTypesList, setExportTypesList] = useState<string[]>([]);
  const [isLoadingTypes, setIsLoadingTypes] = useState<boolean>(true);

  useEffect(() => {
    apiGetExportsTypes()
      .then((res) => {
        setExportTypesList(res);
      })
      .catch((error: any) => {
        showNotification({
          message: error.response?.data?.message || 'Ошибка при загрузке',
          variant: 'error',
        });
      })
      .finally(() => setIsLoadingTypes(false));
  }, [showNotification]);

  const { onExportClick, onSendClick, isDownloading, isSending } =
    useExportSendDashboard(dashboardId, dashboardName);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as any);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (type: string) => {
    setAnchorEl(null);
    onExportClick(type, DashboardExportActionType.DOWNLOAD);
  };

  const menuList = exportTypesList.map((type) => ({
    title: `Выгрузить ${type}`,
    onClick: () => handleDownload(type),
  }));

  return {
    onSendClick,
    isSending,
    handleClick,
    isDownloading,
    anchorEl,
    open,
    handleClose,
    menuList,
    isLoadingTypes,
  };
};
