import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { State } from '../../../slices/types';
import { Operation } from '../../../enums/operation';
import { toggleAdviceModeAction } from '../../../slices/map-view/map-view';
import IconSvg from '../../common/icon-svg/icon-svg';
import { FunctionalPanelProps } from './functional-panel';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

const useStyles = makeStyles({
  functionalPanel: {
    height: 'calc(48px * var(--scale-coefficient))',
    width: '100%',
    backgroundColor: 'var(--white)',
    marginBottom: '0px',
    border: '1px solid #edf1f8',
    borderRadius: 'var(--base-border-radius)',
    boxShadow: '0 2px 12px rgb(0 0 0 / 4%), 0 4px 10px rgb(58 130 228 / 6%)',
    zIndex: 5,
  },
  disabled: {
    opacity: 0.5,
  },
  backdrop: {
    color: 'var(--white)',
  },
  borderRight: {
    borderRight: '1px solid #DAE7ED',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,

    '&:not(:first-child)': {
      borderRadius: 0,
    }
  },
});

const defaultOperationsState = {
  [Operation.Join]: false,
  [Operation.Union]: false,
};
const operationNames = {
  [Operation.Join]: CommonDictionary.JoinOperation,
  [Operation.Union]: CommonDictionary.UnionOperation,
};
interface Operations {
  [key: string]: boolean;
}

export const useFunctionalPanel = ({
  active,
  setCurrentOperation,
}: FunctionalPanelProps) => {
  const dispatch = useDispatch();

  const adviceMode = useSelector((state: State) => state.mapView.adviceMode);
  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;

  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );

  const classes = useStyles();
  const [operationsState, setOperationsState] = useState<Operations>(
    defaultOperationsState,
  );

  const isActiveAndEditable = active && isEditableMap;

  const [nextTickAdviceMode, setNextTickAdviceMode] = useState(false);

  useEffect(() => {
    if (adviceMode) {
      setTimeout(setNextTickAdviceMode, 100, true);
    } else {
      setNextTickAdviceMode(false);
    }
  }, [adviceMode]);

  useEffect(() => {
    if (!adviceMode) {
      resetOperationsState();
    }
  }, [adviceMode]);
  const toggleOperationsState = (operation: Operation) => {
    setOperationsState((prevState) => ({
      ...prevState,
      [operation]: !prevState[operation],
    }));
  };
  const resetOperationsState = () => {
    setOperationsState(defaultOperationsState);
  };
  const handleOperationClick = (operation: Operation) => () => {
    dispatch(toggleAdviceModeAction(!adviceMode));
    setCurrentOperation(operation);
    toggleOperationsState(operation);
  };
  const handleClose = () => {
    dispatch(toggleAdviceModeAction(false));
  };

  useEffect(() => {
    return () => {
      dispatch(toggleAdviceModeAction(false));
    };
  }, [dispatch]);

  const operations = [
    {
      disabled: !isActiveAndEditable,
      startIcon: <IconSvg
        svg={IconDictionary.Join}
        fill={operationsState[Operation.Join] ? 'var(--white)' : 'var(--primary-color)'}
      />,
      className: operationsState[Operation.Join] ? '' : classes.borderRight,
      onClick: handleOperationClick(Operation.Join),
      variant: operationsState[Operation.Join] ? 'contained' : 'text',
      title: operationNames[Operation.Join],
    },
    {
      disabled: !isActiveAndEditable,
      startIcon: <IconSvg
        svg={IconDictionary.UnionOrFullJoin}
        fill={operationsState[Operation.Union] ? 'var(--white)' : 'var(--primary-color)'}
      />,
      className: operationsState[Operation.Union] ? '' : classes.borderRight,
      onClick: handleOperationClick(Operation.Union),
      variant: operationsState[Operation.Union] ? 'contained' : 'text',
      title: operationNames[Operation.Union],
    },
  ];
  
  return {
    classes,
    operations,
    loaderId,
    nextTickAdviceMode,
    handleClose,
  };
};
