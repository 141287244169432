import { Radio, RadioProps, styled } from '@material-ui/core';
import React from 'react';

const StyledRadio = styled(Radio)({
  paddingTop: 'calc(9px * var(--scale-coefficient))',
  paddingBottom: 'calc(9px * var(--scale-coefficient))',
  paddingRight: 'calc(9px * var(--scale-coefficient))',
  paddingLeft: 0,
  backgroundColor: 'inherit !important',

  '&[class*=Mui-checked]': {
    color: 'var(--primary-color)',
  }
});

export const CustomRadio = (props: RadioProps) => {
  return (<StyledRadio disableRipple color="primary" {...props} />);
};
