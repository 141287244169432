/* eslint-disable react/jsx-key */
import { TableBody as MUITableBody } from '@material-ui/core';
import React, { FC } from 'react';
import { TableInstance } from 'react-table';
import { VariableSizeList } from 'react-window';
import '../table-styles.css';
import clsx from 'clsx';
import { getScaleCoefficient } from '../../../../../../helpers/ui-helper';
import { useTableBody } from './useTableBody';

export type TableBodyProps = {
  tableInstance: TableInstance<any>;
  containerWidth: number;
  containerHeight: number;
};



export const TableBody: FC<TableBodyProps> = ({ tableInstance, containerWidth, containerHeight }) => {
  const {
    rowsList,
    headerHeight,
    rows,
    rowTotal,
    rowHeight,
    width,
    containerOffset,
    Row,
    deselectData,
    isActiveFilter
  } = useTableBody({ tableInstance, containerWidth, containerHeight });

  const totalBlockScrollBarOffsetValue = 16;
  const totalRowHeight = (rowHeight * getScaleCoefficient() + totalBlockScrollBarOffsetValue);

  return (
    <>
      <MUITableBody onClick={deselectData} className={clsx('widget-table__table-body', { 'active-drilldown-element': isActiveFilter })}>
        <VariableSizeList
          className="table-body-list screenshot-overflow-y"
          ref={rowsList}
          height={containerHeight - headerHeight - (rowTotal ? totalRowHeight : 0)}
          itemCount={rows.length}
          itemSize={() => rowHeight}
          width={width - containerOffset}
          style={{ overflowX: 'hidden' }}
        >
          {Row}
        </VariableSizeList>
      </MUITableBody>
      {rowTotal && <Row isTotal index={rowTotal.rowIndex} style={{ height: totalRowHeight, overflowY: 'scroll' }} />}
    </>
  );
};
