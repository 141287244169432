import React from 'react';
import * as allCurves from '@visx/curve';
import { LinePath } from '@visx/shape';
import { LineCircle } from './line-circle';
import { FilterField } from '../../../dashboard-page/hooks';
import { useGraphProperties } from '../graph/hooks/useGraphProperties';

interface LinePathProps {
  circleParams: any;
  color: string;
  getX: (i: any) => number;
  getY: (i: any) => number;
  data: any;
  isSmoothed: boolean;
  widgetTooltipGraphProps: any;
  onSelectPoint: (index: number) => void;
  widgetProperties: any[];
  boundFilterField: FilterField;
  isActiveFilter: boolean;
  isNeedToDisplayValuesOnGraph?: boolean;
}

const LIMIT_FOR_CIRCLES = 300;

export const LinePathRenderer = ({
  circleParams,
  color,
  getX,
  getY,
  data,
  isSmoothed,
  widgetTooltipGraphProps,
  onSelectPoint,
  widgetProperties,
  boundFilterField,
  isActiveFilter,
  isNeedToDisplayValuesOnGraph = false,
}: LinePathProps) => {
  const {
    isNeedToDisplayCircles
  } = useGraphProperties({ widgetProperties });

  const isLineWithCircle = isNeedToDisplayCircles && data.length < LIMIT_FOR_CIRCLES;

  return (
    <>
      <LinePath
        curve={isSmoothed ? allCurves.curveMonotoneX : allCurves.curveLinear}
        data={data}
        x={getX}
        y={getY}
        stroke={color}
        strokeWidth={2}
        strokeOpacity={0.8}
        shapeRendering="geometricPrecision"
        markerMid="url(#marker-circle)"
        style={{ pointerEvents: 'none' }}
      />

      {data.map((item: any, index: number) => (
        <LineCircle
          isLineWithCircle={isLineWithCircle}
          key={`line-circle-${index}`}
          isSelected={
            boundFilterField?.value === null ||
            boundFilterField?.value === undefined ||
            boundFilterField?.value?.includes(data[index]?.x)
          }
          color={color}
          index={index}
          value={item}
          cx={getX(item)}
          cy={getY(item)}
          circleParams={circleParams}
          widgetTooltipGraphProps={widgetTooltipGraphProps}
          onSelectPoint={onSelectPoint}
          widgetProperties={widgetProperties}
          isActiveFilter={isActiveFilter}
          isNeedToDisplayValuesOnGraph={isNeedToDisplayValuesOnGraph}
        />
      ))}
    </>
  );
};
