import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectFieldsPanel } from '../types';

const initialState: SelectFieldsPanel = {
  isSaving: false,
};

const selectFieldsPanelSlice = createSlice({
  name: 'selectFieldsPanel',
  initialState,
  reducers: {
    toggleSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.isSaving = payload;
    },
  },
});

export const selectFieldsPanelReducer = selectFieldsPanelSlice.reducer;

export const {
  toggleSaving: toggleSavingSelectFieldsAction,
} = selectFieldsPanelSlice.actions;
