import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { State } from '../../../slices/types';
import { isDashboardPreviewPage } from '../../widget-page/charts/helpers';
import { setDashboardPropAction } from '../../../slices/dashboard/dashboard';
import { Filter, FilterGroupProps } from './index';
import { getPropertyValue } from '../../../helpers/dashboard-page';
import { getFilterSettingValue } from '../../../helpers/dashboard-page/filters-helper';
import {
  DashboardPropertyType,
  FilterProperty,
} from '../../../enums/dashboard-properties';

export const useDashboardFiltersInHead = () => {
  const dispatch = useDispatch();

  const properties = useSelector((state: State) => state.dashboard.properties);
  const values: FilterGroupProps[] = getPropertyValue<FilterGroupProps[]>(
    DashboardPropertyType.filters,
    properties,
  );


  const headerFilterIndex = values.findIndex((filter) => {
    const isVisible = isDashboardPreviewPage() ? getFilterSettingValue(filter, FilterProperty.isVisible) : true;
    return getFilterSettingValue(filter, FilterProperty.isDisplayedOnTop) && filter.actual && isVisible;
  });
  const headerDateFilter: FilterGroupProps | undefined =
    values[headerFilterIndex];

  const setVisibleHeaderDateFilters = useCallback(
    (id: number) => {
      const newFilters: FilterGroupProps[] = values.map((filter) => ({
        ...filter,
        isDisplayedOnTop: filter.id === id,
      }));

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(newFilters),
        }),
      );
    },
    [dispatch, values],
  );

  const changeHeaderDateFilterOperation = useCallback(
    (filters: Filter[], selectDateType?: string, overriddenFunction?: string) => {
      const newValues = [ ...values ];

      if (newValues[headerFilterIndex]) {
        newValues[headerFilterIndex].value = filters;
        const overriddenFunctionSettingIndex = newValues[headerFilterIndex].filterProperties.findIndex(
          (setting) => setting.name === FilterProperty.overriddenFunction,
        );
        newValues[headerFilterIndex].filterProperties[overriddenFunctionSettingIndex] = {
          ...newValues[headerFilterIndex].filterProperties[overriddenFunctionSettingIndex],
          value: overriddenFunction || null,
        };

        const selectDateTypeSettingIndex = newValues[headerFilterIndex].filterProperties.findIndex(
          (setting) => setting.name === FilterProperty.selectDateType,
        );
        newValues[headerFilterIndex].filterProperties[selectDateTypeSettingIndex] = {
          ...newValues[headerFilterIndex].filterProperties[selectDateTypeSettingIndex],
          value: selectDateType || '',
        };
      } else {
        throw new Error(
          `Filter with index "${headerFilterIndex}" not found on dashboard's properties`,
        );
      }

      dispatch(
        setDashboardPropAction({
          name: DashboardPropertyType.filters,
          value: JSON.stringify(newValues),
        }),
      );
    },
    [dispatch, values, headerFilterIndex],
  );

  return {
    setVisibleHeaderDateFilters,
    headerDateFilter,
    changeHeaderDateFilterOperation,
  };
};
