import block from 'bem-cn';
import React from 'react';

import MapConnection from 'src/types/map-connection';
import SourceFieldItem from './source-field-item/source-field-item';

import './fields-section.css';
import { CustomProgress } from '../../../../../uikit/Progress';

interface FieldsSectionProps {
  isLoading: boolean;
  filteredSourceObjectFieldsList: any[];
  sourceObjectProcessedFields: MapConnection.ProcessedField[]
  handleTick: (name: string) => () => void;
  handleRename: (id: string) => (newName: string) => void;
  openTransformationPanel: (id: any) => () => void;
  editComplexField: (sourceFieldId: any) => () => void;
  deleteSourceField: (sourceFieldId: string) => () => void;
  joinKeyNames: string[];
  isEditableMap: boolean;
}

const FieldsSection = ({
  isLoading,
  filteredSourceObjectFieldsList,
  sourceObjectProcessedFields,
  handleTick,
  handleRename,
  openTransformationPanel,
  editComplexField,
  joinKeyNames,
  deleteSourceField,
  isEditableMap,
}: FieldsSectionProps) => {
  const b = block('select-fields-panel');
  const sourceObjectProcessedFieldIds = sourceObjectProcessedFields.map((field) => field?.id);

  return (
    <div className={b('fields-section')}>
      {!isLoading ? (
        filteredSourceObjectFieldsList.map((el: any) => {
          const isHaveAllOperandsFieldsInSchema = el.transformationOperands.every((operandId: string) => sourceObjectProcessedFieldIds.includes(operandId));
          const isMultiplyAlias = filteredSourceObjectFieldsList
            .filter(elem => elem.alias === el.alias ).length > 1;

          const isBrokenField: boolean =
            (!el?.schemaName
              && !isHaveAllOperandsFieldsInSchema)
            || isMultiplyAlias;
          return <SourceFieldItem
            id={el.id}
            key={el.id}
            name={el.displayedName}
            alias={el.alias}
            type={el.displayedType}
            checked={el.selected}
            onTick={handleTick(el.id)}
            onRename={handleRename(el.id)}
            category={el.category}
            deleteSourceField={deleteSourceField}
            openTransformationPanel={openTransformationPanel}
            editComplexField={editComplexField}
            joinKeyNames={joinKeyNames}
            isBrokenField={isBrokenField}
            isEditableMap={isEditableMap}
            hasError={el.hasError}
          />;
        })
      ) : (
        <CustomProgress type="linear" />
      )}
    </div>
  );
};

export default FieldsSection;
