import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import MUITable from '@material-ui/core/Table';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce/lib';
import { DndContext } from '@dnd-kit/core';
import { Box } from '@material-ui/core';
import {
  useContainerResizeObserver,
} from '../../../../../../lib/resize-observer/adapters/useContainerResizeObserver';
import { TableHead } from '../table-head';
import { TableBody } from '../table-body';
import { useColumnsGenerator } from '../../libs/hooks/useColumnsGenerator';
import { setWidgetPropAction } from '../../../../../../slices/widget/widget';
import { useTableInstanse } from '../../libs/hooks/useTableInstanse';
import { NoDataTemplate } from '../../../../../common/no-widget-data-template';
import { useNestedHeaders } from '../../libs/hooks/useNestedHeaders';
import { ComplexTableContext } from '../..';
import { isDashboardPage } from '../../../helpers';
import { PanelType } from '../../../../../../enums/widget-type';
import { getParsedAxisValues } from '../../../../dropdown-layout/helpers/helpers';
import '../table-styles.css';
import { CustomProgress } from '../../../../../../uikit/Progress';

const DEBOUNCE_TIME = 500;
const SMALL_DEBOUNCE_TIME = 100;

export const TableRoot = () => {
  const {
    widgetState,
    widgetError,
    widgetProperties,
    isEnabledResizing,
    isLoadingData,
    hierarchyInHeader,
    isScaleWidgetOnDashboard,
    hiddenColumns,
  } = useContext(ComplexTableContext);

  const containerRef = useRef(document.createElement('div'));

  const { containerWidth, containerHeight } = useContainerResizeObserver(containerRef, SMALL_DEBOUNCE_TIME);

  const isDashboard = isDashboardPage();

  const axisX = useMemo(
    () => getParsedAxisValues(PanelType.axisX, widgetProperties),
    [widgetProperties],
  );

  const { setHeaderParent } = useNestedHeaders();

  const handleDragCancel = () => {
    setDestinationId('');
    setTargetId('');
  };

  const handleDragEnd = (event: any) => {
    if (!event?.active?.data || !event?.over?.data) {
      handleDragCancel();
      return;
    }

    handleDragCancel();
    setHeaderParent(event.active.data.current.id, event.over.data.current.id);
  };

  const handleDragMove = (event: any) => {
    setDestinationId(event.over?.data?.current?.id || '');
    setTargetId(event.active?.data?.current?.id || '');
  };

  const columnsGenerator = useColumnsGenerator(axisX, hiddenColumns);

  const { columns, tableState } = columnsGenerator;

  const data = useMemo(
    () =>
      Array.isArray(widgetState.data?.data) ? widgetState.data?.data || [] : [],
    [JSON.stringify(widgetState)],
  );

  const { setDestinationId, setTargetId } = useNestedHeaders();

  const tableInstance = useTableInstanse({
    columns,
    data,
    isScaleWidgetOnDashboard,
    controlledTableState: tableState,
  });

  const { getTableProps, state } = tableInstance;

  const dispatch = useDispatch();

  useEffect(() => {
    saveTableState();
  }, [JSON.stringify(state)]);

  const { callback: saveTableState } = useDebouncedCallback(() => {
    dispatch(
      setWidgetPropAction({
        name: 'tableState',
        value: JSON.stringify(state),
      }),
    );
  }, DEBOUNCE_TIME);

  if (!data.length && isLoadingData) {
    return null;
  }

  return (
    <div className="widget-table__container" ref={containerRef}>
      <div className="widget-table__full-height screenshot-overflow-x">
        <DndContext
          onDragEnd={isDashboard ? undefined : handleDragEnd}
          onDragCancel={isDashboard ? undefined : handleDragCancel}
          onDragOver={isDashboard ? undefined : handleDragMove}
          onDragStart={isDashboard ? undefined : handleDragMove}
        >
          <MUITable {...getTableProps()} className="widget-table__table">
            <TableHead
              tableInstance={tableInstance}
              isEnabledResizing={isEnabledResizing}
              isNeedToDisplayHierarchyInHeader={hierarchyInHeader}
            />
            {!data.length ? (
              <Box className="widget-table__center-container">
                <NoDataTemplate
                  withLinkToEditor={false}
                  errorText={widgetError}
                />
              </Box>
            ) : (
              <TableBody
                tableInstance={tableInstance}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
              />
            )}
          </MUITable>
        </DndContext>
      </div>
    </div>
  );
};
