import L, { DomEvent } from 'leaflet';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

type EventHandlerFn = DomEvent.EventHandlerFn;

// @ts-ignore
L.Control.Zoom = L.Control.extend({
  options: {
    zoomInText: 'MapZoomIn',
    zoomOutText: 'MapZoomOut',
    zoomInTitle: 'Приблизить',
    zoomOutTitle: 'Отдалить',
  },

  onAdd(map: L.Map) {
    const zoomName = 'leaflet-control-zoom';
    const container = L.DomUtil.create('div', `${zoomName} leaflet-bar`);
    const { options } = this;
    // @ts-ignore
    this._zoomInButton = this._createButton(
      options.zoomInText,
      options.zoomInTitle,
      `${zoomName}-in`,
      container, // @ts-ignore
      this._zoomIn,
    );
    // @ts-ignore
    this._zoomOutButton = this._createButton(
      options.zoomOutText,
      options.zoomOutTitle,
      `${zoomName}-out`,
      container, // @ts-ignore
      this._zoomOut,
    );
    return container;
  },

  // @ts-ignore
  _zoomIn(e: any) {
    // @ts-ignore
    if (!this._disabled) {
      // @ts-ignore
      this._map.zoomIn(e.shiftKey ? 3 : 1);
    }
  },

  // @ts-ignore
  _zoomOut(e: any) {
    // @ts-ignore
    if (!this._disabled) {
      // @ts-ignore
      this._map.zoomOut(e.shiftKey ? 3 : 1);
    }
  },

  // @ts-ignore
  _createButton(
    iconName: keyof typeof IconDictionary,
    title: string,
    className: string,
    container: HTMLElement | undefined,
    fn: EventHandlerFn,
  ) {
    const link = L.DomUtil.create('a', className, container);

    const parentNode = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg',
    );
    parentNode.setAttributeNS(null, 'fill', 'var(--dark-grey)');
    parentNode.setAttributeNS(null, 'width', '24');
    parentNode.setAttributeNS(null, 'height', '24');
    parentNode.setAttributeNS(null, 'viewBox', '0 0 24 24');

    const parser = new DOMParser();
    const doc = parser.parseFromString(
      IconDictionary[iconName],
      'image/svg+xml',
    );
    const pathTags = doc.getElementsByTagName('path');
    for (const path of pathTags) {
      const node = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
      );
      node.setAttributeNS(null, 'd', path.getAttribute('d') || '');
      parentNode.appendChild(node);
    }

    link.appendChild(parentNode);
    link.href = '#';
    link.title = title;
    L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
      .on(link, 'click', L.DomEvent.stop)
      .on(link, 'click', fn, this) // @ts-ignore
      .on(link, 'click', this._refocusOnMap, this);
    return link;
  },
});
