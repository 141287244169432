import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import useErrorBoundary from 'use-error-boundary';
import { ErrorPage } from '../pages/error-page/error-page';
import { State } from '../slices/types';

export const ErrorsHandler: FC<{ children: JSX.Element }> = ({
  children,
}) => {

  const { ErrorBoundary } = useErrorBoundary();

  const isHaveError = Boolean(
    useSelector((state: State) => state.errorPage.code),
  );

  return isHaveError ? (
    <ErrorPage />
  ) : (
    <ErrorBoundary renderError={() => <ErrorPage />}>{children}</ErrorBoundary>
  );
};
