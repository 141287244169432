import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { DropResult } from 'react-beautiful-dnd';
import { useUpdateDataAfterChangeLoaderStatus } from '../../../hooks/useUpdateDataAfterChangeLoaderStatus';
import { selectProjectParameters } from '../../../slices/parameters/parameters';
import { State } from '../../../slices/types';
import { usePanelsValues } from './hooks';
import { useNotificator } from '../../common/snackbar/hooks';
import { createDataFetcher } from './utils';
import { PanelType, WidgetType } from '../../../enums/widget-type';
import { apiFetchSourceData } from '../../../services/widgetController';
import { isFilledRequiredAxes } from '../helpers';
import {
  setWidgetPropsAction,
  setWidgetSourceIdAction,
} from '../../../slices/widget/widget';
import { setDragIsActiveAction } from '../../../slices/drag-and-drop/drag-and-drop';
import {
  getErrorOfAddingFieldToPanel,
  getIsNeedSyncFiltersAxis,
  getSynchronizedFilters,
} from './helpers/helpers';
import { setMapFields } from './helpers/map-helper';
import { getPropertiesForItemSet } from './helpers/itemset-helpers';
import { useRequestCanceller } from '../../../hooks/useRequestCanceller';
import {
  callEffectWidgetEditUpdateDataAction,
  setWasClickOnPushdownCheckboxAction
} from '../../../slices/main-page/main-page-slice';

interface PanelDropResult extends DropResult {
  draggableId: string;
  etlSourceId?: string;
}

export const useDropdownLayout = () => {
  const projectId: number | undefined =
    Number(useParams<{ projectId: string }>()?.projectId) ?? undefined;

  const wasClickOnPushdownCheckbox = useSelector((state: State) => state.mainPage.wasClickOnPushdownCheckbox);
  // изменяется чтобы сработал эффект обновления данных
  const effectWidgetEditUpdateData = useSelector((state: State) => state.mainPage.effectWidgetEditUpdateData);
  const widget = useSelector((state: State) => state.widget);
  const widgetProperties = widget.properties;

  const projectParameters = useSelector(selectProjectParameters);

  const dispatch = useDispatch();
  const {
    axisXCount,
    axisYCount,
    nameStageCount,
    dateEndStageCount,
    axisZCount,
  } = usePanelsValues({
    widgetProperties,
  });

  const dependencies = usePanelsValues({
    widgetProperties,
  });

  const dependenciesJSON = JSON.stringify(dependencies);

  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [currentWidgetType, setWidgetType] = useState('');

  const { cancellableController, callCancellable } = useRequestCanceller();

  const { showNotification } = useNotificator();

  const requestWidgetData = createDataFetcher({
    dispatch,
    widget: { ...widget, projectId },
    showNotification,
    callCancellable,
    requestId: uuid()
  });

  useEffect(() => {
    setWidgetType(widget.type);
  }, [widget.type]);

  useEffect(() => {
    if (!isReady) return;

    const cancelSource = axios.CancelToken.source();

    const fetchData = async () => {
      const isFilledRequiredWidgetAxes = isFilledRequiredAxes(widget.type, {
        axisXCount,
        axisYCount,
        axisZCount,
        nameStageCount,
        dateEndStageCount,
      });
      if (!isFilledRequiredWidgetAxes) return;

      if (wasClickOnPushdownCheckbox) {
        dispatch(setWasClickOnPushdownCheckboxAction(false));
      }

      const { promise } = requestWidgetData({
        isNeedReset: !isFilledRequiredWidgetAxes,
        cancelSource,
      });

      setIsLoading(true);

      promise
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setIsLoading(false);
          }

        });
    };
    fetchData().then();

    return () => {
      cancellableController.cancelAll();
    };
  }, [dependenciesJSON, widget.type, isReady, effectWidgetEditUpdateData, cancellableController, widget.pushDown, projectParameters]);

  const callbackRefreshData = useCallback(() => {
    setIsReady(true);
    dispatch(callEffectWidgetEditUpdateDataAction());
  }, [dispatch]);

  useUpdateDataAfterChangeLoaderStatus('DropdownLayout', callbackRefreshData);

  const onDragStart = () => {
    dispatch(setDragIsActiveAction(true));
  };

  const handleDragEndForMap = useCallback(async (result: PanelDropResult) => {
    dispatch(setWidgetSourceIdAction(JSON.parse(result.draggableId).id));

    const sourceData = await apiFetchSourceData(
      JSON.parse(result.draggableId).id,
    );

    setMapFields(dispatch, sourceData, result, widgetProperties);
  }, [dispatch, widgetProperties]);

  const onDragEnd = useCallback(
    async (result: PanelDropResult) => {
      dispatch(setDragIsActiveAction(false));

      const { destination } = result;
      if (!destination) return;

      const isDropDestinationCorrect = destination.droppableId.includes('widgetDroppable');

      if (isDropDestinationCorrect) {
        const properties = [...widgetProperties] || [];

        const isMapWidget = currentWidgetType === WidgetType.MAP;

        const property = isMapWidget ? result : JSON.parse(result.draggableId);
        const panelType = destination.droppableId.split('-').pop() as PanelType;

        const errorMessage: string = getErrorOfAddingFieldToPanel(
          widgetProperties,
          panelType,
          property,
          widget.type,
        );
        if (errorMessage.length) {
          showNotification({
            message: errorMessage,
            variant: 'warning',
          });
          return;
        }

        if (isMapWidget) {
          await handleDragEndForMap(result);
        } else {
          const newProperties = getPropertiesForItemSet({
            panelType,
            property,
            properties,
            widget,
            etlSourceId: widget.sourceId,
            showNotification,
          });

          const isNeedSyncFiltersAxis = getIsNeedSyncFiltersAxis({
            widgetType: widget.type as WidgetType,
            panelType: panelType as PanelType,
          });

          if (isNeedSyncFiltersAxis) {
            const newPropertiesWithFilters =
              getSynchronizedFilters(newProperties, widget.type as WidgetType);

            dispatch(setWidgetPropsAction(newPropertiesWithFilters));
          } else {
            dispatch(setWidgetPropsAction(newProperties));
          }
        }
      }
    },
    [currentWidgetType, dispatch, handleDragEndForMap, showNotification, widget, JSON.stringify(widgetProperties)],
  );

  return {
    onDragStart,
    onDragEnd,
    isLoading,
    setIsReady,
  };
};
