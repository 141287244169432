import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { values } from 'lodash';
import {
  Dashboard,
  DashboardProperty, DashboardResponse,
  State,
  Widget,
  WidgetAPI,
} from '../types';
import { dashboardSettignsDictionary } from '../../dictionaries/dashboard-settings';
import { DashboardDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';
import { FilterGroupProps } from '../../components/dashboard-page/hooks';

const dashboardInitialPropertiesFromDictionary =
  dashboardSettignsDictionary.map((config) => ({
    name: config.name,
    value: config.defaultValue.toString(),
  }));

const initialState: Dashboard = {
  properties: [...dashboardInitialPropertiesFromDictionary],
  widgetIds: [],
  drillDownWidgetIds: [],
  widgets: {},
  name: DashboardDictionary.title,
  projectId: 0,
  isOpenedFilters: false,
  openedFiltersSetting: '',
  currentWidgetIdFullScreen: null,
  widgetsWithActiveLegend: [],
  validationErrors: null,
  crossFilters: null,
  initPreviewFilters: [],
  shared: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCurrentDashboard: (
      state,
      { payload }: PayloadAction<DashboardResponse | ''>,
    ) => {
      if (!payload) {
        return initialState;
      }

      return {
        ...state,
        ...payload,
        responseAPI: payload,
      };
    },

    openDashboardFilters: (state) => {
      state.isOpenedFilters = !state.isOpenedFilters;
    },

    setDashboardName: (state, { payload }) => {
      state.name = payload.name;
    },
    setDashboardValidationError: (
      state,
      { payload }: PayloadAction<{ key: string; value: string }>,
    ) => {
      if (state.validationErrors) {
        state.validationErrors[payload.key] = payload.value;
      } else {
        state.validationErrors = {
          [payload.key]: payload.value,
        };
      }
    },
    resetDashboardValidationError: (
      state,
      { payload }: PayloadAction<{ key: string }>,
    ) => {
      if (state.validationErrors && state.validationErrors[payload.key]) {
        delete state.validationErrors[payload.key];
      }
    },
    resetDashboardValidationErrors: (state) => {
      state.validationErrors = null;
    },

    resetDashboardWidgets: (state) => {
      state.widgets = {};
    },

    closeDashboardFilters: (state) => {
      state.isOpenedFilters = false;
    },

    openDashboardFiltersSetting: (state, { payload }) => {
      state.openedFiltersSetting = payload.name;
    },

    closeDashboardFiltersSetting: (state) => {
      state.openedFiltersSetting = '';
    },
    setDashboardProp: (
      state,
      { payload }: PayloadAction<DashboardProperty>,
    ) => {
      const existingIndex = state.properties.findIndex(
        (property) => property.name === payload.name,
      );
      if (existingIndex !== -1) {
        state.properties[existingIndex] = { ...state.properties[existingIndex], ...payload };
      } else {
        const properties = state.properties || [];
        state.properties = [...properties, { ...payload }];
      }
    },
    setWidgetIdToDashboard: (state, { payload }: PayloadAction<number>) => {
      const widgetsIdsList = [...state.widgetIds];
      widgetsIdsList.push(payload);
      state.widgetIds = [...widgetsIdsList];
    },
    deleteWidgetIdFromDashboard: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      const widgetsIdsList = [...state.widgetIds];

      const idIndex = widgetsIdsList.findIndex(
        (item: any) => item === Number(payload),
      );

      widgetsIdsList.splice(idIndex, 1);

      state.widgetIds = [...widgetsIdsList];
    },
    setWidgetAPIToDashboard: (
      state,
      {
        payload,
      }: { payload: { widgetId: string; params: Partial<WidgetAPI> } },
    ) => {
      const { widgetId, params } = payload;

      if (!state.widgets) {
        state.widgets = {};
      }

      if (!state.widgets[widgetId]) {
        state.widgets[widgetId] = {
          data: {} as Widget,
          loading: false,
          dirty: false,
          error: null,
          dataProperties: [],
          ...params,
        };
      } else {
        state.widgets[widgetId] = {
          ...state.widgets[widgetId],
          ...params,
        };
      }
    },

    setCurrentWidgetIdFullScreen: (state, { payload }) => {
      state.currentWidgetIdFullScreen = payload;
    },

    setWidgetsIdWithActiveLegend: (state, { payload }) => {
      state.widgetsWithActiveLegend = payload;
    },
    setCrossFilters: (state, { payload }: PayloadAction<FilterGroupProps[] | null>) => {
      state.crossFilters = payload;
    },
    setInitPreviewFilters: (state, { payload }: PayloadAction<FilterGroupProps[]>) => {
      state.initPreviewFilters = payload;
    },
    setResponseAPI: (state, { payload }: PayloadAction<DashboardResponse>) => {
      state.responseAPI = payload;
    },
  },
});

export const dashboardReducer = dashboardSlice.reducer;

export const dashboardWidgetDataSelector =
  (widgetId: string) =>
  (state: State): Widget => {
    return state.dashboard.widgets[widgetId]?.data || {};
  };
export const dashboardWidgetDataPropertiesSelector =
  (widgetId: string) => (state: State) => {
    return state.dashboard.widgets[widgetId]?.dataProperties || [];
  };
export const dashboardWidgetLoadingSelector =
  (widgetId: string) =>
  (state: State): boolean => {
    return state.dashboard.widgets[widgetId]?.loading || false;
  };
export const dashboardWidgetDirtySelector =
  (widgetId: string) =>
  (state: State): boolean => {
    return state.dashboard.widgets[widgetId]?.dirty || false;
  };
export const dashboardWidgetErrorSelector =
  (widgetId: string) =>
  (state: State): string | null => {
    return state.dashboard.widgets[widgetId]?.error || null;
  };
export const dashboardWidgetsSelector = (state: State): Widget[] => {
  return (
    values(state.dashboard.widgets)
      .filter((widget) => state.dashboard.widgetIds.includes(widget.data.id))
      .map((widgetApi) => widgetApi.data) || []
  );
};
export const dashboardCacheWidgetsSelector = (
  state: State,
): Record<string, WidgetAPI> => state.dashboard.widgets;

export const {
  setCurrentDashboard: setCurrentDashboardAction,
  setDashboardProp: setDashboardPropAction,
  setWidgetIdToDashboard: setWidgetIdToDashboardAction,
  setWidgetAPIToDashboard,
  deleteWidgetIdFromDashboard: deleteWidgetIdFromDashboardAction,
  openDashboardFilters: openDashboardFiltersAction,
  closeDashboardFilters: closeDashboardFiltersAction,
  openDashboardFiltersSetting: openDashboardFiltersSettingAction,
  closeDashboardFiltersSetting: closeDashboardFiltersSettingAction,
  setDashboardName: setDashboardNameAction,
  setCurrentWidgetIdFullScreen: setCurrentWidgetIdFullScreenAction,
  setWidgetsIdWithActiveLegend: setWidgetsIdWithActiveLegendAction,
  setDashboardValidationError: setDashboardValidationErrorAction,
  resetDashboardValidationErrors: resetDashboardValidationErrorsAction,
  resetDashboardWidgets: resetDashboardWidgetsAction,
  resetDashboardValidationError: resetDashboardValidationErrorAction,
  setCrossFilters: setCrossFiltersAction,
  setInitPreviewFilters: setInitPreviewFiltersAction,
} = dashboardSlice.actions;
