import React, { Dispatch, useState } from 'react';
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FileTypesEnum } from '../../../enums/fyle-type';
import IconSvg from '../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  item: {
    wordBreak: 'break-all',
  },
});

interface Props {
  fileTypeName: FileTypesEnum;
  files: string[] | null;
  previewFile: string | null;
  setPreviewFile: Dispatch<string | null>;
  setCheckedLists: Dispatch<string[]>;
  isNewFile: boolean;
}

const FileList: React.FC<Props> = ({
  fileTypeName,
  files,
  previewFile,
  setPreviewFile,
  setCheckedLists,
  isNewFile,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState<string[]>([]);

  const handleToggle = (file: string) => () => {
    const currentIndex = checked.indexOf(file);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(file);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setCheckedLists(newChecked);
  };

  const handlePreviewClick = (name: string) => () => {
    setPreviewFile(name);
  };

  const handleListItemClick = (file: string) =>
    isNewFile ? handleToggle(file) : () => {};

  if (files) {
    switch (fileTypeName) {
      case FileTypesEnum.CSV:
        return (
          <div>
            <h3>Предпросмотр файла</h3>
            <List dense>
              {files.map((file) => (
                <ListItem key={file}>
                  <ListItemText className={classes.item} primary={file} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="preview"
                      onClick={handlePreviewClick(file)}
                    >
                      <IconSvg svg={IconDictionary.OpenFile} fill={file === previewFile ? 'var(--primary-color)' : 'var(--dark-grey)'} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        );

      case FileTypesEnum.EXCEL:
        return (
          <div>
            <h3>Список листов</h3>
            <List dense>
              {files.map((file) => (
                <ListItem key={file} button onClick={handleListItemClick(file)}>
                  {isNewFile ? (
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.includes(file)}
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                      />
                    </ListItemIcon>
                  ) : null}
                  <ListItemText className={classes.item} primary={file} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="preview"
                      onClick={handlePreviewClick(file)}
                    >
                      <IconSvg svg={IconDictionary.OpenFile} fill={file === previewFile ? 'var(--primary-color)' : 'var(--dark-grey)'} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        );

      default:
        return (
          <div>
            <h3>Предпросмотр файла</h3>
            <List dense>
              {files.map((file) => (
                <ListItem key={file}>
                  <ListItemText className={classes.item} primary={file} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="preview"
                      onClick={handlePreviewClick(file)}
                    >
                      <IconSvg svg={IconDictionary.OpenFile} fill={file === previewFile ? 'var(--primary-color)' : 'var(--dark-grey)'} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        );
    }
  }

  return null;
};

export default FileList;
