import React from 'react';
import IconSvg from '../../../../../common/icon-svg/icon-svg';
import { useStyles } from './styles';
import { CustomButton } from '../../../../../../uikit/Button';
import { IconDictionary } from '../../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface ButtonsSectionProps {
  onClose: () => void;
  handleSaveButtonClick: () => void;
  handleAddConnection: () => void;
  handleValidationButtonClick: () => void;
  isLoading?: boolean;
  isEditableMap: boolean;
}

const ButtonsSection: React.FC<ButtonsSectionProps> = ({
  onClose,
  handleSaveButtonClick,
  handleAddConnection,
  handleValidationButtonClick,
  isLoading = false,
  isEditableMap
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.buttons} ${isLoading ? classes.disabled : ''} dialog-buttons dialog-buttons_in-form`}>
      <div className={classes['left-side']}>
        <CustomButton
          className={!isEditableMap ? classes.disabled : ''}
          variant="contained"
          onClick={handleSaveButtonClick}
          loading={isLoading}
        >
          Сохранить
        </CustomButton>
        <CustomButton
          type="reset"
          onClick={onClose}
          variant="outlined"
        >
          Отменить
        </CustomButton>
      </div>
      <div className={classes['right-side']}>
        {isEditableMap ? <CustomButton
          size="medium"
          variant="outlined"
          icon={<IconSvg svg={IconDictionary.AddConnection} width={24} height={24} fill="var(--dark-grey)" />}
          onClick={handleAddConnection}
        /> : null}
      </div>
    </div>
  );
};

export default ButtonsSection;
