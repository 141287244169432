import { scaleLinear } from '@visx/scale';
import { max, min } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import { useMemo } from 'react';
import { BubbleData, BubbleSets, Dimensions } from '../types';

interface useScalesProps {
  data: BubbleData[];
  bubbleSets: BubbleSets;
  dimensions: Dimensions;
}

export interface Scales {
  verticalScale: ScaleLinear<number, number, never>;
  radiusScale: ScaleLinear<number, number, never>;
}

export const useScales = ({
  data,
  bubbleSets,
  dimensions,
}: useScalesProps): Scales => {
  const {
    chartHeight,
    chartTopOffset,
    chartBottomOffset,
    minDiameter,
    maxDiameter,
  } = dimensions;

  const { isScaleByValue } = bubbleSets;

  const minData = useMemo(
    () => min(data, (datum: BubbleData) => min(datum.axisYValues)) || 0,
    [data],
  );
  const maxData = useMemo(
    () => max(data, (datum: BubbleData) => max(datum.axisYValues)) || 0,
    [data],
  );

  const minDomain = isScaleByValue ? minData : Math.min(minData, 0);
  const maxDomain = isScaleByValue ? maxData : Math.max(maxData, 0);
  const verticalScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [chartHeight - chartTopOffset - chartBottomOffset, 0],
        domain: [minDomain, maxDomain],
        nice: true,
      }),
    [chartHeight, chartTopOffset, chartBottomOffset, minDomain, maxDomain],
  );
  const radiusScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [minDiameter / 2, maxDiameter / 2],
        domain: [0, 100],
        nice: true,
      }),
    [minDiameter, maxDiameter],
  );

  return {
    verticalScale,
    radiusScale,
  };
};
