import React from 'react';
import { Property } from '../../../dropdown-layout/helpers/Property';
import AxisRestrictionModal from '../../modals/axis-rectriction/axis-restriction-modal';
import { ColumnFilterRestrictionOption } from '../../modals/column-filter-restriction-modal/column-dd-options';
import { ColumnViewOptionsDialog } from '../../modals/column-view-options-modal/column-view-options';
import { ColumnColoringOptionsDialog } from '../../modals/column-conditional-coloring-modal/column-coloring-options';
import { ColumnRenameOptions } from '../../modals/column-rename-modal/column-rename-options';
import { CustomPopover } from '../../../../../uikit/Popover/popover';
import Dictionaries from '../../../../../types/dictionaries';
import { usePanelItemPopover } from './usePanelItemPopover';
import { CustomPopoverMenu } from '../../../../../uikit/PopoverMenu/popover-menu';
import { ColumnDdOptions } from '../../modals/column-dd-modal/column-dd-options';
import { ColumnCombineOptions } from '../../modals/column-combine-modal/column-combine-options';
import { PanelItemSetting } from '../../../../../enums/panel-item-settings';
import { HierarchySettingModal } from '../../modals/hierarchy-modal';
import { LinkSettingModal } from '../../modals/link-modal';

export interface PanelItemPopoverProps {
  containerWidth: number;
  functions: Dictionaries.FunctionOrAggregationObject;
  isShowRestriction: boolean;
  index: number;
  panel: any;
  propertyInstance: Property;
  anchorEl: any;
  setAnchorEl: any;
}

export const PanelItemPopover = ({
  containerWidth,
  functions,
  isShowRestriction,
  index,
  panel,
  propertyInstance,
  anchorEl,
  setAnchorEl,
}: PanelItemPopoverProps) => {
  const {
    isOpenPopover,
    handleClosePopover,
    sourceFields,
    addNewRestriction,
    changeViewOptions,
    changeColoringOptions,
    axisValue,
    changeRenameOptions,
    menuList,
    isShowFunctionsPopover,
    changeDDOptions,
    changeRestrictionFilterOption,
    changeCombineOptions,
    openSettingModalType,
    handleCloseModal,
    changeHierarchy,
    widgetType,
    changeLink,
  } = usePanelItemPopover({
    containerWidth,
    functions,
    isShowRestriction,
    index,
    panel,
    propertyInstance,
    anchorEl,
    setAnchorEl,
  });

  return (
    <>
      <CustomPopover
        key={propertyInstance.data.id}
        open={isOpenPopover}
        anchorEl={anchorEl}
        classes={{ paper: 'panel-item__popover' }}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CustomPopoverMenu
          settings={menuList}
          isLoading={!isShowFunctionsPopover()}
          customStyles={{ width: `${containerWidth}px` }}
          withWordWrap
        />
      </CustomPopover>

      {openSettingModalType === PanelItemSetting.restriction && (
        <AxisRestrictionModal
          sourceFields={sourceFields}
          panelData={propertyInstance.getValue()}
          closeConditionModal={handleCloseModal}
          addNewRestriction={addNewRestriction}
        />
      )}
      {openSettingModalType === PanelItemSetting.view && (
        <ColumnViewOptionsDialog
          panelData={propertyInstance.getValue()}
          changeViewOptions={changeViewOptions}
          closeDialog={handleCloseModal}
          widgetType={widgetType}
        />
      )}
      {openSettingModalType === PanelItemSetting.coloring && (
        <ColumnColoringOptionsDialog
          panelData={propertyInstance.getValue()}
          closeDialog={handleCloseModal}
          changeColoringOptions={changeColoringOptions}
          axisValue={axisValue}
          panelName={panel.name}
        />
      )}
      {openSettingModalType === PanelItemSetting.rename && (
        <ColumnRenameOptions
          panelData={propertyInstance.getValue()}
          closeDialog={handleCloseModal}
          changeRenameOptions={changeRenameOptions}
          storage={propertyInstance.getStorage() || '{}'}
          widgetType={widgetType}
        />
      )}
      {openSettingModalType === PanelItemSetting.drilldown && (
        <ColumnDdOptions
          panelData={propertyInstance.getValue()}
          closeDialog={handleCloseModal}
          changeDDOptions={changeDDOptions}
        />
      )}
      {openSettingModalType === PanelItemSetting.filterRestriction && (
        <ColumnFilterRestrictionOption
          panelData={propertyInstance.getValue()}
          closeDialog={handleCloseModal}
          changeRestrictionFilterOption={changeRestrictionFilterOption}
        />
      )}

      {openSettingModalType === PanelItemSetting.combine && (
        <ColumnCombineOptions
          panelData={propertyInstance.getValue()}
          closeDialog={handleCloseModal}
          changeCombineOptions={changeCombineOptions}
        />
      )}

      {openSettingModalType === PanelItemSetting.hierarchy && (
        <HierarchySettingModal
          panelData={propertyInstance.getValue()}
          closeDialog={handleCloseModal}
          changeHierarchy={changeHierarchy}
        />
      )}
      {openSettingModalType === PanelItemSetting.link && (
        <LinkSettingModal
          panelData={propertyInstance.getValue()}
          closeDialog={handleCloseModal}
          changeLink={changeLink}
        />
      )}
    </>
  );
};
