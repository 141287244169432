import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import { setCurrentProjectAction } from '../../slices/main-page/main-page-slice';
import ConnectionsContainer from '../../components/loader-page/connections/connections-container';
import { Map } from '../../components/loader-page/map';
import DataModelMap from '../../components/loader-page/data-model-map';
import SaveResultPanel from '../../components/loader-page/save-result-panel/save-result-panel';
import SelectFieldsPanel from '../../components/loader-page/select-fields-panel/select-fields-panel';
import { mapConnectionAction } from '../../slices/map-connection/map-connection';
import { dictionariesAction } from '../../slices/dictionaries/dictionaries';
import {
  toggleSaveResultPanelAction,
  toggleShowSelectFieldsPanelAction,
} from '../../slices/map-view/map-view';
import { Project, State, SystemSettings } from '../../slices/types';
import MapConnection from '../../types/map-connection';
import Dictionaries from '../../types/dictionaries';
import CreateTablePanel from '../../components/loader-page/create-table-panel/create-table-panel';

import './project-page.css';
import { apiGetProject } from '../../services/projectsController';
import WidgetCarousel from '../../components/widget-page/widget-carousel/widget-carousel';
import {
  initialState as initialWidgetState,
  setCurrentWidgetAction,
} from '../../slices/widget/widget';
import { apiGetFileTypes } from '../../services/dictionariesController';
import { CommonDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';

const ProjectPage: React.FC = () => {
  const projectId: number = parseInt(useParams<{ id: string }>()?.id) ?? 0;
  const dispatch = useDispatch();

  const widget = useSelector((state: State) => state.widget);
  const settingsData: SystemSettings | null = useSelector((state: State) => state.mainPage.systemSettings);
  const removeCurrentWidget = () => {
    if (widget && widget.properties?.length) {
      dispatch(setCurrentWidgetAction(initialWidgetState));
    }
  };
  useEffect(() => {
    removeCurrentWidget();
  }, []);

  const loaderId = useSelector(
    (state: State) => state.mainPage?.currentProject?.loaderId,
  ) || 0;

  const mapConnection = useSelector<State, MapConnection.Data | undefined>(
    (state) => state.mapConnection[loaderId],
  );
  const savingStrategies = useSelector<State, Dictionaries.SavingStrategies[] | undefined>((state) => state.dictionaries.savingStrategies);
  const showSaveResultPanel = useSelector(
    (state: State) => state.mapView.showSaveResultPanel,
  );
  const showSelectFieldsPanel = useSelector(
    (state: State) => state.mapView.showSelectFieldsPanel,
  );
  const showCreateTablePanel = useSelector(
    (state: State) => state.mapView.showCreateTablePanel,
  );
  const currentStepId = useSelector(
    (state: State) => state.mapView.currentStepId,
  );
  const currentSourceId = useSelector(
    (state: State) => state.mapView.currentSourceId,
  );
  const operationType = useSelector(
    (state: State) => state.mapView.operationType,
  );
  const isModelDataMode = useSelector(
    (state: State) => state.mapView.isModelDataMode,
  );
  const projectName = useSelector(
    (state: State) => state.mainPage?.currentProject?.name,
  );
  const projectGroupName = useSelector(
    (state: State) => state.mainPage?.currentProject?.projectGroupName,
  );

  // TODO dispatch из нужного компонента
  const closeSaveResultPanel = () => {
    dispatch(toggleSaveResultPanelAction(false));
  };

  const closeSidePanels = () => {
    dispatch(toggleSaveResultPanelAction(false));
    dispatch(toggleShowSelectFieldsPanelAction(false));
  };

  useEffect(() => {
    apiGetProject(projectId)
      .then((project: Project) => {
        const currentLoaderId = project.loaderId;
        dispatch(setCurrentProjectAction(project));
        dispatch(mapConnectionAction.getMapConnection(currentLoaderId));
      })
      .catch((err) => {
        console.error(`Ошибка при получении ${CommonDictionary.projectGenitive}`, err.response);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(dictionariesAction.getSavingStrategies());
  }, [dispatch]);

  const [dictFileTypes, setDictFileTypes] = useState<string[]>([]);

  useEffect(() => {
    apiGetFileTypes()
      .then((res ) => {
        setDictFileTypes(res?.map((item) => item.type) || []);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    document.title = `${settingsData?.SYSTEM_UI_NAME || ''} | ${projectGroupName} / ${projectName}`;
    return () => {
      document.title = settingsData?.SYSTEM_UI_NAME || '';
    };
  }, [projectGroupName, projectName]);

  return (
    <div className="project page">
      <div className='loader-page'>
        <ConnectionsContainer />

        {!isModelDataMode ? <Map /> : <DataModelMap
          className='loader-page__map'
        />}

        {showCreateTablePanel && <CreateTablePanel />}
        {showSaveResultPanel && (
          <SaveResultPanel
            loaderId={loaderId}
            data={mapConnection as MapConnection.Data}
            stepId={currentStepId}
            onClose={closeSaveResultPanel}
            onSave={closeSaveResultPanel}
            operationType={operationType}
            savingStrategies={
              savingStrategies as Dictionaries.SavingStrategies[]
            }
          />
        )}
        {showSelectFieldsPanel && currentSourceId && (
          <SelectFieldsPanel
            data={mapConnection as MapConnection.Data}
            sourceObjectId={currentSourceId}
            loaderId={loaderId}
            stepId={currentStepId}
            dictFileTypes={dictFileTypes}
          />
        )}
        <Backdrop
          open={showSaveResultPanel || showSelectFieldsPanel}
          classes={{ root: 'loader-page__backdrop' }}
          onClick={closeSidePanels}
        />
      </div>
      <WidgetCarousel />
    </div>
  );
};

export default ProjectPage;
