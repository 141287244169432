import { TableHead } from '@material-ui/core';
import React from 'react';
import { columns } from './helper';
import { LoaderTableCell } from '../cell/loader-table-cell';

interface LoaderTableHeadProps {
  sortHandler: (sort: string) => void;
}

export const LoaderTableHead = ({ sortHandler }: LoaderTableHeadProps) => {
  return (
    <TableHead className="loader-table__head">
      {columns.map((item, index) => (
        <LoaderTableCell cell={item} key={item.title} sortHandler={sortHandler} cellType='head' index={index} />
      ))}
    </TableHead>
  );
};
