import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { State } from 'src/slices/types';
import { setFiltersAction } from 'src/slices/main-page/main-page-slice';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

const useStyles = makeStyles({
  panel: {
    height: 'calc(46px * var(--scale-coefficient))',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'calc(20px * var(--scale-coefficient))',
    flexGrow: 1,
  },
  input: {
    paddingTop: '19px',
    paddingBottom: '7px',
    height: '20px',
    color: 'black',
    borderBottom: '1px solid black',
  },
  'input-root': {
    background: 'var(--white)',
    borderRadius: '4px',
  },
  'filter-name': {
    overflow: 'hidden',
    flexGrow: 1,
  },
  select: {
    width: 'calc(284px * var(--scale-coefficient))',

    '& .MuiSelect-select:focus': {
      borderRadius: '4px !important',
    },
  },
  'select-root': {
    paddingTop: '15px',
    background: 'var(--white) !important',
    border: '1px solid var(--dark-cyan)',
    borderRadius: '4px',
  },
  focused: {
    background: 'var(--white) !important',
    color: 'black',
    borderBottom: '2px solid var(--primary-color)',
  },
  hidden: {
    display: 'none',
  },
});

const FilterPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filters = useSelector((state: State) => state.mainPage.filters);

  const [search, handleChangeSearch] = useState<string>(filters.search);

  const setSearch = useDebouncedCallback(
    (search: string) => dispatch(setFiltersAction({ ...filters, search })),
    500,
  );

  useEffect(() => {
    handleChangeSearch(filters.search);
  }, [filters.search]);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      handleChangeSearch(value);
      setSearch.callback(value);
    },
    [setSearch],
  );

  return (
    <div className={`${classes.panel} groups-search-panel`}>
      <TextInput
        label={`Поиск по названию ${CommonDictionary.projectGenitive}`}
        className={classes['filter-name']}
        value={search}
        handleChange={handleChangeInput}
        searchIcon
      />
    </div>
  );
};

export default FilterPanel;
