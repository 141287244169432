import { useEffect, useState } from 'react';
import { SettingsButtonProps } from './settings-button';
import { apiGetFilterPropertiesById } from '../../../../../services/dashboardController';
import { DashboardFilterProperties } from '../../../../../slices/types';

export const useSettingsButton = ({ group }: SettingsButtonProps) => {
  const [isOpenSettingsPanel, setIsOpenSettingsPanel] =
    useState<boolean>(false);
  const [settingsGroups, setSettingsGroups] = useState<
    DashboardFilterProperties[]
  >([]);

  useEffect(() => {
    isOpenSettingsPanel &&
      apiGetFilterPropertiesById(group.id).then((res) => {
        setSettingsGroups(res);
      });
  }, [group.id, isOpenSettingsPanel]);

  const toggleSettingsPanel = () => {
    setIsOpenSettingsPanel((prev) => !prev);
  };

  return {
    toggleSettingsPanel,
    isOpenSettingsPanel,
    settingsGroups,
  };
};
