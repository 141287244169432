import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Group } from '@visx/group';
import { AxisLeft } from '@visx/axis';
import { VariableSizeList } from 'react-window';
import { useDebouncedCallback } from 'use-debounce';
import { BarStack } from '@visx/shape';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { makeStyles } from '@material-ui/core/styles';
import _, { times } from 'lodash';
import clsx from 'clsx';
import { useHorizontalScrollReactWindow } from '../../../../hooks/useHorizontalScroll';
import { useContainerResizeObserver } from '../../../../lib/resize-observer/adapters/useContainerResizeObserver';
import {
  ScalableSVGText,
  textDirections,
} from '../../../common/scalable-svg-text';
import { getScrollBarWidth } from '../common/helpers';
import {
  getConvertedBarGroupsForStackedHist,
  getCorrectWidgetData,
  getStackedHistogramData,
  getStackedMinMaxTotal,
} from '../helpers';
import { setPropForNumberValue } from '../formatting-helpers';
import { useEventListener } from '../../../../hooks/useEventListener';
import './styles.css';
import { PanelType } from '../../../../enums/widget-type';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { useSynchronizeWidgetScrollListener } from '../../../../hooks/useSynchronizeWidgetScrollListener';
import {
  axisBottomOffset,
  barValueStyles,
  gridLinesColor,
  gridLinesXOffset,
  gridLinesYOffset,
  leftAxisSettings,
  offsetForHorizontalText,
  stageSettings,
  xScalePadding,
  roundingPixel,
  overflowOffset
} from './settings';
import { WIDGET_HEIGHT } from '../settings';
import { Property } from '../../dropdown-layout/helpers/Property';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';
import { useStackBarProps } from './hooks/useStackBarProps';
import {
  chartOffset,
  defaultOffsetCoeff,
  tickLineWidth,
} from '../bar-group/settings';
import { useBarDimensions } from '../bar-group/hooks/useBarDimensions';
import { getDomainPointsForStackedHistogram } from '../../../../helpers/widget-page/charts';
import { CustomBarGroup } from './components/bar-group';
import { useActiveLegend } from '../hooks/useActiveLegend';

const useStyles = makeStyles({
  container: {
    width: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    minHeight: WIDGET_HEIGHT,
  },
});

export type BarsProps = {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  domain?: number[];
};

export enum histogramTypes {
  stacked = 'С накоплением',
  normalized = 'Нормализованная',
  overlap = 'С перекрытием',
}

export default function StackedHistogram({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  domain,
}: BarsProps) {
  const classes = useStyles();

  const isFullScreen = useWidgetFullScreen(widgetProps.id);
  const enableEvents: boolean = isActiveFilter;
  const widgetProperties = widgetProps.properties;
  const widgetData = widgetProps.data;
  const barsCount = widgetData[0].y.length;

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [barWidth, setBarWidth] = useState<number>(0);
  const [barOffset, setBarOffset] = useState<number>(0);
  const [axisScrollOffset, setAxisScrollOffset] = useState<number>(0);
  const [dataCount, setDatacount] = useState<number>(0);

  const {
    roundingCount,
    columnWidth,
    customAxisXLabelHeight,
    isNormalized,
    isOverlap,
    axisYLabelWidth,
    textDirection,
    isNeedToDisplayBarValuesTotal,
    currentColors,
    isNeedToDisplayAxesGuide,
    columnCount,
    columnOffsetCoeff,
    scaleByNumber,
    formatByNumber,
    axisXValuesFull,
  } = useStackBarProps({
    widgetProperties,
  });

  const scrollBarHeight = useMemo(() => getScrollBarWidth(), []);

  const containerRef = useRef(document.createElement('div'));
  const viewportRef = useRef(document.createElement('div'));
  const debounceDelay = 100;

  const valueFormat = initValueFormatter({ roundingCount });


  const data = useMemo(
    () =>
      getStackedHistogramData(
        getCorrectWidgetData(widgetData),
        isNormalized,
        isOverlap,
      ),
    [widgetData, isNormalized, isOverlap],
  );

  const maxTotal = getStackedMinMaxTotal(data, true, isNormalized, isOverlap);
  const minTotal = getStackedMinMaxTotal(data, false, isNormalized, isOverlap);

  const bottomAxisHeight = !isNaN(customAxisXLabelHeight)
    ? customAxisXLabelHeight
    : (textDirection === textDirections.horizontal
      ? offsetForHorizontalText
      : axisBottomOffset);

  const offsetForTotalValues = isNeedToDisplayBarValuesTotal ? barValueStyles.lineHeight : 0;

  const xMax = width;
  const yMax = height
    - bottomAxisHeight
    - stageSettings.top
    - offsetForTotalValues
    - tickLineWidth;

  const keys: Array<string> = times(barsCount, String);

  const getX = (d: any) => d.x;

  const xScale = scaleBand<string>({
    domain: data.map(getX),
    padding: xScalePadding,
  });

  const {
    startDomainPoint,
    endDomainPoint,
    isAllNegative,
    isHasNegative
  } = getDomainPointsForStackedHistogram(keys, data);

  const pointsForNotOverlap = {
    start: isNormalized && isHasNegative
      ? -100
      : minTotal,
    end: isNormalized && isAllNegative
      ? 0
      : isNormalized && isHasNegative
        ? 100
        : maxTotal
  };

  const pointsForScale = [
    isOverlap
      ? startDomainPoint
      : pointsForNotOverlap.start,
    isOverlap
      ? endDomainPoint
      : pointsForNotOverlap.end
  ];

  const yScale = scaleLinear<number>({
    nice: true,
    domain: domain || pointsForScale,
  });

  xScale.rangeRound([0, xMax]);
  yScale.range([yMax - (isHasNegative ? barValueStyles.lineHeight * 2 : 0), 0]);

  const { changeColorsActiveLegend, colorsActiveLegend } = useActiveLegend(
    widgetProperties,
    widgetProps.id,
    currentColors,
  );

  useEffect(() => {
    changeColorsActiveLegend();
  }, [JSON.stringify(currentColors), widgetData]);

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: colorsActiveLegend,
  });

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setWidth(value);
  }, debounceDelay);

  const deselectBar = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const {
    getOffsetBetweenBarGroups,
    minChartLength,
    groupBarSize
  } = useBarDimensions({
    barWidth,
    barsInGroupCount: 1,
    barGroupsCount: dataCount,
    axisScrollOffset,
    columnOffsetCoeff: isNaN(columnOffsetCoeff) ? defaultOffsetCoeff : columnOffsetCoeff,
  });

  const chartContainerWidth = viewportRef.current.clientWidth - axisYLabelWidth - chartOffset * 2;
  const offsetBetweenGroups = getOffsetBetweenBarGroups(chartContainerWidth);

  const axisXLabelWidth = groupBarSize + offsetBetweenGroups;


  const rowsList = React.useRef<VariableSizeList>({ _outerRef: {} } as any);

  const { containerWidth: rowsListWidth } = useContainerResizeObserver(
    (rowsList.current as unknown as any)._outerRef
  );
  const { containerWidth: viewportRefWidth } = useContainerResizeObserver(
    viewportRef
  );

  const calculateDimensions = useCallback(() => {
    if (!viewportRef.current) return;
    const minHeight = WIDGET_HEIGHT;
    const containerWidth = viewportRef.current.clientWidth - axisYLabelWidth - chartOffset * 2;
    const containerHeight = viewportRef.current.clientHeight;
    const currentHeight = minHeight > containerHeight ? minHeight : containerHeight;

    setDebouncedWidth.callback(containerWidth);
    setDebouncedHeight.callback(currentHeight);
    clearListCash();

    const rowsListEl = (rowsList.current as unknown as any)._outerRef as HTMLDivElement;
    if (rowsListEl.scrollWidth > rowsListEl.clientWidth) {
      setAxisScrollOffset(scrollBarHeight);
    } else {
      setAxisScrollOffset(0);
    }
  }, [axisYLabelWidth, setDebouncedWidth, setDebouncedHeight, minChartLength, scrollBarHeight]);

  useEffect(() => {
    calculateDimensions();

    const containerSize = viewportRef.current.clientWidth - axisYLabelWidth - chartOffset * 2;
    setBarOffset(getOffsetBetweenBarGroups(containerSize));
    setBarWidth(columnWidth);
  }, [
    data,
    calculateDimensions,
    isFullScreen,
    columnWidth,
    getOffsetBetweenBarGroups,
    viewportRef,
    axisYLabelWidth,
    isFullScreen,
    filterField,
    viewportRefWidth,
    rowsListWidth,
  ]);

  const selectBar = useCallback(
    (value: string) => {
      if (!enableEvents) return;
      if (filterField?.value?.includes(value)) {
        deselectBar();
      } else {
        if (!setFilterField || !value) return;

        setFilterField(widgetProps.id, new Property(axisXValuesFull[0]).getId(), [
          {
            operation: '=',
            value: [value],
          },
        ]);
      }
    },
    [enableEvents, filterField, deselectBar, setFilterField, widgetProps.id, axisXValuesFull],
  );

  useSynchronizeWidgetScrollListener(containerRef, widgetProps.id);

  useEventListener('resize', calculateDimensions);

  useHorizontalScrollReactWindow(viewportRef, rowsList);

  const clearListCash = () => {
    rowsList.current && rowsList.current.resetAfterIndex(0);
  };

  useEffect(() => {
    clearListCash();
  }, [width, height, data, barWidth, columnWidth]);

  const listWidth = (viewportRef.current.clientWidth - axisYLabelWidth - chartOffset * 2) + overflowOffset;

  return (
    <div
      ref={viewportRef}
      onClick={deselectBar}
      className="widget-viewport stacked-histogram-widget screenshot-overflow-y"
    >
      <div
        style={{
          width: axisYLabelWidth,
          height: '100%',
        }}
      >
        <svg
          className="viewport-svg"
          width={viewportRef.current.clientWidth}
          height={height >= WIDGET_HEIGHT ? (height - roundingPixel) : height}
        >
          <AxisLeft
            left={axisYLabelWidth}
            hideAxisLine
            hideTicks
            numTicks={7}
            top={leftAxisSettings.top + offsetForTotalValues - axisScrollOffset + tickLineWidth}
            tickFormat={(x) => `${x}`}
            tickComponent={(props) => {
              return (
                <Group>
                  <ScalableSVGText
                    x={props.x - axisYLabelWidth}
                    y={props.y - gridLinesXOffset}
                    formattedValue={valueFormat(
                      props.formattedValue,
                      0,
                      setPropForNumberValue(widgetProperties),
                      PanelType.axisY,
                      isNormalized,
                      false,
                      scaleByNumber ? formatByNumber : null,
                    )}
                    axis={PanelType.axisY}
                    properties={{
                      width: axisYLabelWidth,
                      svgTextStyles: leftAxisSettings,
                    }}
                    widgetProperties={widgetProperties}
                  />
                  {isNeedToDisplayAxesGuide && (
                    <rect
                      x={props.x + gridLinesXOffset}
                      y={props.y - gridLinesYOffset}
                      width="100%"
                      height="1"
                      fill={gridLinesColor}
                    />
                  )}
                </Group>
              );
            }}
            scale={yScale}
            stroke={leftAxisSettings.color}
            tickStroke={leftAxisSettings.color}
            tickLabelProps={() => ({
              fill: leftAxisSettings.color,
              fontSize: 11,
              textAnchor: 'middle',
            })}
          />
        </svg>
      </div>

      <div
        className={clsx(classes.container, 'screenshot-overflow-x')}
        ref={containerRef}
        style={{
          width: width - axisYLabelWidth,
        }}
      >
        <BarStack
          data={data}
          keys={keys}
          height={yMax}
          x={getX}
          xScale={xScale}
          yScale={yScale}
          color={colorScale}
        >
          {(barGroups) => {
            const barList = [...barGroups?.reverse()]?.flatMap((group) => group.bars);
            const convertedBarGroups = getConvertedBarGroupsForStackedHist(barList, columnCount)
              .filter((group) => group !== undefined);

            setDatacount(convertedBarGroups.length);

            return (
              <VariableSizeList
                height={height}
                itemCount={dataCount}
                itemSize={(i) => i < dataCount - 1 ? (barWidth + barOffset) : barWidth}
                layout="horizontal"
                width={listWidth}
                ref={rowsList}
                className="stacked-histogram__list"
                style={{
                  top: 0,
                  paddingTop: stageSettings.top + offsetForTotalValues - axisScrollOffset,
                  overflowX: 'auto',
                  overflowY: 'hidden',
                }}
              >
                {({ index, style }) => {
                  const barGroupProps = {
                    convertedBarGroup: convertedBarGroups[index],
                    barGroups,
                    style,
                    index,
                    isHasNegative,
                    barWidth,
                    yMax,
                    yScale,
                    currentColors,
                    valueFormat,
                    setPropForNumberValue,
                    widgetProperties,
                    isActiveFilter,
                    filterField,
                    data,
                    enableEvents,
                    viewportRef,
                    bottomAxisHeight,
                    widgetData,
                    selectBar,
                    deselectBar,
                    setFilterField,
                    widgetProps,
                    keys,
                    axisXLabelWidth,
                    offsetBetweenGroups
                  };

                  return CustomBarGroup(barGroupProps);
                }}
              </VariableSizeList>
            );
          }}
        </BarStack>
      </div>
    </div>
  );
}
