import { Button as MuiButton } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PanelType } from '../../../../../enums/widget-type';
import { apiFetchSourceData } from '../../../../../services/widgetController';
import { State } from '../../../../../slices/types';
import { setWidgetPropAction } from '../../../../../slices/widget/widget';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';
import './map-card-modal.css';
import {
  getParsedAxisValues,
  getSimplifiedType,
} from '../../../dropdown-layout/helpers/helpers';
import { getBooleanPropertyValue } from '../../../charts/helpers';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { mapModalTypes } from '../../enums';
import SourceField from '../map-modals/source-field';
import { useFieldAggregationsAndFunctions } from '../map-modals/useFieldAggregationsAndFunctions';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CustomButton } from '../../../../../uikit/Button';
import { MapModalDialog } from '../map-modals/map-modal-dialog/map-modal-dialog';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface CardSettingsModalProps {
  etlSourceId: string;
  widget: any;
  sourceDisplayName: string;
  onClose: (type: mapModalTypes) => void;
}

const CardSettingsModal = ({
  widget,
  etlSourceId,
  sourceDisplayName,
  onClose,
}: CardSettingsModalProps) => {
  const dispatch = useDispatch();

  const [initialWidgetSourceFields, setInitialWidgetSourceFields] = useState<any>([]);
  const [filteredWidgetSourceFields, setFilteredWidgetSourceFields] = useState<any>(
    [],
  );

  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    functions,
    currentFunctions,
    onChangeFunctionHandle,
    initCurrentFunctions,
    onDeleteFunctionHandle,
    aggregations,
    currentAggregations,
    onChangeAggregationHandle,
    initCurrentAggregations,
    onDeleteAggregationHandle,
    clearFunctionsAndAggregations,
  } = useFieldAggregationsAndFunctions(
    PanelType.axisY,
    etlSourceId,
    true,
    false,
  );

  const axisYObj = widget.properties.find(
    (property: any) => property.name === 'axisY',
  );

  const mapLayerClusterization = getBooleanPropertyValue(
    'mapLayerClusterization',
    widget.properties,
  );

  const widgetStoreObj = useSelector((state: State) => state.widget);

  const axisYValue = JSON.parse(axisYObj.value);
  const currentSourceFields = axisYValue.filter(
    (item: any) => item.etlSourceId === etlSourceId,
  );

  const [checkedWidgetFields, setCheckedWidgetFields] =
    useState<any>(currentSourceFields);

  const handleClose = (event: any) => {
    onClose(mapModalTypes.cardSettings);
    event.stopPropagation();
  };

  const onSearchChange = useCallback(
    (event: any) => {
      const { value } = event.target;

      const searchResult = initialWidgetSourceFields.filter((field: any) =>
        field.name.toLowerCase().includes(value.toLowerCase()),
      );

      setFilteredWidgetSourceFields(searchResult);
    },
    [initialWidgetSourceFields],
  );

  const handleWrapperClick = (event: any) => {
    event.stopPropagation();
  };

  const [isPoint, setIsPoint] = useState<boolean>(false);

  useEffect(() => {
    apiFetchSourceData(etlSourceId).then((result) => {
      const filteredWidgetFields = result.fields.filter((item: any) => {
        const isDescriptionItem =
          item.type.indexOf('POLYGON') === -1 &&
          item.type.indexOf('POINT') === -1;

        if (item.type.indexOf('POINT') !== -1) {
          setIsPoint(true);
        }

        if (isDescriptionItem) {
          delete item['dimension'];
          delete item['measure'];
          item.etlSourceId = etlSourceId;
          return item;
        }
      });

      setInitialWidgetSourceFields(filteredWidgetFields);
      setFilteredWidgetSourceFields(filteredWidgetFields);
    });
  }, []);

  const onChangeHandle = useCallback(
    (params: any) => {
      const { field, isChecked } = params;
      const fieldsList = [...checkedWidgetFields];

      const fieldIndex = initialWidgetSourceFields.findIndex(
        (item: any) => item.name === field.name,
      );

      if (isChecked) {
        const filteredList = fieldsList.filter(
          (item: any) => item.name !== field.name,
        );

        onDeleteFunctionHandle(fieldIndex, true);
        onDeleteAggregationHandle(fieldIndex, true);

        setCheckedWidgetFields(filteredList);
      } else {
        fieldsList.push(field);
        setCheckedWidgetFields(fieldsList);

        if (getSimplifiedType(field.type) === 'DATE') {
          onChangeFunctionHandle(
            functions.DATE.find((item: any) => item.name === 'DAY'),
            fieldIndex,
          );
        } else if (getSimplifiedType(field.type) === 'NUMBER') {
          onChangeAggregationHandle(
            aggregations.NUMBER.find((item: any) => item.name === 'SUM'),
            fieldIndex,
          );
        }
      }
    },
    [
      checkedWidgetFields,
      onChangeFunctionHandle,
      onChangeAggregationHandle,
      initialWidgetSourceFields,
      onDeleteFunctionHandle,
      onDeleteAggregationHandle,
      aggregations,
      functions,
    ],
  );

  const axisXValues = getParsedAxisValues(PanelType.axisX, widget.properties);

  const currentLayerIndex = axisXValues.findIndex(
    (value: any) => value.displayName === sourceDisplayName,
  );

  const isNeedToDisplayAllCards =
    axisXValues[currentLayerIndex].displayAllCards;

  const setNewAxisXValue = () => {
    const newAxisXValues = [...axisXValues];
    newAxisXValues[currentLayerIndex] = {
      ...newAxisXValues[currentLayerIndex],
      displayAllCards: isShowAllCards,
    };

    return newAxisXValues;
  };

  const onSaveButtonClickHandle = useCallback(
    (event: any) => {
      setLoading(true);

      const filteredFieldsList: PropertyData[] = axisYValue.filter(
        (item: any) => item.etlSourceId !== etlSourceId,
      );

      const newFunctions = currentFunctions;
      const newAggregations = currentAggregations;

      const sortedCurrentFields: any[] = [];
      initialWidgetSourceFields.forEach((sourceField: any, index: number) => {
        const currentFieldIndex = checkedWidgetFields.findIndex(
          (field: any) => sourceField.etlFieldId === field.etlFieldId,
        );
        if (currentFieldIndex !== -1) {
          sortedCurrentFields.push({
            initialIndex: index,
            value: checkedWidgetFields[currentFieldIndex]
          });
        }
      });

      const mappedByAggregationsCheckedFields = sortedCurrentFields.map(
        (field: any, index: number) => {
          const isFieldWithFunction =
            getSimplifiedType(field.value.type) === 'DATE' &&
            newFunctions[field.initialIndex]?.formula;
          const isFieldWithAggregation =
            getSimplifiedType(field.value.type) !== 'DATE' &&
            newAggregations[field.initialIndex]?.formula;

          return {
            ...field.value,
            aggregation: isFieldWithAggregation
              ? newAggregations[field.initialIndex].formula
              : null,
            function: isFieldWithFunction ? newFunctions[field.initialIndex].formula : null,
            isActive: true,
          };
        },
      );

      const newAxisYValue = filteredFieldsList.concat(mappedByAggregationsCheckedFields);
      const widgetCopy = { ...widgetStoreObj };
      const widgetProps = [...widgetCopy.properties];

      const axisYIndex = widget.properties.findIndex(
        (prop: any) => prop.name === 'axisY',
      );
      widgetProps[axisYIndex] = {
        name: PanelType.axisY,
        value: JSON.stringify(newAxisYValue),
      };

      const newAxisXValues = setNewAxisXValue();

      widgetCopy.properties = widgetProps;

      dispatch(
        setWidgetPropAction({
          name: PanelType.axisX,
          value: JSON.stringify(newAxisXValues),
        }),
      );

      dispatch(
        setWidgetPropAction({
          name: PanelType.axisY,
          value: JSON.stringify(newAxisYValue),
        }),
      );

      setLoading(false);
      onClose(mapModalTypes.cardSettings);
    },
    [
      checkedWidgetFields,
      widgetStoreObj,
      setNewAxisXValue,
      currentFunctions,
      currentAggregations,
    ],
  );

  const selectAllFieldsHandle = useCallback(() => {
    setCheckedWidgetFields(initialWidgetSourceFields);
  }, [initialWidgetSourceFields]);

  const removeAllFieldsHandle = useCallback(() => {
    setCheckedWidgetFields([]);
    clearFunctionsAndAggregations();
  }, []);

  const [isShowAllCards, setIsShowAllCards] = useState<boolean>(
    isNeedToDisplayAllCards,
  );

  const toggleIsShowAllCards = () => {
    setIsShowAllCards(!isShowAllCards);
  };

  const toggleMapLayerClusterization = useCallback(() => {
    dispatch(
      setWidgetPropAction({
        name: 'mapLayerClusterization',
        value: (!mapLayerClusterization).toString(),
      }),
    );
  }, [mapLayerClusterization]);

  useEffect(() => {
    const widgetFieldsIndexes: any[] = [];

    initialWidgetSourceFields.forEach((field: any, index: number) => {
      widgetFieldsIndexes.push(index);
    });

    const fieldsToCountInitialAggregationsState = initialWidgetSourceFields.map(
      (field: any) => {
        const checkedField = checkedWidgetFields.find(
          (currentCheckedField: any) =>
            field.etlFieldId === currentCheckedField.etlFieldId,
        );
        return checkedField || field;
      },
    );

    initCurrentFunctions(
      fieldsToCountInitialAggregationsState,
      widgetFieldsIndexes,
    );
    initCurrentAggregations(
      fieldsToCountInitialAggregationsState,
      widgetFieldsIndexes,
    );
  }, [
    initialWidgetSourceFields,
    checkedWidgetFields,
    functions.DATE.length,
    initCurrentFunctions,
    initCurrentAggregations,
  ]);

  return (
    <MapModalDialog open={true} onClick={handleWrapperClick}>
      <div className="card-settings">
        <div className="card-settings__header">
          <h3 className="card-settings__title">{sourceDisplayName}</h3>
          <CustomButton
            variant="outlined"
            icon={
              <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
            }
            onClick={handleClose}
          />
        </div>

        <div className="card-settings__content">
          <div className="card-settings__additional-settings">
            <CustomFormControl
              control={
                <CustomCheckbox
                  checked={isShowAllCards}
                  onChange={toggleIsShowAllCards}
                  withoutTopBottomPadding
                />
              }
              label="Выводить все карточки слоя"
            />
            {isPoint && (
              <CustomFormControl
                control={
                  <CustomCheckbox
                    checked={mapLayerClusterization}
                    onChange={toggleMapLayerClusterization}
                    withoutTopBottomPadding
                  />
                }
                label="Включить кластеризацию точек"
              />
            )}
          </div>

          <TextInput
            label="Поиск по полям"
            className="widget-sidebar__field"
            handleChange={onSearchChange}
            searchIcon
          />

          <div className="card-settings__buttons">
            <MuiButton
              className="card-settings__button"
              onClick={selectAllFieldsHandle}
              startIcon={
                <IconSvg
                  svg={IconDictionary.ListAdd}
                  fill="var(--primary-color)"
                />
              }
            >
              <span className="card-settings__add-button-text">
                Добавить все
              </span>
            </MuiButton>

            <MuiButton
              className="card-settings__button"
              onClick={removeAllFieldsHandle}
              startIcon={
                <IconSvg
                  svg={IconDictionary.ListDelete}
                  fill="var(--dark-grey)"
                />
              }
            >
              <span className="card-settings__delete-button-text">
                Удалить Все
              </span>
            </MuiButton>
          </div>

          <div className="card-settings__list">
            {filteredWidgetSourceFields &&
              filteredWidgetSourceFields.map((field: any, index: number) => {
                const isChecked = checkedWidgetFields.some(
                  (item: any) => item.name === field.name,
                );

                const mainIndex = initialWidgetSourceFields.findIndex(
                  (item: any) => item.name === field.name,
                );
                return (
                  <SourceField
                    key={field.etlSourceId}
                    field={field}
                    view="checkbox"
                    onChangeCurrentFieldHandle={onChangeHandle}
                    onChangeFunctionHandle={
                      getSimplifiedType(field.type) === 'DATE'
                        ? onChangeFunctionHandle
                        : onChangeAggregationHandle
                    }
                    onDeleteFunctionHandle={
                      getSimplifiedType(field.type) === 'DATE'
                        ? onDeleteFunctionHandle
                        : onDeleteAggregationHandle
                    }
                    functions={
                      getSimplifiedType(field.type) === 'DATE'
                        ? functions?.DATE
                        : aggregations[getSimplifiedType(field.type)]
                    }
                    currentFunction={
                      getSimplifiedType(field.type) === 'DATE'
                        ? currentFunctions[mainIndex]
                        : currentAggregations[mainIndex]
                    }
                    isChecked={isChecked}
                    index={mainIndex}
                  />
                );
              })}
          </div>
        </div>

        <div className="dialog-buttons">
          <CustomButton
            disabled={isLoading}
            loading={isLoading}
            onClick={onSaveButtonClickHandle}
            variant="contained"
          >
            Сохранить
          </CustomButton>
          <CustomButton
            disabled={isLoading}
            type="reset"
            onClick={handleClose}
            variant="outlined"
          >
            Отменить
          </CustomButton>
        </div>
      </div>
    </MapModalDialog>
  );
};

export default CardSettingsModal;
