import React, { useCallback, useMemo, useState } from 'react';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { getIconByDataType } from '../../../helpers';
import FunctionsList from './functions-list';
import './source-field.css';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomRadio } from '../../../../../uikit/Radio/Radio';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CustomButton } from '../../../../../uikit/Button';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface ChangeCurrentFieldHandleParams {
  field: any;
  isChecked?: boolean;
  index?: number
}

interface SourceFieldProps {
  field: any;
  view: 'checkbox' | 'radio';
  onChangeCurrentFieldHandle: (params: ChangeCurrentFieldHandleParams) => void;
  isChecked: boolean;
  functions?: any[];
  onChangeFunctionHandle?: any;
  onDeleteFunctionHandle?: any;
  currentFunction?: any;
  index?: number;
  isPossibleDeleteFunction?: boolean;
}

const SourceField = ({
  field,
  view,
  onChangeCurrentFieldHandle,
  onDeleteFunctionHandle,
  functions,
  isChecked,
  onChangeFunctionHandle = null,
  currentFunction = null,
  index,
  isPossibleDeleteFunction = false,
}: SourceFieldProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpenPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popoverId = useMemo(
    () => (isOpenPopover ? 'simple-popover' : undefined),
    [isOpenPopover],
  );
  const onClickFunctionsListHandle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [],
  );
  const onCloseFunctionsListHandle = useCallback((event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const changeFunctionHandler = (event: any) => {
    setAnchorEl(null);
    onChangeFunctionHandle(event, index);
  };

  const deleteFunctionHandler = () => {
    setAnchorEl(null);
    onDeleteFunctionHandle(index);
  };

  const getFormControl = () => {
    switch (view) {
      case 'radio':
        return (
          <CustomRadio
            color="primary"
            onChange={() => onChangeCurrentFieldHandle({ field, index })}
            checked={isChecked}
          />
        );
      case 'checkbox':
        return (
          <CustomCheckbox
            color="primary"
            onChange={() => {
              onChangeFunctionHandle({}, index);
              onChangeCurrentFieldHandle({ field, isChecked, index });
            }}
            checked={isChecked}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="source-field map-modal__source-field">
      <CustomFormControl control={getFormControl()} label="" />

      <IconSvg
        classSVG="source-field__type"
        svg={getIconByDataType(field.type)}
        fill="var(--dark-grey)"
      />

      <span className="source-field__name">{field.name}</span>

      {isChecked && Boolean(functions?.length) && (
        <div className="source-field__functions">
          {currentFunction?.name && (
            <div
              className={
                isPossibleDeleteFunction
                  ? 'source-field__value source-field__delete-aggregation'
                  : 'source-field__value'
              }
              onClick={
                isPossibleDeleteFunction ? deleteFunctionHandler : undefined
              }
            >
              <IconSvg
                classSVG={
                  isPossibleDeleteFunction
                    ? 'source-field__button-delete'
                    : 'source-field__button-delete source-field__button-delete__active'
                }
                svg={IconDictionary.BasketDelete}
                fill="var(--red-normal)"
              />
              {currentFunction.name}
            </div>
          )}
          <div className="source-field__button">
            <CustomButton onClick={onClickFunctionsListHandle} variant="text" size="small">
              <IconSvg svg={IconDictionary.HorizontalMore} fill="var(--title-color)" />
            </CustomButton>
          </div>
        </div>
      )}
      <FunctionsList
        popoverId={popoverId}
        isOpenPopover={isOpenPopover}
        handleCloseFunctionsList={onCloseFunctionsListHandle}
        anchorEl={anchorEl}
        items={functions}
        handleChangeFunction={changeFunctionHandler}
      />
    </div>
  );
};

export default SourceField;
