import React, { useCallback } from 'react';
import {
  debounceDelay,
  getWidgetProperties,
  stageSettings,
  valueScalePadding,
  xScalePadding,
} from '../properties';
import { getCorrectWidgetData } from '../../helpers';
import { useDebouncedCallback } from 'use-debounce';
import { WIDGET_HEIGHT } from '../../settings';

export const useDimensions = ({
  widgetData,
  widgetProperties,
  groupBarsCount,
  containerRef,
  setWidth,
  setHeight,
}: {
  widgetData: any;
  widgetProperties: any;
  groupBarsCount: number;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { columnWidth } = getWidgetProperties(widgetProperties);

  const setDebouncedWidth = useDebouncedCallback((value) => {
    setWidth(value);
  }, debounceDelay);

  const setDebouncedHeight = useDebouncedCallback((value) => {
    setHeight(value);
  }, debounceDelay);

  const getMinWidth = useCallback(() => {
    const stepWidth = columnWidth
      ? (columnWidth + columnWidth * valueScalePadding) * groupBarsCount
      : stageSettings.minWidthStep;
    return (
      getCorrectWidgetData(widgetData).length *
      (stepWidth + stepWidth * xScalePadding)
    );
  }, [columnWidth, groupBarsCount, widgetData]);

  const calculateDimensions = useCallback(() => {
    if (!containerRef.current) return;

    const minHeight = WIDGET_HEIGHT;

    const { width: containerWidth, height: containerHeight  } =
      containerRef.current.getBoundingClientRect();
    const minWidth = getMinWidth();

    const currentWidth = minWidth > containerWidth ? minWidth : containerWidth;
    const currentHeight =
      minHeight > containerHeight ? minHeight : containerHeight;

    setDebouncedWidth.callback(currentWidth);
    setDebouncedHeight.callback(currentHeight);
  }, [containerRef, getMinWidth, setDebouncedWidth, setDebouncedHeight]);

  const getCustomBarWidth = () => {
    const containerWidth = containerRef.current.clientWidth;
    const minWidth = getMinWidth();
    return containerWidth <= minWidth && columnWidth;
  };

  return {
    calculateDimensions,
    getCustomBarWidth,
  };
};
