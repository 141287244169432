import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { State } from '../../../../../slices/types';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { PanelType, WidgetType } from '../../../../../enums/widget-type';
import { getPropertiesForSortableContext } from '../../helpers';
import { WidgetDropdownItemProps } from './widget-dropdown-item';
import Dictionaries from '../../../../../types/dictionaries';
import { apiGetAggregationFunctions } from '../../../../../services/dictionariesController';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';

const WIDGETS_WITH_FILTERS_SYNCHRONIZATION = [
  WidgetType.PIVOT_TABLE,
  WidgetType.TABLE,
];

export const useWidgetDropdownItem = ({
  panel,
  panelType,
}: WidgetDropdownItemProps) => {
  const widget = useSelector((state: State) => state.widget);

  const [functionsAndAggregationsByTypes, setFunctionsAndAggregationsByTypes] =
    useState<Dictionaries.FunctionOrAggregationObjectByTypes>({});

  useEffect(() => {
    const dataTypes = ['TEXT', 'NUMBER', 'DATE', 'BOOLEAN'];
    const functionsAndAggregations: Dictionaries.FunctionOrAggregationObjectByTypes =
      {};

    const requests: Promise<any>[] = [];

    dataTypes.forEach((dataType) => {
      requests.push(apiGetAggregationFunctions({
        type: dataType,
        axisName: panelType,
        widgetType: widget.type,
      }).then((result) => {
        functionsAndAggregations[dataType] = result;
      }));
    });

    Promise.all(requests).then(() => {
      setFunctionsAndAggregationsByTypes(functionsAndAggregations);
    });
    
  }, [panelType, widget.type]);

  const axisXValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisX, widget.properties),
    [widget.properties],
  );

  const axisZValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisZ, widget.properties),
    [widget.properties],
  );

  const sortableItems = getPropertiesForSortableContext(panel);

  const hiddenElements =
    panelType === PanelType.axisFilter && WIDGETS_WITH_FILTERS_SYNCHRONIZATION.includes(widget.type)
      ? axisXValues.concat(axisZValues)
      : [];

  return {
    sortableItems,
    functionsAndAggregationsByTypes,
    hiddenElements,
  };
};
