import React, { useEffect, useState } from 'react';
import { FormControl, RadioGroup } from '@material-ui/core';
import { stringToBoolean } from 'src/utils/functions';
import { CustomModalForm } from 'src/uikit/ModalForm';
import {
  apiCheckProjectMailingSubscription,
  apiProjectMailingSubscribe,
} from '../../../services/projectsController';
import { useNotificator } from '../snackbar/hooks';
import { CustomButton } from '../../../uikit/Button';
import { CustomFormControl } from '../../../uikit/FormControl';
import { CustomRadio } from '../../../uikit/Radio/Radio';


interface SubscribeMailingFormProps {
  handleCloseForm: () => void;
  projectId: number;
}

export const SubscribeMailingForm: React.FC<SubscribeMailingFormProps> = ({
  handleCloseForm,
  projectId,
}) => {
  const { showNotification } = useNotificator();

  const handleSubmit = (e: any) => {
    const params = {
      isSubscribe,
      mailingType: { type: 'MAIL', value: null },
      mailingFormat: 'EXCEL',
      projectId,
    };

    apiProjectMailingSubscribe(params).then(() => {
      showNotification({ message: 'Сохранено', variant: 'success' });
      handleCloseForm();
    });
    e.preventDefault();
    return false;
  };

  const [isSubscribe, setIsSubcribe] = useState<boolean>(false);
  useEffect(() => {
    if (projectId) {
      apiCheckProjectMailingSubscription(projectId).then((data) => {
        setIsSubcribe(data.isSubscribe);
      });
    }
  }, [projectId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubcribe(stringToBoolean[event.currentTarget.value as 'true' | 'false']);
  };

  return (
    <CustomModalForm onSubmit={handleSubmit} autoComplete="off">
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Подписка"
          name="Подписка"
          value={isSubscribe}
          onChange={handleChange}
        >
          <CustomFormControl
            value={true}
            control={<CustomRadio color="primary" />}
            label="Подписываться на рассылку"
          />
          <CustomFormControl
            value={false}
            control={<CustomRadio color="primary" />}
            label="Не подписываться на рассылку"
          />
        </RadioGroup>
      </FormControl>
      <div className="dialog-buttons dialog-buttons_in-form">
        <CustomButton type="submit" variant="contained">
          Сохранить
        </CustomButton>

        <CustomButton variant="outlined" onClick={handleCloseForm}>
          Отменить
        </CustomButton>
      </div>
    </CustomModalForm>
  );
};
