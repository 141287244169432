import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import { replaceAll } from 'src/utils/functions';
import { setWidgetPropAction } from '../../../../../slices/widget/widget';
import {
  PanelType,
  WidgetPropertyType,
} from '../../../../../enums/widget-type';
import { HiddenColumnsModalProps } from './hidden-columns-modal';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { useWidgetPropertiesChangeOnDashboard } from '../../../../../hooks/charts/useWidgetPropertiesChangeOnDashboard';
import { isDashboardPage } from '../../../charts/helpers';

export const useHiddenColumnsModal = ({
  widget,
  onClose,
}: Omit<HiddenColumnsModalProps, 'anchorEl'>) => {
  const dispatch = useDispatch();
  const { changePropertyOnDashboard } = useWidgetPropertiesChangeOnDashboard();

  const axisXValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisX, widget.properties),
    [widget.properties],
  );

  const columnsList = useMemo(
    () =>
      axisXValues.filter((value) => value.isActive).map((item) => ({
        id: item.clientUID as string,
        name: replaceAll(JSON.parse(item.storage || '{}').alias || item.name, '\\n', ' '),
      })),
    [axisXValues],
  );

  const initHiddenColumns: string[] = useMemo(
    () =>
      getParsedAxisValues(WidgetPropertyType.hiddenColumns, widget.properties),
    [widget.properties],
  );

  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    initHiddenColumns || [],
  );

  const handleClickChange = (columnId: string) => {
    if (hiddenColumns.includes(columnId)) {
      const index = hiddenColumns.findIndex((item) => item === columnId);
      const newHiddenColumns = [...hiddenColumns];
      newHiddenColumns.splice(index, 1);
      setHiddenColumns(newHiddenColumns);
    } else {
      setHiddenColumns([...hiddenColumns, columnId]);
    }
  };

  const handleClickChangeAll = () => {
    if (hiddenColumns.length) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(columnsList.map((item) => item.id));
    }
  };

  const handleClickSave = () => {
    const newProperty = {
      name: WidgetPropertyType.hiddenColumns,
      value: JSON.stringify(hiddenColumns),
    };

    if (isDashboardPage()) {
      changePropertyOnDashboard(widget.id as number, [newProperty]);
    } else {
      dispatch(setWidgetPropAction(newProperty));
    }
    onClose();
  };

  const handleClickClose = () => {
    setHiddenColumns(initHiddenColumns);
    onClose();
  };

  const isDisabledButton = hiddenColumns.length === columnsList.length;

  return {
    handleClickSave,
    handleClickChange,
    columnsList,
    hiddenColumns,
    handleClickChangeAll,
    isDisabledButton,
    handleClickClose,
  };
};
