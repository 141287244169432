import { forEach } from 'lodash';

export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

type CookieOptions = {
  path?: string;
  expires?: string | Date;
  'max-age'?: string | Date;
  domain?: string;
  secure?: true;
  samesite?: string;
};

export function setCookie(name: string, value: string, cookieOptions = {}) {

  const options: CookieOptions = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...cookieOptions
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)  }=${  encodeURIComponent(value)}`;

  forEach(options, (value, key) => {
    updatedCookie += `; ${  key}`;
    const optionValue = value;
    if (optionValue !== true) {
      updatedCookie += `=${  optionValue}`;
    }
  });

  document.cookie = updatedCookie;
}


export function deleteCookie(name: string) {
  setCookie(name, '', {
    'max-age': -1
  });
}
