import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';

import block from 'bem-cn';

import { getSourcesFromConnectionId } from '../../../services/sources';
import { useClassButton } from '../../../hooks/useClassButton';
import ConnectionFileItem from './connection-file';
import TablePreview from '../table-preview/table-preview';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import './connection-files-list.css';
import CommonLoader from '../../common/common-loader/common-loader';
import IconSvg from '../../common/icon-svg/icon-svg';
import SqlScriptPanel from '../sql-script-panel/sql-script-panel';
import { State } from '../../../slices/types';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import { isNeedActivatePushDown } from '../../../helpers/loader-page';
import { useNotificator } from '../../common/snackbar/hooks';
import { CustomProgress } from '../../../uikit/Progress';
import {
  InlineTextButton,
} from '../../../uikit/InlineTextButton';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

const SEARCH_DEBOUNCED_TIME = 400;

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: 'var(--white)',
    },
    background: 'var(--white)',
    border: '1px solid var(--dark-cyan)',
  },
  filter: {
    paddingTop: '19px',
    paddingBottom: '7px',
    height: '20px',
  },
  focused: {
    background: 'var(--white) !important',
    borderBottom: '2px solid var(--primary-color)',
  },
  shrink: {
    color: 'var(--dark-grey) !important',
  },
  newFileButton: {
    color: 'var(--primary-color)',
    textTransform: 'initial',
    textDecorationStyle: 'dotted',
    textDecorationColor: 'inherit',
    textDecorationLine: 'underline',

    '&:hover': {
      textDecorationStyle: 'dotted',
      textDecorationColor: 'inherit',
      textDecorationLine: 'underline',
    },
  },
  fullWidth: {
    width: '100%',
  },
});

const b = block('connection-files-list');

interface Props {
  className?: string;
  onCancel: () => void;
  connectionId: number;
  setError: any;
  connectionCategory: string;
}

const ConnectionFilesList: React.FC<Props> = ({
  className = '',
  onCancel,
  setError,
  connectionId,
  connectionCategory,
}) => {
  const dispatch = useDispatch();
  const { showNotification } = useNotificator();
  const isSaving = useSelector(
    (state: State) => state.connectionFilesList.isSaving,
  );

  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;

  const classes = useStyles();
  const classButton = useClassButton();

  const [files, setFiles] = useState<{ name: string }[]>([]);
  const [nameList, setNameList] = useState<string[]>([]);
  const [previewName, setPreviewName] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSQLScript, setIsSQLScript] = useState<boolean>(false);

  const onSave = () => {
    const errorCallback = (e: any) => {
      setError(e);
    };
    dispatch(
      mapConnectionAction.addSourceObjects(
        {
          loaderId,
          loaderGroupId: 1, // TODO: Необходимо научиться получать по loaderId
          connectionId,
          tableNames: nameList,
          needPushDown: isNeedActivatePushDown(connectionCategory),
        },
        onCancel,
        errorCallback,
      ),
    );
  };

  const closePreview = () => {
    setPreviewName('');
  };

  const addToNameList = (name: string) => {
    setNameList([...nameList, name]);
  };

  const deleteFromNameList = (name: string) => {
    const filterNames = [...nameList].filter((nameItem) => nameItem !== name);
    setNameList(filterNames);
  };

  const getConnectionFilesDebounced = useMemo(
    () =>
      debounce((searchQuery: string) => {
        getConnectionFiles(searchQuery);
      }, SEARCH_DEBOUNCED_TIME),
    [connectionId],
  );

  const handlerChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value.toLowerCase();
      getConnectionFilesDebounced(value);
    },
    [getConnectionFilesDebounced]
  );

  const toggleNewSQLScriptClick = () => {
    setIsSQLScript((prevIsSQLScript) => !prevIsSQLScript);
  };

  const onCloseNewSqlScript = () => {
    setIsSQLScript(false);
  };

  const getConnectionFiles = useCallback(
    (searchQuery?: string) => {
      getSourcesFromConnectionId(connectionId, searchQuery)
        .then((response) => {
          setLoading(false);
          setFiles(response.data);
        })
        .catch((error) => {
          setLoading(false);
          showNotification({
            message: error.response?.data?.message || 'Ошибка',
            variant: 'error',
          });
        });
    },
    [connectionId, showNotification],
  );

  useEffect(() => {
    setFiles([]);
    setNameList([]);
    setPreviewName('');
    setLoading(true);
    getConnectionFiles();
  }, [getConnectionFiles]);

  return (
    <div className={`${b()} ${className}`}>
      <div className={b('title-container')}>
        <h3 className={b('title')}>Список таблиц</h3>

        <InlineTextButton
          onClick={toggleNewSQLScriptClick}
          title={CommonDictionary.SQLScript}
        />
      </div>

      <TextInput
        label="Быстрый поиск"
        className={classes.fullWidth}
        handleChange={handlerChangeFilter}
        searchIcon
      />

      <div className={b('files-section')}>
        {isLoading && <CustomProgress type="linear" />}

        {files.map(({ name }: any) => (
          <ConnectionFileItem
            key={name}
            name={name}
            addToNames={addToNameList}
            deleteFromNames={deleteFromNameList}
            setPreviewName={setPreviewName}
            previewName={previewName}
          />
        ))}
      </div>
      <div className={b('buttons')}>
        <Button className={classButton.primary} type="submit" onClick={onSave}>
          Добавить
        </Button>
        <Button className={classButton.default} type="reset" onClick={onCancel}>
          Отменить
        </Button>
      </div>

      {previewName && (
        <TablePreview
          className={b('table-preview')}
          name={previewName}
          connectionId={connectionId}
          onClose={closePreview}
        />
      )}

      {isSQLScript && (
        <SqlScriptPanel
          loaderId={loaderId}
          connectionId={connectionId}
          setError={setError}
          onClose={onCloseNewSqlScript}
          closeListPanel={onCancel}
        />
      )}

      {isSaving && <CommonLoader />}
    </div>
  );
};

export default ConnectionFilesList;
