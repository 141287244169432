import React from 'react';
import { CSSProperties } from '@material-ui/styles';
import { LegendHorizontal } from './legend-horizontal';
import { LegendVertical } from './legend-vertical';

type Props = {
  colors: any;
  data: any;
  valueName?: string;
  zName?: string;
  widgetProperties: any[];
  isSidePosition: boolean;
  style?: CSSProperties;
};

export function LegendArc(props: Props) {

  const { style, data, isSidePosition, valueName, zName, ...defaultProps } = props;

  return (
    <>
      {isSidePosition ? (
        <LegendHorizontal
          {...defaultProps}
          data={data}
          valueName={valueName}
          zName={zName}
          style={style}
        />
      ) : (
        <LegendVertical
          data={data}
          valueName={valueName}
          zName={zName}
          {...defaultProps}
        />
      )}
    </>
  );
}
