import { useDispatch } from 'react-redux';
import { setWidgetPropsAction } from 'src/slices/widget/widget';
import { isDashboardPage } from '../../helpers';
import { PanelType } from '../../../../../enums/widget-type';
import {
  getPanelIndex,
  getParsedAxisValues,
} from '../../../dropdown-layout/helpers/helpers';
import {
  Property,
  PropertyData,
} from '../../../dropdown-layout/helpers/Property';
import { useDashboardFilters } from '../../../../dashboard-page/hooks/useDashboardFilters';
import { WidgetProperties } from '../../../../../slices/types';

interface useColumnFiltersParams {
  widgetProperties: WidgetProperties[];
  panelType: PanelType;
  widgetId: number | undefined;
  currentFieldId: string;
  setDashboardWidgetState?: ( properties: WidgetProperties[]) => void;
}

export const useColumnAndRowFilters = ({
  widgetProperties,
  panelType,
  currentFieldId,
  widgetId,
  setDashboardWidgetState,
}: useColumnFiltersParams) => {
  const isDashboard = isDashboardPage();
  const {
    getFilterByNameAndType,
  } = useDashboardFilters();

  const dispatch = useDispatch();

  const getFilter = () => {
    const filters: PropertyData[] = getParsedAxisValues(PanelType.axisFilter, widgetProperties);

    const axisValues: PropertyData[] = getParsedAxisValues(
      panelType,
      widgetProperties,
    );
    const currentProperty: PropertyData | undefined = axisValues.find(
      (item) => item.etlFieldId === currentFieldId,
    );

    if (!currentProperty) return null;

    const filterIndex = filters.findIndex(
      (item) => item.etlFieldId === currentFieldId,
    );

    if (filterIndex === -1) return null;

    const { name } = currentProperty;

    const type = currentProperty.aggregation?.includes('COUNT')
      ? 'DOUBLE'
      : currentProperty.type;

    const filterValueOnDashboard = getFilterByNameAndType(name, type);
    const currentFilter = filters[filterIndex];
    const filterId = new Property(currentFilter).getId();

    const filterValue = filterValueOnDashboard?.widgetFilters.find((widgetFilter) => {
      return widgetFilter.widgetId === widgetId;
    })?.value;

    if (!filterId) return null;

    if (isDashboardPage()) {
      const dashboardAdaptedFilter: PropertyData = {
        ...currentFilter,
        filter: filterValue || [],
      };

      return dashboardAdaptedFilter;
    }

    return filters[filterIndex] || null;
  };

  const getAllFiltersFromAxis = (): PropertyData[] => {
    return getParsedAxisValues(PanelType.axisFilter, widgetProperties);
  };

  const getFilterResult = (filter: any) => {
    const propertyInstance = new Property(getFilter() as PropertyData);
    propertyInstance.changeFilter(filter);

    const filters = getAllFiltersFromAxis();
    const currentFilterIndex = filters.findIndex(
      (filter) => filter.etlFieldId === currentFieldId,
    );
    filters[currentFilterIndex] = propertyInstance.isCalculated()
      ? propertyInstance.getCalculatedValue()
      : propertyInstance.getValue();

    return filters;
  };

  const getPropertiesWithNewFilter = (filter: any) => {
    const properties = [...widgetProperties];

    const axisIndex: number = getPanelIndex({
      properties,
      type: PanelType.axisFilter,
    });

    properties[axisIndex] = {
      ...(properties[axisIndex] || []),
      value: JSON.stringify(getFilterResult(filter)),
    };

    return properties;
  };

  const setFilterOnEditor = (filter: any) => {
    dispatch(setWidgetPropsAction(getPropertiesWithNewFilter(filter)));
  };

  const setFilterOnDashboard = (filter: any) => {
    const filters = getAllFiltersFromAxis();
    const currentFilter = filters.find(
      (filter) => filter.etlFieldId === currentFieldId,
    );
    if (!currentFilter) return;

    if (!setDashboardWidgetState) return;
    setDashboardWidgetState([
      {
        name: PanelType.axisFilter,
        value: JSON.stringify(getFilterResult(filter)),
      },
    ]);
  };

  return {
    getFilter,
    setFilter: isDashboard ? setFilterOnDashboard : setFilterOnEditor,
  };
};
