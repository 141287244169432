import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';

import './styles.css';
import { InlineTextButton } from '../../../uikit/InlineTextButton';


interface FilterContainerProps {
  children?: React.ReactNode;
  label?: React.ReactNode | string;
  isDisabledApplyButton: boolean;
  handleApply: () => void;
}

export const FilterContainer: React.FC<FilterContainerProps> = ({
  children,
  label,
  isDisabledApplyButton,
  handleApply,
}) => {
  return (
    <div className="filter-container">
      <div className="filter-container__heading">
        {label && typeof label === 'string' ? (
          <Tooltip title={label} placement="bottom-start">
            <Typography className="filter-container__title" variant="h3">
              {label}
            </Typography>
          </Tooltip>
        ) : (
          <>{ label }</>
        )}
        <InlineTextButton
          title="Применить"
          onClick={handleApply}
          disabled={isDisabledApplyButton}
          className={clsx('filter-apply-button')}
        />
      </div>
      <div className="filter-container__filter">{children}</div>
    </div>
  );
};
