import { useCallback, useState } from 'react';
import { Project } from '../../../slices/types';

type SortingProps = { attr: string | null; up: boolean | null };

export const useLoadersTable = (projects?: Project[]) => {
  const [sortingProp, setSortingProp] = useState<SortingProps>({ attr: null, up: null });

  const sortHandler = useCallback((attr: string) => {
    setSortingProp((prop) => ({
      attr,
      up:
        prop.attr !== attr
          ? true
          : prop.up
            ? false
            : prop.up === null
              ? true
              : null,
    }));
  }, []);

  const projectsSorted = [...(projects as Project[])].sort((a: any, b: any) => {
    if (sortingProp.attr && !(sortingProp.up === null)) {
      if (a[sortingProp.attr] < b[sortingProp.attr])
        return sortingProp.up ? -1 : 1;
      if (a[sortingProp.attr] > b[sortingProp.attr])
        return sortingProp.up ? 1 : -1;
    }
    return 0;
  });

  return {
    sortHandler,
    projectsSorted,
  };
};
