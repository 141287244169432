import { WidgetType } from '../enums/widget-type';

declare namespace WidgetTypes {
  interface Type {
    description: string;
    icon: string;
    id: number;
    type: WidgetType;
    propertiesGroups: WidgetPropertiesGroup[];
  }

  interface WidgetPropertiesGroup {
    description: string;
    icon: string;
    id: number;
    type: string;
    name: string;
    propertiesMetaBlocks: WidgetsPropertiesMetaBlocks[];
  }

  interface WidgetsPropertiesMetaBlocks {
    id: number;
    name: string;
    title: string;
    metaProperties: WidgetsMetaProperties[];
  }

  interface WidgetsMetaProperties {
    description: string;
    id: number;
    name: string;
    widgetSetting: boolean;
    defaultValue: string;
    dashboardSetting: false;
    visualType: string;
    options?: any;
    parentName: string;
    validationValue?: string;
    validationDescription?: string;
  }

  interface SourceItem {
    id: string;
    name: string;
    needPushDown: boolean;
  }

  interface ValueType {
    value: string;
    type: string;
  }

  interface DataProperty {
    name: string;
    template: string;
    valueTypeList: ValueType[];
  }

  interface Data {
    data: any;
    properties: DataProperty[];
  }
}

export enum GroupTypes {
  main = 'MAIN',
  additional = 'ADDITIONAL',
  head = 'HEAD',
}

export default WidgetTypes;
