import axios from 'axios';
import store from 'src/store';
import { setCodeAction } from '../slices/error-page/error-page';
import { CustomEventMap } from '../types/root';
import { dispatchRootEvent } from './root-helper';
import { SocketNetwork } from './socket-network';

export const COOKIE_ACCESS_TOKEN = 'access';
export const COOKIE_REFRESH_TOKEN = 'refresh';
export const SHAREABLE_TOKEN = 'shareableToken';
export const SHAREABLE_HEADER = 'X-Shareable-Token';

const TIMEOUT_SERVER = 30 * 1000 * 60; // 30 minutes

const params = {
  timeout: TIMEOUT_SERVER,
  withCredentials: true,
  maxRedirects: 5,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
};

const onFulfilled = (response: any) => {
  return response;
};

export const checkAccess = (error: any) => {
  switch (error.response?.status) {
    case 404: {
      store.dispatch(setCodeAction('404'));
      break;
    }
    case 403: {
      store.dispatch(setCodeAction('403'));
      break;
    }
  }
};

const onRejected = (error: any) => {
  switch (error.response?.status) {
    case 401: {
      const redirectHref = error.response.headers['x-redirect'];
      if (redirectHref) window.location.href = redirectHref;
      break;
    }
    case 498: {
      const customEvent = new CustomEvent('apiUserError', {
        detail: {
          message: (error.response?.data?.message || undefined) as string | undefined,
        },
      });
      dispatchRootEvent(customEvent);
      return Promise.reject(error);
    }

    default: {
      return Promise.reject(error);
    }
  }
};

export const apiAuthenticator = axios.create({
  baseURL: process.env.REACT_APP_API_BI || 'http://172.24.2.6/api/bi',
  ...params,
});

export const apiETL = axios.create({
  baseURL: process.env.REACT_APP_API_ETL || 'http://172.24.2.6/api/uniloader',
  ...params,
});

export const apiBI = axios.create({
  baseURL: process.env.REACT_APP_API_BI || 'http://172.24.2.6/api/bi',
  ...params,
});

apiETL.interceptors.response.use(onFulfilled, onRejected);
apiBI.interceptors.response.use(onFulfilled, onRejected);


// WebSockets

const websocketBiUrl = `${(process.env.REACT_APP_API_BI || '').replace(/\/$/, '')}/bi-websocket`;

export const wsBI = new SocketNetwork({ url: websocketBiUrl });

const websocketEtlUrl = `${(process.env.REACT_APP_API_ETL || '').replace(/\/$/, '')}/uniloader-websocket`;

const currentPage = window.location.pathname.toLowerCase();
const isSharedPage = /\/shared\//.test(currentPage);

export const wsETL = isSharedPage ? null : new SocketNetwork({ url: websocketEtlUrl });
