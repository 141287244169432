import { isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useContainerResizeObserver } from 'src/lib/resize-observer/adapters/useContainerResizeObserver';
import { PanelType } from '../../../../enums/widget-type';
import { getFirstActivePanelItemIndex } from '../../../../helpers/common-helpers';
import { getTooltipHandler } from '../../../common/widget-tooltip/widget-tooltip';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import './bubble-chart.css';
import { setPropForNumberValue } from '../formatting-helpers';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { extendColors } from '../overlay-graph/helper';
import { Bubble } from './components/bubble';
import { SMALL_DEBOUNCE_TIME } from './constants';
import { getBubbleData, getSeriesValues } from './helper';
import { useBubbleProperties } from './hooks/useBubbleProperties';
import { BubbleCartWidgetData, BubbleSets, FormatAxisXValue, SeriesValue } from './types';

export interface BubbleChartProps {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
}

export const BubbleChart = ({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}: BubbleChartProps) => {
  const widgetProperties = widgetProps.properties;
  const widgetData: BubbleCartWidgetData[] = widgetProps.data;

  const {
    axisXValues,
    axisYValues,
    axisZValues,
    currentColors,
    pointMinSize,
    pointMaxSize,
    pointDiffSize,
    axisXHeight,
    axisYWidth,
    pointOffsetCoeff,
    isScaleByValue,
    roundingCount,
    scaleByNumber,
    formatByNumber,
    isNeedToDisplayValue,
    isNeedToDisplayPercent,
    isNeedToDisplayNameSlice,
    isNeedToDisplayTooltip,
    legendsLabels,
    textDirection,
    isNeedToDisplayAxesGuide,
  } = useBubbleProperties({ widgetProperties });

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  // formatting
  const valueFormat = useMemo(
    () => initValueFormatter({ roundingCount }),
    [roundingCount],
  );

  const formatAxisXValue: FormatAxisXValue = useCallback(
    (value: string) => {
      return valueFormat(value, axisXValueIndex, widgetProperties, PanelType.axisX);
    },
    [axisXValueIndex, valueFormat, widgetProperties],
  );

  const getFormatAxisValue = useCallback(
    (axis: PanelType) => {
      return (value: number, index?: number) => {
        return String(
          valueFormat(
            value,
            index || 0,
            isNil(index)
              ? setPropForNumberValue(widgetProperties)
              : widgetProperties,
            axis,
            false,
            false,
            scaleByNumber ? formatByNumber : null,
          ),
        );
      };
    },
    [formatByNumber, scaleByNumber, valueFormat, widgetProperties],
  );
  const formatAxisYValue = useMemo(
    () => getFormatAxisValue(PanelType.axisY),
    [getFormatAxisValue],
  );
  const formatAxisZValue = useMemo(
    () => getFormatAxisValue(PanelType.axisZ),
    [getFormatAxisValue],
  );

  const containerRef = useRef(document.createElement('div'));

  const { containerWidth, containerHeight } = useContainerResizeObserver(
    containerRef,
    SMALL_DEBOUNCE_TIME,
  );

  const colors = extendColors(currentColors, axisYValues.length);

  const xField = getSeriesValues('axisX', axisXValues)[0] as SeriesValue | undefined;
  const yFields = getSeriesValues('axisY', axisYValues, {
    colors,
    legendsLabels,
  });
  const zField = getSeriesValues('axisZ', axisZValues)[0];

  const bubbleData = getBubbleData(widgetData);

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(containerRef);

  useEffect(() => {
    return () => {
      handleWidgetMouseLeave();
    };
  });

  const selectedAxisXValue = filterField?.value || null;
  const deselectBubbleFilter = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const xFieldId = xField?.id;

  const selectBubbleFilter = useCallback(
    (value: string) => {
      if (selectedAxisXValue?.includes(value)) {
        deselectBubbleFilter();
      } else {
        if (!setFilterField || !value || !xFieldId) return;

        setFilterField(widgetProps.id, xFieldId, [
          {
            operation: '=',
            value: [value],
          },
        ]);
      }
    },
    [selectedAxisXValue, deselectBubbleFilter, setFilterField, widgetProps.id, xFieldId],
  );

  if (!xField) return null;

  const deselectBubble = isActiveFilter ? deselectBubbleFilter : undefined;
  const selectBubble = isActiveFilter ? selectBubbleFilter : undefined;

  const interaction = {
    handleBubbleMouseMove: handleWidgetMouseMove,
    handleBubbleMouseLeave: handleWidgetMouseLeave,
    selectBubble,
    deselectBubble,
    selectedAxisXValue,
  };
  const formattingValues = {
    formatAxisXValue,
    formatAxisYValue,
    formatAxisZValue,
  };
  const fields = {
    xField,
    yFields,
    zField,
  };

  const bubbleSets: BubbleSets = {
    pointMinSize,
    pointMaxSize,
    pointDiffSize,
    axisXHeight,
    axisYWidth,
    pointOffsetCoeff,
    isScaleByValue,
    isNeedToDisplayValue,
    isNeedToDisplayPercent,
    isNeedToDisplayNameSlice,
    isNeedToDisplayTooltip,
    textDirection,
    isNeedToDisplayAxesGuide,
  };

  // if (containerWidth < 10 || containerHeight < 10) return  null;

  return (
    <div className="widget-bubble-chart" ref={containerRef}>
      <Bubble
        width={containerWidth}
        height={containerHeight}
        data={bubbleData}
        fields={fields}
        formattingValues={formattingValues}
        interaction={interaction}
        bubbleSets={bubbleSets}
      />
    </div>
  );
};
