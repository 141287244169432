import { useMemo } from 'react';
import _ from 'lodash';
import { useGetNumberWidgetProperty, useGetStringWidgetProperty } from 'src/hooks/get-properties/useGetWidgetProperty';
import { PanelType, WidgetPropertyType } from '../../../../../enums/widget-type';
import { useRoundingCounts } from '../../../../../hooks/useRoundingCounts';
import {
  getAxisValuesForWidget,
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  getLegendsLabelsForWidgetByAxis,
  getSelectPropertyValue,
  sortColorPalette,
} from '../../helpers';
import { displayValuesPositions } from '../../histogram-graph/properties';
import { textDirections } from '../../../../common/scalable-svg-text';
import { initValueFormatter } from '../../hooks/initValueFormatter';
import { leftAxisSettings } from '../settings';
import { histogramTypes } from '..';
import { WidgetProperties } from '../../../../../slices/types';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { NumberFormat } from '../../../../../enums/number-format';
import { defaultColors } from '../../common/color';
import { getActivePanelItems, getActivePanelItemsNames } from '../../../../../helpers/common-helpers';


const getBarValueFromPercent = (data: any, bar: any) => {
  return _.find(data, (row) => row.x === bar.bar.data.x)[bar.key];
};

interface BarProps {
  widgetProperties: WidgetProperties[];
}

export const useStackBarProps = ({
  widgetProperties,
}: BarProps) => {
  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const axisXValuesFull: PropertyData[] = useMemo(
    () => getActivePanelItems(widgetProperties, PanelType.axisX),
    [widgetProperties],
  );

  const axisXValues: string[] = useMemo(
    () => getActivePanelItemsNames(widgetProperties, PanelType.axisX),
    [widgetProperties],
  );

  const isNeedDisplayAggregationInLegend = useMemo(
    () =>
      getBooleanPropertyValue(WidgetPropertyType.displayAggregationInLegend, widgetProperties),
    [widgetProperties],
  );

  const isNeedToDisplayAxesGuide: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displayAxesGuide, widgetProperties),
    [widgetProperties],
  );

  const axisYValues: string[] = useMemo(
    () => getAxisValuesForWidget(PanelType.axisY, widgetProperties),
    [widgetProperties],
  );

  const legendsLabels = useMemo(
    () =>
      getLegendsLabelsForWidgetByAxis(PanelType.axisY, widgetProperties, isNeedDisplayAggregationInLegend),
    [isNeedDisplayAggregationInLegend, widgetProperties],
  );

  const colorsPaletteState = useMemo(
    () => getJSONPropertyValue(WidgetPropertyType.colorPalette, widgetProperties),
    [widgetProperties],
  );
  
  const currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || defaultColors;

  const displayValuesPosition: string = useMemo(
    () =>
      getSelectPropertyValue(WidgetPropertyType.displayValuesPosition, widgetProperties) ||
      displayValuesPositions.inside,
    [widgetProperties],
  );

  const isNeedToDisplayBarValues = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displayBarValues, widgetProperties),
    [widgetProperties],
  );

  const isNeedToDisplayBarValuesTotal = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displayBarValuestotal, widgetProperties),
    [widgetProperties],
  );

  const isDisplayValueInPercent: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displayValueInPercent, widgetProperties),
    [widgetProperties],
  );

  const axisYTotalValueWidth = useMemo(() => parseInt(
    getInputPropertyValue(WidgetPropertyType.offsetValueTotal, widgetProperties),
  ),
  [widgetProperties]) || 30;

  const isNeedToDisplayInsideBarValues: boolean =
  isNeedToDisplayBarValues &&
  displayValuesPosition === displayValuesPositions.inside || isDisplayValueInPercent;

  const isNeedToDisplayOutsideBarValues: boolean =
  isNeedToDisplayBarValues &&
  displayValuesPosition === displayValuesPositions.outside || isDisplayValueInPercent;

  const textDirection: string =
    getSelectPropertyValue(WidgetPropertyType.TextDirection, widgetProperties) ||
    textDirections.horizontal;

  const isNeedToDisplayTooltip = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displayTooltip, widgetProperties),
    [widgetProperties],
  );

  const axisYLabelWidth = useMemo(
    () =>
      !isNaN(parseInt(getInputPropertyValue(WidgetPropertyType.axisYLabelWidth, widgetProperties))) ?
        parseInt(getInputPropertyValue(WidgetPropertyType.axisYLabelWidth, widgetProperties)) :
        leftAxisSettings.left,
    [widgetProperties],
  );

  const histogramType =
    getSelectPropertyValue(WidgetPropertyType.histogramType, widgetProperties) ||
    histogramTypes.stacked;

  const isNormalized = histogramType === histogramTypes.normalized;
  const isOverlap = histogramType === histogramTypes.overlap;
  const isStacked = histogramType === histogramTypes.stacked;

  const customAxisXLabelHeight: number = useMemo(
    () =>  parseInt(getInputPropertyValue(WidgetPropertyType.axisXLabelHeight, widgetProperties)),
    [widgetProperties],
  );

  const customAxisXLabelWidth: number = useMemo(
    () =>  parseInt(getInputPropertyValue(WidgetPropertyType.axisXLabelWidth, widgetProperties)),
    [widgetProperties],
  );

  const columnCount: number = useMemo(
    () => parseInt(getInputPropertyValue(WidgetPropertyType.displayTopColumn, widgetProperties)),
    [widgetProperties],
  );

  const columnWidth = useMemo(
    () => parseInt(getInputPropertyValue(WidgetPropertyType.columnWidth, widgetProperties)),
    [widgetProperties],
  );

  const columnOffsetCoeff = useMemo(
    () =>
      parseFloat(getInputPropertyValue(WidgetPropertyType.columnOffsetCoeff, widgetProperties)),
    [widgetProperties],
  );

  const columnDiffCoeff = useMemo(
    () =>
      parseFloat(getInputPropertyValue(WidgetPropertyType.columnDiffCoeff, widgetProperties)),
    [widgetProperties],
  );

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const valueTextSettings = {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 26,
    fontFamily: 'Roboto',
  };

  const valueTextSize = useGetNumberWidgetProperty(
    WidgetPropertyType.textSize,
    widgetProperties,
    valueTextSettings.fontSize,
  );

  const valueColorTotal = useGetStringWidgetProperty(
    WidgetPropertyType.valueColorTotal,
    widgetProperties,
    undefined,
  );

  const valueColor = useGetStringWidgetProperty(
    WidgetPropertyType.valueColorHistogram,
    widgetProperties,
    undefined,
  );

  return {
    roundingCount,
    columnWidth,
    customAxisXLabelHeight,
    customAxisXLabelWidth,
    axisYTotalValueWidth,
    isDisplayValueInPercent,
    isNormalized,
    isOverlap,
    isStacked,
    axisYLabelWidth,
    isNeedToDisplayTooltip,
    textDirection,
    isNeedToDisplayBarValuesTotal,
    isNeedToDisplayBarValues,
    displayValuesPosition,
    colorsPaletteState,
    legendsLabels,
    axisYValues,
    axisXValuesFull,
    isNeedToDisplayAxesGuide,
    axisXValues,
    isNeedToDisplayInsideBarValues,
    columnCount,
    histogramType,
    columnDiffCoeff,
    columnOffsetCoeff,
    valueFormat,
    formatByNumber,
    scaleByNumber,
    isNeedDisplayAggregationInLegend,
    isNeedToDisplayOutsideBarValues,
    currentColors,
    valueColor,
    valueColorTotal,
    valueTextSize
  };
};
