import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import CustomDialog, { CustomDialogProps } from '../Dialog/custom-dialog';
import './delete-dialog.css';
import { CustomButton } from '../Button';
import { CustomProgress } from '../Progress';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../components/common/icon-svg/icon-svg';

interface DeleteDialogProps extends CustomDialogProps {
  onDelete: () => void;
  error?: string;
  bodyText?: string;
  isLoading?: boolean;
  isDisableDeleting?: boolean;
  children?: React.ReactNode;
  isTitleWithTooltip?: boolean;
}

const DeleteDialog: React.FC<DeleteDialogProps> = (props) => {
  const {
    isOpen,
    onClose,
    onDelete,
    title,
    bodyText,
    maxWidth,
    children,
    error,
    isLoading,
    isDisableDeleting,
    fullWidth = false,
    isTitleWithTooltip = false,
  } = props;

  return (
    <CustomDialog
      isDisableCloseButton={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      isTitleWithTooltip={isTitleWithTooltip}
    >
      {children && children}
      <div className="delete-dialog">
        <div className="delete-dialog__body">
          <IconSvg svg={IconDictionary.BasketDelete} width={40} height={40} fill='var(--red-normal)' />
          <div className="delete-dialog__title">Вы уверены, что хотите удалить {bodyText}?</div>
          <div className="delete-dialog__subtitle">Это действие нельзя будет отменить</div>

          {error && (
            <div className="delete-dialog__alert">
              <Alert severity="error">
                <AlertTitle>Ошибка!</AlertTitle>
                {error}
              </Alert>
            </div>
          )}
        </div>
        <div className="dialog-buttons">
          <CustomButton
            disabled={isLoading || isDisableDeleting}
            variant="contained"
            secondary
            onClick={onDelete}
            startIcon={isLoading ? <CustomProgress type="circular" style={{ color: 'var(--white)' }} size={20} /> : null}
          >
            Удалить
          </CustomButton>
          <CustomButton disabled={isLoading} variant="outlined" onClick={onClose}>
            Отменить
          </CustomButton>
        </div>
      </div>
    </CustomDialog>
  );
};

export default DeleteDialog;
