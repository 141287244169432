export const lineWidth = 70;
export const labelWidth = 230;
export const zLabelAdditionWidth = 70;
export const zLabelWidth = 460;
export const zAdditionalTransition = 160;
export const zAdditionalContainerWidth = 300;
export const maxLabelHeight = 40;
export const sliceLineLength = 10;
export const defaultLineLength = 20;
export const lineStart = 0;
export const lineRadius = 3;

const lineDiameter = lineRadius * 2;

export const maxLineContainerWidth = lineDiameter + defaultLineLength + lineWidth + labelWidth;
export const maxLineContainerHeight = lineDiameter + defaultLineLength + maxLabelHeight;