import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import { ShowNotificationParams } from '../../components/common/snackbar/hooks';
import MapConnection from '../../types/map-connection';
import {
  getProcessedFieldsList,
  getSchemaList,
  getWhereCondition,
  syncStepViaAlias,
  updateProcessedFieldsViaValidation,
} from '../../components/loader-page/save-result-panel/logic';
import { Operation } from '../../enums/operation';
import { State } from '../types';
import { FileFieldType } from '../../enums/connection-field-type';
import { FileTypesEnum } from '../../enums/fyle-type';
import {
  apiCheckSourceObjectAliases,
  apiGetMapConnection,
  apiSaveMapConnection,
  apiValidate,
} from '../../services/loadersController';
import {
  apiCheckSourceUsageByWidgets,
  apiCreateSource,
  apiGetInfoByTableSource,
} from '../../services/sources';
import {
  apiDeleteSourcesFromCache,
  apiNewSourcesToCache,
  apiResetPushDown,
  apiUpdateSourceMap,
  apiUpdateSourcesFields,
  apiUpdateSourcesToCache,
} from '../../services/widgetController';
import { toggleSavingAction } from '../connection-files-list/connection-files-list';
import { toggleSavingSelectFieldsAction } from '../select-fields-panel/select-fields-panel';
import { toggleSavingNewTableAction } from '../create-new-table/create-new-table';
import { setDeletingAction } from '../main-page/main-page-slice';
import {
  createUnionMappingToDisplay,
  createUnionMappingToSave,
  getSourceData,
  getStepsBySourceId,
} from '../../helpers/loader-page/index';

import { MultiplyStepsOnSource } from '../../helpers/MultiplyStepsOnSource';
import { CommonDictionary, WidgetDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';

const defaultStatus: MapConnection.Status = {
  name: 'DRAFT',
  description: 'Черновик',
};

const mapConnectionInitialState: MapConnection.State = {};

const getStepData = (getState: any, loaderId: number, stepId: string) => {
  return getState().mapConnection[loaderId].steps.find(
    (step: any) => step.id === stepId,
  );
};

export const getFilteredFieldsMapping = (
  fieldsMapping: MapConnection.FieldsMapping[] = [],
  checkedFields: [string],
  currentSourceId: string
) => {
  const newFieldsMapping: MapConnection.FieldsMapping[] = JSON.parse(
    JSON.stringify(fieldsMapping),
  );

  return newFieldsMapping.filter(
    (fieldsMappingItem) =>
      (checkedFields.includes(fieldsMappingItem.sourceFieldName.fieldName) &&
      fieldsMappingItem.sourceFieldName.sourceObjectId === currentSourceId) ||
      (checkedFields.includes(fieldsMappingItem.targetField.fieldName) &&
      fieldsMappingItem.targetField.sourceObjectId === currentSourceId),
  );
};

const getCurrentFilteredFieldsMapping = (
  getState: any,
  loaderId: number,
  processedFields: any,
  stepId: string,
  stepIdsData: any,
  currentSourceId: string,
) => {
  const stepData = getStepData(getState, loaderId, stepId);

  let initialFieldsMapping = stepData?.fieldsMapping || [];

  if (stepData?.operation.type === Operation.Union) {
    const sourceObjectId = stepData.inputSourceObjectsIds[0];
    const targetObjectId = stepData.inputSourceObjectsIds[1];
    initialFieldsMapping =
      createUnionMappingToDisplay(
        initialFieldsMapping, sourceObjectId, targetObjectId
      );
  }

  const checkedFields = processedFields
    .filter((item: any) => item.selected)
    .map(
      (checkedField: any) => checkedField.alias || checkedField.displayedName,
    );

  const fieldsMapping = syncStepViaAlias(
    initialFieldsMapping,
    processedFields,
    stepIdsData,
    stepId,
  );
  const filteredFieldsMapping = getFilteredFieldsMapping(
    fieldsMapping, checkedFields, currentSourceId
  );

  if (stepData?.operation.type === Operation.Union) {
    let sourceProcessedFields;
    const stepSourceObjectId = stepData.inputSourceObjectsIds[0];
    if (stepSourceObjectId === currentSourceId) {
      sourceProcessedFields = processedFields;
    } else {
      sourceProcessedFields =
        getSourceData(getState, loaderId, stepSourceObjectId)?.processedFields;
    }
    return createUnionMappingToSave(
      filteredFieldsMapping, stepData.outputSourceObjectId, sourceProcessedFields
    );
  }

  return filteredFieldsMapping;
};

const getDeletedSourceIds = (
  getState: () => State,
  sourceId: string,
  loaderData: MapConnection.Data,
) => {
  let needToRemoveSource = true;
  let currentSourceIds = [sourceId];
  let currentStepsData: MapConnection.Step[] = getStepsBySourceId(
    getState,
    loaderData.id,
    sourceId,
  );

  const stepTypesWithOutputToDelete = [Operation.Join, Operation.Union];
  let deletedSources: string[] = [];

  while (needToRemoveSource) {
    needToRemoveSource = false;
    deletedSources = [...deletedSources, ...currentSourceIds];
    currentSourceIds = [];

    currentStepsData.forEach((currentStepData) => {
      if (stepTypesWithOutputToDelete.includes(currentStepData?.operation.type)) {
        currentSourceIds.push(currentStepData.outputSourceObjectId);
      }
    });

    currentStepsData = []; // We need only output sources steps next iteration

    if (currentSourceIds.length) {
      currentSourceIds.forEach((id)=> {
        currentStepsData = [
          ...currentStepsData,
          ...getStepsBySourceId(
            getState,
            loaderData.id,
            id,
          )
        ];
      });
      needToRemoveSource = true;
    } else {
      needToRemoveSource = false;
    }
  }
  return deletedSources;
};

const addConnection: CaseReducer<
MapConnection.State,
PayloadAction<MapConnection.Payload.AddConnection>
> = (state, { payload: { loaderId, data } }) => {
  return {
    [loaderId]: data,
  };
};

const createAndSaveId = (newIdsList: any[]) => {
  const newId = uuid();
  newIdsList.push(newId);
  return newId;
};

/* Обновляет карту */
const updateConnection: CaseReducer<
MapConnection.State,
PayloadAction<MapConnection.Payload.UpdateConnection>
> = (
  state,
  { payload: { id, loaderId, loaderGroupId, name, sourceObjects = [] } },
) => ({
  ...state,
  [loaderId]: {
    id,
    loaderGroupId,
    name,
    sourceObjects,
    steps: state[loaderId]?.steps ?? [],
    parameters: state[loaderId]?.parameters ?? [],
    status: state[loaderId]?.status ?? defaultStatus,
  },
});

/* Добавляет операцию на карту */
const addStep: CaseReducer<
MapConnection.State,
PayloadAction<MapConnection.Payload.AddStep>
> = (state, { payload: { loaderId, operation, stepId, sourceIdList } }) => {
  const operationCustomProperties = {
    [Operation.Map]: {
      operation: {
        type: operation,
        operationSubTypes: ['DIRECT_LOAD'],
      },
    },
    [Operation.Union]: {
      operation: {
        type: operation,
        operationSubTypes: ['UNION'],
      },
      inputSourceObjectsIds: sourceIdList,
      outputSourceObjectId: uuid(),
    },
    [Operation.Join]: {
      operation: {
        type: operation,
        operationSubTypes: ['INNER_JOIN'],
      },
      inputSourceObjectsIds: sourceIdList,
      outputSourceObjectId: uuid(),
    },
    [Operation.Filter]: {},
    [Operation.Create]: {},
    // For TypeScript. We will add additional props later
  };
  return {
    ...state,
    [loaderId]: {
      ...state[loaderId],
      steps: [
        ...state[loaderId].steps,
        {
          id: stepId,
          loaderId,
          fieldsMapping: [],
          parameters: [],
          operation: {
            type: operation,
            operationSubTypes: [''],
          },
          inputSourceObjectsIds: sourceIdList.slice(0, sourceIdList.length - 1),
          outputSourceObjectId: sourceIdList[sourceIdList.length - 1],
          ...operationCustomProperties[operation],
        },
      ],
    },
  };
};
/* Обновляет Step */
const updateStep: CaseReducer<
MapConnection.State,
PayloadAction<MapConnection.Payload.UpdateStep>
> = (state, { payload: { loaderId, stepId, data, operationSubTypes } }) => ({
  ...state,
  [loaderId]: {
    ...state[loaderId],
    steps: state[loaderId].steps.map((it) =>
      it.id === stepId
        ? {
          ...it,
          operation: {
            ...it.operation,
            operationSubTypes:
                operationSubTypes || it.operation.operationSubTypes,
          },
          fieldsMapping: data,
        }
        : it,
    ),
  },
});

/* Обновляет Source */
const updateSource: CaseReducer<
MapConnection.State,
PayloadAction<MapConnection.Payload.UpdateSourceObject>
> = (
  state,
  {
    payload: {
      loaderId,
      sourceId,
      name,
      processedFields,
      needRemoveDuplicates,
      needRemoveEmptyStrings,
      needCheckSchema,
      needCache,
      needForWidget,
      schema,
      whereCondition,
      needPushDown
    },
  },
) => ({
  ...state,
  [loaderId]: {
    ...state[loaderId],
    sourceObjects: state[loaderId].sourceObjects.map((it) =>
      it.id === sourceId
        ? {
          ...it,
          name,
          needRemoveDuplicates,
          needRemoveEmptyStrings,
          needCheckSchema,
          processedFields,
          needCache,
          needForWidget,
          schema: schema || it.schema,
          whereCondition,
          needPushDown
        }
        : it,
    ),
  },
});

/* Добавляет Трансформацию */
const addTransformation: CaseReducer<
MapConnection.State,
PayloadAction<any>
> = (state, { payload: { loaderId, sourceId, newProcessedField } }) => ({
  ...state,
  [loaderId]: {
    ...state[loaderId],
    sourceObjects: state[loaderId].sourceObjects.map((it) =>
      it.id === sourceId
        ? {
          ...it,
          processedFields: [...it.processedFields, newProcessedField],
        }
        : it,
    ),
  },
});

const mapConnectionSlice = createSlice({
  name: 'mapConnection',
  initialState: mapConnectionInitialState,
  reducers: {
    addConnection,
    updateConnection,
    addStep,
    updateStep,
    updateSource,
    addTransformation,
  },
});

export const asyncActions = {
  /* Обновляет карту на основе настройки источника */
  validateAndSaveMapWithUpdatedProcessedFields: (
    {
      loaderId,
      sourceObjectId,
      sourceObjectName,
      stepId,
      schema,
      processedFields,
      stepIdsData,
      fieldsMapping,
      needRemoveDuplicates,
      needRemoveEmptyStrings,
      needCheckSchema,
      whereCondition,
      needCache,
      needForWidget,
      needPushDown,
    }: MapConnection.Payload.UpdateProcessedFields,
    validationPassedCallback: () => void = () => {},
    validationNotPassedCallback: (error: string) => void = () => {},
    callPurpose: string,
    needToValidateSourceObjects: boolean
  ) => async (dispatch: Dispatch<any>, getState: () => State) => {
    const multiplyStepsHelper = new MultiplyStepsOnSource(loaderId);
    multiplyStepsHelper.update(sourceObjectId, getState);

    const updatedIds: any[] = [];
    const stepsToUpdateOutput = [Operation.Join, Operation.Union];

    let needToUpdate = true;
    let prevStepData: any;
    let firstIteration = true;

    let currentSourceData: any;
    let currentSourceId: string = sourceObjectId;
    let currentSourceObjectName: string = sourceObjectName;
    let currentStepId: string = stepId;
    let currentFieldsMapping: MapConnection.FieldsMapping[] = fieldsMapping;
    let currentProcessedFields: any = processedFields;
    let currentNeedRemoveDuplicates: boolean = needRemoveDuplicates;
    let currentNeedRemoveEmptyStrings: boolean = needRemoveEmptyStrings;
    let currentNeedCheckSchema: boolean = needCheckSchema;
    let currentSchema: any = schema;
    let currentWhereCondition: any = whereCondition;
    let currentNeedCache: boolean = needCache;
    let currentNeedForWidget: boolean = needForWidget;
    let currentSingleSource = false;
    let currentNeedPushDown: boolean = needPushDown;

    currentFieldsMapping = getCurrentFilteredFieldsMapping(
      getState,
      loaderId,
      currentProcessedFields,
      currentStepId,
      stepIdsData,
      currentSourceId,
    );

    while (needToUpdate) {
      if (!firstIteration) {
        currentSourceId = currentSourceData.id;
        currentSourceObjectName = currentSourceData.name;
        currentNeedCache = currentSourceData.needCache;
        currentNeedForWidget = currentSourceData.needForWidget;
        currentSingleSource = currentSourceData.singleSource;
        currentNeedPushDown = currentSourceData.needPushDown;

        if (prevStepData) {
          currentProcessedFields = getProcessedFieldsList(
            prevStepData.operation.type,
            prevStepData.inputSourceObjectsIds,
            getState().mapConnection[loaderId].sourceObjects,
            currentFieldsMapping,
            currentSourceData.processedFields,
          );

          currentSchema = getSchemaList(
            prevStepData.operation.type,
            prevStepData.inputSourceObjectsIds,
            getState().mapConnection[loaderId].sourceObjects,
            currentFieldsMapping
          );
        } else {
          currentProcessedFields = currentSourceData.processedFields;
          currentSchema = currentSourceData.schema;
        }

        currentStepId = getStepsBySourceId(getState, loaderId, currentSourceId)
          ?.[multiplyStepsHelper.getSourceOperationIndex(currentSourceId)]?.id;
        currentFieldsMapping = getCurrentFilteredFieldsMapping(
          getState,
          loaderId,
          currentProcessedFields,
          currentStepId,
          stepIdsData,
          currentSourceId,
        );
        currentNeedRemoveDuplicates = currentSourceData.needRemoveDuplicates;
        currentNeedRemoveEmptyStrings =
          currentSourceData.needRemoveEmptyStrings;
        currentNeedCheckSchema = currentSourceData.needCheckSchema;
        currentWhereCondition = getWhereCondition(currentSourceData.whereCondition, currentProcessedFields);
      }
      multiplyStepsHelper.update(currentSourceId, getState);
      updatedIds.push(currentSourceId);

      const sourceObjectParams = {
        name: currentSourceObjectName,
        processedFields: currentProcessedFields,
        needRemoveDuplicates: currentNeedRemoveDuplicates,
        needRemoveEmptyStrings: currentNeedRemoveEmptyStrings,
        needCheckSchema: currentNeedCheckSchema,
        schema: currentSchema,
        whereCondition: currentWhereCondition,
        needCache: currentNeedCache,
        needForWidget: currentNeedForWidget,
        singleSource: currentSingleSource,
        needPushDown: currentNeedPushDown,
      };

      if (firstIteration) {
        const sourceObject = getState().mapConnection[loaderId].sourceObjects.find(
          (x) => x.id === sourceObjectId,
        );
        if (sourceObject) {
          const sourceObjectToValidate = { ...sourceObject, ...sourceObjectParams };
          if (needToValidateSourceObjects) {
            try {
              let responseStatus;
              if (needToValidateSourceObjects) {
                const response = await apiValidate(sourceObjectToValidate);

                if (callPurpose === 'save') {
                  sourceObjectParams.processedFields = updateProcessedFieldsViaValidation(
                    processedFields,
                  response?.data?.schema,
                  );
                }

                responseStatus = response.status;
              }

              !sourceObjectParams.needPushDown && apiResetPushDown([sourceObject.id]);
            } catch (e: any) {
              const err = e.response || e;
              if (err.status === 400 || err.status === 500) {
                validationNotPassedCallback(err.data?.message || err.message || 'Ошибка в синтаксисе, проверьте созданные показатели');
                return;
              }
              console.error(e);
              dispatch(toggleSavingSelectFieldsAction(false));
            }
          }
        }
      }

      firstIteration = false;

      dispatch(
        mapConnectionAction.updateSource({
          loaderId,
          ...sourceObjectParams,
          sourceId: currentSourceId,
        }),
      );

      dispatch(
        mapConnectionAction.updateStep({
          data: currentFieldsMapping,
          stepId: currentStepId,
          loaderId,
        }),
      );

      prevStepData = getStepData(getState, loaderId, currentStepId);

      currentSourceData = getSourceData(
        getState,
        loaderId,
        prevStepData?.outputSourceObjectId,
      );

      if (
        currentSourceData &&
        stepsToUpdateOutput.includes(prevStepData?.operation.type)
      ) {
        needToUpdate = true;
      } else {
        currentSourceData = multiplyStepsHelper.getSourceData(getState);
        needToUpdate = Boolean(currentSourceData);
        prevStepData = null;
      }
    }
    const uniqueUpdatedIds = [...new Set(updatedIds)];

    try {
      if (callPurpose === 'save') {
        (await apiSaveMapConnection(
          loaderId,
          getState().mapConnection[loaderId],
        ));
        await apiUpdateSourcesToCache(uniqueUpdatedIds);

        const reloadedData = (await apiUpdateSourceMap(
          loaderId,
        )) as MapConnection.Data;
        if (reloadedData) {
          dispatch(
            mapConnectionAction.addConnection({
              loaderId,
              data: reloadedData,
            }),
          );
        }
      }

      apiUpdateSourcesFields(uniqueUpdatedIds);
      validationPassedCallback();
      dispatch(toggleSavingSelectFieldsAction(false));
    } catch (e: any) {
      const err = e.response || e;
      if (err.status === 400 || err.status === 500) {
        validationNotPassedCallback(err.data?.message || err.message);
        return;
      }
      console.error(e);
      dispatch(toggleSavingSelectFieldsAction(false));
    }
    dispatch(toggleSavingSelectFieldsAction(false));
  },
  /* Обновляет карту на основе результата маппинга */
  saveMapWithUpdatedStep: (
    {
      data,
      stepId,
      loaderId,
      newSourceId,
      operationSubTypes,
    }: MapConnection.Payload.UpdateStep,
    callback: () => void = () => {},
    errorCallback: (error: string) => void = () => {},
  ) => async (dispatch: Dispatch<any>, getState: () => State) => {
    try {
      dispatch(
        mapConnectionAction.updateStep({
          data,
          stepId,
          loaderId,
          operationSubTypes,
        }),
      );

      await apiSaveMapConnection(loaderId, getState().mapConnection[loaderId]);
      // WA нужно для получения singleSource сформированого беком
      dispatch(mapConnectionAction.getMapConnection(loaderId));

      if (newSourceId) {
        await apiNewSourcesToCache([newSourceId]);
      }

      callback();
    } catch (err: any) {
      errorCallback(err?.response?.data?.message);
    }
  },
  /* Получение с карты */
  getMapConnection: (loaderId: number) => async (dispatch: Dispatch<any>) => {
    const data = await apiGetMapConnection(loaderId);
    if (data) {
      dispatch(
        mapConnectionSlice.actions.addConnection({
          loaderId,
          data,
        }),
      );
    }
  },
  /* Создает/обновляет карту на основе полученного списка таблиц  */
  addSourceObjects: (
    {
      loaderId,
      loaderGroupId,
      connectionId,
      tableNames,
      needPushDown,
    }: MapConnection.Payload.AddConnectionByTables,
    callback: () => void = () => {},
    errorCallback?: (e: any) => void,
  ) => async (dispatch: Dispatch<any>, getState: () => State) => {
    try {
      dispatch(toggleSavingAction(true));
      let loaderData: MapConnection.Data = getState().mapConnection[loaderId];
      const newIdsList: any[] = [];
      // TODO разобраться может ли вообще не существовать loaderData
      if (!loaderData) {
        const payload: MapConnection.Payload.UpdateConnection = {
          id: parseInt(uuid()),
          name: CommonDictionary.loadingMapTitle,
          loaderId,
          loaderGroupId,
        };
        dispatch(mapConnectionSlice.actions.updateConnection(payload));
        loaderData = getState().mapConnection[loaderId];
      }

      const newLoaderData = { ...loaderData };

      const requestListForSourceId = tableNames.map((tableName) =>
        apiCreateSource({ connectionId, name: tableName }),
      );
      const sourceIdList = await Promise.all(requestListForSourceId);

      const tableList = tableNames.map((tableName, index) => ({
        tableName,
        sourceId: Number(sourceIdList[index]),
      }));

      const requestList = tableNames.map((tableName) =>
        apiGetInfoByTableSource(connectionId, tableName),
      );
      const tablesInfoList = await Promise.all(requestList);

      const tablesSchemaList = tablesInfoList.map(({ schema }) => schema);

      const processedFieldsList = tablesSchemaList.map((schema) => {
        return schema.map(({ name, type }) => {
          const id = uuid();
          return {
            id,
            initialSourceFieldId: id,
            schemaName: name,
            displayedName: name,
            alias: name,
            transformationTemplate: '',
            transformationOperands: [],
            selected: true,
            category: 'SCHEMA',
            displayedType: type,
          };
        });
      });

      newLoaderData.sourceObjects = [
        ...newLoaderData.sourceObjects,
        ...tableList.map((table, index) => ({
          id: createAndSaveId(newIdsList),
          name: table.tableName,
          initialName: table.tableName,
          sourceId: table.sourceId,
          whereCondition: { template: '', operands: [] },
          needRemoveDuplicates: false,
          needRemoveEmptyStrings: false,
          needCheckSchema: false,
          schema: tablesSchemaList[index] || [],
          parameters: [],
          processedFields: processedFieldsList[index],
          script: null,
          needCache: false,
          needForWidget: true,
          singleSource: null,
          needPushDown,
        })),
      ];

      const responseLoaderData = await apiSaveMapConnection(loaderId, newLoaderData);
      dispatch(
        mapConnectionAction.addConnection({ loaderId, data: responseLoaderData }),
      );
      await apiNewSourcesToCache(newIdsList);

      callback();
    } catch (e: any) {
      errorCallback && errorCallback(e);
      console.error(e);
    } finally {
      dispatch(toggleSavingAction(false));
    }
  },
  addSourceObjectsByNewTablePanel: ({
    loaderId,
    connectionId,
    tableName,
    cb,
  }: MapConnection.Payload.AddSourceObjectByNewTablePanel) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    try {
      dispatch(toggleSavingNewTableAction(true));
      const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
      const newIdsList: any[] = [];
      const newLoaderData = { ...loaderData };

      const sourceId = await apiCreateSource({ connectionId, name: tableName });

      newLoaderData.sourceObjects = [
        ...newLoaderData.sourceObjects,
        {
          id: createAndSaveId(newIdsList),
          name: tableName,
          initialName: tableName,
          sourceId,
          whereCondition: { template: '', operands: [] },
          needRemoveDuplicates: false,
          needRemoveEmptyStrings: false,
          needCheckSchema: false,
          schema: [],
          parameters: [],
          processedFields: [],
          script: null,
          needCache: false,
          needForWidget: true,
          singleSource: null,
          needPushDown: false,
        },
      ];

      const responseLoaderData = await apiSaveMapConnection(loaderId, newLoaderData);
      dispatch(
        mapConnectionAction.addConnection({ loaderId, data: responseLoaderData }),
      );
      await apiNewSourcesToCache(newIdsList);

      cb();
      dispatch(toggleSavingNewTableAction(false));
    } catch (e: any) {
      console.error(e);
      dispatch(toggleSavingNewTableAction(false));
    }
  },
  addSourceObjectsByJoinResult: ({
    loaderId,
    outputStepId,
    schemaList,
    processedFields,
  }: MapConnection.Payload.AddSourceObjectByJoinResult) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    try {
      const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
      const newLoaderData = { ...loaderData };

      newLoaderData.sourceObjects = [
        ...newLoaderData.sourceObjects,
        {
          id: outputStepId,
          name: CommonDictionary.JoinResult,
          initialName: CommonDictionary.JoinResult,
          sourceId: null,
          whereCondition: { template: '', operands: [] },
          needRemoveDuplicates: false,
          needRemoveEmptyStrings: false,
          needCheckSchema: false,
          schema: schemaList || [],
          parameters: [],
          processedFields: processedFields || [],
          script: null,
          needCache: false,
          needForWidget: true,
          singleSource: null,
          needPushDown: false,
        },
      ];

      dispatch(
        mapConnectionAction.addConnection({ loaderId, data: newLoaderData }),
      );
    } catch (e: any) {
      console.error(e);
    }
  },
  addSourceObjectsByUnionResult: ({
    loaderId,
    outputStepId,
    schemaList,
    processedFields,
  }: MapConnection.Payload.AddSourceObjectByJoinResult) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    try {
      const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
      const newLoaderData = { ...loaderData };

      newLoaderData.sourceObjects = [
        ...newLoaderData.sourceObjects,
        {
          id: outputStepId,
          name: CommonDictionary.UnionResult,
          initialName: CommonDictionary.UnionResult,
          sourceId: null,
          whereCondition: { template: '', operands: [] },
          needRemoveDuplicates: false,
          needRemoveEmptyStrings: false,
          needCheckSchema: false,
          schema: schemaList || [],
          parameters: [],
          processedFields: processedFields || [],
          script: null,
          needCache: false,
          needForWidget: true,
          singleSource: null,
          needPushDown: false,
        },
      ];


      dispatch(
        mapConnectionAction.addConnection({ loaderId, data: newLoaderData }),
      );
    } catch (e: any) {
      console.error(e);
    }
  },
  addSourceObjectsBySqlScript: ({
    loaderId,
    connectionId,
    script,
    callback,
    errorCallback,
  }: MapConnection.Payload.AddSourceObjectBySqlScript) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    try {
      dispatch(toggleSavingAction(true));
      const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
      const newIdsList: any[] = [];
      const newLoaderData = { ...loaderData };

      const sourceName = `SQL_${Date.now()}`;
      const sourceId = await apiCreateSource({
        connectionId,
        name: sourceName,
        script,
      });

      const { schema } = await apiGetInfoByTableSource(
        connectionId,
        sourceName,
      );
      const processedFields = schema.map(({ name, type }) => {
        const id = uuid();
        return {
          id,
          initialSourceFieldId: id,
          schemaName: name,
          displayedName: name,
          alias: name,
          transformationTemplate: '',
          transformationOperands: [],
          selected: true,
          category: 'SCHEMA',
          displayedType: type,
        };
      });

      newLoaderData.sourceObjects = [
        ...newLoaderData.sourceObjects,
        {
          id: createAndSaveId(newIdsList),
          name: sourceName,
          initialName: sourceName,
          sourceId,
          whereCondition: { template: '', operands: [] },
          needRemoveDuplicates: false,
          needRemoveEmptyStrings: false,
          needCheckSchema: false,
          schema,
          parameters: [],
          processedFields,
          script,
          needCache: false,
          needForWidget: true,
          singleSource: null,
          needPushDown: true,
        },
      ];

      const responseLoaderData = await apiSaveMapConnection(loaderId, newLoaderData);
      dispatch(
        mapConnectionAction.addConnection({ loaderId, data: responseLoaderData }),
      );
      await apiNewSourcesToCache(newIdsList);

      callback && callback();
    } catch (e: any) {
      errorCallback && errorCallback(e);
      console.error(e);
    } finally {
      dispatch(toggleSavingAction(false));
    }
  },
  addSourceObjectsByFileType: ({
    loaderId,
    connectionId,
    tableNames,
    sourceIdList,
    fileTypeList,
    errorCallback,
    callback,
  }: MapConnection.Payload.AddSourceObjectByCsvOrExcel) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    try {
      dispatch(toggleSavingAction(true));
      const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
      const newIdsList: any[] = [];
      const newLoaderData = { ...loaderData };

      const getParameters = (
        fileType: FileTypesEnum,
        tableName: string,
      ) => {
        const parameters = [];
        if (
          fileType === FileTypesEnum.EXCEL ||
          fileType === FileTypesEnum.CSV ||
          fileType === FileTypesEnum.GOOGLE_SHEETS
        ) {
          parameters.push({
            id: null,
            parameterMetaType: FileFieldType.WithHeader,
            parameterMetaDescription: '',
            value: true,
          });

          if (fileType === FileTypesEnum.EXCEL || fileType === FileTypesEnum.GOOGLE_SHEETS) {
            parameters.push({
              id: null,
              parameterMetaType: FileFieldType.DataAddress,
              parameterMetaDescription: '',
              value: `'${tableName.split('\t').pop()}'!A1`,
            });
          }
        }
        return parameters;
      };
      const tableList = tableNames.map((tableName, index) => ({
        tableName,
        sourceId: sourceIdList[index],
        fileType: fileTypeList[index],
        parameters: getParameters(fileTypeList[index], tableName)
      }));

      const requestList = tableNames.map((tableName) =>
        apiGetInfoByTableSource(connectionId, tableName),
      );
      const tablesInfoList = await Promise.all(requestList);

      const tablesSchemaList = tablesInfoList.map(({ schema }) => schema);

      const processedFieldsList = tablesSchemaList.map((schema) => {
        return schema.map(({ name, type }) => {
          const id = uuid();
          return {
            id,
            initialSourceFieldId: id,
            schemaName: name,
            displayedName: name,
            alias: name,
            transformationTemplate: '',
            transformationOperands: [],
            selected: true,
            category: 'SCHEMA',
            displayedType: type,
          };
        });
      });

      newLoaderData.sourceObjects = [
        ...newLoaderData.sourceObjects,
        ...tableList.map((table, index) => ({
          id: createAndSaveId(newIdsList),
          fileType: table.fileType,
          name: table.tableName,
          initialName: table.tableName,
          sourceId: table.sourceId,
          whereCondition: { template: '', operands: [] },
          needRemoveDuplicates: false,
          needRemoveEmptyStrings: false,
          needCheckSchema: false,
          schema: tablesSchemaList[index] || [],
          parameters: table.parameters,
          processedFields: processedFieldsList[index],
          script: null,
          needCache: false,
          needForWidget: true,
          singleSource: null,
          needPushDown: false,
        })),
      ];

      const responseLoaderData = await apiSaveMapConnection(loaderId, newLoaderData);
      dispatch(
        mapConnectionAction.addConnection({ loaderId, data: responseLoaderData }),
      );
      await apiNewSourcesToCache(newIdsList);

      callback && callback();
    } catch (e: any) {
      errorCallback && errorCallback(e);
      console.error(e);
    } finally {
      dispatch(toggleSavingAction(false));
    }
  },
  addSourceObjectsSoapOdata: ({
    loaderId,
    connectionId,
    tableNames,
    sourceIdList,
    fileType,
    callback,
    errorCallback,
  }: MapConnection.Payload.AddSourceObjectSoap) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    try {
      dispatch(toggleSavingAction(true));
      const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
      const newIdsList: any[] = [];
      const newLoaderData = { ...loaderData };

      const tableList = tableNames.map((tableName, index) => ({
        tableName,
        sourceId: sourceIdList[index],
      }));

      const requestList = tableNames.map((tableName) =>
        apiGetInfoByTableSource(connectionId, tableName),
      );
      const tablesInfoList = await Promise.all(requestList);

      const tablesSchemaList = tablesInfoList.map(({ schema }) => schema);


      const processedFieldsList = tablesSchemaList.map((schema) => {
        return schema.map(({ name, type }) => {
          const id = uuid();
          return {
            id,
            initialSourceFieldId: id,
            schemaName: name,
            displayedName: name,
            alias: name,
            transformationTemplate: '',
            transformationOperands: [],
            selected: true,
            category: 'SCHEMA',
            displayedType: type,
          };
        });
      });

      newLoaderData.sourceObjects = [
        ...newLoaderData.sourceObjects,
        ...tableList.map((table, index) => ({
          id: createAndSaveId(newIdsList),
          fileType,
          name: table.tableName,
          initialName: table.tableName,
          sourceId: table.sourceId,
          whereCondition: { template: '', operands: [] },
          needRemoveDuplicates: false,
          needRemoveEmptyStrings: false,
          needCheckSchema: false,
          schema: tablesSchemaList[index] || [],
          parameters: [],
          processedFields: processedFieldsList[index],
          script: null,
          needCache: false,
          needForWidget: true,
          singleSource: null,
          needPushDown: false,
        })),
      ];

      const responseLoaderData = await apiSaveMapConnection(loaderId, newLoaderData);
      dispatch(
        mapConnectionAction.addConnection({ loaderId, data: responseLoaderData }),
      );
      await apiNewSourcesToCache(newIdsList);

      callback && callback();
    } catch (e: any) {
      errorCallback && errorCallback(e);
      console.error(e);
    } finally {
      dispatch(toggleSavingAction(false));
    }
  },
  deleteSourceObject: (
    loaderId: number,
    sourceId: string,
    callback: (error: string) => void,
  ) => async (dispatch: Dispatch<any>, getState: () => State) => {
    try {
      const loaderData = getState().mapConnection[loaderId];

      const sourceIds: string[] = getDeletedSourceIds(
        getState,
        sourceId,
        loaderData,
      );

      const filteredSourceObjects = loaderData.sourceObjects.filter(
        ({ id }) => !sourceIds.includes(id),
      );
      const filteredSteps = loaderData.steps.filter(
        ({ inputSourceObjectsIds, outputSourceObjectId }) =>
          !sourceIds.every((id) => inputSourceObjectsIds.includes(id)) &&
          !sourceIds.includes(outputSourceObjectId),
      );
      const updatedLoaderData = {
        ...loaderData,
        sourceObjects: filteredSourceObjects,
        steps: filteredSteps,
      };

      const sourceInUseByWidgetsData = await apiCheckSourceUsageByWidgets(
        sourceIds,
      );

      if (sourceInUseByWidgetsData.length) {
        return callback(
          `Элемент используется для построения \n ${WidgetDictionary.oneOf}: ${JSON.stringify(sourceInUseByWidgetsData)}`,
        );
      }

      const responseLoaderData = await apiSaveMapConnection(
        loaderId,
        updatedLoaderData,
      );
      await apiDeleteSourcesFromCache(sourceIds);
      dispatch(
        mapConnectionAction.addConnection({
          loaderId,
          data: responseLoaderData,
        }),
      );

    } catch (err: any) {
      return callback(err.message);
    } finally {
      dispatch(setDeletingAction(false));
    }
  },
  deleteStep: (
    loaderId: number,
    stepId: string,
    callbackFromComponent: (error: string) => void,
  ) => async (dispatch: Dispatch<any>, getState: () => State) => {
    const loaderData = getState().mapConnection[loaderId];
    const currentStep = loaderData.steps.find(({ id }) => stepId === id);
    if (currentStep?.operation.type === Operation.Join || currentStep?.operation.type === Operation.Union) {
      // It will also delete current step
      return dispatch(
        mapConnectionAction.deleteSourceObject(
          loaderId,
          currentStep?.outputSourceObjectId,
          callbackFromComponent,
        ),
      );
    }
    const filteredSteps = loaderData.steps.filter(({ id }) => stepId !== id);
    const updatedLoaderData = { ...loaderData, steps: filteredSteps };

    try {
      const responseLoaderData = await apiSaveMapConnection(
        loaderId,
        updatedLoaderData,
      );
      dispatch(
        mapConnectionAction.addConnection({
          loaderId,
          data: responseLoaderData,
        }),
      );
      dispatch(setDeletingAction(false));
    } catch (errorResponse: any) {
      dispatch(setDeletingAction(false));
      return callbackFromComponent(errorResponse.message);
    }

  },
  updatePublishStatus: ({
    published,
    loaderId,
  }: MapConnection.Payload.UpdatePublishStatus) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
    const newLoaderData = { ...loaderData };
    newLoaderData.published = published;
    await apiSaveMapConnection(loaderId, newLoaderData);
  },
  updateNodesState: ({
    nodesState,
    loaderId,
    isModelDataMode
  }: MapConnection.Payload.UpdateNodesState) => async (
    dispatch: Dispatch<any>,
    getState: () => State,
  ) => {
    const loaderData: MapConnection.Data = getState().mapConnection[loaderId];
    const newLoaderData = JSON.parse(JSON.stringify(loaderData));
    if (!nodesState) return;
    nodesState.forEach((nodeState: any) => {
      if (nodeState.type === 'source') {
        const dataModelPrefixIdCharsCount = 4;
        const id = isModelDataMode ? nodeState.id.substring(0,
          nodeState.id.length - dataModelPrefixIdCharsCount
        ) : nodeState.id;
        const currentSourceData = {
          ...getSourceData(getState, loaderId, id)!,
        };

        const sourceObjectIndex: number = newLoaderData.sourceObjects.findIndex(
          (sourceObject: any) => sourceObject.id === currentSourceData.id,
        )!;

        if (isModelDataMode) {
          currentSourceData.modelViewPosition = nodeState.__rf.position;
        } else {
          currentSourceData.position = nodeState.__rf.position;
        }

        newLoaderData.sourceObjects[sourceObjectIndex] = currentSourceData;
      }

      if (nodeState.type === 'operation') {
        const currentStepData = {
          ...getStepData(getState, loaderId, nodeState.id),
        };

        const stepIndex: any = newLoaderData.steps.findIndex(
          (step: any) => step.id === currentStepData.id,
        );

        currentStepData.position = nodeState.__rf.position;
        newLoaderData.steps[stepIndex] = currentStepData;
      }
    });
    dispatch(
      mapConnectionAction.addConnection({ loaderId, data: newLoaderData }),
    );
  },
};

export const mapConnectionReducer = mapConnectionSlice.reducer;

export const mapConnectionAction = {
  ...mapConnectionSlice.actions,
  ...asyncActions,
};
