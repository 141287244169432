import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificator } from '../components/common/snackbar/hooks';
import { isEditDashboardPage } from '../components/widget-page/charts/helpers';
import { DashboardDictionary } from '../dictionaries/naming-dictionary/naming-dictionary';
import { useDashboardChangesObserver } from '../hooks/useDashboardChangesObserver';
import { setDashboardChangedNotificationDisabledAction } from '../slices/main-page/main-page-slice';
import { State } from '../slices/types';

const NOTIFICATION_BLOCK_TIMEOUT = 30000;

export const DashboardChangesObserver: FC<{children: JSX.Element}> = ({ children }) => {
  const dispatch = useDispatch();

  const currentDashboard = useSelector((state: State) => state.dashboard);
  const isDisabled =
    useSelector((state: State) => state.mainPage.isDashboardChangedNotificationDisabled);

  const { showNotification } = useNotificator();

  const onDashboardHasBeenChanged = useCallback(() => {
    if (!isDisabled && isEditDashboardPage()) {
      showNotification({
        message: `${DashboardDictionary.title} был изменён на сервере, обновите страницу`, variant: 'error' }
      );
    }
  }, [isDisabled, showNotification]);

  useEffect(() => {
    if (isDisabled) {
      setTimeout(() => {
        dispatch(setDashboardChangedNotificationDisabledAction(false));
      }, NOTIFICATION_BLOCK_TIMEOUT);
    }
  }, [dispatch, isDisabled]);

  const dashboardId = isEditDashboardPage() ? currentDashboard.id : undefined;

  useDashboardChangesObserver(dashboardId, onDashboardHasBeenChanged);

  return <>{ children }</>;
};
