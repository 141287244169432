import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import { setModelDataModeAction } from '../../../slices/map-view/map-view';
import IconSvg from '../../common/icon-svg/icon-svg';

import { State } from '../../../slices/types';

import './style.css';
import { CustomButton } from '../../../uikit/Button';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const b = block('loader-controls');

const Controls: React.FC = () => {
  const currentProject = useSelector(
    (state: State) => state.mainPage.currentProject,
  );
  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );

  const loaderId: any = (currentProject && currentProject.loaderId) || '';
  const loaderData = useSelector(
    (state: State) => state.mapConnection[loaderId],
  );

  const [currentTitle, setCurrentTitle] = useState<any>('');

  const dispatch = useDispatch();

  const getTitle = () =>
    isEditableMap ? (
      <span className={b('title')}>{currentProject?.name}</span>
    ) : (
      <div>
        {CommonDictionary.loadingMapTitle} <span className={b('title')}>{loaderData?.name}</span>
      </div>
    );

  useEffect(() => {
    setCurrentTitle(getTitle());
  }, [currentProject, loaderData]);
  return (
    <div className={b()}>
      <div className={b('container')}>
        <div className={b('title')}>{currentTitle}</div>
        <div className={b('buttons-container')}>
          <CustomButton
            variant="outlined"
            startIcon={
              <IconSvg svg={IconDictionary.ChainNode} width={24} height={24} fill="var(--primary-color)" />
            }
            onClick={() => {
              dispatch(setModelDataModeAction(false));
            }}
          >
            Карта источников
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
export default Controls;
