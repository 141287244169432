import clsx from 'clsx';
import React, { useMemo } from 'react';
import GridLayout from 'react-grid-layout';
import './styles.css';
import './react-grid.css';
import { getScaleCoefficient } from '../../../helpers/ui-helper';
import { isMobilehelper } from '../../../utils/mobile-helper';
import { BackdropFullscreen } from '../../common/backdrop-fullscreen';
import QuickFilters from '../filters/quick-filters';
import DashboardWidget from './components/widget';
import { getDashboardItemClasses } from '../../../helpers/dashboard-page/dashboard-widgets';
import { DashboardWidgetsViewProps } from './types';


const DashboardWidgetsPreviewView = ({
  dashboardLayout,
  gridLayoutContainer,
  gridContainerWidth,
  currentWidgetIdFullScreen,
  drillDownDepth,
  increaseDrilldownDepth,
  decreaseDrilldownDepth,
}: DashboardWidgetsViewProps) => {

  return (
    <>
      <BackdropFullscreen open={drillDownDepth > 0} />
      {isMobilehelper() ? (
        <div
          className={clsx('dashboard-preview-mobile', {
            'single-widget': dashboardLayout.length === 1,
          })}
        >
          {dashboardLayout.map((widget: any) => (
            <div className={getDashboardItemClasses(Number(widget.id), currentWidgetIdFullScreen, true)} key={`${widget.i}`}>
              <DashboardWidget
                widgetId={Number(widget.id)}
                isStatic={true}
                increaseDrilldownDepth={increaseDrilldownDepth}
                decreaseDrilldownDepth={decreaseDrilldownDepth}
                drillDownDepth={drillDownDepth}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="dashboards-preview" ref={gridLayoutContainer}>
          <QuickFilters />
          {dashboardLayout.length > 0 && (
            <GridLayout
              layout={dashboardLayout}
              className="dashboard-widgets"
              cols={12}
              isDraggable={false}
              isResizable={false}
              rowHeight={30}
              width={gridContainerWidth}
              containerPadding={[12 * getScaleCoefficient(), 12 * getScaleCoefficient()]}
            >
              {dashboardLayout.map((widget: any) => (
                <div className={getDashboardItemClasses(Number(widget.id), currentWidgetIdFullScreen, false)} key={`${widget.id}`}>
                  <DashboardWidget
                    widgetId={Number(widget.id)}
                    isStatic
                    increaseDrilldownDepth={increaseDrilldownDepth}
                    decreaseDrilldownDepth={decreaseDrilldownDepth}
                    drillDownDepth={drillDownDepth}
                  />
                </div>
              ))}
            </GridLayout>
          )}
        </div>
      )}
    </>
  );
};

export default DashboardWidgetsPreviewView;
