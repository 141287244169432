import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { List } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import block from 'bem-cn';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import History, { StatusName } from '../../../types/history';
import { apiHistoryLoader } from '../../../services/loadersController';
import LoaderHistoryElement from './loader-history-element';
import './loader-history.css';
import { DateInput } from '../special-inputs/inputs/date-input';
import { CustomTabs } from '../../../uikit/Tabs';
import { CustomButton } from '../../../uikit/Button';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import IconSvg from '../icon-svg/icon-svg';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    buttonClose: {
      marginLeft: 'auto',
      border: '1px solid var(--dark-cyan)',
      borderRadius: '4px',
    },
    toggledButton: {
      backgroundColor: 'var(--white)',
      color: 'var(--dark-grey)',
    },
    selectedButton: {
      backgroundColor: 'var(--primary-color)',
      color: 'var(--white)',
    },
  }),
);

const b = block('loader-history');

interface LoaderHistoryProps {
  loaderId: number;
  projectName: string;
  handleClose: () => void;
}

const LoaderHistory = ({
  loaderId,
  projectName,
  handleClose,
}: LoaderHistoryProps) => {
  const [data, setData] = useState<History.element[]>([]);

  const [startFilterDate, setStartFilterDate] = useState<string | null>(null);
  const [finishFilterDate, setfinishFilterDate] = useState<string | null>(null);
  const [isErrorFilter, setIsErrorFilter] = useState<boolean>(false);

  const handleStartDateChange = (date: any) => {
    setStartFilterDate(date);
  };

  const handleFinishDateChange = (date: any) => {
    setfinishFilterDate(date);
  };

  const startFilterDateMoment = moment(startFilterDate, moment.ISO_8601);
  const finishFilterDateMoment = moment(finishFilterDate, moment.ISO_8601);

  const filteredSortedData = useMemo(
    () =>
      data
        .filter(({ startLoad, status }) => {
          const errorStatus =
            !isErrorFilter || status.name === StatusName.ERROR;

          if (
            startFilterDateMoment.isValid() &&
            finishFilterDateMoment.isValid()
          ) {
            return (
              errorStatus &&
              moment(startLoad).isBetween(
                startFilterDateMoment,
                finishFilterDateMoment,
                undefined,
                '[]',
              )
            );
          }
          if (startFilterDateMoment.isValid()) {
            return (
              errorStatus &&
              moment(startLoad).isSameOrAfter(startFilterDateMoment)
            );
          }
          if (finishFilterDateMoment.isValid()) {
            return (
              errorStatus &&
              moment(startLoad).isSameOrBefore(finishFilterDateMoment)
            );
          }
          return errorStatus;
        })
        .sort(
          (prev: History.element, next: History.element) =>
            next.startLoad - prev.startLoad,
        ),
    [data, startFilterDateMoment, finishFilterDateMoment, isErrorFilter],
  );

  const classes = useStyles();

  const fetchHistory = useCallback(() => {
    apiHistoryLoader(loaderId)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error('Ошибка получения данных истории', err.response);
      });
  }, [loaderId]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  const tabs = [
    {
      id: 0,
      title: 'Все',
      onClick: () => setIsErrorFilter(false),
    },
    {
      id: 1,
      title: 'С ошибками',
      onClick: () => setIsErrorFilter(true),
    },
  ];

  return (
    <section className={b()}>
      <div className={b('title-panel')}>
        <h2 className={b('title')}>
          История обновления данных в {CommonDictionary.projectPrepositional} {projectName}
        </h2>
        <CustomButton
          variant="outlined"
          icon={<IconSvg
            svg={IconDictionary.Close}
            fill="var(--dark-grey)"
          />}
          onClick={handleClose}
        />
      </div>

      <div className={b('filter')}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateInput
            label="Начало периода"
            value={startFilterDate}
            onChange={handleStartDateChange}
          />
          <DateInput
            label="Конец периода"
            value={finishFilterDate}
            onChange={handleFinishDateChange}
          />
        </MuiPickersUtilsProvider>

        <div className={b('filter-controls')}>
          <CustomTabs buttons={tabs} size={120} />
        </div>
      </div>

      <div className={b('container')}>
        {data.length ? (
          <List className={classes.list}>
            {filteredSortedData.map((el) => (
              <LoaderHistoryElement element={el} key={el.startLoad} />
            ))}
          </List>
        ) : (
          'Загрузка...'
        )}
      </div>
    </section>
  );
};

export default LoaderHistory;
