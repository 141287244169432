import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { VariableSizeList } from 'react-window';
import { TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { isNull } from 'lodash';
import { TableCell } from '../table-cell';
import { ComplexTableContext } from '../../index';
import {
  getFiltersValues,
  getRowHeight,
  MultilineTextVariant,
} from '../../helper';
import { getSelectPropertyValue } from '../../../helpers';
import { TableBodyProps } from './index';
import { useWidgetMultipleFilters } from '../../../../../../hooks/charts/useWidgetMultipleFilters';

export const useTableBody = ({ tableInstance, containerWidth }: TableBodyProps) => {

  const {
    widgetProperties,
    filterField,
    setMultipleFilterFields,
    isActiveFilter,
    widgetState,
    axisXValues,
    isNeedToDisplayTotal,
    valueSize,
    hiddenColumns,
    displayBorder,
  } = useContext(ComplexTableContext);

  const { prepareRow, rows: rowsWithTotal, columns } = tableInstance;

  const rows = rowsWithTotal.filter((row) => !row?.original?.total);
  const rowTotal = isNeedToDisplayTotal
    ? rowsWithTotal
      .map((row, index) => ({ ...row, rowIndex: index }))
      .find((row) => row?.original?.total)
    : undefined;

  const rowsList = React.useRef<VariableSizeList>(null);

  const { selectData, deselectData } = useWidgetMultipleFilters({
    setMultipleFilterFields,
    isActiveFilter,
    widgetId: widgetState.id,
  });

  const [selectedRowValues, setSelectedRowValues] = useState<string | null>(null);

  const changeSelectedRow = (rowValues: string) => {
    rowValues === selectedRowValues && (setSelectedRowValues(null));
    rowValues !== selectedRowValues && (setSelectedRowValues(rowValues));
  };

  useEffect(() => {
    !isNull(selectedRowValues) && !filterField.value && setSelectedRowValues(null);
  }, [filterField, selectedRowValues]);

  const handleClickRow = (event: React.MouseEvent<HTMLTableRowElement>, rowIndex: number) => {
    event.preventDefault();
    event.stopPropagation();
    const row = rows[rowIndex];
    const rowCells = row.original;
    const rowValues = JSON.stringify(row.values);

    const isSameRow = rowValues === selectedRowValues;

    isActiveFilter && changeSelectedRow(rowValues);

    const { values, selectedFieldsIds } = getFiltersValues(axisXValues, rowCells);
    selectData(values, selectedFieldsIds, isSameRow);
  };

  const Row = ({ index, style, isTotal }: { index: number, style: CSSProperties, isTotal?: boolean }) => {
    const row = isTotal ? rowTotal : rows[index] as any;
    const prevRow = rows[index - 1];
    const nextRow = rows[index + 1];
    const isLastRow = rows.length - 1 === index;
    const rowValues = JSON.stringify(row.values);
    prepareRow(row);
    return (
      <TableRow
        key={row.key}
        {...row.getRowProps([{
          className: clsx(row.className, {
            'widget-table__row': true,
            'widget-table__body-row': true,
            'widget-table__filter-row': selectedRowValues === rowValues
          })
        }])}
        style={style}
        onClick={(event: React.MouseEvent<HTMLTableRowElement>) => isTotal ? undefined : handleClickRow(event, index)}
      >
        {row.cells.map((cell: any, index: number) => {
          const currentPropertyIndex = axisXValues.findIndex(
            (value: any) => `${value.clientUID}` === `${cell.column.id}`,
          );

          const currentProperty = axisXValues[currentPropertyIndex];
          const { isCombineValues } = currentProperty || {};

          if (hiddenColumns.includes(cell.column.id)) return null;

          const isCombinedStartValue = isCombineValues && (nextRow?.cells[index]?.value?.value === cell?.value?.value);
          const isCombinedValue = isCombineValues && (prevRow?.cells[index]?.value?.value === cell?.value?.value);

          return (
            <TableCell
              isCombinedStartValue={isCombinedStartValue}
              isCombinedValue={isCombinedValue}
              isDisplayBorder={displayBorder}
              key={cell.key}
              cell={cell}
              cellIndex={index}
              loading={false}
              isTotal={isTotal}
              isLastRow={isLastRow}
            />
          );
        })}
      </TableRow>
    );
  };

  const clearListCash = () => {
    rowsList.current && rowsList.current.resetAfterIndex(0);
  };

  const getMinTableWidth = (columns: any[]) => {
    let width = 0;
    columns.forEach((column) => {
      const columnWidth = hiddenColumns.includes(column.id) ? 0 : column.totalWidth || getMinTableWidth(column.columns);
      width += columnWidth;
    });

    return width;
  };

  const minTableWidth = getMinTableWidth(columns);

  const width = useMemo(() => Math.max(minTableWidth, containerWidth), [minTableWidth, containerWidth]);

  const showMultilineText: MultilineTextVariant = useMemo(
    () => getSelectPropertyValue('showMultilineText', widgetProperties) as MultilineTextVariant,
    [widgetProperties],
  );

  useEffect(() => {
    clearListCash();
  }, [width, showMultilineText, valueSize]);

  const rowHeight = getRowHeight(showMultilineText, valueSize);
  const containerOffset = 2;
  const headerHeight = document.getElementById(`table-head-${widgetState.id}`)?.clientHeight || 0;

  return {
    rowsList,
    headerHeight,
    rows,
    rowTotal,
    rowHeight,
    width,
    containerOffset,
    Row,
    deselectData,
    isActiveFilter,
  };
};
