import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert } from '@material-ui/lab';
import { isEqual } from 'lodash';
import React, {
  createContext,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CommonLoader from '../../components/common/common-loader/common-loader';
import { ParametersPanel } from '../../components/common/parameters/parameters-panel';
import { useNotificator } from '../../components/common/snackbar/hooks';
import Widget from '../../components/widget-page/charts';
import { isNewWidgetPage } from '../../components/widget-page/charts/helpers';
import { DropdownLayout } from '../../components/widget-page/dropdown-layout';
import {
  getDefaultOrderDirection,
  getParsedAxisValues,
} from '../../components/widget-page/dropdown-layout/helpers/helpers';
import {
  getPropertiesWithTableHeader,
  repairRestrictionFilterProperty,
  repairTableProperties,
} from '../../components/widget-page/dropdown-layout/helpers/itemset-helpers';
import { PropertyData } from '../../components/widget-page/dropdown-layout/helpers/Property';
import { usePanelsValues } from '../../components/widget-page/dropdown-layout/hooks';
import WidgetsDropDown from '../../components/widget-page/dropdown-panel/container';
import {
  isFilledRequiredAxes,
  prepareAxisChanges,
  prepareAxisRestrictions,
} from '../../components/widget-page/helpers';
import WidgetSettings from '../../components/widget-page/settings';
import WidgetSidebar from '../../components/widget-page/sidebar';
import WidgetMapSidebar from '../../components/widget-page/sidebar/map-sidebar';
import WidgetCarousel from '../../components/widget-page/widget-carousel/widget-carousel';
import WidgetTypes from '../../components/widget-page/widget-types';
import { WidgetDictionary } from '../../dictionaries/naming-dictionary/naming-dictionary';
import { PanelType, WidgetType } from '../../enums/widget-type';
import { apiGetProject } from '../../services/projectsController';
import {
  apiGetWidgetById,
  apiGetWidgetTypes,
} from '../../services/widgetController';
import {
  setCurrentProjectAction,
  setCurrentProjectWidgetDataAction,
  setCurrentProjectWidgetErrorAction,
  setDataCacheClearedAction,
  setDataCacheLoadingAction,
} from '../../slices/main-page/main-page-slice';
import {
  SystemSettings,
  Project,
  State,
  Widget as IWidget,
  WidgetProperties,
} from '../../slices/types';
import {
  initialState as initialWidgetState,
  setCurrentWidgetAction,
  setWidgetNameAction,
  setWidgetPropsAction,
} from '../../slices/widget/widget';
import { PageParams } from '../../types/meta';
import WidgetsTypes from '../../types/widgets';
import {
  getDefaultWidgetProps,
  getEnumKeys,
  getSettingWidgetProps,
} from '../../utils/functions';
import './widget-page.css';
import { CustomProgress } from '../../uikit/Progress';

const WIDGET_TYPE_FOR_NEW_WIDGETS = WidgetType.TABLE;

interface WidgetLayoutdProps {
  activeWidgetType: WidgetType;
  setActiveWidgetType:  React.Dispatch<React.SetStateAction<WidgetType>>;
  isDragging: boolean;
  isDisableSidebar: boolean;
  isLoading: boolean;
  isReloading: boolean;
  isActive: boolean;
  error: string | null;
  isWidgetFullScreen: boolean;
  widgetErrorText: string;
}

const WidgetLayout: FC<WidgetLayoutdProps> = memo(
  ({
    activeWidgetType,
    setActiveWidgetType,
    isDragging,
    isDisableSidebar,
    isLoading,
    isReloading,
    error,
    isWidgetFullScreen,
    widgetErrorText,
    isActive,
  }) => {

    return (
      <>
        <div className="widget-layout">
          {activeWidgetType === 'MAP' ? (
            <WidgetMapSidebar
              isDragging={isDragging}
            />
          ) : (
            <WidgetSidebar
              isDragging={isDragging}
              isDisable={isDisableSidebar}
            />
          )}
          <div className="widget-content">
            <WidgetTypes
              setActiveWidgetType={setActiveWidgetType}
              activeWidgetType={activeWidgetType}
            />

            <div className="widget-main">
              <div className="widget-main__preview">
                <div className="widget-main__dropdown">
                  <WidgetsDropDown
                    activeWidgetType={activeWidgetType}
                  />
                </div>
                {isLoading || isReloading ? (
                  <CustomProgress type="linear" />
                ) : (
                  <Box height={4} />
                )}
                {error && (
                  <Box mt="10px">
                    <Alert severity="error">
                      Во время загрузки {WidgetDictionary.few} произошла ошибка
                    </Alert>
                  </Box>
                )}

                <div className="widget-main__preview-container">
                  <h3 className="widget-main__title">Предпросмотр</h3>
                  <div
                    className={`widget-main__graphic${
                      isWidgetFullScreen ? ' fullscreen' : ''
                    }`}
                  >
                    <Widget
                      errorText={widgetErrorText}
                      type={activeWidgetType}
                      isLoading={isLoading || isReloading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <WidgetSettings
            activeWidgetType={activeWidgetType}
          />
          <ParametersPanel />
        </div>
        <WidgetCarousel />
        {!isActive && <CommonLoader />}
      </>
    );
  },
);

WidgetLayout.displayName = 'WidgetLayout';


interface WidgetLayoutContainerProps {
  setIsReady: (value: boolean) => void;
  isReloading: boolean;
}

const WidgetLayoutContainer: FC<WidgetLayoutContainerProps> = memo(
  ({ setIsReady, isReloading }) => {
    const [activeWidgetType, setActiveWidgetType] = useState<WidgetType>(
      WidgetType.NONE,
    );
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const isActive = useSelector((state: State) => state.mainPage.isActive);
    const dispatch = useDispatch();
    const params: PageParams = useParams();
    const loaderId: number =
      useSelector((state: State) => state.mainPage?.currentProject?.loaderId) ??
      0;
    const settingsData: SystemSettings | null = useSelector(
      (state: State) => state.mainPage.systemSettings,
    );

    const { showNotification } = useNotificator();

    const isCacheReloading =
      useSelector((state: State) => state.mainPage?.isDataCacheLoading) ||
      false;

    const isDataCacheCleared: boolean =
      useSelector((state: State) => state.mainPage?.isDataCacheCleared) ?? 0;
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<null | string>(null);

    const widgetData = useSelector(
      (state: State) => state.mainPage.currentProjectWidgetData,
    );

    const widgetErrorText = useSelector(
      (state: State) => state.mainPage.widgetError,
    );
    const widget = useSelector((state: State) => state.widget);

    const [isInitWidget, setIsInitWidget] = useState<boolean>(
      isEqual(widget, initialWidgetState),
    );

    const isWidgetFullScreen = useSelector(
      (state: State) => state.mainPage.isWidgetFullScreen,
    );

    const isDisableSidebar = widget.type === 'HTML';

    const widgetTypes = useContext(WidgetsTypesContext);

    useEffect(() => {
      if (!params.widgetId && widgetTypes.length) {
        setActiveWidgetType(WIDGET_TYPE_FOR_NEW_WIDGETS);

        const initialNewWidget = {
          properties: getDefaultWidgetProps(
            widgetTypes,
            WIDGET_TYPE_FOR_NEW_WIDGETS,
          ),
          name: WidgetDictionary.title,
          sourceId: '',
          type: WIDGET_TYPE_FOR_NEW_WIDGETS,
          shared: false,
          pushDown: false,
          needCache: false,
          validationErrors: null,
        };

        dispatch(setCurrentWidgetAction(initialNewWidget));
      }
    }, [dispatch, params.widgetId, widgetTypes]);

    useEffect(() => {
      setError(null);
    }, [widgetData, params.widgetId, params.projectId]);

    useEffect(() => {
      if (!isInitWidget) {
        setError(null);
      } else {
        setIsInitWidget(isEqual(widget, initialWidgetState));
      }
    }, [widget.type]);

    useEffect(() => {
      if (!loaderId && !params.widgetId && !params.projectId) {
        history.push('/');
      }

      setIsLoading(true);
      setIsReady(false);

      apiGetProject(Number(params.projectId))
        .then((project: Project) => {
          dispatch(setCurrentProjectAction(project));
          if (params.widgetId && loaderId !== 0) {
            dispatch(setWidgetNameAction(''));
            dispatch(setCurrentProjectWidgetDataAction(null));
            dispatch(setCurrentProjectWidgetErrorAction(''));
            return Promise.all([
              apiGetWidgetById(Number(params.widgetId)).then((result: IWidget) => {
                dispatch(setCurrentWidgetAction(result));
                setIsReady(true);
              }),
            ]);
          }
          setIsReady(true);
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
        });
    }, [params.widgetId, params.projectId, loaderId]);

    useEffect(() => {
      isDataCacheCleared &&
        params.widgetId &&
        Promise.all([
          apiGetWidgetById(Number(params.widgetId)).then((result: any) => {
            dispatch(setCurrentWidgetAction(result));
          }),
        ]).finally(() => {
          if (isCacheReloading) {
            dispatch(setDataCacheLoadingAction(false));
          }
          dispatch(setDataCacheClearedAction(false));
        });
    }, [
      dispatch,
      isCacheReloading,
      isDataCacheCleared,
      params.widgetId,
      showNotification,
    ]);

    useEffect(() => {
      if (widget && widget.type !== WidgetType.NONE) {
        setActiveWidgetType(widget.type);
      }
    }, [widget]);

    const isNewWidget = isNewWidgetPage();

    const SYSTEM_UI_NAME = settingsData?.SYSTEM_UI_NAME || '';

    useEffect(() => {
      document.title = `${SYSTEM_UI_NAME} | ${
        isNewWidget
          ? WidgetDictionary.newWidget
          : `${WidgetDictionary.title}: ${widget.name}`
      }`;
      return () => {
        document.title = SYSTEM_UI_NAME;
      };
    }, [widget.name, isNewWidget, SYSTEM_UI_NAME]);

    return (
      <WidgetLayout
        activeWidgetType={activeWidgetType}
        setActiveWidgetType={setActiveWidgetType}
        isDragging={isDragging}
        isDisableSidebar={isDisableSidebar}
        isLoading={isLoading}
        isReloading={isReloading}
        isActive={isActive}
        error={error}
        isWidgetFullScreen={isWidgetFullScreen}
        widgetErrorText={widgetErrorText}
      />
    );
  },
);

export const WidgetsTypesContext = createContext<WidgetsTypes.Type[]>([]);

WidgetsTypesContext.displayName = 'WidgetsTypesContext';

const WidgetPage = () => {
  const [widgetTypes, setWidgetTypes] = useState<WidgetsTypes.Type[]>([]);

  useEffect(() => {
    apiGetWidgetTypes().then((types: WidgetsTypes.Type[]) =>
      setWidgetTypes(types),
    );
  }, []);

  return (
    <WidgetsTypesContext.Provider value={widgetTypes}>
      <DropdownLayout>
        {({ isReloading, setIsReady }: any) => {
          return (
            <WidgetLayoutContainer
              isReloading={isReloading}
              setIsReady={setIsReady}
            />
          );
        }}
      </DropdownLayout>
    </WidgetsTypesContext.Provider>
  );
};

export default WidgetPage;
