import { cloneDeep } from 'lodash';
import { DashboardProperty, WidgetProperties } from '../../slices/types';
import { PanelType } from '../../enums/widget-type';
import { getParsedAxisValues } from '../../components/widget-page/dropdown-layout/helpers/helpers';
import { mergeWidgetProperties } from '../../utils/functions';
import { DashboardPropertyType, FilterProperty } from '../../enums/dashboard-properties';
import { BoundFilterGroupProps, Filter, FilterGroupProps } from '../../components/dashboard-page/hooks';
import { getFilterSettingValue } from './filters-helper';
import { FiltersSettingsTypes } from '../../components/dashboard-page/settings';
import { convertDashboardPropertiesForDeepDD } from './dashboard-widgets';
import { BoundType } from '../../components/dashboard-page/settings/enums';

export type SetOverriddenProperties = (properties: WidgetProperties[]) => void;

export const isHaveSorting = (field: any) => ['ASC', 'DESC'].includes(field.orderDirection);

// если на виджете уже есть своя сортировка, то добавляем ось с ней в overriddenProperties
export const checkDefaultSorting = (
  result: WidgetProperties[],
  widgetProperties: WidgetProperties[],
  overriddenProperties: WidgetProperties[],
) => {

  let sortingResult = cloneDeep(result);

  const checkAxis = (panelType: PanelType) => {
    const axis: any[] = getParsedAxisValues(
      panelType,
      widgetProperties,
    );
    const overriddenAxisX: any[] = getParsedAxisValues(
      panelType,
      overriddenProperties,
    );
    if (!overriddenAxisX.length && axis.some(isHaveSorting)) {
      sortingResult = mergeWidgetProperties(sortingResult, [
        {
          name: panelType,
          value: JSON.stringify(axis),
        },
      ]);
    }
  };
  checkAxis(PanelType.axisX);
  checkAxis(PanelType.axisY);

  return sortingResult;
};

// выделяем изменения параметров которые влияют на повторный запрос данных для конкретного виджета
export const getEffectedChangeForDashboardProperties = (
  widgetId: number,
  dashboardParams: DashboardProperty[],
) => {
  const propNames = [
    DashboardPropertyType.autoDrillDownGroups,
    DashboardPropertyType.autoBoundGroups,
    DashboardPropertyType.manualBoundGroups,
  ];

  const properties: DashboardProperty[] = [];

  propNames.forEach((propName) => {
    const groups: BoundFilterGroupProps[] = JSON.parse(
      (dashboardParams.find((prop) => prop.name === propName)?.value as string) ||
      '[]',
    );

    const effectGroups = groups
      .filter(
        (group) =>
          group.participantFilterWidgets.find(
            (part) =>
              part.widgetId === widgetId && part.isActive && !part.isMaster,
          ) &&
          group.isActive &&
          group.selectedSourceFieldId &&
          group.filters,
      )
      .sort((a, b) => ((a.id || 0) > (b.id || 0) ? 1 : -1));

    properties.push({
      name: propName,
      value: JSON.stringify(effectGroups),
    });
  });

  const resultProperties: (DashboardProperty | { value: Filter[] }[])[] =
    properties.sort((a, b) => ((a.name || 0) > (b.name || 0) ? 1 : -1));

  const filtersProperty = dashboardParams.find((prop) => prop.name === DashboardPropertyType.filters);
  if (filtersProperty) {
    const filtersValues: FilterGroupProps[] = JSON.parse(
      filtersProperty.value as string,
    );

    const values = filtersValues.filter(
      (filtersValue) =>
        filtersValue.actual &&
        filtersValue.widgetFilters.find(
          (widget) => widget.actual && widget.widgetId === widgetId,
        ),
    ).map((filtersValue) => ({
      id: filtersValue.id,
      actual: filtersValue.actual,
      value: filtersValue.value,
      function: getFilterSettingValue(
        filtersValue,
        FilterProperty.function,
      )
    }));
    resultProperties.push({
      name: DashboardPropertyType.filters,
      value: JSON.stringify(values),
    });
  }

  return resultProperties;
};

export const getActiveFilterType = (isActiveDrilldownFilter: boolean) => {
  if (isActiveDrilldownFilter) {
    return FiltersSettingsTypes.DRILLDOWN;
  }

  return FiltersSettingsTypes.CHAIN;
};

export const enhanceDashboardParams = (
  dashboardParams: DashboardProperty[] = [],
  overriddenProperties: WidgetProperties[] = [],
  widgetId: number,
  widgetsWithActiveLegend: any[] = [],
  dashboardFiltersGroups: FilterGroupProps[],
  isDrillDown: boolean,
  widgetProps: any,
  dashboardId: number | undefined,
) => {
  // включаем все фильтры для открытого виджета по цепочке для DD, от певого виджета до последнего открытого в DD
  const dashboardParamsCopy = convertDashboardPropertiesForDeepDD(
    cloneDeep(dashboardParams),
    widgetId,
  );

  const activeLegendIndex = widgetsWithActiveLegend?.findIndex(
    (item: any) => item.id === widgetId,
  );

  const isWidgetWithActiveLegend =
    widgetsWithActiveLegend && activeLegendIndex !== -1;

  const widgetOverriddenProperties: WidgetProperties[] =
    cloneDeep(overriddenProperties) || [];

  let result = widgetOverriddenProperties;

  if (isWidgetWithActiveLegend) {
    result = mergeWidgetProperties(widgetOverriddenProperties, [
      {
        name: 'activeLegend',
        value: widgetsWithActiveLegend[activeLegendIndex].activeLegend,
      },
    ]);
  }

  // Фильтрация на виджете (Overridden) возможна только если вЫключена группа фильтров
  // (в которую входит этот фильтр) на дашборде или сам фильтр в этой группе.
  // Убираем фильтрацию в widgetOverriddenProperties в таком случае у этих фильтров.
  const axisFilter: any[] = getParsedAxisValues(PanelType.axisFilter, result);
  if (axisFilter.length) {
    result = mergeWidgetProperties(result, [
      {
        name: PanelType.axisFilter,
        value: JSON.stringify(
          axisFilter.map((field) => {
            const isGroupActive = Boolean(
              dashboardFiltersGroups.find(
                (group) =>
                  group.actual &&
                  group.widgetFilters.find(
                    (widgetFilter) =>
                      widgetFilter.id === (field.id || field.widgetFieldId) &&
                      widgetFilter.actual,
                  ),
              ),
            );
            return {
              ...field,
              filter: !isDrillDown && isGroupActive ? [] : field.filter,
            };
          }),
        ),
      },
    ]);
  }

  result = checkDefaultSorting(
    result,
    widgetProps.properties,
    overriddenProperties,
  );

  // Workaround: BIDEV-3489 для сортировки - если есть сортировка в axisY,
  // то соортрировку нужно убрать в axisX
  // такойже WA есть перед отправкой /preview

  const axisY: any[] = getParsedAxisValues(PanelType.axisY, result);

  if (axisY.some(isHaveSorting)) {
    const axisXWidget: any[] = getParsedAxisValues(
      PanelType.axisX,
      widgetProps.properties,
    );
    const axisXOverridden: any[] = getParsedAxisValues(
      PanelType.axisX,
      widgetProps.properties,
    );
    const axisX = axisXOverridden.length ? axisXOverridden : axisXWidget;

    result = mergeWidgetProperties(result, [
      {
        name: PanelType.axisX,
        value: JSON.stringify(
          axisX.map((field) => ({ ...field, orderDirection: null })),
        ),
      },
    ]);
  }
  // End Workaround: BIDEV-3489

  return {
    dashboardProperties: dashboardParamsCopy,
    widgetOverriddenProperties: result,
    dashboardId,
  };
};

export const getDrillDownGroupsType = (dashboardParams?: DashboardProperty[]) => {
  return JSON.parse(
    (dashboardParams?.find(
      (param) => param.name === BoundType.manualDrillDownGroups,
    )?.value as string) || '[]',
  )?.length > 0
    ? BoundType.manualDrillDownGroups
    : BoundType.autoDrillDownGroups;
};
