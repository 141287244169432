import { InputAdornment, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useEditableTextContainer } from './useEditableTextContainer';
import IconSvg from '../../icon-svg/icon-svg';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import './index.css';
import { TextInput } from '../inputs/text-input';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export interface EditableTextContainerProps {
  text: string;
  callback: (text: string) => void;
  textClassName?: string;
  textContainerClassName?: string;
}

export const EditableTextContainer = ({
  text,
  callback,
  textClassName = '',
  textContainerClassName = '',
}: EditableTextContainerProps) => {
  const {
    isEditMode,
    editableText,
    setEditableText,
    handleSave,
    handleReset,
    setIsEditMode,
  } = useEditableTextContainer({
    text,
    callback,
  });

  return (
    <div className={clsx(textContainerClassName, 'editable-text-container')}>
      {!isEditMode && (
        <>
          <CustomTooltip title={editableText}>
            <Typography
              className={clsx(textClassName, 'editable-text-container__text')}
            >
              {editableText}
            </Typography>
          </CustomTooltip>
          <CustomIconButton
            iconButtonProps={{
              children: <IconSvg svg={IconDictionary.EditFile} fill='var(--dark-grey)' />,
              onClick: () => setIsEditMode((prev) => !prev),
            }}
            type={IconButtonType.secondary}
          />
        </>
      )}
      {isEditMode && (
        <TextInput
          label="Введите название"
          value={editableText}
          handleChange={(event) => setEditableText(event.target.value)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                className="editable-text-container__input-actions"
              >
                <CustomIconButton
                  iconButtonProps={{
                    children: <IconSvg svg={IconDictionary.RoundedCheck} fill="var(--success-color)" />,
                    onClick: handleSave,
                  }}
                  type={IconButtonType.withoutEffects}
                />
                <CustomIconButton
                  iconButtonProps={{
                    children:  <IconSvg svg={IconDictionary.CancelEdit} fill="var(--dark-grey)" />,
                    onClick: handleReset,
                  }}
                  type={IconButtonType.secondary}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};
