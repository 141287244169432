import Connection from '../../../../../../types/connection';

import { fieldName } from './data';

const parsingDataAddressString = (
  string: string,
): { start: string; end: string }[] => {
  return string.split(';').map((row) => {
    const onlyRange = row.split('!').pop();
    const start = onlyRange?.split(':')[0] || 'A1';
    const end = onlyRange?.split(':')[1] || '';
    return { start, end };
  });
};

const getDataAddressValue = (
  string: string | undefined,
): { start: string; end: string }[] => {
  if (string) {
    return parsingDataAddressString(string);
  }
  return [{ start: '', end: '' }];
};

export const getDataAddressString = (
  value: { start: string; end: string }[],
  name: string,
): string => {
  let rangeString = '';
  value.forEach((rangeElement, index) => {
    const start = rangeElement.start.trim() || 'A1';
    const end = rangeElement.end.trim();
    const firstDelimiter = index === 0 ? '' : ';';
    const secondDelimiter = end ? ':' : '';
    rangeString += `${firstDelimiter}'${name}'!${start}${secondDelimiter}${end}`;
  });
  return rangeString;
};

const getCheckboxValue = (value: any) => {
  if (value && value !== 'false') {
    return true;
  }
  return false;
};

export const getInitialValues = (
  data: Connection.FileParam[],
  parameters: Record<string, string>[],
): Record<string, string | boolean | { start: string; end: string }[]> => {
  return data.reduce((acc, item) => {
    if (item.type === fieldName.WITH_HEADER) {
      const withHeaderValue = getCheckboxValue(
        parameters.find(
          (param) => param.parameterMetaType === fieldName.WITH_HEADER,
        )?.value,
      );
      return { ...acc, ...{ [fieldName[item.type]]: withHeaderValue } };
    }
    if (item.type === fieldName.DATA_ADDRESS) {
      const dataAddressValue = getDataAddressValue(
        parameters.find(
          (param) => param.parameterMetaType === fieldName.DATA_ADDRESS,
        )?.value,
      );
      return { ...acc, ...{ [fieldName[item.type]]: dataAddressValue } };
    }
    return { ...acc, ...{ [fieldName[item.type]]: '' } };
  }, {});
};
