import { useContext, useEffect } from 'react';
import { UpdateLoaderStatusContext } from '../providers/UpdateLoaderStatusProvider';


export const useUpdateDataAfterChangeWidgetData = (widgetId: number | undefined, callbackRefreshData: () => void) => {

  const { addWCSubscribe, deleteWCSubscribe } = useContext(UpdateLoaderStatusContext);

  useEffect(() => {
    if (widgetId) {
      addWCSubscribe({
        widgetId,
        func: (widgetIds) => {
          if (widgetIds.includes(widgetId)) {
            callbackRefreshData();
          }
        },
      });
    }
    return () => {
      if (widgetId) {
        deleteWCSubscribe(widgetId);
      }
    };
  }, [addWCSubscribe, callbackRefreshData, deleteWCSubscribe, widgetId]);
};
