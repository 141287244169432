import { AxiosResponse } from 'axios';
import {
  GlobalParameter,
  ParameterValidator,
  ProjectParameter, ProjectParameterValue,
} from '../slices/types';
import { apiBI } from '../utils/api';

export const apiGetGlobalParameters = async (): Promise<GlobalParameter[]> => {
  try {
    const { data } = await apiBI.get('parameters/global');
    return data;
  } catch (error: any) {
    console.error(`Request failed: ${error}`);
    throw error;
  }
};

export const apiGetParameterValidators = async (): Promise<
ParameterValidator[]
> => {
  try {
    const response: AxiosResponse<ParameterValidator[]> = await apiBI.get(
      '/parameters/local/available-types',
    );
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiGetParameters = async (
  projectId: number,
): Promise<ProjectParameter[]> => {
  try {
    const response: AxiosResponse<ProjectParameter[]> = await apiBI.get(
      `/parameters/local/by-project/${projectId}`,
    );
    return response.data;

  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiGetParametersOnDashboard = async (
  dashboardId: number,
): Promise<ProjectParameter[]> => {
  try {
    const response: AxiosResponse<ProjectParameter[]> = await apiBI.get(
      `/parameters/local/by-dashboard/${dashboardId}`,
    );
    return response.data;

  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiAddParameter = async (
  projectId: number,
  parameter: Omit<ProjectParameter, 'parameterId'>,
): Promise<ProjectParameter> => {
  try {
    const response: AxiosResponse<ProjectParameter> = await apiBI.post(
      `/parameters/local/by-project/${projectId}`,
      parameter,
    );
    return response.data;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiEditParameter = async (
  {
    parameterId,
    parameter,
  }: {
    parameterId: number;
    parameter: ProjectParameter;
  }
): Promise<ProjectParameter> => {
  try {
    await apiBI.put(
      `/parameters/local/update/${parameterId}`,
      parameter,
    );
    return parameter;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiEditParameterValues = async (
  projectId: number,
  parameterValues: ProjectParameterValue[],
): Promise<ProjectParameterValue[]> => {
  try {
    await apiBI.put(
      `parameters/local/by-project/${projectId}`,
      parameterValues,
    );
    return parameterValues;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiEditParameterValuesOnDashboard = async (
  dashboardId: number,
  parameterValues: ProjectParameterValue[],
): Promise<ProjectParameterValue[]> => {
  try {
    await apiBI.post(
      `/parameters/local/by-dashboard/${dashboardId}`,
      parameterValues,
    );
    return parameterValues;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw error;
  }
};

export const apiDeleteParameter = async ({
  parameterId,
  force = false,
}: {
  parameterId: number;
  force: boolean;
}): Promise<number> => {
  try {
    await apiBI.delete(`parameters/local/${parameterId}`, {
      params: { force },
    });
    return parameterId;
  } catch (error: any) {
    console.error('Request failed:', error.response);
    throw new Error(error.response?.data?.message || 'Delete Parameter Error');
  }
};
