import React, { useRef, useState } from 'react';
import useMeasure from 'react-use-measure';
import { getScaleCoefficient } from '../../../helpers/ui-helper';
import { useEventListener } from '../../../hooks/useEventListener';
import './widget-tooltip.css';
import { dispatchRootEvent } from '../../../utils/root-helper';

export type WidgetTooltipData = {
  attrX: string;
  valueX: string;
  attrY?: string;
  valueY?: string;
  attrZ?: string;
  valueZ?: string;
  list?: {
    attrY: string;
    valueY: string;
    color: string;
  }[];
  slice?: string;
};

export const getTooltipHandler = (
  ref: React.MutableRefObject<HTMLDivElement>,
) => {
  const handleWidgetMouseMove = (event: any, data: WidgetTooltipData) => {
    const { right, bottom } = ref.current.getBoundingClientRect();
    if (event.clientX && event.clientY) {
      const customEvent = new CustomEvent('widgetTooltip', {
        detail: {
          left: event.clientX,
          top: event.clientY,
          right,
          bottom,
          data,
          show: true,
        },
      });
      dispatchRootEvent(customEvent);
    }
  };

  const handleWidgetMouseLeave = (event?: React.MouseEvent) => {
    if (
      (event?.target as HTMLDivElement)?.className ===
      'widget-tooltip__container'
    ) {
      return false;
    }

    const customEvent = new CustomEvent('widgetTooltip', {
      detail: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        data: '',
        show: false,
      },
    });

    dispatchRootEvent(customEvent);
  };

  return { handleWidgetMouseMove, handleWidgetMouseLeave };
};

const MOUSE_MARGIN = 10;
const TOOLTIP_PADDING = 12;

export const WidgetTooltip = () => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [data, setData] = useState<WidgetTooltipData>({
    attrX: '',
    valueX: '',
  });
  const [isShow, setShow] = useState(false);
  const isCursorOnTooltip = useRef(false);

  const [tooltipRef, tooltipBounds] = useMeasure();

  const hideTooltip = () => {
    setShow(false);
    setData({ attrX: '', valueX: '' });
    setLeft(-10000);
    setTop(-10000);
  };

  useEventListener('widgetTooltip', (e: any) => {
    const scaleCoefficient = getScaleCoefficient();
    const { left } = e.detail;
    const { top } = e.detail;
    const { show } = e.detail;
    const right =
      e.detail.right * scaleCoefficient - tooltipBounds.width - MOUSE_MARGIN;
    const bottom =
      e.detail.bottom * scaleCoefficient - tooltipBounds.height - MOUSE_MARGIN;

    if (show) {
      setShow(true);
      setData(e.detail.data);
      setLeft(left < right ? left : right);
      setTop(top < bottom ? top : bottom);
    } else {
      setTimeout(() => {
        if (!isCursorOnTooltip.current) hideTooltip();
      }, 100);
    }
  });

  return (
    <div
      ref={tooltipRef}
      className="widget-tooltip__container"
      style={{
        display: isShow ? 'block' : 'none',
        top: top + MOUSE_MARGIN,
        left: left + MOUSE_MARGIN,
        padding: TOOLTIP_PADDING,
      }}
      onMouseMove={() => {
        isCursorOnTooltip.current = true;
      }}
      onMouseLeave={() => {
        isCursorOnTooltip.current = false;
        hideTooltip();
      }}
    >
      <table>
        <tbody>
          {data.slice && (
            <tr>
              <td colSpan={2} className="widget-tooltip__title">
                Срез:
              </td>
              <td className="widget-tooltip__value">&nbsp;{data.slice}</td>
            </tr>
          )}
          <tr>
            <td colSpan={2} className="widget-tooltip__title">
              {data.attrX}:
            </td>
            <td className="widget-tooltip__value">&nbsp;{data.valueX}</td>
          </tr>
          {data.list
            ? data.list.map((row) => (
              <tr key={row.attrY}>
                <td className="widget-tooltip__title">
                  <div
                    style={{
                      display: 'inline-block',
                      width: '0.75em',
                      height: '0.75em',
                      backgroundColor: row.color,
                      borderRadius: '50%',
                      marginRight: 5,
                    }}
                  />
                </td>
                <td className="widget-tooltip__title">{row.attrY}:</td>
                <td className="widget-tooltip__value">&nbsp;{row.valueY}</td>
              </tr>
            ))
            : data.attrY && (
            <tr>
              <td colSpan={2} className="widget-tooltip__title">
                {data.attrY}:
              </td>
              <td className="widget-tooltip__value">&nbsp;{data.valueY}</td>
            </tr>
            )}

          {data.attrZ && data.valueZ && (
            <tr>
              <td colSpan={2} className="widget-tooltip__title">
                {data.attrZ}:
              </td>
              <td className="widget-tooltip__value">&nbsp;{data.valueZ}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
