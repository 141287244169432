import React from 'react';
import { Table, TableBody } from '@material-ui/core';
import { Project } from '../../../slices/types';
import { LoaderTableHead } from './head/loader-table-head';
import { useLoadersTable } from './useLoadersTable';
import { LoadersTableRow } from './body/loaders-table-row';
import './loaders-table.css';

interface ProjectsListProps {
  projects: Project[] | undefined;
  groupId: number;
}

export const LoadersTable = ({ projects, groupId }: ProjectsListProps) => {
  const {
    sortHandler,
    projectsSorted,
  } = useLoadersTable(projects);

  if (!projectsSorted.length) return null;

  return (
    <div className="loader-table">
      <Table className="loader-table__table">
        <LoaderTableHead sortHandler={sortHandler} />
        <TableBody>
          {projectsSorted &&
            projectsSorted.map((project) => (
              <LoadersTableRow
                key={project.id}
                project={project}
                groupId={groupId}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
};
