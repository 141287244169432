import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import block from 'bem-cn';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useClassButton } from '../../../hooks/useClassButton';
import IconSvg from '../../common/icon-svg/icon-svg';
import Notification, {
  NotificationProps,
} from '../../common/notification/notification';
import {
  apiUpdateSource,
  apiValidateSqlScript,
} from '../../../services/sources';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { State } from '../../../slices/types';
import './sql-script-form.css';
import { apiUpdateSchemeBySourceObject } from '../../../services/source-objects';
import { CustomProgress } from '../../../uikit/Progress';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

interface Props {
  loaderId: number;
  callback: () => void;
  setError?: any;
  onClose: () => void;
  connectionId?: number;
  className?: string;
  sourceId?: number;
  isNewScript?: boolean;
  script?: string | null;
  isEditableSql?: boolean;
  isEditableMap?: boolean;
}

interface FieldsValue {
  script: string;
}

const validationSchema = Yup.object({
  script: Yup.string().required('Поле скрипта не должно быть пустым'),
});

const b = block('sql-script-form');

const SqlScriptForm: React.FC<Props> = ({
  loaderId,
  connectionId,
  onClose,
  callback,
  setError,
  isNewScript,
  sourceId,
  script,
  isEditableSql = true,
  isEditableMap = true
}) => {
  const dispatch = useDispatch();
  const currentSourceObjectId = useSelector(
    (state: State) => state.mapView.currentSourceId,
  );
  const classButton = useClassButton({ marginRight: 10 });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationProps | null>(
    null,
  );

  const handleFormSubmit = async ({ script }: FieldsValue) => {
    if (await validateScript()) {
      if (isNewScript) {
        return createSourceObject();
      }

      return editSqlScript();
    }
  };

  const { values, errors, handleChange, handleSubmit } = useFormik<FieldsValue>(
    {
      initialValues: {
        script: script || '',
      },
      validationSchema,
      onSubmit: handleFormSubmit,
    },
  );

  const validateScript = async () => {
    setNotification(null);

    if (!values.script) {
      setNotification({
        title: 'Ошибка',
        content: 'Поле скрипта не должно быть пустым',
        isError: true,
      });
      return false;
    }

    try {
      setIsLoading(true);
      await apiValidateSqlScript({
        connectionId,
        sourceId,
        script: values.script,
      });
      setNotification({
        title: 'Успешно',
        content: 'Скрипт успешно прошёл валидацию.',
        isSuccess: true,
      });
      return true;
    } catch (e: any) {
      setNotification({
        title: 'Ошибка',
        content: e.response?.data?.message,
        isError: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const createSourceObject = () => {
    const errorCallback = (e: any) => {
      setError(e);
    };
    if (connectionId) {
      dispatch(
        mapConnectionAction.addSourceObjectsBySqlScript({
          loaderId,
          connectionId,
          script: values.script,
          errorCallback,
          callback,
        }),
      );
    }
  };

  const editSqlScript = async () => {
    setIsLoading(true);
    if (sourceId) {
      try {
        // update script
        await apiUpdateSource(sourceId, { script: values.script });
        // generate schema by new script
        await apiUpdateSchemeBySourceObject(currentSourceObjectId);
        // get new loader data with new schema
        dispatch(mapConnectionAction.getMapConnection(loaderId));
        callback();
      } catch (e: any) {
        setNotification({
          title: 'Ошибка',
          content: e.response?.data?.message,
          isError: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form className={b()} autoComplete="off" noValidate onSubmit={handleSubmit}>
      <TextField
        multiline
        rows={25}
        required
        autoFocus
        type="text"
        name="script"
        label="Введите скрипт"
        variant="filled"
        autoComplete="off"
        value={values.script}
        onChange={handleChange}
        error={Boolean(errors.script)}
        helperText={errors.script}
        disabled={!isEditableMap || !isEditableSql}
      />
      <div className={b('notification-container')}>
        {isLoading && <CustomProgress type="circular" />}
        {notification && (
          <Notification
            title={notification.title}
            content={notification.content}
            isSuccess={notification.isSuccess}
            isWarning={notification.isWarning}
            isError={notification.isError}
          />
        )}
      </div>
      <div className={b('buttons')}>
        <Button disabled={!isEditableSql} className={classButton.primary} type="submit">
          Сохранить
        </Button>
        <Button className={classButton.default} type="reset" onClick={onClose}>
          Отменить
        </Button>
        <Button
          className={classButton.linkDotted}
          startIcon={<IconSvg svg={IconDictionary.Validation} width={24} height={24} fill="var(--primary-color)" />}
          onClick={validateScript}
          disableRipple
        >
          <span className="button-text">Валидация</span>
        </Button>
      </div>
    </form>
  );
};

export default SqlScriptForm;
