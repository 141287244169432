import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { BarGroupHorizontal } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { setPropForNumberValue } from '../../../formatting-helpers';
import { PanelType } from '../../../../../../enums/widget-type';
import { textDirections } from '../../../../../common/scalable-svg-text';
import { FilterField } from '../../../../../dashboard-page/hooks';
import { useBarProps } from '../../hooks/useBarProps';
import { WidgetProperties } from '../../../../../../slices/types';
import { WidgetTooltipData } from '../../../../../../types/widget-tooltip';
import {
  axisLeftOffset,
  barSettings,
  bottomAxisSettings,
  gridLinesYOffset,
  tickLineWidth,
  minBarComputedSize,
  valueTextSettings,
} from '../../settings';
import { getFirstActivePanelItemIndex } from '../../../../../../helpers/common-helpers';

interface CustomBarGroupProps {
  barGroup: BarGroupHorizontal<string>;
  style: CSSProperties;
  filterField: FilterField;
  data: any;
  valueFormat: any;
  xScale: any;
  getComputedBarWidth: any;
  isActiveFilter: boolean;
  axisYLabelWidth: number;
  widgetProperties: WidgetProperties[];
  enableEvents: boolean;
  handleWidgetMouseLeave: (event: React.MouseEvent) => void;
  handleWidgetMouseMove: (event: any, data: WidgetTooltipData) => void;
  selectBar: (groupIndex: number) => void;
  widgetProps: any;
  barHeight: number;
  setBarHeight: React.Dispatch<React.SetStateAction<number>>;
  columnWidth: number;
  getBarXCoord: any;
  isHasNegative: boolean;
  reservedOffsetForOutsideValue: number;
  groupBarSize: number;
  spaceBetweenBars: number;
}

export const CustomBarGroup = ({
  barGroup,
  style,
  filterField,
  data,
  valueFormat,
  xScale,
  getComputedBarWidth,
  isActiveFilter,
  axisYLabelWidth,
  widgetProperties,
  enableEvents,
  handleWidgetMouseLeave,
  handleWidgetMouseMove,
  selectBar,
  widgetProps,
  barHeight,
  setBarHeight,
  columnWidth,
  getBarXCoord,
  isHasNegative,
  reservedOffsetForOutsideValue,
  groupBarSize,
  spaceBetweenBars,
}: CustomBarGroupProps) => {
  setBarHeight(isNaN(columnWidth) ? minBarComputedSize : columnWidth);

  const {
    metaData,
    axisXNames,
    legendsLabels,
    textDirection,
    isNeedToDisplayTooltip,
    isNeedToDisplayOutsideBarValues,
    isNeedToDisplayInsideBarValues,
    customAxisXLabelWidth,
    scaleByNumber,
    formatByNumber,
    valueColor,
    valueTextSize,
  } = useBarProps({
    widgetProperties,
    widgetProps,
  });

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  return (
    <Group
      key={`bar-group-${barGroup.index}-${barGroup.y0}`}
      left={barGroup.y0}
      style={{
        ...style,
      }}
      className="histogram-horizontal__bar-group"
    >
      <div
        className="histogram-horizontal__axisY-value-container"
        style={{
          position: 'absolute',
          height: groupBarSize,
          width: customAxisXLabelWidth,
        }}
      >
        <div
          style={{
            display: 'flex',
            width:
              textDirection === textDirections.vertical
                ? groupBarSize
                : axisYLabelWidth,
            height: '100%',
            transform:
              textDirection === textDirections.diagonal
                ? 'rotate(-45deg)'
                : textDirection === textDirections.vertical
                  ? 'rotate(-90deg)'
                  : 'none',
            transformOrigin: textDirection === textDirections.diagonal ? 'right' : undefined,
          }}
        >
          <span
            className={clsx('histogram-horizontal__axisY-value', {
              'histogram-horizontal__axisY-value_vertical':
                textDirection === textDirections.vertical,
              'histogram-horizontal__axisY-value_diagonal':
                textDirection === textDirections.diagonal,
            })}
            style={{
              height: bottomAxisSettings.lineHeight,
              alignSelf: 'center',
              textAlign: textDirection === textDirections.diagonal ? 'right' : undefined,
            }}
          >
            {valueFormat(
              data[barGroup.index].x,
              axisXValueIndex,
              widgetProperties,
            )}
          </span>
        </div>
      </div>
      {barGroup.bars.map((bar: any, i: number) => {
        const barWidth = getComputedBarWidth(bar, xScale);
        const barValueStyle: CSSProperties = isHasNegative
          ? {
            position: 'absolute',
            left: bar.value >= 0 && barWidth + barSettings.outerTextLeftOffset,
            right: bar.value < 0 && barWidth + barSettings.outerTextLeftOffset,
          }
          : {
            position: 'relative',
            right: -barWidth - barSettings.outerTextLeftOffset,
            marginLeft: barSettings.outerTextLeftOffset,
          };

        return (
          <div
            className="histogram-horizontal__bar"
            key={bar.key}
            style={{
              height: barHeight,
              flexDirection: 'row',
              marginBottom: i < barGroup.bars.length - 1 ? spaceBetweenBars : 0
            }}
          >
            <div
              className={clsx('histogram-horizontal__rect', {
                'active-drilldown-element': isActiveFilter,
              })}
              style={{
                backgroundColor:
                  (metaData &&
                    metaData[barGroup.index] &&
                    metaData[barGroup.index][bar.index]?.backgroundColor) ||
                  bar.color,
                width: getComputedBarWidth(bar, xScale),
                height: barHeight,
                position: 'absolute',
                display: 'grid',
                left: getBarXCoord(bar, xScale)
                  + axisYLabelWidth
                  + axisLeftOffset
                  + reservedOffsetForOutsideValue
                  + tickLineWidth,
                opacity:
                  filterField?.value === null ||
                  filterField?.value === undefined ||
                  filterField?.value?.includes(data[barGroup.index]?.x)
                    ? 0.8
                    : 0.2,
              }}
              key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
              onClick={(e) => {
                if (!enableEvents) return;
                e.stopPropagation();
                selectBar(barGroup.index);
              }}
              onMouseLeave={
                isNeedToDisplayTooltip ? handleWidgetMouseLeave : undefined
              }
              onMouseMove={
                isNeedToDisplayTooltip
                  ? (event) =>
                    handleWidgetMouseMove(event, {
                      attrX: axisXNames[0],
                      valueX: valueFormat(
                        data[barGroup.index].x,
                        axisXValueIndex,
                        widgetProperties,
                      ),
                      attrY: legendsLabels[bar.index],
                      valueY: valueFormat(
                        bar.value,
                        0,
                        setPropForNumberValue(widgetProperties),
                        PanelType.axisY,
                        false,
                        false,
                        scaleByNumber ? formatByNumber : null,
                        true
                      ),
                    })
                  : undefined
              }
            >
              {isNeedToDisplayOutsideBarValues && (
                <div
                  className="histogram-horizontal__outside-value"
                  style={{
                    ...barValueStyle,
                    fontSize: valueTextSize,
                    width: 'fit-content',
                    color: valueColor ||
                      (metaData &&
                        metaData[barGroup.index] &&
                        metaData[barGroup.index][bar.index]?.color),
                  }}
                >
                  {valueFormat(
                    bar.value,
                    0,
                    setPropForNumberValue(widgetProperties),
                    PanelType.axisY,
                    false,
                    false,
                    scaleByNumber ? formatByNumber : null,
                    true,
                  )}
                </div>
              )}

              {isNeedToDisplayInsideBarValues && (
                <div
                  className={clsx('histogram-horizontal__inside-value', {
                    'histogram-horizontal__inside-value_hidden': getComputedBarWidth(bar, xScale) < 30 || barHeight < valueTextSettings.lineHeight,
                  })}
                  style={{
                    paddingRight: bar.value < 0 ? gridLinesYOffset : 'unset',
                    paddingLeft: bar.value < 0 ? 'unset' : gridLinesYOffset,
                    textAlign: bar.value < 0 ? 'right' : 'left',
                    color:
                      (metaData &&
                        metaData[barGroup.index] &&
                        metaData[barGroup.index][bar.index]?.color),
                  }}
                >
                  <span>
                    {valueFormat(
                      bar.value,
                      0,
                      setPropForNumberValue(widgetProperties),
                      PanelType.axisY,
                      false,
                      false,
                      scaleByNumber ? formatByNumber : null,
                      true,
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </Group>
  );
};
