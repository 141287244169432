import React, { useCallback, useState } from 'react';
import { validateWhereCondition } from 'src/helpers/loader-page';
import { useNotificator } from 'src/components/common/snackbar/hooks';
import { CalculatedInputContainer } from 'src/components/common/special-inputs/calculated-input-container/calculated-input-container';
import { wrapFieldToReplace } from '../../../utils/functions';
import MapConnection from '../../../types/map-connection';
import FieldItem from './field-item/field-item';
import './filtration-panel.css';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import { CustomButton } from '../../../uikit/Button';

interface FiltrationPanelProps {
  sourceObjectFields: any[];
  closeFiltrationPanel: () => void;
  addNewTextFilter: (field: any) => void;
  textFilter: { template: string; operands: string[] };
  isEditableMap: boolean;
  sourceObjectId: string;
}

const FiltrationPanel = ({
  addNewTextFilter,
  closeFiltrationPanel,
  sourceObjectFields,
  textFilter,
  isEditableMap,
  sourceObjectId,
}: FiltrationPanelProps) => {
  const [filterSourceObjectFields, setFilterSourceObjectFields] =
    useState<any>(sourceObjectFields);

  const { showNotification } = useNotificator();

  const getInitialTextFromTransformationObject = () => {
    if (!textFilter?.operands.length || !textFilter?.template.length)
      return textFilter?.template || '';
    const operandsAmount = textFilter.operands.length;
    let line = textFilter.template;
    for (let i = 0; i < operandsAmount; i++) {
      const variableOperand = new RegExp(`\\$${i}`, 'g');
      const currentField = sourceObjectFields.find(
        (field) => field.id === textFilter.operands[i],
      );
      const fieldName =
        currentField?.alias || currentField?.displayedName || '';
      line = line.replace(variableOperand, `${wrapFieldToReplace(fieldName)}`);
    }
    return line;
  };

  const initialTextareaContent = getInitialTextFromTransformationObject();

  const [textareaContent, setTextareaContent] = useState<any>(
    initialTextareaContent,
  );

  const getTemplateAndOperands = (): {
    template: string;
    operands: string[];
  } => {
    let fieldsInTemplate: string[] = textareaContent?.match(/\[(.*?)\]/g) || [];
    fieldsInTemplate = fieldsInTemplate.map((field) =>
      field.substring(1, field.length - 1),
    );

    const currentFields = fieldsInTemplate.map((fieldName) => {
      return sourceObjectFields.find(
        (field) =>
          fieldName === field.alias || fieldName === field.displayedName,
      ) as MapConnection.ProcessedField;
    });

    const uniqueFields = [
      ...new Map(currentFields.map((field) => [field.id, field])).values(),
    ];

    const operands = uniqueFields.map((field) => field.id);

    let template = textareaContent || '';
    uniqueFields.forEach((field, index) => {
      template = template.replaceAll(
        `[${field.alias || field.displayedName}]`,
        `$${index}`,
      );
    });

    return { operands, template };
  };

  const onSave = () => {
    const { template, operands } = getTemplateAndOperands();

    validateWhereCondition(
      { template, operands },
      sourceObjectId,
      showNotification,
      () => {
        addNewTextFilter({ template, operands });
        closeFiltrationPanel();
      },
    );
  };

  const onSearchChange = useCallback(
    (event: any) => {
      const { value } = event.target;

      const searchResult = sourceObjectFields.filter((field: any) =>
        field.displayedName.toLowerCase().includes(value.toLowerCase()),
      );

      setFilterSourceObjectFields(searchResult);
    },
    [sourceObjectFields],
  );

  return (
    <>
      <CustomDialog
        isOpen={true}
        onClose={closeFiltrationPanel}
        title="Фильтр таблицы"
        description="Используйте поля для настройки фильтра"
        maxWidth={false}
        fullWidth={false}
      >
        <div className="filtration-panel">
          <div>
            <div className="filtration-panel__side">
              <div className="filtration-panel__side_top">
                <TextInput
                  label="Поиск по полям"
                  handleChange={onSearchChange}
                  searchIcon
                  fullWidth={true}
                />
              </div>
              <div className="filtration-panel__side_bottom">
                {filterSourceObjectFields.map((el: any, index: number) => (
                  <FieldItem
                    key={`filtration-panel-item-${index}`}
                    name={el.displayedName}
                    alias={el.alias}
                    type={el.displayedType}
                  />
                ))}
              </div>
            </div>
            <div className="filtration-panel__main">
              <CalculatedInputContainer
                processedFields={sourceObjectFields}
                className="filtration-panel__textarea"
                textInputProps={{
                  label: 'Введите текст фильтра, выбор поля - @',
                  fullWidth: true,
                  multiline: true,
                  styles: {
                    isBorder: true,
                    isShrink: true,
                    isFixHeight: false,
                  },
                }}
                setValue={setTextareaContent}
                value={textareaContent}
              />
            </div>
          </div>

          <div className="dialog-buttons">
            <CustomButton
              disabled={!isEditableMap}
              onClick={onSave}
              variant="contained"
            >
              Сохранить
            </CustomButton>
            <CustomButton
              type="reset"
              onClick={closeFiltrationPanel}
              variant="outlined"
            >
              Отменить
            </CustomButton>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default FiltrationPanel;
