export const stageSettings = {
  top: 20,
  left: 20,
  minWidthStep: 90,
};

export const leftAxisSettings = {
  color: 'var(--grey)',
  left: 50,
  top: 23,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'end',
};

export const bottomAxisSettings = {
  color: 'var(--grey)',
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'center',
};

export const barValueStyles = {
  outsideColor: 'var(--black)',
  insideColor: 'var(--white)',
  fontSize: 18,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 21,
  textAlign: 'center',
  wordBreak: 'break-all',
  pointerEvents: 'none'
};

export const barSettings = {
  innerTextTop: 20,
  innerTextBottom: 5,
  outerTextTopOffset: 8,
  outerTextBottomOffset: 20,
};

export const gridLinesColor = '#D8D8D8';
export const gridLinesXOffset = 10;
export const gridLinesYOffset = 4;

export const xScalePadding = 0.2;

export const axisBottomOffset = 100;
export const minContainerHeightForDisplayAxisBottom = 150 + axisBottomOffset;
export const barsOffsetForDiagonalText = 40;
export const offsetForHorizontalText = 30;
export const minBottomValueTextWidth = 50;
export const roundingPixel = 1;
export const overflowOffset = 35;
