import React, { FC } from 'react';
import './widget-table-cell.css';
import { getScaleCoefficient } from '../../../../../../../helpers/ui-helper';
import { TableTooltip } from '../../table-tooltip/table-tooltip';

export interface conditionalColoringCellProps {
  color: string;
  backgroundColor: string;
}

export interface cellViewProps {
  valueSize?: number;
  textColor?: string;
  textBold?: boolean;
  backgroundColor?: string;
  progressColor?: string;
  justifyContent?: 'start' | 'center' | 'end';
  conditionalColoring?: conditionalColoringCellProps;
}

export interface CustomCellProps {
  value: string;
  progress: number | null;
  view: cellViewProps;
  isTotal?: boolean;
}

export type DisplayColumnZeros = 'displayed' | 'fromWidget' | 'hidden';

export enum DEFAULT_COMPLEX_TABLE_CELL {
  cellVariant = 'TEXT',
  justifyContent = 'center',
  backgroundColor = 'var(--white)',
  progressColor = '#9cc5ff',
  textColor = '#000000',
  displayColumnZeros = 'fromWidget',
}


export const CustomCell: FC<CustomCellProps> = ({ value, progress, view, isTotal }) => {

  const styleRoot: React.CSSProperties = {
    justifyContent:
      view.justifyContent || DEFAULT_COMPLEX_TABLE_CELL.justifyContent,
    background: isTotal ? 'var(--white)' : view.conditionalColoring?.backgroundColor
      ? view.conditionalColoring.backgroundColor
      : view.backgroundColor,
  };

  const styleProgress: React.CSSProperties = {
    background: view.progressColor || DEFAULT_COMPLEX_TABLE_CELL.progressColor,
    width: `${progress}%`
  };
  const scaleCoefficient = getScaleCoefficient();

  const styleValue: React.CSSProperties = {
    color: view.conditionalColoring?.color
      ? view.conditionalColoring.color
      : view.textColor,
    fontWeight: view.textBold ? 'bold' : 'normal',
    textAlign:
      view.justifyContent || DEFAULT_COMPLEX_TABLE_CELL.justifyContent,
  };
  const initialFontSize = 14;

  const innerStyleValue : React.CSSProperties = {
    fontSize: (view.valueSize || initialFontSize) * scaleCoefficient,
  };

  return (
    <div
      className="widget-table-cell"
      style={styleRoot}
    >
      {Boolean(progress) && !isTotal && <div className="widget-table-cell__progress" style={styleProgress} />}
      <div className="widget-table-cell__value-container" style={styleValue as React.CSSProperties}>
        <TableTooltip style={innerStyleValue as React.CSSProperties} value={value} />
      </div>
    </div>
  );
};
