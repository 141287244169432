import React, { useRef } from 'react';
import { useResizeObserver } from '../lib/resize-observer/adapters/useResizeObserver';
import { RootElement } from '../types/root';
import { dispatchRootEvent, getRootElement } from '../utils/root-helper';

export const ResizeObserver = ({ children }: { children: JSX.Element }) => {

  const root = useRef<RootElement>(getRootElement());

  useResizeObserver(root, () => {
    dispatchRootEvent(new Event('resize'));
  });

  return <>{children}</>;
};
