export const unexpectedError = '<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path opacity="0.5" d="M185.336 352.268C170.903 360.632 147.521 360.632 133.088 352.268C118.655 343.905 118.655 330.337 133.088 321.99C147.521 313.626 170.903 313.626 185.336 321.99C199.769 330.337 199.769 343.905 185.336 352.268Z" fill="#D5E1F2"/>\n' +
  '<path opacity="0.5" d="M459.991 480.39C488.715 480.39 512 466.891 512 450.239C512 433.587 488.715 420.088 459.991 420.088C431.267 420.088 407.981 433.587 407.981 450.239C407.981 466.891 431.267 480.39 459.991 480.39Z" fill="#D5E1F2"/>\n' +
  '<path d="M145.167 322.053C145.644 323.267 153.973 333.45 162.318 335.988C165.942 337.089 168.374 337.233 169.963 337.041C171.569 336.85 172.554 335.19 172.014 333.673C170.583 329.619 166.466 321.176 156.039 315.988C141.908 308.965 145.167 322.053 145.167 322.053Z" fill="#0C3670"/>\n' +
  '<path d="M142.385 325.964C142.385 325.964 145.516 333.354 146.486 339.547C147.074 343.314 145.008 348.214 143.323 351.47C142.274 353.481 139.603 353.944 137.918 352.412C135.2 349.938 131.735 345.628 131.163 339.435C130.193 329.012 131.163 327.193 131.163 327.193L142.385 325.964Z" fill="#0C3670"/>\n' +
  '<path d="M122.309 199.949C122.309 199.949 121.228 213.101 122.309 227.642C123.39 242.183 127.284 310.226 128.016 326.586C128.016 326.586 127.65 331.534 137.203 330.449C146.756 329.364 145.993 323.857 145.993 323.857C145.993 323.857 159.409 323.681 159.838 315.733C160.267 307.8 173.572 220.906 173.572 189.67L122.309 199.949Z" fill="#4A94F8"/>\n' +
  '<path d="M178.531 131.953L185.111 142.168L207.698 169.574C207.698 169.574 206.919 172.112 204.106 172.782C201.912 173.309 182.902 155.097 174.7 147.1C172.522 144.978 172.157 141.594 173.841 139.056L178.531 131.953Z" fill="#FFC2B0"/>\n' +
  '<path d="M165.703 89.991C165.703 89.991 176.146 89.7196 179.786 104.867C183.426 120.014 186.271 138.992 183.108 140.572C173.619 145.313 167.61 139.774 167.61 139.774L165.703 89.991Z" fill="#9CC8FF"/>\n' +
  '<path d="M168.835 90.6296C167.054 89.8794 165.052 89.7837 163.192 90.3104C154.29 92.8802 120.847 102.728 115.331 107.469C108.957 112.928 124.884 143.653 124.646 153.725C124.423 163.797 123.279 175.672 122.405 186.621C121.514 197.571 120.322 203.572 129.239 208.233C138.157 212.894 153.384 213.101 164.781 204.386C176.178 195.671 174.112 189.702 173.635 183.365C173.158 177.028 169.28 149.655 169.614 141.882C169.614 141.882 173.635 138.913 176.607 133.821C179.58 128.729 174.334 119.36 172.141 113.279C169.947 107.198 173.365 100.222 172.697 95.2584C172.363 92.6886 170.742 91.4277 168.835 90.6296Z" fill="#9CC8FF"/>\n' +
  '<path d="M146.869 112.725C153.809 111.832 158.862 106.622 158.156 101.087C157.45 95.552 151.251 91.789 144.311 92.682C137.371 93.5749 132.318 98.7857 133.024 104.321C133.73 109.855 139.929 113.618 146.869 112.725Z" fill="white"/>\n' +
  '<path d="M154.561 101.563C155.069 105.521 151.445 109.24 146.486 109.879C141.526 110.517 137.092 107.836 136.599 103.877C136.09 99.919 139.714 96.2 144.674 95.5616C149.617 94.9231 154.052 97.6046 154.561 101.563Z" fill="#FFC2B0"/>\n' +
  '<path d="M149.633 88.5865C149.633 88.5865 143.593 83.6066 142.417 86.1285C141.225 88.6503 140.255 99.4881 140.255 99.4881L152.24 102.728L150.428 91.7469C150.412 91.7469 152.812 89.7038 149.633 88.5865Z" fill="#FFC2B0"/>\n' +
  '<path d="M151.193 93.593C161.071 93.2139 168.773 84.8656 168.395 74.9466C168.017 65.0276 159.704 57.2941 149.826 57.6732C139.948 58.0524 132.246 66.4007 132.624 76.3197C133.001 86.2387 141.315 93.9722 151.193 93.593Z" fill="#FFC2B0"/>\n' +
  '<path d="M141.574 89.2566C141.463 89.8152 141.368 90.4217 141.272 91.0442C142.481 97.3011 151.795 100.03 151.795 100.03L150.412 91.7146C150.412 91.7146 151.541 90.7569 151.223 89.7833C147.535 88.65 142.894 87.6126 141.574 89.2566Z" fill="#EA9C92"/>\n' +
  '<path d="M151.193 93.593C161.071 93.2139 168.773 84.8656 168.395 74.9466C168.017 65.0276 159.704 57.2941 149.826 57.6732C139.948 58.0524 132.246 66.4007 132.624 76.3197C133.001 86.2387 141.315 93.9722 151.193 93.593Z" fill="#FFC2B0"/>\n' +
  '<path d="M106.382 151.746L108.385 199.853L115.395 200.572L119.957 157.268L106.382 151.746Z" fill="#FFC2B0"/>\n' +
  '<path d="M129.399 147.723C127.078 137.444 122.5 114.763 122.5 114.763L120.545 137.332C122.802 144.02 124.741 150.165 124.662 153.741C124.535 159.646 124.074 166.19 123.549 172.782V172.798C123.565 172.798 131.719 158.002 129.399 147.723Z" fill="#73B2FF"/>\n' +
  '<path d="M116.683 106.495C116.683 106.495 111.088 108.65 107.908 125.92C104.745 143.19 102.361 160.492 108.02 162.727C113.663 164.961 118.399 166.637 120.052 162.009C121.721 157.396 122.739 135.13 122.945 127.117C123.168 119.104 123.47 108.075 116.683 106.495Z" fill="#9CC8FF"/>\n' +
  '<path d="M206.029 167.579C206.029 167.579 209.065 168.697 211.259 171.091C213.055 173.054 214.041 174.73 214.835 175.911C216.584 178.529 218.046 184.179 216.981 184.882C215.916 185.584 209.828 175.416 208.859 177.795C207.873 180.157 208.875 182.344 207.603 182.376C206.347 182.408 201.976 172.16 198.543 168.984C195.093 165.792 206.029 167.579 206.029 167.579Z" fill="#FFC2B0"/>\n' +
  '<path d="M145.993 323.873C145.993 323.873 147.662 323.841 149.824 323.474L152.446 217.714L145.993 323.873Z" fill="#2561B4"/>\n' +
  '<path d="M108.448 199.454C108.448 199.454 106.604 210.675 106.875 214.282C107.161 217.873 109.068 223.811 110.531 223.683C111.993 223.555 110.578 209.222 113.137 210.643C115.697 212.063 115.967 214.521 117.111 213.659C118.256 212.797 115.093 200.539 115.935 195.224C116.777 189.909 108.448 199.454 108.448 199.454Z" fill="#FFC2B0"/>\n' +
  '<path d="M157.375 77.2219C166.355 80.4301 168.104 78.4669 168.183 78.3711C168.263 77.8284 168.326 77.2698 168.358 76.7111C168.469 74.716 168.247 72.7846 167.754 70.965L144.452 67.8047C144.468 67.8047 148.155 73.9339 157.375 77.2219Z" fill="#EA9C92"/>\n' +
  '<path d="M169.009 58.8025C166.895 56.9829 159.25 47.9807 145.58 50.1036C137.028 51.4284 129.828 57.2862 128.222 70.6459C127.141 79.6481 130.432 88.5226 136.504 95.0667C136.837 94.9071 137.171 94.7794 137.473 94.5719C140.907 92.3054 141.272 91.0604 141.272 91.0445L142.035 84.229C142.003 83.2553 141.288 82.3934 140.334 82.3455C139.587 82.2977 138.681 81.9465 137.823 80.8132C135.852 78.2275 136.758 74.2052 140.7 74.0616C142.75 73.9818 143.545 72.8645 143.847 71.827C144.101 70.9172 145.055 70.4064 145.945 70.7257C150.412 72.3537 161.427 75.8493 168.533 73.8381C177.561 71.2524 171.123 60.6221 169.009 58.8025Z" fill="#444444"/>\n' +
  '<path d="M158.804 85.3304L164.288 83.8141C164.288 83.8141 161.681 87.9481 157.851 89.0015C154.036 90.055 153.448 86.8148 153.448 86.8148L158.804 85.3304Z" fill="white"/>\n' +
  '<path opacity="0.5" d="M337.152 465.993L213.184 395.012C208.654 392.379 209.226 387.798 214.456 384.765L285.778 343.409C291.008 340.377 298.939 340.041 303.47 342.675L427.437 413.655C431.967 416.289 431.395 420.87 426.165 423.902L354.843 465.258C349.598 468.291 341.682 468.61 337.152 465.993Z" fill="#EAF0F9"/>\n' +
  '<path d="M438.484 389.745V99.1366L323.434 68.9058L302.245 56.6156C297.382 53.7904 288.893 54.1575 283.266 57.3977L263.493 68.8579L202.121 68.826L220.289 349.363C220.146 351.278 221.211 353.114 223.548 354.47L338.36 441.46C343.223 444.285 351.712 443.934 357.338 440.678L433.858 396.321C437.101 394.454 438.675 392.012 438.484 389.745Z" fill="#4B9BFF"/>\n' +
  '<path d="M293.44 54.732C289.88 54.8118 286.192 55.7216 283.267 57.4135L263.494 68.8738L202.122 68.8419V360.232C201.979 362.148 203.044 363.983 205.38 365.34L338.36 441.492C340.697 442.848 343.876 443.455 347.166 443.375L348.533 150.165L293.44 54.732Z" fill="#C5DDFF"/>\n' +
  '<path d="M339.6 442.082C343.796 443.886 349.868 443.79 354.764 441.907V146.686H339.6V442.082Z" fill="#9CC8FF"/>\n' +
  '<path d="M338.376 150.181L205.38 74.0453C200.516 71.2201 201.12 66.304 206.747 63.0479L283.267 18.6913C288.878 15.4352 297.382 15.084 302.246 17.9092L435.226 94.0608C440.09 96.886 439.486 101.802 433.859 105.058L357.339 149.415C351.728 152.655 343.24 153.006 338.376 150.181Z" fill="#C5DDFF"/>\n' +
  '<path d="M433.859 99.6315L357.339 143.988C351.728 147.244 343.224 147.595 338.36 144.77L205.38 68.6186C204.299 67.9961 203.505 67.2459 202.948 66.4638C201.279 69.1612 202.042 72.0981 205.38 74.0295L338.36 150.181C343.224 153.006 351.712 152.639 357.339 149.399L433.859 105.042C438.23 102.521 439.549 98.9771 437.642 96.1998C436.895 97.4288 435.623 98.61 433.859 99.6315Z" fill="#FAFDFF"/>\n' +
  '<path d="M342.144 119.327L253.607 67.7881C251.334 66.4633 251.62 64.1649 254.243 62.6326L290.055 41.8669C292.678 40.3506 296.651 40.175 298.94 41.4998L387.477 93.039C389.75 94.3638 389.464 96.6622 386.841 98.1945L351.013 118.96C348.391 120.477 344.417 120.652 342.144 119.327Z" fill="#2561B4"/>\n' +
  '<path d="M294.331 70.1024L289.197 67.1336C288.879 66.958 288.926 66.6388 289.292 66.4153L294.251 63.5423C294.617 63.3348 295.157 63.3028 295.475 63.4944L300.609 66.4632C300.927 66.6388 300.88 66.958 300.514 67.1815L295.555 70.0545C295.205 70.262 294.649 70.278 294.331 70.1024Z" fill="#C4DEFF"/>\n' +
  '<path d="M311.482 79.9985L306.348 77.0297C306.03 76.8541 306.077 76.5349 306.443 76.3114L311.402 73.4383C311.768 73.2308 312.308 73.1989 312.626 73.3905L317.76 76.3593C318.078 76.5349 318.031 76.8541 317.665 77.0775L312.706 79.9506C312.34 80.1581 311.8 80.174 311.482 79.9985Z" fill="#C4DEFF"/>\n' +
  '<path d="M328.617 89.8946L323.482 86.9257C323.165 86.7502 323.212 86.4309 323.578 86.2075L328.537 83.3344C328.903 83.1269 329.443 83.095 329.761 83.2865L334.895 86.2554C335.213 86.4309 335.165 86.7502 334.8 86.9736L329.841 89.8467C329.475 90.0542 328.935 90.0861 328.617 89.8946Z" fill="#C4DEFF"/>\n' +
  '<path d="M345.752 99.7907L340.618 96.8219C340.3 96.6463 340.348 96.327 340.713 96.1036L345.673 93.2306C346.038 93.0231 346.579 92.9911 346.897 93.1827L352.031 96.1515C352.349 96.3271 352.301 96.6463 351.935 96.8697L346.976 99.7428C346.626 99.9503 346.07 99.9822 345.752 99.7907Z" fill="#C4DEFF"/>\n' +
  '<path d="M253.591 67.7882L253.702 67.852C253.877 67.7243 254.036 67.5966 254.242 67.4849L290.054 46.7192C292.677 45.2028 296.651 45.0273 298.94 46.3521L387.381 97.8274C389.479 96.3271 389.591 94.2681 387.492 93.055L298.956 41.5158C296.683 40.191 292.709 40.3666 290.07 41.8829L254.258 62.6486C251.604 64.1649 251.318 66.4634 253.591 67.7882Z" fill="#4A94F8"/>\n' +
  '<path d="M323.689 207.77L225.424 150.804C222.595 149.16 220.29 144.754 220.29 140.955V122.871C220.29 119.072 222.579 117.333 225.424 118.977L323.689 175.943C326.518 177.587 328.823 181.992 328.823 185.791V203.875C328.807 207.658 326.518 209.398 323.689 207.77Z" fill="#9DC6FF"/>\n' +
  '<path d="M316.249 191.776L311.623 189.095C311.004 188.743 310.511 187.77 310.511 186.956V182.008C310.511 181.178 311.004 180.795 311.623 181.162L316.249 183.843C316.869 184.194 317.362 185.168 317.362 185.982V190.93C317.362 191.76 316.869 192.143 316.249 191.776Z" fill="#4A94F8"/>\n' +
  '<path d="M300.815 182.838L296.19 180.156C295.57 179.805 295.077 178.832 295.077 178.017V173.069C295.077 172.239 295.57 171.856 296.19 172.224L300.815 174.905C301.435 175.256 301.928 176.23 301.928 177.044V181.992C301.928 182.806 301.419 183.189 300.815 182.838Z" fill="#2561B4"/>\n' +
  '<path d="M323.689 273.084L225.424 216.118C222.595 214.474 220.29 210.068 220.29 206.269V188.185C220.29 184.386 222.579 182.647 225.424 184.291L323.689 241.257C326.518 242.901 328.823 247.306 328.823 251.105V269.189C328.807 272.972 326.518 274.712 323.689 273.084Z" fill="#9DC6FF"/>\n' +
  '<path d="M316.249 257.091L311.623 254.409C311.004 254.058 310.511 253.084 310.511 252.27V247.322C310.511 246.492 311.004 246.109 311.623 246.476L316.249 249.158C316.869 249.509 317.362 250.483 317.362 251.297V256.245C317.362 257.075 316.869 257.458 316.249 257.091Z" fill="#2561B4"/>\n' +
  '<path d="M300.815 248.152L296.19 245.47C295.57 245.119 295.077 244.146 295.077 243.332V238.384C295.077 237.554 295.57 237.17 296.19 237.538L300.815 240.219C301.435 240.57 301.928 241.544 301.928 242.358V247.306C301.928 248.12 301.419 248.503 300.815 248.152Z" fill="#4A94F8"/>\n' +
  '<path d="M323.689 338.397L225.424 281.431C222.595 279.787 220.29 275.382 220.29 271.583V253.499C220.29 249.7 222.579 247.96 225.424 249.604L323.689 306.57C326.518 308.214 328.823 312.62 328.823 316.419V334.503C328.807 338.286 326.518 340.041 323.689 338.397Z" fill="#9DC6FF"/>\n' +
  '<path d="M316.249 322.404L311.623 319.723C311.004 319.372 310.511 318.398 310.511 317.584V312.636C310.511 311.806 311.004 311.423 311.623 311.79L316.249 314.471C316.869 314.823 317.362 315.796 317.362 316.61V321.558C317.362 322.388 316.869 322.771 316.249 322.404Z" fill="#4A94F8"/>\n' +
  '<path d="M300.815 313.466L296.19 310.784C295.57 310.433 295.077 309.459 295.077 308.645V303.697C295.077 302.867 295.57 302.484 296.19 302.851L300.815 305.533C301.435 305.884 301.928 306.858 301.928 307.672V312.62C301.928 313.434 301.419 313.817 300.815 313.466Z" fill="#2561B4"/>\n' +
  '<path d="M323.689 403.711L225.424 346.745C222.595 345.101 220.29 340.696 220.29 336.897V318.813C220.29 315.014 222.579 313.274 225.424 314.918L323.689 371.884C326.518 373.528 328.823 377.933 328.823 381.732V399.816C328.807 403.599 326.518 405.355 323.689 403.711Z" fill="#9DC6FF"/>\n' +
  '<path d="M316.249 387.734L311.623 385.052C311.004 384.701 310.511 383.728 310.511 382.914V377.966C310.511 377.136 311.004 376.753 311.623 377.12L316.249 379.801C316.869 380.152 317.362 381.126 317.362 381.94V386.888C317.362 387.702 316.869 388.085 316.249 387.734Z" fill="#4A94F8"/>\n' +
  '<path d="M300.815 378.779L296.19 376.098C295.57 375.747 295.077 374.773 295.077 373.959V369.011C295.077 368.181 295.57 367.798 296.19 368.165L300.815 370.846C301.435 371.198 301.928 372.171 301.928 372.985V377.933C301.928 378.747 301.419 379.13 300.815 378.779Z" fill="#4A94F8"/>\n' +
  '<path d="M372.296 207.77L422.636 178.832C425.466 177.188 427.771 172.782 427.771 168.983V150.899C427.771 147.1 425.482 145.361 422.636 147.005L372.296 175.943C369.467 177.587 367.162 181.992 367.162 185.791V203.875C367.162 207.658 369.451 209.398 372.296 207.77Z" fill="#2561B4"/>\n' +
  '<path d="M372.296 273.083L422.636 244.145C425.466 242.501 427.771 238.096 427.771 234.297V216.213C427.771 212.414 425.482 210.674 422.636 212.318L372.296 241.256C369.467 242.9 367.162 247.306 367.162 251.105V269.189C367.162 272.972 369.451 274.711 372.296 273.083Z" fill="#2561B4"/>\n' +
  '<path d="M372.296 338.398L422.636 309.46C425.466 307.816 427.771 303.41 427.771 299.611V281.527C427.771 277.728 425.482 275.989 422.636 277.633L372.296 306.571C369.467 308.215 367.162 312.62 367.162 316.419V334.503C367.162 338.286 369.451 340.042 372.296 338.398Z" fill="#2561B4"/>\n' +
  '<path d="M372.296 403.711L422.636 374.773C425.466 373.129 427.771 368.724 427.771 364.925V346.841C427.771 343.042 425.482 341.302 422.636 342.946L372.296 371.884C369.467 373.528 367.162 377.934 367.162 381.732V399.817C367.162 403.599 369.451 405.355 372.296 403.711Z" fill="#2561B4"/>\n' +
  '<path opacity="0.5" d="M52.8164 452.361L13.1736 429.377C10.7417 427.972 11.0437 425.498 13.8571 423.87L52.1329 401.684C54.9464 400.056 59.1905 399.88 61.6224 401.285L101.265 424.269C103.697 425.674 103.395 428.148 100.582 429.776L62.3059 451.962C59.4925 453.606 55.2484 453.782 52.8164 452.361Z" fill="#C5DDFF"/>\n' +
  '<path d="M114.411 406.775V389.569L76.0078 389.553L62.7353 381.86C59.6834 380.088 54.3744 380.311 50.8456 382.355L38.4632 389.537L0.028439 389.521V407.206C-0.0510373 408.403 0.600667 409.553 2.06303 410.399L51.704 439.177C54.7559 440.949 60.0649 440.725 63.5936 438.682L111.518 410.893C113.552 409.712 114.538 408.196 114.411 406.775Z" fill="#4A94F8"/>\n' +
  '<path d="M57.2197 380.663C54.9943 380.711 52.6736 381.285 50.8456 382.339L38.4632 389.521L0.028439 389.505V407.191C-0.0510373 408.388 0.600667 409.537 2.06303 410.383L51.704 439.161C53.1664 440.007 55.1533 440.39 57.2197 440.342V380.663Z" fill="#9DC6FF"/>\n' +
  '<path d="M4.3674 389.505H0.0120814V407.191C-0.067395 408.388 0.584334 409.537 2.0467 410.383L4.35151 411.724V389.505H4.3674Z" fill="#C5DDFF"/>\n' +
  '<path d="M52.467 439.544C55.0898 440.677 58.8887 440.613 61.9724 439.432V419.353H52.467V439.544Z" fill="#75AFFF"/>\n' +
  '<path d="M51.7038 421.556L2.06288 392.777C-0.989008 391.006 -0.607533 387.925 2.92122 385.882L50.8455 358.093C54.3583 356.05 59.6833 355.827 62.7352 357.598L112.376 386.377C115.428 388.148 115.046 391.229 111.518 393.272L63.5935 421.061C60.0806 423.088 54.7557 423.311 51.7038 421.556Z" fill="#75AFFF"/>\n' +
  '<path d="M52.5942 416.368L10.9962 392.25C8.43707 390.766 8.77086 388.18 11.7115 386.472L51.8789 363.185C54.8195 361.477 59.286 361.285 61.8452 362.77L103.443 386.887C106.002 388.372 105.668 390.958 102.728 392.665L62.5605 415.953C59.6199 417.661 55.1533 417.852 52.5942 416.368Z" fill="#0C3670"/>\n' +
  '<path d="M96.1626 411.309C96.1626 412.585 95.2725 414.15 94.1598 414.788C93.0471 415.427 92.157 414.916 92.157 413.623C92.157 412.346 93.0471 410.782 94.1598 410.143C95.2566 409.505 96.1626 410.032 96.1626 411.309Z" fill="#C5DDFF"/>\n' +
  '<path d="M102.616 407.574C102.616 408.85 101.726 410.415 100.613 411.053C99.5005 411.692 98.6104 411.181 98.6104 409.888C98.6104 408.611 99.5005 407.047 100.613 406.408C101.71 405.77 102.616 406.281 102.616 407.574Z" fill="#C5DDFF"/>\n' +
  '<path d="M109.07 403.823C109.07 405.1 108.179 406.664 107.067 407.302C105.954 407.941 105.064 407.43 105.064 406.137C105.064 404.86 105.954 403.296 107.067 402.657C108.164 402.019 109.07 402.546 109.07 403.823Z" fill="#C5DDFF"/>\n' +
  '<path d="M114.411 378.875V361.669L76.0078 361.653L62.7353 353.959C59.6834 352.188 54.3744 352.411 50.8456 354.454L38.4632 361.637L0.028439 361.621V379.306C-0.0510373 380.503 0.600667 381.652 2.06303 382.498L51.704 411.276C54.7559 413.048 60.0649 412.825 63.5936 410.782L111.518 382.993C113.552 381.812 114.538 380.295 114.411 378.875Z" fill="#4A94F8"/>\n' +
  '<path d="M57.2197 352.778C54.9943 352.826 52.6736 353.401 50.8456 354.454L38.4632 361.637L0.028439 361.621V379.306C-0.0510373 380.503 0.600667 381.652 2.06303 382.498L51.704 411.277C53.1664 412.123 55.1533 412.506 57.2197 412.458V352.778Z" fill="#9DC6FF"/>\n' +
  '<path d="M4.3674 361.621H0.0120814V379.306C-0.067395 380.503 0.584334 381.652 2.0467 382.498L4.35151 383.839V361.621H4.3674Z" fill="#C5DDFF"/>\n' +
  '<path d="M52.467 411.66C55.0898 412.793 58.8887 412.729 61.9724 411.548V391.469H52.467V411.66Z" fill="#75AFFF"/>\n' +
  '<path d="M51.7038 393.655L2.06288 364.877C-0.989008 363.105 -0.607533 360.025 2.92122 357.981L50.8455 330.193C54.3583 328.15 59.6833 327.926 62.7352 329.698L112.376 358.476C115.428 360.248 115.046 363.329 111.518 365.372L63.5935 393.16C60.0806 395.203 54.7557 395.427 51.7038 393.655Z" fill="#75AFFF"/>\n' +
  '<path d="M52.5942 388.468L10.9962 364.35C8.43707 362.866 8.77086 360.28 11.7115 358.572L51.8789 335.284C54.8195 333.576 59.286 333.385 61.8452 334.869L103.443 358.987C106.002 360.471 105.668 363.057 102.728 364.765L62.5605 388.053C59.6199 389.776 55.1533 389.952 52.5942 388.468Z" fill="#0C3670"/>\n' +
  '<path d="M96.1626 383.424C96.1626 384.701 95.2725 386.265 94.1598 386.903C93.0471 387.542 92.157 387.031 92.157 385.738C92.157 384.461 93.0471 382.897 94.1598 382.259C95.2566 381.604 96.1626 382.131 96.1626 383.424Z" fill="#C5DDFF"/>\n' +
  '<path d="M102.616 379.673C102.616 380.95 101.726 382.514 100.613 383.152C99.5005 383.791 98.6104 383.28 98.6104 381.987C98.6104 380.71 99.5005 379.146 100.613 378.508C101.71 377.869 102.616 378.396 102.616 379.673Z" fill="#C5DDFF"/>\n' +
  '<path d="M108.454 377.908C109.236 376.549 109.234 375.08 108.451 374.626C107.668 374.172 106.4 374.905 105.619 376.264C104.837 377.623 104.839 379.092 105.622 379.546C106.405 380 107.673 379.267 108.454 377.908Z" fill="#C5DDFF"/>\n' +
  '<path d="M114.411 350.99V333.784L76.0078 333.768L62.7353 326.075C59.6834 324.303 54.3744 324.526 50.8456 326.57L38.4632 333.752L0.028439 333.736V351.421C-0.0510373 352.618 0.600667 353.768 2.06303 354.614L51.704 383.392C54.7559 385.164 60.0649 384.94 63.5936 382.897L111.518 355.108C113.552 353.927 114.538 352.395 114.411 350.99Z" fill="#4A94F8"/>\n' +
  '<path d="M57.2197 324.878C54.9943 324.926 52.6736 325.5 50.8456 326.554L38.4632 333.736L0.028439 333.72V351.405C-0.0510373 352.603 0.600667 353.752 2.06303 354.598L51.704 383.376C53.1664 384.222 55.1533 384.605 57.2197 384.557V324.878Z" fill="#9DC6FF"/>\n' +
  '<path d="M4.36813 333.72H0.0128138V351.406C-0.0666626 352.603 0.585066 353.752 2.04743 354.598L4.35224 355.939V333.72H4.36813Z" fill="#C5DDFF"/>\n' +
  '<path d="M52.4668 383.759C55.0895 384.892 58.8885 384.829 61.9722 383.647V363.568H52.4668V383.759Z" fill="#75AFFF"/>\n' +
  '<path d="M51.7041 365.755L2.06313 336.976C-0.988764 335.205 -0.607288 332.124 2.92146 330.081L50.8457 302.292C54.3586 300.249 59.6835 300.026 62.7354 301.797L112.376 330.576C115.428 332.347 115.047 335.428 111.518 337.471L63.5937 365.26C60.0809 367.303 54.756 367.526 51.7041 365.755Z" fill="#C5DDFF"/>\n' +
  '<path d="M111.518 334.087L63.5937 361.876C60.0808 363.919 54.7559 364.143 51.704 362.371L2.06308 333.592C1.37958 333.193 0.886817 332.731 0.53712 332.236C-0.511968 333.928 -0.0350988 335.763 2.06308 336.976L51.704 365.755C54.7559 367.526 60.0649 367.303 63.5937 365.26L111.518 337.471C114.252 335.891 115.078 333.672 113.886 331.932C113.425 332.715 112.631 333.449 111.518 334.087Z" fill="#C5DDFF"/>\n' +
  '<path d="M96.1634 355.523C96.1634 356.8 95.2732 358.365 94.1605 359.003C93.0479 359.641 92.1577 359.131 92.1577 357.838C92.1577 356.561 93.0479 354.997 94.1605 354.358C95.2573 353.72 96.1634 354.247 96.1634 355.523Z" fill="#C5DDFF"/>\n' +
  '<path d="M102.617 351.789C102.617 353.065 101.727 354.63 100.614 355.268C99.5012 355.907 98.6111 355.396 98.6111 354.103C98.6111 352.81 99.5012 351.262 100.614 350.623C101.711 349.985 102.617 350.496 102.617 351.789Z" fill="#C5DDFF"/>\n' +
  '<path d="M109.07 348.038C109.07 349.315 108.18 350.879 107.068 351.517C105.955 352.156 105.065 351.645 105.065 350.352C105.065 349.075 105.955 347.511 107.068 346.872C108.164 346.234 109.07 346.761 109.07 348.038Z" fill="#C5DDFF"/>\n' +
  '<path d="M54.645 348.692L31.422 335.221C29.9914 334.391 30.1822 332.954 31.8194 331.997L54.2476 318.988C55.9007 318.03 58.3804 317.935 59.8109 318.749L83.034 332.22C84.4645 333.05 84.2738 334.487 82.6366 335.444L60.2083 348.453C58.5552 349.41 56.0597 349.506 54.645 348.692Z" fill="#2561B4"/>\n' +
  '<path d="M56.8703 337.408L53.6594 335.54C53.4687 335.428 53.4845 335.221 53.7071 335.093L56.8067 333.29C57.0292 333.162 57.3789 333.146 57.5697 333.258L60.7805 335.125C60.9712 335.237 60.9553 335.444 60.7328 335.572L57.6332 337.376C57.4107 337.503 57.061 337.519 56.8703 337.408Z" fill="#C5DDFF"/>\n' +
  '<path d="M71.1122 352.188L67.9013 350.32C67.7106 350.208 67.7265 350.001 67.949 349.873L71.0486 348.07C71.2711 347.942 71.6208 347.926 71.8116 348.038L75.0224 349.905C75.2131 350.017 75.1972 350.224 74.9747 350.352L71.8752 352.156C71.6526 352.283 71.3029 352.299 71.1122 352.188Z" fill="#0C3670"/>\n' +
  '<path d="M78.1222 348.07L74.9114 346.202C74.7206 346.091 74.7365 345.883 74.959 345.755L78.0586 343.952C78.2812 343.824 78.6309 343.808 78.8216 343.92L82.0325 345.787C82.2232 345.899 82.2073 346.106 81.9848 346.234L78.8852 348.038C78.6626 348.181 78.3289 348.197 78.1222 348.07Z" fill="#0C3670"/>\n' +
  '<path d="M85.1478 343.968L81.937 342.1C81.7462 341.988 81.7621 341.781 81.9847 341.653L85.0843 339.85C85.3068 339.722 85.6565 339.706 85.8472 339.818L89.0581 341.685C89.2488 341.797 89.2329 342.004 89.0104 342.132L85.9108 343.936C85.6883 344.063 85.3386 344.079 85.1478 343.968Z" fill="#4A94F8"/>\n' +
  '<path d="M31.406 335.221L31.4696 335.253C31.5809 335.173 31.6762 335.093 31.8034 335.029L54.2317 322.021C55.8848 321.063 58.3644 320.967 59.795 321.781L82.9544 335.205C84.2737 334.263 84.3373 332.97 83.018 332.204L59.795 318.733C58.3644 317.903 55.8848 318.014 54.2317 318.972L31.8034 331.981C30.1662 332.954 29.9913 334.391 31.406 335.221Z" fill="#4A94F8"/>\n' +
  '<path d="M36.8739 397.294L16.7822 385.642C15.5424 384.924 14.541 382.993 14.541 381.333C14.541 379.673 15.5424 378.907 16.7822 379.625L36.8739 391.277C38.1137 391.995 39.1151 393.926 39.1151 395.586C39.1151 397.246 38.1137 398.012 36.8739 397.294Z" fill="#0C3670"/>\n' +
  '<path d="M38.67 396.177L18.5784 384.525C17.3386 383.807 16.3372 381.876 16.3372 380.216C16.3372 379.928 16.369 379.689 16.4325 379.466C15.3675 379.082 14.541 379.833 14.541 381.333C14.541 382.993 15.5424 384.924 16.7822 385.643L36.8739 397.294C37.9071 397.885 38.7654 397.454 39.0197 396.337C38.9085 396.289 38.7972 396.241 38.67 396.177Z" fill="#4A94F8"/>\n' +
  '<path d="M489.65 434.931C493.179 436.448 495.388 440.39 495.102 444.62C494.959 446.615 494.721 448.483 494.308 449.361C493.195 451.723 489.507 454.963 483.658 452.633C477.792 450.302 464.774 438.028 464.297 429.984C463.375 415.044 480.383 430.941 489.65 434.931Z" fill="#0C3670"/>\n' +
  '<path d="M455.205 459.624C455.205 459.624 456.604 468.227 455.92 469.807C455.237 471.388 451.152 474.755 445.127 474.021C439.103 473.287 421.761 467.286 417.899 455.809C411.906 438.012 451.374 457.15 451.374 457.15L455.205 459.624Z" fill="#0C3670"/>\n' +
  '<path d="M438.26 321.973L436.925 456.4C437.068 461.077 440.12 465.019 444.221 465.721C444.73 465.801 445.27 465.881 445.858 465.929C450.007 466.296 452.423 464.508 453.806 462.625C454.919 461.109 455.507 459.177 455.602 457.198L457.319 399.817L465.854 348.517L477.665 439.321C477.855 441.779 479.032 444.046 480.907 445.307C482.211 446.169 483.975 446.839 486.296 446.775C489.681 446.68 492.018 444.86 493.449 443.232C494.577 441.955 495.181 440.183 495.149 438.364L490.476 383.728L488.728 314.041L438.26 321.973Z" fill="#75AFFF"/>\n' +
  '<path d="M494.18 277.824C493.941 277.824 493.687 277.808 493.449 277.761L472.069 274.441C469.447 274.026 467.651 271.568 468.048 268.934C468.461 266.3 470.925 264.497 473.532 264.896L488.283 267.194L483.244 238.24C482.783 235.607 484.531 233.117 487.154 232.654C489.761 232.191 492.257 233.947 492.717 236.58L498.901 272.174C499.171 273.706 498.678 275.271 497.597 276.388C496.707 277.314 495.467 277.824 494.18 277.824Z" fill="#FFC2B0"/>\n' +
  '<path d="M474.661 209.143C474.661 209.143 481.814 208.728 491.017 227.769C500.236 246.827 487.615 252.845 487.615 252.845L474.661 209.143Z" fill="#9CC8FF"/>\n' +
  '<path d="M455.046 214.122C455.364 213.994 474.756 201.449 481.925 214.569C493.608 235.957 492.925 304.847 489.237 313.753C485.82 322.005 477.3 328.102 471.307 331.534C465.982 334.583 460.037 336.45 453.918 336.498C447.862 336.546 436.242 331.135 436.655 323.186C438.595 286.603 435.368 255.749 432.396 225.822C432.411 225.838 436.131 221.816 455.046 214.122Z" fill="#9CC8FF"/>\n' +
  '<path d="M424.448 288.837C425.004 288.806 425.544 288.678 426.069 288.422C427.484 287.736 428.501 286.284 428.739 284.56L437.959 257.569C438.372 254.776 433.524 243.906 431.044 243.443C428.564 242.98 422.302 247.912 421.888 250.706L419.615 274.823L396.853 263.587C394.485 262.342 392.53 259.804 391.147 262.15C389.748 264.496 390.305 267.673 392.387 269.253L421.714 287.991C422.556 288.598 423.51 288.885 424.448 288.837Z" fill="#FFC2B0"/>\n' +
  '<path d="M395.121 262.693L393.691 259.181C393.182 257.92 392.387 256.787 391.386 255.861L385.377 250.307C384.869 249.844 384.074 249.908 383.645 250.466C383.311 250.913 383.343 251.552 383.724 251.967L386.236 254.696C386.649 255.143 386.776 255.781 386.569 256.356L385.616 259.037C385.298 259.931 385.473 260.937 386.077 261.671L392.101 268.997L395.121 262.693Z" fill="#FFC2B0"/>\n' +
  '<path d="M432.411 225.838C432.411 225.838 419.472 235.862 417.469 266.364C417.469 266.364 417.183 269.748 424.67 271.775C432.157 273.802 437.259 267.992 437.259 267.992C437.259 267.992 445.986 247.737 432.411 225.838Z" fill="#9CC8FF"/>\n' +
  '<path d="M456.826 202.343L458.94 214.585C458.94 214.585 459.02 218.767 454.378 219.916C449.737 221.081 448.29 218.065 448.29 218.065L446.192 204.274L456.826 202.343Z" fill="#FFC2B0"/>\n' +
  '<path d="M456.272 212.477C464.996 208.098 468.098 196.571 463.199 186.73C458.301 176.889 447.258 172.462 438.534 176.84C429.809 181.219 426.708 192.746 431.606 202.587C436.505 212.428 447.548 216.856 456.272 212.477Z" fill="#FFC2B0"/>\n' +
  '<path d="M444.237 200.97C443.506 201.082 442.759 200.874 442.25 200.332C441.344 199.406 440.963 194.474 438.594 194.059C434.891 193.404 427.341 199.23 424.289 186.733C422.699 180.236 425.592 177.938 424.956 174.331C424.956 174.331 424.988 176.182 441.662 172.144C458.336 168.106 466.395 182.184 468.017 189.286C469.638 196.373 468.207 208.743 456.969 212.367C455.11 212.973 445.97 207.865 446.033 206.988C446.097 206.11 449.133 205.631 448.275 203.221C447.544 201.226 446.161 200.683 444.237 200.97Z" fill="#444444"/>\n' +
  '<path d="M465.823 348.501L477.633 439.305C477.824 441.763 479 444.03 480.876 445.291C481.178 445.498 481.527 445.69 481.877 445.865C479.429 444.014 472.276 367.798 470.941 359.323C469.638 350.831 465.823 348.501 465.823 348.501Z" fill="#226DC9"/>\n' +
  '<path d="M436.21 268.981V269.284C437.275 286.124 437.689 303.857 436.671 323.186C436.497 326.33 438.245 329.076 440.756 331.246C443.713 294.184 440.756 261.91 440.756 261.91C439.167 267.369 436.21 268.981 436.21 268.981Z" fill="#226DC9"/>\n' +
  '<path opacity="0.5" d="M217.779 496C244.668 496 266.466 483.358 266.466 467.764C266.466 452.17 244.668 439.529 217.779 439.529C190.89 439.529 169.092 452.17 169.092 467.764C169.092 483.358 190.89 496 217.779 496Z" fill="#D5E1F2"/>\n' +
  '<path d="M237.521 452.649C236.297 451.947 234.993 451.324 233.626 450.781H233.658L226.155 447.445L226.728 448.754C221.037 447.621 214.87 447.541 209.132 448.563L209.481 447.094L200.659 451.021H200.707C199.563 451.516 198.45 452.042 197.401 452.633C186.322 459.049 186.322 469.472 197.401 475.905C208.48 482.321 226.441 482.321 237.536 475.905C248.599 469.488 248.6 459.065 237.521 452.649Z" fill="#4A94F8"/>\n' +
  '<path d="M226.855 458.714C221.658 461.731 213.249 461.731 208.051 458.714C202.853 455.698 202.853 450.829 208.051 447.813C213.249 444.796 221.658 444.796 226.855 447.813C232.053 450.829 232.053 455.698 226.855 458.714Z" fill="#367AD6"/>\n' +
  '<path d="M217.46 455.346C214.964 455.346 212.946 453.319 212.946 450.813V393.432H221.99V450.813C221.974 453.303 219.956 455.346 217.46 455.346Z" fill="#174B92"/>\n' +
  '<path d="M220.64 395.507C218.843 396.545 215.919 396.545 214.123 395.507C212.326 394.47 212.326 392.762 214.123 391.724C215.919 390.687 218.843 390.687 220.64 391.724C222.436 392.762 222.436 394.454 220.64 395.507Z" fill="#367AD6"/>\n' +
  '<path d="M217.461 395.396C215.442 395.396 213.789 393.752 213.789 391.708V337.775H221.117V391.708C221.133 393.752 219.48 395.396 217.461 395.396Z" fill="#174B92"/>\n' +
  '<path d="M270.153 366.043V363.936L273.125 363.505L276.447 360.999C276.988 360.6 278.021 360.552 278.641 360.919L281.089 362.34L283.521 361.988V364.127H283.505C283.489 364.367 283.314 364.606 282.949 364.766L275.208 368.229C274.588 368.501 273.729 368.469 273.173 368.149L270.598 366.649C270.296 366.489 270.169 366.266 270.153 366.043Z" fill="#4A94F8"/>\n' +
  '<path d="M270.471 363.361L276.448 358.876C276.988 358.477 278.021 358.429 278.641 358.796L283.108 361.382C283.728 361.749 283.664 362.339 282.965 362.659L275.224 366.122C274.604 366.394 273.745 366.362 273.189 366.042L270.614 364.542C270.058 364.223 269.994 363.712 270.471 363.361Z" fill="#174B92"/>\n' +
  '<path d="M260.377 373.544C261.236 373.544 262.094 373.353 262.778 372.954L272.06 367.559C272.569 367.255 272.744 366.601 272.458 366.09C272.156 365.58 271.504 365.404 270.995 365.691L261.713 371.07C261.029 371.469 259.757 371.469 259.074 371.07L243.64 362.084C243.131 361.781 242.479 361.956 242.177 362.483C241.875 362.994 242.05 363.664 242.575 363.951L258.009 372.938C258.677 373.353 259.519 373.544 260.377 373.544Z" fill="#367AD6"/>\n' +
  '<path d="M171.078 353.816L185.988 345.117L177.627 341.318L170.474 346.41L171.078 353.816Z" fill="url(#paint0_radial_7072_74503)"/>\n' +
  '<path d="M266.847 340.887C266.021 340.456 265.178 339.946 264.336 339.323L232.545 315.94C226.807 311.71 220.894 310.625 216.332 312.955C216.221 313.019 216.11 313.099 215.998 313.162L215.967 313.147L172.064 338.717L176.006 340.041L191.377 347.048L212.295 361.94L228.794 370.735L229.541 370.895C233.388 367.639 237.696 364.845 240.064 360.105C244.149 352.922 255.53 350.863 262.222 345.053C262.89 345.484 263.573 345.883 264.241 346.234C265.639 344.862 266.021 342.914 266.847 340.887Z" fill="#174B92"/>\n' +
  '<path d="M280.787 330.353C280.104 330.353 279.484 330.593 278.991 330.992V330.976L236.042 355.939L237.616 362.388L239.221 361.621C236.757 365.468 232.99 368.006 229.573 370.895L234.421 371.932L276.495 347.352C276.686 347.272 276.893 347.192 277.083 347.097C277.656 346.809 278.196 346.458 278.705 346.059L278.784 346.011H278.752C281.947 343.521 283.759 339.212 283.759 333.817V333.306C283.744 331.678 282.424 330.353 280.787 330.353Z" fill="#0C3670"/>\n' +
  '<path d="M221.975 311.758C224.662 311.854 227.523 312.779 230.416 314.503C227.523 312.779 224.646 311.854 221.975 311.758Z" fill="#EAEAEA"/>\n' +
  '<path d="M241.526 357.95V357.439C241.526 355.795 240.207 354.471 238.57 354.471C236.933 354.471 235.613 355.795 235.613 357.439V357.95C235.613 361.909 234.358 364.829 232.164 365.931C229.684 367.192 225.933 366.266 222.102 363.457L190.312 340.073C185.416 336.466 180.409 335.173 176.213 336.306L202.456 321.016L172.032 338.733L175.974 340.057L191.345 347.064L212.263 361.956L228.762 370.751L229.51 370.911C233.356 367.655 237.664 364.861 240.032 360.121C240.445 359.403 240.938 358.748 241.479 358.126C241.526 358.062 241.526 357.998 241.526 357.95Z" fill="#4A94F8"/>\n' +
  '<path d="M228.794 373.385C225.313 373.385 221.482 371.964 217.731 369.203L185.94 345.82C182.126 343.01 178.358 342.085 175.879 343.345C173.685 344.463 172.429 347.368 172.429 351.326C172.429 352.97 171.11 354.295 169.473 354.295C167.836 354.295 166.516 352.97 166.516 351.326C166.516 345.053 168.948 340.217 173.192 338.046C177.754 335.732 183.667 336.817 189.406 341.031L221.196 364.414C225.011 367.224 228.778 368.149 231.258 366.889C233.451 365.771 234.707 362.866 234.707 358.908V358.397C234.707 356.753 236.026 355.428 237.664 355.428C239.301 355.428 240.62 356.753 240.62 358.397V358.908C240.62 365.181 238.188 370.017 233.944 372.188C232.37 372.986 230.638 373.385 228.794 373.385Z" fill="#367AD6"/>\n' +
  '<path d="M203.823 425.674C203.823 425.674 197.831 422.641 194.159 427.158C190.487 431.675 185.226 442.322 185.067 449.536C184.908 456.735 190.742 456.256 194.207 454.548C197.672 452.84 207.813 438.076 208.433 435.187C209.053 432.298 207.702 428.387 206.732 427.621C205.747 426.839 203.823 425.674 203.823 425.674Z" fill="#0C3670"/>\n' +
  '<path d="M205.126 424.222L205.397 430.447C205.46 431.771 204.793 433.032 203.648 433.687C202.599 434.277 201.089 434.74 199.023 434.405C194.508 433.671 194.111 432.378 193.793 426.153C193.475 419.928 205.126 424.222 205.126 424.222Z" fill="#367AD6"/>\n' +
  '<path d="M181.904 413.687C181.904 413.687 175.911 410.655 172.239 415.172C168.568 419.689 163.306 430.335 163.147 437.55C162.988 444.748 168.822 444.269 172.287 442.561C175.752 440.854 185.893 426.089 186.513 423.2C187.133 420.311 185.782 416.401 184.812 415.635C183.843 414.853 181.904 413.687 181.904 413.687Z" fill="#0C3670"/>\n' +
  '<path d="M183.207 412.251L183.477 418.476C183.54 419.8 182.873 421.061 181.728 421.716C180.679 422.306 179.169 422.769 177.103 422.434C172.589 421.7 172.191 420.407 171.873 414.182C171.555 407.957 183.207 412.251 183.207 412.251Z" fill="#367AD6"/>\n' +
  '<path d="M236.631 297.903C234.866 268.95 205.412 302.165 205.412 302.165L185.257 322.005L167.105 342.021C163.56 345.931 161.907 351.214 162.591 356.45L169.934 412.586C170.3 415.363 172.652 417.406 175.45 417.374C176.229 417.374 177.135 417.294 178.168 417.135C184.717 416.113 184.24 409.856 184.24 409.856L182.905 357.344L221.022 334.263C221.006 334.295 238.395 326.857 236.631 297.903Z" fill="#4A94F8"/>\n' +
  '<path d="M258.534 308.933C256.77 279.979 227.316 313.195 227.316 313.195L207.161 333.035L189.008 353.05C185.464 356.961 183.81 362.244 184.494 367.479L191.838 423.615C192.203 426.393 194.556 428.436 197.353 428.404C198.132 428.404 199.038 428.324 200.071 428.164C206.62 427.143 206.143 420.886 206.143 420.886L204.808 368.373L242.925 345.293C242.909 345.325 260.314 337.887 258.534 308.933Z" fill="#4A94F8"/>\n' +
  '<path d="M241.114 216.022C263.447 225.311 262.986 238.575 262.986 238.575C262.986 238.575 258.614 287.162 260.109 319.611C260.728 333.178 188.341 334.216 199.134 298.015C211.866 255.351 207.098 220.81 213.059 215.591C219.019 210.371 235.344 213.612 241.114 216.022Z" fill="#367AD6"/>\n' +
  '<path d="M248.277 227.742C249.444 223.192 245.401 218.213 239.246 216.622C233.091 215.03 227.155 217.428 225.988 221.978C224.821 226.528 228.865 231.507 235.02 233.099C241.175 234.691 247.11 232.292 248.277 227.742Z" fill="#FFC2B0"/>\n' +
  '<path d="M238.84 234.68C237.505 234.68 236.122 234.505 234.755 234.154C228.032 232.414 223.645 226.843 224.948 221.72C226.267 216.596 232.8 213.851 239.508 215.591C246.232 217.33 250.619 222.901 249.315 228.025C248.664 230.562 246.74 232.589 243.927 233.739C242.385 234.377 240.652 234.68 238.84 234.68ZM235.439 217.219C231.29 217.219 227.825 219.15 227.03 222.262C226.013 226.237 229.717 230.642 235.296 232.079C238.046 232.797 240.843 232.669 243.132 231.744C245.31 230.866 246.772 229.349 247.265 227.482C248.282 223.507 244.579 219.102 238.999 217.666C237.791 217.362 236.583 217.219 235.439 217.219Z" fill="white"/>\n' +
  '<path d="M232.164 215C232.164 215 232.355 215.894 231.973 222.997C231.846 225.232 239.635 227.322 241.654 225.295C243.672 223.268 242.862 221.177 243.339 213.564C243.831 205.982 232.164 215 232.164 215Z" fill="#FFC2B0"/>\n' +
  '<path d="M243.339 213.58C243.339 213.58 241.415 220.635 231.973 223.332L232.116 216.932L243.339 213.58Z" fill="#EA9C92"/>\n' +
  '<path d="M241.985 214.53C249.98 209.395 252.316 198.725 247.203 190.696C242.09 182.668 231.464 180.322 223.468 185.456C215.473 190.591 213.137 201.261 218.25 209.289C223.363 217.318 233.989 219.664 241.985 214.53Z" fill="#FFC2B0"/>\n' +
  '<path d="M236.647 200.635C236.647 200.635 227.189 204.705 220.64 204.753C214.091 204.801 215.538 199.294 215.538 199.294L234.994 196.469L236.647 200.635Z" fill="#EA9C92"/>\n' +
  '<path d="M218.669 201.704C218.669 201.704 226.919 198.193 234.135 199.661C235.836 200.013 237.41 200.922 238.506 202.263C238.872 202.71 239.317 203.109 239.826 203.221C241.113 203.508 243.211 198.177 245.707 198.049C248.203 197.922 249.856 199.693 247.821 202.981C245.771 206.269 246.47 209.94 247.408 210.467C248.346 210.994 258.328 203.332 249.522 185.823C240.732 168.329 218.383 177.746 214.902 181.29C214.902 181.29 211.246 184.498 208.258 181.641C208.274 181.657 204.936 206.764 218.669 201.704Z" fill="#444444"/>\n' +
  '<path d="M250.476 274.057V287.178C250.476 288.678 249.84 290.114 248.743 291.136L221.88 315.716L224.932 320.409L260.411 296.547C262.064 295.334 263.129 293.482 263.351 291.439L265.751 266.571L250.476 274.057Z" fill="#FFC2B0"/>\n' +
  '<path d="M261.11 231.999C261.11 231.999 269.264 244.305 272.3 276.691C272.3 276.691 258.376 281.208 250.476 278.638C242.576 276.084 261.11 231.999 261.11 231.999Z" fill="#367AD6"/>\n' +
  '<path d="M248.982 247.561C248.982 247.561 245.787 264.704 245.612 272.764C245.564 274.983 246.915 276.978 248.982 277.728V247.561Z" fill="#2561B4"/>\n' +
  '<path d="M242.018 332.795L236.089 332.013L197.766 309.795C196.542 309.076 194.396 309.172 192.966 309.986L165.816 325.724L162.224 325.245V328.836C162.208 329.315 162.463 329.762 163.051 330.097L206.477 355.268C207.7 355.987 209.846 355.891 211.277 355.077L240.842 337.934C241.637 337.472 242.034 336.881 242.018 336.322V332.795Z" fill="#174B92"/>\n' +
  '<path d="M213.216 318.765L210.498 317.185H180.567L165.816 325.74L162.224 325.261V328.853C162.208 329.332 162.463 329.778 163.051 330.114L206.477 355.285C207.128 355.668 208.034 355.811 208.956 355.748L213.216 318.765Z" fill="#0C3670"/>\n' +
  '<path d="M163.814 325.469L162.224 325.261V328.852C162.208 329.331 162.463 329.778 163.051 330.113L163.814 330.56V325.469Z" fill="#5793CE"/>\n' +
  '<path d="M206.922 355.476C208.082 355.923 209.751 355.827 210.991 355.221V349.123H206.922V355.476Z" fill="#367AD6"/>\n' +
  '<path d="M206.477 351.693L163.051 326.522C161.827 325.804 161.97 324.559 163.401 323.745L192.966 306.602C194.381 305.772 196.527 305.693 197.766 306.411L241.192 331.582C242.416 332.3 242.273 333.545 240.843 334.359L211.277 351.502C209.847 352.316 207.701 352.411 206.477 351.693Z" fill="white"/>\n' +
  '<path d="M205.746 347.878L169.632 326.937C168.614 326.347 168.741 325.309 169.918 324.623L185.765 315.668C186.942 314.982 188.722 314.902 189.755 315.509L225.869 336.45C226.886 337.041 226.759 338.078 225.583 338.764L209.735 347.719C208.559 348.389 206.763 348.469 205.746 347.878Z" fill="#C5DDFF"/>\n' +
  '<path d="M194.651 305.773L194.206 308.789C193.824 311.359 193.888 313.961 194.396 316.499L194.937 319.148C195.239 320.649 196.415 321.798 197.909 322.085C199.07 322.293 200.182 321.479 200.309 320.297L201.025 313.865C201.025 313.865 202.662 316.02 203.457 316.02C204.252 316.02 202.567 309.571 200.786 305.932C199.006 302.293 194.651 305.773 194.651 305.773Z" fill="#FFC2B0"/>\n' +
  '<path d="M209.37 257.952L204.712 267.21C203.616 269.397 202.964 271.791 202.805 274.233L200.786 305.916L194.651 305.773L191.869 270.195C191.79 269.093 191.837 267.992 192.028 266.907L195.207 248.87L209.37 257.952Z" fill="#FFC2B0"/>\n' +
  '<path d="M205.015 354.119L201.375 349.347L163.083 327.081C161.859 326.363 160.858 324.447 160.858 322.803L160.89 291.327L158.68 288.438L160.747 287.241C161.144 286.986 161.669 286.986 162.241 287.321L205.635 312.556C206.859 313.274 207.86 315.174 207.86 316.834L207.829 351.119C207.829 352.044 207.511 352.683 207.018 352.954L205.015 354.119Z" fill="#174B92"/>\n' +
  '<path d="M178.533 336.067L175.815 334.487L160.889 308.438L160.905 291.327L158.696 288.438L160.762 287.241C161.16 286.986 161.684 286.986 162.256 287.321L205.65 312.556C206.302 312.939 206.874 313.641 207.288 314.471L178.533 336.067Z" fill="#2561B4"/>\n' +
  '<path d="M159.665 289.715L158.696 288.438L160.762 287.241C161.16 286.986 161.684 286.986 162.256 287.321L163.019 287.768L159.665 289.715Z" fill="#367AD6"/>\n' +
  '<path d="M206.048 312.843C207.017 313.641 207.765 315.125 207.86 316.514L203.616 318.972L201.581 315.429L206.048 312.843Z" fill="#274F89"/>\n' +
  '<path d="M203.584 313.753L160.19 288.518C158.966 287.8 157.965 288.566 157.965 290.21L157.933 324.495C157.933 326.139 158.918 328.055 160.158 328.773L203.552 354.008C204.776 354.726 205.778 353.96 205.778 352.316L205.809 318.031C205.809 316.387 204.824 314.472 203.584 313.753Z" fill="white"/>\n' +
  '<path d="M224.931 320.409L224.232 321.862C223.437 323.522 222.166 324.927 220.608 325.9L216.3 328.566C214.965 329.396 213.264 329.46 211.897 328.661C211.85 328.63 211.802 328.598 211.754 328.566C210.387 327.704 210.26 325.725 211.389 324.559L215.887 319.915C215.887 319.915 213.36 321.862 212.644 321.08C212.12 320.505 215.092 317.696 217.381 317.616C219.67 317.52 221.88 315.717 221.88 315.717L224.931 320.409Z" fill="#FFC2B0"/>\n' +
  '<path d="M213.503 215.511C213.503 215.511 196.988 233.548 190.327 250.1C190.327 250.1 195.97 260.315 209.799 264.321C223.628 268.343 213.503 215.511 213.503 215.511Z" fill="#367AD6"/>\n' +
  '<path d="M206.683 263.268L209.354 237.298L203.043 261.624C204.188 262.214 205.396 262.757 206.683 263.268Z" fill="#2561B4"/>\n' +
  '<path d="M225.87 211.872L221.435 210.643C221.435 210.643 223.549 213.995 226.648 214.841C229.748 215.687 230.225 213.069 230.225 213.069L225.87 211.872Z" fill="white"/>\n' +
  '<defs>\n' +
  '<radialGradient id="paint0_radial_7072_74503" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(72.4244 342.662) scale(92.0743 92.4573)">\n' +
  '<stop stop-color="#01C8EF"/>\n' +
  '<stop offset="0.0894" stop-color="#03BEE9"/>\n' +
  '<stop offset="0.2388" stop-color="#08A5D7"/>\n' +
  '<stop offset="0.4288" stop-color="#107BBB"/>\n' +
  '<stop offset="0.4457" stop-color="#1177B8"/>\n' +
  '<stop offset="1" stop-color="#112564"/>\n' +
  '</radialGradient>\n' +
  '</defs>\n' +
  '</svg>\n';

export const securityError = '<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<g clip-path="url(#clip0_7072_74705)">\n' +
  '<path d="M322.665 452.695L207.335 387.345C203.116 384.923 203.643 380.703 208.518 377.898L274.877 339.827C279.751 337.037 287.12 336.726 291.338 339.148L406.669 404.497C410.887 406.919 410.36 411.14 405.486 413.944L339.127 452.015C334.252 454.819 326.884 455.117 322.665 452.695Z" fill="#EAF0F9"/>\n' +
  '<path d="M416.945 382.516V114.957L309.909 87.1261L290.198 75.8096C285.68 73.2177 277.77 73.5435 272.54 76.5319L254.14 87.0836L197.059 87.0553L213.962 345.337C213.834 347.094 214.818 348.793 216.998 350.04L323.806 430.133C328.324 432.725 336.234 432.399 341.464 429.411L412.641 388.564C415.648 386.836 417.116 384.598 416.945 382.516Z" fill="#4B9BFF"/>\n' +
  '<path d="M282.004 74.0815C278.698 74.1664 275.263 74.9879 272.541 76.5459L254.141 87.0975L197.06 87.0692V355.336C196.932 357.093 197.915 358.792 200.096 360.039L323.806 430.147C325.987 431.393 328.937 431.96 332.001 431.889L333.27 161.951L282.004 74.0815Z" fill="#C5DDFF"/>\n' +
  '<path d="M324.946 430.685C328.851 432.342 334.495 432.257 339.056 430.529V158.735H324.946V430.685Z" fill="#9CC8FF"/>\n' +
  '<path d="M323.806 161.965L200.095 91.8565C195.577 89.2646 196.133 84.7323 201.364 81.7297L272.54 40.8827C277.771 37.8801 285.667 37.5544 290.199 40.1604L413.91 110.269C418.428 112.861 417.872 117.393 412.641 120.396L341.465 161.243C336.234 164.231 328.338 164.557 323.806 161.965Z" fill="#C5DDFF"/>\n' +
  '<path d="M412.641 115.41L341.465 156.257C336.234 159.26 328.338 159.586 323.806 156.98L200.095 86.8711C199.083 86.2905 198.342 85.6106 197.843 84.8741C196.29 87.3527 196.988 90.0579 200.095 91.8425L323.806 161.951C328.324 164.543 336.234 164.217 341.465 161.229L412.641 120.382C416.703 118.059 417.929 114.801 416.161 112.238C415.463 113.385 414.28 114.475 412.641 115.41Z" fill="#FAFDFF"/>\n' +
  '<path d="M327.312 133.553L244.947 86.1061C242.824 84.8881 243.095 82.7636 245.546 81.3614L278.854 62.2551C281.305 60.8529 284.997 60.6971 287.12 61.9152L369.485 109.362C371.608 110.58 371.338 112.705 368.886 114.107L335.578 133.213C333.127 134.615 329.421 134.757 327.312 133.553Z" fill="#2561B4"/>\n' +
  '<path d="M282.845 88.2305L278.07 85.4969C277.771 85.327 277.814 85.0296 278.156 84.8454L282.759 82.1969C283.101 81.9986 283.614 81.9844 283.9 82.1544L288.674 84.8879C288.973 85.0579 288.931 85.3553 288.589 85.5394L283.985 88.188C283.643 88.3721 283.13 88.4004 282.845 88.2305Z" fill="#D4ECFF"/>\n' +
  '<path d="M298.779 97.3374L294.005 94.6039C293.706 94.434 293.748 94.1365 294.09 93.9524L298.694 91.3039C299.036 91.1056 299.549 91.0914 299.834 91.2614L304.609 93.9949C304.908 94.1649 304.865 94.4623 304.523 94.6464L299.92 97.2949C299.592 97.4932 299.079 97.5074 298.779 97.3374Z" fill="#D4ECFF"/>\n' +
  '<path d="M314.728 106.459L309.953 103.725C309.654 103.555 309.697 103.258 310.039 103.074L314.642 100.425C314.984 100.227 315.497 100.213 315.782 100.383L320.557 103.116C320.856 103.286 320.813 103.583 320.471 103.768L315.868 106.416C315.54 106.6 315.027 106.614 314.728 106.459Z" fill="#D4ECFF"/>\n' +
  '<path d="M330.676 115.566L325.901 112.832C325.602 112.662 325.645 112.365 325.987 112.181L330.59 109.532C330.932 109.334 331.445 109.32 331.731 109.49L336.505 112.223C336.804 112.393 336.762 112.691 336.42 112.875L331.816 115.523C331.474 115.707 330.975 115.736 330.676 115.566Z" fill="#D4ECFF"/>\n' +
  '<path d="M244.933 86.1059L245.033 86.1626C245.19 86.0493 245.347 85.936 245.532 85.8227L278.84 66.7164C281.291 65.3142 284.983 65.1584 287.106 66.3764L369.371 113.767C371.324 112.379 371.423 110.495 369.471 109.362L287.106 61.915C284.983 60.697 281.291 60.8527 278.84 62.2549L245.532 81.3612C243.095 82.7634 242.824 84.8879 244.933 86.1059Z" fill="#4A94F8"/>\n' +
  '<path d="M310.138 214.964L218.723 162.517C216.086 161.002 213.948 156.951 213.948 153.453V136.811C213.948 133.312 216.086 131.712 218.723 133.227L310.138 185.674C312.774 187.19 314.912 191.24 314.912 194.739V211.38C314.912 214.879 312.774 216.479 310.138 214.964Z" fill="#5793CE"/>\n' +
  '<path d="M303.225 200.248L298.935 197.784C298.365 197.458 297.895 196.566 297.895 195.815V191.255C297.895 190.49 298.365 190.15 298.935 190.476L303.225 192.94C303.796 193.266 304.266 194.158 304.266 194.909V199.469C304.266 200.234 303.796 200.588 303.225 200.248Z" fill="#173D7A"/>\n' +
  '<path d="M288.858 192.02L284.568 189.555C283.998 189.229 283.528 188.337 283.528 187.587V183.026C283.528 182.261 283.998 181.921 284.568 182.247L288.858 184.711C289.428 185.037 289.899 185.929 289.899 186.68V191.241C289.899 191.991 289.428 192.345 288.858 192.02Z" fill="#173D7A"/>\n' +
  '<path d="M310.138 275.101L218.723 222.654C216.086 221.139 213.948 217.088 213.948 213.59V196.948C213.948 193.45 216.086 191.849 218.723 193.365L310.138 245.811C312.774 247.327 314.912 251.378 314.912 254.876V271.518C314.912 275.002 312.774 276.603 310.138 275.101Z" fill="#5793CE"/>\n' +
  '<path d="M303.225 260.386L298.935 257.921C298.365 257.596 297.895 256.703 297.895 255.953V251.392C297.895 250.627 298.365 250.287 298.935 250.613L303.225 253.077C303.796 253.403 304.266 254.295 304.266 255.046V259.607C304.266 260.372 303.796 260.711 303.225 260.386Z" fill="#173D7A"/>\n' +
  '<path d="M288.858 252.143L284.568 249.678C283.998 249.352 283.528 248.46 283.528 247.709V243.149C283.528 242.384 283.998 242.044 284.568 242.37L288.858 244.834C289.428 245.16 289.899 246.052 289.899 246.803V251.364C289.899 252.128 289.428 252.468 288.858 252.143Z" fill="#D4ECFF"/>\n' +
  '<path d="M310.138 335.225L218.723 282.778C216.086 281.262 213.948 277.212 213.948 273.713V257.072C213.948 253.573 216.086 251.973 218.723 253.488L310.138 305.935C312.774 307.45 314.912 311.501 314.912 314.999V331.641C314.912 335.14 312.774 336.74 310.138 335.225Z" fill="#5793CE"/>\n' +
  '<path d="M303.225 320.523L298.935 318.059C298.365 317.733 297.895 316.841 297.895 316.09V311.529C297.895 310.765 298.365 310.425 298.935 310.75L303.225 313.215C303.796 313.541 304.266 314.433 304.266 315.184V319.744C304.266 320.495 303.796 320.849 303.225 320.523Z" fill="#D4ECFF"/>\n' +
  '<path d="M288.858 312.28L284.568 309.816C283.998 309.49 283.528 308.598 283.528 307.847V303.286C283.528 302.522 283.998 302.182 284.568 302.507L288.858 304.972C289.428 305.298 289.899 306.19 289.899 306.94V311.501C289.899 312.252 289.428 312.606 288.858 312.28Z" fill="#173D7A"/>\n' +
  '<path d="M310.138 395.362L218.723 342.915C216.086 341.399 213.948 337.349 213.948 333.85V317.209C213.948 313.71 216.086 312.11 218.723 313.625L310.138 366.072C312.774 367.587 314.912 371.638 314.912 375.136V391.778C314.912 395.263 312.774 396.877 310.138 395.362Z" fill="#5793CE"/>\n' +
  '<path d="M303.225 380.646L298.935 378.182C298.365 377.856 297.895 376.964 297.895 376.213V371.652C297.895 370.888 298.365 370.548 298.935 370.873L303.225 373.338C303.796 373.664 304.266 374.556 304.266 375.307V379.867C304.266 380.632 303.796 380.972 303.225 380.646Z" fill="#D4ECFF"/>\n' +
  '<path d="M288.858 372.403L284.568 369.939C283.998 369.613 283.528 368.721 283.528 367.97V363.409C283.528 362.645 283.998 362.305 284.568 362.63L288.858 365.095C289.428 365.421 289.899 366.313 289.899 367.063V371.624C289.899 372.389 289.428 372.729 288.858 372.403Z" fill="#D4ECFF"/>\n' +
  '<path d="M355.36 214.964L402.194 188.323C404.831 186.807 406.968 182.757 406.968 179.258V162.616C406.968 159.118 404.831 157.518 402.194 159.033L355.36 185.674C352.724 187.19 350.586 191.24 350.586 194.739V211.381C350.586 214.879 352.724 216.479 355.36 214.964Z" fill="#2561B4"/>\n' +
  '<path d="M355.36 275.101L402.194 248.46C404.831 246.945 406.968 242.894 406.968 239.396V222.754C406.968 219.255 404.831 217.655 402.194 219.17L355.36 245.812C352.724 247.327 350.586 251.378 350.586 254.876V271.518C350.586 275.002 352.724 276.603 355.36 275.101Z" fill="#2561B4"/>\n' +
  '<path d="M355.36 335.224L402.194 308.583C404.831 307.068 406.968 303.017 406.968 299.519V282.877C406.968 279.378 404.831 277.778 402.194 279.294L355.36 305.935C352.724 307.45 350.586 311.501 350.586 314.999V331.641C350.586 335.139 352.724 336.74 355.36 335.224Z" fill="#2561B4"/>\n' +
  '<path d="M355.36 395.362L402.194 368.721C404.831 367.205 406.968 363.154 406.968 359.656V343.014C406.968 339.516 404.831 337.915 402.194 339.431L355.36 366.072C352.724 367.588 350.586 371.638 350.586 375.137V391.778C350.586 395.263 352.724 396.877 355.36 395.362Z" fill="#2561B4"/>\n' +
  '<path opacity="0.5" d="M293.805 180.08L247.214 147.617C230.553 136.004 213.777 125.82 197.059 117.081V355.336C196.931 357.093 197.915 358.792 200.095 360.039L236.923 380.915C295.814 370.024 293.805 304.83 293.805 304.83V180.08Z" fill="#3787EB"/>\n' +
  '<path d="M265.157 316.147V191.411L218.566 158.948C195.049 142.561 171.333 128.95 147.817 118.356L101.226 97.3662L82.4126 108.314L103.235 123.313V220.955C103.235 220.955 100.684 300.553 185.201 392.416C196.075 393.082 205.496 392.275 213.677 390.405L212.28 394.923L228.827 385.235C230.253 384.471 231.621 383.663 232.946 382.828L233.174 382.686H233.16C266.539 361.214 265.157 316.147 265.157 316.147Z" fill="#226DC9"/>\n' +
  '<path d="M183.091 136.429C171.319 129.673 159.532 123.639 147.803 118.356L101.212 97.3663L82.3984 108.315L103.221 123.313V182.261L183.091 136.429Z" fill="#4B9BFF"/>\n' +
  '<path d="M166.858 127.591L127.593 150.11L159.931 168.664L199.211 146.13C188.436 139.36 177.647 133.171 166.858 127.591Z" fill="#3787EB"/>\n' +
  '<path d="M246.344 327.095V202.358L199.753 169.896C176.236 153.509 152.521 139.898 129.004 129.304L82.4131 108.314V233.05C82.4131 233.05 79.8619 312.648 164.379 404.511C248.895 409.624 246.344 327.095 246.344 327.095Z" fill="#73B2FF"/>\n' +
  '<path d="M213.863 376.256C203.701 386.057 189.235 390.957 170.82 390.759C95.6534 306.615 96.4231 234.155 96.4373 233.49L96.4516 129.97L123.189 142.023C145.879 152.249 168.925 165.477 191.686 181.326L232.291 209.625V327.095V327.52C232.305 327.831 232.704 358.098 213.863 376.256Z" fill="#3787EB"/>\n' +
  '<path d="M189.088 309.108C188.085 309.681 186.476 308.752 185.492 307.032L162.341 266.551L138.747 280.044C137.745 280.617 136.135 279.687 135.152 277.968C134.168 276.248 134.183 274.389 135.186 273.816L158.779 260.323L135.628 219.841C134.645 218.122 134.66 216.263 135.662 215.69C136.665 215.116 138.275 216.046 139.258 217.766L162.409 258.247L186.003 244.754C187.005 244.18 188.615 245.11 189.599 246.83C190.582 248.549 190.567 250.408 189.565 250.982L165.971 264.475L189.122 304.956C190.105 306.676 190.09 308.535 189.088 309.108Z" fill="white"/>\n' +
  '<path d="M39.2717 395.702L10.4961 379.06C8.0732 377.672 8.37252 375.236 11.1802 373.621L38.6018 357.744C41.4095 356.13 45.6425 355.96 48.0797 357.348L76.8552 373.99C79.2781 375.378 78.9788 377.814 76.1711 379.428L48.7495 395.305C45.9418 396.92 41.6946 397.104 39.2717 395.702Z" fill="#EAF0F9"/>\n' +
  '<path d="M87.3159 342.816V335.635L48.92 335.607L49.1908 335.593C46.1408 333.851 40.8246 334.063 37.3043 336.089L38.4445 335.621L0.0200821 335.593V343.255C-0.0654322 344.445 0.590167 345.578 2.05816 346.414L38.1452 367.276C41.1952 369.018 46.5113 368.806 50.0317 366.78L84.4227 346.867C86.4608 345.734 87.4299 344.218 87.3159 342.816Z" fill="#4B9BFF"/>\n' +
  '<path d="M43.6751 334.432C41.4517 334.488 39.1286 335.041 37.3043 336.089L38.4445 335.621L0.0200821 335.593V343.255C-0.0654322 344.445 0.590167 345.578 2.05816 346.414L38.1452 367.276C39.6132 368.112 41.5943 368.495 43.6609 368.452L43.6751 334.432Z" fill="#9CC8FF"/>\n' +
  '<path d="M4.36706 335.607H0.0200821V343.269C-0.0654322 344.459 0.590167 345.592 2.05816 346.428L4.36706 347.759V335.607Z" fill="#C4DEFF"/>\n' +
  '<path d="M38.915 367.673C41.5375 368.792 45.3429 368.735 48.4071 367.56V357.518H38.915V367.673Z" fill="#73B2FF"/>\n' +
  '<path d="M38.1452 359.699L2.05821 338.822C-0.991798 337.08 -0.606975 334.021 2.91336 332.009L37.3043 312.096C40.8247 310.085 46.1408 309.858 49.1908 311.6L85.2779 332.463C88.3279 334.205 87.943 337.264 84.4227 339.275L50.0317 359.189C46.5114 361.228 41.1953 361.441 38.1452 359.699Z" fill="#EDF4FF"/>\n' +
  '<path d="M37.59 330.437C35.7087 331.5 32.6587 331.486 30.7916 330.409C28.9245 329.319 28.9245 327.576 30.8058 326.514C32.6871 325.452 35.7372 325.466 37.6042 326.543C39.4855 327.633 39.4713 329.375 37.59 330.437Z" fill="#9CC8FF"/>\n' +
  '<path d="M48.4923 336.769C46.611 337.831 43.561 337.817 41.6939 336.74C39.8269 335.65 39.8269 333.908 41.7082 332.846C43.5895 331.783 46.6395 331.797 48.5066 332.874C50.3879 333.95 50.3736 335.707 48.4923 336.769Z" fill="#9CC8FF"/>\n' +
  '<path d="M59.3956 343.1C57.5143 344.162 54.4643 344.148 52.5972 343.071C50.7302 341.981 50.7302 340.239 52.6115 339.176C54.4928 338.114 57.5428 338.128 59.4099 339.205C61.2912 340.281 61.2769 342.023 59.3956 343.1Z" fill="#9CC8FF"/>\n' +
  '<path d="M416.959 489.6C436.598 489.6 452.519 480.469 452.519 469.205C452.519 457.941 436.598 448.81 416.959 448.81C397.32 448.81 381.399 457.941 381.399 469.205C381.399 480.469 397.32 489.6 416.959 489.6Z" fill="#EAF0F9"/>\n' +
  '<path d="M433.079 266.372C433.079 266.372 432.323 281.839 431.14 292.235C429.957 302.631 404.773 284.247 404.773 284.247V272.406L433.079 266.372Z" fill="#444444"/>\n' +
  '<path d="M419.667 458.795C419.282 459.73 412.769 467.633 406.241 469.602C403.405 470.466 401.495 470.565 400.241 470.423C398.987 470.282 398.217 468.993 398.63 467.803C399.756 464.659 402.977 458.115 411.158 454.093C422.218 448.64 419.667 458.795 419.667 458.795Z" fill="#0C3670"/>\n' +
  '<path d="M421.848 461.826C421.848 461.826 419.382 467.562 418.627 472.35C418.171 475.267 419.781 479.077 421.107 481.584C421.933 483.142 424.029 483.496 425.34 482.306C427.478 480.38 430.186 477.038 430.642 472.25C431.397 464.163 430.642 462.761 430.642 462.761L421.848 461.826Z" fill="#0C3670"/>\n' +
  '<path d="M437.568 364.128C437.568 364.128 438.423 374.325 437.568 385.599C436.713 396.873 433.663 449.632 433.107 462.322C433.107 462.322 433.392 466.16 425.909 465.325C418.427 464.475 419.026 460.212 419.026 460.212C419.026 460.212 408.522 460.07 408.179 453.909C407.852 447.748 397.419 380.387 397.419 356.168L437.568 364.128Z" fill="#75AFFF"/>\n' +
  '<path d="M401.025 311.497L394.967 348.335L389.452 348.236L389.965 314.471L401.025 311.497Z" fill="#FFC2B0"/>\n' +
  '<path d="M403.576 278.865C403.576 278.865 395.395 278.652 392.545 290.408C389.694 302.163 387.471 316.865 389.951 318.083C397.39 321.751 402.094 317.46 402.094 317.46L403.576 278.865Z" fill="#9CC8FF"/>\n' +
  '<path d="M401.125 279.36C402.522 278.78 404.09 278.709 405.543 279.12C412.513 281.102 438.723 288.751 443.041 292.419C448.03 296.654 435.559 320.476 435.744 328.28C435.93 336.084 436.813 345.291 437.497 353.789C438.196 362.272 439.136 366.932 432.138 370.544C425.155 374.155 413.226 374.325 404.289 367.555C395.353 360.799 396.978 356.168 397.348 351.253C397.719 346.339 400.755 325.108 400.498 319.088C400.498 319.088 397.348 316.78 395.025 312.828C392.688 308.877 396.807 301.611 398.517 296.895C400.227 292.178 397.548 286.782 398.075 282.93C398.36 280.961 399.629 279.984 401.125 279.36Z" fill="#9CC8FF"/>\n' +
  '<path d="M409.49 287.49C408.935 291.782 412.897 295.818 418.341 296.512C423.786 297.206 428.631 294.289 429.187 289.997C429.743 285.706 425.781 281.669 420.337 280.975C414.906 280.281 410.046 283.199 409.49 287.49Z" fill="white"/>\n' +
  '<path d="M412.313 287.844C411.914 290.904 414.75 293.793 418.641 294.289C422.532 294.784 425.995 292.702 426.394 289.629C426.793 286.57 423.957 283.68 420.066 283.185C416.175 282.689 412.698 284.771 412.313 287.844Z" fill="#FFC2B0"/>\n' +
  '<path d="M416.175 277.774C416.175 277.774 420.907 273.908 421.833 275.862C422.76 277.817 423.53 286.23 423.53 286.23L414.137 288.737L415.562 280.225C415.548 280.225 413.681 278.638 416.175 277.774Z" fill="#FFC2B0"/>\n' +
  '<path d="M401.481 268.568C401.937 276.259 408.579 282.108 416.304 281.655C424.043 281.202 429.929 274.601 429.473 266.925C429.017 259.234 422.375 253.385 414.65 253.838C406.926 254.291 401.025 260.891 401.481 268.568Z" fill="#FFC2B0"/>\n' +
  '<path d="M422.489 278.313C422.574 278.752 422.646 279.219 422.731 279.701C421.79 284.544 414.479 286.669 414.479 286.669L415.562 280.225C415.562 280.225 414.679 279.474 414.921 278.738C417.8 277.831 421.448 277.024 422.489 278.313Z" fill="#EA9C92"/>\n' +
  '<path d="M401.481 268.568C401.937 276.259 408.579 282.108 416.304 281.655C424.043 281.202 429.929 274.601 429.473 266.925C429.017 259.234 422.375 253.385 414.65 253.838C406.926 254.291 401.025 260.891 401.481 268.568Z" fill="#FFC2B0"/>\n' +
  '<path d="M450.054 326.751L448.472 364.043L442.984 364.609L439.421 331.028L450.054 326.751Z" fill="#FFC2B0"/>\n' +
  '<path d="M432.01 323.635C433.82 315.675 437.412 298.07 437.412 298.07L438.951 315.576C437.184 320.76 435.659 325.533 435.73 328.295C435.83 332.884 436.186 337.94 436.599 343.067V343.081C436.585 343.067 430.2 331.595 432.01 323.635Z" fill="#73B2FF"/>\n' +
  '<path d="M441.987 291.654C441.987 291.654 446.377 293.326 448.856 306.71C451.336 320.108 453.203 333.521 448.771 335.249C444.338 336.977 440.647 338.28 439.336 334.697C438.039 331.113 437.226 313.848 437.07 307.645C436.899 301.441 436.671 292.887 441.987 291.654Z" fill="#9CC8FF"/>\n' +
  '<path d="M419.011 460.212C419.011 460.212 417.714 460.198 416.004 459.9L413.952 377.909L419.011 460.212Z" fill="#226DC9"/>\n' +
  '<path d="M426.965 415.909L429.886 412.127L460.586 394.465C461.569 393.899 462.367 392.383 462.367 391.08L462.339 366.096L464.106 363.802L462.453 362.853C462.139 362.655 461.712 362.655 461.256 362.924L426.451 382.951C425.468 383.517 424.67 385.033 424.67 386.336L424.698 413.543C424.698 414.28 424.955 414.79 425.354 414.988L426.965 415.909Z" fill="#226DC9"/>\n' +
  '<path d="M448.2 401.59L450.381 400.329L462.353 379.665L462.339 366.096L464.106 363.802L462.453 362.853C462.139 362.655 461.712 362.655 461.256 362.924L426.451 382.951C425.938 383.248 425.468 383.815 425.14 384.48L448.2 401.59Z" fill="#4B9BFF"/>\n' +
  '<path d="M463.323 364.822L464.106 363.802L462.453 362.853C462.14 362.655 461.712 362.655 461.256 362.924L460.643 363.278L463.323 364.822Z" fill="#73B2FF"/>\n' +
  '<path d="M426.124 383.163C425.354 383.786 424.755 384.976 424.67 386.081L428.076 388.035L429.701 385.217L426.124 383.163Z" fill="#73B2FF"/>\n' +
  '<path d="M428.105 383.886L462.909 363.859C463.892 363.292 464.691 363.887 464.691 365.19L464.719 392.398C464.719 393.701 463.921 395.216 462.938 395.783L428.133 415.81C427.15 416.376 426.352 415.782 426.352 414.479L426.323 387.271C426.323 385.968 427.107 384.452 428.105 383.886Z" fill="#1255A7"/>\n' +
  '<path d="M448.428 363.731C448.428 363.731 449.882 372.442 449.654 375.232C449.44 378.022 447.929 382.625 446.789 382.526C445.649 382.427 446.747 371.309 444.751 372.413C442.756 373.518 442.542 375.416 441.63 374.75C440.718 374.085 443.212 364.581 442.542 360.459C441.901 356.338 448.428 363.731 448.428 363.731Z" fill="#FFC2B0"/>\n' +
  '<path d="M389.453 348.236C389.453 348.236 387.999 356.947 388.227 359.737C388.441 362.527 389.951 367.13 391.092 367.031C392.232 366.932 391.134 355.814 393.13 356.919C395.125 358.023 395.339 359.921 396.251 359.255C397.163 358.59 394.669 349.086 395.339 344.965C395.98 340.843 389.453 348.236 389.453 348.236Z" fill="#FFC2B0"/>\n' +
  '<path d="M410.104 268.965C403.063 271.443 401.695 269.928 401.624 269.857C401.552 269.432 401.51 269.007 401.481 268.568C401.396 267.024 401.567 265.523 401.951 264.121L420.209 261.67C420.209 261.67 417.33 266.415 410.104 268.965Z" fill="#EA9C92"/>\n' +
  '<path d="M400.983 254.688C402.636 253.271 408.622 246.289 419.34 247.946C426.038 248.98 431.682 253.512 432.937 263.88C433.777 270.862 431.198 277.731 426.452 282.816C426.195 282.703 425.924 282.59 425.696 282.434C423.017 280.677 422.718 279.714 422.718 279.7L422.119 274.417C422.133 273.667 422.703 273.001 423.459 272.958C424.043 272.916 424.756 272.647 425.426 271.769C426.965 269.772 426.252 266.642 423.174 266.528C421.563 266.472 420.936 265.608 420.708 264.786C420.508 264.078 419.753 263.681 419.069 263.936C415.563 265.197 406.94 267.916 401.382 266.344C394.284 264.333 399.329 256.09 400.983 254.688Z" fill="#444444"/>\n' +
  '<path d="M408.977 275.267L404.688 274.092C404.688 274.092 406.74 277.292 409.733 278.114C412.726 278.935 413.182 276.414 413.182 276.414L408.977 275.267Z" fill="white"/>\n' +
  '<path d="M441.146 374.481L444.794 372.399L443.426 374.623L441.573 375.303L441.146 374.481Z" fill="#D29FFF"/>\n' +
  '<path d="M463.75 220.275L433.82 202.967C431.297 201.523 431.611 198.988 434.532 197.316L463.065 180.802C465.987 179.131 470.391 178.947 472.914 180.391L502.844 197.699C505.367 199.143 505.053 201.679 502.131 203.35L473.598 219.864C470.691 221.536 466.287 221.72 463.75 220.275Z" fill="#EAF0F9"/>\n' +
  '<path d="M511.98 166.61V159.429L473.584 159.415L473.855 159.401C470.805 157.659 465.489 157.872 461.968 159.897L463.109 159.429L424.684 159.401V167.063C424.599 168.239 425.254 169.386 426.722 170.222L462.809 191.084C465.859 192.827 471.175 192.614 474.696 190.589L509.087 170.675C511.125 169.528 512.094 168.012 511.98 166.61Z" fill="#4A94F8"/>\n' +
  '<path d="M468.339 158.226C466.116 158.282 463.793 158.835 461.968 159.883L463.109 159.415L424.684 159.387V167.049C424.599 168.225 425.254 169.372 426.722 170.208L462.809 191.07C464.277 191.906 466.258 192.288 468.325 192.246L468.339 158.226Z" fill="#9DC6FF"/>\n' +
  '<path d="M429.031 159.401H424.684V167.063C424.599 168.239 425.254 169.386 426.722 170.222L429.031 171.553V159.401Z" fill="#C5DDFF"/>\n' +
  '<path d="M463.579 191.467C466.202 192.586 470.007 192.529 473.071 191.354V181.312H463.579V191.467Z" fill="#75AFFF"/>\n' +
  '<path d="M462.809 183.493L426.722 162.63C423.672 160.888 424.057 157.829 427.577 155.818L461.968 135.904C465.489 133.893 470.805 133.666 473.855 135.409L509.942 156.271C512.992 158.013 512.607 161.072 509.087 163.084L474.696 182.997C471.175 185.023 465.859 185.235 462.809 183.493Z" fill="#C5DDFF"/>\n' +
  '<path d="M460.059 154.104C458.177 155.166 455.127 155.152 453.26 154.076C451.393 152.985 451.393 151.243 453.275 150.181C455.156 149.119 458.206 149.133 460.073 150.209C461.94 151.286 461.94 153.028 460.059 154.104Z" fill="#4B9BFF"/>\n' +
  '<path d="M470.962 160.435C469.081 161.498 466.031 161.483 464.164 160.407C462.297 159.316 462.297 157.574 464.178 156.512C466.059 155.45 469.109 155.464 470.976 156.54C472.858 157.617 472.843 159.359 470.962 160.435Z" fill="#4B9BFF"/>\n' +
  '<path d="M481.865 166.752C479.983 167.814 476.934 167.8 475.066 166.724C473.199 165.633 473.199 163.891 475.081 162.829C476.962 161.766 480.012 161.781 481.879 162.857C483.76 163.948 483.746 165.69 481.865 166.752Z" fill="#4B9BFF"/>\n' +
  '</g>\n' +
  '<defs>\n' +
  '<clipPath id="clip0_7072_74705">\n' +
  '<rect width="512" height="512" fill="white"/>\n' +
  '</clipPath>\n' +
  '</defs>\n' +
  '</svg>\n';

export const notFoundError = '<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<g clip-path="url(#clip0_7072_74210)">\n' +
  '<path d="M69.4313 385.637C69.1969 385.637 68.9771 385.578 68.7573 385.476L37.1052 369.093C35.9475 368.495 35.2148 367.299 35.2002 366C35.1855 364.687 35.8889 363.491 37.0465 362.864L68.4495 345.751C68.7279 345.605 68.7719 345.357 68.7719 345.211C68.7719 345.08 68.7279 344.832 68.4495 344.672L37.4276 327.486C36.3139 326.874 35.6251 325.736 35.6105 324.466C35.5958 323.197 36.2259 322.045 37.325 321.374L80.9785 295.275C84.2463 293.32 88.32 293.276 91.6317 295.143L115.884 308.827C116.587 309.221 116.836 310.111 116.44 310.811C116.045 311.512 115.151 311.76 114.448 311.366L90.1957 297.682C87.8071 296.325 84.8471 296.369 82.4878 297.784L38.8343 323.883C38.5705 324.043 38.5266 324.291 38.5412 324.423C38.5412 324.554 38.5852 324.802 38.8636 324.948L69.8856 342.133C71.0139 342.761 71.7173 343.942 71.7026 345.226C71.7026 346.51 70.9993 347.692 69.8563 348.319L38.4533 365.431C38.1749 365.592 38.1309 365.84 38.1309 365.971C38.1309 366.102 38.1749 366.365 38.468 366.511L70.12 382.894C70.8381 383.259 71.1165 384.149 70.7501 384.863C70.4717 385.345 69.9589 385.637 69.4313 385.637Z" fill="#73B2FF"/>\n' +
  '<path d="M72.9581 391.783L76.8649 385L62.1569 376.604L58.2501 383.388L72.9581 391.783Z" fill="#73B2FF"/>\n' +
  '<path d="M57.4149 381.581C57.4149 379.816 58.6459 377.671 60.1845 376.781C61.7231 375.891 62.9687 376.606 62.9687 378.371C62.9687 380.137 61.7378 382.281 60.1992 383.171C58.6605 384.061 57.4149 383.346 57.4149 381.581Z" fill="#73B2FF"/>\n' +
  '<path d="M92.2414 405.594L98.5753 394.596L75.5028 381.426L69.1689 392.423L92.2414 405.594Z" fill="#73B2FF"/>\n' +
  '<path d="M74.4283 383.595L69.446 380.75C67.3798 382.223 65.7679 385.024 65.5334 387.534L70.5157 390.379C72.5819 388.92 74.1938 386.119 74.4283 383.595Z" fill="#396CAA"/>\n' +
  '<path d="M67.8338 389.503C67.8192 386.644 69.8267 383.172 72.3179 381.727C74.7943 380.298 76.8165 381.45 76.8312 384.295C76.8458 387.154 74.8383 390.626 72.3472 392.071C69.856 393.515 67.8338 392.362 67.8338 389.503Z" fill="#73B2FF"/>\n' +
  '<path d="M63.3551 380.081C64.3749 380.078 65.2 379.598 65.1983 379.01C65.1965 378.422 64.3684 377.948 63.3487 377.951C62.329 377.954 61.5038 378.433 61.5055 379.021C61.5073 379.609 62.3354 380.084 63.3551 380.081Z" fill="#C4DEFF"/>\n' +
  '<path d="M168.915 279.446L91.2647 235.359C88.4219 233.725 88.7883 230.88 92.0561 228.984L136.735 203.308C140.018 201.426 144.971 201.221 147.814 202.855L225.464 246.942C228.307 248.576 227.94 251.421 224.672 253.317L179.993 278.994C176.711 280.875 171.743 281.08 168.915 279.446Z" fill="#EDF5FF"/>\n' +
  '<path d="M232.381 232.105V51.6292L160.314 32.8536L147.038 25.2238C143.99 23.4731 138.671 23.6919 135.154 25.7198L122.771 32.839L84.3347 32.8244L95.7207 207.042C95.6327 208.224 96.2921 209.376 97.7575 210.208L169.678 264.23C172.726 265.98 178.045 265.761 181.562 263.734L229.495 236.19C231.517 235.023 232.499 233.506 232.381 232.105Z" fill="#396CAA"/>\n' +
  '<path d="M141.528 24.0566C139.301 24.115 136.985 24.6694 135.154 25.7197L122.771 32.839L84.3345 32.8244V213.782C84.2466 214.964 84.906 216.116 86.3714 216.948L169.678 264.23C171.143 265.076 173.136 265.455 175.188 265.397L176.052 83.3012L141.528 24.0566Z" fill="#C4DEFF"/>\n' +
  '<path d="M170.44 264.594C173.063 265.718 176.873 265.659 179.936 264.492V81.1566H170.44V264.594Z" fill="#73B2FF"/>\n' +
  '<path d="M169.677 83.3303L86.371 36.0485C83.323 34.2978 83.704 31.2342 87.2209 29.221L135.153 1.67759C138.67 -0.350231 143.989 -0.569061 147.037 1.18158L230.344 48.4634C233.392 50.214 233.011 53.2777 229.494 55.2909L181.562 82.8343C178.045 84.8621 172.725 85.0809 169.677 83.3303Z" fill="#C4DEFF"/>\n' +
  '<path d="M229.494 51.9356L181.562 79.479C178.045 81.5068 172.726 81.7256 169.678 79.975L86.3714 32.6932C85.6973 32.2993 85.1991 31.847 84.8474 31.351C83.807 33.0287 84.2759 34.8523 86.3714 36.0486L169.678 83.3304C172.726 85.081 178.045 84.8622 181.562 82.8344L229.494 55.291C232.235 53.7154 233.055 51.5271 231.868 49.8057C231.385 50.5643 230.594 51.3083 229.494 51.9356Z" fill="#EDF4FF"/>\n' +
  '<path d="M172.037 64.1608L116.573 32.1533C115.151 31.3363 115.327 29.9066 116.968 28.9584L139.403 16.062C141.044 15.1137 143.536 15.0116 144.972 15.8286L200.436 47.8361C201.857 48.653 201.682 50.0827 200.04 51.031L177.606 63.9274C175.95 64.8902 173.458 64.9923 172.037 64.1608Z" fill="#063F85"/>\n' +
  '<path d="M142.085 33.5977L138.876 31.7449C138.686 31.6282 138.7 31.4386 138.935 31.3073L142.041 29.5274C142.276 29.3961 142.613 29.3816 142.818 29.4983L146.027 31.351C146.218 31.4677 146.203 31.6574 145.969 31.7887L142.862 33.5685C142.628 33.6998 142.291 33.7144 142.085 33.5977Z" fill="#C4DEFF"/>\n' +
  '<path d="M152.827 39.7395L149.618 37.8867C149.427 37.77 149.442 37.5803 149.676 37.449L152.783 35.6692C153.017 35.5379 153.354 35.5233 153.56 35.64L156.769 37.4928C156.959 37.6095 156.945 37.7992 156.71 37.9305L153.604 39.7103C153.369 39.8416 153.032 39.8562 152.827 39.7395Z" fill="#C4DEFF"/>\n' +
  '<path d="M163.568 45.8957L160.359 44.043C160.168 43.9262 160.183 43.7366 160.417 43.6053L163.524 41.8255C163.758 41.6942 164.095 41.6796 164.3 41.7963L167.51 43.6491C167.7 43.7658 167.685 43.9554 167.451 44.0867L164.344 45.8665C164.11 45.9832 163.758 45.9978 163.568 45.8957Z" fill="#C4DEFF"/>\n' +
  '<path d="M174.309 52.0375L171.099 50.1848C170.909 50.0681 170.924 49.8784 171.158 49.7471L174.265 47.9673C174.499 47.836 174.836 47.8214 175.041 47.9381L178.25 49.7909C178.441 49.9076 178.426 50.0972 178.192 50.2285L175.085 52.0083C174.836 52.1396 174.499 52.1542 174.309 52.0375Z" fill="#C4DEFF"/>\n' +
  '<path d="M116.573 32.1681L116.631 32.2119C116.734 32.1389 116.836 32.0514 116.968 31.9785L139.403 19.0821C141.044 18.1338 143.535 18.0317 144.971 18.8487L200.363 50.8124C201.681 49.8787 201.74 48.5949 200.421 47.8509L144.957 15.8434C143.535 15.0264 141.044 15.1286 139.388 16.0768L116.953 28.9732C115.327 29.9069 115.151 31.3366 116.573 32.1681Z" fill="#73B2FF"/>\n' +
  '<path d="M190.926 119.087L222.461 101.114C224.234 100.093 225.67 97.3645 225.67 95.0011V83.7678C225.67 81.4191 224.234 80.3249 222.461 81.3461L190.926 99.3194C189.153 100.341 187.717 103.069 187.717 105.432V116.665C187.702 119.029 189.153 120.108 190.926 119.087Z" fill="#063F85"/>\n' +
  '<path d="M224.014 81.0254C224.512 81.4922 224.82 82.28 224.82 83.3596V94.5928C224.82 96.9416 223.384 99.6843 221.611 100.705L190.076 118.679C189.504 119 188.977 119.102 188.508 119.014C189.094 119.569 189.944 119.656 190.911 119.102L222.446 101.129C224.219 100.107 225.655 97.3793 225.655 95.0159V83.7826C225.67 82.1779 224.996 81.1859 224.014 81.0254Z" fill="white"/>\n' +
  '<path d="M200.407 106.891L203.294 105.228C203.675 105.009 203.997 104.411 203.997 103.901V100.822C203.997 100.312 203.69 100.078 203.294 100.297L200.407 101.96C200.026 102.179 199.704 102.777 199.704 103.288V106.366C199.704 106.877 200.012 107.11 200.407 106.891Z" fill="#396CAA"/>\n' +
  '<path d="M210.078 101.333L212.965 99.6697C213.346 99.4509 213.669 98.8527 213.669 98.3421V95.2639C213.669 94.7533 213.361 94.5199 212.965 94.7387L210.078 96.4018C209.697 96.6207 209.375 97.2188 209.375 97.7294V100.808C209.375 101.318 209.683 101.552 210.078 101.333Z" fill="white"/>\n' +
  '<path d="M190.926 200.214L222.461 182.241C224.234 181.22 225.67 178.492 225.67 176.128V164.895C225.67 162.546 224.234 161.452 222.461 162.473L190.926 180.447C189.153 181.468 187.717 184.196 187.717 186.559V197.793C187.702 200.141 189.153 201.236 190.926 200.214Z" fill="#063F85"/>\n' +
  '<path d="M224.014 162.138C224.512 162.604 224.82 163.392 224.82 164.472V175.705C224.82 178.054 223.384 180.796 221.611 181.818L190.076 199.791C189.504 200.112 188.977 200.214 188.508 200.126C189.094 200.681 189.944 200.768 190.911 200.214L222.446 182.241C224.219 181.219 225.655 178.491 225.655 176.128V164.895C225.67 163.305 224.996 162.313 224.014 162.138Z" fill="white"/>\n' +
  '<path d="M200.407 188.019L203.294 186.355C203.675 186.137 203.997 185.538 203.997 185.028V181.95C203.997 181.439 203.69 181.206 203.294 181.424L200.407 183.088C200.026 183.306 199.704 183.905 199.704 184.415V187.493C199.704 188.004 200.012 188.237 200.407 188.019Z" fill="#396CAA"/>\n' +
  '<path d="M210.078 182.461L212.965 180.797C213.346 180.579 213.669 179.98 213.669 179.47V176.392C213.669 175.881 213.361 175.648 212.965 175.866L210.078 177.53C209.697 177.748 209.375 178.347 209.375 178.857V181.935C209.375 182.446 209.683 182.679 210.078 182.461Z" fill="white"/>\n' +
  '<path d="M190.926 240.771L222.461 222.798C224.234 221.777 225.67 219.049 225.67 216.685V205.452C225.67 203.103 224.234 202.009 222.461 203.03L190.926 221.004C189.153 222.025 187.717 224.753 187.717 227.116V238.35C187.702 240.713 189.153 241.793 190.926 240.771Z" fill="#063F85"/>\n' +
  '<path d="M224.014 202.709C224.512 203.176 224.82 203.964 224.82 205.044V216.277C224.82 218.626 223.384 221.368 221.611 222.389L190.076 240.363C189.504 240.684 188.977 240.786 188.508 240.698C189.094 241.253 189.944 241.34 190.911 240.786L222.446 222.813C224.219 221.791 225.655 219.063 225.655 216.7V205.467C225.67 203.862 224.996 202.87 224.014 202.709Z" fill="white"/>\n' +
  '<path d="M200.407 228.575L203.294 226.912C203.675 226.693 203.997 226.095 203.997 225.585V222.507C203.997 221.996 203.69 221.763 203.294 221.981L200.407 223.644C200.026 223.863 199.704 224.461 199.704 224.972V228.05C199.704 228.561 200.012 228.794 200.407 228.575Z" fill="#396CAA"/>\n' +
  '<path d="M210.078 223.017L212.965 221.354C213.346 221.135 213.669 220.537 213.669 220.026V216.948C213.669 216.437 213.361 216.204 212.965 216.423L210.078 218.086C209.697 218.305 209.375 218.903 209.375 219.413V222.492C209.375 223.002 209.683 223.236 210.078 223.017Z" fill="white"/>\n' +
  '<path d="M190.926 159.644L222.461 141.67C224.234 140.649 225.67 137.921 225.67 135.558V124.324C225.67 121.976 224.234 120.882 222.461 121.903L190.926 139.876C189.153 140.897 187.717 143.625 187.717 145.989V157.222C187.702 159.585 189.153 160.665 190.926 159.644Z" fill="#063F85"/>\n' +
  '<path d="M224.014 121.582C224.512 122.049 224.82 122.837 224.82 123.916V135.149C224.82 137.498 223.384 140.241 221.611 141.262L190.076 159.235C189.504 159.556 188.977 159.658 188.508 159.571C189.094 160.125 189.944 160.213 190.911 159.658L222.446 141.685C224.219 140.664 225.655 137.936 225.655 135.573V124.339C225.67 122.735 224.996 121.743 224.014 121.582Z" fill="white"/>\n' +
  '<path d="M200.407 147.447L203.294 145.784C203.675 145.565 203.997 144.967 203.997 144.457V141.379C203.997 140.868 203.69 140.635 203.294 140.853L200.407 142.516C200.026 142.735 199.704 143.333 199.704 143.844V146.922C199.704 147.433 200.012 147.681 200.407 147.447Z" fill="#396CAA"/>\n' +
  '<path d="M210.078 141.889L212.965 140.226C213.346 140.007 213.669 139.409 213.669 138.898V135.82C213.669 135.31 213.361 135.076 212.965 135.295L210.078 136.958C209.697 137.177 209.375 137.775 209.375 138.286V141.364C209.375 141.874 209.683 142.122 210.078 141.889Z" fill="white"/>\n' +
  '<path d="M163.612 107.591L90.8705 65.7803C89.2439 64.8466 87.925 62.3374 87.925 60.1782V46.3628C87.925 44.2037 89.2439 43.2116 90.8705 44.1453L163.612 85.9564C165.238 86.8901 166.557 89.3993 166.557 91.5584V105.374C166.557 107.533 165.238 108.525 163.612 107.591Z" fill="#73B2FF"/>\n' +
  '<path opacity="0.4" d="M161.516 102.748L92.9515 63.3294C92.1308 62.8479 91.4568 61.5787 91.4568 60.4846V50.0975C91.4568 49.0033 92.1308 48.4927 92.9515 48.9741L161.516 88.3927C162.337 88.8741 163.011 90.1433 163.011 91.2375V101.625C163.011 102.719 162.352 103.229 161.516 102.748Z" fill="#C4DEFF"/>\n' +
  '<path d="M107.238 66.3053L97.01 60.426C96.204 59.9592 95.5592 58.7338 95.5592 57.6688C95.5592 56.6038 96.204 56.1078 97.01 56.5746L107.238 62.4539C108.044 62.9207 108.689 64.1462 108.689 65.2111C108.689 66.2761 108.044 66.7721 107.238 66.3053Z" fill="#C4DEFF"/>\n' +
  '<path d="M115.465 70.4833C116.117 70.1087 116.114 68.8886 115.458 67.7583C114.803 66.6279 113.743 66.0153 113.091 66.39C112.439 66.7646 112.442 67.9847 113.098 69.115C113.754 70.2454 114.813 70.858 115.465 70.4833Z" fill="#063F85"/>\n' +
  '<path d="M121.878 72.7826C121.878 73.8475 121.13 74.2852 120.207 73.7454C119.284 73.2202 118.537 71.9218 118.537 70.8569C118.537 69.7919 119.284 69.3542 120.207 69.894C121.13 70.4338 121.878 71.7176 121.878 72.7826Z" fill="#063F85"/>\n' +
  '<path d="M127.798 76.1966C127.798 77.2616 127.051 77.6993 126.127 77.1595C125.204 76.6343 124.457 75.3359 124.457 74.2709C124.457 73.206 125.204 72.7683 126.127 73.3081C127.051 73.8333 127.798 75.1317 127.798 76.1966Z" fill="#C4DEFF"/>\n' +
  '<path d="M133.718 79.5959C133.718 80.6608 132.971 81.0985 132.048 80.5587C131.124 80.0335 130.377 78.7351 130.377 77.6702C130.377 76.6052 131.124 76.1675 132.048 76.7073C132.956 77.2325 133.718 78.5309 133.718 79.5959Z" fill="#063F85"/>\n' +
  '<path d="M158.644 96.9709L155.2 94.9868C154.687 94.6951 154.262 93.8927 154.262 93.207V89.5745C154.262 88.8888 154.673 88.5824 155.2 88.8742L158.644 90.8583C159.157 91.15 159.582 91.9524 159.582 92.6381V96.2707C159.567 96.9563 159.157 97.2627 158.644 96.9709Z" fill="#063F85"/>\n' +
  '<path d="M163.611 131.954L90.8555 90.1287C89.2289 89.1951 87.91 86.6858 87.91 84.5267V70.7112C87.91 68.5521 89.2289 67.5601 90.8555 68.4938L163.597 110.305C165.223 111.239 166.542 113.748 166.542 115.907V129.722C166.557 131.896 165.238 132.888 163.611 131.954Z" fill="#73B2FF"/>\n' +
  '<path opacity="0.4" d="M161.515 127.111L92.9505 87.6922C92.1299 87.2108 91.4558 85.9416 91.4558 84.8475V74.4603C91.4558 73.3662 92.1299 72.8556 92.9505 73.337L161.515 112.756C162.336 113.237 163.01 114.506 163.01 115.6V125.987C163.01 127.082 162.351 127.578 161.515 127.111Z" fill="#C4DEFF"/>\n' +
  '<path d="M107.238 90.6537L97.0098 84.7745C96.2039 84.3077 95.5591 83.0822 95.5591 82.0172C95.5591 80.9523 96.2039 80.4563 97.0098 80.9231L107.238 86.8023C108.044 87.2692 108.689 88.4946 108.689 89.5596C108.689 90.6245 108.044 91.1206 107.238 90.6537Z" fill="#C4DEFF"/>\n' +
  '<path d="M115.957 93.7467C115.957 94.8116 115.209 95.2493 114.286 94.7095C113.363 94.1843 112.616 92.8859 112.616 91.821C112.616 90.756 113.363 90.3183 114.286 90.8581C115.209 91.3833 115.957 92.6817 115.957 93.7467Z" fill="white"/>\n' +
  '<path d="M121.877 97.1459C121.877 98.2109 121.129 98.6485 120.206 98.1087C119.283 97.5835 118.536 96.2852 118.536 95.2202C118.536 94.1552 119.283 93.7176 120.206 94.2573C121.129 94.7825 121.877 96.0809 121.877 97.1459Z" fill="#063F85"/>\n' +
  '<path d="M127.797 100.545C127.797 101.61 127.05 102.048 126.126 101.508C125.203 100.983 124.456 99.6844 124.456 98.6194C124.456 97.5544 125.203 97.1168 126.126 97.6565C127.05 98.1817 127.797 99.4801 127.797 100.545Z" fill="#C4DEFF"/>\n' +
  '<path d="M133.212 105.047C133.864 104.672 133.861 103.452 133.205 102.322C132.55 101.191 131.49 100.579 130.838 100.953C130.186 101.328 130.189 102.548 130.845 103.678C131.5 104.809 132.56 105.421 133.212 105.047Z" fill="#063F85"/>\n' +
  '<path d="M158.643 121.319L155.199 119.335C154.686 119.043 154.261 118.241 154.261 117.555V113.923C154.261 113.237 154.672 112.931 155.199 113.222L158.643 115.206C159.156 115.498 159.581 116.3 159.581 116.986V120.619C159.566 121.304 159.156 121.625 158.643 121.319Z" fill="#063F85"/>\n' +
  '<path d="M163.612 156.303L90.8705 114.492C89.2439 113.558 87.925 111.049 87.925 108.89V95.0741C87.925 92.915 89.2439 91.923 90.8705 92.8566L163.612 134.668C165.238 135.601 166.557 138.111 166.557 140.27V154.085C166.557 156.244 165.238 157.236 163.612 156.303Z" fill="#73B2FF"/>\n' +
  '<path opacity="0.4" d="M161.516 151.459L92.9515 112.041C92.1308 111.559 91.4568 110.29 91.4568 109.196V98.8088C91.4568 97.7146 92.1308 97.204 92.9515 97.6855L161.516 137.104C162.337 137.585 163.011 138.855 163.011 139.949V150.336C163.011 151.43 162.352 151.941 161.516 151.459Z" fill="#C4DEFF"/>\n' +
  '<path d="M107.238 115.017L97.01 109.137C96.204 108.671 95.5592 107.445 95.5592 106.38C95.5592 105.315 96.204 104.819 97.01 105.286L107.238 111.165C108.044 111.632 108.689 112.857 108.689 113.922C108.689 114.987 108.044 115.469 107.238 115.017Z" fill="#C4DEFF"/>\n' +
  '<path d="M115.958 118.095C115.958 119.16 115.21 119.598 114.287 119.058C113.364 118.533 112.617 117.234 112.617 116.169C112.617 115.104 113.364 114.667 114.287 115.207C115.21 115.732 115.958 117.03 115.958 118.095Z" fill="white"/>\n' +
  '<path d="M121.878 121.494C121.878 122.559 121.13 122.997 120.207 122.457C119.284 121.932 118.537 120.633 118.537 119.568C118.537 118.503 119.284 118.066 120.207 118.605C121.13 119.131 121.878 120.429 121.878 121.494Z" fill="#063F85"/>\n' +
  '<path d="M127.798 124.894C127.798 125.959 127.051 126.396 126.127 125.856C125.204 125.331 124.457 124.033 124.457 122.968C124.457 121.903 125.204 121.465 126.127 122.005C127.051 122.545 127.798 123.829 127.798 124.894Z" fill="#C4DEFF"/>\n' +
  '<path d="M133.718 128.293C133.718 129.358 132.971 129.795 132.048 129.256C131.124 128.73 130.377 127.432 130.377 126.367C130.377 125.302 131.124 124.864 132.048 125.404C132.956 125.944 133.718 127.228 133.718 128.293Z" fill="#063F85"/>\n' +
  '<path d="M158.644 145.682L155.2 143.698C154.687 143.406 154.262 142.604 154.262 141.918V138.286C154.262 137.6 154.673 137.294 155.2 137.586L158.644 139.57C159.157 139.861 159.582 140.664 159.582 141.349V144.982C159.567 145.653 159.157 145.974 158.644 145.682Z" fill="#063F85"/>\n' +
  '<path d="M163.612 180.651L90.8705 138.84C89.2439 137.906 87.925 135.397 87.925 133.238V119.423C87.925 117.263 89.2439 116.271 90.8705 117.205L163.612 159.016C165.238 159.95 166.557 162.459 166.557 164.618V178.434C166.557 180.593 165.238 181.585 163.612 180.651Z" fill="#73B2FF"/>\n' +
  '<path opacity="0.4" d="M161.516 175.808L92.9515 136.389C92.1308 135.908 91.4568 134.639 91.4568 133.544V123.157C91.4568 122.063 92.1308 121.552 92.9515 122.034L161.516 161.452C162.337 161.934 163.011 163.203 163.011 164.297V174.684C163.011 175.779 162.352 176.289 161.516 175.808Z" fill="#C4DEFF"/>\n' +
  '<path d="M107.238 139.365L97.01 133.486C96.204 133.019 95.5592 131.794 95.5592 130.729C95.5592 129.664 96.204 129.168 97.01 129.634L107.238 135.514C108.044 135.98 108.689 137.206 108.689 138.271C108.689 139.336 108.044 139.817 107.238 139.365Z" fill="#C4DEFF"/>\n' +
  '<path d="M115.958 142.443C115.958 143.508 115.21 143.946 114.287 143.406C113.364 142.866 112.617 141.582 112.617 140.517C112.617 139.452 113.364 139.015 114.287 139.555C115.21 140.094 115.958 141.378 115.958 142.443Z" fill="#063F85"/>\n' +
  '<path d="M121.369 146.948C122.02 146.573 122.017 145.353 121.362 144.223C120.706 143.092 119.647 142.48 118.995 142.854C118.343 143.229 118.346 144.449 119.002 145.579C119.657 146.71 120.717 147.322 121.369 146.948Z" fill="#063F85"/>\n' +
  '<path d="M127.798 149.256C127.798 150.321 127.051 150.759 126.127 150.219C125.204 149.679 124.457 148.395 124.457 147.33C124.457 146.265 125.204 145.828 126.127 146.367C127.051 146.893 127.798 148.191 127.798 149.256Z" fill="#C4DEFF"/>\n' +
  '<path d="M133.718 152.655C133.718 153.72 132.971 154.158 132.048 153.618C131.124 153.078 130.377 151.795 130.377 150.73C130.377 149.665 131.124 149.227 132.048 149.767C132.956 150.292 133.718 151.59 133.718 152.655Z" fill="#063F85"/>\n' +
  '<path d="M158.644 170.03L155.2 168.046C154.687 167.754 154.262 166.952 154.262 166.266V162.634C154.262 161.948 154.673 161.642 155.2 161.934L158.644 163.918C159.157 164.209 159.582 165.012 159.582 165.697V169.33C159.567 170.016 159.157 170.322 158.644 170.03Z" fill="#C4DEFF"/>\n' +
  '<path d="M163.612 205.014L90.8705 163.203C89.2439 162.27 87.925 159.76 87.925 157.601V143.786C87.925 141.627 89.2439 140.635 90.8705 141.568L163.612 183.379C165.238 184.313 166.557 186.822 166.557 188.982V202.797C166.557 204.956 165.238 205.948 163.612 205.014Z" fill="#73B2FF"/>\n' +
  '<path opacity="0.4" d="M161.516 200.171L92.9515 160.752C92.1308 160.271 91.4568 159.002 91.4568 157.908V147.52C91.4568 146.426 92.1308 145.916 92.9515 146.397L161.516 185.816C162.337 186.297 163.011 187.566 163.011 188.661V199.048C163.011 200.142 162.352 200.638 161.516 200.171Z" fill="#C4DEFF"/>\n' +
  '<path d="M107.238 163.714L97.01 157.835C96.204 157.368 95.5592 156.143 95.5592 155.078C95.5592 154.013 96.204 153.517 97.01 153.984L107.238 159.863C108.044 160.33 108.689 161.555 108.689 162.62C108.689 163.685 108.044 164.181 107.238 163.714Z" fill="#C4DEFF"/>\n' +
  '<path d="M115.958 166.807C115.958 167.872 115.21 168.309 114.287 167.77C113.364 167.244 112.617 165.946 112.617 164.881C112.617 163.816 113.364 163.378 114.287 163.918C115.21 164.443 115.958 165.742 115.958 166.807Z" fill="#063F85"/>\n' +
  '<path d="M121.878 170.206C121.878 171.271 121.13 171.709 120.207 171.169C119.284 170.644 118.537 169.345 118.537 168.28C118.537 167.215 119.284 166.778 120.207 167.317C121.13 167.843 121.878 169.141 121.878 170.206Z" fill="white"/>\n' +
  '<path d="M127.798 173.605C127.798 174.67 127.051 175.108 126.127 174.568C125.204 174.043 124.457 172.745 124.457 171.68C124.457 170.615 125.204 170.177 126.127 170.717C127.051 171.242 127.798 172.54 127.798 173.605Z" fill="#C4DEFF"/>\n' +
  '<path d="M133.718 177.004C133.718 178.069 132.971 178.507 132.048 177.967C131.124 177.442 130.377 176.144 130.377 175.079C130.377 174.014 131.124 173.576 132.048 174.116C132.956 174.641 133.718 175.939 133.718 177.004Z" fill="white"/>\n' +
  '<path d="M158.644 194.38L155.2 192.395C154.687 192.104 154.262 191.301 154.262 190.616V186.983C154.262 186.297 154.673 185.991 155.2 186.283L158.644 188.267C159.157 188.559 159.582 189.361 159.582 190.047V193.679C159.567 194.365 159.157 194.686 158.644 194.38Z" fill="#C4DEFF"/>\n' +
  '<path d="M163.612 229.363L90.8705 187.552C89.2439 186.618 87.925 184.109 87.925 181.95V168.134C87.925 165.975 89.2439 164.983 90.8705 165.917L163.612 207.728C165.238 208.662 166.557 211.171 166.557 213.33V227.145C166.557 229.304 165.238 230.297 163.612 229.363Z" fill="#73B2FF"/>\n' +
  '<path opacity="0.4" d="M161.516 224.519L92.9515 185.101C92.1308 184.619 91.4568 183.35 91.4568 182.256V171.869C91.4568 170.775 92.1308 170.264 92.9515 170.746L161.516 210.164C162.337 210.646 163.011 211.915 163.011 213.009V223.396C163.011 224.49 162.352 225.001 161.516 224.519Z" fill="#C4DEFF"/>\n' +
  '<path d="M107.238 188.077L97.01 182.198C96.204 181.731 95.5592 180.505 95.5592 179.44C95.5592 178.375 96.204 177.879 97.01 178.346L107.238 184.225C108.044 184.692 108.689 185.918 108.689 186.983C108.689 188.048 108.044 188.529 107.238 188.077Z" fill="#C4DEFF"/>\n' +
  '<path d="M115.464 192.253C116.115 191.879 116.112 190.659 115.457 189.528C114.801 188.398 113.741 187.785 113.09 188.16C112.438 188.535 112.441 189.755 113.096 190.885C113.752 192.015 114.812 192.628 115.464 192.253Z" fill="#063F85"/>\n' +
  '<path d="M121.878 194.554C121.878 195.619 121.13 196.057 120.207 195.517C119.284 194.978 118.537 193.694 118.537 192.629C118.537 191.564 119.284 191.126 120.207 191.666C121.13 192.191 121.878 193.489 121.878 194.554Z" fill="#063F85"/>\n' +
  '<path d="M127.798 197.954C127.798 199.019 127.051 199.456 126.127 198.917C125.204 198.377 124.457 197.093 124.457 196.028C124.457 194.963 125.204 194.525 126.127 195.065C127.051 195.59 127.798 196.889 127.798 197.954Z" fill="#C4DEFF"/>\n' +
  '<path d="M133.718 201.353C133.718 202.418 132.971 202.856 132.048 202.316C131.124 201.791 130.377 200.492 130.377 199.427C130.377 198.362 131.124 197.925 132.048 198.464C132.956 199.004 133.718 200.288 133.718 201.353Z" fill="#063F85"/>\n' +
  '<path d="M158.644 218.742L155.2 216.758C154.687 216.466 154.262 215.664 154.262 214.978V211.346C154.262 210.66 154.673 210.354 155.2 210.645L158.644 212.629C159.157 212.921 159.582 213.724 159.582 214.409V218.042C159.567 218.713 159.157 219.034 158.644 218.742Z" fill="#063F85"/>\n' +
  '<path d="M163.612 253.711L90.8705 211.9C89.2439 210.967 87.925 208.457 87.925 206.298V192.483C87.925 190.324 89.2439 189.332 90.8705 190.265L163.612 232.076C165.238 233.01 166.557 235.519 166.557 237.678V251.494C166.557 253.653 165.238 254.645 163.612 253.711Z" fill="#73B2FF"/>\n' +
  '<path opacity="0.4" d="M161.516 248.868L92.9515 209.449C92.1308 208.968 91.4568 207.699 91.4568 206.605V196.217C91.4568 195.123 92.1308 194.613 92.9515 195.094L161.516 234.513C162.337 234.994 163.011 236.263 163.011 237.357V247.745C163.011 248.839 162.352 249.349 161.516 248.868Z" fill="#C4DEFF"/>\n' +
  '<path d="M107.238 212.425L97.01 206.546C96.204 206.079 95.5592 204.854 95.5592 203.789C95.5592 202.724 96.204 202.228 97.01 202.695L107.238 208.574C108.044 209.041 108.689 210.266 108.689 211.331C108.689 212.396 108.044 212.877 107.238 212.425Z" fill="#C4DEFF"/>\n' +
  '<path d="M117.832 216.832C117.832 218.845 116.411 219.662 114.667 218.655C112.923 217.649 111.502 215.198 111.502 213.199C111.502 211.186 112.923 210.369 114.667 211.375C116.411 212.368 117.832 214.818 117.832 216.832Z" fill="white"/>\n' +
  '<path d="M130.084 223.965C130.084 225.03 129.337 225.468 128.414 224.928C127.491 224.388 126.743 223.105 126.743 222.04C126.743 220.975 127.491 220.537 128.414 221.077C129.337 221.602 130.084 222.9 130.084 223.965Z" fill="#063F85"/>\n' +
  '<path d="M136.004 227.365C136.004 228.43 135.256 228.867 134.333 228.327C133.41 227.788 132.663 226.504 132.663 225.439C132.663 224.374 133.41 223.936 134.333 224.476C135.256 225.001 136.004 226.3 136.004 227.365Z" fill="#C4DEFF"/>\n' +
  '<path d="M141.924 230.763C141.924 231.828 141.176 232.266 140.253 231.726C139.33 231.186 138.583 229.902 138.583 228.837C138.583 227.772 139.33 227.335 140.253 227.875C141.162 228.414 141.924 229.698 141.924 230.763Z" fill="#063F85"/>\n' +
  '<path d="M158.644 243.09L155.2 241.106C154.687 240.815 154.262 240.012 154.262 239.327V235.694C154.262 235.008 154.673 234.702 155.2 234.994L158.644 236.978C159.157 237.27 159.582 238.072 159.582 238.758V242.39C159.567 243.076 159.157 243.382 158.644 243.09Z" fill="#C4DEFF"/>\n' +
  '<path d="M158.775 314.867C158.526 314.867 158.277 314.808 158.057 314.677L75.3516 267.891C74.3405 267.322 73.7396 266.301 73.725 265.148C73.7103 263.996 74.3112 262.96 75.3076 262.377L110.784 241.529C112.03 240.8 112.807 239.443 112.807 237.999V215.007C112.807 214.205 113.466 213.548 114.272 213.548C115.078 213.548 115.737 214.205 115.737 215.007V237.984C115.737 240.464 114.404 242.769 112.264 244.039L76.7876 264.886C76.6997 264.944 76.6557 265.017 76.6557 265.119C76.6557 265.221 76.6997 265.309 76.8023 265.353L159.508 312.139C160.211 312.532 160.46 313.422 160.065 314.123C159.786 314.604 159.288 314.867 158.775 314.867Z" fill="#73B2FF"/>\n' +
  '<path d="M143.044 309.187L146.95 302.404L132.242 294.008L128.336 300.792L143.044 309.187Z" fill="#73B2FF"/>\n' +
  '<path d="M127.504 298.994C127.504 297.229 128.735 295.084 130.274 294.195C131.812 293.305 133.058 294.02 133.058 295.785C133.058 297.55 131.827 299.695 130.288 300.584C128.75 301.46 127.504 300.759 127.504 298.994Z" fill="#73B2FF"/>\n' +
  '<path d="M162.329 322.999L168.662 312.002L145.59 298.831L139.256 309.829L162.329 322.999Z" fill="#73B2FF"/>\n' +
  '<path d="M144.518 301.007L139.535 298.162C137.469 299.636 135.857 302.437 135.623 304.946L140.605 307.791C142.671 306.318 144.283 303.517 144.518 301.007Z" fill="#396CAA"/>\n' +
  '<path d="M137.923 306.916C137.908 304.056 139.915 300.584 142.407 299.14C144.883 297.71 146.905 298.863 146.92 301.707C146.935 304.567 144.927 308.039 142.436 309.483C139.945 310.927 137.923 309.775 137.923 306.916Z" fill="#73B2FF"/>\n' +
  '<path d="M132.135 297.185C131.417 296.777 131.417 296.106 132.135 295.683C132.853 295.274 134.025 295.259 134.743 295.668C135.461 296.076 135.461 296.747 134.743 297.171C134.025 297.594 132.868 297.594 132.135 297.185Z" fill="#C4DEFF"/>\n' +
  '<path d="M125.158 319.937L129.065 313.154L114.357 304.758L110.45 311.542L125.158 319.937Z" fill="#73B2FF"/>\n' +
  '<path d="M109.612 309.747C109.612 307.981 110.843 305.837 112.382 304.947C113.92 304.057 115.166 304.772 115.166 306.537C115.166 308.302 113.935 310.447 112.396 311.337C110.872 312.227 109.627 311.512 109.612 309.747Z" fill="#73B2FF"/>\n' +
  '<path d="M144.445 333.764L150.779 322.766L127.707 309.596L121.373 320.594L144.445 333.764Z" fill="#73B2FF"/>\n' +
  '<path d="M126.626 311.774L121.643 308.93C119.577 310.403 117.965 313.204 117.731 315.713L122.713 318.558C124.779 317.085 126.406 314.284 126.626 311.774Z" fill="#396CAA"/>\n' +
  '<path d="M120.031 317.683C120.017 314.823 122.024 311.351 124.515 309.907C126.992 308.477 129.014 309.63 129.029 312.475C129.044 315.334 127.036 318.806 124.545 320.25C122.068 321.68 120.046 320.528 120.031 317.683Z" fill="#73B2FF"/>\n' +
  '<path d="M114.258 307.937C113.54 307.529 113.54 306.858 114.258 306.435C114.976 306.012 116.148 306.012 116.866 306.42C117.585 306.829 117.585 307.5 116.866 307.923C116.148 308.346 114.976 308.346 114.258 307.937Z" fill="#C4DEFF"/>\n' +
  '<path d="M294.337 456.1L124.149 358.268C119.328 355.496 119.943 350.667 125.497 347.472L241.379 280.89C246.932 277.695 255.344 277.345 260.165 280.117L430.353 377.934C435.174 380.705 434.559 385.534 429.005 388.729L313.123 455.312C307.57 458.507 299.158 458.857 294.337 456.1Z" fill="#EDF5FF"/>\n' +
  '<path d="M470.225 204.635L300.037 106.818C298.058 105.68 296.051 105.505 294.263 106.118L294.278 106.103L293.97 106.22C293.941 106.234 293.926 106.234 293.897 106.249L287.742 108.598L293.267 111.282C293.237 111.384 293.208 111.472 293.193 111.574L265.469 237.197C264.076 243.484 266.861 250.823 271.667 253.594L437.767 349.063L437.181 356.065L443.907 353.468C446.164 352.418 447.981 350.04 448.728 346.655L476.453 221.032C477.816 214.745 475.032 207.407 470.225 204.635Z" fill="#063F85"/>\n' +
  '<path d="M470.226 204.635L300.037 106.818C298.059 105.68 296.051 105.505 294.264 106.118L294.278 106.103L293.971 106.22C293.941 106.234 293.927 106.234 293.897 106.249L287.743 108.598L293.267 111.282C293.238 111.384 293.209 111.472 293.194 111.574L274.921 194.364L358.198 258.948L475.618 211.389C474.475 208.486 472.6 206.006 470.226 204.635Z" fill="#396CAA"/>\n' +
  '<path d="M463.808 223.95L476.762 218.465C476.996 213.476 474.915 208.311 471.516 205.554L451.015 214.147L463.808 223.95Z" fill="#1255A7"/>\n' +
  '<path d="M431.262 355.409L261.073 257.592C256.252 254.82 253.483 247.482 254.875 241.194L282.6 115.571C283.992 109.283 289.018 106.424 293.824 109.196L464.013 207.013C468.834 209.785 471.604 217.123 470.211 223.41L442.487 349.033C441.094 355.321 436.068 358.166 431.262 355.409Z" fill="#C4DEFF"/>\n' +
  '<path d="M427.569 346.057L267.506 254.105C263.095 251.567 260.56 244.856 261.821 239.108L287.186 124.149C288.461 118.401 293.062 115.79 297.458 118.314L457.521 210.266C461.932 212.805 464.467 219.515 463.207 225.263L437.841 340.222C436.581 345.984 431.98 348.596 427.569 346.057Z" fill="white"/>\n' +
  '<path d="M427.569 346.057L267.506 254.105C263.095 251.567 260.56 244.856 261.821 239.108L287.186 124.149C288.461 118.401 293.062 115.79 297.458 118.314L457.521 210.266C461.932 212.805 464.467 219.515 463.207 225.263L437.841 340.222C436.581 345.984 431.98 348.596 427.569 346.057Z" fill="white"/>\n' +
  '<path d="M433.606 359.668L410.335 356.604L260.164 270.283C255.343 267.511 246.946 267.861 241.378 271.056L134.962 332.197L120.895 330.345V338.296H120.909C120.822 340.134 121.862 341.899 124.133 343.197L294.322 441.014C299.143 443.786 307.539 443.436 313.108 440.241L428.99 373.658C432.111 371.864 433.679 369.544 433.591 367.371V359.668H433.606Z" fill="#063F85"/>\n' +
  '<path d="M320.772 305.121L310.104 298.994H192.801L134.978 332.212L120.91 330.359V338.31H120.925C120.837 340.148 121.877 341.914 124.148 343.212L294.337 441.029C296.887 442.488 300.433 443.071 304.038 442.838L320.772 305.121Z" fill="#396CAA"/>\n' +
  '<path d="M127.153 331.176L120.91 330.359V338.31H120.925C120.837 340.148 121.877 341.914 124.148 343.212L127.153 344.933V331.176Z" fill="#73B2FF"/>\n' +
  '<path d="M296.051 441.831C300.609 443.553 307.144 443.173 312.009 440.839V423.114H296.051V441.831Z" fill="#1255A7"/>\n' +
  '<path d="M294.337 433.108L124.149 335.291C119.328 332.519 119.943 327.69 125.497 324.495L241.379 257.913C246.932 254.718 255.344 254.368 260.165 257.139L430.353 354.956C435.174 357.728 434.559 362.557 429.005 365.752L313.123 432.335C307.57 435.515 299.158 435.865 294.337 433.108Z" fill="#C4DEFF"/>\n' +
  '<path d="M429.005 362.834L313.123 429.417C307.569 432.611 299.158 432.962 294.337 430.19L124.148 332.373C122.566 331.468 121.584 330.33 121.159 329.12C120.412 331.381 121.364 333.686 124.148 335.276L294.337 433.093C299.158 435.865 307.554 435.515 313.123 432.32L429.005 365.737C432.741 363.593 434.221 360.704 433.342 358.195C432.8 359.858 431.349 361.492 429.005 362.834Z" fill="white"/>\n' +
  '<path d="M196.539 302.89L188.845 298.469C188.23 298.119 188.303 297.492 189.021 297.083L198.107 291.86C198.825 291.452 199.894 291.408 200.524 291.758L208.232 296.179C208.848 296.529 208.774 297.156 208.056 297.565L198.971 302.787C198.238 303.196 197.154 303.254 196.539 302.89Z" fill="#063F85"/>\n' +
  '<path d="M208.423 309.731L200.715 305.311C200.099 304.961 200.173 304.333 200.891 303.925L209.976 298.702C210.694 298.294 211.764 298.25 212.394 298.6L220.087 303.02C220.702 303.371 220.629 303.998 219.911 304.406L210.826 309.629C210.122 310.038 209.038 310.081 208.423 309.731Z" fill="#063F85"/>\n' +
  '<path d="M220.321 316.559L212.628 312.139C212.013 311.789 212.086 311.162 212.804 310.753L221.889 305.53C222.607 305.122 223.677 305.078 224.307 305.428L232.015 309.849C232.63 310.199 232.557 310.826 231.839 311.235L222.754 316.457C222.021 316.88 220.937 316.924 220.321 316.559Z" fill="#063F85"/>\n' +
  '<path d="M236.762 310.913L229.054 306.493C228.439 306.142 228.512 305.515 229.23 305.107L236.865 300.715C237.583 300.307 238.653 300.263 239.283 300.613L246.991 305.034C247.606 305.384 247.533 306.011 246.815 306.42L239.18 310.811C238.462 311.234 237.378 311.278 236.762 310.913Z" fill="#063F85"/>\n' +
  '<path d="M248.661 317.756L240.968 313.336C240.352 312.985 240.425 312.358 241.143 311.95L248.778 307.559C249.496 307.15 250.566 307.106 251.196 307.456L258.889 311.877C259.505 312.227 259.431 312.854 258.713 313.263L251.079 317.654C250.361 318.062 249.276 318.106 248.661 317.756Z" fill="#063F85"/>\n' +
  '<path d="M260.545 324.583L252.837 320.162C252.222 319.812 252.295 319.185 253.013 318.776L260.648 314.385C261.366 313.977 262.436 313.933 263.066 314.283L270.774 318.703C271.389 319.053 271.316 319.681 270.598 320.089L262.963 324.48C262.245 324.903 261.161 324.947 260.545 324.583Z" fill="#063F85"/>\n' +
  '<path d="M272.444 331.425L264.751 327.004C264.135 326.654 264.208 326.027 264.926 325.618L272.561 321.227C273.279 320.819 274.349 320.775 274.979 321.125L282.672 325.545C283.288 325.896 283.214 326.523 282.496 326.931L274.862 331.323C274.144 331.731 273.059 331.775 272.444 331.425Z" fill="#063F85"/>\n' +
  '<path d="M284.328 338.253L276.62 333.832C276.005 333.482 276.078 332.855 276.796 332.446L284.431 328.055C285.149 327.647 286.219 327.603 286.849 327.953L294.557 332.374C295.172 332.724 295.099 333.351 294.381 333.76L286.746 338.151C286.028 338.574 284.944 338.618 284.328 338.253Z" fill="#063F85"/>\n' +
  '<path d="M296.228 345.094L288.534 340.674C287.919 340.324 287.992 339.696 288.71 339.288L296.345 334.897C297.063 334.488 298.133 334.445 298.763 334.795L306.456 339.215C307.071 339.565 306.998 340.192 306.28 340.601L298.646 344.992C297.927 345.401 296.843 345.444 296.228 345.094Z" fill="#063F85"/>\n' +
  '<path d="M308.111 351.936L300.403 347.516C299.788 347.166 299.861 346.539 300.579 346.13L308.214 341.739C308.932 341.33 310.002 341.287 310.632 341.637L318.339 346.057C318.955 346.407 318.882 347.035 318.164 347.443L310.529 351.834C309.811 352.243 308.727 352.287 308.111 351.936Z" fill="#063F85"/>\n' +
  '<path d="M320.01 358.765L312.302 354.344C311.687 353.994 311.76 353.367 312.478 352.958L320.113 348.567C320.831 348.159 321.9 348.115 322.531 348.465L330.238 352.885C330.854 353.235 330.781 353.863 330.063 354.271L322.428 358.662C321.71 359.071 320.626 359.115 320.01 358.765Z" fill="#063F85"/>\n' +
  '<path d="M331.894 365.606L324.186 361.186C323.571 360.835 323.644 360.208 324.362 359.8L331.997 355.408C332.715 355 333.784 354.956 334.415 355.306L342.122 359.727C342.738 360.077 342.665 360.704 341.947 361.113L334.312 365.504C333.594 365.912 332.524 365.956 331.894 365.606Z" fill="#063F85"/>\n' +
  '<path d="M343.793 372.434L336.085 368.014C335.47 367.664 335.543 367.036 336.261 366.628L343.896 362.237C344.614 361.828 345.683 361.784 346.314 362.134L354.021 366.555C354.637 366.905 354.564 367.532 353.846 367.941L346.211 372.332C345.493 372.74 344.409 372.784 343.793 372.434Z" fill="#063F85"/>\n' +
  '<path d="M254.288 307.675L246.58 303.254C245.965 302.904 246.038 302.277 246.756 301.868L254.391 297.477C255.109 297.069 256.178 297.025 256.809 297.375L264.516 301.795C265.132 302.146 265.059 302.773 264.341 303.181L256.706 307.573C255.988 307.996 254.904 308.039 254.288 307.675Z" fill="#396CAA"/>\n' +
  '<path d="M242.213 300.745L234.52 296.325C233.905 295.974 233.978 295.347 234.696 294.939L242.331 290.548C243.049 290.139 244.118 290.095 244.749 290.445L252.442 294.866C253.057 295.216 252.984 295.843 252.266 296.252L244.631 300.643C243.913 301.051 242.829 301.095 242.213 300.745Z" fill="#396CAA"/>\n' +
  '<path d="M230.125 293.8L213.536 284.274C212.921 283.924 212.994 283.296 213.712 282.888L221.347 278.497C222.065 278.088 223.135 278.044 223.765 278.395L240.353 287.921C240.968 288.271 240.895 288.898 240.177 289.307L232.542 293.698C231.824 294.107 230.74 294.15 230.125 293.8Z" fill="#063F85"/>\n' +
  '<path d="M238.579 285.339L225.127 277.607C224.512 277.257 224.585 276.63 225.303 276.222L232.938 271.83C233.656 271.422 234.725 271.378 235.356 271.728L248.808 279.46C249.423 279.81 249.35 280.438 248.632 280.846L240.997 285.237C240.279 285.66 239.195 285.704 238.579 285.339Z" fill="#063F85"/>\n' +
  '<path d="M266.172 314.517L258.479 310.097C257.864 309.746 257.937 309.119 258.655 308.711L266.29 304.319C267.008 303.911 268.077 303.867 268.708 304.217L276.401 308.638C277.016 308.988 276.943 309.615 276.225 310.024L268.59 314.415C267.872 314.823 266.803 314.867 266.172 314.517Z" fill="#396CAA"/>\n' +
  '<path d="M278.071 321.359L270.363 316.939C269.748 316.589 269.821 315.961 270.539 315.553L278.174 311.162C278.892 310.753 279.961 310.709 280.592 311.059L288.299 315.48C288.915 315.83 288.842 316.457 288.124 316.866L280.489 321.257C279.771 321.665 278.687 321.709 278.071 321.359Z" fill="#063F85"/>\n' +
  '<path d="M289.955 328.186L282.262 323.766C281.647 323.416 281.72 322.789 282.438 322.38L290.073 317.989C290.791 317.58 291.86 317.537 292.491 317.887L300.184 322.307C300.799 322.657 300.726 323.285 300.008 323.693L292.373 328.084C291.655 328.493 290.586 328.537 289.955 328.186Z" fill="#063F85"/>\n' +
  '<path d="M301.854 335.029L294.146 330.608C293.531 330.258 293.604 329.631 294.322 329.222L301.957 324.831C302.675 324.423 303.744 324.379 304.375 324.729L312.082 329.149C312.698 329.5 312.625 330.127 311.907 330.535L304.272 334.926C303.554 335.335 302.47 335.379 301.854 335.029Z" fill="#063F85"/>\n' +
  '<path d="M313.738 341.856L306.045 337.436C305.43 337.085 305.503 336.458 306.221 336.05L313.856 331.658C314.574 331.25 315.643 331.206 316.273 331.556L323.967 335.977C324.582 336.327 324.509 336.954 323.791 337.363L316.156 341.754C315.438 342.162 314.368 342.221 313.738 341.856Z" fill="#063F85"/>\n' +
  '<path d="M325.637 348.698L317.929 344.278C317.314 343.928 317.387 343.3 318.105 342.892L325.74 338.501C326.458 338.092 327.527 338.048 328.158 338.399L335.865 342.819C336.481 343.169 336.408 343.796 335.69 344.205L328.055 348.596C327.337 349.004 326.253 349.048 325.637 348.698Z" fill="#063F85"/>\n' +
  '<path d="M337.536 355.525L329.828 351.105C329.213 350.755 329.286 350.128 330.004 349.719L337.639 345.328C338.357 344.92 339.426 344.876 340.056 345.226L347.764 349.646C348.38 349.996 348.306 350.624 347.588 351.032L339.954 355.423C339.221 355.846 338.151 355.89 337.536 355.525Z" fill="#063F85"/>\n' +
  '<path d="M349.42 362.367L341.712 357.947C341.097 357.596 341.17 356.969 341.888 356.561L349.523 352.169C350.241 351.761 351.31 351.717 351.94 352.067L359.648 356.488C360.264 356.838 360.191 357.465 359.472 357.874L351.838 362.265C351.12 362.673 350.035 362.717 349.42 362.367Z" fill="#063F85"/>\n' +
  '<path d="M361.319 369.195L353.611 364.775C352.996 364.425 353.069 363.797 353.787 363.389L361.422 358.998C362.14 358.589 363.209 358.545 363.839 358.895L371.547 363.316C372.163 363.666 372.089 364.293 371.371 364.702L363.737 369.093C363.004 369.516 361.934 369.56 361.319 369.195Z" fill="#063F85"/>\n' +
  '<path d="M382.45 381.348L365.642 371.69C365.026 371.34 365.1 370.713 365.818 370.304L373.452 365.913C374.17 365.505 375.24 365.461 375.87 365.811L392.678 375.469C393.293 375.819 393.22 376.446 392.502 376.855L384.867 381.246C384.149 381.669 383.065 381.713 382.45 381.348Z" fill="#063F85"/>\n' +
  '<path d="M405.602 368.042L391.418 359.887C390.802 359.537 390.875 358.91 391.593 358.501L399.228 354.11C399.946 353.702 401.016 353.658 401.646 354.008L415.831 362.163C416.446 362.513 416.373 363.14 415.655 363.549L408.02 367.94C407.302 368.349 406.218 368.392 405.602 368.042Z" fill="#063F85"/>\n' +
  '<path d="M287.01 313.175L279.317 308.754C278.702 308.404 278.775 307.777 279.493 307.368L287.128 302.977C287.846 302.569 288.915 302.525 289.545 302.875L297.239 307.295C297.854 307.646 297.781 308.273 297.063 308.681L289.428 313.073C288.696 313.496 287.626 313.539 287.01 313.175Z" fill="#063F85"/>\n' +
  '<path d="M274.818 306.172L267.111 301.751C266.495 301.401 266.568 300.774 267.286 300.365L274.921 295.974C275.639 295.566 276.709 295.522 277.339 295.872L285.047 300.292C285.662 300.642 285.589 301.27 284.871 301.678L277.236 306.069C276.518 306.478 275.434 306.536 274.818 306.172Z" fill="#063F85"/>\n' +
  '<path d="M262.626 299.169L254.918 294.749C254.303 294.399 254.376 293.771 255.094 293.363L262.729 288.972C263.447 288.563 264.517 288.52 265.147 288.87L272.855 293.29C273.47 293.64 273.397 294.267 272.679 294.676L265.044 299.067C264.326 299.476 263.242 299.519 262.626 299.169Z" fill="#396CAA"/>\n' +
  '<path d="M250.434 292.167L242.741 287.747C242.125 287.396 242.199 286.769 242.917 286.361L250.551 281.969C251.269 281.561 252.339 281.517 252.969 281.867L260.662 286.288C261.278 286.638 261.205 287.265 260.487 287.674L252.852 292.065C252.134 292.473 251.064 292.517 250.434 292.167Z" fill="#063F85"/>\n' +
  '<path d="M298.894 320.017L291.201 315.597C290.586 315.246 290.659 314.619 291.377 314.211L299.012 309.819C299.73 309.411 300.799 309.367 301.429 309.717L309.123 314.138C309.738 314.488 309.665 315.115 308.947 315.524L301.312 319.915C300.594 320.323 299.51 320.367 298.894 320.017Z" fill="#063F85"/>\n' +
  '<path d="M310.793 326.844L303.1 322.424C302.485 322.074 302.558 321.446 303.276 321.038L310.911 316.647C311.629 316.238 312.698 316.195 313.329 316.545L321.022 320.965C321.637 321.315 321.564 321.942 320.846 322.351L313.211 326.742C312.479 327.165 311.409 327.209 310.793 326.844Z" fill="#063F85"/>\n' +
  '<path d="M322.677 333.686L314.984 329.266C314.369 328.916 314.442 328.289 315.16 327.88L322.795 323.489C323.513 323.08 324.582 323.037 325.212 323.387L332.906 327.807C333.521 328.157 333.448 328.785 332.73 329.193L325.095 333.584C324.377 333.993 323.293 334.037 322.677 333.686Z" fill="#063F85"/>\n' +
  '<path d="M334.576 340.514L326.883 336.093C326.268 335.743 326.341 335.116 327.059 334.708L334.694 330.316C335.412 329.908 336.481 329.864 337.111 330.214L344.805 334.635C345.42 334.985 345.347 335.612 344.629 336.02L336.994 340.412C336.276 340.835 335.192 340.878 334.576 340.514Z" fill="#063F85"/>\n' +
  '<path d="M346.46 347.355L338.752 342.935C338.136 342.585 338.21 341.957 338.928 341.549L346.562 337.158C347.28 336.749 348.35 336.705 348.98 337.056L356.688 341.476C357.304 341.826 357.23 342.453 356.512 342.862L348.878 347.253C348.16 347.662 347.075 347.705 346.46 347.355Z" fill="#063F85"/>\n' +
  '<path d="M358.359 354.198L350.666 349.778C350.051 349.428 350.124 348.8 350.842 348.392L358.477 344.001C359.195 343.592 360.264 343.548 360.894 343.899L368.588 348.319C369.203 348.669 369.13 349.296 368.412 349.705L360.777 354.096C360.059 354.504 358.975 354.548 358.359 354.198Z" fill="#063F85"/>\n' +
  '<path d="M370.244 361.026L362.536 356.605C361.92 356.255 361.994 355.628 362.712 355.219L370.346 350.828C371.064 350.42 372.134 350.376 372.764 350.726L380.472 355.146C381.087 355.496 381.014 356.124 380.296 356.532L372.661 360.923C371.943 361.332 370.859 361.376 370.244 361.026Z" fill="#063F85"/>\n' +
  '<path d="M382.142 367.867L374.449 363.447C373.834 363.096 373.907 362.469 374.625 362.061L382.26 357.669C382.978 357.261 384.047 357.217 384.677 357.567L392.371 361.988C392.986 362.338 392.913 362.965 392.195 363.374L384.56 367.765C383.842 368.173 382.758 368.217 382.142 367.867Z" fill="#063F85"/>\n' +
  '<path d="M394.026 374.695L386.318 370.275C385.703 369.925 385.776 369.297 386.494 368.889L394.128 364.498C394.846 364.089 395.916 364.045 396.546 364.395L404.254 368.816C404.87 369.166 404.796 369.793 404.078 370.202L396.444 374.593C395.726 375.001 394.641 375.06 394.026 374.695Z" fill="#063F85"/>\n' +
  '<path d="M280.739 296.267L273.031 291.847C272.415 291.496 272.489 290.869 273.207 290.461L280.841 286.069C281.559 285.661 282.629 285.617 283.259 285.967L290.967 290.388C291.582 290.738 291.509 291.365 290.791 291.774L283.156 296.165C282.438 296.588 281.354 296.632 280.739 296.267Z" fill="#063F85"/>\n' +
  '<path d="M268.912 289.468L261.219 285.048C260.604 284.698 260.677 284.071 261.395 283.662L269.029 279.271C269.747 278.862 270.817 278.819 271.447 279.169L279.14 283.589C279.756 283.939 279.683 284.567 278.965 284.975L271.33 289.366C270.612 289.789 269.528 289.833 268.912 289.468Z" fill="#063F85"/>\n' +
  '<path d="M257.072 282.669L249.364 278.249C248.749 277.899 248.822 277.271 249.54 276.863L257.175 272.472C257.893 272.063 258.963 272.02 259.593 272.37L267.301 276.79C267.916 277.14 267.843 277.767 267.125 278.176L259.49 282.567C258.772 282.976 257.688 283.019 257.072 282.669Z" fill="#063F85"/>\n' +
  '<path d="M245.247 275.871L237.554 271.45C236.939 271.1 237.012 270.473 237.73 270.064L245.364 265.673C246.083 265.265 247.152 265.221 247.782 265.571L255.476 269.992C256.091 270.342 256.018 270.969 255.3 271.377L247.665 275.769C246.947 276.177 245.863 276.221 245.247 275.871Z" fill="#063F85"/>\n' +
  '<path d="M257.41 268.869L249.702 264.449C249.087 264.099 249.16 263.471 249.878 263.063L252.398 261.619C253.116 261.21 254.186 261.166 254.816 261.516L262.509 265.937C263.125 266.287 263.052 266.914 262.334 267.323L259.813 268.767C259.11 269.19 258.025 269.234 257.41 268.869Z" fill="#063F85"/>\n' +
  '<path d="M272.854 277.753L265.161 273.332C264.546 272.982 264.619 272.355 265.337 271.947L267.858 270.502C268.576 270.094 269.645 270.05 270.275 270.4L277.983 274.82C278.599 275.171 278.525 275.798 277.807 276.206L275.287 277.651C274.554 278.059 273.47 278.103 272.854 277.753Z" fill="#063F85"/>\n' +
  '<path d="M284.636 284.522L276.943 280.102C276.328 279.752 276.401 279.125 277.119 278.716L279.639 277.272C280.357 276.863 281.427 276.82 282.057 277.17L289.765 281.59C290.38 281.94 290.307 282.567 289.589 282.976L287.069 284.42C286.336 284.829 285.252 284.872 284.636 284.522Z" fill="#063F85"/>\n' +
  '<path d="M296.417 291.292L288.71 286.871C288.094 286.521 288.167 285.894 288.885 285.486L291.406 284.041C292.124 283.633 293.194 283.589 293.824 283.939L301.532 288.36C302.147 288.71 302.074 289.337 301.356 289.745L298.835 291.19C298.117 291.613 297.033 291.657 296.417 291.292Z" fill="#063F85"/>\n' +
  '<path d="M308.199 298.075L300.491 293.655C299.876 293.305 299.949 292.678 300.667 292.269L303.187 290.825C303.906 290.416 304.975 290.373 305.605 290.723L313.299 295.143C313.914 295.493 313.841 296.121 313.123 296.529L310.602 297.973C309.899 298.382 308.815 298.426 308.199 298.075Z" fill="#063F85"/>\n' +
  '<path d="M373.995 335.889L366.287 331.468C365.672 331.118 365.745 330.491 366.463 330.082L368.983 328.638C369.701 328.23 370.771 328.186 371.401 328.536L379.094 332.956C379.71 333.307 379.637 333.934 378.919 334.342L376.398 335.787C375.68 336.195 374.61 336.239 373.995 335.889Z" fill="#063F85"/>\n' +
  '<path d="M385.776 342.658L378.083 338.238C377.468 337.888 377.541 337.26 378.259 336.852L380.779 335.408C381.497 334.999 382.567 334.955 383.197 335.306L390.905 339.726C391.52 340.076 391.447 340.703 390.729 341.112L388.209 342.556C387.476 342.965 386.392 343.008 385.776 342.658Z" fill="#063F85"/>\n' +
  '<path d="M397.558 349.428L389.865 345.007C389.249 344.657 389.322 344.03 390.04 343.621L392.561 342.177C393.279 341.769 394.349 341.725 394.979 342.075L402.687 346.495C403.302 346.846 403.229 347.473 402.511 347.881L399.99 349.326C399.258 349.734 398.173 349.778 397.558 349.428Z" fill="#063F85"/>\n' +
  '<path d="M409.339 356.196L401.631 351.776C401.016 351.426 401.089 350.799 401.807 350.39L404.328 348.946C405.046 348.537 406.115 348.494 406.745 348.844L414.439 353.264C415.054 353.614 414.981 354.242 414.263 354.65L411.742 356.094C411.039 356.517 409.955 356.561 409.339 356.196Z" fill="#063F85"/>\n' +
  '<path d="M292.637 303.109L284.944 298.689C284.329 298.339 284.402 297.711 285.12 297.303L292.755 292.912C293.473 292.503 294.542 292.459 295.172 292.809L302.866 297.23C303.481 297.58 303.408 298.207 302.69 298.616L295.055 303.007C294.337 303.415 293.253 303.459 292.637 303.109Z" fill="#063F85"/>\n' +
  '<path d="M304.522 309.95L296.814 305.53C296.198 305.18 296.272 304.553 296.99 304.144L304.624 299.753C305.342 299.345 306.412 299.301 307.042 299.651L314.75 304.071C315.365 304.421 315.292 305.049 314.574 305.457L306.939 309.848C306.221 310.257 305.137 310.301 304.522 309.95Z" fill="#063F85"/>\n' +
  '<path d="M316.42 316.778L308.727 312.357C308.112 312.007 308.185 311.38 308.903 310.972L316.537 306.58C317.256 306.172 318.325 306.128 318.955 306.478L326.649 310.899C327.264 311.249 327.191 311.876 326.473 312.285L318.838 316.676C318.12 317.084 317.036 317.128 316.42 316.778Z" fill="#063F85"/>\n' +
  '<path d="M328.305 323.621L320.597 319.2C319.981 318.85 320.054 318.223 320.773 317.815L328.407 313.423C329.125 313.015 330.195 312.971 330.825 313.321L338.533 317.742C339.148 318.092 339.075 318.719 338.357 319.127L330.722 323.519C330.004 323.927 328.92 323.971 328.305 323.621Z" fill="#063F85"/>\n' +
  '<path d="M340.203 330.447L332.51 326.027C331.895 325.677 331.968 325.05 332.686 324.641L340.32 320.25C341.038 319.841 342.108 319.798 342.738 320.148L350.432 324.568C351.047 324.918 350.974 325.546 350.256 325.954L342.621 330.345C341.903 330.754 340.819 330.797 340.203 330.447Z" fill="#063F85"/>\n' +
  '<path d="M352.088 337.29L344.38 332.869C343.764 332.519 343.837 331.892 344.555 331.483L352.19 327.092C352.908 326.684 353.978 326.64 354.608 326.99L362.316 331.41C362.931 331.76 362.858 332.388 362.14 332.796L354.505 337.187C353.787 337.596 352.718 337.64 352.088 337.29Z" fill="#063F85"/>\n' +
  '<path d="M363.987 344.117L356.279 339.697C355.663 339.346 355.736 338.719 356.455 338.311L364.089 333.919C364.807 333.511 365.877 333.467 366.507 333.817L374.215 338.238C374.83 338.588 374.757 339.215 374.039 339.624L366.404 344.015C365.686 344.438 364.602 344.482 363.987 344.117Z" fill="#063F85"/>\n' +
  '<path d="M375.87 350.959L368.163 346.539C367.547 346.189 367.62 345.561 368.338 345.153L375.973 340.762C376.691 340.353 377.761 340.309 378.391 340.66L386.099 345.08C386.714 345.43 386.641 346.057 385.923 346.466L378.288 350.857C377.57 351.265 376.501 351.309 375.87 350.959Z" fill="#063F85"/>\n' +
  '<path d="M387.77 357.787L380.062 353.367C379.447 353.017 379.52 352.389 380.238 351.981L387.873 347.59C388.591 347.181 389.661 347.138 390.291 347.488L397.999 351.908C398.614 352.258 398.541 352.885 397.823 353.294L390.188 357.685C389.47 358.108 388.386 358.152 387.77 357.787Z" fill="#063F85"/>\n' +
  '<path d="M224.644 303.954L201.96 290.912C201.345 290.562 201.418 289.935 202.136 289.526L209.771 285.135C210.489 284.727 211.558 284.683 212.189 285.033L234.872 298.075C235.488 298.425 235.415 299.053 234.697 299.461L227.062 303.852C226.344 304.261 225.26 304.305 224.644 303.954Z" fill="#063F85"/>\n' +
  '<path d="M370.741 387.927L348.057 374.885C347.442 374.535 347.515 373.907 348.233 373.499L355.868 369.108C356.586 368.699 357.655 368.655 358.285 369.006L380.969 382.048C381.585 382.398 381.512 383.025 380.794 383.434L373.159 387.825C372.441 388.233 371.357 388.277 370.741 387.927Z" fill="#063F85"/>\n' +
  '<path d="M323.175 375.687L315.482 371.266C314.866 370.916 314.94 370.289 315.658 369.88L324.743 364.658C325.461 364.249 326.531 364.205 327.161 364.556L334.869 368.976C335.484 369.326 335.411 369.953 334.693 370.362L325.608 375.585C324.875 375.993 323.805 376.037 323.175 375.687Z" fill="#063F85"/>\n' +
  '<path d="M236.821 326.057L224.512 318.981C223.896 318.631 223.97 318.004 224.688 317.595L233.773 312.373C234.491 311.964 235.561 311.92 236.191 312.27L248.5 319.346C249.116 319.696 249.042 320.323 248.324 320.732L239.239 325.955C238.521 326.363 237.437 326.407 236.821 326.057Z" fill="#063F85"/>\n' +
  '<path d="M311.731 369.107L299.422 362.032C298.806 361.682 298.88 361.055 299.598 360.646L308.683 355.423C309.401 355.015 310.471 354.971 311.101 355.321L323.41 362.397C324.025 362.747 323.952 363.374 323.234 363.783L314.149 369.005C313.416 369.414 312.346 369.458 311.731 369.107Z" fill="#063F85"/>\n' +
  '<path d="M253.98 335.918L241.671 328.842C241.055 328.492 241.129 327.865 241.847 327.456L250.932 322.233C251.65 321.825 252.72 321.781 253.35 322.131L265.659 329.207C266.274 329.557 266.201 330.184 265.483 330.593L256.398 335.816C255.68 336.224 254.595 336.268 253.98 335.918Z" fill="#063F85"/>\n' +
  '<path d="M253.424 322.176L306.969 352.958C307.628 353.337 307.628 353.964 306.969 354.344L297.693 359.669C297.034 360.048 295.949 360.048 295.29 359.669L241.745 328.887L253.424 322.176Z" fill="#063F85"/>\n' +
  '<path d="M356.425 388.088L364.998 393.019C365.511 393.311 365.437 393.821 364.851 394.157L361.129 396.287C360.543 396.622 359.664 396.666 359.151 396.374L350.578 391.443C350.065 391.152 350.139 390.641 350.725 390.305L354.447 388.176C355.033 387.825 355.912 387.796 356.425 388.088Z" fill="#063F85"/>\n' +
  '<path d="M344.423 381.186L352.996 386.117C353.509 386.409 353.436 386.92 352.849 387.255L349.127 389.385C348.541 389.721 347.662 389.764 347.149 389.473L338.577 384.542C338.064 384.25 338.137 383.739 338.723 383.404L342.445 381.274C343.031 380.938 343.925 380.894 344.423 381.186Z" fill="#063F85"/>\n' +
  '<path d="M332.539 374.359L341.112 379.29C341.624 379.582 341.551 380.092 340.965 380.428L337.243 382.558C336.657 382.893 335.778 382.937 335.265 382.645L326.692 377.714C326.179 377.423 326.253 376.912 326.839 376.577L330.561 374.447C331.147 374.096 332.026 374.067 332.539 374.359Z" fill="#063F85"/>\n' +
  '<path d="M350.988 377.408L359.56 382.339C360.073 382.631 360 383.141 359.414 383.477L355.692 385.607C355.106 385.942 354.226 385.986 353.713 385.694L345.141 380.764C344.628 380.472 344.701 379.961 345.287 379.626L349.01 377.496C349.596 377.16 350.49 377.116 350.988 377.408Z" fill="#063F85"/>\n' +
  '<path d="M378.586 239.766L376.256 250.032C375.19 254.725 373.859 258.49 372.261 261.327C370.662 264.165 368.829 266.186 366.759 267.393C364.696 268.571 362.446 269.026 360.01 268.757C357.58 268.46 355.013 267.531 352.309 265.97C350.15 264.724 348.211 263.256 346.49 261.567C344.775 259.851 343.322 257.906 342.132 255.734C340.972 253.548 340.094 251.113 339.497 248.43C338.924 245.761 338.679 242.824 338.762 239.617C338.869 236.425 339.348 232.957 340.197 229.213L342.528 218.947C343.593 214.254 344.922 210.503 346.514 207.694C348.13 204.899 349.97 202.912 352.033 201.733C354.097 200.555 356.34 200.128 358.764 200.453C361.194 200.75 363.761 201.679 366.465 203.24C368.648 204.501 370.587 205.968 372.284 207.643C374.005 209.331 375.448 211.255 376.614 213.413C377.811 215.557 378.695 217.964 379.268 220.633C379.847 223.273 380.083 226.19 379.975 229.382C379.899 232.561 379.436 236.022 378.586 239.766ZM366.089 245.868L369.114 232.543C369.647 230.197 369.986 228.086 370.131 226.211C370.307 224.322 370.312 222.634 370.144 221.147C369.977 219.66 369.643 218.346 369.145 217.205C368.653 216.036 368.014 215.02 367.228 214.155C366.442 213.291 365.528 212.558 364.486 211.956C363.196 211.211 361.976 210.839 360.826 210.838C359.682 210.809 358.623 211.209 357.649 212.037C356.676 212.865 355.771 214.192 354.936 216.016C354.132 217.827 353.401 220.185 352.741 223.09L349.716 236.415C349.183 238.762 348.829 240.879 348.653 242.768C348.501 244.671 348.506 246.38 348.667 247.895C348.853 249.425 349.177 250.78 349.638 251.963C350.13 253.132 350.766 254.162 351.546 255.055C352.331 255.919 353.245 256.652 354.287 257.254C355.602 258.013 356.822 258.386 357.948 258.372C359.098 258.373 360.163 257.945 361.143 257.089C362.129 256.205 363.028 254.844 363.838 253.005C364.679 251.152 365.43 248.774 366.089 245.868Z" fill="#3787EB"/>\n' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M330.686 227.371L328.707 236.087L322.27 232.37L319.197 245.905L309.336 240.211L312.408 226.677L289.672 213.55L290.784 206.609L323 179.094L323.186 179.201L330.852 183.627L333.047 184.895L324.248 223.655L330.686 227.371ZM301.251 210.377L318.686 194.945L319.86 193.853L314.387 217.961L301.251 210.377Z" fill="#3787EB"/>\n' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M423.859 281.165L421.88 289.88L415.443 286.164L412.37 299.698L402.509 294.005L405.581 280.47L382.845 267.343L383.957 260.403L416.173 232.887L416.359 232.995L424.025 237.42L426.22 238.688L417.421 277.448L423.859 281.165ZM394.424 264.171L411.859 248.738L413.033 247.646L407.56 271.755L394.424 264.171Z" fill="#3787EB"/>\n' +
  '<path d="M54.2507 432.729L110.682 400.444C115.137 397.906 122.903 398.241 128.018 401.203L243.738 468.048C248.867 471.009 249.409 475.473 244.969 478.012L188.538 510.297C184.083 512.835 176.316 512.5 171.202 509.538L55.4816 442.693C50.3528 439.746 49.7959 435.282 54.2507 432.729Z" fill="#EDF5FF"/>\n' +
  '<path d="M190.794 499.632L248.573 466.575C251.109 465.13 252.369 463.205 252.442 461.162L252.486 451.256L250.625 454.437C249.731 453.401 248.529 452.409 247.006 451.534L130.64 384.324C124.046 380.516 114.067 380.078 108.337 383.361L50.5575 416.419C49.942 416.769 49.4145 417.148 48.9456 417.557L46.7329 414.727L46.6889 424.034C46.601 426.602 48.4473 429.33 52.1254 431.445L168.491 498.655C175.085 502.463 185.064 502.9 190.794 499.632Z" fill="#396CAA"/>\n' +
  '<path d="M50.6017 409.066L108.396 376.008C114.126 372.74 124.105 373.163 130.699 376.971L247.064 444.181C253.659 447.989 254.362 453.736 248.632 457.004L190.853 490.062C185.123 493.33 175.144 492.907 168.55 489.099L52.1843 421.889C45.5755 418.067 44.8721 412.334 50.6017 409.066Z" fill="#C4DEFF"/>\n' +
  '<path d="M248.633 457.004L190.853 490.062C185.124 493.33 175.144 492.906 168.55 489.099L52.1848 421.889C48.9903 420.051 47.1879 417.746 46.8215 415.499C46.3673 418.256 48.1843 421.276 52.1848 423.581L168.55 490.791C175.144 494.599 185.124 495.036 190.853 491.754L248.633 458.696C251.578 457.004 252.809 454.67 252.413 452.262C252.12 454.057 250.875 455.72 248.633 457.004Z" fill="white"/>\n' +
  '<path d="M54.2507 410.394L110.682 378.109C115.137 375.57 122.903 375.906 128.018 378.867L243.738 445.713C248.867 448.674 249.409 453.138 244.969 455.677L188.538 487.961C184.083 490.5 176.316 490.164 171.202 487.203L55.4816 420.358C50.3528 417.396 49.7959 412.932 54.2507 410.394Z" fill="white"/>\n' +
  '<path d="M145.162 422.138L124.266 410.131C123.489 409.679 123.592 408.906 124.485 408.395C125.379 407.885 126.727 407.826 127.504 408.264L148.4 420.27C149.177 420.723 149.074 421.496 148.181 422.006C147.287 422.517 145.939 422.575 145.162 422.138Z" fill="#9CC8FF"/>\n' +
  '<path d="M125.365 418.067L117.906 413.778C117.13 413.326 117.232 412.553 118.126 412.042C119.02 411.531 120.368 411.473 121.145 411.911L128.604 416.2C129.38 416.652 129.278 417.425 128.384 417.936C127.49 418.461 126.142 418.505 125.365 418.067Z" fill="#9CC8FF"/>\n' +
  '<path d="M137.865 425.26L130.406 420.971C129.629 420.519 129.732 419.746 130.626 419.235C131.52 418.724 132.868 418.666 133.645 419.104L141.103 423.393C141.88 423.845 141.777 424.618 140.884 425.129C140.004 425.639 138.642 425.698 137.865 425.26Z" fill="#396CAA"/>\n' +
  '<path d="M163.158 439.79L142.921 428.162C142.145 427.71 142.247 426.937 143.141 426.426C144.035 425.916 145.383 425.857 146.16 426.295L166.396 437.922C167.173 438.374 167.071 439.148 166.177 439.658C165.283 440.183 163.935 440.227 163.158 439.79Z" fill="#9CC8FF"/>\n' +
  '<path d="M137.644 432.438L111.546 417.441C110.77 416.988 110.872 416.215 111.766 415.705C112.66 415.194 114.008 415.136 114.785 415.573L140.883 430.57C141.66 431.023 141.557 431.796 140.663 432.306C139.784 432.832 138.421 432.89 137.644 432.438Z" fill="#9CC8FF"/>\n' +
  '<path d="M169.048 457.807L105.202 421.102C104.425 420.649 104.528 419.876 105.422 419.365C106.316 418.855 107.664 418.797 108.441 419.234L172.287 455.939C173.064 456.391 172.961 457.165 172.067 457.675C171.173 458.186 169.81 458.244 169.048 457.807Z" fill="#396CAA"/>\n' +
  '<path d="M187.673 446.574L150.936 425.449C150.159 424.997 150.262 424.224 151.155 423.713C152.049 423.203 153.398 423.144 154.174 423.582L190.911 444.706C191.688 445.158 191.585 445.932 190.691 446.442C189.797 446.953 188.449 447.011 187.673 446.574Z" fill="#9CC8FF"/>\n' +
  '<path d="M142.671 481.046L137.293 477.953C136.765 477.647 136.326 476.815 136.326 476.115C136.326 475.4 136.765 475.079 137.293 475.385L142.671 478.478C143.198 478.785 143.638 479.616 143.638 480.316C143.638 481.031 143.198 481.352 142.671 481.046Z" fill="#C4DEFF"/>\n' +
  '<path d="M151.126 485.919L145.748 482.826C145.221 482.52 144.781 481.689 144.781 480.988C144.781 480.273 145.221 479.952 145.748 480.259L151.126 483.352C151.654 483.658 152.093 484.49 152.093 485.19C152.093 485.905 151.654 486.226 151.126 485.919Z" fill="#C4DEFF"/>\n' +
  '</g>\n' +
  '<defs>\n' +
  '<clipPath id="clip0_7072_74210">\n' +
  '<rect width="512" height="512" fill="white"/>\n' +
  '</clipPath>\n' +
  '</defs>\n' +
  '</svg>\n';
