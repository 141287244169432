import React from 'react';
import { Line } from '@visx/shape';
import { reverse, sortBy } from 'lodash';
import { getAccessorY, getAccessorZ } from '../helper';
import {
  GraphData,
  HorizontalAccessor,
  NearestVerticalValue,
  SeriesValue,
  VerticalScale,
  XScale,
} from '../types';
import { GlyphCircle } from './glyph-circle';
import Glyphs from './glyphs';

interface FilterGlyphsProps {
  height: number;
  filteredData: GraphData[];
  filterFieldValue: string[] | null;
  xScale: XScale;
  yScale: VerticalScale;
  zScale: VerticalScale;
  yValues: SeriesValue[];
  zValues: SeriesValue[];
  accessorX: HorizontalAccessor;
}

const FilterGlyphs = ({
  height,
  filteredData,
  filterFieldValue,
  xScale,
  yScale,
  zScale,
  yValues,
  zValues,
  accessorX,
}: FilterGlyphsProps) => {

  if (!filterFieldValue) return null;

  const datum = filteredData.find(d => filterFieldValue.includes(d.x));

  if (!datum) return null;

  return (
    <Glyphs
      height={height}
      datum={datum}
      yValues={yValues}
      zValues={zValues}
      xScale={xScale}
      yScale={yScale}
      zScale={zScale}
      accessorX={accessorX}
    />
  );
};

export default FilterGlyphs;
