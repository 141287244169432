import { cloneDeep, find, isEqual, last, slice } from 'lodash';
import clsx from 'clsx';
import { initialMergeAxisFilters, mergeAxisFilters } from '../../utils/functions';
import { PropertyData } from '../../components/widget-page/dropdown-layout/helpers/Property';
import {
  BoundFilterGroupProps,
  FilterForPanel,
  FilterGroupProps,
} from '../../components/dashboard-page/hooks';
import { DashboardProperty, WidgetProperties } from '../../slices/types';
import { BoundType } from '../../components/dashboard-page/settings/enums';
import {
  getOverriddenPropertiesWithoutNumberFilters,
  getParsedAxisValues,
  getSimplifiedType,
} from '../../components/widget-page/dropdown-layout/helpers/helpers';
import { getDatePeriod } from '../../components/dashboard-page/filters/helper';
import { PanelType, WidgetType } from '../../enums/widget-type';
import { isMobilehelper } from '../../utils/mobile-helper';
import {
  getFilterSettingValue,
  isPeriodDateFilter,
} from './filters-helper';
import { DashboardPropertyType, FilterProperty } from '../../enums/dashboard-properties';
import { WidgetSimplifiedDataType } from '../../enums/data-type';
import { DateFiltersSetting } from '../../components/dashboard-page/dashboard-widgets/hooks/useDashboardFiltersUpdate';
import { DateFunctions } from '../../enums/date';

export const getChain = (
  chain: number[],
  blocks: BoundFilterGroupProps[],
): number[] => {
  const widgetId = chain[chain.length - 1];

  const masterBlock = blocks.find((block) =>
    block.participantFilterWidgets.find(
      (widget) => widget.isMaster && widget.widgetId === widgetId,
    ),
  );

  const ddWidgetId = masterBlock?.participantFilterWidgets.find(
    (widget) => !widget.isMaster && widget.isActive,
  )?.widgetId;

  const ddBlock = blocks.find((block) =>
    block.participantFilterWidgets.find(
      (widget) => widget.isMaster && widget.widgetId === ddWidgetId,
    ),
  );

  if (ddWidgetId && ddBlock) {
    return getChain([...chain, ddWidgetId], blocks);
  }

  return chain;
};

// Рекурсия - по-умолчания "от ребенка к родителю",
// флаг revert - инвертирует поиск на "от родителя к ребенку"
export const getRecursiveWidgetIdDDChain = (
  groups: BoundFilterGroupProps[],
  widgetIdCollector: number[],
  widgetId: number,
  revert: boolean = false,
): number[] => {
  const relativeGroup = groups.find((group) => {
    return (
      group.isActive &&
      group.participantFilterWidgets.find(
        (widget) =>
          widget.widgetId === widgetId && widget.isActive && (revert ? widget.isMaster : !widget.isMaster),
      )
    );
  });
  const relativeId =
    relativeGroup &&
    relativeGroup.participantFilterWidgets.find(
      (widget) => widget.isActive && (revert ? !widget.isMaster : widget.isMaster),
    )?.widgetId;
  if (relativeGroup && relativeId) {
    const collector = [...widgetIdCollector, relativeId];
    return getRecursiveWidgetIdDDChain(groups, collector, relativeId, revert);
  }
  return widgetIdCollector;
};

const activateChainDD = (
  groups: BoundFilterGroupProps[],
  widgetId: number,
): BoundFilterGroupProps[] => {
  const activatedGroups = cloneDeep(groups);

  // Получаем цепочку переходов DD, по который мы пришли к widgetId.
  // Убираем первый id, так как это та группа с которой мы начинаем,
  // в ней должен быть уже активен правильный виджет.
  const widgetIds = slice(getRecursiveWidgetIdDDChain(groups, [], widgetId), 1);

  widgetIds.forEach((id) => {
    const groupIndex = groups.findIndex((group) => {
      return (
        group.isActive &&
        group.participantFilterWidgets.find(
          (widget) =>
            widget.widgetId === id && widget.isActive && widget.isMaster,
        )
      );
    });
    if (groupIndex != -1) {
      const participantIndex = groups[
        groupIndex
      ].participantFilterWidgets.findIndex(
        (widget) => widget.widgetId === widgetId,
      );
      // активируем фильтр для виджета открытого в DD на данный момент
      if (participantIndex !== -1) {
        activatedGroups[groupIndex].participantFilterWidgets[
          participantIndex
        ].isActive = true;
      }
    }
  });

  return activatedGroups;
};

export const getFirstMasterForDD = (
  groups: BoundFilterGroupProps[],
  widgetId: number,
): number => {
  return last(getRecursiveWidgetIdDDChain(groups, [], widgetId)) || widgetId;
};

export const convertDashboardPropertiesForDeepDD = (
  dashboardProperties: DashboardProperty[],
  widgetId: number,
) => {
  const newProperties = [...dashboardProperties];

  [BoundType.autoDrillDownGroups].forEach(
    (boundType) => {
      const index = newProperties.findIndex(
        (property) => property.name === boundType,
      );
      if (index !== -1) {
        const drillDownValue: BoundFilterGroupProps[] = JSON.parse(
          newProperties[index].value as string,
        );
        const newDrillDownValue = activateChainDD(drillDownValue, widgetId);
        newProperties[index].value = JSON.stringify(newDrillDownValue);
      }
    },
  );

  return newProperties;
};

export const getFormattedDashboardFilterValue = (filter: FilterGroupProps, index: number = 0) => {
  const defaultValue = 'все';

  switch (getSimplifiedType(filter.type)) {
    case 'BOOLEAN':
    case 'TEXT': {
      return `${filter.value[index]?.value || defaultValue}`;
    }
    case 'NUMBER': {
      let value = defaultValue;
      const from = find(filter.value, { operation: '>=' })?.value;
      const to = find(filter.value, { operation: '<=' })?.value;
      if (from && to) {
        value = `${from}-${to}`;
      } else if (from) {
        value = `с ${from}`;
      } else if (to) {
        value = `по ${to}`;
      }
      return `${value}`;
    }
    case 'DATE': {
      const dateFunction =
        getFilterSettingValue(filter, FilterProperty.overriddenFunction)
        || getFilterSettingValue(filter, FilterProperty.function);
      const value = getDatePeriod(
        filter.value,
        dateFunction ? dateFunction.split("'")[1] : DateFunctions.NO_TYPE,
        isPeriodDateFilter(filter),
      );
      return `${value || defaultValue}`;
    }
    default: {
      return defaultValue;
    }
  }
};

export const isNeedSetDDSetFilterField = (widgetType: WidgetType) => {
  const widgetsWithDrilldownMultipleFilter = [WidgetType.TABLE];

  return !widgetsWithDrilldownMultipleFilter.includes(widgetType);
};

export const getJsonOverriddenFunctionsByDateFilters = (
  dashboardFiltersGroups: FilterGroupProps[],
) => {
  return JSON.stringify(
    dashboardFiltersGroups.map((group) =>
      getFilterSettingValue(group, FilterProperty.overriddenFunction),
    ),
  );
};

export const isEqualPanelFilters = (
  firstFilter: FilterForPanel,
  secondFilter: FilterForPanel,
) => {
  return isEqual(firstFilter.name, secondFilter.name) && firstFilter.name.length > 0;
};

export const getEffectedChangeForDashboardFilters = (
  dashboardFilters: FilterGroupProps[],
) => {
  if (!dashboardFilters) return null;

  const values: DateFiltersSetting[] = dashboardFilters
    .filter(
      (filtersValue) =>
        filtersValue.actual &&
        filtersValue.widgetFilters.find(
          (widget) => widget.actual,
        ) && getSimplifiedType(filtersValue.type) === WidgetSimplifiedDataType.DATE,
    )
    .map((filtersValue) => ({
      filterGroupId: filtersValue.id,
      displayAsPeriod: getFilterSettingValue(
        filtersValue,
        FilterProperty.displayAsPeriod,
      ),
      displayAsSingleDate: getFilterSettingValue(
        filtersValue,
        FilterProperty.displayAsSingleDate,
      ),
      selectDateType: getFilterSettingValue(
        filtersValue,
        FilterProperty.selectDateType,
      ),
    }));

  return values;
};


export const getDashboardFilters = (dashboardParams?: DashboardProperty[]): FilterGroupProps[] | null  => {
  try {
    return JSON.parse(dashboardParams?.find((param) => param.name === DashboardPropertyType.filters)?.value || 'null');
  } catch {
    return null;
  }
};

export const initWidgetFiltersOnDashboard = (
  oldAxisFilters: PropertyData[],
  dashboardFilters: FilterGroupProps[],
  overriddenProperties: WidgetProperties[],
  overriddenPropertiesInherited: WidgetProperties[] | undefined,
  setOverriddenProperties: (newProperties: WidgetProperties[]) => void,
) => {

  const filterValueMerged = overriddenPropertiesInherited ? mergeAxisFilters(
    oldAxisFilters,
    getParsedAxisValues(
      PanelType.axisFilter,
      getOverriddenPropertiesWithoutNumberFilters(overriddenPropertiesInherited) || []),
    {
      dashboardFilterGroups: dashboardFilters,
    },
  ) : initialMergeAxisFilters(oldAxisFilters, dashboardFilters);

  setOverriddenProperties([
    {
      name: PanelType.axisFilter,
      value: JSON.stringify(
        filterValueMerged,
      ),
    },
  ]);
};

export const getDashboardItemClasses = (widgetId: number, currentWidgetIdFullScreen: number | null, isMobile: boolean = false) => {
  const isFullScreenView = currentWidgetIdFullScreen === widgetId;

  return clsx('dashboard-grid-item', {
    'dashboard-mobile-item_container': isMobile,
    'dashboard-grid-item__fullscreen': isFullScreenView,
  });
};
