import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ComplexTableContext } from '../..';
import { PanelType } from '../../../../../../enums/widget-type';
import { setWidgetPropsAction } from '../../../../../../slices/widget/widget';
import {
  getPanelIndex,
  getParsedAxisValues,
} from '../../../../dropdown-layout/helpers/helpers';
import { Property, PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { isDashboardPage } from '../../../helpers';
import { sortBy } from 'lodash';

export interface useColumnSortingParams {
  propertyIndex: number;
}

export enum SortingVariants {
  ASC = 'ASC',
  DESC ='DESC'
}

export const useColumnSorting = ({ propertyIndex }: useColumnSortingParams) => {
  const { setDashboardWidgetState, widgetProperties } = useContext(ComplexTableContext);

  const dispatch = useDispatch();
  const isDashboard = isDashboardPage();

  const getSortingResult = (sorting: SortingVariants | null) => {
    const values = getAllAxisValues();
    const orderPriority = Math.max(...values.map((value: PropertyData) => value.orderPriority || 0)) + 1;

    return values.map((property: any, index: number) => {
      const propertyInstance = new Property(property);

      if (index === propertyIndex) {
        propertyInstance.changeOrderDirection(sorting);
        if (!propertyInstance.data.orderPriority || !sorting) {
          propertyInstance.changeOrderPriority(sorting ? orderPriority : null);
        }
      }
      return propertyInstance.isCalculated()
        ? propertyInstance.getCalculatedValue()
        : propertyInstance.getValue();
    });
  };

  const getPropertiesWithNewSorting = (sorting: SortingVariants | null) => {
    const properties = [...widgetProperties];
  
    const axisIndex: number = getPanelIndex({
      properties,
      type: PanelType.axisX,
    });

    properties[axisIndex] = {
      ...properties[axisIndex],
      value: JSON.stringify(getSortingResult(sorting)),
    };

    return properties;
  };

  const setSortingOnEditor = (sorting: SortingVariants | null) => {
    dispatch(setWidgetPropsAction(getPropertiesWithNewSorting(sorting)));
  };

  const setSortingOnDashboard = (sorting: SortingVariants | null) => {
    if (!setDashboardWidgetState) return;

    setDashboardWidgetState([{
      name: PanelType.axisX,
      value: JSON.stringify(getSortingResult(sorting)),
    }]);
  };
  
  const getAllAxisValues = () => {
    return getParsedAxisValues(PanelType.axisX, widgetProperties);
  };

  const getPropertyData = () => {
    const values = getAllAxisValues();

    return values[propertyIndex] || {};
  };

  const getSorting = (): SortingVariants | null => {
    return getPropertyData().orderDirection;
  };

  const getSortingOrderPriority = (): number | null => {
    const values = getAllAxisValues();
    if (!getPropertyData().orderPriority) return null;
    const sortedValues = sortBy(values, ['orderPriority']);

    return sortedValues.findIndex((value: PropertyData) => value.etlFieldId === getPropertyData().etlFieldId) + 1;
  };

  return {
    setSorting: isDashboard ? setSortingOnDashboard : setSortingOnEditor,
    getSorting,
    getSortingOrderPriority,
  };
};
