import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllGroupsWithMap } from '../../../services';
import { apiGetMapConnection } from '../../../services/loadersController';
import { apiSetProject } from '../../../services/projectsController';
import { setCurrentProjectAction } from '../../../slices/main-page/main-page-slice';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { Group, State, Widget } from '../../../slices/types';
import IconSvg from '../../common/icon-svg/icon-svg';
import { useNotificator } from '../../common/snackbar/hooks';
import { CustomIconButton, IconButtonType } from '../../../uikit/IconButton';
import { CustomProgress } from '../../../uikit/Progress';
import { CustomDropdownList } from '../../../uikit/DropdownList';
import { CustomListItem } from '../../../uikit/ListItem';
import { toggleChangeMapPopupAction } from '../../../slices/map-view/map-view';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const ProjectsWithMapsList = () => {
  const dispatch = useDispatch();

  const currentProject: any = useSelector(
    (state: State) => state.mainPage.currentProject ?? [],
  );

  const isChangeMapPopupShowing = useSelector(
    (state: State) => state.mapView.showChangeMapPopup,
  );

  const widgetsList: Widget[] | [] = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? [],
  );

  const [groups, setGroups] = useState<Group[]>([]);
  const [isGroupsLoading, setGroupLoading] = useState<boolean>(false);
  const [isMapLoading, setIsMapLoading] = useState<boolean>(false);

  useEffect(() => {
    setGroupLoading(true);
    currentProject.initialLoaderId &&
      getAllGroupsWithMap(currentProject.initialLoaderId)
        .then((response) => {
          setGroups(response.data);
          setGroupLoading(false);
        })
        .catch(() => {
          setGroupLoading(false);
          setGroups([]);
        });
  }, [dispatch, currentProject.initialLoaderId]);

  const { showNotification } = useNotificator();

  const setProjectMap = useCallback(
    (newMapLoaderId: any) => {
      setIsMapLoading(true);

      apiGetMapConnection(newMapLoaderId)
        .then((result: any) => {
          const mapData = result;

          const projectCopy: any = { ...currentProject };
          projectCopy.loaderId = newMapLoaderId;
          projectCopy.editableMap = false;

          const mapDataForDeleting = { ...mapData };
          mapDataForDeleting.sourceObjects = [];

          apiSetProject(newMapLoaderId, projectCopy)
            .then(() => {
              dispatch(
                mapConnectionAction.addConnection({
                  loaderId: newMapLoaderId,
                  data: mapDataForDeleting,
                }),
              );

              dispatch(
                mapConnectionAction.addConnection({
                  loaderId: newMapLoaderId,
                  data: mapData,
                }),
              );

              dispatch(setCurrentProjectAction(projectCopy));

              setIsMapLoading(false);
            })
            .catch((error) => {
              const ErrorMessage = error.data.message;
              showNotification({
                message: `Ошибка. ${ErrorMessage}`,
                variant: 'error',
              });
              setIsMapLoading(false);
            });
        })
        .catch(() => setIsMapLoading(false));
    },
    [currentProject, dispatch, showNotification],
  );

  return (
    <div className="map-projects-list">
      {isGroupsLoading && (
        <div className="loader-container">
          <CustomProgress type="circular" />
        </div>
      )}
      {!isGroupsLoading &&
        groups.length > 0 &&
        groups.map((group) => (
          <CustomDropdownList
            title={group.groupName}
            key={group.groupId}
            isOpenInit={true}
          >
            {group.projects?.map((project) => (
              <CustomListItem
                key={group.groupId + project.name}
                title={project.name}
                disabled={isMapLoading}
                onClick={() => {
                  if (widgetsList.length > 0) {
                    dispatch(toggleChangeMapPopupAction(!isChangeMapPopupShowing));
                  } else {
                    setProjectMap(project.loaderId);
                  }
                }}
              >
                <Link to={`/project/${project.id}`} target="_blank">
                  <CustomIconButton
                    iconButtonProps={{
                      children: (
                        <IconSvg svg={IconDictionary.OpenSource} fill="var(--dark-grey)" />
                      ),
                    }}
                    type={IconButtonType.secondary}
                  />
                </Link>
              </CustomListItem>
            ))}
          </CustomDropdownList>
        ))}

      {!isGroupsLoading && groups.length === 0 && (
        <div className="map-projects-list__empty">
          <IconSvg svg={IconDictionary.NoData} fill="var(--dark-grey)" />
          <span className="map-projects-list__empty-text">
            Нет доступных карт
          </span>
        </div>
      )}
    </div>
  );
};

export default ProjectsWithMapsList;
