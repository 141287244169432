import { apiBI } from '../utils/api';

const IMPORT_URL = '/import';

export const apiImportFile = async (groupId: number, file: FormData) => {
  try {
    const { data } = await apiBI.post(`${IMPORT_URL}/${groupId}/`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
};
