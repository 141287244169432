import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useClassButton } from '../../../hooks/useClassButton';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import { isMobilehelper } from '../../../utils/mobile-helper';
import GroupInput from '../group-input/group-input';
import { State } from '../../../slices/types';
import { CustomButton } from '../../../uikit/Button';

type CreateControlsProps = {
  handleAddNewGroup: (groupId: number) => void;
};

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
    marginLeft: 'calc(20px * var(--scale-coefficient))',
  },
});

const CreateControls: React.FC<CreateControlsProps> = ({
  handleAddNewGroup,
}) => {
  const [openModalGroup, setModalGroup] = useState(false);

  const classes = useStyles();
  const classesDefault = useClassButton({
    backgroundColor: 'none',
    border: '1px solid var(--dark-cyan)',
    fontFamily: 'Manrope',
    boxShadow: 'none',
    color: 'var(--title-color)',
    whiteSpace: 'nowrap',
  });
  const userInfo = useSelector(
    (state: State) => state.mainPage.userInfo,
  );

  const handleOpenModalGroup = () => {
    setModalGroup(true);
  };
  const handleCloseModalGroup = () => {
    setModalGroup(false);
  };

  const handleCreateGroup = (newGroupId: number) => {
    handleAddNewGroup(newGroupId);
  };

  return userInfo?.isAdmin && !isMobilehelper() ? (
    <div className={classes.container}>
      <CustomButton
        onClick={handleOpenModalGroup}
        variant="outlined"
      >
        Создать группу
      </CustomButton>

      <CustomDialog
        isOpen={openModalGroup}
        onClose={handleCloseModalGroup}
        title="Создание новой группы"
        maxWidth="xs"
      >
        <GroupInput
          handleCloseForm={handleCloseModalGroup}
          handleCreateNewGroup={handleCreateGroup}
          modalPurpose="create"
        />
      </CustomDialog>
    </div>)
    : null;
};

export default CreateControls;
