import React, { useEffect, useState } from 'react';
import './styles.css';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { State } from '../../../../slices/types';
import { getBooleanPropertyValue, getInputPropertyValue } from '../helpers';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { CustomProgress } from '../../../../uikit/Progress';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export type HtmlProps = {
  widgetProps?: any;
};

export default function HtmlChart({ widgetProps }: HtmlProps) {
  const widgetStoreProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const widgetProperties = widgetProps?.properties || widgetStoreProperties;

  const defaultIframeWidth = '100%';
  const defaultIframeHeight = '100%';

  const [isLoading, setIsLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  const axisLink: string = getInputPropertyValue(
    'axisA',
    widgetProperties,
  );

  const isNeedToShowScroll: boolean = getBooleanPropertyValue(
    'scrollThePage',
    widgetProperties,
  );

  const getHtmlLink = (link: string): string => {
    const linkRegExp = /^http/;
    return linkRegExp.test(link) ? link : `//${link}`;
  };

  useEffect(() => {
    if (axisLink) {
      setIsLoading(true);
    }
  }, [
    axisLink
  ]);

  const onLoad = () => {
    setIsLoading(false);

    // разобраться, как можно обработать ошибку загрузки сайта и почему contentWindow.length = 0 при успешной загрузке
    setIsAvailable(true);
  };

  return (
    <div className="html">
      {!isAvailable
        ? isLoading
          ? (
            <div className="grid-item__container-progress">
              <CustomProgress type="linear" />
            </div>
          ) : (
            <div className="grid-item__container-error">
              <IconSvg svg={IconDictionary.NoData} width={48} height={44} fill="var(--grey)" />
              <p className="grid-item__container-error-title">Сайт недоступен</p>
            </div>
          )
        : null}
      <iframe
        className="html__iframe"
        style={{ 'display': isAvailable ? 'block' : 'none' }}
        width={defaultIframeWidth}
        height={defaultIframeHeight}
        src={getHtmlLink(axisLink)}
        id="frame"
        onLoad={onLoad}
      />
    </div>
  );
}
