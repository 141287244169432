import './dd-filters-panel.css';
import React from 'react';
import { DdFilterPanelItem } from './dd-filter-panel-item';
import { FilterForPanel } from '../../../hooks';

interface DdFiltersPanelProps {
  filters: FilterForPanel[];
}

export const DdFiltersPanel = ({ filters }: DdFiltersPanelProps) => {

  return (
    <div className='dd-filters-panel screenshot-overflow'>
      <div className='dd-filters-panel__container'>
        {filters.map((filter) => (
          <DdFilterPanelItem key={filter.name} filter={filter} />
        ))}
      </div>
    </div>
  );
};
