import { AxisLeft as AxisLeftVisx } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import React from 'react';
import { axisColor, axisLeftTickLabelProps, guideColor } from '../constants';
import { Scales } from '../hooks/useScales';
import { BubbleSets, Dimensions, FormattingValues } from '../types';

interface AxisVerticalProps {
  dimensions: Dimensions;
  bubbleSets: BubbleSets;

  formattingValues: FormattingValues;
  scales: Scales;
}

export const AxisLeft = ({
  dimensions,
  bubbleSets,
  formattingValues,
  scales,
}: AxisVerticalProps) => {
  const { width, chartWidth, chartHeight, chartTopOffset } = dimensions;

  const { axisYWidth, isNeedToDisplayAxesGuide } = bubbleSets;

  const { formatAxisYValue } = formattingValues;

  const { verticalScale } = scales;

  return (
    <div
      className="widget-bubble-chart__axis-vertical"
      style={{
        width: axisYWidth,
        height: chartHeight,
      }}
    >
      <svg width={width} height={chartHeight}>
        <Group top={chartTopOffset}>
          {isNeedToDisplayAxesGuide && (
            <GridRows
              scale={verticalScale}
              width={chartWidth}
              height={chartHeight}
              left={axisYWidth}
              stroke={guideColor}
            />
          )}
          <AxisLeftVisx
            hideTicks
            hideAxisLine
            left={axisYWidth}
            scale={verticalScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickFormat={(value) => {
              return formatAxisYValue(value as number);
            }}
            tickLabelProps={() => axisLeftTickLabelProps}
          />
        </Group>
      </svg>
    </div>
  );
};
