import React from 'react';
import { getDatePeriod } from '../../../../dashboard-page/filters/helper';

interface RangeBlockProps {
  rangeDateValues?: {
    displayAvailablePeriod: boolean;
    minValue: string | null;
    maxValue: string | null;
  };
  filterType: string;
}

export const RangeBlock = ({
  rangeDateValues,
  filterType,
}: RangeBlockProps) => {
  if (!rangeDateValues?.displayAvailablePeriod ||
    !rangeDateValues?.minValue ||
    !rangeDateValues?.maxValue) return (<></>);

  const values = [
    {
      value: rangeDateValues.minValue,
      operation: '>='
    },
    {
      value: rangeDateValues.maxValue,
      operation: '<='
    },
  ];

  return (
    <div className="filter-available-period">
      <div className="filter-available-period__title">
        Доступный период:
      </div>
      <div className="filter-available-period__value">
        {getDatePeriod(values, filterType, true)}
      </div>
    </div>
  );
};
