export const styles: any = {
  addButtonText: {
    color: 'rgba(24, 54, 100, 1)',
    textTransform: 'initial',
  },
  container: {
    minWidth: 'calc(330px * var(--scale-coefficient))',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  headsContainer: {
    width: '100%',
  }
};
