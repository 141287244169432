import { Button, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { numDecline } from '../../../../utils/functions';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import { State, Widget } from '../../../../slices/types';
import WidgetCarouselItem from './widget-carousel-item';
import { widgetIconNames } from '../../../dashboard-page/widgets-list';
import {
  carouselItemFontSize,
  changeSort,
  compareForSort,
  getShowingTooltipState,
  getSortIcon,
  SortingType,
} from '../helper';
import { PageParams } from '../../../../types/meta';
import { CustomIconButton, IconButtonType } from '../../../../uikit/IconButton';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { WidgetDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';

interface WidgetMenuProps {
  classes: any;
  handleWidgetsMenuClose: () => void;
  widgetAnchor: Element | null;
  widgetRef: React.MutableRefObject<HTMLDivElement>;
  currentGroupWidgets: Widget[] | null;
  changeModal: (type: (string | undefined), actionType: string, name: string, id: (number | undefined)) => void;
  carouselItemMaxWidth: number;
}

export const WidgetMenu = ({
  classes,
  handleWidgetsMenuClose,
  widgetAnchor,
  widgetRef,
  currentGroupWidgets,
  changeModal,
  carouselItemMaxWidth,
}: WidgetMenuProps) => {
  const params: PageParams = useParams();

  const widgetsList: Widget[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? null,
  );

  const [widgetSort, setWidgetSort] = useState<SortingType>('noSort');
  const [widgetNameFilter, setWidgetNameFilter] = useState('');

  const filteredWidgetsList =
    widgetsList
      ?.filter(
        (widget: Widget) =>
          !widgetNameFilter ||
          widget.name.toLowerCase().indexOf(widgetNameFilter) !== -1,
      )
      .sort(compareForSort(widgetSort)) || [];

  const filteredGroupWidgetsList =
    currentGroupWidgets
      ?.filter(
        (widget: Widget) =>
          !widgetNameFilter ||
          widget.name.toLowerCase().indexOf(widgetNameFilter) !== -1,
      )
      .sort(compareForSort(widgetSort)) || [];

  const widgetSortIcon = getSortIcon(widgetSort);

  const onWidgetSortChange = () => changeSort(widgetSort, setWidgetSort);

  const onWidgetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWidgetNameFilter(event.target.value.toLowerCase());
  };

  return (
    <CustomPopover
      id="widget"
      open={true}
      onClose={handleWidgetsMenuClose}
      anchorEl={widgetAnchor}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div
        className="widget-footer__popper"
        style={{ width: `${widgetRef.current.clientWidth}px` }}
      >
        <div className="widget-footer__popper-title">
          <h4 className="widget-footer__title">{WidgetDictionary.titleProjectWidgets}</h4>
          <span>
            {`${filteredWidgetsList.length} ${numDecline(
              filteredWidgetsList.length,
              WidgetDictionary.one,
              WidgetDictionary.few,
              WidgetDictionary.many,
            )}`}
          </span>
          <CustomIconButton
            iconButtonProps={{
              children: <IconSvg
                svg={widgetSortIcon}
                fill={widgetSort === 'noSort' ? 'var(--dark-grey)' : 'var(--primary-hover-color)'}
              />,
              onClick: onWidgetSortChange,
            }}
            type={IconButtonType.secondary}
          />
        </div>
        <div className="widget-footer__search-container">
          <TextInput
            label={WidgetDictionary.searchWidget}
            fullWidth
            value={widgetNameFilter}
            handleChange={onWidgetNameChange}
            searchIcon
          />
        </div>
        <div className="widget-footer__popper-item-container">
          {filteredWidgetsList.map((widget: Widget) => (
            <div key={widget.id}>
              <div className="widget-footer__popper-item">
                <MenuItem className={classes.customMenuItem}>
                  <WidgetCarouselItem
                    changeModal={changeModal}
                    isActive={params.widgetId === String(widget.id)}
                    type="widget"
                    id={widget.id}
                    name={widget.name}
                    iconName={
                      widgetIconNames[
                        widget.type as keyof typeof widgetIconNames
                        ]
                    }
                    closeWidgetMenu={handleWidgetsMenuClose}
                    disableHoverTooltipListener={getShowingTooltipState(
                      widget.name,
                      carouselItemFontSize,
                      carouselItemMaxWidth,
                    )}
                  />
                </MenuItem>
                <Button className="widget-carousel__tootip-value">
                  {widget.name}
                </Button>
              </div>
            </div>
          ))}
        </div>
        {currentGroupWidgets?.length ? (
          <div className="widget-footer__popper-item-container">
            <div className="widget-footer__popper-title">
              <h4 className="widget-footer__title">{WidgetDictionary.titleGroupWidgets}</h4>
              <span>
                {`${currentGroupWidgets.length} ${numDecline(
                  currentGroupWidgets.length,
                  WidgetDictionary.one,
                  WidgetDictionary.few,
                  WidgetDictionary.many,
                )}`}
              </span>
            </div>
            {filteredGroupWidgetsList.map((widget: Widget) => (
              <div key={widget.id}>
                <div className="widget-footer__popper-item">
                  <MenuItem className={classes.customMenuItem}>
                    <WidgetCarouselItem
                      changeModal={changeModal}
                      isActive={params.id === String(widget.id)}
                      type="widget"
                      id={widget.id}
                      name={widget.name}
                      iconName={
                        widgetIconNames[
                          widget.type as keyof typeof widgetIconNames
                        ]
                      }
                      closeWidgetMenu={handleWidgetsMenuClose}
                      disableHoverTooltipListener={getShowingTooltipState(
                        widget.name,
                        carouselItemFontSize,
                        carouselItemMaxWidth,
                      )}
                    />
                  </MenuItem>
                  <Button className="widget-carousel__tootip-value">
                    {widget.name}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </CustomPopover>
  );
};
