import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { TextProps } from '@visx/text/lib/types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@visx/text';
import { isNaN } from 'formik';
import clsx from 'clsx';
import {
  ScalableSVGText,
  textDirections,
} from '../../../common/scalable-svg-text';
import {
  getBooleanPropertyValue,
  getCorrectWidgetData,
  getHistogramData,
  getPropertyValueFromWidgetData,
  getSelectPropertyValue,
  sortColorPalette,
} from '../helpers';
import { useEventListener } from '../../../../hooks/useEventListener';
import './styles.css';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { setPropForNumberValue } from '../formatting-helpers';
import { useSynchronizeWidgetScrollListener } from '../../../../hooks/useSynchronizeWidgetScrollListener';
import { getTooltipHandler } from '../../../common/widget-tooltip/widget-tooltip';
import { defaultColors } from '../common/color';
import { LinePathRenderer } from '../common/line-path';
import { BarGroupRenderer } from '../common/bar-group';

import {
  bottomAxisSettings,
  circleParams, defaultOffsetValue,
  getWidgetProperties,
  gridLinesXOffset,
  leftAxisSettings,
} from './properties';

import { getMinMaxData, getMinMaxGraphData, getScales, getX, getZ } from './helpers';
import { useFilterSelectors } from './hooks/useFilterSelectors';
import { useDimensions } from './hooks/useDimensions';
import {
  axisBottomOffset,
  barsOffsetForDiagonalText,
  offsetForHorizontalText,
  xScalePadding,
} from '../bar-group/settings';
import { WIDGET_HEIGHT } from '../settings';
import { useWidgetFullScreen } from '../../../../hooks/charts/useWidgetFullScreen';
import { NumberFormat } from '../../../../enums/number-format';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    overflowX: 'auto',
    overflowY: 'hidden',
    minHeight: WIDGET_HEIGHT,
    marginLeft: '15px',
  },
});

export type BarsProps = {
  widgetProps: any;
  setFilterField: SetFilterField;
  filterField: FilterField;
  isActiveFilter: boolean;
  domain?: number[];
};

export default function HistogramGraph({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
  domain,
}: BarsProps) {
  const enableEvents: boolean = isActiveFilter;
  const widgetProperties = widgetProps.properties;
  const widgetData = widgetProps.data;

  const {
    roundingCountString,
    axisXValues,
    legendsZLabels,
    isNeedToDisplayTooltip,
    isScaleByValueState,
    isNeedToDisplayAxesGuide,
    colorsPaletteState,
    isSmoothedLine,
    customAxisYLabelWidth,
    customAxisZLabelWidth,
    customAxisXLabelHeight,
    isNeedToDisplayValuesOnGraph,
    textDirection,
    axisYValues,
    axisZValues,
    offsetValueTotal,
    isSingleScale,
  } = useMemo(() => getWidgetProperties(widgetProperties), [widgetProperties]);

  const classes = useStyles();

  const isFullScreen = useWidgetFullScreen(widgetProps.id);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [barWidth, setBarWidth] = useState(0);
  const [barGroupWidth, setBarGroupWidth] = useState(0);
  const [barLeftShift, setBarLeftShift] = useState(0);

  const containerRef = useRef(document.createElement('div'));
  const viewportRef = useRef(document.createElement('div'));

  const data = axisYValues.length
    ? getHistogramData(getCorrectWidgetData(widgetData))
    : [];
  const dataGraph = axisZValues.length
    ? getCorrectWidgetData(widgetData)
    : [];

  const propertiesFromWidgetData = useMemo(
    () => widgetProps.data.filter((item: any) => item.isPropertyValue ?? item),
    [widgetProps],
  );

  const metaData = useMemo(
    () => getPropertyValueFromWidgetData('metaData', propertiesFromWidgetData),
    [propertiesFromWidgetData],
  );

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const valueFormat = initValueFormatter({ roundingCount });

  const axisYLabelWidth = isNaN(customAxisYLabelWidth)
    ? leftAxisSettings.left
    : customAxisYLabelWidth;
  const axisZLabelWidth = isNaN(customAxisZLabelWidth)
    ? leftAxisSettings.right
    : customAxisZLabelWidth;

  const xMax = width;

  const bottomAxisHeight = customAxisXLabelHeight >= 0 ? customAxisXLabelHeight : (textDirection === textDirections.horizontal
    ? offsetForHorizontalText
    : axisBottomOffset);

  const currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || defaultColors;

  const referenceData = widgetData[1].y.length
    ? widgetData[1].y
    : widgetData[1].z;

  const groupBarsCount: number = referenceData.length;

  const keys: Array<string> = referenceData.map((value: any, index: number) =>
    String(index),
  );

  const havePositiveValues = (getMinMaxData(data, keys)[1] || 0) > 0 || (getMinMaxGraphData(data)[1] || 0) > 0;
  const haveNegativeValues = (getMinMaxData(data, keys)[0] || 0) < 0;

  const offsetValueTotalTop = havePositiveValues ? offsetValueTotal : defaultOffsetValue;
  const offsetValueTotalBottom = haveNegativeValues ? offsetValueTotal : defaultOffsetValue;

  const yMax = height - bottomAxisHeight - offsetValueTotalTop - offsetValueTotalBottom;

  const graphsCount = dataGraph[0]?.z.length || 0;
  const graphsMapper = useMemo(
    () => new Array(graphsCount).fill(''),
    [graphsCount],
  );

  const getAxisBottomLeft = (): number => {
    const axisBottomOffset = 0;
    const diagonalTextOffset = 20;
    const colWidth = barWidth || xScale.bandwidth() / 2;
    return (
      axisBottomOffset +
      (-colWidth / 2) * groupBarsCount -
      gridLinesXOffset * (groupBarsCount - 1) -
      (textDirection === textDirections.diagonal ? diagonalTextOffset : 0)
    );
  };

  const { calculateDimensions, getCustomBarWidth } = useDimensions({
    widgetData,
    widgetProperties,
    groupBarsCount,
    containerRef,
    setWidth,
    setHeight,
  });

  const groupBarWidth =
    (getCustomBarWidth() || barWidth) * groupBarsCount +
    (getCustomBarWidth() || barWidth) * xScalePadding * (groupBarsCount - 1);

  const getAxisBottomTextSetting = (setting: string) => {
    switch (setting) {
      case 'lineCount':
        return textDirection === textDirections.vertical
          ? Math.floor(
            ((getCustomBarWidth() || barWidth) * groupBarsCount) /
                bottomAxisSettings.lineHeight,
          )
          : textDirection === textDirections.diagonal
            ? 1
            : 2;
      case 'width':
        return textDirection === textDirections.vertical
          ? axisBottomOffset
          : textDirection === textDirections.diagonal
            ? Math.sqrt(
              Math.pow(
                (getCustomBarWidth() || barWidth) * groupBarsCount +
                  (getCustomBarWidth() || barWidth) * xScalePadding * 2,
                2,
              ) + Math.pow(axisBottomOffset, 2),
            ) - bottomAxisSettings.lineHeight
            : groupBarWidth;
      case 'height':
        return textDirection === textDirections.vertical ? groupBarWidth : null;
    }
  };

  const { xScale, valuesScale, yScale, zScale, colorScale } = useMemo(
    () =>
      getScales({
        data,
        dataGraph,
        keys,
        isScaleByValueState,
        domain,
        xMax,
        yMax,
        currentColors,
        isSingleScale,
      }),
    [
      currentColors,
      data,
      dataGraph,
      domain,
      isScaleByValueState,
      keys,
      xMax,
      yMax,
      isSingleScale,
    ],
  );

  useEventListener('resize', calculateDimensions);

  useEffect(() => {
    calculateDimensions();
  }, [
    data,
    calculateDimensions,
    isFullScreen,
  ]);

  useSynchronizeWidgetScrollListener(containerRef, widgetProps.id);

  const { handleWidgetMouseMove, handleWidgetMouseLeave } =
    getTooltipHandler(viewportRef);

  const { selectBar, selectPoint, deselect } = useFilterSelectors({
    widgetProps,
    data,
    dataGraph,
    enableEvents,
    setFilterField,
    filterField,
  });

  const getGraphLeftOffset = () => {
    const colWidth = barGroupWidth || xScale.bandwidth();
    return colWidth / 2 + barLeftShift;
  };

  const isNeedToTranslateTick =
    groupBarWidth + barsOffsetForDiagonalText / 2 <
      (getAxisBottomTextSetting('width') || 0) &&
    textDirection === textDirections.diagonal;

  const tickTranslateX = isNeedToTranslateTick ? groupBarWidth / 2 : 0;

  const needShowAxisLeft = axisYValues.length > 0;
  const needShowAxisRight = axisZValues.length > 0 && !isSingleScale;

  return (
    <>
      <div
        onClick={deselect}
        ref={viewportRef}
        className="widget-histogram-graph-viewport screenshot-overflow-y"
      >
        {needShowAxisLeft &&
          <div
            style={{
              width: `${axisYLabelWidth}px`,
              height: 'min-content',
            }}
          >
            <svg
              className="histogram-graph-viewport-svg"
              width={axisYLabelWidth}
              height={height}
            >
              <AxisLeft
                left={axisYLabelWidth}
                hideAxisLine
                hideTicks
                numTicks={7}
                top={offsetValueTotalTop}
                tickFormat={(x) => `${x}`}
                tickComponent={(props: any) => {
                  return (
                    <Group>
                      <ScalableSVGText
                        x={props.x - axisYLabelWidth + gridLinesXOffset}
                        y={props.y - gridLinesXOffset}
                        formattedValue={valueFormat(
                          props.formattedValue,
                          0,
                          setPropForNumberValue(widgetProperties),
                          PanelType.axisY,
                          false,
                          false,
                          scaleByNumber ? formatByNumber : null
                        )}
                        axis={PanelType.axisY}
                        properties={{
                          width: axisYLabelWidth - gridLinesXOffset,
                          svgTextStyles: leftAxisSettings,
                        }}
                        widgetProperties={widgetProperties}
                        showTooltip={false}
                      />
                    </Group>
                  );
                }}
                scale={yScale}
                stroke={leftAxisSettings.color}
                tickStroke={leftAxisSettings.color}
              />
            </svg>
          </div>}
        <div ref={containerRef} className={clsx(classes.container, 'screenshot-overflow-x')}>
          <svg width={width} height={height} className="histogram-graph-svg">
            <Group
              top={offsetValueTotalTop}
              left={
                textDirection === textDirections.diagonal
                  ? barsOffsetForDiagonalText
                  : 0
              }
            >

              { isNeedToDisplayAxesGuide && (needShowAxisLeft || needShowAxisRight) && (
                <GridRows scale={needShowAxisLeft ? yScale : zScale} numTicks={7} width={xMax} height={yMax} stroke="#e0e0e0" />
              )}
              { isNeedToDisplayAxesGuide && (
                <GridColumns
                  scale={xScale}
                  width={xMax}
                  height={yMax}
                  stroke="#e0e0e0"
                />
              )}

              {keys.length > 0 && (
                <BarGroupRenderer
                  data={data}
                  keys={keys}
                  yMax={yMax}
                  getX={getX}
                  xScale={xScale}
                  valuesScale={valuesScale}
                  yScale={yScale}
                  colorScale={colorScale}
                  widgetProperties={widgetProperties}
                  setBarWidth={setBarWidth}
                  setBarGroupWidth={setBarGroupWidth}
                  setBarLeftShift={setBarLeftShift}
                  enableEvents={enableEvents}
                  selectBar={selectBar}
                  filterField={filterField}
                  getCustomBarWidth={getCustomBarWidth}
                  handleWidgetMouseMove={handleWidgetMouseMove}
                  handleWidgetMouseLeave={handleWidgetMouseLeave}
                  metaData={metaData}
                />
              )}
              {graphsMapper.map((value: any, index: number) => {
                const getScaledX = (d: any) =>
                  (xScale(getX(d)) ?? 0) + getGraphLeftOffset();
                const getScaledY = (d: any) => zScale(getZ(d, index)) ?? 0;

                const widgetTooltipGraphProps = {
                  showWidgetTooltip: handleWidgetMouseMove,
                  hideWidgetTooltip: handleWidgetMouseLeave,
                  attrX: axisXValues[0],
                  attrY: legendsZLabels[index],
                  indexLine: index,
                  isNeedToDisplayTooltip,
                  valueFromAxis: PanelType.axisZ,
                };

                return (
                  <LinePathRenderer
                    key={currentColors[index + keys.length]}
                    circleParams={circleParams}
                    color={currentColors[index + keys.length]}
                    getX={getScaledX}
                    getY={getScaledY}
                    data={dataGraph}
                    isSmoothed={isSmoothedLine}
                    widgetTooltipGraphProps={widgetTooltipGraphProps}
                    onSelectPoint={(pointIndex: number) =>
                      selectPoint(index, pointIndex)}
                    widgetProperties={widgetProperties}
                    boundFilterField={filterField}
                    isActiveFilter={isActiveFilter}
                    isNeedToDisplayValuesOnGraph={isNeedToDisplayValuesOnGraph}
                  />
                );
              })}
            </Group>
            <AxisBottom
              key={`${groupBarsCount}-${xScale.bandwidth()}`}
              top={height - bottomAxisHeight}
              left={getAxisBottomLeft()}
              hideAxisLine={true}
              hideTicks={true}
              numTicks={widgetData.length}
              scale={xScale}
              stroke={bottomAxisSettings.color}
              tickStroke={bottomAxisSettings.color}
              tickComponent={({ formattedValue, x, properties }: any) => {

                let positionProps: Partial<TextProps> = {
                  x: x + groupBarWidth / 2,
                  y: 30,
                  textAnchor: 'middle',
                };

                if (textDirection === textDirections.vertical) {
                  positionProps = {
                    x: x + groupBarWidth / 2,
                    y: 20,
                    textAnchor: 'end',
                    verticalAnchor: 'start',
                    angle: 270,
                  };
                }

                if (textDirection === textDirections.diagonal) {
                  positionProps = {
                    x: x + properties.width / 2,
                    y: 20,
                    textAnchor: 'end',
                    verticalAnchor: 'start',
                    angle: 315,
                  };
                }

                return (
                  <Text
                    {...positionProps}
                    style={{
                      fill: bottomAxisSettings.color,
                      fontSize: bottomAxisSettings.fontSize,
                      fontWeight: bottomAxisSettings.fontWeight,
                      fontFamily: bottomAxisSettings.fontFamily,
                    }}
                  >
                    {valueFormat(
                      formattedValue,
                      0,
                      widgetProperties,
                      PanelType.axisX,
                    )}
                  </Text>
                );
              }}
              tickTransform={`translate (${tickTranslateX}, 0)`}
              tickLabelProps={() => {
                return {
                  axis: PanelType.axisX,
                  properties: {
                    lineCount: getAxisBottomTextSetting('lineCount'),
                    minWidthHide: 30,
                    height: getAxisBottomTextSetting('height'),
                    width: getAxisBottomTextSetting('width'),
                    svgTextStyles: bottomAxisSettings,
                    textDirection,
                    tooltipPlacement: 'top',
                    tickTranslateX,
                  },
                  widgetProperties,
                  textAnchor: 'start',
                };
              }}
            />
          </svg>
        </div>
        {needShowAxisRight && // правая ось
          <div
            style={{
              width: `${axisZLabelWidth}px`,
              height: 'min-content',
            }}
          >
            <svg
              className="histogram-graph-viewport-svg"
              width={axisZLabelWidth}
              height={height}
            >
              <AxisLeft
                left={axisZLabelWidth}
                hideAxisLine
                hideTicks
                numTicks={7}
                top={offsetValueTotalTop}
                tickFormat={(x) => `${x}`}
                tickComponent={(props: any) => {
                  return (
                    <Group>
                      <ScalableSVGText
                        x={props.x - axisZLabelWidth + gridLinesXOffset}
                        y={props.y - gridLinesXOffset}
                        formattedValue={valueFormat(
                          props.formattedValue,
                          0,
                          setPropForNumberValue(widgetProperties),
                          PanelType.axisY,
                          false,
                          false,
                          scaleByNumber ? formatByNumber : null
                        )}
                        axis={PanelType.axisY}
                        properties={{
                          width: axisZLabelWidth - gridLinesXOffset,
                          svgTextStyles: leftAxisSettings,
                        }}
                        widgetProperties={widgetProperties}
                        showTooltip={false}
                      />
                    </Group>
                  );
                }}
                scale={zScale}
                stroke={leftAxisSettings.color}
                tickStroke={leftAxisSettings.color}
              />
            </svg>
          </div>}
      </div>
    </>
  );
}
