import React from 'react';
import { TableCell } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { LoaderTableHeadCell } from '../head/helper';
import { LoaderTableBodyCell } from '../body/useLoadersTableRow';
import { CellBody } from './cell-body';

interface LoaderTableCellProps {
  cell: LoaderTableHeadCell | LoaderTableBodyCell;
  sortHandler?: (sort: string) => void;
  cellType?: 'body' | 'head';
  index: number;
}

export const LoaderTableCell = ({ cell, sortHandler, cellType = 'body', index }: LoaderTableCellProps) => {
  return (
    <TableCell
      align={index ? 'right' : 'left'}
      className={clsx('loader-table__cell', {
        'loader-table__cell_head': cellType === 'head',
        'loader-table__cell_first': !index,
      })}
    >
      {(cell as LoaderTableBodyCell).isActiveLink && (
        <Link to={(cell as LoaderTableBodyCell).link as string} className="loader-table__link">
          <CellBody cell={cell} sortHandler={sortHandler} />
        </Link>
      )}
      {!(cell as LoaderTableBodyCell).isActiveLink && (
        <CellBody cell={cell} sortHandler={sortHandler} />
      )}
    </TableCell>
  );
};
