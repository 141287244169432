import React from 'react';
import { Line } from '@visx/shape';
import { reverse, sortBy } from 'lodash';
import { getAccessorY, getAccessorZ } from '../helper';
import {
  GraphData,
  HorizontalAccessor,
  NearestVerticalValue,
  SeriesValue,
  VerticalScale,
  XScale,
} from '../types';
import { GlyphCircle } from './glyph-circle';

interface GlyphsProps {
  leftLineOffset?: number;
  height: number;
  datum: GraphData;
  nearestValue?: NearestVerticalValue;
  xScale: XScale;
  yScale: VerticalScale;
  zScale: VerticalScale;
  yValues: SeriesValue[];
  zValues: SeriesValue[];
  accessorX: HorizontalAccessor;
}

const Glyphs = ({
  leftLineOffset,
  height,
  datum,
  nearestValue,
  xScale,
  yScale,
  zScale,
  yValues,
  zValues,
  accessorX,
}: GlyphsProps) => {
  const leftOffsetData = xScale(accessorX(datum));

  const createSeriesValue = (
    v: SeriesValue,
    index: number,
    axis: 'y' | 'z',
  ) => {
    const scale = axis === 'y' ? yScale : zScale;
    const getAccessor = axis === 'y' ? getAccessorY : getAccessorZ;
    return {
      ...v,
      index,
      axis,
      topOffsetData: scale(getAccessor(index)(datum) as number),
      isOver: nearestValue ? v.key === nearestValue.key : true,
    };
  };

  // сортируем так, чтобы верхние точки на графике были поверх нижних
  const seriesValues = reverse(
    sortBy(
      [
        ...yValues.map((v, index) => createSeriesValue(v, index, 'y')),
        ...zValues.map((v, index) => createSeriesValue(v, index, 'z')),
      ],
      'topOffsetData',
    ),
  );

  // но при этом ближайшую к курсору точку нужно отобразить поверх всех остальных
  const sortedSeriesValues = [
    ...seriesValues.filter((v) => !v.isOver),
    ...seriesValues.filter((v) => v.isOver),
  ];

  return (
    <g>
      {isFinite(Number(leftLineOffset)) && (
        <Line
          from={{ x: leftLineOffset, y: 0 }}
          to={{ x: leftLineOffset, y: height }}
          stroke="gray"
          strokeWidth={2}
          pointerEvents="none"
          strokeDasharray="5,2"
        />
      )}
      {sortedSeriesValues.map((v) => (
        <GlyphCircle
          key={v.key}
          color={v.color}
          isOver={v.isOver}
          leftOffsetData={leftOffsetData}
          topOffsetData={v.topOffsetData}
        />
      ))}
    </g>
  );
};

export default Glyphs;
