export const stageSettings = {
  top: 10,
  left: 20,
  minWidth: 200,
  minHeightStep: 50,
};

export const leftAxisSettings = {
  color: 'var(--grey)',
  left: 15,
  top: 10,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  offsetHeight: 10,
  textAlign: 'center',
};

export const bottomAxisSettings = {
  color: 'var(--grey)',
  left: 34,
  top: 15,
  fontSize: 14,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 14,
  textAlign: 'center',
};

export const barValueStyles = {
  outsideColor: 'var(--black)',
  insideColor: 'var(--white)',
  fontSize: 18,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 21,
  textAlign: 'center',
  wordBreak: 'break-all',
  pointerEvents: 'none'
};

export const gridLinesColor = '#D8D8D8';
export const gridLinesYOffset = 4;
export const minValueWidth = 30;
export const AxisDiagonalTextOffset = 90;
export const bottomAxisDefaultHeight = 60;
export const bottomAxisValueOffset = 10;
export const defaultLeftDataWidth = 100;
export const defaultMobileLeftDataWidth = 60;
export const insideValueBarOffset = 5;
