import { styled } from '@material-ui/core';
import React, { useMemo } from 'react';
import { CssVariables } from '../../../../enums/css-variables';
import { getScaleCoefficient, varcss } from '../../../../helpers/ui-helper';
import CustomTooltip from '../../../../uikit/CustomTooltip';
import { CustomButton } from '../../../../uikit/Button';
import { CustomProgress } from '../../../../uikit/Progress';
import IconSvg from '../../icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { TextInput } from '../../special-inputs/inputs/text-input';
import { CustomIconButton, IconButtonType } from '../../../../uikit/IconButton';
import {
  CustomPanelHead,
  CustomPanelHeadType,
} from '../../../../uikit/PanelHead';

interface ShareButtonViewProps {
  sharedButton: React.MutableRefObject<HTMLDivElement | null>;
  idSharedPopover: string;
  isLoading: boolean;
  shareDashboard: () => void;
  isOpenSharedPopover: boolean;
  handleCloseSharedPopover: () => void;
  sharedUrl: string | null;
  handleCopySharedUrl: () => void;
  handleClickDeleteShared: () => void;
  isSharedDashboard: boolean;
  handleClick: () => void;
}

const PopoverBody = styled('div')({
  padding: 'var(--modal-left-right-padding_medium)',
  width: 'calc(382px*var(--scale-coefficient))',

  '& > div': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 'calc(20px*var(--scale-coefficient))',

    '& > *:not(:last-child)': {
      marginRight: 'calc(6px*var(--scale-coefficient))',
    },
  },
});

const Button = ({
  variant,
  isLoading = false,
  handleClick,
  children,
}: {
  variant: 'contained' | 'outlined';
  isLoading?: boolean;
  handleClick: () => void;
  children: string;
}) => (
  <CustomButton
    variant={variant}
    onClick={handleClick}
    startIcon={
      isLoading ? (
        <CustomProgress
          type="circular"
          style={{
            color:
              variant === 'outlined' ? 'var(--primary-color)' : 'var(--white)',
          }}
          size={20}
        />
      ) : null
    }
    disabled={isLoading}
    fullWidth
  >
    {children}
  </CustomButton>
);

export const ShareButtonView = ({
  sharedButton,
  idSharedPopover,
  isLoading,
  shareDashboard,
  isOpenSharedPopover,
  handleCloseSharedPopover,
  sharedUrl,
  handleCopySharedUrl,
  handleClickDeleteShared,
  isSharedDashboard,
  handleClick,
}: ShareButtonViewProps) => {
  return (
    <>
      <CustomTooltip title="Поделиться" autoHide>
        <div ref={sharedButton}>
          <CustomButton
            variant={isSharedDashboard ? 'contained' : 'outlined'}
            aria-describedby={idSharedPopover}
            disabled={isLoading}
            icon={
              <IconSvg
                svg={IconDictionary.Planet}
                fill={isSharedDashboard ? 'var(--white)' : 'var(--dark-grey)'}
              />
            }
            onClick={handleClick}
            backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
          />
        </div>
      </CustomTooltip>

      <CustomPopover
        id={idSharedPopover}
        anchorEl={sharedButton.current}
        keepMounted
        open={isOpenSharedPopover}
        onClose={handleCloseSharedPopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        backgroundColor={varcss(CssVariables.HEADER_BACKDROP_COLOR)}
      >
        <div>
          <CustomPanelHead
            onClose={handleCloseSharedPopover}
            type={CustomPanelHeadType.popup}
            title="Настройка публичности"
          />
          <PopoverBody>
            {sharedUrl ? (
              <div>
                <TextInput
                  value={sharedUrl}
                  fullWidth
                  disabled={!sharedUrl?.length}
                />
                <CustomTooltip
                  title="Скопировать"
                  disableHoverListener={!isSharedDashboard}
                >
                  <div>
                    <CustomIconButton
                      iconButtonProps={{
                        children: (
                          <IconSvg
                            svg={IconDictionary.Copy}
                            fill="var(--dark-grey)"
                          />
                        ),
                        onClick: handleCopySharedUrl,
                        disabled: !isSharedDashboard,
                      }}
                      type={IconButtonType.secondary}
                    />
                  </div>
                </CustomTooltip>
              </div>
            ) : isSharedDashboard ? (
              <div style={{ marginBottom: 12 * getScaleCoefficient() }}>
                <Button
                  variant="contained"
                  handleClick={shareDashboard}
                  isLoading={isLoading}
                >
                  Получить новую ссылку
                </Button>
              </div>
            ) : null}
            <Button
              variant={!isSharedDashboard ? 'contained' : 'outlined'}
              handleClick={
                isSharedDashboard ? handleClickDeleteShared : shareDashboard
              }
              isLoading={isLoading}
            >
              {isSharedDashboard ? 'Отменить публикацию' : 'Опубликовать'}
            </Button>
          </PopoverBody>
        </div>
      </CustomPopover>
    </>
  );
};
