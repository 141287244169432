import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiGetProject } from '../services/projectsController';
import { setCurrentProjectAction } from '../slices/main-page/main-page-slice';
import { mapConnectionAction } from '../slices/map-connection/map-connection';
import { Project } from '../slices/types';
import { CommonDictionary } from '../dictionaries/naming-dictionary/naming-dictionary';

interface UseCurrentProjectSetter {
  projectId: number | null;
}

export const useCurrentProjectSetter = ({
  projectId,
}: UseCurrentProjectSetter) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!projectId) return;

    apiGetProject(projectId)
      .then((project: Project) => {
        dispatch(setCurrentProjectAction(project));
      })
      .catch((err) => {
        console.error(`Ошибка при получении ${CommonDictionary.projectGenitive}`, err.response);
      });
  }, [dispatch, projectId]);
};
