import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PanelType } from '../../../../../../enums/widget-type';
import { getScaleCoefficient } from '../../../../../../helpers/ui-helper';
import { setWidgetPropAction } from '../../../../../../slices/widget/widget';
import { getParsedAxisValues } from '../../../../dropdown-layout/helpers/helpers';
import { getJSONPropertyValue } from '../../../helpers';
import { nest } from '../utils/data';
import { Header } from './useTableData';
import { ComplexTableContext } from '../../index';
import { getTableHeader } from '../../helper';

const generateColumn = (head: Header) => {
  return {
    Header: () => <span>{head.title}</span>,
    id: head.id,
    accessor: head.accessor,
    minWidth: 50 * getScaleCoefficient(),
    maxWidth: 850 * getScaleCoefficient(),
    parentID: head.parentID,
  };
};

const generateHeader = (head: Header) => {
  return {
    Header: () => <div>{ head.title }</div>,
    id: head.id,
    minWidth: 200,
    maxWidth: 750,
    parentID: head.parentID,
    style: head.styles || {}
  };
};

export const useColumnsGenerator = (axisX: any, hiddenColumns: string[]) => {
  const { widgetProperties, tableNestedHeaders } = useContext(ComplexTableContext);
  const dispatch = useDispatch();

  const values = useMemo(() =>  getParsedAxisValues(PanelType.axisX, widgetProperties), [widgetProperties]);

  const tableHeader = useMemo(() => getTableHeader(values), [values]);

  const generateNestedHeader = useCallback(() => {
    const complexHeaders = tableNestedHeaders.filter((header) => !header.accessor).map((head: Header) => {
      return head.accessor ? generateColumn(head) : generateHeader(head);
    });
    const headers = tableNestedHeaders.filter((header) => header.accessor).map((head: Header) => {
      return head.accessor ? generateColumn(head) : generateHeader(head);
    });

    const sortedHeaders: any[] = [];

    headers.forEach((header) => {
      const index = axisX.findIndex((value: any) => value.clientUID === header.id);
      sortedHeaders[index] = header;
    });

    const preparedHeader = [...complexHeaders, ...sortedHeaders];

    // tree
    return nest(preparedHeader);
  }, [axisX, tableNestedHeaders]);

  const generateCommonHeader = useCallback(() => {
    const freeHeadsStructure: any = tableHeader.filter((head) => {
      return tableNestedHeaders.findIndex(lHead => `${lHead.accessor}` === `${head.accessor}`) === -1;
    });

    // freeHeads
    return freeHeadsStructure.map((header: Header) => {
      return generateColumn(header);
    });
  }, [tableHeader, tableNestedHeaders]);

  const generateColumns = useCallback(() => {
    const commonHeader = generateCommonHeader();
    const nestedHeads = generateNestedHeader();

    return [...commonHeader, ...nestedHeads];
  }, [generateCommonHeader, generateNestedHeader]);

  const columns = useMemo(() => generateColumns(), [generateColumns]);

  const tableHeaderJSON = JSON.stringify(tableHeader);

  useEffect(() => {
    dispatch(setWidgetPropAction({
      name: 'tableHeader',
      value: tableHeaderJSON,
    }));
  }, [dispatch, tableHeaderJSON]);

  const tableState = useMemo(
    () => {
      const currentState = getJSONPropertyValue('tableState', widgetProperties);
      tableHeader.forEach((header) => {
        if (parseInt(header.width)) {
          currentState.columnResizing.columnWidths[header.accessor] = header.width;
          currentState.hiddenColumns = hiddenColumns;
        }
      });
      return currentState;
    },
    [tableHeader, widgetProperties, hiddenColumns]);

  return {
    columns,
    tableHeader,
    tableState,
  };
};
