import React from 'react';
import './callout-line-label.css';

interface CalloutLineLabelProps {
  text: string;
  count?: number | null;
  percent?: string | null;
  valueLabel?: string;
  zValueLabel?: string | null;
  zCount?: number | null;
  zPercent?: string | null;
  align: 'start' | 'end';
  width: number;
  displayPercentOnLegend?: boolean;
  displayValueOnLegend?: boolean;
  displayTextOnLegend?: boolean;
}

export function CalloutLineLabel({
  text,
  count = null,
  percent = null,
  valueLabel,
  zValueLabel = null,
  zCount,
  zPercent,
  align = 'start',
  width,
  displayPercentOnLegend,
  displayValueOnLegend,
  displayTextOnLegend,
}: CalloutLineLabelProps) {
  const labelValue = () => {
    if (!zValueLabel) {
      if (displayValueOnLegend && displayPercentOnLegend) return `${count} (${percent})`;
      if (displayValueOnLegend && !displayPercentOnLegend) return count;
      if (displayPercentOnLegend && !displayValueOnLegend) return percent;
    } else {
      if (displayValueOnLegend && displayPercentOnLegend) {
        return `${displayTextOnLegend ? valueLabel : ''} ${count} (${percent}) / 
        ${displayTextOnLegend ? zValueLabel: ''} ${zCount} (${zPercent})`;
      };

      if (displayValueOnLegend && !displayPercentOnLegend) {
        return `${displayTextOnLegend ? valueLabel : ''} ${count} / 
        ${displayTextOnLegend ? zValueLabel: ''} ${zCount}`;
      };

      if (displayPercentOnLegend && !displayValueOnLegend) {
        return `${displayTextOnLegend ? valueLabel : ''} ${percent} / 
        ${displayTextOnLegend ? zValueLabel: ''} ${zPercent}`;
      };
    }
    return '';
  };

  return (
    <div className="callout-line-label" style={{ textAlign: align, width: `${width  }px` }}>
      <p className="callout-line-label__text">{text}</p>
      {(count || percent) && (
        <p className="callout-line-label__value">{labelValue()}</p>
      )}
    </div>
  );
}
