import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { WidgetsTypesContext } from '../../../pages/widget-page/widget-page';
import { TextInput } from '../../common/special-inputs/inputs/text-input';
import Sidebar from '../../common/sidebar';
import SettingsItem from './settings-item';
import WidgetsTypes, { GroupTypes } from '../../../types/widgets';
import './settings.css';
import SettingsModal from './settings-modal';
import {
  setWidgetNameAction,
  resetWidgetValidationErrorAction
} from '../../../slices/widget/widget';
import { State } from '../../../slices/types';
import { useDebouncedInput } from '../../../hooks/useDebouncedInput';
import { PushDownCheckbox } from '../sidebar/pushdown-checkbox/pushdown-checkbox';
import { CustomFormControl } from '../../../uikit/FormControl';
import { CustomCheckbox } from '../../../uikit/Checkbox';
import { FormulasInputContainer } from '../../common/special-inputs/formulas-input-container/formulas-input-container';
import { WidgetPropertyType } from '../../../enums/widget-type';
import { WidgetDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomProgress } from '../../../uikit/Progress';

type WidgetSettingsProps = {
  activeWidgetType: string;
};

enum headPropertiesNames {
  title = 'title',
  description = 'description',
}

const WidgetSettings: React.FC<WidgetSettingsProps> = ({
  activeWidgetType,
}) => {
  const dispatch = useDispatch();

  const types = useContext(WidgetsTypesContext);

  const [isModalOpen, setModalOpen] = useState(false);
  const [activePropertiesGroup, setActivePropertiesGroup] = useState('');
  const currentWidget = useSelector((state: State) => state.widget);

  const widgetName = currentWidget?.name;
  const [name, handleWidgetSystemNameChange] = useDebouncedInput(
    widgetName,
    (value) => {
      widgetErrors?.name && name.length && dispatch(resetWidgetValidationErrorAction({ key: 'name' }));
      dispatch(setWidgetNameAction(value));
    },
  );

  const widgetErrors = useSelector(
    (state: State) => state.widget.validationErrors,
  );

  const currentWidgetType = useMemo<WidgetsTypes.Type | undefined>(
    () => types.find((widgetType) => widgetType.type === activeWidgetType),
    [types, activeWidgetType],
  );

  const headerGroups = useMemo<
  WidgetsTypes.WidgetPropertiesGroup[] | undefined
  >(
    () =>
      currentWidgetType?.propertiesGroups?.filter(
        (propertiesGroups) => propertiesGroups.type === GroupTypes.head,
      ),
    [currentWidgetType, GroupTypes],
  );

  const additionalGroups = useMemo<
  WidgetsTypes.WidgetPropertiesGroup[] | undefined
  >(
    () =>
      currentWidgetType?.propertiesGroups?.filter(
        (propertiesGroup) => propertiesGroup.type === GroupTypes.additional,
      ),
    [currentWidgetType, GroupTypes],
  );

  const currentAdditionalGroup = useMemo<
  WidgetsTypes.WidgetPropertiesGroup | undefined
  >(
    () =>
      additionalGroups?.find(
        (propertiesGroup) => propertiesGroup.name === activePropertiesGroup,
      ),
    [additionalGroups, activePropertiesGroup],
  );

  const headerProperties =
    !!headerGroups?.length &&
    headerGroups[0]?.propertiesMetaBlocks[0]?.metaProperties;

  const onSettingsItemClickHandle = useCallback(
    (groupName: string) => {
      setActivePropertiesGroup(groupName);
      setModalOpen(true);
    },
    [isModalOpen],
  );

  const closeModalHandle = useCallback(() => {
    setModalOpen(false);
    setActivePropertiesGroup('');
  }, []);

  useLayoutEffect(() => {
    closeModalHandle();
  }, [activeWidgetType, closeModalHandle]);

  return (
    <Sidebar isRight={true}>
      {!currentWidgetType && <CustomProgress type="circular" size={20} />}
      {!!headerGroups?.length && (
        <div>
          <div className="widget-name">
            <h3 className="settings-title">Имя {WidgetDictionary.few}</h3>
            <TextInput
              InputProps={{ maxlength: 255 }}
              value={name}
              label={`Введите имя ${WidgetDictionary.few}`}
              handleChange={(event) => {
                const { value } = event.target;
                handleWidgetSystemNameChange(value);
              }}
              className="setting-field"
              error={Boolean(widgetErrors?.name)}
            />
          </div>
          <h3 className="settings-title">Заголовок</h3>
          {headerProperties &&
            headerProperties.map((headerGroupWidgetMetaProperty) => (
              <div key={headerGroupWidgetMetaProperty.name}>
                {headerGroupWidgetMetaProperty.name ===
                  headPropertiesNames.title && (
                    <FormulasInputContainer
                      textInputProps={{
                        label: `Введите заголовок ${WidgetDictionary.few}`,
                        fullWidth: true,
                        rows: 2,
                        maxRows: 2
                      }}
                      propertyName={WidgetPropertyType.title}
                      className="setting-field"
                    />
                )}

                {headerGroupWidgetMetaProperty.name ===
                  headPropertiesNames.description && (
                    <FormulasInputContainer
                      textInputProps={{
                        label: 'Описание',
                        fullWidth: true,
                        rows: 4,
                        maxRows: 4
                      }}
                      propertyName={WidgetPropertyType.description}
                      className="setting-field"
                    />
                )}
              </div>
            ))}
        </div>
      )}
      {currentWidgetType && (
        <>
          <h3 className="settings-title">Метод расчета</h3>
          <PushDownCheckbox />
        </>
      )}
      {additionalGroups?.length ? (
        <h3 className="settings-title">Настройки отображения</h3>
      ) : null}

      <div className="view-type">
        {additionalGroups?.map((propertiesItem) => (
          <div
            key={`widget-setting-${propertiesItem.name}`}
            className="view-type__item"
            onClick={() => {
              onSettingsItemClickHandle(propertiesItem.name);
            }}
          >
            <SettingsItem
              isActive={propertiesItem.name === activePropertiesGroup}
              icon={propertiesItem.icon}
              name={propertiesItem.description}
            />
          </div>
        ))}
      </div>
      <SettingsModal
        isOpen={isModalOpen}
        onCloseButtonHandle={closeModalHandle}
        propertiesGroup={currentAdditionalGroup}
      />
    </Sidebar>
  );
};

export default WidgetSettings;
