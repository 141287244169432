import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: 'calc(16px * var(--scale-coefficient))',
      },
      padding: 'calc(20px * var(--scale-coefficient))',
    },
    buttonCreate: {
      marginRight: '10px',
      minWidth: '136px',
    },
    circularProgress: {
      color: 'var(--white)',
    },
    checkbox: {
      marginRight: 'calc(40px * var(--scale-coefficient))',
    },
  }),
);

export const CustomModalForm = (props: React.FormHTMLAttributes<HTMLFormElement>) => {
  const classes = useStyles();
  return (
    <form className={classes.root} {...props} />
  );
};
