import { useMemo } from 'react';
import { WidgetLinkButtonProps } from './widget-link-button';
import { getInputPropertyValue } from '../../../widget-page/charts/helpers';

export const useWidgetLinkButton = ({
  widgetProperties = [],
}: WidgetLinkButtonProps) => {
  const link = useMemo(
    () => getInputPropertyValue('link', widgetProperties),
    [widgetProperties],
  );

  const textTooltip = useMemo(
    () => getInputPropertyValue('textTooltip', widgetProperties),
    [widgetProperties],
  );

  const openLink = () => {
    window.open(link);
  };

  const defaultTooltipTitle = 'Перейти по ссылке';

  const tooltipTitle = textTooltip || defaultTooltipTitle;

  return {
    link,
    openLink,
    tooltipTitle,
  };
};
