export interface IUpdateLoaderStatusSubscribe {
  name: string;
  func: (loaderId: number) => void;
}

export class UpdateLoaderStatusController {
  private subscribes: IUpdateLoaderStatusSubscribe[] = [];

  addSubscribe(subscribe: IUpdateLoaderStatusSubscribe): void {
    const currentSubscribeIndex = this.subscribes.findIndex(
      (sub) => sub.name === subscribe.name,
    );
    if (currentSubscribeIndex > -1) {
      this.subscribes[currentSubscribeIndex] = subscribe;
    } else {
      this.subscribes.push(subscribe);
    }
  }

  deleteSubscribe(label: string): void {
    const currentSubscribeIndex = this.subscribes.findIndex(
      (sub) => sub.name === label,
    );
    this.subscribes.splice(currentSubscribeIndex, 1);
  }

  callSubscribes(id: number) {
    this.subscribes.forEach((sub) => sub.func && sub.func(id));
  }
}

export interface IUpdateWidgetCacheSubscribe {
  widgetId: number;
  func: (widgetIds: number[]) => void;
}

export class UpdateWidgetCacheController {
  private subscribes: IUpdateWidgetCacheSubscribe[] = [];

  addSubscribe(subscribe: IUpdateWidgetCacheSubscribe): void {
    const currentSubscribeIndex = this.subscribes.findIndex(
      (sub) => sub.widgetId === subscribe.widgetId,
    );
    if (currentSubscribeIndex > -1) {
      this.subscribes[currentSubscribeIndex] = subscribe;
    } else {
      this.subscribes.push(subscribe);
    }
  }

  deleteSubscribe(widgetId: number): void {
    const currentSubscribeIndex = this.subscribes.findIndex(
      (sub) => sub.widgetId === widgetId,
    );
    this.subscribes.splice(currentSubscribeIndex, 1);
  }

  callSubscribes(ids: number[]) {
    this.subscribes.forEach((sub) => sub.func && sub.func(ids));
  }
}
