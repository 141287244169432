import React from 'react';
import { Property } from '../../../dropdown-layout/helpers/Property';
import CustomTooltip from '../../../../../uikit/CustomTooltip';

interface PanelItemValueProps {
  propertyInstance: Property;
}

export const PanelItemValue = ({ propertyInstance }: PanelItemValueProps) => {
  const value = propertyInstance.data.displayName
    ? propertyInstance.getViewDisplayName()
    : propertyInstance.getViewName();

  return (
    <div className="panel-item__value">
      <CustomTooltip arrow title={value}>
        <span>{value}</span>
      </CustomTooltip>
    </div>
  );
};
