import { toNumber } from 'lodash';
import { Widget, WidgetProperties } from '../../slices/types';
import {
  getAxisValues,
  getPanelIndex,
  getSimplifiedType,
} from '../../components/widget-page/dropdown-layout/helpers/helpers';
import { PanelType, WidgetPropertyType } from '../../enums/widget-type';
import { FormulasObject } from '../../components/common/special-inputs/formulas-input-container/useFormulasInputContainer';
import WidgetTypes from '../../types/widgets';
import { initValueFormatter } from '../../components/widget-page/charts/hooks/initValueFormatter';
import {
  setPropForDate,
  setPropForNumberValue,
} from '../../components/widget-page/charts/formatting-helpers';
import { getInputPropertyValue } from '../../components/widget-page/charts/helpers';

const PROPERTIES_TO_DELETE = [WidgetPropertyType.activeLegend];

const getWidgetPropertiesWithoutExtraProperties = (
  properties: WidgetProperties[],
) => {
  const newProperties = [...properties];

  PROPERTIES_TO_DELETE.forEach((item) => {
    const propertyIndex = getPanelIndex({
      properties,
      type: item,
    });

    if (propertyIndex !== -1) {
      newProperties.splice(propertyIndex, 1);
    }
  });

  return newProperties;
};

export const getCorrectWidgetProperties = (currentWidget: Widget) => {
  const widget = { ...currentWidget };
  let newProperties = [...widget.properties];

  newProperties = getWidgetPropertiesWithoutExtraProperties(newProperties);

  return newProperties;
};

export const getFormulasProperty = (
  properties: WidgetTypes.DataProperty[],
  propertyName: string,
) => {
  return properties.find((item) => item.name === propertyName);
};

export const getFormulasPropertyFromWidgetData = (
  widget: Widget,
  propertyName: string,
  dataProperties?: WidgetTypes.DataProperty[],
) => {
  if (!dataProperties)
    return getDefaultValueFromFormulasProperty(widget.properties, propertyName);

  const property = getFormulasProperty(dataProperties, propertyName);

  if (!property?.template?.length || isFormulasPropertyEdited(widget.properties, property))
    return getDefaultValueFromFormulasProperty(widget.properties, propertyName);

  return convertFormulasPropertyTemplate(property, widget.properties);
};

const isFormulasPropertyEdited = (
  widgetProperties: WidgetProperties[],
  property: WidgetTypes.DataProperty,
) => {
  const propertyValue = widgetProperties.find(
    (item) => item.name === property.name,
  )?.value;

  try {
    const parsedValue: FormulasObject = JSON.parse(propertyValue);
    return parsedValue.template !== property.template;
  } catch {
    return false;
  }
};

export const getDefaultValueFromFormulasProperty = (
  properties: WidgetProperties[],
  propertyName: string,
) => {
  const propertyValue = properties.find(
    (item) => item.name === propertyName,
  )?.value;

  try {
    const parsedValue: FormulasObject = JSON.parse(propertyValue);
    return parsedValue.valueWithoutOperands || '';
  } catch {
    return '';
  }
};

const getFormattedValues = (
  valueTypeList: WidgetTypes.ValueType[],
  properties: WidgetProperties[],
) => {
  const roundingCountValue = getInputPropertyValue(
    WidgetPropertyType.roundingCount,
    properties,
  );

  const roundingCount = toNumber(roundingCountValue);

  const valueFormat = initValueFormatter({ roundingCount });

  const dateDisplayFormat = getAxisValues(
    WidgetPropertyType.dateDisplayFormat,
    properties,
  );

  return valueTypeList.map((item) => {
    switch (getSimplifiedType(item.type)) {
      case 'DATE':
        return valueFormat(
          item.value,
          0,
          setPropForDate('', dateDisplayFormat, item.type),
          PanelType.axisY,
        );
      case 'NUMBER':
        return valueFormat(
          item.value,
          0,
          setPropForNumberValue(properties),
          PanelType.axisY,
        );
      default:
        return item.value;
    }
  });
};

const convertFormulasPropertyTemplate = (
  property: WidgetTypes.DataProperty,
  widgetProperties: WidgetProperties[],
) => {
  const formattedValues = getFormattedValues(
    property.valueTypeList,
    widgetProperties,
  );
  let { template } = property;
  const operandsAmount = formattedValues.length;

  for (let i = 0; i < operandsAmount; i++) {
    const variableOperand = new RegExp(`\\$${i}`, 'g');
    template = template.replace(
      variableOperand,
      `${formattedValues[i]}`,
    );
  }

  return template;
};
