import { useSelector } from 'react-redux';
import { DashboardProperty, State } from '../../../slices/types';

export const useDashboardPropertyValue = <T>(name: string) => {
  const properties = useSelector((state: State) => state.dashboard.properties);
  const prop: any = properties?.find(
    (prop: DashboardProperty) => prop.name === name,
  );

  return prop?.value;
};
