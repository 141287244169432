import { useCallback, useState } from 'react';

export const useDrilldownDepth = () => {
  const [drillDownDepth, setDrillDownDepth] = useState(0);

  const increaseDrilldownDepth = useCallback(() => {
    setDrillDownDepth((prev) => prev + 1);
  }, []);

  const decreaseDrilldownDepth= useCallback((isClose?: boolean) => {
    const newDepth = isClose ? 0 : drillDownDepth - 1;
    setDrillDownDepth(newDepth);

  }, [drillDownDepth]);

  return {
    drillDownDepth,
    increaseDrilldownDepth,
    decreaseDrilldownDepth,
  };
};
