import { defaultStyles } from '@visx/tooltip';
import { bisector, max, min } from 'd3-array';
import { concat, times } from 'lodash';
import { WidgetSimplifiedDataType } from '../../../../enums/data-type';
import { getStorageName } from '../../../../helpers/common-helpers';
import { PropertyData } from '../../dropdown-layout/helpers/Property';
import {
  AccessorDateX,
  AccessorNumberX,
  GetVerticalAccessor,
  GraphData,
  HorizontalAccessor,
  SeriesValue,
  VerticalScale,
} from './types';
import { getSimplifiedType } from '../../dropdown-layout/helpers/helpers';

export const axisColor = 'var(--grey)';

export const axisTickLabelProps = {
  fontSize: 14,
  fontFamily: 'Roboto',
  fontWeight: 500,
  fill: axisColor,
};
export const axisBottomTickLabelProps = {
  ...axisTickLabelProps,
  textAnchor: 'middle' as const,
};
export const axisLeftTickLabelProps = {
  ...axisTickLabelProps,
  dx: '-0.25em',
  dy: '0.25em',
  textAnchor: 'end' as const,
};
export const axisRightTickLabelProps = {
  ...axisTickLabelProps,
  dx: '-0.25em',
  dy: '0.25em',
  textAnchor: 'start' as const,
};

export const tooltipStyles = {
  ...defaultStyles,
  borderRadius: 5,
  backgroundColor: 'rgba(68,68,68,.9)',
  color: '#fff',
  fontFamily: 'Roboto',
  fontSize: 14,
  fontWeight: 400,
};

export const margin = {
  top: 20,
  right: 40,
  bottom: 15,
  betweenGraphOffset: 10,
  left: 40,
};
// отодвигает график вверх, чтобы он не наезжал на brush, но почему он наезжает - не понятно, надо разобраться
export const chartBrushSeparation = 35;
export const brushHeight = 8;
export const tooltipTopOffset = 20;

// accessors
export const accessorDateX: AccessorDateX = (d: GraphData) => new Date(d.x);
export const accessorNumberX: AccessorNumberX = (d: GraphData) => parseFloat(d.x);
export const getAccessorY: GetVerticalAccessor =
  (index: number) => (d: GraphData) =>
    d.y[index];
export const accessorMinY = (d: GraphData) => min(d.y);
export const accessorMaxY = (d: GraphData) => max(d.y);
export const getAccessorZ: GetVerticalAccessor =
  (index: number) => (d: GraphData) =>
    d.z[index];
export const accessorMinZ = (d: GraphData) => min(d.z);
export const accessorMaxZ = (d: GraphData) => max(d.z);
export const bisectX = (accessorX: HorizontalAccessor) =>
  bisector<GraphData, Date | number>(accessorX).left;

export const onlyNegativeDomainValues = (verticalScale: VerticalScale) =>
  (max(verticalScale.domain()) || -1) <= 0;

export const moveElementsToEndOfArray = (arr: any[], length: number) => {
  const x = length % arr.length;
  const shiftedArray = [...arr];

  for (let i = 0; i < x; i++) {
    shiftedArray.push(shiftedArray[i]);
  }

  shiftedArray.splice(0, x);

  return shiftedArray;
};

export const getSeriesValues = (
  seriesName: string,
  axisValues: PropertyData[],
  colors?: string[],
): SeriesValue[] => {

  const currentAxisValues = axisValues || [];
  return currentAxisValues
    .filter((axisValue) => axisValue.isActive)
    .map((axisValue, index) => {

      const aliasName = getStorageName(axisValue.storage);

      return {
        id: axisValue.id || axisValue.widgetFieldId || null,
        name: aliasName || axisValue.displayName || axisValue.name,
        key: `${seriesName}-${index}`,
        color: colors?.[index] || '#000',
        simplifiedType: getSimplifiedType(axisValue.type),
      };
    });

};

export const extendColors = (currentColors: string[], length: number) => {
  const colors = currentColors;

  const multy = Math.floor(currentColors.length / length);

  return times(multy + 1)
    .map(() => colors)
    .reduce((a, b) => concat(a, b), []);
};

function isValidDate(value: string | Date) {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

export const protectValues = (data: GraphData[], dataType: WidgetSimplifiedDataType) => {
// если с сервера не придет z или y, то нужно добавить поле с пустым массивом, для типобезопасности
  const restoredData = data.map((value: any) => ({
    ...value,
    y: value.y || [],
    z: value.z || [],
  }));

  switch (dataType) {
    case WidgetSimplifiedDataType.DATE: {
      return restoredData.filter(value => isValidDate(accessorDateX(value)));
    }
    case WidgetSimplifiedDataType.NUMBER: {
      return restoredData.filter(value => isFinite(accessorNumberX(value)));
    }
    default: return restoredData;
  }
};
