import React, { CSSProperties, useState } from 'react';
import clsx from 'clsx';
import './popover-menu.css';
import { isNull } from 'lodash';
import { CustomProgress } from '../Progress';
import { IconDictionary } from '../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../components/common/icon-svg/icon-svg';

export interface PopoverMenuSetting {
  title: string;
  icon?: JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  isHidden?: boolean;
  isNeedDivider?: boolean;
  isDelete?: boolean;
  onHover?: () => void;
  children?: PopoverMenuSetting[] | null; // only for two depth levels
  selected?: boolean;
  loading?: boolean;
}

interface PopoverMenuProps {
  settings: PopoverMenuSetting[];
  customStyles?: CSSProperties;
  isLoading?: boolean;
  className?: string;
  withWordWrap?: boolean;
}

export const CustomPopoverMenu = ({
  settings,
  customStyles,
  isLoading,
  className = '',
  withWordWrap = false,
}: PopoverMenuProps) => {
  const [openChildIndex, setOpenChildIndex] = useState<number | null>(null);
  const getItemClasses = (item: PopoverMenuSetting) => {
    return clsx('popover-menu__item', {
      'popover-menu__item_disabled': item.disabled,
      'popover-menu__item_delete': item.isDelete,
      'popover-menu__item_selected': item.selected,
      'popover-menu__item_word-wrap': withWordWrap,
    });
  };
  const handleClickItem = (
    event: React.MouseEvent<HTMLDivElement>,
    item: PopoverMenuSetting,
  ) => {
    item.onClick && item.onClick(event);
    event.stopPropagation();
    event.preventDefault();
  };

  const onMouseLeave = () => {
    !isNull(openChildIndex) && setOpenChildIndex(null);
  };

  const onMouseEnter = (item: PopoverMenuSetting, index: number) => {
    item.onHover && item.onHover();
    item.children && setOpenChildIndex(index);
  };

  return (
    <div className={`popover-menu ${className}`} style={{ ...customStyles }}>
      {isLoading && (
        <div className="popover-menu__loading">
          <CustomProgress type="circular" size={20} />
        </div>
      )}
      {!isLoading &&
        settings.map((item, index) => (
          <>
            {!item.isHidden && (
              <div
                onMouseEnter={() => onMouseEnter(item, index)}
                onMouseLeave={onMouseLeave}
                key={item.title}
                className={getItemClasses(item)}
                onClick={(event) => handleClickItem(event, item)}
              >
                {item.loading && (
                  <div className="popover-menu__icon"><CustomProgress type="circular" style={{ color: 'var(--primary-color)' }} size="100%" /></div>
                )}
                {item.icon && !item.loading && (
                  <div className="popover-menu__icon">{item.icon}</div>
                )}
                <div className="popover-menu__title">{item.title}</div>
                {item.children && openChildIndex === index && (
                  <CustomPopoverMenu
                    settings={item.children}
                    customStyles={customStyles}
                    className="popover-menu__children"
                    withWordWrap={withWordWrap}
                  />
                )}
                {item.children && (
                  <div className="popover-menu__arrow-icon">
                    <IconSvg svg={IconDictionary.CompactArrowToRight} fill="var(--dark-grey)" />
                  </div>
                )}
              </div>
            )}
            {!item.isHidden && item.isNeedDivider && (
              <div className="popover-menu__divider" />
            )}
          </>
        ))}
    </div>
  );
};
