import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import Connections from './connections';
import Sidebar from '../../common/sidebar';
import './style.css';
import ProjectsWithMapsList from './maps-list';
import { State, Widget } from '../../../slices/types';
import { apiSetProject } from '../../../services/projectsController';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { setCurrentProjectAction } from '../../../slices/main-page/main-page-slice';
import { toggleChangeMapPopupAction } from '../../../slices/map-view/map-view';
import { apiGetMapConnection } from '../../../services/loadersController';
import { CustomTabs } from '../../../uikit/Tabs';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import {CommonDictionary, WidgetDictionary} from '../../../dictionaries/naming-dictionary/naming-dictionary';

const ConnectionsContainer = () => {
  const dispatch = useDispatch();

  const widgetsList: Widget[] | [] = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? [],
  );

  const initialLoaderId: number =
    useSelector(
      (state: State) => state.mainPage?.currentProject?.initialLoaderId,
    ) ?? 0;

  const isChangeMapPopupShowing = useSelector(
    (state: State) => state.mapView.showChangeMapPopup,
  );

  const currentProject: any = useSelector(
    (state: State) => state.mainPage.currentProject ?? [],
  );

  const [mapType, setMapType] = useState<string>('');

  const renderMapPanel = (type: string) => {
    switch (type) {
      case 'completedMaps':
        return <ProjectsWithMapsList />;
      case 'newMap':
      default:
        return <Connections />;
    }
  };

  useEffect(() => {
    const isNewMapActive = currentProject.editableMap
      ? 'newMap'
      : 'completedMaps';

    setMapType(isNewMapActive);
  }, [currentProject]);

  const changeMapType = useCallback((type: string) => {
    if (type === 'newMap' && type != mapType) {
      apiGetMapConnection(initialLoaderId).then((result: any) => {
        const mapData = result;

        const projectCopy: any = { ...currentProject };
        projectCopy.loaderId = initialLoaderId;
        projectCopy.editableMap = true;
        apiSetProject(initialLoaderId, projectCopy).then(() => {
          dispatch(
            mapConnectionAction.addConnection({
              loaderId: initialLoaderId,
              data: mapData,
            }),
          );

          dispatch(setCurrentProjectAction(projectCopy));
        });
      });
    }

    setMapType(type);
  }, [currentProject, initialLoaderId, mapType]);

  const tabs = [
    {
      id: 'completedMaps',
      title: CommonDictionary.readyCards,
      onClick: () => {
        if (widgetsList.length > 0) {
          dispatch(toggleChangeMapPopupAction(!isChangeMapPopupShowing));
        } else {
          changeMapType('completedMaps');
        }
      },
    },
    {
      id: 'newMap',
      title: CommonDictionary.ownCard,
      onClick: () => {
        if (widgetsList.length > 0) {
          dispatch(toggleChangeMapPopupAction(!isChangeMapPopupShowing));
        } else {
          changeMapType('newMap');
        }
      },
    },
  ];

  const selectedTabIndex = tabs.findIndex((tab) => tab.id === mapType) || 0;

  return (
    <Sidebar>
      <div className="map-tabs">
        <CustomTabs buttons={tabs} selected={selectedTabIndex} />
      </div>
      {renderMapPanel(mapType)}
      <CustomDialog
        isOpen={isChangeMapPopupShowing}
        onClose={() => {dispatch(toggleChangeMapPopupAction(!isChangeMapPopupShowing));}}
        title="Замена карты данных невозможна"
        maxWidth="xs"
      >
        <div>
          <div className="dialog-body">
            <Alert className="alert" severity="warning">
              <p className="map-tabs-modal__description">
                Смена карты приведет к некорректной работе {WidgetDictionary.many}, построенных ранее.
              </p>
              <p className="map-tabs-modal__description">
                Для смены карты необходимо удалить все виджеты.
              </p>
            </Alert>

          </div>
        </div>
      </CustomDialog>
    </Sidebar>
  );
};

export default ConnectionsContainer;
