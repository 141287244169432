import React, { useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import './styles.css';
import IconSvg from '../icon-svg/icon-svg';
import { InlineTextButton } from '../../../uikit/InlineTextButton';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

type DropzoneProps = {
  options: DropzoneOptions;
  isShowAccepted: boolean;
};

export const Dropzone = ({ options = {}, isShowAccepted }: DropzoneProps) => {
  const [isDragOver, setDragOver] = useState(false);

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...options,
  });

  const isSingle = options?.maxFiles === 1;

  const files = acceptedFiles.map((file: any) => (
    <div key={file.path}>
      {file.path} - {file.size} байт
    </div>
  ));

  return (
    <div className="dropzone-container">
      <div
        {...getRootProps({
          className: `dropzone${
            isDragOver && isDragActive ? ' drag-over' : ''
          }`,
        })}
        onDragOver={() => setDragOver(true)}
        onDragLeave={() => setDragOver(false)}
      >
        <input {...getInputProps()} />
        <IconSvg svg={IconDictionary.Clip} width={50} height={50} fill="var(--dark-grey)" />
        <div className="dropzone__main">
          <span>
            {`Перетяните ${
              isSingle ? 'необходимый файл' : 'необходимые файлы'
            } в эту область или нажмите `}
          </span>
          <InlineTextButton
            title="oбзор"
            onClick={open}
            styleProps={{ fontSize: 16 }}
          />
        </div>
        <p className="hint">Поддерживаемые типы файлов: .xlsx, .xls, .csv</p>
      </div>
      {isShowAccepted && files.length > 0 && (
        <aside>
          <b>{isSingle ? 'Файл:' : 'Файлы:'}</b>
          <div>{files}</div>
        </aside>
      )}
    </div>
  );
};
