import { NavLink } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { State } from '../../../../slices/types';
import {CommonDictionary} from "../../../../dictionaries/naming-dictionary/naming-dictionary";
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

export const ProjectLink = () => {
  const projectId: number = useSelector(
    (state: State) => state.mainPage?.currentProject?.id ?? 0,
  );

  return (
    <div className="widget-carousel__project-link">
      <div className="widget-carousel-item">
        <NavLink to={`/project/${projectId}`}>
          <>
            <IconSvg
              svg={IconDictionary.Data}
              fill="var(--dark-grey)"
              width={22}
              height={22}
            />
            <span className="widget-carousel-item__label">{CommonDictionary.data}</span>
          </>
        </NavLink>
      </div>
    </div>
  );
};
