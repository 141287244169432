import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ConnectionFieldType } from '../../../enums/connection-field-type';
import Connection from '../../../types/connection';
import { charsets, fieldName } from './data';
import { SelectInput } from '../../common/special-inputs/inputs/select-input';
import { TextInput } from '../../common/special-inputs/inputs/text-input';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: 'var(--white)',
    },
    background: 'var(--white)',
    border: '1px solid var(--dark-cyan)',
    '&.Mui-focused': {
      borderBottom: '2px solid var(--primary-color)',
    },
    '&.Mui-focused:hover': {
      borderBottom: '2px solid var(--primary-color)',
    },
    '&.Mui-focused > .MuiInputBase-input': {
      paddingBottom: '6px',
    },
  },
  filter: {
    paddingTop: '19px',
    paddingBottom: '7px',
    height: '20px',
  },
  focused: {
    background: 'var(--white) !important',
    borderBottom: '2px solid var(--primary-color)',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  shrink: {
    color: 'var(--dark-grey) !important',
  },
  select: {
    gridColumn: 'span 4',
    height: 'min-content',
  },
  connectionFormTextarea: {
    gridColumn: 'span 4',
  }
});

interface Props {
  className: string;
  data: Connection.Param;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
}

export const SingleField: React.FC<Props> = ({
  className = '',
  data,
  values,
  errors,
  touched,
  handleChange,
}) => {
  const name: string = fieldName[data.type];
  const classes = useStyles();

  switch (data.type) {
    case ConnectionFieldType.Encoding: {
      return (
        <SelectInput
          fullWidth
          label={data.description}
          value={values[name]}
          className={classes.select}
          handleChange={handleChange(name)}
          itemList={charsets.map((item) => ({ value: item, showValue: item }))}
        />
      );
    }

    case ConnectionFieldType.ConnectionConfig: {
      return (
        <TextInput
          fullWidth
          label={data.description}
          handleChange={handleChange(name)}
          rows={20}
          maxRows={20}
          value={values[name]}
          multiline
          className={classes.connectionFormTextarea}
        />
      );
    }
    default: {
      return (
        <TextInput
          name={name}
          label={data.description}
          value={values[name]}
          className={className}
          handleChange={handleChange}
          error={Boolean(errors[name]) && touched[name]}
          helperText={errors[name]}
          type={
            data.type === ConnectionFieldType.Password ? 'password' : 'text'
          }
        />
      );
    }
  }
};
