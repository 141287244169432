import { BoundFilterGroupProps } from '../../hooks';
import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

export const useCheckingCycleInDrilldown = (
  drilldownValues: BoundFilterGroupProps[],
) => {
  const setRelatedWidgets = () => {
    const widgets: any = [];

    const correctDrilldownValues = drilldownValues.filter(
      (item) =>
        item.participantFilterWidgets.length &&
        item.participantFilterWidgets.findIndex(
          (widget) => widget.isActive && !widget.isMaster,
        ) + 1,
    );

    correctDrilldownValues.map((value) => {
      const master = value.participantFilterWidgets.find(
        (widget) => widget.isActive && widget.isMaster,
      )?.widgetId;
      const dependent = value.participantFilterWidgets.find(
        (widget) => widget.isActive && !widget.isMaster,
      )?.widgetId;
      widgets.push({ master, dependent });
    });

    return widgets;
  };

  const relatedWidgets = useMemo(() => setRelatedWidgets(), [drilldownValues]);

  const [inactiveWidgetsForDD, setInactiveWidgetsForDD] = useState<any[]>([]);

  const checkCycleDD = (master: any, widget: any) => {
    const bounds = relatedWidgets.filter(
      (item: any) => item.master === widget.dependent,
    );

    const isInclude =
      inactiveWidgetsForDD.findIndex((item: any) =>
        isEqual(item, {
          dependent: master.master,
          master: widget.dependent,
        }),
      ) + 1;
    if (!bounds.length && !isInclude) {
      setInactiveWidgetsForDD([
        ...inactiveWidgetsForDD,
        { dependent: master.master, master: widget.dependent },
      ]);
    }

    bounds.forEach((item: any) => {
      checkCycleDD(master, item);
    });
  };

  const setInactiveWidgets = () => {
    relatedWidgets.forEach((widget: any) => {
      checkCycleDD(widget, widget);
    });
  };

  useEffect(() => {
    setInactiveWidgets();
  }, [drilldownValues]);

  return { inactiveWidgetsForDD };
};
