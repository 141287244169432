import React from 'react';
import { Backdrop, Dialog } from '@material-ui/core';
import './index.css';

interface BackdropScreenshotProps {
  open: boolean;
}

export const BackdropScreenshot = ({ open }: BackdropScreenshotProps) => {
  return (
    <Dialog open={open}>
      <Backdrop open={open}>
        <p className="backdrop-screenshot__text">Изображение формируется...</p>
      </Backdrop>
    </Dialog>
  );
};
