import { useSelector } from 'react-redux';
import { MoreButtonProps } from './more-button';
import { isShowRestriction } from '../../helpers';
import { State } from '../../../../../slices/types';
import { getAxisWithRestriction } from '../../../helpers';

export const useMoreButton = ({
  functionsAndAggregations,
  propertyInstance,
  panel,
}: MoreButtonProps) => {
  const widget = useSelector((state: State) => state.widget);

  const panelsWithRestriction = getAxisWithRestriction(widget.type);

  const isShowMoreButton =
    functionsAndAggregations.functions.length ||
    functionsAndAggregations.aggregations.length ||
    isShowRestriction(
      widget.type,
      panelsWithRestriction.includes(panel.name),
      propertyInstance.isComplex(),
    );

  return {
    isShowMoreButton,
  };
};
