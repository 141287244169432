import React from 'react';
import { DashboardFilterProperties } from '../../../../../slices/types';
import { SettingItem } from './setting-item';

interface SettingGroupProps {
  group: DashboardFilterProperties;
  filtersGroupId: number;
}

export const SettingGroup = ({ group, filtersGroupId }: SettingGroupProps) => {

  return (
    <div className="filters-setting-group">
      <div className="filters-setting-group__title">{group.title}</div>
      <div className="filters-setting-group__main">
        {group.settingsGroup.map((settingsGroup) => {
          return settingsGroup.settings.map((setting) => (
            <SettingItem
              key={setting.name}
              setting={setting}
              type={settingsGroup.type}
              filtersGroupId={filtersGroupId}
            />
          ));
        })}
      </div>
    </div>
  );
};
