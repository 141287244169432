import { useEffect, useState } from 'react';
import { EditableTextContainerProps } from './editable-text-container';

export const useEditableTextContainer = ({
  text,
  callback,
}: EditableTextContainerProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableText, setEditableText] = useState(text);

  useEffect(() => {
    setEditableText(text);
  }, [text]);

  const handleSave = () => {
    callback(editableText);
    setIsEditMode((prev) => !prev);
  };

  const handleReset = () => {
    setEditableText(text);
    setIsEditMode((prev) => !prev);
  };

  return {
    editableText,
    isEditMode,
    setEditableText,
    handleSave,
    handleReset,
    setIsEditMode,
  };
};
