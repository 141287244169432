import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import moment from 'moment/moment';
import { CustomTabs } from '../../../../../uikit/Tabs';
import { DateOptions } from '../../../../../slices/types';

interface FilterTabsContainerProps {
  currentDateOption: DateOptions | null;
  options?: { [key in DateOptions]: boolean } | null;
  dateValues: any;
  handleClickTab: (
    startDate: string,
    endDate: moment.Moment,
    option: DateOptions,
  ) => void;
}

export const FilterTabsContainer = ({
  currentDateOption,
  options,
  dateValues,
  handleClickTab,
}: FilterTabsContainerProps) => {
  const tabs = useMemo(() => {
    const DAYS_OFFSET = 1; // can be set from outside in future
    if (options) {
      const activeOptions = Object.keys(options).filter(
        (option) => options[option as DateOptions],
      );

      return activeOptions.map((option) => {
        const startDate = moment()
          .subtract(DAYS_OFFSET, 'days')
          .startOf(dateValues[option as DateOptions].momentName as any)
          .format('YYYY-MM-DD 00:00:00.0')
          .toString();

        const endDate = moment()
          .subtract(DAYS_OFFSET, 'days')
          .endOf(dateValues[option as DateOptions].momentName as any);

        return {
          id: option,
          title: dateValues[option as DateOptions].title,
          onClick: () => handleClickTab(startDate, endDate, option as DateOptions),
        };
      });
    }
    return null;
  }, [handleClickTab, options]);

  const selectTabIndex = tabs?.findIndex((tab) => tab.id === currentDateOption);

  return (
    <>
      {tabs !== null && tabs.length > 0 && (
        <div className="filter-tabs-container">
          <Box display="flex">
            <CustomTabs
              buttons={tabs}
              selected={selectTabIndex}
              size={tabs.length > 2 ? undefined : 80}
              height='small'
              variant='chips'
            />
          </Box>
        </div>
      )}
    </>
  );
};
