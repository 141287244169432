import { useMemo } from 'react';
import {
  useGetAxisWidgetProperty, useGetBooleanWidgetProperty,
  useGetColorPaletteWidgetProperty, useGetNumberWidgetProperty, useGetStringWidgetProperty
} from '../../../../../hooks/get-properties/useGetWidgetProperty';
import { WidgetProperties } from '../../../../../slices/types';
import { PanelType, WidgetPropertyType } from '../../../../../enums/widget-type';
import {
  getLegendsLabelsForWidgetByAxis,
  getSelectPropertyValue,
} from '../../helpers';
import { NumberFormat } from '../../../../../enums/number-format';

export interface useBubblePropertiesProps {
  widgetProperties: WidgetProperties[];
}


export const useBubbleProperties = ({
  widgetProperties,
}: useBubblePropertiesProps) => {
  const axisXValues = useGetAxisWidgetProperty(
    PanelType.axisX,
    widgetProperties,
    [],
  );
  const axisYValues = useGetAxisWidgetProperty(
    PanelType.axisY,
    widgetProperties,
    [],
  );
  const axisZValues = useGetAxisWidgetProperty(
    PanelType.axisZ,
    widgetProperties,
    [],
  );


  const axisXHeight = useGetNumberWidgetProperty(
    WidgetPropertyType.axisXLabelHeight,
    widgetProperties,
    0,
  );
  const axisYWidth = useGetNumberWidgetProperty(
    WidgetPropertyType.axisYLabelWidth,
    widgetProperties,
    0,
  );
  const pointMinSize = useGetNumberWidgetProperty(
    WidgetPropertyType.pointMinSize,
    widgetProperties,
    5,
  );
  const pointMaxSize = useGetNumberWidgetProperty(
    WidgetPropertyType.pointMaxSize,
    widgetProperties,
    30,
  );
  const pointDiffSize = useGetNumberWidgetProperty(
    WidgetPropertyType.pointDiffSize,
    widgetProperties,
    10,
  );
  const pointOffsetCoeff = useGetNumberWidgetProperty(
    WidgetPropertyType.pointOffsetCoeff,
    widgetProperties,
    0.5,
  );

  // todo нужно создать отдельный хук для этого свойства
  const textDirection = useGetStringWidgetProperty(
    WidgetPropertyType.TextDirection,
    widgetProperties,
  );

  const roundingCountString = useGetStringWidgetProperty(
    WidgetPropertyType.roundingCount,
    widgetProperties,
  );

  const roundingCount: number | undefined =
    roundingCountString !== '' ? Number(roundingCountString) : undefined;

  const currentColors = useGetColorPaletteWidgetProperty(widgetProperties);

  const isNeedToDisplayAxesGuide = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayAxesGuide,
    widgetProperties,
  );

  const isScaleByValue = useGetBooleanWidgetProperty(
    WidgetPropertyType.scaleByValue,
    widgetProperties,
  );

  const isNeedToDisplayValue = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayFunnelValue,
    widgetProperties,
  );

  const isNeedToDisplayPercent = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayFunnelPercent,
    widgetProperties,
  );

  const isNeedToDisplayTooltip = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayTooltip,
    widgetProperties,
  );

  const isNeedToDisplayNameSlice = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayNameSlice,
    widgetProperties,
  );

  const isNeedDisplayAggregationInLegend = useGetBooleanWidgetProperty(
    WidgetPropertyType.displayAggregationInLegend,
    widgetProperties,
  );

  const scaleByNumber = useGetBooleanWidgetProperty(
    WidgetPropertyType.scaleByNumber,
    widgetProperties,
  );

  // todo нужно создать отдельный хук для этого свойства
  const legendsLabels = useMemo(() => getLegendsLabelsForWidgetByAxis(
    PanelType.axisY,
    widgetProperties,
    isNeedDisplayAggregationInLegend,
  ),
  [isNeedDisplayAggregationInLegend, widgetProperties]);

  // todo нужно создать отдельный хук для этого свойства
  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  return {
    legendsLabels,
    axisXValues,
    axisYValues,
    axisZValues,
    pointMinSize,
    pointMaxSize,
    pointDiffSize,
    axisXHeight,
    axisYWidth,
    currentColors,
    isNeedToDisplayAxesGuide,
    isScaleByValue,
    textDirection,
    isNeedToDisplayValue,
    isNeedToDisplayPercent,
    isNeedToDisplayTooltip,
    isNeedToDisplayNameSlice,
    formatByNumber,
    scaleByNumber,
    roundingCount,
    pointOffsetCoeff,
  };
};
