import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { numDecline } from '../../../../utils/functions';
import { GroupTitlePanelButtons } from './group-title-panel-buttons';
import { Roles } from '../../../../enums/roles';
import { CommonDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import clsx from 'clsx';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface GroupTitlePanelProps {
  title: string;
  handleGroupHeaderClick: (event: React.MouseEvent<HTMLElement>) => void;
  permission: Roles;
  projectsCount: number;
  groupId: number;
  isShowGroup: boolean;
  handleClickChange: () => void;
  handleClickDelete: () => void;
  handleClickCreate: () => void;
}

export const GroupTitlePanel = ({
  title,
  handleGroupHeaderClick,
  projectsCount,
  permission,
  groupId,
  isShowGroup,
  handleClickChange,
  handleClickDelete,
  handleClickCreate,
}: GroupTitlePanelProps) => {
  const projectsCountValue = `${
    projectsCount
  } ${numDecline(
    projectsCount,
    CommonDictionary.oneProject,
    CommonDictionary.fewProjects,
    CommonDictionary.manyProjects,
  )}`;

  return (
    <ListItem
      button
      disableRipple
      onClick={handleGroupHeaderClick}
      className={clsx('group-title-panel', {'group-title-panel_open': isShowGroup})}
    >
      <ListItemText>
        <div className='counter'>
          <div className='counter__title'>{title}</div>
          <IconSvg
            svg={IconDictionary.ProjectCount}
            fill='var(--dark-grey)'
            classSVG='counter-icon'
            width={20}
            height={20}
          />
          <span className='counter__projects'>{projectsCountValue}</span>
        </div>
      </ListItemText>

      <GroupTitlePanelButtons
        groupId={groupId}
        isShowGroup={isShowGroup}
        projectsCount={projectsCount}
        permission={permission}
        handleClickChange={handleClickChange}
        handleClickDelete={handleClickDelete}
        handleClickCreate={handleClickCreate}
      />
    </ListItem>
  );
};
