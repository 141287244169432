import { ParentSize } from '@visx/responsive';
import { isNil, isNaN } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { NumberValue } from 'd3-scale';
import { PanelType } from '../../../../enums/widget-type';
import { getFirstActivePanelItemIndex } from '../../../../helpers/common-helpers';
import { FilterField, SetFilterField } from '../../../dashboard-page/hooks';
import { setPropForNumberValue } from '../formatting-helpers';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { useOverlayGraphProperties } from './hooks/useOverlayGraphProperties';
import {
  extendColors,
  getSeriesValues,
  moveElementsToEndOfArray,
  protectValues,
} from './helper';
import { FormatAxisXValue, GraphData, SeriesValue } from './types';
import './overlay-graph-styles.css';
import Graph from './components/graph';

interface OverlayGraphProps {
  widgetProps: any;
  setFilterField?: SetFilterField;
  filterField?: FilterField;
  isActiveFilter?: boolean;
}

export default function OverlayGraph({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}: OverlayGraphProps) {
  const widgetProperties = widgetProps.properties;

  const {
    axisXValues,
    axisYValues,
    axisZValues,
    currentColors,
    typeFillGraph,
    isSmoothedLine,
    roundingCount,
    scaleByNumber,
    formatByNumber,
    customAxisYLabelWidth,
    customAxisZLabelWidth,
    isNeedToDisplayTooltip,
    isScaleByValue,
    isNeedToDisplayAxesGuide,
    isNeedInterpolateHorizontalAxis,
    isNeedDisplayBrush,
  } = useOverlayGraphProperties({ widgetProperties });

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  const valueFormat = useMemo(
    () => initValueFormatter({ roundingCount }),
    [roundingCount],
  );

  const formatAxisXValue: FormatAxisXValue = useCallback(
    (value: Date | NumberValue) => {
      if (isNaN(value) || (value instanceof Date && isNaN(value.getTime())))
        return '-';
      return valueFormat(value, axisXValueIndex, widgetProperties, PanelType.axisX);
    },
    [axisXValueIndex, valueFormat, widgetProperties],
  );

  const getFormatAxisValue = useCallback(
    (axis: PanelType) => {
      return (value: number, index?: number) => {
        return String(
          valueFormat(
            value,
            index || 0,
            isNil(index)
              ? setPropForNumberValue(widgetProperties)
              : widgetProperties,
            axis,
            false,
            false,
            scaleByNumber ? formatByNumber : null,
          ),
        );
      };
    },
    [formatByNumber, scaleByNumber, valueFormat, widgetProperties],
  );
  const formatAxisYValue = useMemo(
    () => getFormatAxisValue(PanelType.axisY),
    [getFormatAxisValue],
  );
  const formatAxisZValue = useMemo(
    () => getFormatAxisValue(PanelType.axisZ),
    [getFormatAxisValue],
  );

  const colors = extendColors(
    currentColors,
    axisYValues.length + axisZValues.length,
  );

  const xValue = getSeriesValues('axisX', axisXValues)[0] as SeriesValue | undefined;
  const yValues = getSeriesValues('axisY', axisYValues, colors);
  const zValues = getSeriesValues(
    'axisZ',
    axisZValues,
    moveElementsToEndOfArray(colors, axisYValues.length),
  );

  const filterFieldValue = filterField?.value || null;

  const deselectPoint = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const xValueId = xValue?.id;

  const selectPoint = useCallback(
    (valueX: string) => {
      if (filterFieldValue?.includes(valueX)) {
        deselectPoint();
      } else {
        if (!setFilterField || !valueX || !xValueId) return;

        setFilterField(widgetProps.id, xValueId, [
          {
            operation: '=',
            value: [valueX],
          },
        ]);
      }
    },
    [filterFieldValue, deselectPoint, setFilterField, widgetProps.id, xValueId]);

  if (!xValue) return null;

  const isLineSeries = typeFillGraph === 'Нет';
  const isGradientFill = typeFillGraph === 'Градиентная';

  const widgetData: GraphData[] = protectValues(widgetProps.data, xValue.simplifiedType);

  return (
    <div className="widget-overlay-graph">
      <ParentSize>
        {({ width, height }) => {
          if (height < 10) return null;
          return (
            <Graph
              width={width}
              height={height}
              widgetData={widgetData}
              xValue={xValue}
              yValues={yValues}
              zValues={zValues}
              colors={currentColors}
              isLineSeries={isLineSeries}
              isGradientFill={isGradientFill}
              isSmoothedLine={isSmoothedLine}
              isScaleByValue={isScaleByValue}
              formatAxisXValue={formatAxisXValue}
              formatAxisYValue={formatAxisYValue}
              formatAxisZValue={formatAxisZValue}
              isNeedToDisplayTooltip={isNeedToDisplayTooltip}
              isNeedToDisplayAxesGuide={isNeedToDisplayAxesGuide}
              isNeedInterpolateHorizontalAxis={isNeedInterpolateHorizontalAxis}
              isNeedDisplayBrush={isNeedDisplayBrush}
              customAxisYLabelWidth={customAxisYLabelWidth}
              customAxisZLabelWidth={customAxisZLabelWidth}
              deselectPoint={isActiveFilter ? deselectPoint : undefined}
              selectPoint={isActiveFilter ? selectPoint : undefined}
              filterFieldValue={filterFieldValue}
            />
          );
        }}
      </ParentSize>
    </div>
  );
}
