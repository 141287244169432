import { v4 as uuid } from 'uuid';
import MapConnection from '../../../types/map-connection';
import { SelectOptions } from './type';
import { Operation } from '../../../enums/operation';
import { createUnionMappingToDisplay, getUnionResultDisplayedNames } from '../../../helpers/loader-page/index';

/*
  METHOD PART:
  SAVE STRATEGY PANEL METHODS
*/
export const addAllOptionsToFieldsMapping = (
  type: string,
  targetObjectId: string,
  sourceObjectId: string,
  fieldsMapping: any[],
  selectOptions: SelectOptions,
  stepId: string,
  isNewTarget?: boolean,
) => {
  let selectedOptionsList: any[] = [];
  const targetOptions = selectOptions[targetObjectId];
  const sourceOptions = selectOptions[sourceObjectId];
  const newFieldsMapping = [...fieldsMapping];

  switch (type) {
    case 'source':
      selectedOptionsList = [...selectOptions[sourceObjectId]];
      break;

    case 'target':
      selectedOptionsList = [...selectOptions[targetObjectId]];
      break;
  }

  selectedOptionsList.forEach((option) => {
    let newFieldsMappingItem = createFieldsMappingItem(
      stepId,
      sourceObjectId,
      targetObjectId,
    );
    newFieldsMappingItem = setFieldNames(
      newFieldsMappingItem,
      type,
      option,
      sourceOptions,
      targetOptions,
      isNewTarget,
    );
    newFieldsMapping.push(newFieldsMappingItem);
  });

  return newFieldsMapping;
};

/*
  METHOD PART:
  FORM METHODS
  FIELD LIST PART
*/

export const checkIfNeedToSetAutoValue = (
  optionName: string,
  selectedOptionName: string,
  options: any[],
  isForceSetting?: boolean,
) => {
  if (isForceSetting) return true;
  if (optionName || optionName === 'unknown') return false;
  return options.includes(selectedOptionName);
};

export const setFieldNames = (
  fieldsMappingItem: MapConnection.FieldsMapping,
  type: string,
  value: string,
  sourceOptions: any[],
  targetOptions: any[],
  isNewTarget?: boolean,
) => {
  const newFieldsMappingItem = JSON.parse(JSON.stringify(fieldsMappingItem));

  const targetFieldName = fieldsMappingItem.targetField.fieldName;
  const sourceFieldName = fieldsMappingItem.sourceFieldName.fieldName;

  switch (type) {
    case 'source':
      newFieldsMappingItem.sourceFieldName.fieldName = value;
      if (
        checkIfNeedToSetAutoValue(
          targetFieldName,
          value,
          targetOptions,
          isNewTarget,
        )
      ) {
        newFieldsMappingItem.targetField.fieldName = value;
      }
      break;
    case 'target':
      newFieldsMappingItem.targetField.fieldName = value;
      if (checkIfNeedToSetAutoValue(sourceFieldName, value, sourceOptions)) {
        newFieldsMappingItem.sourceFieldName.fieldName = value;
      }
      break;
  }
  return newFieldsMappingItem;
};

export const createFieldsMappingItem = (
  stepId: string,
  sourceObjectId: string,
  targetObjectId: string,
) => {
  const newField: MapConnection.FieldsMapping = {
    keyForMerge: false,
    usedForGrouping: false,
    loaderStepId: stepId,
    sourceFieldName: {
      sourceObjectId,
      fieldId: '',
      fieldName: '',
    },
    targetField: {
      sourceObjectId: targetObjectId,
      fieldId: '',
      fieldName: '',
    },
  };
  return newField;
};

export const clearEmptyFieldsMappingItems = (fieldsMapping: any[]) => {
  return fieldsMapping.reduce((acc, item) => {
    if (item.targetField.fieldName || item.sourceFieldName.fieldName) {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const deleteSelectedOptionsFromList = (
  fieldsMapping: any[],
  selectOptions: SelectOptions,
  sourceObjectId: string,
  targetObjectId: string,
) => {
  const selectedSourceNames: any[] = [];
  const selectedTargetNames: any[] = [];
  const newFieldsMapping = [...fieldsMapping];

  newFieldsMapping.forEach((item) => {
    selectedSourceNames.push(item.sourceFieldName.fieldName);
    selectedTargetNames.push(item.targetField.fieldName);
  });

  const newSelectOptions = { ...selectOptions };

  newSelectOptions[sourceObjectId] = newSelectOptions[sourceObjectId].filter(
    (el) => !selectedSourceNames.includes(el),
  );
  newSelectOptions[targetObjectId] = newSelectOptions[targetObjectId].filter(
    (el) => !selectedTargetNames.includes(el),
  );
  return newSelectOptions;
};

export const getMetaFromSourceObjects = (
  sourceObjects: MapConnection.SourceObject[],
  sourceId: string,
  targetId: string,
) => {
  const sourceProcessedFields = sourceObjects.find(
    (table) => table.id === sourceId,
  )?.processedFields;
  const targetProcessedFields = sourceObjects.find(
    (table) => table.id === targetId,
  )?.processedFields;

  const sourceMeta = sourceProcessedFields?.reduce(
    (optionsMetaObj, option) => ({
      ...optionsMetaObj,
      [option.alias || option.displayedName]: { type: option.displayedType },
    }),
    {},
  );

  const targetMeta = targetProcessedFields?.reduce(
    (optionsMetaObj, option) => ({
      ...optionsMetaObj,
      [option.alias || option.displayedName]: { type: option.displayedType },
    }),
    {},
  );

  return { [sourceId]: sourceMeta, [targetId]: targetMeta };
};

export const getOptionsFromSourceObjects = (
  sourceObjects: MapConnection.SourceObject[],
  sourceObjectId: string,
  targetObjectId: string,
  operationType: string,
) => {
  const requiredTablesIds = [sourceObjectId, targetObjectId];

  return requiredTablesIds.reduce((selectOptionsObj, id) => {
    const currentTable = sourceObjects.find((table) => {
      return table.id === id;
    });

    let currentTableOptions;

    if (
      (currentTable as MapConnection.SourceObject).id === sourceObjectId ||
      ((operationType === Operation.Join || operationType === Operation.Union) &&
        (currentTable as MapConnection.SourceObject).id === targetObjectId)
    ) {
      const currentProcessedFields = currentTable
        ? currentTable.processedFields.filter(
          (processedField) => processedField.selected,
        )
        : [];
      currentTableOptions = currentProcessedFields.map((processedField) => {
        return processedField.alias || processedField.displayedName;
      });
    } else if (
      (currentTable as MapConnection.SourceObject).id === targetObjectId
    ) {
      const currentTableScheme = currentTable ? currentTable.schema : [];
      currentTableOptions = currentTableScheme.map((schema) => {
        return schema.name;
      });
    }

    return { [id]: currentTableOptions, ...selectOptionsObj };
  }, {});
};

export const getInitialFieldsMapping = (
  fieldsMapping: MapConnection.FieldsMapping[],
  sourceObjects: MapConnection.SourceObject[],
  sourceObjectId: string,
  operationType: string,
  targetId: string,
) => {
  let newFieldsMapping: MapConnection.FieldsMapping[] = JSON.parse(
    JSON.stringify(fieldsMapping),
  );

  if (operationType === Operation.Union) {
    newFieldsMapping = createUnionMappingToDisplay(
      fieldsMapping, sourceObjectId, targetId
    );
  }

  const currentTable = sourceObjects.find(
    (table) => table.id === sourceObjectId,
  );

  const currentProcessedFields = currentTable
    ? currentTable.processedFields
    : [];

  const currentTableOptions = (currentProcessedFields as MapConnection.ProcessedField[]).map(
    (option: MapConnection.ProcessedField) => option.alias || option.displayedName,
  );

  return newFieldsMapping.filter((fieldsMappingItem) =>
    currentTableOptions.includes(fieldsMappingItem.sourceFieldName.fieldName),
  );
};

export const getSchemaList = (
  operationType: string,
  sourceIds: string[],
  sourceObjects: MapConnection.SourceObject[],
  fieldsMapping: MapConnection.FieldsMapping[],
) => {
  let currentSourceIds = sourceIds;
  if (operationType === Operation.Union) {
    currentSourceIds = sourceIds.slice(0, 1);
  }
  let result = currentSourceIds.reduce(
    (schemaList: MapConnection.Schema[], sourceId) => {
      const sourceData = sourceObjects.find(
        (table) => table.id === sourceId,
      ) as MapConnection.SourceObject;

      const filteredProcessedFields = sourceData.processedFields.filter(
        (processedField) => processedField.selected,
      );

      const convertedSchema = filteredProcessedFields.map((processedField) => {
        const newSchema: any = {};
        newSchema.name = processedField.id;
        newSchema.type = processedField.displayedType;
        return newSchema;
      });

      return [...schemaList, ...convertedSchema];
    },
    [],
  );
  if (operationType === Operation.Union) {
    const namesAsIds = true;
    const resultFieldIds = getUnionResultDisplayedNames(sourceIds, fieldsMapping, sourceObjects, namesAsIds);
    result = result.filter((field: any) => resultFieldIds.includes(field.name));
  }
  return result;
};

const filterCalculatedFields = (processedFields: MapConnection.ProcessedField[]) => {
  const processedFieldsIds = processedFields.map((field) => {
    return field.id;
  });
  return processedFields.filter((field: MapConnection.ProcessedField) => {
    const { transformationOperands } = field;
    return transformationOperands.length > 0
      ? transformationOperands.every(id => processedFieldsIds.includes(id))
      : true;
  });
};

export const getProcessedFieldsList = (
  operationType: string,
  sourceIds: string[],
  sourceObjects: MapConnection.SourceObject[],
  fieldsMapping: MapConnection.FieldsMapping[],
  currentProcessedFieldsList?: MapConnection.ProcessedField[],
) => {
  let currentSourceIds = sourceIds;
  if (operationType === Operation.Union) {
    currentSourceIds = sourceIds.slice(0, 1);
  }

  let result = currentSourceIds.reduce(
    (processedFieldsList: MapConnection.ProcessedField[], sourceId, i) => {
      const sourceData = sourceObjects.find(
        (table) => table.id === sourceId,
      ) as MapConnection.SourceObject;

      let noSchemaAdditionalProperties: any[] | undefined = [];
      // Need to add only single time
      if (i === 0) {
        noSchemaAdditionalProperties = currentProcessedFieldsList?.filter(
          // No schema means that it is calculated fields on current source
          (processedField) => processedField.schemaName === '' || processedField.schemaName === null
        ) || [];
      }
      const filteredProcessedFields = [...(sourceData.processedFields.filter(
        (processedField) => processedField.selected,
      )), ...noSchemaAdditionalProperties];

      const convertedProcessedFields = filteredProcessedFields.map(
        (processedField) => {
          const existingField = currentProcessedFieldsList?.find((field: any) => {
            const processedFieldId = processedField.id;
            const matchToValue = field.schemaName;

            return (
              (matchToValue === processedFieldId) ||
              (noSchemaAdditionalProperties?.indexOf(processedField) !== -1 &&
                processedField.id === field.id)
            );
          });
          let newProcessedField;

          const displayedName = operationType === Operation.Union ? `${processedField.displayedName}`:
            `${sourceData.name}.${processedField.alias || processedField.displayedName}`;

          const schemaName = processedField.id;
          const alias = processedField.alias || processedField.displayedName;

          if (existingField) {
            newProcessedField = { ...existingField };

            if (noSchemaAdditionalProperties?.indexOf(existingField) === -1) {
              newProcessedField.displayedName = displayedName;
              if (!newProcessedField.alias) {
                newProcessedField.alias = alias;
              }
            }
          } else {
            newProcessedField = { ...processedField };
            newProcessedField.schemaName = schemaName;
            newProcessedField.alias = alias;
            newProcessedField.transformationTemplate = '';
            newProcessedField.transformationOperands = [];
            newProcessedField.displayedName = displayedName;
            newProcessedField.category = 'SCHEMA';
            newProcessedField.id = uuid();
          }
          return newProcessedField;
        },
      );
      return [...processedFieldsList, ...convertedProcessedFields];
    },
    [],
  );

  if (operationType === Operation.Union) {
    const resultFieldNames =
      getUnionResultDisplayedNames(sourceIds, fieldsMapping, sourceObjects);
    result = result.filter((field: any) => resultFieldNames.includes(
      field.displayedName) || (field.schemaName === '' || field.schemaName === null)
    );
  }
  result = filterCalculatedFields(result);
  return result;
};

export const getWhereCondition = (
  whereCondition: MapConnection.WhereCondition,
  processedFieldsList: MapConnection.ProcessedField[],
) => {
  let isSetToInitial: boolean = false;
  const initialWhereCondition: MapConnection.WhereCondition = { template: '', operands: [] };

  const processedFieldsIds = processedFieldsList.map((field) => {
    return field.id;
  });

  const { operands } = whereCondition;

  if (operands.length) {
    isSetToInitial = !operands.every(id => processedFieldsIds.includes(id));
  } else {
    isSetToInitial = false;
  }

  return isSetToInitial ? initialWhereCondition : whereCondition;
};

export const syncStepViaAlias = (
  fieldsMapping: MapConnection.FieldsMapping[],
  processedFieldsList: MapConnection.ProcessedField[],
  stepsIdsData: any,
  stepId: any,
) => {
  if (!fieldsMapping.length) return [];

  const newFieldsMapping: MapConnection.FieldsMapping[] = JSON.parse(
    JSON.stringify(fieldsMapping),
  );
  newFieldsMapping.map((fieldMapping, i) => {
    const stepIdsData: any = stepsIdsData[stepId];
    const newFieldMapping = { ...fieldMapping };

    const initialSourceName = newFieldMapping.sourceFieldName.fieldName;
    const initialTargetName = newFieldMapping.targetField.fieldName;

    const sourceNameProcessField = processedFieldsList.find(
      (processedField) => processedField.id === stepIdsData[i].source);

    const targetNameProcessField = processedFieldsList.find(
      (processedField) => processedField.id === stepIdsData[i].target);
    const sourceName: any = sourceNameProcessField?.alias ?
      sourceNameProcessField?.alias : sourceNameProcessField?.displayedName;
    const targetName: any = targetNameProcessField?.alias ?
      targetNameProcessField?.alias : targetNameProcessField?.displayedName;

    newFieldMapping.sourceFieldName.fieldName = sourceName || initialSourceName;
    newFieldMapping.targetField.fieldName = targetName || initialTargetName;

    return newFieldMapping;
  });
  return newFieldsMapping;
};

export const updateProcessedFieldsViaValidation = (
  processedFieldsList: MapConnection.ProcessedField[],
  schemaList: MapConnection.Schema[],
) => {
  let newProcessedFieldsList: MapConnection.ProcessedField[] = JSON.parse(
    JSON.stringify(processedFieldsList),
  );
  newProcessedFieldsList = newProcessedFieldsList.map((field) => {
    const newField = { ...field };
    const currentFieldInSchema = schemaList.find((schema) => {
      return newField.alias === schema.name || newField.displayedName === schema.name;
    });
    if (currentFieldInSchema) {
      newField.cachedType = currentFieldInSchema.type;
    }
    return newField;
  });
  return newProcessedFieldsList;
};