import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionFilesList } from '../types';

const initialState: ConnectionFilesList = {
  isSaving: false,
};

const connectionFilesListSlice = createSlice({
  name: 'connectionFilesList',
  initialState,
  reducers: {
    toggleSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.isSaving = payload;
    },
  },
});

export const connectionFilesListReducer = connectionFilesListSlice.reducer;

export const {
  toggleSaving: toggleSavingAction,
} = connectionFilesListSlice.actions;
