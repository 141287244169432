import { EditableParameter } from './types';

export const compareParameterValues = (
  projectParameters: EditableParameter[],
  editableParameters: EditableParameter[],
): number[] => {
  const differingIds: number[] = [];

  for (const item1 of projectParameters) {
    const item2 = editableParameters.find(
      (element) => element.parameterId === item1.parameterId,
    );

    if (item2 && item1.value !== item2.value) {
      differingIds.push(item1.parameterId);
    }
  }

  return differingIds;
};
