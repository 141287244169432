import { useSelector } from 'react-redux';
import { hierarchy, stratify } from '@visx/hierarchy';
import { scaleLinear } from '@visx/scale';
import { HierarchyNode } from '@visx/hierarchy/lib/types';
import { State, Widget, WidgetProperties } from '../../../../slices/types';
import {
  getAxisValues,
  getParsedAxisValues,
} from '../../dropdown-layout/helpers/helpers';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { TreeMapDataObject } from './types';
import {
  getBooleanPropertyValue,
  getJSONPropertyValue,
  getSelectPropertyValue,
  sortColorPalette,
} from '../helpers';
import { defaultColors } from '../common/color';
import { getActivePanelItems } from '../../../../helpers/common-helpers';
import { PropertyData } from '../../dropdown-layout/helpers/Property';


enum TypeColor {
  Multicolor = 'Многоцветная',
  Gradient = 'Градиент',
}

export const useWidgetProperties = (widgetProperties: WidgetProperties[]) => {
  const axisXValuesFull: PropertyData[] = getActivePanelItems(
    widgetProperties,
    PanelType.axisX,
  );

  const axisYValuesFull: Array<any> = getParsedAxisValues(
    PanelType.axisY,
    widgetProperties,
  );

  const typeColor = getSelectPropertyValue('typeFunnelColor', widgetProperties);

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );

  const displayValueOnDiagram: boolean = getBooleanPropertyValue(
    'displayValueOnDiagram',
    widgetProperties,
  );

  const displayPercentOnSector: boolean = getBooleanPropertyValue(
    'displayPercentOnSector',
    widgetProperties,
  );

  const displayTextOnDiagram: boolean = getBooleanPropertyValue(
    'displayTextOnDiagram',
    widgetProperties,
  );

  const dataSlice = getJSONPropertyValue(
    'dataSlice',
    widgetProperties,
  );

  const dateDisplayFormat = getAxisValues(
    WidgetPropertyType.dateDisplayFormat,
    widgetProperties,
  );

  return {
    axisXValuesFull,
    axisYValuesFull,
    typeColor,
    colorsPaletteState,
    displayValueOnDiagram,
    displayPercentOnSector,
    displayTextOnDiagram,
    dataSlice,
    dateDisplayFormat,
  };
};

export const useWidgetData = (widgetProps: Widget) => {

  const displayOther: boolean = getBooleanPropertyValue(
    'displayOther',
    widgetProps.properties,
  );

  const previewData: TreeMapDataObject[] = displayOther ? widgetProps?.data?.items : widgetProps?.data?.items?.filter((item: TreeMapDataObject) => item.id !== 'Прочее');
  const data = stratify<TreeMapDataObject>()
    .id((d) => d.id)
    .parentId((d) => d.parent)(previewData)
    .sum((d) => Math.abs(d.size!) || 0);

  const root = hierarchy(data).sort(
    (a, b) => (b.value || 0) - (a.value || 0),
  );
  const isSliced = Boolean(root.height === 2);

  return {
    data,
    root,
    isSliced,
  };
};

type UseWidgetColorsProps = {
  widgetProperties: WidgetProperties[],
  root: HierarchyNode<HierarchyNode<TreeMapDataObject>>,
  isSliced: boolean,
};

export const useWidgetColors = ({ widgetProperties, root, isSliced }: UseWidgetColorsProps) => {

  const typeColor = getSelectPropertyValue('typeFunnelColor', widgetProperties);

  const colorsPaletteState = getJSONPropertyValue(
    'colorPalette',
    widgetProperties,
  );

  const currentColors =
    sortColorPalette(
      colorsPaletteState?.firstColor,
      colorsPaletteState?.colorsList,
    ) || defaultColors;
  const getPaletteColor = (i: number) => {
    return currentColors[i % currentColors.length];
  };

  const getCellColor = (
    sliceIndex: number,
    cellIndex: number,
    value: number,
    isSelected: boolean,
  ) => {
    return typeColor === TypeColor.Gradient
      ? isSelected
        ? getPaletteColor(sliceIndex)
        : colorScales[sliceIndex](cellIndex)
      : getPaletteColor(isSliced ? sliceIndex : cellIndex);
  };

  const colorScales = (isSliced ? root.children || [] : [root]).map(
    (slice, index: number) => {
      const maxIndex = (slice.children || [])?.length;

      const secondColor = '#ffffff';
      const coefficient = 2;
      // домен расширен по верхней границе в coefficient раз
      // чтобы сдвинуть цветовой диапазон в сторону основного цвета
      return scaleLinear<string>({
        domain: [0, maxIndex * coefficient],
        range: [getPaletteColor(index), secondColor],
      });
    },
  );

  return {
    getCellColor,
  };
};
