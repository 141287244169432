import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { deleteDashboardById } from '../../../../services/dashboardController';
import { deleteWidgetById } from '../../../../services/widgetController';
import {
  setCurrentGroupWidgetsAction,
  setCurrentProjectDashboardsAction,
  setCurrentProjectWidgetsAction, setDashboardChangedNotificationDisabledAction,
} from '../../../../slices/main-page/main-page-slice';
import { Dashboard, DashboardResponse, State, Widget } from '../../../../slices/types';
import { PageParams } from '../../../../types/meta';
import DeleteDialog from '../../../../uikit/DeleteDialog/delete-dialog';
import {DashboardDictionary, WidgetDictionary} from '../../../../dictionaries/naming-dictionary/naming-dictionary';

interface ModalDeleteProps {
  isOpen: boolean;
  handleModalClose: (success?: boolean) => void;
  type: string | undefined;
  name: string;
  id: number | undefined;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
  isOpen,
  handleModalClose,
  name,
  type,
  id,
}) => {
  const dispatch = useDispatch();
  const params: PageParams = useParams();
  const history = useHistory();
  const [error, setError] = useState('');
  const widgetsList: Widget[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectWidgets ?? null,
  );
  const currentGroupWidgets: Widget[] | null = useSelector(
    (state: State) => state.mainPage?.currentGroupWidgets ?? null,
  );

  const dashboardsList: DashboardResponse[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectDashboards ?? null,
  );

  const handleClose = (status: boolean) => {
    setError('');
    handleModalClose(status);
  };

  const onDeleteWidgetClick = useCallback(() => {
    if (type === 'widget') {
      deleteWidgetById(id)
        .then(() => {
          const widgets = [...(widgetsList || [])];
          const newWidgets = widgets.filter((item) => item.id !== id);
          dispatch(setCurrentProjectWidgetsAction(newWidgets));

          const groupWidgets = [...(currentGroupWidgets || [])];
          const newGroupWidgets = groupWidgets.filter((item) => item.id !== id);
          dispatch(setCurrentGroupWidgetsAction(newGroupWidgets));

          if (parseInt(params.widgetId as string) === id) {
            history.push(`/project/${params.projectId}`);
          }
          handleClose(true);
        })
        .catch((error) => {
          setError(error.response.data.message);
        });
    }

    if (type === 'dashboard') {
      dispatch(setDashboardChangedNotificationDisabledAction(true));
      deleteDashboardById(id).then(() => {
        const dashboards = [...(dashboardsList || [])];

        const newDashboards = dashboards.filter((item) => item.id !== id);
        dispatch(setCurrentProjectDashboardsAction(newDashboards));
        if (parseInt(params.widgetId as string) === id) {
          history.push(`/project/${params.projectId}`);
        }
        handleClose(true);
      });
    }
  }, [
    type,
    id,
    widgetsList,
    dispatch,
    currentGroupWidgets,
    params.widgetId,
    params.projectId,
    handleClose,
    history,
    dashboardsList
  ]);

  const onConfirmDeleteClick = useCallback(() => {
    if (type === 'widget') {
      deleteWidgetById(id, true).then(() => {
        const widgets = widgetsList ? [...widgetsList] : [];
        const newWidgets = widgets.filter((item) => item.id !== id);
        dispatch(setCurrentProjectWidgetsAction(newWidgets));
        if (parseInt(params.widgetId as string) === id) {
          history.push(`/project/${params.projectId}`);
        }
        handleClose(true);
      });
    }

    if (type === 'dashboard') {
      dispatch(setDashboardChangedNotificationDisabledAction(true));
      deleteDashboardById(id).then(() => {
        const dashboards = dashboardsList ? [...dashboardsList] : [];

        const newDashboards = dashboards.filter((item) => item.id !== id);
        dispatch(setCurrentProjectDashboardsAction(newDashboards));
        if (parseInt(params.widgetId as string) === id) {
          history.push(`/project/${params.projectId}`);
        }
        handleClose(true);
      }).finally(() => {
        dispatch(setDashboardChangedNotificationDisabledAction(false));
      });
    }
  }, [type, id, widgetsList, dispatch, params.widgetId, params.projectId, handleClose, history, dashboardsList]);

  const handleClearError = useCallback(() => {
    setError('');
  }, []);

  const isDeletingInProgress = useSelector(
    (state: State) => state.mainPage.isDeleting,
  );

  return (
    <>
      <DeleteDialog
        isOpen={!!error}
        error={error}
        onClose={handleClearError}
        onDelete={onConfirmDeleteClick}
        title={`Удаление ${type === 'widget' ? WidgetDictionary.few : DashboardDictionary.few}`}
        bodyText={`${type === 'widget' ? WidgetDictionary.one : DashboardDictionary.one}`}
        maxWidth="xs"
        isLoading={isDeletingInProgress}
      />

      <DeleteDialog
        isOpen={isOpen && !error}
        onClose={() => handleClose(false)}
        onDelete={onDeleteWidgetClick}
        title={`Удаление ${type === 'widget' ? WidgetDictionary.few : DashboardDictionary.few}`}
        bodyText={`${type === 'widget' ? WidgetDictionary.one : DashboardDictionary.one}`}
        maxWidth="xs"
        isLoading={isDeletingInProgress}
      />
    </>
  );
};

export default ModalDelete;
