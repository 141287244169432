import { cloneDeep } from 'lodash';
import { WidgetProperties } from 'src/slices/types';
import { WidgetSimplifiedDataType } from '../../../../enums/data-type';
import { PanelType, WidgetPropertyType, WidgetType } from '../../../../enums/widget-type';
import { isAxisWithBookmarks } from '../../../../helpers/common-helpers';
import { parseJSON } from '../../../../utils/functions';
import { ShowNotificationParams } from '../../../common/snackbar/hooks';
import { getTableHeader } from '../../charts/complex-table/helper';
import { widgetsWithColoringOptions, } from '../../dropdown-panel/panel-item/panel-item-popover/usePanelItemPopover';
import {
  getAxisValues,
  getDefaultOrderDirection,
  getDefaultProperties,
  getDefaultPropertiesForCalculated,
  getNewPropertiesWithCorrectColoring,
  getNewPropertyValue,
  getPanelIndex,
  getSimplifiedType,
  isCalculatedMeasure, isFieldInPivotPanels,
  typeGuards,
} from './helpers';
import { PropertyData } from './Property';


const MAX_ITEMS = 100;

export const getPropertiesWithTableHeader = (properties: WidgetProperties[]) => {
  const propertiesCopy = [...properties];
  const axisIndex = getPanelIndex({ properties, type: PanelType.axisX });
  const axisXValues = parseJSON(properties[axisIndex].value) as PropertyData[];
  const tableHeaderValue = getTableHeader(axisXValues);
  const tableHeaderIndex = propertiesCopy.findIndex(
    (el: WidgetProperties) => el.name === WidgetPropertyType.tableHeader,
  );

  if (tableHeaderIndex > -1) {
    propertiesCopy[tableHeaderIndex] = {
      name: WidgetPropertyType.tableHeader,
      value: JSON.stringify(tableHeaderValue),
    };
  } else {
    propertiesCopy.push({
      name: WidgetPropertyType.tableHeader,
      value: JSON.stringify(tableHeaderValue),
    });
  }
  return propertiesCopy;
};


const correctFieldProp = ({
  properties,
  panelType,
  isFieldNeed,
  propName,
  getDefaultValue,
}: {
  properties: WidgetProperties[],
  panelType: PanelType | WidgetPropertyType,
  isFieldNeed: boolean,
  propName: keyof PropertyData,
  getDefaultValue: (propertyType: string) => string | boolean,
}) => {
  const propertiesCopy = cloneDeep(properties);

  const axisIndex = getPanelIndex({ properties, type: panelType });

  if (isFieldNeed) {
    if (axisIndex > 1) {

      const axisXValues = parseJSON(properties[axisIndex].value) as PropertyData[] | undefined;

      if (axisXValues) {
        const correctedValues = axisXValues.map((axisValue) => {
          const { [propName]: propValue } = axisValue;
          return {
            ...axisValue,
            [propName]: propValue === undefined || propValue === null ? getDefaultValue(axisValue.type) : propValue,
          };
        });

        propertiesCopy[axisIndex] = {
          name: panelType,
          value: JSON.stringify(correctedValues),
        };
      }
    }
  } else if (axisIndex > 1) {
    const axisXValues = parseJSON(properties[axisIndex].value) as PropertyData[] | undefined;

    if (axisXValues) {
      const correctedValues = axisXValues.map((value) => {
        const { [propName]: omittedProp, ...restProps } = value;
        return restProps;
      });

      propertiesCopy[axisIndex] = {
        name: panelType,
        value: JSON.stringify(correctedValues),
      };
    }
  }
  return propertiesCopy;
};

export const repairTableProperties = (
  widgetType: WidgetType,
  properties: WidgetProperties[],
) => {

  const isFieldNeed = widgetType === WidgetType.TABLE;

  const correctedProperties = correctFieldProp({
    properties,
    panelType: PanelType.axisX,
    isFieldNeed,
    propName: 'isDrillDownFilter',
    getDefaultValue: (propertyType) => getSimplifiedType(propertyType) !== WidgetSimplifiedDataType.NUMBER,
  });


  if (isFieldNeed) {
    return getPropertiesWithTableHeader(correctedProperties);
  }

  return correctedProperties.filter((property) => property.name !== WidgetPropertyType.tableHeader);
};

export const repairRestrictionFilterProperty = (
  widgetType: WidgetType,
  properties: WidgetProperties[],
) => {
  return correctFieldProp({
    properties,
    panelType: PanelType.axisFilter,
    // 2 из 2: отключено в задаче BIDEV-7059, если все ок, удалить комментарии из коммита
    isFieldNeed: true, // widgetsWithFilterRestriction.includes(widgetType),
    propName: 'isRestrictionFilter',
    getDefaultValue: (propertyType) => getSimplifiedType(propertyType) === WidgetSimplifiedDataType.DATE,
  });
};

const PIVOT_TABLE_AXIS_Z_LIMIT = 2;

const repairPivotAxisZ = (
  properties: WidgetProperties[],
) => {

  const axisIndex = getPanelIndex({ properties, type: PanelType.axisZ });

  if (axisIndex > -1) {
    const axisZValues = parseJSON(properties[axisIndex].value) as PropertyData[];
    if (axisZValues?.filter((value) => value.isActive)?.length > 2) {

      const axisZActiveIndexes = axisZValues
        .map((value, index) => value.isActive ? index : null)
        .filter(Boolean)
        .slice(-PIVOT_TABLE_AXIS_Z_LIMIT);

      const axisZValuesRepaired = axisZValues
        .map((value, index) => ({
          ...value,
          isActive: axisZActiveIndexes.includes(index)
        }));

      const copyProperties = [...properties];
      copyProperties[axisIndex].value = JSON.stringify(axisZValuesRepaired);
      return copyProperties;
    }
  }

  return properties;
};

export const axisAdditionBreakeRules: {
  name: string;
  checkRule: ({
    widgetType,
    panelType,
    field,
    fields,
    isMeasure,
  }: {
    widgetType: WidgetType;
    panelType: PanelType;
    field: PropertyData;
    fields: PropertyData[];
    isMeasure?: boolean;
  }) => boolean;
  isOnlyForNewField?: boolean;
  notificationDescription?: ShowNotificationParams;
}[] = [
  {
    name: 'Проверка типа в Графике с наложением',
    checkRule: ({ widgetType, panelType, field }) => {
      return (
        widgetType === WidgetType.GRAPH_AREA &&
        panelType === PanelType.axisX &&
        !(
          getSimplifiedType(field.type) === WidgetSimplifiedDataType.DATE ||
          getSimplifiedType(field.type) === WidgetSimplifiedDataType.NUMBER
        )
      );
    },
    notificationDescription: {
      message: 'Можно добавить только поле типа Дата или Число',
      variant: 'warning',
    },
  },
  // Workaround: BIDEV-3549 - для сводных таблиц нельзя переносить вычисляемые показатели c LEAD/LAG
  {
    name: 'Вычисляемое измерение с LEAD/LAG нельзя добавить в сводной',
    checkRule: ({ widgetType, field }) => {
      return Boolean(
        widgetType === WidgetType.PIVOT_TABLE && field.isLagOrLead,
      );
    },
    notificationDescription: {
      message: 'Вычисляемое измерение с LEAD/LAG нельзя добавить',
      variant: 'warning',
    },
  },
  {
    name: 'Проверка в диаграмме Гантта',
    checkRule: ({ widgetType, panelType, field }) => {
      return !typeGuards({
        widgetType,
        axisType: panelType,
        property: field,
      });
    },
  },
  {
    name: 'На оси фильтров нельзя добавить поле с таким же названием которое уже есть',
    checkRule: ({ panelType, fields, field }) => {
      return Boolean(panelType === PanelType.axisFilter && fields &&
        fields.find(
          (filerItem: any) => filerItem.name === field.name,
        ));
    },
    isOnlyForNewField: true,
  },
  {
    name: 'Достигнуто максимальное количество полей',
    checkRule: ({ fields }) => {
      return Boolean(
        fields.length >= MAX_ITEMS,
      );
    },
    notificationDescription: {
      message: 'Достигнуто максимальное количество полей',
      variant: 'warning',
    },
  },
];

export const isSingleProperty = (
  widgetType: WidgetType,
  panelType: PanelType,
) => {

  const singleProperties = [
    PanelType.axisX,
    PanelType.dataSlice,
    PanelType.dateEndStage,
    PanelType.progress,
    PanelType.nameStage,
  ];

  if (
    [
      WidgetType.GANTT_CHART,
      WidgetType.INFORMATION_CARD,
      WidgetType.FUNNEL,
      WidgetType.SUNBURST,
      WidgetType.TREE_MAP,
      WidgetType.DONUT_CHART,
      WidgetType.PIE_CHART,
      WidgetType.SPEEDOMETER,
    ].includes(widgetType)
  ) {
    singleProperties.push(PanelType.axisY);
  }

  if (
    [
      WidgetType.PIE_CHART,
      WidgetType.BUBBLE_CHART,
      WidgetType.INFORMATION_CARD,
      WidgetType.SPEEDOMETER,
    ].includes(widgetType)
  ) {
    singleProperties.push(PanelType.axisZ);
  }

  if ([WidgetType.PIVOT_TABLE].includes(widgetType)) {
    singleProperties.shift();
  }

  if ([WidgetType.SUNBURST].includes(widgetType)) {
    singleProperties.shift();
  }

  return !(isAxisWithBookmarks(widgetType, panelType)) &&
    singleProperties.includes(panelType) &&
    widgetType !== WidgetType.TABLE &&
    widgetType !== WidgetType.MAP;
};

export const getPropertiesForItemSet = ({
  panelType,
  property,
  properties,
  widget,
  etlSourceId,
  showNotification,
}: any) => {
  let propertiesCopy = [...properties];
  const panelIndex = getPanelIndex({ properties, type: panelType });

  const currentValue = getAxisValues(panelType, properties) || '[]';

  const propertyInstance = isCalculatedMeasure(property)
    ? getDefaultPropertiesForCalculated(property, panelType, widget)
    : getDefaultProperties(property, panelType, widget, etlSourceId);

  const newPropertyValue = propertyInstance.isCalculated()
    ? propertyInstance.getCalculatedValue()
    : propertyInstance.getValue();

  const orderDirection = getDefaultOrderDirection(widget.type, panelType);

  if (orderDirection) {
    (newPropertyValue as any).orderDirection = orderDirection;
  }

  if (
    widget.type === WidgetType.PIVOT_TABLE &&
    panelType !== PanelType.axisFilter &&
    isFieldInPivotPanels(properties, newPropertyValue.etlFieldId, panelType)
  ) {
    (newPropertyValue as any).isActive = false;
  }

  const newValue = getNewPropertyValue({
    currentValue,
    value: newPropertyValue,
    isSingle: isSingleProperty(widget.type, panelType),
  });

  const activeLegendIndex = getPanelIndex({
    properties,
    type: WidgetPropertyType.activeLegend,
  });

  for (const axisRuleIndex in axisAdditionBreakeRules) {
    if (axisAdditionBreakeRules[axisRuleIndex].checkRule({
      widgetType: widget.type,
      panelType,
      field: newPropertyValue,
      fields: JSON.parse(currentValue),
      isMeasure: property.measure,
    })) {
      const notification = axisAdditionBreakeRules[axisRuleIndex].notificationDescription;
      notification && showNotification(notification);
      return propertiesCopy;
    }
  }

  if (panelType === PanelType.axisY && activeLegendIndex !== -1) {
    propertiesCopy.splice(activeLegendIndex, 1);
  }

  if (
    widgetsWithColoringOptions.includes(widget.type) &&
    isSingleProperty(widget.type, panelType) &&
    JSON.parse(currentValue).length
  ) {
    propertiesCopy = getNewPropertiesWithCorrectColoring(propertiesCopy, JSON.parse(currentValue)[0]);
  }

  if (panelIndex > -1) {
    propertiesCopy[panelIndex] = {
      name: panelType,
      value: newValue,
    };
  } else {
    propertiesCopy.push({
      name: panelType,
      value: newValue,
    });
  }

  propertiesCopy = repairTableProperties(widget.type, propertiesCopy);
  propertiesCopy = repairRestrictionFilterProperty(widget.type, propertiesCopy);
  if (widget.type === WidgetType.PIVOT_TABLE && panelType === PanelType.axisZ) {
    propertiesCopy = repairPivotAxisZ(propertiesCopy);
  }

  return propertiesCopy;
};
