import { AxiosResponse } from 'axios';
import Keycloak from 'keycloak-js';
import {
  apiAuthenticator,
  apiBI,
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
  SHAREABLE_TOKEN,
  SHAREABLE_HEADER,
} from '../utils/api';
import { deleteCookie, setCookie } from '../utils/cookie';
import { sendLogout } from './index';


export const COOKIE_TOKEN_KEYCLOAK = 'tokenKeycloak';
export const COOKIE_TOKEN_REFRESH_KEYCLOAK = 'tokenRefreshKeycloak';

enum LoginProfiles {
  keycloak = 'keycloak',
  lazy = 'lazy',
  default = 'default',
}

const keycloak = new Keycloak( {
  url: process.env.REACT_APP_KEYCLOAK_URL || '',
  realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || '',
});

const initKeycloak = (callback: Function) => {
  if (!process.env.REACT_APP_KEYCLOAK_URL)
    console.error('Env REACT_APP_KEYCLOAK_URL is not defined');

  keycloak
    .init({})
    .then(() => {
      const token = getToken();
      const refreshToken = getRefreshToken();

      if (!token) {
        doLogin();
      } else {

        if (token) setCookie(COOKIE_TOKEN_KEYCLOAK, token);
        if (refreshToken) setCookie(COOKIE_TOKEN_REFRESH_KEYCLOAK, refreshToken);

        authenticate()
          .then((response) => {
            callback();
          })
          .catch((error) => {
            console.error('Ошибка авторизации');
          });
      }

    });
};

const handleAuthenticateResponse = (response: AxiosResponse<any> | undefined, callback: Function) => {
  if (response?.headers?.profile === LoginProfiles.keycloak) {
    initKeycloak(callback);
  } else {
    const redirectHref = response?.headers['x-redirect'];
    if (redirectHref) {
      window.location.href = redirectHref;
    } else {
      callback();
    }
  }
};

const init = (callback: Function) => {

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const shareableToken = params.get(SHAREABLE_TOKEN);

  if (shareableToken) {
    apiAuthenticator.defaults.headers.common[SHAREABLE_HEADER] = shareableToken;
    apiBI.defaults.headers.common[SHAREABLE_HEADER] = shareableToken;
    callback();
  } else {
    authenticate()
      .then((response) => {
        handleAuthenticateResponse(response, callback);
      })
      .catch((error) => {
        handleAuthenticateResponse(error?.response, callback);
      });
  }
};

const doLogin = keycloak.login;

const doLogout = () => {
  if (isLoggedIn()) {
    deleteCookie(COOKIE_ACCESS_TOKEN);
    deleteCookie(COOKIE_REFRESH_TOKEN);
    deleteCookie(COOKIE_TOKEN_KEYCLOAK);
    deleteCookie(COOKIE_TOKEN_REFRESH_KEYCLOAK);
    keycloak.updateToken(5).then(() => keycloak.logout());
  } else {
    sendLogout().then((result) => {
      window.location.href = result.data;
    });
  }
};

const getToken = () => keycloak.token;
const getRefreshToken = () => keycloak.refreshToken;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback: any) =>
  keycloak.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

export const apiAuthenticate = async () => {
  try {
    const response = await apiAuthenticator.get('auth/authenticate');
    if (response.status === 200) {
      return response;
    }
    console.error('Not authenticated BI!!!');
  } catch (error) {
    console.error(`Request failed!: ${error}`);
    throw error;
  }
};

const authenticate = () => {
  return apiAuthenticate();
};


const AuthService = {
  init,
  doLogin,
  doLogout,
  getToken,
  getRefreshToken,
  isLoggedIn,
  updateToken,
  authenticate,
};

export default AuthService;
