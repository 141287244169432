export const styles: any = {
  container: ({ isChildren }: any) => ({
    backgroundColor: isChildren ? 'rgba(218, 231, 237, 1)' : 'rgba(237, 241, 248, 1)',
    borderRadius: 'var(--base-border-radius)',
    padding: 'calc(16px * var(--scale-coefficient))',
    width: '100%',
  }),
  title: {
    color: 'rgba(68, 68, 68, 1)',
    fontWeight: 700,
    fontSize: 'calc(14px * var(--scale-coefficient))',
    lineHeight: 'calc(16px * var(--scale-coefficient))',
    marginBottom: 'calc(20px * var(--scale-coefficient))',
    flex: 1,
  },
  actions: {
    display: 'flex',
    alignItems: 'baseline',
  },
  header: {
    display: 'flex',
  },
  actionsButton: {
    marginLeft: 'calc(10px * var(--scale-coefficient))',
  },
  actionsButtonIcon: {
    fontSize: 'calc(18px * var(--scale-coefficient))',
    fill: 'rgba(146, 155, 172, 1)',
  }
};
