import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { PanelType } from '../../../../enums/widget-type';
import { State } from '../../../../slices/types';
import { Property } from '../../dropdown-layout/helpers/Property';
import { getActivePanelItems } from '../../../../helpers/common-helpers';

export function useChartFilters<T>({
  widgetProps,
  setFilterField,
  filterField,
  isActiveFilter,
}: any) {
  const enableEvents: boolean = isActiveFilter;

  const widgetStoreProperties = useSelector(
    (state: State) => state.widget.properties,
  );
  const widgetProperties = widgetProps?.properties || widgetStoreProperties;
  const axisXValuesFull: Array<any> = getActivePanelItems(
    widgetProperties,
    PanelType.axisX,
  );

  const deselectFilter = useCallback(() => {
    setFilterField && setFilterField(widgetProps.id, null, []);
  }, [setFilterField, widgetProps.id]);

  const selectFilter = useCallback(
    (label: string) => {
      if (!enableEvents) return;
      if (filterField?.value?.includes(label)) {
        deselectFilter();
      } else {
        if (!setFilterField || !label) return;

        setFilterField(
          widgetProps.id,
          new Property(axisXValuesFull[0]).getId(),
          [
            {
              operation: '=',
              value: [label],
            },
          ],
        );
      }
    },
    [
      axisXValuesFull,
      deselectFilter,
      enableEvents,
      filterField,
      setFilterField,
      widgetProps.id,
    ],
  );

  return {
    selectFilter,
    deselectFilter,
    enableEvents,
  };
}
