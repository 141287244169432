import { scaleOrdinal } from '@visx/scale';
import { LegendProps } from './legend';
import {
  getBooleanPropertyValue,
  getLegendsLabelsForWidgetByAxis,
} from '../helpers';
import {
  PanelType,
  WidgetPropertyType,
  WidgetType,
} from '../../../../enums/widget-type';
import { useWidgetActiveLegend } from './useWidgetActiveLegend';

// TODO GRAPH_AREA нужно будет заменить после изменения типа нового графика
const widgetsWithAxisZLegend = [WidgetType.HISTOGRAM_GRAPH, WidgetType.GRAPH_AREA];

export const useLegend = ({
  currentColors,
  legendSortingProps,
  widgetProperties,
  widgetType,
  widgetId,
  isDisabledActivity,
}: LegendProps) => {
  const isNeedDisplayAggregationInLegend = getBooleanPropertyValue(
    WidgetPropertyType.displayAggregationInLegend,
    widgetProperties,
  );

  let legendsLabels = getLegendsLabelsForWidgetByAxis(
    PanelType.axisY,
    widgetProperties,
    isNeedDisplayAggregationInLegend,
    true,
  );

  if (widgetsWithAxisZLegend.includes(widgetType)) {
    legendsLabels = legendsLabels.concat(
      getLegendsLabelsForWidgetByAxis(
        PanelType.axisZ,
        widgetProperties,
        isNeedDisplayAggregationInLegend,
        true,
      ),
    );
  }

  const isSortedDataLoading = legendSortingProps?.isSortedDataLoading;
  const currentOrderIndexColumn = legendSortingProps?.currentOrderIndexColumn;
  const getOrderIconByIndex = legendSortingProps?.getOrderIconByIndex;
  const changeOrderingHandler = legendSortingProps?.changeOrderingHandler;

  const ordinalColorScale = scaleOrdinal({
    domain: legendsLabels.map((value: string, index: number) => String(index)),
    range: currentColors.slice(0, legendsLabels.length),
  });

  const {
    isWidgetWithActiveLegend,
    onChangeActiveLegend,
    isActiveLegend
  } = useWidgetActiveLegend({
    widgetsWithAxisZLegend,
    widgetType,
    widgetId,
    widgetProperties,
    isDisabledActivity
  });

  return {
    ordinalColorScale,
    isSortedDataLoading,
    changeOrderingHandler,
    currentOrderIndexColumn,
    getOrderIconByIndex,
    isWidgetWithActiveLegend,
    onChangeActiveLegend,
    isActiveLegend,
    legendsLabels,
  };
};
