import React, { useMemo, useState } from 'react';
import './column-view-options.css';
import { RadioGroup } from '@material-ui/core';
import { WidgetType } from '../../../../../enums/widget-type';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import { getSimplifiedType } from '../../../dropdown-layout/helpers/helpers';
import { ColorPicker } from '../../../settings/color-picker';
import { DEFAULT_COMPLEX_TABLE_CELL, DisplayColumnZeros } from '../../../charts/complex-table/ui/cells/custom';
import { CellVariant } from '../../../charts/complex-table/ui/table-cell';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { CustomRadio } from '../../../../../uikit/Radio/Radio';
import { CustomCheckbox } from '../../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../../uikit/FormControl';
import { CustomButton } from '../../../../../uikit/Button';
import { NumberFormat } from '../../../../../enums/number-format';
import { TextInput } from '../../../../common/special-inputs/inputs/text-input';

type ColumnViewOptionsDialogProps = {
  panelData: PropertyData;
  changeViewOptions: (storage: any) => void;
  closeDialog: () => void;
  widgetType: WidgetType;
};

export const ColumnViewOptionsDialog = ({
  panelData,
  changeViewOptions,
  closeDialog,
  widgetType,
}: ColumnViewOptionsDialogProps) => {

  const isTable = widgetType === WidgetType.TABLE;

  const storage = useMemo(
    () => JSON.parse(panelData.storage ?? '{}'),
    [panelData.storage],
  );

  const [cellVariant, setCellVariant] = useState<CellVariant>(
    storage?.cellVariant || DEFAULT_COMPLEX_TABLE_CELL.cellVariant,
  );
  const [titleBackgroundColor, setTitleBackgroundColor] = useState<string>(
    storage?.titleBackgroundColor || '',
  );
  const [titleTextColor, setTitleTextColor] = useState<string>(
    storage?.titleTextColor || '',
  );
  const [cellBackgroundColor, setCellBackgroundColor] = useState<string>(
    storage?.cellBackgroundColor || '',
  );
  const [cellTextColor, setCellTextColor] = useState<string>(
    storage?.cellTextColor || '',
  );
  const [textBold, setTextBold] = useState<boolean>(storage?.textBold || false);
  const [cellJustifyContent, setCellJustifyContent] = useState<
  'start' | 'center' | 'end'
  >(storage?.cellJustifyContent || DEFAULT_COMPLEX_TABLE_CELL.justifyContent);
  const [displayColumnZeros, setDisplayColumnZeros] =
    useState<DisplayColumnZeros>(
      storage?.displayColumnZeros || DEFAULT_COMPLEX_TABLE_CELL.displayColumnZeros
    );
  const [progressColor, setProgressColor] = useState<string>(
    storage?.progressColor || DEFAULT_COMPLEX_TABLE_CELL.progressColor,
  );
  const [numberFormat, setNumberFormat] = useState<NumberFormat>(
    storage?.numberFormat || NumberFormat.no,
  );
  const [displayValueText, setDisplayValueText] = useState<string>(
    storage?.displayValueText || '',
  );

  const [width, setWidth] = useState<string>(
    storage?.width || undefined,
  );

  const handleChangeDisplayValueText = (e: any) => setDisplayValueText(e.target.value);
  const handleChangeWidth = (e: any) => setWidth(e.target.value);
  const handleSelectTypeChange = (e: any) => setCellVariant(e.target.value);
  const handleSelectNumberFormatChange = (e: any) => setNumberFormat(e.target.value);
  const handleSelectJustifyContentChange = (e: any) =>
    setCellJustifyContent(e.target.value);
  const handleSelectDisplayColumnZerosChange = (e: any) =>
    setDisplayColumnZeros(e.target.value);

  const onChangeTitleBackgroundColor = (newColor: string) =>
    setTitleBackgroundColor(newColor);
  const onChangeTitleTextColor = (newColor: string) =>
    setTitleTextColor(newColor);

  const onChangeCellBackgroundColor = (newColor: string) =>
    setCellBackgroundColor(newColor);
  const onChangeCellTextColor = (newColor: string) =>
    setCellTextColor(newColor);
  const handleTextColor = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTextBold(e.target.checked);
  const onChangeProgressColor = (newColor: string) =>
    setProgressColor(newColor);

  const handleSave = () => {
    const newStorage = {
      ...storage,
      cellVariant,
      progressColor,
      cellBackgroundColor,
      cellTextColor,
      textBold,
      cellJustifyContent,
      displayColumnZeros,
      titleBackgroundColor,
      titleTextColor,
      numberFormat,
      displayValueText,
      width,
    };
    changeViewOptions(JSON.stringify(newStorage));
    closeDialog();
  };

  const isNumberData = getSimplifiedType(panelData.type) === 'NUMBER' || panelData.aggregation;

  return (
    <CustomDialog
      isOpen
      onClose={closeDialog}
      title="Настройки отображения"
      description={panelData.name}
      maxWidth={false}
      fullWidth={false}
    >
      <div className="column-view-options-dialog__main">
        {isTable && (
          <>
            {isNumberData && (
              <div className="column-view-options-dialog__block">
                <div className="column-view-options-dialog__title">
                  Тип отображения
                </div>
                <RadioGroup
                  onChange={(e) => {
                    handleSelectTypeChange(e);
                  }}
                >
                  <CustomFormControl
                    value="TEXT"
                    control={
                      <CustomRadio
                        checked={cellVariant === CellVariant.TEXT}
                        color="primary"
                      />
                    }
                    label="Текст"
                  />
                  <CustomFormControl
                    value="PROGRESS_AND_TEXT"
                    control={
                      <CustomRadio
                        checked={cellVariant === CellVariant.PROGRESS_AND_TEXT}
                        color="primary"
                      />
                    }
                    label="Текст с прогресс баром"
                  />
                  <CustomFormControl
                    value="PROGRESS"
                    control={
                      <CustomRadio
                        checked={cellVariant === CellVariant.PROGRESS}
                        color="primary"
                      />
                    }
                    label="Прогресс бар"
                  />
                </RadioGroup>
              </div>
            )}

            {[CellVariant.PROGRESS_AND_TEXT, CellVariant.PROGRESS].includes(
              cellVariant,
            ) && (
              <div className="column-view-options-dialog__block">
                <div className="column-view-options-dialog__title">
                  Цвет прогресс бара
                </div>
                <div className="column-view-options-dialog__palette">
                  <ColorPicker
                    name="Цвет"
                    value={progressColor}
                    onChangeColor={onChangeProgressColor}
                    isColorWithAlpha
                  />
                </div>
              </div>
            )}

            <div className="column-view-options-dialog__block">
              <div className="column-view-options-dialog__title">
                Настройка заголовков
              </div>
              <div className="column-view-options-dialog__color-setting">
                <div className="column-view-options-dialog__palette">
                  <ColorPicker
                    name="Цвет фона"
                    value={titleBackgroundColor}
                    onChangeColor={onChangeTitleBackgroundColor}
                    isColorWithAlpha
                  />
                </div>
                <div className="column-view-options-dialog__palette">
                  <ColorPicker
                    name="Цвет текста"
                    value={titleTextColor}
                    onChangeColor={onChangeTitleTextColor}
                    isColorWithAlpha
                  />
                </div>
              </div>
            </div>

            <div className="column-view-options-dialog__block">
              <div className="column-view-options-dialog__title">
                Настройка ячеек
              </div>
              <div className="column-view-options-dialog__color-setting">
                <div className="column-view-options-dialog__palette">
                  <ColorPicker
                    name="Цвет фона"
                    value={cellBackgroundColor}
                    onChangeColor={onChangeCellBackgroundColor}
                    isColorWithAlpha
                  />
                </div>
                <div className="column-view-options-dialog__palette">
                  <ColorPicker
                    name="Цвет текста"
                    value={cellTextColor}
                    onChangeColor={onChangeCellTextColor}
                    isColorWithAlpha
                  />
                </div>
              </div>
              <div className="column-view-options-dialog__block">
                <CustomFormControl
                  control={
                    <CustomCheckbox
                      onChange={handleTextColor}
                      checked={textBold}
                      color="primary"
                    />
                  }
                  label="Жирный"
                />
              </div>
              <div className="column-view-options-dialog__block">
                <TextInput
                  className="column-view-options-dialog__block-input"
                  label="Ширина столбца, px"
                  value={width}
                  handleChange={handleChangeWidth}
                />
              </div>
            </div>
            {isNumberData && (
              <div className="column-view-options-dialog__block">
                <div className="column-view-options-dialog__title">
                  Формат отображения чисел
                </div>
                <RadioGroup
                  onChange={(e) => {
                    handleSelectNumberFormatChange(e);
                  }}
                >
                  <CustomFormControl
                    value={NumberFormat.no}
                    control={
                      <CustomRadio
                        checked={numberFormat === NumberFormat.no}
                      />
                    }
                    label={NumberFormat.no}
                  />
                  <CustomFormControl
                    value={NumberFormat.ru}
                    control={
                      <CustomRadio
                        checked={numberFormat === NumberFormat.ru}
                      />
                    }
                    label={NumberFormat.ru}
                  />
                  <CustomFormControl
                    value={NumberFormat.en}
                    control={
                      <CustomRadio
                        checked={numberFormat === NumberFormat.en}
                      />
                    }
                    label={NumberFormat.en}
                  />
                </RadioGroup>
              </div>
            )}
          </>
        )}

        <div className="column-view-options-dialog__block">
          <div className="column-view-options-dialog__title">
            Выравнивание текста
          </div>
          <RadioGroup
            onChange={(e) => {
              handleSelectJustifyContentChange(e);
            }}
          >
            <CustomFormControl
              value="start"
              control={
                <CustomRadio
                  checked={cellJustifyContent === 'start'}
                  color="primary"
                />
              }
              label="По левому краю"
            />
            <CustomFormControl
              value="center"
              control={
                <CustomRadio
                  checked={cellJustifyContent === 'center'}
                  color="primary"
                />
              }
              label="По центру"
            />
            <CustomFormControl
              value="end"
              control={
                <CustomRadio
                  checked={cellJustifyContent === 'end'}
                  color="primary"
                />
              }
              label="По правому краю"
            />
          </RadioGroup>
        </div>
        {isNumberData && (
          <div className="column-view-options-dialog__block">
            <TextInput
              className="column-view-options-dialog__block-input"
              label="Текст подписи значения"
              value={displayValueText}
              handleChange={handleChangeDisplayValueText}
            />
          </div>
        )}
        {isNumberData && (
          <div className="column-view-options-dialog__block">
            <div className="column-view-options-dialog__title">
              Незначащие нули
            </div>
            <RadioGroup
              onChange={(e) => {
                handleSelectDisplayColumnZerosChange(e);
              }}
            >
              <CustomFormControl
                value="displayed"
                control={
                  <CustomRadio
                    checked={displayColumnZeros === 'displayed'}
                    color="primary"
                  />
                }
                label="Отображать"
              />
              <CustomFormControl
                value="fromWidget"
                control={
                  <CustomRadio
                    checked={displayColumnZeros === 'fromWidget'}
                    color="primary"
                  />
                }
                label="Настройка виджета"
              />
              <CustomFormControl
                value="hidden"
                control={
                  <CustomRadio
                    checked={displayColumnZeros === 'hidden'}
                    color="primary"
                  />
                }
                label="Не отображать"
              />
            </RadioGroup>
          </div>
        )}
      </div>

      <div className="dialog-buttons">
        <CustomButton variant="contained" type="submit" onClick={handleSave}>
          <span>Сохранить</span>
        </CustomButton>
        <CustomButton variant="outlined" type="reset" onClick={closeDialog}>
          Отменить
        </CustomButton>
      </div>
    </CustomDialog>
  );
};
