export const findDiffChar = (str1: string, str2: string) => {
  // Определяем длину меньшей строки, чтобы не выйти за её пределы
  const minLength = Math.min(str1.length, str2.length);

  // Проходим по символам строк
  for (let i = 0; i < minLength; i++) {
    if (str1[i] !== str2[i]) {
      // Если символы не совпадают, возвращаем индекс первого отличающегося символа
      return i;
    }
  }

  // Если строки дошли до конца без различий, проверяем, если одна строка длиннее другой
  if (str1.length !== str2.length) {
    // Возвращаем индекс первого отличающегося символа, который выходит за пределы более короткой строки
    return minLength;
  }

  // Если строки полностью совпадают
  return -1;
};
