import { SortableContext } from '@dnd-kit/sortable';
import React from 'react';
import { PanelItem } from '../../panel-item/panel-item';
import { WidgetProperties } from '../../../../../slices/types';
import { useWidgetDropdownItem } from './useWidgetDropdownItem';
import { getFunctionsAndAggregations, isHiddenPanelItem } from '../../helpers';
import { FilterInfoItem } from '../filter-info-item/filter-info-item';
import { PanelType } from '../../../../../enums/widget-type';

export interface WidgetDropdownItemProps {
  panel: WidgetProperties;
  panelType: string;
}

export const WidgetDropdownItem = ({
  panel,
  panelType,
}: WidgetDropdownItemProps) => {
  const {
    sortableItems,
    functionsAndAggregationsByTypes,
    hiddenElements,
  } = useWidgetDropdownItem({ panel, panelType });

  return (
    <div>
      <SortableContext items={sortableItems}>
        <div className="widget-dropdown-item__container">
          <FilterInfoItem panelType={panelType as PanelType} hiddenElements={hiddenElements} />

          {sortableItems.map((panelItemString: string, index: number) => {
            if (!panelItemString || isHiddenPanelItem(hiddenElements, panelItemString)) return null;

            return (
              <PanelItem
                key={panelItemString}
                draggableId={panelItemString}
                index={index}
                panel={panel}
                functionsAndAggregations={getFunctionsAndAggregations(
                  functionsAndAggregationsByTypes,
                  panelItemString,
                )}
              />
            );
          })}
        </div>
      </SortableContext>
    </div>
  );
};
