import React from 'react';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SelectOptions, SelectOptionsMeta } from '../../../../type';
import Validation from '../../../../validation/type';
import IconSvg from '../../../../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../../../../dictionaries/icon-dictonary/icon-dictionary';
import { getIconByDataType } from '../../../../../../widget-page/helpers';
import { getErrorText } from '../../../../validation/logic';
import './styles.css';

const useStyles = makeStyles({
  'field-svg': {
    marginRight: 'calc(15px * var(--scale-coefficient))',
  },
  'menu-item': {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    color: 'var(--title-color)',
  }
});

interface SourceSelectFieldProps {
  i: any;
  sourceFieldName: any;
  keyForMerge: any;
  sourceId: any;
  selectOptions: SelectOptions;
  selectOptionsMeta: SelectOptionsMeta;
  needDrawKeyPicture: boolean;
  validation: Validation.Data;
  handleSelectChange: (e: any, i: any, type: string) => void;
  handleKeyClick: (i: any) => void;
}

const SourceSelectField: React.FC<SourceSelectFieldProps> = ({
  i,
  sourceFieldName,
  keyForMerge,
  sourceId,
  selectOptions,
  selectOptionsMeta,
  needDrawKeyPicture,
  validation,
  handleSelectChange,
  handleKeyClick,
}) => {
  const classes = useStyles();

  const getType = (option: string) => selectOptionsMeta[sourceId][option]?.type;

  const errorFill = validation.values?.fill || {};
  const errorDuplicate = validation.values?.duplicate || {};
  const errorTypes = validation.values?.types || {};

  const isShowHelperText =
    !validation.values?.fill &&
    !validation.values?.duplicate &&
    errorTypes[i]?.source;

  return (
    <TextField
      id={`${i}`}
      error={
        errorFill[i]?.source ||
        errorDuplicate[i]?.source ||
        errorTypes[i]?.source
      }
      select
      value={sourceFieldName}
      onChange={(e) => {
        handleSelectChange(e, i, 'source');
      }}
      helperText={isShowHelperText ? getErrorText(validation.values) : null}
      variant="outlined"
      InputProps={{
        endAdornment: needDrawKeyPicture ? (
          <InputAdornment
            onClick={() => {
              handleKeyClick(i);
            }}
            position="end"
          >
            <div className="select-wrapper">
              <IconButton>
                <IconSvg
                  svg={IconDictionary.Key}
                  fill={
                    validation.values.key
                      ? 'var(--red-normal)'
                      : keyForMerge
                        ? 'var(--primary-color)'
                        : 'var(--dark-grey)'
                  }
                />
              </IconButton>
            </div>
          </InputAdornment>
        ) : null,
      }}
    >
      {selectOptions[sourceId].map((option) => (
        <MenuItem key={option} value={option}>
          <div className={classes['menu-item']}>
            {option && (
              <IconSvg
                classSVG={classes['field-svg']}
                svg={getIconByDataType(getType(option))}
                fill="var(--dark-grey)"
              />
            )}
            {option}
          </div>
        </MenuItem>
      ))}

      {!selectOptions[sourceId].includes(sourceFieldName) &&
        sourceFieldName !== 'unknown' && (
          <MenuItem key={sourceFieldName} value={sourceFieldName}>
            <div className={classes['menu-item']}>
              {sourceFieldName && (
                <IconSvg
                  classSVG={classes['field-svg']}
                  svg={getIconByDataType(getType(sourceFieldName))}
                  fill="var(--dark-grey)"
                />
              )}
              {sourceFieldName}
            </div>
          </MenuItem>
      )}
    </TextField>
  );
};

export default SourceSelectField;
