import { useEffect } from 'react';
import { wsBI } from '../utils/api';


export const useDashboardChangesObserver = (dashboardId?: number, callback?: Function) => {

  useEffect(() => {
    if (!dashboardId) return;

    let loaderChangeStatusUID: string;

    const onMessageReceived = (messageOutput: any) => {
      callback && callback(messageOutput);
    };

    wsBI.synchronizedConnect(() => {
      loaderChangeStatusUID = wsBI.addHandler(
        `/topic/dashboard-change/${dashboardId}`,
        onMessageReceived,
      );
    });

    return () => {
      if (!loaderChangeStatusUID) return;
      wsBI.removeHandler(loaderChangeStatusUID);
    };
  }, [dashboardId, callback]);
};
