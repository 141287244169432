import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isNumber } from 'lodash';
import { getActivePanelItems } from '../../../helpers/common-helpers';
import { initValueFormatter } from '../../widget-page/charts/hooks/initValueFormatter';
import { PanelType } from '../../../enums/widget-type';
import { axisBottomOffset } from '../../widget-page/charts/bar-group/settings';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { getParsedAxisValues } from '../../widget-page/dropdown-layout/helpers/helpers';
import { PropertyData } from '../../widget-page/dropdown-layout/helpers/Property';

export interface ScalableSVGTextSettings {
  minWidthHide?: number;
  maxHeightHide?: number;
  isNeedToVerticalAlign?: boolean;
  lineCount?: number;
  width: number;
  height?: number;
  isNeedToFormat?: boolean;
  svgTextStyles: any;
  textDirection: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  tickTranslateX?: number;
  isLineHeightPriority?: boolean;
}

export enum textDirections {
  horizontal = 'Горизонтальные',
  vertical = 'Вертикальные',
  diagonal = 'По диагонали',
}

export const ScalableSVGText = ({
  x,
  y,
  formattedValue,
  axis = PanelType.axisX,
  properties = {},
  widgetProperties,
  showTooltip,
}: any) => {
  const defaultLineCount = 1;
  const defaultIsNeedToVerticalAlign = false;
  const defaultIsLineHeightPriority = false;
  const defaultTextDirection = textDirections.horizontal;

  const {
    minWidthHide,
    maxHeightHide,
    isNeedToVerticalAlign = defaultIsNeedToVerticalAlign,
    isLineHeightPriority = defaultIsLineHeightPriority,
    lineCount = defaultLineCount,
    textDirection = defaultTextDirection,
    width,
    height,
    svgTextStyles,
    tooltipPlacement = 'bottom',
    isNeedToFormat = true,
    tickTranslateX = 0,
  }: ScalableSVGTextSettings = properties;

  const maxTextHeight = lineCount * svgTextStyles.lineHeight;

  const isNeedHide =
    (isNumber(maxHeightHide) && maxHeightHide < maxTextHeight) ||
    (isNumber(minWidthHide) && minWidthHide > width);

  const valueFormat = initValueFormatter();

  const activeIndex = getParsedAxisValues(axis, widgetProperties)
    .findIndex((property: PropertyData) => property.isActive);

  const value =
    axis === PanelType.axisX && widgetProperties && isNeedToFormat
      ? valueFormat(formattedValue, activeIndex === -1 ? 0 : activeIndex, widgetProperties, axis)?.toString()
      : formattedValue?.toString();

  const maxSymbols = Math.round((width * lineCount) / svgTextStyles.lineHeight);

  const isLongText = String(value)?.length > maxSymbols;
  const isLongWord = String(value)
    .split(' ')
    .filter((word) => String(word).length > maxSymbols / lineCount).length;
  const isOneWord =
    String(value).split(' ').length === 1 || String(value).split(' ')[1] === '';

  const isVerticalText = textDirection === textDirections.vertical;
  const isDiagonalText = textDirection === textDirections.diagonal;

  const containerHeight = isNeedToVerticalAlign
    ? height
    : isLongWord || isDiagonalText
    ? svgTextStyles.lineHeight
    : isLongText
    ? maxTextHeight
    : height || maxTextHeight;

  const lineHeightOffset = 2;

  const useStyles = useMemo(
    () =>
      makeStyles({
        'container-svg-text': {
          color: svgTextStyles.color,
          fontSize: svgTextStyles.fontSize,
          fontStyle: svgTextStyles.fontStyle,
          fontFamily: svgTextStyles.fontFamily,
          fontWeight: svgTextStyles.fontWeight,
          lineHeight: isLineHeightPriority && svgTextStyles.lineHeight ? `${svgTextStyles.lineHeight}px` :
            (isNeedToVerticalAlign && isLongWord) || lineCount === 1
              ? `${height || maxTextHeight}px`
              : isLongText && !isLongWord
                ? (height || maxTextHeight) / (lineCount * svgTextStyles.fontSize)
                : `${svgTextStyles.lineHeight + lineHeightOffset}px`,
          height: containerHeight,
          width,
          textAlign: svgTextStyles.textAlign,
          position: 'fixed',
          display: 'grid',
          transform: isDiagonalText
            ? `rotate(-45deg) translate(${-width / 4}px, ${width / 4}px)`
            : isVerticalText
              ? `rotate(-90deg) translate(${containerHeight / 2 - width / 2}px, ${
                height ? height / 2 - width / 2 : 0
              }px)`
              : 'none',
          cursor: 'default'
        },
        'svg-text': {
          wordBreak: isOneWord
            ? 'break-all'
            : svgTextStyles.wordBreak || 'normal',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': isLongWord ? 1 : lineCount,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          margin: isVerticalText ? 'auto 0' : isDiagonalText ? '0 0 0 auto' : '0',
          textAlign: svgTextStyles.innerTextAlign
            ? svgTextStyles.innerTextAlign
            : isDiagonalText || (isVerticalText && !isNeedToVerticalAlign)
              ? 'end'
              : 'center',
          maxWidth: isDiagonalText ? '150px' : 'none'
        },
        'vertical-align-svg-text': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          wordBreak: isOneWord
            ? 'break-all'
            : svgTextStyles.wordBreak || 'normal',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': isLongWord ? 1 : lineCount,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          margin: isVerticalText ? 'auto 0' : '',
          textAlign: isDiagonalText ? 'end' : 'center',
        },
      }),
    [
      containerHeight,
      height,
      isLongText,
      isLongWord,
      isNeedToVerticalAlign,
      isOneWord,
      lineCount,
      svgTextStyles.color,
      svgTextStyles.fontFamily,
      svgTextStyles.fontSize,
      svgTextStyles.fontStyle,
      svgTextStyles.fontWeight,
      svgTextStyles.lineHeight,
      svgTextStyles.textAlign,
      svgTextStyles.innerTextAlign,
      svgTextStyles.wordBreak,
      maxTextHeight,
      width,
      isVerticalText,
      isDiagonalText,
    ],
  );

  const classes = useStyles();

  const xTranslate = isDiagonalText && tickTranslateX ? x + tickTranslateX - (height || maxTextHeight) : x;

  const TextComponent = () => (
    <foreignObject
      width={isVerticalText ? Math.abs(height!) : Math.abs(width)}
      height={
        isVerticalText
          ? Math.abs(width)
          : isDiagonalText
            ? axisBottomOffset
            : containerHeight
      }
      x={xTranslate}
      y={y}
      style={{ pointerEvents: svgTextStyles.pointerEvents || 'auto' }}
    >
      <div className={classes['container-svg-text']}>
        <div
          className={
            isNeedToVerticalAlign && !isLongText && !isLongWord
              ? classes['vertical-align-svg-text']
              : classes['svg-text']
          }
        >
          {value}
        </div>
      </div>
    </foreignObject>
  );

  return isNeedHide ? null : (
    showTooltip ? (
      <CustomTooltip
        key={`${width}-${maxTextHeight}`}
        title={isLongText || isLongWord || isNeedHide ? value : ''}
        placement={tooltipPlacement}
      >
        <TextComponent />
      </CustomTooltip>
    ) : (
      <TextComponent />
    )
  );
};
