import { useCallback, useState } from 'react';
import { apiExportDashboard } from 'src/services/dashboardController';
import { useNotificator } from 'src/components/common/snackbar/hooks';
import { useDashboardWidgetRequestParams } from 'src/components/dashboard-page/hooks/useDashboardWidgetRequestParams';
import {
  defineFileDataFromResponse,
  fileDownload,
} from '../../helpers/import-export';
import {DashboardDictionary} from "../../dictionaries/naming-dictionary/naming-dictionary";

export enum DashboardExportActionType {
  DOWNLOAD = 'download',
  SEND = 'send'
}

export const useExportSendDashboard = (dashboardId: number, dashboardName: string) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { showNotification } = useNotificator();
  const requestParams = useDashboardWidgetRequestParams();

  const onExportClick = useCallback(
    (type: string, actionType: string, callback?: Function) => {
      actionType === DashboardExportActionType.DOWNLOAD ?
        setIsDownloading(true) :
        setIsSending(true);

      apiExportDashboard(dashboardId, type, requestParams)
        .then((response: any) => {
          const fileData = defineFileDataFromResponse(response);
          fileDownload(fileData.file, fileData.fileName);

          callback && callback();
        })
        .catch(() => {
          showNotification({ message: 'Ошибка при загрузке файла', variant: 'error' });
        })
        .finally(() => {
          setIsDownloading(false);
          setIsSending(false);
        });
    },
    [dashboardId, dashboardName, requestParams, showNotification],
  );

  const onSendClick = () => {
    const openEmail = () => {
      const dashboardLink = window.location.href;

      const link = document.createElement('a');
      link.setAttribute('href', `mailto:?body=Ссылка на ${DashboardDictionary.one} - ${dashboardLink} %0D%0AНеобходимо приложить скачанный файл к письму.`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    onExportClick('xlsx', DashboardExportActionType.SEND, openEmail);
  };

  return { onExportClick, onSendClick, isDownloading, isSending };
};
