import React from 'react';
import './bound-placeholder.css';
import IconSvg from '../../../common/icon-svg/icon-svg';
import {DashboardDictionary, WidgetDictionary} from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface BoundPlaceholderProps {
  isCanAddConnection: boolean;
}

export const BoundPlaceholder: React.FC<BoundPlaceholderProps> = ({
  isCanAddConnection,
}) => {

  return (
    <div className="bound-placeholder__container">
      <div className="bound-placeholder__no-connection-container">
        <IconSvg svg={IconDictionary.NoBoundConnection} width={80} height={80} fill="var(--dark-grey)" />
        <h1 className="bound-placeholder__no-connection-heading">
          {isCanAddConnection
            ? `Нет связанных ${WidgetDictionary.many}`
            : `Нет ${WidgetDictionary.many} для создания связи`}
        </h1>
        <h3 className="bound-placeholder__no-connection-subheading">
          {isCanAddConnection
            ? `В данный момент не добавлено ни ${WidgetDictionary.countBoundWidget}`
            : `Для создания связей необходимо построить более ${WidgetDictionary.countWidgets} на одном источнике и перенести хотя бы один на ${DashboardDictionary.one}`}
        </h3>
      </div>
    </div>
  );
};
