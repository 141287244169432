import { uniqWith } from 'lodash';
import { useSelector } from 'react-redux';
import { dashboardCacheWidgetsSelector } from '../../../slices/dashboard/dashboard';
import { PropertyData } from '../../widget-page/dropdown-layout/helpers/Property';
import { BoundFilterGroupProps } from './index';
import { useDashboardFilters } from './useDashboardFilters';
import {
  getFirstMasterForDD,
  isEqualPanelFilters,
} from '../../../helpers/dashboard-page/dashboard-widgets';
import {
  getBoundFiltersForPanel,
  getDashboardFiltersForPanel,
  getDdFiltersForPanel,
  getWidgetFiltersForPanel,
} from '../../../helpers/dashboard-page/filters-helper';

export const useFiltersForPanel = (
  boundFiltersGroups: BoundFilterGroupProps[],
  ddFiltersGroups: BoundFilterGroupProps[],
  widgetId: number,
  widgetFilters: PropertyData[],
  isDrillDown: boolean,
) => {
  const { values: dashboardFiltersGroups } = useDashboardFilters();
  const widgetsCache = useSelector(dashboardCacheWidgetsSelector);

  const widgetFiltersForPanel = getWidgetFiltersForPanel(widgetFilters, widgetId, dashboardFiltersGroups, isDrillDown);

  const boundFiltersForPanel = uniqWith(
    widgetFiltersForPanel.concat(
      getBoundFiltersForPanel(boundFiltersGroups, widgetId, widgetsCache),
    ),
    isEqualPanelFilters,
  );

  const dashboardFilters = getDashboardFiltersForPanel(
    dashboardFiltersGroups,
    widgetId,
  );

  const ddFiltersForPanel = uniqWith(
    // порядок фильтров важен! в приоритете отображаем фильтры DD
    getDdFiltersForPanel(ddFiltersGroups, widgetId, widgetsCache)
      // берем связанные фильтры из виджета на дашборде из которого мы пришли в DD
      .concat(
        getBoundFiltersForPanel(
          boundFiltersGroups,
          getFirstMasterForDD(ddFiltersGroups, widgetId),
          widgetsCache,
        ),
      )
      .concat(dashboardFilters)
      .concat(widgetFiltersForPanel),
    isEqualPanelFilters,
  );

  const filtersForPanel = isDrillDown
    ? ddFiltersForPanel
    : boundFiltersForPanel;

  return {
    filtersForPanel,
  };
};
