import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { Placement } from '../../../../enums/visual-types';
import { FilterContainer } from '../../../common/filter-container/filter-container';
import { useFilter } from '../hooks/useFilter';
import { FilterValue } from '../types';
import { ValueField } from '../fields/value-field';

interface ValueFilterProps {
  onChangeFilter: (filter: any) => void;
  currentFilter: FilterValue[];
  label?: React.ReactNode | string;
  closeFilterPanel?: () => void;
  placement?: Placement;
}

export const ValueFilter: React.FC<ValueFilterProps> = ({
  onChangeFilter,
  currentFilter,
  label,
  closeFilterPanel,
  placement,
}) => {
  const {
    fromValue,
    toValue,
    handleChangeFrom,
    handleChangeTo,
    handleApply,
    isDisabledApplyFilterButton,
  } = useFilter<number | null>({
    onChangeFilter,
    currentFilter,
    toOperation: '<=',
    fromOperation: '>=',
    onlyNumbers: true,
    closeFilterPanel,
  });

  const handleChangeValueFrom = useCallback((value: any) => {
    const correctValueForCheckChanges = value?.length > 0 ? value : null;
    handleChangeFrom(correctValueForCheckChanges);
  }, []);

  const handleChangeValueTo = useCallback((value: any) => {
    const correctValueForCheckChanges = value?.length > 0 ? value : null;
    handleChangeTo(correctValueForCheckChanges);
  }, []);

  return (
    <FilterContainer
      label={label}
      isDisabledApplyButton={isDisabledApplyFilterButton}
      handleApply={handleApply}
    >
      <Box display="flex">
        <Box>
          <ValueField
            label="Значение, с"
            value={fromValue?.toString() || ''}
            onChange={handleChangeValueFrom}
            placement={placement}
          />
        </Box>
        <Box ml="11px">
          <ValueField
            label="Значение, по"
            value={toValue?.toString() || ''}
            onChange={handleChangeValueTo}
            placement={placement}
          />
        </Box>
      </Box>
    </FilterContainer>
  );
};
