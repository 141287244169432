import { TickFormatter } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { extent } from 'd3-array';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';
import {
  accessorDateX,
  accessorNumberX,
  brushHeight,
  chartBrushSeparation,
  getAccessorY,
  getAccessorZ,
  margin,
} from '../helper';
import { useScales } from '../hooks/useScales';
import { useGraphTooltip } from '../hooks/useTooltip';
import AxisBottom from './axis-bottom';
import FilterGlyphs from './filter-glyphs';
import GraphBrush from './graph-brush';
import AxisSeries from './series';
import {
  FormatAxisVerticalValue,
  FormatAxisXValue,
  GraphData,
  SeriesValue,
} from '../types';

interface GraphProps {
  width: number;
  height: number;
  widgetData: GraphData[];
  xValue: SeriesValue;
  yValues: SeriesValue[];
  zValues: SeriesValue[];
  colors: string[];
  isLineSeries: boolean;
  isSmoothedLine: boolean;
  isScaleByValue: boolean;
  isGradientFill: boolean;
  formatAxisXValue: FormatAxisXValue;
  formatAxisYValue: FormatAxisVerticalValue;
  formatAxisZValue: FormatAxisVerticalValue;
  isNeedToDisplayTooltip: boolean;
  isNeedToDisplayAxesGuide: boolean;
  isNeedInterpolateHorizontalAxis: boolean;
  isNeedDisplayBrush: boolean;
  customAxisZLabelWidth: number;
  customAxisYLabelWidth: number;
  deselectPoint?: () => void;
  selectPoint?: (groupIndex: string) => void;
  filterFieldValue: string[] | null;
}

const Graph = ({
  width,
  height,
  widgetData,
  xValue,
  yValues,
  zValues,
  colors,
  isLineSeries,
  isGradientFill,
  isSmoothedLine,
  isScaleByValue,
  formatAxisXValue,
  formatAxisYValue,
  formatAxisZValue,
  isNeedToDisplayTooltip,
  isNeedToDisplayAxesGuide,
  isNeedInterpolateHorizontalAxis,
  isNeedDisplayBrush,
  customAxisZLabelWidth,
  customAxisYLabelWidth,
  deselectPoint,
  selectPoint,
  filterFieldValue,
}: GraphProps) => {
  const [filteredData, setFilteredData] = useState(widgetData);

  // если извне пришли новые данные для виджета, то нужно перестроить виджет
  useEffect(() => {
    setFilteredData(widgetData);
  }, [widgetData, xValue.simplifiedType]);

  const [disableBrushControl, setDisableBrushControl] = useState(false);

  const haveYSeries = yValues.length > 0;
  const haveZSeries = zValues.length > 0;

  // bounds
  const marginForAxisLeft = haveYSeries ? customAxisYLabelWidth : margin.left;
  const marginForAxisRight = haveZSeries ? customAxisZLabelWidth : margin.right;

  const innerHeight = height - margin.top - margin.bottom;
  const chartHeight =
    innerHeight - chartBrushSeparation - (isNeedDisplayBrush ? brushHeight : 0);
  const brushTopOffset = chartHeight + margin.top + chartBrushSeparation;

  const xMax = Math.max(width - marginForAxisLeft - marginForAxisRight, 0);
  const yMax = Math.max(chartHeight, 0);
  const xBrushMax = xMax;

  const axisRightOffsetLeft = xMax;

  const scales = useScales({
    widgetData,
    filteredData,
    xValue,
    yValues,
    zValues,
    colors,
    xMax,
    yMax,
    xBrushMax,
    isScaleByValue,
  });

  const {
    xScale,
    yScale,
    zScale,
    getY1,
    brushXScale,
    brushYScale,
    colorScale,
    accessorX,
    xDataValues,
  } = scales;

  const { EventControllerBar, TooltipGlyphs, Tooltip } = useGraphTooltip({
    data: filteredData,
    xValue,
    yValues,
    zValues,
    scales,
    colors,
    xMax,
    yMax,
    marginForAxisLeft,
    isNeedToDisplayTooltip,
    disableControl: disableBrushControl,
    formatAxisXValue,
    formatAxisYValue,
    formatAxisZValue,
    selectPoint,
  });

  const setData = useCallback((data: GraphData[]) => {
    setFilteredData(data);
  }, []);

  return (
    <div>
      <svg width={width} height={height} onClick={deselectPoint}>
        <Group left={marginForAxisLeft} top={margin.top}>
          <AxisBottom
            xMax={xMax}
            yMax={yMax}
            isNeedToDisplayAxesGuide={isNeedToDisplayAxesGuide}
            isNeedInterpolateHorizontalAxis={isNeedInterpolateHorizontalAxis}
            scale={xScale}
            formatAxisXValue={formatAxisXValue}
            xValue={xValue}
            xDataValues={xDataValues}
          />
          {isNeedToDisplayAxesGuide && (
            <>
              <GridRows
                scale={yScale}
                width={xMax}
                height={yMax}
                stroke="#e0e0e0"
              />
            </>
          )}
          {haveYSeries && (
            <AxisSeries
              series={yValues}
              data={filteredData}
              betweenGraphOffset={margin.betweenGraphOffset}
              xScale={xScale}
              horizontalAccessor={accessorX}
              verticalScale={yScale}
              colorScale={colorScale}
              verticalAccessor={getAccessorY}
              isLineSeries={isLineSeries}
              isGradientFill={isGradientFill}
              isSmoothedLine={isSmoothedLine}
              getY1={getY1}
              formatAxisValue={formatAxisYValue}
            />
          )}
          {haveZSeries && (
            <AxisSeries
              isAxisRight
              axisRightOffsetLeft={axisRightOffsetLeft}
              series={zValues}
              data={filteredData}
              betweenGraphOffset={margin.betweenGraphOffset}
              xScale={xScale}
              horizontalAccessor={accessorX}
              verticalScale={zScale}
              colorScale={colorScale}
              verticalAccessor={getAccessorZ}
              isLineSeries={isLineSeries}
              isGradientFill={isGradientFill}
              isSmoothedLine={isSmoothedLine}
              getY1={getY1}
              formatAxisValue={formatAxisZValue}
            />
          )}
          <FilterGlyphs
            height={height}
            filteredData={filteredData}
            filterFieldValue={filterFieldValue}
            yValues={yValues}
            zValues={zValues}
            xScale={xScale}
            yScale={yScale}
            zScale={zScale}
            accessorX={accessorX}
          />
          <TooltipGlyphs />
          <EventControllerBar />
        </Group>

        {isNeedDisplayBrush && (
          <Group left={marginForAxisLeft} top={brushTopOffset}>
            <GraphBrush
              // Мы не имеем управления над Brush, по-этому, чтобы сбросить Brush, при изменении входных данных,
              // добавлен widgetData в key
              key={JSON.stringify(widgetData)}
              widgetData={widgetData}
              setData={setData}
              brushXScale={brushXScale}
              brushYScale={brushYScale}
              xBrushMax={xBrushMax}
              marginForAxisLeft={marginForAxisLeft}
              widgetWidth={width}
              accessorX={accessorX}
              setDisableBrushControl={setDisableBrushControl}
              xScale={xScale}
              formatAxisXValue={formatAxisXValue}
            />
          </Group>
        )}
      </svg>
      <Tooltip />
    </div>
  );
};

export default Graph;
