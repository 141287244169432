import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { CssVariables } from '../../../enums/css-variables';
import { varcss } from '../../../helpers/ui-helper';
import './styles.css';
import { CustomButton } from '../../../uikit/Button';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import { isMobilehelper } from '../../../utils/mobile-helper';
import { isDashboardPreviewPage } from '../../widget-page/charts/helpers';
import IconSvg from '../icon-svg/icon-svg';

interface OverPanelProps {
  closeHandler: () => void;
  title: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  width?: number
}

export const OverPanel = memo(
  ({ closeHandler, title, children, footer, width = 400 }: OverPanelProps) => {

    return (
      <div
        className="over-panel__container"
        style={{
          top: isDashboardPreviewPage() && !isMobilehelper() ? 'var(--panel-size)' : undefined,
          width: `calc(${width}px * var(--scale-coefficient))`,
        }}
      >
        <div className="over-panel__heading">
          <Typography className="over-panel__title">{title}</Typography>
          <CustomButton
            aria-label="close"
            variant="outlined"
            size="small"
            icon={
              <IconSvg svg={IconDictionary.Close} fill="var(--dark-grey)" />
            }
            onClick={closeHandler}
            backgroundColor={varcss(CssVariables.MAIN_BACKDROP_COLOR)}
          />
        </div>
        <div className="over-panel__wrappers">{children}</div>

        {footer && <div className="over-panel__drop-filters-wrapper">{footer}</div>}
      </div>
    );
  },
);

OverPanel.displayName = 'OverPanel';
