import { useMemo } from 'react';
import { WidgetProperties } from '../../../../../slices/types';
import { getParsedAxisValues, getSimplifiedType } from '../../../dropdown-layout/helpers/helpers';
import { PanelType, WidgetPropertyType } from '../../../../../enums/widget-type';
import {
  getAxisValuesForWidget,
  getBooleanPropertyValue,
  getInputPropertyValue,
  getJSONPropertyValue,
  getSelectPropertyValue,
} from '../../helpers';
import { DateNames } from '../../../../../enums/date';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { NumberFormat } from '../../../../../enums/number-format';
import { getActivePanelItems, getActivePanelItemsNames } from '../../../../../helpers/common-helpers';

interface UseGraphPropertiesProps {
  widgetProperties: WidgetProperties[];
}

export const useGraphProperties = ({ widgetProperties }:UseGraphPropertiesProps) => {
  const axisXValues: PropertyData[] = useMemo(
    () => getActivePanelItems(widgetProperties, PanelType.axisX),
    [widgetProperties],
  );

  const axisXNames: string[] = useMemo(
    () => getActivePanelItemsNames(widgetProperties, PanelType.axisX),
    [widgetProperties],
  );
  const dateLabelAggregation: keyof typeof DateNames = useMemo(
    () => getInputPropertyValue('functionDateLabelAggregation', widgetProperties) as keyof typeof DateNames,
    [widgetProperties],
  );
  const isNeedAxisXDateLabelAggregation: boolean = useMemo(
    () => getBooleanPropertyValue('axisXDateLabelAggregation', widgetProperties),
    [widgetProperties],
  );
  const isNeedDisplayAggregationInLegend: boolean = useMemo(
    () => getBooleanPropertyValue('displayAggregationInLegend', widgetProperties),
    [widgetProperties],
  );
  const isNeedToDisplayTooltip: boolean = useMemo(
    () => getBooleanPropertyValue('displayValuesOnGraph', widgetProperties),
    [widgetProperties],
  );
  const isNeedToDisplayWidgetTooltip: boolean = useMemo(
    () => getBooleanPropertyValue('displayTooltip', widgetProperties),
    [widgetProperties],
  );
  const isSmoothedLine: boolean = useMemo(
    () => getBooleanPropertyValue('smoothedLine', widgetProperties),
    [widgetProperties],
  );
  const isScaleByValueState: boolean = useMemo(
    () => getBooleanPropertyValue('scaleByValue', widgetProperties),
    [widgetProperties],
  );
  const isNeedToDisplayAxesGuide: boolean = useMemo(
    () => getBooleanPropertyValue('displayAxesGuide', widgetProperties),
    [widgetProperties],
  );
  const isNeedToDisplayCircles: boolean = useMemo(
    () => getBooleanPropertyValue('displayPointOnGraph', widgetProperties),
    [widgetProperties],
  );
  const customAxisXWidth: number = useMemo(
    () => parseInt(getInputPropertyValue('axisXLabelWidth', widgetProperties)),
    [widgetProperties],
  );
  const customAxisXHeight: number = useMemo(
    () => parseInt(getInputPropertyValue('axisXLabelHeight', widgetProperties)),
    [widgetProperties],
  );
  const customAxisYLabelWidth: number = useMemo(
    () => parseInt(getInputPropertyValue('axisYLabelWidth', widgetProperties)),
    [widgetProperties],
  );

  const colorsPaletteState = useMemo(
    () => getJSONPropertyValue('colorPalette', widgetProperties),
    [widgetProperties],
  );

  const textDirection = useMemo(
    () => getSelectPropertyValue('TextDirection', widgetProperties),
    [widgetProperties],
  );

  const simplifiedTypeAxisX = getSimplifiedType(axisXValues?.[0]?.type);

  const typeFillGraph = useMemo(
    () => getSelectPropertyValue(WidgetPropertyType.typeFillGraph, widgetProperties),
    [widgetProperties],
  );

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  return {
    axisXValues,
    axisXNames,
    isNeedDisplayAggregationInLegend,
    isNeedToDisplayTooltip,
    isNeedToDisplayWidgetTooltip,
    isSmoothedLine,
    isScaleByValueState,
    isNeedToDisplayAxesGuide,
    customAxisXWidth,
    customAxisXHeight,
    colorsPaletteState,
    textDirection,
    customAxisYLabelWidth,
    isNeedToDisplayCircles,
    isNeedAxisXDateLabelAggregation,
    dateLabelAggregation,
    simplifiedTypeAxisX,
    typeFillGraph,
    formatByNumber,
    scaleByNumber,
  };
};
