import { useMemo } from 'react';
import { PivotTablePropertiesProps } from '../interfaces';
import { getBooleanPropertyValue, getInputPropertyValue, getSelectPropertyValue } from '../../helpers';
import { PanelType, WidgetPropertyType } from '../../../../../enums/widget-type';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { PropertyData } from '../../../dropdown-layout/helpers/Property';
import { ColumnWidthType } from '../../../../../enums/widgets-select-property';

const isActive = (val: PropertyData) => val.isActive;

export const usePivotTableProperties = ({
  widgetProperties,
}: PivotTablePropertiesProps) => {
  const isNeedToDisplayMiddleTotal: boolean = useMemo(
    () => getBooleanPropertyValue('displayMiddleTotal', widgetProperties),
    [widgetProperties],
  );
  const isNeedToDisplayTotal: boolean = useMemo(
    () => getBooleanPropertyValue('displayTotal', widgetProperties),
    [widgetProperties],
  );
  const isNeedToDisplaySorting: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displaySorting, widgetProperties),
    [widgetProperties],
  );

  const isNeedToDisplayTooltip: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.displayTooltip, widgetProperties),
    [widgetProperties],
  );

  const axisXValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisX, widgetProperties).filter(isActive),
    [widgetProperties],
  );
  const axisYValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisY, widgetProperties).filter(isActive),
    [widgetProperties],
  );
  const axisZValues: PropertyData[] = useMemo(
    () => getParsedAxisValues(PanelType.axisZ, widgetProperties).filter(isActive),
    [widgetProperties],
  );

  const firstColumnWidth: string = useMemo(
    () => getInputPropertyValue('widthFirstColumn', widgetProperties),
    [widgetProperties],
  ) || '1';
  const dataColumnWidth: string = useMemo(
    () => getInputPropertyValue('widthDataColumn', widgetProperties),
    [widgetProperties],
  ) || '1';
  const resultColumnWidth: string = useMemo(
    () => getInputPropertyValue('widthResultColumn', widgetProperties),
    [widgetProperties],
  ) || '1';
  const offsetColumnWidth: string = useMemo(
    () => getInputPropertyValue('offsetColumn', widgetProperties),
    [widgetProperties],
  ) || '1';

  const valueSize: number = Number(
    getInputPropertyValue('tableValueSize', widgetProperties) || 16,
  );

  const headerSize: number = Number(
    getInputPropertyValue('tableHeaderSize', widgetProperties) || 16,
  );

  const headBackgroundColor: string = getInputPropertyValue(
    'colorHeader',
    widgetProperties,
  ) || '';

  const headTextColor: string = getInputPropertyValue(
    'colorTextHeader',
    widgetProperties,
  ) || '';

  const columnWidthType: ColumnWidthType = useMemo(() => getSelectPropertyValue(
    WidgetPropertyType.columnWidthType,
    widgetProperties,
  ) as ColumnWidthType || ColumnWidthType.coefficients, [widgetProperties]);

  const scaleWidget = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.scaleWidgetOnDashboard, widgetProperties),
    [widgetProperties],
  );
  const isPivotRowsCollapsed: boolean = useMemo(
    () => getBooleanPropertyValue(WidgetPropertyType.pivotRowsCollapsed, widgetProperties),
    [widgetProperties],
  );

  return {
    isNeedToDisplayMiddleTotal,
    isNeedToDisplayTotal,
    isNeedToDisplayTooltip,
    axisXValues,
    axisYValues,
    axisZValues,
    firstColumnWidth,
    dataColumnWidth,
    resultColumnWidth,
    offsetColumnWidth,
    headBackgroundColor,
    headTextColor,
    isNeedToDisplaySorting,
    valueSize,
    headerSize,
    columnWidthType,
    scaleWidget,
    isPivotRowsCollapsed,
  };
};
