import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CustomTooltip from '../../../../uikit/CustomTooltip';

const useStyles = makeStyles(({ isNotAbsoluteHelperText }: Partial<Props>) => ({
  'control-root': {
    '& .MuiInputBase-formControl': {
      borderRadius: 'var(--base-border-radius) !important',
    },

    '& .MuiInputLabel-filled': {
      transform:
        'translate(calc(12px * var(--scale-coefficient)), calc(20px * var(--scale-coefficient)))',
      fontSize: 'calc(14px * var(--scale-coefficient)) !important',
    },

    '& .MuiSvgIcon-root': {
      width: 'calc(18px * var(--scale-coefficient)) !important',
      height: 'calc(24px * var(--scale-coefficient)) !important',
      top: 'calc(50% - 10px * var(--scale-coefficient)) !important',
      right: 'calc(18px * var(--scale-coefficient)) !important',
      transform: 'scale(1.5)',
      color: 'var(--dark-grey)',
    },

    '& .Mui-error .MuiSelect-root': {
      borderColor: 'var(--red-normal)',
    },

    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      position: isNotAbsoluteHelperText ? 'block' : 'absolute',
      bottom: isNotAbsoluteHelperText ? 0 : '-1.4rem',
    },
  },
  'select-root': {
    paddingTop: 'calc(16px * var(--scale-coefficient))',
    paddingBottom: 'calc(10px * var(--scale-coefficient))',
    paddingLeft: 'calc(12px * var(--scale-coefficient))',
    paddingRight: 'calc(34px * var(--scale-coefficient))',
    background: 'var(--white) !important',
    border: '1px solid var(--dark-cyan)',
    borderRadius: 'var(--base-border-radius) !important',
    fontSize: 'calc(14px * var(--scale-coefficient)) !important',
  },
  focused: {
    background: 'inherit',
  },
  hidden: {
    display: 'none',
  },
  menuItem: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding:
      'calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient))',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'calc(16px * var(--scale-coefficient))',
    color: 'var(--black)',
  },
  paper: {
    background: 'var(--white)',
    border: '1px solid #DAE7ED',
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    borderRadius: 'var(--base-border-radius)',
    padding: '2px',

    '& [class*=MuiList-root]': {
      padding: 0,
    },
  },
  required: {
    '&:after': {
      content: "'*'",
      color: 'var(--red-normal)',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translate(110%, 0)'
    },
  },
}));

interface Props {
  formikName?: string;
  label: string;
  className?: string;
  value: any;
  handleChange: (event: any) => void;
  itemList: any[];
  fullWidth?: any;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  hiddenShrink?: boolean;
  isNotAbsoluteHelperText?: boolean;
  customEmptyOption?: string;
}

export const SelectInput: React.FC<Props> = ({
  formikName,
  label = '',
  className = '',
  value,
  handleChange,
  itemList,
  fullWidth,
  disabled = false,
  error,
  required,
  helperText,
  hiddenShrink = true,
  isNotAbsoluteHelperText = false,
  customEmptyOption = '',
}) => {
  const classes = useStyles({ isNotAbsoluteHelperText });
  const selectRef = useRef(document.createElement('div'));

  return (
    <FormControl
      variant="filled"
      fullWidth={fullWidth}
      classes={{ root: `${className} ${classes['control-root']}` }}
      ref={selectRef}
      error={error}
    >
      <InputLabel
        classes={{ shrink: hiddenShrink ? classes.hidden : undefined }}
        id="demo-simple-select-filled-label"
      >
        <CustomTooltip title={label} arrow>
          <span className={clsx({ [classes.required]: required })}>{label}</span>
        </CustomTooltip>
      </InputLabel>
      <Select
        name={formikName}
        classes={{ root: classes['select-root'] }}
        disableUnderline={true}
        MenuProps={{
          classes: { paper: classes.paper },
          style: { width: selectRef.current.clientWidth },
        }}
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={value || ''} // fix for update, when it doesn't have initial value
        onChange={handleChange}
        disabled={disabled}
      >
        {customEmptyOption ? <MenuItem className={classes.menuItem} value="">{customEmptyOption}</MenuItem> : null}
        {itemList.map((item: any) => (
          <MenuItem
            key={item.value}
            value={item.value}
            disabled={item.disabled}
            className={classes.menuItem}
          >
            <CustomTooltip arrow title={item.showValue}>
              <span>{item.showValue}</span>
            </CustomTooltip>
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
