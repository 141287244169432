export interface GraphData {
  isPropertyValue: boolean;
  x: string;
  y: number[];
  slice?: string;
}

export type CurveProps = {
  width: number;
  height: number;
};

export const getX = (d: any): number => d.x;
export const getY = (d: any, index: number = 0): number => d.y[index] || 0;
