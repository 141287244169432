import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import block from 'bem-cn';

import { apiGetMapConnection } from 'src/services/loadersController';
import { toggleSavingAction } from 'src/slices/connection-files-list/connection-files-list';
import { getSourcesFromConnectionIdForNotDataBase } from '../../../../services/sources';
import { useClassButton } from '../../../../hooks/useClassButton';
import FtpFileItem from './ftp-file-item';
import TablePreview from '../../table-preview/table-preview';
import NewFilePanel from '../../new-file-panel/new-file-panel';
import FileTypeSelect from './file-type-select';
import { FileTypesEnum } from '../../../../enums/fyle-type';
import { State } from '../../../../slices/types';
import { SourcesView } from '../../../../enums/sources-view';
import Dictionaries from '../../../../types/dictionaries';
import { mapConnectionAction } from '../../../../slices/map-connection/map-connection';
import './connection-ftp.css';
import { setDeletingAction } from '../../../../slices/main-page/main-page-slice';
import { apiDeleteSource } from '../../../../services/connection';
import DeleteDialog from '../../../../uikit/DeleteDialog/delete-dialog';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import { UploadFileDialog } from '../../../common/upload-file-dialog';
import CommonLoader from '../../../common/common-loader/common-loader';
import Connection from '../../../../types/connection';
import { ExcelFileItem } from './excel-file-item';
import { apiGetFileTypes } from '../../../../services/dictionariesController';
import { CustomButton } from '../../../../uikit/Button';
import { CustomProgress } from '../../../../uikit/Progress';
import {
  InlineTextButton,
} from '../../../../uikit/InlineTextButton';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../../common/icon-svg/icon-svg';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: 'var(--white)',
    },
    background: 'var(--white)',
    border: '1px solid var(--dark-cyan)',
  },
  filter: {
    paddingTop: '19px',
    paddingBottom: '7px',
    height: '20px',
  },
  focused: {
    background: 'var(--white) !important',
    borderBottom: '2px solid var(--primary-color)',
  },
  shrink: {
    color: 'var(--dark-grey) !important',
  },
  fullWidth: {
    width: '100%',
  },
  newFileButton: {
    color: 'var(--primary-color)',
    textTransform: 'initial',
    textDecorationStyle: 'dotted',
    textDecorationColor: 'inherit',
    textDecorationLine: 'underline',

    '&:hover': {
      textDecorationStyle: 'dotted',
      textDecorationColor: 'inherit',
      textDecorationLine: 'underline',
    },
  },
});

const b = block('connection-ftp');

export interface ExcelParameters {
  id: number;
  name: string;
  sourceId: number;
  value: string;
}

interface Props {
  className?: string;
  onCancel: () => void;
  setError: any;
  connectionId: number;
  isLocal: boolean;
  isGoogleSheets: boolean;
  groupId: number;
}

const ConnectionFtp: React.FC<Props> = ({
  className = '',
  onCancel,
  connectionId,
  setError,
  isLocal,
  isGoogleSheets,
  groupId,
}) => {
  const dispatch = useDispatch();
  const isSaving = useSelector(
    (state: State) => state.connectionFilesList.isSaving,
  );
  const loaderId: number =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) ?? 0;

  const classes = useStyles();
  const classButton = useClassButton();

  const [filter, setFilter] = useState<string>('');
  const [files, setFiles] = useState<Connection.FileFtp[]>([]);
  const [previewName, setPreviewName] = useState<string>('');
  const [fileChangeName, setFileChangeName] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [fileTypeId, setFileTypeId] = useState<number>(0);
  const [fileChangeTypeId, setFileChangeTypeId] = useState<number>(0);
  const [fileChangeSourceId, setFileChangeSourceId] = useState<number | null>(
    null,
  );
  const [googleSheetsData, setGoogleSheetsData] =
    useState<Dictionaries.FileTypes | null>(null);
  const [fileTypeIdMap, setFileTypeIdMap] = useState<Dictionaries.FileTypes[]>(
    [],
  );
  const [fileTypeName, setFileTypeName] = useState<FileTypesEnum>();
  const [isOpenFilePanel, setOpenFilePanel] = useState<boolean>(false);
  const [isEditFilePanel, setEditFilePanel] = useState<boolean>(false);
  const [selectError, setSelectError] = useState<boolean>(false);
  const [checkedFiles, setCheckedFiles] = useState<string[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [currentFileId, setCurrentFileId] = useState<number | null>(null);
  const [deleteErrorState, setDeleteErrorState] = useState<string>('');
  const [isDialogUploadFileOpen, setDialogUploadFileOpen] =
    useState<boolean>(false);
  const [localFileForUpdate, setLocalFileForUpdate] =
    useState<Connection.FileFtp | null>(null);

  const handleToggle = (file: string) => () => {
    const currentIndex = checkedFiles.indexOf(file);
    const newChecked = [...checkedFiles];

    if (currentIndex === -1) {
      newChecked.push(file);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFiles(newChecked);
  };

  const checkedFilesSourceIdList = checkedFiles.map(
    (name) => files.find((file) => name.includes(file.name))?.id || 0,
  );
  let checkedFilesFileTypeList = checkedFiles.map(
    (name) =>
      files.find((file) => name.includes(file.name))?.fileType ||
      FileTypesEnum.CSV,
  );

  if (isGoogleSheets) {
    checkedFilesFileTypeList = [FileTypesEnum.GOOGLE_SHEETS];
  }

  const onSave = () => {
    const errorCallback = (e: any) => {
      setError(e);
    };

    dispatch(
      mapConnectionAction.addSourceObjectsByFileType({
        loaderId,
        connectionId,
        tableNames: checkedFiles,
        sourceIdList: checkedFilesSourceIdList,
        fileTypeList: checkedFilesFileTypeList,
        errorCallback,
        callback: onCancel,
      }),
    );
  };

  const closePreview = () => {
    setPreviewName('');
  };

  const openNewFilePanel = () => {
    setOpenFilePanel(true);
  };

  const closeNewFilePanel = () => {
    setOpenFilePanel(false);
  };

  const openEditFilePanel = () => {
    setEditFilePanel(true);
  };

  const closeEditFilePanel = () => {
    setFileChangeName('');
    setEditFilePanel(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog((prevState) => !prevState);
    setDeleteErrorState('');
  };

  const handlerChangeFilter = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => setFilter(target.value.toLowerCase()),
    [],
  );

  const handleNewFileClick = () => {
    if (fileTypeId === 0) {
      setSelectError(true);
    } else {
      setPreviewName('');
      closeEditFilePanel();
      openNewFilePanel();
    }
  };

  const handleUploadLocalFileClick = useCallback(() => {
    setDialogUploadFileOpen(true);
  }, []);

  const handleUploadLocalFileClose = useCallback(() => {
    setDialogUploadFileOpen(false);
    setLocalFileForUpdate(null);
  }, []);

  const handlePreviewClick = (name: string) => () => {
    closeNewFilePanel();
    closeEditFilePanel();
    setFileChangeName('');
    setPreviewName(name);
  };

  const handleDeleteClick = async () => {
    try {
      dispatch(setDeletingAction(true));
      setLoading(true);
      await apiDeleteSource(currentFileId as number);
      toggleDeleteDialog();
      getConnectionFiles();
    } catch (err: any) {
      setDeleteErrorState(
        `Данное соединение используют следующие загрузчики:${err?.response?.data?.message}`,
      );
    } finally {
      dispatch(setDeletingAction(false));
      setLoading(false);
    }
  };

  const handleFileDeleteClick = (id: number) => () => {
    toggleDeleteDialog();
    setCurrentFileId(id);
  };

  const handleFileChangeClick = (name: string) => () => {
    closeNewFilePanel();
    closePreview();
    setFileChangeName(name);
    const fileChange = files.find((file) => file.name === name);
    const sourceId = fileChange?.id;
    const fileChangeType = fileChange?.fileType;
    const currentTypeId = fileTypeIdMap.find(
      (obj) => obj.type === fileChangeType,
    )?.id;
    fileChangeType && setFileTypeName(fileChangeType);
    currentTypeId && setFileChangeTypeId(currentTypeId);

    sourceId && setFileChangeSourceId(sourceId);

    openEditFilePanel();
  };

  const handleFileUpdateClick = (id: number) => () => {
    const updatedFile = files.find((file) => file.id === id);
    setLocalFileForUpdate(updatedFile as Connection.FileFtp);
    setDialogUploadFileOpen(true);
  };

  const getConnectionFiles = useCallback(
    (callback?: () => void) => {
      getSourcesFromConnectionIdForNotDataBase(
        connectionId,
        fileTypeId,
        SourcesView.WITH_PARAMS_AND_FILE_TYPE,
      )
        .then((response) => {
          setLoading(false);
          setFiles(response.data);
          callback && callback();
        })
        .catch((error) => {
          setLoading(false);
          console.error('Request failed:', error);
        });
    },
    [connectionId, fileTypeId],
  );

  useEffect(() => {
    setDeleteErrorState('');
    setFiles([]);
    setCheckedFiles([]);
    setPreviewName('');
    setFileChangeName('');
    setLoading(true);
    getConnectionFiles();
    closeNewFilePanel();
    closeEditFilePanel();
    setSelectError(false);
    setFileChangeSourceId(null);
  }, [getConnectionFiles]);

  useEffect(() => {
    setSelectError(false);
  }, [fileTypeId]);

  const filteredFilesList = files.filter(
    ({ name }: any) => !filter || name.toLowerCase().indexOf(filter) !== -1,
  );

  const deleteFileName = filteredFilesList.find(
    (file) => file.id === currentFileId,
  )?.name;

  const afterUploadLocalFile = () => {
    setLoading(true);
    getConnectionFiles(() => {
      apiGetMapConnection(loaderId).then((data) => {
        if (data) {
          dispatch(toggleSavingAction(false));
          dispatch(mapConnectionAction.addConnection({ loaderId, data }));
        }
      });
    });
  };

  const getFileTypes = useCallback(() => {
    apiGetFileTypes()
      .then((response) => {
        if (response) {
          const filteredResponse = response.filter(
            (fileType) =>
              fileType.type !== FileTypesEnum.GOOGLE_SHEETS,
          );
          setFileTypeIdMap(filteredResponse);

          if (isGoogleSheets) {
            const googleSheetsType = response.find(
              (fileType) =>
                fileType.type === FileTypesEnum.GOOGLE_SHEETS,
            );
            googleSheetsType && setGoogleSheetsData(googleSheetsType);
          }
        }
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  }, [isGoogleSheets]);

  useEffect(() => {
    getFileTypes();
  }, [getFileTypes]);

  useEffect(() => {
    if (googleSheetsData && isGoogleSheets) {
      setFileTypeId(googleSheetsData.id);
      setFileTypeName(googleSheetsData.type);
      setFileChangeTypeId(googleSheetsData.id);
    } else if (!isGoogleSheets) {
      setFileTypeId(0);
      setFileTypeName(undefined);
      setFileChangeTypeId(0);
    }
  }, [googleSheetsData, isGoogleSheets]);

  return (
    <div className={`${b()} ${className}`}>
      {!isLocal && !isGoogleSheets && (
        <FileTypeSelect
          fileTypeId={fileTypeId}
          setFileTypeId={setFileTypeId}
          setFileTypeName={setFileTypeName}
          selectError={selectError}
          fileTypes={fileTypeIdMap}
        />
      )}

      <div className={b('title-container')}>
        <h3 className={b('title')}>Список файлов</h3>

        <InlineTextButton
          title="Новый файл"
          onClick={isLocal ? handleUploadLocalFileClick : handleNewFileClick}
          startIcon={
            <IconSvg
              svg={IconDictionary.PlusRounded}
              fill="var(--primary-color)"
            />
          }
        />
      </div>

      <TextInput
        label="Быстрый поиск"
        fullWidth
        handleChange={handlerChangeFilter}
        searchIcon
      />

      <div className={b('files-section')}>
        {isLoading && <CustomProgress type="linear" />}

        <List dense>
          {filteredFilesList.map(({ name, fileType, id, parameters }: any) =>
            fileType === FileTypesEnum.EXCEL ? (
              <ExcelFileItem
                id={id}
                key={id}
                file={name}
                parameters={parameters}
                previewName={previewName}
                fileChangeName={fileChangeName}
                onPreviewClick={handlePreviewClick}
                onFileChangeClick={handleFileChangeClick}
                onFileUpdateClick={handleFileUpdateClick}
                onFileDeleteClick={handleFileDeleteClick}
                checkedFiles={checkedFiles}
                handleToggle={handleToggle}
                isLocal={isLocal}
              />
            ) : (
              <FtpFileItem
                key={name}
                id={id}
                file={name}
                previewName={previewName}
                fileChangeName={fileChangeName}
                onPreviewClick={handlePreviewClick}
                onFileChangeClick={handleFileChangeClick}
                onFileUpdateClick={handleFileUpdateClick}
                onFileDeleteClick={handleFileDeleteClick}
                checkedFiles={checkedFiles}
                handleToggle={handleToggle}
                isLocal={isLocal}
              />
            ),
          )}
        </List>
      </div>
      <div className={b('buttons')}>
        <CustomButton
          variant="contained"
          type="submit"
          onClick={onSave}
          disabled={!checkedFiles.length}
        >
          Добавить
        </CustomButton>

        <CustomButton variant="outlined" type="reset" onClick={onCancel}>
          Отменить
        </CustomButton>
      </div>

      {previewName && (
        <TablePreview
          className={b('table-preview')}
          name={previewName}
          connectionId={connectionId}
          onClose={closePreview}
        />
      )}

      {isEditFilePanel && fileTypeName && fileChangeSourceId && (
        <NewFilePanel
          className={b('new-file-panel')}
          fileTypeId={fileChangeTypeId}
          fileTypeName={fileTypeName}
          closeNewFilePanel={closeEditFilePanel}
          closeListPanel={closeEditFilePanel}
          loaderId={loaderId}
          connectionId={connectionId}
          setFileTypeId={setFileTypeId}
          sourceId={fileChangeSourceId}
          isLocal={isLocal}
        />
      )}

      {isOpenFilePanel && fileTypeName && (
        <NewFilePanel
          className={b('new-file-panel')}
          fileTypeId={fileTypeId}
          fileTypeName={fileTypeName}
          closeNewFilePanel={closeNewFilePanel}
          closeListPanel={onCancel}
          loaderId={loaderId}
          connectionId={connectionId}
          setFileTypeId={setFileTypeId}
          isLocal={isLocal}
        />
      )}

      <DeleteDialog
        isOpen={showDeleteDialog}
        onClose={toggleDeleteDialog}
        onDelete={handleDeleteClick}
        title="Удаление файла"
        bodyText={`файл ${deleteFileName}`}
        isLoading={isLoading}
        maxWidth="xs"
        error={deleteErrorState}
        isDisableDeleting={Boolean(deleteErrorState)}
      />
      <UploadFileDialog
        fileForUpdate={localFileForUpdate}
        isOpen={isDialogUploadFileOpen}
        handleClose={handleUploadLocalFileClose}
        groupId={groupId}
        afterUploadCallback={afterUploadLocalFile}
      />
      {isSaving && <CommonLoader />}
    </div>
  );
};

export default ConnectionFtp;
