import React, { memo, useCallback, useEffect, useState } from 'react';
import './widget-carousel.css';
import { createStyles, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { DashboardResponse, State, Widget } from '../../../slices/types';
import {
  setCurrentGroupWidgetsAction,
  setCurrentProjectDashboardsAction,
  setCurrentProjectWidgetsAction,
} from '../../../slices/main-page/main-page-slice';
import {
  apiGetAllProjectWidgets,
  apiGetGroupWidgets,
} from '../../../services/widgetController';
import ModalDelete from './modals/modal-delete';
import ModalRename from './modals/modal-rename';
import { apiGetAllProjectDashboards } from '../../../services/dashboardController';
import { useEventListener } from '../../../hooks/useEventListener';
import { EditModal } from './modals/edit-modal';
import { ProjectLink } from './components/project-link';
import { Content } from './components/content';
import { calcTooltipOpenWidth } from './helper';

const useStyles = makeStyles(() =>
  createStyles({
    customMenu: {
      padding: '0 !important',
    },
    customMenuItem: {
      width: '100%',
      overflow: 'unset',
      padding: '0 !important',
      fontSize: 'inherit',
    },
  }),
);

const WidgetCarousel: React.FC = memo(() => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const adviceMode = useSelector((state: State) => state.mapView.adviceMode);

  const projectId: number = useSelector(
    (state: State) => state.mainPage?.currentProject?.id ?? 0,
  );

  const currentProject = useSelector(
    (state: State) => state.mainPage.currentProject,
  );

  const projectGroupId = useSelector(
    (state: State) => state.mainPage?.currentProject?.projectGroupId,
  );

  const [modalType, setModalType] = useState<string | undefined>('');
  const [modalActionType, setModalActionType] = useState<string | undefined>(
    '',
  );
  const [modalWidgetName, setModalWidgetName] = useState<string>('');
  const [modalElementId, setModalElementId] = useState<number | undefined>(0);

  const [openedProjectSettings, setOpenedProjectSettings] =
    useState<boolean>(false);

  const openProjectSettings = useCallback(() => {
    setOpenedProjectSettings(true);
  }, []);

  const closeProjectSettings = useCallback(() => {
    setOpenedProjectSettings(false);
  }, []);

  useEffect(() => {
    if (projectId) {
      apiGetAllProjectWidgets(projectId).then((result: Widget[]) => {
        dispatch(setCurrentProjectWidgetsAction(result));
      });
      apiGetAllProjectDashboards(projectId).then((result: DashboardResponse[]) => {
        dispatch(setCurrentProjectDashboardsAction(result));
      });
      apiGetGroupWidgets(Number(projectGroupId)).then((widgets: Widget[]) => {
        dispatch(setCurrentGroupWidgetsAction(widgets));
      });
    }
  }, [projectId]);

  const [carouselItemMaxWidth, setCarouselItemMaxWidth] = useState<number>(
    calcTooltipOpenWidth(),
  );

  useEventListener('resize', () => {
    setCarouselItemMaxWidth(calcTooltipOpenWidth());
  });

  const handleModalClose = useCallback(() => {
    setModalActionType('');
  }, []);

  const changeModal = useCallback(
    (
      type: string | undefined,
      actionType: string,
      name: string,
      id: number | undefined,
    ) => {
      setModalType(type);
      setModalActionType(actionType);
      setModalWidgetName(name);
      setModalElementId(id);
    },
    [],
  );

  const showModalByName = useCallback(() => {
    switch (modalActionType) {
      case 'delete':
        return (
          <ModalDelete
            isOpen={true}
            name={modalWidgetName}
            type={modalType}
            id={modalElementId}
            handleModalClose={handleModalClose}
          />
        );
      case 'rename':
        return (
          <ModalRename
            isOpen={true}
            type={modalType}
            name={modalWidgetName}
            id={modalElementId}
            handleModalClose={handleModalClose}
          />
        );
      default:
        return null;
    }
  }, [handleModalClose, modalActionType, modalElementId, modalType, modalWidgetName]);

  return (
    <div
      className={cn({
        'widget-carousel': true,
        'widget-carousel--deep': adviceMode,
      })}
    >
      <ProjectLink />
      <Content
        openProjectSettings={openProjectSettings}
        classes={classes}
        carouselItemMaxWidth={carouselItemMaxWidth}
        changeModal={changeModal}
      />

      {modalActionType && showModalByName()}

      {currentProject && openedProjectSettings && (
        <EditModal
          currentProject={currentProject}
          closeProjectSettings={closeProjectSettings}
        />
      )}
    </div>
  );
});

WidgetCarousel.displayName = 'WidgetCarousel';

export default WidgetCarousel;
