import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { constant, times, uniq } from 'lodash';
import clsx from 'clsx';
import { LegendContainer } from './legend/legend-container';
import {
  getBooleanPropertyValue,
  getEnumPropertyValue,
  getJSONPropertyValue,
  getSliceData,
  getSlices,
  getWidgetRenderer,
  isNeedToHiddenContainerOverflow,
  WIDGETS_SLICE_INSIDE,
} from './helpers';
import {
  getAxisValues,
  getParsedAxisValues,
  getSimplifiedType,
} from '../dropdown-layout/helpers/helpers';
import {
  PanelType,
  WidgetPropertyType,
  WidgetType,
} from '../../../enums/widget-type';
import { LegendPositions } from '../../../enums/legend';
import { CSSForLegendPosition } from '../helpers';
import { DashboardProperty, WidgetProperties } from '../../../slices/types';
import {
  FilterField,
  SetFilterField,
  SetMultipleFilterFields,
} from '../../dashboard-page/hooks';
import { Property } from '../dropdown-layout/helpers/Property';
import { formatDate } from './formatting-helpers';
import { DateFormatProperties } from './hooks/initValueFormatter';
import WidgetTypes from '../../../types/widgets';
import { WidgetSimplifiedDataType } from '../../../enums/data-type';

interface WidgetContainerProps {
  widgetProps: any;
  widgetData?: WidgetTypes.Data | null;
  isLoading?: boolean;
  dashboardProps?: any;
  overriddenProperties?: WidgetProperties[];
  setOverriddenProperties?: (newProperties: WidgetProperties[]) => void;
  setFilterField?: SetFilterField;
  setMultipleFilterFields?: SetMultipleFilterFields;
  filterField?: FilterField;
  isActiveFilter?: number | false | undefined;
  isDrillDown?: boolean;
  errorText?: string;
  enhancedParams?: {
    dashboardProperties: DashboardProperty[];
    widgetOverriddenProperties: WidgetProperties[];
  };
}

export const EXCLUDE_ZOOM_WIDGET_TYPES = [
  WidgetType.TABLE,
  WidgetType.PIVOT_TABLE,
  WidgetType.INFORMATION_CARD,
  WidgetType.GANTT_CHART,
  WidgetType.MAP,
  WidgetType.SUNBURST,
];

const WidgetContainer = ({
  widgetProps,
  widgetData,
  isLoading,
  dashboardProps,
  overriddenProperties,
  setOverriddenProperties,
  setFilterField,
  setMultipleFilterFields,
  filterField,
  isActiveFilter,
  isDrillDown,
  errorText,
  enhancedParams
}: WidgetContainerProps) => {
  const projectId: number =
    parseInt(useParams<{ projectId: string }>()?.projectId) ?? 0;

  const isNeedDisplayLegend = getBooleanPropertyValue(
    'displayLegend',
    widgetProps.properties,
  );

  const legendPosition = useMemo(
    () =>
      getEnumPropertyValue<LegendPositions>(
        'legendPosition',
        widgetProps.properties,
      ),
    [widgetProps.properties],
  );

  const chartContainerStyles = useMemo(
    () => CSSForLegendPosition[legendPosition],
    [legendPosition],
  );

  const dataSlice = getJSONPropertyValue(
    'dataSlice',
    widgetProps.properties,
  );
  const dataSliceProperties = dataSlice[0];

  const dateDisplayFormat = getAxisValues(
    WidgetPropertyType.dateDisplayFormat,
    widgetProps.properties,
  );
  const dateFormatProperties: DateFormatProperties = {
    function: dataSliceProperties ? (new Property(dataSliceProperties).getFunction() || '') : '',
    dataType: dataSliceProperties?.type || '',
    dateDisplayFormat: dateDisplayFormat === '[]' ? '' : dateDisplayFormat,
  };

  // для срезов нужно изменить структуру данных
  // так же для легенды в случае срезов внутри виджета отключена любая активность
  let isDisabledActivityLegend = false;
  let widgetPropsSliced;

  const slices = getSlices(widgetProps.type, widgetData?.data);

  if (dataSliceProperties && slices.length && WIDGETS_SLICE_INSIDE.includes(widgetProps.type)) {
    widgetPropsSliced = {
      ...widgetProps,
      properties: widgetProps.properties.filter(
        (prop: any) => prop.name !== PanelType.axisY,
      ),
    };

    isDisabledActivityLegend = true;

    const axisY: Array<any> = getParsedAxisValues(
      PanelType.axisY,
      widgetProps.properties,
    );

    const axisYLegend: Array<any> = [];

    if (widgetProps.type !== WidgetType.BUBBLE_CHART) {
      const widgetDataPropValues = widgetData?.data.filter((value: any) => value.isPropertyValue)|| [];
      const widgetDataValues = widgetData?.data.filter((value: any) => !value.isPropertyValue) || [];

      let newWidgetData: any[] = uniq(widgetDataValues.map((value: any) => value.x))
        .map((x) => ({
          x,
          y: [],
          isPropertyValue: false,
        }));

      const metaDataIndex =  widgetDataPropValues.findIndex((property: any) => property.x === 'metaData');
      const newMetaData = { x: 'metaData', y: [], isPropertyValue: true };
      const metaData = metaDataIndex !== -1 ? { ...widgetDataPropValues[metaDataIndex] } : null;

      let metaItemIndex = 0;

      slices.forEach((slice) => {
        const widgetSliceData = getSliceData(
          widgetProps.type,
          widgetDataValues,
          slice,
        );

        const sliceFormattedName = getSimplifiedType(dateFormatProperties?.dataType) === WidgetSimplifiedDataType.DATE ?
          formatDate(slice, dateFormatProperties) :
          slice;

        metaItemIndex = 0;

        newWidgetData.forEach((data: any) => {
          const dataItem = widgetSliceData.find((item: any) => item.x === data.x);

          if (metaData) {
            const metaIndex = metaData.y.findIndex(
              (item: any) =>
                item[0]?.barProps?.slice === slice &&
                item[0]?.barProps?.x === data.x,
            );

            const barMetaData: any =
              metaIndex === -1
                ? [null]
                : metaData.y[metaIndex];

            if (newMetaData.y[metaItemIndex]) {
              const newValue: any[] = [...newMetaData.y[metaItemIndex]];
              newValue.push(...barMetaData);
              (newMetaData.y[metaItemIndex] as any[]) = newValue;
            } else {
              (newMetaData.y[metaItemIndex] as any[]) = barMetaData;
            }

            metaItemIndex++;
          }

          const y = dataItem?.y || times(axisY.length, constant(0));
          data.y.push(...y);
        });

        axisYLegend.push(
          ...axisY.map((item) => ({ ...item, name: `${sliceFormattedName}: ${item.name}` })),
        );
      });

      metaData && (widgetDataPropValues[metaDataIndex] = newMetaData);

      newWidgetData = newWidgetData.concat(widgetDataPropValues);

      widgetPropsSliced.data = newWidgetData;
      widgetPropsSliced.propertiesData = widgetData?.properties;
    } else {
      widgetPropsSliced = {
        ...widgetProps,
        properties: widgetProps.properties.filter(
          (prop: any) => prop.name !== PanelType.axisY,
        ),
      };

      slices.forEach((slice) => {
        const sliceFormattedName = getSimplifiedType(dateFormatProperties?.dataType) === WidgetSimplifiedDataType.DATE ?
          formatDate(slice, dateFormatProperties) :
          slice;

        axisYLegend.push(
          ...axisY.map((item) => ({ ...item, name: `${sliceFormattedName}: ${item.name}` })),
        );
      });

      widgetPropsSliced.data = [...widgetData!.data];
      widgetPropsSliced.propertiesData = [...widgetData!.properties];
    }

    widgetPropsSliced.properties.push({
      name: PanelType.axisY,
      value: JSON.stringify(axisYLegend),
    });
  }

  let widgetPropsWithData;
  if (widgetPropsSliced) {
    widgetPropsWithData = widgetPropsSliced;
  } else {
    widgetPropsWithData = { ...widgetProps, data: widgetData?.data, propertiesData: widgetData?.properties };
  }

  const options = dashboardProps
    ? {
      withLinkToEditor: true,
      projectId,
      widgetId: widgetProps.id,
      setFilterField,
      setMultipleFilterFields,
      filterField,
      isActiveFilter,
      overriddenProperties,
      setOverriddenProperties,
      isDrillDown,
      dataProperties: widgetData?.properties
    }
    : { isLoading, dataProperties: widgetData?.properties };

  const renderWidgetType = getWidgetRenderer(
    widgetProps?.type || 'EMPTY',
    widgetPropsWithData,
    options,
    errorText,
    enhancedParams
  );

  return (
    <div
      className={
        clsx('screenshot-overflow', {
          'dashboard-grid-item__container': dashboardProps,
          'widget-main__chart-container' : !dashboardProps,
          'chart-container_zoom': !EXCLUDE_ZOOM_WIDGET_TYPES.includes(widgetProps?.type),
        })
      }
      style={{
        ...chartContainerStyles,
        overflow: isNeedToHiddenContainerOverflow(widgetProps?.type || '')
          ? 'hidden'
          : 'auto',
      }}
      key={widgetProps.id}
    >
      {isNeedDisplayLegend && (
        <LegendContainer
          widgetProps={widgetPropsWithData}
          isDisabledActivity={isDisabledActivityLegend}
          dashboardProps={dashboardProps}
          overriddenProperties={overriddenProperties}
          setOverriddenProperties={setOverriddenProperties}
        />
      )}
      {renderWidgetType()}
    </div>
  );
};

export default WidgetContainer;
