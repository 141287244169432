import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Card, CardActions } from '@material-ui/core';
import { CSSProperties, makeStyles } from '@material-ui/styles';
import { useClassButton } from '../../../hooks/useClassButton';
import { isMobilehelper } from '../../../utils/mobile-helper';
import cardWidgetBg from './images/card-widget.svg';
import cardMapBg from './images/card-map.svg';
import cardDashboardBg from './images/card-dashboard.svg';
import IconSvg from '../../common/icon-svg/icon-svg';
import { Project } from '../../../slices/types';
import { LocalStorage } from '../../../enums/local-storage-type';
import './item-loaders-card.css';

import LoaderMenu from '../loader-menu/loader-menu';
import { Roles } from '../../../enums/roles';
import { useRoles } from '../../../hooks/useRoles';
import CustomTooltip from '../../../uikit/CustomTooltip';
import { CustomPopover } from '../../../uikit/Popover/popover';
import {
  CommonDictionary,
  DashboardDictionary,
  WidgetDictionary
} from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { CustomButton } from '../../../uikit/Button';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
});

const buttonStyle: CSSProperties = {
  backgroundColor: 'transparent',
  border: '1px solid var(--primary-color)',
  borderRadius: 4,
};

const bullButtonStyle: CSSProperties = {
  border: 'none',
  backgroundColor: 'transparent',
};

interface ProjectItemProps {
  project: Project;
  groupId: number;
}

const ProjectItem = ({ project, groupId }: ProjectItemProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const props = {
    borderTopColor: 'var(--primary-color)',
  };

  const classes = useStyles(props);
  const classButton = useClassButton(buttonStyle);
  const classBullButton = useClassButton(bullButtonStyle);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTitleClick = (event: any) => {
    localStorage.setItem(
      LocalStorage.ACTIVE_PROJECTS_GROUP_ID,
      groupId.toString(),
    );
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openMenu = Boolean(anchorEl);
  const popoverId = openMenu ? 'simple-popover-card' : undefined;

  let backgroundImage = '';
  let backgroundName = '';
  if (project.mapSetUp) {
    backgroundImage = cardMapBg;
    backgroundName = 'map';
  }
  if (project.widgetsCount) {
    backgroundImage = cardWidgetBg;
    backgroundName = 'widget';
  }
  if (project.dashboardsCount) {
    backgroundImage = cardDashboardBg;
    backgroundName = 'dashboard';
  }

  const renderProjectIcons = () => {
    // const isNotEmptyProject =
    //   project.mapSetUp || project.widgetsCount || project.dashboardsCount;

    return (
      <>
        <CustomTooltip arrow title={project.personal ? CommonDictionary.personalProject : CommonDictionary.publicProject}>
          <div className="project-card__icon">
            <IconSvg
              svg={project.personal ? IconDictionary.PrivateProject : IconDictionary.PublicProject}
              classSVG="project-card__status-icon"
              fill="var(--dark-grey)"
            />
          </div>
        </CustomTooltip>
        {project.mapSetUp ? (
          project.published ? (
            <CustomTooltip arrow title={CommonDictionary.publicLoadingMap}>
              <div className="project-card__icon">
                <IconSvg
                  classSVG="project-card__status-icon"
                  svg={IconDictionary.PublicMap}
                  fill="#F5A834"
                />
              </div>
            </CustomTooltip>
          ) : (
            <CustomTooltip arrow title={`Есть ${CommonDictionary.loadingMap}`}>
              <div className="project-card__icon">
                <IconSvg
                  classSVG="project-card__status-icon"
                  svg={IconDictionary.PrivateMap}
                  fill="var(--dark-grey)"
                />
              </div>
            </CustomTooltip>
          )
        ) : null}
      </>
    );
  };

  const rolesAccess = useRoles({
    isShowEditPointsButton: project.permission !== Roles.Reporter,
    isAllowToGetInProject: project.permission !== Roles.Reporter,
  });

  return (
    <Card
      className={`project-card ${
        project.published ? 'published' : 'unpublished'
      } ${project.personal && 'personal'}`}
      onClick={handleTitleClick}
    >
      <div
        className="project-card__wrapper"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <Link
          className="project-card__project-link"
          to={`/project/${project.id}`}
          style={{
            pointerEvents: rolesAccess.isAllowToGetInProject && !isMobilehelper()
              ? undefined
              : 'none',
          }}
        />
        <div className="project-card__header">
          <div className="project-card__icons">{renderProjectIcons()}</div>
          {project.cacheLastUpdated ? (
            <div className="project-card-update__time">
              <div className="project-card-update__time-icon">
                <IconSvg
                  svg={IconDictionary.TimeRefresh}
                  fill="var(--dark-grey)"
                  width={24}
                  height={24}
                />
              </div>
              <div className="project-card-update__time-date">
                {project.cacheLastUpdated}
              </div>
            </div>
          ) : null}
        </div>

        <div className="project-card__descr">
          <p className="project-card__title">{project.name}</p>
        </div>

        <div className="project-card__fullnes">
          <div className="project-card__count">
            <CustomTooltip
              title={`Количество ${WidgetDictionary.many} = ${project.widgetsCount}`}
              arrow
            >
              <div className="project-card__icon-container">
                <IconSvg
                  svg={IconDictionary.WidgetCount}
                  fill="var(--success-color)"
                />
              </div>
            </CustomTooltip>
            <span className="project-card__count-text">
              {project.widgetsCount}
            </span>
          </div>
          <div className="project-card__count">
            <CustomTooltip
              title={`${DashboardDictionary.dashboardCount} = ${project.dashboardsCount}`}
              arrow
            >
              <div className="project-card__icon-container">
                <IconSvg
                  svg={IconDictionary.Dashboard}
                  fill="var(--primary-color)"
                />
              </div>
            </CustomTooltip>
            <span className="project-card__count-text">
              {project.dashboardsCount}
            </span>
          </div>
        </div>

        <CardActions className="project-card__buttons">
          <CustomButton
            variant="text"
            size="small"
            disabled={!project.dashboardsCount}
            linkTo={`/project/${project.id}/dashboard/${project.firstDashboardId}/preview`}
            startIcon={
              <IconSvg
                svg={IconDictionary.OpenDashboards}
                width={24}
                height={24}
                fill="var(--primary-color)"
              />
            }
            colorType={project.personal ? 'dark' : 'light'}
          >
            Просмотр
          </CustomButton>

          {!isMobilehelper() && (
            <Button
              className={`project-card__more-button ${
                openMenu ? 'active' : classBullButton.defaultSmall
              }`}
              onClick={handleMenuClick}
            >
              <IconSvg svg={IconDictionary.HorizontalMore} width={24} height={24} fill='var(--primary-color)' />
            </Button>
          )}

          <CustomPopover
            id={popoverId}
            open={openMenu}
            onClose={handleCloseMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <LoaderMenu
              project={project}
              handleCloseMenu={handleCloseMenu}
              rolesAccess={rolesAccess}
            />
          </CustomPopover>
        </CardActions>
      </div>
    </Card>
  );
};

export default ProjectItem;
