import { isEqual, isNumber } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Aggregations } from 'src/components/widget-page/dropdown-panel/modals/axis-rectriction/enums';
import { FilterValue } from '../types';
import { DateOptions } from '../../../../slices/types';
import { useDateFilterSettings } from './useDateFilterSettings';

const DEBOUNCE_TIME = 0;

export function useFilter<T>({
  onChangeFilter,
  currentFilter,
  fromOperation,
  toOperation,
  onlyNumbers,
  closeFilterPanel,
  filterOptions,
  isMandatory = false,
}: any) {
  const possibleFromOperations = ['=', '>='];
  const initialOverriddenFunction = filterOptions?.overriddenFunction || '';
  const initSelectDateType = filterOptions?.selectDateType || '';

  const [selectDateType, setSelectDateType] = useState<string>(initSelectDateType);

  const {
    dateFromOperation,
    isNeedToDisplayPeriod,
  } = useDateFilterSettings({ selectDateType, filterOptions });

  const correctFromOperation = fromOperation || dateFromOperation;

  const handleChangeSelectDateType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectDateType(event.target.value);
  }, []);

  const defaultFromValue: T = useMemo(
    () =>
      currentFilter?.length
        ? currentFilter.find((filter: any) =>
          possibleFromOperations.includes(filter.operation),
        )?.value || null
        : null,
    [currentFilter],
  );

  const defaultToValue: T = useMemo(
    () =>
      currentFilter?.length
        ? currentFilter.find((filter: any) => filter.operation === toOperation)
          ?.value || null
        : null,
    [currentFilter],
  );

  const dateValues = {
    'currentDay': {
      momentName: 'day',
      sysName: 'DAY',
      title: 'день'
    },
    'currentWeek': {
      momentName: 'week',
      sysName: 'WEEK',
      title: 'неделя'
    },
    'currentMonth': {
      momentName: 'month',
      sysName: 'MONTH',
      title: 'месяц'
    },
    'currentYear': {
      momentName: 'year',
      sysName: 'YEAR',
      title: 'год'
    }
  };

  const getInitialCurrentDateOption = ((initialOverriddenFunction: string) => {
    const dateOptionName = Aggregations.DATE.find((value) => {
      return value.formula === initialOverriddenFunction;
    })?.name || null;
    let result = null;

    Object.keys(dateValues).forEach((key) => {
      if (dateValues[key as DateOptions].sysName === dateOptionName) {
        result = key;
      }
    });
    return result;
  });

  const [fromValue, setFromValue] = useState<T>(defaultFromValue);
  const [toValue, setToValue] = useState<T>(defaultToValue);

  const [debounceFromValue] = useDebounce<T>(fromValue, DEBOUNCE_TIME);
  const [debounceToValue] = useDebounce<T>(toValue, DEBOUNCE_TIME);
  const [currentDateOption, setCurrentDateOption] =
    useState<DateOptions | null>(getInitialCurrentDateOption(initialOverriddenFunction));

  const overriddenFunction = useMemo(() => {
    if (currentDateOption) {
      return Aggregations.DATE.find((value) => {
        return value.name === dateValues[currentDateOption as DateOptions].sysName;
      })?.formula || '';
    }
    return '';
  }, [currentDateOption]);

  const handleChangeFrom = (value: any) => {
    setFromValue(value);
  };

  const handleChangeTo = (value: any) => {
    setToValue(value);
  };

  useEffect(() => {
    setFromValue(defaultFromValue);
  }, [defaultFromValue]);

  useEffect(() => {
    setToValue(defaultToValue);
  }, [defaultToValue]);

  const isNoChanges =
    debounceFromValue === defaultFromValue &&
    debounceToValue === defaultToValue &&
    overriddenFunction === initialOverriddenFunction &&
    selectDateType === initSelectDateType;

  const isMandatoryConditionCorrect = !isMandatory || debounceFromValue !== null || debounceToValue !== null;
  const isDisabledApplyFilterButton = isNoChanges || !isMandatoryConditionCorrect;


  const handleApply = useCallback(() => {
    closeFilterPanel && closeFilterPanel();

    const filterLessValue: FilterValue = {
      operation: toOperation,
      value: debounceToValue,
    };

    const filterGreatestValue: FilterValue = {
      operation: correctFromOperation,
      value: debounceFromValue,
    };

    let filterValue: any[] = [];

    if (filterGreatestValue.value && filterLessValue.value) {
      filterValue = [filterGreatestValue, filterLessValue];
    }

    if (filterGreatestValue.value && !filterLessValue.value) {
      filterValue = [filterGreatestValue];
    }

    if (!filterGreatestValue.value && filterLessValue.value) {
      filterValue = [filterLessValue];
    }

    if (onlyNumbers) {
      if (
        (/^[-+]?\d*([.,]?\d+)?$/.test(debounceFromValue as string) || debounceFromValue === null) &&
        (/^[-+]?\d*([.,]?\d+)?$/.test(debounceToValue as string) || debounceToValue === null)
      ) {
        onChangeFilter(filterValue);
      }
    } else {
      onChangeFilter(filterValue, selectDateType, overriddenFunction);
    }
  }, [
    debounceFromValue,
    defaultFromValue,
    debounceToValue,
    defaultToValue,
    overriddenFunction,
    initialOverriddenFunction,
    toOperation,
    correctFromOperation,
    onlyNumbers,
    onChangeFilter,
  ]);

  return {
    fromValue,
    dateValues,
    toValue,
    handleChangeFrom,
    handleChangeTo,
    handleApply,
    setCurrentDateOption,
    currentDateOption,
    isDisabledApplyFilterButton,
    selectDateType,
    handleChangeSelectDateType,
    isNeedToDisplayPeriod,
  };
}
