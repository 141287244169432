import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { extent, max, min } from 'd3-array';
import { useCallback, useMemo } from 'react';
import {
  accessorDateX,
  accessorMinY,
  accessorMinZ,
  accessorMaxY,
  accessorMaxZ,
  onlyNegativeDomainValues,
  accessorNumberX,
} from '../helper';
import {
  ColorScale,
  GetVerticalAccessor,
  GetY1,
  GraphData,
  SeriesValue,
  VerticalAccessor,
  VerticalScale,
  XScale,
} from '../types';
import { WidgetSimplifiedDataType } from '../../../../../enums/data-type';

interface UseScales {
  widgetData: GraphData[];
  filteredData: GraphData[];
  xValue: SeriesValue;
  yValues: SeriesValue[];
  zValues: SeriesValue[];
  colors: string[];
  xMax: number;
  yMax: number;
  xBrushMax: number;
  isScaleByValue: boolean;
}

const useGetVerticalScale = (
  accessorMin: VerticalAccessor,
  accessorMax: VerticalAccessor,
  filteredData: GraphData[],
  isScaleByValue: boolean,
  yMax: number,
): VerticalScale => {
  const minData = useMemo(
    () => min(filteredData, accessorMin) || 0,
    [accessorMin, filteredData],
  );
  const maxData = useMemo(
    () => max(filteredData, accessorMax) || 0,
    [accessorMax, filteredData],
  );

  const minDomain = isScaleByValue ? minData : Math.min(minData, 0);
  const maxDomain = isScaleByValue ? maxData : Math.max(maxData, 0);

  return useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [minDomain, maxDomain],
        nice: true,
      }),
    [yMax, minDomain, maxDomain],
  );
};

const getScaleXFactory =
  (data: GraphData[], isDate: boolean, xMax: number) => () => {
    const extentDate = extent(data, accessorDateX) as [Date, Date];
    const extentNumber = extent(data, accessorNumberX) as [number, number];

    const domain = isDate ? extentDate : extentNumber;

    const scaleConfig = {
      range: [0, xMax],
      domain,
    };

    return isDate
      ? scaleTime<number>(scaleConfig)
      : scaleLinear<number>(scaleConfig);
  };

export const useScales = ({
  widgetData,
  filteredData,
  xValue,
  yValues,
  zValues,
  colors,
  xMax,
  yMax,
  xBrushMax,
  isScaleByValue,
}: UseScales) => {
  // scales
  const isDate = xValue.simplifiedType === WidgetSimplifiedDataType.DATE;

  const accessorX = isDate ? accessorDateX : accessorNumberX;

  const xDataValues = filteredData.map((datum) => accessorX(datum));

  const xScale: XScale = useMemo(getScaleXFactory(filteredData, isDate, xMax), [
    xMax,
    filteredData,
    isDate,
  ]);

  const yScale = useGetVerticalScale(
    accessorMinY,
    accessorMaxY,
    filteredData,
    isScaleByValue,
    yMax,
  );
  const zScale = useGetVerticalScale(
    accessorMinZ,
    accessorMaxZ,
    filteredData,
    isScaleByValue,
    yMax,
  );

  const getY1: GetY1 = useCallback(
    (verticalScale: VerticalScale, getAccessor: GetVerticalAccessor) => {
      const onlyNegativeValues = onlyNegativeDomainValues(verticalScale);
      return isScaleByValue
        ? onlyNegativeValues
          ? 0
          : yMax
        : verticalScale(getAccessor(0)({ y: [0], z: [0] } as GraphData) || 0);
    },
    [isScaleByValue, yMax],
  );

  const brushXScale: XScale = useMemo(
    getScaleXFactory(widgetData, isDate, xBrushMax),
    [xMax, filteredData, isDate],
  );
  const brushYScale: VerticalScale = useMemo(
    () =>
      scaleLinear({
        range: [1, 0],
        domain: [0, 1],
        nice: true,
      }),
    [],
  );

  const colorScale: ColorScale = useMemo(() => {
    const keys = yValues.concat(zValues).map((value) => value.key);
    return scaleOrdinal<string, string>({
      domain: keys,
      range: colors,
    });
  }, [colors, yValues, zValues]);

  return {
    xScale,
    yScale,
    zScale,
    getY1,
    brushXScale,
    brushYScale,
    colorScale,
    accessorX,
    xDataValues,
  };
};
