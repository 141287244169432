import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'react-flow': {
    width: '100%',
    height: '100%',

    '& .react-flow__handle': {
      background: 'var(--primary-color)',
      width: 'calc(12px * var(--scale-coefficient))',
      height: 'calc(12px * var(--scale-coefficient))',
      border: 'calc(2px * var(--scale-coefficient)) solid var(--white)',
    },
    '& svg.react-flow__edges': {
      width: 'auto !important',
      height: 'auto !important',
      maxWidth: 'none !important',
      maxHeight: 'none !important',
    },
    '& .react-flow__controls': {
      position: 'absolute',
      top: 'calc(20px * var(--scale-coefficient))',
      right: 'calc(20px * var(--scale-coefficient))',
      bottom: 'initial',
      left: 'initial',
      background: 'var(--white)',
      border: '1px solid var(--light-cyan)',
      boxShadow:
        '0px 2px 10px rgba(0, 0, 0, 0.04), 0px 2px 10px rgba(58, 130, 228, 0.08)',
      borderRadius: 'var(--base-border-radius)',

      '& .react-flow__controls-button': {
        width: 'calc(52px * var(--scale-coefficient))',
        height: 'calc(52px * var(--scale-coefficient))',
        padding: 0,

        '& svg': {
          fill: 'var(--dark-grey)',
          width: 'calc(14px * var(--scale-coefficient)) !important',
          height: 'calc(14px * var(--scale-coefficient)) !important',
        },
        '&:first-child': {
          borderRadius: '4px 4px 0 0',
        },
        '&:last-child': {
          borderRadius: '0 0 4px 4px',
          borderBottom: 'none',
        },
      },
    },
    '& .react-flow__minimap': {
      position: 'absolute',
      right: 20,
      bottom: 20,
    },
  },
});
