import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { BoundFilterGroupProps } from '../../hooks';
import { CustomSwitch } from '../../../../uikit/Switch';
import { CustomCheckbox } from '../../../../uikit/Checkbox';
import { CustomFormControl } from '../../../../uikit/FormControl';
import CustomTooltip from '../../../../uikit/CustomTooltip';

interface FilterListItemProps {
  data: BoundFilterGroupProps;
  toggleFilterGroup: any;
  toggleIsParticipantInFilters: any;
  toggleIsMaster: any;
  index: number;
}

const FilterListItem: React.FC<FilterListItemProps> = ({
  data,
  toggleFilterGroup,
  toggleIsParticipantInFilters,
}) => {
  return (
    <>
      <div className="filter-list-item">
        <div className="filter-chaining-list-item__head">
          <CustomTooltip title={data?.name}>
            <Typography className='filter-title'>
              {data?.name}
            </Typography>
          </CustomTooltip>
          <Box ml='auto'>
            <CustomSwitch
              checked={data?.isActive}
              onChange={() => toggleFilterGroup(data?.id)}
            />
          </Box>
        </div>
        <div className="filter-chaining-list-item__main">
          {data?.participantFilterWidgets?.map((filter) => (
            <div className="flex-container" key={filter.id}>
              <CustomFormControl
                control={
                  <CustomCheckbox
                    checked={filter.isActive}
                    onChange={(event) =>
                      toggleIsParticipantInFilters(data?.id, filter.id)
                    }
                  />
                }
                label={`${filter.name}`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

FilterListItem.displayName = 'FilterListItemChaining';

export default FilterListItem;
