import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../icon-svg/icon-svg';

const useStyles = makeStyles({
  root: (styles: TextInputStyles) => ({
    '&:hover': {
      backgroundColor: styles?.backgroundColor || 'var(--white)',
    },
    backgroundColor: styles?.backgroundColor || 'var(--white)',
    border: styles?.isBorder ? '1px solid var(--dark-cyan)' : 'none',
    borderRadius: 'var(--base-border-radius) !important',
    height: styles.isFixHeight ? 'initial' : '100%',
    overflowY: styles.isFixHeight ? 'initial' : 'scroll',
    paddingTop: styles.isShrink ? '' : 'calc(10px * var(--scale-coefficient))',
    alignItems: styles.isFixHeight ? 'center' : 'flex-start',

    '&.Mui-error': {
      border: styles?.isBorder ? '1px solid var(--red-normal)' : 'none',
    },

    '&.MuiFilledInput-multiline': {
      padding:
        'calc(27px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient))',
      paddingTop: styles.isShrink
        ? 'calc(27px * var(--scale-coefficient))'
        : 'calc(14px * var(--scale-coefficient))',
    },
    '&.Mui-focused > .MuiInputBase-input': {
      height: styles.isFixHeight
        ? styles.isBottomBorderActive ? 'calc(43px * var(--scale-coefficient))' : 'calc(44px * var(--scale-coefficient))'
        : '100%',
    },
    '&.Mui-disabled:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  }),
  filter: (styles: TextInputStyles) => ({
    height: 'calc(44px * var(--scale-coefficient))',
    boxSizing: 'border-box',
    padding: styles.isMultiline
      ? 0
      : 'calc(27px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient))',
    fontSize: 'calc(18px * var(--scale-coefficient))',
  }),
  focused: (styles: TextInputStyles) => ({
    backgroundColor: `${styles?.backgroundColor || 'var(--white)'}!important`,
    borderBottom:
      styles.isBottomBorderActive && styles.isBorder
        ? 'calc(2px * var(--scale-coefficient)) solid var(--primary-color)'
        : styles.isBorder
          ? ''
          : 'none',
  }),
  shrink: (styles: TextInputStyles) => ({
    color: 'var(--dark-grey) !important',
    display: styles.isShrink ? '' : 'none',
  }),
  search: {
    width: 'calc(30px * var(--scale-coefficient)) !important',
    height: 'calc(30px * var(--scale-coefficient)) !important',
    color: 'var(--dark-grey)',
  },
  helperText: (styles: TextInputStyles) => ({
    fontSize: '0.75rem',
    marginLeft: 0,
    position: !styles.isAbsoluteHelperText ? 'inherit' : 'absolute',
    bottom: !styles.isAbsoluteHelperText ? 0 : '-1.4rem',
  }),
  label: (styles: TextInputStyles) => ({
    '&:after': {
      content: styles.required ? "'*'" : '',
      color: 'var(--red-normal)',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translate(110%, 0)',
    },
  }),
});

interface TextInputStyles {
  isShrink?: boolean;
  isBorder?: boolean;
  isFixHeight?: boolean;
  isMultiline?: boolean;
  isBottomBorderActive?: boolean;
  required?: boolean;
  isAbsoluteHelperText?: boolean;
  backgroundColor?: string;
}

export interface TextInputProps {
  name?: string;
  formikName?: string;
  label?: string;
  className?: string;
  value?: any;
  handleChange?: (event: any) => void;
  handleKeyPress?: (event: any) => void;
  searchIcon?: boolean;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  inputProps?: any;
  InputProps?: any;
  styles?: TextInputStyles;
  helperText?: React.ReactNode;
  type?: string;
  defaultValue?: string | number;
  placeholder?: string;
  ref?: any;
  inputRef?: any;
  focused?: boolean;
  required?: boolean;
  isAbsoluteHelperText?: boolean;
  backgroundColor?: string;
  handlePaste?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  handleBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
}

export const TextInput: React.FC<TextInputProps> = ({
  formikName,
  className = '',
  label = '',
  type = 'text',
  handleKeyPress,
  value,
  handleChange,
  searchIcon,
  disabled,
  error,
  fullWidth,
  name,
  multiline,
  rows,
  minRows,
  maxRows,
  InputProps = {},
  inputProps,
  styles,
  helperText,
  defaultValue = '',
  placeholder,
  ref,
  inputRef,
  focused,
  required,
  isAbsoluteHelperText,
  backgroundColor,
  handlePaste,
  handleBlur,
  handleKeyDown,
}) => {
  const defaultStyles = {
    isBorder: true,
    isFixHeight: true,
    isShrink: true,
    isBottomBorderActive: true,
  };
  const customStyles = { ...defaultStyles, ...styles };

  const defaultClasses = useStyles({
    ...customStyles,
    isMultiline: multiline,
    required,
    isAbsoluteHelperText,
    backgroundColor,
  });

  return (
    <TextField
      ref={ref}
      inputRef={inputRef}
      focused={focused}
      name={name}
      fullWidth={fullWidth}
      id={formikName}
      error={error}
      disabled={disabled}
      className={className}
      label={label}
      variant="filled"
      autoComplete="off"
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      onPaste={handlePaste}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onKeyPress={handleKeyPress}
      type={type}
      multiline={multiline}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      helperText={helperText}
      InputLabelProps={{
        classes: { shrink: defaultClasses.shrink, root: defaultClasses.label },
      }}
      InputProps={{
        disableUnderline: true,
        endAdornment: searchIcon && (
          <InputAdornment position="end">
            <IconSvg
              svg={IconDictionary.Magnifier}
              classSVG={defaultClasses.search}
              fill="var(--dark-grey)"
            />
          </InputAdornment>
        ),
        ...InputProps,
        classes: {
          input: defaultClasses.filter,
          root: defaultClasses.root,
          focused: defaultClasses.focused,
        },
      }}
      inputProps={inputProps}
      placeholder={placeholder}
      FormHelperTextProps={{
        classes: { root: defaultClasses.helperText },
      }}
    />
  );
};
