import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderMenuProps } from './loader-menu';
import { useNotificator } from '../../common/snackbar/hooks';
import {
  setChangeModalAction,
  setCheckFilesModalAction,
  setCopyModalAction,
  setCurrentProjectAction,
  setDeleteModalAction,
  setLoadingAction,
  setRightPanelModeAction,
  setShowSaveVersionModalAction,
  setShowRollbackVersionModalAction,
  setSubscribeModalAction,
  setSubscribeMailingModalAction,
} from '../../../slices/main-page/main-page-slice';
import { Project, State } from '../../../slices/types';
import {
  apiBasicChangeProject,
  apiGetProject,
} from '../../../services/projectsController';
import { getAllGroups } from '../../../services';
import { setGroupsAction } from '../../../slices/group-slice/group-slice';
import IconSvg from '../../common/icon-svg/icon-svg';
import { apiExportFile } from '../../../services/export';
import {
  defineFileDataFromResponse,
  fileDownload,
} from '../../../helpers/import-export';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';

export const useLoaderMenu = ({ project, handleCloseMenu, rolesAccess }: LoaderMenuProps) => {
  const dispatch = useDispatch();

  const { showNotification } = useNotificator();

  const filters = useSelector((state: State) => state.mainPage.filters);

  const beforeClickAction = () => {
    handleCloseMenu();
    dispatch(setCurrentProjectAction(project));
  };

  const setIsFavourite = (project: Project, isFavourite: boolean) => {
    dispatch(setLoadingAction(true));
    const errorMessage = isFavourite ? 'Ошибка при добавлении в "Избранное"' : 'Ошибка при удалении из "Избранного"';
    const successMessage = isFavourite ? `${CommonDictionary.projectAdded} в "Избранное"` : `${CommonDictionary.projectDel} из "Избранного"`;

    apiBasicChangeProject(project.id,
      {
        name: project.name,
        projectGroupId: project.projectGroupId,
        personal: project.personal,
        favourite: isFavourite,
      })
      .then(() => {
        getAllGroups(filters).then((response) => {
          dispatch(setGroupsAction(response?.data));

          apiGetProject(Number(project.id)).then((project: Project) => {
            dispatch(setCurrentProjectAction(project));
            showNotification({ message: successMessage, variant: 'success' });
          });
        });
      })
      .catch((err) => {
        console.error(errorMessage, err.response);
        showNotification({ message: errorMessage, variant: 'error' });
      })
      .finally(() => {
        dispatch(setLoadingAction(false));
      });
  };

  const handleAddFavourite = () => {
    beforeClickAction();
    setIsFavourite(project, true);
  };

  const handleDelFavourite = () => {
    beforeClickAction();
    setIsFavourite(project, false);
  };

  const handleClickDelete = () => {
    beforeClickAction();
    dispatch(setDeleteModalAction(true));
  };

  const handleChangeProjectClick = () => {
    beforeClickAction();
    dispatch(setChangeModalAction(true));
  };

  const handleSubscribeClick = () => {
    beforeClickAction();
    dispatch(setSubscribeModalAction(true));
  };

  const handleSubscribeMailingClick = () => {
    beforeClickAction();
    dispatch(setSubscribeMailingModalAction(true));
  };

  const handleClickCopy = () => {
    beforeClickAction();
    dispatch(setCopyModalAction(true));
  };

  const handleClickSaveVersion = () => {
    beforeClickAction();
    dispatch(setShowSaveVersionModalAction(true));
  };

  const handleClickRollbackVersion = () => {
    beforeClickAction();
    dispatch(setShowRollbackVersionModalAction(true));
  };

  const handleClickSchedule = () => {
    beforeClickAction();
    dispatch(setRightPanelModeAction('schedule'));
  };

  const handleClickHistory = () => {
    beforeClickAction();
    dispatch(setRightPanelModeAction('history'));
  };

  const handleClickExport = () => {
    beforeClickAction();
    apiExportFile(project.id).then((res) => {
      const fileData = defineFileDataFromResponse(res);
      fileDownload(fileData.file, fileData.fileName);
    }).catch((error) => {
      if (error.response?.status === 498) {
        dispatch(setCheckFilesModalAction(true));
      } else {
        const defaultErrorMessage = 'Ошибка при загрузке файла';
        showNotification({
          message: error.response?.data?.message || defaultErrorMessage,
          variant: 'error',
        });
      }
    });
  };

  const menuList = !rolesAccess.isShowEditPointsButton ? [
    {
      title: project.favourite ? 'Удалить из избранного' : 'Добавить в избранное',
      icon: <IconSvg svg={IconDictionary.Favourites} fill='var(--dark-grey)'/>,
      onClick: project.favourite ? handleDelFavourite : handleAddFavourite,
      disabled: false,
      isNeedDivider: false,
    }
  ] : [
    {
      title: project.favourite ? 'Удалить из избранного' : 'Добавить в избранное',
      icon: <IconSvg svg={IconDictionary.Favourites} fill='var(--dark-grey)' />,
      onClick: project.favourite ? handleDelFavourite : handleAddFavourite,
      disabled: false,
      isNeedDivider: true,
    },
    {
      title: 'Уведомления',
      icon: <IconSvg svg={IconDictionary.InfoRounded} fill='var(--dark-grey)' />,
      onClick: handleSubscribeClick,
      disabled: false,
      isNeedDivider: false,
    },
    {
      title: 'Рассылка',
      icon: <IconSvg svg={IconDictionary.Mail} fill='var(--dark-grey)' />,
      onClick: handleSubscribeMailingClick,
      disabled: false,
      isNeedDivider: true,
    },
    {
      title: 'Сохранить версию',
      icon: <IconSvg svg={IconDictionary.Save} fill='var(--dark-grey)' />,
      onClick: handleClickSaveVersion,
      disabled: false,
      isNeedDivider: false,
    },
    {
      title: 'Восстановить версию',
      icon: <IconSvg svg={IconDictionary.Rollback} fill='var(--dark-grey)' />,
      onClick: handleClickRollbackVersion,
      disabled: false,
      isNeedDivider: true,
    },
    {
      title: 'Редактировать',
      icon: <IconSvg svg={IconDictionary.EditFile} fill='var(--dark-grey)' />,
      onClick: handleChangeProjectClick,
      disabled: false,
      isNeedDivider: false,
    },
    {
      title: 'Дублировать',
      icon: <IconSvg svg={IconDictionary.Copy} fill='var(--dark-grey)' />,
      onClick: handleClickCopy,
      disabled: false,
      isNeedDivider: false,
    },
    {
      title: 'Выгрузить',
      icon: <IconSvg svg={IconDictionary.DownloadFile} fill='var(--dark-grey)' />,
      onClick: handleClickExport,
      disabled: false,
      isNeedDivider: true,
    },
    {
      title: 'Расписание запусков',
      icon: <IconSvg svg={IconDictionary.TimeSchedule} fill='var(--dark-grey)' />,
      onClick: handleClickSchedule,
      disabled: false,
      isNeedDivider: false,
    },
    {
      title: 'История запусков',
      icon: <IconSvg svg={IconDictionary.TimeRefresh} fill='var(--dark-grey)' />,
      onClick: handleClickHistory,
      disabled: false,
      isNeedDivider: true,
    },
    {
      title: 'Удалить',
      icon: <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />,
      onClick: handleClickDelete,
      disabled: false,
      isNeedDivider: false,
      isDelete: true,
    }
  ];

  return {
    menuList
  };
};
