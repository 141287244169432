import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { ExcelParameters } from './connection-ftp';
import { CustomCheckbox } from '../../../../uikit/Checkbox';
import { CustomPopover } from '../../../../uikit/Popover/popover';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import { CustomPopoverMenu } from '../../../../uikit/PopoverMenu/popover-menu';
import { IconDictionary } from 'src/dictionaries/icon-dictonary/icon-dictionary';

const useStyles = makeStyles({
  item: {
    minHeight: 'calc(20px * var(--scale-coefficient))',
    padding: '0 !important',
    paddingLeft: 'calc(4px * var(--scale-coefficient)) !important',
    paddingRight: 'calc(34px * var(--scale-coefficient)) !important',
    wordBreak: 'break-all',
  },
  itemIcon: {
    minWidth: 'auto',
  },
  checkbox: {
    padding: 0,
    marginLeft: 0,
    minWidth: 'auto',
    
    '& span': {
      marginLeft: '0 !important',
    }
  },
  content: {
    paddingLeft: '10px',

    '& span': {
      fontSize: 'calc(16px * var(--scale-coefficient))',
    }
  },
  action: {
    right: 0,
  },
  iconControl: {
    borderRadius: 0,
    padding: '3px 0',
    marginRight: '6px',
    width: '100%',
    justifyContent: 'flex-start',
  },
  dropDownIconOn: {
    transform: 'rotate(180deg)',
    transition: '0.3s',
    color: 'var(--primary-color)',
  },
  activeExcel: {
    backgroundColor: '#EDF1F8',
  },
  paper: {
    padding: 'calc(5px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient))',
    minWidth: 'calc(200px * var(--scale-coefficient))',
  },
  popover: {
    border: '1px solid var(--dark-cyan)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12)',
    borderRadius: '4px',
  },
  buttonLabel: {
    position: 'absolute',
    marginLeft: '30px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    color: 'var(--black)',
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0,
  }
});

interface ExcelFileItemProps {
  file: string;
  id: number;
  parameters: ExcelParameters[];
  previewName: string;
  fileChangeName: string;
  onPreviewClick: (name: string) => () => void;
  onFileChangeClick: (name: string) => () => void;
  onFileUpdateClick: (id: number) => () => void;
  onFileDeleteClick: (id: number) => () => void;
  checkedFiles: string[];
  handleToggle: (file: string) => () => void;
  isLocal: boolean;
}

export const ExcelFileItem = ({
  file,
  id,
  parameters,
  previewName,
  fileChangeName,
  onPreviewClick,
  onFileChangeClick,
  onFileUpdateClick,
  onFileDeleteClick,
  checkedFiles,
  handleToggle,
  isLocal,
}: ExcelFileItemProps) => {
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpenPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popoverId = useMemo(() => (isOpenPopover ? 'simple-popover' : undefined), [
    isOpenPopover,
  ]);

  const handleMoreButtonClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(containerRef.current);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  
  const classes = useStyles();

  const [showExcel, setShowExcel] = React.useState(false);

  const handleExcelFileClick = () => {
    setShowExcel(!showExcel);
  };

  const menuList = [
    {
      title: 'Редактировать',
      icon: <IconSvg svg={IconDictionary.EditFile} fill='var(--dark-grey)' />,
      onClick: onFileChangeClick(file),
      isNeedDivider: true,
      isHidden: isLocal,
    },
    {
      title: 'Обновить',
      icon: <IconSvg svg={IconDictionary.Refresh} fill='var(--dark-grey)' />,
      onClick: onFileUpdateClick(id),
      isNeedDivider: true,
      isHidden: !isLocal,
    },
    {
      title: 'Удалить',
      icon: <IconSvg svg={IconDictionary.BasketDelete} fill='var(--red-normal)' />,
      onClick: onFileDeleteClick(id),
      isNeedDivider: false,
      isDelete: true
    },
  ];
  
  return (
    <li className={showExcel ? classes.activeExcel : undefined}>
      <ListItem
        className={classes.item}
        button
        onClick={handleExcelFileClick}
        ref={containerRef}
      >
        <ListItemIcon className={classes.itemIcon}>
          <IconSvg
            svg={IconDictionary.RoundedArrowToBottom}
            width={22}
            height={22}
            fill={showExcel ? 'var(--primary-color)' : 'var(--dark-grey)'}
            classSVG={showExcel ? classes.dropDownIconOn : ''}
          />
        </ListItemIcon>
        <ListItemText primary={file} className={classes.content} />
        <ListItemSecondaryAction className={classes.action}>
          <CustomIconButton
            iconButtonProps={{
              children: (
                <IconSvg
                  svg={IconDictionary.VerticalMore}
                  fill="var(--dark-grey)"
                />
              ),
              onClick: handleMoreButtonClick,
            }}
            type={IconButtonType.secondary}
          />

          <CustomPopover
            id={popoverId}
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <CustomPopoverMenu settings={menuList} />
          </CustomPopover>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={showExcel} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {parameters.map((parameter) =>
            parameter.value.split('*').map((name, index) => (
              <ListItem
                key={`${name}-${index}`}
                className={classes.item}
                button
                onClick={handleToggle(`${file}\t${name}`)}
              >
                <ListItemIcon className={classes.checkbox}>
                  <CustomCheckbox
                    edge="start"
                    checked={checkedFiles.includes(`${file}\t${name}`)}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText primary={name} className={classes.content} />
                <ListItemSecondaryAction className={classes.action}>
                  <CustomIconButton
                    iconButtonProps={{
                      children: (
                        <IconSvg
                          svg={IconDictionary.OpenFile}
                          fill={
                            `${file}\t${name}` === previewName
                              ? 'var(--primary-color)'
                              : 'var(--dark-grey)'
                          }
                        />
                      ),
                      onClick: onPreviewClick(`${file}\t${name}`),
                    }}
                    type={IconButtonType.secondary}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )),
          )}
        </List>
      </Collapse>
    </li>
  );
};
