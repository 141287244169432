import React from 'react';
import { Link } from 'react-router-dom';

type LinkWrapProps = {
  linkTo?: string;
  disabled?: boolean;
  children: React.ReactNode;
  absolute?: boolean;
};

export const LinkWrap: React.FC<LinkWrapProps> = ({
  linkTo,
  disabled,
  children,
  absolute,
}) => {
  const getLink = () =>
    absolute ? (
      <a href={linkTo} target="_blank" rel="noreferrer nofollow">{children}</a>
    ) : (
      <Link to={linkTo as string}>{children}</Link>
    );

  return !disabled && linkTo ? getLink() : <>{children}</>;
};
