export interface DictFieldAggregation {
  name: string;
  description: string;
  formula: string | null;
}

export interface DictFieldAggregations {
  [name: string]: DictFieldAggregation[];
}

export const Aggregations: DictFieldAggregations = {
  'DATE': [
    {
      name: 'Не выбрано',
      description: 'Не выбрано',
      formula: null
    },
    {
      name: 'MINUTE',
      description: 'Минута',
      formula: "date_trunc('MINUTE',%s)",
    },
    {
      name: 'HOUR',
      description: 'Час',
      formula: "date_trunc('HOUR',%s)",
    },
    {
      name: 'DAY',
      description: 'День',
      formula: "date_trunc('DAY',%s)",
    },
    {
      name: 'WEEK',
      description: 'Неделя',
      formula: "date_trunc('WEEK',%s)",
    },
    {
      name: 'MONTH',
      description: 'Месяц',
      formula: "date_trunc('MONTH',%s)",
    },
    {
      name: 'YEAR',
      description: 'Год',
      formula: "date_trunc('YEAR',%s)",
    },
  ],
  'NUMBER': [
    {
      name: 'Не выбрано',
      description: 'Не выбрано',
      formula: null
    },
    {
      name: 'COUNT_DISTINCT',
      description: 'Количество уникальных',
      formula: 'COUNT (DISTINCT %s)',
    },
    {
      name: 'SUM',
      description: 'Сумма',
      formula: 'SUM(%s)',
    },
    {
      name: 'COUNT',
      description: 'Количество',
      formula: 'COUNT (%s)',
    },
    {
      name: 'MIN',
      description: 'Минимум',
      formula: 'MIN(%s)',
    },
    {
      name: 'AVG',
      description: 'Среднее',
      formula: 'AVG(%s)',
    },
    {
      name: 'MAX',
      description: 'Максимум',
      formula: 'MAX(%s)',
    },
  ],
  'TEXT': [
    {
      name: 'Не выбрано',
      description: 'Не выбрано',
      formula: null
    },
    {
      name: 'COUNT_DISTINCT',
      description: 'Количество уникальных',
      formula: 'COUNT (DISTINCT %s)',
    },
    {
      name: 'COUNT',
      description: 'Количество',
      formula: 'COUNT (%s)',
    },
  ],
  'BOOLEAN': [
    {
      name: 'Не выбрано',
      description: 'Не выбрано',
      formula: null
    },
    {
      name: 'COUNT_DISTINCT',
      description: 'Количество уникальных',
      formula: 'COUNT (DISTINCT %s)',
    },
    {
      name: 'COUNT',
      description: 'Количество',
      formula: 'COUNT (%s)',
    },
  ],
  '': [],
};
