import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { CircularProgress } from '@material-ui/core';
import { State } from '../../../slices/types';
import Sidebar from '../../common/sidebar';
import './styles.css';
import LabelField from '../../common/label-field/label-field';
import { apiFetchLoaderSources } from '../../../services/widgetController';
import WidgetsTypes from '../../../types/widgets';
import { setCurrentProjectWidgetSourcesAction } from '../../../slices/main-page/main-page-slice';
import { CustomProgress } from '../../../uikit/Progress';

interface WidgetSidebarProps {
  isDragging: boolean;
}

const WidgetMapSidebar: FC<WidgetSidebarProps> = ({ isDragging = false }) => {
  const dispatch = useDispatch();

  const [sourcesList, setSourcesList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const loaderId: number =
    useSelector((state: State) => state.mainPage?.currentProject?.loaderId) ??
    0;

  const isDataCacheCleared: boolean =
    useSelector((state: State) => state.mainPage?.isDataCacheCleared) ?? 0;

  useEffect(() => {
    setLoading(true);
    apiFetchLoaderSources(loaderId, 'MAP').then((result: any) => {
      setSourcesList(result);
      dispatch(setCurrentProjectWidgetSourcesAction(result));
      setLoading(false);
    });

    return () => {
      dispatch(setCurrentProjectWidgetSourcesAction(null));
    };
  }, [loaderId, isDataCacheCleared, dispatch]);

  return (
    <Sidebar>
      {isLoading ? (
        <div className="widget-sidebar__loader">
          <CustomProgress type="circular" size={40} />
        </div>
      ) : (
        <div className="widget-sidebar__droppable">
          <Droppable droppableId="map">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="widget-sidebar__item">
                  {sourcesList &&
                    sourcesList.map(
                      (item: { name: string; id: string }, index) => (
                        <div
                          className={`widget-sidebar__dropdown ${
                            isDragging ? 'widget-sidebar__dragged' : ''
                          }`}
                          key={item.name}
                        >
                          <Draggable
                            draggableId={JSON.stringify(item)}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom:
                                    'calc(16px * var(--scale-coefficient))',
                                }}
                              >
                                <LabelField field={{ ...item, type: 'STRING' }} />
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ),
                    )}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Sidebar>
  );
};

export default WidgetMapSidebar;
