import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './map-icons-modal.css';
import clsx from 'clsx';
import { useClassButton } from '../../../../../hooks/useClassButton';
import { mapModalTypes } from '../../enums';
import CustomDialog from '../../../../../uikit/Dialog/custom-dialog';
import { apiGetMapIcons } from '../../../../../services';
import { getParsedAxisValues } from '../../../dropdown-layout/helpers/helpers';
import { PanelType } from '../../../../../enums/widget-type';
import { setWidgetPropAction } from '../../../../../slices/widget/widget';
import { CustomButton } from '../../../../../uikit/Button';

interface MapIconModalProps {
  onClose: (type: mapModalTypes) => void;
  index: number;
  widget: any;
}

const MapIconsModal = ({ onClose, index, widget }: MapIconModalProps) => {
  const dispatch = useDispatch();
  const classButton = useClassButton();

  const axisXValues = getParsedAxisValues(PanelType.axisX, widget.properties);

  const [iconsList, setIconsList] = useState<string[]>([]);
  const [currentIcon, setCurrentIcon] = useState<string>(JSON.parse(axisXValues[index].storage || '{}').icon || '');

  useEffect(() => {
    apiGetMapIcons()
      .then((res: any) => {
        res.options?.length && setIconsList(res.options);
        !currentIcon.length && setCurrentIcon(JSON.parse(res.defaultValue));
      });
  }, []);

  const handleSave = () => {
    const newAxisXValues = [...axisXValues];
    const currentAxisValue = newAxisXValues[index];
    const storage = JSON.parse(currentAxisValue.storage || '{}');

    const newStorage = { ...storage, icon: currentIcon };
    const newCurrentAxisValue = {
      ...currentAxisValue,
      storage: JSON.stringify(newStorage),
    };

    newAxisXValues[index] = newCurrentAxisValue;

    dispatch(
      setWidgetPropAction({
        name: PanelType.axisX,
        value: JSON.stringify(newAxisXValues),
      }),
    );
  };

  const handleClick = (icon: string) => {
    setCurrentIcon(icon);
  };

  const handleClose = () => {
    onClose(mapModalTypes.iconsSettings);
  };

  return (
    <CustomDialog
      isOpen={true}
      onClose={handleClose}
      title="Настройка точек"
      maxWidth={false}
      fullWidth={false}
    >
      <div className="map-icons-modal__main">
        {iconsList.map((icon: string, index: number) => {
          const isSelected = icon === currentIcon;
          return (
            <div
              key={`map-icons-modal-${index}`}
              className={clsx('map-icons-modal__icon-container', {
                'map-icons-modal__selected': isSelected,
              })}
              role="button"
              onClick={() => handleClick(icon)}
              tabIndex={0}
            >
              <div
                className="map-icons-modal__icon"
                style={{
                  backgroundImage: `url('${icon}')`,
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="dialog-buttons">
        <CustomButton
          variant="contained"
          type="submit"
          onClick={handleSave}
        >
          Сохранить
        </CustomButton>
        <CustomButton
          variant="outlined"
          type="reset"
          onClick={handleClose}
        >
          Отменить
        </CustomButton>
      </div>
    </CustomDialog>
  );
};

export default MapIconsModal;
