import block from 'bem-cn';
import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import './title-section.css';
import { Modes } from '../../helper';
import MapConnection from '../../../../../types/map-connection';
import { apiGetConnectionInfoBySourceId } from '../../../../../services/sources';
import { FileTypesEnum } from '../../../../../enums/fyle-type';
import EditFormContainer from '../filter-section/edit-form/edit-form-container';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { CustomButton } from '../../../../../uikit/Button';
import {
  IconButtonType,
  CustomIconButton,
} from '../../../../../uikit/IconButton';
import { CustomPopover } from '../../../../../uikit/Popover/popover';
import { CustomTabs } from '../../../../../uikit/Tabs';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';
import { CommonDictionary } from '../../../../../dictionaries/naming-dictionary/naming-dictionary';
import { ConnectionTooltip } from '../connection-tooltip';

const useStyles = makeStyles({
  popper: {
    minWidth: 'calc(240px * var(--scale-coefficient))',
    textAlign: 'center',
  },
  list: {
    marginTop: 'calc(5px * var(--scale-coefficient))',
  },
});

interface TitleSectionProps {
  sourceObjectName: string;
  sourceObject: MapConnection.SourceObject;
  closeSelectFieldsPanel: () => void;
  mode: Modes;
  toggleMode: (value: Modes) => void;
  sqlScript?: string | null;
  changeTitle: (title: string) => void;
  setRangeError: (error: string) => void;
  reloadScheme: () => void;
  isEditableMap: boolean;
}

const TitleSection = ({
  changeTitle,
  sourceObjectName,
  sourceObject,
  closeSelectFieldsPanel,
  mode,
  toggleMode,
  sqlScript,
  setRangeError,
  reloadScheme,
  isEditableMap,
}: TitleSectionProps) => {
  const b = block('select-fields-panel');
  const classes = useStyles();
  const [newTitle, setNewTitle] = useState<string>('');
  const [editableTitleMode, setEditableTitleMode] = useState<boolean>(false);

  const isRangeButtonDisabled =
    sourceObject.fileType !== FileTypesEnum.EXCEL &&
    sourceObject.fileType !== FileTypesEnum.CSV &&
    sourceObject.fileType !== FileTypesEnum.GOOGLE_SHEETS;

  const handleEditButtonClick = () => {
    setNewTitle(sourceObjectName);
    setEditableTitleMode(true);
  };
  const handleInputChange = (e: any) => {
    setNewTitle(e.target.value);
  };
  const handleSaveInputClick = () => {
    changeTitle(newTitle.trim());
    setEditableTitleMode(false);
  };
  const handleCancelInputClick = () => {
    setNewTitle('');
    setEditableTitleMode(false);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdditionalFiltersClose = () => {
    setAnchorEl(null);
  };

  const isOpenMenu = Boolean(anchorEl);
  const popoverIdSettings = isOpenMenu ? 'settings-popover' : undefined;

  const tabs = [
    {
      id: Modes.CONSTRUCTOR,
      title: 'Конструктор',
      onClick: () => toggleMode(Modes.CONSTRUCTOR),
    },
    {
      id: Modes.SQL,
      title: CommonDictionary.SQLRequest,
      onClick: () => toggleMode(Modes.SQL),
    },
  ];

  const selectTabIndex = tabs.findIndex((tab) => tab.id === mode);

  return (
    <div className={b('title-section')}>
      <div className={b('title-section-left')}>
        {!editableTitleMode ? (
          <>
            <div
              className={b('title-section-title')}
              style={{ maxWidth: sqlScript ? 'calc(320px * var(--scale-coefficient))' : 'calc(500px * var(--scale-coefficient))' }}
            >
              {sourceObjectName}
            </div>
            {isEditableMap ? <CustomIconButton
              iconButtonProps={{
                children: <IconSvg svg={IconDictionary.EditFile} width={24} height={24} fill="var(--dark-grey)" />,
                onClick: handleEditButtonClick,
              }}
              type={IconButtonType.secondary}
            />: null}
            {sourceObject.sourceId && (
              <div style={{ marginLeft: 'auto' }}>
                <ConnectionTooltip sourceId={sourceObject.sourceId} />
              </div>
            )}
          </>
        ) : (
          <div className={b('title-section-title')}>
            <TextField
              id="title-edit"
              value={newTitle}
              variant="outlined"
              onChange={handleInputChange}
              InputProps={{
                inputProps: {
                  maxlength: '27'
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSaveInputClick}>
                      <IconSvg svg={IconDictionary.RoundedCheck} classSVG={b('title-section-save-icon').toString()} width={24} height={24} fill="var(--success-color)" />
                    </IconButton>
                    <IconButton onClick={handleCancelInputClick}>
                      <IconSvg svg={IconDictionary.CancelEdit} width={24} height={24} fill="var(--dark-grey)" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      </div>
      <div className={b('title-section-right')}>
        <div className={isRangeButtonDisabled ? b('range-button-hidden') : ''}>
          <CustomTooltip
            arrow
            title="Наличие заголовка в Файле и диапазон ячеек"
            classes={{ popper: classes.popper }}
          >
            <div>
              <CustomButton
                id="rangeButton"
                variant="outlined"
                icon={<IconSvg
                  svg={IconDictionary.TableRange}
                  fill="var(--dark-grey)"
                />}
                onClick={handleMenuOpen}
                disabled={isRangeButtonDisabled}
              />
            </div>
          </CustomTooltip>
        </div>

        {sqlScript && (
          <CustomTabs buttons={tabs} size={120} selected={selectTabIndex} />
        )}
        <CustomButton
          size="medium"
          variant="outlined"
          icon={<IconSvg
            svg={IconDictionary.Close}
            fill="var(--dark-grey)"
          />}
          onClick={closeSelectFieldsPanel}
        />
      </div>

      <CustomPopover
        id={popoverIdSettings}
        open={isOpenMenu}
        onClose={handleAdditionalFiltersClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {anchorEl?.id === 'rangeButton' && (
          <EditFormContainer
            handlePopoverClose={handleAdditionalFiltersClose}
            sourceObject={sourceObject}
            setRangeError={setRangeError}
            reloadScheme={reloadScheme}
          />
        )}
      </CustomPopover>
    </div>
  );
};

export default TitleSection;
