import block from 'bem-cn';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import MapConnection from '../../../types/map-connection';
import Dictionaries from '../../../types/dictionaries';
import { Operation } from '../../../enums/operation';
import {
  FormSection,
  NoConnections,
  OperationSubTypeSection,
  TitleSection,
  ValidationAlertSection,
} from './sections';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import {
  addAllOptionsToFieldsMapping,
  clearEmptyFieldsMappingItems,
  createFieldsMappingItem,
  deleteSelectedOptionsFromList,
  getInitialFieldsMapping,
  getMetaFromSourceObjects,
  getOptionsFromSourceObjects,
  getProcessedFieldsList,
  getSchemaList,
  setFieldNames,
} from './logic';

import { defineValidation, getInitialValidation } from './validation/logic';
import Validation from './validation/type';
import { SaveResultPanelProps, SelectOptions, SelectOptionsMeta } from './type';
import { joinTypes, unionTypes } from './data';
import './save-result-panel.css';
import { toggleSavingSelectFieldsAction } from '../../../slices/select-fields-panel/select-fields-panel';
import { State } from '../../../slices/types';
import { useNotificator } from '../../common/snackbar/hooks';
import CustomDialog from '../../../uikit/Dialog/custom-dialog';
import {
  createUnionMappingToSave,
  getDisplayedNameByAliasOrName,
  getStepIdsData,
} from '../../../helpers/loader-page/index';
import { CustomErrorAlert } from '../../../uikit/ErrorAlert';

const SaveResultPanel = ({
  onSave,
  onClose,
  loaderId,
  stepId,
  savingStrategies,
  operationType,
  data,
}: SaveResultPanelProps) => {
  const [error, setError] = useState<null | string>(null);
  const dispatch = useDispatch();
  /*
    INITIAL PART
  */
  const getOperationSubTypes = useCallback(
    (operation: Operation) => {
      switch (operation) {
        case Operation.Map:
          return savingStrategies;
        case Operation.Join:
          return joinTypes;
        case Operation.Union:
          return unionTypes;
        default:
          return savingStrategies;
      }
    },
    [savingStrategies, joinTypes, unionTypes],
  );

  const operationSubTypes: Dictionaries.SavingStrategies[] =
    getOperationSubTypes(operationType);
  const currentStep = useMemo<MapConnection.Step | object>(() => {
    const result = data.steps.find((step) => step.id === stepId);
    if (!result) return {};
    return result;
  }, [data, stepId]);

  const isEditableMap = useSelector(
    (state: State) => state.mainPage.currentProject?.editableMap || false,
  );

  const sourceObjectId = (currentStep as MapConnection.Step)
    .inputSourceObjectsIds[0];
  let targetObjectId = (currentStep as MapConnection.Step).outputSourceObjectId;
  if (operationType === Operation.Join || operationType === Operation.Union) {
    // eslint-disable-next-line prefer-destructuring
    targetObjectId = (currentStep as MapConnection.Step)
      .inputSourceObjectsIds[1];
  }
  const sourceObjectData = data.sourceObjects.find(
    (sourceObject) => sourceObject.id === sourceObjectId,
  );
  const outputId = (currentStep as MapConnection.Step).outputSourceObjectId;

  const initialFieldsMapping = getInitialFieldsMapping(
    (currentStep as MapConnection.Step).fieldsMapping,
    data.sourceObjects,
    sourceObjectId,
    operationType,
    targetObjectId,
  );

  const [fieldsMapping, setFieldsMapping] = useState(initialFieldsMapping);

  const extendFieldsMappingByIds = useCallback(
    (fieldsMapping: MapConnection.FieldsMapping[]) => {
      let newFieldsMapping: MapConnection.FieldsMapping[] = JSON.parse(
        JSON.stringify(fieldsMapping),
      );

      const sourceProcessedFields = data.sourceObjects.find(
        (table) => table.id === sourceObjectId,
      )?.processedFields as MapConnection.ProcessedField[];
      const targetProcessedFields = data.sourceObjects.find(
        (table) => table.id === targetObjectId,
      )?.processedFields as MapConnection.ProcessedField[];

      newFieldsMapping = newFieldsMapping.map((field) => {
        const newField = { ...field };
        newField.sourceFieldName.fieldId = (
          getDisplayedNameByAliasOrName(
            newField.sourceFieldName.fieldName,
            sourceProcessedFields,
            true,
          ) as MapConnection.ProcessedField
        )?.id as string;

        newField.targetField.fieldId = (
          getDisplayedNameByAliasOrName(
            newField.targetField.fieldName,
            targetProcessedFields,
            true,
          ) as MapConnection.ProcessedField
        )?.id as string;
        return newField;
      });
      return newFieldsMapping;
    },
    [data.sourceObjects, sourceObjectId, targetObjectId],
  );

  const isSaving = useSelector(
    (state: State) => state.selectFieldsPanel.isSaving,
  );

  const calculateSelectOptions = useCallback(
    (fieldsMappingParam: any) => {
      const selectOptions = getOptionsFromSourceObjects(
        data.sourceObjects,
        sourceObjectId,
        targetObjectId,
        operationType,
      );
      return deleteSelectedOptionsFromList(
        fieldsMappingParam,
        selectOptions,
        sourceObjectId,
        targetObjectId,
      );
    },
    [data, sourceObjectId, targetObjectId, operationType],
  );

  const isNewTarget: boolean = false; // need to delete this logic

  const initialSelectOptions = calculateSelectOptions(
    initialFieldsMapping,
  ) as SelectOptions;
  const [selectOptions, setSelectOptions] = useState(initialSelectOptions);

  const selectOptionsMeta = getMetaFromSourceObjects(
    data.sourceObjects,
    sourceObjectId,
    targetObjectId,
  ) as SelectOptionsMeta;

  const initialEditableFields: any = {};
  const [editableFields, setEditableFields] = useState(initialEditableFields);

  const initialValidation: Validation.Data = getInitialValidation();
  const [validation, setValidation] = useState(initialValidation);
  /*
    METHOD PART:
    SAVE STRATEGY PANEL METHODS
  */
  const initialSavingStrategy: string =
    (currentStep as MapConnection.Step).operation?.operationSubTypes[0] ||
    savingStrategies[0].name;
  // need to change this below
  const [currentOperationSubType, setCurrentSavingStrategy] = useState(
    initialSavingStrategy,
  );
  const handleOperationSubTypeChange = useCallback((e: any) => {
    setCurrentSavingStrategy(e.target.value);
  }, []);
  const handleAddAllOptions = useCallback(
    (type: any) => () => {
      let newFieldsMapping = addAllOptionsToFieldsMapping(
        type,
        targetObjectId,
        sourceObjectId,
        fieldsMapping,
        selectOptions,
        stepId,
        isNewTarget,
      );
      newFieldsMapping = clearEmptyFieldsMappingItems(newFieldsMapping);
      setSelectOptions(calculateSelectOptions(newFieldsMapping));
      setFieldsMapping(extendFieldsMappingByIds(newFieldsMapping));
      setEditableFields({});
    },
    [
      fieldsMapping,
      calculateSelectOptions,
      selectOptions,
      stepId,
      sourceObjectId,
      targetObjectId,
      isNewTarget,
    ],
  );

  const fieldsMappingToSave =
    operationType === Operation.Union
      ? createUnionMappingToSave(
          fieldsMapping,
          outputId,
          sourceObjectData?.processedFields as MapConnection.ProcessedField[],
        )
      : fieldsMapping;

  /*
    METHOD PART:
    FORM METHODS
    FIELD LIST PART
  */
  const needDrawKeyPicture = useMemo<boolean>(() => {
    return !!operationSubTypes.find((operationSubType) => {
      return operationSubType.name === currentOperationSubType;
    })?.needDrawKeyPicture;
  }, [currentOperationSubType, operationSubTypes]);

  const handleSelectChange = useCallback(
    (e: any, i: any, type: string) => {
      const currentValidation = defineValidation(
        validation,
        fieldsMapping,
        needDrawKeyPicture,
        selectOptionsMeta,
      );
      setValidation(currentValidation);

      const newFieldsMapping = [...fieldsMapping];
      const targetOptions = selectOptions[targetObjectId];
      const sourceOptions = selectOptions[sourceObjectId];

      newFieldsMapping[i] = setFieldNames(
        newFieldsMapping[i],
        type,
        e.target.value,
        sourceOptions,
        targetOptions,
        isNewTarget,
      );

      setSelectOptions(calculateSelectOptions(newFieldsMapping));
      setFieldsMapping(extendFieldsMappingByIds(newFieldsMapping));
    },
    [
      fieldsMapping,
      calculateSelectOptions,
      selectOptions,
      sourceObjectId,
      targetObjectId,
      isNewTarget,
      needDrawKeyPicture,
      selectOptionsMeta,
    ],
  );

  useEffect(() => {
    setValidation(getInitialValidation());
  }, [fieldsMapping, editableFields, currentOperationSubType]);

  const handleKeyClick = useCallback(
    (i: any) => {
      const newFieldsMapping = [...fieldsMapping];
      newFieldsMapping[i].keyForMerge = !newFieldsMapping[i].keyForMerge;
      setFieldsMapping(extendFieldsMappingByIds(newFieldsMapping));
    },
    [fieldsMapping],
  );

  const handleAddConnection = useCallback(() => {
    const newFieldsMappingItem: MapConnection.FieldsMapping =
      createFieldsMappingItem(stepId, sourceObjectId, targetObjectId);
    const newFieldsMapping = [...fieldsMapping];
    newFieldsMapping.push(newFieldsMappingItem);
    setSelectOptions(calculateSelectOptions(newFieldsMapping));
    setFieldsMapping(extendFieldsMappingByIds(newFieldsMapping));
  }, [
    fieldsMapping,
    stepId,
    sourceObjectId,
    targetObjectId,
    calculateSelectOptions,
  ]);

  const handleDeleteConnection = useCallback(
    (i: any) => () => {
      const newFieldsMapping = [...fieldsMapping];
      const deleteElementsCount = 1;

      newFieldsMapping.splice(i, deleteElementsCount);
      setSelectOptions(calculateSelectOptions(newFieldsMapping));
      setFieldsMapping(extendFieldsMappingByIds(newFieldsMapping));
      setEditableFields({});
    },
    [fieldsMapping, calculateSelectOptions],
  );

  const handleCancelInputClick = useCallback(() => {
    setEditableFields({});
  }, []);

  const handleSaveInputClick = useCallback(
    (i: any) => () => {
      const targetOptions = selectOptions[targetObjectId];
      const sourceOptions = selectOptions[sourceObjectId];
      const newFieldsMapping = [...fieldsMapping];

      newFieldsMapping[i] = setFieldNames(
        newFieldsMapping[i],
        'target',
        editableFields[i].name,
        sourceOptions,
        targetOptions,
      );
      setSelectOptions(calculateSelectOptions(newFieldsMapping));
      setFieldsMapping(extendFieldsMappingByIds(newFieldsMapping));
      setEditableFields({});
    },
    [
      fieldsMapping,
      editableFields,
      selectOptions,
      targetObjectId,
      sourceObjectId,
      calculateSelectOptions,
    ],
  );

  const handleInputChange = useCallback(
    (e: any, i: any) => {
      const newEditableFields = { ...editableFields };
      newEditableFields[i].name = e.target.value;
      setEditableFields(newEditableFields);
    },
    [editableFields],
  );
  /*
    METHOD PART:
    FORM METHODS
    BUTTONS PART
  */

  const { showNotification } = useNotificator();

  const handleSaveButtonClick = useCallback(() => {
    const currentValidation = defineValidation(
      validation,
      fieldsMapping,
      needDrawKeyPicture,
      selectOptionsMeta,
    );
    setValidation(currentValidation);

    if (currentValidation.isValid) {
      const afterSave = () => {
        dispatch(toggleSavingSelectFieldsAction(false));
        onSave();
        showNotification({
          message: 'Сохранено',
          variant: 'success',
        });
      };

      const afterErrorSave = (error: string) => {
        dispatch(toggleSavingSelectFieldsAction(false));
        setError(error);
      };

      dispatch(toggleSavingSelectFieldsAction(true));
      const isNewTable = !data.sourceObjects.filter(
        (sourceObject) => sourceObject.id === outputId,
      ).length;

      const processedFields = getProcessedFieldsList(
        operationType,
        (currentStep as MapConnection.Step).inputSourceObjectsIds,
        data.sourceObjects,
        fieldsMappingToSave,
      );

      const schemaList = getSchemaList(
        operationType,
        (currentStep as MapConnection.Step).inputSourceObjectsIds,
        data.sourceObjects,
        fieldsMappingToSave,
      );

      if (operationType === Operation.Join && isNewTable) {
        dispatch(
          mapConnectionAction.addSourceObjectsByJoinResult({
            loaderId,
            outputStepId: outputId,
            schemaList,
            processedFields,
          }),
        );
      }

      if (operationType === Operation.Union) {
        if (isNewTable) {
          dispatch(
            mapConnectionAction.addSourceObjectsByUnionResult({
              loaderId,
              outputStepId: outputId,
              schemaList,
              processedFields,
            }),
          );
        } else {
          const resultUnionTableId = (currentStep as MapConnection.Step)
            .outputSourceObjectId;
          const resultUnionTable = data.sourceObjects.find(
            (source) => source.id === resultUnionTableId,
          );
          if (resultUnionTable) {
            dispatch(
              mapConnectionAction.updateStep({
                data: fieldsMappingToSave,
                stepId,
                newSourceId: outputId,
                loaderId,
                operationSubTypes: [currentOperationSubType],
              }),
            );

            const unionResultAsSourceStep: any = data.steps.find((step) =>
              step.inputSourceObjectsIds.includes(resultUnionTableId),
            );
            const unionResultAsSourceFieldsMapping = (
              unionResultAsSourceStep as MapConnection.Step
            )?.fieldsMapping;
            const unionResultAsSourceStepId = (
              unionResultAsSourceStep as MapConnection.Step
            )?.id;

            const resultUnionProcessedFields = getProcessedFieldsList(
              operationType,
              (currentStep as MapConnection.Step).inputSourceObjectsIds,
              data.sourceObjects,
              fieldsMappingToSave,
              resultUnionTable.processedFields,
            );
            dispatch(
              mapConnectionAction.validateAndSaveMapWithUpdatedProcessedFields(
                {
                  loaderId,
                  sourceObjectId: resultUnionTable.id,
                  sourceObjectName: resultUnionTable.name,
                  processedFields: resultUnionProcessedFields,
                  needRemoveDuplicates: resultUnionTable.needRemoveDuplicates,
                  needRemoveEmptyStrings:
                    resultUnionTable.needRemoveEmptyStrings,
                  needCheckSchema: resultUnionTable.needCheckSchema,
                  schema: schemaList,
                  whereCondition: resultUnionTable.whereCondition,
                  stepId: unionResultAsSourceStepId,
                  stepIdsData: getStepIdsData(
                    resultUnionTable,
                    data.steps,
                    data.sourceObjects,
                  ),
                  fieldsMapping: unionResultAsSourceFieldsMapping,
                  needCache: resultUnionTable.needCache,
                  needForWidget: resultUnionTable.needForWidget,
                  singleSource: resultUnionTable.singleSource,
                  needPushDown: resultUnionTable.needPushDown,
                },
                afterSave,
                afterErrorSave,
                'save',
                false,
              ),
            );
            return;
          }
        }
      }
      dispatch(
        mapConnectionAction.saveMapWithUpdatedStep(
          {
            data: fieldsMappingToSave,
            stepId,
            newSourceId: outputId,
            loaderId,
            operationSubTypes: [currentOperationSubType],
          },
          afterSave,
          afterErrorSave,
        ),
      );
      // Add notify logic
    }
  }, [
    dispatch,
    fieldsMapping,
    stepId,
    loaderId,
    currentOperationSubType,
    onSave,
    validation,
    needDrawKeyPicture,
    outputId,
    data,
    selectOptionsMeta,
  ]);

  const handleValidationButtonClick = useCallback(() => {
    setValidation(
      defineValidation(
        validation,
        fieldsMapping,
        needDrawKeyPicture,
        selectOptionsMeta,
      ),
    );
  }, [fieldsMapping, validation, needDrawKeyPicture, selectOptionsMeta]);

  const handleErrorDialogClose = useCallback(() => {
    setError(null);
    onClose();
    dispatch(
      mapConnectionAction.deleteSourceObject(loaderId, outputId, () => {}),
    );
  }, [loaderId, outputId]);

  const b = block('save-result-panel');
  return (
    <div className={b()}>
      <div className={b('background')} />
      <TitleSection onClose={onClose} />
      <ValidationAlertSection errorText={validation.errorText} />
      <OperationSubTypeSection
        isEditableMap={isEditableMap}
        data={data}
        currentOperationSubType={currentOperationSubType}
        operationSubTypes={operationSubTypes}
        addAllSourceOptions={handleAddAllOptions('source')}
        addAllTargetOptions={handleAddAllOptions('target')}
        operationSubTypeChange={handleOperationSubTypeChange}
        calculateSelectOptions={calculateSelectOptions}
        sourceObjectId={sourceObjectId}
        targetObjectId={targetObjectId}
        isNewTarget={isNewTarget}
        operationType={operationType}
      />
      {fieldsMapping.length ? (
        <FormSection
          fieldsMapping={fieldsMapping}
          handleSelectChange={handleSelectChange}
          handleKeyClick={handleKeyClick}
          needDrawKeyPicture={needDrawKeyPicture}
          selectOptions={selectOptions}
          selectOptionsMeta={selectOptionsMeta}
          editableFields={editableFields}
          setEditableFields={setEditableFields}
          handleCancelInputClick={handleCancelInputClick}
          handleDeleteConnection={handleDeleteConnection}
          handleSaveInputClick={handleSaveInputClick}
          handleInputChange={handleInputChange}
          onClose={onClose}
          handleSaveButtonClick={handleSaveButtonClick}
          handleValidationButtonClick={handleValidationButtonClick}
          handleAddConnection={handleAddConnection}
          isNewTarget={isNewTarget}
          validation={validation}
          operationType={operationType}
          isLoading={isSaving}
          isEditableMap={isEditableMap}
        />
      ) : (
        <NoConnections
          isEditableMap={isEditableMap}
          onAddConnection={handleAddConnection}
        />
      )}

      <CustomDialog
        isOpen={!!error}
        onClose={handleErrorDialogClose}
        maxWidth="xs"
      >
        <div className="dialog-body">
          <CustomErrorAlert
            title={
              error?.includes('Multiple entries with same key')
                ? 'Переименуйте источник'
                : 'Валидация не пройдена'
            }
          >
            {error}
          </CustomErrorAlert>
        </div>
      </CustomDialog>
    </div>
  );
};

export default SaveResultPanel;
