import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CustomButton } from 'src/uikit/Button';
import CustomTooltip from 'src/uikit/CustomTooltip';
import { CssVariables } from '../../../../enums/css-variables';
import { varcss } from '../../../../helpers/ui-helper';
import { PropertyData } from '../../dropdown-layout/helpers/Property';
import { FiltersContainer } from '../filters-container';
import { getParsedAxisValues } from '../../dropdown-layout/helpers/helpers';
import { PanelType } from '../../../../enums/widget-type';
import { State, Widget, WidgetProperties } from '../../../../slices/types';
import './styles.css';
import { FilterGroupProps } from '../../../dashboard-page/hooks';
import { isDashboardPage } from '../../charts/helpers';
import { useDashboardFilters } from '../../../dashboard-page/hooks/useDashboardFilters';
import { SetOverriddenProperties } from '../../../../helpers/dashboard-page/widget';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { IconDictionary } from '../../../../dictionaries/icon-dictonary/icon-dictionary';

interface FiltersButtonProps {
  widgetProps?: Widget;
  setOverriddenProperties?: SetOverriddenProperties;
  overriddenProperties?: WidgetProperties[];
  overriddenPropertiesInherited?: WidgetProperties[];
  dashboardFiltersGroups?: FilterGroupProps[];
  isDrillDown?: boolean;
}


export const FiltersButton: React.FC<FiltersButtonProps> = ({
  widgetProps,
  setOverriddenProperties,
  overriddenProperties,
  overriddenPropertiesInherited,
  dashboardFiltersGroups,
  isDrillDown = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const localWidgetProperties = useSelector(
    (state: State) => state.widget.properties,
  );

  const widgetProperties = widgetProps?.properties || localWidgetProperties;

  const isDashboard = isDashboardPage();
  const { values } = useDashboardFilters();

  const parsedValues: PropertyData[] = getParsedAxisValues(
    PanelType.axisFilter,
    widgetProperties,
  ) // на дашборде очищаем значение фильтра
    .map((filter: PropertyData) => ({ ...filter, filter: isDashboard ? [] : filter.filter }));

  const parsedOverriddenValues: PropertyData[] = getParsedAxisValues(
    PanelType.axisFilter,
    overriddenProperties || [],
  );

  let filtersValues: PropertyData[] = parsedOverriddenValues.length
    ? parsedOverriddenValues
    : parsedValues;

  if (isDashboard && filtersValues) {
    filtersValues = filtersValues
      .filter(({ id, widgetFieldId }: any) => {
        for (const group of values) {
          for (const filter of group.widgetFilters) {
            const filterValueId = id || widgetFieldId;
            if (filter.id === filterValueId) {
              if (group.actual && filter.actual) return false;
            }
          }
        }
        return true;
      });
  }

  const displayedFilters = filtersValues.filter((filter) => !filter.isInherited);

  const filtersCount = displayedFilters.length;

  const hasActiveFilters = Boolean(
    filtersValues.filter((prop) => !!prop?.filter?.length && !prop.isInherited).length
  );

  const handleOpen = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return filtersCount > 0 ? (
    <>
      <CustomTooltip arrow title="Фильтры" autoHide>
        <div>
          <CustomButton
            disabled={!filtersCount || !!anchorEl}
            onClick={handleOpen}
            icon={<IconSvg svg={IconDictionary.WidgetFilter} fill="var(--dark-grey)" />}
            {...props}
            variant="tab"
            size="small"
            className={
              hasActiveFilters ? 'filters-button--active' : ''
            }
            backgroundColor={varcss(CssVariables.WIDGET_BACKDROP_COLOR)}
          />
        </div>
      </CustomTooltip>

      <FiltersContainer
        anchorEl={anchorEl}
        onClose={handleClose}
        values={filtersValues}
        widgetProps={widgetProps}
        setOverriddenProperties={setOverriddenProperties}
        overriddenProperties={overriddenProperties}
        overriddenPropertiesInherited={overriddenPropertiesInherited}
        dashboardFiltersGroups={dashboardFiltersGroups}
      />
    </>
  ) : null;
};
