import { Button, Menu, MenuItem, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { numDecline } from '../../../../utils/functions';
import IconSvg from '../../../common/icon-svg/icon-svg';
import { TextInput } from '../../../common/special-inputs/inputs/text-input';
import { Dashboard, DashboardResponse, State } from '../../../../slices/types';
import WidgetCarouselItem from './widget-carousel-item';
import {
  carouselItemFontSize,
  changeSort,
  compareForSort,
  getShowingTooltipState,
  getSortIcon,
  SortingType,
} from '../helper';
import { PageParams } from '../../../../types/meta';
import { setCurrentDashboardAction } from '../../../../slices/dashboard/dashboard';
import { IconButtonType, CustomIconButton } from '../../../../uikit/IconButton';
import { CommonDictionary, DashboardDictionary } from '../../../../dictionaries/naming-dictionary/naming-dictionary';
import { sort } from 'd3-array';

interface DashboardMenuProps {
  classes: any;
  handleDashboardsMenuClose: () => void;
  dashboardAnchor: Element | null;
  changeModal: (
    type: string | undefined,
    actionType: string,
    name: string,
    id: number | undefined,
  ) => void;
  carouselItemMaxWidth: number;
  dashboardRef: React.MutableRefObject<HTMLDivElement>;
}

export const DashboardMenu = ({
  classes,
  handleDashboardsMenuClose,
  dashboardAnchor,
  changeModal,
  carouselItemMaxWidth,
  dashboardRef,
}: DashboardMenuProps) => {
  const params: PageParams = useParams();
  const dispatch = useDispatch();

  const dashboardsList: DashboardResponse[] | null = useSelector(
    (state: State) => state.mainPage?.currentProjectDashboards ?? null,
  );

  const [dashboardNameFilter, setDashboardNameFilter] = useState('');
  const [dashboardSort, setDashboardSort] = useState<SortingType>('noSort');

  const onDashboardNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDashboardNameFilter(event.target.value.toLowerCase());
  };

  const dashboardSortIcon = getSortIcon(dashboardSort);

  const onDashboardSortChange = () =>
    changeSort(dashboardSort, setDashboardSort);

  const filteredDashboardsList =
    dashboardsList
      ?.filter(
        (db: DashboardResponse) =>
          !dashboardNameFilter ||
          db.name.toLowerCase().indexOf(dashboardNameFilter) !== -1,
      )
      .sort(compareForSort(dashboardSort)) || [];

  const onDashboardClick = (dashboard: DashboardResponse) => {
    dispatch(setCurrentDashboardAction(dashboard));
  };

  return (
    <Menu
      className="widget-footer__popper-container"
      classes={{ list: classes.customMenu }}
      id="dashboard"
      open={true}
      onClose={handleDashboardsMenuClose}
      anchorEl={dashboardAnchor}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Paper>
        <div
          className="widget-footer__popper"
          style={{ width: `${dashboardRef.current.clientWidth}px` }}
        >
          <div className="widget-footer__popper-title">
            <h4 className="widget-footer__title">{DashboardDictionary.titleMany} {CommonDictionary.projectGenitive}</h4>
            <span>
              {`${filteredDashboardsList.length} ${numDecline(
                filteredDashboardsList.length,
                DashboardDictionary.one,
                DashboardDictionary.few,
                DashboardDictionary.many,
              )}`}
            </span>
            <CustomIconButton
              iconButtonProps={{
                children: <IconSvg
                  svg={dashboardSortIcon}
                  fill={dashboardSort === 'noSort' ? 'var(--dark-grey)' : 'var(--primary-hover-color)'}
                />,
                onClick: onDashboardSortChange,
              }}
              type={IconButtonType.secondary}
            />
          </div>
          <div className="widget-footer__search-container">
            <TextInput
              label={DashboardDictionary.dashboardSearch}
              fullWidth
              value={dashboardNameFilter}
              handleChange={onDashboardNameChange}
              searchIcon
            />
          </div>
          <div className="widget-footer__popper-item-container">
            {filteredDashboardsList.map((dashboard: DashboardResponse) => (
              <div
                aria-hidden="true"
                onClick={() => {
                  onDashboardClick(dashboard);
                }}
                key={dashboard.id}
              >
                <div className="widget-footer__popper-item">
                  <MenuItem className={classes.customMenuItem}>
                    <WidgetCarouselItem
                      changeModal={changeModal}
                      isActive={params.id === String(dashboard.id)}
                      name={dashboard.name}
                      type="dashboard"
                      id={dashboard.id}
                      iconName="Dashboard"
                      closeDashboardMenu={handleDashboardsMenuClose}
                      disableHoverTooltipListener={getShowingTooltipState(
                        dashboard.name,
                        carouselItemFontSize,
                        carouselItemMaxWidth,
                      )}
                    />
                  </MenuItem>

                  <Button className="widget-carousel__tootip-value">
                    {dashboard.name}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Paper>
    </Menu>
  );
};
