import block from 'bem-cn';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from 'react-flow-renderer/dist/types';
import './style.css';
import Controls from './controls';
import RenderMap from './render-map';
import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { setEdgesDataListAction } from '../../../slices/map-view/map-view';
import { State } from '../../../slices/types';
import { Operation } from '../../../enums/operation';
import { getDisplayedNameByAliasOrName } from '../../../helpers/loader-page';
import MapConnection from '../../../types/map-connection';
import { apiUpdatePositions } from '../../../services/loadersController';
import { ArrowHeadType } from 'react-flow-renderer/nocss';
import { ReactFlowProvider } from 'react-flow-renderer';
import { CommonDictionary } from '../../../dictionaries/naming-dictionary/naming-dictionary';

enum Position {
  Left = 'left',
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
}

enum OffsetDistance {
  TableX = 600,
  OffsetTop = 25,
}

const getInitialFieldId = (sourceObjects: MapConnection.SourceObject[], sourceObjectId: string, name: string) => {
  const currentSourceObject = sourceObjects.find(
    (sourceObject: any) => sourceObject.id === sourceObjectId,
  );
  return (getDisplayedNameByAliasOrName(
    name,
    currentSourceObject?.processedFields as MapConnection.ProcessedField[],
    true
  ) as MapConnection.ProcessedField)?.initialSourceFieldId;
};

const getInitialSourceIdOfField = (sourceObjects: MapConnection.SourceObject[], initialFieldId: string) => {
  let initialFieldSourceId: string = '';
  sourceObjects.forEach((sourceObject) => {
    sourceObject.processedFields.forEach((processedField) => {
      if (processedField.id === initialFieldId) {
        initialFieldSourceId = sourceObject.id;
      }
    });
  });
  return initialFieldSourceId;
};

const checkIfNeedToAddEdge = (drawLinesSourceObjectsList: any[], sourceObjectId: string, targetToConnect: string) => {
  return !(drawLinesSourceObjectsList.find((item) => {
    return (item?.sourceId === sourceObjectId && item?.targetId === targetToConnect) ||
    (item?.sourceId === targetToConnect && item?.targetId === sourceObjectId);
  }));
};

const getDrawLinesSourceObjectsList = (
  drawLinesSourceObjectsList: any[],
  sourceObjectId: string,
  targetToConnect: string,
  initialFieldsList: string[]
) => {
  const copiedDrawLinesSourceObjectsList: any[] = JSON.parse(JSON.stringify(drawLinesSourceObjectsList));
  const currentObject = copiedDrawLinesSourceObjectsList.find((item) => {
    return (item?.sourceId === sourceObjectId && item?.targetId === targetToConnect) ||
      (item?.sourceId === targetToConnect && item?.targetId === sourceObjectId);
  });
  if (currentObject) {
    initialFieldsList.forEach((field) => {
      if (!currentObject.initialFieldsList.includes(field)) {
        currentObject.initialFieldsList.push(field);
      }
    });
  } else {
    copiedDrawLinesSourceObjectsList.push({
      sourceId: sourceObjectId,
      targetId: targetToConnect,
      initialFieldsList
    });
  }
  return copiedDrawLinesSourceObjectsList;
};

const getTargetIdToConnect = (
  fieldMapping: MapConnection.FieldsMapping,
  nodesArr: MapConnection.SourceObject[],
  sourceObjectId: string,
  filteredNodes: MapConnection.SourceObject[]
) => {
  const sourceId: string = fieldMapping.sourceFieldName.sourceObjectId;
  const sourceFieldName: string = fieldMapping.sourceFieldName.fieldName;
  const targetId: string = fieldMapping.targetField.sourceObjectId;
  const targetFieldName: string = fieldMapping.targetField.fieldName;

  const initialSourceFieldId = getInitialFieldId(nodesArr, sourceId, sourceFieldName);
  const initialTargetFieldId = getInitialFieldId(nodesArr, targetId, targetFieldName);

  const actualSourceId = getInitialSourceIdOfField(filteredNodes, initialSourceFieldId);
  const actualTargetId = getInitialSourceIdOfField(filteredNodes, initialTargetFieldId);

  return sourceObjectId === actualSourceId ? actualTargetId : actualSourceId;
};

const getInitialFieldsList = (
  fieldMapping: MapConnection.FieldsMapping,
  nodesArr: MapConnection.SourceObject[],
) => {
  const sourceId: string = fieldMapping.sourceFieldName.sourceObjectId;
  const sourceFieldName: string = fieldMapping.sourceFieldName.fieldName;
  const targetId: string = fieldMapping.targetField.sourceObjectId;
  const targetFieldName: string = fieldMapping.targetField.fieldName;

  const initialSourceFieldId = getInitialFieldId(nodesArr, sourceId, sourceFieldName);
  const initialTargetFieldId = getInitialFieldId(nodesArr, targetId, targetFieldName);

  return [initialSourceFieldId, initialTargetFieldId];

};

const checkIfStepConnectedWithSource = (
  step: MapConnection.Step,
  nodesArr: MapConnection.SourceObject[],
  sourceObjectId: string,
) => {
  let checkingResult = false;
  if (step.operation.type === Operation.Join) {
    if (step.inputSourceObjectsIds.includes(sourceObjectId)) {
      checkingResult = true;
    }
    step.fieldsMapping.forEach((fieldMapping) => {
      const sourceId: string = fieldMapping.sourceFieldName.sourceObjectId;
      const sourceFieldName: string = fieldMapping.sourceFieldName.fieldName;
      const initialSourceFieldId = getInitialFieldId(nodesArr, sourceId, sourceFieldName);
      const initialSourceId = getInitialSourceIdOfField(nodesArr, initialSourceFieldId);
      if (initialSourceId === sourceObjectId) {
        checkingResult = true;
        return checkingResult;
      }
    });
  }
  return checkingResult;

};

const convertData = (
  nodesArr: MapConnection.SourceObject[],
  stepsList: MapConnection.Step[],
): {elements: Elements, drawLinesList: any[]}=> {
  const filteredNodes: MapConnection.SourceObject[] = nodesArr.filter((sourceObject) => {
    return sourceObject.initialName !== CommonDictionary.JoinResult &&
    sourceObject.initialName !== CommonDictionary.UnionResult;
  });
  const nodes = filteredNodes.map((obj, index) => ({
    id: `${obj.id}data`,
    data: {
      processedFields: obj.processedFields,
      label: obj.name,
      sourceId: obj.sourceId,
      sourceObjectId: obj.id,
    },
    position: obj.modelViewPosition || {
      x: 20,
      y: index * 100 + OffsetDistance.OffsetTop,
    },
    targetPosition: Position.Top,
    sourcePosition: Position.Top,
    type: 'source',
  }));

  const edjes: any[] = [];
  let drawLinesSourceObjectsList: any[] = [];
  filteredNodes.forEach((sourceObject) => {
    stepsList.forEach((step) => {
      const stepIsConnectedWithSource = checkIfStepConnectedWithSource(step, nodesArr, sourceObject.id);
      if (stepIsConnectedWithSource) {
        step.fieldsMapping.forEach((fieldMapping) => {
          const targetToConnect = getTargetIdToConnect(fieldMapping, nodesArr, sourceObject.id, filteredNodes);
          const needToAddEdge = checkIfNeedToAddEdge(drawLinesSourceObjectsList, sourceObject.id, targetToConnect);
          const initialFieldsList = getInitialFieldsList(fieldMapping, nodesArr);
          drawLinesSourceObjectsList = getDrawLinesSourceObjectsList(
            drawLinesSourceObjectsList, sourceObject.id, targetToConnect, initialFieldsList
          );
          if (needToAddEdge && targetToConnect) {
            edjes.push({
              id: `${sourceObject.id}${targetToConnect}data_edje`,
              source: `${sourceObject.id}data`,
              target: `${targetToConnect}data`,
              type: 'custom',
              data: { sourceId:`${sourceObject.id}`, targetId: `${targetToConnect}` },
              arrowHeadType: ArrowHeadType.Arrow,
              style: { stroke: 'var(--primary-color)', strokeWidth: 'calc(2px * var(--scale-coefficient))', cursor: 'pointer' },
            });
          }
        });
      }
    });
  });
  return {
    elements: [...nodes, ...edjes],
    drawLinesList: drawLinesSourceObjectsList
  };
};

interface Props {
  className?: string;
}

const b = block('map');

const DataModelMap: React.FC<Props> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const jsonData = useSelector((state: State) => state.mapConnection);

  const loaderId =
    useSelector((state: State) => state.mainPage.currentProject?.loaderId) || 0;

  const onDragStopCallback = useCallback(
    (nodesState: any, el: any) => {
      dispatch(mapConnectionAction.updateNodesState({
        loaderId, nodesState, isModelDataMode: true
      }));
      const modelDataIdLength = 4;
      const sourceObjectId = el.id?.substring(0, el.id.length - modelDataIdLength);

      apiUpdatePositions(
        sourceObjectId,
        {
          isSourceObject: el.type === 'source',
          isModelView: true,
          position: el.position
        }
      );
    },
    [loaderId],
  );

  const convertedData = useMemo(
    () => convertData(
      jsonData[loaderId]?.sourceObjects || [],
      jsonData[loaderId]?.steps || [],
    ),
    [jsonData[loaderId]?.sourceObjects, jsonData[loaderId]?.steps]);

  const { elements, drawLinesList } = convertedData;

  useEffect(() => {
    dispatch(setEdgesDataListAction(drawLinesList));
  }, [drawLinesList]);

  return (
    <div className={`${b()} ${className}`}>
      <div className={b('top-section')}>
        <Controls />
      </div>
      <div
        className={b('container')}
      >
        {jsonData[loaderId] && ( // @ts-ignore
          <ReactFlowProvider>
            <RenderMap
              dataForElements={elements}
              onDragStopCallback={onDragStopCallback}
            />
          </ReactFlowProvider>
        )}
      </div>
    </div>
  );
};

export default DataModelMap;
