import React from 'react';
import { IconDictionary } from '../../../dictionaries/icon-dictonary/icon-dictionary';
import IconSvg from '../../common/icon-svg/icon-svg';

export const UpdateTime = ({ time }: { time: string }) => {
  return (
    <div className="project-card-update__time">
      <div className="project-card-update__time-icon">
        <IconSvg
          svg={IconDictionary.TimeRefresh}
          fill="var(--dark-grey)"
          width={24}
          height={24}
        />
      </div>
      <div className="project-card-update__time-date">{time}</div>
    </div>
  );
};
