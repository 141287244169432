import { useCallback, useEffect, useState } from 'react';
import { apiGetAggregationFunctions } from '../../../../../services/dictionariesController';
import { WidgetType } from '../../../../../enums/widget-type';
import { getSimplifiedType } from '../../../dropdown-layout/helpers/helpers';

export interface FunctionsDictionary {
  NUMBER: any[];
  TEXT: any[];
  DATE: any[];
}

export const useFieldAggregationsAndFunctions = (
  axisName: string,
  etlSourceId: string,
  isRequiredAggregation: boolean,
  isSingle: boolean,
  changeAggregationCallback?: (aggregation: any) => void
) => {
  const [currentSingleAggregation, setCurrentSingleAggregation] = useState<any>(null);
  const [currentAggregations, setCurrentAggregations] = useState<any[]>([]);
  const [aggregations, setAggregations] = useState<any>({
    NUMBER: [],
    TEXT: [],
    DATE: [],
  });
  const [currentFunctions, setCurrentFunctions] = useState<any[]>([]);
  const [functions, setFunctions] = useState<any>({
    NUMBER: [],
    TEXT: [],
    DATE: [],
  });

  useEffect(() => {
    changeAggregationCallback && changeAggregationCallback(null);
    setCurrentSingleAggregation(null);
    setCurrentAggregations([]);
    setCurrentFunctions([]);
  }, [etlSourceId]);

  const setDefaultAggregationsAndFunctionsList = () => {
    const aggregation: any = {
      TEXT: [],
      DATE: [],
      NUMBER: [],
    };
    const functions: any = {
      TEXT: [],
      DATE: [],
      NUMBER: [],
    };

    Object.keys(aggregation).forEach((key) => {
      apiGetAggregationFunctions({
        type: key,
        axisName,
        widgetType: WidgetType.MAP,
      }).then((result: any) => {
        aggregation[key].push(...result?.aggregations);
        functions[key].push(...result?.functions);
      });
    });

    setAggregations(aggregation);
    setFunctions(functions);
  };

  useEffect(() => {
    setDefaultAggregationsAndFunctionsList();
  }, []);

  const initMultipleCurrentAggregations = (
    currentWidgetFields: any,
    currentWidgetFieldsIndex: any,
  ) => {
    const newAggregations: any[] = [];
    currentWidgetFields.forEach((field: any, index: number) => {
      if (field.etlSourceId === etlSourceId) {
        const fieldType = getSimplifiedType(field.type);

        const aggr = field.aggregation
          ? aggregations[fieldType].find(
              (aggregation: any) => aggregation.formula === field.aggregation,
            )
          : isRequiredAggregation && fieldType === 'NUMBER'
          ? aggregations.NUMBER.find(
              (aggregation: any) => aggregation.name === 'SUM',
            )
          : {};

        newAggregations[currentWidgetFieldsIndex[index]] = aggr;
      }
    });

    setCurrentAggregations(newAggregations);
  };

  const initSingleCurrentAggregation = (currentWidgetField: any) => {
    const fieldType = getSimplifiedType(currentWidgetField.type);

    const aggregation = currentWidgetField.aggregation
      ? aggregations[fieldType].find(
        (aggregation: any) => aggregation.formula === currentWidgetField.aggregation,
      )
      : isRequiredAggregation ? aggregations[fieldType][0] : null;

    setCurrentSingleAggregation(aggregation);
    changeAggregationCallback && changeAggregationCallback(aggregation);
  };

  const initCurrentAggregations = (
    currentWidgetFields?: any[],
    currentWidgetFieldsIndex?: number[],
    currentWidgetField?: any,
  ) => {
    const simpleType = getSimplifiedType(currentWidgetField?.type);

    if (!currentAggregations.length && !isSingle) {
      initMultipleCurrentAggregations(currentWidgetFields, currentWidgetFieldsIndex);
    } else {
      const formulasForType = (aggregations?.[simpleType] || []).map((aggregation: any) => aggregation.formula)
      if (
        isSingle &&
        aggregations?.[simpleType]?.length &&
        currentWidgetField &&
        !formulasForType.includes(currentSingleAggregation?.formula)
      ) {
        initSingleCurrentAggregation(currentWidgetField);
      }
    }
  };

  const initCurrentFunctions = (
    currentWidgetFields: any[],
    currentWidgetFieldsIndex: number[],
  ) => {
    if (!currentFunctions.length && functions?.DATE.length) {
      const newFunctions: any[] = [];
      currentWidgetFields.forEach((field: any, index) => {
        if (field.etlSourceId === etlSourceId) {
          const fieldType = getSimplifiedType(field.type);

          const func = field.function
            ? functions.DATE.find(
                (item: any) => item.formula === field.function,
              )
            : isRequiredAggregation && fieldType === 'DATE'
            ? functions.DATE.find((item: any) => item.name === 'DAY')
            : {};
          newFunctions[currentWidgetFieldsIndex[index]] = func;
        }
      });
      setCurrentFunctions(newFunctions);
    }
  };

  const addEmptyFunctionOrAggregation = (
    isFunction: boolean,
    index: number,
  ) => {
    if (isFunction) {
      const newFunctions = [...currentFunctions];
      newFunctions[index] = {};
      setCurrentFunctions(newFunctions);
    } else {
      const newAggregations = [...currentAggregations];
      newAggregations[index] = {};
      setCurrentAggregations(newAggregations);
    }
  };

  const onChangeAggregationHandle = useCallback(
    (aggregation: any, index: number) => {
      if (isSingle) {
        setCurrentSingleAggregation(aggregation);
        changeAggregationCallback && changeAggregationCallback(aggregation);
      } else {
        addEmptyFunctionOrAggregation(true, index);

        const newAggregations = [...currentAggregations];
        newAggregations[index] = aggregation;
        setCurrentAggregations(newAggregations);
      }
    },
    [currentAggregations],
  );

  const onChangeFunctionHandle = useCallback(
    (func: any, index: number) => {
      addEmptyFunctionOrAggregation(false, index);

      const newFunctions = [...currentFunctions];
      newFunctions[index] = func;
      setCurrentFunctions(newFunctions);
    },
    [currentFunctions],
  );

  const onDeleteAggregationHandle = useCallback(
    (index: number, isDeleteField?: boolean) => {
      const newAggregations = [...currentAggregations];
      if (isDeleteField) {
        newAggregations[index] = null;
      } else {
        newAggregations[index] = {};
      }

      setCurrentAggregations(newAggregations);
    },
    [currentAggregations],
  );

  const onDeleteFunctionHandle = useCallback(
    (index: number, isDeleteField?: boolean) => {
      const newFunctions = [...currentFunctions];
      if (isDeleteField) {
        newFunctions[index] = null;
      } else {
        newFunctions[index] = {};
      }

      setCurrentFunctions(newFunctions);
    },
    [currentFunctions],
  );

  const clearFunctionsAndAggregations = () => {
    setCurrentAggregations([]);
    setCurrentFunctions([]);
  };

  const resetCurrentSingleAggregation = (
    currentWidgetField: any,
  ) => {
    const currentWidgetFieldType = getSimplifiedType(currentWidgetField?.type);

    const newAggregation = currentWidgetFieldType !== 'DATE' ? aggregations[currentWidgetFieldType]?.find(
      (aggregation: any) => aggregation.name === 'SUM') : aggregations[currentWidgetFieldType]?.[0];

    setCurrentSingleAggregation(newAggregation);
    changeAggregationCallback && changeAggregationCallback(newAggregation);
  };

  return {
    aggregations,
    currentAggregations,
    onChangeAggregationHandle,
    initCurrentAggregations,
    onDeleteAggregationHandle,
    functions,
    currentFunctions,
    onChangeFunctionHandle,
    initCurrentFunctions,
    onDeleteFunctionHandle,
    clearFunctionsAndAggregations,
    currentSingleAggregation,
    resetCurrentSingleAggregation,
  };
};
