import React from 'react';
import { CalloutLineLabel } from './callout-line-label/callout-line-label';
import {
  defaultLineLength,
  labelWidth,
  lineRadius,
  lineStart,
  lineWidth,
  maxLabelHeight,
  sliceLineLength,
  zLabelWidth,
} from './callout-line-settings';

interface CalloutLineProps {
  color: string;
  x: number;
  y: number;
  radius: number;
  legend: CalloutLineLegendProps;
  angleDifference: number;
  arcIndex: number;
  innerRadius?: number;
  hasSpaceForLine?: boolean;
}

interface CalloutLineLegendProps {
  label: string;
  count?: number | null;
  percent?: string | null;
  valueLabel?: string;
  zValueLabel?: string;
  zCount?: number | null;
  zPercent?: string | null;
  displayPercentOnLegend?: boolean
  displayValueOnLegend?: boolean
  displayTextOnLegend?: boolean
}

export function CalloutLine({
  color,
  x,
  y,
  radius,
  innerRadius,
  legend,
  angleDifference,
  hasSpaceForLine = true,
  arcIndex,
}: CalloutLineProps) {
  const currentLabelWidth = legend.zCount ? zLabelWidth : labelWidth;
  const lineLength = defaultLineLength;
  const linePadding = innerRadius ? defaultLineLength : sliceLineLength;
  const labelHeight =
    legend.count || legend.percent ? maxLabelHeight : maxLabelHeight / 2;
  const xTransitionCoeff = x / (radius / 2);
  const yTransitionCoeff = y / (radius / 2);
  const lineAngleX = xTransitionCoeff * lineLength;
  const lineAngleY = yTransitionCoeff * lineLength;
  const lineEndX =
    lineAngleX > 0 ? lineAngleX + lineWidth : lineAngleX - lineWidth;
  const lineEndY = lineAngleY;
  const labelX = lineEndX > 0 ? lineEndX : lineEndX - currentLabelWidth;
  const labelY = lineEndY - labelHeight / 2;
  const halfRadius = radius / 2;
  const translateLength = innerRadius
    ? halfRadius + linePadding
    : halfRadius + halfRadius / 2 + linePadding;

  const visibilityCoeff = () => {
    if (radius > 400) return 1;
    if (radius > 250) return 2;
    if (radius > 150) return 3;
    return 4;
  };

  const isHideLine =
    !hasSpaceForLine ||
    angleDifference < 0.3 &&
    Math.abs(yTransitionCoeff) > 0.4 &&
    arcIndex % visibilityCoeff();
  return (
    <>
      <g
        transform={`translate(${xTransitionCoeff * translateLength}, ${
          yTransitionCoeff * translateLength
        })`}
        style={{ visibility: isHideLine ? 'hidden' : 'visible' }}
      >
        <circle cx={lineStart} cy={lineStart} r={lineRadius} fill={color} />
        <line
          x1={lineStart}
          y1={lineStart}
          x2={lineAngleX}
          y2={lineAngleY}
          stroke={color}
        />
        <line
          x1={lineAngleX}
          y1={lineAngleY}
          x2={lineEndX}
          y2={lineEndY}
          stroke={color}
        />
        <foreignObject
          x={labelX}
          y={labelY}
          width={currentLabelWidth}
          height={labelHeight}
        >
          <CalloutLineLabel
            displayPercentOnLegend={legend.displayPercentOnLegend}
            displayValueOnLegend={legend.displayValueOnLegend}
            displayTextOnLegend={legend.displayTextOnLegend}
            zValueLabel={legend.zValueLabel}
            zCount={legend.zCount}
            zPercent={legend.zPercent}
            valueLabel={legend.valueLabel}
            text={legend.label}
            count={legend.count}
            percent={legend.percent}
            align={x < 0 ? 'end' : 'start'}
            width={currentLabelWidth}
          />
        </foreignObject>
      </g>
    </>
  );
}
