import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import block from 'bem-cn';

import { toggleSavingAction } from 'src/slices/connection-files-list/connection-files-list';
import { apiGetMapConnection } from 'src/services/loadersController';
import {
  apiGetFilesForPreview,
  createNewFileSource,
} from '../../../services/sources';
import { apiSetSourceParams } from '../../../services/source-parameters';

import { mapConnectionAction } from '../../../slices/map-connection/map-connection';
import { useConnectionParamsById } from '../../../hooks/useConnectionParamsById';
import { useValuesBySourceId } from '../../../hooks/useValuesBySourceId';
import Connection from '../../../types/connection';
import { FileTypesEnum } from '../../../enums/fyle-type';

import FileForm from './file-form';
import FileList from './file-list';
import TablePreview from '../table-preview/table-preview';
import { fieldName } from './data';
import { getInitialValues, getValidationSchema } from './utils';
import './new-file-panel.css';
import { CustomProgress } from '../../../uikit/Progress';

interface Props {
  className?: string;
  loaderId: number;
  fileTypeId: number;
  fileTypeName: FileTypesEnum;
  closeNewFilePanel: () => void;
  closeListPanel: () => void;
  connectionId: number;
  setFileTypeId: Dispatch<SetStateAction<number>>;
  sourceId?: number;
  isLocal: boolean;
}

const b = block('new-file-panel');

const NewFilePanel: React.FC<Props> = ({
  className = '',
  loaderId,
  fileTypeId,
  fileTypeName,
  closeNewFilePanel,
  closeListPanel,
  connectionId,
  setFileTypeId,
  sourceId: sourceIdProps,
  isLocal,
}) => {
  const dispatch = useDispatch();

  const {
    data: paramsData,
    error: errorParams,
    setId,
    loading: loadingRequestForParams,
  } = useConnectionParamsById(fileTypeId, true);
  const {
    data: valuesData,
    error: errorValues,
    setId: setSourceId,
    loading: loadingRequestForValues,
  } = useValuesBySourceId(sourceIdProps);
  const [errorSubmit, setErrorSubmit] = useState<string | null>(null);
  const [files, setFiles] = useState<string[] | null>(null);
  const [previewFile, setPreviewFile] = useState<string | null>(null);
  const [checkedLists, setCheckedLists] = useState<string[]>([]);
  const [formParams, setFormParams] = useState<
    {
      name: string;
      value: string | boolean;
    }[]
  >();

  const typedData = paramsData as Connection.FileParam[];
  const initialValues = getInitialValues(typedData, valuesData);
  const validationSchema = getValidationSchema(typedData);
  const isDisabledAddButton = !files;
  const isNewFile = !valuesData.length;
  const title = isNewFile ? 'Добавление нового файла' : 'Редактирование файла';

  const onSuccessCreateFile = (name: string, sourceId: number) => {
    let tableNames: string[] = [name];
    let sourceIdList: number[] = [sourceId];
    let fileTypeList: FileTypesEnum[] = [fileTypeName];

    if (fileTypeName === FileTypesEnum.EXCEL) {
      if (!checkedLists.length) {
        closeNewFilePanel();
        setFileTypeId(0);
        return null;
      }
      tableNames = checkedLists.map((listName) => `${name}\t${listName}`);
      sourceIdList = checkedLists.map((listName) => sourceId);
      fileTypeList = checkedLists.map((listName) => FileTypesEnum.EXCEL);
    }
   
    dispatch(
      mapConnectionAction.addSourceObjectsByFileType({
        loaderId,
        connectionId,
        tableNames,
        sourceIdList,
        fileTypeList,
        callback: closeListPanel,
      }),
    );
  };

  const addFileToSourceList = (values: {
    [key: string]: string | boolean;
  }) => async () => {
    let workingDirectory = values[fieldName.WORKING_DIRECTORY];

    if (!/^\//.test(workingDirectory as string))
      workingDirectory = `/${  workingDirectory}`;
    if (!/\/$/.test(workingDirectory as string)) workingDirectory += '/';

    let name;

    if (fileTypeName === FileTypesEnum.GOOGLE_SHEETS) {
      name = `${values[fieldName.SHEET]}`;
    } else {
      name = `${fileTypeName}:/${workingDirectory}${
        values[fieldName.MASK]
      }`;
    }
    const params = Object.entries(values).map(([key, value]) => ({
      name: key,
      value,
    }));

    if (fileTypeName === FileTypesEnum.EXCEL) {
      files && params.push({ name: 'EXCEL_LISTS', value: files.join('*') });
    }

    try {
      const sourceId = await createNewFileSource(
        connectionId,
        name,
        fileTypeName,
        params,
      );

      onSuccessCreateFile(name, sourceId);
    } catch (err: any) {
      setErrorSubmit(err?.response?.data?.message);
    }
  };

  const changeSource = (values: {
    [key: string]: string | boolean;
  }) => async () => {

    const idMapParamValues: Record<string, number> = valuesData.reduce(
      (acc, item) => {
        return { ...acc, ...{ [item.name]: item.id } };
      },
      {},
    );
    const params: {
      name: string;
      value: string | boolean;
      id?: number;
    }[] = Object.entries(values)
      .map(([key, value]) => ({ name: key, value, id: idMapParamValues[key] }))
      .filter((param) => param.name !== 'EXCEL_LISTS');
    if (fileTypeName === FileTypesEnum.EXCEL) {
      files &&
        params.push({
          name: 'EXCEL_LISTS',
          value: files.join('*'),
          id: idMapParamValues.EXCEL_LISTS,
        });
    }

    try {
      dispatch(toggleSavingAction(true));
      await apiSetSourceParams(sourceIdProps!, params);
      await apiGetMapConnection(loaderId)
        .then((data) => {
          if (data) {
            dispatch(toggleSavingAction(false));
            dispatch(mapConnectionAction.addConnection({ loaderId, data }));
          }
        });
      closeListPanel();
    } catch (err: any) {
      dispatch(toggleSavingAction(false));
      setErrorSubmit(err.data.message);
    }
  };

  const handleFormSubmit = async (values: {
    [key: string]: string | boolean;
  }) => {
    setErrorSubmit(null);
    setFiles(null);
    setPreviewFile(null);

    const params = Object.entries(values).map(([key, value]) => ({
      name: key,
      value,
    }));
    setFormParams(params);
    try {
      const res = await apiGetFilesForPreview(
        connectionId,
        fileTypeName,
        params,
      );
      setFiles(res.data);
    } catch (err: any) {
      setErrorSubmit(err?.response?.data?.message);
    }
  };

  useEffect(() => setId(fileTypeId), [setId, fileTypeId]);
  useEffect(() => {
    sourceIdProps && setFiles(null);
    sourceIdProps && setErrorSubmit(null);
    setSourceId(sourceIdProps!);
  }, [setSourceId, sourceIdProps]);

  return (
    <div className={`${b()} ${className}`}>
      <h3 className={b('title')}>{title}</h3>

      {loadingRequestForParams || loadingRequestForValues ? (
        <CustomProgress type="linear" />
      ) : null}

      {errorValues ? (
        <p className={b('error', { mt: true })}>{errorValues}</p>
      ) : null}

      {paramsData.length ? (
        <FileForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleFormSubmit={handleFormSubmit}
          errorSubmit={errorSubmit}
          fieldsData={typedData}
          onCancel={closeNewFilePanel}
          isDisabledAddButton={isDisabledAddButton}
          addFileToSourceList={addFileToSourceList}
          changeSource={changeSource}
          isNewFile={isNewFile}
          filesComponent={
            <FileList
              files={files}
              fileTypeName={fileTypeName}
              previewFile={previewFile}
              setPreviewFile={setPreviewFile}
              setCheckedLists={setCheckedLists}
              isNewFile={isNewFile}
            />
          }
          isLocal={isLocal}
        />
      ) : errorParams ? (
        <p className={b('error', { mt: true })}>{errorParams}</p>
      ) : paramsData.length === 0 ? (
        <p className={b('no-data')}>Нет данных для отображения</p>
      ) : null}

      {previewFile && (
        <TablePreview
          className={b('table-preview')}
          name={previewFile}
          connectionId={connectionId}
          onClose={() => setPreviewFile(null)}
          fileTypeName={fileTypeName}
          formParams={formParams}
          isNew
        />
      )}
    </div>
  );
};

export default NewFilePanel;
