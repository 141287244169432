import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ComplexTableContext } from '../..';
import { PanelType } from '../../../../../../enums/widget-type';
import { setWidgetPropsAction } from '../../../../../../slices/widget/widget';
import { FilterGroupProps } from '../../../../../dashboard-page/hooks';
import { useDashboardFilters } from '../../../../../dashboard-page/hooks/useDashboardFilters';
import {
  getPanelIndex,
  getParsedAxisValues,
} from '../../../../dropdown-layout/helpers/helpers';
import { Property, PropertyData } from '../../../../dropdown-layout/helpers/Property';
import { isDashboardPage } from '../../../helpers';
import { getFilterSettingValue, getFilterValueInGroup } from '../../../../../../helpers/dashboard-page/filters-helper';
import { FilterProperty } from '../../../../../../enums/dashboard-properties';

interface useColumnFiltersParams {
  propertyIndex: number;
}

const isDashboardFilter = (
  filterId: number,
  dashboardFilters: FilterGroupProps[],
) => {
  return Boolean(
    dashboardFilters.find(
      (group) =>
        group.actual &&
        group.widgetFilters.find(
          (widgetFilter) => widgetFilter.actual && widgetFilter.id === filterId,
        ),
    ),
  );
};

export const useColumnFilters = ({ propertyIndex }: useColumnFiltersParams) => {
  const { widgetProperties, axisXValues, setDashboardWidgetState, widgetState } = useContext(ComplexTableContext);
  const isDashboard = isDashboardPage();
  const {
    getFilterByNameAndType,
    values: dashboardFilters
  } = useDashboardFilters();

  const dispatch = useDispatch();

  const getDashboardFilter = (currentFilter: PropertyData) => {
    const { name, aggregation } = axisXValues[propertyIndex];

    const type = axisXValues[propertyIndex].aggregation?.includes('COUNT')
      ? 'DOUBLE'
      : axisXValues[propertyIndex].type;

    const searchName = aggregation ? aggregation.replace('%s', name) : name;
    const filterGroupOnDashboard = getFilterByNameAndType(searchName, type);

    const isVisibleFilter = filterGroupOnDashboard
      ? getFilterSettingValue(filterGroupOnDashboard, FilterProperty.isVisible)
      : false;

    const dashboardAdaptedFilter = {
      aggregation: currentFilter?.aggregation,
      function: currentFilter?.function,
      name: currentFilter?.name,
      type: currentFilter?.type,
      id: currentFilter.id || currentFilter.widgetFieldId,
      isVisible: isVisibleFilter,
      filter: getFilterValueInGroup(dashboardFilters, searchName, type, parseInt(widgetState.id)),
      isDashboardFilter: isDashboardFilter(currentFilter.id || currentFilter.widgetFieldId || 0, dashboardFilters),
    };

    return dashboardAdaptedFilter;
  };

  const getFilter = (): any => {
    const filters = getParsedAxisValues(PanelType.axisFilter, widgetProperties);
    const currentFilter = filters[propertyIndex];

    if (!currentFilter) return {};

    if (isDashboardPage()) {
      if (!axisXValues[propertyIndex]) return {};

      return getDashboardFilter(currentFilter);
    }

    return filters[propertyIndex] || {};
  };

  const getAllFiltersFromAxis = () => {
    return getParsedAxisValues(PanelType.axisFilter, widgetProperties);
  };

  const getFilterResult = (filter: any) => {

    const filters = getAllFiltersFromAxis();
    filters[propertyIndex].filter = filter;

    return filters;
  };

  const getPropertiesWithNewFilter = (filter: any) => {
    const properties = [...widgetProperties];

    const axisIndex: number = getPanelIndex({
      properties,
      type: PanelType.axisFilter,
    });

    properties[axisIndex] = {
      ...(properties[axisIndex] || []),
      value: JSON.stringify(getFilterResult(filter)),
    };

    return properties;
  };

  const setFilterOnEditor = (filter: any) => {
    dispatch(setWidgetPropsAction(getPropertiesWithNewFilter(filter)));
  };

  const setFilterOnDashboard = (filter: any) => {
    if (!setDashboardWidgetState) return;

    setDashboardWidgetState([{
      name: PanelType.axisFilter,
      value: JSON.stringify(getFilterResult(filter)),
    }]);

  };

  return {
    getFilter,
    setFilter: isDashboard ? setFilterOnDashboard : setFilterOnEditor,
    getAllFiltersFromAxis
  };
};
