import React, { useContext } from 'react';
import {
  useBlockLayout,
  useFlexLayout,
  useResizeColumns,
  useTable,
} from 'react-table';
import { merge } from 'lodash';
import { ComplexTableContext } from '../../index';
import { isDashboardPage } from '../../../helpers';

export const useTableInstanse = ({
  columns,
  data,
  isScaleWidgetOnDashboard,
  controlledTableState
}: any) => {

  const { isEnabledResizing } = useContext(ComplexTableContext);

  const hooks: any[] = [
    useResizeColumns,
  ];

  if (isEnabledResizing && !isDashboardPage() || !isScaleWidgetOnDashboard) {
    hooks.push(useBlockLayout);
  } else {
    hooks.push(useFlexLayout);
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        ...controlledTableState,
      },
      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...merge(controlledTableState, state),
          }),
          [state]
        );
      }
    },
    ...hooks
  );

  return tableInstance;
};
