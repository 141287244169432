import React, { memo } from 'react';
import { ParametersPanel } from '../../components/common/parameters/parameters-panel';
import DashboardWidgetsController
  from '../../components/dashboard-page/dashboard-widgets/dashboard-widgets-controller';
import DashboardSidebar from '../../components/dashboard-page/sidebar';
import WidgetCarousel from '../../components/widget-page/widget-carousel/widget-carousel';
import './dashboard-page.css';
import DropdownLayout from '../../components/dashboard-page/dropdown-layout';
import CommonLoader from '../../components/common/common-loader/common-loader';
import { DashboardFilters } from '../../components/dashboard-page/filters';
import { useDashboardPage } from './useDashboardPage';


const DashboardPage = memo(() => {
  const { isActive, dashboardId } = useDashboardPage();

  return (
    <DropdownLayout>
      <div className="dashboard-layout">
        <div className="dashboard-content">
          <DashboardSidebar />
          <div className="dashboard-main">
            <DashboardWidgetsController key={dashboardId} dashboardId={dashboardId} isPreview={false} />
          </div>
          <DashboardFilters />
          <ParametersPanel isDashboard />
        </div>
        <WidgetCarousel />
      </div>
      {!isActive && <CommonLoader />}
    </DropdownLayout>
  );
});

DashboardPage.displayName = 'DashboardPage';

export default DashboardPage;
