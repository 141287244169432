import { CSSProperties } from 'react';

export const SMALL_DEBOUNCE_TIME = 100;

export const MIN_CHART_TOP_OFFSET = 12;
export const MARGIN_TOP_LABEL_VALUE = 4;
export const LABEL_LINE_HEIGHT = 18;

// todo взять цвета из темы
export const axisColor = 'var(--grey)';
export const guideColor = '#e0e0e0';

export const labelStyleProps = {
  fontSize: 14,
  lineHeight: `${LABEL_LINE_HEIGHT}px`,
  fontFamily: 'Roboto',
  fontWeight: 500,
  fill: axisColor,
  color: axisColor,
};

export const axisTickLabelProps: CSSProperties = {
  ...labelStyleProps,
  textAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const axisLeftTickLabelProps = {
  ...labelStyleProps,
  dx: '-0.25em',
  dy: '0.25em',
  textAnchor: 'end' as const,
};
