export enum DateNames {
  Неделя = 'week',
  Квартал = 'quarter',
  День = 'day',
  Год = 'year',
  Час = 'hour',
  Секунда = 'second',
  Минута = 'minute',
  Месяц = 'month',
}

export enum DateNamesValues {
  День = 'DD.MM.YYYY',
  Неделя = 'DD.MM.YYYY',
  Квартал = 'Q[Q].YYYY',
  Месяц = 'MM.YYYY',
  Год = 'YYYY',
  Секунда = 'DD.MM.YYYY',
  Минута = 'DD.MM.YYYY',
  Час = 'DD.MM.YYYY',
}

export enum DateFunctions {
  NO_TYPE = '',
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}