import React, { useState } from 'react';
import { LoaderTableSettingCellProps } from './loader-table-setting-cell';
import { useRoles } from '../../../../hooks/useRoles';
import { Roles } from '../../../../enums/roles';

export const useLoaderTableSettingCell = ({
  project,
}: LoaderTableSettingCellProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const openMenu = Boolean(anchorEl);

  const rolesAccess = useRoles({
    isShowEditPointsButton: project.permission !== Roles.Reporter,
    isAllowToGetInProject: project.permission !== Roles.Reporter,
  });

  return {
    handleMenuClick,
    openMenu,
    handleCloseMenu,
    anchorEl,
    rolesAccess,
  };
};
