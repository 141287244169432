import { apiETL } from '../utils/api';
import MapConnection from '../types/map-connection';

const SOURCE_OBJECTS_URL = '/source-objects';

/* Создание source object */
export const apiCreateSourceObject = async (
  body: MapConnection.SourceObject,
): Promise<number> => {
  try {
    const { data } = await apiETL.post(`${SOURCE_OBJECTS_URL}/`, body);
    return data;
  } catch (error: any) {
    console.error('Request failed:', error.response?.message);
    throw error;
  }
};

/* Получение schema по source object id */
export const apiGetSchemaBySourceObject = async (
  sourceObjectId: string,
): Promise<MapConnection.Schema> => {
  try {
    const { data } = await apiETL.get(
      `${SOURCE_OBJECTS_URL}/${sourceObjectId}/schema`,
    );
    return data;
  } catch (error: any) {
    console.error('Request failed:', error.response?.message);
    throw error;
  }
};

/* Обновление схемы источника */
export const apiUpdateSchemeBySourceObject = async (sourceObjectId: string) => {
  try {
    await apiETL.put(`${SOURCE_OBJECTS_URL}/${sourceObjectId}/schema`);
  } catch (error: any) {
    console.error(`Request failed: ${error.response?.status}`);
    throw error;
  }
};

export const apiGetSqlScript = async (sourceObjectId: string): Promise<string> => {
  try {
    const { data } = await apiETL.get(`${SOURCE_OBJECTS_URL}/${sourceObjectId}/pushdown-sql`);
    return data;
  } catch (error: any) {
    console.error('Request failed:', error.response?.message);
    throw error;
  }
};
