import React from 'react';
import IconSvg from '../../../../common/icon-svg/icon-svg';
import { CustomSwitch } from '../../../../../uikit/Switch';
import { SettingsButton } from '../settings-panel/settings-button';
import { useDashboardFilters } from '../../../hooks/useDashboardFilters';
import { FilterGroupProps } from '../../../hooks';
import CustomTooltip from '../../../../../uikit/CustomTooltip';
import { EditableTextContainer } from '../../../../common/special-inputs/editable-text-container/editable-text-container';
import { getIconByDataType } from '../../../../widget-page/helpers';
import { IconDictionary } from '../../../../../dictionaries/icon-dictonary/icon-dictionary';

interface ListItemHeadProps {
  group: FilterGroupProps;
}

export const ListItemHead = ({ group }: ListItemHeadProps) => {
  const { toggleActualGroup, renameFilterGroup } = useDashboardFilters();

  const renameFilterGroupCallback = (text: string) => {
    const filterGroupAlias = text.length > 0 && text !== group.name ? text : null;
    renameFilterGroup(group.id, filterGroupAlias);
  };

  return (
    <div className="filter-list-item__head">
      <IconSvg
        svg={getIconByDataType(group.type)}
        fill="var(--dark-grey)"
      />
      <EditableTextContainer
        text={group.alias || group.name}
        callback={renameFilterGroupCallback}
        textClassName="filter-title"
        textContainerClassName="filter-title-container"
      />

      {group.alias && (
        <CustomTooltip title={group.name}>
          <IconSvg svg={IconDictionary.InfoRounded} fill="var(--dark-grey)" />
        </CustomTooltip>
      )}
      <CustomSwitch
        checked={group.actual && Boolean(group.widgetFilters?.length)}
        onChange={() =>
          Boolean(group.widgetFilters?.length) && toggleActualGroup(group.id)}
      />
      <SettingsButton group={group} />
    </div>
  );
};
