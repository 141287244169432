import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardPropertyType } from '../../../enums/dashboard-properties';
import {
  getDashboardWithCrossFilters,
  getPropertyValue,
} from '../../../helpers/dashboard-page';
import { useRequestCanceller } from '../../../hooks/useRequestCanceller';
import { apigetDashboardById } from '../../../services/dashboardController';
import {
  closeDashboardFiltersAction,
  setCurrentDashboardAction,
  setInitPreviewFiltersAction,
} from '../../../slices/dashboard/dashboard';
import { resetSelectedMapCardsAction } from '../../../slices/main-page/main-page-slice';
import { State } from '../../../slices/types';
import { getBooleanPropertyValue } from '../../widget-page/charts/helpers';
import DashboardWidgets from './dashboard-widgets';

interface DashboardWidgetsControllerProps {
  dashboardId?: number;
  isPreview: boolean;
}

const DashboardWidgetsController = memo(({
  dashboardId,
  isPreview,
}: DashboardWidgetsControllerProps) => {
  const dispatch = useDispatch();
  const { cancellableController } = useRequestCanceller();

  const isNewDashboard = !dashboardId && !isPreview;

  const [isDashboardLoading, setDashboardLoading] = useState(!isNewDashboard);

  const crossFilters = useSelector(
    (state: State) => state.dashboard.crossFilters,
  );

  useEffect(() => {
    if (dashboardId) {
      apigetDashboardById(dashboardId, isPreview)
        .then((result) => {
          dispatch(setCurrentDashboardAction(result));

          const isActiveCrossFiltration = getBooleanPropertyValue(
            DashboardPropertyType.isActiveCrossFiltration,
            result.properties,
          );

          if (isPreview && isActiveCrossFiltration) {
            const dashboard = crossFilters
              ? getDashboardWithCrossFilters(result, crossFilters)
              : result;
            dispatch(setCurrentDashboardAction(dashboard));
            dispatch(
              setInitPreviewFiltersAction(
                getPropertyValue(
                  DashboardPropertyType.filters,
                  result.properties,
                ),
              ),
            );
          }
        })
        .finally(() => setDashboardLoading(false));
    }

    return () => {
      cancellableController.cancelAll();

      dispatch(closeDashboardFiltersAction());
      dispatch(resetSelectedMapCardsAction());
    };
  }, [dashboardId, cancellableController, dispatch, isPreview, crossFilters]);

  return isDashboardLoading ? null : (
    <DashboardWidgets isPreview={isPreview} />
  );
});

DashboardWidgetsController.displayName = 'DashboardWidgetsController';

export default DashboardWidgetsController;
