import { MainMenuItem } from './type';

export const mainMenuItems: MainMenuItem[] = [
  {
    dictionaryId: 50,
    name: 'Представление данных',
    link: '/auth',
    img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADNSURBVHgB5ZbRDcMgDESPKgOwSRkhHamTZKQwAqOwAcUVkQCRYtOmrZT3RYhPDnbOQoUQNACDPl4p5WgRNRSvGRpHwXPgsW4qWjM18wUHM1XP9+exShbsl9AlTY5JmmYCF+ts8414TI99fCO+CJjwHprqXO1dP5mAyrG+Cvh6k7lQY1k+GEqwGY5DncDUfwEaX3o+J1M9b4zYluH6To4NI6HFGH0nh4FxLWECw40JC14pC/5+XIsTSMe1OAHLyfk76bheIOP341qFg68tD30y+svj7LdyAAAAAElFTkSuQmCC',
    disabled: false,
  },
];
