import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { styled } from '@material-ui/core';
import React, { useMemo } from 'react';
import { CustomAutocompletePopper } from './popper';

interface CustomAutocompleteProps
  extends AutocompleteProps<any, any, any, any> {
  onlyInput?: boolean;
  backgroundColor?: string;
  hideClearButton?: boolean;
}

const StyledAutocomplete = styled(Autocomplete)(
  ({ onlyInput, backgroundColor, hideClearButton }: CustomAutocompleteProps) => ({
    '& [class*=MuiAutocomplete-inputRoot]': {
      height: 'calc(44px * var(--scale-coefficient))',
      overflow: 'hiddden',
      '&:hover': {
        backgroundColor: backgroundColor || 'var(--white)',
      },
      '&:before': {
        content: 'none',
      },
      '&:after': {
        content: 'none',
      },
      backgroundColor: backgroundColor || 'var(--white)',
      border: '1px solid var(--dark-cyan)',
      borderRadius: 'var(--base-border-radius) !important',
      overflowY: 'hidden',
      maxHeight: '100px',
      paddingTop: '0 !important',
      paddingRight: onlyInput
        ? '10px !important'
        : 'calc(65px * var(--scale-coefficient)) !important',

      '& [class*=MuiAutocomplete-endAdornment]': {
        top: 'calc(50% - 14px * var(--scale-coefficient))',
      },
      '& [class*=MuiAutocomplete-clearIndicator]': {
        display: hideClearButton ? 'none' : undefined,
      },
    },
    '& [class*=MuiAutocomplete-tag]': {
      backgroundColor: backgroundColor || 'var(--white)',
      color: 'var(--dark-grey)',
      fontSize: 'calc(14px * var(--scale-coefficient))',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      border: '1px solid var(--dark-cyan)',
      borderRadius: '20px',
      padding: 'calc(5px * var(--scale-coefficient))',

      '&[class*=MuiChip-root]': {
        display: 'none',
        maxWidth: 'calc(130px * var(--scale-coefficient))',
        height: 'calc(32px * var(--scale-coefficient))',
        textOverflow: 'ellipsis',
        color: 'var(--black)',
        padding: 'auto',

        '&:first-child': {
          display: 'flex',
        },
      },

      '& > .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  }),
);

export const CustomAutocomplete = ({
  onlyInput = false,
  multiple = true,
  hideClearButton = false,
  ...props
}: CustomAutocompleteProps) => {

  return (
    <StyledAutocomplete
      multiple={multiple}
      onlyInput={onlyInput}
      limitTags={1}
      noOptionsText="Нет вариантов"
      disableCloseOnSelect
      fullWidth
      ListboxProps={{ style: { maxHeight: '300px' } }}
      {...props}
      PopperComponent={(popperProps) => {
        return (
          <CustomAutocompletePopper
            {...popperProps}
            backgroundColor={props.backgroundColor}
          >
            {popperProps.children}
          </CustomAutocompletePopper>
        );
      }}
      hideClearButton={hideClearButton}
    />
  );
};
