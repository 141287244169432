import React, { useMemo } from 'react';
import clsx from 'clsx';
import { scaleOrdinal } from '@visx/scale';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import './legend-horizontal.css';
import { initValueFormatter } from '../hooks/initValueFormatter';
import { PanelType, WidgetPropertyType } from '../../../../enums/widget-type';
import { setPropForNumberValue } from '../formatting-helpers';
import { getBooleanPropertyValue, getSelectPropertyValue } from '../helpers';
import { useRoundingCounts } from '../../../../hooks/useRoundingCounts';
import { NumberFormat } from '../../../../enums/number-format';
import { getFirstActivePanelItemIndex } from '../../../../helpers/common-helpers';

const legendGlyphSize = 16;

export function LegendHorizontal({
  colors,
  data,
  className,
  style,
  widgetProperties,
  zName,
  valueName
}: {
  colors: any;
  data: any;
  className?: string;
  style?: any;
  zName?: string;
  valueName?: string;
  widgetProperties: any[];
}) {
  const labels = Object.values(data).map((item: any) => item.label) as string[];
  const counts = Object.values(data).map((item: any) => item.count) as number[];
  const zPercent = Object.values(data).map((item: any) => item.zpercent) as number[];
  const zValue = Object.values(data).map((item: any) => item.zvalue) as number[];
  const percents = Object.values(data).map(
    (item: any) => item.percent,
  ) as number[];

  const ordinal = {
    domain: labels,
    range: colors,
  };

  const ordinalColorScale = scaleOrdinal(ordinal);

  const roundingCount = useRoundingCounts(widgetProperties);

  const valueFormat = initValueFormatter({ roundingCount });

  const isNeedToDisplayPercentOnLegend: boolean = getBooleanPropertyValue(
    'displayPercentOnLegend',
    widgetProperties,
  );

  const isNeedToDisplayValueOnLegend: boolean = getBooleanPropertyValue(
    'displayValueOnLegend',
    widgetProperties,
  );

  const isNeedToDisplayTextOnLegend: boolean = getBooleanPropertyValue(
    'displayTextLegend',
    widgetProperties,
  );

  const formatByNumber: NumberFormat =
    useMemo(
      () =>
        getSelectPropertyValue(
          WidgetPropertyType.formatByNumber,
          widgetProperties,
        ) as NumberFormat,
      [widgetProperties],
    ) || NumberFormat.ru;

  const scaleByNumber: boolean = useMemo(
    () =>
      getBooleanPropertyValue(
        WidgetPropertyType.scaleByNumber,
        widgetProperties,
      ),
    [widgetProperties],
  );

  const axisXValueIndex = getFirstActivePanelItemIndex(widgetProperties, PanelType.axisX);

  return (
    <div className={clsx('legend-donut screenshot-overflow', { className })} style={style}>
      <LegendOrdinal scale={ordinalColorScale}>
        {(labels) =>
          labels.map((label: any, i: number) => (
            <LegendItem key={`legend-${i}`}>
              <svg
                width={legendGlyphSize}
                height={legendGlyphSize}
                style={{ margin: '11px 0', minWidth: `${legendGlyphSize}px` }}
              >
                <rect
                  fill={label.value}
                  width={legendGlyphSize}
                  height={legendGlyphSize}
                  rx={2}
                />
              </svg>
              <LegendLabel align="left">
                <div
                  className="legend-donut-label__content"
                  style={
                    (isNeedToDisplayValueOnLegend && counts[i]) ||
                    (isNeedToDisplayPercentOnLegend && percents[i])
                      ? {}
                      : { minWidth: 'auto' }
                  }
                >
                  <div className="legend-donut-label__text">
                    {valueFormat(label.text, axisXValueIndex, widgetProperties)}
                  </div>
                  <div
                    className="legend-donut-label__percents"
                    style={
                      (isNeedToDisplayValueOnLegend && counts[i]) ||
                      (isNeedToDisplayPercentOnLegend && percents[i] !== undefined)
                        ? {}
                        : { margin: 0, display: 'none' }
                    }
                  >
                    {!zName
                      ? <>
                        {isNeedToDisplayValueOnLegend && counts[i] && (
                        <div>
                          {valueFormat(
                            counts[i],
                            0,
                            setPropForNumberValue(widgetProperties),
                            PanelType.axisY,
                            false,
                            false,
                            scaleByNumber ? formatByNumber : null,
                            true
                          )}
                        </div>
                        )}
                        {isNeedToDisplayPercentOnLegend && percents[i] !== undefined && (
                        <div className="legend-donut-label__percent-value">
                          {valueFormat(
                            percents[i],
                            0,
                            setPropForNumberValue(widgetProperties),
                            PanelType.axisY,
                          )}
                          %
                        </div>
                        )}
                        </>
                      : <>
                        <div className="legend-donut-label__container">
                          <span>{isNeedToDisplayTextOnLegend && `${valueName}:`}</span>
                          {isNeedToDisplayValueOnLegend && counts[i] && (
                            <div className="legend-donut-label__multi-value">
                              {valueFormat(
                                counts[i],
                                0,
                                setPropForNumberValue(widgetProperties),
                                PanelType.axisY,
                                false,
                                false,
                                scaleByNumber ? formatByNumber : null,
                                true
                              )}
                            </div>
                          )}
                          {isNeedToDisplayPercentOnLegend && percents[i] !== undefined && (
                            <div className="legend-donut-label__percent-multi-value">
                              ({valueFormat(
                              percents[i],
                              0,
                              setPropForNumberValue(widgetProperties),
                              PanelType.axisY,
                            )}
                              %)
                            </div>
                          )}
                        </div>
                        <div className="legend-donut-label__container">
                          {isNeedToDisplayTextOnLegend && `${zName}:`}
                          {isNeedToDisplayValueOnLegend && zValue[i] && (
                            <div className="legend-donut-label__multi-value">
                              {valueFormat(
                                zValue[i],
                                0,
                                setPropForNumberValue(widgetProperties),
                                PanelType.axisY,
                                false,
                                false,
                                scaleByNumber ? formatByNumber : null,
                                true
                              )}
                            </div>
                          )}
                          {isNeedToDisplayPercentOnLegend && (zPercent[i] !== undefined) && (
                            <div className="legend-donut-label__percent-multi-value">
                              ({valueFormat(
                              zPercent[i],
                              0,
                              setPropForNumberValue(widgetProperties),
                              PanelType.axisY,
                            )}
                              %)
                            </div>
                          )}
                        </div>
                        </>}
                  </div>
                </div>
              </LegendLabel>
            </LegendItem>
          ))}
      </LegendOrdinal>
    </div>
  );
}
