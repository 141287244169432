import { getScaleCoefficient } from '../../../../helpers/ui-helper';
import { isNeedMergeNotNumberValues } from '../../../../utils/functions';
import { PropertyData } from '../../dropdown-layout/helpers/Property';

export enum MultilineTextVariant {
  oneString = 'в 1 строку',
  twoString = 'в 2 строки',
  threeString = 'в 3 строки',
}

export const getStringCountByMultilineTextVariant = (variant: MultilineTextVariant) => {
  switch (variant) {
    case MultilineTextVariant.oneString:
      return 1;
    case MultilineTextVariant.twoString:
      return 2;
    case MultilineTextVariant.threeString:
      return 3;
    default:
      return 1;
  }
};

export const getRowHeight = (variant: MultilineTextVariant, valueSize: number) => {
  const initialFontSize = 14 * getScaleCoefficient();
  const heightPerPixelOffset = 3;
  const defaultRowHeight = 32 * getScaleCoefficient();
  const cellFontSize = valueSize * getScaleCoefficient();

  const stringsCount = getStringCountByMultilineTextVariant(variant);

  return defaultRowHeight + ((cellFontSize * stringsCount) + (cellFontSize / initialFontSize * heightPerPixelOffset) * stringsCount);
};

export const getFiltersValues = (axisXValues: PropertyData[], rowCells: any) => {
  const selectedFieldsIds: number[] = [];
  const values: string[] = [];

  axisXValues.forEach((item) => {
    const isNeedAddValue = item.isDrillDownFilter && item.isActive;
    if (isNeedAddValue) {
      const columnId = item.clientUID || '';
      const value = rowCells[columnId]?.value;
      values.push(value);
      const id = item.id || item.widgetFieldId;
      id && selectedFieldsIds.push(id);
    }
  });

  return {
    selectedFieldsIds,
    values
  };
};

export const getTableHeader = (axisXValues: PropertyData[]) => {
  return axisXValues
    .filter((axisXValue) => axisXValue.isActive !== false)
    .map((property, index: number) => {
      return {
        title: property.name,
        accessor: `${property.clientUID || `temp_${index}`}`,
        cellVariant: JSON.parse(property?.storage ?? '{}')?.cellVariant || 'TEXT',
        width: JSON.parse(property?.storage ?? '{}')?.width || undefined,
      };
    });
};
