import React from 'react';
import SVGInline from 'react-svg-inline';
import { makeStyles } from '@material-ui/styles';

export interface Icon {
  width?: number;
  height?: number;
  fill: string;
  classSVG?: string;
  svg: string;
  isNeedToScale?: boolean;
}

interface Props extends Icon {
  component?: any; // тег обертка для svg по умолчанию тег i
}

const useStyles = makeStyles({
  'center-svg-inline': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const IconSvg: React.FC<Props> = ({
  fill,
  classSVG = '',
  component = 'i',
  width = 24,
  height = 24,
  svg,
  isNeedToScale = true,
}) => {
  const classes = useStyles();

  return (
    <SVGInline
      svg={svg}
      component={component}
      className={`${classSVG} ${classes['center-svg-inline']}`}
      fill={fill}
      width={`calc(${width}px * ${
        isNeedToScale ? 'var(--scale-coefficient)' : 1
      })`}
      height={`calc(${height}px * ${
        isNeedToScale ? 'var(--scale-coefficient)' : 1
      })`}
    />
  );
};

export default IconSvg;
